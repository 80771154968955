import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import BaseModal from "../../components/modal/BaseModal";
import FullCard from "../../components/cards/FullCard";
import BaseImage from "../../components/images/BaseImage";
import i18n from "../../language/i18n";
import {
  createStandardExamAccessibilityByStandardExamId,
  createStandardExamWithActivityIdList,
  deleteStandardExam,
  patchStandardExam,
  queryAllStandardExamDTOPageByAccessibilityParams,
  queryAllStandardExamDTOPageByUserIdAndUserProfileId,
  queryStandardExamActivityCountByParams,
  retrieveStandardExamById,
} from "../../requests/CatchtivityRequests";
import {
  checkDeletePermissionGranted,
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import AdvancedTable from "../../components/tables/AdvancedTable";
import { NotificationManager } from "react-notifications";
import {
  retrieveCoterieTypeFromStandardExamCoterieType,
  retrieveStandardExamCoterieTypeOptionListByStandardExamType,
  retrieveStandardExamTypeIcon,
  retrieveStandardExamTypeOptionList,
  retrieveValidationRequirementList,
} from "../../utilization/CatchtivityUtilization";
import BaseTitle from "../../components/titles/BaseTitle";
import DividerLine from "../../components/divider/DividerLine";
import InputGroup from "../../components/groups/InputGroup";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import ListActivity from "../../components/activity/ListActivity";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import ListActivityBundle from "../../components/activity/ListActivityBundle";
import debounce from "lodash.debounce";
import SingleActivityItem from "../../components/activity/SingleActivityItem";
import SelectionTab from "../../components/tabs/SelectionTab";
import SingleInformationIconTextItem from "../../components/infos/SingleInformationIconTextItem";
import CreateButton from "../../components/buttons/CreateButton";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const StandardExamListScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen, catchtivityStompClient } = useApp();
  const {
    language,
    categorySet,
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
  } = useAuth();
  const [pageState, setPageState] = useState(0);
  const [innerPageState, setInnerPageState] = useState(0);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedStandardExamType, setSelectedStandardExamType] =
    useState("DEFAULT_OPTION");
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterTextContains, setFilterTextContains] = useState("");
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [filterStandardExamType, setFilterStandardExamType] =
    useState("DEFAULT_OPTION");
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [someoneInformation, setSomeoneInformation] = useState(null);
  const [showSomeoneUpdatedModal, setShowSomeoneUpdatedModal] = useState(false);
  const [standardExamDTOList, setStandardExamDTOList] = useState([]);
  const [standardExamActivityList, setStandardExamActivityList] = useState([]);
  const [
    selectedStandardExamActivityCoterieType,
    setSelectedStandardExamActivityCoterieType,
  ] = useState(null);
  const [correspondingId, setCorrespondingId] = useState("DEFAULT_OPTION");
  const [errorMap, setErrorMap] = useState({
    name: null,
    description: null,
  });
  const [listActivityState, setListActivityState] = useState(0);
  const [subscribedId, setSubscribedId] = useState(null);
  const [activityCountDTOList, setActivityCountDTOList] = useState([]);
  const [debounceState, setDebounceState] = useState("");

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("standard_exam_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetSpecificStandardExamById = async () => {
      const { data, err } = await retrieveStandardExamById(id);
      if (err) {
        console.log(err);
      } else {
        const { standardExamActivityDTOList } = data;
        const convertedStandardExamActivityDTOList =
          standardExamActivityDTOList.map((standardActivityDTO) => ({
            id: standardActivityDTO.id,
            standardExamCoterieType:
              standardActivityDTO.standardExamCoterieType,
            coterieType: standardActivityDTO.coterieType,
            standardExamActivityItemSet:
              standardActivityDTO.standardExamActivityItemDTOList.map(
                (standardExamActivityItem) => ({
                  activity: standardExamActivityItem.activityDTO,
                  index: standardExamActivityItem.index,
                })
              ),
          }));
        setStandardExamActivityList(convertedStandardExamActivityDTOList);
      }
    };
    if (id) {
      retrieveAndSetSpecificStandardExamById();
    }
  }, [id]);

  useEffect(() => {
    retrieveAndSetStandardExamPageByFilter();
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    setDebounceState("BASE_FILTER");
    retrieveAndSetStandardExamPageByFilter();
  }, [
    isFilterApplied,
    filterBeginAt,
    filterEndAt,
    filterStandardExamType,
    filterShowDeleted,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    if (filterTextContains) {
      setDebounceState("TEXT_CONTAINS");
      debouncedRetrieveAndSetStandardExamPageByFilterDebounce(
        filterTextContains
      );
    } else {
      retrieveAndSetStandardExamPageByFilter();
    }
  }, [filterTextContains]);

  useEffect(() => {
    setListActivityState(0);
  }, [selectedStandardExamActivityCoterieType]);

  useEffect(() => {
    if (id && catchtivityStompClient) {
      const subscribed = catchtivityStompClient.subscribe(
        "/topic/standard-exam",
        (message) => {
          let { body } = message;
          body = JSON.parse(body);
          if (body.state === "UPDATED" && body.standardExamId === id) {
            catchtivityStompClient.unsubscribe(subscribedId);
            setSubscribedId(null);
            handleReturnFromStandardExamOnClick();
            retrieveAndSetStandardExamPageByFilter();
            if (body.username !== userInformation.username) {
              setSomeoneInformation({
                username: body.username,
                firstName: body.firstName,
                lastName: body.lastName,
              });
              setShowSomeoneUpdatedModal(true);
            }
          }
        }
      );
      setSubscribedId(subscribed.id);
    }
  }, [id]);

  useEffect(() => {
    if (pageState === 0 && subscribedId) {
      catchtivityStompClient.unsubscribe(subscribedId);
      setSubscribedId(null);
    }
  }, [pageState]);

  useEffect(() => {
    const retrieveAndSetActivityCountByParams = async (activityIdList) => {
      const { data, err } = await queryStandardExamActivityCountByParams({
        activityIdList,
        brandId: userProfileBrand.id,
        campusId: userProfileCampus?.id,
        institutionId: userProfileInstitution?.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      setActivityCountDTOList(data);
    };
    const selectedActivityList = retrieveSelectedStandardExamActivityItemList();
    if (selectedActivityList.length > 0) {
      const selectedActivityIdList = selectedActivityList.map(
        (selectedActivity) => selectedActivity.id
      );
      retrieveAndSetActivityCountByParams(selectedActivityIdList);
    }
  }, [standardExamActivityList]);

  const retrieveAndSetStandardExamPageByFilter = async () => {
    setTableLoading(true);
    let data;
    let err;
    if (userProfile.role === "STAFF") {
      const { data: staffData, err: staffErr } =
        await queryAllStandardExamDTOPageByAccessibilityParams(
          pagedQueryParams
        );
      data = staffData;
      err = staffErr;
    } else if (
      userProfile.role === "INDIVIDUAL" ||
      userProfile.role === "CONTENT_CREATOR"
    ) {
      const { data: individualData, err: individualErr } =
        await queryAllStandardExamDTOPageByUserIdAndUserProfileId(
          pagedQueryParams
        );
      data = individualData;
      err = individualErr;
    }

    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setStandardExamDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const retrieveAndSetStandardExamPageByFilterDebounce = async (
    filterTextContains
  ) => {
    setTableLoading(true);
    if (isFilterApplied) {
      pagedQueryParams.params.textContains = filterTextContains.trim();
    }
    let data;
    let err;
    if (userProfile.role === "STAFF") {
      const { data: staffData, err: staffErr } =
        await queryAllStandardExamDTOPageByAccessibilityParams(
          pagedQueryParams
        );
      data = staffData;
      err = staffErr;
    } else if (
      userProfile.role === "INDIVIDUAL" ||
      userProfile.role === "CONTENT_CREATOR"
    ) {
      const { data: individualData, err: individualErr } =
        await queryAllStandardExamDTOPageByUserIdAndUserProfileId(
          pagedQueryParams
        );
      data = individualData;
      err = individualErr;
    }

    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setStandardExamDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const debouncedRetrieveAndSetStandardExamPageByFilterDebounce = useCallback(
    debounce(function (textContains) {
      retrieveAndSetStandardExamPageByFilterDebounce(textContains);
    }, 1000),
    [debounceState]
  );

  const retrieveSelectedStandardExamActivityItemList = () => {
    const foundStandardExamActivity = standardExamActivityList.find(
      (standardExamActivity) =>
        standardExamActivity.standardExamCoterieType ===
        selectedStandardExamActivityCoterieType
    );
    if (foundStandardExamActivity) {
      return foundStandardExamActivity.standardExamActivityItemSet;
    }
    return [];
  };

  const filterCorrespondingTYTOptionList = () => {
    return standardExamDTOList
      .filter((standardExamDTO) => standardExamDTO.standardExamType === "TYT")
      .map((standardExamDTO) => ({
        value: standardExamDTO.id,
        text: standardExamDTO.name,
      }));
  };

  const checkCanNavigate = () => {
    let canNavigate = true;
    if (!checkViewPermissionGranted("standard_exam_accessibility")) {
      canNavigate = false;
    }
    return canNavigate;
  };

  const checkCommonValidation = () => {
    if (
      !name ||
      name.trim() === "" ||
      !description ||
      description.trim() === "" ||
      (selectedStandardExamType === "AYT" &&
        correspondingId === "DEFAULT_OPTION")
    ) {
      const currentErrorMap = {
        name: null,
        description: null,
      };
      if (!name || name.trim() === "") {
        currentErrorMap.name = i18n.t("name_required_field");
      }
      if (!description || description.trim() === "") {
        currentErrorMap.description = i18n.t("description_required_field");
      }
      if (
        selectedStandardExamType === "AYT" &&
        correspondingId === "DEFAULT_OPTION"
      ) {
        NotificationManager.error(i18n.t("corresponding_id_required_field"));
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const constructNavigatableList = () => {
    let navigateList = [];
    if (checkViewPermissionGranted("standard_exam_accessibility")) {
      navigateList.push({
        id: 0,
        text: i18n.t("to_standard_exam_accessibilities_screen"),
        icon: (
          <BaseImage
            src="/icons/navigate-gray.png"
            alt="navigate"
            size="small"
          />
        ),
        value: "/standard-exams/{id}/accessibilities",
      });
    }
    if (checkInsertPermissionGranted("standard_exam_list")) {
      navigateList.push({
        id: "fast_apply",
        text: i18n.t("fast_apply"),
        icon: (
          <BaseImage
            src="/icons/standard-exam.png"
            alt="standard-exam"
            size="small"
          />
        ),
        value: "/standard-exams/applications",
        includeKeyValueToParams: {
          standardExamId: "id",
        },
        params: {
          pageState: 1,
        },
      });
    }
    return navigateList;
  };

  const constructPagedQueryParams = () => {
    return {
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        beginAt:
          isFilterApplied && filterBeginAt
            ? new Date(filterBeginAt).getTime()
            : null,
        endAt:
          isFilterApplied && filterEndAt
            ? new Date(filterEndAt).getTime()
            : null,
        deleted: isFilterApplied ? (filterShowDeleted ? null : false) : false,
        userId: userInformation.id,
        userProfileId: userProfile.id,
        brandId: userProfileBrand.id,
        campusId: userProfileCampus?.id,
        institutionId: userProfileInstitution?.id,
        textContains:
          isFilterApplied && filterTextContains ? filterTextContains : null,
        standardExamType:
          isFilterApplied && filterStandardExamType !== "DEFAULT_OPTION"
            ? filterStandardExamType
            : null,
        deleted: filterShowDeleted === null ? null : filterShowDeleted,
      },
    };
  };

  const resetParams = () => {
    setId(null);
    setName(null);
    setDescription(null);
    setSelectedStandardExamType("DEFAULT_OPTION");
    setSelectedStandardExamActivityCoterieType(null);
    setCorrespondingId("DEFAULT_OPTION");
    setStandardExamActivityList([]);
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    } else if (type === "textContains") {
      setFilterTextContains(value);
    } else if (type === "standardExamType") {
      setFilterStandardExamType(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleReturnFromStandardExamOnClick = () => {
    resetParams();
    setPageState(0);
  };

  const handleToCreateStandardExamOnClick = () => {
    setId(null);
    setPageState(1);
    setInnerPageState(0);
  };

  const handleToUpdateStandardExamOnClick = (standardExamDTO) => {
    setId(standardExamDTO.id);
    setName(standardExamDTO.name);
    setDescription(standardExamDTO.description);
    setSelectedStandardExamType(standardExamDTO.standardExamType);
    if (standardExamDTO.standardExamType === "AYT") {
      setCorrespondingId(standardExamDTO.correspondingId);
    }
    setPageState(1);
    setInnerPageState(1);
  };

  const handleRemoveStandardExamOnClick = async (standardExamId) => {
    if (checkDeletePermissionGranted("standard_exam_list")) {
      const { data, err } = await deleteStandardExam(standardExamId);
      if (err) {
        if (err.response.data) {
          NotificationManager.error(i18n.t(err.response.data));
        }
      } else {
        const foundStandardExam = standardExamDTOList.find(
          (standardExamDTO) => standardExamDTO.id === standardExamId
        );
        foundStandardExam.deleted = true;
        setStandardExamDTOList(JSON.parse(JSON.stringify(standardExamDTOList)));
        retrieveAndSetStandardExamPageByFilter();
      }
    }
  };

  const handlePreviewStandardExamOnClick = (standardExamDTO) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    window.open(
      `${connection.app.url}/preview/standard-exam/${standardExamDTO.id}/${btoa(
        encodeURI(JSON.stringify({ token: accessToken }))
      )}`
    );
  };

  const handleUpdateStandardExamOnClick = async () => {
    if (checkUpdatePermissionGranted("standard_exam_list")) {
      if (checkCommonValidation()) {
        const convertedStandardExamActivityList = standardExamActivityList.map(
          (item) => ({
            id: item.id,
            standardExamCoterieType: item.standardExamCoterieType,
            coterieType: item.coterieType,
            patchStandardExamActivityItemList:
              item.standardExamActivityItemSet.map((innerItem, index) => ({
                activityId: innerItem.activity.id,
                index,
              })),
          })
        );
        const currentStandardExam = {
          id,
          userId: userInformation.id,
          userProfileId: userProfile.id,
          name,
          description,
          standardExamActivityList: convertedStandardExamActivityList,
        };
        if (selectedStandardExamType === "AYT") {
          currentStandardExam.correspondingId = correspondingId;
        }
        const { data, err } = await patchStandardExam(currentStandardExam);
        if (err) {
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
          return;
        }
        const foundIndex = standardExamDTOList.findIndex(
          (item) => item.id === data.id
        );
        if (foundIndex !== -1) {
          standardExamDTOList[foundIndex] = data;
          setStandardExamDTOList(
            JSON.parse(JSON.stringify(standardExamDTOList))
          );
          if (catchtivityStompClient) {
            catchtivityStompClient.send(
              "/app/standard-exam/updates",
              null,
              JSON.stringify({
                standardExamId: id,
                username: userInformation.username,
                firstName: userInformation.firstName,
                lastName: userInformation.lastName,
                state: "UPDATED",
              })
            );
          }
        }
        handleReturnFromStandardExamOnClick();
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleCreateStandardExamOnClick = async () => {
    if (checkInsertPermissionGranted("standard_exam_list")) {
      if (checkCommonValidation()) {
        const newStandardExamActivityList = JSON.parse(
          JSON.stringify(standardExamActivityList)
        );
        newStandardExamActivityList.forEach((standardExamActivity) => {
          const { standardExamActivityItemSet } = standardExamActivity;
          standardExamActivityItemSet.forEach(
            (standardExamActivityItem, index) => {
              standardExamActivityItem.activity = {
                id: standardExamActivityItem.activity.id,
              };
              standardExamActivityItem.index = index;
            }
          );
        });
        const currentStandardExam = {
          id,
          name,
          description,
          standardExamType: selectedStandardExamType,
          userId: userInformation.id,
          userProfileId: userProfile.id,
          standardExamActivitySet: newStandardExamActivityList,
        };
        if (selectedStandardExamType === "AYT") {
          currentStandardExam.correspondingId = correspondingId;
        }
        const { data, err } = await createStandardExamWithActivityIdList(
          currentStandardExam
        );
        if (err) {
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
          return;
        }
        standardExamDTOList.unshift(data);
        setStandardExamDTOList(JSON.parse(JSON.stringify(standardExamDTOList)));
        handleCreateStandardExamAccessibilityOnClick(data.id);
        handleReturnFromStandardExamOnClick();
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleCreateStandardExamAccessibilityOnClick = async (
    standardExamId
  ) => {
    let newStandardExamAccessibility = {
      userId: userInformation.id,
      userProfileId: userProfile.id,
      standardExam: { id: standardExamId },
    };
    if (userProfileInstitution) {
      newStandardExamAccessibility = {
        ...newStandardExamAccessibility,
        brandIdSet: [userProfileBrand.id],
        campusIdSet: [userProfileCampus.id],
        institutionIdSet: [userProfileInstitution.id],
      };
    } else if (userProfileCampus) {
      newStandardExamAccessibility = {
        ...newStandardExamAccessibility,
        brandIdSet: [userProfileBrand.id],
        campusIdSet: [userProfileCampus.id],
        institutionIdSet: [],
      };
    } else if (userProfileBrand) {
      newStandardExamAccessibility = {
        ...newStandardExamAccessibility,
        brandIdSet: [userProfileBrand.id],
        campusIdSet: [],
        institutionIdSet: [],
      };
    }
    await createStandardExamAccessibilityByStandardExamId(
      newStandardExamAccessibility
    );
  };

  const handleAddStandardExamActivityOnClick = (standardExamCoterieType) => {
    const foundIndex = standardExamActivityList.findIndex(
      (standardExamActivity) =>
        standardExamActivity.standardExamCoterieType === standardExamCoterieType
    );
    if (foundIndex === -1) {
      standardExamActivityList.push({
        standardExamCoterieType,
        coterieType: retrieveCoterieTypeFromStandardExamCoterieType(
          selectedStandardExamType,
          standardExamCoterieType
        ),
        standardExamActivityItemSet: [],
      });
      setStandardExamActivityList(
        JSON.parse(JSON.stringify(standardExamActivityList))
      );
    }
  };

  const handleRemoveActivityFromStandardExam = (standardExamCoterieType) => {
    const foundIndex = standardExamActivityList.findIndex(
      (standardExamActivity) =>
        standardExamActivity.standardExamCoterieType === standardExamCoterieType
    );
    if (foundIndex > -1) {
      standardExamActivityList.splice(foundIndex, 1);
      setStandardExamActivityList(
        JSON.parse(JSON.stringify(standardExamActivityList))
      );
    }
    setSelectedStandardExamActivityCoterieType(null);
  };

  const handleOnChange = (type, value) => {
    if (type === "name") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: i18n.t("name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: null,
        }));
      }
      setName(value);
    } else if (type === "description") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: i18n.t("description_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: null,
        }));
      }
      setDescription(value);
    } else if (type === "correspondingId") {
      setCorrespondingId(value);
    }
  };

  const handlePreviewActivityOnClick = (activityDTO) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    window.open(
      `${connection.app.url}/preview/activity/${activityDTO.id}/${btoa(
        encodeURI(JSON.stringify({ token: accessToken }))
      )}`
    );
  };

  const handleAddActivityToStandardExamActivity = (item) => {
    const foundStandardExamActivity = standardExamActivityList.find(
      (standardExamActivity) =>
        standardExamActivity.standardExamCoterieType ===
        selectedStandardExamActivityCoterieType
    );
    if (!foundStandardExamActivity) return;
    const foundIndex =
      foundStandardExamActivity.standardExamActivityItemSet.findIndex(
        (standardExamActivityItem) =>
          parseFloat(standardExamActivityItem.activity.id) ===
          parseFloat(item.id)
      );
    if (foundIndex === -1) {
      foundStandardExamActivity.standardExamActivityItemSet.push({
        activity: {
          id: item.id,
          name: item.name,
          categoryIdList: item.categoryIdList,
          activityTemplateDTOList: item.activityTemplateDTOList,
          description: item.description,
          coterieType: item.coterieType,
          status: item.status,
          createdAt: item.createdAt,
        },
      });
    } else {
      foundStandardExamActivity.standardExamActivityItemSet.splice(
        foundIndex,
        1
      );
    }
    setStandardExamActivityList(
      JSON.parse(JSON.stringify(standardExamActivityList))
    );
  };

  const RenderValidationRequirements = () => {
    const validationRequirementList = retrieveValidationRequirementList(
      selectedStandardExamType
    );
    return (
      <div className="flex flex-row items-center justify-center flex-wrap gap-x-4 gap-y-2">
        {validationRequirementList.map((item) => {
          const foundStandardExamActivity = standardExamActivityList.find(
            (standardExamActivity) =>
              standardExamActivity.standardExamCoterieType === item.value
          );
          let currentCount = 0;
          let standardExamCoterieType;
          let standardExamCoterieTypeExist =
            standardExamActivityList.filter(
              (standardExamActivity) =>
                standardExamActivity.standardExamCoterieType === item.value
            ).length > 0;
          if (foundStandardExamActivity) {
            const { standardExamActivityItemSet } = foundStandardExamActivity;
            currentCount = standardExamActivityItemSet.length;
            standardExamCoterieType =
              foundStandardExamActivity.standardExamCoterieType;
          }
          return (
            <div
              className={`${
                currentCount > item.count
                  ? "text-catchup-red border-catchup-red border rounded-catchup-xlarge"
                  : currentCount === item.count
                  ? "text-catchup-green"
                  : standardExamCoterieType ===
                    selectedStandardExamActivityCoterieType
                  ? "text-catchup-blue"
                  : standardExamCoterieTypeExist
                  ? "text-catchup-dark-blue"
                  : currentCount === 0
                  ? "text-catchup-gray opacity-70"
                  : "text-catchup-red border-catchup-red border rounded-catchup-xlarge"
              } p-2`}
            >
              <p>
                <span className="font-semibold">{i18n.t(item.value)}</span>
                {": "}
                {currentCount}/{item.count}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={i18n.t("standard_exam_list")}
            description={i18n.t("standard_exam_list_table_description_text")}
            itemName={i18n.t("standard_exam")}
            headerList={[
              { name: i18n.t("name"), key: "name", type: "info" },
              {
                name: i18n.t("standard_exam_type"),
                key: "standardExamType",
                type: "enumeration",
              },
              {
                name: i18n.t("created_at"),
                key: "createdAt",
                type: "datetime",
              },
            ]}
            selectable={false}
            showFilter={true}
            showItemCount={selectedShowItemCount}
            totalItemCount={totalItemCount}
            pageNumber={pageNumber}
            loading={tableLoading}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleOnFilterChange={handleOnFilterChange}
            handleOnTableParamsChange={handleOnTableParamsChange}
            filterParams={{
              isFilterApplied,
              showBeginDate: true,
              beginAt: filterBeginAt,
              showEndDate: true,
              endAt: filterEndAt,
              showTextContains: true,
              textContains: filterTextContains,
              showDeleted: filterShowDeleted,
              showStandardExamType: true,
              standardExamType: filterStandardExamType,
            }}
            itemList={standardExamDTOList}
            insertable={checkInsertPermissionGranted("standard_exam_list")}
            handleInsertOnClick={handleToCreateStandardExamOnClick}
            updatable={checkUpdatePermissionGranted("standard_exam_list")}
            handleUpdateOnClick={handleToUpdateStandardExamOnClick}
            deletable={checkDeletePermissionGranted("standard_exam_list")}
            handleDeleteOnClick={handleRemoveStandardExamOnClick}
            previewable={true}
            handlePreviewOnClick={handlePreviewStandardExamOnClick}
            navigatable={checkCanNavigate()}
            navigateOptionList={constructNavigatableList()}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <div className={`${innerPageState === 0 ? "block" : "hidden"}`}>
            <FullCard isShadowed={true}>
              <div className="">
                <BaseTitle title={i18n.t("standard_exam_type_selection")} />
              </div>
              <DividerLine />
              <div className="flex flex-row flex-wrap">
                {retrieveStandardExamTypeOptionList().map(
                  (baseReportType, baseReportTypeIndex) => (
                    <div
                      key={baseReportTypeIndex}
                      className="w-[200px] h-[200px] m-4"
                    >
                      <div
                        className="flex-1 flex flex-col h-full justify-center items-center  rounded-catchup-xlarge p-6 cursor-pointer"
                        onClick={() => {
                          setSelectedStandardExamType(baseReportType.value);
                          setInnerPageState(1);
                        }}
                        style={{
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                      >
                        <div className="flex-1 flex flex-col justify-center items-center gap-y-2">
                          <BaseImage
                            size="large"
                            src={retrieveStandardExamTypeIcon(
                              baseReportType.value
                            )}
                            alt="base-report"
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </FullCard>
          </div>
          <div className={`${innerPageState === 1 ? "block" : "hidden"}`}>
            <FullCard isShadowed={true}>
              <div className="">
                <div className="flex flex-row items-center gap-x-2">
                  <div className="cursor-pointer">
                    <BaseImage
                      size="medium"
                      src="/icons/long-arrow-left.png"
                      alt="long-arrow-left"
                      onClick={() => {
                        handleReturnFromStandardExamOnClick();
                      }}
                    />
                  </div>
                  <BaseTitle
                    title={
                      id
                        ? `${i18n.t(
                            "update_standard_exam"
                          )} - ${selectedStandardExamType}`
                        : `${i18n.t(
                            "create_standard_exam"
                          )} - ${selectedStandardExamType}`
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col items-center justify-center">
                <DividerLine />
                <div className="w-full">
                  <p className="font-semibold text-lg">
                    {i18n.t("standard_exam_information")}
                  </p>
                </div>
                <div className="w-full flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("name")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="text"
                      value={name}
                      onChange={(e) => {
                        handleOnChange("name", e.target.value);
                      }}
                      errorText={errorMap.name}
                    />
                  </div>
                </div>

                <div className="w-full flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("description")}</p>
                  </div>
                  <div className="flex-1 h-catchup-description">
                    <InputGroup
                      type="textarea"
                      value={description}
                      onChange={(e) => {
                        handleOnChange("description", e.target.value);
                      }}
                      errorText={errorMap.description}
                    />
                  </div>
                </div>

                {selectedStandardExamType === "AYT" ? (
                  <div className="w-full flex flex-row">
                    <div className="w-catchup-input-group-title py-5">
                      <p>{i18n.t("corresponding_tyt")}</p>
                    </div>
                    <div className="flex-1">
                      <InputGroup
                        type="select"
                        value={correspondingId}
                        optionList={filterCorrespondingTYTOptionList()}
                        onChange={(event) => {
                          handleOnChange("correspondingId", event.target.value);
                        }}
                        errorText={errorMap.coterieType}
                      />
                    </div>
                  </div>
                ) : null}

                <DividerLine />
                <div className="">{RenderValidationRequirements()}</div>
                <DividerLine />
                <div className="w-full">
                  <p className="font-semibold text-lg">
                    {i18n.t("standard_exam_activity")}
                  </p>
                  <div className="flex flex-row flex-wrap items-center gap-3 my-4">
                    {retrieveStandardExamCoterieTypeOptionListByStandardExamType(
                      selectedStandardExamType
                    ).map((option, optionIndex) => (
                      <div className="relative" key={optionIndex}>
                        <div
                          className={`flex flex-col items-center justify-center ${
                            selectedStandardExamActivityCoterieType ===
                            option.value
                              ? "bg-catchup-blue"
                              : standardExamActivityList.findIndex(
                                  (standardExamActivity) =>
                                    standardExamActivity.standardExamCoterieType ===
                                    option.value
                                ) > -1
                              ? "bg-catchup-darker-blue"
                              : "bg-catchup-light-gray"
                          }  text-catchup-white rounded-catchup-xlarge w-[150px] h-[80px] text-center cursor-pointer transition-all duration-300`}
                          onClick={() => {
                            handleAddStandardExamActivityOnClick(option.value);
                            setSelectedStandardExamActivityCoterieType(
                              option.value
                            );
                          }}
                        >
                          <p className="p-5">{i18n.t(option.text)}</p>
                        </div>
                        {standardExamActivityList.findIndex(
                          (standardExamActivity) =>
                            standardExamActivity.standardExamCoterieType ===
                            option.value
                        ) > -1 ? (
                          <div className="absolute top-2 right-2">
                            <BaseImage
                              src="/icons/cross-red.png"
                              alt="cross"
                              size="small"
                              onClick={(e) => {
                                e.preventDefault();
                                handleRemoveActivityFromStandardExam(
                                  option.value
                                );
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {selectedStandardExamActivityCoterieType !== null ? (
                <>
                  <DividerLine />
                  <div className="w-full">
                    <p className="font-semibold text-lg">
                      {i18n.t("activity_information")}
                    </p>
                  </div>
                  {SingleInformationIconTextItem(
                    i18n.t(
                      "you_can_choose_your_activities_from_your_activity_list_or_from_publishing_house_activities"
                    )
                  )}
                  <div className="w-full my-2">
                    <SelectionTab
                      optionList={[
                        {
                          id: 0,
                          title: i18n.t("activity_list"),
                        },
                        {
                          id: 1,
                          title: i18n.t("publishing_house_activity_list"),
                        },
                        {
                          id: 2,
                          title: i18n.t("selected_activities"),
                        },
                      ]}
                      selectedId={listActivityState}
                      handleSelectOnClick={(selectedId) => {
                        setListActivityState(selectedId);
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <div
                      className={`${
                        listActivityState === 0 ? "block" : "hidden"
                      }`}
                    >
                      <ListActivity
                        showTitle={false}
                        usePadding={false}
                        initialFilterApplied={true}
                        initialCoterieType={retrieveCoterieTypeFromStandardExamCoterieType(
                          selectedStandardExamType,
                          selectedStandardExamActivityCoterieType
                        )}
                        initialLevel={null}
                        showCoterieType={false}
                        showLevel={true}
                        showDeleted={false}
                        labelable={false}
                        showLabelList={true}
                        previewable={true}
                        handlePreviewOnClick={handlePreviewActivityOnClick}
                        selectable={true}
                        selectedItemIdList={retrieveSelectedStandardExamActivityItemList().map(
                          (item) => item.activity.id
                        )}
                        handleSelectOnClick={
                          handleAddActivityToStandardExamActivity
                        }
                        activityTemplateTypeList={["MCSA"]}
                      />
                    </div>
                    <div
                      className={`${
                        listActivityState === 1 ? "block" : "hidden"
                      }`}
                    >
                      <ListActivityBundle
                        usePadding={false}
                        initialFilterApplied={true}
                        initialCoterieType={retrieveCoterieTypeFromStandardExamCoterieType(
                          selectedStandardExamType,
                          selectedStandardExamActivityCoterieType
                        )}
                        selectable={true}
                        selectedItemIdList={retrieveSelectedStandardExamActivityItemList().map(
                          (selectedActivity) => selectedActivity.activity.id
                        )}
                        handleSelectOnClick={
                          handleAddActivityToStandardExamActivity
                        }
                        activityTemplateTypeList={["MCSA"]}
                      />
                    </div>
                    <div
                      className={`${
                        listActivityState === 2 ? "block" : "hidden"
                      }`}
                    >
                      {retrieveSelectedStandardExamActivityItemList().map(
                        (selectedActivity, index) => (
                          <div className="" key={index}>
                            <SingleActivityItem
                              number={index + 1}
                              language={language}
                              activityDTO={selectedActivity}
                              showActivityCount={true}
                              showActivityTemplate={true}
                              activityCountDTOList={activityCountDTOList}
                              categorySet={categorySet}
                              handlePreviewActivityOnClick={
                                handlePreviewActivityOnClick
                              }
                              handleRemoveActivityOnClick={
                                handleRemoveActivityFromStandardExam
                              }
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </>
              ) : null}

              {standardExamActivityList.length > 0 ? (
                <div className="mt-4 ml-auto">
                  {id ? (
                    <SecondaryButton
                      title={i18n.t("update")}
                      size="medium"
                      onClick={handleUpdateStandardExamOnClick}
                    />
                  ) : (
                    <CreateButton
                      title={i18n.t("create")}
                      size="medium"
                      onClick={handleCreateStandardExamOnClick}
                    />
                  )}
                </div>
              ) : null}
            </FullCard>
          </div>
        </div>
      </div>
    );
  };

  const RenderShowSomeoneUpdatedModal = () => {
    return (
      <BaseModal
        isOpen={showSomeoneUpdatedModal}
        size="small"
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowSomeoneUpdatedModal(false);
          setId(null);
        }}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            <div className="flex flex-col items-center justify-center gap-y-4">
              <BaseImage
                size="large"
                src="/icons/already-updated.png"
                alt="already-updated"
              />
              <div>
                <p className="w-[300px] text-xl text-center">
                  {i18n.t("someone_updated_the_standard_exam_text")}
                </p>
              </div>
            </div>

            {someoneInformation ? (
              <div className="flex flex-col justify-center items-center">
                <p>
                  {someoneInformation.firstName} {someoneInformation.lastName}
                </p>
              </div>
            ) : null}
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  const pagedQueryParams = constructPagedQueryParams();

  return (
    <div className="flex-1 flex flex-col">
      {RenderShowSomeoneUpdatedModal()}
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default StandardExamListScreen;
