import { useEffect, useState } from "react";
import InputGroup from "../../components/groups/InputGroup";
import i18n from "../../language/i18n";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import {
  queryCampusListByBrandId,
  queryInstitutionListByCampusId,
} from "../../requests/ManagementRequests";
import {
  ONE_DAY,
  ONE_HOUR,
  ONE_MONTH,
  ONE_WEEK,
  THREE_MONTHS,
  retrieveDateIntervalOptionList,
} from "../../utilization/DateUtilization";
import FullCard from "../../components/cards/FullCard";
import DividerLine from "../../components/divider/DividerLine";
import { queryTokenUsageDTOListByParams } from "../../requests/TokenRequests";
import { retrieveTokenUsageSubTypeOptionList } from "../../utilization/TokenUtilization";
import { Line } from "react-chartjs-2";
import RenderInformationCardItem from "../../components/cards/InformationCard";
import SingleInformationIconTextItem from "../../components/infos/SingleInformationIconTextItem";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import { useNavigate } from "react-router-dom";
import BaseImage from "../../components/images/BaseImage";
import {
  retrieveCampusDTOOptionList,
  retrieveInstitutionDTOOptionList,
} from "../../utilization/ManagementUtilization";
import BaseLoading from "../../components/loading/BaseLoading";

const TokenUsageVisualizerScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    language,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
  } = useAuth();
  const [campusDTOList, setCampusDTOList] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState(userProfileBrand.id);
  const [selectedCampusId, setSelectedCampusId] = useState(
    userProfileCampus ? userProfileCampus.id : "DEFAULT_OPTION"
  );
  const [selectedInstitutionId, setSelectedInstitutionId] = useState(
    userProfileInstitution ? userProfileInstitution.id : "DEFAULT_OPTION"
  );
  const [selectedType, setSelectedType] = useState("AI");
  const [selectedSubType, setSelectedSubType] = useState(
    "ACTIVITY_TEMPLATE_GENERATION"
  );
  const [institutionDTOList, setInstitutionDTOList] = useState([]);
  const [selectedDateInterval, setSelectedDateInterval] = useState("LAST_DAY");
  const [selectedUserProfileId, setSelectedUserProfileId] = useState(-1);
  const [tokenUsageList, setTokenUsageList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (checkViewPermissionGranted("token_assignment_list")) {
    } else {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    if (selectedBrandId !== "DEFAULT_OPTION") {
      if (userProfileCampus) {
        setCampusDTOList([userProfileCampus]);
      } else {
        retrieveAndSetCampusDTOListByBrandId();
      }
    } else {
      setSelectedCampusId("DEFAULT_OPTION");
      setCampusDTOList([]);
    }
  }, [selectedBrandId]);

  useEffect(() => {
    if (selectedCampusId !== "DEFAULT_OPTION") {
      if (userProfileInstitution) {
        setInstitutionDTOList([userProfileInstitution]);
      } else {
        retrieveAndSetInstitutionDTOListByCampusId();
      }
    } else {
      setSelectedInstitutionId("DEFAULT_OPTION");
      setInstitutionDTOList([]);
    }
  }, [selectedCampusId]);

  useEffect(() => {
    if (selectedSubType === "DEFAULT_OPTION") return;
    retrieveAndSetTokenUsageDTOListByParams();
  }, [selectedSubType, selectedCampusId, selectedInstitutionId]);

  const retrieveAndSetTokenUsageDTOListByParams = async () => {
    setLoading(true);
    const { data, err } = await queryTokenUsageDTOListByParams({
      brandId: selectedBrandId,
      campusId: selectedCampusId === "DEFAULT_OPTION" ? null : selectedCampusId,
      institutionId:
        selectedInstitutionId === "DEFAULT_OPTION"
          ? null
          : selectedInstitutionId,
      type: selectedType,
      subType: selectedSubType,
    });
    if (err) {
      console.log(err);
    } else {
      setTokenUsageList(data);
    }
    setLoading(false);
  };

  const retrieveAndSetCampusDTOListByBrandId = async () => {
    const { data, err } = await queryCampusListByBrandId(selectedBrandId);
    if (err) {
      console.log(err);
    } else {
      setCampusDTOList(data);
    }
  };

  const retrieveAndSetInstitutionDTOListByCampusId = async () => {
    const { data, err } = await queryInstitutionListByCampusId(
      selectedCampusId
    );
    if (err) {
      console.log(err);
    } else {
      setInstitutionDTOList(data);
    }
  };

  const retrieveDayIntervalArray = () => {
    const intervalArray = [];
    if (selectedDateInterval === "LAST_DAY") {
      for (let i = 22; i >= -1; i--) {
        const iHourAgo = new Date().getTime() - i * ONE_HOUR;
        const currentTimestamp = new Date(
          new Date(iHourAgo).getFullYear(),
          new Date(iHourAgo).getUTCMonth(),
          new Date(iHourAgo).getDate(),
          new Date(iHourAgo).getHours(),
          0,
          0
        ).getTime();
        intervalArray.push({
          timestamp: currentTimestamp,
        });
      }
    } else if (selectedDateInterval === "LAST_WEEK") {
      for (let i = 166; i >= -1; i--) {
        const iHourAgo = new Date().getTime() - i * ONE_HOUR;
        const currentTimestamp = new Date(
          new Date(iHourAgo).getFullYear(),
          new Date(iHourAgo).getUTCMonth(),
          new Date(iHourAgo).getDate(),
          new Date(iHourAgo).getHours(),
          0,
          0
        ).getTime();
        intervalArray.push({
          timestamp: currentTimestamp,
        });
      }
    } else if (selectedDateInterval === "LAST_MONTH") {
      for (let i = 28; i >= -1; i--) {
        const iDayAgo = new Date().getTime() - i * ONE_DAY;
        const currentTimestamp = new Date(
          new Date(iDayAgo).getFullYear(),
          new Date(iDayAgo).getUTCMonth(),
          new Date(iDayAgo).getDate(),
          0,
          0,
          0
        ).getTime();
        intervalArray.push({
          timestamp: currentTimestamp,
        });
      }
    } else if (selectedDateInterval === "LAST_THREE_MONTHS") {
      for (let i = 88; i >= -1; i--) {
        const iDayAgo = new Date().getTime() - i * ONE_DAY;
        const currentTimestamp = new Date(
          new Date(iDayAgo).getFullYear(),
          new Date(iDayAgo).getUTCMonth(),
          new Date(iDayAgo).getDate(),
          0,
          0,
          0
        ).getTime();
        intervalArray.push({
          timestamp: currentTimestamp,
        });
      }
    }
    return intervalArray;
  };

  const filterByTimeInterval = () => {
    if (selectedDateInterval === "LAST_DAY") {
      return tokenUsageList.filter(
        (tokenUsage) => tokenUsage.createdAt > new Date().getTime() - ONE_DAY
      );
    } else if (selectedDateInterval === "LAST_WEEK") {
      return tokenUsageList.filter(
        (tokenUsage) => tokenUsage.createdAt > new Date().getTime() - ONE_WEEK
      );
    } else if (selectedDateInterval === "LAST_MONTH") {
      return tokenUsageList.filter(
        (tokenUsage) => tokenUsage.createdAt > new Date().getTime() - ONE_MONTH
      );
    } else if (selectedDateInterval === "LAST_THREE_MONTHS") {
      return tokenUsageList.filter(
        (tokenUsage) =>
          tokenUsage.createdAt > new Date().getTime() - THREE_MONTHS
      );
    }
    return [];
  };

  const filterUserOptionList = () => {
    const userOptionList = [
      {
        value: -1,
        text: i18n.t("all"),
      },
    ];
    tokenUsageList.forEach((tokenUsage) => {
      const { profileName, firstName, lastName, userProfileId } = tokenUsage;
      const foundIndex = userOptionList.findIndex(
        (userOption) => userOption.value === userProfileId
      );
      if (foundIndex === -1) {
        userOptionList.push({
          value: userProfileId,
          text: `${firstName} ${lastName} (${profileName})`,
        });
      }
    });
    return userOptionList;
  };

  const retrieveAmountSpentLineOptions = () => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: i18n.t("line_graphic_amount_spent"),
        },
      },
    };
  };

  const retrieveAmountSpentLineData = () => {
    const labels = dayIntervalArray.map((dayInterval) =>
      new Date(dayInterval.timestamp).toLocaleString(language)
    );
    return {
      labels,
      datasets: [
        {
          label: i18n.t("amount"),
          data: amountData,
          borderColor: "rgb(17, 72, 89)",
          backgroundColor: "rgba(17, 72, 89, 0.5)",
        },
      ],
    };
  };

  const retrieveTokenSpentLineOptions = () => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: i18n.t("line_graphic_token_spent"),
        },
      },
    };
  };

  const constructSpentLineData = () => {
    const tokenData = [];
    const userProfileIdData = [];
    const amountData = [];
    for (let i = 0; i < dayIntervalArray.length - 1; i++) {
      let filteredTokenUsageList = filteredByTimeInterval.filter(
        (tokenUsage) =>
          tokenUsage.createdAt > dayIntervalArray[i].timestamp &&
          tokenUsage.createdAt < dayIntervalArray[i + 1].timestamp
      );
      if (selectedUserProfileId !== -1) {
        filteredTokenUsageList = filteredTokenUsageList.filter(
          (tokenUsage) => tokenUsage.userProfileId === selectedUserProfileId
        );
      }
      const distinctUserList = [];
      let tokenSpent = 0;
      let amountSpent = 0;
      filteredTokenUsageList.forEach((tokenUsage) => {
        const { token, amount, userProfileId } = tokenUsage;
        tokenSpent += amount;
        amountSpent += token;
        const foundUserProfileId = userProfileIdData.find(
          (item) => item === userProfileId
        );
        if (!foundUserProfileId) {
          userProfileIdData.push(userProfileId);
        }
        const foundDistinctUser = distinctUserList.find(
          (distinctUser) => distinctUser.userProfileId === userProfileId
        );
        if (!foundDistinctUser) {
          distinctUserList.push({
            userProfileId: userProfileId,
          });
        }
      });

      if (distinctUserList.length === 0) {
        tokenData.push(0);
        amountData.push(0);
      } else {
        tokenData.push(tokenSpent);
        amountData.push(amountSpent);
      }
    }

    return { tokenData, amountData, userProfileIdData };
  };

  const retrieveTokenSpentLineData = () => {
    const labels = dayIntervalArray.map((dayInterval) =>
      new Date(dayInterval.timestamp).toLocaleString(language)
    );
    return {
      labels,
      datasets: [
        {
          label: i18n.t("token"),
          data: tokenData,
          borderColor: "rgb(17, 72, 89)",
          backgroundColor: "rgba(17, 72, 89, 0.5)",
        },
      ],
    };
  };

  const calculateTotalTokenSpent = () => {
    let totalTokenSpent = 0;
    tokenData.forEach((item) => {
      totalTokenSpent += item;
    });
    return totalTokenSpent;
  };

  const calculateTotalAmountSpent = () => {
    let totalAmountSpent = 0;
    amountData.forEach((item) => {
      totalAmountSpent += item;
    });
    return totalAmountSpent;
  };

  const calculateAverageTokenSpent = () => {
    if (userProfileIdData.length === 0) return 0;
    return totalTokenSpent / userProfileIdData.length;
  };

  const calculateAverageAmountSpent = () => {
    if (totalTokenSpent === 0) return 0;
    return totalAmountSpent / totalTokenSpent;
  };

  const handleOnChange = (type, value) => {
    if (type === "brandId") {
      setSelectedBrandId(value);
    } else if (type === "campusId") {
      setSelectedCampusId(value);
    } else if (type === "institutionId") {
      setSelectedInstitutionId(value);
    } else if (type === "date_interval") {
      setSelectedDateInterval(value);
    } else if (type === "subType") {
      setSelectedSubType(value);
    } else if (type === "userProfileId") {
      setSelectedUserProfileId(value);
    }
  };

  const RenderMainContent = () => {
    return (
      <FullCard isShadowed={true}>
        {SingleInformationIconTextItem(
          i18n.t(
            "specifically_observe_from_the_selected_management_item_point_of_view"
          )
        )}
        <div className="flex flex-row flex-wrap items-center mb-3">
          <div className="w-1/2 lg:w-1/3">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("sub_type")}
                placeholder={i18n.t("sub_type")}
                value={selectedSubType}
                optionList={retrieveTokenUsageSubTypeOptionList()}
                onChange={(event) => {
                  handleOnChange("subType", event.target.value);
                }}
              />
            </div>
          </div>
          {selectedBrandId !== "DEFAULT_OPTION" ? (
            <div className="w-1/2 lg:w-1/3">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("campus_name")}
                  placeholder={i18n.t("campus_name")}
                  value={selectedCampusId}
                  optionList={retrieveCampusDTOOptionList(campusDTOList)}
                  onChange={(event) => {
                    handleOnChange("campusId", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}

          {selectedCampusId !== "DEFAULT_OPTION" ? (
            <div className="w-1/2 lg:w-1/3">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("institution_name")}
                  placeholder={i18n.t("institution_name")}
                  value={selectedInstitutionId}
                  optionList={retrieveInstitutionDTOOptionList(
                    institutionDTOList
                  )}
                  onChange={(event) => {
                    handleOnChange("institutionId", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
        <div className="flex flex-row items-center mb-3">
          <div className="w-1/2 lg:w-1/3">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("date_interval")}
                placeholder={i18n.t("date_interval")}
                value={selectedDateInterval}
                optionList={retrieveDateIntervalOptionList()}
                onChange={(event) => {
                  handleOnChange("date_interval", event.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-1/2 lg:w-1/3">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("user_profile")}
                placeholder={i18n.t("user_profile")}
                value={selectedUserProfileId}
                optionList={filterUserOptionList()}
                onChange={(event) => {
                  handleOnChange("userProfileId", event.target.value);
                }}
              />
            </div>
          </div>
        </div>

        {loading ? (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <>
            <div className="flex flex-row flex-wrap">
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                {RenderInformationCardItem(
                  i18n.t("total_token_spent"),
                  null,
                  <BaseImage src="/icons/token.png" alt="token" size="small" />,
                  totalTokenSpent,
                  1
                )}
              </div>
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                {RenderInformationCardItem(
                  i18n.t("total_amount_spent"),
                  null,
                  <BaseImage src="/icons/token.png" alt="token" size="small" />,
                  totalAmountSpent,
                  2
                )}
              </div>
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                {RenderInformationCardItem(
                  i18n.t("average_token_spent"),
                  null,
                  <BaseImage src="/icons/token.png" alt="token" size="small" />,
                  averageTokenSpent.toFixed(2),
                  3
                )}
              </div>
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                {RenderInformationCardItem(
                  i18n.t("average_amount_spent"),
                  null,
                  <BaseImage src="/icons/token.png" alt="token" size="small" />,
                  averageAmountSpent.toFixed(2),
                  4
                )}
              </div>
            </div>

            <DividerLine />
            <Line
              options={retrieveTokenSpentLineOptions()}
              data={retrieveTokenSpentLineData()}
            />
            <DividerLine />
            <Line
              options={retrieveAmountSpentLineOptions()}
              data={retrieveAmountSpentLineData()}
            />
          </>
        )}
      </FullCard>
    );
  };

  const dayIntervalArray = retrieveDayIntervalArray();
  const filteredByTimeInterval = filterByTimeInterval();
  const { tokenData, amountData, userProfileIdData } = constructSpentLineData();
  const totalTokenSpent = calculateTotalTokenSpent();
  const totalAmountSpent = calculateTotalAmountSpent();
  const averageTokenSpent = calculateAverageTokenSpent();
  const averageAmountSpent = calculateAverageAmountSpent();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default TokenUsageVisualizerScreen;
