import { useEffect, useRef, useState } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useApp } from "./context/AppContextProvider";
import { useAuth } from "./context/UserContextProvider";
import i18n from "./language/i18n";
import {
  queryBrandLabelListByBrandId,
  queryLabelListByUserIdAndUserProfileId,
  queryPublishingHouseLabelList,
} from "./requests/LabelRequests";
import {
  retrieveAccessTokenFromRefreshToken,
  retrieveGenixoDashboardMetricsManagement,
  retrieveProfileTokenInformation,
  retrieveUserInformationFromToken,
  retrieveUserProfileList,
  retrieveUserStaffDashboardMetricsManagement,
} from "./requests/ManagementRequests";
import { retrieveStaffNumberOfUnReadAnnouncements } from "./requests/NotificationRequests";
import {
  removeAxiosInterceptor,
  setAxiosInterceptor,
} from "./utilization/AxiosUtilization";
import {
  retrieveBrandDTOByUserProfileOptionList,
  retrieveCampusDTOByUserProfileOptionList,
  retrieveInstitutionDTOByUserProfileOptionList,
  retrieveSeasonDTOByUserProfileOptionList,
  retrieveGradeDTOByUserProfileOptionList,
  retrieveBranchDTOByUserProfileOptionList,
} from "./utilization/ManagementUtilization";
import FullCard from "./components/cards/FullCard";
import PrimaryButton from "./components/buttons/PrimaryButton";
import endpoints from "./config/endpoints.json";
import connection_production from "./config/connection.json";
import connection_development from "./config/connection_local.json";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { queryCategoryListByVersionCodeList } from "./requests/CategoryRequests";
import {
  filterCategoryVersionCodeOptionList,
  filterCategoryVersionCodeOptionListByGradeDTO,
  filterCategoryVersionCodeOptionListByInstitutionDTO,
  retrieveCategoryVersionCodeOptionList,
} from "./utilization/CategoryUtilization";
import useTabActive from "./hooks/useTabActive";
import {
  retrieveAllActivityTemplateList,
  retrieveGenixoDashboardMetricsCatchtivity,
  retrieveUserContentCreatorDashboardMetricsCatchtivity,
  retrieveUserIndividualDashboardMetricsCatchtivity,
  retrieveUserStaffDashboardMetricsCatchtivity,
} from "./requests/CatchtivityRequests";
import { checkInsertPermissionGranted } from "./utilization/ScreenUtilization";
import BaseModal from "./components/modal/BaseModal";
import BaseImage from "./components/images/BaseImage";
import { useMutationObserver } from "./hooks/useMutationObserver";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const App = (props) => {
  const {
    boarding,
    setBoarding,
    theme,
    managementStompClient,
    catchtivityStompClient,
    planStompClient,
    setTheme,
    mainLeftNavigationState,
    setMainLeftNavigationState,
    setManagementStompClient,
    setCatchtivityStompClient,
    setPlanStompClient,
  } = useApp();
  const {
    language,
    handleStateLogout,
    setLanguage,
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
    managementData,
    catchtivityData,
    setUserInformation,
    setUserProfileSet,
    setUserProfile,
    setUserProfileBrand,
    setUserProfileCampus,
    setUserProfileInstitution,
    setUserProfileSeason,
    setUserProfileGrade,
    setUserProfileBranch,
    setUserLabelSet,
    setUserBrandLabelSet,
    setUserPublishingHouseLabelSet,
    setCategorySet,
    setNumberOfUnreadAnnouncements,
    setActivityTemplateSet,
    setManagementData,
    setCatchtivityData,
    setDashboardLoading,
  } = useAuth();
  const mutationRef = useRef();
  const [showCoterieTypeWarningModal, setShowCoterieTypeWarningModal] =
    useState(false);
  const isTabActive = useTabActive();
  const [latestUpdateTime, setLatestUpdateTime] = useState(
    new Date().getTime()
  );
  const [firstLoginTime, setFirstLoginTime] = useState(new Date().getTime());
  const [managementSubscribedId, setManagementSubscribedId] = useState(null);

  useMutationObserver(mutationRef, () => {
    const elementList = document.getElementsByClassName("katex-mathml");
    for (const element of elementList) {
      element.style.display = "none";
    }
  });

  useEffect(() => {
    const handleAppStateEventListener = () => {
      const localStorageAppState = localStorage.getItem("appState");
      if (
        localStorageAppState === "LOGIN" ||
        localStorageAppState === "LOGOUT" ||
        localStorageAppState === "CHANGE_PROFILE"
      ) {
        localStorage.removeItem("appState");
        if (localStorageAppState === "CHANGE_PROFILE") {
          const userProfileId = parseFloat(
            localStorage.getItem("userProfileId")
          );
          if (userProfile) {
            if (userProfileId !== userProfile.id) {
              window.location.replace(connection.admin.url);
            }
          } else {
            window.location.replace(connection.admin.url);
          }
        } else {
          window.location.replace(connection.admin.url);
        }
      }
    };
    window.addEventListener("storage", handleAppStateEventListener);
    return () => {
      window.removeEventListener("storage", handleAppStateEventListener);
    };
  }, [userProfile]);

  useEffect(() => {
    if (isTabActive) {
      if (boarding === "DASHBOARD") {
        if (new Date().getTime() - latestUpdateTime > 7200000) {
          retrieveAndSetAccessTokenInformationFromRefreshToken(true);
          setLatestUpdateTime(new Date().getTime());
        }
      }
    }
  }, [isTabActive]);

  useEffect(() => {
    const retrieveAndSetUserInformationFromToken = async () => {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        removeAxiosInterceptor();
        setAxiosInterceptor(accessToken);
        const { data } = await retrieveUserInformationFromToken();
        if (data.enabled) {
          setUserInformation(data);
          setBoarding("USER_RETRIEVED");
        } else {
          setBoarding("ACCOUNT_ACTIVATION_REQUIRED");
        }
      } else {
        handleLogoutOnClick();
        setBoarding("USER_NOT_RETRIEVED");
      }
    };
    const retrieveAndSetMainLeftNavigationState = () => {
      const foundMainLeftNavigationState = localStorage.getItem(
        "mainLeftNavigationState"
      );
      if (foundMainLeftNavigationState) {
        if (
          foundMainLeftNavigationState === "FULL" ||
          foundMainLeftNavigationState === "ICON_ONLY"
        ) {
          setMainLeftNavigationState(foundMainLeftNavigationState);
        } else {
          setMainLeftNavigationState("FULL");
        }
      }
    };
    const retrieveAndSetTheme = () => {
      const foundTheme = localStorage.getItem("theme");
      if (foundTheme) {
        setTheme(foundTheme);
      }
    };
    const retrieveAndSetLanguage = () => {
      const foundLanguage = localStorage.getItem("language");
      if (foundLanguage) {
        setLanguage(foundLanguage);
      }
    };
    const retrieveAndSetUserProfileInformation = async () => {
      const { data } = await retrieveUserProfileList();
      if (data) {
        setUserProfileSet([...data]);
      }

      if (userInformation.requiredAction === "PROFILE_SELECTION") {
        setBoarding("PROFILE_SELECTION_REQUIRED");
        return;
      }
      const foundProfileId = localStorage.getItem("userProfileId");

      if (foundProfileId) {
        const foundUserProfile = data.find(
          (userProfile) => userProfile.id === parseFloat(foundProfileId)
        );
        if (foundUserProfile) {
          const refreshToken = localStorage.getItem("refreshToken");
          const { data: profileTokenData } =
            await retrieveProfileTokenInformation(foundProfileId, refreshToken);
          localStorage.setItem("accessToken", profileTokenData.accessToken);
          removeAxiosInterceptor();
          setAxiosInterceptor(profileTokenData.accessToken);
          setUserProfile(foundUserProfile);
          if (foundUserProfile.role === "STAFF") {
            const brandId = localStorage.getItem("brandId");
            const foundBrand = retrieveBrandDTOByUserProfileOptionList(
              foundUserProfile,
              brandId
            ).find((brandDTO) => brandDTO.value === parseFloat(brandId));
            if (foundBrand) {
              setUserProfileBrand(foundBrand.fullValue);
            } else {
              setBoarding("PROFILE_SELECTION_REQUIRED");
              return;
            }

            const campusId = localStorage.getItem("campusId");
            const foundCampus = retrieveCampusDTOByUserProfileOptionList(
              foundUserProfile,
              brandId
            ).find((campusDTO) => campusDTO.value === parseFloat(campusId));
            if (campusId) {
              if (foundCampus) {
                setUserProfileCampus(foundCampus.fullValue);
              } else {
                setBoarding("PROFILE_SELECTION_REQUIRED");
                return;
              }
            }

            const institutionId = localStorage.getItem("institutionId");
            const foundInstitution =
              retrieveInstitutionDTOByUserProfileOptionList(
                foundUserProfile,
                brandId,
                campusId
              ).find(
                (institutionDTO) =>
                  institutionDTO.value === parseFloat(institutionId)
              );

            if (institutionId) {
              if (foundInstitution) {
                setUserProfileInstitution(foundInstitution.fullValue);
              } else {
                setBoarding("PROFILE_SELECTION_REQUIRED");
                return;
              }
            }

            const seasonId = localStorage.getItem("seasonId");
            const foundSeason = retrieveSeasonDTOByUserProfileOptionList(
              foundUserProfile,
              brandId,
              institutionId
            ).find((seasonDTO) => seasonDTO.value === parseFloat(seasonId));

            if (seasonId) {
              if (foundSeason) {
                setUserProfileSeason(foundSeason.fullValue);
              } else {
                setBoarding("PROFILE_SELECTION_REQUIRED");
                return;
              }
            }

            const gradeId = localStorage.getItem("gradeId");
            const foundGrade = retrieveGradeDTOByUserProfileOptionList(
              foundUserProfile,
              brandId,
              seasonId
            ).find((gradeDTO) => gradeDTO.value === parseFloat(gradeId));
            if (gradeId) {
              if (foundGrade) {
                setUserProfileGrade(foundGrade.fullValue);
              } else {
                setBoarding("PROFILE_SELECTION_REQUIRED");
                return;
              }
            }

            const branchId = localStorage.getItem("branchId");
            const foundBranch = retrieveBranchDTOByUserProfileOptionList(
              foundUserProfile,
              brandId,
              gradeId
            ).find((branchDTO) => branchDTO.value === parseFloat(branchId));
            if (branchId) {
              if (foundBranch) {
                setUserProfileBranch(foundBranch.fullValue);
              } else {
                setBoarding("PROFILE_SELECTION_REQUIRED");
                return;
              }
            }
          } else if (foundUserProfile.role === "INDIVIDUAL") {
            const brandId = localStorage.getItem("brandId");
            const foundBrand =
              foundUserProfile.seasonDTO.institutionDTO.campusDTO.brandDTO;
            if (parseFloat(foundBrand.id) === parseFloat(brandId)) {
              setUserProfileBrand(foundBrand);
            } else {
              setBoarding("PROFILE_SELECTION_REQUIRED");
              return;
            }

            const campusId = localStorage.getItem("campusId");
            const foundCampus =
              foundUserProfile.seasonDTO.institutionDTO.campusDTO;
            if (parseFloat(foundCampus.id) === parseFloat(campusId)) {
              setUserProfileCampus(foundCampus);
            } else {
              setBoarding("PROFILE_SELECTION_REQUIRED");
              return;
            }

            const institutionId = localStorage.getItem("institutionId");
            const foundInstitution = foundUserProfile.seasonDTO.institutionDTO;
            if (parseFloat(foundInstitution.id) === parseFloat(institutionId)) {
              setUserProfileInstitution(foundInstitution);
            } else {
              setBoarding("PROFILE_SELECTION_REQUIRED");
              return;
            }

            const seasonId = localStorage.getItem("seasonId");
            const foundSeason = foundUserProfile.seasonDTO;
            if (parseFloat(foundSeason.id) === parseFloat(seasonId)) {
              setUserProfileSeason(foundSeason);
            } else {
              setBoarding("PROFILE_SELECTION_REQUIRED");
              return;
            }
          } else if (foundUserProfile.role === "CONTENT_CREATOR") {
            const brandId = localStorage.getItem("brandId");
            const foundBrand = retrieveBrandDTOByUserProfileOptionList(
              foundUserProfile,
              brandId
            ).find((brandDTO) => brandDTO.value === parseFloat(brandId));
            if (foundBrand) {
              setUserProfileBrand(foundBrand.fullValue);
            } else {
              setBoarding("PROFILE_SELECTION_REQUIRED");
              return;
            }

            const campusId = localStorage.getItem("campusId");
            const foundCampus = retrieveCampusDTOByUserProfileOptionList(
              foundUserProfile,
              brandId
            ).find((campusDTO) => campusDTO.value === parseFloat(campusId));
            if (campusId) {
              if (foundCampus) {
                setUserProfileCampus(foundCampus.fullValue);
              } else {
                setBoarding("PROFILE_SELECTION_REQUIRED");
                return;
              }
            }

            const institutionId = localStorage.getItem("institutionId");
            const foundInstitution =
              retrieveInstitutionDTOByUserProfileOptionList(
                foundUserProfile,
                brandId,
                campusId
              ).find(
                (institutionDTO) =>
                  institutionDTO.value === parseFloat(institutionId)
              );

            if (institutionId) {
              if (foundInstitution) {
                setUserProfileInstitution(foundInstitution.fullValue);
              } else {
                setBoarding("PROFILE_SELECTION_REQUIRED");
                return;
              }
            }
          }
          const pathName = window.location.pathname;
          if (pathName.startsWith("/view/pdf")) {
            setBoarding("VIEW_PDF");
          } else {
            setBoarding("DASHBOARD");
          }
        } else {
          setBoarding("PROFILE_SELECTION_REQUIRED");
        }
      } else {
        setBoarding("PROFILE_SELECTION_REQUIRED");
      }
    };
    const retrieveAndSetNumberOfUnreadAnnouncements = async () => {
      const brandId = localStorage.getItem("brandId");
      const campusId = localStorage.getItem("campusId");
      const institutionId = localStorage.getItem("institutionId");
      const seasonId = localStorage.getItem("seasonId");
      const gradeId = localStorage.getItem("gradeId");
      const branchId = localStorage.getItem("branchId");
      const { data, err } = await retrieveStaffNumberOfUnReadAnnouncements({
        userId: userInformation.id,
        userProfileId: userProfile.id,
        audience: "STAFF",
        coterieType: userProfile.coterieType,
        brandId,
        campusId,
        institutionId,
        seasonId,
        gradeId,
        branchId,
      });
      if (err) {
        console.log(err);
      } else {
        setNumberOfUnreadAnnouncements(data);
      }
    };

    if (boarding === "INITIALIZED") {
      removeAxiosInterceptor();
      retrieveAndSetAccessTokenInformationFromRefreshToken(true);
      retrieveAndSetMainLeftNavigationState();
      retrieveAndSetTheme();
      retrieveAndSetLanguage();
    } else if (boarding === "PROFILE_SELECTION_REQUIRED") {
      // retrieveAndSetUserProfileInformation();
    } else if (boarding === "TOKEN_RETRIEVED") {
      retrieveAndSetUserInformationFromToken();
    } else if (
      boarding === "USER_RETRIEVED" ||
      boarding === "USER_NOT_RETRIEVED"
    ) {
      if (userInformation) {
        if (userInformation.requiredAction === "LOGOUT") {
          handleLogoutOnClick();
        } else {
          retrieveAndSetUserProfileInformation();
        }
      } else {
        setBoarding("LANDING");
      }
    } else if (boarding === "DASHBOARD") {
      retrieveAndSetNumberOfUnreadAnnouncements();
    }
  }, [boarding]);

  useEffect(() => {
    if (theme === "DARK") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "DARK");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "LIGHT");
    }
  }, [theme]);

  useEffect(() => {
    setLanguage(language);
    localStorage.setItem("language", language);
    i18n.changeLanguage(language);
    document.documentElement.lang = language.toLowerCase();
  }, [language]);

  useEffect(() => {
    const retrieveAndSetLabelList = async () => {
      const { data, err } = await queryLabelListByUserIdAndUserProfileId({
        userId: userInformation.id,
        userProfileId: userProfile.id,
      });
      if (err) {
        console.log(err);
      } else {
        setUserLabelSet(data);
      }
    };
    if (userInformation && userProfile && boarding === "DASHBOARD") {
      retrieveAndSetLabelList();
    }
  }, [userInformation, userProfile, boarding]);

  useEffect(() => {
    if (
      userInformation &&
      userInformation.accountType !== "GENIXO" &&
      userProfile &&
      userProfile.coterieType === null &&
      userProfile.role === "STAFF"
    ) {
      setShowCoterieTypeWarningModal(true);
    } else {
      setShowCoterieTypeWarningModal(false);
    }
  }, [userInformation, userProfile]);

  useEffect(() => {
    const retrieveAndSetActivityCategoryList = async () => {
      let currentCategoryVersionCodeOptionList =
        retrieveCategoryVersionCodeOptionList();
      const { coterieType } = userProfile;
      if (userProfileGrade) {
        currentCategoryVersionCodeOptionList =
          filterCategoryVersionCodeOptionListByGradeDTO(
            currentCategoryVersionCodeOptionList,
            coterieType,
            userProfileGrade
          );
      } else if (userProfileInstitution) {
        currentCategoryVersionCodeOptionList =
          filterCategoryVersionCodeOptionListByInstitutionDTO(
            currentCategoryVersionCodeOptionList,
            coterieType,
            userProfileInstitution
          );
      } else {
        currentCategoryVersionCodeOptionList =
          filterCategoryVersionCodeOptionList(
            currentCategoryVersionCodeOptionList,
            coterieType
          );
      }
      const versionCodeList = currentCategoryVersionCodeOptionList.map(
        (categoryVersionCode) => categoryVersionCode.value
      );
      const { data, err } = await queryCategoryListByVersionCodeList({
        versionCodeList,
      });
      if (err) {
        console.log(err);
      } else {
        setCategorySet(data);
      }
    };
    if (userInformation && userProfile && boarding === "DASHBOARD") {
      retrieveAndSetActivityCategoryList();
    }
  }, [userInformation, userProfile, boarding]);

  useEffect(() => {
    const retrieveAndSetActivityTemplateList = async () => {
      const { data, err } = await retrieveAllActivityTemplateList();
      if (err) {
        console.log(err);
      } else {
        setActivityTemplateSet(data.sort((a, b) => b.id - a.id));
      }
    };
    if (userInformation && userProfile && boarding === "DASHBOARD") {
      if (checkInsertPermissionGranted("activity_list")) {
        retrieveAndSetActivityTemplateList();
      }
    }
  }, [userInformation, userProfile, boarding]);

  useEffect(() => {
    const retrieveAndSetGenixoDashboardManagementMetrics = async () => {
      const { data: managementData, err: managementErr } =
        await retrieveGenixoDashboardMetricsManagement({
          brandId: userProfileBrand.id,
          campusId: userProfileCampus?.id,
          institutionId: userProfileInstitution?.id,
          seasonId: userProfileSeason?.id,
          gradeId: userProfileGrade?.id,
          branchId: userProfileBranch?.id,
        });
      if (managementErr) {
        console.log(managementErr);
      } else {
        setManagementData(managementData);
      }
    };
    const retrieveAndSetGenixoDashboardCatchtivityMetrics = async () => {
      const { data: catchtivityData, err: catchtivityErr } =
        await retrieveGenixoDashboardMetricsCatchtivity({
          brandId: userProfileBrand.id,
          campusId: userProfileCampus?.id,
          institutionId: userProfileInstitution?.id,
          seasonId: userProfileSeason?.id,
          gradeId: userProfileGrade?.id,
          branchId: userProfileBranch?.id,
          coterieType: "MANAGEMENT",
          deleted: false,
        });
      if (catchtivityErr) {
        console.log(catchtivityErr);
      } else {
        setCatchtivityData(catchtivityData);
      }
    };
    const retrieveAndSetUserStaffDashboardManagementMetrics = async () => {
      const { data: managementData, err: managementErr } =
        await retrieveUserStaffDashboardMetricsManagement({
          brandId: userProfileBrand.id,
          campusId: userProfileCampus?.id,
          institutionId: userProfileInstitution?.id,
          seasonId: userProfileSeason?.id,
          gradeId: userProfileGrade?.id,
          branchId: userProfileBranch?.id,
          coterieType: userProfile.coterieType,
          level: userProfileGrade?.level,
          deleted: false,
        });
      if (managementErr) {
        console.log(managementErr);
      } else {
        setManagementData(managementData);
      }
    };
    const retrieveAndSetUserStaffDashboardCatchtivityMetrics = async () => {
      const { data: catchtivityData, err: catchtivityErr } =
        await retrieveUserStaffDashboardMetricsCatchtivity({
          brandId: userProfileBrand.id,
          campusId: userProfileCampus?.id,
          institutionId: userProfileInstitution?.id,
          seasonId: userProfileSeason?.id,
          gradeId: userProfileGrade?.id,
          branchId: userProfileBranch?.id,
          coterieType: userProfile.coterieType,
          level: userProfileGrade?.level,
          deleted: false,
        });
      if (catchtivityErr) {
        console.log(catchtivityErr);
      } else {
        setCatchtivityData(catchtivityData);
      }
    };
    const retrieveAndSetUserIndividualDashboardManagementMetrics = async () => {
      const { data: managementData, err: managementErr } =
        await retrieveUserStaffDashboardMetricsManagement({
          brandId: userProfileBrand.id,
          campusId: userProfileCampus?.id,
          institutionId: userProfileInstitution?.id,
          seasonId: userProfileSeason?.id,
          gradeId: userProfileGrade?.id,
          branchId: userProfileBranch?.id,
          coterieType: userProfile.coterieType,
        });
      if (managementErr) {
        console.log(managementErr);
      } else {
        setManagementData(managementData);
      }
    };
    const retrieveAndSetUserIndividualDashboardCatchtivityMetrics =
      async () => {
        const { data: catchtivityData, err: catchtivityErr } =
          await retrieveUserIndividualDashboardMetricsCatchtivity({
            userId: userInformation.id,
            userProfileId: userProfile.id,
            seasonId: userProfileSeason.id,
            deleted: false,
          });
        if (catchtivityErr) {
          console.log(catchtivityErr);
        } else {
          setCatchtivityData(catchtivityData);
        }
      };
    const retrieveAndSetUserContentCreatorDashboardManagementMetrics =
      async () => {
        setManagementData({});
      };

    const retrieveAndSetUserContentCreatorDashboardCatchtivityMetrics =
      async () => {
        const { data: catchtivityData, err: catchtivityErr } =
          await retrieveUserContentCreatorDashboardMetricsCatchtivity({
            userId: userInformation.id,
            userProfileId: userProfile.id,
            deleted: false,
          });
        if (catchtivityErr) {
          console.log(catchtivityErr);
        } else {
          setCatchtivityData(catchtivityData);
        }
      };

    if (userInformation && userProfile && boarding === "DASHBOARD") {
      setDashboardLoading(true);
      if (userInformation.accountType === "GENIXO") {
        retrieveAndSetGenixoDashboardManagementMetrics();
        retrieveAndSetGenixoDashboardCatchtivityMetrics();
      } else if (userInformation.accountType === "USER") {
        if (userProfile.role === "STAFF") {
          retrieveAndSetUserStaffDashboardManagementMetrics();
          retrieveAndSetUserStaffDashboardCatchtivityMetrics();
        } else if (userProfile.role === "INDIVIDUAL") {
          retrieveAndSetUserIndividualDashboardManagementMetrics();
          retrieveAndSetUserIndividualDashboardCatchtivityMetrics();
        } else if (userProfile.role === "CONTENT_CREATOR") {
          retrieveAndSetUserContentCreatorDashboardManagementMetrics();
          retrieveAndSetUserContentCreatorDashboardCatchtivityMetrics();
        }
      }
    }
  }, [userInformation, userProfile, boarding]);

  useEffect(() => {
    if (!managementData) return;
    if (!catchtivityData) return;
    setDashboardLoading(false);
  }, [managementData, catchtivityData]);

  useEffect(() => {
    const retrieveAndSetBrandLabelList = async () => {
      const { data, err } = await queryBrandLabelListByBrandId({
        brandId: userProfileBrand.id,
      });
      if (err) {
        console.log(err);
      } else {
        setUserBrandLabelSet(data);
      }
    };
    if (userProfileBrand && boarding === "DASHBOARD") {
      if (userProfile.role === "STAFF") {
        retrieveAndSetBrandLabelList();
      }
    }
  }, [userProfileBrand, boarding]);

  useEffect(() => {
    const retrieveAndSetPublishingHouseLabelList = async () => {
      const { data, err } = await queryPublishingHouseLabelList({});
      if (err) {
        console.log(err);
        return;
      }
      setUserPublishingHouseLabelSet(data);
    };
    if (!userInformation) return;
    if (userInformation.accountType === "GENIXO" && boarding === "DASHBOARD") {
      retrieveAndSetPublishingHouseLabelList();
    }
  }, [userInformation, boarding]);

  useEffect(() => {
    localStorage.setItem("mainLeftNavigationState", mainLeftNavigationState);
  }, [mainLeftNavigationState]);

  useEffect(() => {
    const retrieveAndSetManagementStompClient = (accessToken) => {
      const newManagementSocket = SockJS(
        `${connection.base.management.url}${endpoints.management.context}${endpoints.management.ws.context}`
      );
      const newManagementStompClient = Stomp.over(newManagementSocket);
      newManagementStompClient.connect(
        {
          "X-Authorization": "Bearer " + accessToken,
          "X-Details": JSON.stringify({
            brandId: userProfileBrand?.id,
            campusId: userProfileCampus?.id,
            institutionId: userProfileInstitution?.id,
            role: userProfile.role,
          }),
        },
        () => {
          setManagementStompClient(newManagementStompClient);
        }
      );
    };
    const retrieveAndSetCatchtivityStompClient = (accessToken) => {
      const newCatchtivitySocket = SockJS(
        `${connection.base.catchtivity.url}${endpoints.catchtivities.context}${endpoints.catchtivities.ws.context}`
      );
      const newCatchtivityStompClient = Stomp.over(newCatchtivitySocket);
      // stompClient.debug = () => {};
      newCatchtivityStompClient.connect(
        { "X-Authorization": "Bearer " + accessToken },
        () => {
          setCatchtivityStompClient(newCatchtivityStompClient);
        }
      );
    };
    const retrieveAndSetPlanStompClient = (accessToken) => {
      const newPlanSocket = SockJS(
        `${connection.base.plan.url}${endpoints.plans.context}${endpoints.plans.ws.context}`
      );
      const newPlanStompClient = Stomp.over(newPlanSocket);
      // stompClient.debug = () => {};
      newPlanStompClient.connect(
        { "X-Authorization": "Bearer " + accessToken },
        () => {
          setPlanStompClient(newPlanStompClient);
        }
      );
    };

    if (!window.location.pathname.includes("/email-verification/")) {
      if (userInformation && userProfile) {
        if (boarding === "DASHBOARD") {
          const accessToken = localStorage.getItem("accessToken");
          retrieveAndSetManagementStompClient(accessToken);
          retrieveAndSetCatchtivityStompClient(accessToken);
          retrieveAndSetPlanStompClient(accessToken);
        }
      } else {
        if (managementStompClient) {
          managementStompClient.disconnect();
          setManagementSubscribedId(null);
          setManagementStompClient(null);
        }
        if (catchtivityStompClient) {
          catchtivityStompClient.disconnect();
          setCatchtivityStompClient(null);
        }
        if (planStompClient) {
          planStompClient.disconnect();
          setPlanStompClient(null);
        }
      }
    }
  }, [userInformation, userProfile, boarding]);

  useEffect(() => {
    if (managementStompClient) {
      const subscribed = managementStompClient.subscribe(
        `/user/${userInformation.username}/topic/user/required-action`,
        (message) => {
          let { body } = message;
          body = JSON.parse(body);
          if (body === "PROFILE_SELECTION") {
            setBoarding("PROFILE_SELECTION_REQUIRED");
            window.location.replace(connection.admin.url);
          } else if (body === "LOGOUT") {
            handleLogoutOnClick();
          }
        }
      );
      setManagementSubscribedId(subscribed.id);
    }
  }, [managementStompClient]);

  const retrieveAndSetAccessTokenInformationFromRefreshToken = async (
    changeBoarding
  ) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (accessToken) {
      removeAxiosInterceptor();
      setAxiosInterceptor(accessToken);
      const { data, err } = await retrieveAccessTokenFromRefreshToken(
        refreshToken
      );
      if (err) {
        removeAxiosInterceptor();
        setBoarding("TOKEN_ERROR");
      } else {
        removeAxiosInterceptor();
        localStorage.setItem("refreshToken", data.refreshToken);
        setAxiosInterceptor(data.accessToken);
        if (err && err.code === "ERR_NETWORK") {
          setBoarding("NETWORK_ERROR");
        } else {
          if (changeBoarding) {
            setBoarding("TOKEN_RETRIEVED");
          }
        }
      }
    } else {
      setBoarding("LANDING");
    }
  };

  const handleLogoutOnClick = () => {
    handleStateLogout();
    setBoarding("LANDING");
    NotificationManager.success(i18n.t("logout_successful_text"));
  };

  const handleCheckAndRefreshToken = async () => {
    if (boarding === "DASHBOARD") {
      setLatestUpdateTime(new Date().getTime());
      if (new Date().getTime() - firstLoginTime > 7200000) {
        retrieveAndSetAccessTokenInformationFromRefreshToken(false);
        setFirstLoginTime(new Date().getTime());
      }
    }
  };

  const RenderCoterieTypeModal = () => {
    return (
      <BaseModal
        isOpen={showCoterieTypeWarningModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {}}
      >
        <div className="w-3/5 m-auto">
          <FullCard isShadowed={true}>
            <div className="flex-1 flex flex-col text-center my-5">
              <div className="flex flex-col items-center justify-center mb-5">
                <BaseImage
                  className="h-catchup-large-logo w-catchup-large-logo"
                  src="/logos/logo-primary.png"
                  alt="main-logo"
                  size="custom"
                />
              </div>
              <div className="my-3">
                <p className="italic font-medium">
                  {i18n.t(
                    "contact_your_administrator_to_assign_your_coterie_type_text"
                  )}
                </p>
              </div>
              <div className="mt-5">
                <PrimaryButton
                  title={i18n.t("logout")}
                  size="small"
                  onClick={handleLogoutOnClick}
                />
              </div>
            </div>
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  return (
    <div
      className="h-full text-base font-quicksand text-catchup-gray-600"
      onClick={handleCheckAndRefreshToken}
      ref={mutationRef}
    >
      <NotificationContainer />
      <div className="relative h-full">
        {RenderCoterieTypeModal()}
        {process.env.REACT_APP_NODE_ENV === "development" ? (
          <div className="absolute left-0 right-0 top-0 bg-catchup-red text-catchup-white flex flex-col items-center justify-center p-1">
            <p>DEVELOPMENT</p>
          </div>
        ) : null}

        {props.children}
      </div>
    </div>
  );
};

export default App;
