import { useEffect, useState } from "react";
import { Image } from "@react-pdf/renderer";
import katex from "katex";
import html2canvas from "html2canvas";
import axios from "axios";
import { convertToBase64 } from "../../utilization/FunctionUtilization";

export const LatexToPdfImage = (props) => {
  const { latex } = props;
  const [url, setUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [height, setHeight] = useState(null);
  const [useLocal, setUseLocal] = useState(false);
  const [key, setKey] = useState(new Date().getTime());

  useEffect(() => {
    const retrieveAndSetImageUrl = async () => {
      const { currentHeight } = retrieveDrawParams();
      setHeight(currentHeight);
      const axiosInstance = axios.create();
      const cleanFormula = latex.trim().replace(/\s+/g, " ");
      const encodedFormula = encodeURIComponent(cleanFormula);
      const url = `https://latex.codecogs.com/png.latex?%5Cdpi%7B600%7D%5Cbg_white%20${encodedFormula}`;
      setUrl(url);
      try {
        const response = await axiosInstance.get(url, {
          responseType: "arraybuffer",
        });
        const base64 = await convertToBase64(response.data);
        const dataUrl = `data:image/png;base64,${base64}`;
        setImageUrl(dataUrl);
        setKey(new Date().getTime());
      } catch (err) {
        setUseLocal(true);
      }
    };

    if (!latex) return;
    if (useLocal) return;
    retrieveAndSetImageUrl();
  }, [latex, useLocal]);

  useEffect(() => {
    const renderLatex = async () => {
      try {
        const { currentHeight, currentOffsetHeight, currentOffsetWidth } =
          retrieveDrawParams();
        setHeight(currentHeight);

        // Create container
        const container = document.createElement("div");
        container.style.display = "inline-block";
        container.style.margin = 0;
        container.style.padding = 0;
        container.style.position = "absolute";
        container.style.top = 0;
        container.style.left = 0;

        document.body.appendChild(container);

        // Render LaTeX
        katex.render(latex, container, {
          displayMode: true,
          //   macros: {
          //     "\\frac":
          //       "\\genfrac{}{}{0.4pt}{0}{\\raisebox{3pt}{#1}}{\\raisebox{0pt}{#2}}",
          //   },
          //   macros: {
          //     "\\frac": "\\genfrac{}{}{0.4pt}{0}{\\strut {#1}}{\\strut {#2}}",
          //   },
        });

        // Get the actual KaTeX element
        const katexElement = container.querySelector(".katex");
        if (!katexElement) {
          throw new Error("KaTeX element not found");
        }

        // Get precise dimensions
        const bounds = katexElement.getBoundingClientRect();

        // Create canvas with exact dimensions of the KaTeX element
        const canvas = await html2canvas(katexElement, {
          scale: 2,
          backgroundColor: null,
          y: currentOffsetHeight,
          canvas: (() => {
            const canvas = document.createElement("canvas");
            canvas.width =
              Math.ceil(bounds.width * 2) + 10 + currentOffsetWidth; // Account for scale
            canvas.height = Math.ceil(bounds.height * 2) * 1.5;
            return canvas;
          })(),
          width: Math.ceil(bounds.width),
          height: Math.ceil(bounds.height),
          useCORS: true,
        });

        const dataUrl = canvas.toDataURL("image/png");
        setImageUrl(dataUrl);

        // Cleanup
        document.body.removeChild(container);
      } catch (error) {
        console.error("Error rendering LaTeX:", error);
      }
    };
    if (!latex) return;
    if (!useLocal) return;
    renderLatex();
  }, [useLocal, latex]);

  const retrieveDrawParams = () => {
    let currentHeight = 16;
    let currentOffsetHeight = 0;
    let currentOffsetWidth = 0;
    if (latex.includes("\\int")) {
      currentHeight = 24;
    } else if (latex.includes("\\lim")) {
      currentHeight = 24;
      currentOffsetWidth = 30;
    } else if (latex.includes("\\ldots")) {
      currentOffsetWidth = 10;
    } else if (latex.includes("\\frac" || "\\dfrac")) {
      currentHeight = 24;
    } else if (latex.includes("\\sqrt")) {
    } else {
      currentHeight = 12;
    }
    return { currentHeight, currentOffsetHeight, currentOffsetWidth };
  };

  const handleOnLoad = () => {
    setKey(new Date().getTime());
  };

  return imageUrl && height ? (
    <Image
      key={key}
      src={url}
      onLoad={handleOnLoad}
      style={{
        height,
        padding: 1,
      }}
    />
  ) : null;
};
