import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Server
 */
export const checkEmailService = async () => {
  try {
    const response = await axios
      .create()
      .get(
        `${connection.base.email.url}${endpoints.emails.context}${endpoints.emails.actuator.health.context}`
      );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
