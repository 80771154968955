const SelectionBox = (props) => {
  const { optionList, selectedId, handleSelectOnClick } = props;

  return (
    <div className="flex flex-row items-center gap-x-4 gap-y-2 flex-wrap text-center">
      {optionList.map((option, index) => (
        <div
          key={index}
          className={`${
            option.id === selectedId
              ? "border-catchup-blue-400"
              : "border-catchup-gray-100 hover:border-catchup-blue-500"
          } border-2 rounded-catchup-xlarge py-3 px-8 cursor-pointer duration-300 transition-all`}
          onClick={() => {
            handleSelectOnClick(option.id);
          }}
        >
          <div
            className={`flex flex-row items-center gap-x-1 ${
              option.id === selectedId ? "opacity-100" : "opacity-50"
            }`}
          >
            <div>{option.icon}</div>
            <div className="flex-1 flex flex-col items-center">
              <p>{option.text}</p>
              {option.subText ? (
                <p className="text-md">({option.subText})</p>
              ) : null}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectionBox;
