import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import i18n from "../../language/i18n";
import {
  deleteUserProfileById,
  patchUserOnlyRequiredAction,
  queryAllUserProfileDTOPageByFilter,
} from "../../requests/ManagementRequests";
import {
  checkInsertPermissionGranted,
  checkViewPermissionGranted,
  checkDeletePermissionGranted,
  checkUpdatePermissionGranted,
} from "../../utilization/ScreenUtilization";
import { NotificationManager } from "react-notifications";
import { useAuth } from "../../context/UserContextProvider";
import { useApp } from "../../context/AppContextProvider";
import AdvancedTable from "../../components/tables/AdvancedTable";
import CreateUserProfile from "../../components/user-profiles/CreateUserProfile";
import BaseImage from "../../components/images/BaseImage";

const UserProfileListScreen = () => {
  const { userId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const { userProfile, userProfileBrand } = useAuth();
  const [userProfileDTOList, setUserProfileDTOList] = useState([]);
  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const [pageState, setPageState] = useState(0);
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [userProfileRole, setUserProfileRole] = useState(null);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("user_profile_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    const pathRole = window.location.pathname.split("/")[1];
    if (pathRole === "learner-users") {
      setUserProfileRole("LEARNER");
    } else if (pathRole === "staff-users") {
      setUserProfileRole("STAFF");
    } else if (pathRole === "individual-users") {
      setUserProfileRole("INDIVIDUAL");
    } else if (pathRole === "content-creator-users") {
      setUserProfileRole("CONTENT_CREATOR");
    } else {
      setUserProfileRole("ADMIN");
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (state) {
      const foundState = state.find(
        (s) => s.screenName === "user_profile_list"
      );
      if (!foundState) return;
      const { pageNumber, itemCount } = foundState;
      setPageNumber(pageNumber);
      setSelectedShowItemCount(itemCount);
    } else {
      setPageNumber(0);
      setSelectedShowItemCount(25);
    }
  }, [state]);

  useEffect(() => {
    if (userProfileRole) {
      retrieveAndSetUserProfilePageByFilter();
    }
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
    userProfileRole,
  ]);

  useEffect(() => {
    if (isFilterApplied) {
      retrieveAndSetUserProfilePageByFilter();
    }
  }, [isFilterApplied, filterBeginAt, filterEndAt, filterShowDeleted]);

  const retrieveAndSetUserProfilePageByFilter = async () => {
    setTableLoading(true);
    const { data, err } = await queryAllUserProfileDTOPageByFilter({
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        brandId: userProfileBrand.id,
        userId: userId,
        beginAt:
          isFilterApplied && filterBeginAt
            ? new Date(filterBeginAt).getTime()
            : null,
        endAt:
          isFilterApplied && filterEndAt
            ? new Date(filterEndAt).getTime()
            : null,
        deleted: isFilterApplied ? (filterShowDeleted ? null : false) : false,
      },
    });
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setUserProfileDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const retrievePrevTitle = () => {
    if (userProfileRole === "LEARNER") {
      return i18n.t("learner_user_list");
    } else if (userProfileRole === "STAFF") {
      return i18n.t("staff_user_list");
    } else if (userProfileRole === "INDIVIDUAL") {
      return i18n.t("individual_user_list");
    } else if (userProfileRole === "CONTENT_CREATOR") {
      return i18n.t("content_creator_user_list");
    } else if (userProfileRole === "ADMIN") {
      return i18n.t("user_list");
    }
    return null;
  };

  const checkCanNavigate = () => {
    let canNavigate = true;
    if (!checkViewPermissionGranted("user_profile_authority_list")) {
      canNavigate = false;
    }
    return canNavigate;
  };

  const constructNavigatableList = () => {
    let navigateList = [];
    if (checkViewPermissionGranted("user_profile_authority_list")) {
      navigateList.push({
        id: 0,
        text: i18n.t("to_authority_set_screen"),
        icon: (
          <BaseImage
            src="/icons/navigate-gray.png"
            alt="navigate"
            size="small"
          />
        ),
        value: `/${
          window.location.pathname.split("/")[1]
        }/${userId}/profiles/{id}/authorities`,
        params: [
          ...state,
          {
            screenName: "user_profile_list",
            pageNumber,
            itemCount: selectedShowItemCount,
          },
        ],
      });
    }
    return navigateList;
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handlePatchUserRequiredActionOnClick = async () => {
    await patchUserOnlyRequiredAction({
      id: userId,
      requiredAction: "PROFILE_SELECTION",
    });
  };

  const handleReturnFromUserProfileOnClick = () => {
    setPageState(0);
  };

  const handleAfterUpdateOnClick = () => {
    handlePatchUserRequiredActionOnClick();
    retrieveAndSetUserProfilePageByFilter();
    handleReturnFromUserProfileOnClick();
  };

  const handleAfterCreateOnClick = () => {
    retrieveAndSetUserProfilePageByFilter();
    handleReturnFromUserProfileOnClick();
  };

  const handleDeleteUserProfileOnClick = async (userProfileId) => {
    if (checkDeletePermissionGranted("user_profile_list")) {
      const { err } = await deleteUserProfileById(userProfileId);
      if (err) {
        console.log(err);
      } else {
        const foundUserProfile = userProfileDTOList.find(
          (userProfileDTO) => userProfileDTO.id === userProfileId
        );
        foundUserProfile.deleted = true;
        setUserProfileDTOList(JSON.parse(JSON.stringify(userProfileDTOList)));
        retrieveAndSetUserProfilePageByFilter();
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_delete"));
    }
  };

  const handleToCreateUserProfileInformation = () => {
    setSelectedUserProfile(null);
    setPageState(1);
  };

  const handleToUpdateUserProfileInformation = (userProfileDTO) => {
    setSelectedUserProfile(userProfileDTO);
    setPageState(1);
  };

  const constructHeaderList = () => {
    if (userProfileRole === "LEARNER") {
      return [
        { name: i18n.t("name"), key: "name", type: "info" },
        { name: i18n.t("role"), key: "role", type: "enumeration" },
        {
          name: i18n.t("branch_name"),
          key: "branchDTOList.name",
          type: "listItemAsString",
        },
        {
          name: i18n.t("created_at"),
          key: "createdAt",
          type: "datetime",
        },
      ];
    } else if (userProfileRole === "STAFF") {
      return [
        { name: i18n.t("name"), key: "name", type: "info" },
        { name: i18n.t("role"), key: "role", type: "enumeration" },
        {
          name: i18n.t("coterie_type"),
          key: "coterieType",
          type: "enumeration",
        },
        {
          name: i18n.t("created_at"),
          key: "createdAt",
          type: "datetime",
        },
      ];
    } else if (userProfileRole === "INDIVIDUAL") {
      return [
        { name: i18n.t("name"), key: "name", type: "info" },
        { name: i18n.t("role"), key: "role", type: "enumeration" },
        {
          name: i18n.t("created_at"),
          key: "createdAt",
          type: "datetime",
        },
      ];
    } else if (userProfileRole === "CONTENT_CREATOR") {
      return [
        { name: i18n.t("name"), key: "name", type: "info" },
        { name: i18n.t("role"), key: "role", type: "enumeration" },
        {
          name: i18n.t("coterie_type"),
          key: "coterieType",
          type: "enumeration",
        },
        {
          name: i18n.t("created_at"),
          key: "createdAt",
          type: "datetime",
        },
      ];
    } else {
      return [
        { name: i18n.t("name"), key: "name", type: "info" },
        { name: i18n.t("role"), key: "role", type: "enumeration" },
        {
          name: i18n.t("created_at"),
          key: "createdAt",
          type: "datetime",
        },
      ];
    }
  };

  const checkIfCanCreate = () => {
    if (userProfile.role === "INDIVIDUAL") {
      return false;
    } else {
      return checkInsertPermissionGranted("user_profile_list");
    }
  };

  const checkIfCanDelete = () => {
    if (
      userProfileRole === "LEARNER" ||
      userProfileRole === "STAFF" ||
      userProfileRole === "CONTENT_CREATOR"
    ) {
      return checkDeletePermissionGranted("user_profile_list");
    } else if (userProfileRole === "INDIVIDUAL") {
      return false;
    }
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={i18n.t("user_profile_list")}
            description={i18n.t("user_profile_list_table_description_text")}
            itemName={i18n.t("user_profile")}
            selectable={false}
            headerList={constructHeaderList()}
            showFilter={true}
            showItemCount={selectedShowItemCount}
            totalItemCount={totalItemCount}
            pageNumber={pageNumber}
            loading={tableLoading}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleOnFilterChange={handleOnFilterChange}
            handleOnTableParamsChange={handleOnTableParamsChange}
            filterParams={{
              isFilterApplied,
              showBeginDate: true,
              beginAt: filterBeginAt,
              showEndDate: true,
              endAt: filterEndAt,
              showDeleted: filterShowDeleted,
            }}
            itemList={userProfileDTOList}
            insertable={checkIfCanCreate()}
            handleInsertOnClick={handleToCreateUserProfileInformation}
            updatable={checkUpdatePermissionGranted("user_profile_list")}
            handleUpdateOnClick={handleToUpdateUserProfileInformation}
            deletable={checkIfCanDelete()}
            handleDeleteOnClick={handleDeleteUserProfileOnClick}
            navigatable={checkCanNavigate()}
            navigateOptionList={constructNavigatableList()}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <CreateUserProfile
            showTitle={true}
            useCardView={true}
            handleAfterUpdateOnClick={handleAfterUpdateOnClick}
            handleAfterCreateOnClick={handleAfterCreateOnClick}
            handleReturnOnClick={handleReturnFromUserProfileOnClick}
            user={{ id: userId }}
            selectedUserProfile={selectedUserProfile}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default UserProfileListScreen;
