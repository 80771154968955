import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import i18n from "../../language/i18n";
import { queryLabelActionByLabelId } from "../../requests/LabelRequests";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import FullCard from "../../components/cards/FullCard";
import BaseTitle from "../../components/titles/BaseTitle";

const LabelDetailScreen = () => {
  const navigate = useNavigate();
  const { labelId } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [labelActionDTO, setLabelActionDTO] = useState(null);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetLabelDetail = async () => {
      const { data, err } = await queryLabelActionByLabelId({ labelId });
      if (err) {
        console.log(err);
      } else {
        setLabelActionDTO(data);
      }
    };
    if (checkViewPermissionGranted("label_detail")) {
      retrieveAndSetLabelDetail();
    } else {
      navigate("/permission-error");
    }
  }, []);

  const RenderMainContent = () => {
    if (labelActionDTO) {
      return (
        <FullCard isShadowed={true}>
          <div className="mb-5">
            <div className="flex flex-row items-end">
              <p
                className="text-xl font-italic cursor-pointer mr-2"
                onClick={() => {
                  navigate(`/labels`);
                }}
              >
                {i18n.t("label_list")}
                {" / "}
              </p>
              <BaseTitle title={i18n.t("label_details")} />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center text-catchup-dark-blue">
            <div className="w-full flex flex-row items-center">
              <div className="w-catchup-input-group-title py-2">
                <p>{i18n.t("label_name")}</p>
              </div>
              <div className="flex-1">
                <p>{labelActionDTO.labelDTO.name}</p>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-row items-center">
            <div className="w-catchup-input-group-title py-2">
              <p>{i18n.t("label_description")}</p>
            </div>
            <div className="flex-1">
              <p>{labelActionDTO.labelDTO.description}</p>
            </div>
          </div>
          <br />

          <div className="m-auto">
            {labelActionDTO.labelItemDTOList.map((labelItemDTO) => (
              <div className="my-5">
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <p>{i18n.t("item_id")}</p>
                  </div>
                  <div>
                    <p>{i18n.t(labelItemDTO.itemId)}</p>
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <p>{i18n.t("item_type")}</p>
                  </div>
                  <div>
                    <p>{i18n.t(labelItemDTO.itemType)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </FullCard>
      );
    }
    return null;
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default LabelDetailScreen;
