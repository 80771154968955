import { useNavigate } from "react-router-dom";
import i18n from "../../language/i18n";
import ApproveButton from "../buttons/ApproveButton";
import SecondaryButton from "../buttons/SecondaryButton";
import BaseImage from "../images/BaseImage";
import { Tooltip } from "react-tooltip";
import DividerLine from "../divider/DividerLine";
import GradeLabel from "../label/GradeLabel";
import { retrieveMonthNameByIndex } from "../../utilization/DateUtilization";

const StandardExamApplicationCard = (props) => {
  const navigate = useNavigate();
  const {
    standardExamApplicationDTO,
    language,
    handleToUpdateOnClick,
    handleEvaluateOnClick,
  } = props;
  const ONE_DAY = 86400000;

  let borderColor;
  let deadlineAtColor;
  let showToUpdateButton;
  let showToDetailButton;
  let showEvaluateButton;

  if (!standardExamApplicationDTO) return;
  const { id, beginAt, endAt, isEvaluated, standardExamDTO, completionStatus } =
    standardExamApplicationDTO;
  console.log(standardExamDTO);
  const { name, description, standardExamType, level, activityDTOList } =
    standardExamDTO;

  const currentTime = new Date();
  const currentTimestamp = currentTime.getTime();
  const beginTime = new Date(beginAt);
  const endTime = new Date(endAt);

  const calculateRemainingDays = (providedTime) => {
    const remainingTime = providedTime - currentTimestamp;
    return Math.ceil(remainingTime / 1000 / 60 / 60 / 24);
  };

  if (beginAt > currentTimestamp) {
    if (beginAt - currentTimestamp > ONE_DAY) {
      borderColor = "border-catchup-gray-50";
      deadlineAtColor = "text-catchup-gray-400";
      showToUpdateButton = true;
      showToDetailButton = false;
      showEvaluateButton = false;
    } else {
      borderColor = "border-catchup-gray-50";
      deadlineAtColor = "text-catchup-gray-400";
      showToUpdateButton = true;
      showToDetailButton = false;
      showEvaluateButton = false;
    }
  } else if (beginAt < currentTimestamp && endAt > currentTimestamp) {
    if (endAt - currentTimestamp > ONE_DAY) {
      borderColor = "border-catchup-blue-400";
      deadlineAtColor = "text-catchup-gray-400";
      showToUpdateButton = true;
      showToDetailButton = false;
      showEvaluateButton = false;
    } else {
      borderColor = "border-catchup-blue-400";
      deadlineAtColor = "text-catchup-red";
      showToUpdateButton = true;
      showToDetailButton = false;
      showEvaluateButton = false;
    }
  } else {
    if (isEvaluated) {
      borderColor = "border-catchup-green";
      deadlineAtColor = "text-catchup-gray-400";
      showToUpdateButton = false;
      showToDetailButton = false;
      showEvaluateButton = true;
    } else {
      borderColor = "border-catchup-green";
      deadlineAtColor = "text-catchup-gray-400";
      showToUpdateButton = false;
      showToDetailButton = false;
      showEvaluateButton = true;
    }
  }

  return (
    <div
      className={`${borderColor} bg-catchup-white border-2 rounded-catchup-xlarge p-4 mx-2 my-4 w-[400px]`}
    >
      <div className="flex flex-row gap-x-2">
        <BaseImage
          size="large"
          src="/icons/standard-exam.png"
          alt="standard-exam"
        />
        <div className="flex-1 flex flex-col">
          <p className="font-semibold text-catchup-blue-500 text-lg">
            {i18n.t("standard_exam")}
          </p>
          <p>
            {beginTime.getUTCDate()}
            {"-"}
            {endTime.getUTCDate()}{" "}
            {retrieveMonthNameByIndex(endTime.getMonth())}{" "}
            {endTime.getFullYear()}
          </p>
        </div>
      </div>
      <div className="my-2">
        <DividerLine />
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <p className="font-semibold">{name}</p>
          <div className="flex flex-row items-center gap-x-2 text-catchup-gray-300">
            <div>
              <p>{i18n.t(standardExamType)}</p>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-2 mt-2">
            <p className={`${deadlineAtColor}`}>{`${i18n.t(
              "deadline_date"
            )}: ${endTime.toLocaleString(language)}`}</p>
          </div>
        </div>
        <div>
          <Tooltip id="info-description" />
          <div className="mr-2">
            <BaseImage
              size="small"
              src="/icons/info.png"
              alt="info"
              dataToolTipId="info-description"
              dataToolTipContent={description}
              dataToolTipPlace="top"
              dataToolTipVariant="info"
            />
          </div>
        </div>
      </div>

      {showEvaluateButton ? (
        isEvaluated ? (
          <div className="flex-1 flex flex-col mt-5">
            <SecondaryButton
              title={i18n.t("to_detail")}
              size="unlimited"
              onClick={() => {
                navigate(`/standard-exam/applications/${id}/detail`);
              }}
            />
          </div>
        ) : (
          <div className="flex-1 flex flex-col mt-5">
            <ApproveButton
              title={i18n.t("evaluate")}
              size="unlimited"
              onClick={async () => {
                await handleEvaluateOnClick(id);
              }}
            />
          </div>
        )
      ) : showToDetailButton ? (
        <div className="flex-1 flex flex-col mt-5">
          <SecondaryButton
            title={i18n.t("to_detail")}
            size="unlimited"
            onClick={() => {
              navigate(`/standard-exams/applications/${id}/detail`);
            }}
          />
        </div>
      ) : showToUpdateButton ? (
        <div className="flex-1 flex flex-col mt-5">
          <SecondaryButton
            title={i18n.t("edit")}
            size="unlimited"
            onClick={() => {
              handleToUpdateOnClick(standardExamApplicationDTO);
            }}
          />
        </div>
      ) : null}

      {/* <div className="flex flex-col h-full">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row items-center gap-x-2">
            <BaseImage
              size="2xlarge"
              src="/icons/catchtivity.png"
              alt="catchtivity"
            />
            <p className="text-2xl font-semibold">{i18n.t("catchtivity")}</p>
          </div>
          <div>
            <Tooltip id="info-description" />
            <div className="mr-2">
              <BaseImage
                size="small"
                src="/icons/info.png"
                alt="info"
                dataToolTipId="info-description"
                dataToolTipContent={description}
                dataToolTipPlace="top"
                dataToolTipVariant="info"
              />
            </div>
          </div>
        </div>

        <div className="my-2">
          <p className="text-xl font-semibold">{name}</p>
        </div>

        <div className="my-2 flex flex-col">
          <div className="">
            <p className="">{i18n.t(coterieType)}</p>
          </div>
          <div className="">
            <p className="">{`${i18n.t("grade")}: ${level}`}</p>
          </div>
          <div className="">
            <p className="text-md italic text">
              {`${i18n.t(activityDTOList.length)} ${i18n.t("activity")}`}
            </p>
          </div>
          <div className="flex flex-row items-center gap-x-2">
            <p className={`${beginAtColor}`}>{`${i18n.t(
              "begin_at"
            )}: ${beginTime.toLocaleString(language)}`}</p>
          </div>
          <div className="flex flex-row items-center gap-x-2">
            <p className={`${endAtColor}`}>{`${i18n.t(
              "deadline_date"
            )}: ${endTime.toLocaleString(language)}`}</p>
            {showExclamation ? (
              <BaseImage
                src="/icons/exclamation-red.png"
                alt="exclamation"
                size="small"
              />
            ) : null}
          </div>
        </div>
        <div className="mt-auto flex flex-col">
          {completionStatus === "TIME_UP" ? (
            <div
              className={`${buttonBackgroundColor} ${buttonTextColor} ${buttonBorderColor} flex flex-col text-center p-2 rounded-xl`}
            >
              <p>{`${i18n.t("time_is_up_text")}`}</p>
            </div>
          ) : completionStatus === "COMPLETED" ? (
            <div
              className={`${buttonBackgroundColor} ${buttonTextColor} ${buttonBorderColor} flex flex-col text-center p-2 rounded-xl`}
            >
              <p>{`${i18n.t("completed_text")}`}</p>
            </div>
          ) : null}
        </div>
      </div>
      {showRemainingDays ? (
        <div className="mt-5">
          <div
            className={`${buttonBackgroundColor} ${buttonTextColor} flex flex-col text-center p-2 rounded-xl`}
          >
            <p>{`${i18n.t(
              "remaining_days_left_text_1"
            )}${calculateRemainingDays(beginAt)}${i18n.t(
              "remaining_days_left_text_2"
            )}`}</p>
          </div>
        </div>
      ) : null}
      {showToDetailButton ? (
        <div className="flex flex-row justify-end mt-5">
          <SecondaryButton
            title={i18n.t("to_detail")}
            size="small"
            onClick={() => {
              navigate(`/catchtivities/applications/${id}/detail`);
            }}
          />
        </div>
      ) : null} */}
    </div>
  );
};

export default StandardExamApplicationCard;
