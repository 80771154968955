import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import { useEffect, useRef, useState } from "react";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import { retrieveExternalExamDTOResultsByIdAndLearnerId } from "../../requests/CatchtivityRequests";
import FullCard from "../../components/cards/FullCard";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import DividerLine from "../../components/divider/DividerLine";
import i18n from "../../language/i18n";
import { showDocumentByPathAndFileName } from "../../utilization/StorageFileUtilization";
import BaseLoading from "../../components/loading/BaseLoading";
import PageTravelGroup from "../../components/groups/PageTravelGroup";

const ExternalExamSingleResultScreen = () => {
  const navigate = useNavigate();
  const { externalExamId, learnerId } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [externalExamSingleResultDTO, setExternalExamSingleResultDTO] =
    useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [imageReady, setImageReady] = useState(false);
  const imageRef = useRef(null);
  const [sidebarTop, setSidebarTop] = useState(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("external_exam_list")) {
      navigate("/permission-error");
      return;
    }
  }, []);

  useEffect(() => {
    if (!externalExamId) return;
    if (!learnerId) return;
    retrieveAndSetExternalSingleExamResultDTO();
  }, [externalExamId, learnerId]);

  useEffect(() => {
    if (!imageReady) return;
    if (!imageRef) return;
    if (!imageRef.current) return;
    const rect = imageRef.current.getBoundingClientRect();
    setSidebarTop(rect.top);
  }, [imageRef, imageReady]);

  useEffect(() => {
    if (!sidebarTop) return;
    const scrollElement = document.getElementById("scroll");
    scrollElement.addEventListener("scroll", checkIsSticky);
    return () => {
      scrollElement.removeEventListener("scroll", checkIsSticky);
    };
  }, [sidebarTop]);

  const checkIsSticky = () => {
    const scrollElement = document.getElementById("scroll");
    const scrollTop = scrollElement.scrollTop;
    if (scrollTop >= sidebarTop - 40) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const retrieveAndSetExternalSingleExamResultDTO = async () => {
    setLoading(true);
    const { data, err } = await retrieveExternalExamDTOResultsByIdAndLearnerId(
      externalExamId,
      learnerId
    );
    if (err) {
      console.log(err);
      return;
    }
    setExternalExamSingleResultDTO(data);
    setLoading(false);
  };

  const constructImageFileListFromExternalExamPartLearnerDTOList = (
    externalExamPartResultLearnerDTOList
  ) => {
    const imageFileList = [];
    externalExamPartResultLearnerDTOList.forEach(
      (externalExamPartResultLearnerDTO) => {
        const { answerFilePath, answerFileName } =
          externalExamPartResultLearnerDTO;
        const lastIndex = answerFilePath.lastIndexOf("/");
        const parsedAnswerFilePath = answerFilePath.substring(0, lastIndex);
        const foundImageFile = imageFileList.find(
          (imageFile) =>
            imageFile.fileName === answerFileName &&
            imageFile.filePath === parsedAnswerFilePath
        );
        if (!foundImageFile) {
          return imageFileList.push({
            fileName: answerFileName,
            filePath: parsedAnswerFilePath,
          });
        }
      }
    );

    return imageFileList;
  };

  const handleOnImageLoad = () => {
    setImageReady(true);
  };

  const RenderResultContent = () => {
    if (!externalExamSingleResultDTO) return;
    const { externalExamResultLearnerDTO } = externalExamSingleResultDTO;
    const { externalExamActivityResultLearnerDTOList } =
      externalExamResultLearnerDTO;
    const imageFileList =
      constructImageFileListFromExternalExamPartLearnerDTOList(
        externalExamActivityResultLearnerDTOList
      );
    const currentImageFile = imageFileList[pageNumber];
    const { filePath, fileName } = currentImageFile;
    const filteredExternalExamActivityResultLearnerDTOList =
      externalExamActivityResultLearnerDTOList.filter(
        (externalExamActivityResultLearnerDTO) =>
          externalExamActivityResultLearnerDTO.answerFileName === fileName
      );
    return (
      <div className="my-4">
        <PageTravelGroup
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          imageReady={imageReady}
          setImageReady={setImageReady}
          isImageProcessing={false}
          initialTotalPageNumber={imageFileList.length}
        />
        <div className="w-full flex flex-row gap-x-2">
          <div className="flex-1" ref={imageRef}>
            {showDocumentByPathAndFileName(
              "FULL_WIDTH_IMAGE",
              filePath,
              fileName,
              handleOnImageLoad
            )}
          </div>
          <div className={`w-[150px] md:w-[250px] lg:w-[300px] xl:w-[350px]`}>
            <div
              className={`flex-1 w-full border rounded-md p-2 max-h-[300px] md:max-h-[400px] lg:max-h-[500px] xl:max-h-[600px] overflow-y-auto ${
                isSticky
                  ? "fixed top-10 z-10 w-[150px] md:w-[250px] lg:w-[300px] xl:w-[350px] animate-[fadeInDown_500ms_ease-in-out]"
                  : ""
              }`}
            >
              {filteredExternalExamActivityResultLearnerDTOList.map(
                (externalExamActivityResultLearnerDTO) => (
                  <div className="my-3">
                    <div className="border-b">
                      <p className="font-bold">
                        {externalExamActivityResultLearnerDTO.position}.
                        {i18n.t(
                          `${externalExamActivityResultLearnerDTO.coterieType}_ABBREVATION`
                        )}
                        <span className="mx-2">
                          (
                          {externalExamActivityResultLearnerDTO.score.toFixed(
                            2
                          )}
                          {"/"}
                          {externalExamActivityResultLearnerDTO.maxScore.toFixed(
                            2
                          )}
                          )
                        </span>
                      </p>
                    </div>
                    <div className="mt-1">
                      <p className="">
                        {externalExamActivityResultLearnerDTO.aiFeedbackText}
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderMainContent = () => {
    return (
      <FullCard isShadowed={true}>
        <div className="">
          <div className="flex flex-row items-center gap-x-2">
            <div className="cursor-pointer">
              <BaseImage
                size="medium"
                src="/icons/long-arrow-left.png"
                alt="long-arrow-left"
                onClick={() => {
                  navigate(
                    `/external-exams/${externalExamId}/external-exam-results`
                  );
                }}
              />
            </div>
            <BaseTitle
              title={i18n.t("create_external_exam_answers_from_pdf")}
            />
          </div>
          <DividerLine />
          {loading ? (
            <BaseLoading
              size="large"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          ) : (
            RenderResultContent()
          )}
        </div>
      </FullCard>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ExternalExamSingleResultScreen;
