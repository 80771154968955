import { useState } from "react";
import BaseImage from "../images/BaseImage";

const BaseCarousel = (props) => {
  const { componentList, showBoxShadow, showArrows } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  const RenderCurrentComponent = () => {
    return componentList[currentIndex];
  };

  const RenderDots = () => {
    return (
      <div className="flex flex-row items-center justify-center">
        {componentList.map((component, index) => (
          <div className="px-1 py-2" key={index}>
            <BaseImage
              size="xsmall"
              className="cursor-pointer transition-all duration-300"
              src={`${
                index === currentIndex
                  ? "/icons/item-element.png"
                  : "/icons/not-selected-item-element.png"
              }`}
              alt="selectable-item"
              onClick={() => {
                setCurrentIndex(index);
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className="relative flex-1 flex flex-col h-full bg-catchup-white rounded-catchup-xlarge"
      style={{
        boxShadow: showBoxShadow
          ? "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"
          : null,
      }}
    >
      <div className="flex-1 flex flex-col justify-center ">
        {RenderCurrentComponent()}
      </div>
      {/* <div className="absolute h-9 left-1/2 bottom-0">{RenderDots()}</div> */}
    </div>
  );
};

export default BaseCarousel;
