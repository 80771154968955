import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import { useEffect, useState } from "react";
import {
  evaluateStandardExamApplicationById,
  retrieveActivityWithDataById,
  retrieveStandardExamApplicationInDetail,
} from "../../requests/CatchtivityRequests";
import i18n from "../../language/i18n";
import RenderInformationCardItem from "../../components/cards/InformationCard";
import BaseImage from "../../components/images/BaseImage";
import InputGroup from "../../components/groups/InputGroup";
import DividerLine from "../../components/divider/DividerLine";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import { Tooltip } from "react-tooltip";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import BaseTitle from "../../components/titles/BaseTitle";
import FullCard from "../../components/cards/FullCard";
import { retrieveLearnerStandardExamReportDetailsByParams } from "../../requests/ReportRequests";
import { retrieveColorByScore } from "../../utilization/CatchtivityUtilization";
import SelectionTab from "../../components/tabs/SelectionTab";
import ActivityTemplateLabel from "../../components/label/ActivityTemplateLabel";
import BaseLoading from "../../components/loading/BaseLoading";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const StandardExamApplicationDetailScreen = () => {
  const { standardExamApplicationId } = useParams();
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userProfileInstitution,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
  } = useAuth();
  const [standardExamApplicationDetail, setStandardExamApplicationDetail] =
    useState(null);
  const [selectedInstitutionId, setSelectedInstitutionId] =
    useState("DEFAULT_OPTION");
  const [selectedSeasonId, setSelectedSeasonId] = useState("DEFAULT_OPTION");
  const [selectedGradeId, setSelectedGradeId] = useState("DEFAULT_OPTION");
  const [selectedBranchId, setSelectedBranchId] = useState("DEFAULT_OPTION");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserProfileId, setSelectedUserProfileId] = useState(null);
  const [
    showDetailedUserUserProfileModal,
    setShowDetailedUserUserProfileModal,
  ] = useState(false);
  const [showActivityStatisticsModal, setShowActivityStatisticsModal] =
    useState(false);
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");
  const [
    learnerStandardExamReportLoading,
    setLearnerStandardExamReportLoading,
  ] = useState(false);
  const [learnerStandardExamReport, setLearnerStandardExamReport] =
    useState(null);
  const [selectedInformationState, setSelectedInformationState] =
    useState("LEARNER");

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (standardExamApplicationId) {
      retrieveAndSetStandardExamApplicationInDetail();
    }
  }, [standardExamApplicationId]);

  useEffect(() => {
    if (userProfileBranch) {
      setSelectedInstitutionId(
        userProfileBranch.gradeDTO.seasonDTO.institutionDTO.id
      );
      setSelectedSeasonId(userProfileBranch.gradeDTO.seasonDTO.id);
      setSelectedGradeId(userProfileBranch.gradeDTO.id);
      setSelectedBranchId(userProfileBranch.id);
    } else if (userProfileGrade) {
      setSelectedInstitutionId(userProfileGrade.seasonDTO.institutionDTO.id);
      setSelectedSeasonId(userProfileGrade.seasonDTO.id);
      setSelectedGradeId(userProfileGrade.id);
    } else if (userProfileSeason) {
      setSelectedInstitutionId(userProfileSeason.institutionDTO.id);
      setSelectedSeasonId(userProfileSeason.id);
    } else if (userProfileInstitution) {
      setSelectedInstitutionId(userProfileInstitution.id);
    } else {
      const filteredApplicationSet =
        retrieveInstitutionFilteredApplicationSet();
      if (filteredApplicationSet.length === 1) {
        setSelectedInstitutionId(filteredApplicationSet[0].institutionId);
      }
    }
  }, [standardExamApplicationDetail]);

  useEffect(() => {
    if (selectedInstitutionId !== "DEFAULT_OPTION") {
      if (retrieveSeasonDTOOptionList().length > 0) {
        setSelectedSeasonId(retrieveSeasonDTOOptionList()[0].value);
      }
    }
  }, [standardExamApplicationDetail, selectedInstitutionId]);

  useEffect(() => {
    if (selectedSeasonId !== "DEFAULT_OPTION") {
      if (retrieveGradeDTOOptionList().length > 0) {
        setSelectedGradeId(retrieveGradeDTOOptionList()[0].value);
      }
    }
  }, [standardExamApplicationDetail, selectedSeasonId]);

  useEffect(() => {
    if (selectedGradeId !== "DEFAULT_OPTION") {
      if (retrieveBranchDTOOptionList().length > 0) {
        setSelectedBranchId(retrieveBranchDTOOptionList()[0].value);
      }
    }
  }, [standardExamApplicationDetail, selectedGradeId]);

  useEffect(() => {
    const retrieveAndSetStandardExamReportDTO = async () => {
      setLearnerStandardExamReportLoading(true);
      const { data, err } =
        await retrieveLearnerStandardExamReportDetailsByParams({
          userId: selectedUserId,
          userProfileId: selectedUserProfileId,
          standardExamApplicationId,
        });
      if (err) {
        console.log(err);
      } else {
        setLearnerStandardExamReport(data);
      }
      setLearnerStandardExamReportLoading(false);
    };
    if (selectedUserId && selectedUserProfileId) {
      retrieveAndSetStandardExamReportDTO();
    }
  }, [selectedUserId, selectedUserProfileId]);

  const retrieveAndSetStandardExamApplicationInDetail = async () => {
    const { data, err } = await retrieveStandardExamApplicationInDetail(
      standardExamApplicationId
    );
    if (err) {
      console.log(err);
      return;
    }
    setStandardExamApplicationDetail(data);
  };

  const filterAvailableApplicationUserDetailList = () => {
    let filteredApplicationList = [];
    if (standardExamApplicationDetail) {
      if (userProfileBranch) {
        filteredApplicationList =
          standardExamApplicationDetail.standardExamUserDTOList.filter(
            (standardExamUserDTO) =>
              parseFloat(standardExamUserDTO.branchId) ===
              parseFloat(userProfileBranch.id)
          );
      } else if (userProfileGrade) {
        filteredApplicationList =
          standardExamApplicationDetail.standardExamUserDTOList.filter(
            (standardExamUserDTO) =>
              parseFloat(standardExamUserDTO.gradeId) ===
              parseFloat(userProfileGrade.id)
          );
      } else if (userProfileSeason) {
        filteredApplicationList =
          standardExamApplicationDetail.standardExamUserDTOList.filter(
            (standardExamUserDTO) =>
              parseFloat(standardExamUserDTO.seasonId) ===
              parseFloat(userProfileSeason.id)
          );
      } else if (userProfileInstitution) {
        filteredApplicationList =
          standardExamApplicationDetail.standardExamUserDTOList.filter(
            (standardExamUserDTO) =>
              parseFloat(standardExamUserDTO.institutionId) ===
              parseFloat(userProfileInstitution.id)
          );
      } else {
        return standardExamApplicationDetail.standardExamUserDTOList;
      }
    }
    return filteredApplicationList;
  };

  const filterSelectedApplicationUserDetailList = () => {
    if (standardExamApplicationDetail) {
      return standardExamApplicationDetail.standardExamUserDTOList.filter(
        (standardExamUserDTO) =>
          parseFloat(standardExamUserDTO.branchId) ===
            parseFloat(selectedBranchId) &&
          parseFloat(standardExamUserDTO.gradeId) ===
            parseFloat(selectedGradeId) &&
          parseFloat(standardExamUserDTO.seasonId) ===
            parseFloat(selectedSeasonId) &&
          parseFloat(standardExamUserDTO.institutionId) ===
            parseFloat(selectedInstitutionId)
      );
    }
    return [];
  };

  const retrieveInstitutionFilteredApplicationSet = () => {
    const filteredApplicationSet = [];
    filterAvailableApplicationUserDetailList().forEach((filterApplication) => {
      if (
        filteredApplicationSet.findIndex(
          (newFilterApplication) =>
            parseFloat(newFilterApplication.institutionId) ===
            parseFloat(filterApplication.institutionId)
        ) === -1
      ) {
        filteredApplicationSet.push(filterApplication);
      }
    });
    return filteredApplicationSet;
  };

  const retrieveInstitutionDTOOptionList = () => {
    return retrieveInstitutionFilteredApplicationSet().map(
      (standardExamUserDTO) => ({
        value: standardExamUserDTO.institutionId,
        text: standardExamUserDTO.institutionName,
      })
    );
  };

  const retrieveSeasonDTOOptionList = () => {
    const filteredApplicationSet = [];
    filterAvailableApplicationUserDetailList()
      .filter(
        (standardExamUserDTO) =>
          parseFloat(standardExamUserDTO.institutionId) ===
          parseFloat(selectedInstitutionId)
      )
      .forEach((filterApplication) => {
        if (
          filteredApplicationSet.findIndex(
            (newFilterApplication) =>
              parseFloat(newFilterApplication.seasonId) ===
              parseFloat(filterApplication.seasonId)
          ) === -1
        ) {
          filteredApplicationSet.push(filterApplication);
        }
      });

    return filteredApplicationSet.map((standardExamUserDTO) => ({
      value: standardExamUserDTO.seasonId,
      text: standardExamUserDTO.seasonName,
    }));
  };

  const retrieveGradeDTOOptionList = () => {
    const filteredApplicationSet = [];
    filterAvailableApplicationUserDetailList()
      .filter(
        (standardExamUserDTO) =>
          parseFloat(standardExamUserDTO.seasonId) ===
          parseFloat(selectedSeasonId)
      )
      .forEach((filterApplication) => {
        if (
          filteredApplicationSet.findIndex(
            (newFilterApplication) =>
              parseFloat(newFilterApplication.gradeId) ===
              parseFloat(filterApplication.gradeId)
          ) === -1
        ) {
          filteredApplicationSet.push(filterApplication);
        }
      });

    return filteredApplicationSet.map((standardExamUserDTO) => ({
      value: standardExamUserDTO.gradeId,
      text: standardExamUserDTO.gradeName,
    }));
  };

  const retrieveBranchDTOOptionList = () => {
    const filteredApplicationSet = [];
    filterAvailableApplicationUserDetailList()
      .filter(
        (standardExamUserDTO) =>
          parseFloat(standardExamUserDTO.gradeId) ===
          parseFloat(selectedGradeId)
      )
      .forEach((filterApplication) => {
        if (
          filteredApplicationSet.findIndex(
            (newFilterApplication) =>
              parseFloat(newFilterApplication.branchId) ===
              parseFloat(filterApplication.branchId)
          ) === -1
        ) {
          filteredApplicationSet.push(filterApplication);
        }
      });

    return filteredApplicationSet.map((standardExamUserDTO) => ({
      value: standardExamUserDTO.branchId,
      text: standardExamUserDTO.branchName,
    }));
  };

  const findStarStudent = () => {
    const sortedStandardExamUserDTOList = JSON.parse(
      JSON.stringify(applicationUserDetailList)
    ).sort((a, b) => b.score - a.score);
    if (sortedStandardExamUserDTOList.length > 0) {
      const highestScore = sortedStandardExamUserDTOList[0].score;
      if (highestScore === 0) {
        return "-";
      } else {
        const filteredStandardExamUserDTOList =
          sortedStandardExamUserDTOList.filter(
            (standardExamUserDTO) => standardExamUserDTO.score === highestScore
          );
        filteredStandardExamUserDTOList.sort(
          (a, b) => a.timeSpent - b.timeSpent
        );
        return `${filteredStandardExamUserDTOList[0].firstName} ${filteredStandardExamUserDTOList[0].lastName}`;
      }
    }
    return "-";
  };

  const calculateAverageTimeSpent = () => {
    let currentTimeSpent = 0;
    let currentCount = 0;
    if (standardExamApplicationDetail) {
      applicationUserDetailList
        .filter(
          (standardExamUserDTO) =>
            standardExamUserDTO.completionStatus !== "NOT_STARTED"
        )
        .forEach((standardExamUserDTO) => {
          currentTimeSpent += standardExamUserDTO.timeSpent;
          currentCount++;
        });
    }
    return currentCount === 0
      ? 0
      : `${(currentTimeSpent / currentCount / 1000 / 60).toFixed(2)}`;
  };

  const calculateCompletedLearnerCount = () => {
    let notStartedCount = 0;
    if (standardExamApplicationDetail) {
      notStartedCount = applicationUserDetailList.filter(
        (standardExamUserDTO) =>
          standardExamUserDTO.completionStatus === "COMPLETED"
      ).length;
    }
    return notStartedCount;
  };

  const calculateNotStartedLearnerCount = () => {
    let notStartedCount = 0;
    if (standardExamApplicationDetail) {
      notStartedCount = applicationUserDetailList.filter(
        (standardExamUserDTO) =>
          standardExamUserDTO.completionStatus === "NOT_STARTED"
      ).length;
    }
    return notStartedCount;
  };

  const calculateActivityCount = () => {
    let activityCount = 0;
    if (standardExamApplicationDetail) {
      standardExamApplicationDetail.standardExamApplicationDTO.standardExamDTO.standardExamActivityDTOList.forEach(
        (item) => {
          activityCount += item.standardExamActivityItemDTOList.length;
        }
      );
    }
    return activityCount;
  };

  const calculateStandardExamSectionCount = () => {
    let activityTemplateCount = 0;
    if (standardExamApplicationDetail) {
      activityTemplateCount =
        standardExamApplicationDetail.standardExamApplicationDTO.standardExamDTO
          .standardExamActivityDTOList.length;
    }
    return activityTemplateCount;
  };

  const calculateScoreMoreThan = () => {
    return applicationUserDetailList.filter(
      (standardExamUserDTO) =>
        calculateUserPerformance(standardExamUserDTO) >= 80
    ).length;
  };

  const calculateUserPerformance = (standardExamUserDTO) => {
    if (
      standardExamApplicationDetail.standardExamApplicationDTO.standardExamDTO
        .standardExamType === "AYT"
    ) {
      const { score: linguisticScore, activityCount: linguisticActivityCount } =
        calculateLinguisticScoreSingleUser(standardExamUserDTO);
      const {
        score: computationalScore,
        activityCount: computationalActivityCount,
      } = calculateComputationalScoreSingleUser(standardExamUserDTO);
      const {
        score: foreignLanguageScore,
        activityCount: foreignLanguageActivityCount,
      } = calculateForeignLanguageScoreSingleUser(standardExamUserDTO);
      const scoreArray = [];
      if (linguisticActivityCount !== 0)
        scoreArray.push(linguisticScore / linguisticActivityCount);
      if (computationalActivityCount !== 0)
        scoreArray.push(computationalScore / computationalActivityCount);
      if (foreignLanguageActivityCount !== 0)
        scoreArray.push(foreignLanguageScore / foreignLanguageActivityCount);
      return Math.max(...scoreArray) * 100;
    } else {
      return (standardExamUserDTO.score / calculateActivityCount()) * 100;
    }
  };

  const calculateAverageScore = () => {
    const filteredStandardExamUserDTOList = applicationUserDetailList.filter(
      (standardExamUserDTO) =>
        standardExamUserDTO.completionStatus !== "NOT_STARTED"
    );
    let totalScore = 0;
    filteredStandardExamUserDTOList.forEach((standardExamUserDTO) => {
      totalScore += calculateUserPerformance(standardExamUserDTO);
    });
    if (filteredStandardExamUserDTOList.length === 0) {
      return 0;
    } else {
      return totalScore / filteredStandardExamUserDTOList.length;
    }
  };

  const calculateLinguisticAverageScore = () => {
    const filteredStandardExamUserDTOList = applicationUserDetailList.filter(
      (standardExamUserDTO) =>
        standardExamUserDTO.completionStatus !== "NOT_STARTED"
    );
    let totalScore = 0;
    let count = 0;
    filteredStandardExamUserDTOList.forEach((standardExamUserDTO) => {
      const { score, activityCount } =
        calculateLinguisticScoreSingleUser(standardExamUserDTO);
      if (score !== 0) {
        count++;
        totalScore += score / activityCount;
      }
    });
    if (filteredStandardExamUserDTOList.length === 0) {
      return 0;
    } else {
      if (count === 0) {
        return 0;
      }
      return (totalScore / count) * 100;
    }
  };

  const calculateLinguisticScoreSingleUser = (standardExamUserDTO) => {
    const { standardExamActivityActivityTemplateScoreDTOList } =
      standardExamUserDTO;
    const filteredItemList =
      standardExamActivityActivityTemplateScoreDTOList.filter(
        (item) =>
          item.standardExamCoterieType === "LITERATURE" ||
          item.standardExamCoterieType === "HISTORY_I" ||
          item.standardExamCoterieType === "HISTORY_II" ||
          item.standardExamCoterieType === "GEOGRAPHY_I" ||
          item.standardExamCoterieType === "GEOGRAPHY_II" ||
          item.standardExamCoterieType === "PHILOSOPHY" ||
          item.standardExamCoterieType ===
            "PHILOSOPHY_OR_CULTURE_AND_RELIGION_KNOWLEDGE"
      );
    let activityCount = 0;
    let score = 0;
    filteredItemList.forEach((item) => {
      activityCount += item.activityActivityTemplateScoreDTOList.length;
      score += item.score;
    });
    return { score, activityCount };
  };

  const calculateComputationalAverageScore = () => {
    const filteredStandardExamUserDTOList = applicationUserDetailList.filter(
      (standardExamUserDTO) =>
        standardExamUserDTO.completionStatus !== "NOT_STARTED"
    );
    let totalScore = 0;
    let count = 0;
    filteredStandardExamUserDTOList.forEach((standardExamUserDTO) => {
      const { score, activityCount } =
        calculateComputationalScoreSingleUser(standardExamUserDTO);
      if (score !== 0) {
        count++;
        totalScore += score / activityCount;
      }
    });
    if (filteredStandardExamUserDTOList.length === 0) {
      return 0;
    } else {
      if (count === 0) {
        return 0;
      }
      return (totalScore / count) * 100;
    }
  };

  const calculateComputationalScoreSingleUser = (standardExamUserDTO) => {
    const { standardExamActivityActivityTemplateScoreDTOList } =
      standardExamUserDTO;
    const filteredItemList =
      standardExamActivityActivityTemplateScoreDTOList.filter(
        (item) =>
          item.standardExamCoterieType === "MATHEMATICS" ||
          item.standardExamCoterieType === "GEOMETRY" ||
          item.standardExamCoterieType === "PHYSICS" ||
          item.standardExamCoterieType === "CHEMISTRY" ||
          item.standardExamCoterieType === "BIOLOGY"
      );
    let activityCount = 0;
    let score = 0;
    filteredItemList.forEach((item) => {
      activityCount += item.activityActivityTemplateScoreDTOList.length;
      score += item.score;
    });
    return { score, activityCount };
  };

  const calculateForeignLanguageAverageScore = () => {
    const filteredStandardExamUserDTOList = applicationUserDetailList.filter(
      (standardExamUserDTO) =>
        standardExamUserDTO.completionStatus !== "NOT_STARTED"
    );
    let totalScore = 0;
    let count = 0;
    filteredStandardExamUserDTOList.forEach((standardExamUserDTO) => {
      const { score, activityCount } =
        calculateForeignLanguageScoreSingleUser(standardExamUserDTO);
      if (score !== 0) {
        count++;
        totalScore += score / activityCount;
      }
    });
    if (filteredStandardExamUserDTOList.length === 0) {
      return 0;
    } else {
      if (count === 0) {
        return 0;
      }
      return (totalScore / count) * 100;
    }
  };

  const calculateForeignLanguageScoreSingleUser = (standardExamUserDTO) => {
    const { standardExamActivityActivityTemplateScoreDTOList } =
      standardExamUserDTO;
    const filteredItemList =
      standardExamActivityActivityTemplateScoreDTOList.filter(
        (item) => item.standardExamCoterieType === "ENGLISH"
      );
    let activityCount = 0;
    let score = 0;
    filteredItemList.forEach((item) => {
      activityCount += item.activityActivityTemplateScoreDTOList.length;
      score += item.score;
    });
    return { score, activityCount };
  };

  const calculateHighestAverageScore = () => {
    const filteredStandardExamUserDTOList = applicationUserDetailList.filter(
      (standardExamUserDTO) =>
        standardExamUserDTO.completionStatus !== "NOT_STARTED"
    );
    let totalScore = 0;
    filteredStandardExamUserDTOList.forEach((standardExamUserDTO) => {
      totalScore += calculateUserPerformance(standardExamUserDTO);
    });
    if (filteredStandardExamUserDTOList.length === 0) {
      return 0;
    } else {
      return totalScore / filteredStandardExamUserDTOList.length;
    }
  };

  const constructActivityActivityTemplateStaticticsList = () => {
    const activityStatistics = [];
    for (const standardExamUserDTO of applicationUserDetailList) {
      const {
        firstName,
        lastName,
        standardExamActivityActivityTemplateScoreDTOList,
      } = standardExamUserDTO;
      for (const standardExamActivityActivityTemplateScoreDTO of standardExamActivityActivityTemplateScoreDTOList) {
        const { coterieType, activityActivityTemplateScoreDTOList } =
          standardExamActivityActivityTemplateScoreDTO;
        for (const activityTemplateScoreDTO of activityActivityTemplateScoreDTOList) {
          const foundActivityActivityTemplateStatictics =
            activityStatistics.find(
              (activityActivityTemplateStatictics) =>
                activityActivityTemplateStatictics.id ===
                activityTemplateScoreDTO.activityId
            );
          if (foundActivityActivityTemplateStatictics) {
            const { templateList } = foundActivityActivityTemplateStatictics;
            const foundTemplate = templateList.find(
              (template) =>
                template.id === activityTemplateScoreDTO.activityTemplateId
            );
            if (foundTemplate) {
              foundTemplate.score += activityTemplateScoreDTO.score;
              foundTemplate.count += 1;
              foundTemplate.userList.push({
                firstName,
                lastName,
                score: activityTemplateScoreDTO.score,
              });
            } else {
              templateList.push({
                id: activityTemplateScoreDTO.activityTemplateId,
                name: activityTemplateScoreDTO.activityTemplateName,
                score: activityTemplateScoreDTO.score,
                isEmpty: activityTemplateScoreDTO.isEmpty,
                userList: [
                  {
                    firstName,
                    lastName,
                    score: activityTemplateScoreDTO.score,
                  },
                ],
                count: 1,
              });
            }
            foundActivityActivityTemplateStatictics.score +=
              activityTemplateScoreDTO.score;
            foundActivityActivityTemplateStatictics.count += 1;
          } else {
            activityStatistics.push({
              id: activityTemplateScoreDTO.activityId,
              name: activityTemplateScoreDTO.activityName,
              coterieType,
              templateList: [
                {
                  id: activityTemplateScoreDTO.activityTemplateId,
                  name: activityTemplateScoreDTO.activityTemplateName,
                  score: activityTemplateScoreDTO.score,
                  isEmpty: activityTemplateScoreDTO.isEmpty,
                  userList: [
                    {
                      firstName,
                      lastName,
                      score: activityTemplateScoreDTO.score,
                    },
                  ],
                  count: 1,
                },
              ],
              score: activityTemplateScoreDTO.score,
              count: 1,
            });
          }
        }
      }
    }
    for (const activityStaticticsItem of activityStatistics) {
      const { templateList } = activityStaticticsItem;
      activityStaticticsItem.score /= activityStaticticsItem.count;
      for (const template of templateList) {
        template.score /= template.count;
        template.userList.sort((a, b) => a.score - b.score);
      }
      activityStaticticsItem.templateList =
        activityStaticticsItem.templateList.sort((a, b) => a.score - b.score);
    }

    return activityStatistics.sort((a, b) => a.score - b.score);
  };

  const handleOnChange = (type, value) => {
    if (type === "institutionId") {
      setSelectedInstitutionId(value);
      setSelectedSeasonId("DEFAULT_OPTION");
      setSelectedGradeId("DEFAULT_OPTION");
      setSelectedBranchId("DEFAULT_OPTION");
    } else if (type === "seasonId") {
      setSelectedSeasonId(value);
      setSelectedGradeId("DEFAULT_OPTION");
      setSelectedBranchId("DEFAULT_OPTION");
    } else if (type === "gradeId") {
      setSelectedGradeId(value);
      setSelectedBranchId("DEFAULT_OPTION");
    } else if (type === "branchId") {
      setSelectedBranchId(value);
    } else if (type === "selectedCoterieType") {
      setSelectedCoterieType(value);
    }
  };

  const handleEvaluateStandardExamApplicationOnClick = async (id) => {
    const { data, err } = await evaluateStandardExamApplicationById(id);
    if (err) {
      console.log(err);
    } else {
      if (data === "EVALUATED") {
        retrieveAndSetStandardExamApplicationInDetail();
      }
    }
  };

  const handlePreviewActivityOnClick = (activityDTO) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    window.open(
      `${connection.app.url}/preview/activity/${activityDTO.id}/${btoa(
        encodeURI(JSON.stringify({ token: accessToken }))
      )}`
    );
  };

  const RenderRecursivelyCategoryList = (
    categoryActivityAnswerDTO,
    recursiveCount
  ) => {
    let score = 0;
    for (const activityScoreDTO of categoryActivityAnswerDTO.activityScoreDTOSet) {
      score += activityScoreDTO.score;
    }
    return (
      <div className="flex flex-col">
        <div
          className="flex-1 flex flex-row bg-catchup-dark-blue text-catchup-white border-b border-b-catchup-white"
          style={{
            paddingLeft: recursiveCount * 20,
            opacity: 1 - 0.05 * recursiveCount,
          }}
        >
          <div className="flex-1">
            <p className="p-2">{categoryActivityAnswerDTO.categoryDTO.name}</p>
          </div>
          <div
            className="flex justify-center items-center w-[75px] bg-catchup-white text-catchup-dark-blue border-r border-b border-catchup-dark-blue"
            style={{
              fontWeight: "bold",
            }}
          >
            <p className="p-2">
              {categoryActivityAnswerDTO.activityScoreDTOSet.length}
            </p>
          </div>
          <div
            className="flex justify-center items-center w-[75px] bg-catchup-white text-catchup-dark-blue border-r border-b border-catchup-dark-blue text-center"
            style={{
              fontWeight: "bold",
            }}
          >
            <p className="p-2">{score}</p>
          </div>
          <div
            className="flex justify-center items-center w-[100px] bg-catchup-white text-catchup-dark-blue border-r border-b border-catchup-dark-blue text-center"
            style={{
              fontWeight: "bold",
            }}
          >
            <p className="p-2">
              {(
                (score / categoryActivityAnswerDTO.activityScoreDTOSet.length) *
                100
              ).toFixed(2)}
              %
            </p>
          </div>
        </div>
        {categoryActivityAnswerDTO.subCategoryActivityAnswerDTOList &&
        categoryActivityAnswerDTO.subCategoryActivityAnswerDTOList.length > 0
          ? categoryActivityAnswerDTO.subCategoryActivityAnswerDTOList.map(
              (subCategoryActivityAnswerDTO) =>
                RenderRecursivelyCategoryList(
                  subCategoryActivityAnswerDTO,
                  recursiveCount + 1
                )
            )
          : null}
      </div>
    );
  };

  const RenderLearnerStandardExamReport = () => {
    if (learnerStandardExamReportLoading) {
      return (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      );
    } else {
      if (learnerStandardExamReport) {
        const { standardExamCategoryActivityAnswerDTOList } =
          learnerStandardExamReport;
        return (
          <>
            <DividerLine />
            <div className="w-full flex flex-col my-5 text-sm">
              {standardExamCategoryActivityAnswerDTOList.map(
                (standardExamCategoryActivityAnswerDTO) => (
                  <div className="flex flex-col">
                    {standardExamCategoryActivityAnswerDTO.categoryActivityAnswerDTOList.map(
                      (categoryActivityAnswerDTO) => (
                        <>
                          <div className="flex flex-col">
                            <div className="flex-1 flex flex-row bg-catchup-white font-semibold">
                              <div className="flex-1"></div>
                              <div className="w-[75px] bg-catchup-dark-blue text-catchup-white border-r border-r-catchup-white text-center">
                                <p className="p-2">#</p>
                              </div>
                              <div className="w-[75px] bg-catchup-dark-blue text-catchup-white border-r border-r-catchup-white text-center">
                                <p className="p-2">✓</p>
                              </div>
                              <div className="w-[100px] bg-catchup-dark-blue text-catchup-white border-r border-r-catchup-dark-blue text-center">
                                <p className="p-2">%</p>
                              </div>
                            </div>
                          </div>
                          {RenderRecursivelyCategoryList(
                            categoryActivityAnswerDTO,
                            0
                          )}
                        </>
                      )
                    )}
                    <DividerLine />
                  </div>
                )
              )}
            </div>
          </>
        );
      }
    }

    return null;
  };

  const RenderActivityStaticticsModal = () => {
    const coterieTypeList = [];
    for (const item of activityStatistics) {
      const { coterieType } = item;
      const foundIndex = coterieTypeList.findIndex(
        (ct) => ct.value === coterieType
      );
      if (foundIndex === -1) {
        coterieTypeList.push({
          text: i18n.t(coterieType),
          value: coterieType,
        });
      }
    }
    return (
      <>
        <div className="w-1/2 lg:w-1/3 flex flex-row">
          <div className="w-full">
            <div className="mr-2 lg:mx-2">
              <InputGroup
                type="select"
                title={i18n.t("coterie_type")}
                placeholder={null}
                value={selectedCoterieType}
                optionList={coterieTypeList}
                onChange={(event) => {
                  handleOnChange("selectedCoterieType", event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="p-5">
          <div className="flex flex-row flex-wrap">
            {activityStatistics
              .filter((item) => item.coterieType === selectedCoterieType)
              .map((activityStatisticsItem, index) => {
                const { id, name, score, templateList } =
                  activityStatisticsItem;
                return (
                  <div key={index} className="w-full xl:w-1/2">
                    <div className="mx-2">
                      <div className="flex flex-col px-4 py-2 my-2 text-lg">
                        <div className="flex flex-row justify-between">
                          <div className="flex flex-row items-center justify-start gap-x-2">
                            <div>
                              <div>
                                <p className="">{name}</p>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row items-center justify-center gap-x-1">
                            <div>
                              <p
                                className=""
                                style={{
                                  color: retrieveColorByScore(score * 100),
                                }}
                              >
                                {(score * 100).toFixed(2)}%
                              </p>
                            </div>
                            <div>
                              <BaseImage
                                src="/icons/preview.png"
                                alt="preview"
                                size="small"
                                onClick={() => {
                                  const accessToken =
                                    localStorage.getItem("accessToken");
                                  if (!accessToken) return;
                                  window.open(
                                    `${
                                      connection.app.url
                                    }/preview/activity/${id}/${btoa(
                                      encodeURI(
                                        JSON.stringify({ token: accessToken })
                                      )
                                    )}`
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col mx-5 my-2 gap-y-1">
                          {templateList.map((template) => {
                            const { name, count, score, userList } = template;
                            let nameString = "";
                            for (const user of userList) {
                              const { firstName, lastName, score } = user;
                              nameString += `${firstName} ${lastName} (${(
                                score * 100
                              ).toFixed(2)}%)<br />`;
                            }
                            return (
                              <div className="flex flex-row justify-between">
                                <ActivityTemplateLabel
                                  title={`${i18n.t(name)} (${count})`}
                                />
                                <div className="flex flex-row items-center justify-center gap-x-1">
                                  <div>
                                    <p
                                      className="font-medium"
                                      style={{
                                        color: retrieveColorByScore(
                                          score * 100
                                        ),
                                      }}
                                    >
                                      {(score * 100).toFixed(2)}%
                                    </p>
                                  </div>
                                  <Tooltip id="activity_activity_template_statistics" />
                                  <div className="">
                                    <BaseImage
                                      size="small"
                                      src="/icons/info.png"
                                      alt="info"
                                      dataToolTipId="activity_activity_template_statistics"
                                      dataToolTipHTML={nameString}
                                      dataToolTipPlace="top"
                                      dataToolTipVariant="info"
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {(activityStatistics.length % 2 === 0 &&
                        index > activityStatistics.length - 3) ||
                      (activityStatistics.length % 2 === 1 &&
                        index > activityStatistics.length - 2) ? null : (
                        <DividerLine />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </>
    );
  };

  const RenderDetailedUserUserProfile = () => {
    const foundUserUserProfile = applicationUserDetailList.find(
      (standardExamUserDTO) =>
        parseFloat(standardExamUserDTO.userId) === selectedUserId &&
        parseFloat(standardExamUserDTO.userProfileId) === selectedUserProfileId
    );
    if (!foundUserUserProfile) return;
    const {
      firstName,
      lastName,
      branchName,
      gradeName,
      seasonName,
      institutionName,
      answeredActivityCount,
      correctActivityCount,
      timeSpent,
      standardExamActivityActivityTemplateScoreDTOList,
    } = foundUserUserProfile;
    const { standardExamApplicationDTO } = standardExamApplicationDetail;
    const { standardExamDTO, isEvaluated } = standardExamApplicationDTO;
    const { standardExamActivityDTOList } = standardExamDTO;
    const standardExamActivityByCoterieType = {};
    standardExamActivityDTOList
      .sort((a, b) => a.index - b.index)
      .forEach((standardExamActivityDTO) => {
        const { standardExamActivityItemDTOList } = standardExamActivityDTO;
        const activityList = standardExamActivityItemDTOList.map(
          (item) => item.activityDTO
        );
        standardExamActivityByCoterieType[
          standardExamActivityDTO.standardExamCoterieType
        ] = activityList;
      });

    return (
      <div className="border border-catchup-blue-500 rounded-catchup-xlarge p-5 text-lg ">
        <div className="flex flex-row justify-between flex-wrap my-2 px-4">
          <div className="flex-1 flex flex-col">
            <div>
              <p className="font-semibold text-2xl">
                {firstName} {lastName}
              </p>
            </div>
            <div className="my-1 text-catchup-gray-400">
              <div className="flex flex-row flex-wrap gap-x-2 items-center">
                <p className="">{branchName}</p>
                <img
                  className="w-1 h-1"
                  src="/icons/small-dot-gray.png"
                  alt="small-dot-gray"
                />
                <p className="">{gradeName}</p>
                <img
                  className="w-1 h-1"
                  src="/icons/small-dot-gray.png"
                  alt="small-dot-gray"
                />
                <p className="">{seasonName}</p>
                <img
                  className="w-1 h-1"
                  src="/icons/small-dot-gray.png"
                  alt="small-dot-gray"
                />
                <p className="">{institutionName}</p>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-row flex-wrap justify-end items-center gap-x-5">
            {isEvaluated ? (
              <>
                <div>
                  <p>
                    {i18n.t("answered_activity_count")}:{" "}
                    <span className="font-bold">{answeredActivityCount}</span>
                  </p>
                </div>
                <div>
                  <p>
                    {i18n.t("correct_activity_count")}:{" "}
                    <span className="font-bold">{correctActivityCount}</span>
                  </p>
                </div>
                <div>
                  <p>
                    {i18n.t("time_spent")}:{" "}
                    <span className="font-bold">{`${(
                      timeSpent /
                      1000 /
                      60
                    ).toFixed(2)} ${i18n.t("minute_abbrevation")}`}</span>
                  </p>
                </div>
              </>
            ) : null}
          </div>
        </div>
        {isEvaluated ? <DividerLine /> : null}
        {isEvaluated ? (
          <div className="flex-1 flex flex-col my-3">
            {Object.keys(standardExamActivityByCoterieType).map((key) => {
              const foundStandardExamActivityActivityTemplateScoreDTO =
                standardExamActivityActivityTemplateScoreDTOList.find(
                  (item) => item.standardExamCoterieType === key
                );
              const { activityActivityTemplateScoreDTOList } =
                foundStandardExamActivityActivityTemplateScoreDTO;
              if (activityActivityTemplateScoreDTOList.length > 0) {
                return (
                  <div className="flex flex-col">
                    <p className="font-semibold">{i18n.t(key)}</p>
                    <div className="flex flex-row flex-wrap">
                      {activityActivityTemplateScoreDTOList.map(
                        (activityActivityTemplateScoreDTO, index) => {
                          const foundAnswer = JSON.parse(
                            activityActivityTemplateScoreDTO.data
                          ).find((answerData) => answerData.type === "MCSA");
                          let correctAnswer;
                          let learnerAnswer;
                          if (foundAnswer) {
                            correctAnswer = Object.keys(
                              foundAnswer.answerMap
                            )[0];
                            learnerAnswer =
                              foundAnswer.answerMap[correctAnswer];
                          }
                          return (
                            <div
                              className={`flex flex-col my-2 ${
                                activityActivityTemplateScoreDTO.isEmpty
                                  ? "border rounded-catchup-xlarge border-catchup-orange"
                                  : ""
                              } px-2 mx-2`}
                              key={index}
                            >
                              <div className="flex flex-col justify-center items-center">
                                <div className="my-1">
                                  <p>{`${index + 1}`}</p>
                                </div>
                                {activityActivityTemplateScoreDTO.score ===
                                1 ? (
                                  <div className="my-1">
                                    <BaseImage
                                      size="small"
                                      src="/icons/checkbox.png"
                                      alt="checkbox"
                                    />
                                  </div>
                                ) : activityActivityTemplateScoreDTO.isEmpty ? (
                                  <div className="my-1">
                                    <BaseImage
                                      size="small"
                                      src="/icons/checkbox-empty.png"
                                      alt="checkbox"
                                    />
                                  </div>
                                ) : (
                                  <div className="my-1">
                                    <Tooltip id="correct-incorrect-answer" />
                                    <div className="">
                                      <BaseImage
                                        size="small"
                                        src="/icons/cross-red.png"
                                        alt="cross-red"
                                      />
                                    </div>
                                  </div>
                                )}
                                <div className="my-1">
                                  <BaseImage
                                    src="/icons/preview.png"
                                    alt="preview"
                                    size="small"
                                    onClick={async () => {
                                      const { data, err } =
                                        await retrieveActivityWithDataById(
                                          activityActivityTemplateScoreDTO.activityId
                                        );
                                      if (err) {
                                      } else {
                                        const dataWithAnswer = {
                                          ...JSON.parse(data.data),
                                          answerMap: JSON.parse(
                                            activityActivityTemplateScoreDTO.data
                                          ),
                                        };
                                        window.open(
                                          `${
                                            connection.app.url
                                          }/preview/activity/by-learner-data/${btoa(
                                            encodeURI(
                                              JSON.stringify(dataWithAnswer)
                                            )
                                          ).replaceAll(
                                            "/",
                                            "CATCHUPPER_SLASH"
                                          )}`
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                );
              }
            })}

            {RenderLearnerStandardExamReport()}
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <p className="font-semibold text-catchup-red">
              {i18n.t("not_evaluated_yet")}
            </p>
            <p className="text-catchup-red italic text-md">
              {i18n.t("not_evaluated_yet_description")}
            </p>
          </div>
        )}
      </div>
    );
  };

  const RenderMainContent = () => {
    if (standardExamApplicationDetail) {
      const { standardExamApplicationDTO } = standardExamApplicationDetail;
      const { id, standardExamDTO, isEvaluated, endAt } =
        standardExamApplicationDTO;
      return (
        <FullCard isShadowed={true}>
          <div className="">
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      navigate("/standard-exams/applications");
                    }}
                  />
                </div>
                <BaseTitle title={standardExamDTO.name} />
              </div>
              {endAt > new Date().getTime() ? (
                <div className="flex flex-col items-end">
                  <p className="font-semibold text-catchup-red">
                    {i18n.t("not_evaluated_yet")}
                  </p>
                  <p className="text-catchup-red italic text-md">
                    {i18n.t("not_evaluated_yet_description")}
                  </p>
                </div>
              ) : isEvaluated ? null : (
                <div>
                  <SecondaryButton
                    title={i18n.t("evaluate")}
                    size="small"
                    onClick={async () => {
                      await handleEvaluateStandardExamApplicationOnClick(id);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <DividerLine />
          <div className="">
            <p className="font-semibold text-lg">
              {i18n.t("branch_selection")}
            </p>
          </div>

          <div className="flex flex-row flex-wrap items-center mb-3">
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("institution_name")}
                  placeholder={i18n.t("institution_name")}
                  value={selectedInstitutionId}
                  optionList={retrieveInstitutionDTOOptionList()}
                  onChange={(event) => {
                    handleOnChange("institutionId", event.target.value);
                  }}
                />
              </div>
            </div>
            {selectedInstitutionId !== "DEFAULT_OPTION" ? (
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("season_name")}
                    placeholder={i18n.t("season_name")}
                    value={selectedSeasonId}
                    optionList={retrieveSeasonDTOOptionList()}
                    onChange={(event) => {
                      handleOnChange("seasonId", event.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}

            {selectedSeasonId !== "DEFAULT_OPTION" ? (
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("grade_name")}
                    placeholder={i18n.t("grade_name")}
                    value={selectedGradeId}
                    optionList={retrieveGradeDTOOptionList()}
                    onChange={(event) => {
                      handleOnChange("gradeId", event.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}

            {selectedGradeId !== "DEFAULT_OPTION" ? (
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("branch_name")}
                    placeholder={i18n.t("branch_name")}
                    value={selectedBranchId}
                    optionList={retrieveBranchDTOOptionList()}
                    onChange={(event) => {
                      handleOnChange("branchId", event.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="">
            <p className="font-semibold text-lg">
              {i18n.t("general_overview")}
            </p>
          </div>
          <div className="flex flex-row items-center flex-wrap">
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderInformationCardItem(
                i18n.t("activity_count"),
                null,
                <BaseImage
                  src="/icons/activity.png"
                  alt="activity"
                  size="small"
                />,
                calculateActivityCount(),
                1
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderInformationCardItem(
                i18n.t("standard_exam_section_count"),
                null,
                <BaseImage
                  src="/icons/standard-exam.png"
                  alt="standard-exam"
                  size="small"
                />,
                calculateStandardExamSectionCount(),
                2
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderInformationCardItem(
                i18n.t("completed_learner_count"),
                null,
                <BaseImage
                  src="/icons/user-profile-check.png"
                  alt="user-profile-check"
                  size="small"
                />,
                calculateCompletedLearnerCount(),
                3
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderInformationCardItem(
                i18n.t("not_started_learner_count"),
                null,
                <BaseImage
                  src="/icons/user-profile-exclamation.png"
                  alt="user-profile-exclamation"
                  size="small"
                />,
                calculateNotStartedLearnerCount(),
                4
              )}
            </div>
            {isEvaluated ? (
              <>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("star_learner"),
                    null,
                    <BaseImage src="/icons/star.png" alt="star" size="small" />,
                    findStarStudent(),
                    1
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("high_performance_learner_count"),
                    null,
                    <BaseImage
                      src="/icons/learner.png"
                      alt="learner"
                      size="small"
                    />,
                    calculateScoreMoreThan(),
                    2
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("average_time_spent"),
                    null,
                    <BaseImage
                      src="/icons/average-time-spent.png"
                      alt="average-time-spent"
                      size="small"
                    />,
                    `${calculateAverageTimeSpent()} ${i18n.t(
                      "minute_abbrevation"
                    )}`,
                    3
                  )}
                </div>
                {standardExamDTO.standardExamType === "AYT" ? (
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("highest_average_performance"),
                      null,
                      <BaseImage
                        src="/icons/learner.png"
                        alt="learner"
                        size="small"
                      />,
                      `${calculateHighestAverageScore().toFixed(2)}%`,
                      4
                    )}
                  </div>
                ) : (
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("average_performance"),
                      null,
                      <BaseImage
                        src="/icons/average-performance.png"
                        alt="average-performance"
                        size="small"
                      />,
                      `${calculateAverageScore().toFixed(2)}%`,
                      4
                    )}
                  </div>
                )}
                {standardExamDTO.standardExamType === "AYT" ? (
                  <>
                    <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                      {RenderInformationCardItem(
                        i18n.t("linguistic_average_performance"),
                        <BaseImage
                          src="/icons/average-performance.png"
                          alt="average-performance"
                          size="small"
                        />,
                        null,
                        `${calculateLinguisticAverageScore().toFixed(2)}%`,
                        1
                      )}
                    </div>
                    <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                      {RenderInformationCardItem(
                        i18n.t("computational_average_performance"),
                        <BaseImage
                          src="/icons/average-performance.png"
                          alt="average-performance"
                          size="small"
                        />,
                        null,
                        `${calculateComputationalAverageScore().toFixed(2)}%`,
                        2
                      )}
                    </div>
                    <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                      {RenderInformationCardItem(
                        i18n.t("foreign_language_average_performance"),
                        <BaseImage
                          src="/icons/average-performance.png"
                          alt="average-performance"
                          size="small"
                        />,
                        null,
                        `${calculateForeignLanguageAverageScore().toFixed(2)}%`,
                        3
                      )}
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
          </div>
          {isEvaluated ? (
            <div className="w-full mt-5">
              <SelectionTab
                optionList={[
                  {
                    id: "LEARNER",
                    title: i18n.t("learners"),
                  },
                  {
                    id: "ACTIVITY_STATISTICS",
                    title: i18n.t("activity_statistics"),
                  },
                ]}
                selectedId={selectedInformationState}
                handleSelectOnClick={(selectedId) => {
                  setSelectedInformationState(selectedId);
                }}
              />
            </div>
          ) : null}

          {selectedInformationState === "LEARNER" ? (
            <>
              <div className="flex flex-row items-start flex-wrap gap-x-3 mt-5">
                {applicationUserDetailList.map((standardExamUserDTO, index) => (
                  <div
                    key={index}
                    className={`${
                      selectedUserId === standardExamUserDTO.userId
                        ? "border-catchup-blue-800"
                        : "border-catchup-gray-50"
                    } relative w-[150px] lg:w-[200px] min-h-[220px] flex flex-col items-center justify-start my-3 p-3 cursor-pointer border rounded-catchup-xlarge`}
                    onClick={() => {
                      setSelectedUserId(standardExamUserDTO.userId);
                      setSelectedUserProfileId(
                        standardExamUserDTO.userProfileId
                      );
                      setShowDetailedUserUserProfileModal(true);
                    }}
                  >
                    <div
                      className={`absolute ${
                        standardExamUserDTO.completionStatus === "TIME_UP"
                          ? "bg-catchup-light-red border-catchup-red text-catchup-red"
                          : standardExamUserDTO.completionStatus ===
                            "NOT_STARTED"
                          ? "bg-catchup-light-red border-catchup-red text-catchup-red"
                          : standardExamUserDTO.completionStatus === "ON_GOING"
                          ? "bg-catchup-blue-50 border-catchup-blue-400 text-catchup-blue-400"
                          : standardExamUserDTO.completionStatus === "ON_GOING"
                          ? ""
                          : "bg-catchup-light-green border-catchup-green text-catchup-green"
                      } -top-4 bg-catchup-white border rounded-catchup-3xlarge px-4`}
                    >
                      <p className="text-center mt-1">
                        {i18n.t(standardExamUserDTO.completionStatus)}
                      </p>
                    </div>
                    <div className="mt-4 mb-2">
                      <BaseImage
                        size="xlarge"
                        src={
                          standardExamUserDTO.completionStatus === "TIME_UP"
                            ? "/icons/user-profile-time-up.png"
                            : standardExamUserDTO.completionStatus ===
                              "NOT_STARTED"
                            ? "/icons/user-profile-not-started.png"
                            : standardExamUserDTO.completionStatus ===
                              "ON_GOING"
                            ? "/icons/user-profile-started.png"
                            : standardExamUserDTO.completionStatus ===
                              "COMPLETED"
                            ? "/icons/user-profile-completed.png"
                            : null
                        }
                        alt="user-profile-completion-status"
                      />
                    </div>

                    <div className="my-2">
                      <p className="text-center font-semibold">
                        {standardExamUserDTO.firstName}{" "}
                        {standardExamUserDTO.lastName}
                      </p>
                      <p className="text-center text-base text-catchup-gray-400">
                        {standardExamUserDTO.profileName}
                      </p>

                      {isEvaluated ? (
                        <>
                          <div className="flex flex-col justify-center items-center">
                            <div className="flex flex-row items-center gap-x-1">
                              <BaseImage
                                src="/icons/timer-gray.png"
                                alt="timer"
                                size="xsmall"
                              />
                              <div className="flex-1">
                                <p className="text-center">
                                  {`${(
                                    standardExamUserDTO.timeSpent /
                                    1000 /
                                    60
                                  ).toFixed(2)} ${i18n.t(
                                    "minute_abbrevation"
                                  )}`}
                                </p>
                              </div>
                            </div>
                            <DividerLine />
                            <div>
                              <p
                                className="text-center text-2xl"
                                style={{
                                  color: retrieveColorByScore(
                                    calculateUserPerformance(
                                      standardExamUserDTO
                                    )
                                  ),
                                }}
                              >
                                {standardExamUserDTO.score > 0
                                  ? `${calculateUserPerformance(
                                      standardExamUserDTO
                                    ).toFixed(2)}%`
                                  : "0.00%"}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
              {RenderDetailedUserUserProfile()}
            </>
          ) : selectedInformationState === "ACTIVITY_STATISTICS" ? (
            RenderActivityStaticticsModal()
          ) : null}
        </FullCard>
      );
    } else {
      return (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      );
    }
  };

  const applicationUserDetailList = filterSelectedApplicationUserDetailList();
  const activityStatistics = constructActivityActivityTemplateStaticticsList();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default StandardExamApplicationDetailScreen;
