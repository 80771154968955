import { useEffect, useState } from "react";
import { useAuth } from "../../context/UserContextProvider";
import { useApp } from "../../context/AppContextProvider";
import {
  queryCampusListByBrandId,
  queryInstitutionListByCampusId,
  queryUserLoginDTOByParams,
} from "../../requests/ManagementRequests";
import FullCard from "../../components/cards/FullCard";
import i18n from "../../language/i18n";
import InputGroup from "../../components/groups/InputGroup";
import DividerLine from "../../components/divider/DividerLine";
import RenderInformationCardItem from "../../components/cards/InformationCard";
import { Line } from "react-chartjs-2";
import {
  retrieveBrandDTOOptionList,
  retrieveCampusDTOOptionList,
  retrieveInstitutionDTOOptionList,
  retrieveUserRoleOptionList,
} from "../../utilization/ManagementUtilization";
import {
  ONE_DAY,
  ONE_HOUR,
  ONE_MONTH,
  ONE_WEEK,
  THREE_MONTHS,
  retrieveDateIntervalOptionList,
} from "../../utilization/DateUtilization";
import SingleInformationIconTextItem from "../../components/infos/SingleInformationIconTextItem";
import BaseImage from "../../components/images/BaseImage";
import BaseLoading from "../../components/loading/BaseLoading";

const UserLoginVisualizerScreen = () => {
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    language,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
  } = useAuth();
  const [userLoginList, setUserLoginList] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState(userProfileBrand.id);
  const [selectedCampusId, setSelectedCampusId] = useState(
    userProfileCampus ? userProfileCampus.id : "DEFAULT_OPTION"
  );
  const [selectedInstitutionId, setSelectedInstitutionId] = useState(
    userProfileInstitution ? userProfileInstitution.id : "DEFAULT_OPTION"
  );
  const [brandDTOList, setBrandDTOList] = useState([userProfileBrand]);
  const [campusDTOList, setCampusDTOList] = useState([]);
  const [institutionDTOList, setInstitutionDTOList] = useState([]);
  const [selectedDateInterval, setSelectedDateInterval] = useState("LAST_DAY");
  const [selectedRole, setSelectedRole] = useState("STAFF");
  const [selectedUserId, setSelectedUserId] = useState(-1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetUserLoginList = async () => {
      setLoading(true);
      const { data, err } = await queryUserLoginDTOByParams({
        brandId: selectedBrandId,
        campusId:
          selectedCampusId !== "DEFAULT_OPTION" ? selectedCampusId : null,
        institutionId:
          selectedInstitutionId !== "DEFAULT_OPTION"
            ? selectedInstitutionId
            : null,
      });
      if (err) {
        console.log(err);
        return;
      }
      setUserLoginList(data);
      setLoading(false);
    };

    if (selectedBrandId !== "DEFAULT_OPTION") {
      retrieveAndSetUserLoginList();
    } else {
      setUserLoginList([]);
    }
  }, [selectedBrandId, selectedCampusId, selectedInstitutionId]);

  useEffect(() => {
    if (selectedBrandId !== "DEFAULT_OPTION") {
      if (userProfileCampus) {
        setCampusDTOList([userProfileCampus]);
      } else {
        retrieveAndSetCampusDTOListByBrandId();
      }
    } else {
      setSelectedCampusId("DEFAULT_OPTION");
      setCampusDTOList([]);
    }
  }, [selectedBrandId]);

  useEffect(() => {
    if (selectedCampusId !== "DEFAULT_OPTION") {
      if (userProfileInstitution) {
        setInstitutionDTOList([userProfileInstitution]);
      } else {
        retrieveAndSetInstitutionDTOListByCampusId();
      }
    } else {
      setSelectedInstitutionId("DEFAULT_OPTION");
      setInstitutionDTOList([]);
    }
  }, [selectedCampusId]);

  useEffect(() => {
    setSelectedUserId(-1);
  }, [selectedRole]);

  const retrieveAndSetCampusDTOListByBrandId = async () => {
    const { data, err } = await queryCampusListByBrandId(selectedBrandId);
    if (err) {
      console.log(err);
    } else {
      setCampusDTOList(data);
    }
  };

  const retrieveAndSetInstitutionDTOListByCampusId = async () => {
    const { data, err } = await queryInstitutionListByCampusId(
      selectedCampusId
    );
    if (err) {
      console.log(err);
    } else {
      setInstitutionDTOList(data);
    }
  };

  const retrieveDayIntervalArray = () => {
    const intervalArray = [];
    if (selectedDateInterval === "LAST_DAY") {
      for (let i = 22; i >= -1; i--) {
        const iHourAgo = new Date().getTime() - i * ONE_HOUR;
        const currentTimestamp = new Date(
          new Date(iHourAgo).getFullYear(),
          new Date(iHourAgo).getUTCMonth(),
          new Date(iHourAgo).getDate(),
          new Date(iHourAgo).getHours(),
          0,
          0
        ).getTime();
        intervalArray.push({
          timestamp: currentTimestamp,
        });
      }
    } else if (selectedDateInterval === "LAST_WEEK") {
      for (let i = 166; i >= -1; i--) {
        const iHourAgo = new Date().getTime() - i * ONE_HOUR;
        const currentTimestamp = new Date(
          new Date(iHourAgo).getFullYear(),
          new Date(iHourAgo).getUTCMonth(),
          new Date(iHourAgo).getDate(),
          new Date(iHourAgo).getHours(),
          0,
          0
        ).getTime();
        intervalArray.push({
          timestamp: currentTimestamp,
        });
      }
    } else if (selectedDateInterval === "LAST_MONTH") {
      for (let i = 28; i >= -1; i--) {
        const iDayAgo = new Date().getTime() - i * ONE_DAY;
        const currentTimestamp = new Date(
          new Date(iDayAgo).getFullYear(),
          new Date(iDayAgo).getUTCMonth(),
          new Date(iDayAgo).getDate(),
          0,
          0,
          0
        ).getTime();
        intervalArray.push({
          timestamp: currentTimestamp,
        });
      }
    } else if (selectedDateInterval === "LAST_THREE_MONTHS") {
      for (let i = 88; i >= -1; i--) {
        const iDayAgo = new Date().getTime() - i * ONE_DAY;
        const currentTimestamp = new Date(
          new Date(iDayAgo).getFullYear(),
          new Date(iDayAgo).getUTCMonth(),
          new Date(iDayAgo).getDate(),
          0,
          0,
          0
        ).getTime();
        intervalArray.push({
          timestamp: currentTimestamp,
        });
      }
    }
    return intervalArray;
  };

  const retrieveActiveUserCountLineOptions = () => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: i18n.t("line_graphic_active_user_count"),
        },
      },
    };
  };

  const filterByTimeInterval = () => {
    if (selectedDateInterval === "LAST_DAY") {
      return userLoginList.filter(
        (userLogin) => userLogin.beginAt > new Date().getTime() - ONE_DAY
      );
    } else if (selectedDateInterval === "LAST_WEEK") {
      return userLoginList.filter(
        (userLogin) => userLogin.beginAt > new Date().getTime() - ONE_WEEK
      );
    } else if (selectedDateInterval === "LAST_MONTH") {
      return userLoginList.filter(
        (userLogin) => userLogin.beginAt > new Date().getTime() - ONE_MONTH
      );
    } else if (selectedDateInterval === "LAST_THREE_MONTHS") {
      return userLoginList.filter(
        (userLogin) => userLogin.beginAt > new Date().getTime() - THREE_MONTHS
      );
    }
    return [];
  };

  const filterUserRoleOptionList = () => {
    return retrieveUserRoleOptionList().filter(
      (userRoleOption) => userRoleOption.value !== "PARENT"
    );
  };

  const filterUserOptionList = () => {
    const userOptionList = [
      {
        value: -1,
        text: i18n.t("all"),
      },
    ];
    userLoginList.forEach((userLogin) => {
      const { userProfileDTO, role } = userLogin;
      const { name, userDTO } = userProfileDTO;
      const { id, firstName, lastName, accountType } = userDTO;
      if (role === selectedRole && accountType !== "GENIXO") {
        const foundIndex = userOptionList.findIndex(
          (userOption) => userOption.value === id
        );
        if (foundIndex === -1) {
          userOptionList.push({
            value: id,
            text: `${firstName} ${lastName} (${name})`,
          });
        }
      }
    });
    return userOptionList;
  };

  const constructedActiveUserCountLineData = () => {
    const data = [];
    for (let i = 0; i < dayIntervalArray.length - 1; i++) {
      let beginAt = dayIntervalArray[i].timestamp;
      let endAt = dayIntervalArray[i + 1].timestamp;

      let filteredUserLoginList = filteredByTimeInterval
        .filter(
          (userLogin) =>
            userLogin.role === selectedRole &&
            userLogin.userProfileDTO.userDTO.accountType !== "GENIXO"
        )
        .filter(
          (userLogin) =>
            (userLogin.beginAt < beginAt && userLogin.endAt > beginAt) ||
            (userLogin.beginAt < endAt && userLogin.endAt > endAt) ||
            (userLogin.beginAt > beginAt && userLogin.endAt < endAt)
        );
      if (selectedUserId !== -1) {
        filteredUserLoginList = filteredUserLoginList.filter(
          (userLogin) => userLogin.userProfileDTO.userDTO.id === selectedUserId
        );
      }
      let distinctUserList = [];
      filteredUserLoginList.forEach((userLogin) => {
        const foundDistinctUser = distinctUserList.find(
          (distinctUser) =>
            distinctUser.userProfileId === userLogin.userProfileDTO.id
        );
        if (!foundDistinctUser) {
          distinctUserList.push({
            userProfileId: userLogin.userProfileDTO.id,
          });
        }
      });
      data.push(distinctUserList.length);
    }

    return data;
  };

  const retriveActiveUserCountLineData = () => {
    const labels = dayIntervalArray.map((dayInterval) =>
      new Date(dayInterval.timestamp).toLocaleString(language)
    );
    return {
      labels,
      datasets: [
        {
          label: i18n.t(selectedRole),
          data: constructedActiveUserCountLineData(),
          borderColor: "rgb(17, 72, 89)",
          backgroundColor: "rgba(17, 72, 89, 0.5)",
        },
      ],
    };
  };

  const retrieveTimeSpentLineOptions = () => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: i18n.t("line_graphic_time_spent"),
        },
      },
    };
  };

  const constructedTimeSpentLineData = () => {
    const timeSpentData = [];
    for (let i = 0; i < dayIntervalArray.length - 1; i++) {
      let filteredUserLoginList = filteredByTimeInterval
        .filter(
          (userLogin) =>
            userLogin.role === selectedRole &&
            userLogin.userProfileDTO.userDTO.accountType !== "GENIXO"
        )
        .filter(
          (userLogin) =>
            (userLogin.beginAt < dayIntervalArray[i].timestamp &&
              userLogin.endAt > dayIntervalArray[i].timestamp) ||
            (userLogin.beginAt < dayIntervalArray[i + 1].timestamp &&
              userLogin.endAt > dayIntervalArray[i + 1].timestamp) ||
            (userLogin.beginAt > dayIntervalArray[i].timestamp &&
              userLogin.endAt < dayIntervalArray[i + 1].timestamp)
        );
      if (selectedUserId !== -1) {
        filteredUserLoginList = filteredUserLoginList.filter(
          (userLogin) => userLogin.userProfileDTO.userDTO.id === selectedUserId
        );
      }
      const distinctUserList = [];
      let timeSpent = 0;
      filteredUserLoginList.forEach((userLogin) => {
        let beginAt = userLogin.beginAt;
        if (userLogin.beginAt < dayIntervalArray[i].timestamp) {
          beginAt = dayIntervalArray[i].timestamp;
        }
        let endAt = userLogin.endAt;
        if (userLogin.endAt > dayIntervalArray[i + 1].timestamp) {
          endAt = dayIntervalArray[i + 1].timestamp;
        }
        timeSpent += endAt - beginAt;
        const foundDistinctUser = distinctUserList.find(
          (distinctUser) =>
            distinctUser.userProfileId === userLogin.userProfileDTO.id
        );
        if (!foundDistinctUser) {
          distinctUserList.push({
            userProfileId: userLogin.userProfileDTO.id,
          });
        }
      });
      if (distinctUserList.length === 0) {
        timeSpentData.push(0);
      } else {
        timeSpentData.push(timeSpent / distinctUserList.length / 1000 / 60);
      }
    }

    return { timeSpentData };
  };

  const retrieveTimeSpentLineData = () => {
    const labels = dayIntervalArray.map((dayInterval) =>
      new Date(dayInterval.timestamp).toLocaleString(language)
    );
    console.log(timeSpentData);
    return {
      labels,
      datasets: [
        {
          label: i18n.t(selectedRole),
          data: timeSpentData,
          borderColor: "rgb(17, 72, 89)",
          backgroundColor: "rgba(17, 72, 89, 0.5)",
        },
      ],
    };
  };

  const calculateAverageTimeSpent = () => {
    let averageTimeSpent = 0;
    const userIdArray = [];
    filteredByTimeInterval
      .filter(
        (userLogin) =>
          userLogin.role === selectedRole &&
          userLogin.userProfileDTO.userDTO.accountType !== "GENIXO"
      )
      .forEach((userLogin) => {
        const currentTimestamp = userLogin.endAt - userLogin.beginAt;
        averageTimeSpent += currentTimestamp;
        if (!userIdArray.includes(userLogin.userProfileDTO.id)) {
          userIdArray.push(userLogin.userProfileDTO.id);
        }
      });
    if (userIdArray.length === 0) {
      return 0;
    }
    return averageTimeSpent / userIdArray.length / 1000 / 60;
  };

  const calculateMostTimeSpent = () => {
    const timeSpentList = [];
    filteredByTimeInterval
      .filter(
        (userLogin) =>
          userLogin.role === selectedRole &&
          userLogin.userProfileDTO.userDTO.accountType !== "GENIXO"
      )
      .forEach((userLogin) => {
        const currentTimestamp = userLogin.endAt - userLogin.beginAt;
        const foundTimeSpent = timeSpentList.find(
          (timeSpent) => timeSpent.userProfileId === userLogin.userProfileDTO.id
        );
        if (foundTimeSpent) {
          foundTimeSpent.timeSpent += currentTimestamp;
        } else {
          timeSpentList.push({
            userProfileId: userLogin.userProfileDTO.id,
            firstName: userLogin.userProfileDTO.userDTO.firstName,
            lastName: userLogin.userProfileDTO.userDTO.lastName,
            timeSpent: currentTimestamp,
          });
        }
      });

    if (
      filteredByTimeInterval.filter(
        (userLogin) =>
          userLogin.role === selectedRole &&
          userLogin.userProfileDTO.userDTO.accountType !== "GENIXO"
      ).length > 0
    ) {
      const sortedTimeSpentList = timeSpentList.sort(
        (a, b) => b.timeSpent - a.timeSpent
      );
      return {
        timeSpent: sortedTimeSpentList[0].timeSpent / 1000 / 60,
        firstName: sortedTimeSpentList[0].firstName,
        lastName: sortedTimeSpentList[0].lastName,
      };
    }
    return { timeSpent: 0, firstName: "-", lastName: "" };
  };

  const calculateMostLogin = () => {
    const loginList = [];
    filteredByTimeInterval
      .filter(
        (userLogin) =>
          userLogin.role === selectedRole &&
          userLogin.userProfileDTO.userDTO.accountType !== "GENIXO"
      )
      .forEach((userLogin) => {
        const foundLogin = loginList.find(
          (login) => login.userProfileId === userLogin.userProfileDTO.id
        );
        if (foundLogin) {
          foundLogin.count += 1;
        } else {
          loginList.push({
            userProfileId: userLogin.userProfileDTO.id,
            firstName: userLogin.userProfileDTO.userDTO.firstName,
            lastName: userLogin.userProfileDTO.userDTO.lastName,
            count: 1,
          });
        }
      });

    if (
      filteredByTimeInterval.filter(
        (userLogin) =>
          userLogin.role === selectedRole &&
          userLogin.userProfileDTO.userDTO.accountType !== "GENIXO"
      ).length > 0
    ) {
      const sortedLoginList = loginList.sort((a, b) => b.count - a.count);
      return {
        count: sortedLoginList[0].count,
        firstName: sortedLoginList[0].firstName,
        lastName: sortedLoginList[0].lastName,
      };
    }
    return { count: 0, firstName: "-", lastName: "" };
  };

  const handleOnChange = (type, value) => {
    if (type === "brandId") {
      setSelectedBrandId(value);
    } else if (type === "campusId") {
      setSelectedCampusId(value);
    } else if (type === "institutionId") {
      setSelectedInstitutionId(value);
    } else if (type === "date_interval") {
      setSelectedDateInterval(value);
    } else if (type === "role") {
      setSelectedRole(value);
    } else if (type === "userId") {
      setSelectedUserId(value);
    }
  };

  const RenderMainContent = () => {
    return (
      <FullCard isShadowed={true}>
        {SingleInformationIconTextItem(
          i18n.t(
            "specifically_observe_from_the_selected_management_item_point_of_view"
          )
        )}
        <div className="flex flex-row flex-wrap items-center mb-3">
          <div className="w-1/2 lg:w-1/3">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("brand_name")}
                placeholder={i18n.t("brand_name")}
                value={selectedBrandId}
                optionList={retrieveBrandDTOOptionList(brandDTOList)}
                onChange={(event) => {
                  handleOnChange("brandId", event.target.value);
                }}
              />
            </div>
          </div>
          {selectedBrandId !== "DEFAULT_OPTION" ? (
            <div className="w-1/2 lg:w-1/3">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("campus_name")}
                  placeholder={i18n.t("campus_name")}
                  value={selectedCampusId}
                  optionList={retrieveCampusDTOOptionList(campusDTOList)}
                  onChange={(event) => {
                    handleOnChange("campusId", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}

          {selectedCampusId !== "DEFAULT_OPTION" ? (
            <div className="w-1/2 lg:w-1/3">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("institution_name")}
                  placeholder={i18n.t("institution_name")}
                  value={selectedInstitutionId}
                  optionList={retrieveInstitutionDTOOptionList(
                    institutionDTOList
                  )}
                  onChange={(event) => {
                    handleOnChange("institutionId", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
        <div className="flex flex-row items-center mb-3">
          <div className="w-1/2 lg:w-1/3">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("date_interval")}
                placeholder={i18n.t("date_interval")}
                value={selectedDateInterval}
                optionList={retrieveDateIntervalOptionList()}
                onChange={(event) => {
                  handleOnChange("date_interval", event.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-1/2 lg:w-1/3">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("role")}
                placeholder={i18n.t("role")}
                value={selectedRole}
                optionList={filterUserRoleOptionList()}
                onChange={(event) => {
                  handleOnChange("role", event.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-1/2 lg:w-1/3">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("user")}
                placeholder={i18n.t("user")}
                value={selectedUserId}
                optionList={filterUserOptionList()}
                onChange={(event) => {
                  handleOnChange("userId", event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <DividerLine />

        {loading ? (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <>
            {selectedRole !== "DEFAULT_OPTION" ? (
              <>
                <div className="flex flex-row flex-wrap">
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("average_time_spent"),

                      `(${i18n.t(selectedRole)})`,
                      <BaseImage
                        src="/icons/average-time-spent.png"
                        alt="average-time-spent"
                        size="small"
                      />,
                      `${averageTimeSpent.toFixed(2)} ${i18n.t(
                        "minute_abbrevation"
                      )}`,
                      1
                    )}
                  </div>
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("most_time_spent"),
                      `(${i18n.t(selectedRole)})`,
                      <BaseImage
                        src="/icons/most-time-spent.png"
                        alt="most-time-spent"
                        size="small"
                      />,
                      `${mostTimeSpent.timeSpent.toFixed(2)} ${i18n.t(
                        "minute_abbrevation"
                      )}`,
                      2
                    )}
                  </div>
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("most_time_spent"),

                      `(${i18n.t(selectedRole)})`,
                      <BaseImage
                        src="/icons/most-time-spent.png"
                        alt="most-time-spent"
                        size="small"
                      />,
                      `${mostTimeSpent.firstName} ${mostTimeSpent.lastName}`,
                      3
                    )}
                  </div>
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("most_login"),
                      `(${i18n.t(selectedRole)})`,
                      <BaseImage
                        src="/icons/most-login.png"
                        alt="most-login"
                        size="small"
                      />,
                      `${mostLogin.firstName} ${mostLogin.lastName} (${mostLogin.count})`,
                      4
                    )}
                  </div>
                </div>
                {selectedUserId === -1 ? (
                  <>
                    <DividerLine />
                    <Line
                      options={retrieveActiveUserCountLineOptions()}
                      data={retriveActiveUserCountLineData()}
                    />
                  </>
                ) : null}

                <DividerLine />
                <Line
                  options={retrieveTimeSpentLineOptions()}
                  data={retrieveTimeSpentLineData()}
                />
              </>
            ) : null}
          </>
        )}
      </FullCard>
    );
  };

  const dayIntervalArray = retrieveDayIntervalArray();
  const filteredByTimeInterval = filterByTimeInterval();
  const { timeSpentData } = constructedTimeSpentLineData();
  const averageTimeSpent = calculateAverageTimeSpent();
  const mostTimeSpent = calculateMostTimeSpent();
  const mostLogin = calculateMostLogin();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default UserLoginVisualizerScreen;
