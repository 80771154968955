import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Server
 */
export const checkNotificationService = async () => {
  try {
    const response = await axios
      .create()
      .get(
        `${connection.base.notification.url}${endpoints.notifications.context}${endpoints.notifications.actuator.health.context}`
      );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Notification
 */
export const queryAnnouncementListByUserIdAndUserProfileId = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.notification.url}${endpoints.notifications.announcements.context}${endpoints.notifications.announcements.query["by-userId-userProfileId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createAnnouncement = async (newAnnouncement) => {
  try {
    const response = await axios.post(
      `${connection.base.notification.url}${endpoints.notifications.announcements.context}/`,
      newAnnouncement
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchAnnouncement = async (patchAnnouncement) => {
  try {
    const response = await axios.patch(
      `${connection.base.notification.url}${endpoints.notifications.announcements.context}/`,
      patchAnnouncement
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteAnnouncement = async (notificationId) => {
  try {
    const response = await axios.delete(
      `${connection.base.notification.url}${endpoints.notifications.announcements.context}/${notificationId}`
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

export const queryAllAnnouncementDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.notification.url}${endpoints.notifications.announcements.context}${endpoints.notifications.announcements.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Announcement Action
 */
export const retrieveStaffNumberOfUnReadAnnouncements = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.notification.url}${endpoints.notifications.announcements.actions.context}${endpoints.notifications.announcements.actions.staff["number-of-unread"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createAnnouncementActionByAnnouncementId = async (
  newAnnouncementAction
) => {
  try {
    const response = await axios.post(
      `${connection.base.notification.url}${endpoints.notifications.announcements.actions.context}${endpoints.notifications.announcements.actions["by-announcementId"].context}`,
      newAnnouncementAction
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryStaffAnnouncementDTOList = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.notification.url}${endpoints.notifications.announcements.actions.context}${endpoints.notifications.announcements.actions.staff.query.dto.context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
