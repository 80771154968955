import DividerLine from "../divider/DividerLine";
import PrimaryButton from "../buttons/PrimaryButton";
import i18n from "../../language/i18n";
import BaseTitle from "../titles/BaseTitle";
import BaseLoading from "../loading/BaseLoading";

const GeneralParticipationReportDetail = (props) => {
  const {
    language,
    isShadowed,
    usePadding,
    showTitle,
    reportDetailList,
    reportDetailLoading,
    subType,
    handleGeneratePDF,
  } = props;

  const combineCatchtivityApplicationBasedOnActivityId = (
    singleParticipationDetailList,
    totalCount
  ) => {
    const combinedSingleParticipationDetailList = [];
    singleParticipationDetailList.forEach((singleParticipationDetail) => {
      const foundCombinedSingleParticipationDetail =
        combinedSingleParticipationDetailList.find(
          (combinedSingleParticipationDetail) =>
            parseFloat(
              combinedSingleParticipationDetail.catchtivityApplicationId
            ) === parseFloat(singleParticipationDetail.catchtivityApplicationId)
        );
      if (foundCombinedSingleParticipationDetail) {
        foundCombinedSingleParticipationDetail.count +=
          singleParticipationDetail.count;
        foundCombinedSingleParticipationDetail.totalCount += totalCount;
      } else {
        combinedSingleParticipationDetailList.push({
          beginAt: singleParticipationDetail.beginAt,
          endAt: singleParticipationDetail.endAt,
          activityId: singleParticipationDetail.activityId,
          catchtivityName: singleParticipationDetail.catchtivityName,
          catchtivityApplicationId:
            singleParticipationDetail.catchtivityApplicationId,
          count: singleParticipationDetail.count,
          totalCount,
        });
      }
    });
    return combinedSingleParticipationDetailList.map(
      (combinedSingleParticipationDetail) => ({
        ...combinedSingleParticipationDetail,
        count:
          combinedSingleParticipationDetail.count /
          combinedSingleParticipationDetail.totalCount,
      })
    );
  };

  const combineCatchxamApplicationBasedOnActivityId = (
    singleParticipationDetailList,
    totalCount
  ) => {
    const combinedSingleParticipationDetailList = [];
    singleParticipationDetailList.forEach((singleParticipationDetail) => {
      const foundCombinedSingleParticipationDetail =
        combinedSingleParticipationDetailList.find(
          (combinedSingleParticipationDetail) =>
            parseFloat(
              combinedSingleParticipationDetail.catchxamApplicationId
            ) === parseFloat(singleParticipationDetail.catchxamApplicationId)
        );
      if (foundCombinedSingleParticipationDetail) {
        foundCombinedSingleParticipationDetail.count +=
          singleParticipationDetail.count;
        foundCombinedSingleParticipationDetail.totalCount += totalCount;
      } else {
        combinedSingleParticipationDetailList.push({
          beginAt: singleParticipationDetail.beginAt,
          endAt: singleParticipationDetail.endAt,
          catchxamName: singleParticipationDetail.catchxamName,
          catchxamApplicationId:
            singleParticipationDetail.catchxamApplicationId,
          count: singleParticipationDetail.count,
          totalCount,
        });
      }
    });
    return combinedSingleParticipationDetailList.map(
      (combinedSingleParticipationDetail) => ({
        ...combinedSingleParticipationDetail,
        count:
          combinedSingleParticipationDetail.count /
          combinedSingleParticipationDetail.totalCount,
      })
    );
  };

  const RenderCommonParticipationReport = (reportDetail) => {
    return (
      <>
        <DividerLine />
        <div className="w-full flex flex-col my-5 text-md">
          {reportDetail.participationCatchtivityCatchxamApplicationMap
            .catchtivityApplicationParticipationMapList.length > 0 ? (
            <>
              <div className="flex flex-col">
                <div className="flex-1 flex flex-row bg-catchup-white font-semibold">
                  <div className="flex-1"></div>
                  <div className="w-[150px] bg-catchup-dark-blue text-catchup-white border-r border-b border-r-catchup-white border-b-catchup-white text-center">
                    <p className="p-2">{i18n.t("catchtivity")}</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex-1 flex flex-row bg-catchup-white font-semibold">
                  <div className="flex-1"></div>
                  <div className="w-[150px] bg-catchup-dark-blue text-catchup-white border-r border-r-catchup-white text-center">
                    <p className="p-2">%</p>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {combineCatchtivityApplicationBasedOnActivityId(
            reportDetail.participationCatchtivityCatchxamApplicationMap
              .catchtivityApplicationParticipationMapList,
            reportDetail.totalCount
          ).map((catchtivityApplicationParticipation, key) => (
            <div key={key} className="flex flex-col">
              <div className="flex-1 flex flex-row bg-catchup-dark-blue text-catchup-white border-b border-b-catchup-white">
                <div className="flex-1">
                  <p className="p-2">
                    (
                    {new Date(
                      catchtivityApplicationParticipation.beginAt
                    ).toLocaleDateString(language)}{" "}
                    -{" "}
                    {new Date(
                      catchtivityApplicationParticipation.endAt
                    ).toLocaleDateString(language)}
                    ) {catchtivityApplicationParticipation.catchtivityName}
                  </p>
                </div>
                <div
                  className="flex justify-center items-center w-[150px] bg-catchup-white text-catchup-dark-blue border-r border-b border-catchup-dark-blue"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {`${(catchtivityApplicationParticipation.count * 100).toFixed(
                    2
                  )}`}
                </div>
              </div>
            </div>
          ))}
          {reportDetail.participationCatchtivityCatchxamApplicationMap
            .catchxamApplicationParticipationMapList.length > 0 ? (
            <>
              <div className="flex flex-col">
                <div className="flex-1 flex flex-row bg-catchup-white font-semibold">
                  <div className="flex-1"></div>
                  <div className="w-[150px] bg-catchup-dark-blue text-catchup-white border-r border-b border-r-catchup-white border-b-catchup-white text-center">
                    <p className="p-2">{i18n.t("catchxam")}</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex-1 flex flex-row bg-catchup-white font-semibold">
                  <div className="flex-1"></div>
                  <div className="w-[150px] bg-catchup-dark-blue text-catchup-white border-r border-r-catchup-white text-center">
                    <p className="p-2">%</p>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {combineCatchxamApplicationBasedOnActivityId(
            reportDetail.participationCatchtivityCatchxamApplicationMap
              .catchxamApplicationParticipationMapList,
            reportDetail.totalCount
          ).map((catchxamApplicationParticipation, key) => (
            <div key={key} className="flex flex-col">
              <div className="flex-1 flex flex-row bg-catchup-dark-blue text-catchup-white border-b border-b-catchup-white">
                <div className="flex-1">
                  <p className="p-2">
                    (
                    {new Date(
                      catchxamApplicationParticipation.beginAt
                    ).toLocaleDateString(language)}{" "}
                    -{" "}
                    {new Date(
                      catchxamApplicationParticipation.endAt
                    ).toLocaleDateString(language)}
                    ) {catchxamApplicationParticipation.catchxamName}
                  </p>
                </div>

                <div
                  className="flex justify-center items-center w-[150px] bg-catchup-white text-catchup-dark-blue border-r border-b border-catchup-dark-blue"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {`${(catchxamApplicationParticipation.count * 100).toFixed(
                    2
                  )}`}
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const RenderBranchParticipationReport = (reportDetail) => {
    return <>{RenderCommonParticipationReport(reportDetail)}</>;
  };

  const RenderGradeParticipationReport = (reportDetail) => {
    return <>{RenderCommonParticipationReport(reportDetail)}</>;
  };

  const RenderSeasonParticipationReport = (reportDetail) => {
    return <>{RenderCommonParticipationReport(reportDetail)}</>;
  };

  const RenderUserProfileParticipationReport = (reportDetail) => {
    return <>{RenderCommonParticipationReport(reportDetail)}</>;
  };

  const RenderReportContent = (reportDetail) => {
    if (subType === "BRANCH") {
      return RenderBranchParticipationReport(reportDetail);
    } else if (subType === "GRADE") {
      return RenderGradeParticipationReport(reportDetail);
    } else if (subType === "SEASON") {
      return RenderSeasonParticipationReport(reportDetail);
    } else if (subType === "USER_PROFILE") {
      return RenderUserProfileParticipationReport(reportDetail);
    }
  };

  const RenderMainContent = () => {
    if (reportDetailLoading) {
      return (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      );
    } else {
      if (reportDetailList.length > 0) {
        return (
          <>
            {showTitle ? (
              <>
                <div className="">
                  <BaseTitle title={i18n.t("report_details")} />
                </div>
                <DividerLine />
              </>
            ) : null}
            {reportDetailList.length > 0 ? (
              <div className="my-2 ml-auto">
                <PrimaryButton
                  title={i18n.t("generate_pdf")}
                  size="unlimited"
                  onClick={handleGeneratePDF}
                />
              </div>
            ) : null}
            {reportDetailList.map((reportDetail, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center"
              >
                <div className="w-full flex flex-row flex-wrap items-center">
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                    <div className="w-catchup-input-group-title py-2">
                      <p className="font-semibold">{i18n.t("type")}</p>
                    </div>
                    <div className="flex-1">
                      <p>{i18n.t(subType)}</p>
                    </div>
                  </div>
                  {reportDetail.seasonGradeBranchMap ? (
                    <>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("season_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonGradeBranchMap.seasonName}</p>
                        </div>
                      </div>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("grade_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonGradeBranchMap.gradeName}</p>
                        </div>
                      </div>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("branch_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonGradeBranchMap.branchName}</p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {reportDetail.seasonGradeMap ? (
                    <>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("season_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonGradeMap.seasonName}</p>
                        </div>
                      </div>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("grade_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonGradeMap.gradeName}</p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {reportDetail.seasonMap ? (
                    <>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("season_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonMap.seasonName}</p>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
                {reportDetail.userProfileMap ? (
                  <div className="w-full flex flex-row items-center">
                    <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                      <div className="w-catchup-input-group-title py-2">
                        <p className="font-semibold">{i18n.t("first_name")}</p>
                      </div>
                      <div className="flex-1">
                        <p>{reportDetail.userProfileMap.firstName}</p>
                      </div>
                    </div>
                    <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                      <div className="w-catchup-input-group-title py-2">
                        <p className="font-semibold">{i18n.t("last_name")}</p>
                      </div>
                      <div className="flex-1">
                        <p>{reportDetail.userProfileMap.lastName}</p>
                      </div>
                    </div>
                    <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                      <div className="w-catchup-input-group-title py-2">
                        <p className="font-semibold">
                          {i18n.t("user_profile")}
                        </p>
                      </div>
                      <div className="flex-1">
                        <p>{reportDetail.userProfileMap.userProfileName}</p>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="w-full">
                  {RenderReportContent(reportDetail)}
                </div>
              </div>
            ))}
          </>
        );
      }
    }
  };

  return <div className="flex-1 flex flex-col">{RenderMainContent()}</div>;
};

export default GeneralParticipationReportDetail;
