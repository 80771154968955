import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FullCard from "../../components/cards/FullCard";
import i18n from "../../language/i18n";
import { retrieveSeasonByIdInDetail } from "../../requests/ManagementRequests";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import BaseLoading from "../../components/loading/BaseLoading";

const SeasonDetailScreen = () => {
  const { seasonId } = useParams();
  const navigate = useNavigate();
  const [seasonInDetail, setSeasonInDetail] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const retrieveAndSetSeasonInDetail = async () => {
      setLoading(true);
      const { data, err } = await retrieveSeasonByIdInDetail(seasonId);
      if (err) {
        console.log(err);
      } else {
        setSeasonInDetail(data);
      }
      setLoading(false);
    };
    if (seasonId) {
      retrieveAndSetSeasonInDetail();
    }
  }, [seasonId]);

  const RenderMainContent = () => {
    if (loading) {
      return (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      );
    } else {
      if (!seasonInDetail) return;
      return (
        <FullCard isShadowed={true}>
          <div className="">
            <div className="flex flex-row items-center gap-x-2">
              <div className="cursor-pointer">
                <BaseImage
                  size="medium"
                  src="/icons/long-arrow-left.png"
                  alt="long-arrow-left"
                  onClick={() => {
                    navigate("/seasons");
                  }}
                />
              </div>
              <BaseTitle
                title={`${seasonInDetail.name} ${i18n.t("season_detail")}`}
              />
            </div>
          </div>
          <DividerLine />
          {seasonInDetail.staffUserProfileDTOList &&
          seasonInDetail.staffUserProfileDTOList.length > 0 ? (
            <div className="my-3">
              <div className="">
                <p className="font-semibold text-lg">
                  {i18n.t("staff_user_profiles")}
                </p>
              </div>
              <div className="flex flex-row items-center flex-wrap gap-x-3">
                {seasonInDetail.staffUserProfileDTOList.map(
                  (staffUserProfileDTO) => (
                    <div
                      className={` w-[200px] flex flex-col items-center justify-center my-3 p-3`}
                    >
                      <div className="cursor-pointer">
                        <BaseImage
                          size="xlarge"
                          src="/icons/user-profile-started.png"
                          alt="user-profile-started"
                        />
                      </div>
                      <div className="my-2">
                        <p className="text-center">
                          {staffUserProfileDTO.userDTO.firstName}{" "}
                          {staffUserProfileDTO.userDTO.lastName}
                        </p>
                        <p className="text-center">
                          {staffUserProfileDTO.name}
                        </p>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null}

          {seasonInDetail.learnerUserProfileDTOList &&
          seasonInDetail.learnerUserProfileDTOList.length > 0 ? (
            <div className="my-3">
              <div className="">
                <p className="font-semibold text-lg">
                  {i18n.t("learner_user_profiles")}
                </p>
              </div>
              <div className="flex flex-row items-center flex-wrap gap-x-3">
                {seasonInDetail.learnerUserProfileDTOList.map(
                  (learnerUserProfileDTO) => (
                    <div
                      className={` w-[200px] flex flex-col items-center justify-center my-3 p-3`}
                    >
                      <BaseImage
                        size="xlarge"
                        src="/icons/user-profile-started.png"
                        alt="user-profile-started"
                      />
                      <div className="my-2">
                        <p className="text-center">
                          {learnerUserProfileDTO.userDTO.firstName}{" "}
                          {learnerUserProfileDTO.userDTO.lastName}
                        </p>
                        <p className="text-center">
                          {learnerUserProfileDTO.name}
                        </p>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null}
        </FullCard>
      );
    }
    return null;
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default SeasonDetailScreen;
