import {
  Document,
  Font,
  Image,
  Text,
  Page,
  PDFViewer,
  View,
} from "@react-pdf/renderer";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import { useEffect, useRef, useState } from "react";
import { retrieveExternalExamDTODetailById } from "../../requests/CatchtivityRequests";
import BaseLoading from "../../components/loading/BaseLoading";
import i18n from "../../language/i18n";
import { shuffleArray } from "../../utilization/AppUtilization";
import { constructInputWithSpecialExpressionList } from "../../utilization/CatchtivityUtilization";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import { LatexToPdfImage } from "../../components/pdf/LatexToPdfImage";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
      fontWeight: "normal",
      textDecoration: "none",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
      fontWeight: "bold",
      textDecoration: "none",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
      fontWeight: "normal",
      textDecoration: "underline",
    },
  ],
});

const ExternalExamActivitiesPDFViewerScreen = () => {
  const navigate = useNavigate();
  const { externalExamId } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [loading, setLoading] = useState(false);
  const [externalExamDTOInDetail, setExternalExamDTOInDetail] = useState(null);

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("external_exam_list")) {
      navigate("/permission-error");
      return;
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetExternalExamPartDTOList = async () => {
      setLoading(true);
      const { data, err } = await retrieveExternalExamDTODetailById(
        externalExamId
      );
      if (err) {
        console.log(err);
        return;
      }
      setExternalExamDTOInDetail(data);
      setLoading(false);
    };
    if (!externalExamId) return;
    retrieveAndSetExternalExamPartDTOList();
  }, [externalExamId]);

  const RenderSingleItemJSON = (itemJSONValue, isMaterial) => {
    return (
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        {isMaterial ? (
          <Image
            src="/icons/arrow-right.png"
            style={{ width: 10, height: 10 }}
          />
        ) : null}
        {constructInputWithSpecialExpressionList(itemJSONValue).map(
          (inputPart) => {
            const { isEquation, isBold, isUnderline, value } = inputPart;
            return isEquation ? (
              <LatexToPdfImage latex={value} />
            ) : (
              value.split(" ").map((valueItem) => (
                <Text
                  style={{
                    fontWeight: isBold ? "bold" : "normal",
                    textDecoration: isUnderline ? "underline" : "none",
                  }}
                >
                  {valueItem.replaceAll("@@", "______")}{" "}
                </Text>
              ))
            );
          }
        )}
      </View>
    );
  };

  const RenderBodyContent = (bodyMap) => {
    const bodyMapJSON = JSON.parse(bodyMap);

    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <View>
          {Object.keys(bodyMapJSON).map((key) => {
            const itemJSON = JSON.parse(bodyMapJSON[key]);

            if (itemJSON.type === "TEXT") {
              return (
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  {RenderSingleItemJSON(itemJSON.value)}
                </View>
              );
            } else if (itemJSON.type === "IMAGE") {
              let width = "100%";
              if (itemJSON.size === "1/2") {
                width = "50%";
              } else if (itemJSON.size === "1/3") {
                width = "33%";
              }
              return (
                <View>
                  <Image
                    src={{
                      uri: itemJSON.value,
                    }}
                    style={{ width, padding: 5 }}
                  />
                </View>
              );
            }
          })}
        </View>
      </View>
    );
  };

  const RenderMaterialContent = (materialMap, type) => {
    const materialMapJSON = JSON.parse(materialMap);
    if (type === "ORDERING") {
      const shuffleMaterialMapJSONKeys = shuffleArray(
        Object.keys(materialMapJSON)
      );
      return (
        <View
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {shuffleMaterialMapJSONKeys.map((key, index) => (
            <View
              key={index}
              style={{ flexDirection: "row", flexWrap: "wrap" }}
            >
              {RenderSingleItemJSON(shuffleMaterialMapJSONKeys[key], true)}
            </View>
          ))}
        </View>
      );
    } else if (type === "DROPDOWN") {
    } else if (type === "MCSA") {
      const key = Object.keys(materialMapJSON)[0];
      const optionList = JSON.parse(materialMapJSON[key]);
      return (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {optionList.map((option, index) => (
            <View
              key={index}
              style={{
                width: "50%",
                flex: 1,
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {RenderSingleItemJSON(option, true)}
            </View>
          ))}
        </View>
      );
    } else if (type === "MCMA") {
      const key = Object.keys(materialMapJSON)[0];
      const optionList = JSON.parse(materialMapJSON[key]);
      return (
        <View
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {optionList.map((option, index) => (
            <View
              key={index}
              style={{ flexDirection: "row", flexWrap: "wrap" }}
            >
              {RenderSingleItemJSON(option, true)}
            </View>
          ))}
        </View>
      );
    } else if (type === "MATCHING") {
      const keyList = Object.keys(materialMapJSON);
      const optionList = [];
      for (const key of keyList) {
        optionList.push(materialMapJSON[key]);
      }
      return (
        <>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {keyList.map((key, index) => (
              <View style={{ flexDirection: "row" }}>
                <Text style={{ marginRight: 5 }}>{key}</Text>
                {index !== keyList.length - 1 ? (
                  <Text style={{ marginRight: 5 }}>-</Text>
                ) : null}
              </View>
            ))}
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            {optionList.map((option, index) => (
              <View
                key={index}
                style={{ flexDirection: "row", flexWrap: "wrap" }}
              >
                {RenderSingleItemJSON(option, true)}
              </View>
            ))}
          </View>
        </>
      );
    } else if (type === "GROUPING") {
      const keyList = Object.keys(materialMapJSON);
      const optionList = [];
      for (const key of keyList) {
        const subOptionList = JSON.parse(materialMapJSON[key]);
        optionList.push(...subOptionList);
      }
      return (
        <>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {keyList.map((key, index) => (
              <View style={{ flexDirection: "row" }}>
                <Text style={{ marginRight: 5 }}>{key}</Text>
                {index !== keyList.length - 1 ? (
                  <Text style={{ marginRight: 5 }}>-</Text>
                ) : null}
              </View>
            ))}
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            {optionList.map((option, index) => (
              <View
                key={index}
                style={{ flexDirection: "row", flexWrap: "wrap" }}
              >
                {RenderSingleItemJSON(option, true)}
              </View>
            ))}
          </View>
        </>
      );
    } else if (type === "FILL_IN_THE_BLANKS") {
    } else if (type === "OPEN_ENDED") {
      return;
    } else if (type === "TRUE_FALSE") {
      const { trueList, falseList } = materialMapJSON;
      const trueListJSON = JSON.parse(trueList);
      const falseListJSON = JSON.parse(falseList);
      const shuffleTrueFalseListJSON = shuffleArray([
        ...trueListJSON,
        ...falseListJSON,
      ]);
      return (
        <View
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {shuffleTrueFalseListJSON.map((option, index) => (
            <View
              key={index}
              style={{ flexDirection: "row", flexWrap: "wrap" }}
            >
              {RenderSingleItemJSON(option, true)}
            </View>
          ))}
        </View>
      );
    }
  };

  const RenderExternalExamPartContent = (externalExamPartInDetailDTO) => {
    if (!externalExamPartInDetailDTO) return;
    const { externalExamActivityInDetailDTOList, activityOffset } =
      externalExamPartInDetailDTO;
    return externalExamActivityInDetailDTOList.map(
      (externalExamActivityInDetailDTO) => {
        const {
          activityWithDataDTO,
          activityTemplateDTO,
          coterieType,
          position,
          coefficient,
        } = externalExamActivityInDetailDTO;
        const { data } = activityWithDataDTO;
        const dataJSON = JSON.parse(data);
        let currentBodyMap = {};
        let currentMaterialMap = {};
        if (activityTemplateDTO.type === "ORDERING") {
          const { orderingBodyMap, orderingMaterialMap } = dataJSON;
          currentBodyMap = orderingBodyMap;
          currentMaterialMap = orderingMaterialMap;
        } else if (activityTemplateDTO.type === "DROPDOWN") {
          const { dropdownBodyMap, dropdownMaterialMap } = dataJSON;
          currentBodyMap = dropdownBodyMap;
          currentMaterialMap = dropdownMaterialMap;
        } else if (activityTemplateDTO.type === "MCSA") {
          const { MCSABodyMap, MCSAMaterialMap } = dataJSON;
          currentBodyMap = MCSABodyMap;
          currentMaterialMap = MCSAMaterialMap;
        } else if (activityTemplateDTO.type === "MCMA") {
          const { MCMABodyMap, MCMAMaterialMap } = dataJSON;
          currentBodyMap = MCMABodyMap;
          currentMaterialMap = MCMAMaterialMap;
        } else if (activityTemplateDTO.type === "MATCHING") {
          const { matchingBodyMap, matchingMaterialMap } = dataJSON;
          currentBodyMap = matchingBodyMap;
          currentMaterialMap = matchingMaterialMap;
        } else if (activityTemplateDTO.type === "GROUPING") {
          const { groupingBodyMap, groupingMaterialMap } = dataJSON;
          currentBodyMap = groupingBodyMap;
          currentMaterialMap = groupingMaterialMap;
        } else if (activityTemplateDTO.type === "FILL_IN_THE_BLANKS") {
          const { fillInTheBlanksBodyMap, fillInTheBlanksMaterialMap } =
            dataJSON;
          currentBodyMap = fillInTheBlanksBodyMap;
          currentMaterialMap = fillInTheBlanksMaterialMap;
        } else if (activityTemplateDTO.type === "OPEN_ENDED") {
          const { openEndedBodyMap, openEndedMaterialMap } = dataJSON;
          currentBodyMap = openEndedBodyMap;
          currentMaterialMap = openEndedMaterialMap;
        } else if (activityTemplateDTO.type === "TRUE_FALSE") {
          const { trueFalseBodyMap, trueFalseMaterialMap } = dataJSON;
          currentBodyMap = trueFalseBodyMap;
          currentMaterialMap = trueFalseMaterialMap;
        }
        return (
          <View style={{ marginVertical: 15 }}>
            <View style={{ marginBottom: 5 }}>
              <Text style={{ fontWeight: "bold" }}>
                {position + activityOffset}.
                {i18n.t(`${coterieType}_ABBREVATION`)} ({coefficient * 100}{" "}
                {i18n.t("score")})
              </Text>
            </View>
            {RenderBodyContent(currentBodyMap)}
            <View style={{ marginTop: 10 }}>
              {RenderMaterialContent(
                currentMaterialMap,
                activityTemplateDTO.type
              )}
            </View>
          </View>
        );
      }
    );
  };

  const RenderExternalExamContent = () => {
    if (!externalExamDTOInDetail) return;
    const { externalExamPartInDetailDTOList } = externalExamDTOInDetail;
    return externalExamPartInDetailDTOList.map(
      (externalExamPartInDetailDTO, index) => {
        return (
          <View key={index}>
            {RenderExternalExamPartContent(externalExamPartInDetailDTO)}
          </View>
        );
      }
    );
  };

  return (
    <div className="flex-1 flex flex-col h-full">
      <div className="m-4 h-full">
        {loading ? (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <PDFViewer className="h-full w-full">
            <Document>
              <Page
                size="A4"
                style={{
                  backgroundColor: "#ffffff",
                  fontFamily: "Roboto",
                  paddingVertical: 30,
                  paddingHorizontal: 15,
                  fontSize: 10,
                }}
              >
                <View>{RenderExternalExamContent()}</View>
              </Page>
            </Document>
          </PDFViewer>
        )}
      </div>
    </div>
  );
};

export default ExternalExamActivitiesPDFViewerScreen;
