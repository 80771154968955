import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import FullCard from "../../components/cards/FullCard";
import InputGroup from "../../components/groups/InputGroup";
import { useApp } from "../../context/AppContextProvider";
import i18n from "../../language/i18n";
import { createIndividualUser } from "../../requests/ManagementRequests";
import {
  retrieveCoterieTypeOptionList,
  retrieveCountryCodeOptionList,
  retrieveCountryNameOptionList,
  retrieveGenderOptionList,
  retrievePhoneNumberAreaCodeList,
  retrieveProvinceNameOptionList,
} from "../../utilization/ManagementUtilization";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import LeftTextRightInputGroup from "../../components/groups/LeftTextRightInputGroup";

const RegisterScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [identityNumber, setIdentityNumber] = useState("11111111111");
  const [gender, setGender] = useState("DEFAULT_OPTION");
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [countryName, setCountryName] = useState("DEFAULT_OPTION");
  const [provinceName, setProvinceName] = useState("DEFAULT_OPTION");
  const [coterieTypeList, setCoterieTypeList] = useState([]);
  const [errorMap, setErrorMap] = useState({
    username: null,
    password: null,
    email: null,
    firstName: null,
    lastName: null,
    dateOfBirth: null,
    gender: null,
    phoneNumber: null,
    provinceName: null,
    countryName: null,
  });

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  const filterProvinceNameOptionList = () => {
    if (countryName !== "DEFAULT_OPTION") {
      return retrieveProvinceNameOptionList().filter(
        (provinceName) => provinceName.parent === countryName
      );
    }
    return [];
  };

  const retrievePhoneNumberAreaCode = () => {
    if (countryName !== "DEFAULT_OPTION") {
      return retrievePhoneNumberAreaCodeList().find(
        (phoneNumberAreaCode) => phoneNumberAreaCode.parent === countryName
      ).value;
    }
    return null;
  };

  const retrieveCountryCodeOption = () => {
    if (countryName !== "DEFAULT_OPTION") {
      return retrieveCountryCodeOptionList().find(
        (countryCodeOption) => countryCodeOption.parent === countryName
      ).value;
    }
    return null;
  };

  const filterCoterieTypeOptionList = () => {
    return retrieveCoterieTypeOptionList().filter(
      (coterieTypeOption) => coterieTypeOption.value !== "MANAGEMENT"
    );
  };

  const handleOnChange = (type, value) => {
    if (type === "username") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          username: i18n.t("username_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          username: null,
        }));
      }
      setUsername(value);
    } else if (type === "password") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          password: i18n.t("password_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          password: null,
        }));
      }
      setPassword(value);
    } else if (type === "email") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          email: i18n.t("email_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          email: null,
        }));
      }
      setEmail(value);
    } else if (type === "firstName") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          firstName: i18n.t("first_name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          firstName: null,
        }));
      }
      setFirstName(value);
    } else if (type === "lastName") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          lastName: i18n.t("last_name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          lastName: null,
        }));
      }
      setLastName(value);
    } else if (type === "identityNumber") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          identityNumber: i18n.t("identity_number_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          identityNumber: null,
        }));
      }
      setIdentityNumber(value);
    } else if (type === "dateOfBirth") {
      setDateOfBirth(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        dateOfBirth: null,
      }));
    } else if (type === "gender") {
      setGender(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        gender: null,
      }));
    } else if (type === "countryName") {
      setCountryName(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        countryName: null,
      }));
    } else if (type === "provinceName") {
      setProvinceName(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        provinceName: null,
      }));
    } else if (type === "phoneNumber") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          phoneNumber: i18n.t("phone_number_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          phoneNumber: null,
        }));
      }
      setPhoneNumber(value);
    }
  };

  const handleRegisterOnClick = async () => {
    if (
      !username ||
      username.trim() === "" ||
      !password ||
      password.trim() === "" ||
      !email ||
      email.trim() === "" ||
      !firstName ||
      firstName.trim() === "" ||
      !lastName ||
      lastName.trim() === "" ||
      !dateOfBirth ||
      !dateOfBirth.trim() === "" ||
      !phoneNumber ||
      phoneNumber.trim() === "" ||
      gender === "DEFAULT_OPTION" ||
      countryName === "DEFAULT_OPTION" ||
      provinceName === "DEFAULT_OPTION"
    ) {
      const currentErrorMap = {
        username: null,
        password: null,
        email: null,
        firstName: null,
        lastName: null,
        dateOfBirth: null,
        phoneNumber: null,
        provinceName: null,
        countryName: null,
      };
      if (!username || username.trim() === "") {
        currentErrorMap.username = i18n.t("username_required_field");
      }
      if (!password || password.trim() === "") {
        currentErrorMap.password = i18n.t("password_required_field");
      }
      if (!email || email.trim() === "") {
        currentErrorMap.email = i18n.t("email_required_field");
      }
      if (!firstName || firstName.trim() === "") {
        currentErrorMap.firstName = i18n.t("first_name_required_field");
      }
      if (!lastName || lastName.trim() === "") {
        currentErrorMap.lastName = i18n.t("last_name_required_field");
      }
      if (!dateOfBirth || dateOfBirth.trim() === "") {
        currentErrorMap.dateOfBirth = i18n.t("date_of_birth_required_field");
      }
      if (!phoneNumber || phoneNumber.trim() === "") {
        currentErrorMap.phoneNumber = i18n.t("phone_number_required_field");
      }
      if (gender === "DEFAULT_OPTION") {
        currentErrorMap.gender = i18n.t("gender_required_field");
      }
      if (countryName === "DEFAULT_OPTION") {
        currentErrorMap.countryName = i18n.t("country_name_required_field");
      }
      if (provinceName === "DEFAULT_OPTION") {
        currentErrorMap.provinceName = i18n.t("province_name_required_field");
      }
      setErrorMap(currentErrorMap);
      return;
    }

    if (coterieTypeList.length === 0) {
      NotificationManager.error(i18n.t("coterie_type_list_cannot_be_empty"));
      return;
    }

    const newBasicUser = {
      user: {
        username,
        password,
        email,
        firstName,
        lastName,
        identityNumber,
        dateOfBirth: new Date(dateOfBirth).getTime(),
        gender,
        phoneNumber: `${retrievePhoneNumberAreaCode()}${phoneNumber}`,
        accountType: "USER",
      },
      region: {
        countryCode: retrieveCountryCodeOption(),
        countryName,
        provinceName,
      },
      coterieTypeList,
      role: "INDIVIDUAL",
    };
    const { err } = await createIndividualUser(newBasicUser);
    if (err) {
      if (err.response.data) {
        NotificationManager.error(i18n.t(err.response.data));
      } else {
        console.log(err);
      }
    } else {
      navigate("/login", {
        state: {
          action: "LOGIN",
          username,
          password,
        },
      });
    }
  };

  const handleAddCoterieTypeOnClick = (newCoterieType) => {
    if (
      coterieTypeList.findIndex(
        (coterieType) => coterieType === newCoterieType
      ) === -1
    ) {
      coterieTypeList.push(newCoterieType);
      setCoterieTypeList(JSON.parse(JSON.stringify(coterieTypeList)));
    }
  };

  const handleRemoveCoterieTypeOnClick = (currentCoterieType) => {
    const foundIndex = coterieTypeList.findIndex(
      (coterieType) => coterieType === currentCoterieType
    );
    if (foundIndex !== -1) {
      coterieTypeList.splice(foundIndex, 1);
      setCoterieTypeList(JSON.parse(JSON.stringify(coterieTypeList)));
    }
  };

  const RenderCoterieTypeList = () => {
    return (
      <div className="w-full flex flex-row">
        <div className="w-catchup-input-group-title py-5">
          <p>{i18n.t("coteries")}</p>
        </div>
        <div className="flex flex-row flex-wrap gap-x-4 gap-y-2 my-5">
          {filterCoterieTypeOptionList().map((coterieTypeOption) => {
            if (
              coterieTypeList.findIndex(
                (coterieType) => coterieType === coterieTypeOption.value
              ) > -1
            ) {
              return (
                <div
                  className="flex flex-row items-center gap-x-2 cursor-pointer"
                  onClick={() => {
                    handleRemoveCoterieTypeOnClick(coterieTypeOption.value);
                  }}
                >
                  <BaseImage
                    size="medium"
                    src="/icons/checkbox.png"
                    alt="checkbox"
                  />
                  <div className="">
                    <p className="">{coterieTypeOption.text}</p>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  className="flex flex-row items-center gap-x-2 cursor-pointer"
                  onClick={() => {
                    handleAddCoterieTypeOnClick(coterieTypeOption.value);
                  }}
                >
                  <BaseImage
                    size="small"
                    src="/icons/checkbox-empty.png"
                    alt="checkbox"
                  />
                  <div className="text-md">
                    <p>{coterieTypeOption.text}</p>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  };

  const RenderMainContent = () => {
    return (
      <FullCard isShadowed={true}>
        <div className="">
          <div className="flex flex-row items-center gap-x-2">
            <div className="cursor-pointer">
              <BaseImage
                size="medium"
                src="/icons/long-arrow-left.png"
                alt="long-arrow-left"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <BaseTitle title={i18n.t("register_screen_header_text")} />
          </div>
        </div>
        <DividerLine />
        <div className="flex flex-col justify-center">
          <div className="flex flex-row items-center">
            <p className="font-semibold text-lg">
              {i18n.t("user_information")}
            </p>
          </div>

          <div className="w-full flex flex-row flex-wrap">
            <div className="w-full lg:w-1/2 flex flex-row">
              <LeftTextRightInputGroup
                type={"text"}
                title={i18n.t("username")}
                value={username}
                onChange={(e) => {
                  handleOnChange("username", e.target.value);
                }}
                errorText={errorMap.username}
              />
            </div>

            <div className="w-full lg:w-1/2 flex flex-row">
              <LeftTextRightInputGroup
                type={"password"}
                title={i18n.t("password")}
                value={password}
                onChange={(e) => {
                  handleOnChange("password", e.target.value);
                }}
                errorText={errorMap.password}
              />
            </div>

            <div className="w-full lg:w-1/2 flex flex-row">
              <LeftTextRightInputGroup
                type={"text"}
                title={i18n.t("first_name")}
                value={firstName}
                onChange={(e) => {
                  handleOnChange("firstName", e.target.value);
                }}
                errorText={errorMap.firstName}
              />
            </div>
            <div className="w-full lg:w-1/2 flex flex-row">
              <LeftTextRightInputGroup
                type={"text"}
                title={i18n.t("last_name")}
                value={lastName}
                onChange={(e) => {
                  handleOnChange("lastName", e.target.value);
                }}
                errorText={errorMap.lastName}
              />
            </div>

            <div className="w-full lg:w-1/2 flex flex-row">
              <LeftTextRightInputGroup
                type={"text"}
                title={i18n.t("email")}
                value={email}
                onChange={(e) => {
                  handleOnChange("email", e.target.value);
                }}
                errorText={errorMap.email}
              />
            </div>
            <div className="w-full lg:w-1/2 flex flex-row">
              <LeftTextRightInputGroup
                type={"date"}
                title={i18n.t("date_of_birth")}
                value={dateOfBirth}
                onChange={(e) => {
                  handleOnChange("dateOfBirth", e.target.value);
                }}
                errorText={errorMap.dateOfBirth}
              />
            </div>

            <div className="w-full lg:w-1/2 flex flex-row">
              <LeftTextRightInputGroup
                type="select"
                optionList={retrieveGenderOptionList()}
                title={i18n.t("gender")}
                value={gender}
                onChange={(e) => {
                  handleOnChange("gender", e.target.value);
                }}
                errorText={errorMap.gender}
              />
            </div>
          </div>

          <DividerLine />
          <div className="flex flex-row items-center">
            <p className="font-semibold text-lg">
              {i18n.t("region_information")}
            </p>
          </div>
          <div className="w-full flex flex-row flex-wrap">
            <div className="w-full lg:w-1/2 flex flex-row">
              <LeftTextRightInputGroup
                type="select"
                optionList={retrieveCountryNameOptionList()}
                title={i18n.t("country_name")}
                value={countryName}
                onChange={(e) => {
                  handleOnChange("countryName", e.target.value);
                }}
                errorText={errorMap.countryName}
              />
            </div>
            <div className="w-full lg:w-1/2 flex flex-row">
              <LeftTextRightInputGroup
                type="select"
                optionList={filterProvinceNameOptionList()}
                title={i18n.t("province_name")}
                value={provinceName}
                onChange={(e) => {
                  handleOnChange("provinceName", e.target.value);
                }}
                errorText={errorMap.provinceName}
              />
            </div>
            {countryName !== "DEFAULT_OPTION" ? (
              <div className="w-full lg:w-1/2 flex flex-row">
                <div className="w-full flex flex-row mx-2">
                  <div className="w-catchup-input-group-title py-5 flex flex-col">
                    <p>{`${i18n.t("phone_number")}`}</p>
                  </div>
                  <div className="flex-1 flex flex-row items-center">
                    <div className="mr-2">
                      <p>({retrievePhoneNumberAreaCode()})</p>
                    </div>
                    <div className="flex-1">
                      <InputGroup
                        type="number"
                        value={phoneNumber}
                        onChange={(e) => {
                          handleOnChange("phoneNumber", e.target.value);
                        }}
                        errorText={errorMap.phoneNumber}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <DividerLine />
          <div className="flex flex-row items-center">
            <p className="font-semibold text-lg">
              {i18n.t("coterie_information")}
            </p>
          </div>

          {RenderCoterieTypeList()}

          <div className="mt-4 ml-auto">
            <SecondaryButton
              title={i18n.t("register")}
              size="small"
              onClick={handleRegisterOnClick}
            />
          </div>
        </div>
      </FullCard>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default RegisterScreen;
