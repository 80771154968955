import { useEffect, useState } from "react";
import { useAuth } from "../../context/UserContextProvider";
import { useNavigate, useParams } from "react-router-dom";
import {
  patchInstitutionAISettings,
  retrieveInstitutionAISettingsByInstitutionId,
  retrieveInstitutionDTOById,
} from "../../requests/ManagementRequests";
import FullCard from "../../components/cards/FullCard";
import i18n from "../../language/i18n";
import Switch from "react-switch";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import DividerLine from "../../components/divider/DividerLine";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import { useApp } from "../../context/AppContextProvider";
import BaseLoading from "../../components/loading/BaseLoading";

const InstitutionAISettingsScreen = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const { userInformation } = useAuth();
  const [institutionDTO, setInstitutionDTO] = useState(null);
  const [institutionAISettings, setInstitutionAISettings] = useState(null);
  const [loadingMap, setLoadingMap] = useState({
    institution: false,
    settings: false,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const { accountType } = userInformation;
    if (accountType !== "GENIXO") {
      navigate("/");
    }
  }, [userInformation]);

  useEffect(() => {
    if (!id) return;
    retrieveAndSetInstitutionById();
    retrieveAndSetInstitutionAISettings();
  }, [id]);

  const retrieveAndSetInstitutionAISettings = async () => {
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      settings: true,
    }));
    const { data, err } = await retrieveInstitutionAISettingsByInstitutionId(
      id
    );
    if (err) {
      console.log(err);
      return;
    }
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      settings: false,
    }));
    setInstitutionAISettings(data);
  };

  const retrieveAndSetInstitutionById = async () => {
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      institution: true,
    }));
    const { data, err } = await retrieveInstitutionDTOById(id);
    if (err) {
      console.log(err);
      return;
    }
    setInstitutionDTO(data);
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      institution: false,
    }));
  };

  const handleUpdateInstitutionAISettingsOnClick = async () => {
    const { data, err } = await patchInstitutionAISettings(
      institutionAISettings
    );
    if (err) {
      console.log(err);
      return;
    }
    navigate(-1);
  };

  const RenderMainContent = () => {
    if (!institutionAISettings) return;
    const {
      canConvertActivityTemplate,
      canGenerateActivity,
      canEvaluateOpenEnded,
      canSolveActivityTemplate,
    } = institutionAISettings;
    return (
      <FullCard isShadowed={true}>
        <div className="">
          <div className="flex flex-row items-center gap-x-2">
            <div className="cursor-pointer">
              <BaseImage
                size="medium"
                src="/icons/long-arrow-left.png"
                alt="long-arrow-left"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <BaseTitle title={i18n.t("institution_ai_settings")} />
          </div>
        </div>
        <DividerLine />
        {loadingMap.institution || loadingMap.settings ? (
          <BaseLoading size="medium" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <>
            <div className="">
              <p className="">
                {i18n.t("brand_name")}:{" "}
                <span className="font-semibold">
                  {institutionDTO.campusDTO.brandDTO.name}
                </span>
              </p>
              <p className="">
                {i18n.t("campus_name")}:{" "}
                <span className="font-semibold">
                  {institutionDTO.campusDTO.name}
                </span>
              </p>
              <p className="">
                {i18n.t("branch_name")}:{" "}
                <span className="font-semibold">{institutionDTO.name}</span>
              </p>
            </div>
            <DividerLine />
            <div className="flex flex-row items-center flex-wrap">
              <div className="w-full lg:w-1/2">
                <div className="flex flex-row items-center justify-between gap-x-2">
                  <div className="flex-1">
                    <p>{i18n.t("can_convert_activity_template")}</p>
                  </div>
                  <div className="mx-2">
                    <Switch
                      onChange={() => {
                        if (canConvertActivityTemplate) {
                          setInstitutionAISettings(
                            (prevInstitutionAISettings) => ({
                              ...prevInstitutionAISettings,
                              canConvertActivityTemplate: false,
                            })
                          );
                        } else {
                          setInstitutionAISettings(
                            (prevInstitutionAISettings) => ({
                              ...prevInstitutionAISettings,
                              canConvertActivityTemplate: true,
                            })
                          );
                        }
                      }}
                      checkedIcon={false}
                      offColor={"#e2e8f0"}
                      onColor={"#57C2D3"}
                      offHandleColor={"#57C2D3"}
                      onHandleColor={"#e2e8f0"}
                      uncheckedIcon={false}
                      checked={canConvertActivityTemplate}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="flex flex-row items-center justify-between gap-x-2">
                  <div className="flex-1">
                    <p>{i18n.t("can_generate_activity")}</p>
                  </div>
                  <div className="mx-2">
                    <Switch
                      onChange={() => {
                        if (canGenerateActivity) {
                          setInstitutionAISettings(
                            (prevInstitutionAISettings) => ({
                              ...prevInstitutionAISettings,
                              canGenerateActivity: false,
                            })
                          );
                        } else {
                          setInstitutionAISettings(
                            (prevInstitutionAISettings) => ({
                              ...prevInstitutionAISettings,
                              canGenerateActivity: true,
                            })
                          );
                        }
                      }}
                      checkedIcon={false}
                      offColor={"#e2e8f0"}
                      onColor={"#57C2D3"}
                      offHandleColor={"#57C2D3"}
                      onHandleColor={"#e2e8f0"}
                      uncheckedIcon={false}
                      checked={canGenerateActivity}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="flex flex-row items-center justify-between gap-x-2">
                  <div className="flex-1">
                    <p>{i18n.t("can_evaluate_open_ended")}</p>
                  </div>
                  <div className="mx-2">
                    <Switch
                      onChange={() => {
                        if (canEvaluateOpenEnded) {
                          setInstitutionAISettings(
                            (prevInstitutionAISettings) => ({
                              ...prevInstitutionAISettings,
                              canEvaluateOpenEnded: false,
                            })
                          );
                        } else {
                          setInstitutionAISettings(
                            (prevInstitutionAISettings) => ({
                              ...prevInstitutionAISettings,
                              canEvaluateOpenEnded: true,
                            })
                          );
                        }
                      }}
                      checkedIcon={false}
                      offColor={"#e2e8f0"}
                      onColor={"#57C2D3"}
                      offHandleColor={"#57C2D3"}
                      onHandleColor={"#e2e8f0"}
                      uncheckedIcon={false}
                      checked={canEvaluateOpenEnded}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="flex flex-row items-center justify-between gap-x-2">
                  <div className="flex-1">
                    <p>{i18n.t("can_solve_activity_template")}</p>
                  </div>
                  <div className="mx-2">
                    <Switch
                      onChange={() => {
                        if (canSolveActivityTemplate) {
                          setInstitutionAISettings(
                            (prevInstitutionAISettings) => ({
                              ...prevInstitutionAISettings,
                              canSolveActivityTemplate: false,
                            })
                          );
                        } else {
                          setInstitutionAISettings(
                            (prevInstitutionAISettings) => ({
                              ...prevInstitutionAISettings,
                              canSolveActivityTemplate: true,
                            })
                          );
                        }
                      }}
                      checkedIcon={false}
                      offColor={"#e2e8f0"}
                      onColor={"#57C2D3"}
                      offHandleColor={"#57C2D3"}
                      onHandleColor={"#e2e8f0"}
                      uncheckedIcon={false}
                      checked={canSolveActivityTemplate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {!loadingMap.institution && !loadingMap.settings ? (
          <div className="mt-4 ml-auto">
            <SecondaryButton
              title={i18n.t("update")}
              size="medium"
              onClick={handleUpdateInstitutionAISettingsOnClick}
            />
          </div>
        ) : null}
      </FullCard>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default InstitutionAISettingsScreen;
