import { useEffect, useState } from "react";
import FullCard from "../../components/cards/FullCard";
import DividerLine from "../../components/divider/DividerLine";
import SingleInformationIconTextItem from "../../components/infos/SingleInformationIconTextItem";
import SubTitle from "../../components/titles/SubTitle";
import i18n from "../../language/i18n";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import {
  queryCampusListByBrandId,
  queryGradeListBySeasonId,
  queryInstitutionListByCampusId,
  querySeasonListByInstitutionId,
  retrieveAllBrandDTOList,
} from "../../requests/ManagementRequests";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/UserContextProvider";
import InputGroup from "../../components/groups/InputGroup";
import { filterCoterieTypeOptionList } from "../../utilization/ManagementUtilization";
import {
  filterCategoryVersionCodeOptionListByGradeDTO,
  retrieveCategoryVersionCodeOptionList,
} from "../../utilization/CategoryUtilization";
import {
  queryPlanListByParams,
  transferPlanFromGradeIdToGradeId,
} from "../../requests/PlanRequests";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import { NotificationManager } from "react-notifications";
import { useApp } from "../../context/AppContextProvider";
import BaseLoading from "../../components/loading/BaseLoading";

const PlanTransferScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileSeason,
    userProfileGrade,
  } = useAuth();
  const [fromBrandDTOList, setFromBrandDTOList] = useState([userProfileBrand]);
  const [fromCampusDTOList, setFromCampusDTOList] = useState([]);
  const [fromInstitutionDTOList, setFromInstitutionDTOList] = useState([]);
  const [fromSeasonDTOList, setFromSeasonDTOList] = useState([]);
  const [fromGradeDTOList, setFromGradeDTOList] = useState([]);
  //   const [branchDTOList, setBranchDTOList] = useState([]);
  const [selectedFromBrandId, setSelectedFromBrandId] = useState(
    userProfileBrand.id
  );
  const [selectedFromCampusId, setSelectedFromCampusId] =
    useState("DEFAULT_OPTION");
  const [selectedFromSeasonId, setSelectedFromSeasonId] =
    useState("DEFAULT_OPTION");
  const [selectedFromGradeId, setSelectedFromGradeId] =
    useState("DEFAULT_OPTION");
  const [selectedFromInstitutionId, setSelectedFromInstitutionId] =
    useState("DEFAULT_OPTION");
  const [selectedFromVersionCode, setSelectedFromVersionCode] =
    useState("DEFAULT_OPTION");
  const [selectedFromCoterieType, setSelectedFromCoterieType] = useState(
    userProfile?.coterieType
      ? userProfile?.coterieType
      : userProfile?.coterieTypeList && userProfile?.coterieTypeList.length > 0
      ? userProfile?.coterieTypeList[0]
      : "DEFAULT_OPTION"
  );
  const [canQueryFromPlan, setCanQueryFromPlan] = useState(false);
  const [fromPlanLoading, setFromPlanLoading] = useState(false);
  const [fromPlan, setFromPlan] = useState(null);

  const [targetBrandDTOList, setTargetBrandDTOList] = useState([]);
  const [targetCampusDTOList, setTargetCampusDTOList] = useState([]);
  const [targetInstitutionDTOList, setTargetInstitutionDTOList] = useState([]);
  const [targetSeasonDTOList, setTargetSeasonDTOList] = useState([]);
  const [targetGradeDTOList, setTargetGradeDTOList] = useState([]);
  //   const [branchDTOList, setBranchDTOList] = useState([]);
  const [selectedTargetBrandId, setSelectedTargetBrandId] =
    useState("DEFAULT_OPTION");
  const [selectedTargetCampusId, setSelectedTargetCampusId] =
    useState("DEFAULT_OPTION");
  const [selectedTargetSeasonId, setSelectedTargetSeasonId] =
    useState("DEFAULT_OPTION");
  const [selectedTargetGradeId, setSelectedTargetGradeId] =
    useState("DEFAULT_OPTION");
  const [selectedTargetInstitutionId, setSelectedTargetInstitutionId] =
    useState("DEFAULT_OPTION");
  const [selectedTargetVersionCode, setSelectedTargetVersionCode] =
    useState("DEFAULT_OPTION");
  const [selectedTargetCoterieType, setSelectedTargetCoterieType] = useState(
    userProfile?.coterieType
      ? userProfile?.coterieType
      : userProfile?.coterieTypeList && userProfile?.coterieTypeList.length > 0
      ? userProfile?.coterieTypeList[0]
      : "DEFAULT_OPTION"
  );
  const [canQueryTargetPlan, setCanQueryTargetPlan] = useState(false);
  const [targetPlanLoading, setTargetPlanLoading] = useState(false);
  const [targetPlan, setTargetPlan] = useState(null);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (checkViewPermissionGranted("activity_transfer")) {
      retrieveAndSetTargetBrandList();
      retrieveAndSetFromBrandList();
    } else {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    setSelectedFromCampusId("DEFAULT_OPTION");
    if (selectedFromBrandId === "DEFAULT_OPTION") {
      setFromCampusDTOList([]);
    } else {
      if (userProfileCampus) {
        setFromCampusDTOList([userProfileCampus]);
        setSelectedFromCampusId(userProfileCampus.id);
      } else {
        retrieveAndSetFromCampusDTOListByBrandId();
      }
    }
  }, [selectedFromBrandId]);

  useEffect(() => {
    setSelectedFromInstitutionId("DEFAULT_OPTION");
    if (selectedFromCampusId === "DEFAULT_OPTION") {
      setFromInstitutionDTOList([]);
    } else {
      if (userProfileInstitution) {
        setFromInstitutionDTOList([userProfileInstitution]);
        setSelectedFromInstitutionId(userProfileInstitution.id);
      } else {
        retrieveAndSetFromInstitutionDTOListByCampusId();
      }
    }
  }, [selectedFromCampusId]);

  useEffect(() => {
    setSelectedFromSeasonId("DEFAULT_OPTION");
    if (selectedFromInstitutionId === "DEFAULT_OPTION") {
      setFromSeasonDTOList([]);
    } else {
      if (userProfileSeason) {
        setFromSeasonDTOList([userProfileSeason]);
        setSelectedFromSeasonId(userProfileSeason.id);
      } else {
        retrieveAndSetFromSeasonDTOListByInstitutionId();
      }
    }
  }, [selectedFromInstitutionId]);

  useEffect(() => {
    setSelectedFromGradeId("DEFAULT_OPTION");
    if (selectedFromSeasonId === "DEFAULT_OPTION") {
      setFromGradeDTOList([]);
    } else {
      if (userProfileGrade) {
        setFromGradeDTOList([userProfileGrade]);
        setSelectedFromGradeId(userProfileGrade.id);
      } else {
        retrieveAndSetFromGradeDTOListBySeasonId();
      }
    }
  }, [selectedFromSeasonId]);

  //   useEffect(() => {
  //     if (selectedFromGradeId === "DEFAULT_OPTION") {
  //       setBranchDTOList([]);
  //     } else {
  //       retrieveAndSetBranchDTOListByGradeId();
  //     }
  //   }, [selectedFromGradeId]);

  useEffect(() => {
    if (
      selectedFromBrandId !== "DEFAULT_OPTION" &&
      selectedFromCampusId !== "DEFAULT_OPTION" &&
      selectedFromInstitutionId !== "DEFAULT_OPTION" &&
      selectedFromSeasonId !== "DEFAULT_OPTION" &&
      selectedFromGradeId !== "DEFAULT_OPTION" &&
      selectedFromCoterieType !== "DEFAULT_OPTION" &&
      selectedFromCoterieType !== "MANAGEMENT" &&
      selectedFromVersionCode !== "DEFAULT_OPTION"
    ) {
      setCanQueryFromPlan(true);
    } else {
      setCanQueryFromPlan(false);
    }
  }, [
    selectedFromBrandId,
    selectedFromCampusId,
    selectedFromInstitutionId,
    selectedFromSeasonId,
    selectedFromGradeId,
    selectedFromCoterieType,
    selectedFromVersionCode,
  ]);

  useEffect(() => {
    const retrieveAndSetPlan = async () => {
      setFromPlanLoading(true);
      let queryParams = {
        brandId: selectedFromBrandId,
        campusId: selectedFromCampusId,
        institutionId: selectedFromInstitutionId,
        seasonId: selectedFromSeasonId,
        gradeId: selectedFromGradeId,
        coterieType: selectedFromCoterieType,
        versionCode: selectedFromVersionCode,
      };
      const { data, err } = await queryPlanListByParams(queryParams);
      if (err) {
        console.log(err);
        setFromPlan("NOT_FOUND");
      } else {
        if (data.length > 0) {
          const foundPlan = data.shift();
          setFromPlan(foundPlan);
        } else {
          setFromPlan("NOT_FOUND");
        }
      }
      setFromPlanLoading(false);
    };
    if (canQueryFromPlan) {
      retrieveAndSetPlan();
    } else {
      setFromPlan(null);
    }
  }, [canQueryFromPlan]);

  useEffect(() => {
    setSelectedTargetCampusId("DEFAULT_OPTION");
    if (selectedTargetBrandId === "DEFAULT_OPTION") {
      setTargetCampusDTOList([]);
    } else {
      retrieveAndSetTargetCampusDTOListByBrandId();
    }
  }, [selectedTargetBrandId]);

  useEffect(() => {
    setSelectedTargetInstitutionId("DEFAULT_OPTION");
    if (selectedTargetCampusId === "DEFAULT_OPTION") {
      setTargetInstitutionDTOList([]);
    } else {
      retrieveAndSetTargetInstitutionDTOListByCampusId();
    }
  }, [selectedTargetCampusId]);

  useEffect(() => {
    setSelectedTargetSeasonId("DEFAULT_OPTION");
    if (selectedTargetInstitutionId === "DEFAULT_OPTION") {
      setTargetSeasonDTOList([]);
    } else {
      retrieveAndSetTargetSeasonDTOListByInstitutionId();
    }
  }, [selectedTargetInstitutionId]);

  useEffect(() => {
    setSelectedTargetGradeId("DEFAULT_OPTION");
    if (selectedTargetSeasonId === "DEFAULT_OPTION") {
      setTargetGradeDTOList([]);
    } else {
      retrieveAndSetTargetGradeDTOListBySeasonId();
    }
  }, [selectedTargetSeasonId]);

  //   useEffect(() => {
  //     if (selectedTargetGradeId === "DEFAULT_OPTION") {
  //       setBranchDTOList([]);
  //     } else {
  //       retrieveAndSetBranchDTOListByGradeId();
  //     }
  //   }, [selectedTargetGradeId]);

  useEffect(() => {
    if (
      selectedTargetBrandId !== "DEFAULT_OPTION" &&
      selectedTargetCampusId !== "DEFAULT_OPTION" &&
      selectedTargetInstitutionId !== "DEFAULT_OPTION" &&
      selectedTargetSeasonId !== "DEFAULT_OPTION" &&
      selectedTargetGradeId !== "DEFAULT_OPTION" &&
      selectedTargetCoterieType !== "DEFAULT_OPTION" &&
      selectedTargetCoterieType !== "MANAGEMENT" &&
      selectedTargetVersionCode !== "DEFAULT_OPTION"
    ) {
      setCanQueryTargetPlan(true);
    } else {
      setCanQueryTargetPlan(false);
    }
  }, [
    selectedTargetBrandId,
    selectedTargetCampusId,
    selectedTargetInstitutionId,
    selectedTargetSeasonId,
    selectedTargetGradeId,
    selectedTargetCoterieType,
    selectedTargetVersionCode,
  ]);

  useEffect(() => {
    const retrieveAndSetPlan = async () => {
      setTargetPlanLoading(true);
      let queryParams = {
        brandId: selectedTargetBrandId,
        campusId: selectedTargetCampusId,
        institutionId: selectedTargetInstitutionId,
        seasonId: selectedTargetSeasonId,
        gradeId: selectedTargetGradeId,
        coterieType: selectedTargetCoterieType,
        versionCode: selectedTargetVersionCode,
      };
      const { data, err } = await queryPlanListByParams(queryParams);
      if (err) {
        console.log(err);
        setTargetPlan("NOT_FOUND");
      } else {
        if (data.length > 0) {
          const foundPlan = data.shift();
          setTargetPlan(foundPlan);
        } else {
          setTargetPlan("NOT_FOUND");
        }
      }
      setTargetPlanLoading(false);
    };
    if (canQueryTargetPlan) {
      retrieveAndSetPlan();
    } else {
      setTargetPlan(null);
    }
  }, [canQueryTargetPlan]);

  const retrieveAndSetFromBrandList = async () => {
    const { data, err } = await retrieveAllBrandDTOList();
    if (err) {
      console.log(err);
      return;
    }
    setFromBrandDTOList(data);
  };

  const retrieveAndSetFromCampusDTOListByBrandId = async () => {
    const { data, err } = await queryCampusListByBrandId(selectedFromBrandId);
    if (err) {
      console.log(err);
      return;
    }
    setFromCampusDTOList(data);
  };

  const retrieveAndSetFromInstitutionDTOListByCampusId = async () => {
    const { data, err } = await queryInstitutionListByCampusId(
      selectedFromCampusId
    );
    if (err) {
      console.log(err);
      return;
    }
    setFromInstitutionDTOList(data);
  };

  const retrieveAndSetFromSeasonDTOListByInstitutionId = async () => {
    const { data, err } = await querySeasonListByInstitutionId(
      selectedFromInstitutionId
    );
    if (err) {
      console.log(err);
      return;
    }
    setFromSeasonDTOList(data);
  };

  const retrieveAndSetFromGradeDTOListBySeasonId = async () => {
    const { data, err } = await queryGradeListBySeasonId(selectedFromSeasonId);
    if (err) {
      console.log(err);
      return;
    }
    setFromGradeDTOList(data);
  };

  //   const retrieveAndSetBranchDTOListByGradeId = async () => {
  //     const { data, err } = await queryBranchListByGradeId(selectedTargetGradeId);
  //     if (err) {
  //       console.log(err);
  //     } else {
  //       setBranchDTOList(data);
  //     }
  //   };

  const retrieveFromBrandDTOOptionList = () => {
    return fromBrandDTOList.map((brandDTO) => ({
      value: brandDTO.id,
      text: brandDTO.name,
    }));
  };

  const retrieveFromCampusDTOOptionList = () => {
    return fromCampusDTOList.map((campusDTO) => ({
      value: campusDTO.id,
      text: campusDTO.name,
    }));
  };

  const retrieveFromInstitutionDTOOptionList = () => {
    return fromInstitutionDTOList.map((institutionDTO) => ({
      value: institutionDTO.id,
      text: institutionDTO.name,
    }));
  };

  const retrieveFromSeasonDTOOptionList = () => {
    return fromSeasonDTOList.map((seasonDTO) => ({
      value: seasonDTO.id,
      text: seasonDTO.name,
    }));
  };

  const retrieveFromGradeDTOOptionList = () => {
    return fromGradeDTOList.map((gradeDTO) => ({
      value: gradeDTO.id,
      text: gradeDTO.name,
    }));
  };

  const retrieveAndSetTargetBrandList = async () => {
    const { data, err } = await retrieveAllBrandDTOList();
    if (err) {
      console.log(err);
      return;
    }
    setTargetBrandDTOList(data);
  };

  const retrieveAndSetTargetCampusDTOListByBrandId = async () => {
    const { data, err } = await queryCampusListByBrandId(selectedTargetBrandId);
    if (err) {
      console.log(err);
      return;
    }
    setTargetCampusDTOList(data);
  };

  const retrieveAndSetTargetInstitutionDTOListByCampusId = async () => {
    const { data, err } = await queryInstitutionListByCampusId(
      selectedTargetCampusId
    );
    if (err) {
      console.log(err);
      return;
    }
    setTargetInstitutionDTOList(data);
  };

  const retrieveAndSetTargetSeasonDTOListByInstitutionId = async () => {
    const { data, err } = await querySeasonListByInstitutionId(
      selectedTargetInstitutionId
    );
    if (err) {
      console.log(err);
      return;
    }
    setTargetSeasonDTOList(data);
  };

  const retrieveAndSetTargetGradeDTOListBySeasonId = async () => {
    const { data, err } = await queryGradeListBySeasonId(
      selectedTargetSeasonId
    );
    if (err) {
      console.log(err);
      return;
    }
    setTargetGradeDTOList(data);
  };

  //   const retrieveAndSetBranchDTOListByGradeId = async () => {
  //     const { data, err } = await queryBranchListByGradeId(selectedTargetGradeId);
  //     if (err) {
  //       console.log(err);
  //     } else {
  //       setBranchDTOList(data);
  //     }
  //   };

  const retrieveTargetBrandDTOOptionList = () => {
    return targetBrandDTOList.map((brandDTO) => ({
      value: brandDTO.id,
      text: brandDTO.name,
    }));
  };

  const retrieveTargetCampusDTOOptionList = () => {
    return targetCampusDTOList.map((campusDTO) => ({
      value: campusDTO.id,
      text: campusDTO.name,
    }));
  };

  const retrieveTargetInstitutionDTOOptionList = () => {
    return targetInstitutionDTOList.map((institutionDTO) => ({
      value: institutionDTO.id,
      text: institutionDTO.name,
    }));
  };

  const retrieveTargetSeasonDTOOptionList = () => {
    return targetSeasonDTOList.map((seasonDTO) => ({
      value: seasonDTO.id,
      text: seasonDTO.name,
    }));
  };

  const retrieveTargetGradeDTOOptionList = () => {
    return targetGradeDTOList.map((gradeDTO) => ({
      value: gradeDTO.id,
      text: gradeDTO.name,
    }));
  };

  const handleOnChange = (type, value) => {
    setCanQueryTargetPlan(false);
    if (type === "selectedFromBrandId") {
      setSelectedFromBrandId(value);
    } else if (type === "selectedFromCampusId") {
      setSelectedFromCampusId(value);
    } else if (type === "selectedFromInstitutionId") {
      setSelectedFromInstitutionId(value);
    } else if (type === "selectedFromSeasonId") {
      setSelectedFromSeasonId(value);
    } else if (type === "selectedFromGradeId") {
      setSelectedFromGradeId(value);
    } else if (type === "selectedFromCoterieType") {
      setSelectedFromVersionCode("DEFAULT_OPTION");
      setSelectedTargetVersionCode("DEFAULT_OPTION");
      setSelectedFromCoterieType(value);
      setSelectedTargetCoterieType(value);
    } else if (type === "selectedFromVersionCode") {
      setCanQueryFromPlan(false);
      setCanQueryTargetPlan(false);
      setSelectedFromVersionCode(value);
      setSelectedTargetVersionCode(value);
    } else if (type === "selectedTargetBrandId") {
      setSelectedTargetBrandId(value);
    } else if (type === "selectedTargetCampusId") {
      setSelectedTargetCampusId(value);
    } else if (type === "selectedTargetInstitutionId") {
      setSelectedTargetInstitutionId(value);
    } else if (type === "selectedTargetSeasonId") {
      setSelectedTargetSeasonId(value);
    } else if (type === "selectedTargetGradeId") {
      setSelectedTargetGradeId(value);
    } else if (type === "selectedTargetCoterieType") {
      setSelectedTargetVersionCode("DEFAULT_OPTION");
      setSelectedTargetCoterieType(value);
    } else if (type === "selectedTargetVersionCode") {
      setCanQueryTargetPlan(false);
      setSelectedTargetVersionCode(value);
    }
  };

  const handlePlanTransferOnClick = async () => {
    const { data, err } = await transferPlanFromGradeIdToGradeId({
      fromId: fromPlan.id,
      toId: targetPlan.id,
    });
    if (err) {
      NotificationManager.error(i18n.t(err.response.data));
      return;
    }
    NotificationManager.success(i18n.t("plan_transfer_successful_text"));
  };

  const RenderMainContent = () => {
    return (
      <FullCard isShadowed={true}>
        <SubTitle title={i18n.t("from_where")} />
        {SingleInformationIconTextItem(i18n.t("please_select_from_grade"))}
        <div className="flex flex-row flex-wrap items-center">
          {selectedFromBrandId !== "DEFAULT_OPTION" ? (
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("campus")}
                  placeholder={i18n.t("campus")}
                  value={selectedFromCampusId}
                  optionList={retrieveFromCampusDTOOptionList()}
                  onChange={(event) => {
                    handleOnChange("selectedFromCampusId", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}

          {selectedFromCampusId !== "DEFAULT_OPTION" ? (
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("institution")}
                  placeholder={i18n.t("institution")}
                  value={selectedFromInstitutionId}
                  optionList={retrieveFromInstitutionDTOOptionList()}
                  onChange={(event) => {
                    handleOnChange(
                      "selectedFromInstitutionId",
                      event.target.value
                    );
                  }}
                />
              </div>
            </div>
          ) : null}

          {selectedFromInstitutionId !== "DEFAULT_OPTION" ? (
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("season")}
                  placeholder={i18n.t("season")}
                  value={selectedFromSeasonId}
                  optionList={retrieveFromSeasonDTOOptionList()}
                  onChange={(event) => {
                    handleOnChange("selectedFromSeasonId", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}

          {selectedFromSeasonId !== "DEFAULT_OPTION" ? (
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("grade")}
                  placeholder={i18n.t("grade")}
                  value={selectedFromGradeId}
                  optionList={retrieveFromGradeDTOOptionList()}
                  onChange={(event) => {
                    handleOnChange("selectedFromGradeId", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}

          {selectedFromGradeId !== "DEFAULT_OPTION" ? <DividerLine /> : null}

          {selectedFromGradeId !== "DEFAULT_OPTION" ? (
            <>
              {SingleInformationIconTextItem(
                i18n.t("please_select_from_coterie_type_and_version_code")
              )}
              <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("coterie_type")}
                    placeholder={i18n.t("coterie_type")}
                    value={selectedFromCoterieType}
                    optionList={filterCoterieTypeOptionList(
                      userInformation,
                      userProfile,
                      userProfileInstitution
                    )}
                    onChange={(event) => {
                      handleOnChange(
                        "selectedFromCoterieType",
                        event.target.value
                      );
                    }}
                  />
                </div>
              </div>
              {selectedFromCoterieType !== "DEFAULT_OPTION" &&
              selectedFromCoterieType !== "MANAGEMENT" ? (
                <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                  <div className="mx-2">
                    <InputGroup
                      type="select"
                      title={i18n.t("version_code")}
                      placeholder={i18n.t("version_code")}
                      value={selectedFromVersionCode}
                      optionList={filteredFromCategoryVersionCodeOptionList}
                      onChange={(event) => {
                        handleOnChange(
                          "selectedFromVersionCode",
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
        {canQueryFromPlan && selectedFromVersionCode !== "DEFAULT_OPTION" ? (
          <>
            <DividerLine />
            {fromPlanLoading ? (
              <BaseLoading
                size="large"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            ) : fromPlan === "NOT_FOUND" ? (
              <div>
                <p>{i18n.t("plan_not_found")}</p>
              </div>
            ) : null}
          </>
        ) : null}

        {fromPlan && fromPlan !== "NOT_FOUND" ? (
          <>
            <SubTitle title={i18n.t("to_where")} />
            {SingleInformationIconTextItem(
              i18n.t("please_select_target_grade")
            )}
            <div className="flex flex-row flex-wrap items-center">
              <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("brand")}
                    placeholder={i18n.t("brand")}
                    value={selectedTargetBrandId}
                    optionList={retrieveTargetBrandDTOOptionList()}
                    onChange={(event) => {
                      handleOnChange(
                        "selectedTargetBrandId",
                        event.target.value
                      );
                    }}
                  />
                </div>
              </div>

              {selectedTargetBrandId !== "DEFAULT_OPTION" ? (
                <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                  <div className="mx-2">
                    <InputGroup
                      type="select"
                      title={i18n.t("campus")}
                      placeholder={i18n.t("campus")}
                      value={selectedTargetCampusId}
                      optionList={retrieveTargetCampusDTOOptionList()}
                      onChange={(event) => {
                        handleOnChange(
                          "selectedTargetCampusId",
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              ) : null}

              {selectedTargetCampusId !== "DEFAULT_OPTION" ? (
                <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                  <div className="mx-2">
                    <InputGroup
                      type="select"
                      title={i18n.t("institution")}
                      placeholder={i18n.t("institution")}
                      value={selectedTargetInstitutionId}
                      optionList={retrieveTargetInstitutionDTOOptionList()}
                      onChange={(event) => {
                        handleOnChange(
                          "selectedTargetInstitutionId",
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              ) : null}

              {selectedTargetInstitutionId !== "DEFAULT_OPTION" ? (
                <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                  <div className="mx-2">
                    <InputGroup
                      type="select"
                      title={i18n.t("season")}
                      placeholder={i18n.t("season")}
                      value={selectedTargetSeasonId}
                      optionList={retrieveTargetSeasonDTOOptionList()}
                      onChange={(event) => {
                        handleOnChange(
                          "selectedTargetSeasonId",
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              ) : null}

              {selectedTargetSeasonId !== "DEFAULT_OPTION" ? (
                <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                  <div className="mx-2">
                    <InputGroup
                      type="select"
                      title={i18n.t("grade")}
                      placeholder={i18n.t("grade")}
                      value={selectedTargetGradeId}
                      optionList={retrieveTargetGradeDTOOptionList()}
                      onChange={(event) => {
                        handleOnChange(
                          "selectedTargetGradeId",
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              ) : null}

              {selectedTargetGradeId !== "DEFAULT_OPTION" ? (
                <DividerLine />
              ) : null}

              {selectedTargetGradeId !== "DEFAULT_OPTION" ? (
                <>
                  {SingleInformationIconTextItem(
                    i18n.t("please_select_target_coterie_type_and_version_code")
                  )}
                  <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                    <div className="mx-2">
                      <InputGroup
                        type="select"
                        title={i18n.t("coterie_type")}
                        placeholder={i18n.t("coterie_type")}
                        value={selectedTargetCoterieType}
                        optionList={filterCoterieTypeOptionList(
                          userInformation,
                          userProfile,
                          userProfileInstitution
                        )}
                        onChange={(event) => {
                          handleOnChange(
                            "selectedTargetCoterieType",
                            event.target.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  {selectedTargetCoterieType !== "DEFAULT_OPTION" &&
                  selectedTargetCoterieType !== "MANAGEMENT" ? (
                    <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                      <div className="mx-2">
                        <InputGroup
                          type="select"
                          title={i18n.t("version_code")}
                          placeholder={i18n.t("version_code")}
                          value={selectedTargetVersionCode}
                          optionList={
                            filteredTargetCategoryVersionCodeOptionList
                          }
                          onChange={(event) => {
                            handleOnChange(
                              "selectedTargetVersionCode",
                              event.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
            {canQueryTargetPlan &&
            selectedTargetVersionCode !== "DEFAULT_OPTION" ? (
              <>
                <DividerLine />
                {targetPlanLoading ? (
                  <BaseLoading
                    size="large"
                    color="#57C2D3"
                    secondaryColor="#57C2D3"
                  />
                ) : targetPlan && targetPlan !== "NOT_FOUND" ? (
                  <>
                    <div className="flex flex-row justify-end items-center">
                      <SecondaryButton
                        title={i18n.t("transfer")}
                        size="small"
                        onClick={handlePlanTransferOnClick}
                      />
                    </div>
                  </>
                ) : (
                  <div>
                    <p>{i18n.t("plan_not_found")}</p>
                  </div>
                )}
              </>
            ) : null}
          </>
        ) : null}
      </FullCard>
    );
  };

  const categoryVersionCodeOptionList = retrieveCategoryVersionCodeOptionList();
  const filteredFromCategoryVersionCodeOptionList =
    filterCategoryVersionCodeOptionListByGradeDTO(
      categoryVersionCodeOptionList,
      selectedFromCoterieType,
      fromGradeDTOList.find((gradeDTO) => gradeDTO.id === selectedFromGradeId)
    );

  const filteredTargetCategoryVersionCodeOptionList =
    filterCategoryVersionCodeOptionListByGradeDTO(
      categoryVersionCodeOptionList,
      selectedTargetCoterieType,
      targetGradeDTOList.find(
        (gradeDTO) => gradeDTO.id === selectedTargetGradeId
      )
    );

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default PlanTransferScreen;
