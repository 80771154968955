import { useEffect, useState } from "react";
import { useApp } from "../../context/AppContextProvider";
import {
  checkInsertPermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import { useNavigate } from "react-router-dom";
import FullCard from "../../components/cards/FullCard";
import InputGroup from "../../components/groups/InputGroup";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  queryCampusListByBrandId,
  queryInstitutionListByCampusId,
  retrieveAllBrandDTOList,
} from "../../requests/ManagementRequests";
import DividerLine from "../../components/divider/DividerLine";
import SingleInformationIconTextItem from "../../components/infos/SingleInformationIconTextItem";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import { activityTransfer } from "../../requests/CatchtivityRequests";
import { NotificationManager } from "react-notifications";
import { queryPublishingHouseLabelActionByPublishingHouseLabelIdListAndItemType } from "../../requests/LabelRequests";
import {
  retrieveBrandDTOOptionList,
  retrieveCampusDTOOptionList,
} from "../../utilization/ManagementUtilization";

const ActivityTransferScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const { userInformation, userProfile, userPublishingHouseLabelSet } =
    useAuth();
  const [brandDTOList, setBrandDTOList] = useState([]);
  const [campusDTOList, setCampusDTOList] = useState([]);
  const [institutionDTOList, setInstitutionDTOList] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState("DEFAULT_OPTION");
  const [selectedCampusId, setSelectedCampusId] = useState("DEFAULT_OPTION");
  const [selectedInstitutionId, setSelectedInstitutionId] =
    useState("DEFAULT_OPTION");
  const [
    includedPublishingHouseLabelIdList,
    setIncludedPublishingHouseLabelIdList,
  ] = useState([]);
  const [
    publishingHouseLabelActionDTOList,
    setPublishingHouseLabelActionDTOList,
  ] = useState([]);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (checkViewPermissionGranted("activity_transfer")) {
      retrieveAndSetBrandList();
    } else {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    if (selectedBrandId === "DEFAULT_OPTION") {
      setCampusDTOList([]);
      setInstitutionDTOList([]);
    } else {
      retrieveAndSetCampusDTOListByBrandId();
    }
  }, [selectedBrandId]);

  useEffect(() => {
    if (selectedCampusId === "DEFAULT_OPTION") {
      setInstitutionDTOList([]);
    } else {
      retrieveAndSetInstitutionDTOListByCampusId();
    }
  }, [selectedCampusId]);

  useEffect(() => {
    const retrieveAndSetPublishingHouseLabelActionDTOList = async () => {
      const { data, err } =
        await queryPublishingHouseLabelActionByPublishingHouseLabelIdListAndItemType(
          {
            publishingHouseLabelIdList: includedPublishingHouseLabelIdList,
            itemType: "ACTIVITY",
          }
        );
      if (err) {
        console.log(err);
        return;
      }
      setPublishingHouseLabelActionDTOList(data);
    };
    if (includedPublishingHouseLabelIdList.length > 0) {
      retrieveAndSetPublishingHouseLabelActionDTOList();
    } else {
      setPublishingHouseLabelActionDTOList([]);
    }
  }, [includedPublishingHouseLabelIdList]);

  const retrieveAndSetBrandList = async () => {
    const { data, err } = await retrieveAllBrandDTOList();
    if (err) {
      console.log(err);
    } else {
      setBrandDTOList(data);
    }
  };

  const retrieveAndSetCampusDTOListByBrandId = async () => {
    const { data, err } = await queryCampusListByBrandId(selectedBrandId);
    if (err) {
      console.log(err);
    } else {
      setCampusDTOList(data);
    }
  };

  const retrieveAndSetInstitutionDTOListByCampusId = async () => {
    const { data, err } = await queryInstitutionListByCampusId(
      selectedCampusId
    );
    if (err) {
      console.log(err);
    } else {
      setInstitutionDTOList(data);
    }
  };

  const retrieveInstitutionDTOOptionList = () => {
    return institutionDTOList.map((institutionDTO) => ({
      value: institutionDTO.id,
      text: institutionDTO.name,
    }));
  };

  const retrievePublishingHouseLabelOptionList = () => {
    return userPublishingHouseLabelSet.map((userPublishingHouseLabel) => ({
      text: userPublishingHouseLabel.name,
      value: userPublishingHouseLabel.id,
    }));
  };

  const filteredActivityIdList = () => {
    const currentActivityIdList = [];
    publishingHouseLabelActionDTOList.forEach((publishingHouseActionDTO) => {
      const { publishingHouseLabelItemDTOList } = publishingHouseActionDTO;
      currentActivityIdList.push(
        ...publishingHouseLabelItemDTOList.map((item) => item.itemId)
      );
    });
    const occurrences = currentActivityIdList.reduce((acc, curr) => {
      return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
    }, {});
    const filteredActivityIdList = [];
    Object.keys(occurrences).forEach((key) => {
      if (occurrences[key] === includedPublishingHouseLabelIdList.length) {
        filteredActivityIdList.push(parseFloat(key));
      }
    });
    return filteredActivityIdList;
  };

  const canTransferActivity = () => {
    if (includedPublishingHouseLabelIdList.length === 0) return false;
    if (
      selectedBrandId === "DEFAULT_OPTION" ||
      selectedCampusId === "DEFAULT_OPTION" ||
      selectedInstitutionId === "DEFAULT_OPTION"
    )
      return false;
    return true;
  };

  const handleOnChange = (type, value) => {
    if (type === "brandId") {
      setSelectedBrandId(value);
      setSelectedCampusId("DEFAULT_OPTION");
      setSelectedInstitutionId("DEFAULT_OPTION");
    } else if (type === "campusId") {
      setSelectedCampusId(value);
      setSelectedInstitutionId("DEFAULT_OPTION");
    } else if (type === "institutionId") {
      setSelectedInstitutionId(value);
    }
  };

  const handleAddPublishingHouseLabelOnClick = (publishingHouseLabelId) => {
    const foundUserPublishingHouseLabel = userPublishingHouseLabelSet.find(
      (userPublishingHouseLabel) =>
        userPublishingHouseLabel.id === publishingHouseLabelId
    );
    if (foundUserPublishingHouseLabel) {
      const foundIndex = includedPublishingHouseLabelIdList.findIndex(
        (includedPublishingHouseLabelId) =>
          includedPublishingHouseLabelId === publishingHouseLabelId
      );
      if (foundIndex === -1) {
        includedPublishingHouseLabelIdList.push(publishingHouseLabelId);
        setIncludedPublishingHouseLabelIdList(
          JSON.parse(JSON.stringify(includedPublishingHouseLabelIdList))
        );
      }
    }
  };

  const handleRemovePublishingHouseLabelOnClick = (publishingHouseLabelId) => {
    const foundIndex = includedPublishingHouseLabelIdList.findIndex(
      (includedPublishingHouseLabelId) =>
        includedPublishingHouseLabelId === publishingHouseLabelId
    );
    if (foundIndex !== -1) {
      includedPublishingHouseLabelIdList.splice(foundIndex, 1);
      setIncludedPublishingHouseLabelIdList(
        JSON.parse(JSON.stringify(includedPublishingHouseLabelIdList))
      );
    }
  };

  const handleActivityTransferOnClick = async () => {
    if (checkInsertPermissionGranted("activity_transfer")) {
      if (selectedBrandId === "DEFAULT_OPTION") {
        NotificationManager.error(i18n.t("brand_required_field"));
        return;
      }
      if (selectedCampusId === "DEFAULT_OPTION") {
        NotificationManager.error(i18n.t("campus_required_field"));
        return;
      }
      if (selectedInstitutionId === "DEFAULT_OPTION") {
        NotificationManager.error(i18n.t("institution_required_field"));
        return;
      }
      const { data, err } = await activityTransfer({
        brandId: selectedBrandId,
        campusId: selectedCampusId,
        institutionId: selectedInstitutionId,
        coterieType: "MANAGEMENT",
        userId: userInformation.id,
        userProfileId: userProfile.id,
        idList: filteredActivityIdList(),
        deleted: false,
      });
      if (err) {
        console.log(err);
        return;
      }
      navigate("/");
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const RenderPublishingHouseLabelListContent = () => {
    return (
      <div className="flex flex-row flex-wrap items-center gap-x-2">
        {includedPublishingHouseLabelIdList.map(
          (includedPublishingHouseLabelId, index) => {
            const foundLabel = userPublishingHouseLabelSet.find(
              (userPublishingHouseLabel) =>
                userPublishingHouseLabel.id === includedPublishingHouseLabelId
            );
            if (!foundLabel) return;
            return (
              <div
                key={index}
                className={`px-3 py-1 gap-x-3 border border-catchup-red rounded-md my-2 cursor-pointer`}
                onClick={() =>
                  handleRemovePublishingHouseLabelOnClick(foundLabel.id)
                }
              >
                <p className="text-sm">{foundLabel.name}</p>
              </div>
            );
          }
        )}
      </div>
    );
  };

  const RenderMainContent = () => {
    return (
      <FullCard isShadowed={true}>
        {SingleInformationIconTextItem(
          i18n.t("please_select_target_institution")
        )}
        <div className="flex flex-row flex-wrap items-center ">
          <div className="w-full md:w-1/2 lg:w-1/3">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("brand")}
                placeholder={i18n.t("brand")}
                value={selectedBrandId}
                optionList={retrieveBrandDTOOptionList(brandDTOList)}
                onChange={(event) => {
                  handleOnChange("brandId", event.target.value);
                }}
              />
            </div>
          </div>

          {selectedBrandId !== "DEFAULT_OPTION" ? (
            <div className="w-full md:w-1/2 lg:w-1/3">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("campus")}
                  placeholder={i18n.t("campus")}
                  value={selectedCampusId}
                  optionList={retrieveCampusDTOOptionList(campusDTOList)}
                  onChange={(event) => {
                    handleOnChange("campusId", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}

          {selectedCampusId !== "DEFAULT_OPTION" ? (
            <div className="w-full md:w-1/2 lg:w-1/3">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("institution")}
                  placeholder={i18n.t("institution")}
                  value={selectedInstitutionId}
                  optionList={retrieveInstitutionDTOOptionList()}
                  onChange={(event) => {
                    handleOnChange("institutionId", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>

        {selectedInstitutionId !== "DEFAULT_OPTION" ? (
          <>
            <DividerLine />
            {SingleInformationIconTextItem(
              i18n.t("please_select_labels_to_filter")
            )}
            <div className="flex flex-row flex-wrap items-center">
              <div className="w-full md:w-1/2 lg:w-1/3">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("label")}
                    placeholder={null}
                    value={null}
                    optionList={retrievePublishingHouseLabelOptionList()}
                    onChange={(event) => {
                      handleAddPublishingHouseLabelOnClick(
                        parseFloat(event.target.value)
                      );
                    }}
                  />
                </div>
              </div>
              <div className="flex-1">
                {RenderPublishingHouseLabelListContent()}
              </div>
            </div>

            <DividerLine />
            <div>
              <p className="font-semibold">
                {i18n.t("activity_count")}: {filteredActivityIdList().length}
              </p>
            </div>
            {canTransferActivity() ? (
              <div className="flex flex-row justify-end items-center">
                <SecondaryButton
                  title={i18n.t("transfer")}
                  size="small"
                  onClick={handleActivityTransferOnClick}
                />
              </div>
            ) : null}
          </>
        ) : null}
      </FullCard>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ActivityTransferScreen;
