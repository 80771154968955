import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import FullCard from "../../components/cards/FullCard";
import InputGroup from "../../components/groups/InputGroup";
import { useApp } from "../../context/AppContextProvider";
import i18n from "../../language/i18n";
import {
  addOrRemoveUserAuthorityToDefaultUserRole,
  retrieveAuthorityList,
  retrieveDefaultUserProfileAuthorityByRole,
} from "../../requests/ManagementRequests";
import {
  retrieveDefaultUserRoleOptionList,
  retrieveUserAuthorityGeneralOptionList,
} from "../../utilization/ManagementUtilization";
import {
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import BaseImage from "../../components/images/BaseImage";
import BaseLoading from "../../components/loading/BaseLoading";

const DefaultProfileAuthorityScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [selectedUserRoleAuthority, setSelectedUserRoleAuthority] =
    useState("DEFAULT_OPTION");
  const [defaultUserRoleAuthority, setDefaultUserRoleAuthority] =
    useState(null);
  const [userAuthorityList, setUserAuthorityList] = useState([]);
  const [includedAuthorityIdList, setIncludedAuthorityIdList] = useState([]);
  const [removedAuthorityIdList, setRemovedAuthorityIdList] = useState([]);
  const [selectedAvailableFilter, setSelectedAvailableFilter] = useState("");
  const [selectedAssignedFilter, setSelectedAssignedFilter] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetUserAuthorityList = async () => {
      const { data } = await retrieveAuthorityList();
      setUserAuthorityList(data.sort((a, b) => b.id - a.id));
    };
    if (checkViewPermissionGranted("default_user_role_authority")) {
      retrieveAndSetUserAuthorityList();
    } else {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetDefaultUserRoleAuthority = async () => {
      setLoading(true);
      const { data, err } = await retrieveDefaultUserProfileAuthorityByRole(
        selectedUserRoleAuthority
      );
      if (err) {
        console.log(err);
      } else {
        setDefaultUserRoleAuthority(data);
      }
      setLoading(false);
    };

    if (selectedUserRoleAuthority !== "DEFAULT_OPTION") {
      retrieveAndSetDefaultUserRoleAuthority();
    } else {
      setDefaultUserRoleAuthority(null);
    }
  }, [selectedUserRoleAuthority]);

  const retrieveNotIncludedAuthorityList = () => {
    if (defaultUserRoleAuthority) {
      const currentAuthorityDTOList = JSON.parse(
        JSON.stringify(
          userAuthorityList.filter(
            (userAuthority) =>
              defaultUserRoleAuthority.userAuthoritySet.findIndex(
                (userProfileAuthority) =>
                  userProfileAuthority.id === userAuthority.id
              ) === -1
          )
        )
      );
      includedAuthorityIdList.forEach((includedAuthorityId) => {
        const foundIndex = currentAuthorityDTOList.findIndex(
          (currentAuthority) =>
            parseFloat(currentAuthority.id) === parseFloat(includedAuthorityId)
        );
        if (foundIndex > -1) {
          currentAuthorityDTOList.splice(foundIndex, 1);
        }
      });
      removedAuthorityIdList.forEach((removedAuthorityId) => {
        const foundUserAuthority = userAuthorityList.find(
          (userAuthority) =>
            parseFloat(userAuthority.id) === parseFloat(removedAuthorityId)
        );
        currentAuthorityDTOList.push({ ...foundUserAuthority, enabled: true });
      });
      return currentAuthorityDTOList.filter((currentAuthorityDTO) =>
        currentAuthorityDTO.name.includes(selectedAvailableFilter)
      );
    }
    return [];
  };

  const retrieveIncludedAuthorityList = () => {
    if (defaultUserRoleAuthority) {
      const currentAuthorityDTOList = JSON.parse(
        JSON.stringify(defaultUserRoleAuthority.userAuthoritySet)
      );
      includedAuthorityIdList.forEach((includedAuthorityId) => {
        const foundUserAuthority = userAuthorityList.find(
          (userAuthority) =>
            parseFloat(userAuthority.id) === parseFloat(includedAuthorityId)
        );
        currentAuthorityDTOList.push({
          ...foundUserAuthority,
          enabled: true,
        });
      });
      removedAuthorityIdList.forEach((removedAuthorityId) => {
        const foundUserAuthorityIndex = currentAuthorityDTOList.findIndex(
          (currentAuthority) =>
            parseFloat(currentAuthority.id) === parseFloat(removedAuthorityId)
        );
        currentAuthorityDTOList.splice(foundUserAuthorityIndex, 1);
      });
      return currentAuthorityDTOList.filter((currentAuthorityDTO) =>
        currentAuthorityDTO.name.includes(selectedAssignedFilter)
      );
    }
    return [];
  };

  const handleOnChange = (type, value) => {
    if (type === "availableFilter") {
      setSelectedAvailableFilter(value);
    } else if (type === "assignedFilter") {
      setSelectedAssignedFilter(value);
    } else if (type === "selectedDefaultUserRoleAuthority") {
      setSelectedUserRoleAuthority(value);
      setIncludedAuthorityIdList([]);
      setRemovedAuthorityIdList([]);
    }
  };

  const handleAddOrRemoveMultipleRoleToProfileOnClick = async () => {
    if (checkUpdatePermissionGranted("default_user_role_authority")) {
      if (selectedUserRoleAuthority !== "DEFAULT_OPTION") {
        const { data, err } = await addOrRemoveUserAuthorityToDefaultUserRole(
          selectedUserRoleAuthority,
          includedAuthorityIdList,
          removedAuthorityIdList
        );
        if (err) {
          console.log(err);
        } else {
          navigate("/");
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const RenderSingleNonIncludedUserProfileAuthorityItem = (
    userProfileAuthorityDTO
  ) => {
    return (
      <div className="flex flex-row items-center my-2 p-2 border-b-2">
        <div className="mx-1">
          <div className="mx-1">
            <BaseImage
              src="/icons/plus.png"
              alt="plus"
              size="small"
              onClick={() => {
                const foundRemovedIndex = removedAuthorityIdList.findIndex(
                  (removedAuthorityId) =>
                    parseFloat(removedAuthorityId) ===
                    parseFloat(userProfileAuthorityDTO.id)
                );
                if (foundRemovedIndex !== -1) {
                  removedAuthorityIdList.splice(foundRemovedIndex, 1);
                  setRemovedAuthorityIdList(
                    JSON.parse(JSON.stringify(removedAuthorityIdList))
                  );
                } else {
                  const foundIndex = includedAuthorityIdList.findIndex(
                    (includedAuthorityId) =>
                      parseFloat(includedAuthorityId) ===
                      parseFloat(userProfileAuthorityDTO.id)
                  );
                  if (foundIndex === -1) {
                    includedAuthorityIdList.push(userProfileAuthorityDTO.id);
                    setIncludedAuthorityIdList(
                      JSON.parse(JSON.stringify(includedAuthorityIdList))
                    );
                  }
                }
              }}
            />
          </div>
        </div>
        <div className="">
          <p className="mx-1">{userProfileAuthorityDTO.name}</p>
        </div>
      </div>
    );
  };

  const RenderSingleIncludedUserProfileAuthorityItem = (
    userProfileAuthorityDTO
  ) => {
    return (
      <div className="flex flex-row items-center my-2 p-2 border-b-2">
        <div className="mx-1">
          <BaseImage
            src="/icons/remove-red.png"
            alt="remove-red"
            size="small"
            onClick={() => {
              const foundIncludedIndex = includedAuthorityIdList.findIndex(
                (includedAuthorityId) =>
                  parseFloat(includedAuthorityId) ===
                  parseFloat(userProfileAuthorityDTO.id)
              );
              if (foundIncludedIndex !== -1) {
                includedAuthorityIdList.splice(foundIncludedIndex, 1);
                setIncludedAuthorityIdList(
                  JSON.parse(JSON.stringify(includedAuthorityIdList))
                );
              } else {
                const foundIndex = removedAuthorityIdList.findIndex(
                  (removedAuthorityId) =>
                    parseFloat(removedAuthorityId) ===
                    parseFloat(userProfileAuthorityDTO.id)
                );
                if (foundIndex === -1) {
                  removedAuthorityIdList.push(userProfileAuthorityDTO.id);
                  setRemovedAuthorityIdList(
                    JSON.parse(JSON.stringify(removedAuthorityIdList))
                  );
                }
              }
            }}
          />
        </div>

        <div className="">
          <p className="mx-1">{userProfileAuthorityDTO.name}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">
        <FullCard isShadowed={true}>
          <div className="mb-5 w-1/2">
            <InputGroup
              type="select"
              title={i18n.t("default_user_role_authority")}
              value={selectedUserRoleAuthority}
              optionList={retrieveDefaultUserRoleOptionList()}
              onChange={(event) => {
                handleOnChange(
                  "selectedDefaultUserRoleAuthority",
                  event.target.value
                );
              }}
            />
          </div>
          {selectedUserRoleAuthority !== "DEFAULT_OPTION" ? (
            <>
              <div className="flex flex-row">
                <div className="w-1/2">
                  <div className="text-xl font-medium">
                    <p>{i18n.t("available_authorities")}</p>
                  </div>
                  <div className="m-2 p-2">
                    <InputGroup
                      type="select"
                      value={selectedAvailableFilter}
                      optionList={retrieveUserAuthorityGeneralOptionList()}
                      onChange={(event) => {
                        handleOnChange("availableFilter", event.target.value);
                      }}
                    />
                  </div>
                  <div className="m-2 p-2 h-catchup-user-authority overflow-auto">
                    {loading ? (
                      <BaseLoading
                        size="large"
                        color="#57C2D3"
                        secondaryColor="#57C2D3"
                      />
                    ) : (
                      retrieveNotIncludedAuthorityList().map(
                        (notIncludedAuthority) => (
                          <div key={notIncludedAuthority.id}>
                            {RenderSingleNonIncludedUserProfileAuthorityItem(
                              notIncludedAuthority
                            )}
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="text-xl font-medium">
                    <p>{i18n.t("assigned_authorities")}</p>
                  </div>
                  <div className="m-2 p-2">
                    <InputGroup
                      type="select"
                      value={selectedAssignedFilter}
                      optionList={retrieveUserAuthorityGeneralOptionList()}
                      onChange={(event) => {
                        handleOnChange("assignedFilter", event.target.value);
                      }}
                    />
                  </div>
                  <div className="m-2 p-2 h-catchup-user-authority overflow-auto">
                    {loading ? (
                      <BaseLoading
                        size="large"
                        color="#57C2D3"
                        secondaryColor="#57C2D3"
                      />
                    ) : (
                      retrieveIncludedAuthorityList().map(
                        (userProfileAuthorityDTO) => (
                          <div key={userProfileAuthorityDTO.id}>
                            {RenderSingleIncludedUserProfileAuthorityItem(
                              userProfileAuthorityDTO
                            )}
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-4 ml-auto">
                <PrimaryButton
                  title={i18n.t("add")}
                  size="small"
                  onClick={handleAddOrRemoveMultipleRoleToProfileOnClick}
                />
              </div>
            </>
          ) : null}
        </FullCard>
      </div>
    </div>
  );
};

export default DefaultProfileAuthorityScreen;
