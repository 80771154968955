import { useNavigate } from "react-router-dom";
import {
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import { useEffect, useState } from "react";
import FullCard from "../../components/cards/FullCard";
import i18n from "../../language/i18n";
import { useAuth } from "../../context/UserContextProvider";
import InputGroup from "../../components/groups/InputGroup";
import {
  filterCoterieTypeOptionList,
  retrieveInstitutionDTOOptionList,
} from "../../utilization/ManagementUtilization";
import DividerLine from "../../components/divider/DividerLine";
import {
  queryBranchListByParams,
  queryCampusListByBrandId,
  queryInstitutionListByCampusId,
  queryInstitutionListByCampusIdList,
} from "../../requests/ManagementRequests";
import {
  createEtudeListFromBaseActivityList,
  queryAllActivityCategoryScoreListByParams,
  queryAllCatchxamApplicationDTOListByFilterActive,
  queryAllEtudeListByParams,
  retrieveCatchxamApplicationInDetail,
} from "../../requests/CatchtivityRequests";
import BaseImage from "../../components/images/BaseImage";
import { retrieveColorByScore } from "../../utilization/CatchtivityUtilization";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import ProgressBar from "../../components/progress-bar/ProgressBar";
import { NotificationManager } from "react-notifications";
import CreateButton from "../../components/buttons/CreateButton";
import BaseLoading from "../../components/loading/BaseLoading";

const AssignEtudeScreen = () => {
  const navigate = useNavigate();
  const {
    userProfile,
    userInformation,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
    categorySet,
  } = useAuth();
  const [
    activeCatchxamApplicationDTOList,
    setActiveCatchxamApplicationDTOList,
  ] = useState([]);
  const [institutionDTOList, setInstitutionDTOList] = useState([]);
  const [filterCoterieType, setFilterCoterieType] = useState(
    userProfile?.coterieType
      ? userProfile?.coterieType
      : userProfile?.coterieTypeList && userProfile?.coterieTypeList.length > 0
      ? userProfile?.coterieTypeList[0]
      : "DEFAULT_OPTION"
  );
  const [pageState, setPageState] = useState(0);
  const [activeLoading, setActiveLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [etudeLoading, setEtudeLoading] = useState(false);
  const [selectedCatchxamApplicationId, setSelectedCatchxamApplicationId] =
    useState("DEFAULT_OPTION");
  const [filterInstitutionId, setFilterInstitutionId] = useState(
    userProfileInstitution ? userProfileInstitution.id : "DEFAULT_OPTION"
  );
  const [selectedSeasonId, setSelectedSeasonId] = useState("DEFAULT_OPTION");
  const [selectedGradeId, setSelectedGradeId] = useState("DEFAULT_OPTION");
  const [selectedBranchId, setSelectedBranchId] = useState("DEFAULT_OPTION");
  const [catchxamApplicationDetail, setCatchxamApplicationDetail] =
    useState(null);
  const [selectedUserProfileIdList, setSelectedUserProfileIdList] = useState(
    []
  );
  const [activityCategoryScoreList, setActivityCategoryScoreList] = useState(
    []
  );
  const [activityCategoryScoreMapList, setActivityCategoryScoreMapList] =
    useState([]);
  const [etudeList, setEtudeList] = useState([]);

  useEffect(() => {
    if (!checkViewPermissionGranted("assign_etude")) {
      navigate("/permission-error");
      return;
    }
    retrieveAndSetActiveCatchxamApplicationListByFilter();
  }, []);

  useEffect(() => {
    const queryAndSetInstitutionListByBrandId = async () => {
      const { data: campusData, err: campusErr } =
        await queryCampusListByBrandId(userProfileBrand.id);
      if (campusErr) {
        console.log(campusErr);
      } else {
        const { data: institutionData, err: institutionErr } =
          await queryInstitutionListByCampusIdList(
            campusData.map((campus) => campus.id)
          );
        if (institutionErr) {
          console.log(institutionErr);
        } else {
          setInstitutionDTOList(institutionData);
        }
      }
    };
    const queryAndSetInstitutionListByCampusId = async () => {
      const { data: institutionData, err: institutionErr } =
        await queryInstitutionListByCampusId(userProfileCampus.id);
      if (institutionErr) {
        console.log(institutionErr);
      } else {
        setInstitutionDTOList(institutionData);
      }
    };
    if (!userProfileInstitution) {
      if (userProfileCampus) {
        queryAndSetInstitutionListByCampusId();
      } else {
        queryAndSetInstitutionListByBrandId();
      }
    } else {
      setInstitutionDTOList([userProfileInstitution]);
    }
  }, [userProfileInstitution]);

  useEffect(() => {
    if (
      filterCoterieType !== "DEFAULT_OPTION" &&
      filterInstitutionId !== "DEFAULT_OPTION"
    ) {
      retrieveAndSetActiveCatchxamApplicationListByFilter();
    }
  }, [filterCoterieType, filterInstitutionId]);

  useEffect(() => {
    if (selectedCatchxamApplicationId !== "DEFAULT_OPTION") {
      setPageState(0);
      setSelectedUserProfileIdList([]);
      setSelectedBranchId("DEFAULT_OPTION");
      setSelectedGradeId("DEFAULT_OPTION");
      setSelectedSeasonId("DEFAULT_OPTION");
      retrieveAndSetCatchxamApplicationInDetail();
    }
  }, [selectedCatchxamApplicationId]);

  useEffect(() => {
    if (userProfileBranch) {
      setSelectedSeasonId(userProfileBranch.gradeDTO.seasonDTO.id);
      setSelectedGradeId(userProfileBranch.gradeDTO.id);
      setSelectedBranchId(userProfileBranch.id);
    } else if (userProfileGrade) {
      setSelectedSeasonId(userProfileGrade.seasonDTO.id);
      setSelectedGradeId(userProfileGrade.id);
    } else if (userProfileSeason) {
      setSelectedSeasonId(userProfileSeason.id);
    }
  }, [catchxamApplicationDetail]);

  useEffect(() => {
    if (selectedSeasonId === "DEFAULT_OPTION") return;
    if (selectedGradeId === "DEFAULT_OPTION") return;
    if (selectedBranchId === "DEFAULT_OPTION") return;
    if (selectedCatchxamApplicationId === "DEFAULT_OPTION") return;
    retrieveAndSetEtudeList();
  }, [selectedSeasonId, selectedGradeId, selectedBranchId]);

  useEffect(() => {
    if (pageState === 1) {
      retrieveAndSetActivityCategoryScoreListByUserIdUserProfileIdAndCatchxamApplicationId();
    } else {
      setActivityCategoryScoreList([]);
    }
  }, [pageState]);

  useEffect(() => {
    if (activityCategoryScoreList.length > 0) {
      const currentActivityCategoryScoreMapList = [];
      const selectedUserDTOList = filterSelectedUserDTOList();
      for (const selectedUserDTO of selectedUserDTOList) {
        currentActivityCategoryScoreMapList.push({
          userId: selectedUserDTO.userId,
          userProfileId: selectedUserDTO.userProfileId,
          categoryScoreMap: constructCategoryScoreMapByUserIdUserProfileId(
            selectedUserDTO.userId,
            selectedUserDTO.userProfileId
          ),
        });
      }
      setActivityCategoryScoreMapList(currentActivityCategoryScoreMapList);
    } else {
      setActivityCategoryScoreMapList([]);
    }
  }, [catchxamApplicationDetail, activityCategoryScoreList]);

  const retrieveAndSetActivityCategoryScoreListByUserIdUserProfileIdAndCatchxamApplicationId =
    async () => {
      // TODO: Buraya belki userIdList de eklenebilir
      const { data, err } = await queryAllActivityCategoryScoreListByParams({
        userProfileIdList: selectedUserProfileIdList,
        catchxamApplicationId: selectedCatchxamApplicationId,
      });
      if (err) {
        console.log(err);
        return;
      }
      setActivityCategoryScoreList(data);
    };

  const retrieveAndSetEtudeList = async () => {
    setEtudeLoading(true);
    const { data, err } = await queryAllEtudeListByParams({
      catchxamApplicationId: selectedCatchxamApplicationId,
      seasonId: selectedSeasonId,
      gradeId: selectedGradeId,
      branchId: selectedBranchId,
    });
    if (err) {
      NotificationManager.error(i18n.t("error_occurred_on_query_etudes"));
      return;
    }
    setEtudeList(data);
    setSelectedUserProfileIdList(data.map((item) => item.userProfileId));
    setEtudeLoading(false);
  };

  const filterAvailableApplicationUserDetailList = () => {
    let filteredApplicationList = [];
    if (catchxamApplicationDetail) {
      if (userProfileBranch) {
        filteredApplicationList =
          catchxamApplicationDetail.catchxamUserDTOList.filter(
            (catchxamUserDTO) =>
              parseFloat(catchxamUserDTO.branchId) ===
              parseFloat(userProfileBranch.id)
          );
      } else if (userProfileGrade) {
        filteredApplicationList =
          catchxamApplicationDetail.catchxamUserDTOList.filter(
            (catchxamUserDTO) =>
              parseFloat(catchxamUserDTO.gradeId) ===
              parseFloat(userProfileGrade.id)
          );
      } else if (userProfileSeason) {
        filteredApplicationList =
          catchxamApplicationDetail.catchxamUserDTOList.filter(
            (catchxamUserDTO) =>
              parseFloat(catchxamUserDTO.seasonId) ===
              parseFloat(userProfileSeason.id)
          );
      } else if (userProfileInstitution) {
        filteredApplicationList =
          catchxamApplicationDetail.catchxamUserDTOList.filter(
            (catchxamUserDTO) =>
              parseFloat(catchxamUserDTO.institutionId) ===
              parseFloat(userProfileInstitution.id)
          );
      } else {
        return catchxamApplicationDetail.catchxamUserDTOList;
      }
    }
    return filteredApplicationList;
  };

  const filterSelectedApplicationUserDetailList = () => {
    if (catchxamApplicationDetail) {
      return catchxamApplicationDetail.catchxamUserDTOList.filter(
        (catchxamUserDTO) =>
          parseFloat(catchxamUserDTO.branchId) ===
            parseFloat(selectedBranchId) &&
          parseFloat(catchxamUserDTO.gradeId) === parseFloat(selectedGradeId) &&
          parseFloat(catchxamUserDTO.seasonId) ===
            parseFloat(selectedSeasonId) &&
          parseFloat(catchxamUserDTO.institutionId) ===
            parseFloat(filterInstitutionId)
      );
    }
    return [];
  };

  const retrieveAndSetActiveCatchxamApplicationListByFilter = async () => {
    setActiveLoading(true);
    // setSelectedCatchxamApplicationId("DEFAULT_OPTION");
    let queryBranchParams;
    if (userProfileBranch) {
      queryBranchParams = { branchIdList: [userProfileBranch.id] };
    } else if (userProfileGrade) {
      queryBranchParams = { gradeIdList: [userProfileGrade.id] };
    } else if (userProfileSeason) {
      queryBranchParams = { seasonIdList: [userProfileSeason.id] };
    } else {
      queryBranchParams = { institutionIdList: [filterInstitutionId] };
    }
    queryBranchParams.deleted = false;
    const { data, err } = await queryBranchListByParams(queryBranchParams);
    if (err) {
      console.log(err);
    } else {
      const seasonIdList = [];
      const gradeIdList = [];
      const branchIdList = [];
      for (const foundBranch of data) {
        if (
          branchIdList.findIndex((branchId) => branchId === foundBranch.id) ===
          -1
        ) {
          branchIdList.push(foundBranch.id);
        }
        if (
          gradeIdList.findIndex(
            (gradeId) => gradeId === foundBranch.gradeDTO.id
          ) === -1
        ) {
          gradeIdList.push(foundBranch.gradeDTO.id);
        }
        if (
          seasonIdList.findIndex(
            (seasonId) => seasonId === foundBranch.gradeDTO.seasonDTO.id
          ) === -1
        ) {
          seasonIdList.push(foundBranch.gradeDTO.seasonDTO.id);
        }
      }
      const { data: activeData, err: activeErr } =
        await queryAllCatchxamApplicationDTOListByFilterActive({
          seasonIdList,
          gradeIdList,
          branchIdList,
          coterieType: filterCoterieType,
        });
      if (activeErr) {
        console.log(activeErr);
      } else {
        setActiveCatchxamApplicationDTOList(
          activeData
            .sort((a, b) => a.endAt - b.endAt)
            .filter((item) => item.isEvaluated)
        );
      }
    }
    setActiveLoading(false);
  };

  const retrieveAndSetCatchxamApplicationInDetail = async () => {
    setDetailLoading(true);
    const { data, err } = await retrieveCatchxamApplicationInDetail(
      selectedCatchxamApplicationId
    );
    if (err) {
      console.log(err);
      return;
    }
    setCatchxamApplicationDetail(data);
    setDetailLoading(false);
  };

  const retrieveCatchxamApplicationOptionList = () => {
    return activeCatchxamApplicationDTOList.map((catchxamApplicationDTO) => ({
      value: catchxamApplicationDTO.id,
      text: catchxamApplicationDTO.catchxamDTO.name,
    }));
  };

  const retrieveSeasonDTOOptionList = () => {
    const filteredApplicationSet = [];
    filterAvailableApplicationUserDetailList()
      .filter(
        (catchxamUserDTO) =>
          parseFloat(catchxamUserDTO.institutionId) ===
          parseFloat(filterInstitutionId)
      )
      .forEach((filterApplication) => {
        if (
          filteredApplicationSet.findIndex(
            (newFilterApplication) =>
              parseFloat(newFilterApplication.seasonId) ===
              parseFloat(filterApplication.seasonId)
          ) === -1
        ) {
          filteredApplicationSet.push(filterApplication);
        }
      });

    return filteredApplicationSet.map((catchxamUserDTO) => ({
      value: catchxamUserDTO.seasonId,
      text: catchxamUserDTO.seasonName,
    }));
  };

  const retrieveGradeDTOOptionList = () => {
    const filteredApplicationSet = [];
    filterAvailableApplicationUserDetailList()
      .filter(
        (catchxamUserDTO) =>
          parseFloat(catchxamUserDTO.seasonId) === parseFloat(selectedSeasonId)
      )
      .forEach((filterApplication) => {
        if (
          filteredApplicationSet.findIndex(
            (newFilterApplication) =>
              parseFloat(newFilterApplication.gradeId) ===
              parseFloat(filterApplication.gradeId)
          ) === -1
        ) {
          filteredApplicationSet.push(filterApplication);
        }
      });

    return filteredApplicationSet.map((catchxamUserDTO) => ({
      value: catchxamUserDTO.gradeId,
      text: catchxamUserDTO.gradeName,
    }));
  };

  const retrieveBranchDTOOptionList = () => {
    const filteredApplicationSet = [];
    filterAvailableApplicationUserDetailList()
      .filter(
        (catchxamUserDTO) =>
          parseFloat(catchxamUserDTO.gradeId) === parseFloat(selectedGradeId)
      )
      .forEach((filterApplication) => {
        if (
          filteredApplicationSet.findIndex(
            (newFilterApplication) =>
              parseFloat(newFilterApplication.branchId) ===
              parseFloat(filterApplication.branchId)
          ) === -1
        ) {
          filteredApplicationSet.push(filterApplication);
        }
      });

    return filteredApplicationSet.map((catchxamUserDTO) => ({
      value: catchxamUserDTO.branchId,
      text: catchxamUserDTO.branchName,
    }));
  };

  const calculateUserPerformance = (catchxamUserDTO) => {
    const totalLength =
      catchxamApplicationDetail.catchxamApplicationDTO.catchxamDTO
        .activityDTOList.length;
    if (totalLength === 0) return 0;
    return (catchxamUserDTO.score / totalLength) * 100;
  };

  const calculateMaxWeight = (categoryScoreMap) => {
    let max = 5;
    Object.keys(categoryScoreMap).forEach((categoryScoreKey) => {
      const { count, score } = categoryScoreMap[categoryScoreKey];
      if (score !== 0) {
        if (count / score > max) {
          max = count / score;
        }
      }
    });
    return max;
  };

  const checkUserProfileExists = (userProfileId) => {
    return (
      selectedUserProfileIdList.findIndex(
        (selectedUserProfileId) =>
          selectedUserProfileId === parseFloat(userProfileId)
      ) > -1
    );
  };

  const checkExistsInEtudeList = (userProfileId) => {
    return (
      etudeList.findIndex((etude) => etude.userProfileId === userProfileId) > -1
    );
  };

  const handleOnChange = (type, value) => {
    if (type === "catchxamApplicationId") {
      setSelectedCatchxamApplicationId(value);
    } else if (type === "filterCoterieType") {
      setFilterCoterieType(value);
    } else if (type === "filterInstitutionId") {
      setFilterInstitutionId(value);
    } else if (type === "seasonId") {
      setSelectedSeasonId(value);
      setSelectedGradeId("DEFAULT_OPTION");
      setSelectedBranchId("DEFAULT_OPTION");
    } else if (type === "gradeId") {
      setSelectedGradeId(value);
      setSelectedBranchId("DEFAULT_OPTION");
    } else if (type === "branchId") {
      setSelectedBranchId(value);
    }
  };

  const handleAddUserProfileIdToSelectedUserProfileIdList = (userProfileId) => {
    const foundIndex = selectedUserProfileIdList.findIndex(
      (selectedUserProfileId) =>
        selectedUserProfileId === parseFloat(userProfileId)
    );
    if (foundIndex === -1) {
      selectedUserProfileIdList.push(parseFloat(userProfileId));
      setSelectedUserProfileIdList([...selectedUserProfileIdList]);
    }
  };

  const handleRemoveUserProfileIdFromSelectedUserProfileIdList = (
    userProfileId
  ) => {
    if (checkExistsInEtudeList(userProfileId)) {
      return;
    }
    const foundIndex = selectedUserProfileIdList.findIndex(
      (selectedUserProfileId) =>
        selectedUserProfileId === parseFloat(userProfileId)
    );
    if (foundIndex > -1) {
      selectedUserProfileIdList.splice(foundIndex, 1);
      setSelectedUserProfileIdList([...selectedUserProfileIdList]);
    }
  };

  const handleCreateEtudeOnClick = async () => {
    const constructedEtudeList = [];
    activityCategoryScoreMapList.forEach((activityCategoryScoreMap) => {
      const { userId, userProfileId, categoryScoreMap } =
        activityCategoryScoreMap;
      const etudeCategorySet = Object.keys(categoryScoreMap).map(
        (categoryScoreMapKey) => ({
          categoryId: categoryScoreMapKey,
          weight: categoryScoreMap[categoryScoreMapKey].weight,
        })
      );
      constructedEtudeList.push({
        userId,
        userProfileId,
        seasonId: selectedSeasonId,
        gradeId: selectedGradeId,
        branchId: selectedBranchId,
        catchxamApplication: {
          id: selectedCatchxamApplicationId,
        },
        etudeCategorySet,
      });
    });

    const { data, err } = await createEtudeListFromBaseActivityList(
      constructedEtudeList
    );
    if (err) {
      NotificationManager.error(i18n.t("error_on_etude_creation"));
      return;
    }
    setPageState(0);
    retrieveAndSetEtudeList();
  };

  const handleUpdateEtudeOnClick = async () => {
    const constructedEtudeList = [];
    const filteredActivityCategoryScoreMapList =
      activityCategoryScoreMapList.filter(
        (activityCategoryScoreMap) =>
          etudeList.findIndex(
            (etude) =>
              etude.userId === activityCategoryScoreMap.userId &&
              etude.userProfileId === activityCategoryScoreMap.userProfileId
          ) === -1
      );
    filteredActivityCategoryScoreMapList.forEach((activityCategoryScoreMap) => {
      const { userId, userProfileId, categoryScoreMap } =
        activityCategoryScoreMap;
      const etudeCategorySet = Object.keys(categoryScoreMap).map(
        (categoryScoreMapKey) => ({
          categoryId: categoryScoreMapKey,
          weight: categoryScoreMap[categoryScoreMapKey].weight,
        })
      );
      constructedEtudeList.push({
        userId,
        userProfileId,
        seasonId: selectedSeasonId,
        gradeId: selectedGradeId,
        branchId: selectedBranchId,
        catchxamApplication: {
          id: selectedCatchxamApplicationId,
        },
        etudeCategorySet,
      });
    });
    if (constructedEtudeList.length !== 0) {
      const { data, err } = await createEtudeListFromBaseActivityList(
        constructedEtudeList
      );
      if (err) {
        NotificationManager.error(i18n.t("error_on_etude_update"));
        return;
      }
    }

    setPageState(0);
    retrieveAndSetEtudeList();
  };

  const filterSelectedUserDTOList = () => {
    if (!catchxamApplicationDetail) return [];
    const { catchxamUserDTOList } = catchxamApplicationDetail;
    return catchxamUserDTOList.filter(
      (catchxamUserDTO) =>
        selectedUserProfileIdList.findIndex(
          (selectedUserProfileId) =>
            selectedUserProfileId === catchxamUserDTO.userProfileId
        ) > -1
    );
  };

  const constructCategoryScoreMapByUserIdUserProfileId = (
    userId,
    userProfileId
  ) => {
    const categoryScoreMap = {};
    const foundActivityCategoryScoreList = activityCategoryScoreList.filter(
      (activityCategoryScore) =>
        activityCategoryScore.userId === userId &&
        activityCategoryScore.userProfileId === userProfileId
    );
    const { catchxamApplicationDTO } = catchxamApplicationDetail;
    const { catchxamDTO } = catchxamApplicationDTO;
    const { activityDTOList } = catchxamDTO;
    for (const activityDTO of activityDTOList) {
      const { categoryIdList } = activityDTO;
      for (const categoryId of categoryIdList) {
        if (categoryScoreMap[categoryId]) {
          categoryScoreMap[categoryId].count =
            categoryScoreMap[categoryId].count + 1;
        } else {
          const filteredActivityCategoryScoreList =
            foundActivityCategoryScoreList.filter(
              (activityCategoryScore) =>
                activityCategoryScore.categoryId === categoryId
            );
          let score = 0;
          filteredActivityCategoryScoreList.forEach(
            (item) => (score += item.score)
          );
          categoryScoreMap[categoryId] = {
            count: 1,
            score,
          };
        }
      }
    }
    const max = calculateMaxWeight(categoryScoreMap);
    Object.keys(categoryScoreMap).forEach((categoryScoreMapKey) => {
      const { count, score } = categoryScoreMap[categoryScoreMapKey];
      const weight =
        score === 0 ? max : count / score === 1 ? 0 : count / score;
      categoryScoreMap[categoryScoreMapKey].weight = weight;
    });

    return categoryScoreMap;
  };

  const RenderCatchxamApplicationPageContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <div className="relative">
            {etudeLoading ? (
              <div
                className="absolute top-1/2 left-1/2"
                style={{ transform: "translate(-50%, -50%)" }}
              >
                <BaseLoading
                  size="large"
                  color="#57C2D3"
                  secondaryColor="#57C2D3"
                />
              </div>
            ) : null}
            {selectedSeasonId !== "DEFAULT_OPTION" &&
            selectedGradeId !== "DEFAULT_OPTION" &&
            selectedBranchId !== "DEFAULT_OPTION" ? (
              <div className={`${etudeLoading ? "opacity-50" : "opacity-100"}`}>
                <div className="">
                  <p className="font-semibold text-lg">{i18n.t("learners")}</p>
                </div>
                <div className="flex flex-row items-center flex-wrap gap-x-3">
                  {filterSelectedApplicationUserDetailList().map(
                    (catchxamUserDTO, index) => (
                      <div
                        key={index}
                        className={`w-[150px] lg:w-[200px] min-h-[220px] flex flex-col items-center justify-start my-3 p-3 cursor-pointer ${
                          checkUserProfileExists(catchxamUserDTO.userProfileId)
                            ? "opacity-100"
                            : "opacity-30"
                        } ${
                          checkExistsInEtudeList(catchxamUserDTO.userProfileId)
                            ? "border border-catchup-dark-blue rounded-catchup-xlarge"
                            : ""
                        } transition-all duration-300`}
                        onClick={() => {
                          if (
                            checkUserProfileExists(
                              catchxamUserDTO.userProfileId
                            )
                          ) {
                            handleRemoveUserProfileIdFromSelectedUserProfileIdList(
                              catchxamUserDTO.userProfileId
                            );
                          } else {
                            handleAddUserProfileIdToSelectedUserProfileIdList(
                              catchxamUserDTO.userProfileId
                            );
                          }
                        }}
                      >
                        <div className="">
                          <BaseImage
                            size="xlarge"
                            src={
                              checkUserProfileExists(
                                catchxamUserDTO.userProfileId
                              )
                                ? "/icons/user-profile-started.png"
                                : "/icons/user-profile-not-started.png"
                            }
                            alt="user-profile-completion-status"
                          />
                        </div>
                        <div className="my-2">
                          <p className="text-center">
                            {catchxamUserDTO.firstName}{" "}
                            {catchxamUserDTO.lastName}
                          </p>
                          <p className="text-center italic">
                            {i18n.t(catchxamUserDTO.completionStatus)}
                          </p>
                          {catchxamApplicationDetail.catchxamApplicationDTO
                            .isEvaluated ? (
                            <>
                              <p
                                className="text-center text-2xl"
                                style={{
                                  color: retrieveColorByScore(
                                    calculateUserPerformance(catchxamUserDTO)
                                  ),
                                }}
                              >
                                {catchxamUserDTO.score > 0
                                  ? `${calculateUserPerformance(
                                      catchxamUserDTO
                                    ).toFixed(2)}%`
                                  : "0.00%"}
                              </p>
                              <p className="text-center italic">
                                (
                                {`${(
                                  catchxamUserDTO.timeSpent /
                                  1000 /
                                  60
                                ).toFixed(2)} ${i18n.t("minute_abbrevation")}`}
                                )
                              </p>
                            </>
                          ) : null}
                        </div>
                      </div>
                    )
                  )}
                </div>
                {selectedUserProfileIdList.length > 0 ? (
                  <>
                    <DividerLine />
                    <div className="flex flex-row justify-end">
                      <SecondaryButton
                        title={i18n.t("continue")}
                        size="small"
                        onClick={() => {
                          setPageState(1);
                        }}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <div>
            <div className="">
              <p className="font-semibold text-lg">{i18n.t("learners")}</p>
            </div>
            <div className="mx-2 my-2">
              {filterSelectedUserDTOList().map((selectedUserDTO) => {
                const foundActivityCategoryScoreMap =
                  activityCategoryScoreMapList.find(
                    (activityCategoryScoreMap) =>
                      activityCategoryScoreMap.userId ===
                        selectedUserDTO.userId &&
                      activityCategoryScoreMap.userProfileId ===
                        selectedUserDTO.userProfileId
                  );
                if (!foundActivityCategoryScoreMap) return;
                const { categoryScoreMap } = foundActivityCategoryScoreMap;
                const max = calculateMaxWeight(categoryScoreMap);
                return (
                  <div className="my-4">
                    <div>
                      <p>
                        {selectedUserDTO.firstName} {selectedUserDTO.lastName}
                      </p>
                    </div>

                    <DividerLine />
                    <div className="px-4">
                      {Object.keys(categoryScoreMap).map((categoryScoreKey) => {
                        const { count, score, weight } =
                          categoryScoreMap[categoryScoreKey];
                        const foundCategory = categorySet.find(
                          (category) =>
                            category.id === parseFloat(categoryScoreKey)
                        );
                        const scorePercentage = (score / count) * 100;
                        const { name } = foundCategory;
                        return (
                          <div className="flex flex-row items-center">
                            <div className="w-1/2">
                              <div>
                                <p className="italic text-base">{name}</p>
                              </div>
                            </div>
                            <div className="w-1/2">
                              <div className="flex flex-row items-center">
                                <div className="w-[20px]">
                                  <p
                                    className="font-semibold"
                                    style={{
                                      color:
                                        retrieveColorByScore(scorePercentage),
                                    }}
                                  >
                                    {weight.toFixed(0)}
                                  </p>
                                </div>
                                <div className="flex-1">
                                  <div className="mx-2 my-2">
                                    <ProgressBar
                                      color={"bg-catchup-dark-blue"}
                                      height={"h-3"}
                                      remainingTimeInSeconds={max - weight}
                                      totalTimeInSeconds={max}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <>
              <DividerLine />
              <div className="flex flex-row justify-between">
                <PrimaryButton
                  title={i18n.t("go_back")}
                  size="small"
                  onClick={() => {
                    setPageState(0);
                  }}
                />
                {etudeList.length > 0 ? (
                  checkUpdatePermissionGranted("assign_etude") ? (
                    <SecondaryButton
                      title={i18n.t("update")}
                      size="medium"
                      onClick={() => {
                        handleUpdateEtudeOnClick();
                      }}
                    />
                  ) : null
                ) : checkInsertPermissionGranted("assign_etude") ? (
                  <CreateButton
                    title={i18n.t("create")}
                    size="medium"
                    onClick={() => {
                      handleCreateEtudeOnClick();
                    }}
                  />
                ) : null}
              </div>
            </>
          </div>
        </div>
      </div>
    );
  };

  const RenderCatchxamApplicationDetailContent = () => {
    if (!catchxamApplicationDetail) return;
    return (
      <div>
        <div className="">
          <p className="font-semibold text-lg">{i18n.t("branch_selection")}</p>
        </div>
        <div className="flex flex-row flex-wrap items-center mb-3">
          <div className="w-1/2 lg:w-1/3 xl:w-1/4">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("season_name")}
                placeholder={i18n.t("season_name")}
                value={selectedSeasonId}
                optionList={retrieveSeasonDTOOptionList()}
                onChange={(event) => {
                  handleOnChange("seasonId", event.target.value);
                }}
              />
            </div>
          </div>

          {selectedSeasonId !== "DEFAULT_OPTION" ? (
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("grade_name")}
                  placeholder={i18n.t("grade_name")}
                  value={selectedGradeId}
                  optionList={retrieveGradeDTOOptionList()}
                  onChange={(event) => {
                    handleOnChange("gradeId", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}

          {selectedGradeId !== "DEFAULT_OPTION" ? (
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("branch_name")}
                  placeholder={i18n.t("branch_name")}
                  value={selectedBranchId}
                  optionList={retrieveBranchDTOOptionList()}
                  onChange={(event) => {
                    handleOnChange("branchId", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
        <DividerLine />
        {RenderCatchxamApplicationPageContent()}
      </div>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">
        <FullCard isShadowed={true}>
          <div className="">
            <p className="font-semibold text-lg">
              {i18n.t("catchxam_application_selection")}
            </p>
          </div>

          <div className="flex flex-row items-center flex-wrap">
            {userProfile.coterieType &&
            userProfile.coterieType !== "MANAGEMENT" ? null : (
              <div className="w-1/2 lg:w-1/3">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("coterie_type")}
                    placeholder={i18n.t("coterie_type")}
                    value={filterCoterieType}
                    optionList={filterCoterieTypeOptionList(
                      userInformation,
                      userProfile,
                      userProfileInstitution
                    )}
                    onChange={(event) => {
                      handleOnChange("filterCoterieType", event.target.value);
                    }}
                  />
                </div>
              </div>
            )}
            {userProfileInstitution ? null : (
              <div className="w-1/2 lg:w-1/3">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("institution_name")}
                    placeholder={i18n.t("institution_name")}
                    value={filterInstitutionId}
                    optionList={retrieveInstitutionDTOOptionList(
                      institutionDTOList
                    )}
                    onChange={(event) => {
                      handleOnChange("filterInstitutionId", event.target.value);
                    }}
                  />
                </div>
              </div>
            )}

            {filterInstitutionId === "DEFAULT_OPTION" ||
            filterCoterieType === "DEFAULT_OPTION" ? null : (
              <div className="w-1/2 lg:w-1/3">
                <div className="mx-2">
                  {activeLoading ? (
                    <BaseLoading
                      size="small"
                      color="#57C2D3"
                      secondaryColor="#57C2D3"
                    />
                  ) : (
                    <InputGroup
                      type="select"
                      title={i18n.t("catchxam_application")}
                      placeholder={i18n.t("catchxam_application")}
                      value={selectedCatchxamApplicationId}
                      optionList={retrieveCatchxamApplicationOptionList()}
                      onChange={(event) => {
                        handleOnChange(
                          "catchxamApplicationId",
                          event.target.value
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          {selectedCatchxamApplicationId !== "DEFAULT_OPTION" ? (
            <DividerLine />
          ) : null}

          {detailLoading ? (
            <BaseLoading
              size="large"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          ) : (
            RenderCatchxamApplicationDetailContent()
          )}
        </FullCard>
      </div>
    </div>
  );
};

export default AssignEtudeScreen;
