import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FullCard from "../../components/cards/FullCard";
import i18n from "../../language/i18n";
import { retrieveGradeByIdInDetail } from "../../requests/ManagementRequests";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import LearnerPerformanceGraphicalData from "../../components/graphical-data/LearnerPerformanceGraphicalData";
import BaseLoading from "../../components/loading/BaseLoading";

const GradeDetailScreen = () => {
  const { gradeId } = useParams();
  const navigate = useNavigate();
  const [gradeInDetail, setGradeInDetail] = useState(null);
  const [learnerPerformance, setLearnerPerformance] = useState(null);
  const [learnerPerformanceLoading, setLearnerPerformanceLoading] =
    useState(false);
  const [selectedUserProfileDTOMap, setSelectedUserProfileDTOMap] = useState({
    userId: null,
    userProfileId: null,
    branchId: null,
    gradeId: null,
    seasonId: null,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const retrieveAndSetGradeInDetail = async () => {
      setLoading(true);
      const { data, err } = await retrieveGradeByIdInDetail(gradeId);
      if (err) {
        console.log(err);
      } else {
        setGradeInDetail(data);
      }
      setLoading(false);
    };
    if (gradeId) {
      retrieveAndSetGradeInDetail();
    }
  }, [gradeId]);

  const RenderMainContent = () => {
    if (loading) {
      return (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      );
    } else {
      if (!gradeInDetail) return;
      return (
        <FullCard isShadowed={true}>
          <div className="">
            <div className="flex flex-row items-center gap-x-2">
              <div className="cursor-pointer">
                <BaseImage
                  size="medium"
                  src="/icons/long-arrow-left.png"
                  alt="long-arrow-left"
                  onClick={() => {
                    navigate("/grades");
                  }}
                />
              </div>
              <BaseTitle
                title={`${gradeInDetail.name} ${i18n.t("grade_detail")}`}
              />
            </div>
          </div>
          <DividerLine />
          {gradeInDetail.staffUserProfileDTOList &&
          gradeInDetail.staffUserProfileDTOList.length > 0 ? (
            <div className="my-3">
              <div className="">
                <p className="font-semibold text-lg">
                  {i18n.t("staff_user_profiles")}
                </p>
              </div>
              <div className="flex flex-row items-center flex-wrap gap-x-3">
                {gradeInDetail.staffUserProfileDTOList.map(
                  (staffUserProfileDTO) => (
                    <div
                      className={` w-[200px] flex flex-col items-center justify-center my-3 p-3`}
                    >
                      <BaseImage
                        size="xlarge"
                        src="/icons/user-profile-started.png"
                        alt="user-profile-started"
                      />
                      <div className="my-2">
                        <p className="text-center">
                          {staffUserProfileDTO.userDTO.firstName}{" "}
                          {staffUserProfileDTO.userDTO.lastName}
                        </p>
                        <p className="text-center">
                          {staffUserProfileDTO.name}
                        </p>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null}

          {gradeInDetail.learnerUserProfileDTOList &&
          gradeInDetail.learnerUserProfileDTOList.length > 0 ? (
            <div className="my-3">
              <div className="">
                <p className="font-semibold text-lg">
                  {i18n.t("learner_user_profiles")}
                </p>
              </div>
              <div className="flex flex-row items-center flex-wrap gap-x-3 gap-y-3">
                {gradeInDetail.learnerUserProfileDTOList.map(
                  (learnerUserProfileDTO) => (
                    <div
                      className={`w-[200px] flex flex-col items-center justify-center p-3`}
                    >
                      <BaseImage
                        size="xlarge"
                        src="/icons/user-profile-started.png"
                        alt="user-profile-started"
                      />
                      <div className="my-2">
                        <p className="text-center">
                          {learnerUserProfileDTO.userDTO.firstName}{" "}
                          {learnerUserProfileDTO.userDTO.lastName}
                        </p>
                        <p className="text-center">
                          {learnerUserProfileDTO.name}
                        </p>
                      </div>
                    </div>
                  )
                )}
              </div>
              {learnerPerformanceLoading ? (
                <BaseLoading
                  size="large"
                  color="#57C2D3"
                  secondaryColor="#57C2D3"
                />
              ) : (
                <>
                  <DividerLine />
                  <LearnerPerformanceGraphicalData
                    learnerPerformance={learnerPerformance}
                    selectedCoterieType={"DEFAULT_OPTION"}
                    applicationList={[]}
                  />
                </>
              )}
            </div>
          ) : null}
        </FullCard>
      );
    }
    return null;
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default GradeDetailScreen;
