const ErrorWarning = (props) => {
  const { text } = props;

  return (
    <div className="my-2 p-3 border rounded-catchup-xlarge bg-catchup-red text-catchup-white">
      <p className="">{text}</p>
    </div>
  );
};

export default ErrorWarning;
