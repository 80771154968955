import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputGroup from "../../components/groups/InputGroup";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import CancelButton from "../buttons/CancelButton";
import FullCard from "../cards/FullCard";
import {
  filterCoterieTypeOptionList,
  filterGradeLevelOptionList,
} from "../../utilization/ManagementUtilization";
import { Tooltip } from "react-tooltip";
import { retrieveCategoryVersionCodeOptionList } from "../../utilization/CategoryUtilization";
import BaseModal from "../modal/BaseModal";
import BaseImage from "../images/BaseImage";
import { retrieveDocumentTypeOptionList } from "../../utilization/StorageFileUtilization";
import { retrieveStandardExamTypeOptionList } from "../../utilization/CatchtivityUtilization";
import HoverFilterButton from "../buttons/HoverFilterButton";
import PersonalLabel from "../label/PersonalLabel";
import BrandLabel from "../label/BrandLabel";
import PublishingHouseLabel from "../label/PublishingHouseLabel";
import CreateButton from "../buttons/CreateButton";
import BaseTitle from "../titles/BaseTitle";
import PrimaryButton from "../buttons/PrimaryButton";
import BaseLoading from "../loading/BaseLoading";

const AdvancedTable = (props) => {
  const {
    usePadding,
    title,
    itemName,
    description,
    headerList,
    itemList,
    showFilter,
    showItemCount,
    totalItemCount,
    pageNumber,
    loading,
    sortKey,
    sortDirection,
    filterParams,
    handleOnFilterChange,
    handleOnTableParamsChange,
    selectable,
    selectedItemIdList,
    handleSelectOnClick,
    labelable,
    showLabelList,
    labelActionList,
    handleLabelOnClick,
    insertable,
    handleInsertOnClick,
    updatable,
    handleUpdateOnClick,
    deletable,
    handleDeleteOnClick,
    previewable,
    handlePreviewOnClick,
    navigatable,
    navigateOptionList,
  } = props;
  const navigate = useNavigate();
  let {
    isFilterApplied,
    showBeginDate,
    beginAt,
    showEndDate,
    endAt,
    showTextContains,
    textContains,
    showActive,
    active,
    showDeleted,
    showCoterieType,
    mainCoterieType,
    coterieType,
    showLevel,
    level,
    showCategoryCode,
    categoryCode,
    subCategoryCodeList,
    subCategoryCode,
    outcomeCodeList,
    outcomeCode,
    showDocumentType,
    documentType,
    documentOptionFilter,
    showStandardExamType,
    standardExamType,
  } = {};
  if (showFilter) {
    const {
      isFilterApplied: fromParamsIsFilterApplied,
      showBeginDate: fromParamsShowBeginDate,
      beginAt: fromParamsBeginAt,
      showEndDate: fromParamsShowEndDate,
      endAt: fromParamsEndAt,
      showTextContains: fromParamsShowTextContains,
      textContains: fromParamsTextContains,
      showActive: fromParamsShowActive,
      active: fromParamsActive,
      showDeleted: fromParamsShowDeleted,
      showCoterieType: fromParamsShowCoterieType,
      mainCoterieType: fromParamsMainCoterieType,
      coterieType: fromParamsCoterieType,
      showLevel: fromParamsShowLevel,
      level: fromParamsLevel,
      showCategoryCode: fromParamsShowCategoryCode,
      categoryCode: fromParamsCategoryCode,
      subCategoryCodeList: fromParamsSubCategoryCodeList,
      subCategoryCode: fromParamsSubCategoryCode,
      outcomeCodeList: fromParamsOutcomeCodeList,
      outcomeCode: fromParamsOutcomeCode,
      showDocumentType: fromParamsShowDocumentType,
      documentType: fromParamsDocumentType,
      documentOptionFilter: fromParamsDocumentOptionFilter,
      showStandardExamType: fromParamsShowStandardExamType,
      standardExamType: fromParamsStandardExamType,
    } = filterParams;
    isFilterApplied = fromParamsIsFilterApplied;
    showBeginDate = fromParamsShowBeginDate;
    beginAt = fromParamsBeginAt;
    showEndDate = fromParamsShowEndDate;
    endAt = fromParamsEndAt;
    showTextContains = fromParamsShowTextContains;
    textContains = fromParamsTextContains;
    showActive = fromParamsShowActive;
    active = fromParamsActive;
    showDeleted = fromParamsShowDeleted;
    showCoterieType = fromParamsShowCoterieType;
    mainCoterieType = fromParamsMainCoterieType;
    coterieType = fromParamsCoterieType;
    showLevel = fromParamsShowLevel;
    level = fromParamsLevel;
    showCategoryCode = fromParamsShowCategoryCode;
    categoryCode = fromParamsCategoryCode;
    subCategoryCodeList = fromParamsSubCategoryCodeList;
    subCategoryCode = fromParamsSubCategoryCode;
    outcomeCodeList = fromParamsOutcomeCodeList;
    outcomeCode = fromParamsOutcomeCode;
    showDocumentType = fromParamsShowDocumentType;
    documentType = fromParamsDocumentType;
    documentOptionFilter = fromParamsDocumentOptionFilter;
    showStandardExamType = fromParamsShowStandardExamType;
    standardExamType = fromParamsStandardExamType;
  }
  const {
    language,
    userInformation,
    userProfile,
    userProfileInstitution,
    userProfileGrade,
  } = useAuth();
  const [showDropdownId, setShowDropdownId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItemId, setSelectedDeleteItemId] = useState(null);
  const [currentSelectedItem, setCurrentSelectedItem] = useState(null);
  const [hoverSelectedItemId, setHoverSelectedItemId] = useState(null);

  const retrieveTableShowItemCountOptionList = () => {
    return [
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ];
  };

  const retrieveMultipleHeaderKeyValue = (item, type, key) => {
    if (key?.includes(".")) {
      const splittedHeaderKeyList = key.split(".");
      let value = item;
      if (type === "listItemAsString") {
        value = item[splittedHeaderKeyList[0]];
        let subValue = "";
        for (const subItem of value) {
          subValue += subItem[splittedHeaderKeyList[1]] + ", ";
        }
        if (subValue.length > 2) {
          subValue = subValue.substring(0, subValue.length - 2);
        }
        return subValue;
      } else {
        for (const splittedHeaderKey of splittedHeaderKeyList) {
          if (value[splittedHeaderKey] !== undefined) {
            value = value[splittedHeaderKey];
          } else {
            return null;
          }
        }
        if (type === "length") {
          return value.length;
        }
      }

      return value;
    }
    if (type === "length") {
      return item[key].length;
    }
    return item[key];
  };

  const retrieveBodyText = (type, value) => {
    if (type === "boolean") {
      return i18n.t(value);
    } else if (type === "enumeration") {
      return i18n.t(value);
    } else if (type === "date") {
      return new Date(value).toLocaleDateString(language);
    } else if (type === "datetime") {
      return new Date(value).toLocaleString(language);
    } else {
      return value;
    }
  };

  const retrieveDocument = (item, header) => {
    const length = header.funcParams.length;
    if (length === 1) {
      return header.func(item[header.funcParams[0]]);
    } else if (length === 2) {
      return header.func(
        item[header.funcParams[0]],
        item[header.funcParams[1]]
      );
    } else if (length === 3) {
      return header.func(
        item[header.funcParams[0]],
        item[header.funcParams[1]],
        item[header.funcParams[2]]
      );
    } else if (length === 4) {
      return header.func(
        item[header.funcParams[0]],
        item[header.funcParams[1]],
        item[header.funcParams[2]],
        item[header.funcParams[3]]
      );
    }
    return null;
  };

  const retrieveStartingIndex = () => {
    return pageNumber * showItemCount;
  };

  const constructNavigatableOptionList = (item) => {
    const optionList = [];
    if (updatable && !handleSelectOnClick) {
      optionList.push({
        icon: <BaseImage src="/icons/edit-gray.png" alt="edit" size="small" />,
        onClick: () => {
          handleUpdateOnClick(item);
        },
        text: i18n.t("edit"),
      });
    }

    if (labelable && !handleSelectOnClick) {
      optionList.push({
        icon: (
          <BaseImage src="/icons/label-gray.png" alt="label" size="small" />
        ),
        onClick: () => {
          handleLabelOnClick(item);
        },
        text: i18n.t("label"),
      });
    }
    if (navigatable) {
      if (item.id === hoverSelectedItemId) {
        const foundNavigateOption = navigateOptionList.find(
          (navigateOption) => navigateOption.id === "fast_apply"
        );
        if (foundNavigateOption) {
          const { includeKeyValueToParams } = foundNavigateOption;
          if (includeKeyValueToParams) {
            for (const key of Object.keys(includeKeyValueToParams)) {
              const value = includeKeyValueToParams[key];
              foundNavigateOption.params[key] = item[value];
            }
          }
        }
        optionList.push(...navigateOptionList);
      }
    }
    if (deletable && !handleSelectOnClick) {
      optionList.push({
        icon: (
          <BaseImage src="/icons/remove-gray.png" alt="remove" size="small" />
        ),
        onClick: () => {
          setShowDeleteModal(true);
          setSelectedDeleteItemId(item.id);
        },
        text: i18n.t("delete"),
      });
    }
    return optionList;
  };

  const constructFilterOptionList = () => {
    if (!showFilter) return [];
    const optionList = [];
    if (showBeginDate) {
      optionList.push({
        id: "show-begin-date",
        text: i18n.t("begin_at"),
        content: (
          <InputGroup
            type="date"
            title={i18n.t("begin_at")}
            placeholder={i18n.t("begin_at")}
            value={beginAt}
            onChange={(e) => {
              handleOnFilterChange("beginAt", e.target.value);
            }}
          />
        ),
        value: beginAt,
        type: "date",
        onRemoveClick: () => {
          handleOnFilterChange("beginAt", "");
        },
      });
    }
    if (showEndDate) {
      optionList.push({
        id: "show-end-date",
        text: i18n.t("end_at"),
        content: (
          <InputGroup
            type="date"
            title={i18n.t("end_at")}
            placeholder={i18n.t("end_at")}
            value={endAt}
            onChange={(e) => {
              handleOnFilterChange("endAt", e.target.value);
            }}
          />
        ),
        value: endAt,
        type: "date",
        onRemoveClick: () => {
          handleOnFilterChange("endAt", "");
        },
      });
    }
    if (showTextContains) {
      optionList.push({
        id: "show-text-contains",
        text: i18n.t("text_contains"),
        content: (
          <InputGroup
            type="text"
            title={i18n.t("text_contains")}
            placeholder={i18n.t("text_contains")}
            value={textContains}
            onChange={(e) => {
              handleOnFilterChange("textContains", e.target.value);
            }}
          />
        ),
        value: textContains,
        type: "text",
        onRemoveClick: () => {
          handleOnFilterChange("textContains", "");
        },
      });
    }
    if (showCoterieType) {
      optionList.push({
        id: "show-coterie-type",
        text: i18n.t("coterie_type"),
        content: (
          <InputGroup
            type="select"
            title={i18n.t("coterie_type")}
            placeholder={i18n.t("coterie_type")}
            value={coterieType}
            optionList={filterCoterieTypeOptionList(
              userInformation,
              userProfile,
              userProfileInstitution
            )}
            onChange={(event) => {
              handleOnFilterChange("coterieType", event.target.value);
              handleOnTableParamsChange("pageNumber", 0);
            }}
          />
        ),
        value: coterieType,
        type: "enumeration",
        onRemoveClick: () => {
          handleOnFilterChange("coterieType", "DEFAULT_OPTION");
        },
      });
    }
    if (showLevel) {
      optionList.push({
        id: "show-level",
        text: i18n.t("level"),
        content: (
          <InputGroup
            type="select"
            title={i18n.t("level")}
            placeholder={i18n.t("level")}
            value={level}
            optionList={filterGradeLevelOptionList(
              userProfileInstitution,
              userProfileGrade
            )}
            onChange={(event) => {
              handleOnFilterChange("level", event.target.value);
              handleOnTableParamsChange("pageNumber", 0);
            }}
          />
        ),
        value: level,
        type: "enumeration",
        onRemoveClick: () => {
          handleOnFilterChange("level", "DEFAULT_OPTION");
        },
      });
    }
    if (showStandardExamType) {
      optionList.push({
        id: "show-standard-exam-type",
        text: i18n.t("standard_exam_type"),
        content: (
          <InputGroup
            type="select"
            title={i18n.t("standard_exam_type")}
            placeholder={i18n.t("standard_exam_type")}
            value={standardExamType}
            optionList={retrieveStandardExamTypeOptionList()}
            onChange={(event) => {
              handleOnFilterChange("standardExamType", event.target.value);
              handleOnTableParamsChange("pageNumber", 0);
            }}
          />
        ),
        value: standardExamType,
        type: "enumeration",
        onRemoveClick: () => {
          handleOnFilterChange("standardExamType", "DEFAULT_OPTION");
        },
      });
    }
    if (showDocumentType) {
      optionList.push({
        id: "show-document-type",
        text: i18n.t("document_type"),
        content: (
          <InputGroup
            type="select"
            title={i18n.t("document_type")}
            placeholder={i18n.t("document_type")}
            value={documentType}
            optionList={retrieveDocumentTypeOptionList(documentOptionFilter)}
            onChange={(event) => {
              handleOnFilterChange("documentType", event.target.value);
              handleOnTableParamsChange("pageNumber", 0);
            }}
          />
        ),
        value: documentType,
        type: "enumeration",
        onRemoveClick: () => {
          handleOnFilterChange("documentType", "DEFAULT_OPTION");
        },
      });
    }
    if (showCategoryCode) {
      optionList.push({
        id: "show-category-code",
        text: i18n.t("category_code"),
        content: (
          <InputGroup
            type="select"
            title={i18n.t("category_code")}
            placeholder={null}
            value={categoryCode}
            optionList={filteredCategoryVersionCodeOptionList}
            onChange={(event) => {
              handleOnFilterChange("categoryCode", event.target.value);
              handleOnTableParamsChange("pageNumber", 0);
            }}
          />
        ),
        value: categoryCode,
        type: "enumeration",
        onRemoveClick: () => {
          handleOnFilterChange("categoryCode", "DEFAULT_OPTION");
        },
      });
    }
    if (showCategoryCode && categoryCode !== "DEFAULT_OPTION") {
      optionList.push({
        id: "show-sub-category-code",
        text: i18n.t("sub_category_code"),
        content: (
          <InputGroup
            type="select"
            title={i18n.t("sub_category_code")}
            placeholder={null}
            value={subCategoryCode}
            optionList={subCategoryCodeList}
            onChange={(event) => {
              // handleOnChange("categoryId", event.target.value);
              handleOnFilterChange("subCategoryCode", event.target.value);
              handleOnTableParamsChange("pageNumber", 0);
            }}
          />
        ),
        value: subCategoryCodeList.find(
          (option) => option.value === subCategoryCode
        )?.text,
        type: "enumeration",
        onRemoveClick: () => {
          handleOnFilterChange("subCategoryCode", "DEFAULT_OPTION");
        },
      });
    }
    if (showCategoryCode && categoryCode !== "DEFAULT_OPTION") {
      optionList.push({
        id: "show-outcome-code",
        text: i18n.t("outcome_code"),
        content: (
          <InputGroup
            type="select"
            title={i18n.t("outcome_code")}
            placeholder={null}
            value={outcomeCode}
            optionList={outcomeCodeList}
            onChange={(event) => {
              // handleOnChange("categoryId", event.target.value);
              handleOnFilterChange("outcomeCode", event.target.value);
              handleOnTableParamsChange("pageNumber", 0);
            }}
          />
        ),
        value: outcomeCodeList.find((option) => option.value === outcomeCode)
          ?.text,
        type: "enumeration",
        onRemoveClick: () => {
          handleOnFilterChange("outcomeCode", "DEFAULT_OPTION");
        },
      });
    }
    if (showActive) {
      optionList.push({
        id: "show-active",
        text: i18n.t("active"),
        content: (
          <div
            className="flex flex-row items-center gap-x-2 my-2 cursor-pointer"
            onClick={() => {
              if (active) {
                handleOnFilterChange("active", false);
              } else {
                handleOnFilterChange("active", true);
              }
            }}
          >
            {active ? (
              <BaseImage
                src="/icons/checkbox.png"
                alt="checkbox"
                size="small"
              />
            ) : (
              <BaseImage
                src="/icons/checkbox-empty.png"
                alt="checkbox"
                size="small"
              />
            )}
            <p>{i18n.t("active")}</p>
          </div>
        ),
      });
    }
    if (userInformation.accountType === "GENIXO") {
      optionList.push({
        id: "show-delete-button",
        text: i18n.t("show_deleted_text"),
        content: (
          <div
            className="flex flex-row items-center gap-x-2 my-2 cursor-pointer"
            onClick={() => {
              if (showDeleted) {
                handleOnFilterChange("showDeleted", false);
              } else {
                handleOnFilterChange("showDeleted", true);
              }
            }}
          >
            {showDeleted ? (
              <BaseImage
                src="/icons/checkbox.png"
                alt="checkbox"
                size="small"
              />
            ) : (
              <BaseImage
                src="/icons/checkbox-empty.png"
                alt="checkbox"
                size="small"
              />
            )}
            <p>{i18n.t("show_deleted_text")}</p>
          </div>
        ),
        value: showDeleteModal,
        type: "boolean",
      });
    }
    return optionList;
  };

  const calculateTotalPageNumber = () => {
    return Math.ceil(totalItemCount / showItemCount);
  };

  const calculateNumberOfHeaderItems = (item) => {
    let size = headerList.length;
    if (previewable) {
      size++;
    }
    if (constructNavigatableOptionList(item).length > 0) {
      size++;
    }
    return size;
  };

  const filterCategoryVersionCodeOptionList = (
    categoryVersionCodeOptionList
  ) => {
    if (coterieType === "DEFAULT_OPTION") return [];
    if (showLevel && level === "DEFAULT_OPTION") return [];
    let currentCategoryVersionCodeOptionList = categoryVersionCodeOptionList;
    if (coterieType !== "MANAGEMENT") {
      currentCategoryVersionCodeOptionList =
        categoryVersionCodeOptionList.filter(
          (categoryVersionCode) => categoryVersionCode.type === coterieType
        );
    }
    if (showLevel && level !== "DEFAULT_OPTION") {
      currentCategoryVersionCodeOptionList =
        currentCategoryVersionCodeOptionList.filter((categoryVersionCode) =>
          categoryVersionCode.availableLevelList.includes(level)
        );
    }
    return currentCategoryVersionCodeOptionList;
  };

  const checkIsAllItemsSelected = () => {
    let isAllItemsSelected = false;
    if (!selectedItemIdList || selectedItemIdList.length === 0)
      return isAllItemsSelected;
    for (const item of itemList) {
      const foundIndex = selectedItemIdList.findIndex(
        (selectedItemId) => selectedItemId === item.id
      );
      isAllItemsSelected = foundIndex !== -1;
      if (!isAllItemsSelected) break;
    }
    return isAllItemsSelected;
  };

  const handleDeleteAndSetState = async () => {
    await handleDeleteOnClick(selectedDeleteItemId);
    setSelectedDeleteItemId(null);
  };

  const RenderShowDeleteModal = () => {
    return (
      <BaseModal
        isOpen={showDeleteModal}
        size="small"
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowDeleteModal(false);
          setSelectedDeleteItemId(null);
        }}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            <div className="flex flex-col items-center gap-y-4">
              <div className="">
                <BaseImage size="large" src="/icons/remove.png" alt="remove" />
              </div>
              <div className="w-[300px] text-center">
                <p className="text-xl">
                  {i18n.t("are_you_sure_do_you_want_to_delete_text")}
                </p>
              </div>
              <div className="flex flex-row items-center justify-center gap-x-4">
                <CancelButton
                  title={i18n.t("cancel")}
                  size="small"
                  onClick={() => {
                    setShowDeleteModal(false);
                  }}
                />
                <PrimaryButton
                  title={i18n.t("approve")}
                  size="small"
                  onClick={() => {
                    setShowDeleteModal(false);
                    handleDeleteAndSetState();
                  }}
                />
              </div>
            </div>
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  const categoryVersionCodeOptionList = retrieveCategoryVersionCodeOptionList();
  const filteredCategoryVersionCodeOptionList =
    filterCategoryVersionCodeOptionList(categoryVersionCodeOptionList);
  const constructedFilterOptionList = constructFilterOptionList();
  const isAllItemsSelected = checkIsAllItemsSelected();

  return (
    <div
      className={`flex-1 flex flex-col  ${
        usePadding === false ? "p-0" : "p-0"
      }  rounded-catchup-xlarge min-h-catchup-card text-catchup-blue-800 bg-catchup-white border-catchup-blue-50 border-[6px]`}
    >
      {RenderShowDeleteModal()}
      <div className="p-5">
        <div className="flex flex-row items-center justify-between">
          <BaseTitle
            title={title}
            totalItemCount={totalItemCount}
            itemName={itemName}
            description={description}
          />
          <div className="flex flex-row items-center gap-x-2">
            {showFilter ? (
              <div className="flex flex-row items-center gap-x-2">
                {isFilterApplied ? (
                  <div className="text-catchup-gray-400">
                    <p className="font-bold">{i18n.t("filter_active")}</p>
                  </div>
                ) : null}

                <HoverFilterButton
                  src="/icons/filter-search-gray.png"
                  alt="filter-search"
                  size="small"
                  optionList={constructedFilterOptionList}
                  optionSize="medium"
                  isFilterApplied={isFilterApplied}
                  onFilterClick={() => {
                    handleOnFilterChange("isFilterApplied", !isFilterApplied);
                  }}
                />
              </div>
            ) : null}
            {insertable ? (
              <div>
                <CreateButton
                  title={i18n.t("create")}
                  size="medium"
                  onClick={handleInsertOnClick}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="flex-1 flex flex-row">
        <div className="flex-1 min-h-catchup-table overflow-x-auto overflow-y-hidden">
          {loading ? (
            <BaseLoading
              size="large"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          ) : (
            <table className="table-auto w-full">
              <thead className="border bg-catchup-gray-50 border-catchup-gray-50 h-12 text-catchup-gray-500">
                <tr>
                  <th className="min-w-catchup-table-gap" />
                  {selectable ? (
                    <th className="min-w-catchup-table-body-icon">
                      <BaseImage
                        src={
                          isAllItemsSelected
                            ? "/icons/checkbox.png"
                            : "/icons/checkbox-empty.png"
                        }
                        alt="checkbox"
                        size="small"
                        onClick={() => {
                          if (isAllItemsSelected) {
                            for (const item of itemList) {
                              const foundIndex = selectedItemIdList.findIndex(
                                (selectedItemId) => selectedItemId === item.id
                              );
                              if (foundIndex !== -1) {
                                handleSelectOnClick(item);
                              }
                            }
                          } else {
                            for (const item of itemList) {
                              const foundIndex = selectedItemIdList.findIndex(
                                (selectedItemId) => selectedItemId === item.id
                              );
                              if (foundIndex === -1) {
                                handleSelectOnClick(item);
                              }
                            }
                          }
                        }}
                      />
                    </th>
                  ) : null}
                  <th className="min-w-catchup-table-body-icon">
                    {i18n.t("number_abbrevate")}
                  </th>
                  {headerList.map((header, index) => (
                    // <th key={index} className="min-w-catchup-table-header-item">
                    <th key={index} className="">
                      <div
                        className="flex flex-row items-center cursor-pointer"
                        onClick={() => {
                          if (header.key === sortKey) {
                            if (sortDirection === "ASC") {
                              handleOnTableParamsChange(
                                "sortDirection",
                                "DESC"
                              );
                            } else {
                              handleOnTableParamsChange("sortDirection", "ASC");
                            }
                          } else {
                            handleOnTableParamsChange("sortKey", header.key);
                          }
                        }}
                      >
                        <div className="">
                          <p className="font-semibold p-2 text-start">
                            {header.name}
                          </p>
                        </div>
                        {sortKey === header.key ? (
                          <div className="">
                            <BaseImage
                              size="small"
                              src={`${
                                sortDirection === "ASC"
                                  ? "/icons/sort-selected.png"
                                  : "/icons/sort-selected.png"
                              }`}
                              alt="sort-sort-selected"
                            />
                          </div>
                        ) : (
                          <div className="">
                            <BaseImage
                              size="small"
                              src="/icons/sort.png"
                              alt="sort-sort-selected"
                            />
                          </div>
                        )}
                      </div>
                    </th>
                  ))}
                  <th className="min-w-catchup-table-body-icon"></th>
                </tr>
              </thead>
              <tbody>
                {itemList.map((item, itemIndex) => (
                  <>
                    <tr
                      key={`0-${itemIndex}`}
                      className={`${
                        selectable
                          ? "hover:bg-catchup-blue-50"
                          : currentSelectedItem === item.id
                          ? "bg-catchup-blue-100"
                          : "hover:bg-catchup-blue-50"
                      } ${
                        showLabelList ? "" : "border-b border-b-catchup-gray-50"
                      } cursor-pointer`}
                      onClick={(e) => {
                        setCurrentSelectedItem(item.id);
                        if (selectable || handleSelectOnClick) {
                          e.preventDefault();
                          handleSelectOnClick(item);
                        }
                      }}
                      onMouseEnter={() => {
                        setHoverSelectedItemId(item.id);
                      }}
                    >
                      <td className="" rowSpan={showLabelList ? 2 : 1} />
                      {selectable ? (
                        <td>
                          <BaseImage
                            src={
                              selectedItemIdList.find(
                                (itemId) => itemId === item.id
                              )
                                ? "/icons/checkbox.png"
                                : "/icons/checkbox-empty.png"
                            }
                            alt="checkbox"
                            size="small"
                          />
                        </td>
                      ) : null}

                      <td className="px-2 font-semibold text-center">
                        {retrieveStartingIndex() + itemIndex + 1}
                      </td>
                      {headerList.map((header, index) => (
                        <td key={index} className="">
                          <div className="p-3 flex flex-row">
                            {header.type === "info" ? (
                              <>
                                <Tooltip id="table-description" />
                                <div className="mr-2">
                                  <BaseImage
                                    size="small"
                                    alt="info"
                                    src="/icons/info.png"
                                    dataToolTipId="table-description"
                                    dataToolTipContent={
                                      item.description
                                        ? item.description
                                        : retrieveMultipleHeaderKeyValue(
                                            item,
                                            header.type,
                                            header["infoKey"]
                                          )
                                    }
                                    dataToolTipPlace="top"
                                    dataToolTipVariant="info"
                                  />
                                </div>
                              </>
                            ) : null}

                            <p className="text-start">
                              {header.type === "document"
                                ? retrieveDocument(item, header)
                                : retrieveBodyText(
                                    header.type,
                                    retrieveMultipleHeaderKeyValue(
                                      item,
                                      header.type,
                                      header.key
                                    )
                                  ) != null
                                ? retrieveBodyText(
                                    header.type,
                                    retrieveMultipleHeaderKeyValue(
                                      item,
                                      header.type,
                                      header.key
                                    )
                                  )
                                : retrieveBodyText(
                                    header.notExistsType,
                                    retrieveMultipleHeaderKeyValue(
                                      item,
                                      header.notExistsType,
                                      header.notExistsKey
                                    )
                                  )}
                            </p>
                          </div>
                        </td>
                      ))}
                      {item.deleted ? null : (
                        <td className="ml-auto p-2">
                          <div className="flex flex-row items-center justify-center">
                            {previewable ? (
                              <div className="min-w-catchup-table-body-icon">
                                <BaseImage
                                  src="/icons/preview-gray.png"
                                  alt="preview"
                                  size="small"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handlePreviewOnClick(item);
                                    if (selectable) {
                                      handleSelectOnClick(item);
                                    }
                                  }}
                                />
                              </div>
                            ) : null}
                            {constructNavigatableOptionList(item).length > 0 ? (
                              <div
                                key={item.id}
                                className="min-w-catchup-table-body-icon relative h-5"
                                onMouseEnter={() => {
                                  setShowDropdownId(item.id);
                                }}
                                onMouseLeave={() => {
                                  setShowDropdownId(null);
                                }}
                              >
                                <BaseImage
                                  src="/icons/three-dots-gray.png"
                                  alt="three-dots"
                                  size="small"
                                />
                                <ul
                                  className={`absolute ${
                                    item.id === showDropdownId
                                      ? "opacity-100 visible"
                                      : "opacity-0 invisible"
                                  } flex flex-col min-w-catchup-medium-dropdown rounded-catchup-xlarge transition-all  duration-300 border-catchup-gray-100 bg-catchup-white px-4 py-4 translate-x-[10px] ${
                                    itemIndex > itemList.length - 6 &&
                                    itemList.length > 6
                                      ? "-translate-y-full -top-3"
                                      : ""
                                  } right-1/2 mt-2 z-10 shadow-dropdown`}
                                >
                                  {constructNavigatableOptionList(item).map(
                                    (navigateOption, index) => (
                                      <li
                                        key={navigateOption.id}
                                        className={`${
                                          index !==
                                          constructNavigatableOptionList(item)
                                            .length -
                                            1
                                            ? "border-b border-b-catchup-gray-50"
                                            : ""
                                        }`}
                                      >
                                        <div
                                          className={`flex flex-row items-center gap-x-2 my-1`}
                                        >
                                          <div>{navigateOption.icon}</div>
                                          <div className="flex-1">
                                            <p
                                              className="px-1 cursor-pointer"
                                              onClick={() => {
                                                if (navigateOption.value) {
                                                  let navigateUrl =
                                                    navigateOption.value;
                                                  while (
                                                    navigateUrl.indexOf("{") !==
                                                    -1
                                                  ) {
                                                    const firstIndex =
                                                      navigateUrl.indexOf("{");
                                                    const lastIndex =
                                                      navigateUrl.indexOf(
                                                        "}",
                                                        firstIndex
                                                      );
                                                    const key =
                                                      navigateUrl.substring(
                                                        firstIndex + 1,
                                                        lastIndex
                                                      );
                                                    navigateUrl =
                                                      navigateUrl.replace(
                                                        `{${key}}`,
                                                        item[key]
                                                      );
                                                  }
                                                  if (
                                                    navigateUrl.includes(
                                                      "|BEFORE_FUNC|"
                                                    )
                                                  ) {
                                                    navigateUrl =
                                                      navigateUrl.replace(
                                                        "|BEFORE_FUNC|",
                                                        navigateOption.beforeFunc(
                                                          item
                                                        )
                                                      );
                                                  }
                                                  navigate(navigateUrl, {
                                                    state:
                                                      navigateOption.params,
                                                  });
                                                } else {
                                                  navigateOption.onClick(item);
                                                }
                                              }}
                                            >
                                              {navigateOption.text}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            ) : null}
                          </div>
                        </td>
                      )}
                    </tr>
                    {showLabelList && labelActionList ? (
                      <tr
                        key={`1-${itemIndex}`}
                        className={`${
                          selectable
                            ? "hover:bg-catchup-blue-50"
                            : currentSelectedItem === item.id
                            ? "bg-catchup-blue-100"
                            : "hover:bg-catchup-blue-50"
                        } border-b border-b-catchup-gray-50`}
                      >
                        <td></td>
                        <td colSpan={calculateNumberOfHeaderItems(item)}>
                          <div className="flex flex-row flex-wrap items-center gap-x-2">
                            {labelActionList
                              .filter(
                                (labelAction) =>
                                  labelAction.labelItemList.filter(
                                    (labelItem) =>
                                      parseFloat(labelItem.itemId) ===
                                      parseFloat(item.id)
                                  ).length > 0
                              )
                              .map((labelAction, labelActionIndex) => (
                                <div key={labelActionIndex} className="mb-2">
                                  {labelAction.type === "PERSONAL" ? (
                                    <PersonalLabel
                                      title={labelAction.label.name}
                                    />
                                  ) : labelAction.type === "BRAND" ? (
                                    <BrandLabel
                                      title={labelAction.label.name}
                                    />
                                  ) : labelAction.type ===
                                    "PUBLISHING_HOUSE" ? (
                                    <PublishingHouseLabel
                                      title={labelAction.label.name}
                                    />
                                  ) : null}
                                </div>
                              ))}
                          </div>
                        </td>
                      </tr>
                    ) : null}
                  </>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="flex flex-row">
        {showFilter ? (
          <div className="hidden lg:block w-catchup-table-filter"></div>
        ) : null}

        <div className="flex-1 flex flex-row items-center justify-between">
          <div className="">
            <div className="flex flex-row items-center">
              <div></div>
              <div className="flex flex-row items-center font-medium">
                <div className="px-1">
                  <p>{i18n.t("show")}</p>
                </div>
                <div className="px-1">
                  <InputGroup
                    type="select"
                    title={null}
                    placeholder={null}
                    value={showItemCount}
                    optionList={retrieveTableShowItemCountOptionList()}
                    onChange={(event) => {
                      handleOnTableParamsChange(
                        "showItemCount",
                        event.target.value
                      );
                      handleOnTableParamsChange("pageNumber", 0);
                    }}
                  />
                </div>
                <div className="px-1">
                  <p>{i18n.t("entries_lower_case")}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="flex flex-row items-center">
              {pageNumber === 0 ? null : (
                <div className="px-2">
                  <div className="cursor-pointer">
                    <BaseImage
                      size="small"
                      alt="arrow-left"
                      src="/icons/arrow-left.png"
                      onClick={() => {
                        handleOnTableParamsChange("pageNumber", pageNumber - 1);
                      }}
                    />
                  </div>
                </div>
              )}
              {Array.from(Array(calculateTotalPageNumber()).keys())
                .filter((index) => index < pageNumber + 5)
                .filter((index) => index > pageNumber - 5)
                .map((index) => (
                  <div key={index} className="px-2">
                    <p
                      className={`${
                        pageNumber === index ? "text-2xl" : "text-md"
                      } cursor-pointer`}
                      onClick={() => {
                        handleOnTableParamsChange("pageNumber", index);
                      }}
                    >
                      {index + 1}
                    </p>
                  </div>
                ))}
              {calculateTotalPageNumber() === 0 ||
              pageNumber === calculateTotalPageNumber() - 1 ? null : (
                <div className="px-2">
                  <div className="cursor-pointer">
                    <BaseImage
                      size="small"
                      alt="arrow-right"
                      src="/icons/arrow-right.png"
                      onClick={() => {
                        handleOnTableParamsChange("pageNumber", pageNumber + 1);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedTable;
