import { useEffect } from "react";
import ListActivityBundle from "../../components/activity/ListActivityBundle";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";

const PublishingHouseActivityListScreen = () => {
  const { isFullScreen, setIsFullScreen } = useApp();
  const { userProfile, userProfileGrade } = useAuth();

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  const RenderMainContent = () => {
    return (
      <ListActivityBundle
        showTitle={true}
        selectable={false}
        initialCoterieType={userProfile.coterieType}
        initialLevel={userProfileGrade?.level}
      />
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default PublishingHouseActivityListScreen;
