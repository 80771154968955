import i18n from "../../language/i18n";
import BaseImage from "../images/BaseImage";

const CleanFilterGroup = (props) => {
  const { subCategoryFilter, setSubCategoryFilter } = props;
  return (
    <div
      className="flex flex-row items-center gap-x-2 cursor-pointer text-md transition-all duration-300 text-catchup-gray-400"
      onClick={() => {
        setSubCategoryFilter(!subCategoryFilter);
      }}
    >
      {subCategoryFilter ? (
        <div className="flex flex-row items-center gap-x-1">
          <p className="font-bold">{i18n.t("filter_active")}</p>
          <BaseImage src="/icons/checkbox.png" alt="checkbox" size="xsmall" />
        </div>
      ) : (
        <div className="flex flex-row items-center gap-x-1">
          <p className="font-bold">{i18n.t("filter_passive")}</p>
          <BaseImage
            src="/icons/checkbox-empty.png"
            alt="checkbox"
            size="xsmall"
          />
        </div>
      )}
    </div>
  );
};

export default CleanFilterGroup;
