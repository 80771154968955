import { Link, useNavigate } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import {
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import { useEffect, useState } from "react";
import {
  addCommentToActivityTemplateFeedback,
  patchActivityTemplateFeedbackStatus,
  queryActivityTemplateFeedbackByParams,
  queryActivityTemplateFeedbackByUserIdUserProfile,
} from "../../requests/CatchtivityRequests";
import FullCard from "../../components/cards/FullCard";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import BaseTitle from "../../components/titles/BaseTitle";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { NotificationManager } from "react-notifications";
import InputGroup from "../../components/groups/InputGroup";
import SingleInformationIconTextItem from "../../components/infos/SingleInformationIconTextItem";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import AdvancedTable from "../../components/tables/AdvancedTable";
import SelectionTab from "../../components/tabs/SelectionTab";
import ActivityTemplateLabel from "../../components/label/ActivityTemplateLabel";
import BaseLoading from "../../components/loading/BaseLoading";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const ActivityTemplateFeedbackListScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    language,
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
  } = useAuth();
  const [
    openActivityTemplateFeedbackList,
    setOpenActivityTemplateFeedbackList,
  ] = useState([]);
  const [
    inReviewActivityTemplateFeedbackList,
    setInReviewActivityTemplateFeedbackList,
  ] = useState([]);
  const [activityTemplateFeedbackList, setActivityTemplateFeedbackList] =
    useState([]);
  const [selectedOpenShowItemCount, setSelectedOpenShowItemCount] =
    useState(25);
  const [selectedInReviewShowItemCount, setSelectedInReviewShowItemCount] =
    useState(25);
  const [openPageNumber, setOpenPageNumber] = useState(0);
  const [inReviewPageNumber, setInReviewPageNumber] = useState(0);
  const [openSortKey, setOpenSortKey] = useState("createdAt");
  const [inReviewsortKey, setInReviewSortKey] = useState("createdAt");
  const [openSortDirection, setOpenSortDirection] = useState("DESC");
  const [inReviewSortDirection, setInReviewSortDirection] = useState("DESC");
  const [pageState, setPageState] = useState(0);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");
  const [errorMap, setErrorMap] = useState({
    text: null,
  });
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("activity_template_feedback_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    if (!userInformation) return;
    if (!userProfile) return;

    if (userInformation.accountType === "GENIXO") {
      retrieveAndSetActivityTemplateFeedbackListByParams();
    } else {
      if (userProfile.role === "CONTENT_CREATOR") {
        retrieveAndSetActivityTemplateFeedbackListByUserIdUserProfileId();
      }
    }
  }, [userInformation, userProfile]);

  useEffect(() => {
    if (activityTemplateFeedbackList.length > 0) {
      setOpenActivityTemplateFeedbackList(
        activityTemplateFeedbackList
          .filter(
            (activityTemplateFeedback) =>
              activityTemplateFeedback.status === "OPEN"
          )
          .map((item) => {
            const { commentDTOList, activityDTO } = item;
            const { name, description } = activityDTO;
            const firstComment = commentDTOList[0];
            const { text, firstName, lastName } = firstComment;
            return {
              ...item,
              name,
              description,
              fullName: `${firstName} ${lastName}`,
            };
          })
      );
      setInReviewActivityTemplateFeedbackList(
        activityTemplateFeedbackList
          .filter(
            (activityTemplateFeedback) =>
              activityTemplateFeedback.status === "IN_REVIEW"
          )
          .map((item) => {
            const { commentDTOList, activityDTO } = item;
            const { name, description } = activityDTO;
            const firstComment = commentDTOList[0];
            const { text, firstName, lastName } = firstComment;
            return {
              ...item,
              name,
              description,
              fullName: `${firstName} ${lastName}`,
            };
          })
      );
    } else {
      setOpenActivityTemplateFeedbackList([]);
      setInReviewActivityTemplateFeedbackList([]);
    }
  }, [activityTemplateFeedbackList]);

  const retrieveCurrentActivityTemplateFeedback = () => {
    return activityTemplateFeedbackList.find(
      (activityTemplateFeedback) => activityTemplateFeedback.id === selectedId
    );
  };

  const constructPreviewActivityUrl = () => {
    const { activityDTO } = currentActivityTemplateFeedback;
    if (!accessToken) return;
    return `${connection.app.url}/preview/activity/${activityDTO.id}/${btoa(
      encodeURI(JSON.stringify({ token: accessToken }))
    )}`;
  };

  const retrieveAndSetActivityTemplateFeedbackListByParams = async () => {
    setLoading(true);
    const { data, err } = await queryActivityTemplateFeedbackByParams({
      brandId: userProfileBrand.id,
      campusId: userProfileCampus?.id,
      institutionId: userProfileInstitution?.id,
      sortDirection: "DESC",
    });
    if (err) {
      console.log(err);
      return;
    }
    setActivityTemplateFeedbackList(data);
    setLoading(false);
  };

  const retrieveAndSetActivityTemplateFeedbackListByUserIdUserProfileId =
    async () => {
      setLoading(true);
      const { data, err } =
        await queryActivityTemplateFeedbackByUserIdUserProfile({
          userId: userInformation.id,
          userProfileId: userProfile.id,
        });
      if (err) {
        console.log(err);
        return;
      }
      setActivityTemplateFeedbackList(data);
      setLoading(false);
    };

  const filterOpenActivityTemplateFeedbackList = () => {
    return openActivityTemplateFeedbackList.slice(
      openPageNumber * selectedOpenShowItemCount,
      (openPageNumber + 1) * selectedOpenShowItemCount
    );
  };

  const filterInReviewActivityTemplateFeedbackList = () => {
    return inReviewActivityTemplateFeedbackList.slice(
      inReviewPageNumber * selectedInReviewShowItemCount,
      (inReviewPageNumber + 1) * selectedInReviewShowItemCount
    );
  };

  const resetParams = () => {
    setPageState(0);
    setSelectedId(null);
    setText("");
  };

  const handleOpenOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedOpenShowItemCount(value);
    } else if (type === "pageNumber") {
      setOpenPageNumber(value);
    } else if (type === "sortKey") {
      setOpenSortKey(value);
    } else if (type === "sortDirection") {
      setOpenSortDirection(value);
    }
  };

  const handleInReviewOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedInReviewShowItemCount(value);
    } else if (type === "pageNumber") {
      setInReviewPageNumber(value);
    } else if (type === "sortKey") {
      setInReviewSortKey(value);
    } else if (type === "sortDirection") {
      setInReviewSortDirection(value);
    }
  };

  const handleOnChange = (type, value) => {
    if (type === "text") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          text: i18n.t("text_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          text: null,
        }));
      }
      setText(value);
    }
  };

  const handleUpdateActivityTemplateFeedbackStatusOnClick = async () => {
    if (checkUpdatePermissionGranted("activity_template_feedback_list")) {
      const { data, err } = await patchActivityTemplateFeedbackStatus({
        id: selectedId,
        status: "CLOSED",
      });
      if (err) {
        console.log(err);
        return;
      }
      NotificationManager.success(
        i18n.t("activity_template_feedback_successfully_closed_text")
      );
      resetParams();
      retrieveAndSetActivityTemplateFeedbackListByParams();
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleAddCommentToActivityTemplateFeedbackOnClick = async () => {
    if (checkUpdatePermissionGranted("activity_template_feedback_list")) {
      const { data, err } = await addCommentToActivityTemplateFeedback({
        userId: userInformation.id,
        userProfileId: userProfile.id,
        text,
        username: userInformation.username,
        firstName: userInformation.firstName,
        lastName: userInformation.lastName,
        activityTemplateFeedback: {
          id: selectedId,
        },
      });
      if (err) {
        console.log(err);
        return;
      }
      NotificationManager.success(
        i18n.t(
          "comment_has_successfully_been_added_to_activity_template_feedback_text"
        )
      );
      resetParams();
      retrieveAndSetActivityTemplateFeedbackListByParams();
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleToUpdateActivityTemplateFeedbackOnClick = (item) => {
    setSelectedId(item.id);
    setPageState(1);
  };

  const RenderSingleCommentItem = (commentDTO) => {
    if (!commentDTO) return;
    const { text, firstName, lastName, username, createdAt } = commentDTO;
    return (
      <div className="flex flex-row my-2">
        <div className="w-1/6">
          <p className="font-semibold">
            {firstName} {lastName}
          </p>
          <p className="italic">{username}</p>
          <p className="text-md">
            {new Date(createdAt).toLocaleString(language)}
          </p>
        </div>
        <div className="flex-1">
          <p>{text}</p>
        </div>
      </div>
    );
  };

  const RenderCurrentActivityTemplateFeedbackItem = () => {
    if (!currentActivityTemplateFeedback) return;
    return (
      <FullCard>
        <div className="flex flex-row items-center gap-x-2">
          <div className="cursor-pointer">
            <BaseImage
              size="medium"
              src="/icons/long-arrow-left.png"
              alt="long-arrow-left"
              onClick={() => {
                resetParams();
              }}
            />
          </div>
          <BaseTitle title={i18n.t("activity_template_feedback")} />
        </div>
        <DividerLine />
        <div className="h-[850px] w-full rounded-catchup-xlarge">
          <iframe
            width="100%"
            height="100%"
            className="rounded-catchup-xlarge"
            src={constructPreviewActivityUrl()}
          />
        </div>
        <div className="mt-5">
          <div className="">
            <p className="">
              {i18n.t("activity_name")}:{" "}
              <span className="font-semibold hover:underline">
                <Link
                  to={`/activities?activity_name=${currentActivityTemplateFeedback.activityDTO.name}`}
                  target={"_blank"}
                >
                  {currentActivityTemplateFeedback.activityDTO.name}
                </Link>
              </span>
            </p>
            <p className="">
              {i18n.t("activity_description")}:{" "}
              <span className="font-semibold">
                {currentActivityTemplateFeedback.activityDTO.description}
              </span>
            </p>
          </div>
          <div className="flex flex-row mt-2">
            <ActivityTemplateLabel
              title={i18n.t(
                currentActivityTemplateFeedback.activityTemplateDTO.type
              )}
            />
          </div>
        </div>
        <div className="mt-5">
          <div className="border border-catchup-gray-100 rounded-catchup-xlarge p-5">
            {currentActivityTemplateFeedback.commentDTOList.map(
              (commentDTO, index) => (
                <div key={index}>{RenderSingleCommentItem(commentDTO)}</div>
              )
            )}
          </div>
        </div>
        <div className="mt-5">
          {SingleInformationIconTextItem(
            i18n.t("add_a_comment_to_activity_template_feedback_text")
          )}
          <InputGroup
            type="textarea"
            value={text}
            onChange={(event) => {
              handleOnChange("text", event.target.value);
            }}
            errorText={errorMap.text}
          />
        </div>

        <div className="mt-5 flex flex-row justify-end">
          <div className="flex flex-row items-center gap-x-2">
            <SecondaryButton
              title={i18n.t("add_a_comment")}
              size="unlimited"
              onClick={handleAddCommentToActivityTemplateFeedbackOnClick}
            />
            <PrimaryButton
              title={i18n.t("close")}
              size="small"
              onClick={handleUpdateActivityTemplateFeedbackStatusOnClick}
            />
          </div>
        </div>
      </FullCard>
    );
  };

  const RenderMainContent = () => {
    return loading ? (
      <FullCard>
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      </FullCard>
    ) : (
      <div className="w-full">
        {pageState === 0 ? (
          <SelectionTab
            optionList={[
              {
                id: 0,
                title: i18n.t("status_open_activity_template_feedbacks_text"),
              },
              {
                id: 1,
                title: i18n.t(
                  "status_in_review_activity_template_feedbacks_text"
                ),
              },
            ]}
            selectedId={selectedTabIndex}
            handleSelectOnClick={(selectedId) => {
              setSelectedTabIndex(selectedId);
            }}
          />
        ) : null}

        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          {selectedTabIndex === 0 ? (
            <AdvancedTable
              title={i18n.t("status_open_activity_template_feedbacks_text")}
              description={null}
              itemName={i18n.t("activity_template_feedback")}
              headerList={[
                {
                  name: i18n.t("name"),
                  key: "name",
                  type: "info",
                },
                {
                  name: i18n.t("type"),
                  key: "activityTemplateDTO.type",
                  type: "enumeration",
                },
                {
                  name: i18n.t("full_name"),
                  key: "fullName",
                },
                {
                  name: i18n.t("comment_count"),
                  key: "commentDTOList",
                  type: "length",
                },
                {
                  name: i18n.t("created_at"),
                  key: "createdAt",
                  type: "datetime",
                },
              ]}
              selectable={false}
              showFilter={true}
              showItemCount={selectedOpenShowItemCount}
              totalItemCount={openActivityTemplateFeedbackList.length}
              pageNumber={openPageNumber}
              loading={loading}
              sortKey={openSortKey}
              sortDirection={openSortDirection}
              handleOnTableParamsChange={handleOpenOnTableParamsChange}
              filterParams={{
                isFilterApplied: false,
              }}
              itemList={filteredOpenActivityTemplateFeedbackList}
              updatable={true}
              handleUpdateOnClick={
                handleToUpdateActivityTemplateFeedbackOnClick
              }
            />
          ) : (
            <AdvancedTable
              title={i18n.t(
                "status_in_review_activity_template_feedbacks_text"
              )}
              description={null}
              itemName={i18n.t("activity_template_feedback")}
              headerList={[
                {
                  name: i18n.t("name"),
                  key: "name",
                  type: "info",
                },
                {
                  name: i18n.t("type"),
                  key: "activityTemplateDTO.type",
                  type: "enumeration",
                },
                {
                  name: i18n.t("full_name"),
                  key: "fullName",
                },
                {
                  name: i18n.t("comment_count"),
                  key: "commentDTOList",
                  type: "length",
                },
                {
                  name: i18n.t("created_at"),
                  key: "createdAt",
                  type: "datetime",
                },
              ]}
              selectable={false}
              showFilter={true}
              showItemCount={selectedInReviewShowItemCount}
              totalItemCount={inReviewActivityTemplateFeedbackList.length}
              pageNumber={inReviewPageNumber}
              loading={loading}
              sortKey={inReviewsortKey}
              sortDirection={inReviewSortDirection}
              handleOnTableParamsChange={handleInReviewOnTableParamsChange}
              filterParams={{
                isFilterApplied: false,
              }}
              itemList={filteredInReviewActivityTemplateFeedbackList}
              updatable={true}
              handleUpdateOnClick={
                handleToUpdateActivityTemplateFeedbackOnClick
              }
            />
          )}
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          {RenderCurrentActivityTemplateFeedbackItem()}
        </div>
      </div>
    );
  };

  const currentActivityTemplateFeedback =
    retrieveCurrentActivityTemplateFeedback();
  const filteredOpenActivityTemplateFeedbackList =
    filterOpenActivityTemplateFeedbackList();
  const filteredInReviewActivityTemplateFeedbackList =
    filterInReviewActivityTemplateFeedbackList();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ActivityTemplateFeedbackListScreen;
