import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import FullCard from "../../components/cards/FullCard";
import InputGroup from "../../components/groups/InputGroup";
import { useApp } from "../../context/AppContextProvider";
import i18n from "../../language/i18n";
import {
  addOrRemoveUserAuthorityToUserProfile,
  patchUserOnlyRequiredAction,
  retrieveAuthorityList,
  retrieveUserProfileAuthorityListByUserProfileId,
} from "../../requests/ManagementRequests";
import { retrieveUserAuthorityGeneralOptionList } from "../../utilization/ManagementUtilization";
import {
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import BaseImage from "../../components/images/BaseImage";
import { useAuth } from "../../context/UserContextProvider";

const UserProfileAuthorityListScreen = () => {
  const { userId, userProfileId } = useParams();
  const { state } = useLocation();
  const { userInformation } = useAuth();
  const { isFullScreen, setIsFullScreen } = useApp();
  const navigate = useNavigate();
  const [userProfileAuthorityDTOList, setUserProfileAuthorityDTOList] =
    useState([]);
  const [userAuthorityList, setUserAuthorityList] = useState([]);
  const [includedAuthorityIdList, setIncludedAuthorityIdList] = useState([]);
  const [removedAuthorityIdList, setRemovedAuthorityIdList] = useState([]);
  const [selectedAvailableFilter, setSelectedAvailableFilter] = useState("");
  const [selectedAssignedFilter, setSelectedAssignedFilter] = useState("");
  const [userProfileRole, setUserProfileRole] = useState(null);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const pathRole = window.location.pathname.split("/")[1];
    if (pathRole === "learner-users") {
      setUserProfileRole("LEARNER");
    } else if (pathRole === "staff-users") {
      setUserProfileRole("STAFF");
    } else if (pathRole === "individual-users") {
      setUserProfileRole("INDIVIDUAL");
    } else if (pathRole === "content-creator-users") {
      setUserProfileRole("CONTENT_CREATOR");
    } else {
      setUserProfileRole("ADMIN");
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const retrieveAndSetUserProfileAuthorityList = async () => {
      const { data } = await retrieveUserProfileAuthorityListByUserProfileId(
        userProfileId
      );
      let filteredData = data;
      if (userInformation.accountType !== "GENIXO") {
        filteredData = filteredData.filter(
          (item) =>
            item.name !== "PUBLISHING_HOUSE_LABEL_QUERY" &&
            item.name !== "PUBLISHING_HOUSE_LABEL_INSERT" &&
            item.name !== "PUBLISHING_HOUSE_LABEL_UPDATE" &&
            item.name !== "PUBLISHING_HOUSE_LABEL_DELETE" &&
            item.name !== "PUBLISHING_HOUSE_LABEL_VIEW" &&
            item.name !== "ACTIVITY_TRANSFER_VIEW" &&
            item.name !== "ACTIVITY_TRANSFER_INSERT" &&
            item.name !== "ADMIN_OPERATIONS"
        );
      }
      setUserProfileAuthorityDTOList(filteredData.sort((a, b) => b.id - a.id));
    };
    const retrieveAndSetUserAuthorityList = async () => {
      const { data } = await retrieveAuthorityList();
      let filteredData = data;
      if (userInformation.accountType !== "GENIXO") {
        filteredData = filteredData.filter(
          (item) =>
            item.name !== "PUBLISHING_HOUSE_LABEL_QUERY" &&
            item.name !== "PUBLISHING_HOUSE_LABEL_INSERT" &&
            item.name !== "PUBLISHING_HOUSE_LABEL_UPDATE" &&
            item.name !== "PUBLISHING_HOUSE_LABEL_DELETE" &&
            item.name !== "PUBLISHING_HOUSE_LABEL_VIEW" &&
            item.name !== "ACTIVITY_TRANSFER_VIEW" &&
            item.name !== "ACTIVITY_TRANSFER_INSERT" &&
            item.name !== "ADMIN_OPERATIONS"
        );
      }
      setUserAuthorityList(filteredData.sort((a, b) => b.id - a.id));
    };
    if (checkViewPermissionGranted("user_profile_authority_list")) {
      retrieveAndSetUserProfileAuthorityList();
      retrieveAndSetUserAuthorityList();
    } else {
      navigate("/permission-error");
    }
  }, []);

  const retrieveNotIncludedAuthorityList = () => {
    const currentAuthorityDTOList = JSON.parse(
      JSON.stringify(
        userAuthorityList.filter(
          (userAuthority) =>
            userProfileAuthorityDTOList.findIndex(
              (userProfileAuthority) =>
                userProfileAuthority.id === userAuthority.id
            ) === -1
        )
      )
    );
    includedAuthorityIdList.forEach((includedAuthorityId) => {
      const foundIndex = currentAuthorityDTOList.findIndex(
        (currentAuthority) =>
          parseFloat(currentAuthority.id) === parseFloat(includedAuthorityId)
      );
      if (foundIndex > -1) {
        currentAuthorityDTOList.splice(foundIndex, 1);
      }
    });
    removedAuthorityIdList.forEach((removedAuthorityId) => {
      const foundUserAuthority = userAuthorityList.find(
        (userAuthority) =>
          parseFloat(userAuthority.id) === parseFloat(removedAuthorityId)
      );
      currentAuthorityDTOList.push({ ...foundUserAuthority, enabled: true });
    });
    return currentAuthorityDTOList.filter((currentAuthorityDTO) =>
      currentAuthorityDTO.name.includes(selectedAvailableFilter)
    );
  };

  const retrieveIncludedAuthorityList = () => {
    const currentAuthorityDTOList = JSON.parse(
      JSON.stringify(userProfileAuthorityDTOList)
    );
    includedAuthorityIdList.forEach((includedAuthorityId) => {
      const foundUserAuthority = userAuthorityList.find(
        (userAuthority) =>
          parseFloat(userAuthority.id) === parseFloat(includedAuthorityId)
      );
      currentAuthorityDTOList.push({
        ...foundUserAuthority,
        enabled: true,
      });
    });
    removedAuthorityIdList.forEach((removedAuthorityId) => {
      const foundUserAuthorityIndex = currentAuthorityDTOList.findIndex(
        (currentAuthority) =>
          parseFloat(currentAuthority.id) === parseFloat(removedAuthorityId)
      );
      currentAuthorityDTOList.splice(foundUserAuthorityIndex, 1);
    });
    return currentAuthorityDTOList.filter((currentAuthorityDTO) =>
      currentAuthorityDTO.name.includes(selectedAssignedFilter)
    );
  };

  const retrievePrevTitle = () => {
    if (userProfileRole === "LEARNER") {
      return i18n.t("learner_user_list");
    } else if (userProfileRole === "STAFF") {
      return i18n.t("staff_user_list");
    } else if (userProfileRole === "INDIVIDUAL") {
      return i18n.t("individual_user_list");
    } else if (userProfileRole === "CONTENT_CREATOR") {
      return i18n.t("content_creator_user_list");
    } else if (userProfileRole === "ADMIN") {
      return i18n.t("user_list");
    }
    return null;
  };

  const constructUserListBackParams = () => {
    if (state) {
      return [state.find((s) => s.screenName === "user_list")];
    }
    return null;
  };

  const constructUserProfileListBackParams = () => {
    if (state) {
      return state.filter(
        (s) =>
          s.screenName === "user_list" || s.screenName === "user_profile_list"
      );
    }
    return null;
  };

  const handleOnChange = (type, value) => {
    if (type === "availableFilter") {
      setSelectedAvailableFilter(value);
    } else if (type === "assignedFilter") {
      setSelectedAssignedFilter(value);
    }
  };

  const handleAddOrRemoveMultipleRoleToProfileOnClick = async () => {
    if (checkUpdatePermissionGranted("user_profile_authority_list")) {
      const { data, err } = await addOrRemoveUserAuthorityToUserProfile(
        userProfileId,
        includedAuthorityIdList,
        removedAuthorityIdList
      );
      if (err) {
        console.log(err);
      } else {
        handlePatchUserRequiredActionOnClick();
        navigate(-1);
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handlePatchUserRequiredActionOnClick = async () => {
    await patchUserOnlyRequiredAction({
      id: userId,
      requiredAction: "PROFILE_SELECTION",
    });
  };

  const RenderSingleIncludedUserProfileAuthorityItem = (
    userProfileAuthorityDTO
  ) => {
    return (
      <div className="flex flex-row items-center my-2 p-2 border-b-2">
        <div className="mx-1">
          {userProfileAuthorityDTO.enabled ? (
            <BaseImage
              src="/icons/remove-red.png"
              alt="remove-red"
              size="small"
              onClick={() => {
                const foundIncludedIndex = includedAuthorityIdList.findIndex(
                  (includedAuthorityId) =>
                    parseFloat(includedAuthorityId) ===
                    parseFloat(userProfileAuthorityDTO.id)
                );
                if (foundIncludedIndex !== -1) {
                  includedAuthorityIdList.splice(foundIncludedIndex, 1);
                  setIncludedAuthorityIdList(
                    JSON.parse(JSON.stringify(includedAuthorityIdList))
                  );
                } else {
                  const foundIndex = removedAuthorityIdList.findIndex(
                    (removedAuthorityId) =>
                      parseFloat(removedAuthorityId) ===
                      parseFloat(userProfileAuthorityDTO.id)
                  );
                  if (foundIndex === -1) {
                    removedAuthorityIdList.push(userProfileAuthorityDTO.id);
                    setRemovedAuthorityIdList(
                      JSON.parse(JSON.stringify(removedAuthorityIdList))
                    );
                  }
                }
              }}
            />
          ) : (
            <div className="h-6 w-6" />
          )}
        </div>

        <div className="flex flex-row flex-wrap mx-1">
          <div className="mr-2">
            <BaseImage
              size="small"
              src="/icons/info.png"
              alt="info"
              dataToolTipId="authority-description"
              dataToolTipContent={userProfileAuthorityDTO.description}
              dataToolTipPlace="top"
              dataToolTipVariant="info"
            />
          </div>
          <div className="">
            <p className="">{userProfileAuthorityDTO.name}</p>
          </div>
        </div>
      </div>
    );
  };

  const RenderSingleNonIncludedUserProfileAuthorityItem = (
    userProfileAuthorityDTO
  ) => {
    return (
      <div className="flex flex-row items-center my-2 p-2 border-b-2">
        <div className="mx-1">
          <div className="mx-1">
            <BaseImage
              src="/icons/plus.png"
              alt="plus"
              size="small"
              onClick={() => {
                const foundRemovedIndex = removedAuthorityIdList.findIndex(
                  (removedAuthorityId) =>
                    parseFloat(removedAuthorityId) ===
                    parseFloat(userProfileAuthorityDTO.id)
                );
                if (foundRemovedIndex !== -1) {
                  removedAuthorityIdList.splice(foundRemovedIndex, 1);
                  setRemovedAuthorityIdList(
                    JSON.parse(JSON.stringify(removedAuthorityIdList))
                  );
                } else {
                  const foundIndex = includedAuthorityIdList.findIndex(
                    (includedAuthorityId) =>
                      parseFloat(includedAuthorityId) ===
                      parseFloat(userProfileAuthorityDTO.id)
                  );
                  if (foundIndex === -1) {
                    includedAuthorityIdList.push(userProfileAuthorityDTO.id);
                    setIncludedAuthorityIdList(
                      JSON.parse(JSON.stringify(includedAuthorityIdList))
                    );
                  }
                }
              }}
            />
          </div>
        </div>
        <div className="flex flex-row flex-wrap mx-1">
          <div className="mr-2">
            <BaseImage
              size="small"
              src="/icons/info.png"
              alt="info"
              dataToolTipId="authority-description"
              dataToolTipContent={userProfileAuthorityDTO.description}
              dataToolTipPlace="top"
              dataToolTipVariant="info"
            />
          </div>
          <div className="">
            <p className="">{userProfileAuthorityDTO.name}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">
        <FullCard isShadowed={true}>
          <div className="flex flex-row">
            <div className="w-1/2">
              <div className="text-xl font-medium">
                <p>{i18n.t("available_authorities")}</p>
              </div>
              <div className="m-2 p-2">
                <InputGroup
                  type="select"
                  value={selectedAvailableFilter}
                  optionList={retrieveUserAuthorityGeneralOptionList()}
                  onChange={(event) => {
                    handleOnChange("availableFilter", event.target.value);
                  }}
                />
              </div>
              <div className="m-2 p-2 h-catchup-user-authority overflow-auto">
                <Tooltip id="authority-description" />
                {retrieveNotIncludedAuthorityList().map(
                  (notIncludedAuthority) => (
                    <div key={notIncludedAuthority.id}>
                      {RenderSingleNonIncludedUserProfileAuthorityItem(
                        notIncludedAuthority
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="w-1/2">
              <div className="text-xl font-medium">
                <p>{i18n.t("assigned_authorities")}</p>
              </div>
              <div className="m-2 p-2">
                <InputGroup
                  type="select"
                  value={selectedAssignedFilter}
                  optionList={retrieveUserAuthorityGeneralOptionList()}
                  onChange={(event) => {
                    handleOnChange("assignedFilter", event.target.value);
                  }}
                />
              </div>
              <div className="m-2 p-2 h-catchup-user-authority overflow-auto">
                <Tooltip id="authority-description" />
                {retrieveIncludedAuthorityList().map(
                  (userProfileAuthorityDTO) => (
                    <div key={userProfileAuthorityDTO.id}>
                      {RenderSingleIncludedUserProfileAuthorityItem(
                        userProfileAuthorityDTO
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          <div className="mt-4 ml-auto">
            <PrimaryButton
              title={i18n.t("add")}
              size="small"
              onClick={handleAddOrRemoveMultipleRoleToProfileOnClick}
            />
          </div>
        </FullCard>
      </div>
    </div>
  );
};

export default UserProfileAuthorityListScreen;
