import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import { checkUpdatePermissionGranted } from "../../utilization/ScreenUtilization";
import {
  createExternalExamActivity,
  createExternalExamPart,
  deleteExternalExamActivity,
  patchExternalExamPart,
  removeExternalExamPart,
  retrieveExternalExamDTOById,
} from "../../requests/CatchtivityRequests";
import FullCard from "../../components/cards/FullCard";
import BaseImage from "../../components/images/BaseImage";
import i18n from "../../language/i18n";
import BaseTitle from "../../components/titles/BaseTitle";
import DividerLine from "../../components/divider/DividerLine";
import InputGroup from "../../components/groups/InputGroup";
import useScreenSize from "../../hooks/useScreenSize";
import { useAuth } from "../../context/UserContextProvider";
import {
  filterCoterieTypeOptionList,
  filterGradeLevelOptionList,
} from "../../utilization/ManagementUtilization";
import { retrieveActivityTemplateDTOOptionList } from "../../utilization/CatchtivityUtilization";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import BaseModal from "../../components/modal/BaseModal";
import ListActivity from "../../components/activity/ListActivity";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import BaseLoading from "../../components/loading/BaseLoading";
import SingleActivityItem from "../../components/activity/SingleActivityItem";
import CreateButton from "../../components/buttons/CreateButton";
import { NotificationManager } from "react-notifications";
import ActivityTemplateLabel from "../../components/label/ActivityTemplateLabel";
import CoterieLabel from "../../components/label/CoterieLabel";
import CancelButton from "../../components/buttons/CancelButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import SelectionTab from "../../components/tabs/SelectionTab";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const ExternalExamPartListScreen = () => {
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const { externalExamId } = useParams();
  const { isFullScreen, setIsFullScreen, mainLeftNavigationState } = useApp();
  const {
    language,
    userInformation,
    userProfile,
    userProfileInstitution,
    userProfileGrade,
    activityTemplateSet,
    categorySet,
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const [externalExamDTO, setExternalExamDTO] = useState(null);
  const [selectedExternalExamPartId, setSelectedExternalExamPartId] =
    useState(null);
  const [selectedPageNumber, setSelectedPageNumber] =
    useState("DEFAULT_OPTION");
  const [selectedPageFormat, setSelectedPageFormat] =
    useState("DEFAULT_OPTION");
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedCoterieType, setSelectedCoterieType] = useState(
    userProfile?.coterieType
      ? userProfile?.coterieType
      : userProfile?.coterieTypeList && userProfile?.coterieTypeList.length > 0
      ? userProfile?.coterieTypeList[0]
      : "DEFAULT_OPTION"
  );
  const [selectedLevel, setSelectedLevel] = useState("DEFAULT_OPTION");
  const [coefficient, setCoefficient] = useState(20);
  const [selectedActivityTemplateId, setSelectedActivityTemplateId] =
    useState(295);
  const [selectedActivityDTO, setSelectedActivityDTO] = useState(null);
  const [showChooseActivityModal, setShowChooseActivityModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItemId, setSelectedDeleteItemId] = useState(null);
  const [changePageNumberModalActionList, setChangePageNumberModalActionList] =
    useState([]);
  const [showChangePageNumberModal, setShowChangePageNumberModal] =
    useState(false);
  const [showChangePageFormatModal, setShowChangePageFormatModal] =
    useState(false);
  const [selectedChangePageNumber, setSelectedChangePageNumber] =
    useState(null);
  const [selectedChangePageFormat, setSelectedChangePageFormat] =
    useState(null);
  const mainDivRef = useRef(null);
  const [a4Width, setA4Width] = useState(0);
  const [a4Height, setA4Height] = useState(0);
  const RATIO = 1.42;
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (!mainDivRef) return;
    if (!mainDivRef.current) return;
    const currentA4Width = mainDivRef.current.clientWidth - 80;
    setA4Width(currentA4Width);
    setA4Height(currentA4Width * RATIO);
  }, [screenSize, mainLeftNavigationState, mainDivRef]);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkUpdatePermissionGranted("external_exam_list")) {
      navigate("/permission-error");
      return;
    }
  }, []);

  useEffect(() => {
    if (!externalExamId) return;
    retrieveAndSetExternalExamDTOById();
  }, [externalExamId]);

  useEffect(() => {
    if (!externalExamDTO) return;
    if (!selectedExternalExamPartId) return;
    const { externalExamPartDTOList } = externalExamDTO;
    const foundExternalExamPartDTO = externalExamPartDTOList.find(
      (externalExamPartDTO) =>
        externalExamPartDTO.id === selectedExternalExamPartId
    );
    if (!foundExternalExamPartDTO) return;
    const { pageFormat } = foundExternalExamPartDTO;
    setSelectedPageFormat(pageFormat);
  }, [externalExamDTO, selectedExternalExamPartId]);

  const retrieveAndSetExternalExamDTOById = async () => {
    setLoading(true);
    const { data, err } = await retrieveExternalExamDTOById(externalExamId);
    if (err) {
      console.log(err);
      return;
    }
    setExternalExamDTO(data);
    const { externalExamPartDTOList } = data;
    setSelectedExternalExamPartId(externalExamPartDTOList[0].id);
    setSelectedPageNumber(externalExamPartDTOList.length);
    setLoading(false);
  };

  const retrievePageNumberOptionList = () => {
    return [
      {
        text: 1,
        value: 1,
      },
      {
        text: 2,
        value: 2,
      },
      {
        text: 3,
        value: 3,
      },
    ];
  };

  const retrievePageFormatOptionList = () => {
    return [
      {
        text: i18n.t("ONE_BY_TWO"),
        value: "ONE_BY_TWO",
      },
      {
        text: i18n.t("ONE_BY_THREE"),
        value: "ONE_BY_THREE",
      },
      {
        text: i18n.t("ONE_BY_FOUR"),
        value: "ONE_BY_FOUR",
      },
      {
        text: i18n.t("ONE_BY_FIVE"),
        value: "ONE_BY_FIVE",
      },
      {
        text: i18n.t("TWO_BY_ONE"),
        value: "TWO_BY_ONE",
      },
      {
        text: i18n.t("TWO_BY_TWO"),
        value: "TWO_BY_TWO",
      },
      {
        text: i18n.t("TWO_BY_THREE"),
        value: "TWO_BY_THREE",
      },
      {
        text: i18n.t("TWO_BY_FOUR"),
        value: "TWO_BY_FOUR",
      },
    ];
  };

  const retrieveActivityTemplateTypeList = () => {
    const foundActivityTemplate = activityTemplateSet.find(
      (activityTemplate) => activityTemplate.id === selectedActivityTemplateId
    );
    if (!foundActivityTemplate) return [];
    return [foundActivityTemplate.type];
  };

  const resetParams = () => {
    setSelectedPosition(null);
    setCoefficient(20);
    setSelectedCoterieType(
      userProfile?.coterieType
        ? userProfile?.coterieType
        : userProfile?.coterieTypeList &&
          userProfile?.coterieTypeList.length > 0
        ? userProfile?.coterieTypeList[0]
        : "DEFAULT_OPTION"
    );
    setSelectedLevel("DEFAULT_OPTION");
    setSelectedActivityTemplateId(295);
    setSelectedActivityDTO(null);
  };

  const handleReturnFromExternalRegistrationOnClick = () => {
    navigate("/external-exams");
  };

  const handleCheckPageNumberOnChange = (value) => {
    if (!externalExamDTO) return;
    const { externalExamPartDTOList } = externalExamDTO;
    const filteredExternalPartDTOList = externalExamPartDTOList.filter(
      (externalExamPartDTO) => externalExamPartDTO.pageNumber > value
    );
    const currentChangePageNumberModalActionList = [];
    if (filteredExternalPartDTOList.length > 0) {
      for (const externalPartDTO of filteredExternalPartDTOList) {
        currentChangePageNumberModalActionList.push({
          state: "DELETE",
          value: externalPartDTO.id,
        });
      }
    } else {
      const foundExternalPartDTO = externalExamPartDTOList.find(
        (externalExamPartDTO) => externalExamPartDTO.pageNumber === value
      );
      if (!foundExternalPartDTO) {
        const currentSize = externalExamPartDTOList.length;
        for (let i = 1; i <= value - currentSize; i++) {
          currentChangePageNumberModalActionList.push({
            state: "ADD",
            value: i + currentSize,
          });
        }
      }
    }
    setChangePageNumberModalActionList(currentChangePageNumberModalActionList);
    if (currentChangePageNumberModalActionList.length > 0) {
      setShowChangePageNumberModal(true);
    }
  };

  const handleCheckPageFormatOnChange = (value) => {
    if (!externalExamDTO) return;
    const { externalExamPartDTOList } = externalExamDTO;
    const foundExternalExamPartDTO = externalExamPartDTOList.find(
      (externalExamPartDTO) =>
        externalExamPartDTO.id === selectedExternalExamPartId
    );
    if (!foundExternalExamPartDTO) return;
    const { externalExamActivityDTOList } = foundExternalExamPartDTO;

    const highestPosition = externalExamActivityDTOList.reduce(
      (max, item) => Math.max(max, item.position),
      0
    );
    if (
      (value === "ONE_BY_TWO" && highestPosition > 2) ||
      (value === "ONE_BY_THREE" && highestPosition > 3) ||
      (value === "ONE_BY_FOUR" && highestPosition > 4) ||
      (value === "ONE_BY_FIVE" && highestPosition > 5) ||
      (value === "TWO_BY_ONE" && highestPosition > 2) ||
      (value === "TWO_BY_TWO" && highestPosition > 4) ||
      (value === "TWO_BY_THREE" && highestPosition > 6) ||
      (value === "TWO_BY_FOUR" && highestPosition > 8)
    ) {
      setShowChangePageFormatModal(true);
      setSelectedChangePageFormat(value);
    } else {
      handlePatchExternalExamPartByPageFormat(value);
      foundExternalExamPartDTO.pageFormat = value;
      handleOnChange("pageFormat", value);
    }
  };

  const handleChangePageNumberOnChange = async () => {
    if (changePageNumberModalActionList.length === 0) return;
    for (const changePageNumberModalAction of changePageNumberModalActionList) {
      const { state, value } = changePageNumberModalAction;
      if (state === "ADD") {
        await handleCreateExternalExamPartByPageNumber(value);
      } else if (state === "DELETE") {
        await handleDeleteExternalExamPartByExternalExamPartId(value);
      }
    }
    await retrieveAndSetExternalExamDTOById();
    setShowChangePageNumberModal(false);
  };

  const handleChangePageFormatOnChange = async () => {
    if (!externalExamDTO) return;
    const { externalExamPartDTOList } = externalExamDTO;
    const foundExternalExamPartDTO = externalExamPartDTOList.find(
      (externalExamPartDTO) =>
        externalExamPartDTO.id === selectedExternalExamPartId
    );
    if (!foundExternalExamPartDTO) return;
    const { externalExamActivityDTOList } = foundExternalExamPartDTO;
    const highestPosition = externalExamActivityDTOList.reduce(
      (max, item) => Math.max(max, item.position),
      0
    );
    let filteredExternalExamActivityDTOList = [];
    if (selectedChangePageFormat === "ONE_BY_TWO" && highestPosition > 2) {
      filteredExternalExamActivityDTOList = externalExamActivityDTOList.filter(
        (externalExamActivityDTO) => externalExamActivityDTO.position > 2
      );
    } else if (
      selectedChangePageFormat === "ONE_BY_THREE" &&
      highestPosition > 3
    ) {
      filteredExternalExamActivityDTOList = externalExamActivityDTOList.filter(
        (externalExamActivityDTO) => externalExamActivityDTO.position > 3
      );
    } else if (
      selectedChangePageFormat === "ONE_BY_FOUR" &&
      highestPosition > 4
    ) {
      filteredExternalExamActivityDTOList = externalExamActivityDTOList.filter(
        (externalExamActivityDTO) => externalExamActivityDTO.position > 4
      );
    } else if (
      selectedChangePageFormat === "ONE_BY_FIVE" &&
      highestPosition > 5
    ) {
      filteredExternalExamActivityDTOList = externalExamActivityDTOList.filter(
        (externalExamActivityDTO) => externalExamActivityDTO.position > 5
      );
    } else if (
      selectedChangePageFormat === "TWO_BY_ONE" &&
      highestPosition > 2
    ) {
      filteredExternalExamActivityDTOList = externalExamActivityDTOList.filter(
        (externalExamActivityDTO) => externalExamActivityDTO.position > 2
      );
    } else if (
      selectedChangePageFormat === "TWO_BY_TWO" &&
      highestPosition > 4
    ) {
      filteredExternalExamActivityDTOList = externalExamActivityDTOList.filter(
        (externalExamActivityDTO) => externalExamActivityDTO.position > 4
      );
    } else if (
      selectedChangePageFormat === "TWO_BY_THREE" &&
      highestPosition > 6
    ) {
      filteredExternalExamActivityDTOList = externalExamActivityDTOList.filter(
        (externalExamActivityDTO) => externalExamActivityDTO.position > 6
      );
    } else if (
      selectedChangePageFormat === "TWO_BY_FOUR" &&
      highestPosition > 8
    ) {
      filteredExternalExamActivityDTOList = externalExamActivityDTOList.filter(
        (externalExamActivityDTO) => externalExamActivityDTO.position > 8
      );
    }
    for (const externalExamActivityDTO of filteredExternalExamActivityDTOList) {
      const { data, err } = await deleteExternalExamActivity(
        externalExamActivityDTO.id
      );
    }

    setShowChangePageFormatModal(false);
    handlePatchExternalExamPartByPageFormat(selectedChangePageFormat);
    foundExternalExamPartDTO.pageFormat = selectedChangePageFormat;
    handleOnChange("pageFormat", selectedChangePageFormat);
  };

  const handlePatchExternalExamPartByPageFormat = async (pageFormat) => {
    if (!externalExamDTO) return;
    const { externalExamPartDTOList } = externalExamDTO;
    const foundExternalExamPartDTO = externalExamPartDTOList.find(
      (externalExamPartDTO) =>
        externalExamPartDTO.id === selectedExternalExamPartId
    );
    if (!foundExternalExamPartDTO) return;
    const { data, err } = await patchExternalExamPart({
      id: selectedExternalExamPartId,
      pageNumber: foundExternalExamPartDTO.pageNumber,
      pageFormat,
    });
    if (err) {
      console.log(err);
      return;
    }
  };

  const handleOnChange = (type, value) => {
    if (type === "pageNumber") {
      setSelectedPageNumber(value);
    } else if (type === "pageFormat") {
      setSelectedChangePageFormat(null);
      setSelectedPageFormat(value);
    } else if (type === "coterieType") {
      setSelectedCoterieType(value);
    } else if (type === "coefficient") {
      if (value > 100) return;
      setCoefficient(value);
    } else if (type === "level") {
      setSelectedLevel(value);
    } else if (type === "activityTemplateId") {
      setSelectedActivityTemplateId(value);
    } else if (type === "activityDTO") {
      setSelectedActivityDTO(value);
    }
  };

  const handlePreviewActivityOnClick = (activityDTO) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    window.open(
      `${connection.app.url}/preview/activity/${activityDTO.id}/${btoa(
        encodeURI(JSON.stringify({ token: accessToken }))
      )}`
    );
  };

  const handleCreateExternalExamPartByPageNumber = async (pageNumber) => {
    const { data, err } = await createExternalExamPart({
      externalExam: {
        id: externalExamId,
      },
      pageFormat: "TWO_BY_TWO",
      pageNumber,
    });
    if (err) {
      console.log(err);
      return;
    }
    await retrieveAndSetExternalExamDTOById();
  };

  const handleDeleteExternalExamPartByExternalExamPartId = async (
    externalExamPartId
  ) => {
    const { data, err } = await removeExternalExamPart(externalExamPartId);
    if (err) {
      console.log(err);
      return;
    }
    await retrieveAndSetExternalExamDTOById();
  };

  const handleCreateExternalExamActivityOnClick = async (position) => {
    if (!externalExamDTO) return;
    const { externalExamPartDTOList } = externalExamDTO;
    const foundExternalExamPartDTO = externalExamPartDTOList.find(
      (externalExamPartDTO) =>
        externalExamPartDTO.id === selectedExternalExamPartId
    );
    if (!foundExternalExamPartDTO) return;
    const foundActivityTemplateDTO = activityTemplateSet.find(
      (activityTemplate) => activityTemplate.id === selectedActivityTemplateId
    );
    if (!foundActivityTemplateDTO) return;

    const newExternalExamPart = {
      position,
      coefficient: coefficient / 100,
      coterieType: selectedCoterieType,
      externalExam: {
        id: externalExamDTO.id,
      },
      externalExamPart: {
        id: foundExternalExamPartDTO.id,
      },
      activity: {
        id: selectedActivityDTO.id,
      },
      activityTemplate: {
        id: foundActivityTemplateDTO.id,
        type: foundActivityTemplateDTO.type,
      },
    };
    const { data, err } = await createExternalExamActivity(newExternalExamPart);
    if (err) {
      console.log(err);
      if (err.response.data) {
        NotificationManager.error(i18n.t(err.response.data));
      }
    } else {
      retrieveAndSetExternalExamDTOById();
      resetParams();
    }
  };

  const handleDeleteExternalExamActivityOnClick = async () => {
    const { data, err } = await deleteExternalExamActivity(
      selectedDeleteItemId
    );
    if (err) {
      console.log(err);
      if (err.response.data) {
        NotificationManager.error(i18n.t(err.response.data));
      }
    } else {
      retrieveAndSetExternalExamDTOById();
    }
  };

  const RenderDefaultSingleExamActivity = (
    position,
    width,
    height,
    paddingHorizontal,
    paddingVertical
  ) => {
    return (
      <div className="" style={{ width, height }}>
        <div
          className="h-full "
          style={{
            paddingLeft: paddingHorizontal,
            paddingRight: paddingHorizontal,
            paddingTop: paddingVertical,
            paddingBottom: paddingVertical,
          }}
        >
          <div
            className={`${
              position === selectedPosition
                ? ""
                : "hover:border-catchup-blue-200 hover:bg-catchup-blue-100"
            } h-full bg-catchup-white border border-catchup-light-gray rounded-md transition-all duration-300 cursor-pointer p-4 overflow-y-auto`}
            onClick={() => {
              setSelectedPosition(position);
            }}
          >
            <div className="flex flex-col">
              {selectedPosition === position ? (
                <div className="flex flex-row flex-wrap">
                  <div className="w-3/4">
                    <div className="mx-2">
                      <InputGroup
                        type="select"
                        title={i18n.t("coterie_type")}
                        placeholder={i18n.t("coterie_type")}
                        value={selectedCoterieType}
                        optionList={filterCoterieTypeOptionList(
                          userInformation,
                          userProfile,
                          userProfileInstitution
                        )}
                        onChange={(event) => {
                          handleOnChange("coterieType", event.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-1/4">
                    <div className="mx-2">
                      <InputGroup
                        type="number"
                        title={i18n.t("score")}
                        placeholder={i18n.t("coefficient")}
                        value={coefficient}
                        onChange={(event) => {
                          handleOnChange("coefficient", event.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-3/4">
                    <div className="mx-2">
                      <InputGroup
                        type="select"
                        title={i18n.t("activity_template")}
                        placeholder={i18n.t("activity_template")}
                        value={selectedActivityTemplateId}
                        optionList={retrieveActivityTemplateDTOOptionList(
                          activityTemplateSet
                        )}
                        onChange={(event) => {
                          handleOnChange(
                            "activityTemplateId",
                            event.target.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-1/4">
                    <div className="mx-2">
                      <InputGroup
                        type="select"
                        title={i18n.t("level")}
                        placeholder={i18n.t("level")}
                        value={selectedLevel}
                        optionList={filterGradeLevelOptionList(
                          userProfileInstitution,
                          userProfileGrade
                        )}
                        onChange={(event) => {
                          handleOnChange("level", event.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {selectedLevel !== "DEFAULT_OPTION" &&
                  selectedCoterieType !== "MANAGEMENT" &&
                  selectedCoterieType !== "DEFAULT_OPTION" &&
                  selectedActivityTemplateId !== "DEFAULT_OPTION" ? (
                    <>
                      <div className="w-full">
                        {selectedActivityDTO ? (
                          <SingleActivityItem
                            language={language}
                            activityDTO={selectedActivityDTO}
                            categorySet={categorySet}
                            showActivityCount={false}
                            showActivityTemplate={false}
                            activityCountDTOList={[]}
                            handlePreviewActivityOnClick={
                              handlePreviewActivityOnClick
                            }
                            handleRemoveActivityOnClick={() => {
                              setSelectedActivityDTO(null);
                            }}
                          />
                        ) : (
                          <div className="mt-4 flex flex-col justify-center items-center">
                            <PrimaryButton
                              title={i18n.t("choose_activity")}
                              size="unlimited"
                              onClick={() => {
                                setShowChooseActivityModal(true);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {selectedActivityDTO ? (
                        <div className="mt-4 ml-auto">
                          <CreateButton
                            title={i18n.t("create")}
                            size="small"
                            onClick={() => {
                              handleCreateExternalExamActivityOnClick(position);
                            }}
                          />
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderFoundSingleExamActivity = (
    externalExamPartDTO,
    width,
    height,
    paddingHorizontal,
    paddingVertical
  ) => {
    const { id, coterieType, activityTemplateDTO, activityDTO, coefficient } =
      externalExamPartDTO;
    const constructedUrl = `${connection.app.url}/preview/activity/${
      activityDTO.id
    }/${activityTemplateDTO.id}/${btoa(
      encodeURI(JSON.stringify({ token: accessToken }))
    )}`;
    return (
      <div className="" style={{ width, height }}>
        <div
          className="h-full "
          style={{
            paddingLeft: paddingHorizontal,
            paddingRight: paddingHorizontal,
            paddingTop: paddingVertical,
            paddingBottom: paddingVertical,
          }}
        >
          <div
            className={`h-full bg-catchup-white border border-catchup-light-gray rounded-md transition-all duration-300 p-4 overflow-y-auto`}
            onClick={() => {}}
          >
            <div className="flex flex-col">
              <div className="flex flex-row justify-between my-2 flex-wrap">
                <div className="flex-1 flex flex-row items-center gap-x-2">
                  <CoterieLabel title={i18n.t(coterieType)} />
                  <ActivityTemplateLabel
                    title={i18n.t(activityTemplateDTO.type)}
                  />
                  <div className="">
                    <p>
                      {coefficient * 100} {i18n.t("score")}
                    </p>
                  </div>
                </div>
                {externalExamDTO.evaluationStatus === "NOT_EVALUATED" ? (
                  <div>
                    <BaseImage
                      src="/icons/remove-red.png"
                      alt="remove"
                      size="small"
                      onClick={() => {
                        setShowDeleteModal(true);
                        setSelectedDeleteItemId(id);
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <div
                className="w-full rounded-catchup-xlarge"
                style={{ height: Math.max(400, height - 110) }}
              >
                <iframe
                  width="100%"
                  height="100%"
                  className="rounded-catchup-xlarge"
                  src={constructedUrl}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderSingleExamActivity = (
    position,
    width,
    height,
    paddingHorizontal,
    paddingVertical
  ) => {
    if (!externalExamDTO) return;
    const { externalExamPartDTOList } = externalExamDTO;
    const foundExternalExamPartDTO = externalExamPartDTOList.find(
      (externalExamPartDTO) =>
        externalExamPartDTO.id === selectedExternalExamPartId
    );
    if (!foundExternalExamPartDTO) return;
    const { externalExamActivityDTOList } = foundExternalExamPartDTO;

    const foundExteralExamActivityDTO = externalExamActivityDTOList.find(
      (externalExamPartDTO) => externalExamPartDTO.position === position
    );
    if (foundExteralExamActivityDTO) {
      return RenderFoundSingleExamActivity(
        foundExteralExamActivityDTO,
        width,
        height,
        paddingHorizontal,
        paddingVertical
      );
    } else {
      return RenderDefaultSingleExamActivity(
        position,
        width,
        height,
        paddingHorizontal,
        paddingVertical
      );
    }
  };

  const RenderPageFormat = () => {
    const currentA4Width = a4Height - 58;
    if (selectedPageFormat === "ONE_BY_TWO") {
      const paddingVertical = 12;
      const paddingHorizontal = 16;
      const currentWidth = parseInt(a4Width);
      const currentHeight = parseInt(currentA4Width / 2);
      return (
        <div className="flex flex-col">
          {RenderSingleExamActivity(
            1,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            2,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
        </div>
      );
    } else if (selectedPageFormat === "ONE_BY_THREE") {
      const paddingVertical = 12;
      const paddingHorizontal = 16;
      const currentWidth = parseInt(a4Width);
      const currentHeight = parseInt(currentA4Width / 3);
      return (
        <div className="flex flex-col">
          {RenderSingleExamActivity(
            1,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            2,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            3,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
        </div>
      );
    } else if (selectedPageFormat === "ONE_BY_FOUR") {
      const paddingVertical = 12;
      const paddingHorizontal = 16;
      const currentWidth = parseInt(a4Width);
      const currentHeight = parseInt(currentA4Width / 4);
      return (
        <div className="flex flex-col">
          {RenderSingleExamActivity(
            1,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            2,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            3,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            4,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
        </div>
      );
    } else if (selectedPageFormat === "ONE_BY_FIVE") {
      const paddingVertical = 12;
      const paddingHorizontal = 16;
      const currentWidth = parseInt(a4Width);
      const currentHeight = parseInt(currentA4Width / 5);
      return (
        <div className="flex flex-col">
          {RenderSingleExamActivity(
            1,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            2,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            3,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            4,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            5,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
        </div>
      );
    } else if (selectedPageFormat === "TWO_BY_ONE") {
      const paddingVertical = 16;
      const paddingHorizontal = 12;
      const currentWidth = parseInt(a4Width / 2) - 1;
      const currentHeight = parseInt(currentA4Width);
      return (
        <div className="flex flex-row flex-wrap">
          {RenderSingleExamActivity(
            1,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            2,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
        </div>
      );
    } else if (selectedPageFormat === "TWO_BY_TWO") {
      const paddingVertical = 16;
      const paddingHorizontal = 12;
      const currentWidth = parseInt(a4Width / 2) - 1;
      const currentHeight = parseInt(currentA4Width / 2);
      return (
        <div className="flex flex-row flex-wrap">
          {RenderSingleExamActivity(
            1,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            2,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            3,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            4,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
        </div>
      );
    } else if (selectedPageFormat === "TWO_BY_THREE") {
      const paddingVertical = 16;
      const paddingHorizontal = 12;
      const currentWidth = parseInt(a4Width / 2) - 1;
      const currentHeight = parseInt(currentA4Width / 3);
      return (
        <div className="flex flex-row flex-wrap">
          {RenderSingleExamActivity(
            1,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            2,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            3,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            4,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            5,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            6,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
        </div>
      );
    } else if (selectedPageFormat === "TWO_BY_FOUR") {
      const paddingVertical = 16;
      const paddingHorizontal = 12;
      const currentWidth = parseInt(a4Width / 2) - 1;
      const currentHeight = parseInt(currentA4Width / 4);
      return (
        <div className="flex flex-row flex-wrap">
          {RenderSingleExamActivity(
            1,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            2,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            3,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            4,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            5,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            6,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            7,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
          {RenderSingleExamActivity(
            8,
            currentWidth,
            currentHeight,
            paddingHorizontal,
            paddingVertical
          )}
        </div>
      );
    }
  };

  const RenderExternalExamContent = () => {
    if (!externalExamDTO) return;
    return (
      <>
        <div className="flex flex-row items-center justify-between flex-wrap">
          <div className="flex-1 flex flex-row items-center flex-wrap">
            <div className="w-full lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("page_number")}
                  value={selectedPageNumber}
                  optionList={retrievePageNumberOptionList()}
                  onChange={(event) => {
                    handleCheckPageNumberOnChange(
                      parseFloat(event.target.value)
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center flex-wrap">
            <div className="mx-2">
              <SecondaryButton
                title={i18n.t("convert_activities_to_pdf")}
                size="unlimited"
                onClick={() => {
                  window.open(
                    `/external-exams/${externalExamId}/activities/view/pdf`,
                    "_blank"
                  );
                }}
              />
            </div>
            <div className="mx-2">
              <PrimaryButton
                title={i18n.t("convert_learners_to_pdf")}
                size="unlimited"
                onClick={() => {
                  window.open(
                    `/external-exams/${externalExamId}/learners/view/pdf`,
                    "_blank"
                  );
                }}
              />
            </div>
          </div>
        </div>
        <DividerLine />
        <SelectionTab
          optionList={externalExamDTO.externalExamPartDTOList.map((item) => ({
            id: item.id,
            title: `${i18n.t("page")} ${item.pageNumber}`,
          }))}
          selectedId={selectedExternalExamPartId}
          handleSelectOnClick={(itemId) => {
            setSelectedExternalExamPartId(itemId);
          }}
        />

        <div className="flex-1 flex flex-row items-center flex-wrap">
          <div className="w-full lg:w-1/3 xl:w-1/4">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("page_format")}
                value={selectedPageFormat}
                optionList={retrievePageFormatOptionList()}
                onChange={(event) => {
                  handleCheckPageFormatOnChange(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="" style={{ width: a4Width, height: a4Height }}>
          <div className="flex flex-row justify-between items-center pt-4 px-4">
            <div className="flex flex-col">
              <div className="flex flex-col">
                <p className="font-bold">{externalExamDTO?.name}</p>
              </div>
              <div className="">
                <p className="font-bold">
                  {i18n.t("first_name")} {i18n.t("last_name")}
                </p>
              </div>
            </div>
            <div className="flex flex-row gap-x-2">
              <p className="font-bold text-2xl">{i18n.t("#ID")}</p>
            </div>
          </div>
          {RenderPageFormat()}
        </div>
      </>
    );
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <FullCard isShadowed={true}>
          <div className="">
            <div className="flex flex-row items-center gap-x-2">
              <div className="cursor-pointer">
                <BaseImage
                  size="medium"
                  src="/icons/long-arrow-left.png"
                  alt="long-arrow-left"
                  onClick={() => {
                    handleReturnFromExternalRegistrationOnClick();
                  }}
                />
              </div>
              <BaseTitle
                title={
                  externalExamDTO?.externalExamPartDTOList.length > 0
                    ? i18n.t("update_external_exam_part")
                    : i18n.t("create_external_exam_part")
                }
              />
            </div>
          </div>
          <DividerLine />
          {loading ? (
            <BaseLoading
              size="large"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          ) : (
            RenderExternalExamContent()
          )}
        </FullCard>
      </div>
    );
  };

  const RenderChooseActivityModal = () => {
    return (
      <BaseModal
        isOpen={showChooseActivityModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowChooseActivityModal(false);
        }}
      >
        <div className="flex-1 flex flex-col">
          <ListActivity
            showTitle={false}
            usePadding={false}
            initialFilterApplied={true}
            initialCoterieType={selectedCoterieType}
            initialLevel={selectedLevel}
            showCoterieType={false}
            showLevel={false}
            showDeleted={false}
            labelable={false}
            showLabelList={false}
            previewable={true}
            handlePreviewOnClick={handlePreviewActivityOnClick}
            selectedItemIdList={[]}
            selectable={false}
            handleSelectOnClick={(item) => {
              handleOnChange("activityDTO", item);
              setShowChooseActivityModal(false);
            }}
            activityTemplateTypeList={retrieveActivityTemplateTypeList()}
          />
        </div>
      </BaseModal>
    );
  };

  const RenderShowDeleteModal = () => {
    return (
      <BaseModal
        isOpen={showDeleteModal}
        size="small"
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowDeleteModal(false);
          setSelectedDeleteItemId(null);
        }}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            <div className="flex flex-col items-center gap-y-4">
              <div className="">
                <BaseImage size="large" src="/icons/remove.png" alt="remove" />
              </div>
              <div className="w-[300px] text-center">
                <p className="text-xl">
                  {i18n.t(
                    "are_you_sure_do_you_want_to_delete_external_exam_part_text"
                  )}
                </p>
              </div>
              <div className="flex flex-row items-center justify-center gap-x-4">
                <CancelButton
                  title={i18n.t("cancel")}
                  size="small"
                  onClick={() => {
                    setShowDeleteModal(false);
                  }}
                />
                <PrimaryButton
                  title={i18n.t("approve")}
                  size="small"
                  onClick={() => {
                    setShowDeleteModal(false);
                    handleDeleteExternalExamActivityOnClick();
                  }}
                />
              </div>
            </div>
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  const RenderChangePageFormatModal = () => {
    return (
      <BaseModal
        isOpen={showChangePageFormatModal}
        size="small"
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowChangePageFormatModal(false);
          setSelectedChangePageFormat(null);
        }}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            <div className="flex flex-col items-center gap-y-4">
              <div className="">
                <BaseImage size="large" src="/icons/remove.png" alt="remove" />
              </div>
              <div className="w-[300px] text-center">
                <p className="text-xl">
                  {i18n.t(
                    "are_you_sure_do_you_want_to_change_page_format_text"
                  )}
                </p>
              </div>
              <div className="flex flex-row items-center justify-center gap-x-4">
                <CancelButton
                  title={i18n.t("cancel")}
                  size="small"
                  onClick={() => {
                    setShowChangePageFormatModal(false);
                    setSelectedChangePageFormat(null);
                  }}
                />
                <PrimaryButton
                  title={i18n.t("approve")}
                  size="small"
                  onClick={() => {
                    handleChangePageFormatOnChange();
                  }}
                />
              </div>
            </div>
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  const RenderChangePageNumberModal = () => {
    const isAdd =
      changePageNumberModalActionList.find((item) => item.state === "ADD") !==
      undefined;
    return (
      <BaseModal
        isOpen={showChangePageNumberModal}
        size="small"
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowChangePageNumberModal(false);
          setChangePageNumberModalActionList([]);
        }}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            <div className="flex flex-col items-center gap-y-4">
              <div className="">
                {isAdd ? (
                  <BaseImage
                    size="large"
                    src="/icons/plus-2.png"
                    alt="remove"
                  />
                ) : (
                  <BaseImage
                    size="large"
                    src="/icons/remove.png"
                    alt="remove"
                  />
                )}
              </div>
              <div className="w-[300px] text-center">
                {isAdd ? (
                  <p className="text-xl">
                    {i18n.t("are_you_sure_do_you_want_to_add_page_number_text")}
                  </p>
                ) : (
                  <p className="text-xl">
                    {i18n.t(
                      "are_you_sure_do_you_want_to_remove_page_number_text"
                    )}
                  </p>
                )}
              </div>
              <div className="flex flex-row items-center justify-center gap-x-4">
                <CancelButton
                  title={i18n.t("cancel")}
                  size="small"
                  onClick={() => {
                    setShowChangePageNumberModal(false);
                    setChangePageNumberModalActionList(null);
                  }}
                />
                <PrimaryButton
                  title={i18n.t("approve")}
                  size="small"
                  onClick={() => {
                    handleChangePageNumberOnChange();
                  }}
                />
              </div>
            </div>
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  return (
    <div ref={mainDivRef} className="flex-1 flex flex-col">
      {RenderChooseActivityModal()}
      {RenderShowDeleteModal()}
      {RenderChangePageNumberModal()}
      {RenderChangePageFormatModal()}
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ExternalExamPartListScreen;
