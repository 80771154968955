import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import FullCard from "../../components/cards/FullCard";
import InputGroup from "../../components/groups/InputGroup";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  addOrRemoveActivityBundleToBrandOnClick,
  queryAllActivityBundleDTOListByBrandId,
  queryAssignActivityBundleDTOListByBrandId,
  queryAssignActivityBundleDTOListByBrandIdAndCampusIdAndInstitutionId_Seperately,
  queryAssignActivityBundleDTOListByBrandIdAndCampusId_Seperately,
} from "../../requests/BundleRequests";
import {
  queryCampusListByBrandId,
  queryInstitutionListByCampusId,
  retrieveAllBrandDTOList,
} from "../../requests/ManagementRequests";
import {
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import BaseImage from "../../components/images/BaseImage";
import {
  filterGradeLevelOptionList,
  retrieveBrandDTOOptionList,
} from "../../utilization/ManagementUtilization";
import BaseLoading from "../../components/loading/BaseLoading";

const AssignBundleActivityScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const { userProfileBrand } = useAuth();
  const [activityBundleDTOList, setActivityBundleDTOList] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState("DEFAULT_OPTION");
  const [brandDTOList, setBrandDTOList] = useState([]);
  const [selectedCampusId, setSelectedCampusId] = useState("DEFAULT_OPTION");
  const [campusDTOList, setCampusDTOList] = useState([]);
  const [selectedInstitutionId, setSelectedInstitutionId] =
    useState("DEFAULT_OPTION");
  const [institutionDTOList, setInstitutionDTOList] = useState([]);
  const [assignActivityBundleDTOList, setAssignActivityBundleDTOList] =
    useState([]);
  const [includedActivityBundleIdList, setIncludedActivityBundleIdList] =
    useState([]);
  const [removedActivityBundleIdList, setRemovedActivityBundleIdList] =
    useState([]);
  const [loadingMap, setLoadingMap] = useState({
    activityBundle: false,
    assignActivityBundle: false,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (checkViewPermissionGranted("bundle_activity_assign")) {
      retrieveAndSetActivityBundleList();
      retrieveAndSetBrandList();
    } else {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    if (selectedBrandId === "DEFAULT_OPTION") {
      setCampusDTOList([]);
      setInstitutionDTOList([]);
      setAssignActivityBundleDTOList([]);
    } else {
      retrieveAndSetCampusDTOListByBrandId();
      retrieveAndSetAssignActivityBundleByBrandId();
    }
  }, [selectedBrandId]);

  useEffect(() => {
    if (selectedCampusId === "DEFAULT_OPTION") {
      setInstitutionDTOList([]);
      if (selectedBrandId === "DEFAULT_OPTION") {
        setAssignActivityBundleDTOList([]);
      } else {
        retrieveAndSetAssignActivityBundleByBrandId();
      }
    } else {
      retrieveAndSetInstitutionDTOListByCampusId();
      retrieveAndSetAssignActivityBundleByCampusId();
    }
  }, [selectedCampusId]);

  useEffect(() => {
    if (selectedInstitutionId === "DEFAULT_OPTION") {
      if (selectedCampusId === "DEFAULT_OPTION") {
        if (selectedBrandId === "DEFAULT_OPTION") {
          setAssignActivityBundleDTOList([]);
        } else {
          retrieveAndSetAssignActivityBundleByBrandId();
        }
      } else {
        retrieveAndSetAssignActivityBundleByCampusId();
      }
    } else {
      retrieveAndSetAssignActivityBundleByInstitutionId();
    }
  }, [selectedInstitutionId]);

  const retrieveAndSetBrandList = async () => {
    const { data, err } = await retrieveAllBrandDTOList();
    if (err) {
      console.log(err);
    } else {
      setBrandDTOList(data);
    }
  };

  const retrieveAndSetActivityBundleList = async () => {
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      activityBundle: true,
    }));
    const { data, err } = await queryAllActivityBundleDTOListByBrandId(
      userProfileBrand.id
    );
    if (err) {
      console.log(err);
    } else {
      setActivityBundleDTOList(data);
    }
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      activityBundle: false,
    }));
  };

  const retrieveAndSetCampusDTOListByBrandId = async () => {
    const { data, err } = await queryCampusListByBrandId(selectedBrandId);
    if (err) {
      console.log(err);
    } else {
      setCampusDTOList(data);
    }
  };

  const retrieveAndSetAssignActivityBundleByBrandId = async () => {
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      assignActivityBundle: true,
    }));
    const { data, err } = await queryAssignActivityBundleDTOListByBrandId(
      selectedBrandId
    );
    if (err) {
      console.log(err);
    } else {
      setAssignActivityBundleDTOList(data);
    }
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      assignActivityBundle: false,
    }));
  };

  const retrieveAndSetInstitutionDTOListByCampusId = async () => {
    const { data, err } = await queryInstitutionListByCampusId(
      selectedCampusId
    );
    if (err) {
      console.log(err);
    } else {
      setInstitutionDTOList(data);
    }
  };

  const retrieveAndSetAssignActivityBundleByCampusId = async () => {
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      assignActivityBundle: true,
    }));
    const { data, err } =
      await queryAssignActivityBundleDTOListByBrandIdAndCampusId_Seperately(
        selectedBrandId,
        selectedCampusId
      );
    if (err) {
      console.log(err);
    } else {
      setAssignActivityBundleDTOList(data);
    }
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      assignActivityBundle: false,
    }));
  };

  const retrieveAndSetAssignActivityBundleByInstitutionId = async () => {
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      assignActivityBundle: true,
    }));
    const { data, err } =
      await queryAssignActivityBundleDTOListByBrandIdAndCampusIdAndInstitutionId_Seperately(
        selectedBrandId,
        selectedCampusId,
        selectedInstitutionId
      );
    if (err) {
      console.log(err);
    } else {
      setAssignActivityBundleDTOList(data);
    }
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      assignActivityBundle: false,
    }));
  };

  const retrieveIncludedActivityBundleList = () => {
    const currentActivityBundleDTOList = JSON.parse(
      JSON.stringify(
        assignActivityBundleDTOList
          .filter(
            (assignActivityBundle) =>
              assignActivityBundle.activityBundleDTO.brandId ===
              userProfileBrand.id
          )
          .map((assignActivityBundle) => ({
            id: assignActivityBundle.activityBundleDTO.id,
            name: assignActivityBundle.activityBundleDTO.name,
            description: assignActivityBundle.activityBundleDTO.description,
            level: assignActivityBundle.activityBundleDTO.level,
            assignedBrandId: assignActivityBundle.brandId,
            assignedCampusId: assignActivityBundle.campusId,
            assignedInstitutionId: assignActivityBundle.institutionId,
          }))
      )
    );
    includedActivityBundleIdList.forEach((includedActivityBundleId) => {
      const foundActivityBundle = activityBundleDTOList.find(
        (activityBundle) =>
          parseFloat(activityBundle.id) === parseFloat(includedActivityBundleId)
      );
      currentActivityBundleDTOList.push(foundActivityBundle);
    });
    removedActivityBundleIdList.forEach((removedActivityBundleId) => {
      const foundActivityBundleIndex = currentActivityBundleDTOList.findIndex(
        (currentActivityBundle) =>
          parseFloat(currentActivityBundle.id) ===
          parseFloat(removedActivityBundleId)
      );
      currentActivityBundleDTOList.splice(foundActivityBundleIndex, 1);
    });
    return currentActivityBundleDTOList;
  };

  const retrieveNotIncludedActivityBundleList = () => {
    const currentActivityBundleDTOList = JSON.parse(
      JSON.stringify(
        activityBundleDTOList.filter(
          (activityBundleDTO) =>
            assignActivityBundleDTOList.findIndex(
              (assignedActivityBundle) =>
                parseFloat(assignedActivityBundle.activityBundleDTO.id) ===
                parseFloat(activityBundleDTO.id)
            ) === -1
        )
      )
    );
    includedActivityBundleIdList.forEach((includedActivityBundleId) => {
      const foundIndex = currentActivityBundleDTOList.findIndex(
        (currentActivityBundle) =>
          parseFloat(currentActivityBundle.id) ===
          parseFloat(includedActivityBundleId)
      );
      if (foundIndex > -1) {
        currentActivityBundleDTOList.splice(foundIndex, 1);
      }
    });
    removedActivityBundleIdList.forEach((removedActivityBundleId) => {
      const foundActivityBundle = activityBundleDTOList.find(
        (activityBundle) =>
          parseFloat(activityBundle.id) === parseFloat(removedActivityBundleId)
      );
      currentActivityBundleDTOList.push(foundActivityBundle);
    });
    return currentActivityBundleDTOList;
    // return currentAuthorityDTOList.filter((currentAuthorityDTO) =>
    //   currentAuthorityDTO.name.includes(selectedAvailableFilter)
    // );
  };

  const retrieveCampusDTOOptionList = () => {
    return campusDTOList.map((campusDTO) => ({
      value: campusDTO.id,
      text: `${campusDTO.name} (${campusDTO.brandDTO.name})`,
    }));
  };

  const retrieveInstitutionDTOOptionList = () => {
    return institutionDTOList.map((institutionDTO) => ({
      value: institutionDTO.id,
      text: `${institutionDTO.name} (${institutionDTO.campusDTO.name} - ${institutionDTO.campusDTO.brandDTO.name})`,
    }));
  };

  const filterActivityBundleDTOList = (
    activityBundleDTOList,
    gradeLevelList
  ) => {
    return activityBundleDTOList
      .filter((activityBundleDTO) =>
        gradeLevelList.includes(activityBundleDTO.level)
      )
      .sort((a, b) => a.level - b.level);
  };

  const filterGradeLevelList = () => {
    if (!selectedInstitutionId) return [];
    const foundInstitutionDTO = institutionDTOList.find(
      (institutionDTO) => institutionDTO.id === selectedInstitutionId
    );
    const filteredGradeLevelOptionList =
      filterGradeLevelOptionList(foundInstitutionDTO);
    return filteredGradeLevelOptionList.map((item) => item.value);
  };

  const checkCanShowRemoveIcon = (activityBundleDTO) => {
    if (
      activityBundleDTO.assignedBrandId !== null &&
      activityBundleDTO.assignedCampusId === null &&
      activityBundleDTO.assignedInstitutionId === null &&
      selectedBrandId !== "DEFAULT_OPTION" &&
      selectedCampusId === "DEFAULT_OPTION" &&
      selectedInstitutionId === "DEFAULT_OPTION"
    ) {
      return true;
    } else if (
      activityBundleDTO.assignedBrandId !== null &&
      activityBundleDTO.assignedCampusId !== null &&
      activityBundleDTO.assignedInstitutionId === null &&
      selectedBrandId !== "DEFAULT_OPTION" &&
      selectedCampusId !== "DEFAULT_OPTION" &&
      selectedInstitutionId === "DEFAULT_OPTION"
    ) {
      return true;
    } else if (
      activityBundleDTO.assignedBrandId !== null &&
      activityBundleDTO.assignedCampusId !== null &&
      activityBundleDTO.assignedInstitutionId !== null &&
      selectedBrandId !== "DEFAULT_OPTION" &&
      selectedCampusId !== "DEFAULT_OPTION" &&
      selectedInstitutionId !== "DEFAULT_OPTION"
    ) {
      return true;
    } else if (
      !activityBundleDTO.assignedBrandId &&
      !activityBundleDTO.assignedCampusId &&
      !activityBundleDTO.assignedInstitutionId
    ) {
      return true;
    }
    return false;
  };

  const handleOnChange = (type, value) => {
    if (type === "brandId") {
      setSelectedBrandId(value);
      setSelectedCampusId("DEFAULT_OPTION");
      setSelectedInstitutionId("DEFAULT_OPTION");
      setIncludedActivityBundleIdList([]);
      setRemovedActivityBundleIdList([]);
    } else if (type === "campusId") {
      setSelectedCampusId(value);
      setSelectedInstitutionId("DEFAULT_OPTION");
      setIncludedActivityBundleIdList([]);
      setRemovedActivityBundleIdList([]);
    } else if (type === "institutionId") {
      setSelectedInstitutionId(value);
      setIncludedActivityBundleIdList([]);
      setRemovedActivityBundleIdList([]);
    }
  };

  const handleAddOrRemoveMultipleActivityBundleToBrandOnClick = async () => {
    if (checkUpdatePermissionGranted("bundle_activity_assign")) {
      const { data, err } = await addOrRemoveActivityBundleToBrandOnClick({
        includedIdList: includedActivityBundleIdList,
        removedIdList: removedActivityBundleIdList,
        brandId: selectedBrandId !== "DEFAULT_OPTION" ? selectedBrandId : null,
        campusId:
          selectedCampusId !== "DEFAULT_OPTION" ? selectedCampusId : null,
        institutionId:
          selectedInstitutionId !== "DEFAULT_OPTION"
            ? selectedInstitutionId
            : null,
      });
      if (err) {
        console.log(err);
      } else {
        setIncludedActivityBundleIdList([]);
        setRemovedActivityBundleIdList([]);
        if (selectedInstitutionId !== "DEFAULT_OPTION") {
          retrieveAndSetAssignActivityBundleByInstitutionId();
        } else if (selectedCampusId !== "DEFAULT_OPTION") {
          retrieveAndSetAssignActivityBundleByCampusId();
        } else if (selectedBrandId !== "DEFAULT_OPTION") {
          retrieveAndSetAssignActivityBundleByBrandId();
        }
        NotificationManager.success(
          i18n.t("you_have_successfully_updated_assign_bundle_activity")
        );
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const RenderSingleIncludedActivityBundleItem = (activityBundleDTO) => {
    return (
      <div className="flex flex-row items-center my-2 p-2 border-b-2">
        <div className="mx-1">
          {checkCanShowRemoveIcon(activityBundleDTO) ? (
            <BaseImage
              src="/icons/remove-red.png"
              alt="remove-red"
              size="small"
              onClick={() => {
                const foundIncludedIndex =
                  includedActivityBundleIdList.findIndex(
                    (includedActivityBundleId) =>
                      parseFloat(includedActivityBundleId) ===
                      parseFloat(activityBundleDTO.id)
                  );
                if (foundIncludedIndex !== -1) {
                  includedActivityBundleIdList.splice(foundIncludedIndex, 1);
                  setIncludedActivityBundleIdList(
                    JSON.parse(JSON.stringify(includedActivityBundleIdList))
                  );
                } else {
                  const foundIndex = removedActivityBundleIdList.findIndex(
                    (removedActivityBundleId) =>
                      parseFloat(removedActivityBundleId) ===
                      parseFloat(activityBundleDTO.id)
                  );
                  if (foundIndex === -1) {
                    removedActivityBundleIdList.push(activityBundleDTO.id);
                    setRemovedActivityBundleIdList(
                      JSON.parse(JSON.stringify(removedActivityBundleIdList))
                    );
                  }
                }
              }}
            />
          ) : null}
        </div>

        <div className="flex flex-row flex-wrap mx-1">
          <div className="mr-1">
            <Tooltip id="activity-bundle-description" />
            <div className="mr-2">
              <BaseImage
                size="small"
                src="/icons/info.png"
                alt="info"
                dataToolTipId="activity-bundle-description"
                dataToolTipContent={activityBundleDTO.description}
                dataToolTipPlace="top"
                dataToolTipVariant="info"
              />
            </div>
          </div>
          <div className="ml-1">
            <p className="font-semibold">{activityBundleDTO.name}</p>
          </div>
          <div className="">
            <p className="italic">
              {activityBundleDTO.assignedInstitutionId
                ? `(${i18n.t("INSTITUTION")})`
                : activityBundleDTO.assignedCampusId
                ? `(${i18n.t("CAMPUS")})`
                : activityBundleDTO.assignedBrandId
                ? `(${i18n.t("BRAND")})`
                : null}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const RenderSingleNonIncludedActivityBundleItem = (activityBundleDTO) => {
    return (
      <div className="flex flex-row items-center my-2 p-2 border-b-2">
        <div className="mx-1">
          <div className="mx-1">
            <BaseImage
              src="/icons/plus.png"
              alt="plus"
              size="small"
              onClick={() => {
                const foundRemovedIndex = removedActivityBundleIdList.findIndex(
                  (removedActivityBundleId) =>
                    parseFloat(removedActivityBundleId) ===
                    parseFloat(activityBundleDTO.id)
                );
                if (foundRemovedIndex !== -1) {
                  removedActivityBundleIdList.splice(foundRemovedIndex, 1);
                  setRemovedActivityBundleIdList(
                    JSON.parse(JSON.stringify(removedActivityBundleIdList))
                  );
                } else {
                  const foundIndex = includedActivityBundleIdList.findIndex(
                    (includedActivityBundleId) =>
                      parseFloat(includedActivityBundleId) ===
                      parseFloat(activityBundleDTO.id)
                  );
                  if (foundIndex === -1) {
                    includedActivityBundleIdList.push(activityBundleDTO.id);
                    setIncludedActivityBundleIdList(
                      JSON.parse(JSON.stringify(includedActivityBundleIdList))
                    );
                  }
                }
              }}
            />
          </div>
        </div>
        <div className="flex flex-row flex-wrap mx-1">
          <div className="mr-1">
            <Tooltip id="activity-bundle-description" />
            <div className="mr-2">
              <BaseImage
                size="small"
                src="/icons/info.png"
                alt="info"
                dataToolTipId="activity-bundle-description"
                dataToolTipContent={activityBundleDTO.description}
                dataToolTipPlace="top"
                dataToolTipVariant="info"
              />
            </div>
          </div>
          <div className="">
            <p className="ml-1">{activityBundleDTO.name}</p>
          </div>
        </div>
      </div>
    );
  };

  const RenderMainContent = () => {
    return (
      <FullCard isShadowed={true}>
        <div className="">
          <p className="font-semibold text-lg">
            {i18n.t("institution_selection")}
          </p>
        </div>
        <div className="mb-3">
          <div className="flex flex-row items-center flex-wrap">
            <div className="w-1/2 lg:w-1/3">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("brand_name")}
                  placeholder={i18n.t("brand_name")}
                  value={selectedBrandId}
                  optionList={retrieveBrandDTOOptionList(brandDTOList)}
                  onChange={(event) => {
                    handleOnChange("brandId", event.target.value);
                  }}
                />
              </div>
            </div>
            {selectedBrandId !== "DEFAULT_OPTION" ? (
              <div className="w-1/2 lg:w-1/3">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("campus_name")}
                    placeholder={i18n.t("campus_name")}
                    value={selectedCampusId}
                    optionList={retrieveCampusDTOOptionList()}
                    onChange={(event) => {
                      handleOnChange("campusId", event.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}

            {selectedCampusId !== "DEFAULT_OPTION" ? (
              <div className="w-1/2 lg:w-1/3">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("institution_name")}
                    placeholder={i18n.t("institution_name")}
                    value={selectedInstitutionId}
                    optionList={retrieveInstitutionDTOOptionList()}
                    onChange={(event) => {
                      handleOnChange("institutionId", event.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {selectedBrandId !== "DEFAULT_OPTION" ? (
          loadingMap.activityBundle === false &&
          loadingMap.assignActivityBundle === false ? (
            <>
              <div className="flex flex-row">
                <div className="flex-1">
                  <div className="text-xl font-medium">
                    <p>{i18n.t("available_bundle_activitys")}</p>
                  </div>
                  <div className="m-2 p-2 h-catchup-user-authority overflow-auto">
                    {filteredNonIncludedActivityBundleList.map(
                      (notIncludedActivityBundle, index) => (
                        <div key={index}>
                          {RenderSingleNonIncludedActivityBundleItem(
                            notIncludedActivityBundle
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>

                <div className="flex-1">
                  <div className="text-xl font-medium">
                    <p>{i18n.t("assigned_bundle_activitys")}</p>
                  </div>
                  <div className="m-2 p-2 h-catchup-user-authority overflow-auto">
                    {filteredIncludedActivityBundleDTOList.map(
                      (activityBundleDTO, index) => (
                        <div key={index}>
                          {RenderSingleIncludedActivityBundleItem(
                            activityBundleDTO
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-4 ml-auto">
                <PrimaryButton
                  title={i18n.t("update")}
                  size="medium"
                  onClick={
                    handleAddOrRemoveMultipleActivityBundleToBrandOnClick
                  }
                />
              </div>
            </>
          ) : (
            <BaseLoading
              size="large"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          )
        ) : null}
      </FullCard>
    );
  };

  const filteredGradeLevelList = filterGradeLevelList();

  const nonIncludedActivityBundleDTOList =
    retrieveNotIncludedActivityBundleList();
  const filteredNonIncludedActivityBundleList = filterActivityBundleDTOList(
    nonIncludedActivityBundleDTOList,
    filteredGradeLevelList
  );

  const includedActivityBundleDTOList = retrieveIncludedActivityBundleList();
  const filteredIncludedActivityBundleDTOList = filterActivityBundleDTOList(
    includedActivityBundleDTOList,
    filteredGradeLevelList
  );

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default AssignBundleActivityScreen;
