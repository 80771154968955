import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Server
 */
export const checkCatchtivityService = async () => {
  try {
    const response = await axios
      .create()
      .get(
        `${connection.base.catchtivity.url}${endpoints.catchtivities.context}${endpoints.catchtivities.actuator.health.context}`
      );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Dashboard Metrics
 */
export const retrieveGenixoDashboardMetricsCatchtivity = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.metrics.context}${endpoints.catchtivities.metrics.dashboard.genixo.context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveUserStaffDashboardMetricsCatchtivity = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.metrics.context}${endpoints.catchtivities.metrics.dashboard.user.staff.context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveUserIndividualDashboardMetricsCatchtivity = async (
  params
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.metrics.context}${endpoints.catchtivities.metrics.dashboard.user.individual.context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveUserContentCreatorDashboardMetricsCatchtivity = async (
  params
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.metrics.context}${endpoints.catchtivities.metrics.dashboard.user["content-creator"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Catchtivity
 */
export const retrieveCatchtivityById = async (catchtivityId) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${
        endpoints.catchtivities.context
      }${endpoints.catchtivities.dto.id.context.replace("{id}", catchtivityId)}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveAllCatchtivityList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.context}${endpoints.catchtivities.dto.context}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createCatchtivityWithActivityIdList = async (newCatchtivity) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.context}${endpoints.catchtivities.with.activityIdList.context}`,
      newCatchtivity
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchCatchtivity = async (patchCatchtivity) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.context}/`,
      patchCatchtivity
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteCatchtivity = async (catchtivityId) => {
  try {
    const response = await axios.delete(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.context}/${catchtivityId}`
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

export const queryCatchtivityFromCatchtivityAccessibilityByParams = async (
  queryCatchtivityAccessibilityParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.context}${endpoints.catchtivities.query.dto["from-accessibility"]["by-brandId-campusId-institutionId"].context}`,
      queryCatchtivityAccessibilityParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryCatchtivityDTOListByUserIdUserProfileId = async (
  queryCatchtivityAccessibilityParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.context}${endpoints.catchtivities.query.dto["by-userId-userProfileId"].context}`,
      queryCatchtivityAccessibilityParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllCatchtivityDTOPageByAccessibilityParams = async (
  pagedParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.context}${endpoints.catchtivities.paged.query.dto["from-accessibility"]["by-brandId-campusId-institutionId"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllCatchtivityDTOPageByUserIdAndUserProfileId = async (
  pagedParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.context}${endpoints.catchtivities.paged.query.dto["from-accessibility"]["by-userId-userProfileId"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Catchtivity Accessibilities
 */
export const retrieveCatchtivityAccessibilityByCatchtivityId = async (
  catchtivityId
) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.accessibilities.context}${endpoints.catchtivities.accessibilities.dto["by-catchtivityId"].context}/${catchtivityId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createCatchtivityAccessibilityByCatchtivityId = async (
  catchtivityAccessibility
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.accessibilities.context}${endpoints.catchtivities.accessibilities["by-catchtivityId"].context}`,
      catchtivityAccessibility
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchCatchtivityAccessibility = async (
  patchCatchtivityAccessibility
) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.accessibilities.context}/`,
      patchCatchtivityAccessibility
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Catchtivity Application
 */
export const retrieveCatchtivityApplicationInDetail = async (id) => {
  try {
    const constructedUrl =
      `${connection.base.catchtivity.url}${endpoints.catchtivities.applications.context}${endpoints.catchtivities.applications.dto.id.detail.context}`.replace(
        "{id}",
        id
      );
    const response = await axios.get(constructedUrl);
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveAllCatchtivityApplicationList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.applications.context}/`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createCatchtivityApplicationByCatchtivityId = async (
  newCatchtivityApplication
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.applications.context}${endpoints.catchtivities.applications["by-catchtivityId"].context}`,
      newCatchtivityApplication
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchCatchtivityApplication = async (
  currentCatchtivityApplication
) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.applications.context}/`,
      currentCatchtivityApplication
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteCatchtivityApplication = async (
  catchtivityApplicationId
) => {
  try {
    const response = await axios.delete(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.applications.context}/${catchtivityApplicationId}`
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

export const queryAllCatchtivityApplicationDTOPageByFilter = async (
  pagedParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.applications.context}${endpoints.catchtivities.applications.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllCatchtivityApplicationListBySeasonGradeBranchMapList =
  async (queryParams) => {
    try {
      const response = await axios.post(
        `${connection.base.catchtivity.url}${endpoints.catchtivities.applications.context}${endpoints.catchtivities.applications.query.dto["by-seasonId-gradeBranchMapList"].context}`,
        queryParams
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

export const queryAllCatchtivityListByPlanWeekItemList = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.context}${endpoints.catchtivities.query.dto["by-planWeekItemList"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllCatchtivityApplicationListByPlanWeekItemList = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.applications.context}${endpoints.catchtivities.applications.query.dto["by-planWeekItemList"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllCatchtivityApplicationDTOListByFilterActive = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.applications.context}${endpoints.catchtivities.applications.query.dto["by-filter"].active.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const evaluateCatchtivityApplicationById = async (id) => {
  try {
    const constructedUrl =
      `${connection.base.catchtivity.url}${endpoints.catchtivities.applications.context}${endpoints.catchtivities.applications.id.evaluate.context}`.replace(
        "{id}",
        id
      );
    const response = await axios.get(constructedUrl);
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Catchxam
 */
export const retrieveCatchxamById = async (catchxamId) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${
        endpoints.catchtivities.catchxams.context
      }${endpoints.catchtivities.catchxams.dto.id.context.replace(
        "{id}",
        catchxamId
      )}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllCatchxamDTOPageByAccessibilityParams = async (
  pagedParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.context}${endpoints.catchtivities.catchxams.paged.query.dto["from-accessibility"]["by-brandId-campusId-institutionId"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllCatchxamDTOPageByUserIdAndUserProfileId = async (
  pagedParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.context}${endpoints.catchtivities.catchxams.paged.query.dto["from-accessibility"]["by-userId-userProfileId"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryCatchxamFromCatchxamAccessibilityByParams = async (
  queryCatchxamAccessibilityParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.context}${endpoints.catchtivities.catchxams.query.dto["from-accessibility"]["by-brandId-campusId-institutionId"].context}`,
      queryCatchxamAccessibilityParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryCatchxamDTOListByUserIdUserProfileId = async (
  queryCatchxamAccessibilityParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.context}${endpoints.catchtivities.catchxams.query.dto["by-userId-userProfileId"].context}`,
      queryCatchxamAccessibilityParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createCatchxamWithActivityIdList = async (newCatchxam) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.context}${endpoints.catchtivities.catchxams.with.activityIdList.context}`,
      newCatchxam
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchCatchxam = async (patchCatchxam) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.context}/`,
      patchCatchxam
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteCatchxam = async (catchxamId) => {
  try {
    const response = await axios.delete(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.context}/${catchxamId}`
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

/**
 * Catchxam Accessibilities
 */
export const retrieveCatchxamAccessibilityByCatchxamId = async (catchxamId) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.accessibilities.context}${endpoints.catchtivities.catchxams.accessibilities.dto["by-catchxamId"].context}/${catchxamId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createCatchxamAccessibilityByCatchxamId = async (
  catchxamAccessibility
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.accessibilities.context}${endpoints.catchtivities.catchxams.accessibilities["by-catchxamId"].context}`,
      catchxamAccessibility
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchCatchxamAccessibility = async (
  patchCatchxamAccessibility
) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.accessibilities.context}/`,
      patchCatchxamAccessibility
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Catchxam Application
 */
export const retrieveCatchxamApplicationInDetail = async (id) => {
  try {
    const constructedUrl =
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.applications.context}${endpoints.catchtivities.catchxams.applications.dto.id.detail.context}`.replace(
        "{id}",
        id
      );
    const response = await axios.get(constructedUrl);
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveAllCatchxamApplicationList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.applications.context}/`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createCatchxamApplicationByCatchxamId = async (
  catchxamApplicationId
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.applications.context}${endpoints.catchtivities.catchxams.applications["by-catchxamId"].context}`,
      catchxamApplicationId
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchCatchxamApplication = async (currentCatchxamApplication) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.applications.context}/`,
      currentCatchxamApplication
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteCatchxamApplication = async (catchxamApplicationId) => {
  try {
    const response = await axios.delete(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.applications.context}/${catchxamApplicationId}`
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

export const queryAllCatchxamApplicationDTOPageByFilter = async (
  pagedParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.applications.context}${endpoints.catchtivities.catchxams.applications.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllCatchxamApplicationDTOListByFilterActive = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.applications.context}${endpoints.catchtivities.catchxams.applications.query.dto["by-filter"].active.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllCatchxamApplicationListBySeasonGradeBranchMapList = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.applications.context}${endpoints.catchtivities.catchxams.applications.query.dto["by-seasonId-gradeBranchMapList"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllCatchxamListByPlanWeekItemList = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.context}${endpoints.catchtivities.catchxams.query.dto["by-planWeekItemList"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllCatchxamApplicationListByPlanWeekItemList = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.applications.context}${endpoints.catchtivities.catchxams.applications.query.dto["by-planWeekItemList"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const evaluateCatchxamApplicationById = async (id) => {
  try {
    const constructedUrl =
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.applications.context}${endpoints.catchtivities.catchxams.applications.id.evaluate.context}`.replace(
        "{id}",
        id
      );
    const response = await axios.get(constructedUrl);
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrievePerformanceCatchxamApplication = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.applications.context}${endpoints.catchtivities.catchxams.applications.performance.learner.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Standard Exams
 */
export const retrieveStandardExamById = async (standardExamId) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${
        endpoints.catchtivities["standard-exams"].context
      }${endpoints.catchtivities["standard-exams"].dto.id.context.replace(
        "{id}",
        standardExamId
      )}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryStandardExamFromStandardExamAccessibilityByParams = async (
  queryStandardExamAccessibilityParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].context}${endpoints.catchtivities["standard-exams"].query.dto["from-accessibility"]["by-brandId-campusId-institutionId"].context}`,
      queryStandardExamAccessibilityParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllStandardExamDTOPageByAccessibilityParams = async (
  pagedParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].context}${endpoints.catchtivities["standard-exams"].paged.query.dto["from-accessibility"]["by-brandId-campusId-institutionId"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllStandardExamDTOPageByUserIdAndUserProfileId = async (
  pagedParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].context}${endpoints.catchtivities["standard-exams"].paged.query.dto["from-accessibility"]["by-userId-userProfileId"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryStandardExamDTOListByUserIdUserProfileId = async (
  queryStandardExamAccessibilityParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].context}${endpoints.catchtivities["standard-exams"].query.dto["by-userId-userProfileId"].context}`,
      queryStandardExamAccessibilityParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createStandardExamWithActivityIdList = async (standardExam) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].context}${endpoints.catchtivities["standard-exams"].with.activityIdList.context}`,
      standardExam
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

export const updateStandardExam = async (standardExam) => {
  try {
    const response = await axios.put(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].context}`,
      standardExam
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

export const patchStandardExam = async (patchStandardExam) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].context}/`,
      patchStandardExam
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteStandardExam = async (standardExamId) => {
  try {
    const response = await axios.delete(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].context}/${standardExamId}`
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

/**
 * Standard Exam Accessibilities
 */
export const retrieveStandardExamAccessibilityByStandardExamId = async (
  standardExamId
) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].accessibilities.context}${endpoints.catchtivities["standard-exams"].accessibilities.dto["by-standardExamId"].context}/${standardExamId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveCustomStandardExamAccessibilityByStandardExamId = async (
  standardExamId
) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].accessibilities.context}${endpoints.catchtivities["standard-exams"].accessibilities.dto["by-standardExamId"].context}/${standardExamId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createStandardExamAccessibilityByStandardExamId = async (
  standardExamAccessibility
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].accessibilities.context}${endpoints.catchtivities["standard-exams"].accessibilities["by-standardExamId"].context}`,
      standardExamAccessibility
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchStandardExamAccessibility = async (
  patchStandardExamAccessibility
) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].accessibilities.context}/`,
      patchStandardExamAccessibility
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Standard Exam Application
 */
export const retrieveStandardExamApplicationInDetail = async (id) => {
  try {
    const constructedUrl =
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].applications.context}${endpoints.catchtivities["standard-exams"].applications.dto.id.detail.context}`.replace(
        "{id}",
        id
      );
    const response = await axios.get(constructedUrl);
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createStandardExamApplicationByStandardExamId = async (
  newStandardExamApplication
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].applications.context}${endpoints.catchtivities["standard-exams"].applications["by-standardExamId"].context}`,
      newStandardExamApplication
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllStandardExamApplicationDTOPageByFilter = async (
  pagedParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].applications.context}${endpoints.catchtivities["standard-exams"].applications.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllStandardExamApplicationDTOListByFilterActive = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].applications.context}${endpoints.catchtivities["standard-exams"].applications.query.dto["by-filter"].active.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchStandardExamApplication = async (
  currentStandardExamApplication
) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].applications.context}/`,
      currentStandardExamApplication
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteStandardExamApplication = async (
  standardExamApplicationId
) => {
  try {
    const response = await axios.delete(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].applications.context}/${standardExamApplicationId}`
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

export const evaluateStandardExamApplicationById = async (id) => {
  try {
    const constructedUrl =
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].applications.context}${endpoints.catchtivities["standard-exams"].applications.id.evaluate.context}`.replace(
        "{id}",
        id
      );
    const response = await axios.get(constructedUrl);
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Activity
 */
export const retrieveAllActivityList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.dto.context}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveActivityWithDataById = async (id) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.with.data.dto.context}/${id}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveActivityById = async (id) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.dto.context}/${id}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createActivityWithData = async (newActivity) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.with.data.context}`,
      newActivity
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchActivityWithData = async (patchActivity) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.with.data.context}`,
      patchActivity
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteActivity = async (activityId) => {
  try {
    const response = await axios.delete(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}/${activityId}`
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

export const queryActivityListByUserIdUserProfileId = async (
  queryActivityAccessibilityParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.query.dto["by-userId-userProfileId"].context}`,
      queryActivityAccessibilityParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActivityListByAccessibilityParams = async (
  queryActivityAccessibilityParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.query.dto["from-accessibility"].context}`,
      queryActivityAccessibilityParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllActivityDTOPageByAccessibilityParams = async (
  pagedParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.paged.query.dto["from-accessibility"]["by-brandId-campusId-institutionId"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllActivityDTOPageByUserIdAndUserProfileId = async (
  pagedParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.paged.query.dto["from-accessibility"]["by-userId-userProfileId"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActivityDTOListByIdList = async (idList) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.query.dto["by-idList"].context}`,
      { idList }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActivityDTOListByCategoryIdList = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.query.dto.with.data["by-categoryId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryInvalidActivityDTOListByIncomplete = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.check.invalid.incomplete.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryInvalidActivityDTOListByNotInBold = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.check.invalid["not-in-bold"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryInvalidActivityDTOListByRedaction = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.check.invalid.redaction.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryInvalidActivityDTOListByWrongActivity = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.check.invalid["wrong-activity"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryInvalidActivityDTOListByIncompleteAnswer = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.check.invalid["incomplete-answer"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryInvalidActivityDTOListByWrongAnswer = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.check.invalid["wrong-answer"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryInvalidActivityDTOListByMathematicalExpression = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.check.invalid["mathematical-expression"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryInvalidActivityDTOListByNotFlagged = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.check.invalid["not-flagged"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Activity Evaluations
 */
export const patchActivityEvaluation = async (patchActivityEvaluation) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.evaluations.context}/`,
      patchActivityEvaluation
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const checkUnevaluatedExists = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.evaluations.context}${endpoints.catchtivities.activities.evaluations.check["unevaluated-exists"].context}/`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Activity Accessibilities
 */
export const retrieveActivityAccessibilityByActivityId = async (activityId) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.accessibilities.context}${endpoints.catchtivities.activities.accessibilities.dto["by-activityId"].context}/${activityId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createActivityAccessibilityByActivityId = async (
  activityAccessibility
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.accessibilities.context}${endpoints.catchtivities.activities.accessibilities["by-activityId"].context}`,
      activityAccessibility
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchActivityAccessibility = async (
  patchActivityAccessibility
) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.accessibilities.context}/`,
      patchActivityAccessibility
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Activity Templates
 */
export const retrieveAllActivityTemplateList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["activity-templates"].context}${endpoints.catchtivities["activity-templates"].dto.context}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveActivityTemplateDTOById = async (activityTemplateId) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["activity-templates"].context}${endpoints.catchtivities["activity-templates"].id.dto.context}`.replace(
        "{id}",
        activityTemplateId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveAllBaseRuleList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["activity-templates"].context}${endpoints.catchtivities["activity-templates"]["base-rules"].dto.context}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllActivityTemplateDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["activity-templates"].context}${endpoints.catchtivities["activity-templates"].paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const addOrRemoveBaseRuleToActivityTemplate = async (
  baseRuleOperations
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["activity-templates"].context}${endpoints.catchtivities["activity-templates"]["add-or-remove"].multiple["base-rule-to-activity-template"].context}`,
      baseRuleOperations
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveBaseRuleListByActivityTemplateId = async (
  activityTemplateId
) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["activity-templates"].context}${endpoints.catchtivities["activity-templates"].id["base-rules"].dto.context}`.replace(
        "{id}",
        activityTemplateId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const convertActivityTemplateWithAI = async (generateWithAI) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.ai["activity-template-generator"].context}${endpoints.catchtivities.ai["activity-template-generator"]["from-gpt4"].mcsa.context}`,
      generateWithAI
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateActivityTemplateWithAI = async (generateWithAI) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.ai["activity-template-generator"].context}${endpoints.catchtivities.ai["activity-template-generator"]["from-ollama"].context}`,
      generateWithAI
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const evaluateActivityOpenEndedWithAI = async (evaluateWithAI) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.ai["activity-evaluate"].context}${endpoints.catchtivities.ai["activity-evaluate"]["from-gpt4"]["open-ended"].context}`,
      evaluateWithAI
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Activity Category Score
 */
export const queryAllActivityCategoryScoreListByParams = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["activity-category-scores"].context}${endpoints.catchtivities["activity-category-scores"].query["by-params"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Etude
 */
export const createEtudeFromBaseActivityList = async (newEtude) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.etudes.context}${endpoints.catchtivities.etudes["from-base-activity-list"].context}`,
      newEtude
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createEtudeListFromBaseActivityList = async (newEtudeList) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.etudes.context}${endpoints.catchtivities.etudes["as-list"]["from-base-activity-list"].context}`,
      newEtudeList
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchEtudeListFromBaseActivityList = async (patchEtudeList) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.etudes.context}${endpoints.catchtivities.etudes["as-list"]["from-base-activity-list"].context}`,
      patchEtudeList
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllEtudeListByParams = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.etudes.context}${endpoints.catchtivities.etudes.query["by-params"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllEtudeDTOListByUserIdAndUserProfileId = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.etudes.context}${endpoints.catchtivities.etudes.query.dto["by-userId-userProfileId"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveAllEtudeCategoryScoreDTOListByEtudeId = async (
  etudeId
) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.etudes.context}${endpoints.catchtivities.etudes.dto.id.scores.context}`.replace(
        "{id}",
        etudeId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Activity Transfer
 */
export const activityTransfer = async (transferParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.transfer.context}${endpoints.catchtivities.activities.transfer["to-institution"].context}`,
      transferParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Activity Count
 */
export const queryCatchtivityActivityCountByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.context}${endpoints.catchtivities.query["activity-count"]["by-params"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryCatchxamActivityCountByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.context}${endpoints.catchtivities.catchxams.query["activity-count"]["by-params"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryStandardExamActivityCountByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].context}${endpoints.catchtivities["standard-exams"].query["activity-count"]["by-params"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * AI
 */
export const processImageToMCSAWithAI = async (processImageParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.ai["image-processing"].context}${endpoints.catchtivities.ai["image-processing"]["from-gpt4"]["to-mcsa"].context}`,
      processImageParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const solveActivityWithAIFromGPT4 = async (solveWithAI) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.ai["activity-solver"].context}${endpoints.catchtivities.ai["activity-solver"]["from-gpt4"].context}`,
      solveWithAI
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const solveActivityWithAIFromO1 = async (solveWithAI) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.ai["activity-solver"].context}${endpoints.catchtivities.ai["activity-solver"]["from-o1"].context}`,
      solveWithAI
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Activity Template Feedback
 */
export const queryActivityTemplateFeedbackByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["activity-template-feedbacks"].context}${endpoints.catchtivities["activity-template-feedbacks"].query.dto["by-params"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActivityTemplateFeedbackByUserIdUserProfile = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["activity-template-feedbacks"].context}${endpoints.catchtivities["activity-template-feedbacks"].query.dto["by-userId-userProfileId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchActivityTemplateFeedbackStatus = async (patchParams) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["activity-template-feedbacks"].context}/`,
      patchParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const addCommentToActivityTemplateFeedback = async (addParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["activity-template-feedbacks"].context}${endpoints.catchtivities["activity-template-feedbacks"].add.comment.context}`,
      addParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * External Exam
 */
export const queryAllExternalExamDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exams"].context}${endpoints.catchtivities["external-exams"].paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createExternalExamWithDefaultExternalExamPart = async (
  newExternalExam
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exams"].context}${endpoints.catchtivities["external-exams"]["with-default-external-exam-part"].context}`,
      newExternalExam
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchExternalExam = async (currentExternalExam) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exams"].context}/`,
      currentExternalExam
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const evaluateExternalExam = async (evaluateParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exams"].context}${endpoints.catchtivities["external-exams"].evaluate.context}`,
      evaluateParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const evaluateSingleExternalExam = async (evaluateParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exams"].context}${endpoints.catchtivities["external-exams"].evaluate.single.context}`,
      evaluateParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteExternalExam = async (externalExamId) => {
  try {
    const response = await axios.delete(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exams"].context}${endpoints.catchtivities["external-exams"].id.context}`.replace(
        "{id}",
        externalExamId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveExternalExamDTOById = async (externalExamId) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exams"].context}${endpoints.catchtivities["external-exams"].dto.id.context}`.replace(
        "{id}",
        externalExamId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveExternalExamDTODetailById = async (externalExamId) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exams"].context}${endpoints.catchtivities["external-exams"].dto.id["in-detail"].context}`.replace(
        "{id}",
        externalExamId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveExternalExamDTOResultsById = async (externalExamId) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exams"].context}${endpoints.catchtivities["external-exams"].dto.id.results.context}`.replace(
        "{id}",
        externalExamId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveExternalExamDTOResultsByIdAndLearnerId = async (
  externalExamId,
  learnerId
) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exams"].context}${endpoints.catchtivities["external-exams"].dto.id.results.learnerId.context}`
        .replace("{id}", externalExamId)
        .replace("{learnerId}", learnerId)
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * External Exam Part
 */
export const createExternalExamPart = async (createParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exam-parts"].context}/`,
      createParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryExternalExamPartDTOListByExternalExamId = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exam-parts"].context}${endpoints.catchtivities["external-exam-parts"].query.dto["by-externalExamId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchExternalExamPart = async (currentExternalExamPart) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exam-parts"].context}/`,
      currentExternalExamPart
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const removeExternalExamPart = async (externalExamPartId) => {
  try {
    const response = await axios.delete(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exam-parts"].context}${endpoints.catchtivities["external-exam-parts"].id.context}`.replace(
        "{id}",
        externalExamPartId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * External Exam Activity
 */
export const queryExternalExamActivityDTOListByExternalExamId = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exam-activities"].context}${endpoints.catchtivities["external-exam-activities"].query.dto["by-externalExamPartId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createExternalExamActivity = async (newExternalExamPart) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exam-activities"].context}/`,
      newExternalExamPart
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteExternalExamActivity = async (externalExamActivityId) => {
  try {
    const response = await axios.delete(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-exam-activities"].context}${endpoints.catchtivities["external-exam-activities"].id.context}`.replace(
        "{id}",
        externalExamActivityId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * External Activity Evaluation
 */
export const queryExternalActivityEvaluationDTOListByExternalExamId = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["external-activity-evaluations"].context}${endpoints.catchtivities["external-activity-evaluations"].query.dto["by-externalExamId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
