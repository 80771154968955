import { Component, createContext, useContext } from "react";
import {
  removeAxiosInterceptor,
  setAxiosInterceptor,
} from "../utilization/AxiosUtilization";

export const UserContext = createContext();

class UserContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInformation: null,
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "TR",
      userProfileSet: [],
      userLabelSet: [],
      userBrandLabelSet: [],
      userPublishingHouseLabelSet: [],
      activityTemplateSet: [],
      categorySet: [],
      userProfile: null,
      userProfileBrand: null,
      userProfileCampus: null,
      userProfileInstitution: null,
      userProfileSeason: null,
      userProfileGrade: null,
      userProfileBranch: null,
      managementData: null,
      catchtivityData: null,
      dashboardLoading: true,
      numberOfUnreadAnnouncements: 0,
    };
    this.handleStateLogin = this.handleStateLogin.bind(this);
    this.handleStateLogout = this.handleStateLogout.bind(this);
    this.handleStateChooseProfile = this.handleStateChooseProfile.bind(this);
    this.handleStateChangeProfile = this.handleStateChangeProfile.bind(this);
    this.setUserInformation = this.setUserInformation.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.setUserProfileSet = this.setUserProfileSet.bind(this);
    this.setUserProfile = this.setUserProfile.bind(this);
    this.setUserProfileBrand = this.setUserProfileBrand.bind(this);
    this.setUserProfileCampus = this.setUserProfileCampus.bind(this);
    this.setUserProfileInstitution = this.setUserProfileInstitution.bind(this);
    this.setUserProfileSeason = this.setUserProfileSeason.bind(this);
    this.setUserProfileGrade = this.setUserProfileGrade.bind(this);
    this.setUserProfileBranch = this.setUserProfileBranch.bind(this);
    this.setUserLabelSet = this.setUserLabelSet.bind(this);
    this.setUserBrandLabelSet = this.setUserBrandLabelSet.bind(this);
    this.setUserPublishingHouseLabelSet =
      this.setUserPublishingHouseLabelSet.bind(this);
    this.setNumberOfUnreadAnnouncements =
      this.setNumberOfUnreadAnnouncements.bind(this);
    this.setCategorySet = this.setCategorySet.bind(this);
    this.setActivityTemplateSet = this.setActivityTemplateSet.bind(this);
    this.setManagementData = this.setManagementData.bind(this);
    this.setCatchtivityData = this.setCatchtivityData.bind(this);
    this.setDashboardLoading = this.setDashboardLoading.bind(this);
  }

  handleStateLogin(accessToken, refreshToken, userInformation, userProfileSet) {
    if (accessToken) {
      removeAxiosInterceptor();
      setAxiosInterceptor(accessToken);
      localStorage.setItem("accessToken", accessToken);
    }
    if (refreshToken) {
      localStorage.setItem("refreshToken", refreshToken);
    }
    if (userInformation) {
      localStorage.setItem("userId", userInformation.id);
      this.setUserInformation(userInformation);
    }
    if (userProfileSet) {
      this.setUserProfileSet(userProfileSet);
    }
  }

  handleStateLogout() {
    this.setUserInformation(null);
    this.setUserProfile(null);
    removeAxiosInterceptor();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("userProfileId");
    localStorage.removeItem("brandId");
    localStorage.removeItem("campusId");
    localStorage.removeItem("institutionId");
    localStorage.removeItem("seasonId");
    localStorage.removeItem("gradeId");
    localStorage.removeItem("branchId");
    localStorage.removeItem("createActivityFromPDFParamList");
    localStorage.removeItem("latestSelectedPlanVersionCode");
    localStorage.setItem("appState", "LOGOUT");
  }

  handleStateChooseProfile(
    userProfileDTO,
    brandDTO,
    campusDTO,
    institutionDTO,
    seasonDTO,
    gradeDTO,
    branchDTO
  ) {
    this.setUserProfile(userProfileDTO);
    this.setUserProfileBrand(brandDTO);
    this.setUserProfileCampus(campusDTO);
    this.setUserProfileInstitution(institutionDTO);
    this.setUserProfileSeason(seasonDTO);
    this.setUserProfileGrade(gradeDTO);
    this.setUserProfileBranch(branchDTO);
    if (userProfileDTO)
      localStorage.setItem("userProfileId", userProfileDTO.id);
    if (brandDTO) localStorage.setItem("brandId", brandDTO.id);
    if (campusDTO) localStorage.setItem("campusId", campusDTO.id);
    if (institutionDTO)
      localStorage.setItem("institutionId", institutionDTO.id);
    if (seasonDTO) localStorage.setItem("seasonId", seasonDTO.id);
    if (gradeDTO) localStorage.setItem("gradeId", gradeDTO.id);
    if (branchDTO) localStorage.setItem("branchId", branchDTO.id);
  }

  handleStateChangeProfile() {
    this.setUserProfile(null);
    this.setUserProfileBrand(null);
    this.setUserProfileCampus(null);
    this.setUserProfileInstitution(null);
    this.setUserProfileSeason(null);
    this.setUserProfileGrade(null);
    this.setUserProfileBranch(null);
    localStorage.removeItem("userProfileId");
    localStorage.removeItem("brandId");
    localStorage.removeItem("campusId");
    localStorage.removeItem("institutionId");
    localStorage.removeItem("seasonId");
    localStorage.removeItem("gradeId");
    localStorage.removeItem("branchId");
    localStorage.removeItem("dashboardPageState");
    localStorage.removeItem("dashboardPractiseCoterieType");
    localStorage.removeItem("dashboardReportsCoterieType");
    localStorage.removeItem("dashboardPlansCoterieType");
    localStorage.removeItem("dashboardPlansVersionCode");
  }

  setUserInformation(userInformation) {
    this.setState({ userInformation });
  }

  setLanguage(language) {
    this.setState({ language });
  }

  setUserProfileSet(userProfileSet) {
    this.setState({ userProfileSet });
  }

  setUserProfile(userProfile) {
    this.setState({ userProfile });
  }

  setUserProfileBrand(userProfileBrand) {
    this.setState({ userProfileBrand });
  }

  setUserProfileCampus(userProfileCampus) {
    this.setState({ userProfileCampus });
  }

  setUserProfileInstitution(userProfileInstitution) {
    this.setState({ userProfileInstitution });
  }

  setUserProfileSeason(userProfileSeason) {
    this.setState({ userProfileSeason });
  }

  setUserProfileGrade(userProfileGrade) {
    this.setState({ userProfileGrade });
  }

  setUserProfileBranch(userProfileBranch) {
    this.setState({ userProfileBranch });
  }

  setUserLabelSet(userLabelSet) {
    this.setState({ userLabelSet });
  }

  setUserBrandLabelSet(userBrandLabelSet) {
    this.setState({ userBrandLabelSet });
  }

  setUserPublishingHouseLabelSet(userPublishingHouseLabelSet) {
    this.setState({ userPublishingHouseLabelSet });
  }

  setCategorySet(categorySet) {
    this.setState({ categorySet });
  }

  setActivityTemplateSet(activityTemplateSet) {
    this.setState({ activityTemplateSet });
  }

  setNumberOfUnreadAnnouncements(numberOfUnreadAnnouncements) {
    this.setState({ numberOfUnreadAnnouncements });
  }

  setManagementData(managementData) {
    this.setState({ managementData });
  }

  setCatchtivityData(catchtivityData) {
    this.setState({ catchtivityData });
  }

  setDashboardLoading(dashboardLoading) {
    this.setState({ dashboardLoading });
  }

  render() {
    return (
      <UserContext.Provider
        value={{
          ...this.state,
          handleStateLogin: this.handleStateLogin,
          handleStateLogout: this.handleStateLogout,
          handleStateChooseProfile: this.handleStateChooseProfile,
          handleStateChangeProfile: this.handleStateChangeProfile,
          setUserInformation: this.setUserInformation,
          setLanguage: this.setLanguage,
          setUserProfileSet: this.setUserProfileSet,
          setUserProfile: this.setUserProfile,
          setUserProfileBrand: this.setUserProfileBrand,
          setUserProfileCampus: this.setUserProfileCampus,
          setUserProfileInstitution: this.setUserProfileInstitution,
          setUserProfileSeason: this.setUserProfileSeason,
          setUserProfileGrade: this.setUserProfileGrade,
          setUserProfileBranch: this.setUserProfileBranch,
          setUserLabelSet: this.setUserLabelSet,
          setUserBrandLabelSet: this.setUserBrandLabelSet,
          setUserPublishingHouseLabelSet: this.setUserPublishingHouseLabelSet,
          setNumberOfUnreadAnnouncements: this.setNumberOfUnreadAnnouncements,
          setCategorySet: this.setCategorySet,
          setActivityTemplateSet: this.setActivityTemplateSet,
          setManagementData: this.setManagementData,
          setCatchtivityData: this.setCatchtivityData,
          setDashboardLoading: this.setDashboardLoading,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export const useAuth = () => {
  const context = useContext(UserContext);
  if (!context) throw Error("Context must be not null.");
  return context;
};

export default UserContextProvider;
