import { useState } from "react";
import BaseImage from "../images/BaseImage";
import BaseLoading from "../loading/BaseLoading";

const CancelButton = (props) => {
  const { title, size, onClick, disabled, textOnly, iconOnly } = props;
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const internalOnClick = async () => {
    if (loading) return;
    if (disabled) return;
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  let currentWidthClassName;
  let currentHeightClassName;
  let currentLoadingSize;
  if (size === "small") {
    currentWidthClassName = "w-32";
    currentHeightClassName = "h-8";
    currentLoadingSize = 14;
  } else if (size === "medium") {
    currentWidthClassName = "w-32";
    currentHeightClassName = "h-10";
    currentLoadingSize = 16;
  } else if (size === "large") {
    currentWidthClassName = "w-32";
    currentHeightClassName = "h-12";
    currentLoadingSize = 18;
  } else if (size === "unlimited") {
    currentLoadingSize = 14;
    currentHeightClassName = "h-8";
  }

  return (
    <button
      className={`border border-catchup-gray-400 bg-catchup-white text-catchup-gray-600 rounded-catchup-button ${
        loading
          ? ""
          : disabled
          ? "opacity-50"
          : "hover:bg-catchup-gray-400 hover:text-catchup-white hover:border-catchup-gray-400 active:bg-catchup-gray-500 active:border-catchup-gray-500 active:text-catchup-white"
      } transition duration-300 ${currentWidthClassName} ${currentHeightClassName}`}
      onClick={internalOnClick}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      {loading ? (
        <BaseLoading
          height={currentLoadingSize}
          width={currentLoadingSize}
          color="#55777f"
          secondaryColor="#55777f"
          hideText={true}
        />
      ) : (
        <div className="flex flex-row justify-center items-center gap-x-2">
          {textOnly ? null : (
            <BaseImage
              src={`${
                isHovered
                  ? "/icons/cancel-button-arrow-left-white.png"
                  : "/icons/cancel-button-arrow-left.png"
              }`}
              alt="header-arrow-left-selected"
              size="xsmall"
            />
          )}

          {iconOnly ? null : <p className="">{title}</p>}
        </div>
      )}
    </button>
  );
};

export default CancelButton;
