import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import FullCard from "../../components/cards/FullCard";
import InputGroup from "../../components/groups/InputGroup";
import { useApp } from "../../context/AppContextProvider";
import i18n from "../../language/i18n";
import {
  createCategory,
  patchCategory,
  queryCategoryDTOByVersionCode,
} from "../../requests/CategoryRequests";
import { retrieveCategoryVersionCodeOptionList } from "../../utilization/CategoryUtilization";
import {
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import { retrieveGradeLevelOptionList } from "../../utilization/ManagementUtilization";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import CreateButton from "../../components/buttons/CreateButton";
import BaseLoading from "../../components/loading/BaseLoading";

const CategoryListScreen = (props) => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [pageState, setPageState] = useState(0);
  const [selectedVersionCode, setSelectedVersionCode] =
    useState("DEFAULT_OPTION");
  const [categoryDTOTree, setCategoryDTOTree] = useState(null);
  const [codeStartsWith, setCodeStartsWith] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [code, setCode] = useState(null);
  const [parentCode, setParentCode] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState("DEFAULT_OPTION");
  const [errorMap, setErrorMap] = useState({
    name: null,
    code: null,
    parentCode: null,
    versionCode: null,
    coterieType: null,
    level: null,
  });
  let count = 0;

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (checkViewPermissionGranted("category_list")) {
    } else {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetCategoryList = async () => {
      setLoading(true);
      const { data, err } = await queryCategoryDTOByVersionCode({
        versionCode: selectedVersionCode,
      });
      if (err) {
        console.log(err);
        setCategoryDTOTree(null);
      } else {
        setCategoryDTOTree(data);
      }
      setLoading(false);
    };

    if (selectedVersionCode !== "DEFAULT_OPTION" && pageState === 0) {
      retrieveAndSetCategoryList();
    } else {
      setCategoryDTOTree(null);
    }
  }, [selectedVersionCode, pageState]);

  const filterCategoryTreeWithStartsWith = (categoryList) => {
    if (categoryList) {
      return categoryList.filter((subCategory) => {
        if (codeStartsWith.length < subCategory.code.length) {
          return subCategory.code.startsWith(codeStartsWith);
        } else {
          if (codeStartsWith.includes(subCategory.code)) {
            return true;
          } else {
            return false;
          }
        }
      });
    }
    return [];
  };

  const checkCommonValidation = () => {
    if (
      !name ||
      name.trim() === "" ||
      !code ||
      code.trim() === "" ||
      selectedVersionCode === "DEFAULT_OPTION" ||
      selectedLevel === "DEFAULT_OPTION"
    ) {
      const currentErrorMap = {
        name: null,
        description: null,
        code: null,
        parentCode: null,
        versionCode: null,
        level: null,
      };
      if (!name || name.trim() === "") {
        currentErrorMap.name = i18n.t("name_required_field");
      }
      if (!code || code.trim() === "") {
        currentErrorMap.code = i18n.t("code_required_field");
      }
      if (!parentCode || parentCode.trim() === "") {
        currentErrorMap.parentCode = i18n.t("parent_code_required_field");
      }
      if (selectedVersionCode === "DEFAULT_OPTION") {
        currentErrorMap.versionCode = i18n.t("version_code_required_field");
      }
      if (selectedLevel === "DEFAULT_OPTION") {
        currentErrorMap.level = i18n.t("level_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const resetParams = () => {
    setId(null);
    setName(null);
    setDescription(null);
    setCode(null);
    setParentCode(null);
    setSelectedLevel("DEFAULT_OPTION");
  };

  const handleOnChange = (type, value) => {
    if (type === "versionCode") {
      setSelectedVersionCode(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        versionCode: null,
      }));
    } else if (type === "codeStartsWith") {
      setCodeStartsWith(value);
    } else if (type === "name") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: i18n.t("name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: null,
        }));
      }
      setName(value);
    } else if (type === "description") {
      setDescription(value);
    } else if (type === "code") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          code: i18n.t("code_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          code: null,
        }));
      }
      setCode(value);
    } else if (type === "parentCode") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          parentCode: i18n.t("code_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          parentCode: null,
        }));
      }
      setParentCode(value);
    } else if (type === "level") {
      setSelectedLevel(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        level: null,
      }));
    }
  };

  const handleUpdateCategoryOnClick = async () => {
    if (checkUpdatePermissionGranted("category_list")) {
      if (checkCommonValidation()) {
        const currentCategory = {
          id,
          name,
          description,
          code,
          level: selectedLevel === "DEFAULT_OPTION" ? null : selectedLevel,
          parentCode,
          versionCode: selectedVersionCode,
        };
        const { data, err } = await patchCategory(currentCategory);
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          handleReturnFromCategoryOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleCreateCategoryOnClick = async () => {
    if (checkInsertPermissionGranted("category_list")) {
      if (checkCommonValidation()) {
        const newCategory = {
          name,
          description,
          code,
          level: selectedLevel === "DEFAULT_OPTION" ? null : selectedLevel,
          parentCode,
          versionCode: selectedVersionCode,
        };
        const { data, err } = await createCategory(newCategory);
        if (err) {
          console.log(err);
        } else {
          handleReturnFromCategoryOnClick();
          setCodeStartsWith(code);
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleToUpdateCategoryOnClick = (categoryDTO) => {
    setId(categoryDTO.id);
    setName(categoryDTO.name);
    setDescription(categoryDTO.description);
    setCode(categoryDTO.code);
    setParentCode(categoryDTO.parentCode);
    setSelectedLevel(categoryDTO.level);
    setPageState(1);
  };

  const handleToCreateCategoryOnClick = () => {
    setId(null);
    setPageState(1);
  };

  const handleReturnFromCategoryOnClick = () => {
    resetParams();
    setPageState(0);
  };

  const RenderSingleCategoryItem = (categoryDTO) => {
    if (!categoryDTO.code.endsWith(".")) {
      count++;
    }
    return (
      <>
        <div className="flex flex-row justify-center items-center my-4">
          {checkUpdatePermissionGranted("category_list") ? (
            <div className="mr-2">
              <BaseImage
                src="/icons/edit.png"
                alt="edit"
                size="small"
                onClick={() => {
                  handleToUpdateCategoryOnClick(categoryDTO);
                }}
              />
            </div>
          ) : null}

          <div className="w-1/6">
            <p className="mx-1">{categoryDTO.code}</p>
          </div>
          <div className="w-1/3">
            <p className="mx-1">{categoryDTO.name}</p>
          </div>
          <div className="flex-1">
            <p className="mx-1">{categoryDTO.description}</p>
          </div>
        </div>
        <div className="ml-5">
          {filterCategoryTreeWithStartsWith(categoryDTO.subCategoryDTOList)
            .sort((a, b) => a.code.localeCompare(b.code))
            .map((subCategoryDTO) => (
              <div key={subCategoryDTO.id}>
                {RenderSingleCategoryItem(subCategoryDTO)}
              </div>
            ))}
        </div>
      </>
    );
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <FullCard isShadowed={true}>
            <div className="">
              <div className="flex flex-row items-center justify-between">
                <div></div>
                {checkInsertPermissionGranted("category_list") ? (
                  <div>
                    <CreateButton
                      title={i18n.t("create")}
                      size="medium"
                      onClick={handleToCreateCategoryOnClick}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex flex-row items-center mb-5">
              <p className="font-semibold text-lg">
                {i18n.t("category_and_outcome_selection")}
              </p>
            </div>
            <div className="flex flex-col items-center justify-center text-catchup-dark-blue">
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("category_code")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={selectedVersionCode}
                    optionList={categoryVersionCodeOptionList}
                    onChange={(event) => {
                      handleOnChange("versionCode", event.target.value);
                    }}
                    errorText={errorMap.versionCode}
                  />
                </div>
              </div>
              {selectedVersionCode !== "DEFAULT_OPTION" ? (
                <>
                  <div className="w-full flex flex-row">
                    <div className="w-catchup-input-group-title py-5">
                      <p>{i18n.t("code_starts_with")}</p>
                    </div>
                    <div className="flex-1">
                      <InputGroup
                        type="text"
                        value={codeStartsWith}
                        onChange={(event) => {
                          handleOnChange("codeStartsWith", event.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {loading ? (
                    <BaseLoading
                      size="large"
                      color="#57C2D3"
                      secondaryColor="#57C2D3"
                    />
                  ) : categoryDTOTree ? (
                    <div className="p-2 w-full">
                      {filterCategoryTreeWithStartsWith(
                        categoryDTOTree.subCategoryDTOList
                      )
                        .sort((a, b) => a.code.localeCompare(b.code))
                        .map((subCategoryDTO) => (
                          <div key={subCategoryDTO.id}>
                            {RenderSingleCategoryItem(subCategoryDTO)}
                          </div>
                        ))}
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          </FullCard>
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <FullCard isShadowed={true}>
            <div className="">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      handleReturnFromCategoryOnClick();
                    }}
                  />
                </div>
                <BaseTitle
                  title={
                    id ? i18n.t("update_category") : i18n.t("create_category")
                  }
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <DividerLine />

              <div className="w-full">
                <p className="font-semibold text-lg">
                  {i18n.t("category_information")}
                </p>
              </div>
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("category_code")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={selectedVersionCode}
                    optionList={categoryVersionCodeOptionList}
                    onChange={(event) => {
                      handleOnChange("versionCode", event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={name}
                    onChange={(e) => {
                      handleOnChange("name", e.target.value);
                    }}
                    errorText={errorMap.name}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("description")}</p>
                </div>
                <div className="flex-1 h-catchup-description">
                  <InputGroup
                    type="textarea"
                    value={description}
                    onChange={(e) => {
                      handleOnChange("description", e.target.value);
                    }}
                    errorText={errorMap.description}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("level")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={selectedLevel}
                    optionList={retrieveGradeLevelOptionList()}
                    onChange={(event) => {
                      handleOnChange("level", event.target.value);
                    }}
                    errorText={errorMap.level}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("parent_code")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={parentCode}
                    onChange={(e) => {
                      handleOnChange("parentCode", e.target.value);
                    }}
                    errorText={errorMap["parentCode"]}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("code")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={code}
                    onChange={(e) => {
                      handleOnChange("code", e.target.value);
                    }}
                    errorText={errorMap["code"]}
                  />
                </div>
              </div>

              <div className="mt-4 ml-auto">
                {id ? (
                  <SecondaryButton
                    title={i18n.t("update")}
                    size="medium"
                    onClick={handleUpdateCategoryOnClick}
                  />
                ) : (
                  <CreateButton
                    title={i18n.t("create")}
                    size="medium"
                    onClick={handleCreateCategoryOnClick}
                  />
                )}
              </div>
            </div>
          </FullCard>
        </div>
      </div>
    );
  };

  const categoryVersionCodeOptionList = retrieveCategoryVersionCodeOptionList();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default CategoryListScreen;
