import { useState } from "react";
import BaseLoading from "../loading/BaseLoading";

const BaseImage = (props) => {
  const {
    ref,
    size,
    className,
    widthClassName,
    heightClassName,
    src,
    alt,
    showLoading,
    onLoad,
    onClick,
    dataToolTipId,
    dataToolTipContent,
    dataToolTipPlace,
    dataToolTipVariant,
    dataToolTipHTML,
  } = props;
  const [loading, setLoading] = useState(false);

  const handleOnClick = (e) => {
    e.preventDefault();
    if (showLoading) {
      setLoading(true);
      onClick(e);
      setLoading(false);
    } else {
      onClick(e);
    }
  };

  if (!src) return null;

  let currentClassName;
  let currentWidthClassName;
  let currentHeightClassName;
  if (size === "xsmall") {
    currentWidthClassName = "w-4";
    currentHeightClassName = "h-4";
  } else if (size === "small") {
    currentWidthClassName = "w-6";
    currentHeightClassName = "h-6";
  } else if (size === "medium") {
    currentWidthClassName = "w-8";
    currentHeightClassName = "h-8";
  } else if (size === "large") {
    currentWidthClassName = "w-10";
    currentHeightClassName = "h-10";
  } else if (size === "xlarge") {
    currentWidthClassName = "w-12";
    currentHeightClassName = "h-12";
  } else if (size === "2xlarge") {
    currentWidthClassName = "w-14";
    currentHeightClassName = "h-14";
  } else if (size === "3xlarge") {
    currentWidthClassName = "w-16";
    currentHeightClassName = "h-16";
  } else if (size === "custom") {
    currentClassName = className;
    currentWidthClassName = widthClassName;
    currentHeightClassName = heightClassName;
  }

  return loading ? (
    <BaseLoading size="small" color="#57C2D3" secondaryColor="#57C2D3" />
  ) : (
    <div
      className={`${currentWidthClassName ? currentWidthClassName : ""} ${
        currentHeightClassName ? currentHeightClassName : ""
      } ${currentClassName ? currentClassName : ""} cursor-pointer`}
      onClick={handleOnClick}
    >
      <img
        ref={ref}
        className="w-full"
        src={src}
        alt={alt}
        onLoad={onLoad}
        data-tooltip-id={dataToolTipId}
        data-tooltip-content={dataToolTipContent}
        data-tooltip-place={dataToolTipPlace}
        data-tooltip-variant={dataToolTipVariant}
        data-tooltip-html={dataToolTipHTML}
      />
    </div>
  );
};

export default BaseImage;
