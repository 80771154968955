import PrimaryButton from "../../components/buttons/PrimaryButton";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import DividerLine from "../../components/divider/DividerLine";
import { Tooltip } from "react-tooltip";
import BaseImage from "../images/BaseImage";
import BaseTitle from "../titles/BaseTitle";
import BaseLoading from "../loading/BaseLoading";

const GeneralInformationReportDetail = (props) => {
  const {
    isShadowed,
    usePadding,
    showTitle,
    reportDetail,
    reportDetailLoading,
    subType,
    handleGeneratePDF,
  } = props;
  const { language } = useAuth();

  const RenderUserReportContent = () => {
    return (
      <div className="w-full mt-4">
        <p className="font-semibold text-lg">{i18n.t("found_user_profiles")}</p>
        <div>
          {reportDetail.userDTOList.map((userDTO, userDTOIndex) => (
            <div key={userDTOIndex} className="flex flex-row items-center my-3">
              <div className="flex-1">
                <p className="px-2">{userDTO.firstName}</p>
              </div>
              <div className="flex-1">
                <p className="px-2">{userDTO.lastName}</p>
              </div>
              <div className="flex-1">
                <p className="px-2">{userDTO.email}</p>
              </div>
              <div className="flex-1">
                <p className="px-2">
                  {new Date(userDTO.dateOfBirth).toISOString().split("T")[0]}
                </p>
              </div>
              <div className="flex-1">
                <p className="px-2">{userDTO.phoneNumber}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const RenderUserProfileReportContent = () => {
    return (
      <div className="w-full mt-4">
        <p className="font-semibold text-lg">{i18n.t("found_users")}</p>
        <div>
          {reportDetail.userProfileDTOList.map(
            (userProfileDTO, userProfileDTOIndex) => (
              <div
                key={userProfileDTOIndex}
                className="flex flex-row items-center my-3"
              >
                <div className="flex-1">
                  <div className="flex flex-row">
                    <Tooltip id="item-descriptions-in-specific-report" />
                    <div className="mr-2">
                      <BaseImage
                        size="small"
                        src="/icons/info.png"
                        alt="info"
                        dataToolTipId="item-descriptions-in-specific-report"
                        dataToolTipContent={userProfileDTO.description}
                        dataToolTipPlace="top"
                        dataToolTipVariant="info"
                      />
                    </div>
                    <p className="mx-1">{userProfileDTO.name}</p>
                  </div>
                </div>
                <div className="flex-1">
                  <p className="px-2">{i18n.t(userProfileDTO.role)}</p>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const RenderBranchReportUserProfilesContent = (basicUserProfileDTOList) => {
    return basicUserProfileDTOList.map(
      (userProfileDTO, userProfileDTOIndex) => (
        <div
          key={userProfileDTOIndex}
          className="flex flex-row items-center px-3"
        >
          <div className="flex-1">
            <div className="flex flex-row">
              <Tooltip id="item-descriptions-in-specific-report" />
              <div className="mr-2">
                <BaseImage
                  size="small"
                  src="/icons/info.png"
                  alt="info"
                  dataToolTipId="item-descriptions-in-specific-report"
                  dataToolTipContent={userProfileDTO.description}
                  dataToolTipPlace="top"
                  dataToolTipVariant="info"
                />
              </div>
              <p className="mx-1">{userProfileDTO.name}</p>
            </div>
          </div>
          <div className="flex-1">
            <p className="px-2">{userProfileDTO.userDTO.firstName}</p>
          </div>
          <div className="flex-1">
            <p className="px-2">{userProfileDTO.userDTO.lastName}</p>
          </div>
          <div className="flex-1">
            <p className="px-2">{i18n.t(userProfileDTO.role)}</p>
          </div>
        </div>
      )
    );
  };

  const RenderGradeReportUserProfilesContent = (
    basicUserProfileDTOList,
    branchDTOForReportList
  ) => {
    return (
      <>
        {basicUserProfileDTOList.map((userProfileDTO, userProfileDTOIndex) => (
          <div
            key={userProfileDTOIndex}
            className="flex flex-row items-center px-3"
          >
            <div className="flex-1">
              <div className="flex flex-row">
                <Tooltip id="item-descriptions-in-specific-report" />
                <div className="mr-2">
                  <BaseImage
                    size="small"
                    src="/icons/info.png"
                    alt="info"
                    dataToolTipId="item-descriptions-in-specific-report"
                    dataToolTipContent={userProfileDTO.description}
                    dataToolTipPlace="top"
                    dataToolTipVariant="info"
                  />
                </div>
                <p className="mx-1">{userProfileDTO.name}</p>
              </div>
            </div>
            <div className="flex-1">
              <p className="px-2">{userProfileDTO.userDTO.firstName}</p>
            </div>
            <div className="flex-1">
              <p className="px-2">{userProfileDTO.userDTO.lastName}</p>
            </div>
            <div className="flex-1">
              <p className="px-2">{i18n.t(userProfileDTO.role)}</p>
            </div>
          </div>
        ))}
        <div className="flex-1">
          {branchDTOForReportList.map((branchDTOForReport) =>
            RenderBranchReportUserProfilesContent(
              branchDTOForReport.basicUserProfileDTOList
            )
          )}
        </div>
      </>
    );
  };

  const RenderSeasonReportUserProfilesContent = (
    basicUserProfileDTOList,
    gradeDTOForReportList
  ) => {
    return (
      <>
        {basicUserProfileDTOList.map((userProfileDTO, userProfileDTOIndex) => (
          <div
            key={userProfileDTOIndex}
            className="flex flex-row items-center px-3"
          >
            <div className="flex-1">
              <div className="flex flex-row">
                <Tooltip id="item-descriptions-in-specific-report" />
                <div className="mr-2">
                  <BaseImage
                    size="small"
                    src="/icons/info.png"
                    alt="info"
                    dataToolTipId="item-descriptions-in-specific-report"
                    dataToolTipContent={userProfileDTO.description}
                    dataToolTipPlace="top"
                    dataToolTipVariant="info"
                  />
                </div>
                <p className="mx-1">{userProfileDTO.name}</p>
              </div>
            </div>
            <div className="flex-1">
              <p className="px-2">{userProfileDTO.userDTO.firstName}</p>
            </div>
            <div className="flex-1">
              <p className="px-2">{userProfileDTO.userDTO.lastName}</p>
            </div>
            <div className="flex-1">
              <p className="px-2">{i18n.t(userProfileDTO.role)}</p>
            </div>
          </div>
        ))}
        <div className="flex-1">
          {gradeDTOForReportList.map((gradeDTOForReport) =>
            RenderGradeReportUserProfilesContent(
              gradeDTOForReport.basicUserProfileDTOList,
              gradeDTOForReport.branchDTOForReportList
            )
          )}
        </div>
      </>
    );
  };

  const RenderInstitutionReportUserProfilesContent = (
    basicUserProfileDTOList,
    seasonDTOForReportList
  ) => {
    return (
      <>
        {basicUserProfileDTOList.map((userProfileDTO, userProfileDTOIndex) => (
          <div
            key={userProfileDTOIndex}
            className="flex flex-row items-center px-3"
          >
            <div className="flex-1">
              <div className="flex flex-row">
                <Tooltip id="item-descriptions-in-specific-report" />
                <div className="mr-2">
                  <BaseImage
                    size="small"
                    src="/icons/info.png"
                    alt="info"
                    dataToolTipId="item-descriptions-in-specific-report"
                    dataToolTipContent={userProfileDTO.description}
                    dataToolTipPlace="top"
                    dataToolTipVariant="info"
                  />
                </div>
                <p className="mx-1">{userProfileDTO.name}</p>
              </div>
            </div>
            <div className="flex-1">
              <p className="px-2">{userProfileDTO.userDTO.firstName}</p>
            </div>
            <div className="flex-1">
              <p className="px-2">{userProfileDTO.userDTO.lastName}</p>
            </div>
            <div className="flex-1">
              <p className="px-2">{i18n.t(userProfileDTO.role)}</p>
            </div>
          </div>
        ))}
        <div className="flex-1">
          {seasonDTOForReportList.map((seasonDTOForReport) =>
            RenderSeasonReportUserProfilesContent(
              seasonDTOForReport.basicUserProfileDTOList,
              seasonDTOForReport.gradeDTOForReportList
            )
          )}
        </div>
      </>
    );
  };

  const RenderCampusReportUserProfilesContent = (
    basicUserProfileDTOList,
    institutionDTOForReportList
  ) => {
    return (
      <>
        {basicUserProfileDTOList.map((userProfileDTO, userProfileDTOIndex) => (
          <div
            key={userProfileDTOIndex}
            className="flex flex-row items-center px-3"
          >
            <div className="flex-1">
              <div className="flex flex-row">
                <Tooltip id="item-descriptions-in-specific-report" />
                <div className="mr-2">
                  <BaseImage
                    size="small"
                    src="/icons/info.png"
                    alt="info"
                    dataToolTipId="item-descriptions-in-specific-report"
                    dataToolTipContent={userProfileDTO.description}
                    dataToolTipPlace="top"
                    dataToolTipVariant="info"
                  />
                </div>
                <p className="mx-1">{userProfileDTO.name}</p>
              </div>
            </div>
            <div className="flex-1">
              <p className="px-2">{userProfileDTO.userDTO.firstName}</p>
            </div>
            <div className="flex-1">
              <p className="px-2">{userProfileDTO.userDTO.lastName}</p>
            </div>
            <div className="flex-1">
              <p className="px-2">{i18n.t(userProfileDTO.role)}</p>
            </div>
          </div>
        ))}
        <div className="flex-1">
          {institutionDTOForReportList.map((institutionDTOForReport) =>
            RenderInstitutionReportUserProfilesContent(
              institutionDTOForReport.basicUserProfileDTOList,
              institutionDTOForReport.seasonDTOForReportList
            )
          )}
        </div>
      </>
    );
  };

  const RenderBrandReportUserProfilesContent = (
    basicUserProfileDTOList,
    campusDTOForReportList
  ) => {
    return (
      <>
        {basicUserProfileDTOList.map((userProfileDTO, userProfileDTOIndex) => (
          <div
            key={userProfileDTOIndex}
            className="flex flex-row items-center px-3"
          >
            <div className="flex-1">
              <div className="flex flex-row">
                <Tooltip id="item-descriptions-in-specific-report" />
                <div className="mr-2">
                  <BaseImage
                    size="small"
                    src="/icons/info.png"
                    alt="info"
                    dataToolTipId="item-descriptions-in-specific-report"
                    dataToolTipContent={userProfileDTO.description}
                    dataToolTipPlace="top"
                    dataToolTipVariant="info"
                  />
                </div>
                <p className="mx-1">{userProfileDTO.name}</p>
              </div>
            </div>
            <div className="flex-1">
              <p className="px-2">{userProfileDTO.userDTO.firstName}</p>
            </div>
            <div className="flex-1">
              <p className="px-2">{userProfileDTO.userDTO.lastName}</p>
            </div>
            <div className="flex-1">
              <p className="px-2">{i18n.t(userProfileDTO.role)}</p>
            </div>
          </div>
        ))}
        <div className="flex-1">
          {campusDTOForReportList.map((campusDTOForReport) =>
            RenderCampusReportUserProfilesContent(
              campusDTOForReport.basicUserProfileDTOList,
              campusDTOForReport.institutionDTOForReportList
            )
          )}
        </div>
      </>
    );
  };

  const RenderBranchReportContent = () => {
    return (
      <div className="w-full mt-4">
        <p className="font-semibold text-lg">{i18n.t("found_branches")}</p>
        <div>
          {reportDetail.branchDTOForReportList.map(
            (branchDTOForReport, branchDTOForReportIndex) => (
              <div key={branchDTOForReportIndex}>
                <div className="flex flex-row items-center my-3">
                  <div className="flex-1 flex flex-row flex-wrap">
                    <div className="px-1">
                      <p className="">{branchDTOForReport.name}</p>
                    </div>
                    <div className="flex-1">
                      <p className="italic">
                        ({branchDTOForReport.description})
                      </p>
                    </div>
                  </div>
                </div>
                <DividerLine />
                {RenderBranchReportUserProfilesContent(
                  branchDTOForReport.basicUserProfileDTOList
                )}
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const RenderGradeReportContent = () => {
    return (
      <div className="w-full mt-4">
        <p className="font-semibold text-lg">{i18n.t("found_grades")}</p>
        <div>
          {reportDetail.gradeDTOForReportList.map(
            (gradeDTOForReport, gradeDTOForReportIndex) => (
              <div key={gradeDTOForReportIndex}>
                <div className="flex flex-row items-center my-3">
                  <div className="flex-1 flex flex-row flex-wrap">
                    <div className="px-1">
                      <p className="">{gradeDTOForReport.name}</p>
                    </div>
                    <div className="flex-1">
                      <p className="italic">
                        ({gradeDTOForReport.description})
                      </p>
                    </div>
                  </div>
                </div>
                <DividerLine />
                {RenderGradeReportUserProfilesContent(
                  gradeDTOForReport.basicUserProfileDTOList,
                  gradeDTOForReport.branchDTOForReportList
                )}
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const RenderSeasonReportContent = () => {
    return (
      <div className="w-full mt-4">
        <p className="font-semibold text-lg">{i18n.t("found_seasons")}</p>
        <div>
          {reportDetail.seasonDTOForReportList.map(
            (seasonDTOForReport, seasonDTOForReportIndex) => (
              <div key={seasonDTOForReportIndex}>
                <div className="flex flex-row items-center my-3">
                  <div className="flex-1 flex flex-row flex-wrap">
                    <div className="px-1">
                      <p className="">{seasonDTOForReport.name}</p>
                    </div>
                    <div className="flex-1">
                      <p className="italic">
                        ({seasonDTOForReport.description})
                      </p>
                    </div>
                  </div>
                </div>
                <DividerLine />
                {seasonDTOForReport.basicUserProfileDTOList.map(
                  (userProfileDTO, userProfileDTOIndex) => (
                    <div
                      key={userProfileDTOIndex}
                      className="flex flex-row items-center px-3"
                    >
                      <div className="flex-1">
                        <div className="flex flex-row">
                          <Tooltip id="item-descriptions-in-specific-report" />
                          <div className="mr-2">
                            <BaseImage
                              size="small"
                              src="/icons/info.png"
                              alt="info"
                              dataToolTipId="item-descriptions-in-specific-report"
                              dataToolTipContent={userProfileDTO.description}
                              dataToolTipPlace="top"
                              dataToolTipVariant="info"
                            />
                          </div>
                          <p className="mx-1">{userProfileDTO.name}</p>
                        </div>
                      </div>
                      <div className="flex-1">
                        <p className="px-2">
                          {userProfileDTO.userDTO.firstName}
                        </p>
                      </div>
                      <div className="flex-1">
                        <p className="px-2">
                          {userProfileDTO.userDTO.lastName}
                        </p>
                      </div>
                      <div className="flex-1">
                        <p className="px-2">{i18n.t(userProfileDTO.role)}</p>
                      </div>
                    </div>
                  )
                )}
                {RenderSeasonReportUserProfilesContent(
                  seasonDTOForReport.basicUserProfileDTOList,
                  seasonDTOForReport.gradeDTOForReportList
                )}
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const RenderInstitutionReportContent = () => {
    return (
      <div className="w-full mt-4">
        <p className="font-semibold text-lg">{i18n.t("found_institutions")}</p>
        <div>
          {reportDetail.institutionDTOForReportList.map(
            (institutionDTOForReport, institutionDTOForReportIndex) => (
              <div key={institutionDTOForReportIndex}>
                <div className="flex flex-row items-center my-3">
                  <div className="flex-1 flex flex-row flex-wrap">
                    <div className="px-1">
                      <p className="">{institutionDTOForReport.name}</p>
                    </div>
                    <div className="flex-1">
                      <p className="italic">
                        ({institutionDTOForReport.description})
                      </p>
                    </div>
                  </div>
                </div>
                <DividerLine />
                {institutionDTOForReport.basicUserProfileDTOList.map(
                  (userProfileDTO, userProfileDTOIndex) => (
                    <div
                      key={userProfileDTOIndex}
                      className="flex flex-row items-center px-3"
                    >
                      <div className="flex-1">
                        <div className="flex flex-row">
                          <Tooltip id="item-descriptions-in-specific-report" />
                          <div className="mr-2">
                            <BaseImage
                              size="small"
                              src="/icons/info.png"
                              alt="info"
                              dataToolTipId="item-descriptions-in-specific-report"
                              dataToolTipContent={userProfileDTO.description}
                              dataToolTipPlace="top"
                              dataToolTipVariant="info"
                            />
                          </div>
                          <p className="mx-1">{userProfileDTO.name}</p>
                        </div>
                      </div>
                      <div className="flex-1">
                        <p className="px-2">
                          {userProfileDTO.userDTO.firstName}
                        </p>
                      </div>
                      <div className="flex-1">
                        <p className="px-2">
                          {userProfileDTO.userDTO.lastName}
                        </p>
                      </div>
                      <div className="flex-1">
                        <p className="px-2">{i18n.t(userProfileDTO.role)}</p>
                      </div>
                    </div>
                  )
                )}
                {RenderInstitutionReportUserProfilesContent(
                  institutionDTOForReport.basicUserProfileDTOList,
                  institutionDTOForReport.seasonDTOForReportList
                )}
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const RenderCampusReportContent = () => {
    return (
      <div className="w-full mt-4">
        <p className="font-semibold text-lg">{i18n.t("found_campuses")}</p>
        <div>
          {reportDetail.campusDTOForReportList.map(
            (campusDTOForReport, campusDTOForReportIndex) => (
              <div key={campusDTOForReportIndex}>
                <div className="flex flex-row items-center my-3">
                  <div className="flex-1 flex flex-row flex-wrap">
                    <div className="px-1">
                      <p className="">{campusDTOForReport.name}</p>
                    </div>
                    <div className="flex-1">
                      <p className="italic">
                        ({campusDTOForReport.description})
                      </p>
                    </div>
                  </div>
                </div>
                <DividerLine />
                {campusDTOForReport.basicUserProfileDTOList.map(
                  (userProfileDTO, userProfileDTOIndex) => (
                    <div
                      key={userProfileDTOIndex}
                      className="flex flex-row items-center px-3"
                    >
                      <div className="flex-1">
                        <div className="flex flex-row">
                          <Tooltip id="item-descriptions-in-specific-report" />
                          <div className="mr-2">
                            <BaseImage
                              size="small"
                              src="/icons/info.png"
                              alt="info"
                              dataToolTipId="item-descriptions-in-specific-report"
                              dataToolTipContent={userProfileDTO.description}
                              dataToolTipPlace="top"
                              dataToolTipVariant="info"
                            />
                          </div>
                          <p className="mx-1">{userProfileDTO.name}</p>
                        </div>
                      </div>
                      <div className="flex-1">
                        <p className="px-2">
                          {userProfileDTO.userDTO.firstName}
                        </p>
                      </div>
                      <div className="flex-1">
                        <p className="px-2">
                          {userProfileDTO.userDTO.lastName}
                        </p>
                      </div>
                      <div className="flex-1">
                        <p className="px-2">{i18n.t(userProfileDTO.role)}</p>
                      </div>
                    </div>
                  )
                )}
                {RenderCampusReportUserProfilesContent(
                  campusDTOForReport.basicUserProfileDTOList,
                  campusDTOForReport.institutionDTOForReportList
                )}
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const RenderBrandReportContent = () => {
    return (
      <div className="w-full mt-4">
        <p className="font-semibold text-lg">{i18n.t("found_brands")}</p>
        <div>
          {reportDetail.brandDTOForReportList.map(
            (brandDTOForReport, brandDTOForReportIndex) => (
              <div key={brandDTOForReportIndex}>
                <div className="flex flex-row items-center my-3">
                  <div className="flex-1 flex flex-row flex-wrap">
                    <div className="px-1">
                      <p className="">{brandDTOForReport.name}</p>
                    </div>
                    <div className="flex-1">
                      <p className="italic">
                        ({brandDTOForReport.description})
                      </p>
                    </div>
                  </div>
                </div>
                <DividerLine />
                {brandDTOForReport.basicUserProfileDTOList.map(
                  (userProfileDTO, userProfileDTOIndex) => (
                    <div
                      key={userProfileDTOIndex}
                      className="flex flex-row items-center px-3"
                    >
                      <div className="flex-1">
                        <div className="flex flex-row">
                          <Tooltip id="item-descriptions-in-specific-report" />
                          <div className="mr-2">
                            <BaseImage
                              size="small"
                              src="/icons/info.png"
                              alt="info"
                              dataToolTipId="item-descriptions-in-specific-report"
                              dataToolTipContent={userProfileDTO.description}
                              dataToolTipPlace="top"
                              dataToolTipVariant="info"
                            />
                          </div>
                          <p className="mx-1">{userProfileDTO.name}</p>
                        </div>
                      </div>
                      <div className="flex-1">
                        <p className="px-2">
                          {userProfileDTO.userDTO.firstName}
                        </p>
                      </div>
                      <div className="flex-1">
                        <p className="px-2">
                          {userProfileDTO.userDTO.lastName}
                        </p>
                      </div>
                      <div className="flex-1">
                        <p className="px-2">{i18n.t(userProfileDTO.role)}</p>
                      </div>
                    </div>
                  )
                )}
                {RenderBrandReportUserProfilesContent(
                  brandDTOForReport.basicUserProfileDTOList,
                  brandDTOForReport.campusDTOForReportList
                )}
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const RenderRegionReportContent = () => {
    return (
      <div className="w-full mt-4">
        <p className="font-semibold text-lg">{i18n.t("found_regions")}</p>
        <div>
          {reportDetail.regionDTOForReportList.map(
            (regionDTOForReport, regionDTOForReportIndex) => (
              <div key={regionDTOForReportIndex}>
                <div className="flex flex-row items-center my-3">
                  <div className="flex-1 flex flex-row flex-wrap">
                    <div className="px-1">
                      <p className="">{regionDTOForReport.code}</p>
                    </div>
                    <div className="flex-1">
                      <p className="italic">
                        ({regionDTOForReport.countryCode} -{" "}
                        {regionDTOForReport.countryName})
                      </p>
                    </div>
                  </div>
                </div>
                <DividerLine />
                {regionDTOForReport.brandDTOForReportList.map(
                  (brandDTOForReport, brandDTOForReportIndex) => (
                    <div key={brandDTOForReportIndex} className="px-2">
                      <div className="flex flex-row items-center my-3">
                        <div className="flex-1">
                          <p className="px-2">{brandDTOForReport.name}</p>
                        </div>
                        <div className="flex-1">
                          <p className="px-2">
                            {brandDTOForReport.description}
                          </p>
                        </div>
                      </div>
                      {brandDTOForReport.basicUserProfileDTOList.map(
                        (userProfileDTO, userProfileDTOIndex) => (
                          <div
                            key={userProfileDTOIndex}
                            className="flex flex-row items-center px-3"
                          >
                            <div className="flex-1">
                              <p className="px-2">{userProfileDTO.name}</p>
                            </div>
                            <div className="flex-1">
                              <p className="px-2">
                                {userProfileDTO.description}
                              </p>
                            </div>
                            <div className="flex-1">
                              <p className="px-2">
                                {userProfileDTO.userDTO.firstName}
                              </p>
                            </div>
                            <div className="flex-1">
                              <p className="px-2">
                                {userProfileDTO.userDTO.lastName}
                              </p>
                            </div>
                            <div className="flex-1">
                              <p className="px-2">
                                {i18n.t(userProfileDTO.role)}
                              </p>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )
                )}
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const RenderReportContent = () => {
    if (reportDetail.type === "USER") {
      return RenderUserReportContent();
    } else if (reportDetail.type === "USER_PROFILE") {
      return RenderUserProfileReportContent();
    } else if (reportDetail.type === "BRANCH") {
      return RenderBranchReportContent();
    } else if (reportDetail.type === "GRADE") {
      return RenderGradeReportContent();
    } else if (reportDetail.type === "SEASON") {
      return RenderSeasonReportContent();
    } else if (reportDetail.type === "INSTITUTION") {
      return RenderInstitutionReportContent();
    } else if (reportDetail.type === "CAMPUS") {
      return RenderCampusReportContent();
    } else if (reportDetail.type === "BRAND") {
      return RenderBrandReportContent();
    } else if (reportDetail.type === "REGION") {
      return RenderRegionReportContent();
    }
    return null;
  };

  const RenderMainContent = () => {
    if (reportDetailLoading) {
      return (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      );
    } else {
      if (reportDetail) {
        return (
          <>
            {showTitle ? (
              <>
                <div className="">
                  <BaseTitle title={i18n.t("report_details")} />
                </div>
                <DividerLine />
              </>
            ) : null}
            {reportDetail ? (
              <div className="my-2 ml-auto">
                <PrimaryButton
                  title={i18n.t("generate_pdf")}
                  size="unlimited"
                  onClick={handleGeneratePDF}
                />
              </div>
            ) : null}
            <div className="flex flex-col items-center justify-center">
              <div className="w-full flex flex-row flex-wrap items-center">
                <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                  <div className="w-catchup-input-group-title py-2">
                    <p className="font-semibold">{i18n.t("begin_at")}</p>
                  </div>
                  <div className="flex-1">
                    <p>
                      {new Date(reportDetail.beginAt).toLocaleDateString(
                        language
                      )}
                    </p>
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                  <div className="w-full flex flex-row items-center">
                    <div className="w-catchup-input-group-title py-2">
                      <p className="font-semibold">{i18n.t("end_at")}</p>
                    </div>
                    <div className="flex-1">
                      <p>
                        {new Date(reportDetail.endAt).toLocaleDateString(
                          language
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                  <div className="w-full flex flex-row items-center">
                    <div className="w-catchup-input-group-title py-2">
                      <p className="font-semibold">{i18n.t("type")}</p>
                    </div>
                    <div className="flex-1">
                      <p>{i18n.t(reportDetail.type)}</p>
                    </div>
                  </div>
                </div>
              </div>

              {RenderReportContent()}
            </div>
          </>
        );
      }
    }
  };

  return <div className="flex-1 flex flex-col">{RenderMainContent()}</div>;
};

export default GeneralInformationReportDetail;
