import { Text, View } from "@react-pdf/renderer";
import i18n from "../../language/i18n";

const ParticipationReportViewContent = (props) => {
  const { language, subType, reportDetailList } = props;

  const constructSubHeaderText = () => {
    if (subType === "BRANCH") {
      return i18n.t("branch_report");
    } else if (subType === "GRADE") {
      return i18n.t("grade_report");
    } else if (subType === "SEASON") {
      return i18n.t("season_report");
    } else if (subType === "USER_PROFILE") {
      return i18n.t("user_profile_report");
    }
    return null;
  };

  const RenderCommonReportViewContent = (reportDetail) => {
    return (
      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
        {reportDetail.seasonGradeBranchMap ? (
          <>
            <View
              style={{ width: "33%", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Text style={{ marginVertical: 5, fontWeight: "bold" }}>
                {i18n.t("season_name")}:
              </Text>
              <Text style={{ marginVertical: 5, marginLeft: 5 }}>
                {reportDetail.seasonGradeBranchMap.seasonName}
              </Text>
            </View>
            <View
              style={{ width: "33%", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Text style={{ marginVertical: 5, fontWeight: "bold" }}>
                {i18n.t("grade_name")}:
              </Text>
              <Text style={{ marginVertical: 5, marginLeft: 5 }}>
                {reportDetail.seasonGradeBranchMap.gradeName}
              </Text>
            </View>
            <View
              style={{ width: "33%", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Text style={{ marginVertical: 5, fontWeight: "bold" }}>
                {i18n.t("branch_name")}:
              </Text>
              <Text style={{ marginVertical: 5, marginLeft: 5 }}>
                {reportDetail.seasonGradeBranchMap.branchName}
              </Text>
            </View>
          </>
        ) : null}
        {reportDetail.seasonGradeMap ? (
          <>
            <View
              style={{ width: "50%", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Text style={{ marginVertical: 5, fontWeight: "bold" }}>
                {i18n.t("season_name")}:
              </Text>
              <Text style={{ marginVertical: 5, marginLeft: 5 }}>
                {reportDetail.seasonGradeMap.seasonName}
              </Text>
            </View>
            <View
              style={{ width: "50%", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Text style={{ marginVertical: 5, fontWeight: "bold" }}>
                {i18n.t("grade_name")}:
              </Text>
              <Text style={{ marginVertical: 5, marginLeft: 5 }}>
                {reportDetail.seasonGradeMap.gradeName}
              </Text>
            </View>
          </>
        ) : null}
        {reportDetail.seasonMap ? (
          <>
            <View
              style={{ width: "100%", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Text style={{ marginVertical: 5, fontWeight: "bold" }}>
                {i18n.t("season_name")}:
              </Text>
              <Text style={{ marginVertical: 5, marginLeft: 5 }}>
                {reportDetail.seasonMap.seasonName}
              </Text>
            </View>
          </>
        ) : null}
        {reportDetail.userProfileMap ? (
          <>
            <View
              style={{ width: "33%", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Text style={{ marginVertical: 5, fontWeight: "bold" }}>
                {i18n.t("first_name")}:
              </Text>
              <Text style={{ marginVertical: 5, marginLeft: 5 }}>
                {reportDetail.userProfileMap.firstName}
              </Text>
            </View>
            <View
              style={{ width: "33%", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Text style={{ marginVertical: 5, fontWeight: "bold" }}>
                {i18n.t("last_name")}:
              </Text>
              <Text style={{ marginVertical: 5, marginLeft: 5 }}>
                {reportDetail.userProfileMap.lastName}
              </Text>
            </View>
            <View
              style={{ width: "33%", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Text style={{ marginVertical: 5, fontWeight: "bold" }}>
                {i18n.t("user_profile")}:
              </Text>
              <Text style={{ marginVertical: 5, marginLeft: 5 }}>
                {reportDetail.userProfileMap.userProfileName}
              </Text>
            </View>
          </>
        ) : null}
      </View>
    );
  };

  const combineCatchtivityApplicationBasedOnActivityId = (
    singleParticipationDetailList,
    totalCount
  ) => {
    const combinedSingleParticipationDetailList = [];
    singleParticipationDetailList.forEach((singleParticipationDetail) => {
      const foundCombinedSingleParticipationDetail =
        combinedSingleParticipationDetailList.find(
          (combinedSingleParticipationDetail) =>
            parseFloat(
              combinedSingleParticipationDetail.catchtivityApplicationId
            ) === parseFloat(singleParticipationDetail.catchtivityApplicationId)
        );
      if (foundCombinedSingleParticipationDetail) {
        foundCombinedSingleParticipationDetail.count +=
          singleParticipationDetail.count;
        foundCombinedSingleParticipationDetail.totalCount += totalCount;
      } else {
        combinedSingleParticipationDetailList.push({
          beginAt: singleParticipationDetail.beginAt,
          endAt: singleParticipationDetail.endAt,
          activityId: singleParticipationDetail.activityId,
          catchtivityName: singleParticipationDetail.catchtivityName,
          catchtivityApplicationId:
            singleParticipationDetail.catchtivityApplicationId,
          count: singleParticipationDetail.count,
          totalCount,
        });
      }
    });
    return combinedSingleParticipationDetailList.map(
      (combinedSingleParticipationDetail) => ({
        ...combinedSingleParticipationDetail,
        count:
          combinedSingleParticipationDetail.count /
          combinedSingleParticipationDetail.totalCount,
      })
    );
  };

  const combineCatchxamApplicationBasedOnActivityId = (
    singleParticipationDetailList,
    totalCount
  ) => {
    const combinedSingleParticipationDetailList = [];
    singleParticipationDetailList.forEach((singleParticipationDetail) => {
      const foundCombinedSingleParticipationDetail =
        combinedSingleParticipationDetailList.find(
          (combinedSingleParticipationDetail) =>
            parseFloat(
              combinedSingleParticipationDetail.catchxamApplicationId
            ) === parseFloat(singleParticipationDetail.catchxamApplicationId)
        );
      if (foundCombinedSingleParticipationDetail) {
        foundCombinedSingleParticipationDetail.count +=
          singleParticipationDetail.count;
        foundCombinedSingleParticipationDetail.totalCount += totalCount;
      } else {
        combinedSingleParticipationDetailList.push({
          beginAt: singleParticipationDetail.beginAt,
          endAt: singleParticipationDetail.endAt,
          catchxamName: singleParticipationDetail.catchxamName,
          catchxamApplicationId:
            singleParticipationDetail.catchxamApplicationId,
          count: singleParticipationDetail.count,
          totalCount,
        });
      }
    });
    return combinedSingleParticipationDetailList.map(
      (combinedSingleParticipationDetail) => ({
        ...combinedSingleParticipationDetail,
        count:
          combinedSingleParticipationDetail.count /
          combinedSingleParticipationDetail.totalCount,
      })
    );
  };

  const RenderCommonParticipationReportContent = (reportDetail) => {
    return (
      <View style={{ flex: 1, marginVertical: 5 }}>
        {reportDetail.participationCatchtivityCatchxamApplicationMap
          .catchtivityApplicationParticipationMapList.length > 0 ? (
          <View style={{ flex: 1 }}>
            <View style={{ flex: 1, flexDirection: "row" }}>
              <View style={{ width: "90%" }}></View>

              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "10%",
                }}
              >
                <Text style={{ padding: 8 }}>{i18n.t("catchtivity")}</Text>
              </View>
            </View>
            <View style={{ flex: 1, flexDirection: "row" }}>
              <View style={{ width: "90%" }}></View>

              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "10%",
                }}
              >
                <Text style={{ padding: 8 }}>%</Text>
              </View>
            </View>
          </View>
        ) : null}

        {combineCatchtivityApplicationBasedOnActivityId(
          reportDetail.participationCatchtivityCatchxamApplicationMap
            .catchtivityApplicationParticipationMapList,
          reportDetail.totalCount
        ).map((catchtivityApplicationParticipation) => (
          <View style={{ flex: 1, flexDirection: "row" }}>
            <View style={{ width: "90%" }}>
              <Text style={{ padding: 8 }}>
                (
                {new Date(
                  catchtivityApplicationParticipation.beginAt
                ).toLocaleDateString(language)}{" "}
                -{" "}
                {new Date(
                  catchtivityApplicationParticipation.endAt
                ).toLocaleDateString(language)}
                ) {catchtivityApplicationParticipation.catchtivityName}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "10%",
              }}
            >
              <Text style={{ padding: 8, fontWeight: "bold" }}>
                {`${(catchtivityApplicationParticipation.count * 100).toFixed(
                  2
                )}`}
              </Text>
            </View>
          </View>
        ))}

        {reportDetail.participationCatchtivityCatchxamApplicationMap
          .catchxamApplicationParticipationMapList.length > 0 ? (
          <View style={{ flex: 1 }}>
            <View style={{ flex: 1, flexDirection: "row" }}>
              <View style={{ width: "90%" }}></View>

              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "10%",
                }}
              >
                <Text style={{ padding: 8 }}>{i18n.t("catchxam")}</Text>
              </View>
            </View>
            <View style={{ flex: 1, flexDirection: "row" }}>
              <View style={{ width: "90%" }}></View>

              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "10%",
                }}
              >
                <Text style={{ padding: 8 }}>%</Text>
              </View>
            </View>
          </View>
        ) : null}

        {combineCatchxamApplicationBasedOnActivityId(
          reportDetail.participationCatchtivityCatchxamApplicationMap
            .catchxamApplicationParticipationMapList,
          reportDetail.totalCount
        ).map((catchxamApplicationParticipation) => (
          <View style={{ flex: 1, flexDirection: "row" }}>
            <View style={{ width: "90%" }}>
              <Text style={{ padding: 8 }}>
                (
                {new Date(
                  catchxamApplicationParticipation.beginAt
                ).toLocaleDateString(language)}{" "}
                -{" "}
                {new Date(
                  catchxamApplicationParticipation.endAt
                ).toLocaleDateString(language)}
                ) {catchxamApplicationParticipation.catchxamName}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "10%",
              }}
            >
              <Text style={{ padding: 8, fontWeight: "bold" }}>
                {`${(catchxamApplicationParticipation.count * 100).toFixed(2)}`}
              </Text>
            </View>
          </View>
        ))}
      </View>
    );
  };

  return (
    <View style={{ width: "100%" }}>
      <View style={{ flexDirection: "column", alignItems: "center" }}>
        <Text style={{ fontSize: 18, fontWeight: "bold" }}>
          {i18n.t("participation_report")}
        </Text>
        <Text style={{ fontSize: 14 }}>{constructSubHeaderText()}</Text>
      </View>

      {reportDetailList.map((reportDetail) => (
        <View style={{ marginVertical: 20 }}>
          {RenderCommonReportViewContent(reportDetail)}
          <View style={{ borderBottom: 0.5, marginVertical: 5 }} />
          {RenderCommonParticipationReportContent(reportDetail)}
        </View>
      ))}
    </View>
  );
};

export default ParticipationReportViewContent;
