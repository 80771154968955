import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Server
 */
export const checkLabelService = async () => {
  try {
    const response = await axios
      .create()
      .get(
        `${connection.base.label.url}${endpoints.labels.context}${endpoints.labels.actuator.health.context}`
      );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Label
 */
export const queryLabelListByUserIdAndUserProfileId = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.context}${endpoints.labels.query.dto["by-userId-userProfileId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createLabel = async (newLabel) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.context}/`,
      newLabel
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchLabel = async (currentLabel) => {
  try {
    const response = await axios.patch(
      `${connection.base.label.url}${endpoints.labels.context}/`,
      currentLabel
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Label Action
 */
export const queryLabelActionByLabelId = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.actions.context}${endpoints.labels.actions.query.dto["by-labelId"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryLabelActionByItemType = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.actions.context}${endpoints.labels.actions.query.dto["by-itemType"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryLabelActionByItemIdAndItemType = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.actions.context}${endpoints.labels.actions.query.dto["by-itemId-itemType"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryLabelActionByItemIdListAndItemType = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.actions.context}${endpoints.labels.actions.query.dto["by-itemIdList-itemType"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const addLabelItemToLabelAction = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.actions.context}${endpoints.labels.actions.add["label-item-to-label-action"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const addLabelItemToLabelActionFromLabelId = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.actions.context}${endpoints.labels.actions.add["label-item-to-label-action"]["from-labelId"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const removeLabelItemFromLabelAction = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.actions.context}${endpoints.labels.actions.remove["label-item-from-label-action"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const removeLabelItemFromLabelActionFromLabelId = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.actions.context}${endpoints.labels.actions.remove["label-item-from-label-action"]["from-labelId"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllLabelDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.context}${endpoints.labels.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Brand Label
 */
export const queryBrandLabelListByBrandId = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.brands.context}${endpoints.labels.brands.query.dto["by-brandId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createBrandLabel = async (newLabel) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.brands.context}/`,
      newLabel
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchBrandLabel = async (currentLabel) => {
  try {
    const response = await axios.patch(
      `${connection.base.label.url}${endpoints.labels.brands.context}/`,
      currentLabel
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllBrandLabelDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.brands.context}${endpoints.labels.brands.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Brand Label Action
 */
export const queryBrandLabelActionByItemIdAndItemType = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.brands.actions.context}${endpoints.labels.brands.actions.query.dto["by-itemId-itemType"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryBrandLabelActionByItemIdListAndItemType = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.brands.actions.context}${endpoints.labels.brands.actions.query.dto["by-itemIdList-itemType"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryBrandLabelActionByItemType = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.brands.actions.context}${endpoints.labels.brands.actions.query.dto["by-itemType"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const addBrandLabelItemToBrandLabelActionFromBrandLabelId = async (
  params
) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.brands.actions.context}${endpoints.labels.brands.actions.add["brand-label-item-to-brand-label-action"]["from-brandLabelId"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const removeBrandLabelItemFromBrandLabelActionFromBrandLabelId = async (
  params
) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels.brands.actions.context}${endpoints.labels.brands.actions.remove["brand-label-item-from-brand-label-action"]["from-brandLabelId"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryLabelActionDTOInDetailByBrandLabelIdListAllActivities =
  async (queryParams) => {
    try {
      const response = await axios.post(
        `${connection.base.label.url}${endpoints.labels.brands.actions.context}${endpoints.labels.brands.actions.query.dto["by-brandLabelIdList"]["in-all-activities"].detail.context}`,
        queryParams
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

/**
 * Publishing House Label
 */
export const queryPublishingHouseLabelList = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels["publishing-houses"].context}${endpoints.labels["publishing-houses"].query.dto.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createPublishingHouseLabel = async (newLabel) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels["publishing-houses"].context}/`,
      newLabel
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchPublishingHouseLabel = async (currentLabel) => {
  try {
    const response = await axios.patch(
      `${connection.base.label.url}${endpoints.labels["publishing-houses"].context}/`,
      currentLabel
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllPublishingHouseLabelDTOPageByFilter = async (
  pagedParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels["publishing-houses"].context}${endpoints.labels["publishing-houses"].paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Publishing House Label Action
 */

export const queryPublishingHouseLabelActionByItemIdListAndItemType = async (
  params
) => {
  try {
    const response = await axios.post(
      `${connection.base.label.url}${endpoints.labels["publishing-houses"].actions.context}${endpoints.labels["publishing-houses"].actions.query.dto["by-itemIdList-itemType"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryPublishingHouseLabelActionByPublishingHouseLabelIdListAndItemType =
  async (params) => {
    try {
      const response = await axios.post(
        `${connection.base.label.url}${endpoints.labels["publishing-houses"].actions.context}${endpoints.labels["publishing-houses"].actions.query.dto["by-publishingHouseLabelIdList-itemType"].context}`,
        params
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

export const queryPublishingHouseLabelActionByPublishingHouseLabelIdInDetail =
  async (params) => {
    try {
      const response = await axios.post(
        `${connection.base.label.url}${endpoints.labels["publishing-houses"].actions.context}${endpoints.labels["publishing-houses"].actions.query.dto["by-publishingHouseLabelId"].detail.context}`,
        params
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

export const addPublishingHouseLabelItemToPublishingHouseLabelActionFromPublishingHouseLabelId =
  async (params) => {
    try {
      const response = await axios.post(
        `${connection.base.label.url}${endpoints.labels["publishing-houses"].actions.context}${endpoints.labels["publishing-houses"].actions.add["publishing-house-label-item-to-publishing-house-label-action"]["from-publishingHouseLabelId"].context}`,
        params
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

export const removePublishingHouseLabelItemFromPublishingHouseLabelActionFromPublishingHouseLabelId =
  async (params) => {
    try {
      const response = await axios.post(
        `${connection.base.label.url}${endpoints.labels["publishing-houses"].actions.context}${endpoints.labels["publishing-houses"].actions.remove["publishing-house-label-item-from-publishing-house-label-action"]["from-publishingHouseLabelId"].context}`,
        params
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };
