import { useEffect, useState } from "react";
import BaseImage from "../images/BaseImage";
import { NotificationManager } from "react-notifications";
import i18n from "../../language/i18n";
import InputGroup from "./InputGroup";

const PageTravelGroup = (props) => {
  const {
    isImageProcessing,
    initialTotalPageNumber,
    pageNumber,
    setPageNumber,
    imageReady,
    setImageReady,
  } = props;
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [newPageNumber, setNewPageNumber] = useState(null);

  useEffect(() => {
    if (!initialTotalPageNumber) return;
    setTotalPageNumber(initialTotalPageNumber);
  }, [initialTotalPageNumber]);

  useEffect(() => {
    setNewPageNumber(pageNumber + 1);
  }, [pageNumber]);

  return (
    <div className="flex-1 flex flex-row justify-center items-center flex-wrap">
      {pageNumber === 0 ? null : (
        <div className="px-2">
          <div className="cursor-pointer">
            <BaseImage
              size="small"
              alt="arrow-left"
              src="/icons/arrow-left.png"
              onClick={() => {
                if (isImageProcessing) {
                  NotificationManager.info(
                    i18n.t("page_is_being_processed_please_wait")
                  );
                } else {
                  setPageNumber(pageNumber - 1);
                  setImageReady(false);
                }
              }}
            />
          </div>
        </div>
      )}
      {Array.from(Array(totalPageNumber).keys())
        .filter((index) => index < pageNumber + 5)
        .filter((index) => index > pageNumber - 5)
        .map((index) => (
          <div key={index} className="px-2">
            <p
              className={`${
                pageNumber === index ? "text-2xl" : "text-md"
              } cursor-pointer`}
              onClick={() => {
                if (isImageProcessing) {
                  NotificationManager.info(
                    i18n.t("page_is_being_processed_please_wait")
                  );
                } else {
                  setPageNumber(index);
                  setImageReady(false);
                }
              }}
            >
              {index + 1}
            </p>
          </div>
        ))}

      {totalPageNumber === 0 || pageNumber === totalPageNumber - 1 ? null : (
        <div className="px-2">
          <BaseImage
            size="small"
            alt="arrow-right"
            src="/icons/arrow-right.png"
            onClick={() => {
              if (isImageProcessing) {
                NotificationManager.info(
                  i18n.t("page_is_being_processed_please_wait")
                );
              } else {
                setPageNumber(pageNumber + 1);
                setImageReady(false);
              }
            }}
          />
        </div>
      )}
      <div>
        <input
          className={`w-[65px] py-2 px-4 border border-catchup-gray-100 rounded-catchup-xlarge focus:outline-none placeholder-catchup-gray-200 focus:border-catchup-blue-400 focus:shadow-input`}
          type="text"
          value={newPageNumber}
          onChange={(e) => {
            setNewPageNumber(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setPageNumber(parseFloat(newPageNumber) - 1);
            }
          }}
        />
      </div>
    </div>
  );
};

export default PageTravelGroup;
