import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingScreen from "../screens/landing/LandingScreen";
import ErrorScreen from "../screens/error/ErrorScreen";
import LoginScreen from "../screens/login/LoginScreen";
import RegisterScreen from "../screens/register/RegisterScreen";
import DashboardScreen from "../screens/dashboard/DashboardScreen";
import NetworkErrorScreen from "../screens/error/NetworkErrorScreen";
import PermissionErrorScreen from "../screens/error/PermissionErrorScreen";
import { useApp } from "../context/AppContextProvider";
import { Blocks } from "react-loader-spinner";
import ProfileSelectionScreen from "../screens/profile/ProfileSelectionScreen";
import ServerDiagnosticsScreen from "../screens/diagnostics/ServerDiagnosticsScreen";
import TokenErrorScreen from "../screens/error/TokenErrorScreen";
import AccountActivationErrorScreen from "../screens/error/AccountActivationErrorScreen";
import UserListScreen from "../screens/users/UserListScreen";
import UserProfileListScreen from "../screens/users/UserProfileListScreen";
import UserProfileAuthorityListScreen from "../screens/users/UserProfileAuthorityListScreen";
import BrandListScreen from "../screens/brands/BrandListScreen";
import RegionListScreen from "../screens/regions/RegionListScreen";
import CampusListScreen from "../screens/campus/CampusListScreen";
import InstitutionListScreen from "../screens/institutions/InstitutionListScreen";
import SeasonListScreen from "../screens/seasons/SeasonListScreen";
import GradeListScreen from "../screens/grades/GradeListScreen";
import BranchListScreen from "../screens/branches/BranchListScreen";
import ActivityListScreen from "../screens/activities/ActivityListScreen";
import CatchtivityListScreen from "../screens/catchtivities/CatchtivityListScreen";
import ActivityAccessibilityScreen from "../screens/activities/ActivityAccessibilityScreen";
import CatchtivityAccessibilityScreen from "../screens/catchtivities/CatchtivityAccessibilityScreen";
import CatchtivityApplicationListScreen from "../screens/catchtivity-applications/CatchtivityApplicationListScreen";
import ReportListScreen from "../screens/reports/ReportListScreen";
import ReportPDFViewerScreen from "../screens/pdf-viewer/ReportPDFViewerScreen";
import LabelListScreen from "../screens/labels/LabelListScreen";
import LabelDetailScreen from "../screens/labels/LabelDetailScreen";
import BrandLabelListScreen from "../screens/labels/BrandLabelListScreen";
import ActivityDocumentListScreen from "../screens/activities/ActivityDocumentListScreen";
import PlanDocumentListScreen from "../screens/plans/PlanDocumentListScreen";
import CategoryListScreen from "../screens/categories/CategoryListScreen";
import DashboardHeader from "../components/headers/DashboardHeader";
import ActivityTemplateListScreen from "../screens/activity-templates/ActivityTemplateListScreen";
import ActivityTemplateRuleListScreen from "../screens/activity-templates/ActivityTemplateRuleListScreen";
import DefaultProfileAuthorityScreen from "../screens/diagnostics/DefaultProfileAuthorityScreen";
import CreateUserScreen from "../screens/users/CreateUserScreen";
import CatchxamListScreen from "../screens/catchxam/CatchxamListScreen";
import CatchxamAccessibilityScreen from "../screens/catchxam/CatchxamAccessibilityScreen";
import CatchxamApplicationListScreen from "../screens/catchxam-applications/CatchxamApplicationListScreen";
import CatchtivityApplicationDetailScreen from "../screens/catchtivity-applications/CatchtivityApplicationDetailScreen";
import CatchxamApplicationDetailScreen from "../screens/catchxam-applications/CatchxamApplicationDetailScreen";
import BranchDetailScreen from "../screens/branches/BranchDetailScreen";
import GradeDetailScreen from "../screens/grades/GradeDetailScreen";
import SeasonDetailScreen from "../screens/seasons/SeasonDetailScreen";
import { useAuth } from "../context/UserContextProvider";
import BundleActivityListScreen from "../screens/bundles/BundleActivityListScreen";
import AssignBundleActivityScreen from "../screens/bundles/AssignBundleActivityScreen";
import PublishingHouseActivityListScreen from "../screens/activities/PublishingHouseActivityListScreen";
import UserLoginVisualizerScreen from "../screens/diagnostics/UserLoginVisualizerScreen";
import UserSettingsScreen from "../screens/settings/UserSettingsScreen";
import EmailVerificationScreen from "../screens/email-verification/EmailVerificationScreen";
import CreateActivityScreen from "../screens/activities/CreateActivityScreen";
import CreateActivityFromPDFScreen from "../screens/activities/CreateActivityFromPDFScreen";
import PlanScreen from "../screens/plans/PlanScreen";
import StandardExamListScreen from "../screens/standard-exams/StandardExamListScreen";
import StandardExamAccessibilityScreen from "../screens/standard-exams/StandardExamAccessibilityScreen";
import StandardExamApplicationListScreen from "../screens/standard-exam-applications/StandardExamApplicationListScreen";
import StandardExamApplicationDetailScreen from "../screens/standard-exam-applications/StandardExamApplicationDetailScreen";
import AssignEtudeScreen from "../screens/etude/AssignEtudeScreen";
import CheckEtudeScreen from "../screens/etude/CheckEtudeScreen";
import GenerateWithAIFromActivityScreen from "../screens/generate-with-ai/GenerateWithAIFromActivityScreen";
import GenerateWithAITryAPromptScreen from "../screens/generate-with-ai/GenerateWithAITryAPromptScreen";
import PublishingHouseLabelListScreen from "../screens/labels/PublishingHouseLabelListScreen";
import ActivityTransferScreen from "../screens/activity-transfer/ActivityTransferScreen";
import InstitutionAISettingsScreen from "../screens/institutions/InstitutionAISettingsScreen";
import CampusAISettingsScreen from "../screens/campus/CampusAISettingsScreen";
import BrandAISettingsScreen from "../screens/brands/BrandAISettingsScreen";
import PurchaseTokenListScreen from "../screens/tokens/PurchaseTokenListScreen";
import TokenAssignmentListScreen from "../screens/tokens/TokenAssignmentListScreen";
import TokenUsageVisualizerScreen from "../screens/diagnostics/TokenUsageVisualizerScreen";
import PublishingHouseLabelVisualizerScreen from "../screens/diagnostics/PublishingHouseLabelVisualizerScreen";
import BrandLabelVisualizerScreen from "../screens/diagnostics/BrandLabelVisualizerScreen";
import PlanTransferScreen from "../screens/plan-transfer/PlanTransferScreen";
import ForgotPasswordScreen from "../screens/forgot-password/ForgotPasswordScreen";
import ActivityTemplateFeedbackListScreen from "../screens/activity-template-feedbacks/ActivityTemplateFeedbackListScreen";
import ActivityJSONLConverterScreen from "../screens/activity-jsonl-converter/ActivityJSONLConverterScreen";
import InstitutionIntegrationSettingsScreen from "../screens/institutions/InstitutionIntegrationSettingsScreen";
import TransferLearnerFromK12Screen from "../screens/transfer/learner/TransferLearnerFromK12Screen";
import ActivityDocumentSettingsScreen from "../components/storage-file/ActivityDocumentSettingsScreen";
import MainLeftNavigation from "../components/navigations/MainLeftNavigation";
import BaseLoading from "../components/loading/BaseLoading";
import InstitutionGeneralSettingsScreen from "../screens/institutions/InstitutionGeneralSettingsScreen";
import AnnouncementListScreen from "../screens/announcements/AnnouncementListScreen";
import MyAnnouncementListScreen from "../screens/announcements/MyAnnouncementListScreen";
import AnnouncementDocumentListScreen from "../screens/announcements/AnnouncementDocumentListScreen";
import ActivityInvalidDataListScreen from "../screens/activity-invalid-data/ActivityInvalidDataListScreen";
import ExternalRegistrationListScreen from "../screens/external/ExternalRegistrationListScreen";
import ExternalLearnerListScreen from "../screens/external/ExternalLearnerListScreen";
import CreateExternalLearnerScreen from "../screens/external/CreateExternalLearnerScreen";
import ExternalExamListScreen from "../screens/external/ExternalExamListScreen";
import ExternalExamPartListScreen from "../screens/external/ExternalExamPartListScreen";
import ExternalExamActivitiesPDFViewerScreen from "../screens/pdf-viewer/ExternalExamActivitiesPDFViewerScreen";
import ExternalExamLearnersPDFViewerScreen from "../screens/pdf-viewer/ExternalExamLearnersPDFViewerScreen";
import ExternalExamAnswerListScreen from "../screens/external/ExternalExamAnswerListScreen";
import ExternalExamResultScreen from "../screens/external/ExternalExamResultScreen";
import ExternalExamSingleResultScreen from "../screens/external/ExternalExamSingleResultScreen";
import ExternalExamResultsPDFViewerScreen from "../screens/pdf-viewer/ExternalExamResultsPDFViewerScreen";

const retrieveLandingRouterOptionList = () => {
  return [
    {
      path: "/",
      element: <LandingScreen />,
    },
    {
      path: "/login",
      element: <LoginScreen />,
    },
    {
      path: "/register",
      element: <RegisterScreen />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPasswordScreen />,
    },
    {
      path: "/network-error",
      element: <NetworkErrorScreen />,
    },
    {
      path: "/email-verification/:emailVerificationBase64Code",
      element: <EmailVerificationScreen />,
    },
    {
      path: "*",
      element: <LoginScreen />,
    },
  ];
};

const retrieveDashboardRouterOptionList = () => {
  return [
    {
      path: "/",
      element: <DashboardScreen />,
      errorElement: <ErrorScreen />,
    },
    {
      path: "/users",
      element: <UserListScreen />,
    },
    {
      path: "/staff-users",
      element: <UserListScreen />,
    },
    {
      path: "/learner-users",
      element: <UserListScreen />,
    },
    {
      path: "/individual-users",
      element: <UserListScreen />,
    },
    {
      path: "/content-creator-users",
      element: <UserListScreen />,
    },
    {
      path: "/users/:userId/profiles",
      element: <UserProfileListScreen />,
    },
    {
      path: "/staff-users/:userId/profiles",
      element: <UserProfileListScreen />,
    },
    {
      path: "/learner-users/:userId/profiles",
      element: <UserProfileListScreen />,
    },
    {
      path: "/individual-users/:userId/profiles",
      element: <UserProfileListScreen />,
    },
    {
      path: "/content-creator-users/:userId/profiles",
      element: <UserProfileListScreen />,
    },
    {
      path: "/users/:userId/profiles/:userProfileId/authorities",
      element: <UserProfileAuthorityListScreen />,
    },
    {
      path: "/staff-users/:userId/profiles/:userProfileId/authorities",
      element: <UserProfileAuthorityListScreen />,
    },
    {
      path: "/learner-users/:userId/profiles/:userProfileId/authorities",
      element: <UserProfileAuthorityListScreen />,
    },
    {
      path: "/individual-users/:userId/profiles/:userProfileId/authorities",
      element: <UserProfileAuthorityListScreen />,
    },
    {
      path: "/content-creator-users/:userId/profiles/:userProfileId/authorities",
      element: <UserProfileAuthorityListScreen />,
    },
    {
      path: "/users/create/:mode",
      element: <CreateUserScreen />,
    },
    {
      path: "/activities/create/:mode",
      element: <CreateActivityScreen />,
    },
    {
      path: "/activities/create/:mode/:fileName",
      element: <CreateActivityFromPDFScreen />,
    },
    {
      path: "/profiles",
      element: <ProfileSelectionScreen />,
    },
    {
      path: "/brands",
      element: <BrandListScreen />,
    },
    {
      path: "/brands/:id/settings/ai-settings",
      element: <BrandAISettingsScreen />,
    },
    {
      path: "/regions",
      element: <RegionListScreen />,
    },
    {
      path: "/campuses",
      element: <CampusListScreen />,
    },
    {
      path: "/campuses/:id/settings/ai-settings",
      element: <CampusAISettingsScreen />,
    },
    {
      path: "/institutions",
      element: <InstitutionListScreen />,
    },
    {
      path: "/institutions/:id/settings/general-settings",
      element: <InstitutionGeneralSettingsScreen />,
    },
    {
      path: "/institutions/:id/settings/ai-settings",
      element: <InstitutionAISettingsScreen />,
    },
    {
      path: "/institutions/:id/settings/integration-settings",
      element: <InstitutionIntegrationSettingsScreen />,
    },
    {
      path: "/seasons",
      element: <SeasonListScreen />,
    },
    {
      path: "/seasons/:seasonId/detail",
      element: <SeasonDetailScreen />,
    },
    {
      path: "/grades",
      element: <GradeListScreen />,
    },
    {
      path: "/grades/:gradeId/detail",
      element: <GradeDetailScreen />,
    },
    {
      path: "/branches",
      element: <BranchListScreen />,
    },
    {
      path: "/branches/:branchId/detail",
      element: <BranchDetailScreen />,
    },
    {
      path: "/branches/:branchId/transfer-learner/from-k12",
      element: <TransferLearnerFromK12Screen />,
    },
    {
      path: "/activities",
      element: <ActivityListScreen />,
    },
    {
      path: "/publishing-house-activities",
      element: <PublishingHouseActivityListScreen />,
    },
    {
      path: "/activity-templates",
      element: <ActivityTemplateListScreen />,
    },
    {
      path: "/activity-templates/:activityTemplateId/rules",
      element: <ActivityTemplateRuleListScreen />,
    },
    {
      path: "/activity-jsonl-converter",
      element: <ActivityJSONLConverterScreen />,
    },
    {
      path: "/activities/:activityId/accessibilities",
      element: <ActivityAccessibilityScreen />,
    },
    {
      path: "/catchtivities",
      element: <CatchtivityListScreen />,
    },
    {
      path: "/catchtivities/:catchtivityId/accessibilities",
      element: <CatchtivityAccessibilityScreen />,
    },
    {
      path: "/catchtivities/applications",
      element: <CatchtivityApplicationListScreen />,
    },
    {
      path: "/catchtivities/applications/:catchtivityApplicationId/detail",
      element: <CatchtivityApplicationDetailScreen />,
    },
    {
      path: "/catchxams",
      element: <CatchxamListScreen />,
    },
    {
      path: "/catchxams/:catchxamId/accessibilities",
      element: <CatchxamAccessibilityScreen />,
    },
    {
      path: "/catchxams/applications",
      element: <CatchxamApplicationListScreen />,
    },
    {
      path: "/catchxams/applications/:catchxamApplicationId/detail",
      element: <CatchxamApplicationDetailScreen />,
    },
    { path: "/etudes/assign", element: <AssignEtudeScreen /> },
    { path: "/etudes/check", element: <CheckEtudeScreen /> },
    {
      path: "/standard-exams",
      element: <StandardExamListScreen />,
    },
    {
      path: "/standard-exams/:standardExamId/accessibilities",
      element: <StandardExamAccessibilityScreen />,
    },
    {
      path: "/standard-exams/applications",
      element: <StandardExamApplicationListScreen />,
    },
    {
      path: "/standard-exams/applications/:standardExamApplicationId/detail",
      element: <StandardExamApplicationDetailScreen />,
    },
    {
      path: "/external-registrations",
      element: <ExternalRegistrationListScreen />,
    },
    {
      path: "/external-registrations/:externalRegistrationId/external-learners",
      element: <ExternalLearnerListScreen />,
    },
    {
      path: "/external-registrations/:externalRegistrationId/external-learners/create/:mode",
      element: <CreateExternalLearnerScreen />,
    },
    {
      path: "/external-exams",
      element: <ExternalExamListScreen />,
    },
    {
      path: "/external-exams/:externalExamId/external-exam-parts",
      element: <ExternalExamPartListScreen />,
    },
    {
      path: "/external-exams/:externalExamId/external-exam-answers",
      element: <ExternalExamAnswerListScreen />,
    },
    {
      path: "/external-exams/:externalExamId/activities/view/pdf",
      element: <ExternalExamActivitiesPDFViewerScreen />,
    },
    {
      path: "/external-exams/:externalExamId/learners/view/pdf",
      element: <ExternalExamLearnersPDFViewerScreen />,
    },
    {
      path: "/external-exams/:externalExamId/results/view/pdf",
      element: <ExternalExamResultsPDFViewerScreen />,
    },
    {
      path: "/external-exams/:externalExamId/external-exam-results",
      element: <ExternalExamResultScreen />,
    },
    {
      path: "/external-exams/:externalExamId/external-exam-results/:learnerId",
      element: <ExternalExamSingleResultScreen />,
    },
    {
      path: "/announcements",
      element: <AnnouncementListScreen />,
    },
    {
      path: "/my-announcements",
      element: <MyAnnouncementListScreen />,
    },
    {
      path: "/announcement-documents",
      element: <AnnouncementDocumentListScreen />,
    },
    {
      path: "/reports",
      element: <ReportListScreen />,
    },
    {
      path: "/categories",
      element: <CategoryListScreen />,
    },
    {
      path: "/labels",
      element: <LabelListScreen />,
    },
    {
      path: "/labels/:labelId",
      element: <LabelDetailScreen />,
    },
    {
      path: "/bundles/activities",
      element: <BundleActivityListScreen />,
    },
    {
      path: "/bundles/activities/assign",
      element: <AssignBundleActivityScreen />,
    },
    {
      path: "/tokens/purchases",
      element: <PurchaseTokenListScreen />,
    },
    {
      path: "/tokens/assign",
      element: <TokenAssignmentListScreen />,
    },
    {
      path: "/brand-labels",
      element: <BrandLabelListScreen />,
    },
    {
      path: "/publishing-house-labels",
      element: <PublishingHouseLabelListScreen />,
    },
    {
      path: "/activity-documents",
      element: <ActivityDocumentListScreen />,
    },
    {
      path: "/activity-documents/settings/:fileName",
      element: <ActivityDocumentSettingsScreen />,
    },
    { path: "/activity-transfer", element: <ActivityTransferScreen /> },
    {
      path: "/activity-template-feedbacks",
      element: <ActivityTemplateFeedbackListScreen />,
    },
    {
      path: "/activity-invalid-data",
      element: <ActivityInvalidDataListScreen />,
    },
    {
      path: "/diagnostics",
      element: <ServerDiagnosticsScreen />,
    },
    {
      path: "/settings",
      element: <UserSettingsScreen />,
    },
    {
      path: "/email-verification/:emailVerificationBase64Code",
      element: <EmailVerificationScreen />,
    },
    {
      path: "/default-user-role-authority",
      element: <DefaultProfileAuthorityScreen />,
    },
    {
      path: "/user-login-visualizer",
      element: <UserLoginVisualizerScreen />,
    },
    {
      path: "/token-usage-visualizer",
      element: <TokenUsageVisualizerScreen />,
    },
    {
      path: "/brand-label-visualizer",
      element: <BrandLabelVisualizerScreen />,
    },
    {
      path: "/publishing-house-label-visualizer",
      element: <PublishingHouseLabelVisualizerScreen />,
    },
    {
      path: "/plans",
      element: <PlanScreen />,
    },
    {
      path: "/plan-documents",
      element: <PlanDocumentListScreen />,
    },
    {
      path: "/plan-transfer",
      element: <PlanTransferScreen />,
    },
    {
      path: "/activities/generate-with-ai/:activityId/from-activity",
      element: <GenerateWithAIFromActivityScreen />,
    },
    {
      path: "/activities/generate-with-ai/:activityId/try-a-prompt",
      element: <GenerateWithAITryAPromptScreen />,
    },
    {
      path: "/network-error",
      element: <NetworkErrorScreen />,
    },
    {
      path: "/permission-error",
      element: <PermissionErrorScreen />,
    },
  ];
};

const retrieveViewPDFRouterOptionList = () => {
  return [
    {
      path: "/view/pdf/:base64DataEncoded",
      element: <ReportPDFViewerScreen />,
    },
  ];
};

const landingRouterOptionList = retrieveLandingRouterOptionList();
const dashboardRouterOptionList = retrieveDashboardRouterOptionList();

const BaseRouterProvider = () => {
  const { boarding, isFullScreen } = useApp();
  const { userProfile } = useAuth();
  const RenderMainRouterContent = () => {
    if (boarding === "LANDING" || boarding === "DASHBOARD") {
      return (
        <Router>
          <div key={userProfile?.id} className="flex-1 flex flex-col">
            <div className="flex flex-row">
              {isFullScreen ? null : (
                <div className="h-screen">
                  <MainLeftNavigation />
                </div>
              )}
              <div className="-ml-4 flex-1">
                <div className="h-full px-10">
                  <div
                    id="scroll"
                    className="w-full h-screen no-scrollbar overflow-y-auto"
                  >
                    <div className="h-full container mx-auto">
                      {boarding === "DASHBOARD" ? <DashboardHeader /> : null}
                      <Routes>
                        {boarding === "LANDING"
                          ? landingRouterOptionList.map(
                              (routerOption, index) => (
                                <Route
                                  key={index}
                                  path={routerOption.path}
                                  errorElement={routerOption.errorElement}
                                  element={routerOption.element}
                                />
                              )
                            )
                          : dashboardRouterOptionList.map(
                              (routerOption, index) => (
                                <Route
                                  key={index}
                                  path={routerOption.path}
                                  errorElement={
                                    routerOption.errorElement
                                      ? routerOption.errorElement
                                      : null
                                  }
                                  element={routerOption.element}
                                />
                              )
                            )}
                      </Routes>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Router>
      );
    } else if (boarding === "VIEW_PDF") {
      return (
        <Router>
          <div key={userProfile?.id} className="flex-1 flex flex-col">
            <div className="flex-1 flex flex-row">
              <div className={`flex-1 overflow-y-scroll h-screen`}>
                <div className="container mx-auto h-full">
                  <Routes>
                    {retrieveViewPDFRouterOptionList().map(
                      (routerOption, index) => (
                        <Route
                          key={index}
                          path={routerOption.path}
                          errorElement={routerOption.errorElement}
                          element={routerOption.element}
                        />
                      )
                    )}
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </Router>
      );
    } else if (boarding === "NETWORK_ERROR") {
      return (
        <div className="h-screen flex items-center justify-center">
          <NetworkErrorScreen />
        </div>
      );
    } else if (boarding === "PROFILE_SELECTION_REQUIRED") {
      return (
        <div className="w-full h-screen no-scrollbar overflow-y-auto">
          <div className="h-full container m-auto">
            <ProfileSelectionScreen />
          </div>
        </div>
      );
    } else if (boarding === "ACCOUNT_ACTIVATION_REQUIRED") {
      return (
        <div className="h-screen flex items-center justify-center">
          <AccountActivationErrorScreen />
        </div>
      );
    } else if (boarding === "TOKEN_ERROR") {
      return (
        <Router>
          <div className="flex-1 flex flex-col">
            <div className="flex-1 flex flex-row">
              {isFullScreen ? null : (
                <div className="h-screen">
                  <MainLeftNavigation />
                </div>
              )}
              <div className="w-full h-screen overflow-y-auto">
                <div className="container mx-auto">
                  <DashboardHeader />
                  <Routes>
                    <Route
                      path="/"
                      errorElement={<ErrorScreen />}
                      element={<TokenErrorScreen />}
                    />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </Router>
      );
    } else {
      return (
        <div className="h-screen flex items-center justify-center">
          <BaseLoading size="xlarge" color="#55777f" secondaryColor="#55777f" />
        </div>
      );
    }
  };

  return RenderMainRouterContent();
};

export default BaseRouterProvider;
