import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  addBrandLabelItemToBrandLabelActionFromBrandLabelId,
  addLabelItemToLabelActionFromLabelId,
  addPublishingHouseLabelItemToPublishingHouseLabelActionFromPublishingHouseLabelId,
  createBrandLabel,
  createLabel,
  createPublishingHouseLabel,
  removeBrandLabelItemFromBrandLabelActionFromBrandLabelId,
  removeLabelItemFromLabelActionFromLabelId,
  removePublishingHouseLabelItemFromPublishingHouseLabelActionFromPublishingHouseLabelId,
} from "../../requests/LabelRequests";
import {
  checkDeletePermissionGranted,
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
} from "../../utilization/ScreenUtilization";
import FullCard from "../cards/FullCard";
import InputGroup from "../groups/InputGroup";
import BaseImage from "../images/BaseImage";
import CreateButton from "../buttons/CreateButton";
import PersonalLabel from "./PersonalLabel";
import BrandLabel from "./BrandLabel";
import PublishingHouseLabel from "./PublishingHouseLabel";
import SelectionTab from "../tabs/SelectionTab";
import BaseLoading from "../loading/BaseLoading";

const SetOrUpsertLabel = (props) => {
  const {
    item,
    itemType,
    personalLabelLoading,
    personalLabelActionList,
    setPersonalLabelActionList,
    brandLabelLoading,
    brandLabelActionList,
    setBrandLabelActionList,
    publishingHouseLabelLoading,
    publishingHouseLabelActionList,
    setPublishingHouseLabelActionList,
  } = props;
  const {
    userInformation,
    userProfile,
    userProfileBrand,
    userLabelSet,
    userBrandLabelSet,
    userPublishingHouseLabelSet,
    setUserLabelSet,
    setUserBrandLabelSet,
    setUserPublishingHouseLabelSet,
  } = useAuth();
  const [selectedLabelType, setSelectedLabelType] = useState("PERSONAL");
  const [selectedLabelState, setSelectedLabelState] = useState(0);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [errorMap, setErrorMap] = useState({
    name: null,
    description: null,
  });

  useEffect(() => {
    if (personalLabelActionList) {
      setSelectedLabelType("PERSONAL");
    } else if (brandLabelActionList) {
      setSelectedLabelType("BRAND");
    } else if (publishingHouseLabelActionList) {
      setSelectedLabelState("PUBLISHING_HOUSE");
    }
  }, []);

  useEffect(() => {
    setSelectedLabelState(0);
    setName(null);
    setDescription(null);
    setErrorMap({
      name: null,
      description: null,
    });
  }, [selectedLabelType]);

  const retrieveAvailableLabelList = () => {
    return userLabelSet.filter(
      (userLabel) =>
        filteredLabelActionList.findIndex(
          (personalLabelAction) =>
            personalLabelAction.labelDTO.id === userLabel.id
        ) === -1
    );
  };

  const retrieveAvailableBrandLabelList = () => {
    return userBrandLabelSet.filter(
      (userBrandLabel) =>
        filteredBrandLabelActionList.findIndex(
          (brandLabelAction) =>
            brandLabelAction.brandLabelDTO.id === userBrandLabel.id
        ) === -1
    );
  };

  const retrieveAvailablePublishingHouseLabelList = () => {
    return userPublishingHouseLabelSet.filter(
      (userPublishingHouseLabel) =>
        filteredPublishingHouseLabelActionList.findIndex(
          (publishingHouseLabelAction) =>
            publishingHouseLabelAction.publishingHouseLabelDTO.id ===
            userPublishingHouseLabel.id
        ) === -1
    );
  };

  const filterLabelActionList = () => {
    if (!item) return [];
    if (!personalLabelActionList) return [];
    return personalLabelActionList.filter(
      (labelAction) =>
        labelAction.labelItemDTOList.filter(
          (labelItem) => parseFloat(labelItem.itemId) === parseFloat(item.id)
        ).length > 0
    );
  };

  const filterBrandLabelActionList = () => {
    if (!item) return [];
    if (!brandLabelActionList) return [];
    return brandLabelActionList.filter(
      (brandLabelAction) =>
        brandLabelAction.brandLabelItemDTOList.filter(
          (brandLabelItem) =>
            parseFloat(brandLabelItem.itemId) === parseFloat(item.id)
        ).length > 0
    );
  };

  const filterPublishingHouseLabelActionList = () => {
    if (!item) return [];
    if (!publishingHouseLabelActionList) return [];
    return publishingHouseLabelActionList.filter(
      (publishingHouseLabelAction) =>
        publishingHouseLabelAction.publishingHouseLabelItemDTOList.filter(
          (publishingHouseLabelItem) =>
            parseFloat(publishingHouseLabelItem.itemId) === parseFloat(item.id)
        ).length > 0
    );
  };

  const checkCommonValidation = () => {
    if (
      !name ||
      name.trim() === "" ||
      !description ||
      description.trim() === ""
    ) {
      const currentErrorMap = {
        name: null,
        description: null,
      };
      if (!name || name.trim() === "") {
        currentErrorMap.name = i18n.t("name_required_field");
      }
      if (!description || description.trim() === "") {
        currentErrorMap.description = i18n.t("description_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const handleOnChange = (type, value) => {
    if (type === "name") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: i18n.t("name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: null,
        }));
      }
      setName(value);
    } else if (type === "description") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: i18n.t("description_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: null,
        }));
      }
      setDescription(value);
    } else {
    }
  };

  const handleAddLabelItemToLabelActionOnClick = async (labelDTO) => {
    if (checkUpdatePermissionGranted("label_list")) {
      const currentIndex = filteredLabelActionList.findIndex(
        (labelActionDTO) =>
          parseFloat(labelActionDTO.labelDTO.id) === parseFloat(labelDTO.id)
      );
      if (currentIndex === -1) {
        const { err } = await addLabelItemToLabelActionFromLabelId({
          labelId: labelDTO.id,
          itemId: item.id,
          itemType: itemType,
        });
        if (err) {
        } else {
          personalLabelActionList.push({
            labelDTO: labelDTO,
            labelItemDTOList: [
              { itemId: item.id, itemType: itemType, deleted: false },
            ],
            deleted: false,
          });
          setPersonalLabelActionList(
            JSON.parse(JSON.stringify(personalLabelActionList))
          );
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleAddBrandLabelItemToBrandLabelActionOnClick = async (
    brandLabelDTO
  ) => {
    if (checkUpdatePermissionGranted("brand_label_list")) {
      const currentIndex = filteredBrandLabelActionList.findIndex(
        (brandLabelAction) =>
          parseFloat(brandLabelAction.brandLabelDTO.id) ===
          parseFloat(brandLabelDTO.id)
      );
      if (currentIndex === -1) {
        const { err } =
          await addBrandLabelItemToBrandLabelActionFromBrandLabelId({
            userId: userInformation.id,
            userProfileId: userProfile.id,
            brandLabelId: brandLabelDTO.id,
            itemId: item.id,
            itemType: itemType,
          });
        if (err) {
        } else {
          brandLabelActionList.push({
            brandLabelDTO,
            brandLabelItemDTOList: [
              { itemId: item.id, itemType: itemType, deleted: false },
            ],
            deleted: false,
          });
          setBrandLabelActionList(
            JSON.parse(JSON.stringify(brandLabelActionList))
          );
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleAddPublishingHouseLabelItemToPublishingHouseLabelActionOnClick =
    async (publishingHouseLabelDTO) => {
      if (checkUpdatePermissionGranted("publishing_house_label_list")) {
        const currentIndex = filteredPublishingHouseLabelActionList.findIndex(
          (publishingHouseLabelAction) =>
            parseFloat(
              publishingHouseLabelAction.publishingHouseLabelDTO.id
            ) === parseFloat(publishingHouseLabelDTO.id)
        );
        if (currentIndex === -1) {
          const { err } =
            await addPublishingHouseLabelItemToPublishingHouseLabelActionFromPublishingHouseLabelId(
              {
                userId: userInformation.id,
                userProfileId: userProfile.id,
                publishingHouseLabelId: publishingHouseLabelDTO.id,
                itemId: item.id,
                itemType: itemType,
              }
            );
          if (err) {
          } else {
            publishingHouseLabelActionList.push({
              publishingHouseLabelDTO,
              publishingHouseLabelItemDTOList: [
                { itemId: item.id, itemType: itemType, deleted: false },
              ],
              deleted: false,
            });
            setPublishingHouseLabelActionList(
              JSON.parse(JSON.stringify(publishingHouseLabelActionList))
            );
          }
        }
      } else {
        NotificationManager.error(
          i18n.t("you_do_not_have_permission_to_update")
        );
      }
    };

  const handleRemoveLabelItemFromLabelAction = async (labelId) => {
    if (checkUpdatePermissionGranted("label_list")) {
      const { data, err } = await removeLabelItemFromLabelActionFromLabelId({
        labelId,
        itemId: item.id,
      });
      if (err) {
        console.log(err);
      } else {
        const foundLabelActionIndex = filteredLabelActionList.findIndex(
          (labelActionDTO) =>
            parseFloat(labelActionDTO.labelDTO.id) === parseFloat(labelId)
        );
        if (foundLabelActionIndex > -1) {
          const foundLabelActionItemIndex = filteredLabelActionList[
            foundLabelActionIndex
          ].labelItemDTOList.findIndex(
            (labelItemDTO) =>
              parseFloat(labelItemDTO.itemId) === parseFloat(item.id)
          );
          filteredLabelActionList[
            foundLabelActionIndex
          ].labelItemDTOList.splice(foundLabelActionItemIndex, 1);
          setPersonalLabelActionList(
            JSON.parse(JSON.stringify(personalLabelActionList))
          );
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleRemoveBrandLabelItemFromBrandLabelAction = async (
    brandLabelId
  ) => {
    if (checkUpdatePermissionGranted("brand_label_list")) {
      const { data, err } =
        await removeBrandLabelItemFromBrandLabelActionFromBrandLabelId({
          brandLabelId,
          itemId: item.id,
        });
      if (err) {
        console.log(err);
      } else {
        const foundBrandLabelActionIndex =
          filteredBrandLabelActionList.findIndex(
            (brandLabelAction) =>
              parseFloat(brandLabelAction.brandLabelDTO.id) ===
              parseFloat(brandLabelId)
          );
        if (foundBrandLabelActionIndex > -1) {
          const foundBrandLabelActionItemIndex = filteredBrandLabelActionList[
            foundBrandLabelActionIndex
          ].brandLabelItemDTOList.findIndex(
            (brandLabelItemDTO) =>
              parseFloat(brandLabelItemDTO.itemId) === parseFloat(item.id)
          );
          filteredBrandLabelActionList[
            foundBrandLabelActionIndex
          ].brandLabelItemDTOList.splice(foundBrandLabelActionItemIndex, 1);
          setBrandLabelActionList(
            JSON.parse(JSON.stringify(brandLabelActionList))
          );
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleRemovePublishingHouseLabelItemFromPublishingHouseLabelAction =
    async (publishingHouseLabelId) => {
      if (checkUpdatePermissionGranted("publishing_house_label_list")) {
        const { data, err } =
          await removePublishingHouseLabelItemFromPublishingHouseLabelActionFromPublishingHouseLabelId(
            {
              publishingHouseLabelId,
              itemId: item.id,
            }
          );
        if (err) {
          console.log(err);
        } else {
          const foundPublishingHouseLabelActionIndex =
            filteredPublishingHouseLabelActionList.findIndex(
              (publishingHouseLabelAction) =>
                parseFloat(
                  publishingHouseLabelAction.publishingHouseLabelDTO.id
                ) === parseFloat(publishingHouseLabelId)
            );
          if (foundPublishingHouseLabelActionIndex > -1) {
            const foundPublishingHouseLabelActionItemIndex =
              filteredPublishingHouseLabelActionList[
                foundPublishingHouseLabelActionIndex
              ].publishingHouseLabelItemDTOList.findIndex(
                (publishingHouseLabelItemDTO) =>
                  parseFloat(publishingHouseLabelItemDTO.itemId) ===
                  parseFloat(item.id)
              );
            filteredPublishingHouseLabelActionList[
              foundPublishingHouseLabelActionIndex
            ].publishingHouseLabelItemDTOList.splice(
              foundPublishingHouseLabelActionItemIndex,
              1
            );
            setPublishingHouseLabelActionList(
              JSON.parse(JSON.stringify(publishingHouseLabelActionList))
            );
          }
        }
      } else {
        NotificationManager.error(
          i18n.t("you_do_not_have_permission_to_update")
        );
      }
    };

  const handleCreateLabelOnClick = async () => {
    if (checkInsertPermissionGranted("label_list")) {
      if (checkCommonValidation()) {
        const newLabel = {
          userId: userInformation.id,
          userProfileId: userProfile.id,
          name,
          description,
        };
        const { data, err } = await createLabel(newLabel);
        if (err) {
          console.log(err);
        } else {
          userLabelSet.push({
            id: data.id,
            userId: data.userId,
            userProfileId: data.userProfileId,
            name: data.name,
            description: data.description,
            deleted: data.deleted,
            createdAt: data.createdAt,
          });
          setUserLabelSet(JSON.parse(JSON.stringify(userLabelSet)));
          setSelectedLabelState(0);
          setName(null);
          setDescription(null);
          setErrorMap({
            name: null,
            description: null,
          });
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleCreateBrandLabelOnClick = async () => {
    if (checkInsertPermissionGranted("brand_label_list")) {
      if (checkCommonValidation()) {
        const newLabel = {
          brandId: userProfileBrand.id,
          name,
          description,
        };
        const { data, err } = await createBrandLabel(newLabel);
        if (err) {
          console.log(err);
        } else {
          userBrandLabelSet.push({
            id: data.id,
            userId: data.userId,
            userProfileId: data.userProfileId,
            name: data.name,
            description: data.description,
            deleted: data.deleted,
            createdAt: data.createdAt,
          });
          setUserBrandLabelSet(JSON.parse(JSON.stringify(userBrandLabelSet)));
          setSelectedLabelState(0);
          setName(null);
          setDescription(null);
          setErrorMap({
            name: null,
            description: null,
          });
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleCreatePublishingHouseLabelOnClick = async () => {
    if (checkInsertPermissionGranted("publishing_house_label_list")) {
      if (checkCommonValidation()) {
        const newLabel = {
          name,
          description,
        };
        const { data, err } = await createPublishingHouseLabel(newLabel);
        if (err) {
          console.log(err);
        } else {
          userPublishingHouseLabelSet.push({
            id: data.id,
            userId: data.userId,
            userProfileId: data.userProfileId,
            name: data.name,
            description: data.description,
            deleted: data.deleted,
            createdAt: data.createdAt,
          });
          setUserPublishingHouseLabelSet(
            JSON.parse(JSON.stringify(userPublishingHouseLabelSet))
          );
          setSelectedLabelState(0);
          setName(null);
          setDescription(null);
          setErrorMap({
            name: null,
            description: null,
          });
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const RenderPersonalLabelItem = (labelDTO, state) => {
    return (
      <div className="flex flex-row items-center py-1 gap-x-1 my-2">
        <PersonalLabel title={labelDTO.name} />
        {state === "ADD" && checkInsertPermissionGranted("label_list") ? (
          <BaseImage
            src="/icons/plus.png"
            alt="plus"
            size="xsmall"
            showLoading={true}
            onClick={() => {
              handleAddLabelItemToLabelActionOnClick(labelDTO);
            }}
          />
        ) : state === "REMOVE" && checkDeletePermissionGranted("label_list") ? (
          <BaseImage
            src="/icons/remove-red.png"
            alt="remove-red"
            size="xsmall"
            showLoading={true}
            onClick={() => {
              handleRemoveLabelItemFromLabelAction(labelDTO.id);
            }}
          />
        ) : null}
      </div>
    );
  };

  const RenderBrandLabelItem = (labelDTO, state) => {
    return (
      <div className="flex flex-row items-center py-1 gap-x-1 my-2">
        <BrandLabel title={labelDTO.name} />
        {state === "ADD" && checkInsertPermissionGranted("brand_label_list") ? (
          <BaseImage
            src="/icons/plus.png"
            alt="plus"
            size="xsmall"
            showLoading={true}
            onClick={() => {
              handleAddBrandLabelItemToBrandLabelActionOnClick(labelDTO);
            }}
          />
        ) : state === "REMOVE" &&
          checkDeletePermissionGranted("brand_label_list") ? (
          <BaseImage
            src="/icons/remove-red.png"
            alt="remove-red"
            size="xsmall"
            onClick={() => {
              handleRemoveBrandLabelItemFromBrandLabelAction(labelDTO.id);
            }}
          />
        ) : null}
      </div>
    );
  };

  const RenderPublishingHouseLabelItem = (labelDTO, state) => {
    return (
      <div className="flex flex-row items-center py-1 gap-x-1 my-2">
        <PublishingHouseLabel title={labelDTO.name} />
        {state === "ADD" &&
        checkInsertPermissionGranted("publishing_house_label_list") ? (
          <BaseImage
            src="/icons/plus.png"
            alt="plus"
            size="xsmall"
            showLoading={true}
            onClick={() => {
              handleAddPublishingHouseLabelItemToPublishingHouseLabelActionOnClick(
                labelDTO
              );
            }}
          />
        ) : state === "REMOVE" &&
          checkDeletePermissionGranted("publishing_house_label_list") ? (
          <BaseImage
            src="/icons/remove-red.png"
            alt="remove-red"
            size="xsmall"
            onClick={() => {
              handleRemovePublishingHouseLabelItemFromPublishingHouseLabelAction(
                labelDTO.id
              );
            }}
          />
        ) : null}
      </div>
    );
  };

  const RenderMainContent = () => {
    if (selectedLabelType === "PERSONAL") {
      if (personalLabelLoading) {
        return (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        );
      } else {
        if (selectedLabelState === 0) {
          return (
            <div className="flex flex-row flex-wrap items-center gap-x-3 my-5">
              {retrieveAvailableLabelList().map((personalLabelDTO) =>
                RenderPersonalLabelItem(personalLabelDTO, "ADD")
              )}
            </div>
          );
        } else if (selectedLabelState === 1) {
          return (
            <div className="flex flex-col items-center justify-center text-catchup-dark-blue">
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={name}
                    onChange={(e) => {
                      handleOnChange("name", e.target.value);
                    }}
                    errorText={errorMap.name}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("description")}</p>
                </div>
                <div className="flex-1 h-catchup-description">
                  <InputGroup
                    type="textarea"
                    value={description}
                    onChange={(e) => {
                      handleOnChange("description", e.target.value);
                    }}
                    errorText={errorMap.description}
                  />
                </div>
              </div>

              <div className="mt-4 ml-auto">
                <CreateButton
                  title={i18n.t("create")}
                  size="medium"
                  onClick={handleCreateLabelOnClick}
                />
              </div>
            </div>
          );
        }
      }
    } else if (selectedLabelType === "BRAND") {
      if (brandLabelLoading) {
        return (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        );
      } else {
        if (selectedLabelState === 0) {
          return (
            <div className="flex flex-row flex-wrap items-center gap-x-3 my-5">
              {retrieveAvailableBrandLabelList().map((brandLabelDTO) =>
                RenderBrandLabelItem(brandLabelDTO, "ADD")
              )}
            </div>
          );
        } else if (selectedLabelState === 1) {
          return (
            <div className="flex flex-col items-center justify-center text-catchup-dark-blue">
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={name}
                    onChange={(e) => {
                      handleOnChange("name", e.target.value);
                    }}
                    errorText={errorMap.name}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("description")}</p>
                </div>
                <div className="flex-1 h-catchup-description">
                  <InputGroup
                    type="textarea"
                    value={description}
                    onChange={(e) => {
                      handleOnChange("description", e.target.value);
                    }}
                    errorText={errorMap.description}
                  />
                </div>
              </div>

              <div className="mt-4 ml-auto">
                <CreateButton
                  title={i18n.t("create")}
                  size="medium"
                  onClick={handleCreateBrandLabelOnClick}
                />
              </div>
            </div>
          );
        }
      }
    } else if (selectedLabelType === "PUBLISHING_HOUSE") {
      if (publishingHouseLabelLoading) {
        return (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        );
      } else {
        if (selectedLabelState === 0) {
          return (
            <div className="flex flex-row flex-wrap items-center gap-x-3 my-5">
              {retrieveAvailablePublishingHouseLabelList().map(
                (publishingHouseLabelDTO) =>
                  RenderPublishingHouseLabelItem(publishingHouseLabelDTO, "ADD")
              )}
            </div>
          );
        } else if (selectedLabelState === 1) {
          return (
            <div className="flex flex-col items-center justify-center text-catchup-dark-blue">
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={name}
                    onChange={(e) => {
                      handleOnChange("name", e.target.value);
                    }}
                    errorText={errorMap.name}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("description")}</p>
                </div>
                <div className="flex-1 h-catchup-description">
                  <InputGroup
                    type="textarea"
                    value={description}
                    onChange={(e) => {
                      handleOnChange("description", e.target.value);
                    }}
                    errorText={errorMap.description}
                  />
                </div>
              </div>

              <div className="mt-4 ml-auto">
                <CreateButton
                  title={i18n.t("create")}
                  size="medium"
                  onClick={handleCreatePublishingHouseLabelOnClick}
                />
              </div>
            </div>
          );
        }
      }
    }
  };

  const RenderSelectionTabContent = () => {
    const optionList = [];
    if (personalLabelActionList) {
      optionList.push({
        id: "PERSONAL",
        title: i18n.t("personal"),
      });
    }
    if (userProfile.role === "STAFF" && brandLabelActionList) {
      optionList.push({
        id: "BRAND",
        title: i18n.t("brand"),
      });
    }
    if (
      userInformation.accountType === "GENIXO" &&
      publishingHouseLabelActionList
    ) {
      optionList.push({
        id: "PUBLISHING_HOUSE",
        title: i18n.t("publishing_house_label"),
      });
    }

    return (
      <SelectionTab
        optionList={optionList}
        selectedId={selectedLabelType}
        handleSelectOnClick={(selectedId) => {
          setSelectedLabelType(selectedId);
        }}
      />
    );
  };

  const filteredLabelActionList = filterLabelActionList();
  const filteredBrandLabelActionList = filterBrandLabelActionList();
  const filteredPublishingHouseLabelActionList =
    filterPublishingHouseLabelActionList();

  return (
    <div className="flex-1 flex flex-col">
      <FullCard isShadowed={true}>
        <div className="mb-5">
          {RenderSelectionTabContent()}

          <div className="my-5">
            <div className="my-3">
              <p className="font-semibold">{i18n.t("active_labels")}</p>
            </div>
            <div className="flex flex-row flex-wrap items-center gap-x-3">
              {filteredLabelActionList.map((personalLabelAction) =>
                RenderPersonalLabelItem(personalLabelAction.labelDTO, "REMOVE")
              )}
              {filteredBrandLabelActionList.map((brandLabelAction) =>
                RenderBrandLabelItem(brandLabelAction.brandLabelDTO, "REMOVE")
              )}
              {filteredPublishingHouseLabelActionList.map(
                (publishingHouseLabelAction) =>
                  RenderPublishingHouseLabelItem(
                    publishingHouseLabelAction.publishingHouseLabelDTO,
                    "REMOVE"
                  )
              )}
            </div>
          </div>

          <div className="flex flex-row justify-end">
            <SelectionTab
              optionList={[
                {
                  id: 0,
                  title: i18n.t("label"),
                },
                {
                  id: 1,
                  title: i18n.t("create"),
                },
              ]}
              selectedId={selectedLabelState}
              handleSelectOnClick={(selectedId) => {
                setSelectedLabelState(selectedId);
              }}
            />
          </div>

          {RenderMainContent()}
        </div>
      </FullCard>
    </div>
  );
};

export default SetOrUpsertLabel;
