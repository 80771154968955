import {
  Document,
  Font,
  Text,
  Page,
  PDFViewer,
  View,
} from "@react-pdf/renderer";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import { useEffect, useState } from "react";
import { retrieveExternalExamDTODetailById } from "../../requests/CatchtivityRequests";
import BaseLoading from "../../components/loading/BaseLoading";
import i18n from "../../language/i18n";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
      fontWeight: "normal",
      textDecoration: "none",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
      fontWeight: "bold",
      textDecoration: "none",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
      fontWeight: "normal",
      textDecoration: "underline",
    },
  ],
});

const ExternalExamLearnersPDFViewerScreen = () => {
  const navigate = useNavigate();
  const { externalExamId } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [loading, setLoading] = useState(false);
  const [externalExamDTOInDetail, setExternalExamDTOInDetail] = useState(null);

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("external_exam_list")) {
      navigate("/permission-error");
      return;
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetExternalExamPartDTOList = async () => {
      setLoading(true);
      const { data, err } = await retrieveExternalExamDTODetailById(
        externalExamId
      );
      if (err) {
        console.log(err);
        return;
      }
      setExternalExamDTOInDetail(data);
      setLoading(false);
    };
    if (!externalExamId) return;
    retrieveAndSetExternalExamPartDTOList();
  }, [externalExamId]);

  const RenderExternalPartTitleContent = (
    externalExamActivityInDetailDTO,
    offset
  ) => {
    if (!externalExamActivityInDetailDTO) return;
    const { coterieType, position } = externalExamActivityInDetailDTO;
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: 5,
          marginTop: 5,
        }}
      >
        <View
          style={{
            border: 1,
            paddingVertical: 2,
            paddingHorizontal: 4,
            borderRadius: 5,
          }}
        >
          <Text style={{ fontWeight: "bold" }}>
            {position + offset}.{i18n.t(`${coterieType}_ABBREVATION`)}
          </Text>
        </View>
      </View>
    );
  };

  const RenderLearnerExternalExamPartContent = (
    externalExamActivityLearnerDTO,
    gradeName,
    institutionName,
    externalExamPartInDetailDTO
  ) => {
    if (!externalExamPartInDetailDTO) return;
    const { pageFormat, activityOffset, externalExamActivityInDetailDTOList } =
      externalExamPartInDetailDTO;

    let width = "100%";
    let height = "100%";
    let foundExternalExamPartInDetailDTO_1 = null;
    let foundExternalExamPartInDetailDTO_2 = null;
    let foundExternalExamPartInDetailDTO_3 = null;
    let foundExternalExamPartInDetailDTO_4 = null;
    let foundExternalExamPartInDetailDTO_5 = null;
    let foundExternalExamPartInDetailDTO_6 = null;
    let foundExternalExamPartInDetailDTO_7 = null;
    let foundExternalExamPartInDetailDTO_8 = null;

    if (pageFormat === "ONE_BY_TWO") {
      width = "100%";
      height = "50%";
      foundExternalExamPartInDetailDTO_1 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 1
        );
      foundExternalExamPartInDetailDTO_2 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 2
        );
    } else if (pageFormat === "ONE_BY_THREE") {
      width = "100%";
      height = "33%";
      foundExternalExamPartInDetailDTO_1 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 1
        );
      foundExternalExamPartInDetailDTO_2 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 2
        );
      foundExternalExamPartInDetailDTO_3 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 3
        );
    } else if (pageFormat === "ONE_BY_FOUR") {
      width = "100%";
      height = "25%";
      foundExternalExamPartInDetailDTO_1 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 1
        );
      foundExternalExamPartInDetailDTO_2 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 2
        );
      foundExternalExamPartInDetailDTO_3 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 3
        );
      foundExternalExamPartInDetailDTO_4 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 4
        );
    } else if (pageFormat === "ONE_BY_FIVE") {
      width = "100%";
      height = "20%";
      foundExternalExamPartInDetailDTO_1 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 1
        );
      foundExternalExamPartInDetailDTO_2 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 2
        );
      foundExternalExamPartInDetailDTO_3 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 3
        );
      foundExternalExamPartInDetailDTO_4 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 4
        );
      foundExternalExamPartInDetailDTO_5 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 5
        );
    } else if (pageFormat === "TWO_BY_ONE") {
      width = "50%";
      height = "100%";
      foundExternalExamPartInDetailDTO_1 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 1
        );
      foundExternalExamPartInDetailDTO_2 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 2
        );
    } else if (pageFormat === "TWO_BY_TWO") {
      width = "50%";
      height = "50%";
      foundExternalExamPartInDetailDTO_1 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 1
        );
      foundExternalExamPartInDetailDTO_2 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 2
        );
      foundExternalExamPartInDetailDTO_3 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 3
        );
      foundExternalExamPartInDetailDTO_4 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 4
        );
    } else if (pageFormat === "TWO_BY_THREE") {
      width = "50%";
      height = "33%";
      foundExternalExamPartInDetailDTO_1 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 1
        );
      foundExternalExamPartInDetailDTO_2 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 2
        );
      foundExternalExamPartInDetailDTO_3 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 3
        );
      foundExternalExamPartInDetailDTO_4 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 4
        );
      foundExternalExamPartInDetailDTO_5 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 5
        );
      foundExternalExamPartInDetailDTO_6 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 6
        );
    } else if (pageFormat === "TWO_BY_FOUR") {
      width = "50%";
      height = "25%";
      foundExternalExamPartInDetailDTO_1 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 1
        );
      foundExternalExamPartInDetailDTO_2 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 2
        );
      foundExternalExamPartInDetailDTO_3 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 3
        );
      foundExternalExamPartInDetailDTO_4 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 4
        );
      foundExternalExamPartInDetailDTO_5 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 5
        );
      foundExternalExamPartInDetailDTO_6 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 6
        );
      foundExternalExamPartInDetailDTO_7 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 7
        );
      foundExternalExamPartInDetailDTO_8 =
        externalExamActivityInDetailDTOList.find(
          (externalExamPartInDetailDTO) =>
            externalExamPartInDetailDTO.position === 8
        );
    }

    const { firstName, lastName, externalLearnerId, userId, userProfileId } =
      externalExamActivityLearnerDTO;
    return (
      <View
        style={{
          height: "100vh",
          paddingVertical: 30,
          paddingHorizontal: 15,
        }}
      >
        <View
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <View style={{ display: "flex", flexDirection: "column" }}>
            <View style={{ display: "flex", flexDirection: "column" }}>
              <Text style={{ fontWeight: "bold" }}>
                {gradeName} / {institutionName}
              </Text>
              <Text style={{ fontWeight: "bold" }}>
                {firstName} {lastName}
              </Text>
            </View>
          </View>
          <View style={{ display: "flex" }}>
            <Text style={{ fontWeight: "bold", fontSize: 14 }}>
              #
              {externalLearnerId
                ? externalLearnerId
                : `${userId}-${userProfileId}`}
            </Text>
          </View>
        </View>
        <View style={{ flex: 1, flexDirection: "row", flexWrap: "wrap" }}>
          {pageFormat === "ONE_BY_TWO" || pageFormat === "TWO_BY_ONE" ? (
            <>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_1,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_2,
                  activityOffset
                )}
              </View>
            </>
          ) : null}
          {pageFormat === "ONE_BY_THREE" ? (
            <>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_1,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_2,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_3,
                  activityOffset
                )}
              </View>
            </>
          ) : null}
          {pageFormat === "ONE_BY_FOUR" || pageFormat === "TWO_BY_TWO" ? (
            <>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_1,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_2,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_3,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_4,
                  activityOffset
                )}
              </View>
            </>
          ) : null}
          {pageFormat === "ONE_BY_FIVE" ? (
            <>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_1,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_2,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_3,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_4,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_5,
                  activityOffset
                )}
              </View>
            </>
          ) : null}
          {pageFormat === "TWO_BY_THREE" ? (
            <>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_1,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_2,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_3,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_4,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_5,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_6,
                  activityOffset
                )}
              </View>
            </>
          ) : null}
          {pageFormat === "TWO_BY_FOUR" ? (
            <>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_1,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_2,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_3,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_4,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_5,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_6,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_7,
                  activityOffset
                )}
              </View>
              <View
                style={{
                  width,
                  height,
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
                {RenderExternalPartTitleContent(
                  foundExternalExamPartInDetailDTO_8,
                  activityOffset
                )}
              </View>
            </>
          ) : null}
        </View>
      </View>
    );
  };

  const RenderExternalExamPartContent = (
    externalExamActivityLearnerDTO,
    gradeName,
    institutionName,
    externalExamPartInDetailDTOList
  ) => {
    return externalExamPartInDetailDTOList.map(
      (externalExamPartInDetailDTO, index) => {
        return (
          <View key={index}>
            {RenderLearnerExternalExamPartContent(
              externalExamActivityLearnerDTO,
              gradeName,
              institutionName,
              externalExamPartInDetailDTO
            )}
          </View>
        );
      }
    );
  };

  const RenderExternalExamContent = () => {
    if (!externalExamDTOInDetail) return;
    console.log(externalExamDTOInDetail);
    const {
      externalExamActivityLearnerDTOList,
      externalExamPartInDetailDTOList,
      gradeName,
      institutionName,
    } = externalExamDTOInDetail;

    return externalExamActivityLearnerDTOList.map(
      (externalExamActivityLearnerDTO, index) => {
        return (
          <View key={index}>
            {RenderExternalExamPartContent(
              externalExamActivityLearnerDTO,
              gradeName,
              institutionName,
              externalExamPartInDetailDTOList
            )}
          </View>
        );
      }
    );
  };

  return (
    <div className="flex-1 flex flex-col h-full">
      <div className="m-4 h-full">
        {loading ? (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <PDFViewer className="h-full w-full">
            <Document>
              <Page
                size="A4"
                style={{
                  backgroundColor: "#ffffff",
                  fontFamily: "Roboto",
                  fontSize: 10,
                }}
              >
                {RenderExternalExamContent()}
              </Page>
            </Document>
          </PDFViewer>
        )}
      </div>
    </div>
  );
};

export default ExternalExamLearnersPDFViewerScreen;
