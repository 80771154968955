import { useCallback, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import FullCard from "../../components/cards/FullCard";
import InputGroup from "../../components/groups/InputGroup";
import AdvancedTable from "../../components/tables/AdvancedTable";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  createGradeBySeasonId,
  deleteGrade,
  patchGrade,
  queryActiveSeasonListByBrandId,
  queryActiveSeasonListByCampusId,
  queryActiveSeasonListByInstitutionId,
  queryAllGradeDTOPageByFilter,
} from "../../requests/ManagementRequests";
import {
  filterGradeLevelOptionList,
  retrieveSeasonDTOOptionList,
} from "../../utilization/ManagementUtilization";
import {
  checkDeletePermissionGranted,
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import debounce from "lodash.debounce";
import CreateButton from "../../components/buttons/CreateButton";

const GradeListScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileSeason,
  } = useAuth();
  const [gradeDTOList, setGradeDTOList] = useState([]);
  const [seasonDTOList, setSeasonDTOList] = useState([]);
  const [pageState, setPageState] = useState(0);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedSeasonId, setSelectedSeasonId] = useState("DEFAULT_OPTION");
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterTextContains, setFilterTextContains] = useState(null);
  const [filterActive, setFilterActive] = useState(true);
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [debounceState, setDebounceState] = useState("");
  const [errorMap, setErrorMap] = useState({
    name: null,
    description: null,
    level: null,
    seasonId: null,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (userProfileSeason) {
      setSelectedSeasonId(userProfileSeason.id);
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetSeasonDTOListByInstitutionId = async () => {
      const { data, err } = await queryActiveSeasonListByInstitutionId(
        userProfileInstitution.id
      );
      if (err) {
        console.log(err);
      } else {
        setSeasonDTOList(data);
      }
    };
    const retrieveAndSetSeasonDTOListByCampusId = async () => {
      const { data, err } = await queryActiveSeasonListByCampusId(
        userProfileCampus.id
      );
      if (err) {
        console.log(err);
      } else {
        setSeasonDTOList(data);
      }
    };
    const retrieveAndSetSeasonDTOListByBrandId = async () => {
      const { data, err } = await queryActiveSeasonListByBrandId(
        userProfileBrand.id
      );
      if (err) {
        console.log(err);
      } else {
        setSeasonDTOList(data);
      }
    };
    if (checkViewPermissionGranted("grade_list")) {
      if (userProfileSeason) {
        setSeasonDTOList([userProfileSeason]);
      } else if (userProfileInstitution) {
        retrieveAndSetSeasonDTOListByInstitutionId();
      } else if (userProfileCampus) {
        retrieveAndSetSeasonDTOListByCampusId();
      } else if (userProfileBrand) {
        retrieveAndSetSeasonDTOListByBrandId();
      }
    } else {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    retrieveAndSetGradePageByFilter();
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    setDebounceState("BASE_FILTER");
    retrieveAndSetGradePageByFilter();
  }, [
    isFilterApplied,
    filterBeginAt,
    filterEndAt,
    filterActive,
    filterShowDeleted,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    if (filterTextContains) {
      setDebounceState("TEXT_CONTAINS");
      debouncedRetrieveAndSetGradePageByFilterDebounce(filterTextContains);
    } else {
      retrieveAndSetGradePageByFilter();
    }
  }, [filterTextContains]);

  const retrieveAndSetGradePageByFilter = async () => {
    setTableLoading(true);
    const { data, err } = await queryAllGradeDTOPageByFilter(pagedQueryParams);
    if (err) {
      console.log(err);
      return;
    }
    setTotalItemCount(data.totalItemCount);
    setGradeDTOList(data.itemList);
    setTableLoading(false);
  };

  const retrieveAndSetGradePageByFilterDebounce = async (
    filterTextContains
  ) => {
    setTableLoading(true);
    if (isFilterApplied) {
      pagedQueryParams.params.textContains = filterTextContains.trim();
    }
    const { data, err } = await queryAllGradeDTOPageByFilter(pagedQueryParams);
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setGradeDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const debouncedRetrieveAndSetGradePageByFilterDebounce = useCallback(
    debounce(function (textContains) {
      retrieveAndSetGradePageByFilterDebounce(textContains);
    }, 1000),
    [debounceState]
  );

  const filterGradeLevelOptionListBySeasonDTO = () => {
    const foundSeasonDTO = seasonDTOList.find(
      (season) => parseFloat(season.id) === parseFloat(selectedSeasonId)
    );
    return filterGradeLevelOptionList(foundSeasonDTO.institutionDTO, null);
  };

  const checkCommonValidation = () => {
    if (
      !name ||
      name.trim() === "" ||
      !description ||
      description.trim() === "" ||
      selectedSeasonId === "DEFAULT_OPTION" ||
      selectedLevel === "DEFAULT_OPTION"
    ) {
      const currentErrorMap = {
        name: null,
        description: null,
        seasonId: null,
      };
      if (!name || name.trim() === "") {
        currentErrorMap.name = i18n.t("name_required_field");
      }
      if (!description || description.trim() === "") {
        currentErrorMap.description = i18n.t("description_required_field");
      }
      if (selectedSeasonId === "DEFAULT_OPTION") {
        currentErrorMap.seasonId = i18n.t("season_required_field");
      }
      if (selectedLevel === "DEFAULT_OPTION") {
        currentErrorMap.level = i18n.t("level_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const checkCanNavigate = () => {
    let canNavigate = true;
    if (!checkViewPermissionGranted("grade_list")) {
      canNavigate = false;
    }
    return canNavigate;
  };

  const constructNavigatableList = () => {
    let navigateList = [];
    if (checkViewPermissionGranted("grade_list")) {
      navigateList.push({
        id: 0,
        text: i18n.t("to_grade_detail"),
        icon: (
          <BaseImage
            src="/icons/navigate-gray.png"
            alt="navigate"
            size="small"
          />
        ),
        value: "/grades/{id}/detail",
      });
    }
    return navigateList;
  };

  const constructPagedQueryParams = () => {
    return {
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        beginAt:
          isFilterApplied && filterBeginAt
            ? new Date(filterBeginAt).getTime()
            : null,
        endAt:
          isFilterApplied && filterEndAt
            ? new Date(filterEndAt).getTime()
            : null,
        textContains:
          isFilterApplied && filterTextContains ? filterTextContains : null,
        deleted: isFilterApplied ? (filterShowDeleted ? null : false) : false,
        brandId: userProfileBrand.id,
        campusId: userProfileCampus?.id,
        institutionId: userProfileInstitution?.id,
        seasonId: userProfileSeason?.id,
        isActive: isFilterApplied ? filterActive : true,
      },
    };
  };

  const resetParams = () => {
    setId(null);
    setName(null);
    setDescription(null);
    setSelectedSeasonId("DEFAULT_OPTION");
    setSelectedLevel(null);
  };

  const handleOnChange = (type, value) => {
    if (type === "name") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: i18n.t("name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: null,
        }));
      }
      setName(value);
    } else if (type === "description") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: i18n.t("description_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: null,
        }));
      }
      setDescription(value);
    } else if (type === "level") {
      setSelectedLevel(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        level: null,
      }));
    } else if (type === "seasonId") {
      setSelectedSeasonId(value);
      setSelectedLevel("DEFAULT_OPTION");
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        seasonId: null,
      }));
    } else {
    }
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "textContains") {
      setFilterTextContains(value);
    } else if (type === "active") {
      setFilterActive(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleToCreateGradeOnClick = () => {
    setId(null);
    setPageState(1);
  };

  const handleToUpdateGradeOnClick = (gradeDTO) => {
    setId(gradeDTO.id);
    setName(gradeDTO.name);
    setDescription(gradeDTO.description);
    setSelectedLevel(gradeDTO.level);
    setSelectedSeasonId(gradeDTO.seasonDTO?.id);
    setPageState(1);
  };

  const handleUpdateGradeOnClick = async () => {
    if (checkUpdatePermissionGranted("grade_list")) {
      if (checkCommonValidation()) {
        const currentGrade = {
          id,
          name,
          description,
          level: selectedLevel,
          seasonId: selectedSeasonId,
        };
        const { data, err } = await patchGrade(currentGrade);
        if (err) {
          console.log(err);
        } else {
          retrieveAndSetGradePageByFilter();
          handleReturnFromGradeOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleCreateGradeOnClick = async () => {
    if (checkInsertPermissionGranted("grade_list")) {
      if (checkCommonValidation()) {
        const newGrade = {
          name,
          description,
          level: selectedLevel,
          season: {
            id: selectedSeasonId,
          },
        };
        const { data, err } = await createGradeBySeasonId(newGrade);
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          retrieveAndSetGradePageByFilter();
          handleReturnFromGradeOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleRemoveGradeOnClick = async (gradeId) => {
    if (checkDeletePermissionGranted("grade_list")) {
      const { err } = await deleteGrade(gradeId);
      if (err) {
        console.log(err);
        if (err?.response?.data) {
          NotificationManager.error(i18n.t(err.response.data));
        }
      } else {
        const foundGradeDTO = gradeDTOList.find(
          (gradeDTO) => gradeDTO.id === gradeId
        );
        foundGradeDTO.deleted = true;
        setGradeDTOList(
          JSON.parse(JSON.stringify(gradeDTOList.sort((a, b) => b.id - a.id)))
        );
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_delete"));
    }
  };

  const handleReturnFromGradeOnClick = () => {
    resetParams();
    setPageState(0);
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={i18n.t("grade_list")}
            description={i18n.t("grade_list_table_description_text")}
            itemName={i18n.t("grade")}
            headerList={[
              { name: i18n.t("name"), key: "name", type: "info" },
              { name: i18n.t("level"), key: "level" },
              { name: i18n.t("season_name"), key: "seasonDTO.name" },
              {
                name: i18n.t("institution_name"),
                key: "seasonDTO.institutionDTO.name",
              },
              {
                name: i18n.t("created_at"),
                key: "createdAt",
                type: "datetime",
              },
            ]}
            selectable={false}
            showFilter={true}
            showItemCount={selectedShowItemCount}
            totalItemCount={totalItemCount}
            pageNumber={pageNumber}
            loading={tableLoading}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleOnFilterChange={handleOnFilterChange}
            handleOnTableParamsChange={handleOnTableParamsChange}
            filterParams={{
              isFilterApplied,
              showBeginDate: true,
              beginAt: filterBeginAt,
              showEndDate: true,
              endAt: filterEndAt,
              showTextContains: true,
              textContains: filterTextContains,
              showActive: true,
              active: filterActive,
              showDeleted: filterShowDeleted,
            }}
            itemList={gradeDTOList}
            insertable={checkInsertPermissionGranted("grade_list")}
            handleInsertOnClick={handleToCreateGradeOnClick}
            updatable={checkUpdatePermissionGranted("grade_list")}
            handleUpdateOnClick={handleToUpdateGradeOnClick}
            deletable={checkDeletePermissionGranted("grade_list")}
            handleDeleteOnClick={handleRemoveGradeOnClick}
            navigatable={checkCanNavigate()}
            navigateOptionList={constructNavigatableList()}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <FullCard isShadowed={true}>
            <div className="">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      handleReturnFromGradeOnClick();
                    }}
                  />
                </div>
                <BaseTitle
                  title={id ? i18n.t("update_grade") : i18n.t("create_grade")}
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <DividerLine />

              <div className="w-full">
                <p className="font-semibold text-lg">
                  {i18n.t("grade_information")}
                </p>
              </div>

              <div className="w-full flex flex-row items-center gap-x-2 my-2">
                <BaseImage src="/icons/info.png" alt="info" size="small" />
                <div className="flex-1">
                  <p className="">
                    {i18n.t("grade_name_detailed_explaination_text")}
                  </p>
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={name}
                    onChange={(e) => {
                      handleOnChange("name", e.target.value);
                    }}
                    errorText={errorMap.name}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("description")}</p>
                </div>
                <div className="flex-1 h-catchup-description">
                  <InputGroup
                    type="textarea"
                    value={description}
                    onChange={(e) => {
                      handleOnChange("description", e.target.value);
                    }}
                    errorText={errorMap.description}
                  />
                </div>
              </div>

              {userProfile.role === "STAFF" ? (
                <div className="w-full flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("season_name")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="select"
                      value={selectedSeasonId}
                      optionList={retrieveSeasonDTOOptionList(seasonDTOList)}
                      onChange={(event) => {
                        handleOnChange("seasonId", event.target.value);
                      }}
                      errorText={errorMap.seasonId}
                    />
                  </div>
                </div>
              ) : null}

              {selectedSeasonId !== "DEFAULT_OPTION" ? (
                <div className="w-full flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("grade_level")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="select"
                      value={selectedLevel}
                      optionList={filterGradeLevelOptionListBySeasonDTO()}
                      onChange={(event) => {
                        handleOnChange("level", event.target.value);
                      }}
                      errorText={errorMap.level}
                    />
                  </div>
                </div>
              ) : null}

              <div className="mt-4 ml-auto">
                {id ? (
                  <SecondaryButton
                    title={i18n.t("update")}
                    size="medium"
                    onClick={handleUpdateGradeOnClick}
                  />
                ) : (
                  <CreateButton
                    title={i18n.t("create")}
                    size="medium"
                    onClick={handleCreateGradeOnClick}
                  />
                )}
              </div>
            </div>
          </FullCard>
        </div>
      </div>
    );
  };

  const pagedQueryParams = constructPagedQueryParams();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default GradeListScreen;
