import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import i18n from "../../language/i18n";
import { searchUserListByParams } from "../../requests/ManagementRequests";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import SecondaryButton from "../buttons/SecondaryButton";
import FullCard from "../cards/FullCard";
import InputGroup from "../groups/InputGroup";
import { useAuth } from "../../context/UserContextProvider";
import CreateUserProfile from "./../user-profiles/CreateUserProfile";
import {
  retrieveGenderOptionList,
  retrievePhoneNumberAreaCodeList,
} from "../../utilization/ManagementUtilization";
import DividerLine from "../divider/DividerLine";
import BaseModal from "../modal/BaseModal";
import BaseImage from "../images/BaseImage";
import BaseTitle from "../titles/BaseTitle";
import CreateButton from "../buttons/CreateButton";
import CancelButton from "../buttons/CancelButton";
import PrimaryButton from "../buttons/PrimaryButton";

const CustomCreateUser = () => {
  const navigate = useNavigate();
  const { language } = useAuth();
  const [pageState, setPageState] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [identityNumber, setIdentityNumber] = useState("");
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [gender, setGender] = useState("DEFAULT_OPTION");
  const [foundUserList, setFoundUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [showSelectUserModal, setShowSelectUserModal] = useState(false);
  const [errorMap, setErrorMap] = useState({
    firstName: null,
    lastName: null,
    gender: null,
  });
  const countryName = "TURKEY";

  const retrievePhoneNumberAreaCode = () => {
    if (countryName !== "DEFAULT_OPTION") {
      return retrievePhoneNumberAreaCodeList().find(
        (phoneNumberAreaCode) => phoneNumberAreaCode.parent === countryName
      ).value;
    }
    return null;
  };

  const checkContinueCommonValidation = () => {
    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !(dateOfBirth.trim() || phoneNumber.trim() || identityNumber.trim())
    ) {
      const currentErrorMap = {
        firstName: null,
        lastName: null,
      };
      if (
        !(dateOfBirth.trim() || phoneNumber.trim() || identityNumber.trim())
      ) {
        NotificationManager.error(
          i18n.t("date_of_birth_phone_number_identity_number_required")
        );
      }
      if (!firstName || firstName.trim() === "") {
        currentErrorMap.firstName = i18n.t("first_name_required_field");
      }
      if (!lastName || lastName.trim() === "") {
        currentErrorMap.lastName = i18n.t("last_name_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const constructUser = () => {
    if (selectedUser.id) {
      return selectedUser;
    } else {
      return {
        id: null,
        username: username?.trim(),
        password: password?.trim(),
        email: email?.trim(),
        firstName: firstName?.trim(),
        lastName: lastName?.trim(),
        phoneNumber: phoneNumber ? phoneNumber.trim() : "5555555555",
        dateOfBirth: dateOfBirth
          ? new Date(dateOfBirth).getTime() -
            60000 * new Date(dateOfBirth).getTimezoneOffset()
          : 631152000000,
        identityNumber: identityNumber ? identityNumber.trim() : "11111111111",
        gender,
        accountType: "USER",
      };
    }
  };

  const handleOnChange = (type, value) => {
    if (type === "username") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          username: i18n.t("username_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          username: null,
        }));
      }
      setUsername(value);
    } else if (type === "password") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          password: i18n.t("password_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          password: null,
        }));
      }
      setPassword(value);
    } else if (type === "email") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          email: i18n.t("email_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          email: null,
        }));
      }
      setEmail(value);
    } else if (type === "firstName") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          firstName: i18n.t("first_name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          firstName: null,
        }));
      }
      setFirstName(value);
    } else if (type === "lastName") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          lastName: i18n.t("last_name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          lastName: null,
        }));
      }
      setLastName(value);
    } else if (type === "phoneNumber") {
      setPhoneNumber(value);
    } else if (type === "dateOfBirth") {
      setDateOfBirth(value);
    } else if (type === "gender") {
      setGender(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        gender: null,
      }));
    } else if (type === "identityNumber") {
      setIdentityNumber(value);
    }
  };

  const handleContinueButtonOnClick = async () => {
    if (checkViewPermissionGranted("user_list")) {
      if (checkContinueCommonValidation()) {
        const { data, err } = await searchUserListByParams({
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          dateOfBirth: dateOfBirth ? new Date(dateOfBirth).getTime() : null,
          phoneNumber: phoneNumber ? phoneNumber.trim() : null,
          identityNumber: identityNumber ? identityNumber.trim() : null,
        });
        if (err) {
          console.log(err);
        } else {
          setFoundUserList(data);
          setShowSelectUserModal(true);
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_query"));
    }
  };

  const handleAfterCreateOnClick = (data) => {
    const { role } = data;
    if (role === "STAFF") {
      navigate(`/staff-users`);
    } else if (role === "LEARNER") {
      navigate(`/learner-users`);
    } else if (role === "CONTENT_CREATOR") {
      navigate("/content-creator-users");
    }
  };

  const RenderSingleUserProperty = (key, value) => {
    return (
      <div className="flex flex-row items-center w-1/3">
        <p className="font-semibold px-2">{key}:</p>
        <p className="">{value}</p>
      </div>
    );
  };

  const RenderSingleUser = (user, handleOnClick) => {
    if (user.id) {
      return (
        <div
          className="flex flex-row flex-wrap p-5 my-2 border rounded-catchup-xlarge border-catchup-gray-100 cursor-pointer hover:border-catchup-blue-500 transition-all duration-300"
          onClick={handleOnClick}
        >
          {RenderSingleUserProperty(i18n.t("username"), user.username)}
          {RenderSingleUserProperty(
            i18n.t("password"),
            i18n.t("uses_own_user_password")
          )}
          {RenderSingleUserProperty(i18n.t("email"), user.email)}
          {RenderSingleUserProperty(i18n.t("first_name"), user.firstName)}
          {RenderSingleUserProperty(i18n.t("last_name"), user.lastName)}
          {RenderSingleUserProperty(i18n.t("gender"), i18n.t(user.gender))}
          {RenderSingleUserProperty(i18n.t("phone_number"), user.phoneNumber)}
          {RenderSingleUserProperty(
            i18n.t("date_of_birth"),
            new Date(user.dateOfBirth).toLocaleDateString(language)
          )}
          {RenderSingleUserProperty(
            i18n.t("created_at"),
            new Date(user.createdAt).toLocaleDateString(language)
          )}
        </div>
      );
    } else {
      return (
        <div className="w-full">
          <div className="flex flex-col items-center justify-center text-catchup-dark-blue">
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("username")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="text"
                  value={username}
                  onChange={(e) => {
                    handleOnChange("username", e.target.value);
                  }}
                  errorText={errorMap.username}
                />
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("password")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="text"
                  value={password}
                  onChange={(e) => {
                    handleOnChange("password", e.target.value);
                  }}
                  errorText={errorMap.password}
                />
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("email")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="text"
                  value={email}
                  onChange={(e) => {
                    handleOnChange("email", e.target.value);
                  }}
                  errorText={errorMap.email}
                />
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("first_name")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="text"
                  value={firstName}
                  disabled={true}
                  onChange={(e) => {
                    handleOnChange("firstName", e.target.value);
                  }}
                  errorText={errorMap.firstName}
                />
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("last_name")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="text"
                  value={lastName}
                  disabled={true}
                  onChange={(e) => {
                    handleOnChange("lastName", e.target.value);
                  }}
                  errorText={errorMap.lastName}
                />
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("date_of_birth")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="date"
                  value={dateOfBirth}
                  disabled={true}
                  onChange={(e) => {
                    handleOnChange("dateOfBirth", e.target.value);
                  }}
                  errorText={errorMap.dateOfBirth}
                />
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("gender")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={gender}
                  optionList={retrieveGenderOptionList()}
                  onChange={(e) => {
                    handleOnChange("gender", e.target.value);
                  }}
                  errorText={errorMap.gender}
                />
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("phone_number")}</p>
              </div>
              <div className="flex-1 flex flex-row items-center">
                <div className="mr-2">
                  <p>({retrievePhoneNumberAreaCode()})</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="number"
                    value={phoneNumber}
                    disabled={true}
                    onChange={(e) => {
                      handleOnChange("phoneNumber", e.target.value);
                    }}
                    errorText={errorMap.phoneNumber}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("identity_number")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="number"
                  value={identityNumber}
                  disabled={true}
                  onChange={(e) => {
                    handleOnChange("identityNumber", e.target.value);
                  }}
                  errorText={errorMap["identityNumber"]}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const RenderSelectUserModal = () => {
    return (
      <BaseModal
        isOpen={showSelectUserModal}
        size={foundUserList.length === 0 ? "small" : null}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowSelectUserModal(false);
        }}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            <div className="">
              {foundUserList.length > 0 ? (
                <div className="flex flex-col my-3">
                  {foundUserList.map((user) =>
                    RenderSingleUser(user, () => {
                      setSelectedUser(user);
                      setPageState(1);
                      setShowSelectUserModal(false);
                    })
                  )}
                  <div className="flex flex-row items-center justify-between mt-10">
                    <div className="flex flex-row items-center w-[600px]">
                      <div className="mr-10">
                        <BaseImage
                          src="/icons/exclamation-red.png"
                          alt="exclamation-red"
                          size="large"
                        />
                      </div>
                      <p className="italic text-xl">
                        {i18n.t("search_user_found_but_create_new_one_text")}
                      </p>
                    </div>
                    <div className="ml-3">
                      <CreateButton
                        title={i18n.t("create")}
                        size="medium"
                        onClick={() => {
                          setSelectedUser({
                            id: null,
                            username: null,
                            password: null,
                            firstName,
                            lastName,
                            dateOfBirth,
                            phoneNumber,
                            email: null,
                            gender: "DEFAULT_OPTION",
                          });
                          setPageState(1);
                          setShowSelectUserModal(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center gap-y-4">
                  <BaseImage
                    src="/icons/exclamation-red.png"
                    alt="exclamation-red"
                    size="large"
                  />
                  <div className="w-[300px] text-center">
                    <p className="text-xl">
                      {i18n.t("search_user_not_found_text")}
                    </p>
                  </div>
                  <div className="flex flex-row items-center justify-center gap-x-4">
                    <CancelButton
                      title={i18n.t("cancel")}
                      size="small"
                      onClick={() => {
                        setShowSelectUserModal(false);
                      }}
                    />
                    <PrimaryButton
                      title={i18n.t("create")}
                      size="small"
                      onClick={() => {
                        setSelectedUser({
                          id: null,
                          username: null,
                          password: null,
                          firstName,
                          lastName,
                          dateOfBirth,
                          phoneNumber,
                          email: null,
                          gender: "DEFAULT_OPTION",
                        });
                        setPageState(1);
                        setShowSelectUserModal(false);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}></div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <>
            <DividerLine />
            <div className="w-full">
              <p className="font-semibold text-lg">
                {i18n.t("user_information")}
              </p>
            </div>
            {RenderSingleUser(selectedUser, () => {})}
            {selectedUser ? (
              <CreateUserProfile
                showTitle={false}
                useCardView={false}
                handleAfterUpdateOnClick={() => {}}
                handleAfterCreateOnClick={handleAfterCreateOnClick}
                handleReturnOnClick={null}
                user={constructUser()}
                selectedUserProfile={null}
              />
            ) : null}
          </>
        </div>
      </div>
    );
  };

  return (
    <FullCard isShadowed={true}>
      {RenderSelectUserModal()}
      <div className="">
        <div className="flex flex-row items-center gap-x-2">
          <div className="cursor-pointer">
            <BaseImage
              size="medium"
              src="/icons/long-arrow-left.png"
              alt="long-arrow-left"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <BaseTitle title={i18n.t("create_custom_user")} />
        </div>
      </div>
      <div className={`${pageState === 0 ? "block" : "hidden"}`}>
        <div className="flex flex-col items-center justify-center text-catchup-dark-blue">
          <DividerLine />
          <div className="w-full">
            <p className="font-semibold text-lg">
              {i18n.t("search_information")}
            </p>
          </div>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("first_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="text"
                value={firstName}
                disabled={pageState !== 0}
                onChange={(e) => {
                  handleOnChange("firstName", e.target.value);
                }}
                errorText={errorMap["firstName"]}
              />
            </div>
          </div>

          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("last_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="text"
                value={lastName}
                disabled={pageState !== 0}
                onChange={(e) => {
                  handleOnChange("lastName", e.target.value);
                }}
                errorText={errorMap["lastName"]}
              />
            </div>
          </div>

          <DividerLine />

          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("date_of_birth")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="date"
                value={dateOfBirth}
                disabled={pageState !== 0}
                onChange={(e) => {
                  handleOnChange("dateOfBirth", e.target.value);
                }}
                errorText={errorMap["dateOfBirth"]}
              />
            </div>
          </div>

          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("phone_number")}</p>
            </div>
            <div className="flex-1 flex flex-row items-center">
              <div className="mr-2">
                <p>({retrievePhoneNumberAreaCode()})</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="number"
                  value={phoneNumber}
                  disabled={pageState !== 0}
                  onChange={(e) => {
                    handleOnChange("phoneNumber", e.target.value);
                  }}
                  errorText={errorMap.phoneNumber}
                />
              </div>
            </div>
          </div>

          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("identity_number")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="number"
                value={identityNumber}
                disabled={pageState !== 0}
                onChange={(e) => {
                  handleOnChange("identityNumber", e.target.value);
                }}
                errorText={errorMap["identityNumber"]}
              />
            </div>
          </div>

          <div className="mt-4 ml-auto">
            <SecondaryButton
              title={i18n.t("continue")}
              size="small"
              onClick={handleContinueButtonOnClick}
            />
          </div>
        </div>
      </div>
      {RenderMainContent()}
    </FullCard>
  );
};

export default CustomCreateUser;
