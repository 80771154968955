import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Server
 */
export const checkFileConverterService = async () => {
  try {
    const response = await axios
      .create()
      .get(
        `${connection.base["file-converter"].url}${endpoints["file-converter"].context}${endpoints["file-converter"].actuator.health.context}`
      );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * File Converter
 */
export const convertFileAndUpload = async (convertFile) => {
  try {
    const response = await axios.post(
      `${connection.base["file-converter"].url}${endpoints["file-converter"].context}${endpoints["file-converter"]["convert-file-and-upload"].context}`,
      convertFile
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryConvertedFileList = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base["file-converter"].url}${endpoints["file-converter"].context}${endpoints["file-converter"].query.context}`,
      queryParams,
      { validateStatus: (status) => true }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
