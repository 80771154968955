import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../../context/AppContextProvider";
import {
  createUserImportFromK12,
  retrieveAllK12StudentListByOrganizationId,
  retrieveBranchById,
  searchListWithStateChangeUsernameByParams,
} from "../../../requests/ManagementRequests";
import FullCard from "../../../components/cards/FullCard";
import BaseImage from "../../../components/images/BaseImage";
import BaseTitle from "../../../components/titles/BaseTitle";
import i18n from "../../../language/i18n";
import InputGroup from "../../../components/groups/InputGroup";
import DividerLine from "../../../components/divider/DividerLine";
import { useAuth } from "../../../context/UserContextProvider";
import { convertTurkishCharactersToEnglish } from "../../../utilization/FunctionUtilization";
import AdvancedTable from "../../../components/tables/AdvancedTable";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import BaseLoading from "../../../components/loading/BaseLoading";

const TransferLearnerFromK12Screen = () => {
  const { branchId } = useParams();
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const { language, userInformation } = useAuth();
  const [branchDTO, setBranchDTO] = useState(null);
  const [k12OrganizationId, setK12OrganizationId] = useState(null);
  const [k12BranchList, setK12BranchList] = useState([]);
  const [k12StudentList, setK12StudentList] = useState([]);
  const [loadingMap, setLoadingMap] = useState({
    branch: true,
    userStateList: true,
    k12StudentList: true,
  });
  const [selectedBranchName, setSelectedBranchName] =
    useState("DEFAULT_OPTION");
  const [userStateList, setUserStateList] = useState([]);
  const [invalidCheckSelectedItemCount, setInvalidCheckSelectedItemCount] =
    useState(25);
  const [invalidCheckPageNumber, setInvalidCheckPageNumber] = useState(0);
  const [invalidCheckSortKey, setInvalidCheckSortKey] = useState("row");
  const [invalidCheckSortDirection, setInvalidCheckSortDirection] =
    useState("ASC");
  const [
    notAcceptingCheckSelectedItemCount,
    setNotAcceptingCheckSelectedItemCount,
  ] = useState(25);
  const [notAcceptingCheckPageNumber, setNotAcceptingCheckPageNumber] =
    useState(0);
  const [notAcceptingCheckSortKey, setNotAcceptingCheckSortKey] =
    useState("lastName");
  const [notAcceptingCheckSortDirection, setNotAcceptingCheckSortDirection] =
    useState("ASC");
  const [acceptingCheckSelectedItemCount, setAcceptingCheckSelectedItemCount] =
    useState(25);
  const [acceptingCheckPageNumber, setAcceptingCheckPageNumber] = useState(0);
  const [acceptingCheckSortKey, setAcceptingCheckSortKey] =
    useState("lastName");
  const [acceptingCheckSortDirection, setAcceptingCheckSortDirection] =
    useState("ASC");

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const { accountType } = userInformation;
    if (accountType !== "GENIXO") navigate("/");
  }, [userInformation]);

  useEffect(() => {
    if (!branchId) navigate("/");
    retrieveAndSetBranchById();
  }, [branchId]);

  useEffect(() => {
    if (!branchDTO) return;
    const { gradeDTO } = branchDTO;
    const { seasonDTO } = gradeDTO;
    const { institutionDTO } = seasonDTO;
    const { institutionSettingsDTO } = institutionDTO;
    const { institutionIntegrationSettingsDTO } = institutionSettingsDTO;
    const { k12Id } = institutionIntegrationSettingsDTO;
    setK12OrganizationId(k12Id);
  }, [branchDTO]);

  useEffect(() => {
    if (!k12OrganizationId) return;
    retrieveAndSetK12BranchList();
  }, [k12OrganizationId]);

  useEffect(() => {
    setSelectedBranchName("DEFAULT_OPTION");
    if (k12StudentList.length === 0) {
      setK12BranchList([]);
    } else {
      const k12BranchSet = new Set();
      for (const k12Student of k12StudentList) {
        const { enrollment: k12Enrollment } = k12Student;
        if (!k12Enrollment) continue;
        const { homeroom: k12Homeroom } = k12Enrollment;
        if (!k12Homeroom) continue;
        k12BranchSet.add(k12Homeroom);
      }
      setK12BranchList(
        Array.from(k12BranchSet).sort((a, b) => a.localeCompare(b))
      );
    }
  }, [k12StudentList]);

  useEffect(() => {
    const retrieveAndCheckWithState = async () => {
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        userStateList: true,
      }));
      const queryParams = filteredK12StudentList.map((item) => {
        const { fullName, dateOfBirth, nationalID, phoneNumber } = item;
        const { username, firstName, lastName } =
          constructUserParamsFromFullName(fullName);
        return {
          username,
          firstName,
          lastName,
          dateOfBirth,
          phoneNumber,
          identityNumber: nationalID,
        };
      });
      const { data, err } = await searchListWithStateChangeUsernameByParams(
        branchDTO.gradeDTO.seasonDTO.id,
        queryParams
      );
      if (err) {
        console.log(err);
        return;
      }
      setUserStateList(data);
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        userStateList: false,
      }));
    };
    if (!branchDTO) return;
    if (selectedBranchName === "DEFAULT_OPTION") return;
    if (filteredInvalidK12UserList.length !== 0) return;
    retrieveAndCheckWithState();
  }, [branchDTO, selectedBranchName]);

  const retrieveAndSetBranchById = async () => {
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      branch: true,
    }));
    const { data, err } = await retrieveBranchById(branchId);
    if (err) {
      console.log(err);
      return;
    }
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      branch: false,
    }));
    setBranchDTO(data);
  };

  const retrieveAndSetK12BranchList = async () => {
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      k12StudentList: true,
    }));
    const { data, err } = await retrieveAllK12StudentListByOrganizationId(
      k12OrganizationId
    );
    if (err) {
      console.log(err);
      return;
    }
    const { gradeDTO } = branchDTO;
    const { level } = gradeDTO;
    const convertedK12StudentList = data.map((item) => {
      const { birthDate, gender, emails, phoneNumbers, ...rest } = item;
      return {
        dateOfBirth: birthDate
          ? new Date(birthDate).getTime()
          : new Date(
              `${new Date().getFullYear() - level - 7}-01-02T00:00:00`
            ).getTime(),
        gender: gender ? "MALE" : "FEMALE",
        email: emails?.length > 0 ? emails[0] : "yanlisveri@k12.com.tr",
        phoneNumber: phoneNumbers?.length > 0 ? phoneNumbers[0] : "5555555555",
        ...rest,
      };
    });

    setK12StudentList(convertedK12StudentList);
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      k12StudentList: false,
    }));
  };

  const retrieveK12BranchOptionList = () => {
    return k12BranchList.map((item) => ({
      text: item,
      value: item,
    }));
  };

  const constructUserParamsFromFullName = (fullName) => {
    const lastIndex = fullName.trim().lastIndexOf(" ");
    const firstNameFromLastIndex = fullName.substring(0, lastIndex).trim();
    const firstName = `${firstNameFromLastIndex
      .substring(0, 1)
      .toLocaleUpperCase(language)}${firstNameFromLastIndex
      .substring(1, firstNameFromLastIndex.length)
      .toLocaleLowerCase(language)}`;

    const lastNameFromLastIndex = fullName.substring(lastIndex).trim();
    const lastName = lastNameFromLastIndex.toLocaleUpperCase(language);
    const convertedFirstName = firstNameFromLastIndex.replaceAll(" ", ".");
    const convertedLastName = lastNameFromLastIndex.replaceAll(" ", ".");
    const username = convertTurkishCharactersToEnglish(
      `${convertedFirstName.toLocaleLowerCase(
        language
      )}.${convertedLastName.toLocaleLowerCase(language)}`
    );
    return { username, firstName, lastName };
  };

  const filterK12StudentList = () => {
    let filteredK12StudentList;
    if (selectedBranchName === "0/A") {
      filteredK12StudentList = k12StudentList.filter(
        (student) => student.enrollment.homeroom === null
      );
    } else {
      filteredK12StudentList = k12StudentList.filter(
        (student) => student.enrollment.homeroom === selectedBranchName
      );
    }
    return filteredK12StudentList;
  };

  const filterInvalidK12UserList = () => {
    const invalidK12UserList = [];
    for (const k12Student of filteredK12StudentList) {
      const {
        id,
        fullName,
        email,
        phoneNumber,
        gender,
        dateOfBirth,
        enrollment,
      } = k12Student;
      const { username, firstName, lastName } =
        constructUserParamsFromFullName(fullName);
      const errorList = [];
      if (!username) {
        errorList.push(`${i18n.t("username")} ${i18n.t("must_be_valid")}`);
      }
      if (!firstName) {
        errorList.push(`${i18n.t("first_name")} ${i18n.t("must_be_valid")}`);
      }
      if (!lastName) {
        errorList.push(`${i18n.t("last_name")} ${i18n.t("must_be_valid")}`);
      }
      if (!email) {
        errorList.push(`${i18n.t("email")} ${i18n.t("must_be_valid")}`);
      }
      if (!phoneNumber) {
        errorList.push(`${i18n.t("phone_number")} ${i18n.t("must_be_valid")}`);
      }
      if (!gender) {
        errorList.push(`${i18n.t("gender")} ${i18n.t("must_be_valid")}`);
      }
      if (!dateOfBirth) {
        errorList.push(`${i18n.t("date_of_birth")} ${i18n.t("must_be_valid")}`);
      }
      if (!enrollment) {
        errorList.push(`${i18n.t("enrollment")} ${i18n.t("must_be_valid")}`);
      }
      if (errorList.length > 0) {
        invalidK12UserList.push({
          id,
          error: errorList.join(" "),
          fullName,
        });
      }
    }
    return invalidK12UserList;
  };

  const filterNotAcceptingUserStateList = () => {
    return userStateList.filter(
      (userState) =>
        userState.searchUserState === "FOUND_MULTIPLE_USERS" ||
        userState.searchUserState === "ALREADY_EXISTS_IN_SEASON"
    );
  };

  const filterAcceptingUserStateList = () => {
    return userStateList.filter(
      (userState) =>
        userState.searchUserState === "NEW_USER_AND_USER_PROFILE" ||
        userState.searchUserState === "NEW_USER_PROFILE"
    );
  };

  const convertUserStateListToNotAcceptingCheckArray = (userStateList) => {
    const notAcceptingCheckArray = [];
    for (const userState of userStateList) {
      const { providedUsername, searchUserState } = userState;
      const foundK12Student = filteredK12StudentList.find((k12Student) => {
        const { fullName } = k12Student;
        const { username } = constructUserParamsFromFullName(fullName);
        return username === providedUsername;
      });
      if (!foundK12Student) continue;
      let error;
      if (searchUserState === "FOUND_MULTIPLE_USERS") {
        error = i18n.t("FOUND_MULTIPLE_USERS");
      } else if (searchUserState === "ALREADY_EXISTS_IN_SEASON") {
        error = i18n.t("ALREADY_EXISTS_IN_SEASON");
      }
      const { id, fullName, nationalID, ...rest } = foundK12Student;
      const { username, firstName, lastName } =
        constructUserParamsFromFullName(fullName);
      notAcceptingCheckArray.push({
        id,
        error,
        username,
        password: nationalID.substring(0, 4),
        firstName,
        lastName,
        identityNumber: nationalID,
        ...rest,
      });
    }
    return notAcceptingCheckArray;
  };

  const convertUserStateListToAcceptingCheckArray = (userStateList) => {
    const acceptingCheckArray = [];
    for (const userState of userStateList) {
      const { providedUsername, foundUsername, searchUserState } = userState;
      const foundK12Student = filteredK12StudentList.find((k12Student) => {
        const { fullName } = k12Student;
        const { username } = constructUserParamsFromFullName(fullName);
        return username === providedUsername;
      });
      if (!foundK12Student) continue;
      let status;
      if (searchUserState === "NEW_USER_AND_USER_PROFILE") {
        status = i18n.t("NEW_USER_AND_USER_PROFILE");
      } else if (searchUserState === "NEW_USER_PROFILE") {
        status = i18n.t("NEW_USER_PROFILE");
      }
      const {
        id,
        fullName,
        email,
        phoneNumber,
        gender,
        dateOfBirth,
        nationalID,
      } = foundK12Student;
      const { firstName, lastName } = constructUserParamsFromFullName(fullName);
      acceptingCheckArray.push({
        id,
        status,
        searchUserState,
        username: foundUsername,
        password: nationalID.substring(0, 4),
        firstName,
        lastName,
        email,
        identityNumber: nationalID,
        phoneNumber,
        gender,
        dateOfBirth,
        integrationId: id,
      });
    }
    return acceptingCheckArray;
  };

  const handleOnChange = (type, value) => {
    if (type === "k12BranchName") {
      setSelectedBranchName(value);
    }
  };

  const handleOnInvalidCheckTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setInvalidCheckSelectedItemCount(value);
    } else if (type === "pageNumber") {
      setInvalidCheckPageNumber(value);
    } else if (type === "sortKey") {
      setInvalidCheckSortKey(value);
    } else if (type === "sortDirection") {
      setInvalidCheckSortDirection(value);
    }
  };

  const handleOnNotAcceptingCheckTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setNotAcceptingCheckSelectedItemCount(value);
    } else if (type === "pageNumber") {
      setNotAcceptingCheckPageNumber(value);
    } else if (type === "sortKey") {
      setNotAcceptingCheckSortKey(value);
    } else if (type === "sortDirection") {
      setNotAcceptingCheckSortDirection(value);
    }
  };

  const handleOnAcceptingCheckTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setAcceptingCheckSelectedItemCount(value);
    } else if (type === "pageNumber") {
      setAcceptingCheckPageNumber(value);
    } else if (type === "sortKey") {
      setAcceptingCheckSortKey(value);
    } else if (type === "sortDirection") {
      setAcceptingCheckSortDirection(value);
    }
  };

  const handleTransferFromK12OnClick = async () => {
    const acceptingCheckArray = convertUserStateListToAcceptingCheckArray(
      filteredAcceptingUserStateList
    );
    const constructedData = [];
    for (const item of acceptingCheckArray) {
      const { searchUserState, status, integrationId, ...rest } = item;
      constructedData.push({
        user: {
          ...rest,
          id: null,
          accountType: "USER",
        },
        role: "LEARNER",
        seasonId: parseFloat(branchDTO.gradeDTO.seasonDTO.id),
        branchIdList: [branchDTO.id],
        name: `${branchDTO.name} ${i18n.t("LEARNER")}`,
        description: i18n.t("automatically_generated_by_admin"),
        searchUserState,
        integrationId,
      });
    }
    const { data, err } = await createUserImportFromK12(constructedData);
    if (err) {
      console.log(err);
    } else {
      navigate(`/learner-users`);
    }
  };

  const RenderInvalidStudentList = () => {
    if (filteredInvalidK12UserList.length === 0) {
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/checkbox.png"
                alt="checkbox"
              />
            </div>
            <p className="text-xl">
              {i18n.t("import_from_excel_items_valid_text")}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/exclamation-red.png"
                alt="exclamation-red"
              />
            </div>
            <BaseTitle
              title={i18n.t(
                "import_from_excel_items_validation_exception_text"
              )}
            />
          </div>
          <AdvancedTable
            title={null}
            description={null}
            itemName={i18n.t("learner")}
            headerList={[
              { name: i18n.t("error"), key: "error" },
              { name: i18n.t("full_name"), key: "fullName" },
            ]}
            selectable={false}
            showFilter={false}
            showItemCount={invalidCheckSelectedItemCount}
            totalItemCount={filteredInvalidK12UserList.length}
            pageNumber={invalidCheckPageNumber}
            loading={false}
            sortKey={invalidCheckSortKey}
            sortDirection={invalidCheckSortDirection}
            handleOnTableParamsChange={handleOnInvalidCheckTableParamsChange}
            itemList={filteredInvalidK12UserList}
            insertable={false}
            updatable={false}
            deletable={false}
            navigatable={false}
          />
        </div>
      );
    }
  };

  const RenderNotAcceptingStudentList = () => {
    if (filteredInvalidK12UserList.length !== 0) return null;
    if (filteredNotAcceptingUserStateList.length === 0) {
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/checkbox.png"
                alt="checkbox"
              />
            </div>
            <p className="text-xl">
              {i18n.t("import_from_excel_items_not_accepted_valid_text")}
            </p>
          </div>
        </div>
      );
    } else {
      const notAcceptingCheckArray =
        convertUserStateListToNotAcceptingCheckArray(
          filteredNotAcceptingUserStateList
        );
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/exclamation-red.png"
                alt="exclamation-red"
              />
            </div>
            <p className="text-xl font-semibold">
              {i18n.t("import_from_excel_items_not_accepted_exception_text")}
            </p>
          </div>
          <AdvancedTable
            title={null}
            description={null}
            itemName={i18n.t("learner")}
            headerList={[
              { name: i18n.t("error"), key: "error" },
              { name: i18n.t("username"), key: "username" },
              { name: i18n.t("password"), key: "password" },
              { name: i18n.t("first_name"), key: "firstName" },
              { name: i18n.t("last_name"), key: "lastName" },
              { name: i18n.t("email"), key: "email" },
              {
                name: i18n.t("gender"),
                key: "gender",
                type: "enumeration",
              },
              {
                name: i18n.t("identity_number"),
                key: "identityNumber",
              },
              {
                name: i18n.t("phone_number"),
                key: "phoneNumber",
              },
              {
                name: i18n.t("date_of_birth"),
                key: "dateOfBirth",
                type: "date",
              },
            ]}
            selectable={false}
            showFilter={false}
            showItemCount={notAcceptingCheckSelectedItemCount}
            totalItemCount={notAcceptingCheckArray.length}
            pageNumber={notAcceptingCheckPageNumber}
            loading={false}
            sortKey={notAcceptingCheckSortKey}
            sortDirection={notAcceptingCheckSortDirection}
            handleOnTableParamsChange={
              handleOnNotAcceptingCheckTableParamsChange
            }
            itemList={notAcceptingCheckArray}
            insertable={false}
            updatable={false}
            deletable={false}
            navigatable={false}
          />
        </div>
      );
    }
  };

  const RenderAcceptingStudentList = () => {
    if (filteredInvalidK12UserList.length !== 0) return null;
    if (filteredAcceptingUserStateList.length === 0) {
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/exclamation-red.png"
                alt="exclamation-red"
              />
            </div>
            <p className="text-xl">
              {i18n.t("import_from_excel_items_accepted_exception_text")}
            </p>
          </div>
        </div>
      );
    } else {
      const acceptingCheckArray = convertUserStateListToAcceptingCheckArray(
        filteredAcceptingUserStateList
      );
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/checkbox.png"
                alt="checkbox"
              />
            </div>
            <p className="text-xl">
              {i18n.t("import_from_excel_items_accepted_valid_text")}
            </p>
          </div>
          <AdvancedTable
            title={null}
            description={null}
            itemName={i18n.t("learner")}
            headerList={[
              { name: i18n.t("status"), key: "status" },
              { name: i18n.t("username"), key: "username" },
              { name: i18n.t("password"), key: "password" },
              { name: i18n.t("first_name"), key: "firstName" },
              { name: i18n.t("last_name"), key: "lastName" },
              { name: i18n.t("email"), key: "email" },
              {
                name: i18n.t("gender"),
                key: "gender",
                type: "enumeration",
              },
              {
                name: i18n.t("identity_number"),
                key: "identityNumber",
              },
              {
                name: i18n.t("phone_number"),
                key: "phoneNumber",
              },
              {
                name: i18n.t("date_of_birth"),
                key: "dateOfBirth",
                type: "date",
              },
            ]}
            selectable={false}
            showFilter={false}
            showItemCount={acceptingCheckSelectedItemCount}
            totalItemCount={acceptingCheckArray.length}
            pageNumber={acceptingCheckPageNumber}
            loading={false}
            sortKey={acceptingCheckSortKey}
            sortDirection={acceptingCheckSortDirection}
            handleOnTableParamsChange={handleOnAcceptingCheckTableParamsChange}
            itemList={acceptingCheckArray}
            insertable={false}
            updatable={false}
            deletable={false}
            navigatable={false}
          />
        </div>
      );
    }
  };

  const RenderStudentListContent = () => {
    if (selectedBranchName === "DEFAULT_OPTION") return;
    if (loadingMap.userStateList) {
      return (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      );
    } else {
      return (
        <>
          <DividerLine />
          <div className="">{RenderInvalidStudentList()}</div>
          <div className="">{RenderNotAcceptingStudentList()}</div>
          <div className="">{RenderAcceptingStudentList()}</div>
          {filteredAcceptingUserStateList.length > 0 ? (
            <div className="mt-4 ml-auto">
              <SecondaryButton
                title={i18n.t("transfer")}
                size="small"
                onClick={handleTransferFromK12OnClick}
              />
            </div>
          ) : null}
        </>
      );
    }
  };

  const RenderMainContent = () => {
    return (
      <FullCard isShadowed={true}>
        <div className="">
          <div className="flex flex-row items-center gap-x-2">
            <div className="cursor-pointer">
              <BaseImage
                size="medium"
                src="/icons/long-arrow-left.png"
                alt="long-arrow-left"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <BaseTitle title={i18n.t("learner_transfer_from_k12")} />
          </div>
        </div>
        <DividerLine />
        {loadingMap.branch ? (
          <BaseLoading size="medium" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <>
            <div className="">
              <p className="">
                {i18n.t("institution_name")}:{" "}
                <span className="font-semibold">
                  {branchDTO.gradeDTO.seasonDTO.institutionDTO.name}
                </span>
              </p>
              <p className="">
                {i18n.t("season_name")}:{" "}
                <span className="font-semibold">
                  {branchDTO.gradeDTO.seasonDTO.name}
                </span>
              </p>
              <p className="">
                {i18n.t("grade_name")}:{" "}
                <span className="font-semibold">{branchDTO.gradeDTO.name}</span>
              </p>
              <p className="">
                {i18n.t("branch_name")}:{" "}
                <span className="font-semibold">{branchDTO.name}</span>
              </p>
            </div>
            <DividerLine />
            <div className="flex flex-row items-center flex-wrap">
              {loadingMap.k12StudentList ? (
                <div className="flex-1 flex flex-row justify-center">
                  <BaseLoading
                    size="medium"
                    color="#57C2D3"
                    secondaryColor="#57C2D3"
                  />
                </div>
              ) : (
                <div className="w-full lg:w-1/2">
                  <div className="mx-2">
                    <InputGroup
                      type="select"
                      optionList={retrieveK12BranchOptionList()}
                      title={i18n.t("k12_branch_name")}
                      placeholder={null}
                      value={selectedBranchName}
                      onChange={(event) => {
                        handleOnChange("k12BranchName", event.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {RenderStudentListContent()}
      </FullCard>
    );
  };

  const filteredK12StudentList = filterK12StudentList();
  const filteredInvalidK12UserList = filterInvalidK12UserList();
  const filteredNotAcceptingUserStateList = filterNotAcceptingUserStateList();
  const filteredAcceptingUserStateList = filterAcceptingUserStateList();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default TransferLearnerFromK12Screen;
