import { useState } from "react";
import BaseImage from "../images/BaseImage";
import i18n from "../../language/i18n";
import SecondaryButton from "./SecondaryButton";
import PrimaryButton from "./PrimaryButton";

const HoverFilterButton = (props) => {
  const {
    src,
    alt,
    size,
    optionList,
    optionSize,
    isFilterApplied,
    onFilterClick,
  } = props;
  const [selectedId, setSelectedId] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div
      className="relative"
      onMouseEnter={() => {
        setShowDropdown(true);
      }}
      onMouseLeave={() => {
        setShowDropdown(false);
      }}
    >
      <BaseImage src={src} alt={alt} size={size} />
      <ul
        className={`${
          showDropdown ? "opacity-100 visible" : "opacity-0 invisible"
        } absolute translate-x-1/4 right-1/2 px-20 z-10`}
      >
        <div
          className={`flex flex-col ${
            optionSize === "small"
              ? "min-w-catchup-small-dropdown"
              : optionSize === "medium"
              ? "min-w-catchup-medium-dropdown"
              : ""
          } rounded-catchup-xlarge transition-all duration-300 bg-catchup-white py-4 mt-2 shadow-dropdown`}
        >
          {optionList.map((option, index) => (
            <div
              key={index}
              className={`${
                index !== optionList.length - 1 || !isFilterApplied
                  ? "border-b border-b-catchup-gray-50"
                  : ""
              }`}
            >
              <div
                className={`${
                  selectedId === option.id ? "bg-catchup-gray-50" : ""
                } `}
                onClick={() => {
                  if (option.id === selectedId) {
                    setSelectedId(null);
                  } else {
                    setSelectedId(option.id);
                  }
                }}
              >
                <div
                  className={`flex flex-row items-center justify-between gap-x-2 py-1 px-4 `}
                >
                  <div className="flex-1 flex-row items-center">
                    <div className="mx-1">{option.icon}</div>
                    <p className="cursor-pointer font-semibold">
                      {option.text}
                    </p>
                  </div>
                  {selectedId === option.id ? (
                    <div className="">
                      <BaseImage
                        src="/icons/arrow-down.png"
                        alt="arrow-down"
                        size="xsmall"
                      />
                    </div>
                  ) : (
                    <div className="">
                      <BaseImage
                        src="/icons/arrow-up.png"
                        alt="arrow-down"
                        size="xsmall"
                      />
                    </div>
                  )}
                </div>
              </div>
              {selectedId === option.id ? (
                <div className="mx-5">{option.content}</div>
              ) : null}
              {option.value && option.value !== "DEFAULT_OPTION" ? (
                <div className="flex flex-row px-5 my-1">
                  <div className="flex flex-row items-center gap-x-2 border bg-catchup-gray-50 border-catchup-gray-100 rounded-catchup-medium py-1 px-2">
                    <div>
                      <BaseImage
                        src="/icons/cross.png"
                        alt="cross"
                        size="xsmall"
                        onClick={option.onRemoveClick}
                      />
                    </div>
                    {option.type === "text" || option.type === "date" ? (
                      <p>{option.value}</p>
                    ) : option.type === "enumeration" ? (
                      <p>{i18n.t(option.value)}</p>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          ))}
          {isFilterApplied ? (
            <div className="mt-2 flex flex-col items-center justify-center">
              <SecondaryButton
                title={i18n.t("remove")}
                size="small"
                onClick={onFilterClick}
              />
            </div>
          ) : (
            <div className="mt-2 flex flex-col items-center justify-center">
              <PrimaryButton
                title={i18n.t("filter")}
                size="small"
                onClick={onFilterClick}
              />
            </div>
          )}
        </div>
      </ul>
    </div>
  );
};

export default HoverFilterButton;
