import { Text, View } from "@react-pdf/renderer";
import i18n from "../../language/i18n";

const StandardExamReportViewContent = (props) => {
  const { subType, reportDetailList } = props;

  const constructSubHeaderText = () => {
    if (subType === "BRANCH") {
      return i18n.t("branch_report");
    } else if (subType === "GRADE") {
      return i18n.t("grade_report");
    } else if (subType === "SEASON") {
      return i18n.t("season_report");
    }
    return null;
  };

  const RenderCommonReportViewContent = (reportDetail) => {
    return (
      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
        <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap" }}>
          <Text style={{ marginVertical: 5, fontWeight: "bold" }}>
            {i18n.t("standard_exam_name")}:
          </Text>
          <Text style={{ marginVertical: 5, marginLeft: 5 }}>
            {reportDetail.standardExamDTO.name}
          </Text>
        </View>
        {reportDetail.seasonGradeBranchMap ? (
          <>
            <View
              style={{ width: "33%", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Text style={{ marginVertical: 5, fontWeight: "bold" }}>
                {i18n.t("season_name")}:
              </Text>
              <Text style={{ marginVertical: 5, marginLeft: 5 }}>
                {reportDetail.seasonGradeBranchMap.seasonName}
              </Text>
            </View>
            <View
              style={{ width: "33%", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Text style={{ marginVertical: 5, fontWeight: "bold" }}>
                {i18n.t("grade_name")}:
              </Text>
              <Text style={{ marginVertical: 5, marginLeft: 5 }}>
                {reportDetail.seasonGradeBranchMap.gradeName}
              </Text>
            </View>
            <View
              style={{ width: "33%", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Text style={{ marginVertical: 5, fontWeight: "bold" }}>
                {i18n.t("branch_name")}:
              </Text>
              <Text style={{ marginVertical: 5, marginLeft: 5 }}>
                {reportDetail.seasonGradeBranchMap.branchName}
              </Text>
            </View>
          </>
        ) : null}
        {reportDetail.seasonGradeMap ? (
          <>
            <View
              style={{ width: "50%", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Text style={{ marginVertical: 5, fontWeight: "bold" }}>
                {i18n.t("season_name")}:
              </Text>
              <Text style={{ marginVertical: 5, marginLeft: 5 }}>
                {reportDetail.seasonGradeMap.seasonName}
              </Text>
            </View>
            <View
              style={{ width: "50%", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Text style={{ marginVertical: 5, fontWeight: "bold" }}>
                {i18n.t("grade_name")}:
              </Text>
              <Text style={{ marginVertical: 5, marginLeft: 5 }}>
                {reportDetail.seasonGradeMap.gradeName}
              </Text>
            </View>
          </>
        ) : null}
        {reportDetail.seasonMap ? (
          <>
            <View
              style={{ width: "100%", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Text style={{ marginVertical: 5, fontWeight: "bold" }}>
                {i18n.t("season_name")}:
              </Text>
              <Text style={{ marginVertical: 5, marginLeft: 5 }}>
                {reportDetail.seasonMap.seasonName}
              </Text>
            </View>
          </>
        ) : null}
      </View>
    );
  };

  const RenderCommonStandardExamReportContent = (reportDetail) => {
    const { standardExamCategoryActivityAnswerDTOList } = reportDetail;
    return (
      <View style={{ flex: 1, marginVertical: 5 }}>
        {standardExamCategoryActivityAnswerDTOList
          .filter((item) => item.categoryActivityAnswerDTOList.length > 0)
          .map((standardExamCategoryActivityAnswerDTO) => {
            const { categoryActivityAnswerDTOList } =
              standardExamCategoryActivityAnswerDTO;
            return categoryActivityAnswerDTOList.map(
              (categoryActivityAnswerDTO) => (
                <>
                  <View style={{ flex: 1 }}>
                    <View style={{ flex: 1, flexDirection: "row" }}>
                      <View style={{ width: "80%" }}></View>

                      <View
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "5%",
                        }}
                      >
                        <Text style={{ padding: 8 }}>#</Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "5%",
                        }}
                      >
                        <Text style={{ padding: 8 }}>+</Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "10%",
                        }}
                      >
                        <Text style={{ padding: 8 }}>%</Text>
                      </View>
                    </View>
                  </View>
                  {RenderRecursivelyCategoryListContent(
                    categoryActivityAnswerDTO,
                    0
                  )}
                </>
              )
            );
          })}
      </View>
    );
  };

  const RenderRecursivelyCategoryListContent = (
    categoryActivityAnswerDTO,
    recursiveCount
  ) => {
    let score = 0;
    for (const activityScoreDTO of categoryActivityAnswerDTO.activityScoreDTOSet) {
      score += activityScoreDTO.score;
    }
    return (
      <View style={{ flex: 1 }}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            paddingLeft: recursiveCount * 10,
          }}
        >
          <View
            style={{
              display: "flex",
              width: "80%",
            }}
          >
            <Text style={{ padding: 8 }}>
              {categoryActivityAnswerDTO.categoryDTO.name}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "5%",
            }}
          >
            <Text style={{ padding: 8, fontWeight: "bold" }}>
              {categoryActivityAnswerDTO.activityScoreDTOSet.length}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "5%",
            }}
          >
            <Text style={{ padding: 8, fontWeight: "bold" }}>
              {score.toFixed(2)}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "10%",
            }}
          >
            <Text style={{ padding: 8, fontWeight: "bold" }}>
              {(
                (score / categoryActivityAnswerDTO.activityScoreDTOSet.length) *
                100
              ).toFixed(2)}
              %
            </Text>
          </View>
        </View>
        {categoryActivityAnswerDTO.subCategoryActivityAnswerDTOList &&
        categoryActivityAnswerDTO.subCategoryActivityAnswerDTOList.length > 0
          ? categoryActivityAnswerDTO.subCategoryActivityAnswerDTOList.map(
              (subCategoryActivityAnswerDTO) =>
                RenderRecursivelyCategoryListContent(
                  subCategoryActivityAnswerDTO,
                  recursiveCount + 1
                )
            )
          : null}
      </View>
    );
  };

  return (
    <View style={{ width: "100%" }}>
      <View style={{ flexDirection: "column", alignItems: "center" }}>
        <Text style={{ fontSize: 18, fontWeight: "bold" }}>
          {i18n.t("standard_exam_report")}
        </Text>
        <Text style={{ fontSize: 14 }}>{constructSubHeaderText()}</Text>
      </View>

      {reportDetailList.map((reportDetail) => (
        <View style={{ marginVertical: 20 }}>
          {RenderCommonReportViewContent(reportDetail)}
          <View style={{ borderBottom: 0.5, marginVertical: 5 }} />
          {RenderCommonStandardExamReportContent(reportDetail)}
        </View>
      ))}
    </View>
  );
};

export default StandardExamReportViewContent;
