import {
  Document,
  Font,
  Image,
  Page,
  PDFViewer,
  View,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import InformationReportViewContent from "../../components/pdf-viewer/InformationReportViewContent";
import { useAuth } from "../../context/UserContextProvider";
import CatchtivityReportViewContent from "../../components/pdf-viewer/CatchtivityReportViewContent";
import CatchxamReportViewContent from "../../components/pdf-viewer/CatchxamReportViewContent";
import LearningOutcomeReportViewContent from "../../components/pdf-viewer/LearningOutcomeReportViewContent";
import ParticipationReportViewContent from "../../components/pdf-viewer/ParticipationReportViewContent";
import StandardExamReportViewContent from "../../components/pdf-viewer/StandardExamReportViewContent";
import {
  generateCatchtivityBranchReport,
  generateCatchtivityGradeReport,
  generateCatchtivitySeasonReport,
  generateCatchxamBranchReport,
  generateCatchxamGradeReport,
  generateCatchxamSeasonReport,
  generateInformationBranchReport,
  generateInformationBrandReport,
  generateInformationCampusReport,
  generateInformationGradeReport,
  generateInformationInstitutionReport,
  generateInformationRegionReport,
  generateInformationSeasonReport,
  generateLearningOutcomeBranchReport,
  generateLearningOutcomeGradeReport,
  generateLearningOutcomeSeasonReport,
  generateLearningOutcomeUserProfileReport,
  generateParticipationBranchReport,
  generateParticipationGradeReport,
  generateParticipationSeasonReport,
  generateParticipationUserProfileReport,
  generatePerformanceCoterieReport,
  generatePerformanceUserProfileReport,
  generateStandardExamBranchReport,
  generateStandardExamGradeReport,
  generateStandardExamSeasonReport,
} from "../../requests/ReportRequests";
import { NotificationManager } from "react-notifications";
import i18n from "../../language/i18n";
import BaseLoading from "../../components/loading/BaseLoading";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
      fontWeight: "normal",
      textDecoration: "none",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
      fontWeight: "bold",
      textDecoration: "none",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
      fontWeight: "normal",
      textDecoration: "underline",
    },
  ],
});

const ReportPDFViewerScreen = () => {
  const { state } = useLocation();
  const { base64DataEncoded } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const { language, userProfileBrand } = useAuth();
  const [base64Data, setBase64Data] = useState(null);
  const [baseType, setBaseType] = useState(null);
  const [subType, setSubType] = useState(null);
  const [reportDetailListLoading, setReportDetailListLoading] = useState(true);
  const [reportDetailList, setReportDetailList] = useState([]);

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  useEffect(() => {
    if (!base64DataEncoded) return;
    const base64Data = JSON.parse(decodeURI(atob(base64DataEncoded)));
    const { baseReportType, subReportType } = base64Data;
    setBaseType(baseReportType);
    setSubType(subReportType);
    setBase64Data(base64Data);
  }, [base64DataEncoded]);

  useEffect(() => {
    const retrieveAndSetReportDetailList = async () => {
      setReportDetailListLoading(true);
      let data = null;
      let err = null;
      if (baseType === "INFORMATION") {
        if (subType === "BRANCH") {
          const { data: branchData, err: branchErr } =
            await generateInformationBranchReport(base64Data);
          data = [branchData];
          err = branchErr;
        } else if (subType === "GRADE") {
          const { data: gradeData, err: gradeErr } =
            await generateInformationGradeReport(base64Data);
          data = [gradeData];
          err = gradeErr;
        } else if (subType === "SEASON") {
          const { data: seasonData, err: seasonErr } =
            await generateInformationSeasonReport(base64Data);
          data = [seasonData];
          err = seasonErr;
        } else if (subType === "INSTITUTION") {
          const { data: institutionData, err: institutionErr } =
            await generateInformationInstitutionReport(base64Data);
          data = [institutionData];
          err = institutionErr;
        } else if (subType === "CAMPUS") {
          const { data: campusData, err: campusErr } =
            await generateInformationCampusReport(base64Data);
          data = [campusData];
          err = campusErr;
        } else if (subType === "REGION") {
          const { data: regionData, err: regionErr } =
            await generateInformationRegionReport(base64Data);
          data = [regionData];
          err = regionErr;
        } else if (subType === "BRAND") {
          const { data: brandData, err: brandErr } =
            await generateInformationBrandReport(base64Data);
          data = [brandData];
          err = brandErr;
        }
      } else if (baseType === "CATCHTIVITY") {
        if (subType === "BRANCH") {
          const { data: branchData, err: branchErr } =
            await generateCatchtivityBranchReport(base64Data);
          data = branchData;
          err = branchErr;
        } else if (subType === "GRADE") {
          const { data: gradeData, err: gradeErr } =
            await generateCatchtivityGradeReport(base64Data);
          data = gradeData;
          err = gradeErr;
        } else if (subType === "SEASON") {
          const { data: seasonData, err: seasonErr } =
            await generateCatchtivitySeasonReport(base64Data);
          data = seasonData;
          err = seasonErr;
        }
      } else if (baseType === "CATCHXAM") {
        if (subType === "BRANCH") {
          const { data: branchData, err: branchErr } =
            await generateCatchxamBranchReport(base64Data);
          data = branchData;
          err = branchErr;
        } else if (subType === "GRADE") {
          const { data: gradeData, err: gradeErr } =
            await generateCatchxamGradeReport(base64Data);
          data = gradeData;
          err = gradeErr;
        } else if (subType === "SEASON") {
          const { data: seasonData, err: seasonErr } =
            await generateCatchxamSeasonReport(base64Data);
          data = seasonData;
          err = seasonErr;
        }
      } else if (baseType === "STANDARD_EXAM") {
        if (subType === "BRANCH") {
          const { data: branchData, err: branchErr } =
            await generateStandardExamBranchReport(base64Data);
          data = branchData;
          err = branchErr;
        } else if (subType === "GRADE") {
          const { data: gradeData, err: gradeErr } =
            await generateStandardExamGradeReport(base64Data);
          data = gradeData;
          err = gradeErr;
        } else if (subType === "SEASON") {
          const { data: seasonData, err: seasonErr } =
            await generateStandardExamSeasonReport(base64Data);
          data = seasonData;
          err = seasonErr;
        }
      } else if (baseType === "LEARNING_OUTCOME") {
        if (subType === "BRANCH") {
          const { data: branchData, err: branchErr } =
            await generateLearningOutcomeBranchReport(base64Data);
          data = branchData;
          err = branchErr;
        } else if (subType === "GRADE") {
          const { data: gradeData, err: gradeErr } =
            await generateLearningOutcomeGradeReport(base64Data);
          data = gradeData;
          err = gradeErr;
        } else if (subType === "SEASON") {
          const { data: seasonData, err: seasonErr } =
            await generateLearningOutcomeSeasonReport(base64Data);
          data = seasonData;
          err = seasonErr;
        } else if (subType === "USER_PROFILE") {
          const { data: userProfileData, err: userProfileErr } =
            await generateLearningOutcomeUserProfileReport(base64Data);
          data = userProfileData;
          err = userProfileErr;
        }
      } else if (baseType === "PARTICIPATION") {
        if (subType === "BRANCH") {
          const { data: branchData, err: branchErr } =
            await generateParticipationBranchReport(base64Data);
          data = branchData;
          err = branchErr;
        } else if (subType === "GRADE") {
          const { data: gradeData, err: gradeErr } =
            await generateParticipationGradeReport(base64Data);
          data = gradeData;
          err = gradeErr;
        } else if (subType === "SEASON") {
          const { data: seasonData, err: seasonErr } =
            await generateParticipationSeasonReport(base64Data);
          data = seasonData;
          err = seasonErr;
        } else if (subType === "USER_PROFILE") {
          const { data: userProfileData, err: userProfileErr } =
            await generateParticipationUserProfileReport(base64Data);
          data = userProfileData;
          err = userProfileErr;
        }
      } else if (baseType === "PERFORMANCE") {
        if (subType === "USER_PROFILE") {
          const { data: userProfileData, err: userProfileErr } =
            await generatePerformanceUserProfileReport(base64Data);
          data = userProfileData;
          err = userProfileErr;
        } else if (subType === "COTERIE_TYPE") {
          const { data: coterieData, err: coterieErr } =
            await generatePerformanceCoterieReport(base64Data.newReportList);
          data = coterieData;
          err = coterieErr;
        }
      }
      if (err) {
        NotificationManager.error(i18n.t(err.response.data));
        console.log(err);
      } else {
        setReportDetailList(data);
      }
      setReportDetailListLoading(false);
    };
    if (!baseType) return;
    if (!subType) return;
    if (!base64Data) return;
    retrieveAndSetReportDetailList();
  }, [baseType, subType, base64Data]);

  const RenderViewContent = () => {
    return (
      <View style={{ width: "100%" }}>
        {baseType === "INFORMATION" ? (
          <>
            <InformationReportViewContent
              language={language}
              subType={subType}
              reportDetail={reportDetailList[0]}
            />
          </>
        ) : baseType === "CATCHTIVITY" ? (
          <>
            <CatchtivityReportViewContent
              subType={subType}
              reportDetailList={reportDetailList}
            />
          </>
        ) : baseType === "CATCHXAM" ? (
          <>
            <CatchxamReportViewContent
              subType={subType}
              reportDetailList={reportDetailList}
            />
          </>
        ) : baseType === "STANDARD_EXAM" ? (
          <>
            <StandardExamReportViewContent
              subType={subType}
              reportDetailList={reportDetailList}
            />
          </>
        ) : baseType === "LEARNING_OUTCOME" ? (
          <LearningOutcomeReportViewContent
            subType={subType}
            reportDetailList={reportDetailList}
          />
        ) : baseType === "PARTICIPATION" ? (
          <ParticipationReportViewContent
            language={language}
            subType={subType}
            reportDetailList={reportDetailList}
          />
        ) : null}
      </View>
    );
  };

  return (
    <div className="flex-1 flex flex-col h-full">
      <div className="m-4 h-full">
        {reportDetailListLoading ? (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <PDFViewer className="h-full w-full">
            <Document>
              <Page
                size="A4"
                style={{
                  backgroundColor: "#ffffff",
                  fontFamily: "Roboto",
                  paddingVertical: 30,
                  paddingHorizontal: 15,
                  fontSize: 8,
                }}
              >
                {userProfileBrand.logoUrl ? (
                  <View
                    style={{
                      height: "10%",
                      margin: "auto",
                    }}
                  >
                    <Image
                      src={{
                        uri: userProfileBrand.logoUrl,
                      }}
                      style={{ height: "100%", marginVertical: 10 }}
                    />
                  </View>
                ) : null}
                <View style={{ flex: 1 }}>{RenderViewContent()}</View>
              </Page>
            </Document>
          </PDFViewer>
        )}
      </div>
    </div>
  );
};

export default ReportPDFViewerScreen;
