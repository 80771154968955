import { useEffect, useState } from "react";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import {
  queryInvalidActivityDTOListByIncomplete,
  queryInvalidActivityDTOListByIncompleteAnswer,
  queryInvalidActivityDTOListByMathematicalExpression,
  queryInvalidActivityDTOListByNotFlagged,
  queryInvalidActivityDTOListByNotInBold,
  queryInvalidActivityDTOListByRedaction,
  queryInvalidActivityDTOListByWrongActivity,
  queryInvalidActivityDTOListByWrongAnswer,
} from "../../requests/CatchtivityRequests";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import { useNavigate } from "react-router-dom";
import AdvancedTable from "../../components/tables/AdvancedTable";
import i18n from "../../language/i18n";
import BaseImage from "../../components/images/BaseImage";
import InputGroup from "../../components/groups/InputGroup";
import {
  filterCoterieTypeOptionList,
  filterGradeLevelOptionList,
} from "../../utilization/ManagementUtilization";
import FullCard from "../../components/cards/FullCard";

const ActivityInvalidDataListScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileInstitution,
    userProfileGrade,
  } = useAuth();
  const [invalidActivityDTOList, setInvalidActivityDTOList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);
  const [invalidState, setInvalidState] = useState("DEFAULT_OPTION");
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");
  const [selectedLevel, setSelectedLevel] = useState("DEFAULT_OPTION");

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("activity_invalid_data")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    if (!userInformation) return;
    if (!userProfile) return;
    if (invalidState === "DEFAULT_OPTION") {
      setInvalidActivityDTOList([]);
      setTableLoading(false);
    } else if (invalidState === "INCOMPLETE") {
      retrieveAndSetInvalidActivityDTOListByIncomplete();
    } else if (invalidState === "NOT_IN_BOLD") {
      retrieveAndSetInvalidActivityDTOListByNotInBold();
    } else if (invalidState === "REDACTION") {
      retrieveAndSetInvalidActivityDTOListByRedaction();
    } else if (invalidState === "WRONG_ACTIVITY") {
      if (
        selectedCoterieType === "DEFAULT_OPTION" ||
        selectedLevel === "DEFAULT_OPTION"
      ) {
        setInvalidActivityDTOList([]);
      } else {
        retrieveAndSetInvalidActivityDTOListByWrongActivity();
      }
    } else if (invalidState === "WRONG_ANSWER") {
      if (
        selectedCoterieType === "DEFAULT_OPTION" ||
        selectedLevel === "DEFAULT_OPTION"
      ) {
        setInvalidActivityDTOList([]);
      } else {
        retrieveAndSetInvalidActivityDTOListByWrongAnswer();
      }
    } else if (invalidState === "INCOMPLETE_ANSWER") {
      if (
        selectedCoterieType === "DEFAULT_OPTION" ||
        selectedLevel === "DEFAULT_OPTION"
      ) {
        setInvalidActivityDTOList([]);
      } else {
        retrieveAndSetInvalidActivityDTOListByIncompleteAnswer();
      }
    } else if (invalidState === "MATHEMATICAL_EXPRESSION") {
      if (
        selectedCoterieType === "DEFAULT_OPTION" ||
        selectedLevel === "DEFAULT_OPTION"
      ) {
        setInvalidActivityDTOList([]);
      } else {
        retrieveAndSetInvalidActivityDTOListByMathematicalExpression();
      }
    } else if (invalidState === "NOT_FLAGGED") {
      if (
        selectedCoterieType === "DEFAULT_OPTION" ||
        selectedLevel === "DEFAULT_OPTION"
      ) {
        setInvalidActivityDTOList([]);
      } else {
        retrieveAndSetInvalidActivityDTOListByNotFlagged();
      }
    }
  }, [
    userInformation,
    userProfile,
    invalidState,
    selectedCoterieType,
    selectedLevel,
  ]);

  const retrieveAndSetInvalidActivityDTOListByIncomplete = async () => {
    setTableLoading(true);
    const { data, err } = await queryInvalidActivityDTOListByIncomplete({
      brandId: userProfileBrand.id,
      deleted: false,
    });
    if (err) {
      console.log(err);
      return;
    }
    setInvalidActivityDTOList(data);
    setTotalItemCount(data.length);
    setTableLoading(false);
  };

  const retrieveAndSetInvalidActivityDTOListByNotInBold = async () => {
    setTableLoading(true);
    const { data, err } = await queryInvalidActivityDTOListByNotInBold({
      brandId: userProfileBrand.id,
      deleted: false,
    });
    if (err) {
      console.log(err);
      return;
    }
    setInvalidActivityDTOList(data);
    setTotalItemCount(data.length);
    setTableLoading(false);
  };

  const retrieveAndSetInvalidActivityDTOListByRedaction = async () => {
    setTableLoading(true);
    const { data, err } = await queryInvalidActivityDTOListByRedaction({
      brandId: userProfileBrand.id,
      deleted: false,
    });
    if (err) {
      console.log(err);
      return;
    }
    setInvalidActivityDTOList(data);
    setTotalItemCount(data.length);
    setTableLoading(false);
  };

  const retrieveAndSetInvalidActivityDTOListByWrongActivity = async () => {
    setTableLoading(true);
    const { data, err } = await queryInvalidActivityDTOListByWrongActivity({
      coterieType: selectedCoterieType,
      level: selectedLevel,
      brandId: userProfileBrand.id,
      deleted: false,
    });
    if (err) {
      console.log(err);
      return;
    }
    setInvalidActivityDTOList(data);
    setTotalItemCount(data.length);
    setTableLoading(false);
  };

  const retrieveAndSetInvalidActivityDTOListByIncompleteAnswer = async () => {
    setTableLoading(true);
    const { data, err } = await queryInvalidActivityDTOListByIncompleteAnswer({
      coterieType: selectedCoterieType,
      level: selectedLevel,
      brandId: userProfileBrand.id,
      deleted: false,
    });
    if (err) {
      console.log(err);
      return;
    }
    setInvalidActivityDTOList(data);
    setTotalItemCount(data.length);
    setTableLoading(false);
  };

  const retrieveAndSetInvalidActivityDTOListByWrongAnswer = async () => {
    setTableLoading(true);
    const { data, err } = await queryInvalidActivityDTOListByWrongAnswer({
      coterieType: selectedCoterieType,
      level: selectedLevel,
      brandId: userProfileBrand.id,
      deleted: false,
    });
    if (err) {
      console.log(err);
      return;
    }
    setInvalidActivityDTOList(data);
    setTotalItemCount(data.length);
    setTableLoading(false);
  };

  const retrieveAndSetInvalidActivityDTOListByMathematicalExpression =
    async () => {
      setTableLoading(true);
      const { data, err } =
        await queryInvalidActivityDTOListByMathematicalExpression({
          coterieType: selectedCoterieType,
          level: selectedLevel,
          brandId: userProfileBrand.id,
          deleted: false,
        });
      if (err) {
        console.log(err);
        return;
      }
      setInvalidActivityDTOList(data);
      setTotalItemCount(data.length);
      setTableLoading(false);
    };

  const retrieveAndSetInvalidActivityDTOListByNotFlagged = async () => {
    setTableLoading(true);
    const { data, err } = await queryInvalidActivityDTOListByNotFlagged({
      coterieType: selectedCoterieType,
      level: selectedLevel,
      brandId: userProfileBrand.id,
      deleted: false,
    });
    if (err) {
      console.log(err);
      return;
    }
    setInvalidActivityDTOList(data);
    setTotalItemCount(data.length);
    setTableLoading(false);
  };

  const constructInvalidStateOptionList = () => {
    return [
      {
        text: i18n.t("INCOMPLETE"),
        value: "INCOMPLETE",
      },
      {
        text: i18n.t("NOT_IN_BOLD"),
        value: "NOT_IN_BOLD",
      },
      // {
      //   text: i18n.t("REDACTION"),
      //   value: "REDACTION",
      // },
      {
        text: i18n.t("WRONG_ACTIVITY"),
        value: "WRONG_ACTIVITY",
      },
      {
        text: i18n.t("INCOMPLETE_ANSWER"),
        value: "INCOMPLETE_ANSWER",
      },
      {
        text: i18n.t("WRONG_ANSWER"),
        value: "WRONG_ANSWER",
      },
      {
        text: i18n.t("MATHEMATICAL_EXPRESSION"),
        value: "MATHEMATICAL_EXPRESSION",
      },
      {
        text: i18n.t("NOT_FLAGGED"),
        value: "NOT_FLAGGED",
      },
    ];
  };

  const constructNavigatableList = () => {
    const navigateList = [];
    navigateList.push({
      id: 0,
      text: i18n.t("to_activity_list_screen"),
      icon: (
        <BaseImage src="/icons/navigate-gray.png" alt="navigate" size="small" />
      ),
      onClick: (item) => {
        window.open(`/activities?activity_name=${item.name}`, "_blank");
      },
    });
    return navigateList;
  };

  const handleOnChange = (key, value) => {
    if (key === "invalidState") {
      setInvalidState(value);
    } else if (key === "coterieType") {
      setSelectedCoterieType(value);
    } else if (key === "level") {
      setSelectedLevel(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const filterInvalidActivityDTOList = () => {
    if (invalidActivityDTOList.length === 0) return [];
    const startIndex = pageNumber * selectedShowItemCount;
    const endIndex = (pageNumber + 1) * selectedShowItemCount;
    return invalidActivityDTOList.slice(startIndex, endIndex);
  };

  const filteredInvalidActivityDTOList = filterInvalidActivityDTOList();

  const RenderMainContent = () => {
    return (
      <FullCard>
        <div className="w-full">
          <div className="flex flex-row flex-wrap items-center">
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("invalid_state")}
                  placeholder={null}
                  value={invalidState}
                  optionList={constructInvalidStateOptionList()}
                  onChange={(event) => {
                    handleOnChange("invalidState", event.target.value);
                  }}
                />
              </div>
            </div>
            {invalidState === "MATHEMATICAL_EXPRESSION" ||
            invalidState === "WRONG_ACTIVITY" ||
            invalidState === "WRONG_ANSWER" ||
            invalidState === "INCOMPLETE_ANSWER" ||
            invalidState === "NOT_FLAGGED" ? (
              <>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  <div className="mx-2">
                    <InputGroup
                      type="select"
                      title={i18n.t("coterie_type")}
                      placeholder={null}
                      value={selectedCoterieType}
                      optionList={filterCoterieTypeOptionList(
                        userInformation,
                        userProfile,
                        userProfileInstitution
                      )}
                      onChange={(event) => {
                        handleOnChange("coterieType", event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  <div className="mx-2">
                    <InputGroup
                      type="select"
                      title={i18n.t("level")}
                      placeholder={null}
                      value={selectedLevel}
                      optionList={filterGradeLevelOptionList(
                        userProfileInstitution,
                        userProfileGrade
                      )}
                      onChange={(event) => {
                        handleOnChange("level", event.target.value);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>

          <div>
            <AdvancedTable
              title={i18n.t("activity_invalid_data_list")}
              description={i18n.t(
                "bundle_activity_list_table_description_text"
              )}
              itemName={i18n.t("activity_invalid_data")}
              headerList={[
                { name: i18n.t("name"), key: "name", type: "info" },
                {
                  name: i18n.t("coterie_type"),
                  key: "coterieType",
                  type: "enumeration",
                },
                {
                  name: i18n.t("level"),
                  key: "level",
                },
                {
                  name: i18n.t("created_at"),
                  key: "createdAt",
                  type: "datetime",
                },
              ]}
              selectable={false}
              showFilter={true}
              showItemCount={selectedShowItemCount}
              totalItemCount={totalItemCount}
              pageNumber={pageNumber}
              loading={tableLoading}
              sortKey={sortKey}
              sortDirection={sortDirection}
              handleOnFilterChange={() => {}}
              handleOnTableParamsChange={handleOnTableParamsChange}
              filterParams={{
                isFilterApplied: false,
              }}
              itemList={filteredInvalidActivityDTOList}
              insertable={false}
              updatable={false}
              deletable={false}
              navigatable={true}
              navigateOptionList={constructNavigatableList()}
            />
          </div>
        </div>
      </FullCard>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ActivityInvalidDataListScreen;
