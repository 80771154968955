import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FullCard from "../cards/FullCard";
import i18n from "../../language/i18n";
import DividerLine from "../divider/DividerLine";
import InputGroup from "../groups/InputGroup";
import { useAuth } from "../../context/UserContextProvider";
import AdvancedTable from "../tables/AdvancedTable";
import { filterCoterieTypeOptionList } from "../../utilization/ManagementUtilization";
import {
  deleteStorageFile,
  moveFileToBrandPath,
  queryAllMainFileDTOPageByFilter,
  uploadFileToPath,
} from "../../requests/StorageRequests";
import { Dna } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
import PrimaryButton from "../buttons/PrimaryButton";
import { convertFileAndUpload } from "../../requests/FileConverterRequests";
import BaseModal from "../modal/BaseModal";
import BaseImage from "../images/BaseImage";
import BaseTitle from "../titles/BaseTitle";
import SingleInformationIconTextItem from "../infos/SingleInformationIconTextItem";
import ErrorWarning from "../warnings/ErrorWarning";
import SetOrUpsertLabel from "../label/SetOrUpsertLabel";
import { queryBrandLabelActionByItemIdListAndItemType } from "../../requests/LabelRequests";

const ImportFromPDFCreateActivity = () => {
  const navigate = useNavigate();
  const {
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileInstitution,
  } = useAuth();
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [movedFile, setMovedFile] = useState(null);
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [isFilterApplied, setIsFilterApplied] = useState(true);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [filterCoterieType, setFilterCoterieType] = useState(
    userInformation.accountType === "GENIXO"
      ? "MANAGEMENT"
      : userProfile.coterieType
      ? userProfile.coterieType
      : "DEFAULT_OPTION"
  );
  const [uploadedPDFList, setUploadedPDFList] = useState([]);
  const [fileUploadStatus, setFileUploadStatus] = useState(null);
  const [fileMoveStatus, setFileMoveStatus] = useState(null);
  const [fileParseStatus, setFileParseStatus] = useState(null);
  const [selectedCoterieType, setSelectedCoterieType] = useState(
    userInformation.accountType === "GENIXO"
      ? "MANAGEMENT"
      : userProfile.coterieType
      ? userProfile.coterieType
      : "DEFAULT_OPTION"
  );
  const ownershipType = "ACTIVITY_DOCUMENT";
  const ITEM_TYPE = "STORAGE_FILE";
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [selectedStorageFile, setSelectedStorageFile] = useState(null);
  const [brandLabelLoading, setBrandLabelLoading] = useState(false);
  const [brandLabelActionDTOList, setBrandLabelActionDTOList] = useState([]);

  useEffect(() => {
    retrieveAndSetStorageFilePageByFilter();
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
  ]);

  useEffect(() => {
    if (isFilterApplied) {
      retrieveAndSetStorageFilePageByFilter();
    }
  }, [
    isFilterApplied,
    filterBeginAt,
    filterEndAt,
    filterShowDeleted,
    filterCoterieType,
  ]);

  useEffect(() => {
    if (uploadFile) {
      handleSendSingleFileUpload(uploadFile);
    }
  }, [uploadFile]);

  useEffect(() => {
    const handleConvertFileAndUpload = async () => {
      setFileParseStatus("PARSING");
      let inputPath = "";
      if (process.env.REACT_APP_NODE_ENV === "development") {
        inputPath += ".";
      }
      inputPath += `./catchup-storage/brand-files/${userProfileBrand.id}/pdfs/${uploadedFile.fileName}`;
      const { data, err } = await convertFileAndUpload({
        inputPath,
        inputUploadPath: `/${userProfileBrand.id}/pdfs/${uploadedFile.fileName}`,
        inputType: "PDF",
        outputType: "JPEG",
        outputEncoding: "file",
        ownershipType,
        coterieType: selectedCoterieType,
      });
      retrieveAndSetStorageFilePageByFilter();
      if (err) {
        console.log(err);
        NotificationManager.error(i18n.t("error_occurred_on_parse_file"));
        setFileParseStatus("ERROR");
        return;
      }
      setFileParseStatus("PARSED");
    };
    if (fileMoveStatus === "MOVED") {
      handleConvertFileAndUpload();
      setFileMoveStatus(null);
    }
  }, [fileMoveStatus]);

  useEffect(() => {
    if (fileParseStatus === "PARSED") {
      setTimeout(() => {
        navigate(`/activities/create/import-from-pdf/${uploadedFile.fileName}`);
        setFileParseStatus(null);
      }, 3000);
    } else if (fileParseStatus === "ERROR") {
      handleRemoveUploadedPDFOnClick(movedFile.id);
    }
  }, [fileParseStatus]);

  useEffect(() => {
    const retrieveAndSetBrandLabelActionList = async () => {
      setBrandLabelLoading(true);
      const { data, err } = await queryBrandLabelActionByItemIdListAndItemType({
        itemIdList: uploadedPDFList.map((uploadedPDF) => uploadedPDF.id),
        itemType: ITEM_TYPE,
        brandId: userProfileBrand.id,
      });
      if (err) {
      } else {
        setBrandLabelActionDTOList(data);
      }
      setBrandLabelLoading(false);
    };
    if (uploadedPDFList.length === 0) {
      setBrandLabelActionDTOList([]);
    } else {
      retrieveAndSetBrandLabelActionList();
    }
  }, [uploadedPDFList]);

  useEffect(() => {
    setFilterCoterieType(selectedCoterieType);
  }, [selectedCoterieType]);

  const retrieveAndSetStorageFilePageByFilter = async () => {
    setTableLoading(true);

    const queryParams = {
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        beginAt:
          isFilterApplied && filterBeginAt
            ? new Date(filterBeginAt).getTime()
            : null,
        endAt:
          isFilterApplied && filterEndAt
            ? new Date(filterEndAt).getTime()
            : null,
        coterieType:
          isFilterApplied && filterCoterieType !== "DEFAULT_OPTION"
            ? filterCoterieType
            : null,
        deleted: isFilterApplied ? (filterShowDeleted ? null : false) : false,
        documentType: "PDF",
      },
    };
    if (userProfile.role === "STAFF") {
      queryParams.params.brandId = userProfileBrand.id;
    } else if (userProfile.role === "CONTENT_CREATOR") {
      queryParams.params.userId = userInformation.id;
      queryParams.params.userProfileId = userProfile.id;
    }
    const { data, err } = await queryAllMainFileDTOPageByFilter(queryParams);
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setUploadedPDFList(data.itemList);
    }

    setTableLoading(false);
  };

  const retrieveAcceptedFormats = () => {
    return ".pdf";
  };

  const checkCanNavigate = () => {
    return true;
  };

  const constructNavigatableList = () => {
    const navigateList = [];
    navigateList.push({
      id: 0,
      text: i18n.t("to_create_activity_from_pdf_screen"),
      icon: (
        <BaseImage src="/icons/navigate-gray.png" alt="navigate" size="small" />
      ),
      value: "/activities/create/import-from-pdf/{fileName}",
    });
    if (userInformation.accountType === "GENIXO") {
      navigateList.push({
        id: 1,
        text: i18n.t("to_update_document_settings_screen"),
        icon: (
          <BaseImage
            src="/icons/navigate-gray.png"
            alt="navigate"
            size="small"
          />
        ),
        value: "/activity-documents/settings/{fileName}",
      });
    }
    return navigateList;
  };

  const constructLabelActionList = () => {
    const actionDTOList = [];
    for (const brandLabelAction of brandLabelActionDTOList) {
      actionDTOList.push({
        id: brandLabelAction.id,
        label: brandLabelAction.brandLabelDTO,
        labelItemList: brandLabelAction.brandLabelItemDTOList,
        type: "BRAND",
      });
    }
    return actionDTOList;
  };

  const handleSendSingleFileUpload = async (file) => {
    setFileUploadStatus("UPLOADING");
    const formData = new FormData();
    formData.append("brandId", userProfileBrand.id);
    formData.append("userId", userInformation.id);
    formData.append("userProfileId", userProfile.id);
    formData.append("coterieType", selectedCoterieType);
    formData.append("documentType", "PDF");
    formData.append("path", `/${userProfileBrand.id}/pdfs`);
    formData.append("file", file);
    formData.append("changeFileName", true);
    const { data, err } = await uploadFileToPath(formData);
    if (err) {
      setFileUploadStatus("ERROR");
      NotificationManager.error(i18n.t("error_occurred_on_upload_file"));
      return;
    }
    setUploadedFile({
      id: data.id,
      fileName: data.fileName,
      tempPath: data.path,
      ownershipType,
    });
    setFileUploadStatus("UPLOADED");
  };

  const handleMoveSingleFileUpload = async (uploadedFile) => {
    setFileMoveStatus("MOVING");
    const { id, ...rest } = uploadedFile;
    const { data, err } = await moveFileToBrandPath(rest);
    if (err) {
      console.log(err);
      setFileMoveStatus("FAILED");
      NotificationManager.error(i18n.t("error_occurred_on_upload_file"));
      return;
    }
    setMovedFile({
      id: data.id,
    });
    setFileMoveStatus("MOVED");
    NotificationManager.success(i18n.t("file_successfully_uploaded"));
  };

  const handleOnChange = (type, value) => {
    if (type === "uploadFile") {
      setUploadFile(value[0]);
    } else if (type === "selectedCoterieType") {
      setSelectedCoterieType(value);
    }
  };

  const handleToLabelStorageFileOnClick = (storageFile) => {
    setShowLabelModal(true);
    setSelectedStorageFile(storageFile);
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    } else if (type === "coterieType") {
      setFilterCoterieType(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleRemoveUploadedPDFOnClick = async (storageFileId) => {
    const { err } = await deleteStorageFile(storageFileId);
    if (err) {
      console.log(err);
      return;
    }
    retrieveAndSetStorageFilePageByFilter();
  };

  const RenderShowUploadStatusModal = () => {
    if (fileMoveStatus === "MOVING") {
      return (
        <BaseModal
          isOpen={true}
          size="small"
          onAfterOpen={() => {}}
          onRequestClose={() => {}}
        >
          <div className="flex-1 flex flex-col">
            <FullCard>
              <p className="text-xl text-center">
                {i18n.t("upload_status_file_moving")}
              </p>
            </FullCard>
          </div>
        </BaseModal>
      );
    } else if (fileMoveStatus === "MOVED") {
      return (
        <BaseModal
          isOpen={true}
          size="small"
          onAfterOpen={() => {}}
          onRequestClose={() => {}}
        >
          <div className="flex-1 flex flex-col">
            <FullCard>
              <p className="text-xl text-center">
                {i18n.t("upload_status_file_moved")}
              </p>
            </FullCard>
          </div>
        </BaseModal>
      );
    } else if (fileParseStatus === "PARSING") {
      return (
        <BaseModal
          isOpen={true}
          size="small"
          onAfterOpen={() => {}}
          onRequestClose={() => {}}
        >
          <div className="flex-1 flex flex-col">
            <FullCard>
              <div className="flex flex-col items-center justify-center gap-y-4">
                <div className="flex flex-row items-center justify-center">
                  <div className="mx-2">
                    <Dna
                      visible={true}
                      height="32"
                      width="32"
                      ariaLabel="dna-loading"
                      wrapperStyle={{}}
                      wrapperClass="dna-wrapper"
                    />
                  </div>
                  <div>
                    <p className="text-xl  text-center">
                      {i18n.t("upload_status_file_parsing")}
                    </p>
                  </div>
                  <div className="mx-2">
                    <Dna
                      visible={true}
                      height="32"
                      width="32"
                      ariaLabel="dna-loading"
                      wrapperStyle={{}}
                      wrapperClass="dna-wrapper"
                    />
                  </div>
                </div>
                <div className="my-2 text-catchup-gray-400">
                  {SingleInformationIconTextItem(
                    i18n.t("upload_status_file_parsing_description_text_1")
                  )}
                  {SingleInformationIconTextItem(
                    i18n.t("upload_status_file_parsing_description_text_2")
                  )}
                  {SingleInformationIconTextItem(
                    i18n.t("upload_status_file_parsing_description_text_3")
                  )}
                </div>
              </div>
            </FullCard>
          </div>
        </BaseModal>
      );
    } else if (fileParseStatus === "PARSED") {
      return (
        <BaseModal
          isOpen={true}
          size="small"
          onAfterOpen={() => {}}
          onRequestClose={() => {}}
        >
          <div className="flex-1 flex flex-col">
            <FullCard>
              <p className="text-xl  text-center">
                {i18n.t("upload_status_file_parsed")}
              </p>
            </FullCard>
          </div>
        </BaseModal>
      );
    }
  };

  const RenderLabelModal = () => {
    return (
      <BaseModal
        isOpen={showLabelModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowLabelModal(false);
          setSelectedStorageFile(null);
        }}
      >
        <div className="flex-1 flex flex-col">
          <SetOrUpsertLabel
            item={selectedStorageFile}
            itemType={ITEM_TYPE}
            brandLabelLoading={brandLabelLoading}
            brandLabelActionList={brandLabelActionDTOList}
            setBrandLabelActionList={setBrandLabelActionDTOList}
          />
        </div>
      </BaseModal>
    );
  };

  const navigatableList = constructNavigatableList();
  const canNavigate = checkCanNavigate();

  return (
    <>
      {RenderLabelModal()}
      {RenderShowUploadStatusModal()}
      <FullCard isShadowed={true}>
        <div className="">
          <div className="flex flex-row items-center gap-x-2">
            <BaseImage
              size="medium"
              src="/icons/long-arrow-left.png"
              alt="long-arrow-left"
              onClick={() => {
                navigate(-1);
              }}
            />
            <BaseTitle title={i18n.t("create_activity_from_pdf")} />
          </div>
        </div>
        <DividerLine />

        <div className="flex flex-col">
          <div className="my-2 p-3">
            {SingleInformationIconTextItem(
              i18n.t("import_from_from_pdf_information_text_1")
            )}
            {SingleInformationIconTextItem(
              i18n.t("import_from_from_pdf_information_text_2")
            )}
            {SingleInformationIconTextItem(
              i18n.t("import_from_from_pdf_information_text_3")
            )}
            {SingleInformationIconTextItem(
              i18n.t("import_from_from_pdf_information_text_4")
            )}
          </div>

          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("coterie_type")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedCoterieType}
                optionList={filterCoterieTypeOptionList(
                  userInformation,
                  userProfile,
                  userProfileInstitution
                )}
                onChange={(event) => {
                  if (uploadFile) {
                    NotificationManager.error(
                      i18n.t(
                        "coterie_type_cannot_be_changed_after_uploading_a_file"
                      )
                    );
                  } else {
                    handleOnChange("selectedCoterieType", event.target.value);
                  }
                }}
              />
            </div>
          </div>

          {selectedCoterieType !== "DEFAULT_OPTION" ? (
            selectedCoterieType === "MANAGEMENT" ? (
              <ErrorWarning
                text={i18n.t(
                  "please_select_a_different_coterie_type_to_upload_a_document"
                )}
              />
            ) : (
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("file_upload")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="file"
                    multiple={false}
                    accept={retrieveAcceptedFormats()}
                    value={null}
                    onChange={(event) => {
                      handleOnChange("uploadFile", {
                        ...event.target.files,
                      });
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                </div>
              </div>
            )
          ) : null}

          {uploadFile ? (
            <div className="w-5/6 ml-auto">
              <div className="flex flex-row justify-between items-center">
                <div>
                  <p>{uploadFile.name}</p>
                </div>
                <div>
                  <div>
                    {fileUploadStatus === "UPLOADING" ? (
                      <>
                        <div className="mx-2">
                          <Dna
                            visible={true}
                            height="32"
                            width="32"
                            ariaLabel="dna-loading"
                            wrapperStyle={{}}
                            wrapperClass="dna-wrapper"
                          />
                        </div>
                      </>
                    ) : fileUploadStatus === "UPLOADED" ? (
                      <>
                        <div className="mx-2">
                          <BaseImage
                            src="/icons/checkbox.png"
                            alt="checkbox"
                            size="small"
                          />
                        </div>
                      </>
                    ) : fileUploadStatus === "ERROR" ? (
                      <>
                        <div className="mx-2">
                          <BaseImage
                            src="/icons/remove-red.png"
                            alt="remove"
                            onClick={() => {
                              setUploadFile(null);
                              setFileUploadStatus(null);
                            }}
                            size="small"
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {fileUploadStatus === "UPLOADED" ? (
            <div className="ml-auto mt-2">
              <PrimaryButton
                title={i18n.t("continue")}
                size="small"
                onClick={async () => {
                  await handleMoveSingleFileUpload(uploadedFile);
                  setUploadFile(null);
                  setFileUploadStatus(null);
                }}
              />
            </div>
          ) : null}
        </div>

        <AdvancedTable
          title={i18n.t("uploaded_pdf_list")}
          description={i18n.t("uploaded_pdf_list_table_description_text")}
          itemName={i18n.t("file")}
          headerList={[
            { name: i18n.t("name"), key: "fileOriginalName", type: "info" },
            {
              name: i18n.t("coterie_type"),
              key: "coterieType",
              type: "enumeration",
            },
            {
              name: i18n.t("type"),
              type: "enumeration",
              key: "documentType",
            },
            {
              name: i18n.t("page_offset"),
              key: "pageOffset",
            },
            { name: i18n.t("created_at"), key: "createdAt", type: "datetime" },
          ]}
          selectable={false}
          showFilter={true}
          showItemCount={selectedShowItemCount}
          totalItemCount={totalItemCount}
          pageNumber={pageNumber}
          loading={tableLoading}
          sortKey={sortKey}
          sortDirection={sortDirection}
          handleOnFilterChange={handleOnFilterChange}
          handleOnTableParamsChange={handleOnTableParamsChange}
          filterParams={{
            isFilterApplied,
            showBeginDate: true,
            beginAt: filterBeginAt,
            showEndDate: true,
            endAt: filterEndAt,
            showDeleted: filterShowDeleted,
            showCoterieType: true,
            mainCoterieType: userProfile.coterieType
              ? userProfile.coterieType
              : null,
            coterieType: filterCoterieType,
          }}
          itemList={uploadedPDFList}
          labelable={true}
          showLabelList={true}
          labelActionList={constructLabelActionList()}
          handleLabelOnClick={handleToLabelStorageFileOnClick}
          insertable={false}
          updatable={false}
          deletable={true}
          handleDeleteOnClick={handleRemoveUploadedPDFOnClick}
          navigatable={canNavigate}
          navigateOptionList={navigatableList}
        />
      </FullCard>
    </>
  );
};

export default ImportFromPDFCreateActivity;
