import { useCallback, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useLocation, useNavigate } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import i18n from "../../language/i18n";
import {
  createCatchxamAccessibilityByCatchxamId,
  createCatchxamWithActivityIdList,
  deleteCatchxam,
  patchCatchxam,
  queryAllCatchxamDTOPageByAccessibilityParams,
  queryAllCatchxamDTOPageByUserIdAndUserProfileId,
  queryCatchxamActivityCountByParams,
  retrieveCatchxamById,
} from "../../requests/CatchtivityRequests";
import {
  filterCoterieTypeOptionList,
  filterGradeLevelOptionList,
} from "../../utilization/ManagementUtilization";
import {
  checkDeletePermissionGranted,
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import InputGroup from "../../components/groups/InputGroup";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import FullCard from "../../components/cards/FullCard";
import AdvancedTable from "../../components/tables/AdvancedTable";
import ListActivity from "../../components/activity/ListActivity";
import ListActivityBundle from "../../components/activity/ListActivityBundle";
import DividerLine from "../../components/divider/DividerLine";
import BaseModal from "../../components/modal/BaseModal";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import SingleInformationIconTextItem from "../../components/infos/SingleInformationIconTextItem";
import debounce from "lodash.debounce";
import SingleActivityItem from "../../components/activity/SingleActivityItem";
import SelectionTab from "../../components/tabs/SelectionTab";
import CreateButton from "../../components/buttons/CreateButton";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const CatchxamListScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isFullScreen, setIsFullScreen, catchtivityStompClient } = useApp();
  const {
    language,
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileGrade,
    categorySet,
  } = useAuth();
  const [pageState, setPageState] = useState(0);
  const [innerPageState, setInnerPageState] = useState(0);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");
  const [selectedLevel, setSelectedLevel] = useState("DEFAULT_OPTION");
  const [planWeekId, setPlanWeekId] = useState(null);
  const [catchxamDTOList, setCatchxamDTOList] = useState([]);
  const [selectedActivityList, setSelectedActivityList] = useState([]);
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterTextContains, setFilterTextContains] = useState("");
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [filterCoterieType, setFilterCoterieType] = useState(
    userInformation.accountType === "GENIXO"
      ? "MANAGEMENT"
      : userProfile.coterieType
      ? userProfile.coterieType
      : "DEFAULT_OPTION"
  );
  const [filterLevel, setFilterLevel] = useState(
    userProfileGrade ? userProfileGrade.level : "DEFAULT_OPTION"
  );
  const [selectedVersionCode, setSelectedVersionCode] =
    useState("DEFAULT_OPTION");
  const [selectedSubCategoryCode, setSelectedSubCategoryCode] =
    useState("DEFAULT_OPTION");
  const [selectedCategoryOccurrenceList, setSelectedCategoryOccurrenceList] =
    useState([]);
  const [chooseActivityParams, setChooseActivityParams] = useState(null);
  const [isSelectedInitially, setIsSelectedInitially] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [errorMap, setErrorMap] = useState({
    name: null,
    description: null,
    activityIdList: null,
    coterieType: null,
    level: null,
  });
  const [subscribedId, setSubscribedId] = useState(null);
  const [someoneInformation, setSomeoneInformation] = useState(null);
  const [showSomeoneUpdatedModal, setShowSomeoneUpdatedModal] = useState(false);
  const [activityCountDTOList, setActivityCountDTOList] = useState([]);
  const [debounceState, setDebounceState] = useState("");
  const [
    showCreateCatchxamSelectionModal,
    setShowCreateCatchxamSelectionModal,
  ] = useState(false);
  const [createCatchxamSelectionState, setCreateCatchxamSelectionState] =
    useState(null);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("catchxam_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetSpecificCatchxamById = async () => {
      const { data, err } = await retrieveCatchxamById(id);
      if (err) {
        console.log(err);
      } else {
        const foundActivityList = data.activityDTOList.map((activityDTO) => ({
          id: activityDTO.id,
          name: activityDTO.name,
          description: activityDTO.description,
          categoryIdList: activityDTO.categoryIdList,
          activityTemplateDTOList: activityDTO.activityTemplateDTOList,
          status: activityDTO.status,
          coterieType: activityDTO.coterieType,
          createdAt: activityDTO.createdAt,
        }));
        setSelectedActivityList(foundActivityList);
      }
    };
    if (id) {
      retrieveAndSetSpecificCatchxamById();
    }
  }, [id]);

  useEffect(() => {
    retrieveAndSetCatchxamPageByFilter();
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    setDebounceState("BASE_FILTER");
    retrieveAndSetCatchxamPageByFilter();
  }, [
    isFilterApplied,
    filterBeginAt,
    filterEndAt,
    filterCoterieType,
    filterLevel,
    filterShowDeleted,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    if (filterTextContains) {
      setDebounceState("TEXT_CONTAINS");
      debouncedRetrieveAndSetCatchxamPageByFilterDebounce(filterTextContains);
    } else {
      retrieveAndSetCatchxamPageByFilter();
    }
  }, [filterTextContains]);

  useEffect(() => {
    if (id && catchtivityStompClient) {
      const subscribed = catchtivityStompClient.subscribe(
        "/topic/catchxam",
        (message) => {
          let { body } = message;
          body = JSON.parse(body);
          if (body.state === "UPDATED" && body.catchxamId === id) {
            catchtivityStompClient.unsubscribe(subscribedId);
            setSubscribedId(null);
            handleReturnFromCatchxamOnClick();
            retrieveAndSetCatchxamPageByFilter();
            if (body.username !== userInformation.username) {
              setSomeoneInformation({
                username: body.username,
                firstName: body.firstName,
                lastName: body.lastName,
              });
              setShowSomeoneUpdatedModal(true);
            }
          }
        }
      );
      setSubscribedId(subscribed.id);
    }
  }, [id]);

  useEffect(() => {
    if (pageState === 0 && subscribedId) {
      catchtivityStompClient.unsubscribe(subscribedId);
      setSubscribedId(null);
    }
  }, [pageState]);

  useEffect(() => {
    if (!createCatchxamSelectionState) return;
    if (!state) return;
    const { filterParams: stateFilterParams } = state;
    if (!stateFilterParams) return;
    const {
      versionCode: stateVersionCode,
      categoryOccurrenceList: stateCategoryOccurrenceList,
    } = stateFilterParams;
    if (!stateVersionCode) return;
    setSelectedVersionCode(stateVersionCode);
    if (stateCategoryOccurrenceList?.length > 0) {
      setSelectedCategoryOccurrenceList(stateCategoryOccurrenceList);
      const foundCategory = categorySet.find(
        (category) => category.id === stateCategoryOccurrenceList[0].categoryId
      );
      if (!foundCategory) return;
      const { parentCode } = foundCategory;
      const foundParentCategory = categorySet.find(
        (category) => category.code === parentCode
      );
      if (!foundParentCategory) return;
      setSelectedSubCategoryCode(foundParentCategory.id);
    }
  }, [state, createCatchxamSelectionState]);

  useEffect(() => {
    if (!state) return;
    const { createParams: stateCreateParams } = state;
    if (!stateCreateParams) return;
    const {
      name: stateName,
      description: stateDescription,
      coterieType: stateCoterieType,
      level: stateLevel,
      planWeekId: statePlanWeekId,
    } = stateCreateParams;
    if (stateName) {
      setName(stateName);
    }
    if (stateDescription) {
      setDescription(stateDescription);
    }
    if (stateCoterieType) {
      setSelectedCoterieType(stateCoterieType);
    }
    if (stateLevel) {
      setSelectedLevel(stateLevel);
    }
    if (statePlanWeekId) {
      setPlanWeekId(statePlanWeekId);
    }
  }, [state]);

  useEffect(() => {
    if (!state) return;
    const { createParams: stateCreateParams, filterParams: stateFilterParams } =
      state;
    if (!stateFilterParams || !stateCreateParams) return;
    setShowCreateCatchxamSelectionModal(true);
  }, [state]);

  useEffect(() => {
    if (!state) return;
    const { chooseParams: stateChooseParams } = state;
    if (!stateChooseParams) return;
    setChooseActivityParams(stateChooseParams);
  }, [state]);

  useEffect(() => {
    if (!state) return;
    const { pageState } = state;
    setPageState(pageState);
  }, [state]);

  useEffect(() => {
    if (!createCatchxamSelectionState) return;
    if (createCatchxamSelectionState === "ACTIVITY_LIST") {
      setInnerPageState(0);
    } else if (createCatchxamSelectionState === "PUBLISHING_HOUSE") {
      setInnerPageState(1);
    }
  }, [createCatchxamSelectionState]);

  useEffect(() => {
    const retrieveAndSetActivityCountByParams = async (activityIdList) => {
      const { data, err } = await queryCatchxamActivityCountByParams({
        activityIdList,
        brandId: userProfileBrand.id,
        campusId: userProfileCampus?.id,
        institutionId: userProfileInstitution?.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      setActivityCountDTOList(data);
    };
    if (selectedActivityList.length > 0) {
      const selectedActivityIdList = selectedActivityList.map(
        (selectedActivity) => selectedActivity.id
      );
      retrieveAndSetActivityCountByParams(selectedActivityIdList);
    }
  }, [selectedActivityList]);

  useEffect(() => {
    if (!userProfileGrade) return;
    setSelectedLevel(userProfileGrade.level);
  }, [userProfileGrade]);

  const retrieveAndSetCatchxamPageByFilter = async () => {
    setTableLoading(true);
    let data;
    let err;
    if (userProfile.role === "STAFF") {
      const { data: staffData, err: staffErr } =
        await queryAllCatchxamDTOPageByAccessibilityParams(pagedQueryParams);
      data = staffData;
      err = staffErr;
    } else if (
      userProfile.role === "INDIVIDUAL" ||
      userProfile.role === "CONTENT_CREATOR"
    ) {
      const { data: individualData, err: individualErr } =
        await queryAllCatchxamDTOPageByUserIdAndUserProfileId(pagedQueryParams);
      data = individualData;
      err = individualErr;
    }
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setCatchxamDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const retrieveAndSetCatchxamPageByFilterDebounce = async (
    filterTextContains
  ) => {
    setTableLoading(true);
    if (isFilterApplied) {
      pagedQueryParams.params.textContains = filterTextContains.trim();
    }
    let data;
    let err;
    if (userProfile.role === "STAFF") {
      const { data: staffData, err: staffErr } =
        await queryAllCatchxamDTOPageByAccessibilityParams(pagedQueryParams);
      data = staffData;
      err = staffErr;
    } else if (
      userProfile.role === "INDIVIDUAL" ||
      userProfile.role === "CONTENT_CREATOR"
    ) {
      const { data: individualData, err: individualErr } =
        await queryAllCatchxamDTOPageByUserIdAndUserProfileId(pagedQueryParams);
      data = individualData;
      err = individualErr;
    }
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setCatchxamDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const retrieveActivityListSelectedCategoryOccurrenceList = () => {
    if (createCatchxamSelectionState !== "ACTIVITY_LIST") return;
    return selectedCategoryOccurrenceList;
  };

  const retrievePublishingHouseSelectedCategoryOccurrenceList = () => {
    if (createCatchxamSelectionState !== "PUBLISHING_HOUSE") return;
    return selectedCategoryOccurrenceList;
  };

  const debouncedRetrieveAndSetCatchxamPageByFilterDebounce = useCallback(
    debounce(function (textContains) {
      retrieveAndSetCatchxamPageByFilterDebounce(textContains);
    }, 1000),
    [debounceState]
  );

  const checkCommonValidation = () => {
    if (
      !name ||
      name.trim() === "" ||
      !description ||
      description.trim() === "" ||
      selectedCoterieType === "DEFAULT_OPTION" ||
      selectedActivityList.length === 0 ||
      selectedLevel === "DEFAULT_OPTION"
    ) {
      const currentErrorMap = {
        name: null,
        description: null,
        activityIdList: null,
      };
      if (!name || name.trim() === "") {
        currentErrorMap.name = i18n.t("name_required_field");
      }
      if (!description || description.trim() === "") {
        currentErrorMap.description = i18n.t("description_required_field");
      }
      if (selectedCoterieType === "DEFAULT_OPTION") {
        currentErrorMap.coterieType = i18n.t("coterie_type_required");
      }
      if (selectedActivityList.length === 0) {
        currentErrorMap.activityIdList = i18n.t("activity_required_field");
      }
      if (selectedLevel === "DEFAULT_OPTION") {
        currentErrorMap.level = i18n.t("level_required");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const checkCanNavigate = () => {
    let canNavigate = true;
    if (!checkViewPermissionGranted("catchxam_accessibility")) {
      canNavigate = false;
    }
    return canNavigate;
  };

  const constructNavigatableList = () => {
    let navigateList = [];
    if (checkViewPermissionGranted("catchxam_accessibility")) {
      navigateList.push({
        id: 0,
        text: i18n.t("to_catchxam_accessibilities_screen"),
        icon: (
          <BaseImage
            src="/icons/navigate-gray.png"
            alt="navigate"
            size="small"
          />
        ),
        value: "/catchxams/{id}/accessibilities",
      });
    }
    if (checkInsertPermissionGranted("catchxam_application_list")) {
      navigateList.push({
        id: "fast_apply",
        text: i18n.t("fast_apply"),
        icon: (
          <BaseImage
            src="/icons/catchxam-gray.png"
            alt="catchxam"
            size="small"
          />
        ),
        value: "/catchxams/applications",
        includeKeyValueToParams: {
          catchxamId: "id",
          coterieType: "coterieType",
          level: "level",
        },
        params: {
          pageState: 1,
        },
      });
    }
    return navigateList;
  };

  const constructPagedQueryParams = () => {
    return {
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        beginAt:
          isFilterApplied && filterBeginAt
            ? new Date(filterBeginAt).getTime()
            : null,
        endAt:
          isFilterApplied && filterEndAt
            ? new Date(filterEndAt).getTime()
            : null,
        deleted: isFilterApplied ? (filterShowDeleted ? null : false) : false,
        userId: userInformation.id,
        userProfileId: userProfile.id,
        brandId: userProfileBrand.id,
        campusId: userProfileCampus?.id,
        institutionId: userProfileInstitution?.id,
        textContains:
          isFilterApplied && filterTextContains ? filterTextContains : null,
        coterieType: isFilterApplied
          ? filterCoterieType !== "DEFAULT_OPTION"
            ? filterCoterieType
            : null
          : userProfile.coterieType
          ? userProfile.coterieType
          : null,
        level: isFilterApplied
          ? filterLevel !== "DEFAULT_OPTION"
            ? filterLevel
            : null
          : userProfileGrade?.level,
      },
    };
  };

  const resetParams = () => {
    setId(null);
    setName(null);
    setDescription(null);
    setSelectedCoterieType("DEFAULT_OPTION");
    setSelectedLevel(
      userProfileGrade ? userProfileGrade.level : "DEFAULT_OPTION"
    );
    setPlanWeekId(null);
    setSelectedActivityList([]);
  };

  const handleOnChange = (type, value) => {
    if (type === "name") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: i18n.t("name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: null,
        }));
      }
      setName(value);
    } else if (type === "description") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: i18n.t("description_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: null,
        }));
      }
      setDescription(value);
    } else if (type === "coterieType") {
      setSelectedCoterieType(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        coterieType: null,
      }));
    } else if (type === "level") {
      setSelectedLevel(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        level: null,
      }));
    } else {
    }
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    } else if (type === "coterieType") {
      setFilterCoterieType(value);
    } else if (type === "textContains") {
      setFilterTextContains(value);
    } else if (type === "level") {
      setFilterLevel(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleReturnFromCatchxamOnClick = () => {
    resetParams();
    setPageState(0);
  };

  const handleAddActivityToCatchxam = (item) => {
    const foundIndex = selectedActivityList.findIndex(
      (selectedActivity) =>
        parseFloat(selectedActivity.id) === parseFloat(item.id)
    );
    if (foundIndex === -1) {
      selectedActivityList.push({
        id: item.id,
        name: item.name,
        categoryIdList: item.categoryIdList,
        activityTemplateDTOList: item.activityTemplateDTOList,
        description: item.description,
        coterieType: item.coterieType,
        status: item.status,
        createdAt: item.createdAt,
      });
    } else {
      selectedActivityList.splice(foundIndex, 1);
    }
    setSelectedActivityList(JSON.parse(JSON.stringify(selectedActivityList)));
  };

  const handleInitialActivityListToCatchxam = (itemList) => {
    if (isSelectedInitially) return false;
    if (itemList.length === 0) return;
    for (const item of itemList) {
      const foundIndex = selectedActivityList.findIndex(
        (selectedActivity) =>
          parseFloat(selectedActivity.id) === parseFloat(item.id)
      );
      if (foundIndex === -1) {
        selectedActivityList.push({
          id: item.id,
          name: item.name,
          categoryIdList: item.categoryIdList,
          activityTemplateDTOList: item.activityTemplateDTOList,
          description: item.description,
          coterieType: item.coterieType,
          status: item.status,
          createdAt: item.createdAt,
        });
      }
    }
    setSelectedActivityList(JSON.parse(JSON.stringify(selectedActivityList)));
    setIsSelectedInitially(true);
  };

  const handleRemoveActivityFromCatchxam = (activityId) => {
    const foundIndex = selectedActivityList.findIndex(
      (selectedActivity) =>
        parseFloat(selectedActivity.id) === parseFloat(activityId)
    );
    if (foundIndex > -1) {
      selectedActivityList.splice(foundIndex, 1);
      setSelectedActivityList(
        JSON.parse(
          JSON.stringify(selectedActivityList.sort((a, b) => b.id - a.id))
        )
      );
    }
  };

  const handleToCreateCatchxamOnClick = () => {
    setId(null);
    setSelectedCoterieType(
      userProfile.coterieType ? userProfile.coterieType : "DEFAULT_OPTION"
    );
    setPageState(1);
    setInnerPageState(0);
  };

  const handleToUpdateCatchxamOnClick = (catchxamDTO) => {
    setId(catchxamDTO.id);
    setName(catchxamDTO.name);
    setDescription(catchxamDTO.description);
    setSelectedCoterieType(catchxamDTO.coterieType);
    setSelectedLevel(catchxamDTO.level);
    setPageState(1);
    setInnerPageState(0);
  };

  const handleRemoveCatchxamOnClick = async (catchxamId) => {
    if (checkDeletePermissionGranted("catchxam_list")) {
      const { data, err } = await deleteCatchxam(catchxamId);
      if (err) {
        if (err.response.data) {
          NotificationManager.error(i18n.t(err.response.data));
        }
      } else {
        const foundCatchxam = catchxamDTOList.find(
          (catchxamDTO) => catchxamDTO.id === catchxamId
        );
        foundCatchxam.deleted = true;
        setCatchxamDTOList(JSON.parse(JSON.stringify(catchxamDTOList)));
        retrieveAndSetCatchxamPageByFilter();
      }
    }
  };

  const handleUpdateCatchxamOnClick = async () => {
    if (checkUpdatePermissionGranted("catchxam_list")) {
      if (checkCommonValidation()) {
        const currentCatchxam = {
          id,
          name,
          description,
          coterieType: selectedCoterieType,
          level: selectedLevel,
          userId: userInformation.id,
          userProfileId: userProfile.id,
          activityIdList: selectedActivityList.map(
            (selectedActivity) => selectedActivity.id
          ),
        };
        const { data, err } = await patchCatchxam(currentCatchxam);
        if (err) {
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          const foundCatchxamIndex = catchxamDTOList.findIndex(
            (catchxamDTO) => catchxamDTO.id === currentCatchxam.id
          );
          if (foundCatchxamIndex !== -1) {
            catchxamDTOList[foundCatchxamIndex] = {
              ...catchxamDTOList[foundCatchxamIndex],
              id: data.id,
              name: data.name,
              description: data.description,
              coterieType: data.coterieType,
              level: data.level,
              userId: data.userId,
              userProfileId: data.userProfileId,
              activityDTOList: data.activityDTOList,
              deleted: data.deleted,
              createdAt: data.createdAt,
            };
            setCatchxamDTOList(
              JSON.parse(
                JSON.stringify(catchxamDTOList.sort((a, b) => b.id - a.id))
              )
            );
            if (catchtivityStompClient) {
              catchtivityStompClient.send(
                "/app/catchxam/updates",
                null,
                JSON.stringify({
                  catchxamId: id,
                  username: userInformation.username,
                  firstName: userInformation.firstName,
                  lastName: userInformation.lastName,
                  state: "UPDATED",
                })
              );
            }
          }

          handleReturnFromCatchxamOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleCreateCatchxamOnClick = async () => {
    if (checkInsertPermissionGranted("catchxam_list")) {
      if (checkCommonValidation()) {
        const newCatchxam = {
          name,
          description,
          userId: userInformation.id,
          userProfileId: userProfile.id,
          coterieType: selectedCoterieType,
          level: selectedLevel,
          activitySet: selectedActivityList.map((selectedActivity) => ({
            id: selectedActivity.id,
          })),
          planWeekId,
        };
        const { data, err } = await createCatchxamWithActivityIdList(
          newCatchxam
        );
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          catchxamDTOList.unshift({
            id: data.id,
            name: data.name,
            description: data.description,
            coterieType: data.coterieType,
            level: data.level,
            userId: data.userId,
            userProfileId: data.userProfileId,
            activityDTOList: data.activityDTOList,
            deleted: data.deleted,
            createdAt: data.createdAt,
          });
          setCatchxamDTOList(JSON.parse(JSON.stringify(catchxamDTOList)));
          handleCreateCatchxamAccessibilityOnClick(data.id);
          handleReturnFromCatchxamOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleCreateCatchxamAccessibilityOnClick = async (catchxamId) => {
    let newCatchxamAccessibility = {
      userId: userInformation.id,
      userProfileId: userProfile.id,
      catchxam: { id: catchxamId },
    };
    if (userProfileInstitution) {
      newCatchxamAccessibility = {
        ...newCatchxamAccessibility,
        brandIdSet: [userProfileBrand.id],
        campusIdSet: [userProfileCampus.id],
        institutionIdSet: [userProfileInstitution.id],
      };
    } else if (userProfileCampus) {
      newCatchxamAccessibility = {
        ...newCatchxamAccessibility,
        brandIdSet: [userProfileBrand.id],
        campusIdSet: [userProfileCampus.id],
        institutionIdSet: [],
      };
    } else if (userProfileBrand) {
      newCatchxamAccessibility = {
        ...newCatchxamAccessibility,
        brandIdSet: [userProfileBrand.id],
        campusIdSet: [],
        institutionIdSet: [],
      };
    }
    await createCatchxamAccessibilityByCatchxamId(newCatchxamAccessibility);
  };

  const handlePreviewActivityOnClick = (activityDTO) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    window.open(
      `${connection.app.url}/preview/activity/${activityDTO.id}/${btoa(
        encodeURI(JSON.stringify({ token: accessToken }))
      )}`
    );
  };

  const handlePreviewCatchxamOnClick = (catchxamDTO) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    window.open(
      `${connection.app.url}/preview/catchxam/${catchxamDTO.id}/${btoa(
        encodeURI(JSON.stringify({ token: accessToken }))
      )}`
    );
  };

  const RenderShowSomeoneUpdatedModal = () => {
    return (
      <BaseModal
        isOpen={showSomeoneUpdatedModal}
        size="small"
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowSomeoneUpdatedModal(false);
          setId(null);
        }}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            <div className="flex flex-col items-center justify-center gap-y-4">
              <BaseImage
                size="large"
                src="/icons/already-updated.png"
                alt="already-updated"
              />
              <div>
                <p className="w-[300px] text-xl text-center">
                  {i18n.t("someone_updated_the_catchxam_text")}
                </p>
              </div>
            </div>
            {someoneInformation ? (
              <div className="flex flex-col justify-center items-center">
                <p>
                  {someoneInformation.firstName} {someoneInformation.lastName}
                </p>
              </div>
            ) : null}
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  const RenderCreateCatchxamSelectionModal = () => {
    return (
      <BaseModal
        isOpen={showCreateCatchxamSelectionModal}
        size="small"
        onAfterOpen={() => {}}
        onRequestClose={() => {}}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            <div className="my-10">
              {createCatchxamSelectionState ===
              "PUBLISHING_HOUSE" ? null : createCatchxamSelectionState ===
                "ACTIVITY_LIST" ? null : (
                <div className="flex flex-row justify-center items-center gap-x-2">
                  <div
                    className="w-[250px] flex flex-col items-center gap-y-3 cursor-pointer p-10 border border-catchup-gray-100 hover:border-catchup-blue-500 rounded-catchup-xlarge transition-all duration-300 hover:shadow-input"
                    onClick={() => {
                      setCreateCatchxamSelectionState("PUBLISHING_HOUSE");
                      setShowCreateCatchxamSelectionModal(false);
                    }}
                  >
                    <BaseImage
                      src="/icons/activity-bundle.png"
                      alt="activity-bundle"
                      size="large"
                    />
                    <p className="font-semibold text-xl text-catchup-gray-400 text-center">
                      {i18n.t("create_from_publishing_house_activities")}
                    </p>
                  </div>

                  <div
                    className="w-[250px] flex flex-col items-center gap-y-3 cursor-pointer p-10 border border-catchup-gray-100 hover:border-catchup-blue-500 rounded-catchup-xlarge transition-all duration-300 hover:shadow-input"
                    onClick={() => {
                      setCreateCatchxamSelectionState("ACTIVITY_LIST");
                      setShowCreateCatchxamSelectionModal(false);
                    }}
                  >
                    <BaseImage
                      src="/icons/activity.png"
                      alt="activity"
                      size="large"
                    />
                    <p className="font-semibold text-xl text-catchup-gray-400 text-center">
                      {i18n.t("create_from_activity_list")}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={i18n.t("catchxam_list")}
            description={i18n.t("catchxam_list_table_description_text")}
            itemName={i18n.t("catchxam")}
            headerList={[
              { name: i18n.t("name"), key: "name", type: "info" },
              {
                name: i18n.t("coterie_type"),
                key: "coterieType",
                type: "enumeration",
              },
              {
                name: i18n.t("level"),
                key: "level",
              },
              {
                name: i18n.t("activity_count"),
                key: "activityDTOList",
                type: "length",
              },
              {
                name: i18n.t("created_at"),
                key: "createdAt",
                type: "datetime",
              },
            ]}
            selectable={false}
            showFilter={true}
            showItemCount={selectedShowItemCount}
            totalItemCount={totalItemCount}
            pageNumber={pageNumber}
            loading={tableLoading}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleOnFilterChange={handleOnFilterChange}
            handleOnTableParamsChange={handleOnTableParamsChange}
            filterParams={{
              isFilterApplied,
              showBeginDate: true,
              beginAt: filterBeginAt,
              showEndDate: true,
              endAt: filterEndAt,
              showTextContains: true,
              textContains: filterTextContains,
              showDeleted: filterShowDeleted,
              showCoterieType: true,
              mainCoterieType: userProfile.coterieType
                ? userProfile.coterieType
                : null,
              coterieType: filterCoterieType,
              showLevel: true,
              level: filterLevel,
            }}
            itemList={catchxamDTOList}
            insertable={checkInsertPermissionGranted("catchxam_list")}
            handleInsertOnClick={handleToCreateCatchxamOnClick}
            updatable={checkUpdatePermissionGranted("catchxam_list")}
            handleUpdateOnClick={handleToUpdateCatchxamOnClick}
            deletable={checkDeletePermissionGranted("catchxam_list")}
            handleDeleteOnClick={handleRemoveCatchxamOnClick}
            previewable={true}
            handlePreviewOnClick={handlePreviewCatchxamOnClick}
            navigatable={checkCanNavigate()}
            navigateOptionList={constructNavigatableList()}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <FullCard isShadowed={true}>
            <div className="">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      handleReturnFromCatchxamOnClick();
                    }}
                  />
                </div>
                <BaseTitle
                  title={
                    id ? i18n.t("update_catchxam") : i18n.t("create_catchxam")
                  }
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <DividerLine />
              <div className="w-full">
                <p className="font-semibold text-lg">
                  {i18n.t("catchxam_information")}
                </p>
              </div>
              {SingleInformationIconTextItem(
                i18n.t("follow_a_format_to_provide_an_catchxam_name")
              )}
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={name}
                    onChange={(e) => {
                      handleOnChange("name", e.target.value);
                    }}
                    errorText={errorMap.name}
                  />
                </div>
              </div>
              {SingleInformationIconTextItem(
                i18n.t("follow_a_format_to_provide_an_catchxam_description")
              )}
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("description")}</p>
                </div>
                <div className="flex-1 h-catchup-description">
                  <InputGroup
                    type="textarea"
                    value={description}
                    onChange={(e) => {
                      handleOnChange("description", e.target.value);
                    }}
                    errorText={errorMap.description}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row flex-wrap gap-x-4">
                <div className="flex-1 flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("coterie_type")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="select"
                      value={selectedCoterieType}
                      optionList={filterCoterieTypeOptionList(
                        userInformation,
                        userProfile,
                        userProfileInstitution
                      )}
                      onChange={(event) => {
                        handleOnChange("coterieType", event.target.value);
                      }}
                      errorText={errorMap.coterieType}
                    />
                  </div>
                </div>
                <div className="flex-1 flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("level")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="select"
                      value={selectedLevel}
                      optionList={filterGradeLevelOptionList(
                        userProfileInstitution,
                        userProfileGrade
                      )}
                      onChange={(event) => {
                        handleOnChange("level", event.target.value);
                      }}
                      errorText={errorMap.level}
                    />
                  </div>
                </div>
              </div>

              {selectedCoterieType !== "DEFAULT_OPTION" &&
              selectedCoterieType !== "MANAGEMENT" &&
              selectedLevel !== "DEFAULT_OPTION" ? (
                <>
                  <DividerLine />

                  <div className="w-full">
                    <p className="font-semibold text-lg">
                      {i18n.t("activity_information")}
                    </p>
                  </div>
                  {SingleInformationIconTextItem(
                    i18n.t(
                      "you_can_choose_your_activities_from_your_activity_list_or_from_publishing_house_activities"
                    )
                  )}
                  <div className="w-full my-2">
                    <SelectionTab
                      optionList={[
                        {
                          id: 0,
                          title: i18n.t("activity_list"),
                        },
                        {
                          id: 1,
                          title: i18n.t("publishing_house_activity_list"),
                        },
                        {
                          id: 2,
                          title: `${i18n.t("selected_activities")} (${
                            selectedActivityList.length
                          })`,
                        },
                      ]}
                      selectedId={innerPageState}
                      handleSelectOnClick={(selectedId) => {
                        setInnerPageState(selectedId);
                      }}
                    />
                  </div>

                  {createCatchxamSelectionState !== null ? (
                    <div className="w-full">
                      {SingleInformationIconTextItem(
                        i18n.t("create_catchxam_from_plan_information_text_1")
                      )}
                      {SingleInformationIconTextItem(
                        i18n.t("create_catchxam_from_plan_information_text_2")
                      )}
                      {SingleInformationIconTextItem(
                        i18n.t("create_catchxam_from_plan_information_text_3")
                      )}
                      {SingleInformationIconTextItem(
                        i18n.t("create_catchxam_from_plan_information_text_4")
                      )}
                    </div>
                  ) : null}

                  <div className="w-full">
                    <div
                      className={`${innerPageState === 0 ? "block" : "hidden"}`}
                    >
                      <ListActivity
                        showTitle={false}
                        usePadding={false}
                        initialFilterApplied={true}
                        initialCoterieType={selectedCoterieType}
                        initialCategoryCode={selectedVersionCode}
                        initialSubCategoryCode={selectedSubCategoryCode}
                        categoryOccurrenceList={retrieveActivityListSelectedCategoryOccurrenceList()}
                        chooseActivityParams={chooseActivityParams}
                        showCoterieType={false}
                        initialLevel={selectedLevel}
                        showLevel={false}
                        showDeleted={false}
                        labelable={false}
                        showLabelList={true}
                        previewable={true}
                        handlePreviewOnClick={handlePreviewActivityOnClick}
                        selectable={true}
                        selectedItemIdList={selectedActivityList.map(
                          (selectedActivity) => selectedActivity.id
                        )}
                        handleSelectOnClick={handleAddActivityToCatchxam}
                        handleInitialSelectListOnClick={
                          handleInitialActivityListToCatchxam
                        }
                        activityTemplateTypeList={["MCSA"]}
                      />
                    </div>
                    <div
                      className={`${innerPageState === 1 ? "block" : "hidden"}`}
                    >
                      <ListActivityBundle
                        usePadding={false}
                        initialFilterApplied={true}
                        initialCoterieType={selectedCoterieType}
                        initialLevel={selectedLevel}
                        selectable={true}
                        selectedItemIdList={selectedActivityList.map(
                          (selectedActivity) => selectedActivity.id
                        )}
                        categoryOccurrenceList={retrievePublishingHouseSelectedCategoryOccurrenceList()}
                        chooseActivityParams={chooseActivityParams}
                        handleSelectOnClick={handleAddActivityToCatchxam}
                        handleInitialSelectListOnClick={
                          handleInitialActivityListToCatchxam
                        }
                        activityTemplateTypeList={["MCSA"]}
                      />
                    </div>
                    <div
                      className={`${innerPageState === 2 ? "block" : "hidden"}`}
                    >
                      {selectedActivityList.map((selectedActivity, index) => (
                        <div className="" key={selectedActivity.id}>
                          <SingleActivityItem
                            number={index + 1}
                            language={language}
                            activityDTO={selectedActivity}
                            showActivityCount={true}
                            showActivityTemplate={true}
                            activityCountDTOList={activityCountDTOList}
                            categorySet={categorySet}
                            handlePreviewActivityOnClick={
                              handlePreviewActivityOnClick
                            }
                            handleRemoveActivityOnClick={
                              handleRemoveActivityFromCatchxam
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="mt-4 ml-auto">
                    {id ? (
                      <SecondaryButton
                        title={i18n.t("update")}
                        size="medium"
                        onClick={handleUpdateCatchxamOnClick}
                      />
                    ) : (
                      <CreateButton
                        title={i18n.t("create")}
                        size="medium"
                        onClick={handleCreateCatchxamOnClick}
                      />
                    )}
                  </div>
                </>
              ) : null}
            </div>
          </FullCard>
        </div>
      </div>
    );
  };

  const pagedQueryParams = constructPagedQueryParams();

  return (
    <div className="flex-1 flex flex-col">
      {RenderShowSomeoneUpdatedModal()}
      {RenderCreateCatchxamSelectionModal()}
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default CatchxamListScreen;
