import i18n from "../../language/i18n";

const Error = (props) => {
  const { statusCode, statusText } = props;

  return (
    <div className="flex flex-col justify-center items-center">
      <p className="text-6xl text-catchup-red my-5">{i18n.t("ooops_text")}</p>
      <div className="text-center my-5">
        <p className="italic">{i18n.t("unexcepted_error_text")}</p>
        <p className="">
          ({statusCode ? `${statusCode} - ` : null}
          {statusText})
        </p>
      </div>
    </div>
  );
};

export default Error;
