import { useEffect, useState } from "react";
import FullCard from "../../components/cards/FullCard";
import InputGroup from "../../components/groups/InputGroup";
import i18n from "../../language/i18n";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import DividerLine from "../../components/divider/DividerLine";
import { queryLabelActionDTOInDetailByBrandLabelIdListAllActivities } from "../../requests/LabelRequests";
import { Line } from "react-chartjs-2";
import {
  ONE_DAY,
  ONE_HOUR,
  ONE_MONTH,
  ONE_WEEK,
  THREE_MONTHS,
  retrieveDateIntervalOptionList,
} from "../../utilization/DateUtilization";
import RenderInformationCardItem from "../../components/cards/InformationCard";
import BaseImage from "../../components/images/BaseImage";
import BaseLoading from "../../components/loading/BaseLoading";

const BrandLabelVisualizerScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const { language, userBrandLabelSet } = useAuth();
  const [selectedDateInterval, setSelectedDateInterval] = useState("LAST_DAY");
  const [includedBrandLabelIdList, setIncludedBrandLabelIdList] = useState([]);
  const [labelUsageDTOList, setLabelUsageDTOList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("brand_label_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetBrandLabelItemDTOList = async () => {
      setLoading(true);
      const { data, err } =
        await queryLabelActionDTOInDetailByBrandLabelIdListAllActivities({
          brandLabelIdList: includedBrandLabelIdList,
          itemType: "STORAGE_FILE",
        });
      if (err) {
        console.log(err);
        return;
      }
      setLabelUsageDTOList(data);
      setLoading(false);
    };
    if (includedBrandLabelIdList.length === 0) {
      setLabelUsageDTOList([]);
    } else {
      retrieveAndSetBrandLabelItemDTOList();
    }
  }, [includedBrandLabelIdList]);

  const retrieveDayIntervalArray = () => {
    const intervalArray = [];
    if (selectedDateInterval === "LAST_DAY") {
      for (let i = 22; i >= -1; i--) {
        const iHourAgo = new Date().getTime() - i * ONE_HOUR;
        const currentTimestamp = new Date(
          new Date(iHourAgo).getFullYear(),
          new Date(iHourAgo).getUTCMonth(),
          new Date(iHourAgo).getDate(),
          new Date(iHourAgo).getHours(),
          0,
          0
        ).getTime();
        intervalArray.push({
          timestamp: currentTimestamp,
        });
      }
    } else if (selectedDateInterval === "LAST_WEEK") {
      for (let i = 166; i >= -1; i--) {
        const iHourAgo = new Date().getTime() - i * ONE_HOUR;
        const currentTimestamp = new Date(
          new Date(iHourAgo).getFullYear(),
          new Date(iHourAgo).getUTCMonth(),
          new Date(iHourAgo).getDate(),
          new Date(iHourAgo).getHours(),
          0,
          0
        ).getTime();
        intervalArray.push({
          timestamp: currentTimestamp,
        });
      }
    } else if (selectedDateInterval === "LAST_MONTH") {
      for (let i = 28; i >= -1; i--) {
        const iDayAgo = new Date().getTime() - i * ONE_DAY;
        const currentTimestamp = new Date(
          new Date(iDayAgo).getFullYear(),
          new Date(iDayAgo).getUTCMonth(),
          new Date(iDayAgo).getDate(),
          0,
          0,
          0
        ).getTime();
        intervalArray.push({
          timestamp: currentTimestamp,
        });
      }
    } else if (selectedDateInterval === "LAST_THREE_MONTHS") {
      for (let i = 88; i >= -1; i--) {
        const iDayAgo = new Date().getTime() - i * ONE_DAY;
        const currentTimestamp = new Date(
          new Date(iDayAgo).getFullYear(),
          new Date(iDayAgo).getUTCMonth(),
          new Date(iDayAgo).getDate(),
          0,
          0,
          0
        ).getTime();
        intervalArray.push({
          timestamp: currentTimestamp,
        });
      }
    }
    return intervalArray;
  };

  const retrieveLabelUsedLineData = () => {
    const labels = dayIntervalArray.map((dayInterval) =>
      new Date(dayInterval.timestamp).toLocaleString(language)
    );
    return {
      labels,
      datasets: [
        {
          label: i18n.t("amount"),
          data: labelData,
          borderColor: "rgb(17, 72, 89)",
          backgroundColor: "rgba(17, 72, 89, 0.5)",
        },
      ],
    };
  };

  const retrieveLabelUsedLineOptions = () => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: i18n.t("line_graphic_label_used"),
        },
      },
    };
  };

  const filterByTimeInterval = () => {
    if (selectedDateInterval === "LAST_DAY") {
      return labelUsageDTOList.filter(
        (labelUsageDTO) =>
          labelUsageDTO.activityCreatedAt > new Date().getTime() - ONE_DAY
      );
    } else if (selectedDateInterval === "LAST_WEEK") {
      return labelUsageDTOList.filter(
        (labelUsageDTO) =>
          labelUsageDTO.activityCreatedAt > new Date().getTime() - ONE_WEEK
      );
    } else if (selectedDateInterval === "LAST_MONTH") {
      return labelUsageDTOList.filter(
        (labelUsageDTO) =>
          labelUsageDTO.activityCreatedAt > new Date().getTime() - ONE_MONTH
      );
    } else if (selectedDateInterval === "LAST_THREE_MONTHS") {
      return labelUsageDTOList.filter(
        (labelUsageDTO) =>
          labelUsageDTO.activityCreatedAt > new Date().getTime() - THREE_MONTHS
      );
    }

    return [];
  };

  const filterBrandLabelOptionList = () => {
    return userBrandLabelSet.map((item) => ({
      text: item.name,
      value: item.id,
    }));
  };

  const constructLabelLineData = () => {
    const labelData = [];
    for (let i = 0; i < dayIntervalArray.length - 1; i++) {
      let filteredBrandLabelDTOItemList = filteredByTimeInterval.filter(
        (filteredBrandLabelDTOItem) =>
          filteredBrandLabelDTOItem.activityCreatedAt >
            dayIntervalArray[i].timestamp &&
          filteredBrandLabelDTOItem.activityCreatedAt <
            dayIntervalArray[i + 1].timestamp
      );
      labelData.push(filteredBrandLabelDTOItemList.length);
    }

    return { labelData };
  };

  const calculateTotalLabelUsed = () => {
    let totalLabelUsed = 0;
    labelData.forEach((item) => {
      totalLabelUsed += item;
    });
    return totalLabelUsed;
  };

  const handleAddBrandLabelOnClick = (brandLabelId) => {
    const foundUserBrandLabel = userBrandLabelSet.find(
      (userBrandLabel) => userBrandLabel.id === brandLabelId
    );
    if (foundUserBrandLabel) {
      const foundIndex = includedBrandLabelIdList.findIndex(
        (includedBrandLabelId) => includedBrandLabelId === brandLabelId
      );
      if (foundIndex === -1) {
        includedBrandLabelIdList.push(brandLabelId);
        setIncludedBrandLabelIdList(
          JSON.parse(JSON.stringify(includedBrandLabelIdList))
        );
      }
    }
  };

  const handleRemoveBrandLabelOnClick = (brandLabelId) => {
    const foundIndex = includedBrandLabelIdList.findIndex(
      (includedBrandLabelId) => includedBrandLabelId === brandLabelId
    );
    if (foundIndex !== -1) {
      includedBrandLabelIdList.splice(foundIndex, 1);
      setIncludedBrandLabelIdList(
        JSON.parse(JSON.stringify(includedBrandLabelIdList))
      );
    }
  };

  const handleOnChange = (type, value) => {
    if (type === "date_interval") {
      setSelectedDateInterval(value);
    }
  };

  const RenderBrandLabelListContent = () => {
    return (
      <div className="flex flex-row flex-wrap items-center gap-x-2">
        {includedBrandLabelIdList.map((includedBrandLabelId, index) => {
          const foundLabel = userBrandLabelSet.find(
            (userBrandLabel) => userBrandLabel.id === includedBrandLabelId
          );
          if (!foundLabel) return;
          return (
            <div
              key={index}
              className={`px-3 py-1 gap-x-3 border border-catchup-red rounded-md my-2 cursor-pointer`}
              onClick={() => handleRemoveBrandLabelOnClick(foundLabel.id)}
            >
              <p className="text-sm">{foundLabel.name}</p>
            </div>
          );
        })}
      </div>
    );
  };

  const RenderMainContent = () => {
    return (
      <FullCard isShadowed={true}>
        <div className="flex flex-row flex-wrap items-center">
          <div className="w-1/2 lg:w-1/4">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("label")}
                placeholder={null}
                value={null}
                optionList={filterBrandLabelOptionList()}
                onChange={(event) => {
                  handleAddBrandLabelOnClick(parseFloat(event.target.value));
                }}
              />
            </div>
          </div>
          <div className="flex-1">{RenderBrandLabelListContent()}</div>
        </div>
        <DividerLine />
        <div className="flex flex-row flex-wrap items-center mb-3">
          <div className="w-1/2 lg:w-1/3">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("date_interval")}
                placeholder={i18n.t("date_interval")}
                value={selectedDateInterval}
                optionList={retrieveDateIntervalOptionList()}
                onChange={(event) => {
                  handleOnChange("date_interval", event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <>
            <div className="flex flex-row flex-wrap">
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                {RenderInformationCardItem(
                  i18n.t("total_label_used"),
                  null,
                  <BaseImage src="/icons/label.png" alt="label" size="small" />,
                  totalTokenSpent,
                  1
                )}
              </div>
            </div>
            <DividerLine />
            <Line
              options={retrieveLabelUsedLineOptions()}
              data={retrieveLabelUsedLineData()}
            />
          </>
        )}
      </FullCard>
    );
  };

  const dayIntervalArray = retrieveDayIntervalArray();
  const filteredByTimeInterval = filterByTimeInterval();
  const { labelData } = constructLabelLineData();
  const totalTokenSpent = calculateTotalLabelUsed();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default BrandLabelVisualizerScreen;
