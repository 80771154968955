import { useEffect, useState } from "react";
import { useApp } from "../../context/AppContextProvider";
import Error from "../../components/errors/Error";
import BaseCard from "../../components/cards/BaseCard";
import i18n from "../../language/i18n";
import { useParams } from "react-router-dom";
import Success from "../../components/successes/Success";
import { patchAndVerifyUserEmail } from "../../requests/ManagementRequests";
import BaseImage from "../../components/images/BaseImage";
import RightsReserved from "../../components/landing/RightsReserved";
import BaseLoading from "../../components/loading/BaseLoading";

const EmailVerificationScreen = (props) => {
  const { emailVerificationBase64Code } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [emailVerificationCode, setEmailVerificationCode] = useState(null);
  const [status, setStatus] = useState("WAITING");

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  useEffect(() => {
    if (emailVerificationBase64Code) {
      try {
        let emailVerificationCode = atob(emailVerificationBase64Code);
        if (
          emailVerificationCode.startsWith("{") &&
          emailVerificationCode.endsWith("}")
        ) {
          emailVerificationCode = emailVerificationCode.substring(
            1,
            emailVerificationCode.length - 1
          );
          const splittedEmailVerificationCodeList =
            emailVerificationCode.split(",");
          let emailVerificationMap = {};
          splittedEmailVerificationCodeList.forEach((part) => {
            emailVerificationMap[part.trim().split("=")[0]] = `${
              part.trim().split("=")[1]
            }`;
          });
          setEmailVerificationCode(emailVerificationMap);
        }
      } catch (err) {
        setStatus("FAILED");
      }
    } else {
      setEmailVerificationCode(null);
      setStatus("FAILED");
    }
  }, [emailVerificationBase64Code]);

  useEffect(() => {
    const patchGeneratedCodeByParams = async () => {
      const { data, err } = await patchAndVerifyUserEmail({
        id: emailVerificationCode.id,
        userId: emailVerificationCode.userId,
        type: "EMAIL_VERIFICATION",
        code: emailVerificationCode.code,
      });
      if (err) {
        console.log(err);
        setStatus("FAILED");
      } else {
        setStatus("SUCCESS");
      }
    };
    if (emailVerificationCode) {
      patchGeneratedCodeByParams();
    }
  }, [emailVerificationCode]);

  return (
    <BaseCard>
      <BaseImage
        size="custom"
        className="h-20 w-72 mx-auto"
        src="/logos/logo-primary.png"
        alt="logo-primary"
      />
      {status === "WAITING" ? (
        <BaseLoading size="medium" color="#57C2D3" secondaryColor="#57C2D3" />
      ) : status === "FAILED" ? (
        <>
          <div className="flex justify-center my-5">
            <Error
              statusCode={null}
              statusText={i18n.t("email_verification_failed_text")}
            />
          </div>
        </>
      ) : status === "SUCCESS" ? (
        <>
          <div className="flex justify-center my-5">
            <Success
              statusText={i18n.t("email_verification_successfully_text")}
            />
          </div>
        </>
      ) : null}

      <div className="mt-auto">
        <RightsReserved />
      </div>
    </BaseCard>
  );
};

export default EmailVerificationScreen;
