import { useCallback, useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import FullCard from "../../components/cards/FullCard";
import InputGroup from "../../components/groups/InputGroup";
import AdvancedTable from "../../components/tables/AdvancedTable";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  createBranchByGradeId,
  deleteBranch,
  patchBranch,
  queryActiveGradeListByBrandId,
  queryActiveGradeListByCampusId,
  queryActiveGradeListByInstitutionId,
  queryActiveGradeListBySeasonId,
  queryAllBranchDTOPageByFilter,
} from "../../requests/ManagementRequests";
import {
  checkDeletePermissionGranted,
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import debounce from "lodash.debounce";
import CreateButton from "../../components/buttons/CreateButton";
import { retrieveGradeDTOOptionList } from "../../utilization/ManagementUtilization";

const BranchListScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userInformation,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileSeason,
    userProfileGrade,
  } = useAuth();
  const [branchDTOList, setBranchDTOList] = useState([]);
  const [gradeDTOList, setGradeDTOList] = useState([]);
  const [pageState, setPageState] = useState(0);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedGradeId, setSelectedGradeId] = useState("DEFAULT_OPTION");
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterTextContains, setFilterTextContains] = useState(null);
  const [filterActive, setFilterActive] = useState(true);
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [debounceState, setDebounceState] = useState("");
  const [errorMap, setErrorMap] = useState({
    name: null,
    description: null,
    gradeId: null,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetGradeDTOByBrandId = async () => {
      const { data, err } = await queryActiveGradeListByBrandId(
        userProfileBrand.id
      );
      if (err) {
        console.log(err);
      } else {
        setGradeDTOList(data);
      }
    };
    const retrieveAndSetGradeDTOByCampusId = async () => {
      const { data, err } = await queryActiveGradeListByCampusId(
        userProfileCampus.id
      );
      if (err) {
        console.log(err);
      } else {
        setGradeDTOList(data);
      }
    };
    const retrieveAndSetGradeDTOByInstitutionId = async () => {
      const { data, err } = await queryActiveGradeListByInstitutionId(
        userProfileInstitution.id
      );
      if (err) {
        console.log(err);
      } else {
        setGradeDTOList(data);
      }
    };
    const retrieveAndSetGradeDTOBySeasonId = async () => {
      const { data, err } = await queryActiveGradeListBySeasonId(
        userProfileSeason.id
      );
      if (err) {
        console.log(err);
      } else {
        setGradeDTOList(data);
      }
    };
    if (checkViewPermissionGranted("branch_list")) {
      if (userProfileGrade) {
        setGradeDTOList([userProfileGrade]);
      } else if (userProfileSeason) {
        retrieveAndSetGradeDTOBySeasonId();
      } else if (userProfileInstitution) {
        retrieveAndSetGradeDTOByInstitutionId();
      } else if (userProfileCampus) {
        retrieveAndSetGradeDTOByCampusId();
      } else if (userProfileBrand) {
        retrieveAndSetGradeDTOByBrandId();
      }
    } else {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    retrieveAndSetBranchPageByFilter();
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    setDebounceState("BASE_FILTER");
    retrieveAndSetBranchPageByFilter();
  }, [
    isFilterApplied,
    filterBeginAt,
    filterEndAt,
    filterActive,
    filterShowDeleted,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    if (filterTextContains) {
      setDebounceState("TEXT_CONTAINS");
      debouncedRetrieveAndSetBranchPageByFilterDebounce(filterTextContains);
    } else {
      retrieveAndSetBranchPageByFilter();
    }
  }, [filterTextContains]);

  const retrieveAndSetBranchPageByFilter = async () => {
    setTableLoading(true);
    const { data, err } = await queryAllBranchDTOPageByFilter(pagedQueryParams);
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setBranchDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const retrieveAndSetBranchPageByFilterDebounce = async (
    filterTextContains
  ) => {
    setTableLoading(true);
    if (isFilterApplied) {
      pagedQueryParams.params.textContains = filterTextContains.trim();
    }
    const { data, err } = await queryAllBranchDTOPageByFilter(pagedQueryParams);
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setBranchDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const debouncedRetrieveAndSetBranchPageByFilterDebounce = useCallback(
    debounce(function (textContains) {
      retrieveAndSetBranchPageByFilterDebounce(textContains);
    }, 1000),
    [debounceState]
  );

  const checkCommonValidation = () => {
    if (
      !name ||
      name.trim() === "" ||
      !description ||
      description.trim() === "" ||
      selectedGradeId === "DEFAULT_OPTION"
    ) {
      const currentErrorMap = {
        name: null,
        description: null,
        gradeId: null,
      };
      if (!name || name.trim() === "") {
        currentErrorMap.name = i18n.t("name_required_field");
      }
      if (!description || description.trim() === "") {
        currentErrorMap.description = i18n.t("description_required_field");
      }
      if (selectedGradeId === "DEFAULT_OPTION") {
        currentErrorMap.gradeId = i18n.t("grade_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const checkCanNavigate = () => {
    let canNavigate = true;
    if (!checkViewPermissionGranted("branch_list")) {
      canNavigate = false;
    }
    return canNavigate;
  };

  const constructNavigatableList = () => {
    let navigateList = [];
    if (checkViewPermissionGranted("branch_list")) {
      navigateList.push({
        id: 0,
        text: i18n.t("to_branch_detail"),
        icon: (
          <BaseImage
            src="/icons/navigate-gray.png"
            alt="navigate"
            size="small"
          />
        ),
        value: "/branches/{id}/detail",
      });
      const { accountType } = userInformation;
      if (accountType === "GENIXO") {
        if (branchDTOList.length > 0) {
          const branchDTO = branchDTOList[0];
          const { gradeDTO } = branchDTO;
          const { seasonDTO } = gradeDTO;
          const { institutionDTO } = seasonDTO;
          const { institutionSettingsDTO } = institutionDTO;
          const { institutionIntegrationSettingsDTO } = institutionSettingsDTO;
          const { k12Id } = institutionIntegrationSettingsDTO;
          if (k12Id) {
            navigateList.push({
              id: 1,
              text: i18n.t("to_learner_transfer_from_k12_screen"),
              icon: (
                <BaseImage
                  src="/icons/navigate-gray.png"
                  alt="navigate"
                  size="small"
                />
              ),
              value: "/branches/{id}/transfer-learner/from-k12",
            });
          }
        }
      }
    }
    return navigateList;
  };

  const constructPagedQueryParams = () => {
    return {
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        beginAt:
          isFilterApplied && filterBeginAt
            ? new Date(filterBeginAt).getTime()
            : null,
        endAt:
          isFilterApplied && filterEndAt
            ? new Date(filterEndAt).getTime()
            : null,
        textContains:
          isFilterApplied && filterTextContains ? filterTextContains : null,
        deleted: isFilterApplied ? (filterShowDeleted ? null : false) : false,
        brandId: userProfileBrand.id,
        campusId: userProfileCampus?.id,
        institutionId: userProfileInstitution?.id,
        seasonId: userProfileSeason?.id,
        gradeId: userProfileGrade?.id,
        isActive: isFilterApplied ? filterActive : true,
      },
    };
  };

  const resetParams = () => {
    setId(null);
    setName(null);
    setDescription(null);
    setSelectedGradeId("DEFAULT_OPTION");
  };

  const handleOnChange = (type, value) => {
    if (type === "name") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: i18n.t("name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: null,
        }));
      }
      setName(value);
    } else if (type === "description") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: i18n.t("description_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: null,
        }));
      }
      setDescription(value);
    } else if (type === "gradeId") {
      setSelectedGradeId(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        gradeId: null,
      }));
    } else {
    }
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "textContains") {
      setFilterTextContains(value);
    } else if (type === "active") {
      setFilterActive(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleToCreateBranchOnClick = () => {
    setId(null);
    setPageState(1);
  };

  const handleToUpdateBranchOnClick = (branchDTO) => {
    setId(branchDTO.id);
    setName(branchDTO.name);
    setDescription(branchDTO.description);
    setSelectedGradeId(branchDTO.gradeDTO.id);
    setPageState(1);
  };

  const handleUpdateBranchOnClick = async () => {
    if (checkUpdatePermissionGranted("branch_list")) {
      if (checkCommonValidation()) {
        const currentBranch = {
          id,
          name,
          description,
          gradeId: selectedGradeId,
        };
        const { data, err } = await patchBranch(currentBranch);
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          retrieveAndSetBranchPageByFilter();
          handleReturnFromBranchOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleCreateBranchOnClick = async () => {
    if (checkInsertPermissionGranted("branch_list")) {
      if (checkCommonValidation()) {
        const newBranch = {
          name,
          description,
          grade: {
            id: selectedGradeId,
          },
        };
        const { data, err } = await createBranchByGradeId(newBranch);
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          retrieveAndSetBranchPageByFilter();
          handleReturnFromBranchOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleRemoveBranchOnClick = async (branchId) => {
    if (checkDeletePermissionGranted("branch_list")) {
      const { err } = await deleteBranch(branchId);
      if (err) {
      } else {
        const foundBranchDTO = branchDTOList.find(
          (branchDTO) => branchDTO.id === branchId
        );
        foundBranchDTO.deleted = true;
        setBranchDTOList(JSON.parse(JSON.stringify(branchDTOList)));
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_delete"));
    }
  };

  const handleReturnFromBranchOnClick = () => {
    resetParams();
    setPageState(0);
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={i18n.t("branch_list")}
            description={i18n.t("branch_list_table_description_text")}
            itemName={i18n.t("branch")}
            headerList={[
              { name: i18n.t("name"), key: "name", type: "info" },
              { name: i18n.t("grade_name"), key: "gradeDTO.name" },
              { name: i18n.t("season_name"), key: "gradeDTO.seasonDTO.name" },
              {
                name: i18n.t("institution_name"),
                key: "gradeDTO.seasonDTO.institutionDTO.name",
              },
              {
                name: i18n.t("created_at"),
                key: "createdAt",
                type: "datetime",
              },
            ]}
            selectable={false}
            showFilter={true}
            showItemCount={selectedShowItemCount}
            totalItemCount={totalItemCount}
            pageNumber={pageNumber}
            loading={tableLoading}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleOnFilterChange={handleOnFilterChange}
            handleOnTableParamsChange={handleOnTableParamsChange}
            filterParams={{
              isFilterApplied,
              showBeginDate: true,
              beginAt: filterBeginAt,
              showEndDate: true,
              endAt: filterEndAt,
              showTextContains: true,
              textContains: filterTextContains,
              showActive: true,
              active: filterActive,
              showDeleted: filterShowDeleted,
            }}
            itemList={branchDTOList}
            insertable={checkInsertPermissionGranted("branch_list")}
            handleInsertOnClick={handleToCreateBranchOnClick}
            updatable={checkUpdatePermissionGranted("branch_list")}
            handleUpdateOnClick={handleToUpdateBranchOnClick}
            deletable={checkDeletePermissionGranted("branch_list")}
            handleDeleteOnClick={handleRemoveBranchOnClick}
            navigatable={checkCanNavigate()}
            navigateOptionList={constructNavigatableList()}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <FullCard isShadowed={true}>
            <div className="">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      handleReturnFromBranchOnClick();
                    }}
                  />
                </div>
                <BaseTitle
                  title={id ? i18n.t("update_branch") : i18n.t("create_branch")}
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <DividerLine />
              <div className="w-full">
                <p className="font-semibold text-lg">
                  {i18n.t("branch_information")}
                </p>
              </div>
              <div className="w-full flex flex-row items-center gap-x-2 my-2">
                <BaseImage src="/icons/info.png" alt="info" size="small" />
                <div className="flex-1">
                  <p className="">
                    {i18n.t("branch_name_detailed_explaination_text")}
                  </p>
                </div>
              </div>
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={name}
                    onChange={(e) => {
                      handleOnChange("name", e.target.value);
                    }}
                    errorText={errorMap.name}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("description")}</p>
                </div>
                <div className="flex-1 h-catchup-description">
                  <InputGroup
                    type="textarea"
                    value={description}
                    onChange={(e) => {
                      handleOnChange("description", e.target.value);
                    }}
                    errorText={errorMap.description}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("grade_name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={selectedGradeId}
                    optionList={retrieveGradeDTOOptionList(gradeDTOList)}
                    onChange={(event) => {
                      handleOnChange("gradeId", event.target.value);
                    }}
                    errorText={errorMap.gradeId}
                  />
                </div>
              </div>

              <div className="mt-4 ml-auto">
                {id ? (
                  <SecondaryButton
                    title={i18n.t("update")}
                    size="medium"
                    onClick={handleUpdateBranchOnClick}
                  />
                ) : (
                  <CreateButton
                    title={i18n.t("create")}
                    size="medium"
                    onClick={handleCreateBranchOnClick}
                  />
                )}
              </div>
            </div>
          </FullCard>
        </div>
      </div>
    );
  };

  const pagedQueryParams = constructPagedQueryParams();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default BranchListScreen;
