import BaseImage from "../images/BaseImage";

const SelectionCheckbox = (props) => {
  const {
    optionList,
    selectedIdList,
    handleSelectOnClick,
    handleRemoveOnClick,
  } = props;

  return (
    <div className="flex flex-row items-center gap-x-4 gap-y-2 flex-wrap text-center">
      {optionList.map((option, index) => (
        <div
          key={index}
          className={`${
            selectedIdList.findIndex((selectedId) => selectedId === option.id) >
            -1
              ? "border-catchup-blue-400"
              : "border-catchup-gray-100 hover:border-catchup-blue-500"
          } border-2 rounded-catchup-xlarge py-3 px-6 cursor-pointer duration-300 transition-all`}
          onClick={() => {
            if (
              selectedIdList.findIndex(
                (selectedId) => selectedId === option.id
              ) === -1
            ) {
              handleSelectOnClick(option.id);
            } else {
              handleRemoveOnClick(option.id);
            }
          }}
        >
          <div
            className={`flex flex-row items-center gap-x-1 ${
              selectedIdList.findIndex(
                (selectedId) => selectedId === option.id
              ) > -1
                ? "opacity-100"
                : "opacity-50"
            }`}
          >
            <div>
              <BaseImage
                src={
                  selectedIdList.findIndex(
                    (selectedId) => selectedId === option.id
                  ) > -1
                    ? "/icons/checkbox.png"
                    : "/icons/checkbox-empty.png"
                }
                alt="checkbox"
                size="small"
              />
            </div>
            <div className="flex-1">
              <p>{option.text}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectionCheckbox;
