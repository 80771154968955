import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import AdvancedTable from "../../components/tables/AdvancedTable";
import { useApp } from "../../context/AppContextProvider";
import i18n from "../../language/i18n";
import { queryAllActivityTemplateDTOPageByFilter } from "../../requests/CatchtivityRequests";
import {
  checkDeletePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import BaseImage from "../../components/images/BaseImage";

const ActivityTemplateListScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [activityTemplateDTOList, setActivityTemplateDTOList] = useState([]);
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [pageState, setPageState] = useState(0);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (checkViewPermissionGranted("activity_template_list")) {
    } else {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    retrieveAndSetActivityTemplatePageByFilter();
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
  ]);

  useEffect(() => {
    if (isFilterApplied) {
      retrieveAndSetActivityTemplatePageByFilter();
    }
  }, [isFilterApplied, filterBeginAt, filterEndAt, filterShowDeleted]);

  const retrieveAndSetActivityTemplatePageByFilter = async () => {
    setTableLoading(true);
    const { data, err } = await queryAllActivityTemplateDTOPageByFilter({
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        beginAt:
          isFilterApplied && filterBeginAt
            ? new Date(filterBeginAt).getTime()
            : null,
        endAt:
          isFilterApplied && filterEndAt
            ? new Date(filterEndAt).getTime()
            : null,
        deleted: isFilterApplied ? (filterShowDeleted ? null : false) : false,
      },
    });
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setActivityTemplateDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const checkCanNavigate = () => {
    let canNavigate = true;
    if (!checkViewPermissionGranted("activity_template_rule_list")) {
      canNavigate = false;
    }
    return canNavigate;
  };

  const constructNavigatableList = () => {
    let navigateList = [];
    if (checkViewPermissionGranted("activity_template_rule_list")) {
      navigateList.push({
        id: 0,
        text: i18n.t("to_activity_template_rule_list_screen"),
        icon: (
          <BaseImage
            src="/icons/navigate-gray.png"
            alt="navigate"
            size="small"
          />
        ),
        value: "/activity-templates/{id}/rules",
      });
    }
    return navigateList;
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleToCreateActivityTemplateOnClick = () => {
    setId(null);
    setPageState(1);
  };

  const handleToUpdateActivityTemplateOnClick = (activityTemplateDTO) => {
    setId(activityTemplateDTO.id);
    setName(activityTemplateDTO.name);
    setDescription(activityTemplateDTO.description);
    setPageState(1);
  };

  const handleRemoveActivityTemplateOnClick = async (activityTemplateId) => {
    if (checkDeletePermissionGranted("activity_template_list")) {
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_delete"));
    }
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={i18n.t("activity_template_list")}
            description={i18n.t(
              "activity_document_list_table_description_text"
            )}
            itemName={i18n.t("activity_template")}
            headerList={[
              { name: i18n.t("name"), key: "name", type: "info" },
              { name: i18n.t("type"), key: "type", type: "enumeration" },
              { name: i18n.t("version"), key: "version" },
              { name: i18n.t("version_code"), key: "versionCode" },
              {
                name: i18n.t("created_at"),
                key: "createdAt",
                type: "datetime",
              },
            ]}
            selectable={false}
            showFilter={true}
            showItemCount={selectedShowItemCount}
            totalItemCount={totalItemCount}
            pageNumber={pageNumber}
            loading={tableLoading}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleOnFilterChange={handleOnFilterChange}
            handleOnTableParamsChange={handleOnTableParamsChange}
            filterParams={{
              isFilterApplied,
              showBeginDate: true,
              beginAt: filterBeginAt,
              showEndDate: true,
              endAt: filterEndAt,
              showDeleted: filterShowDeleted,
            }}
            itemList={activityTemplateDTOList}
            insertable={false}
            handleInsertOnClick={handleToCreateActivityTemplateOnClick}
            updatable={false}
            handleUpdateOnClick={handleToUpdateActivityTemplateOnClick}
            deletable={false}
            handleDeleteOnClick={handleRemoveActivityTemplateOnClick}
            navigatable={checkCanNavigate()}
            navigateOptionList={constructNavigatableList()}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}></div>
      </div>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ActivityTemplateListScreen;
