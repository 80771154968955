import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

export const queryIndividualModalByParams = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base["ai-model-constructor"].url}${endpoints["ai-model-constructor"].context}${endpoints["ai-model-constructor"]["individual-models"]["query"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
