import { useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import FullCard from "../../components/cards/FullCard";
import InputGroup from "../../components/groups/InputGroup";
import { AppContext, useApp } from "../../context/AppContextProvider";
import i18n from "../../language/i18n";
import {
  addOrRemoveBaseRuleToActivityTemplate,
  retrieveActivityTemplateDTOById,
  retrieveAllBaseRuleList,
} from "../../requests/CatchtivityRequests";
import {
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import BaseImage from "../../components/images/BaseImage";

const ActivityTemplateRuleListScreen = () => {
  const { activityTemplateId } = useParams();
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [activityTemplateDTO, setActivityTemplateDTO] = useState(null);
  const [baseRuleDTOList, setBaseRuleDTOList] = useState([]);
  const [includedRuleKeyList, setIncludedRuleKeyList] = useState([]);
  const [removedRuleKeyList, setRemovedRuleKeyList] = useState([]);
  const [selectedValueOption, setSelectedValueOption] =
    useState("DEFAULT_OPTION");

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (checkViewPermissionGranted("activity_template_rule_list")) {
    } else {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetBaseRuleListById = async () => {
      const { data, err } = await retrieveActivityTemplateDTOById(
        activityTemplateId
      );
      if (err) {
        console.log(err);
      } else {
        setActivityTemplateDTO(data);
      }
    };
    if (activityTemplateId) {
      retrieveAndSetBaseRuleListById();
    }
  }, [activityTemplateId]);

  useEffect(() => {
    const retrieveAndSetAllBaseRuleList = async () => {
      const { data, err } = await retrieveAllBaseRuleList();
      if (err) {
        console.log(err);
      } else {
        setBaseRuleDTOList(data);
      }
    };
    retrieveAndSetAllBaseRuleList();
  }, []);

  const retrieveParentKey = () => {
    return "MUST_KEY_EXISTS_MAP";
  };

  const retrieveParentValue = () => {
    if (activityTemplateDTO) {
      if (activityTemplateDTO.type === "ORDERING") {
        return "orderingMaterialMap";
      } else if (activityTemplateDTO.type === "DROPDOWN") {
        return "dropdownMaterialMap";
      } else if (activityTemplateDTO.type === "MCSA") {
        return "MCSAMaterialMap";
      } else if (activityTemplateDTO.type === "MCMA") {
        return "MCMAMaterialMap";
      } else if (activityTemplateDTO.type === "MATCHING") {
        return "matchingMaterialMap";
      } else if (activityTemplateDTO.type === "GROUPING") {
        return "groupingMaterialMap";
      } else if (activityTemplateDTO.type === "FILL_IN_THE_BLANKS") {
        return "fillInTheBlanksMaterialMap";
      } else if (activityTemplateDTO.type === "OPEN_ENDED") {
        return "openEndedMaterialMap";
      } else if (activityTemplateDTO.type === "TRUE_FALSE") {
        return "trueFalseMaterialMap";
      }
    }
  };

  const retrieveValueOptionList = () => {
    const valueOptionList = [];
    if (activityTemplateDTO) {
      activityTemplateDTO.baseRuleDTOList.forEach((activityTemplateRule) => {
        let currentValue;
        if (activityTemplateRule.value === null) {
          currentValue = "";
        } else {
          currentValue = activityTemplateRule.value;
        }
        const foundIndex = valueOptionList.findIndex(
          (valueOption) => valueOption.value === currentValue
        );
        if (foundIndex === -1) {
          valueOptionList.push({
            text: currentValue,
            value: currentValue,
          });
        }
      });
    }

    return valueOptionList;
  };

  const retrieveIncludedRuleList = () => {
    let filteredCurrentRuleDTOList = [];
    if (activityTemplateDTO) {
      const currentRuleDTOList = JSON.parse(
        JSON.stringify(activityTemplateDTO.baseRuleDTOList)
      );
      if (selectedValueOption === "") {
        filteredCurrentRuleDTOList = currentRuleDTOList.filter(
          (ruleDTO) => ruleDTO.value === null
        );
      } else {
        filteredCurrentRuleDTOList = currentRuleDTOList.filter(
          (ruleDTO) => ruleDTO.value === selectedValueOption
        );
      }
      includedRuleKeyList.forEach((includedRuleKey) => {
        const foundUserRule = baseRuleDTOList.find(
          (baseRuleDTO) => baseRuleDTO.key === includedRuleKey
        );
        filteredCurrentRuleDTOList.push({
          ...foundUserRule,
          enabled: true,
        });
      });
      removedRuleKeyList.forEach((removedRuleKey) => {
        const foundUserRuleIndex = filteredCurrentRuleDTOList.findIndex(
          (currentRule) => currentRule.key === removedRuleKey
        );
        filteredCurrentRuleDTOList.splice(foundUserRuleIndex, 1);
      });
    }

    return filteredCurrentRuleDTOList;
  };

  const retrieveNotIncludedRuleList = () => {
    const currentRuleDTOList = JSON.parse(
      JSON.stringify(
        baseRuleDTOList.filter(
          (baseRule) =>
            retrieveIncludedRuleList().findIndex(
              (activityTemplateRuleDTO) =>
                activityTemplateRuleDTO.key === baseRule.key
            ) === -1
        )
      )
    );
    // const currentRuleDTOList = JSON.parse(
    //   JSON.stringify(
    //     baseRuleDTOList.filter(
    //       (baseRule) => baseRule.value === selectedValueOption
    //     )
    //   )
    // );
    includedRuleKeyList.forEach((includedRuleKey) => {
      const foundIndex = currentRuleDTOList.findIndex(
        (currentRule) => currentRule.key === includedRuleKey
      );
      if (foundIndex > -1) {
        currentRuleDTOList.splice(foundIndex, 1);
      }
    });
    removedRuleKeyList.forEach((removedRuleKey) => {
      const foundBaseRule = baseRuleDTOList.find(
        (baseRuleDTO) => baseRuleDTO.key === removedRuleKey
      );
      currentRuleDTOList.push(foundBaseRule);
    });
    return currentRuleDTOList;
  };

  const handleAddOrRemoveMultipleBaseRuleToActivityTemplateOnClick =
    async () => {
      if (checkUpdatePermissionGranted("activity_template_rule_list")) {
        const { data, err } = await addOrRemoveBaseRuleToActivityTemplate({
          activityTemplateId,
          selectedValueOption:
            selectedValueOption === "" ? null : selectedValueOption,
          parentKey: retrieveParentKey(),
          parentValue: retrieveParentValue(),
          includedKeyList: includedRuleKeyList,
          removedKeyList: removedRuleKeyList,
        });
        if (err) {
          console.log(err);
        } else {
          navigate(-1);
        }
      } else {
        NotificationManager.error(
          i18n.t("you_do_not_have_permission_to_update")
        );
      }
    };

  const RenderSingleIncludedBaseRuleItem = (baseRuleDTO) => {
    return (
      <div className="flex flex-row items-center my-2 p-2 border-b-2">
        <div className="mx-1">
          <BaseImage
            src="/icons/minus.png"
            alt="minus"
            onClick={() => {
              if (selectedValueOption !== "DEFAULT_OPTION") {
                const foundIncludedIndex = includedRuleKeyList.findIndex(
                  (includedRuleKey) => includedRuleKey === baseRuleDTO.key
                );
                if (foundIncludedIndex !== -1) {
                  includedRuleKeyList.splice(foundIncludedIndex, 1);
                  setIncludedRuleKeyList(
                    JSON.parse(JSON.stringify(includedRuleKeyList))
                  );
                } else {
                  const foundIndex = removedRuleKeyList.findIndex(
                    (removedRuleKey) => removedRuleKey === baseRuleDTO.key
                  );
                  if (foundIndex === -1) {
                    removedRuleKeyList.push(baseRuleDTO.key);
                    setRemovedRuleKeyList(
                      JSON.parse(JSON.stringify(removedRuleKeyList))
                    );
                  }
                }
              }
            }}
            size="small"
          />
        </div>

        <div className="">
          <p className="mx-1">{i18n.t(baseRuleDTO.key)}</p>
        </div>
      </div>
    );
  };

  const RenderSingleNonIncludedBaseRuleItem = (baseRuleDTO) => {
    return (
      <div className="flex flex-row items-center my-2 p-2 border-b-2">
        <div className="mx-1">
          <div className="mx-1">
            <BaseImage
              src="/icons/plus.png"
              alt="plus"
              onClick={() => {
                if (selectedValueOption !== "DEFAULT_OPTION") {
                  const foundRemovedIndex = removedRuleKeyList.findIndex(
                    (removedRuleKey) => removedRuleKey === baseRuleDTO.key
                  );
                  if (foundRemovedIndex !== -1) {
                    removedRuleKeyList.splice(foundRemovedIndex, 1);
                    setRemovedRuleKeyList(
                      JSON.parse(JSON.stringify(removedRuleKeyList))
                    );
                  } else {
                    const foundIndex = includedRuleKeyList.findIndex(
                      (includedRuleKey) => includedRuleKey === baseRuleDTO.key
                    );
                    if (foundIndex === -1) {
                      includedRuleKeyList.push(baseRuleDTO.key);
                      setIncludedRuleKeyList(
                        JSON.parse(JSON.stringify(includedRuleKeyList))
                      );
                    }
                  }
                }
              }}
              size="small"
            />
          </div>
        </div>
        <div className="">
          <p className="mx-1">{i18n.t(baseRuleDTO.key)}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">
        <FullCard isShadowed={true}>
          <div className="m-2 p-2">
            <InputGroup
              type="select"
              value={selectedValueOption}
              optionList={retrieveValueOptionList()}
              onChange={(event) => {
                setSelectedValueOption(event.target.value);
                setIncludedRuleKeyList([]);
                setRemovedRuleKeyList([]);
              }}
            />
          </div>
          <div className="flex flex-row">
            <div className="flex-1">
              <div className="text-xl font-medium">
                <p>{i18n.t("available_base_rules")}</p>
              </div>
              <div className="m-2 p-2 h-catchup-user-authority overflow-auto">
                {retrieveNotIncludedRuleList().map((notIncludedRule, index) => (
                  <div key={index}>
                    {RenderSingleNonIncludedBaseRuleItem(notIncludedRule)}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-1">
              <div className="text-xl font-medium">
                <p>{i18n.t("assigned_base_rules")}</p>
              </div>
              <div className="m-2 p-2 h-catchup-user-authority overflow-auto">
                {retrieveIncludedRuleList().map((includedRule, index) => (
                  <div key={index}>
                    {RenderSingleIncludedBaseRuleItem(includedRule)}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="mt-4 ml-auto">
            <PrimaryButton
              title={i18n.t("add")}
              size="small"
              onClick={
                handleAddOrRemoveMultipleBaseRuleToActivityTemplateOnClick
              }
            />
          </div>
        </FullCard>
      </div>
    </div>
  );
};

export default ActivityTemplateRuleListScreen;
