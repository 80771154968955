import { useCallback, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import FullCard from "../../components/cards/FullCard";
import InputGroup from "../../components/groups/InputGroup";
import AdvancedTable from "../../components/tables/AdvancedTable";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  createLabel,
  patchLabel,
  queryAllLabelDTOPageByFilter,
  queryLabelListByUserIdAndUserProfileId,
} from "../../requests/LabelRequests";
import {
  checkDeletePermissionGranted,
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import debounce from "lodash.debounce";
import CreateButton from "../../components/buttons/CreateButton";

const LabelListScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const { userInformation, userProfile, setUserLabelSet } = useAuth();
  const [pageState, setPageState] = useState(0);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [labelDTOList, setLabelDTOList] = useState([]);
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterTextContains, setFilterTextContains] = useState(null);
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [debounceState, setDebounceState] = useState("");
  const [errorMap, setErrorMap] = useState({
    name: null,
    description: null,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("label_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    retrieveAndSetLabelPageByFilter();
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    setDebounceState("BASE_FILTER");
    retrieveAndSetLabelPageByFilter();
  }, [isFilterApplied, filterBeginAt, filterEndAt, filterShowDeleted]);

  useEffect(() => {
    if (!isFilterApplied) return;
    if (filterTextContains) {
      setDebounceState("TEXT_CONTAINS");
      debouncedRetrieveAndSetLabelPageByFilterDebounce(filterTextContains);
    } else {
      retrieveAndSetLabelPageByFilter();
    }
  }, [filterTextContains]);

  const retrieveAndSetLabelPageByFilter = async () => {
    setTableLoading(true);
    const { data, err } = await queryAllLabelDTOPageByFilter(pagedQueryParams);
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setLabelDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const retrieveAndSetLabelPageByFilterDebounce = async (
    filterTextContains
  ) => {
    setTableLoading(true);
    if (isFilterApplied) {
      pagedQueryParams.params.textContains = filterTextContains.trim();
    }
    const { data, err } = await queryAllLabelDTOPageByFilter(pagedQueryParams);
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setLabelDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const debouncedRetrieveAndSetLabelPageByFilterDebounce = useCallback(
    debounce(function (textContains) {
      retrieveAndSetLabelPageByFilterDebounce(textContains);
    }, 1000),
    [debounceState]
  );

  const retrieveAndSetLabelList = async () => {
    const { data, err } = await queryLabelListByUserIdAndUserProfileId({
      userId: userInformation.id,
      userProfileId: userProfile.id,
    });
    if (err) {
      console.log(err);
    } else {
      setUserLabelSet(data);
    }
  };

  const checkCommonValidation = () => {
    if (
      !name ||
      name.trim() === "" ||
      !description ||
      description.trim() === ""
    ) {
      const currentErrorMap = {
        name: null,
        description: null,
      };
      if (!name || name.trim() === "") {
        currentErrorMap.name = i18n.t("name_required_field");
      }
      if (!description || description.trim() === "") {
        currentErrorMap.description = i18n.t("description_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const checkCanNavigate = () => {
    let canNavigate = true;
    if (!checkViewPermissionGranted("label_detail")) {
      canNavigate = false;
    }
    return canNavigate;
  };

  const constructNavigatableList = () => {
    let navigateList = [];
    if (checkViewPermissionGranted("label_detail")) {
      navigateList.push({
        id: 0,
        text: i18n.t("to_label_detail_screen"),
        icon: (
          <BaseImage
            src="/icons/navigate-gray.png"
            alt="navigate"
            size="small"
          />
        ),
        value: "/labels/{id}",
      });
    }
    return navigateList;
  };

  const constructPagedQueryParams = () => {
    return {
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        userId: userInformation.id,
        userProfileId: userProfile.id,
        beginAt:
          isFilterApplied && filterBeginAt
            ? new Date(filterBeginAt).getTime()
            : null,
        endAt:
          isFilterApplied && filterEndAt
            ? new Date(filterEndAt).getTime()
            : null,
        textContains:
          isFilterApplied && filterTextContains ? filterTextContains : null,
        deleted: isFilterApplied ? (filterShowDeleted ? null : false) : false,
      },
    };
  };

  const resetParams = () => {
    setId(null);
    setName(null);
    setDescription(null);
  };

  const handleOnChange = (type, value) => {
    if (type === "name") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: i18n.t("name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: null,
        }));
      }
      setName(value);
    } else if (type === "description") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: i18n.t("description_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: null,
        }));
      }
      setDescription(value);
    } else {
    }
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    } else if (type === "textContains") {
      setFilterTextContains(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleReturnFromLabelOnClick = () => {
    resetParams();
    setPageState(0);
  };

  const handleToCreateLabelOnClick = () => {
    setId(null);
    setPageState(1);
  };

  const handleToUpdateLabelOnClick = (labelDTO) => {
    setId(labelDTO.id);
    setName(labelDTO.name);
    setDescription(labelDTO.description);
    setPageState(1);
  };

  const handleUpdateLabelOnClick = async () => {
    if (checkUpdatePermissionGranted("label_list")) {
      if (checkCommonValidation()) {
        const currentLabel = {
          id,
          userId: userInformation.id,
          userProfileId: userProfile.id,
          name,
          description,
        };
        const { data, err } = await patchLabel(currentLabel);
        if (err) {
          console.log(err);
        } else {
          const foundLabelIndex = labelDTOList.findIndex(
            (labelDTO) => labelDTO.id === currentLabel.id
          );
          labelDTOList[foundLabelIndex] = {
            ...labelDTOList[foundLabelIndex],
            id: data.id,
            userId: data.userId,
            userProfileId: data.userProfileId,
            name: data.name,
            description: data.description,
            deleted: data.deleted,
            createdAt: data.createdAt,
          };
          setLabelDTOList(JSON.parse(JSON.stringify(labelDTOList)));
          retrieveAndSetLabelList();
          handleReturnFromLabelOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleCreateLabelOnClick = async () => {
    if (checkInsertPermissionGranted("label_list")) {
      if (checkCommonValidation()) {
        const newLabel = {
          userId: userInformation.id,
          userProfileId: userProfile.id,
          name,
          description,
        };
        const { data, err } = await createLabel(newLabel);
        if (err) {
          console.log(err);
        } else {
          labelDTOList.push({
            id: data.id,
            userId: data.userId,
            userProfileId: data.userProfileId,
            name: data.name,
            description: data.description,
            deleted: data.deleted,
            createdAt: data.createdAt,
          });
          setLabelDTOList(JSON.parse(JSON.stringify(labelDTOList)));
          retrieveAndSetLabelList();
          handleReturnFromLabelOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleRemoveLabelOnClick = () => {};

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={i18n.t("label_list")}
            description={i18n.t("personal_label_list_table_description_text")}
            itemName={i18n.t("personal_label")}
            headerList={[
              { name: i18n.t("name"), key: "name", type: "info" },
              {
                name: i18n.t("created_at"),
                key: "createdAt",
                type: "datetime",
              },
            ]}
            selectable={false}
            showFilter={true}
            showItemCount={selectedShowItemCount}
            totalItemCount={totalItemCount}
            pageNumber={pageNumber}
            loading={tableLoading}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleOnFilterChange={handleOnFilterChange}
            handleOnTableParamsChange={handleOnTableParamsChange}
            filterParams={{
              isFilterApplied,
              showBeginDate: true,
              beginAt: filterBeginAt,
              showEndDate: true,
              endAt: filterEndAt,
              showTextContains: false,
              textContains: filterTextContains,
              showDeleted: filterShowDeleted,
            }}
            itemList={labelDTOList}
            insertable={checkInsertPermissionGranted("label_list")}
            handleInsertOnClick={handleToCreateLabelOnClick}
            updatable={checkUpdatePermissionGranted("label_list")}
            handleUpdateOnClick={handleToUpdateLabelOnClick}
            deletable={checkDeletePermissionGranted("label_list")}
            handleDeleteOnClick={handleRemoveLabelOnClick}
            navigatable={checkCanNavigate()}
            navigateOptionList={constructNavigatableList()}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <FullCard isShadowed={true}>
            <div className="">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      handleReturnFromLabelOnClick();
                    }}
                  />
                </div>
                <BaseTitle
                  title={id ? i18n.t("update_label") : i18n.t("create_label")}
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <DividerLine />

              <div className="w-full">
                <p className="font-semibold text-lg">
                  {i18n.t("label_information")}
                </p>
              </div>
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={name}
                    onChange={(e) => {
                      handleOnChange("name", e.target.value);
                    }}
                    errorText={errorMap.name}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("description")}</p>
                </div>
                <div className="flex-1 h-catchup-description">
                  <InputGroup
                    type="textarea"
                    value={description}
                    onChange={(e) => {
                      handleOnChange("description", e.target.value);
                    }}
                    errorText={errorMap.description}
                  />
                </div>
              </div>

              <div className="mt-4 ml-auto">
                {id ? (
                  <SecondaryButton
                    title={i18n.t("update")}
                    size="medium"
                    onClick={handleUpdateLabelOnClick}
                  />
                ) : (
                  <CreateButton
                    title={i18n.t("create")}
                    size="medium"
                    onClick={handleCreateLabelOnClick}
                  />
                )}
              </div>
            </div>
          </FullCard>
        </div>
      </div>
    );
  };

  const pagedQueryParams = constructPagedQueryParams();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default LabelListScreen;
