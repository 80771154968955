import { useCallback, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import InputGroup from "../../components/groups/InputGroup";
import AdvancedTable from "../../components/tables/AdvancedTable";
import { useApp } from "../../context/AppContextProvider";
import i18n from "../../language/i18n";
import {
  createBrand,
  deleteBrand,
  patchBrand,
  queryAllBrandDTOPageByFilter,
} from "../../requests/ManagementRequests";
import {
  checkDeletePermissionGranted,
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import FullCard from "../../components/cards/FullCard";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import debounce from "lodash.debounce";
import { useAuth } from "../../context/UserContextProvider";
import CreateButton from "../../components/buttons/CreateButton";

const BrandListScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const { userInformation } = useAuth();
  const [brandDTOList, setBrandDTOList] = useState([]);
  const [pageState, setPageState] = useState(0);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterTextContains, setFilterTextContains] = useState(null);
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [debounceState, setDebounceState] = useState("");
  const [errorMap, setErrorMap] = useState({
    name: null,
    description: null,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("brand_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    retrieveAndSetBrandPageByFilter();
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    setDebounceState("BASE_FILTER");
    retrieveAndSetBrandPageByFilter();
  }, [isFilterApplied, filterBeginAt, filterEndAt, filterShowDeleted]);

  useEffect(() => {
    if (!isFilterApplied) return;
    if (filterTextContains) {
      setDebounceState("TEXT_CONTAINS");
      debouncedRetrieveAndSetBrandPageByFilterDebounce(filterTextContains);
    } else {
      retrieveAndSetBrandPageByFilter();
    }
  }, [filterTextContains]);

  const retrieveAndSetBrandPageByFilter = async () => {
    setTableLoading(true);
    const { data, err } = await queryAllBrandDTOPageByFilter(pagedQueryParams);
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setBrandDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const retrieveAndSetBrandPageByFilterDebounce = async (
    filterTextContains
  ) => {
    setTableLoading(true);
    if (isFilterApplied) {
      pagedQueryParams.params.textContains = filterTextContains.trim();
    }
    const { data, err } = await queryAllBrandDTOPageByFilter(pagedQueryParams);
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setBrandDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const debouncedRetrieveAndSetBrandPageByFilterDebounce = useCallback(
    debounce(function (textContains) {
      retrieveAndSetBrandPageByFilterDebounce(textContains);
    }, 1000),
    [debounceState]
  );

  const checkCommonValidation = () => {
    if (
      !name ||
      name.trim() === "" ||
      !description ||
      description.trim() === ""
    ) {
      const currentErrorMap = {
        name: null,
        description: null,
      };
      if (!name || name.trim() === "") {
        currentErrorMap.name = i18n.t("name_required_field");
      }
      if (!description || description.trim() === "") {
        currentErrorMap.description = i18n.t("description_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const checkCanNavigate = () => {
    const { accountType } = userInformation;
    if (accountType === "GENIXO") {
      return true;
    }
    return false;
  };

  const constructNavigatableList = () => {
    const navigateList = [];
    const { accountType } = userInformation;
    if (accountType === "GENIXO") {
      navigateList.push({
        id: 0,
        text: i18n.t("to_ai_settings_screen"),
        icon: (
          <BaseImage
            src="/icons/navigate-gray.png"
            alt="navigate"
            size="small"
          />
        ),
        value: "/brands/{id}/settings/ai-settings",
      });
    }
    return navigateList;
  };

  const constructPagedQueryParams = () => {
    return {
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        beginAt:
          isFilterApplied && filterBeginAt
            ? new Date(filterBeginAt).getTime()
            : null,
        endAt:
          isFilterApplied && filterEndAt
            ? new Date(filterEndAt).getTime()
            : null,
        textContains:
          isFilterApplied && filterTextContains ? filterTextContains : null,
        deleted: isFilterApplied ? (filterShowDeleted ? null : false) : false,
      },
    };
  };

  const resetParams = () => {
    setId(null);
    setName(null);
    setDescription(null);
  };

  const handleOnChange = (type, value) => {
    if (type === "name") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: i18n.t("name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: null,
        }));
      }
      setName(value);
    } else if (type === "description") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: i18n.t("description_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: null,
        }));
      }
      setDescription(value);
    } else {
    }
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    } else if (type === "textContains") {
      setFilterTextContains(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleToCreateBrandOnClick = () => {
    setId(null);
    setPageState(1);
  };

  const handleToUpdateBrandOnClick = (brandDTO) => {
    setId(brandDTO.id);
    setName(brandDTO.name);
    setDescription(brandDTO.description);
    setPageState(1);
  };

  const handleUpdateBrandOnClick = async () => {
    if (checkUpdatePermissionGranted("brand_list")) {
      if (checkCommonValidation()) {
        const currentBrand = {
          id,
          name,
          description,
        };
        const { data, err } = await patchBrand(currentBrand);
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          retrieveAndSetBrandPageByFilter();
          handleReturnFromBrandOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleCreateBrandOnClick = async () => {
    if (checkInsertPermissionGranted("brand_list")) {
      if (checkCommonValidation()) {
        const newBrand = {
          name,
          description,
        };
        const { data, err } = await createBrand(newBrand);
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          retrieveAndSetBrandPageByFilter();
          handleReturnFromBrandOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleRemoveBrandOnClick = async (brandId) => {
    if (checkDeletePermissionGranted("brand_list")) {
      const { err } = await deleteBrand(brandId);
      if (err) {
        console.log(err);
        if (err?.response?.data) {
          NotificationManager.error(i18n.t(err.response.data));
        }
      } else {
        const foundBrand = brandDTOList.find(
          (brandDTO) => brandDTO.id === brandId
        );
        foundBrand.deleted = true;
        setBrandDTOList(
          JSON.parse(JSON.stringify(brandDTOList.sort((a, b) => b.id - a.id)))
        );
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_delete"));
    }
  };

  const handleReturnFromBrandOnClick = () => {
    resetParams();
    setPageState(0);
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={i18n.t("brand_list")}
            description={i18n.t("brand_list_table_description_text")}
            itemName={i18n.t("brand")}
            headerList={[
              { name: i18n.t("name"), key: "name", type: "info" },
              {
                name: i18n.t("created_at"),
                key: "createdAt",
                type: "datetime",
              },
            ]}
            selectable={false}
            showFilter={true}
            showItemCount={selectedShowItemCount}
            totalItemCount={totalItemCount}
            pageNumber={pageNumber}
            loading={tableLoading}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleOnFilterChange={handleOnFilterChange}
            handleOnTableParamsChange={handleOnTableParamsChange}
            filterParams={{
              isFilterApplied,
              showBeginDate: true,
              beginAt: filterBeginAt,
              showEndDate: true,
              showTextContains: true,
              textContains: filterTextContains,
              endAt: filterEndAt,
              showDeleted: filterShowDeleted,
            }}
            itemList={brandDTOList}
            insertable={checkInsertPermissionGranted("brand_list")}
            handleInsertOnClick={handleToCreateBrandOnClick}
            updatable={checkUpdatePermissionGranted("brand_list")}
            handleUpdateOnClick={handleToUpdateBrandOnClick}
            deletable={checkDeletePermissionGranted("brand_list")}
            handleDeleteOnClick={handleRemoveBrandOnClick}
            navigatable={checkCanNavigate()}
            navigateOptionList={constructNavigatableList()}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <FullCard isShadowed={true}>
            <div className="">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      handleReturnFromBrandOnClick();
                    }}
                  />
                </div>
                <BaseTitle
                  title={id ? i18n.t("update_brand") : i18n.t("create_brand")}
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <DividerLine />
              <div className="w-full">
                <p className="font-semibold text-lg">
                  {i18n.t("brand_information")}
                </p>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={name}
                    onChange={(e) => {
                      handleOnChange("name", e.target.value);
                    }}
                    errorText={errorMap.name}
                  />
                </div>
              </div>
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("description")}</p>
                </div>
                <div className="flex-1 h-catchup-description">
                  <InputGroup
                    type="textarea"
                    vertical={true}
                    value={description}
                    onChange={(e) => {
                      handleOnChange("description", e.target.value);
                    }}
                    errorText={errorMap.description}
                  />
                </div>
              </div>
              <div className="mt-4 ml-auto">
                {id ? (
                  <SecondaryButton
                    title={i18n.t("update")}
                    size="medium"
                    onClick={handleUpdateBrandOnClick}
                  />
                ) : (
                  <CreateButton
                    title={i18n.t("create")}
                    size="medium"
                    onClick={handleCreateBrandOnClick}
                  />
                )}
              </div>
            </div>
          </FullCard>
        </div>
      </div>
    );
  };

  const pagedQueryParams = constructPagedQueryParams();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default BrandListScreen;
