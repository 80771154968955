import { useEffect, useState } from "react";
import FullCard from "../cards/FullCard";
import i18n from "../../language/i18n";
import {
  constructWeekName,
  retrieveMonthNameByIndex,
} from "../../utilization/DateUtilization";
import {
  checkDeletePermissionGranted,
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
} from "../../utilization/ScreenUtilization";
import HoverViewButton from "../buttons/HoverViewButton";
import DividerLine from "../divider/DividerLine";
import BlueVerticalDividerLine from "../divider/BlueVerticalDividerLine";
import SecondaryButton from "../buttons/SecondaryButton";
import InputGroup from "../groups/InputGroup";
import {
  filterCoterieTypeOptionList,
  retrieveBrandDTOOptionList,
  retrieveCampusDTOOptionList,
  retrieveGradeDTOOptionList,
  retrieveInstitutionDTOOptionList,
  retrieveSeasonDTOOptionList,
} from "../../utilization/ManagementUtilization";
import {
  addOrRemoveMultiplePlanWeekCategoryToPlanWeek,
  addOrRemoveMultiplePlanWeekDocumentToPlanWeek,
  createPlan,
  deletePlan,
  patchPlanWeek,
  queryPlanListByParams,
  queryPlanWeekListByParams,
} from "../../requests/PlanRequests";
import {
  queryBranchListByGradeId,
  queryCampusListByBrandId,
  queryGradeListBySeasonId,
  queryInstitutionListByCampusId,
  querySeasonListByInstitutionId,
} from "../../requests/ManagementRequests";
import { useAuth } from "../../context/UserContextProvider";
import {
  queryAllCatchtivityApplicationListByPlanWeekItemList,
  queryAllCatchtivityListByPlanWeekItemList,
  queryAllCatchxamApplicationListByPlanWeekItemList,
  queryAllCatchxamListByPlanWeekItemList,
} from "../../requests/CatchtivityRequests";
import {
  filterCategoryVersionCodeOptionListByGradeDTO,
  retrieveCategoryVersionCodeOptionList,
} from "../../utilization/CategoryUtilization";
import moment from "moment";
import { useApp } from "../../context/AppContextProvider";
import BaseModal from "../modal/BaseModal";
import BaseImage from "../images/BaseImage";
import BaseTitle from "../titles/BaseTitle";
import ListAndUpsertStorageFile from "../storage-file/ListAndUpsertStorageFile";
import { queryMainFileByPlanWeekItemList } from "../../requests/StorageRequests";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import SingleInformationIconTextItem from "../infos/SingleInformationIconTextItem";
import SelectionTab from "../tabs/SelectionTab";
import DeleteButton from "../buttons/DeleteButton";
import CreateButton from "../buttons/CreateButton";
import CancelButton from "../buttons/CancelButton";
import RedAlert from "../alert/RedAlert";
import PrimaryButton from "../buttons/PrimaryButton";
import CleanFilterGroup from "../groups/CleanFilterGroup";
import BaseLoading from "../loading/BaseLoading";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const ListPlan = (props) => {
  const navigate = useNavigate();
  const { planStompClient } = useApp();
  const { isPlanWeek, planWeekBeginAt, planWeekEndAt } = props;
  const {
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
    categorySet,
  } = useAuth();
  const [brandDTOList, setBrandDTOList] = useState([userProfileBrand]);
  const [campusDTOList, setCampusDTOList] = useState([]);
  const [institutionDTOList, setInstitutionDTOList] = useState([]);
  const [seasonDTOList, setSeasonDTOList] = useState([]);
  const [gradeDTOList, setGradeDTOList] = useState([]);
  const [branchDTOList, setBranchDTOList] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState(userProfileBrand.id);
  const [selectedCampusId, setSelectedCampusId] = useState("DEFAULT_OPTION");
  const [selectedInstitutionId, setSelectedInstitutionId] =
    useState("DEFAULT_OPTION");
  const [selectedSeasonId, setSelectedSeasonId] = useState("DEFAULT_OPTION");
  const [selectedGradeId, setSelectedGradeId] = useState("DEFAULT_OPTION");
  const [selectedVersionCode, setSelectedVersionCode] =
    useState("DEFAULT_OPTION");
  const [selectedCoterieType, setSelectedCoterieType] = useState(
    userProfile?.coterieType
      ? userProfile?.coterieType
      : userProfile?.coterieTypeList && userProfile?.coterieTypeList.length > 0
      ? userProfile?.coterieTypeList[0]
      : "DEFAULT_OPTION"
  );
  const [subCategoryFilter, setSubCategoryFilter] = useState(true);
  const [canQueryPlan, setCanQueryPlan] = useState(false);
  const [plan, setPlan] = useState(null);
  const [loadingMap, setLoadingMap] = useState({
    plan: true,
    catchtivityPlanWeekMap: true,
    catchtivityApplicationPlanWeekMap: true,
    catchxamPlanWeekMap: true,
    catchxamApplicationPlanWeekMap: true,
    documentPlanWeekMap: true,
  });
  const [editPlanWeekId, setEditPlanWeekId] = useState(null);
  const [updatePlanWeekId, setUpdatePlanWeekId] = useState(null);
  const [includedCategoryIdList, setIncludedCategoryIdList] = useState([]);
  const [removedCategoryIdList, setRemovedCategoryIdList] = useState([]);
  const [includedDocumentIdList, setIncludedDocumentIdList] = useState([]);
  const [removedDocumentIdList, setRemovedDocumentIdList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItemId, setSelectedDeleteItemId] = useState(null);
  const [canQueryCatchtivityApplications, setCanQueryCatchtivityApplications] =
    useState(false);
  const [catchtivityMap, setCatchtivityMap] = useState({});
  const [catchtivityApplicationMap, setCatchtivityApplicationMap] = useState(
    {}
  );
  const [canQueryCatchxamApplications, setCanQueryCatchxamApplications] =
    useState(false);
  const [catchxamMap, setCatchxamMap] = useState({});
  const [catchxamApplicationMap, setCatchxamApplicationMap] = useState({});
  const [showCatchtivityOutcomes, setShowCatchtivityOutcomes] = useState(false);
  const [showCatchxamOutcomes, setShowCatchxamOutcomes] = useState(false);
  const [planWeekState, setPlanWeekState] = useState("PRESENT");
  const [subscribedId, setSubscribedId] = useState(null);
  const [someoneInformation, setSomeoneInformation] = useState(null);
  const [showSomeoneUpdatedModal, setShowSomeoneUpdatedModal] = useState(false);
  const [showDocumentLibraryModal, setShowDocumentLibraryModal] =
    useState(false);
  const [canQueryDocuments, setCanQueryDocuments] = useState(false);
  const [documentMap, setDocumentMap] = useState({});
  const [showChooseOutcomeModal, setShowChooseOutcomeModal] = useState(false);
  const [chooseOutcomeState, setChooseOutcomeState] = useState(null);
  const [chooseOutcomePlanWeek, setChooseOutcomePlanWeek] = useState(null);
  const [chooseOutcomeChooseParams, setChooseOutcomeChooseParams] =
    useState(null);
  const [
    chooseOutcomeOutcomeOccurrenceList,
    setChooseOutcomeOutcomeOccurrenceList,
  ] = useState([]);

  useEffect(() => {
    const foundVersionCode = localStorage.getItem(
      "latestSelectedPlanVersionCode"
    );
    if (foundVersionCode) {
      setSelectedVersionCode(foundVersionCode);
    }
  }, []);

  useEffect(() => {
    if (plan && plan !== "NOT_FOUND" && planStompClient) {
      const subscribed = planStompClient.subscribe("/topic/plan", (message) => {
        let { body } = message;
        body = JSON.parse(body);
        const { state, planId, planWeekId, username, firstName, lastName } =
          body;
        if (state === "UPDATED" && planId === plan.id) {
          if (planWeekId) {
            retrieveAndSetSpecificPlanWeek(planWeekId);
          } else {
            retrieveAndSetPlan();
          }

          if (username !== userInformation.username) {
            setSomeoneInformation({
              username: username,
              firstName: firstName,
              lastName: lastName,
            });
            setShowSomeoneUpdatedModal(true);
          }
        }
      });
      setSubscribedId(subscribed.id);
    }

    return () => {
      if (subscribedId) {
        planStompClient.unsubscribe(subscribedId);
        setSubscribedId(null);
      }
    };
  }, [plan]);

  useEffect(() => {
    setSelectedCampusId("DEFAULT_OPTION");
    if (selectedBrandId === "DEFAULT_OPTION") {
      setCampusDTOList([]);
    } else {
      if (userProfileCampus) {
        setCampusDTOList([userProfileCampus]);
        setSelectedCampusId(userProfileCampus.id);
      } else {
        retrieveAndSetCampusDTOListByBrandId();
      }
    }
  }, [selectedBrandId]);

  useEffect(() => {
    setSelectedInstitutionId("DEFAULT_OPTION");
    if (selectedCampusId === "DEFAULT_OPTION") {
      setInstitutionDTOList([]);
    } else {
      if (userProfileInstitution) {
        setInstitutionDTOList([userProfileInstitution]);
        setSelectedInstitutionId(userProfileInstitution.id);
      } else {
        retrieveAndSetInstitutionDTOListByCampusId();
      }
    }
  }, [selectedCampusId]);

  useEffect(() => {
    setSelectedSeasonId("DEFAULT_OPTION");
    if (selectedInstitutionId === "DEFAULT_OPTION") {
      setSeasonDTOList([]);
    } else {
      if (userProfileSeason) {
        setSeasonDTOList([userProfileSeason]);
        setSelectedSeasonId(userProfileSeason.id);
      } else {
        retrieveAndSetSeasonDTOListByInstitutionId();
      }
    }
  }, [selectedInstitutionId]);

  useEffect(() => {
    setSelectedGradeId("DEFAULT_OPTION");
    if (selectedSeasonId === "DEFAULT_OPTION") {
      setGradeDTOList([]);
    } else {
      if (userProfileGrade) {
        setGradeDTOList([userProfileGrade]);
        setSelectedGradeId(userProfileGrade.id);
      } else {
        retrieveAndSetGradeDTOListBySeasonId();
      }
    }
  }, [selectedSeasonId]);

  useEffect(() => {
    if (selectedGradeId === "DEFAULT_OPTION") {
      setBranchDTOList([]);
    } else {
      if (userProfileBranch) {
        setBranchDTOList([userProfileBranch]);
      } else {
        retrieveAndSetBranchDTOListByGradeId();
      }
    }
  }, [selectedGradeId]);

  useEffect(() => {
    if (
      selectedBrandId !== "DEFAULT_OPTION" &&
      selectedCampusId !== "DEFAULT_OPTION" &&
      selectedInstitutionId !== "DEFAULT_OPTION" &&
      selectedSeasonId !== "DEFAULT_OPTION" &&
      selectedGradeId !== "DEFAULT_OPTION" &&
      selectedCoterieType !== "DEFAULT_OPTION" &&
      selectedCoterieType !== "MANAGEMENT" &&
      selectedVersionCode !== "DEFAULT_OPTION"
    ) {
      setCanQueryPlan(true);
    } else {
      setCanQueryPlan(false);
    }
  }, [
    selectedBrandId,
    selectedCampusId,
    selectedInstitutionId,
    selectedSeasonId,
    selectedGradeId,
    selectedCoterieType,
    selectedVersionCode,
  ]);

  useEffect(() => {
    if (canQueryPlan) {
      retrieveAndSetPlan();
    } else {
      setPlan(null);
    }
  }, [canQueryPlan]);

  useEffect(() => {
    if (plan && plan !== "NOT_FOUND") {
      setCatchtivityApplicationMap({});
      setCatchxamApplicationMap({});
      setDocumentMap({});
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        catchtivityPlanWeekMap: true,
        catchtivityApplicationPlanWeekMap: true,
        catchxamPlanWeekMap: true,
        catchxamApplicationPlanWeekMap: true,
        documentPlanWeekMap: true,
      }));
      setCanQueryCatchtivityApplications(true);
      setCanQueryCatchxamApplications(true);
      setCanQueryDocuments(true);
    } else {
      setUpdatePlanWeekId(null);
    }
  }, [plan, updatePlanWeekId]);

  useEffect(() => {
    const retrieveAndSetCatchtivityMap = async () => {
      const { planWeekDTOList } = plan;
      const planWeekItemList = [];
      for (const planWeek of planWeekDTOList) {
        const { id, beginAt, endAt } = planWeek;
        planWeekItemList.push({
          id,
          beginAt,
          endAt,
        });
      }
      const foundGrade = gradeDTOList.find(
        (gradeDTO) => gradeDTO.id === selectedGradeId
      );
      const { data, err } = await queryAllCatchtivityListByPlanWeekItemList({
        coterieType: selectedCoterieType,
        level: foundGrade.level,
        planWeekItemList,
        deleted: false,
      });
      if (err) {
        console.log(err);
        return;
      }
      const currentCatchtivityMap = {};
      for (const item of data) {
        const { id, catchtivityDTOList } = item;
        currentCatchtivityMap[id] = catchtivityDTOList;
      }
      setCatchtivityMap(currentCatchtivityMap);
      // setCanQueryCatchtivitys(false);
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        catchtivityPlanWeekMap: false,
      }));
    };
    if (!canQueryCatchtivityApplications) return;
    retrieveAndSetCatchtivityMap();
  }, [canQueryCatchtivityApplications]);

  useEffect(() => {
    const retrieveAndSetCatchtivityApplicationMap = async () => {
      const { planWeekDTOList } = plan;
      const planWeekItemList = [];
      for (const planWeek of planWeekDTOList) {
        const { id, beginAt, endAt } = planWeek;
        planWeekItemList.push({
          id,
          beginAt,
          endAt,
        });
      }
      const foundGrade = gradeDTOList.find(
        (gradeDTO) => gradeDTO.id === selectedGradeId
      );
      const { data, err } =
        await queryAllCatchtivityApplicationListByPlanWeekItemList({
          coterieType: selectedCoterieType,
          level: foundGrade.level,
          seasonId: selectedSeasonId,
          gradeBranchMapList: [
            {
              gradeId: selectedGradeId,
              branchIdList: branchDTOList.map((branchDTO) => branchDTO.id),
            },
          ],
          planWeekItemList,
          deleted: false,
        });
      if (err) {
        console.log(err);
        return;
      }
      const currentCatchtivityApplicationMap = {};
      for (const item of data) {
        const { id, catchtivityApplicationDTOList } = item;
        currentCatchtivityApplicationMap[id] = catchtivityApplicationDTOList;
      }
      setCatchtivityApplicationMap(currentCatchtivityApplicationMap);
      setCanQueryCatchtivityApplications(false);
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        catchtivityApplicationPlanWeekMap: false,
      }));
    };
    if (!canQueryCatchtivityApplications) return;
    retrieveAndSetCatchtivityApplicationMap();
  }, [canQueryCatchtivityApplications]);

  useEffect(() => {
    const retrieveAndSetCatchxamApplicationMap = async () => {
      const { planWeekDTOList } = plan;
      const planWeekItemList = [];
      for (const planWeek of planWeekDTOList) {
        const { id, beginAt, endAt } = planWeek;
        planWeekItemList.push({
          id,
          beginAt,
          endAt,
        });
      }
      const foundGrade = gradeDTOList.find(
        (gradeDTO) => gradeDTO.id === selectedGradeId
      );
      const { data, err } =
        await queryAllCatchxamApplicationListByPlanWeekItemList({
          coterieType: selectedCoterieType,
          level: foundGrade.level,
          seasonId: selectedSeasonId,
          gradeBranchMapList: [
            {
              gradeId: selectedGradeId,
              branchIdList: branchDTOList.map((branchDTO) => branchDTO.id),
            },
          ],
          planWeekItemList,
          deleted: false,
        });
      if (err) {
        console.log(err);
        return;
      }
      const currentCatchxamApplicationMap = {};
      for (const item of data) {
        const { id, catchxamApplicationDTOList } = item;
        currentCatchxamApplicationMap[id] = catchxamApplicationDTOList;
      }
      setCatchxamApplicationMap(currentCatchxamApplicationMap);
      setCanQueryCatchxamApplications(false);
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        catchxamApplicationPlanWeekMap: false,
      }));
    };
    if (!canQueryCatchxamApplications) return;
    retrieveAndSetCatchxamApplicationMap();
  }, [canQueryCatchxamApplications]);

  useEffect(() => {
    const retrieveAndSetCatchxamMap = async () => {
      const { planWeekDTOList } = plan;
      const planWeekItemList = [];
      for (const planWeek of planWeekDTOList) {
        const { id, beginAt, endAt } = planWeek;
        planWeekItemList.push({
          id,
          beginAt,
          endAt,
        });
      }
      const foundGrade = gradeDTOList.find(
        (gradeDTO) => gradeDTO.id === selectedGradeId
      );
      const { data, err } = await queryAllCatchxamListByPlanWeekItemList({
        coterieType: selectedCoterieType,
        level: foundGrade.level,
        planWeekItemList,
        deleted: false,
      });
      if (err) {
        console.log(err);
        return;
      }
      const currentCatchxamMap = {};
      for (const item of data) {
        const { id, catchxamDTOList } = item;
        currentCatchxamMap[id] = catchxamDTOList;
      }
      setCatchxamMap(currentCatchxamMap);
      // setCanQueryCatchtivitys(false);
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        catchxamPlanWeekMap: false,
      }));
    };
    if (!canQueryCatchxamApplications) return;
    retrieveAndSetCatchxamMap();
  }, [canQueryCatchxamApplications]);

  useEffect(() => {
    const retrieveAndSetDocumentMap = async () => {
      const { planWeekDTOList } = plan;
      const planWeekItemList = [];
      for (const planWeek of planWeekDTOList) {
        const { id } = planWeek;
        const foundPlanWeek = plan.planWeekDTOList.find(
          (planWeekDTO) => planWeekDTO.id === id
        );
        const documentIdList = foundPlanWeek.planWeekDocumentDTOList.map(
          (planWeekDocument) => planWeekDocument.documentId
        );
        planWeekItemList.push({
          id,
          documentIdList,
        });
      }
      const { data, err } = await queryMainFileByPlanWeekItemList({
        planWeekItemList,
      });
      if (err) {
        console.log(err);
        return;
      }
      const currentDocumentMap = {};
      for (const item of data) {
        const { id, mainFileList } = item;
        currentDocumentMap[id] = mainFileList;
      }
      setDocumentMap(currentDocumentMap);
      setCanQueryDocuments(false);
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        documentPlanWeekMap: false,
      }));
    };
    if (!canQueryDocuments) return;
    retrieveAndSetDocumentMap();
  }, [canQueryDocuments]);

  useEffect(() => {
    if (selectedVersionCode !== "DEFAULT_OPTION") {
      localStorage.setItem(
        "latestSelectedPlanVersionCode",
        selectedVersionCode
      );
    } else {
      localStorage.removeItem("latestSelectedPlanVersionCode");
    }
  }, [selectedVersionCode]);

  const retrieveAndSetPlan = async () => {
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      plan: true,
      catchtivityPlanWeekMap: true,
      catchtivityApplicationPlanWeekMap: true,
      catchxamPlanWeekMap: true,
      catchxamApplicationPlanWeekMap: true,
      documentPlanWeekMap: true,
    }));
    setCatchtivityApplicationMap({});
    setCatchxamApplicationMap({});
    setDocumentMap({});
    let queryParams = {
      brandId: selectedBrandId,
      campusId: selectedCampusId,
      institutionId: selectedInstitutionId,
      seasonId: selectedSeasonId,
      gradeId: selectedGradeId,
      coterieType: selectedCoterieType,
      versionCode: selectedVersionCode,
    };
    if (isPlanWeek) {
      queryParams = {
        ...queryParams,
        beginAt: planWeekBeginAt,
        endAt: planWeekEndAt,
      };
      const { data, err } = await queryPlanWeekListByParams(queryParams);
      if (err) {
        console.log(err);
      } else {
        if (data.length > 0) {
          const { planId } = data[0];
          setPlan({
            id: planId,
            planWeekDTOList: data,
          });
        } else {
          setPlan("NOT_FOUND");
        }
      }
    } else {
      const { data, err } = await queryPlanListByParams(queryParams);
      if (err) {
        console.log(err);
      } else {
        if (data.length > 0) {
          const foundPlan = data.shift();
          setPlan(foundPlan);
        } else {
          setPlan("NOT_FOUND");
        }
      }
    }

    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      plan: false,
    }));
  };

  const retrieveAndSetCampusDTOListByBrandId = async () => {
    const { data, err } = await queryCampusListByBrandId(selectedBrandId);
    if (err) {
      console.log(err);
    } else {
      setCampusDTOList(data);
    }
  };

  const retrieveAndSetInstitutionDTOListByCampusId = async () => {
    const { data, err } = await queryInstitutionListByCampusId(
      selectedCampusId
    );
    if (err) {
      console.log(err);
    } else {
      setInstitutionDTOList(data);
    }
  };

  const retrieveAndSetSeasonDTOListByInstitutionId = async () => {
    const { data, err } = await querySeasonListByInstitutionId(
      selectedInstitutionId
    );
    if (err) {
      console.log(err);
    } else {
      setSeasonDTOList(data);
    }
  };

  const retrieveAndSetGradeDTOListBySeasonId = async () => {
    const { data, err } = await queryGradeListBySeasonId(selectedSeasonId);
    if (err) {
      console.log(err);
    } else {
      setGradeDTOList(data);
    }
  };

  const retrieveAndSetBranchDTOListByGradeId = async () => {
    const { data, err } = await queryBranchListByGradeId(selectedGradeId);
    if (err) {
      console.log(err);
    } else {
      setBranchDTOList(data);
    }
  };

  const retrieveAndSetSpecificPlanWeek = async (planWeekId) => {
    const foundIndex = plan.planWeekDTOList.findIndex(
      (planWeekDTO) => planWeekDTO.id === planWeekId
    );
    const queryParams = {
      brandId: selectedBrandId,
      campusId: selectedCampusId,
      institutionId: selectedInstitutionId,
      seasonId: selectedSeasonId,
      gradeId: selectedGradeId,
      coterieType: selectedCoterieType,
      versionCode: selectedVersionCode,
      beginAt: plan.planWeekDTOList[foundIndex].beginAt,
      endAt: plan.planWeekDTOList[foundIndex].endAt,
    };
    const { data: planWeekData, err: planWeekErr } =
      await queryPlanWeekListByParams(queryParams);
    if (planWeekErr) {
      await retrieveAndSetPlan();
    } else {
      plan.planWeekDTOList[foundIndex] = planWeekData[0];
    }
  };

  const retrieveCategoryDTOOptionList = () => {
    let filteredMainCategoryDTOList;
    if (subCategoryFilter) {
      const foundGradeDTO = gradeDTOList.find(
        (gradeDTO) => gradeDTO.id === selectedGradeId
      );

      filteredMainCategoryDTOList = categorySet.filter(
        (categoryDTO) =>
          parseFloat(categoryDTO.level) === parseFloat(foundGradeDTO.level) &&
          categoryDTO.versionCode === selectedVersionCode
      );
    } else {
      filteredMainCategoryDTOList = categorySet.filter(
        (categoryDTO) => categoryDTO.versionCode === selectedVersionCode
      );
    }

    const filteredCategoryDTOList = [];
    for (const currentCategoryDTO of filteredMainCategoryDTOList) {
      const foundIndex = filteredMainCategoryDTOList.findIndex(
        (categoryDTO) => categoryDTO.parentCode === currentCategoryDTO.code
      );
      if (foundIndex === -1) {
        filteredCategoryDTOList.push(currentCategoryDTO);
      }
    }

    return filteredCategoryDTOList
      .sort((a, b) => a.code.localeCompare(b.code))
      .map((categoryDTO) => ({
        value: categoryDTO.id,
        text: `${categoryDTO.code} - ${categoryDTO.name}`,
      }));
  };

  const filterSeasonDTOList = () => {
    return seasonDTOList.filter(
      (seasonDTO) => seasonDTO.endAt > new Date().getTime()
    );
  };

  const constructEditOptionList = () => {
    return [
      {
        text: i18n.t("save"),
        icon: (
          <BaseImage src="/icons/checkbox.png" alt="checkbox" size="small" />
        ),
        onClick: handleSavePlanWeekOnClick,
      },
    ];
  };

  const constructPlanWeekOptionList = (planWeek) => {
    const optionList = [];
    if (checkUpdatePermissionGranted("plan_list")) {
      optionList.push({
        id: 0,
        text: i18n.t("edit"),
        icon: <BaseImage src="/icons/edit.png" alt="edit" size="small" />,
        onClick: () => {
          setIncludedCategoryIdList([]);
          setRemovedCategoryIdList([]);
          setIncludedDocumentIdList([]);
          setRemovedDocumentIdList([]);
          setEditPlanWeekId(planWeek.id);
        },
      });
    }
    if (checkInsertPermissionGranted("catchtivity_list")) {
      if (catchtivityApplicationMap[planWeek.id]?.length === 0) {
        if (catchtivityMap[planWeek.id]?.length === 0) {
          optionList.push({
            id: 1,
            text: i18n.t("create_catchtivity"),
            icon: (
              <BaseImage
                src="/icons/catchtivity.png"
                alt="catchtivity"
                size="small"
              />
            ),
            onClick: () => {
              handleCreateCatchtivityByPlanWeek(planWeek);
            },
          });
        } else {
          optionList.push({
            id: 1,
            text: i18n.t("create_catchtivity_application"),
            icon: (
              <BaseImage
                src="/icons/catchtivity.png"
                alt="catchtivity"
                size="small"
              />
            ),
            onClick: () => {
              handleCreateCatchtivityApplicationByPlanWeek(planWeek);
            },
          });
        }
      }
      if (catchxamApplicationMap[planWeek.id]?.length === 0) {
        if (catchxamMap[planWeek.id]?.length === 0) {
          optionList.push({
            id: 2,
            text: i18n.t("create_catchxam"),
            icon: (
              <BaseImage
                src="/icons/catchxam.png"
                alt="catchxam"
                size="small"
              />
            ),
            onClick: () => {
              handleCreateCatchxamByPlanWeek(planWeek);
            },
          });
        } else {
          optionList.push({
            id: 2,
            text: i18n.t("create_catchxam_application"),
            icon: (
              <BaseImage
                src="/icons/catchxam.png"
                alt="catchxam"
                size="small"
              />
            ),
            onClick: () => {
              handleCreateCatchxamApplicationByPlanWeek(planWeek);
            },
          });
        }
      }
    }
    if (checkUpdatePermissionGranted("plan_list")) {
      optionList.push({
        id: 3,
        text: i18n.t("set_holiday"),
        icon: <BaseImage src="/icons/holiday.png" alt="holiday" size="small" />,
        onClick: () => {
          handleSetHolidayOnClick(planWeek.id);
        },
      });
    }
    return optionList;
  };

  const constructHolidayOptionList = (planWeek) => {
    return [
      {
        text: i18n.t("set_plan_week"),
        icon: (
          <BaseImage src="/icons/plan-week.png" alt="plan-week" size="small" />
        ),
        onClick: () => {
          handleSetPlanWeekOnClick(planWeek.id);
        },
      },
    ];
  };

  const constructCatchtivityCategoryListByCatchtivityApplicationDTOList = (
    catchtivityApplicationDTOList
  ) => {
    const catchtivityCategoryList = [];
    let index = 0;
    if (catchtivityApplicationDTOList) {
      for (const catchtivityApplicationDTO of catchtivityApplicationDTOList) {
        index++;
        for (const activityDTO of catchtivityApplicationDTO.catchtivityDTO
          .activityDTOList) {
          for (const categoryId of activityDTO.categoryIdList) {
            const foundCatchtivityCategory = catchtivityCategoryList.find(
              (catchtivityCategory) =>
                catchtivityCategory.categoryDTO.id === categoryId
            );
            if (foundCatchtivityCategory) {
              const foundIndex = foundCatchtivityCategory.indexList.find(
                (categoryIndex) => categoryIndex === index
              );
              if (!foundIndex) {
                foundCatchtivityCategory.indexList.push(index);
              }
            } else {
              catchtivityCategoryList.push({
                indexList: [index],
                categoryDTO: {
                  id: categoryId,
                },
              });
            }
          }
        }
      }
    }

    catchtivityCategoryList.forEach((catchtivityCategory) => {
      const foundCategoryDTO = categorySet.find(
        (categoryDTO) => categoryDTO.id === catchtivityCategory.categoryDTO.id
      );
      if (foundCategoryDTO) {
        catchtivityCategory.categoryDTO = { ...foundCategoryDTO };
      }
    });
    return catchtivityCategoryList;
  };

  const constructCatchxamCategoryListByCatchxamApplicationDTOList = (
    catchxamApplicationDTOList
  ) => {
    const catchxamCategoryList = [];
    let index = 0;
    if (catchxamApplicationDTOList) {
      for (const catchxamApplicationDTO of catchxamApplicationDTOList) {
        index++;
        for (const activityDTO of catchxamApplicationDTO.catchxamDTO
          .activityDTOList) {
          for (const categoryId of activityDTO.categoryIdList) {
            const foundCatchxamCategory = catchxamCategoryList.find(
              (catchxamCategory) =>
                catchxamCategory.categoryDTO.id === categoryId
            );
            if (foundCatchxamCategory) {
              const foundIndex = foundCatchxamCategory.indexList.find(
                (categoryIndex) => categoryIndex === index
              );
              if (!foundIndex) {
                foundCatchxamCategory.indexList.push(index);
              }
            } else {
              catchxamCategoryList.push({
                indexList: [index],
                categoryDTO: {
                  id: categoryId,
                },
              });
            }
          }
        }
      }
    }

    catchxamCategoryList.forEach((catchxamCategory) => {
      const foundCategoryDTO = categorySet.find(
        (categoryDTO) => categoryDTO.id === catchxamCategory.categoryDTO.id
      );
      if (foundCategoryDTO) {
        catchxamCategory.categoryDTO = { ...foundCategoryDTO };
      }
    });
    return catchxamCategoryList;
  };

  const handleOnChange = (type, value) => {
    setCanQueryPlan(false);
    if (type === "selectedBrandId") {
      setSelectedBrandId(value);
    } else if (type === "selectedCampusId") {
      setSelectedCampusId(value);
    } else if (type === "selectedInstitutionId") {
      setSelectedInstitutionId(value);
    } else if (type === "selectedSeasonId") {
      setSelectedSeasonId(value);
    } else if (type === "selectedGradeId") {
      setSelectedGradeId(value);
    } else if (type === "selectedCoterieType") {
      setSelectedVersionCode("DEFAULT_OPTION");
      setSelectedCoterieType(value);
    } else if (type === "selectedVersionCode") {
      setSelectedVersionCode(value);
    }
  };

  const handleCreatePlanOnClick = async () => {
    const foundSeasonDTO = seasonDTOList.find(
      (seasonDTO) => seasonDTO.id === selectedSeasonId
    );

    const planWeekList = [];
    let currentTimestamp = foundSeasonDTO.beginAt;
    while (currentTimestamp < foundSeasonDTO.endAt) {
      const beginAt = moment(currentTimestamp)
        .startOf("isoWeek")
        .toDate()
        .getTime();
      const endAt = moment(currentTimestamp)
        .endOf("isoWeek")
        .toDate()
        .getTime();

      planWeekList.push({
        beginAt,
        endAt,
        isHoliday: false,
      });
      currentTimestamp += 1000 * 60 * 60 * 24 * 7;
    }

    const plan = {
      brandId: selectedBrandId,
      campusId: selectedCampusId,
      institutionId: selectedInstitutionId,
      seasonId: selectedSeasonId,
      gradeId: selectedGradeId,
      coterieType: selectedCoterieType,
      versionCode: selectedVersionCode,
      planWeekList,
      userId: userInformation.id,
      userProfileId: userProfile.id,
    };
    const { data, err } = await createPlan(plan);
    if (err) {
      console.log(err);
      return;
    }
    setPlan(data);
    if (planStompClient) {
      planStompClient.send(
        "/app/plan/updates",
        null,
        JSON.stringify({
          planId: data.id,
          username: userInformation.username,
          firstName: userInformation.firstName,
          lastName: userInformation.lastName,
          state: "UPDATED",
        })
      );
    }
  };

  const handleDeletePlanOnClick = async () => {
    if (selectedDeleteItemId) {
      const { data, err } = await deletePlan(selectedDeleteItemId);
      if (err) {
        console.log(err);
        return;
      }
      await retrieveAndSetPlan();
      if (planStompClient) {
        planStompClient.send(
          "/app/plan/updates",
          null,
          JSON.stringify({
            planId: selectedDeleteItemId,
            username: userInformation.username,
            firstName: userInformation.firstName,
            lastName: userInformation.lastName,
            state: "UPDATED",
          })
        );
      }
    }
  };

  const handleSavePlanWeekCategoryOnClick = async () => {
    if (includedCategoryIdList.length > 0 || removedCategoryIdList.length > 0) {
      const { data, err } = await addOrRemoveMultiplePlanWeekCategoryToPlanWeek(
        {
          id: editPlanWeekId,
          includedIdList: includedCategoryIdList,
          removedIdList: removedCategoryIdList,
        }
      );
      if (err) {
        console.log(err);
      }
    }
  };

  const handleSavePlanWeekDocumentOnClick = async () => {
    if (includedDocumentIdList.length > 0 || removedDocumentIdList.length > 0) {
      const { data, err } = await addOrRemoveMultiplePlanWeekDocumentToPlanWeek(
        {
          id: editPlanWeekId,
          includedIdList: includedDocumentIdList,
          removedIdList: removedDocumentIdList,
        }
      );
      if (err) {
        console.log(err);
      }
    }
  };

  const handleSavePlanWeekOnClick = async () => {
    await handleSavePlanWeekCategoryOnClick();
    await handleSavePlanWeekDocumentOnClick();
    await retrieveAndSetSpecificPlanWeek(editPlanWeekId);
    setUpdatePlanWeekId(editPlanWeekId);
    setEditPlanWeekId(null);
    if (planStompClient) {
      planStompClient.send(
        "/app/plan/updates",
        null,
        JSON.stringify({
          planId: plan.id,
          planWeekId: editPlanWeekId,
          username: userInformation.username,
          firstName: userInformation.firstName,
          lastName: userInformation.lastName,
          state: "UPDATED",
        })
      );
    }
  };

  const handleAddCategoryIdToIncludedCategoryIdListOnClick = (categoryId) => {
    if (categoryId !== "DEFAULT_OPTION") {
      const foundPlanWeek = plan.planWeekDTOList.find(
        (planWeek) => planWeek.id === editPlanWeekId
      );
      const currentIndex = foundPlanWeek.planWeekCategoryDTOList.findIndex(
        (activityCategory) =>
          parseFloat(activityCategory.id) === parseFloat(categoryId)
      );
      const currentIncludedIndex = includedCategoryIdList.findIndex(
        (includedCategoryId) =>
          parseFloat(includedCategoryId) === parseFloat(categoryId)
      );
      const currentRemovedIndex = removedCategoryIdList.findIndex(
        (removedCategoryId) =>
          parseFloat(removedCategoryId) === parseFloat(categoryId)
      );
      if (currentRemovedIndex !== -1) {
        removedCategoryIdList.splice(currentRemovedIndex, 1);
        setRemovedCategoryIdList([...removedCategoryIdList]);
      } else {
        if (currentIndex === -1 && currentIncludedIndex === -1) {
          const foundCategoryDTO = categorySet.find(
            (categoryDTO) => categoryDTO.id === parseFloat(categoryId)
          );
          const foundPlanWeek = plan.planWeekDTOList.find(
            (planWeek) => planWeek.id === editPlanWeekId
          );
          if (foundPlanWeek) {
            includedCategoryIdList.push(foundCategoryDTO.id);
            setIncludedCategoryIdList([...includedCategoryIdList]);
          }
        }
      }
    }
  };

  const handleAddCategoryIdToRemovedCategoryIdListOnClick = (categoryId) => {
    const currentRemovedIndex = removedCategoryIdList.findIndex(
      (removedCategoryId) =>
        parseFloat(removedCategoryId) === parseFloat(categoryId)
    );
    const currentIncludedIndex = includedCategoryIdList.findIndex(
      (includedCategoryId) =>
        parseFloat(includedCategoryId) === parseFloat(categoryId)
    );
    if (currentIncludedIndex !== -1) {
      includedCategoryIdList.splice(currentIncludedIndex, 1);
      setIncludedCategoryIdList([...includedCategoryIdList]);
    } else {
      if (currentRemovedIndex === -1) {
        removedCategoryIdList.push(categoryId);
        setRemovedCategoryIdList([...removedCategoryIdList]);
      }
    }
  };

  const handleAddDocumentIdToIncludedDocumentIdListOnClick = (documentItem) => {
    if (!documentItem) return;
    const currentIncludedIndex = includedDocumentIdList.findIndex(
      (includedDocumentId) =>
        parseFloat(includedDocumentId) === parseFloat(documentItem.id)
    );
    if (currentIncludedIndex === -1) {
      if (
        documentMap[editPlanWeekId] == null ||
        documentMap[editPlanWeekId].length === 0
      ) {
        documentMap[editPlanWeekId] = [documentItem];
      } else {
        documentMap[editPlanWeekId].push(documentItem);
      }
      includedDocumentIdList.push(documentItem.id);
      setIncludedDocumentIdList([...includedDocumentIdList]);
      setShowDocumentLibraryModal(false);
    }
  };

  const handleRemoveIdFromRemovedDocumentIdListOnClick = (documentId) => {
    const currentRemovedIndex = removedDocumentIdList.findIndex(
      (removedDocumentId) =>
        parseFloat(removedDocumentId) === parseFloat(documentId)
    );
    if (currentRemovedIndex === -1) {
      const foundItemIndex = documentMap[editPlanWeekId].findIndex(
        (item) => item.id === parseFloat(documentId)
      );
      if (foundItemIndex > -1) {
        documentMap[editPlanWeekId].splice(foundItemIndex);
        setDocumentMap(JSON.parse(JSON.stringify(documentMap)));
      }
      removedDocumentIdList.push(documentId);
      setRemovedDocumentIdList([...removedDocumentIdList]);
    }
  };

  const handleSetHolidayOnClick = async (planWeekId) => {
    const { data, err } = await patchPlanWeek({
      id: planWeekId,
      isHoliday: true,
    });
    if (err) {
      console.log(err);
      return;
    }
    const foundPlanWeek = plan.planWeekDTOList.find(
      (planWeekDTO) => planWeekDTO.id === planWeekId
    );
    foundPlanWeek.isHoliday = true;
    setPlan({ ...plan });
  };

  const handleSetPlanWeekOnClick = async (planWeekId) => {
    const { data, err } = await patchPlanWeek({
      id: planWeekId,
      isHoliday: false,
    });
    if (err) {
      console.log(err);
      return;
    }
    const foundPlanWeek = plan.planWeekDTOList.find(
      (planWeekDTO) => planWeekDTO.id === planWeekId
    );
    foundPlanWeek.isHoliday = false;
    setPlan({ ...plan });
  };

  const handleCreateCatchtivityByPlanWeek = (planWeek) => {
    const { planWeekDTOList } = plan;
    const beginIndex = planWeek.id - 1;
    const endIndex = planWeekDTOList[0].id;
    const coefficient = Math.floor(planWeek.id - endIndex / 10);
    const ratio = Math.max(0.5, 0.7 - coefficient * 0.1);
    const previousPlanWeekList = [];
    for (
      let planWeekIndex = beginIndex;
      planWeekIndex >= endIndex;
      planWeekIndex--
    ) {
      const foundPlanWeekDTO = planWeekDTOList.find(
        (planWeekDTO) => planWeekDTO.id === planWeekIndex
      );
      previousPlanWeekList.push(foundPlanWeekDTO);
    }
    const outcomeOccurrenceList = [];
    const { planWeekCategoryDTOList } = planWeek;
    for (const previousPlanWeek of previousPlanWeekList) {
      const { planWeekCategoryDTOList } = previousPlanWeek;
      for (const planWeekCategoryDTO of planWeekCategoryDTOList) {
        const { categoryId } = planWeekCategoryDTO;
        if (
          outcomeOccurrenceList.findIndex(
            (outcomeOccurrence) => outcomeOccurrence.categoryId === categoryId
          ) === -1
        ) {
          outcomeOccurrenceList.push({ categoryId, isMust: false });
        }
      }
    }

    for (const planWeekCategoryDTO of planWeekCategoryDTOList) {
      const { categoryId } = planWeekCategoryDTO;
      const foundOutcomeOutcomeOccurrence = outcomeOccurrenceList.find(
        (outcomeOccurrence) => outcomeOccurrence.categoryId === categoryId
      );
      if (foundOutcomeOutcomeOccurrence) {
        foundOutcomeOutcomeOccurrence.isMust = true;
      } else {
        outcomeOccurrenceList.push({ categoryId, isMust: true });
      }
    }
    if (outcomeOccurrenceList.length === 0) {
      NotificationManager.error(i18n.t("empty_category_in_plan_week"));
      return;
    }
    const foundGrade = gradeDTOList.find(
      (gradeDTO) => gradeDTO.id === selectedGradeId
    );
    const { level } = foundGrade;
    setChooseOutcomeChooseParams({
      numberOfActivity: level < 5 ? 7 : 15,
      mustCategoryRatio: ratio,
    });
    setChooseOutcomePlanWeek(JSON.parse(JSON.stringify(planWeek)));
    setChooseOutcomeOutcomeOccurrenceList(
      JSON.parse(JSON.stringify(outcomeOccurrenceList))
    );
    setChooseOutcomeState("CATCHTIVITY");
    setShowChooseOutcomeModal(true);
  };

  const handleCreateCatchtivityApplicationByPlanWeek = (planWeek) => {
    if (
      !catchtivityMap[planWeek.id] ||
      catchtivityMap[planWeek.id].length === 0
    )
      return;
    const { beginAt, endAt } = planWeek;
    const catchtivityDTO = catchtivityMap[planWeek.id][0];
    handleToNavigateCatchtivityApplication(catchtivityDTO, beginAt, endAt);
  };

  const handleCreateCatchxamByPlanWeek = (planWeek) => {
    const { planWeekDTOList } = plan;
    const beginIndex = planWeek.id - 1;
    let endIndex = planWeekDTOList[0].id;
    for (
      let planWeekIndex = beginIndex - 1;
      planWeekIndex >= endIndex;
      planWeekIndex--
    ) {
      if (catchxamApplicationMap[planWeekIndex].length !== 0) {
        endIndex = planWeekIndex;
        break;
      }
    }
    const outcomeOccurrenceSet = new Set();
    for (
      let planWeekIndex = beginIndex;
      planWeekIndex >= endIndex;
      planWeekIndex--
    ) {
      if (catchtivityApplicationMap[planWeekIndex].length > 0) {
        for (
          let i = 0;
          i < catchtivityApplicationMap[planWeekIndex].length;
          i++
        ) {
          const catchtivityApplicationDTO =
            catchtivityApplicationMap[planWeekIndex][i];
          const { catchtivityDTO } = catchtivityApplicationDTO;
          const { activityDTOList } = catchtivityDTO;
          for (const activityDTO of activityDTOList) {
            const { categoryIdList } = activityDTO;
            for (const categoryId of categoryIdList) {
              outcomeOccurrenceSet.add({ categoryId, isMust: true });
            }
          }
        }
      }
    }
    const outcomeOccurrenceList = Array.from(outcomeOccurrenceSet);
    if (outcomeOccurrenceList.length === 0) {
      NotificationManager.error(i18n.t("empty_category_in_plan_week"));
      return;
    }
    const foundGrade = gradeDTOList.find(
      (gradeDTO) => gradeDTO.id === selectedGradeId
    );
    const { level } = foundGrade;
    setChooseOutcomeChooseParams({
      numberOfActivity: level < 5 ? 7 : 15,
      mustCategoryRatio: 1,
    });
    setChooseOutcomePlanWeek(JSON.parse(JSON.stringify(planWeek)));
    setChooseOutcomeOutcomeOccurrenceList(
      JSON.parse(JSON.stringify(outcomeOccurrenceList))
    );
    setChooseOutcomeState("CATCHXAM");
    setShowChooseOutcomeModal(true);
  };

  const handleCreateCatchxamApplicationByPlanWeek = (planWeek) => {
    if (!catchxamMap[planWeek.id] || catchxamMap[planWeek.id].length === 0)
      return;
    const { beginAt, endAt } = planWeek;
    const catchxamDTO = catchxamMap[planWeek.id][0];
    handleToNavigateCatchxamApplication(catchxamDTO, beginAt, endAt);
  };

  const handleToNavigateCatchtivityApplication = (
    catchtivityDTO,
    beginAtTimestamp,
    endAtTimestamp
  ) => {
    navigate("/catchtivities/applications", {
      state: {
        pageState: 1,
        beginAtTimestamp,
        endAtTimestamp,
        catchtivityId: catchtivityDTO.id,
        coterieType: catchtivityDTO.coterieType,
        level: catchtivityDTO.level,
      },
    });
  };

  const handleToNavigateCatchxamApplication = (
    catchxamDTO,
    beginAtTimestamp,
    endAtTimestamp
  ) => {
    navigate("/catchxams/applications", {
      state: {
        pageState: 1,
        beginAtTimestamp,
        endAtTimestamp,
        catchxamId: catchxamDTO.id,
        coterieType: catchxamDTO.coterieType,
        level: catchxamDTO.level,
      },
    });
  };

  const RenderPlanWeekCatchtivityDTOItem = (catchtivityApplicationDTOList) => {
    const currentCatchtivityApplicationMap = {};
    const globalAppliedBranchIdList = [];
    for (const catchtivityApplicationDTO of catchtivityApplicationDTOList) {
      const appliedBranchList = [];
      const { branchIdSet, catchtivityDTO } = catchtivityApplicationDTO;
      const { name } = catchtivityDTO;
      if (branchIdSet.length > 0) {
        for (const branchDTO of branchDTOList) {
          if (branchIdSet.includes(branchDTO.id)) {
            appliedBranchList.push(branchDTO);
            const foundGlobalBranchDTO = globalAppliedBranchIdList.find(
              (globalBranchDTO) => globalBranchDTO.id === branchDTO.id
            );
            if (!foundGlobalBranchDTO) {
              globalAppliedBranchIdList.push(branchDTO.id);
            }
          }
        }
      } else {
        for (const branchDTO of branchDTOList) {
          appliedBranchList.push(branchDTO);
          const foundGlobalBranchDTO = globalAppliedBranchIdList.find(
            (globalBranchDTO) => globalBranchDTO.id === branchDTO.id
          );
          if (!foundGlobalBranchDTO) {
            globalAppliedBranchIdList.push(branchDTO.id);
          }
        }
      }
      if (currentCatchtivityApplicationMap[catchtivityApplicationDTO.id]) {
        currentCatchtivityApplicationMap[
          catchtivityApplicationDTO.id
        ].appliedBranchList.push(...appliedBranchList);
      } else {
        currentCatchtivityApplicationMap[catchtivityApplicationDTO.id] = {
          appliedBranchList,
        };
      }
    }
    const notAppliedBranchList = branchDTOList.filter(
      (branchDTO) => !globalAppliedBranchIdList.includes(branchDTO.id)
    );
    return (
      <div className="">
        <div className="mb-2">
          <p className="font-semibold">{i18n.t("catchtivity_applications")}</p>
        </div>
        {catchtivityApplicationDTOList.map(
          (catchtivityApplicationDTO, index) => (
            <div key={index}>
              <p>
                <span className="text-catchup-blue">({index + 1}) </span>
                {catchtivityApplicationDTO.catchtivityDTO.name}{" "}
                <span className="text-catchup-green mx-2">
                  {currentCatchtivityApplicationMap[
                    catchtivityApplicationDTO.id
                  ].appliedBranchList.length > 0 ? (
                    <span>
                      {currentCatchtivityApplicationMap[
                        catchtivityApplicationDTO.id
                      ].appliedBranchList.map((branchDTO, index) => {
                        return (
                          <span className="font-semibold">
                            {branchDTO.name}
                            {index !==
                            currentCatchtivityApplicationMap[
                              catchtivityApplicationDTO.id
                            ].appliedBranchList.length -
                              1
                              ? ", "
                              : ""}
                          </span>
                        );
                      })}
                    </span>
                  ) : null}
                </span>
              </p>
            </div>
          )
        )}

        {notAppliedBranchList.length > 0 ? (
          <div className="flex flex-row my-2">
            <RedAlert text={i18n.t("not_applied")}>
              {notAppliedBranchList.map((branchDTO, index) => {
                return (
                  <span key={index} className="cursor-pointer">
                    {branchDTO.name}
                    {index !== notAppliedBranchList.length - 1 ? ", " : ""}
                  </span>
                );
              })}
            </RedAlert>
          </div>
        ) : null}
      </div>
    );
  };

  const RenderPlanWeekCatchxamDTOItem = (catchxamApplicationDTOList) => {
    const currentCatchxamApplicationMap = {};
    const globalAppliedBranchIdList = [];
    for (const catchxamApplicationDTO of catchxamApplicationDTOList) {
      const appliedBranchList = [];
      const { branchIdSet } = catchxamApplicationDTO;
      if (branchIdSet.length > 0) {
        for (const branchDTO of branchDTOList) {
          if (branchIdSet.includes(branchDTO.id)) {
            appliedBranchList.push(branchDTO);
            const foundGlobalBranchDTO = globalAppliedBranchIdList.find(
              (globalBranchDTO) => globalBranchDTO.id === branchDTO.id
            );
            if (!foundGlobalBranchDTO) {
              globalAppliedBranchIdList.push(branchDTO.id);
            }
          }
        }
      } else {
        for (const branchDTO of branchDTOList) {
          appliedBranchList.push(branchDTO);
          const foundGlobalBranchDTO = globalAppliedBranchIdList.find(
            (globalBranchDTO) => globalBranchDTO.id === branchDTO.id
          );
          if (!foundGlobalBranchDTO) {
            globalAppliedBranchIdList.push(branchDTO.id);
          }
        }
      }
      if (currentCatchxamApplicationMap[catchxamApplicationDTO.id]) {
        currentCatchxamApplicationMap[
          catchxamApplicationDTO.id
        ].appliedBranchList.push(...appliedBranchList);
      } else {
        currentCatchxamApplicationMap[catchxamApplicationDTO.id] = {
          appliedBranchList,
        };
      }
    }
    const notAppliedBranchList = branchDTOList.filter(
      (branchDTO) => !globalAppliedBranchIdList.includes(branchDTO.id)
    );

    return (
      <div className="mt-2">
        <div className="mb-2">
          <p className="font-semibold">{i18n.t("catchxam_applications")}</p>
        </div>
        {catchxamApplicationDTOList.map((catchxamApplicationDTO, index) => (
          <div key={index}>
            <p>
              <span className="text-catchup-dark-blue">({index + 1}) </span>
              {catchxamApplicationDTO.catchxamDTO.name}{" "}
              <span>
                {currentCatchxamApplicationMap[catchxamApplicationDTO.id]
                  .appliedBranchList.length > 0 ? (
                  <span className="text-catchup-green mx-2">
                    {currentCatchxamApplicationMap[
                      catchxamApplicationDTO.id
                    ].appliedBranchList.map((branchDTO, index) => {
                      return (
                        <span className="font-semibold" key={index}>
                          {branchDTO.name}
                          {index !==
                          currentCatchxamApplicationMap[
                            catchxamApplicationDTO.id
                          ].appliedBranchList.length -
                            1
                            ? ", "
                            : ""}
                        </span>
                      );
                    })}
                  </span>
                ) : null}
              </span>
            </p>
          </div>
        ))}

        {notAppliedBranchList.length > 0 ? (
          <div className="flex flex-row my-2">
            <RedAlert text={i18n.t("not_applied")}>
              {notAppliedBranchList.map((branchDTO, index) => {
                return (
                  <span key={index}>
                    {branchDTO.name}
                    {index !== notAppliedBranchList.length - 1 ? ", " : ""}
                  </span>
                );
              })}
            </RedAlert>
          </div>
        ) : null}
      </div>
    );
  };

  const RenderPlanWeekDocumentDTOItem = (documentDTOList, planWeekId) => {
    return (
      <div className="w-full flex flex-row items-center flex-wrap gap-x-3">
        {documentDTOList.map((document, index) => {
          const { path, fileName, fileOriginalName } = document;
          return (
            <div key={index} className="flex flex-row items-center gap-x-2">
              {editPlanWeekId === planWeekId ? (
                <BaseImage
                  src="/icons/remove-red.png"
                  alt="remove-red"
                  onClick={() => {
                    handleRemoveIdFromRemovedDocumentIdListOnClick(document.id);
                  }}
                  size="xsmall"
                />
              ) : null}
              <div className="flex-1 flex flex-row items-center cursor-pointer gap-x-2">
                <BaseImage
                  src="/icons/document.png"
                  alt="document"
                  size="xsmall"
                />
                <div className="flex-1">
                  <a
                    href={`${connection.base.storage.url}/storage-files/preview/file${path}/${fileName}`}
                    target={"_blank"}
                  >
                    {fileOriginalName}
                  </a>
                </div>
              </div>
            </div>
          );
        })}
        {editPlanWeekId === planWeekId ? (
          <BaseImage
            src="/icons/plus.png"
            alt="plus"
            onClick={() => {
              setShowDocumentLibraryModal(true);
            }}
            size="small"
          />
        ) : null}
      </div>
    );
  };

  const RenderPlanNotFoundContent = () => {
    return (
      <>
        {selectedVersionCode !== "DEFAULT_OPTION" ? (
          <>
            <div className="flex justify-center items-center my-5">
              <div className="flex flex-row justify-center items-center gap-x-1">
                <BaseImage
                  src="/icons/exclamation-red.png"
                  alt="exclamation-red"
                  size="large"
                />
                <p className="text-2xl">{i18n.t("plan_not_found")}</p>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const RenderPlanWeekEmptyCategoryContent = () => {
    return (
      <div className="mb-2">
        <div className="flex flex-row items-center gap-x-2 opacity-70">
          <BaseImage src="/icons/outcome.png" alt="outcome" size="small" />
          <div className="flex-1">
            <p className="">{i18n.t("empty_plan_week_category_text")}</p>
          </div>
        </div>
      </div>
    );
  };

  const RenderPlanWeekCategoryContent = (
    planWeekCategoryDTOList,
    catchtivityApplicationDTOList,
    catchxamApplicationDTOList
  ) => {
    let categoryIdList = planWeekCategoryDTOList.map(
      (planWeekCategory) => planWeekCategory.categoryId
    );
    const catchtivityCategoryList =
      constructCatchtivityCategoryListByCatchtivityApplicationDTOList(
        catchtivityApplicationDTOList
      );
    const catchxamCategoryList =
      constructCatchxamCategoryListByCatchxamApplicationDTOList(
        catchxamApplicationDTOList
      );
    return (
      <div className="mb-4">
        <div className="mb-2">
          <p className="font-semibold">{i18n.t("plan_week_outcomes")}</p>
        </div>
        {categorySet
          .filter((categoryDTO) =>
            categoryIdList.includes(parseFloat(categoryDTO.id))
          )
          .sort((a, b) => a.code.localeCompare(b.code))
          .map((categoryDTO, index) => {
            const foundCatchtivityCategory = catchtivityCategoryList.find(
              (catchtivityCategory) =>
                catchtivityCategory.categoryDTO.id === categoryDTO.id
            );
            const foundCatchxamCategory = catchxamCategoryList.find(
              (catchxamCategory) =>
                catchxamCategory.categoryDTO.id === categoryDTO.id
            );
            const found =
              foundCatchtivityCategory !== undefined ||
              foundCatchxamCategory !== undefined;
            return (
              <div key={index} className="flex flex-1 flex-row my-1">
                <div>
                  <p className="">
                    <span
                      className={`${
                        found ? "text-catchup-green" : "text-catchup-red"
                      } font-semibold mr-2`}
                    >
                      {categoryDTO.code}
                    </span>
                    {categoryDTO.name}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const RenderPlanWeekCatchtivityCategoryContent = (
    catchtivityApplicationDTOList
  ) => {
    const catchtivityCategoryList =
      constructCatchtivityCategoryListByCatchtivityApplicationDTOList(
        catchtivityApplicationDTOList
      );
    return (
      <div className="mb-4">
        <div className="mb-2">
          <p className="font-semibold">{i18n.t("catchtivity_outcomes")}</p>
        </div>
        {catchtivityCategoryList
          .sort((a, b) => a.categoryDTO.code.localeCompare(b.categoryDTO.code))
          .map((catchtivityCategoryDTO, index) => (
            <div key={index} className="flex flex-1 flex-row my-1 gap-x-2">
              <div className="flex-1">
                <p className="">
                  <span className="text-catchup-blue">{"("}</span>
                  {catchtivityCategoryDTO.indexList.map(
                    (catchtivityIndex, index) => {
                      return (
                        <span key={index} className="text-catchup-blue">
                          <span className="">{catchtivityIndex}</span>
                          <span className="">
                            {index ===
                            catchtivityCategoryDTO.indexList.length - 1
                              ? null
                              : ", "}
                          </span>
                        </span>
                      );
                    }
                  )}
                  <span className="text-catchup-blue">{") "}</span>
                  <span className="font-semibold mr-2">
                    {catchtivityCategoryDTO.categoryDTO.code}
                  </span>
                  {catchtivityCategoryDTO.categoryDTO.name}
                </p>
              </div>
            </div>
          ))}
      </div>
    );
  };

  const RenderPlanWeekCatchxamCategoryContent = (
    catchxamApplicationDTOList
  ) => {
    const catchxamCategoryList =
      constructCatchxamCategoryListByCatchxamApplicationDTOList(
        catchxamApplicationDTOList
      );
    return (
      <div className="mb-4">
        <div className="mb-2">
          <p className="font-semibold">{i18n.t("catchxam_outcomes")}</p>
        </div>
        {catchxamCategoryList
          .sort((a, b) => a.categoryDTO.code.localeCompare(b.categoryDTO.code))
          .map((catchxamCategoryDTO, index) => (
            <div key={index} className="flex flex-1 flex-row my-1 gap-x-2">
              <div className="flex-1">
                <p className="">
                  <span className="text-catchup-dark-blue">{"("}</span>
                  {catchxamCategoryDTO.indexList.map((catchxamIndex, index) => {
                    return (
                      <span key={index} className="text-catchup-dark-blue">
                        <span className="">{catchxamIndex}</span>
                        <span className="">
                          {index === catchxamCategoryDTO.indexList.length - 1
                            ? null
                            : ", "}
                        </span>
                      </span>
                    );
                  })}
                  <span className="text-catchup-dark-blue">{") "}</span>
                  <span className="font-semibold mr-2">
                    {catchxamCategoryDTO.categoryDTO.code}
                  </span>
                  {catchxamCategoryDTO.categoryDTO.name}
                </p>
              </div>
            </div>
          ))}
      </div>
    );
  };

  const RenderPlanWeekEditCategoryContent = (planWeekCategoryDTOList) => {
    let categoryIdList = planWeekCategoryDTOList.map(
      (planWeekCategory) => planWeekCategory.categoryId
    );
    categoryIdList = categoryIdList.concat(includedCategoryIdList);
    categoryIdList = categoryIdList.filter(
      (categoryId) => !removedCategoryIdList.includes(categoryId)
    );
    const filteredCategorySet = categorySet.filter((categoryDTO) =>
      categoryIdList.includes(parseFloat(categoryDTO.id))
    );
    return (
      <div className="mx-2">
        <div className="relative">
          <div className="absolute right-5 top-1">
            <CleanFilterGroup
              subCategoryFilter={subCategoryFilter}
              setSubCategoryFilter={setSubCategoryFilter}
            />
          </div>
          <InputGroup
            type="select"
            title={i18n.t("sub_category_code")}
            placeholder={null}
            value={"DEFAULT_OPTION"}
            optionList={retrieveCategoryDTOOptionList()}
            onChange={(event) => {
              handleAddCategoryIdToIncludedCategoryIdListOnClick(
                event.target.value
              );
            }}
          />
        </div>
        {filteredCategorySet
          .sort((a, b) => a.code.localeCompare(b.code))
          .map((categoryDTO, index) => (
            <div key={index} className="flex flex-1 flex-row my-1 gap-x-2">
              <div className="">
                <BaseImage
                  src="/icons/remove-red.png"
                  alt="remove-red"
                  size="xsmall"
                  onClick={() => {
                    handleAddCategoryIdToRemovedCategoryIdListOnClick(
                      categoryDTO.id
                    );
                  }}
                />
              </div>
              <div className="flex-1">
                <p className="">
                  <span className="font-semibold mr-2">{categoryDTO.code}</span>
                  {categoryDTO.name}
                </p>
              </div>
            </div>
          ))}
      </div>
    );
  };

  const RenderSuggestedPlanWeekActionContent = (planWeek, index) => {
    if (isPlanWeek) return;
    if (editPlanWeekId) return;
    if (
      loadingMap.catchtivityPlanWeekMap ||
      loadingMap.catchtivityApplicationPlanWeekMap ||
      loadingMap.catchxamPlanWeekMap ||
      loadingMap.catchxamApplicationPlanWeekMap
    )
      return;
    if (index === 1) return;
    let activeWeek = false;
    if (
      new Date().getTime() > planWeek.beginAt &&
      new Date().getTime() < planWeek.endAt
    ) {
      activeWeek = true;
    }
    if (!activeWeek) return;
    let suggestedCatchxam = false;

    const planWeekId = planWeek.id;
    if (
      catchxamApplicationMap[planWeekId - 1]?.length === 0 &&
      catchtivityApplicationMap[planWeekId - 1]?.length !== 0
    ) {
      suggestedCatchxam = true;
    }
    let isCatchtivityGiven = false;
    let isCatchtivityApplicationGiven = false;
    if (catchtivityMap[planWeekId]?.length > 0) {
      isCatchtivityGiven = true;
      if (catchtivityApplicationMap[planWeekId]?.length > 0) {
        isCatchtivityApplicationGiven = true;
      }
    }
    let isCatchxamGiven = false;
    let isCatchxamApplicationGiven = false;
    if (catchxamMap[planWeekId]?.length > 0) {
      isCatchxamGiven = true;
      if (catchxamApplicationMap[planWeekId]?.length > 0) {
        isCatchxamApplicationGiven = true;
      }
    }

    const showCatchtivitySuggestion = !isCatchtivityGiven;
    const showCatchtivityApplicationSuggestion = !isCatchtivityApplicationGiven;
    const showCatchxamSuggestion = suggestedCatchxam && !isCatchxamGiven;
    const showCatchxamApplicationSuggestion =
      suggestedCatchxam && !isCatchxamApplicationGiven;
    let top;
    if (
      (showCatchtivitySuggestion || showCatchtivityApplicationSuggestion) &&
      (showCatchxamSuggestion || showCatchxamApplicationSuggestion)
    ) {
      top = -71;
    } else {
      top = -48;
    }

    if (
      !showCatchtivitySuggestion &&
      !showCatchtivityApplicationSuggestion &&
      !showCatchxamSuggestion &&
      !showCatchxamApplicationSuggestion
    )
      return;

    return (
      <div
        className={`${
          activeWeek ? "border-catchup-green" : "border-catchup-blue-400"
        } absolute left-6 border-4 rounded-t-catchup-xlarge border-b-0 py-2 px-4`}
        style={{ top }}
      >
        {showCatchtivityApplicationSuggestion ? (
          showCatchtivitySuggestion ? (
            <div className="flex flex-row items-center gap-x-2">
              <div className="">
                <BaseImage size="small" src="/icons/info.png" />
              </div>
              <div
                className="flex-1 cursor-pointer"
                onClick={() => {
                  handleCreateCatchtivityByPlanWeek(planWeek);
                }}
              >
                <p className="hover:underline">
                  {i18n.t("you_should_assign_catchtivity_this_week")}
                </p>
              </div>
            </div>
          ) : (
            <div className="flex flex-row items-center gap-x-2">
              <div className="">
                <BaseImage size="small" src="/icons/info.png" />
              </div>
              <div
                className="flex-1 cursor-pointer"
                onClick={() => {
                  handleCreateCatchtivityApplicationByPlanWeek(planWeek);
                }}
              >
                <p className="hover:underline">
                  {i18n.t(
                    "you_should_assign_catchtivity_application_this_week"
                  )}
                </p>
              </div>
            </div>
          )
        ) : null}
        {showCatchxamApplicationSuggestion ? (
          showCatchxamSuggestion ? (
            <div className="flex flex-row items-center gap-x-2">
              <div className="">
                <BaseImage size="small" src="/icons/info.png" />
              </div>
              <div
                className="flex-1 cursor-pointer"
                onClick={() => {
                  handleCreateCatchxamByPlanWeek(planWeek);
                }}
              >
                <p className="hover:underline">
                  {i18n.t("you_should_assign_catchxam_this_week")}
                </p>
              </div>
            </div>
          ) : (
            <div className="flex flex-row items-center gap-x-2">
              <div className="">
                <BaseImage size="small" src="/icons/info.png" />
              </div>
              <div
                className="flex-1 cursor-pointer"
                onClick={() => {
                  handleCreateCatchxamApplicationByPlanWeek(planWeek);
                }}
              >
                <p className="hover:underline">
                  {i18n.t("you_should_assign_catchxam_application_this_week")}
                </p>
              </div>
            </div>
          )
        ) : null}
      </div>
    );
  };

  const RenderPlanWeekContent = (planWeek, index) => {
    const beginDate = new Date(planWeek.beginAt);
    const endDate = new Date(planWeek.endAt);
    return (
      <>
        {new Date().getTime() > planWeek.endAt ? (
          planWeekState === "ALL" ? (
            index === 1 ? null : (
              <div className="mb-16 lg:mb-0 flex flex-col items-center justify-center ">
                <BlueVerticalDividerLine opacity="medium" />
              </div>
            )
          ) : null
        ) : index === 1 ? null : (
          <div className="mb-16 lg:mb-0 flex flex-col items-center justify-center ">
            <BlueVerticalDividerLine opacity="medium" />
          </div>
        )}
        <div
          className={`${
            planWeekState === "ALL"
              ? ""
              : new Date().getTime() > planWeek.endAt
              ? "hidden"
              : ""
          } ${
            new Date().getTime() > planWeek.beginAt &&
            new Date().getTime() < planWeek.endAt
              ? "border-4 border-catchup-green rounded-catchup-xlarge"
              : planWeek.id === editPlanWeekId
              ? ""
              : "rounded-catchup-xlarge border-opacity-50"
          } transition-all duration-300 my-2 relative bg-catchup-plan-item text-catchup-gray-400`}
        >
          {RenderSuggestedPlanWeekActionContent(planWeek, index)}
          <div className="flex flex-1 flex-col p-3">
            <div className="flex flex-1 flex-row items-center justify-between">
              <div className="">
                {isPlanWeek ? (
                  <p className="font-semibold">{i18n.t("this_week")}</p>
                ) : (
                  <p className="font-semibold text-lg">
                    {index}. {i18n.t("week")}
                    <span className="font-normal px-2">
                      {constructWeekName(beginDate)}
                    </span>
                  </p>
                )}
              </div>
              <div className="">
                {!isPlanWeek && editPlanWeekId !== planWeek.id ? (
                  <HoverViewButton
                    src="/icons/three-dots.png"
                    alt="three-dots"
                    size="small"
                    optionList={
                      planWeek.id === editPlanWeekId
                        ? constructEditOptionList()
                        : constructPlanWeekOptionList(planWeek)
                    }
                    optionSize={
                      planWeek.id === editPlanWeekId ? "small" : "medium"
                    }
                  />
                ) : null}
              </div>
              {editPlanWeekId === planWeek.id ? (
                <div className="flex flex-row justify-end flex-wrap gap-x-2">
                  <CancelButton
                    title={i18n.t("cancel")}
                    size="small"
                    onClick={() => {
                      setUpdatePlanWeekId(editPlanWeekId);
                      setEditPlanWeekId(null);
                    }}
                  />

                  <CreateButton
                    title={i18n.t("save")}
                    size="small"
                    onClick={handleSavePlanWeekOnClick}
                  />
                </div>
              ) : null}
            </div>
            <div className="flex flex-1 flex-row gap-x-5 mt-5">
              <div className="flex-1">
                {planWeek.id === editPlanWeekId
                  ? RenderPlanWeekEditCategoryContent(
                      planWeek.planWeekCategoryDTOList
                    )
                  : planWeek.planWeekCategoryDTOList.length === 0
                  ? RenderPlanWeekEmptyCategoryContent()
                  : RenderPlanWeekCategoryContent(
                      planWeek.planWeekCategoryDTOList,
                      catchtivityApplicationMap[planWeek.id],
                      catchxamApplicationMap[planWeek.id]
                    )}

                {showCatchtivityOutcomes &&
                catchtivityApplicationMap[planWeek.id]
                  ? catchtivityApplicationMap[planWeek.id].length !== 0
                    ? RenderPlanWeekCatchtivityCategoryContent(
                        catchtivityApplicationMap[planWeek.id]
                      )
                    : null
                  : null}

                {showCatchxamOutcomes && catchxamApplicationMap[planWeek.id]
                  ? catchxamApplicationMap[planWeek.id].length !== 0
                    ? RenderPlanWeekCatchxamCategoryContent(
                        catchxamApplicationMap[planWeek.id]
                      )
                    : null
                  : null}
              </div>
              <div className="flex-1">
                <div className="mb-2">
                  {loadingMap.catchtivityApplicationPlanWeekMap ? (
                    <BaseLoading
                      size="small"
                      color="#57C2D3"
                      secondaryColor="#57C2D3"
                    />
                  ) : catchtivityApplicationMap[planWeek.id] ? (
                    catchtivityApplicationMap[planWeek.id].length === 0 ? (
                      <>
                        <div className="flex flex-row items-center gap-x-2 opacity-70">
                          <BaseImage
                            src="/icons/catchtivity.png"
                            alt="catchtivity"
                            size="small"
                          />
                          <div className="flex-1">
                            <p className="">
                              {i18n.t(
                                "empty_plan_week_catchtivity_application_text"
                              )}
                            </p>
                          </div>
                        </div>

                        {catchtivityMap[planWeek.id]?.length > 0
                          ? RenderCreatedButNotAppliedContent(
                              catchtivityMap[planWeek.id][0].name,
                              () => {
                                handleToNavigateCatchtivityApplication(
                                  catchtivityMap[planWeek.id][0],
                                  planWeek.beginAt,
                                  planWeek.endAt
                                );
                              }
                            )
                          : null}
                      </>
                    ) : (
                      RenderPlanWeekCatchtivityDTOItem(
                        catchtivityApplicationMap[planWeek.id]
                      )
                    )
                  ) : null}
                </div>
                <div className="mb-2">
                  {loadingMap.catchxamApplicationPlanWeekMap ? (
                    <BaseLoading
                      size="small"
                      color="#57C2D3"
                      secondaryColor="#57C2D3"
                    />
                  ) : catchxamApplicationMap[planWeek.id] ? (
                    catchxamApplicationMap[planWeek.id].length === 0 ? (
                      <>
                        <div className="flex flex-row items-center gap-x-2 opacity-70">
                          <BaseImage
                            src="/icons/catchxam.png"
                            alt="catchxam"
                            size="small"
                          />
                          <div className="flex-1">
                            <p className="">
                              {i18n.t(
                                "empty_plan_week_catchxam_application_text"
                              )}
                            </p>
                          </div>
                        </div>
                        {catchxamMap[planWeek.id]?.length > 0
                          ? RenderCreatedButNotAppliedContent(
                              catchxamMap[planWeek.id][0].name,
                              () => {
                                handleToNavigateCatchxamApplication(
                                  catchxamMap[planWeek.id][0],
                                  planWeek.beginAt,
                                  planWeek.endAt
                                );
                              }
                            )
                          : null}
                      </>
                    ) : (
                      RenderPlanWeekCatchxamDTOItem(
                        catchxamApplicationMap[planWeek.id]
                      )
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="p-3">
            <DividerLine />
            {loadingMap.documentPlanWeekMap ? (
              <BaseLoading
                size="small"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            ) : documentMap[planWeek.id] ? (
              documentMap[planWeek.id].length === 0 ? (
                <div className="flex flex-row items-center flex-wrap gap-x-2">
                  <div className="flex flex-row items-center gap-x-2 opacity-70">
                    <BaseImage
                      src="/icons/document.png"
                      alt="document"
                      size="small"
                    />
                    <div className="flex-1">
                      <p className="">
                        {i18n.t("empty_plan_week_document_text")}
                      </p>
                    </div>
                  </div>
                  {editPlanWeekId === planWeek.id ? (
                    <BaseImage
                      src="/icons/plus.png"
                      alt="plus"
                      size="small"
                      onClick={() => {
                        setShowDocumentLibraryModal(true);
                      }}
                    />
                  ) : null}
                </div>
              ) : (
                RenderPlanWeekDocumentDTOItem(
                  documentMap[planWeek.id],
                  planWeek.id
                )
              )
            ) : null}
          </div>
        </div>
      </>
    );
  };

  const RenderCreatedButNotAppliedContent = (name, onClick) => {
    return (
      <div className="flex flex-row items-center my-2">
        <RedAlert text={i18n.t("created_but_not_applied")}>
          <span className="cursor-pointer" onClick={onClick}>
            {name}
          </span>
        </RedAlert>
      </div>
    );
  };

  const RenderPlanWeekHolidayContent = (planWeek, index) => {
    const beginDate = new Date(planWeek.beginAt);
    const endDate = new Date(planWeek.endAt);
    return (
      <>
        {new Date().getTime() > planWeek.endAt ? (
          planWeekState === "ALL" ? (
            index === 1 ? null : (
              <div className="mb-16 lg:mb-0 flex flex-col items-center justify-center ">
                <BlueVerticalDividerLine opacity="medium" />
              </div>
            )
          ) : null
        ) : index === 1 ? null : (
          <div className="mb-16 lg:mb-0 flex flex-col items-center justify-center ">
            <BlueVerticalDividerLine opacity="medium" />
          </div>
        )}
        <div
          className={`${
            planWeekState === "ALL"
              ? ""
              : new Date().getTime() > planWeek.endAt
              ? "hidden"
              : ""
          } rounded-catchup-xlarge border-opacity-50 transition-all duration-300 my-2 relative bg-catchup-blue-50 text-catchup-gray-400`}
        >
          <div className="flex flex-1 flex-col p-3">
            <div className="flex flex-1 flex-row items-center justify-between">
              <div className="">
                {isPlanWeek ? (
                  <p className="font-semibold">{i18n.t("this_week")}</p>
                ) : (
                  <p className="font-semibold text-lg">
                    {i18n.t("week")}
                    <span className="font-normal px-2">
                      {constructWeekName(beginDate)}
                    </span>
                  </p>
                )}
              </div>
              <div className="">
                {!isPlanWeek ? (
                  <div className="ml-3">
                    <HoverViewButton
                      src="/icons/three-dots.png"
                      alt="three-dots"
                      size="small"
                      optionList={constructHolidayOptionList(planWeek)}
                      optionSize={"small"}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex flex-1 flex-row gap-x-5 mt-5">
              <div className="flex-1 flex flex-col justify-center items-center">
                <BaseImage
                  src="icons/holiday.png"
                  alt="holiday"
                  size="xlarge"
                />
              </div>
              <div className="flex-1">
                <div className="mb-2">
                  {loadingMap.catchtivityApplicationPlanWeekMap ? (
                    <BaseLoading
                      size="small"
                      color="#57C2D3"
                      secondaryColor="#57C2D3"
                    />
                  ) : catchtivityApplicationMap[planWeek.id] ? (
                    catchtivityApplicationMap[planWeek.id].length === 0 ? (
                      <>
                        <div className="flex flex-row items-center gap-x-2 opacity-70">
                          <BaseImage
                            src="/icons/catchtivity.png"
                            alt="catchtivity"
                            size="small"
                          />
                          <div className="flex-1">
                            <p className="">
                              {i18n.t(
                                "empty_plan_week_catchtivity_application_text"
                              )}
                            </p>
                          </div>
                        </div>
                        {catchtivityMap[planWeek.id]?.length > 0
                          ? RenderCreatedButNotAppliedContent(
                              catchtivityMap[planWeek.id][0].name,
                              () => {
                                handleToNavigateCatchtivityApplication(
                                  catchtivityMap[planWeek.id][0],
                                  planWeek.beginAt,
                                  planWeek.endAt
                                );
                              }
                            )
                          : null}
                      </>
                    ) : (
                      RenderPlanWeekCatchtivityDTOItem(
                        catchtivityApplicationMap[planWeek.id]
                      )
                    )
                  ) : null}
                </div>
                <div className="mb-2">
                  {loadingMap.catchxamApplicationPlanWeekMap ? (
                    <BaseLoading
                      size="small"
                      color="#57C2D3"
                      secondaryColor="#57C2D3"
                    />
                  ) : catchxamApplicationMap[planWeek.id] ? (
                    catchxamApplicationMap[planWeek.id].length === 0 ? (
                      <>
                        <div className="flex flex-row items-center gap-x-2 opacity-70">
                          <BaseImage
                            src="/icons/catchxam.png"
                            alt="catchxam"
                            size="small"
                          />
                          <div className="flex-1">
                            <p className="">
                              {i18n.t(
                                "empty_plan_week_catchxam_application_text"
                              )}
                            </p>
                          </div>
                        </div>
                        {catchxamMap[planWeek.id]?.length > 0
                          ? RenderCreatedButNotAppliedContent(
                              catchxamMap[planWeek.id][0].name,
                              () => {
                                handleToNavigateCatchxamApplication(
                                  catchxamMap[planWeek.id][0],
                                  planWeek.beginAt,
                                  planWeek.endAt
                                );
                              }
                            )
                          : null}
                      </>
                    ) : (
                      RenderPlanWeekCatchxamDTOItem(
                        catchxamApplicationMap[planWeek.id]
                      )
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const RenderPlanFoundContent = () => {
    let weekIndex = 0;
    return (
      <div className="my-10">
        {plan.planWeekDTOList
          .sort((a, b) => a.id - b.id)
          .map((planWeekDTO, index) => {
            if (planWeekDTO.isHoliday) {
              return (
                <div key={index}>
                  {RenderPlanWeekHolidayContent(planWeekDTO, weekIndex)}
                </div>
              );
            } else {
              weekIndex++;
              return (
                <div key={index}>
                  {RenderPlanWeekContent(planWeekDTO, weekIndex)}
                </div>
              );
            }
          })}
      </div>
    );
  };

  const RenderPlanContent = () => {
    if (canQueryPlan) {
      return (
        <>
          {loadingMap.plan ? (
            <BaseLoading
              size="medium"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          ) : plan ? (
            plan === "NOT_FOUND" ? (
              RenderPlanNotFoundContent()
            ) : (
              RenderPlanFoundContent()
            )
          ) : null}
        </>
      );
    }
    return null;
  };

  const RenderShowDeleteModal = () => {
    return (
      <BaseModal
        isOpen={showDeleteModal}
        size="small"
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowDeleteModal(false);
          setSelectedDeleteItemId(null);
        }}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            <div className="flex flex-col items-center gap-y-4">
              <div className="">
                <BaseImage size="large" src="/icons/remove.png" alt="remove" />
              </div>
              <div className="w-[300px] text-center">
                <p className="text-xl">
                  {i18n.t("are_you_sure_do_you_want_to_delete_plan_text")}
                </p>
              </div>
              <div className="flex flex-row items-center justify-center gap-x-4">
                <CancelButton
                  title={i18n.t("cancel")}
                  size="small"
                  onClick={() => {
                    setShowDeleteModal(false);
                  }}
                />
                <PrimaryButton
                  title={i18n.t("approve")}
                  size="small"
                  onClick={() => {
                    setShowDeleteModal(false);
                    handleDeletePlanOnClick();
                  }}
                />
              </div>
            </div>
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  const RenderShowSomeoneUpdatedModal = () => {
    return (
      <BaseModal
        isOpen={showSomeoneUpdatedModal}
        size="small"
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowSomeoneUpdatedModal(false);
        }}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            <div className="flex flex-col items-center justify-center gap-y-4">
              <BaseImage
                size="large"
                src="/icons/already-updated.png"
                alt="already-updated"
              />
              <div>
                <p className="w-[300px] text-xl text-center">
                  {i18n.t("someone_updated_the_plan_text")}
                </p>
              </div>
            </div>

            {someoneInformation ? (
              <div className="flex flex-col justify-center items-center">
                <p>
                  {someoneInformation.firstName} {someoneInformation.lastName}
                </p>
              </div>
            ) : null}
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  const RenderLibrarySelectionModal = () => {
    return (
      <BaseModal
        isOpen={showDocumentLibraryModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowDocumentLibraryModal(false);
        }}
      >
        <div className="flex-1 flex flex-col">
          {showDocumentLibraryModal ? (
            <ListAndUpsertStorageFile
              title={i18n.t("plan_documents")}
              description={i18n.t("plan_document_list_table_description_text")}
              type={userProfile.role === "STAFF" ? "brand" : "personal"}
              selectable={false}
              labelable={true}
              showLabelList={true}
              initialFilterApplied={true}
              initialCoterieType={selectedCoterieType}
              initialDocumentType={"PDF"}
              handleSelectOnClick={
                handleAddDocumentIdToIncludedDocumentIdListOnClick
              }
              ownershipType={"PLAN_DOCUMENT"}
              optionFilter={"PDF"}
            />
          ) : null}
        </div>
      </BaseModal>
    );
  };

  const RenderSingleChooseOutcomeOutcomeItemContent = (
    outcome,
    isSuggested
  ) => {
    const foundOutcomeOccurrence = chooseOutcomeOutcomeOccurrenceList.find(
      (outcomeOccurrence) => outcomeOccurrence.categoryId === outcome.id
    );
    return (
      <div
        className={`${
          foundOutcomeOccurrence.isMust ? "opacity-100" : "opacity-70"
        } flex flex-row items-center py-2 transition-all duration-300 cursor-pointer gap-x-2`}
        onClick={() => {
          if (isSuggested) {
            NotificationManager.info(
              i18n.t("do_not_remove_suggested_outcome_text")
            );
          } else {
            if (
              !foundOutcomeOccurrence.isMust &&
              chooseOutcomeOutcomeOccurrenceList.filter((item) => item.isMust)
                .length === chooseOutcomeChooseParams.numberOfActivity
            ) {
              NotificationManager.info(
                i18n.t(
                  "number_of_outcomes_cannot_be_more_than_number_of_activity"
                )
              );
            } else {
              foundOutcomeOccurrence.isMust = !foundOutcomeOccurrence.isMust;
              setChooseOutcomeOutcomeOccurrenceList(
                JSON.parse(JSON.stringify(chooseOutcomeOutcomeOccurrenceList))
              );
            }
          }
        }}
      >
        <BaseImage
          src={`${
            foundOutcomeOccurrence.isMust
              ? "/icons/checkbox.png"
              : "/icons/checkbox-empty.png"
          }`}
          alt="checkbox"
          size="small"
        />
        <div className="flex-1">
          <p className="">{`${outcome.code} ${outcome.name}`}</p>
        </div>
      </div>
    );
  };

  const RenderChooseOutcomeModal = () => {
    if (!chooseOutcomePlanWeek) return;
    if (!chooseOutcomeOutcomeOccurrenceList.length === 0) return;
    const outcomeSet = new Set();
    const otherOutcomeSet = new Set();
    const { id: planWeekId, planWeekCategoryDTOList } = chooseOutcomePlanWeek;
    chooseOutcomeOutcomeOccurrenceList.forEach((outcomeOccurrence) => {
      const foundCategoryDTO = categorySet.find(
        (categoryDTO) => categoryDTO.id === outcomeOccurrence.categoryId
      );
      if (chooseOutcomeState === "CATCHTIVITY") {
        if (
          planWeekCategoryDTOList.findIndex(
            (planWeekCategoryDTO) =>
              planWeekCategoryDTO.categoryId === outcomeOccurrence.categoryId
          ) !== -1
        ) {
          outcomeSet.add(foundCategoryDTO);
        } else {
          otherOutcomeSet.add(foundCategoryDTO);
        }
      } else if (chooseOutcomeState === "CATCHXAM") {
        outcomeSet.add(foundCategoryDTO);
      }
    });
    const outcomeList = Array.from(outcomeSet);
    outcomeList.sort((a, b) => a.code.localeCompare(b.code));
    const otherOutcomeList = Array.from(otherOutcomeSet).filter(
      (otherOutcome) =>
        outcomeList.findIndex((outcome) => outcome.id === otherOutcome.id) ===
        -1
    );
    otherOutcomeList.sort((a, b) => a.code.localeCompare(b.code));
    return (
      <BaseModal
        isOpen={showChooseOutcomeModal}
        onAfterOpen={() => {
          if (
            chooseOutcomeState !== "CATCHTIVITY" &&
            chooseOutcomeState !== "CATCHXAM"
          ) {
            setShowChooseOutcomeModal(false);
            setChooseOutcomeState(null);
            setChooseOutcomePlanWeek(null);
            setChooseOutcomeChooseParams(null);
            setChooseOutcomeOutcomeOccurrenceList([]);
          }
        }}
        onRequestClose={() => {
          setShowChooseOutcomeModal(false);
          setChooseOutcomeState(null);
          setChooseOutcomePlanWeek(null);
          setChooseOutcomeChooseParams(null);
          setChooseOutcomeOutcomeOccurrenceList([]);
        }}
      >
        <FullCard isShadowed={true}>
          <div className="h-full">
            <div className="">
              <div className="flex flex-row items-center gap-x-2">
                {chooseOutcomeState === "CATCHTIVITY" ? (
                  <BaseTitle title={i18n.t("choose_catchtivity_outcomes")} />
                ) : chooseOutcomeState === "CATCHXAM" ? (
                  <BaseTitle title={i18n.t("choose_catchxam_outcomes")} />
                ) : null}
              </div>
            </div>
            <DividerLine />
            <div className="h-full">
              <div className="flex flex-col mb-4">
                {chooseOutcomeState === "CATCHTIVITY" ? (
                  <>
                    {SingleInformationIconTextItem(
                      i18n.t("choose_catchtivity_plan_week_outcomes_text_1")
                    )}
                    {SingleInformationIconTextItem(
                      i18n.t("choose_catchtivity_plan_week_outcomes_text_2")
                    )}
                    {SingleInformationIconTextItem(
                      i18n.t("choose_catchtivity_plan_week_outcomes_text_3")
                    )}
                    {SingleInformationIconTextItem(
                      i18n.t("choose_catchtivity_plan_week_outcomes_text_4")
                    )}
                  </>
                ) : chooseOutcomeState === "CATCHXAM" ? (
                  <>
                    {SingleInformationIconTextItem(
                      i18n.t("choose_catchxam_plan_week_outcomes_text_1")
                    )}
                    {SingleInformationIconTextItem(
                      i18n.t("choose_catchxam_plan_week_outcomes_text_2")
                    )}
                    {SingleInformationIconTextItem(
                      i18n.t("choose_catchxam_plan_week_outcomes_text_3")
                    )}
                    {SingleInformationIconTextItem(
                      i18n.t("choose_catchxam_plan_week_outcomes_text_4")
                    )}
                  </>
                ) : null}
              </div>
              <div className="h-full flex-1 px-4 py-2 border border-catchup-gray-100 rounded-catchup-xlarge">
                <div className="w-full">
                  <p className="font-semibold text-lg">
                    {i18n.t("suggested_outcomes")}
                  </p>
                </div>
                {outcomeList.map((outcome, index) => (
                  <div key={`suggested_${index}`}>
                    {RenderSingleChooseOutcomeOutcomeItemContent(outcome, true)}
                  </div>
                ))}
                {otherOutcomeList.length > 0 ? (
                  <>
                    <DividerLine />
                    <div className="w-full">
                      <p className="font-semibold text-lg">
                        {i18n.t("other_outcomes")}
                      </p>
                    </div>
                    {otherOutcomeList.map((outcome, index) => (
                      <div key={`other_${index}`}>
                        {RenderSingleChooseOutcomeOutcomeItemContent(
                          outcome,
                          false
                        )}
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
              {chooseOutcomeOutcomeOccurrenceList.filter((item) => item.isMust)
                .length > 0 ? (
                <div className="flex flex-col">
                  <div className="mt-4 ml-auto">
                    <SecondaryButton
                      title={i18n.t("choose")}
                      size="small"
                      onClick={() => {
                        const { beginAt, endAt } = chooseOutcomePlanWeek;
                        const beginDate = new Date(beginAt);
                        const weekName = constructWeekName(beginDate);
                        const name = weekName;
                        const foundGrade = gradeDTOList.find(
                          (gradeDTO) => gradeDTO.id === selectedGradeId
                        );
                        let navigatePath;
                        if (chooseOutcomeState === "CATCHTIVITY") {
                          navigatePath = "/catchtivities";
                        } else if (chooseOutcomeState === "CATCHXAM") {
                          navigatePath = "/catchxams";
                        }
                        navigate(navigatePath, {
                          state: {
                            createParams: {
                              name,
                              description: name,
                              level: foundGrade.level,
                              coterieType: selectedCoterieType,
                              planWeekId,
                            },
                            filterParams: {
                              versionCode: selectedVersionCode,
                              categoryOccurrenceList:
                                chooseOutcomeOutcomeOccurrenceList,
                            },
                            chooseParams: chooseOutcomeChooseParams,
                            pageState: 1,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </FullCard>
      </BaseModal>
    );
  };

  const filteredSeasonDTOList = filterSeasonDTOList();
  const categoryVersionCodeOptionList = retrieveCategoryVersionCodeOptionList();
  const filteredCategoryVersionCodeOptionList =
    filterCategoryVersionCodeOptionListByGradeDTO(
      categoryVersionCodeOptionList,
      selectedCoterieType,
      gradeDTOList.find((gradeDTO) => gradeDTO.id === selectedGradeId)
    );

  return (
    <>
      {RenderShowSomeoneUpdatedModal()}
      {RenderLibrarySelectionModal()}
      {RenderShowDeleteModal()}
      {RenderChooseOutcomeModal()}
      <FullCard isShadowed={true}>
        <div className="">
          <BaseTitle title={i18n.t("syllabus")} />
        </div>
        <DividerLine />
        {userProfileGrade
          ? null
          : SingleInformationIconTextItem(i18n.t("please_select_grade"))}

        <div className="flex flex-row items-center flex-wrap">
          {userProfileBrand ? null : (
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("brand")}
                  placeholder={i18n.t("brand")}
                  value={selectedBrandId}
                  optionList={retrieveBrandDTOOptionList(brandDTOList)}
                  onChange={(event) => {
                    handleOnChange("selectedBrandId", event.target.value);
                  }}
                />
              </div>
            </div>
          )}

          {userProfileCampus ? null : selectedBrandId !== "DEFAULT_OPTION" ? (
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("campus")}
                  placeholder={i18n.t("campus")}
                  value={selectedCampusId}
                  optionList={retrieveCampusDTOOptionList(campusDTOList)}
                  onChange={(event) => {
                    handleOnChange("selectedCampusId", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}

          {userProfileInstitution ? null : selectedCampusId !==
            "DEFAULT_OPTION" ? (
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("institution")}
                  placeholder={i18n.t("institution")}
                  value={selectedInstitutionId}
                  optionList={retrieveInstitutionDTOOptionList(
                    institutionDTOList
                  )}
                  onChange={(event) => {
                    handleOnChange("selectedInstitutionId", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}

          {userProfileSeason ? null : selectedInstitutionId !==
            "DEFAULT_OPTION" ? (
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("season")}
                  placeholder={i18n.t("season")}
                  value={selectedSeasonId}
                  optionList={retrieveSeasonDTOOptionList(
                    filteredSeasonDTOList
                  )}
                  onChange={(event) => {
                    handleOnChange("selectedSeasonId", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}

          {userProfileGrade ? null : selectedSeasonId !== "DEFAULT_OPTION" ? (
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("grade")}
                  placeholder={i18n.t("grade")}
                  value={selectedGradeId}
                  optionList={retrieveGradeDTOOptionList(gradeDTOList)}
                  onChange={(event) => {
                    handleOnChange("selectedGradeId", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>

        {selectedGradeId !== "DEFAULT_OPTION" ? (
          <>
            {userProfileGrade ? null : <DividerLine />}
            {SingleInformationIconTextItem(
              i18n.t("please_select_coterie_type_and_version_code")
            )}
            <div className="flex justify-between items-center">
              <div className="flex-1 flex flex-row">
                {userProfile.coterieType === "MANAGEMENT" ? (
                  <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                    <div className="mx-2">
                      <InputGroup
                        type="select"
                        title={i18n.t("coterie_type")}
                        placeholder={i18n.t("coterie_type")}
                        value={selectedCoterieType}
                        optionList={filterCoterieTypeOptionList(
                          userInformation,
                          userProfile,
                          userProfileInstitution
                        )}
                        onChange={(event) => {
                          handleOnChange(
                            "selectedCoterieType",
                            event.target.value
                          );
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {selectedCoterieType !== "DEFAULT_OPTION" &&
                selectedCoterieType !== "MANAGEMENT" ? (
                  <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                    <div className="mx-2">
                      <InputGroup
                        type="select"
                        title={i18n.t("version_code")}
                        placeholder={i18n.t("version_code")}
                        value={selectedVersionCode}
                        optionList={filteredCategoryVersionCodeOptionList}
                        onChange={(event) => {
                          handleOnChange(
                            "selectedVersionCode",
                            event.target.value
                          );
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        ) : null}

        {plan ? (
          <>
            {plan !== "NOT_FOUND" ? <DividerLine /> : null}
            <div className="flex flex-row justify-between items-center gap-x-2">
              {plan !== "NOT_FOUND" ? (
                <>
                  {isPlanWeek ? null : (
                    <div className="w-full flex flex-row items-center justify-between">
                      <SelectionTab
                        optionList={[
                          {
                            id: "PRESENT",
                            title: i18n.t("present"),
                          },
                          { id: "ALL", title: i18n.t("all_weeks") },
                        ]}
                        selectedId={planWeekState}
                        handleSelectOnClick={(selectedId) => {
                          setPlanWeekState(selectedId);
                        }}
                      />
                      <div className="flex flex-col">
                        <div
                          className="flex flex-row items-center gap-x-2 cursor-pointer"
                          onClick={() => {
                            setShowCatchtivityOutcomes(
                              !showCatchtivityOutcomes
                            );
                          }}
                        >
                          <BaseImage
                            src={`${
                              showCatchtivityOutcomes
                                ? "/icons/checkbox.png"
                                : "/icons/checkbox-empty.png"
                            }`}
                            alt="checkbox"
                            size="small"
                          />
                          <div className="flex-1">
                            <p>{i18n.t("show_catchtivity_outcomes")}</p>
                          </div>
                        </div>
                        <div
                          className="flex flex-row items-center gap-x-2 cursor-pointer"
                          onClick={() => {
                            setShowCatchxamOutcomes(!showCatchxamOutcomes);
                          }}
                        >
                          <BaseImage
                            src={`${
                              showCatchxamOutcomes
                                ? "/icons/checkbox.png"
                                : "/icons/checkbox-empty.png"
                            }`}
                            alt="checkbox"
                            size="small"
                          />

                          <div className="flex-1">
                            <p>{i18n.t("show_catchxam_outcomes")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {plan === "NOT_FOUND" &&
              checkInsertPermissionGranted("plan_list") &&
              !isPlanWeek ? (
                <div className="flex-1 flex flex-row justify-end mx-2">
                  <CreateButton
                    title={i18n.t("create")}
                    size="medium"
                    onClick={handleCreatePlanOnClick}
                  />
                </div>
              ) : plan &&
                checkDeletePermissionGranted("plan_list") &&
                !isPlanWeek ? (
                <div className="mx-2">
                  <DeleteButton
                    title={i18n.t("delete_plan")}
                    size="medium"
                    onClick={() => {
                      setSelectedDeleteItemId(plan.id);
                      setShowDeleteModal(true);
                    }}
                  />
                </div>
              ) : null}
            </div>
          </>
        ) : null}

        {RenderPlanContent()}
      </FullCard>
    </>
  );
};

export default ListPlan;
