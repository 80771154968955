import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Server
 */
export const checkBundleService = async () => {
  try {
    const response = await axios
      .create()
      .get(
        `${connection.base.bundle.url}${endpoints.bundle.context}${endpoints.bundle.actuator.health.context}`
      );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Activity Bundle
 */
export const queryAllActivityBundleDTOPage = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.bundle.url}${endpoints.bundle.activities.context}${endpoints.bundle.activities.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllActivityBundleDTOListByBrandId = async (brandId) => {
  try {
    const response = await axios.post(
      `${connection.base.bundle.url}${endpoints.bundle.activities.context}${endpoints.bundle.activities.query.dto["by-brandId"].context}`,
      { brandId }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createActivityBundle = async (newActivityBundle) => {
  try {
    const response = await axios.post(
      `${connection.base.bundle.url}${endpoints.bundle.activities.context}/`,
      newActivityBundle
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

export const patchActivityBundle = async (patchActivityBundle) => {
  try {
    const response = await axios.patch(
      `${connection.base.bundle.url}${endpoints.bundle.activities.context}/`,
      patchActivityBundle
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

export const deleteActivityBundle = async (activityBundleId) => {
  try {
    const response = await axios.delete(
      `${connection.base.bundle.url}${endpoints.bundle.activities.context}/${activityBundleId}`
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

/**
 * Assign Activity Bundle
 */
export const queryAssignActivityBundleDTOPage = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.bundle.url}${endpoints.bundle.activities.assign.context}${endpoints.bundle.activities.assign.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAssignActivityBundleDTOListByBrandId = async (brandId) => {
  try {
    const response = await axios.post(
      `${connection.base.bundle.url}${endpoints.bundle.activities.assign.context}${endpoints.bundle.activities.assign.query.dto["by-brandId"].context}`,
      { brandId }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAssignActivityBundleDTOListByCampusId = async (campusId) => {
  try {
    const response = await axios.post(
      `${connection.base.bundle.url}${endpoints.bundle.activities.assign.context}${endpoints.bundle.activities.assign.query.dto["by-campusId"].context}`,
      { campusId }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAssignActivityBundleDTOListByBrandIdAndCampusId_Seperately =
  async (brandId, campusId) => {
    try {
      const response = await axios.post(
        `${connection.base.bundle.url}${endpoints.bundle.activities.assign.context}${endpoints.bundle.activities.assign.query.dto["by-brandId-campusId"].context}`,
        { brandId, campusId }
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

export const queryAssignActivityBundleDTOListByInstitutionId = async (
  institutionId
) => {
  try {
    const response = await axios.post(
      `${connection.base.bundle.url}${endpoints.bundle.activities.assign.context}${endpoints.bundle.activities.assign.query.dto["by-institutionId"].context}`,
      { institutionId }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAssignActivityBundleDTOListByBrandIdAndCampusIdAndInstitutionId_Seperately =
  async (brandId, campusId, institutionId) => {
    try {
      const response = await axios.post(
        `${connection.base.bundle.url}${endpoints.bundle.activities.assign.context}${endpoints.bundle.activities.assign.query.dto["by-brandId-campusId-institutionId"].context}`,
        { brandId, campusId, institutionId }
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

export const queryAssignActivityBundleDTOListByBrandIdAndCampusId = async (
  brandId,
  campusId
) => {
  try {
    const response = await axios.post(
      `${connection.base.bundle.url}${endpoints.bundle.activities.assign.context}${endpoints.bundle.activities.assign.query.dto["by-brandId-campusId"].context}`,
      { brandId, campusId }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const addOrRemoveActivityBundleToBrandOnClick = async (
  activityBundleOperations
) => {
  try {
    const response = await axios.post(
      `${connection.base.bundle.url}${endpoints.bundle.activities.assign.context}${endpoints.bundle.activities.assign["add-or-remove"].multiple["activity-bundle-to-brand"].context}`,
      activityBundleOperations
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
