import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import InputGroup from "../../components/groups/InputGroup";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  retrieveTokenInformation,
  retrieveUserInformationFromToken,
  retrieveUserProfileList,
} from "../../requests/ManagementRequests";
import BaseCard from "../../components/cards/BaseCard";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import BaseImage from "../../components/images/BaseImage";
import RightsReserved from "../../components/landing/RightsReserved";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const LoginScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setBoarding, isFullScreen, setIsFullScreen } = useApp();
  const { handleStateLogin } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMap, setErrorMap] = useState({
    username: null,
    password: null,
  });

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  useEffect(() => {
    if (!state) return;
    const { action, username, password } = state;
    if (action == "LOGIN") {
      handleLogin(username, password);
    }
  }, [state]);

  const handleOnChange = (type, value) => {
    if (type === "username") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          username: i18n.t("username_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          username: null,
        }));
      }
      setUsername(value);
    } else if (type === "password") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          password: i18n.t("password_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          password: null,
        }));
      }
      setPassword(value);
    }
  };

  const handleLogin = async (username, password) => {
    try {
      const { data, err } = await retrieveTokenInformation({
        username,
        password,
      });
      if (err) {
        NotificationManager.error(i18n.t("invalid_user_credentials_text"));
        return;
      }
      const { accessToken, refreshToken } = data;
      handleStateLogin(accessToken, refreshToken, null, null);
      const { data: userData, err: userErr } =
        await retrieveUserInformationFromToken();
      if (userErr) {
        return;
      }
      const { enabled } = userData;
      if (enabled) {
        const { data: userProfileSetData } = await retrieveUserProfileList();
        handleStateLogin(null, null, userData, userProfileSetData);
        setBoarding("PROFILE_SELECTION_REQUIRED");
        NotificationManager.success(i18n.t("login_successful_text"));
      } else {
        setBoarding("ACCOUNT_ACTIVATION_REQUIRED");
      }
      navigate("/");
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const checkCommonValidation = () => {
    if (!username || !password) {
      const currentErrorMap = {
        username: null,
        password: null,
      };
      if (!username) {
        currentErrorMap.username = i18n.t("username_required_field");
      }
      if (!password) {
        currentErrorMap.password = i18n.t("password_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const handleLoginOnClick = async () => {
    if (checkCommonValidation()) {
      await handleLogin(username, password);
      localStorage.setItem("appState", "LOGIN");
    }
  };

  const handleOnKeyDownOnClick = async (e) => {
    if (e.key === "Enter") {
      handleLoginOnClick();
    }
  };

  return (
    <BaseCard>
      <BaseImage
        size="custom"
        className="h-20 w-72 mx-auto"
        src="/logos/logo-primary.png"
        alt="logo-primary"
      />
      <div className="flex flex-col mt-8 h-full">
        <InputGroup
          type="text"
          title={i18n.t("username")}
          placeholder={i18n.t("username")}
          value={username}
          onChange={(e) => {
            handleOnChange("username", e.target.value.trim());
          }}
          errorText={errorMap.username}
          onKeyDown={handleOnKeyDownOnClick}
        />
        <InputGroup
          type="password"
          title={i18n.t("password")}
          placeholder={i18n.t("password")}
          value={password}
          onChange={(e) => {
            handleOnChange("password", e.target.value.trim());
          }}
          errorText={errorMap.password}
          onKeyDown={handleOnKeyDownOnClick}
        />
        <div className="flex flex-row text-catchup-blue-600">
          <p
            className="cursor-pointer text-base underline font-semibold"
            onClick={() => {
              navigate("/forgot-password");
            }}
          >
            {i18n.t("forgot_password_text")}
          </p>
        </div>
        <div className="my-8 flex flex-row justify-center">
          <PrimaryButton
            title={i18n.t("login")}
            size="medium"
            onClick={handleLoginOnClick}
          />
        </div>
        <div className="my-1 ">
          <div className="flex flex-row justify-center my-1">
            <div className="flex-1 flex flex-col items-center">
              <p className="mx-2 text-base">{i18n.t("to_app_text")}</p>
              <p
                className="cursor-pointer mx-2 text-base underline text-catchup-blue-500 font-semibold"
                onClick={() => {
                  window.open(connection.app.url, "_blank");
                }}
              >
                {i18n.t("to_app_cta")}
              </p>
            </div>
            {/* <div className="flex-1 flex flex-col items-center ">
              <p className="mx-2 text-base ">{i18n.t("to_register_text")}</p>
              <p
                className="cursor-pointer mx-2 text-base underline text-catchup-blue-500 font-semibold"
                onClick={() => {
                  navigate("/register");
                }}
              >
                {i18n.t("to_register_cta")}
              </p>
            </div> */}
          </div>
        </div>
        <div className="mt-auto">
          <RightsReserved />
        </div>
      </div>
    </BaseCard>
  );
};

export default LoginScreen;
