import i18n from "../../language/i18n";
import DividerLine from "../divider/DividerLine";
import BaseTitle from "../titles/BaseTitle";
import LearnerPerformanceGraphicalData from "../graphical-data/LearnerPerformanceGraphicalData";
import { useState } from "react";
import InputGroup from "../groups/InputGroup";
import BaseImage from "../images/BaseImage";
import { Tooltip } from "react-tooltip";
import OutcomeDetail from "../outcomes/OutcomeDetail";
import OutcomeGraph from "../outcomes/OutcomeGraph";
import BaseLoading from "../loading/BaseLoading";

const GeneralPerformanceReportDetail = (props) => {
  const {
    language,
    userProfile,
    isShadowed,
    usePadding,
    showTitle,
    reportDetailList,
    reportDetailLoading,
    subType,
    individualModelLoading,
    individualModelList,
    showIndividualModel,
    setShowIndividualModel,
    handleGeneratePDF,
  } = props;
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");

  const handleOnChange = (key, value, index) => {
    if (key === "selectedCoterieType") {
      setSelectedCoterieType(value);
    }
  };

  const filterCoterieTypeOptionList = (applicationDTOList) => {
    const coterieTypeOptionList = [];
    applicationDTOList.forEach((applicationDTO) => {
      if (
        !coterieTypeOptionList.find(
          (coterieTypeOption) =>
            coterieTypeOption.value === applicationDTO.catchxamDTO.coterieType
        )
      ) {
        if (
          userProfile.coterieType === "MANAGEMENT" ||
          userProfile.coterieType === applicationDTO.catchxamDTO.coterieType
        ) {
          coterieTypeOptionList.push({
            value: applicationDTO.catchxamDTO.coterieType,
            text: i18n.t(applicationDTO.catchxamDTO.coterieType),
          });
        }
      }
    });
    return coterieTypeOptionList;
  };

  const filterCatchxamApplicationDTOList = (learnerPerformance) => {
    if (!learnerPerformance) return [];
    const { catchxamApplicationPerformanceDTOList } = learnerPerformance;
    const filteredCatchxamApplicationPerformanceDTOList =
      catchxamApplicationPerformanceDTOList.filter(
        (item) => item.catchxamApplicationDTO.isEvaluated
      );
    return filteredCatchxamApplicationPerformanceDTOList;
  };

  const filterLearnerCatchxamReportListByCoterieType = (
    learnerCatchxamReportList
  ) => {
    if (selectedCoterieType === "DEFAULT_OPTION") {
      return learnerCatchxamReportList;
    } else {
      return learnerCatchxamReportList.filter(
        (learnerCatchxamReport) =>
          learnerCatchxamReport.catchxamApplicationDTO.catchxamDTO
            .coterieType === selectedCoterieType
      );
    }
  };

  const filterApplicationDTOListByCoterieType = (applicationDTOList) => {
    if (selectedCoterieType === "DEFAULT_OPTION") {
      return applicationDTOList;
    } else {
      return applicationDTOList.filter(
        (catchxamApplicationDTO) =>
          catchxamApplicationDTO.catchxamDTO.coterieType === selectedCoterieType
      );
    }
  };

  const filterLearnerCatchxamApplicationPerformanceDTOListByCoterieType = (
    filteredCatchxamApplicationDTOList
  ) => {
    if (selectedCoterieType === "DEFAULT_OPTION") {
      return filteredCatchxamApplicationDTOList;
    } else {
      return filteredCatchxamApplicationDTOList.filter(
        (catchxamApplicationPerformanceDTO) =>
          catchxamApplicationPerformanceDTO.coterieType === selectedCoterieType
      );
    }
  };

  const recursivelyIncludeCategoryActivityAnswerDTO = (
    parentCategoryActivityAnswerDTO,
    categoryActivityAnswerDTO
  ) => {
    const { subCategoryActivityAnswerDTOList } =
      parentCategoryActivityAnswerDTO;
    if (subCategoryActivityAnswerDTOList.length === 0) return;
    const foundCategoryActivityAnswerDTOById =
      subCategoryActivityAnswerDTOList.find(
        (answerDTO) =>
          answerDTO.categoryDTO.id === categoryActivityAnswerDTO.categoryDTO.id
      );
    if (foundCategoryActivityAnswerDTOById) {
      foundCategoryActivityAnswerDTOById.activityScoreDTOSet.push(
        ...categoryActivityAnswerDTO.activityScoreDTOSet
      );
    } else {
      if (
        parentCategoryActivityAnswerDTO.categoryDTO?.code ===
        categoryActivityAnswerDTO.categoryDTO.parentCode
      ) {
        subCategoryActivityAnswerDTOList.push(categoryActivityAnswerDTO);
        // return false;
      } else {
        // let state = false;
        for (const answerDTO of subCategoryActivityAnswerDTOList) {
          recursivelyIncludeCategoryActivityAnswerDTO(
            answerDTO,
            categoryActivityAnswerDTO
          );
          // if (!state) return;
        }
      }
    }
    // return true;
  };

  const recursivelyIncludeCategoryActivityAnswerDTOList = (
    parentCategoryActivityAnswerDTO,
    categoryActivityAnswerDTOList
  ) => {
    for (const newAnswerDTO of categoryActivityAnswerDTOList) {
      recursivelyIncludeCategoryActivityAnswerDTO(
        {
          subCategoryActivityAnswerDTOList:
            parentCategoryActivityAnswerDTO.currentCategoryActivityAnswerDTOList,
        },
        newAnswerDTO
      );
      const { subCategoryActivityAnswerDTOList } = newAnswerDTO;
      // if (subCategoryActivityAnswerDTOList.length === 0) return;
      recursivelyIncludeCategoryActivityAnswerDTOList(
        parentCategoryActivityAnswerDTO,
        subCategoryActivityAnswerDTOList
      );
    }
  };

  const constructCombinedCatchxamReportMap = (learnerCatchxamReportList) => {
    const combinedCatchxamReportMap = {};
    const filteredLearnerCatchxamReportListByCoterieType =
      filterLearnerCatchxamReportListByCoterieType(learnerCatchxamReportList);
    const copyFilteredLearnerCatchxamReportListByCoterieType = JSON.parse(
      JSON.stringify(filteredLearnerCatchxamReportListByCoterieType)
    );
    for (const learnerCatchxamReport of copyFilteredLearnerCatchxamReportListByCoterieType) {
      const { catchxamApplicationDTO, categoryActivityAnswerDTOList } =
        learnerCatchxamReport;
      const { catchxamDTO } = catchxamApplicationDTO;
      const { coterieType } = catchxamDTO;
      const currentCategoryActivityAnswerDTOList =
        combinedCatchxamReportMap[coterieType];
      if (currentCategoryActivityAnswerDTOList) {
        recursivelyIncludeCategoryActivityAnswerDTOList(
          { currentCategoryActivityAnswerDTOList },
          categoryActivityAnswerDTOList
        );
      } else {
        combinedCatchxamReportMap[coterieType] = categoryActivityAnswerDTOList;
      }
    }
    return combinedCatchxamReportMap;
  };

  const RenderSingleTableHeaderTextItem = (text, isBold) => {
    return (
      <div className={`my-2 ${isBold ? "font-semibold" : ""}`}>
        <p>{text}</p>
      </div>
    );
  };

  const RenderSingleTableTextItem = (text, isBold) => {
    return (
      <div className={`my-2 ${isBold ? "font-semibold" : ""}`}>
        <p>{text}</p>
      </div>
    );
  };

  const RenderCatchxamOutcomeList = (learnerCatchxamReportList) => {
    const combinedCatchxamReportMap = constructCombinedCatchxamReportMap(
      learnerCatchxamReportList
    );
    return (
      <>
        <div className="my-2">
          <p className="font-semibold text-lg">{i18n.t("catchxam_outcomes")}</p>
        </div>
        {Object.keys(combinedCatchxamReportMap).map((key) => (
          <div key={key} className="flex flex-1 flex-col">
            <div className="my-2">
              <p className="font-semibold">{i18n.t(key)}</p>
            </div>
            <div className="">
              <OutcomeGraph
                language={language}
                catchxamReportList={combinedCatchxamReportMap[key]}
              />
            </div>
            <div className="w-full flex flex-col my-5 text-sm">
              {combinedCatchxamReportMap[key].map(
                (categoryActivityAnswerDTO) => (
                  <div key={key}>
                    <OutcomeDetail
                      categoryActivityAnswerDTO={categoryActivityAnswerDTO}
                    />
                  </div>
                )
              )}
            </div>
            <DividerLine />
          </div>
        ))}
      </>
    );
  };

  const RenderCatchxamApplicationDTOItem_Learner = (
    catchxamApplicationDTO,
    index,
    foundApplicationPerformance
  ) => {
    const { catchxamDTO, beginAt, endAt, completionStatus } =
      catchxamApplicationDTO;
    const { name, description, coterieType } = catchxamDTO;
    return (
      <tr key={index} className="text-center">
        <td>{RenderSingleTableTextItem(index + 1, true)}</td>
        <td>
          <Tooltip id="info-description" />
          <div className="flex flex-row items-center gap-x-2">
            <BaseImage
              size="small"
              src="/icons/info.png"
              alt="info"
              dataTooltipId="info-description"
              dataTooltipContent={description}
              dataTooltipPlace="top"
              dataTooltipVariant="info"
            />
            {RenderSingleTableTextItem(name)}
          </div>
        </td>

        <td>{RenderSingleTableTextItem(i18n.t(coterieType))}</td>
        <td>
          {RenderSingleTableTextItem(
            new Date(beginAt).toLocaleString(language)
          )}
        </td>
        <td>
          {RenderSingleTableTextItem(new Date(endAt).toLocaleString(language))}
        </td>
        {/* <td>
          {RenderSingleTableTextItem(
            foundApplicationPerformance.learnerScore
              ? i18n.t(completionStatus)
              : i18n.t("COMPLETED")
          )}
        </td> */}
        <td>
          {RenderSingleTableTextItem(
            `${(foundApplicationPerformance.learnerScore
              ? foundApplicationPerformance.learnerScore * 100
              : foundApplicationPerformance.branchScore * 100
            ).toFixed(2)} %`,
            true
          )}
        </td>
      </tr>
    );
  };

  const RenderCatchxamApplicationDTOItem_Staff = (
    staffPerformance,
    index,
    gradeBranchListMapList
  ) => {
    const { branchId, gradeId, catchxamApplicationDTO } = staffPerformance;
    const { catchxamDTO, beginAt, endAt } = catchxamApplicationDTO;
    const { name, description, coterieType } = catchxamDTO;
    const foundGradeMapList = gradeBranchListMapList.filter(
      (grade) => grade.gradeId === gradeId
    );
    let branchName = "";
    foundGradeMapList.forEach((gradeMap) => {
      const foundBranchMap = gradeMap.branchMapList.find(
        (branch) => branch.branchId === branchId
      );
      if (foundBranchMap) {
        branchName = foundBranchMap.branchName;
        return;
      }
    });

    return (
      <tr key={index} className="text-center">
        <td>{RenderSingleTableTextItem(index + 1, true)}</td>
        <td>
          <Tooltip id="info-description" />
          <div className="flex flex-row items-center gap-x-2">
            <BaseImage
              size="small"
              src="/icons/info.png"
              alt="info"
              dataTooltipId="info-description"
              dataTooltipContent={description}
              dataTooltipPlace="top"
              dataTooltipVariant="info"
            />
            {RenderSingleTableTextItem(name)}
          </div>
        </td>

        <td>{RenderSingleTableTextItem(i18n.t(coterieType))}</td>
        <td>
          {RenderSingleTableTextItem(
            new Date(beginAt).toLocaleString(language)
          )}
        </td>
        <td>
          {RenderSingleTableTextItem(new Date(endAt).toLocaleString(language))}
        </td>
        <td>{RenderSingleTableTextItem(branchName)}</td>
        <td>
          {RenderSingleTableTextItem(
            `${(staffPerformance.branchScore * 100).toFixed(2)} %`,
            true
          )}
        </td>
      </tr>
    );
  };

  const RenderApplicationDTOList_Learner = (
    applicationDTOListByCoterieType,
    catchxamApplicationPerformanceDTOList
  ) => {
    return (
      <>
        <div className="my-2">
          <p className="font-semibold text-lg">
            {i18n.t("catchxam_applications")}
          </p>
        </div>
        <table className="bg-catchup-white">
          <thead className="border-b-2">
            <tr>
              <th className="min-w-catchup-table-gap"></th>
              <th>{RenderSingleTableHeaderTextItem(i18n.t("name"))}</th>

              <th>{RenderSingleTableHeaderTextItem(i18n.t("coterie_type"))}</th>
              <th>{RenderSingleTableHeaderTextItem(i18n.t("begin_at"))}</th>
              <th>{RenderSingleTableHeaderTextItem(i18n.t("end_at"))}</th>
              {/* <th>
                {RenderSingleTableHeaderTextItem(i18n.t("completion_status"))}
              </th> */}
              <th>
                {RenderSingleTableHeaderTextItem(i18n.t("success_percentage"))}
              </th>
            </tr>
          </thead>
          <tbody>
            {applicationDTOListByCoterieType.map(
              (catchxamApplicationDTO, index) =>
                RenderCatchxamApplicationDTOItem_Learner(
                  catchxamApplicationDTO,
                  index,
                  catchxamApplicationPerformanceDTOList.find(
                    (catchxamApplicationPerformanceDTO) =>
                      catchxamApplicationPerformanceDTO.catchxamApplicationDTO
                        .id === catchxamApplicationDTO.id
                  )
                )
            )}
          </tbody>
        </table>
      </>
    );
  };

  const RenderApplicationDTOList_Staff = (
    gradeBranchListMapList,
    catchxamApplicationPerformanceDTOList
  ) => {
    return (
      <>
        <div className="my-2">
          <p className="font-semibold text-lg">
            {i18n.t("catchxam_applications")}
          </p>
        </div>
        <table className="bg-catchup-white">
          <thead className="border-b-2">
            <tr>
              <th className="min-w-catchup-table-gap"></th>
              <th>{RenderSingleTableHeaderTextItem(i18n.t("name"))}</th>
              <th>{RenderSingleTableHeaderTextItem(i18n.t("coterie_type"))}</th>
              <th>{RenderSingleTableHeaderTextItem(i18n.t("begin_at"))}</th>
              <th>{RenderSingleTableHeaderTextItem(i18n.t("end_at"))}</th>
              <th>{RenderSingleTableHeaderTextItem(i18n.t("branch_name"))}</th>
              <th>
                {RenderSingleTableHeaderTextItem(i18n.t("success_percentage"))}
              </th>
            </tr>
          </thead>
          <tbody>
            {catchxamApplicationPerformanceDTOList.map(
              (staffCatchxamApplicationPerformanceDTO, index) =>
                RenderCatchxamApplicationDTOItem_Staff(
                  staffCatchxamApplicationPerformanceDTO,
                  index,
                  gradeBranchListMapList
                )
            )}
          </tbody>
        </table>
      </>
    );
  };

  const convertToApplicationDTOList = (
    catchxamApplicationPerformanceDTOList
  ) => {
    return catchxamApplicationPerformanceDTOList.map(
      (catchxamApplicationPerformanceDTO) => {
        const { catchxamApplicationDTO } = catchxamApplicationPerformanceDTO;
        return catchxamApplicationDTO;
      }
    );
  };

  const RenderReportContent = (reportDetail) => {
    const {
      overallCatchxamApplicationPerformanceDTO,
      learnerCatchxamReportDTOList,
      gradeBranchListMapList,
    } = reportDetail;
    const filteredCatchxamApplicationDTOList = filterCatchxamApplicationDTOList(
      overallCatchxamApplicationPerformanceDTO
    );
    const applicationDTOList = convertToApplicationDTOList(
      filteredCatchxamApplicationDTOList
    );
    const applicationDTOListByCoterieType =
      filterApplicationDTOListByCoterieType(applicationDTOList);
    const filterCatchxamApplicationPerformanceDTOList =
      filterLearnerCatchxamApplicationPerformanceDTOListByCoterieType(
        filteredCatchxamApplicationDTOList
      );
    return (
      <div className="h-full flex flex-col p-4">
        {subType === "USER_PROFILE" ? (
          <div className="flex flex-row items-center justify-between">
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("coterie_type")}
                  placeholder={i18n.t("coterie_type")}
                  value={selectedCoterieType}
                  optionList={filterCoterieTypeOptionList(applicationDTOList)}
                  onChange={(event) => {
                    handleOnChange("selectedCoterieType", event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <p
                  className="underline cursor-pointer"
                  onClick={() => {
                    setShowIndividualModel(true);
                  }}
                >
                  {i18n.t("show_individual_model")}
                </p>
              </div>
            </div>
          </div>
        ) : null}

        <div className="flex flex-1 flex-col">
          <LearnerPerformanceGraphicalData
            learnerPerformance={overallCatchxamApplicationPerformanceDTO}
            selectedCoterieType={selectedCoterieType}
            showLearner={subType === "USER_PROFILE" ? true : false}
            individualModelList={individualModelList}
            individualModelLoading={individualModelLoading}
            showIndividualModel={showIndividualModel}
            setShowIndividualModel={setShowIndividualModel}
          />
          {/* <DividerLine /> */}

          {subType === "USER_PROFILE"
            ? RenderApplicationDTOList_Learner(
                applicationDTOListByCoterieType,
                filterCatchxamApplicationPerformanceDTOList
              )
            : subType === "COTERIE_TYPE"
            ? RenderApplicationDTOList_Staff(
                gradeBranchListMapList,
                filterCatchxamApplicationPerformanceDTOList
              )
            : null}

          {subType === "USER_PROFILE" ? (
            <>
              {/* <DividerLine /> */}
              {RenderCatchxamOutcomeList(learnerCatchxamReportDTOList)}
            </>
          ) : null}
        </div>
      </div>
    );
  };

  const RenderMainContent = () => {
    if (reportDetailLoading) {
      return (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      );
    } else {
      if (reportDetailList.length > 0) {
        return (
          <>
            {showTitle ? (
              <>
                <div className="">
                  <BaseTitle title={i18n.t("report_details")} />
                </div>
                <DividerLine />
              </>
            ) : null}
            {reportDetailList.map((reportDetail, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center my-5"
              >
                <div className="w-full flex flex-row flex-wrap items-center">
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                    <div className="w-catchup-input-group-title py-2">
                      <p className="font-semibold">{i18n.t("type")}</p>
                    </div>
                    <div className="flex-1">
                      <p>{i18n.t(subType)}</p>
                    </div>
                  </div>
                  {reportDetail.seasonGradeBranchMap ? (
                    <>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("season_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonGradeBranchMap.seasonName}</p>
                        </div>
                      </div>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("grade_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonGradeBranchMap.gradeName}</p>
                        </div>
                      </div>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("branch_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonGradeBranchMap.branchName}</p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {reportDetail.seasonGradeMap ? (
                    <>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("season_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonGradeMap.seasonName}</p>
                        </div>
                      </div>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("grade_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonGradeMap.gradeName}</p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {reportDetail.seasonMap ? (
                    <>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("season_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonMap.seasonName}</p>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                {reportDetail.userProfileMap ? (
                  <div className="w-full flex flex-row items-center">
                    <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                      <div className="w-catchup-input-group-title py-2">
                        <p className="font-semibold">{i18n.t("first_name")}</p>
                      </div>
                      <div className="flex-1">
                        <p>{reportDetail.userProfileMap.firstName}</p>
                      </div>
                    </div>
                    <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                      <div className="w-catchup-input-group-title py-2">
                        <p className="font-semibold">{i18n.t("last_name")}</p>
                      </div>
                      <div className="flex-1">
                        <p>{reportDetail.userProfileMap.lastName}</p>
                      </div>
                    </div>
                    <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                      <div className="w-catchup-input-group-title py-2">
                        <p className="font-semibold">
                          {i18n.t("user_profile")}
                        </p>
                      </div>
                      <div className="flex-1">
                        <p>{reportDetail.userProfileMap.userProfileName}</p>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="w-full" key={index}>
                  {RenderReportContent(reportDetail)}
                </div>
              </div>
            ))}
          </>
        );
      }
    }
  };

  return <div className="flex-1 flex flex-col">{RenderMainContent()}</div>;
};

export default GeneralPerformanceReportDetail;
