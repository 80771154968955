import i18n from "../language/i18n";

export const retrieveMainLeftNavigationOptionList = () => {
  return [
    {
      id: "dashboard",
      name: i18n.t("dashboard"),
      visible: true,
      icon: "/icons/dashboard.png",
      selectedIcon: "/icons/dashboard-selected.png",
      roleList: ["STAFF", "INDIVIDUAL"],
      authorityScreenName: "dashboard",
      navigate: "/",
      subOptionList: null,
    },
    {
      id: "management",
      name: i18n.t("management"),
      visible: true,
      icon: "/icons/management.png",
      selectedIcon: "/icons/management-selected.png",
      roleList: ["STAFF", "INDIVIDUAL"],
      subOptionList: [
        {
          id: "user-management",
          parentId: "management",
          name: i18n.t("user_management"),
          roleList: ["ADMIN"],
          authorityScreenName: "user_list",
          navigate: "/users",
          subOptionList: [
            {
              id: "user-profile-management",
              parentId: "user-management",
              name: i18n.t("profile"),
              roleList: ["ADMIN"],
              authorityScreenName: "user_profile_list",
              navigate: "/users/{id}/profiles",
              subOptionList: [
                {
                  id: "-user-profile-authority-management",
                  parentId: "-user-profile-management",
                  name: i18n.t("authority"),
                  roleList: ["ADMIN"],
                  authorityScreenName: "user_profile_authority_list",
                  navigate: "/users/{id}/profiles/{id}/authorities",
                },
              ],
            },
            {
              id: "user-management-create",
              parentId: "user-management",
              name: i18n.t("create"),
              roleList: ["ADMIN"],
              authorityScreenName: "user_list",
              navigate: "/users/create",
              subOptionList: [
                {
                  id: "user-management-create-custom",
                  parentId: "user-management-create",
                  name: i18n.t("custom"),
                  roleList: ["STAFF"],
                  authorityScreenName: "user_list",
                  navigate: "/users/create/custom",
                },
                {
                  id: "user-management-create-import-from-excel",
                  parentId: "user-management-create",
                  name: i18n.t("import_from_excel"),
                  roleList: ["STAFF"],
                  authorityScreenName: "user_list",
                  navigate: "/users/create/import-from-excel",
                },
              ],
            },
          ],
        },
        {
          id: "staff-user-management",
          parentId: "management",
          name: i18n.t("staff_user_management"),
          roleList: ["STAFF"],
          authorityScreenName: "user_list",
          navigate: "/staff-users",
          subOptionList: [
            {
              id: "staff-user-profile-management",
              parentId: "staff-user-management",
              name: i18n.t("profile"),
              roleList: ["STAFF"],
              authorityScreenName: "user_profile_list",
              navigate: "/staff-users/{id}/profiles",
              subOptionList: [
                {
                  id: "staff-user-profile-authority-management",
                  parentId: "staff-user-profile-management",
                  name: i18n.t("authority"),
                  roleList: ["STAFF"],
                  authorityScreenName: "user_profile_authority_list",
                  navigate: "/staff-users/{id}/profiles/{id}/authorities",
                },
              ],
            },
          ],
        },
        {
          id: "learner-user-management",
          parentId: "management",
          name: i18n.t("learner_user_management"),
          roleList: ["STAFF", "INDIVIDUAL"],
          authorityScreenName: "user_list",
          navigate: "/learner-users",
          subOptionList: [
            {
              id: "learner-user-profile-management",
              parentId: "learner-user-management",
              name: i18n.t("profile"),
              roleList: ["STAFF", "INDIVIDUAL"],
              authorityScreenName: "user_profile_list",
              navigate: "/learner-users/{id}/profiles",
              subOptionList: [
                {
                  id: "learner-user-profile-authority-management",
                  parentId: "learner-user-profile-management",
                  name: i18n.t("authority"),
                  roleList: ["STAFF", "INDIVIDUAL"],
                  authorityScreenName: "user_profile_authority_list",
                  navigate: "/learner-users/{id}/profiles/{id}/authorities",
                },
              ],
            },
          ],
        },
        {
          id: "individual-user-management",
          parentId: "management",
          name: i18n.t("individual_user_management"),
          roleList: ["ADMIN"],
          authorityScreenName: "user_list",
          navigate: "/individual-users",
          subOptionList: [
            {
              id: "individual-user-profile-management",
              parentId: "individual-user-management",
              name: i18n.t("profile"),
              roleList: ["ADMIN"],
              authorityScreenName: "user_profile_list",
              navigate: "/individual-users/{id}/profiles",
              subOptionList: [
                {
                  id: "individual-user-profile-authority-management",
                  parentId: "individual-user-profile-management",
                  name: i18n.t("authority"),
                  roleList: ["ADMIN"],
                  authorityScreenName: "user_profile_authority_list",
                  navigate: "/individual-users/{id}/profiles/{id}/authorities",
                },
              ],
            },
          ],
        },
        {
          id: "content-creator-user-management",
          parentId: "management",
          name: i18n.t("content_creator_user_management"),
          roleList: ["ADMIN"],
          authorityScreenName: "user_list",
          navigate: "/content-creator-users",
          subOptionList: [
            {
              id: "content-creator-user-profile-management",
              parentId: "content-creator-user-management",
              name: i18n.t("profile"),
              roleList: ["ADMIN"],
              authorityScreenName: "user_profile_list",
              navigate: "/content-creator-users/{id}/profiles",
              subOptionList: [
                {
                  id: "content-creator-user-profile-authority-management",
                  parentId: "content-creator-user-profile-management",
                  name: i18n.t("authority"),
                  roleList: ["ADMIN"],
                  authorityScreenName: "user_profile_authority_list",
                  navigate:
                    "/content-creator-users/{id}/profiles/{id}/authorities",
                },
              ],
            },
          ],
        },
        {
          id: "brand-management",
          parentId: "management",
          name: i18n.t("brand_management"),
          roleList: ["ADMIN"],
          authorityScreenName: "brand_list",
          navigate: "/brands",
          subOptionList: [
            {
              id: "brand-management-ai-settings",
              parentId: "brand-management",
              name: i18n.t("ai_settings"),
              roleList: ["STAFF"],
              authorityScreenName: "brand_list",
              navigate: "/brands/{id}/settings/ai-settings",
            },
          ],
        },
        {
          id: "region-management",
          parentId: "management",
          name: i18n.t("region_management"),
          roleList: ["ADMIN"],
          authorityScreenName: "region_list",
          navigate: "/regions",
        },
        {
          id: "campus-management",
          parentId: "management",
          name: i18n.t("campus_management"),
          roleList: ["STAFF"],
          authorityScreenName: "campus_list",
          navigate: "/campuses",
          subOptionList: [
            {
              id: "campus-management-ai-settings",
              parentId: "campus-management",
              name: i18n.t("ai_settings"),
              roleList: ["STAFF"],
              authorityScreenName: "campus_list",
              navigate: "/campuses/{id}/settings/ai-settings",
            },
          ],
        },
        {
          id: "institution-management",
          parentId: "management",
          name: i18n.t("institution_management"),
          roleList: ["STAFF"],
          authorityScreenName: "institution_list",
          navigate: "/institutions",
          subOptionList: [
            {
              id: "institution-management-general-settings",
              parentId: "institution-management",
              name: i18n.t("general_settings"),
              roleList: ["STAFF"],
              authorityScreenName: "institution_list",
              navigate: "/institutions/{id}/settings/general-settings",
            },
            {
              id: "institution-management-ai-settings",
              parentId: "institution-management",
              name: i18n.t("ai_settings"),
              roleList: ["STAFF"],
              authorityScreenName: "institution_list",
              navigate: "/institutions/{id}/settings/ai-settings",
            },
            {
              id: "institution-management-ai-settings",
              parentId: "institution-management",
              name: i18n.t("integration_settings"),
              roleList: ["STAFF"],
              authorityScreenName: "institution_list",
              navigate: "/institutions/{id}/settings/integration-settings",
            },
          ],
        },
        {
          id: "season-management",
          parentId: "management",
          name: i18n.t("season_management"),
          roleList: ["STAFF"],
          authorityScreenName: "season_list",
          navigate: "/seasons",
          subOptionList: [
            {
              id: "season-management-detail",
              parentId: "season-management",
              name: i18n.t("detail"),
              roleList: ["STAFF"],
              authorityScreenName: "season_list",
              navigate: "/seasons/{id}/detail",
            },
          ],
        },
        {
          id: "grade-management",
          parentId: "management",
          name: i18n.t("grade_management"),
          roleList: ["STAFF", "INDIVIDUAL"],
          authorityScreenName: "grade_list",
          navigate: "/grades",
          subOptionList: [
            {
              id: "grade-management-detail",
              parentId: "grade-management",
              name: i18n.t("detail"),
              roleList: ["STAFF"],
              authorityScreenName: "grade_list",
              navigate: "/grades/{id}/detail",
            },
          ],
        },
        {
          id: "branch-management",
          parentId: "management",
          name: i18n.t("branch_management"),
          roleList: ["STAFF", "INDIVIDUAL"],
          authorityScreenName: "branch_list",
          navigate: "/branches",
          subOptionList: [
            {
              id: "branch-management-detail",
              parentId: "branch-management",
              name: i18n.t("detail"),
              roleList: ["STAFF"],
              authorityScreenName: "branch_list",
              navigate: "/branches/{id}/detail",
            },
            {
              id: "branch-management-transfer-learner-from-k12",
              parentId: "branch-management",
              name: i18n.t("learner_transfer_from_k12"),
              roleList: ["ADMIN"],
              authorityScreenName: "branch_list",
              navigate: "/branches/{id}/transfer-learner/from-k12",
            },
          ],
        },
      ],
    },
    {
      id: "plan",
      name: i18n.t("plans"),
      visible: true,
      icon: "/icons/plan.png",
      selectedIcon: "/icons/plan-selected.png",
      roleList: ["STAFF", "INDIVIDUAL"],
      authorityScreenName: "plan_list",
      subOptionList: [
        {
          id: "plan",
          parentId: "plan",
          name: i18n.t("plans"),
          roleList: ["STAFF", "INDIVIDUAL"],
          authorityScreenName: "plan_list",
          navigate: "/plans",
        },
        {
          id: "plan_documents",
          parentId: "plan",
          name: i18n.t("plan_documents"),
          roleList: ["STAFF", "INDIVIDUAL"],
          authorityScreenName: "storage_file_list",
          navigate: "/plan-documents",
          subOptionList: null,
        },
        {
          id: "plan-transfer",
          parentId: "plan",
          name: i18n.t("plan_transfer"),
          roleList: ["ADMIN"],
          authorityScreenName: "plan_list",
          navigate: "/plan-transfer",
        },
      ],
    },
    {
      id: "activity",
      name: i18n.t("activity"),
      visible: true,
      icon: "/icons/activity.png",
      selectedIcon: "/icons/activity-selected.png",
      roleList: ["STAFF", "INDIVIDUAL", "CONTENT_CREATOR"],
      subOptionList: [
        {
          id: "activities",
          parentId: "activity",
          name: i18n.t("activities"),
          roleList: ["STAFF", "INDIVIDUAL", "CONTENT_CREATOR"],
          authorityScreenName: "activity_list",
          navigate: "/activities",
          subOptionList: [
            {
              id: "activity-create",
              parentId: "activities",
              name: i18n.t("create"),
              roleList: ["STAFF", "INDIVIDUAL", "CONTENT_CREATOR"],
              authorityScreenName: "activity_list",
              navigate: "/activities/create",
              subOptionList: [
                {
                  id: "activity-create-import-from-pdf",
                  parentId: "activity-create",
                  name: i18n.t("import_from_pdf"),
                  roleList: ["STAFF", "INDIVIDUAL", "CONTENT_CREATOR"],
                  authorityScreenName: "activity_list",
                  navigate: "/activities/create/import-from-pdf",
                },
              ],
            },
            {
              id: "activity-accessibility",
              parentId: "activities",
              name: i18n.t("accessibility"),
              roleList: ["STAFF"],
              authorityScreenName: "activity_accessibility",
              navigate: "/activities/{id}/accessibilities",
            },
            {
              id: "activity-generate-with-ai",
              parentId: "activities",
              name: i18n.t("generate_with_ai"),
              roleList: ["ADMIN"],
              authorityScreenName: "activity_list",
              navigate: "/activities/generate-with-ai",
              subOptionList: [
                {
                  id: "activity-generate-with-ai-try-a-prompt",
                  parentId: "activity-generate-with-ai",
                  name: i18n.t("try_a_prompt"),
                  roleList: ["ADMIN"],
                  authorityScreenName: "activity_list",
                  navigate: "/activities/generate-with-ai/{id}/try-a-prompt",
                },
                {
                  id: "activity-generate-with-ai-from-activity",
                  parentId: "activity-generate-with-ai",
                  name: i18n.t("from_activity"),
                  roleList: ["ADMIN"],
                  authorityScreenName: "activity_list",
                  navigate: "/activities/generate-with-ai/{id}/from-activity",
                },
              ],
            },
          ],
        },
        {
          id: "activity-bundles",
          parentId: "activity",
          name: i18n.t("publishing_house_activities"),
          roleList: ["STAFF"],
          authorityScreenName: "bundle_activity_list",
          navigate: "/publishing-house-activities",
        },
        {
          id: "activity-documents",
          parentId: "activity",
          name: i18n.t("activity_documents"),
          roleList: ["STAFF"],
          authorityScreenName: "activity_document_list",
          navigate: "/activity-documents",
          subOptionList: [
            {
              id: "activity-documents-settings",
              parentId: "activity-documents",
              name: i18n.t("settings"),
              roleList: ["STAFF"],
              authorityScreenName: "activity_document_list",
              navigate: "/activity-documents/settings/{id}",
            },
          ],
        },
        {
          id: "activity-templates",
          parentId: "activity",
          name: i18n.t("activity_templates"),
          roleList: ["ADMIN"],
          authorityScreenName: "activity_template_list",
          navigate: "/activity-templates",
          subOptionList: [
            {
              id: "activity-template-rules",
              parentId: "activity-templates",
              name: i18n.t("rules"),
              roleList: ["STAFF"],
              authorityScreenName: "activity_template_rule_list",
              navigate: "/activity-templates/{id}/rules",
            },
          ],
        },
        {
          id: "activity-transfer",
          parentId: "activity",
          name: i18n.t("activity_transfer"),
          roleList: ["ADMIN"],
          authorityScreenName: "activity_transfer",
          navigate: "/activity-transfer",
        },
        {
          id: "activity-template-feedback",
          parentId: "activity",
          name: i18n.t("activity_template_feedback"),
          roleList: ["ADMIN", "CONTENT_CREATOR"],
          authorityScreenName: "activity_template_feedback_list",
          navigate: "/activity-template-feedbacks",
        },
        {
          id: "activity-jsonl-converter",
          parentId: "activity",
          name: i18n.t("activity_jsonl_converter"),
          roleList: ["ADMIN"],
          authorityScreenName: "activity_jsonl_converter",
          navigate: "/activity-jsonl-converter",
        },
        {
          id: "activity-invalid-data",
          parentId: "activity",
          name: i18n.t("activity_invalid_data"),
          roleList: ["ADMIN"],
          authorityScreenName: "activity_invalid_data",
          navigate: "/activity-invalid-data",
        },
      ],
    },
    {
      id: "catchtivity",
      name: i18n.t("catchtivity"),
      visible: true,
      icon: "/icons/catchtivity.png",
      selectedIcon: "/icons/catchtivity-selected.png",
      roleList: ["STAFF", "INDIVIDUAL"],
      subOptionList: [
        {
          id: "catchtivity-catchtivities",
          parentId: "catchtivity",
          name: i18n.t("catchtivities"),
          roleList: ["STAFF", "INDIVIDUAL"],
          authorityScreenName: "catchtivity_list",
          navigate: "/catchtivities",
          subOptionList: [
            {
              id: "catchtivity-accessibility",
              parentId: "catchtivities",
              name: i18n.t("accessibility"),
              roleList: ["STAFF"],
              authorityScreenName: "catchtivity_accessibility",
              navigate: "/catchtivities/{id}/accessibilities",
            },
          ],
        },
        {
          id: "catchtivity-catchtivity-application",
          parentId: "catchtivity",
          name: i18n.t("catchtivity_applications"),
          roleList: ["STAFF", "INDIVIDUAL"],
          authorityScreenName: "catchtivity_application_list",
          navigate: "/catchtivities/applications",
          subOptionList: [
            {
              id: "catchtivity-catchtivity-application-detail",
              parentId: "catchtivity-catchtivity-application",
              name: i18n.t("detail"),
              roleList: ["STAFF"],
              authorityScreenName: "catchtivity_application_list",
              navigate: "/catchtivities/applications/{id}/detail",
            },
          ],
        },
      ],
    },
    {
      id: "catchxam",
      name: i18n.t("catchxam"),
      visible: true,
      icon: "/icons/catchxam.png",
      selectedIcon: "/icons/catchxam-selected.png",
      roleList: ["STAFF", "INDIVIDUAL"],
      subOptionList: [
        {
          id: "catchxam-catchxams",
          parentId: "catchxam",
          name: i18n.t("catchxams"),
          roleList: ["STAFF", "INDIVIDUAL"],
          authorityScreenName: "catchxam_list",
          navigate: "/catchxams",
          subOptionList: [
            {
              id: "catchxam-accessibility",
              parentId: "catchxams",
              name: i18n.t("accessibility"),
              roleList: ["STAFF"],
              authorityScreenName: "catchxam_accessibility",
              navigate: "/catchxams/{id}/accessibilities",
            },
          ],
        },
        {
          id: "catchxam-catchxam-applications",
          parentId: "catchxam",
          name: i18n.t("catchxam_applications"),
          roleList: ["STAFF", "INDIVIDUAL"],
          authorityScreenName: "catchxam_application_list",
          navigate: "/catchxams/applications",
          subOptionList: [
            {
              id: "catchxam-catchxam-application-detail",
              parentId: "catchxam-catchxam-application",
              name: i18n.t("detail"),
              roleList: ["STAFF"],
              authorityScreenName: "catchxam_application_list",
              navigate: "/catchxams/applications/{id}/detail",
            },
          ],
        },
      ],
    },
    {
      id: "etude",
      name: i18n.t("etude"),
      visible: true,
      icon: "/icons/etude.png",
      selectedIcon: "/icons/etude-selected.png",
      roleList: ["STAFF", "INDIVIDUAL"],
      subOptionList: [
        {
          id: "etude-assign",
          parentId: "etude",
          name: i18n.t("assign_etude"),
          roleList: ["STAFF", "INDIVIDUAL"],
          authorityScreenName: "assign_etude",
          navigate: "/etudes/assign",
        },
        {
          id: "etude-check",
          parentId: "etude",
          name: i18n.t("check_etude"),
          roleList: ["STAFF", "INDIVIDUAL"],
          authorityScreenName: "check_etude",
          navigate: "/etudes/check",
        },
      ],
    },
    {
      id: "standard-exam",
      name: i18n.t("standard_exam"),
      visible: true,
      icon: "/icons/standard-exam.png",
      selectedIcon: "/icons/standard-exam-selected.png",
      roleList: ["ADMIN"],
      subOptionList: [
        {
          id: "standard-exam-standard-exams",
          parentId: "standard-exam",
          name: i18n.t("standard_exams"),
          roleList: ["STAFF", "INDIVIDUAL"],
          authorityScreenName: "standard_exam_list",
          navigate: "/standard-exams",
          subOptionList: [
            {
              id: "standard-exam-accessibility",
              parentId: "standard-exam-standard-exams",
              name: i18n.t("accessibility"),
              roleList: ["STAFF"],
              authorityScreenName: "standard_exam_accessibility",
              navigate: "/standard-exams/{id}/accessibilities",
            },
          ],
        },
        {
          id: "standard-exam-standard-exam-applications",
          parentId: "standard-exam-standard-exams",
          name: i18n.t("standard_exam_applications"),
          roleList: ["STAFF", "INDIVIDUAL"],
          authorityScreenName: "standard_exam_application_list",
          navigate: "/standard-exams/applications",
          subOptionList: [
            {
              id: "standard-exam-standard-exam-application-detail",
              parentId: "standard-exam-standard-exam-application",
              name: i18n.t("detail"),
              roleList: ["STAFF"],
              authorityScreenName: "standard_exam_application_list",
              navigate: "/standard-exams/applications/{id}/detail",
            },
          ],
        },
      ],
    },
    {
      id: "external-exam",
      name: i18n.t("external_exams"),
      visible: true,
      icon: "/icons/external-exam.png",
      selectedIcon: "/icons/external-exam-selected.png",
      roleList: ["ADMIN"],
      subOptionList: [
        {
          id: "external-exam-external-registrations",
          parentId: "external-exam",
          name: i18n.t("external_registrations"),
          roleList: ["STAFF"],
          authorityScreenName: "external_registration_list",
          navigate: "/external-registrations",
          subOptionList: [
            {
              id: "external-exam-external-registrations-external-learners",
              parentId: "external_exam-external-registrations",
              name: i18n.t("external_learners"),
              roleList: ["STAFF"],
              authorityScreenName: "external_registration_list",
              navigate: "/external-registrations/{id}/external-learners",
              subOptionList: null,
            },
          ],
        },
        {
          id: "external-exam-external-exam",
          parentId: "external-exam",
          name: i18n.t("external_exams"),
          roleList: ["STAFF"],
          authorityScreenName: "external_exam_list",
          navigate: "/external-exams",
          subOptionList: [
            {
              id: "external-exam-external-exams-external-exam-parts",
              parentId: "external-exam-external-exam",
              name: i18n.t("external_exam_parts"),
              roleList: ["STAFF"],
              authorityScreenName: "external_exams",
              navigate: "/external-exams/{id}/external-exam-parts",
              subOptionList: null,
            },
            {
              id: "external-exam-external-exams-external-exam-answers",
              parentId: "external-exam-external-exam",
              name: i18n.t("external_exam_answers"),
              roleList: ["STAFF"],
              authorityScreenName: "external_exams",
              navigate: "/external-exams/{id}/external-exam-answers",
              subOptionList: null,
            },
            {
              id: "external-exam-external-exams-external-exam-results",
              parentId: "external-exam-external-exam",
              name: i18n.t("external_exam_results"),
              roleList: ["STAFF"],
              authorityScreenName: "external_exams",
              navigate: "/external-exams/{id}/external-exam-results",
              subOptionList: null,
            },
            {
              id: "external-exam-external-exams-activities-view-pdf",
              parentId: "external-exam-external-exam",
              name: i18n.t("activities_view_pdf"),
              roleList: ["STAFF"],
              authorityScreenName: "external_exams",
              navigate: "/external-exams/{id}/activities/view/pdf",
              subOptionList: null,
            },
            {
              id: "external-exam-external-exams-learners-view-pdf",
              parentId: "external-exam-external-exam",
              name: i18n.t("learners_view_pdf"),
              roleList: ["STAFF"],
              authorityScreenName: "external_exams",
              navigate: "/external-exams/{id}/learners",
              subOptionList: null,
            },
            {
              id: "external-exam-external-exams-results-view-pdf",
              parentId: "external-exam-external-exam",
              name: i18n.t("results_view_pdf"),
              roleList: ["STAFF"],
              authorityScreenName: "external_exams",
              navigate: "/external-exams/{id}/results",
              subOptionList: null,
            },
          ],
        },
      ],
    },
    {
      id: "bundle",
      name: i18n.t("bundle"),
      visible: true,
      icon: "/icons/activity-bundle.png",
      selectedIcon: "/icons/activity-bundle-selected.png",
      roleList: ["ADMIN"],
      subOptionList: [
        {
          id: "bundle-activities",
          parentId: "bundle",
          name: i18n.t("bundle_activities"),
          roleList: ["ADMIN"],
          authorityScreenName: "bundle_activity_list",
          navigate: "/bundles/activities",
        },
        {
          id: "bundle-activities-assign",
          parentId: "bundle",
          name: i18n.t("bundle_activity_assign"),
          roleList: ["ADMIN"],
          authorityScreenName: "bundle_activity_assign",
          navigate: "/bundles/activities/assign",
        },
      ],
    },
    {
      id: "token",
      name: i18n.t("token"),
      visible: true,
      icon: "/icons/token.png",
      selectedIcon: "/icons/token-selected.png",
      roleList: ["ADMIN"],
      subOptionList: [
        {
          id: "purchase-token",
          parentId: "token",
          name: i18n.t("purchase_token"),
          roleList: ["ADMIN"],
          authorityScreenName: "token_purchase_list",
          navigate: "/tokens/purchases",
        },
        {
          id: "token-assignment",
          parentId: "token",
          name: i18n.t("token_assign"),
          roleList: ["ADMIN", "STAFF"],
          authorityScreenName: "token_assignment_list",
          navigate: "/tokens/assign",
        },
      ],
    },
    {
      id: "label",
      name: i18n.t("labels"),
      visible: true,
      icon: "/icons/label.png",
      selectedIcon: "/icons/label-selected.png",
      roleList: ["STAFF", "INDIVIDUAL"],
      subOptionList: [
        {
          id: "personal-labels",
          parentId: "label",
          name: i18n.t("personal_labels"),
          roleList: ["STAFF", "INDIVIDUAL"],
          authorityScreenName: "label_list",
          navigate: "/labels",
        },
        {
          id: "brand-labels",
          parentId: "label",
          name: i18n.t("brand_labels"),
          authorityScreenName: "brand_label_list",
          roleList: ["STAFF"],
          navigate: "/brand-labels",
        },
        {
          id: "publishing-house-labels",
          parentId: "label",
          name: i18n.t("publishing_house_labels"),
          authorityScreenName: "publishing_house_label_list",
          roleList: ["ADMIN"],
          navigate: "/publishing-house-labels",
        },
      ],
    },
    {
      id: "announcement",
      name: i18n.t("announcements"),
      visible: true,
      icon: "/icons/announcement.png",
      roleList: ["STAFF", "INDIVIDUAL"],
      authorityScreenName: "announcement_list",
      subOptionList: [
        {
          id: "announcement",
          parentId: "announcement",
          name: i18n.t("announcements"),
          roleList: ["STAFF", "INDIVIDUAL"],
          authorityScreenName: "announcement_list",
          navigate: "/announcements",
        },
        {
          id: "announcement_documents",
          parentId: "announcement",
          name: i18n.t("announcement_documents"),
          roleList: ["STAFF", "INDIVIDUAL"],
          authorityScreenName: "storage_file_list",
          navigate: "/announcement-documents",
        },
      ],
    },
    {
      id: "category",
      name: i18n.t("categories"),
      visible: true,
      icon: "/icons/category.png",
      selectedIcon: "/icons/category-selected.png",
      roleList: ["ADMIN"],
      authorityScreenName: "category_list",
      navigate: "/categories",
      subOptionList: null,
    },
    {
      id: "report",
      name: i18n.t("reports"),
      visible: true,
      icon: "/icons/report.png",
      selectedIcon: "/icons/report-selected.png",
      roleList: ["STAFF", "INDIVIDUAL"],
      authorityScreenName: "report_list",
      navigate: "/reports",
      subOptionList: null,
    },
    {
      id: "diagnostics",
      name: i18n.t("diagnostics"),
      visible: true,
      icon: "/icons/diagnostics.png",
      selectedIcon: "/icons/diagnostics-selected.png",
      roleList: ["ADMIN"],
      subOptionList: [
        {
          id: "system-diagnostics",
          parentId: "diagnostics",
          name: i18n.t("system_diagnostics"),
          roleList: ["ADMIN"],
          navigate: "/diagnostics",
        },
        {
          id: "default-user-role-authority",
          parentId: "diagnostics",
          name: i18n.t("default_user_role_authority"),
          roleList: ["ADMIN"],
          navigate: "/default-user-role-authority",
        },
        {
          id: "user_login_visualizer",
          parentId: "diagnostics",
          name: i18n.t("user_logins"),
          roleList: ["ADMIN"],
          navigate: "/user-login-visualizer",
        },
        {
          id: "token_usage_visualizer",
          parentId: "diagnostics",
          name: i18n.t("token_usages"),
          roleList: ["ADMIN"],
          navigate: "/token-usage-visualizer",
        },
        {
          id: "brand_label_visualizer",
          parentId: "diagnostics",
          name: i18n.t("brand_label_usages"),
          roleList: ["ADMIN"],
          navigate: "/brand-label-visualizer",
        },
        {
          id: "publishing_house_label_visualizer",
          parentId: "diagnostics",
          name: i18n.t("publishing_house_label_usages"),
          roleList: ["ADMIN"],
          navigate: "/publishing-house-label-visualizer",
        },
      ],
    },
    {
      id: "settings",
      name: i18n.t("settings"),
      visible: false,
      navigate: "/settings",
      subOptionList: null,
    },
    {
      id: "announcements",
      name: i18n.t("announcements"),
      visible: false,
      navigate: "/my-announcements",
      subOptionList: null,
    },
    {
      id: "network-error",
      name: i18n.t("network_error"),
      visible: false,
      navigate: "/network-error",
      subOptionList: null,
    },
    {
      id: "permission-error",
      name: i18n.t("permission_error"),
      visible: false,
      navigate: "/permission-error",
      subOptionList: null,
    },
  ];
};

export const retrieveLanguageOptionList = () => {
  return [
    { value: "TR", text: i18n.t("turkish") },
    { value: "EN", text: i18n.t("english") },
  ];
};
