import { parseJwt } from "./AuthorizationUtilization";
import authorizationMap from "../authorization/ScreenAuthority.json";

export const checkViewPermissionGranted = (screenName) => {
  const accessToken = localStorage.getItem("accessToken");
  const tokenJSON = parseJwt(accessToken);
  const authorization = authorizationMap.find(
    (authorization) => authorization.name === screenName
  );
  if (authorization.view.length === 0 || tokenJSON.roles.length === 0) {
    return false;
  }
  for (const viewAuthority of authorization.view) {
    if (!tokenJSON.roles.includes(viewAuthority)) {
      return false;
    }
  }
  return true;
};

export const checkInsertPermissionGranted = (screenName) => {
  const accessToken = localStorage.getItem("accessToken");
  const tokenJSON = parseJwt(accessToken);
  const authorization = authorizationMap.find(
    (authorization) => authorization.name === screenName
  );
  if (authorization.insert.length === 0 || tokenJSON.roles.length === 0) {
    return false;
  }
  for (const insertAuthority of authorization.insert) {
    if (!tokenJSON.roles.includes(insertAuthority)) {
      return false;
    }
  }
  return true;
};

export const checkDeletePermissionGranted = (screenName) => {
  const accessToken = localStorage.getItem("accessToken");
  const tokenJSON = parseJwt(accessToken);
  const authorization = authorizationMap.find(
    (authorization) => authorization.name === screenName
  );
  if (authorization.delete.length === 0 || tokenJSON.roles.length === 0) {
    return false;
  }
  for (const deleteAuthority of authorization.delete) {
    if (!tokenJSON.roles.includes(deleteAuthority)) {
      return false;
    }
  }
  return true;
};

export const checkUpdatePermissionGranted = (screenName) => {
  const accessToken = localStorage.getItem("accessToken");
  const tokenJSON = parseJwt(accessToken);
  const authorization = authorizationMap.find(
    (authorization) => authorization.name === screenName
  );
  if (authorization.update.length === 0 || tokenJSON.roles.length === 0) {
    return false;
  }
  for (const updateAuthority of authorization.update) {
    if (!tokenJSON.roles.includes(updateAuthority)) {
      return false;
    }
  }
  return true;
};
