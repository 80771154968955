import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImportFromPDFCreateActivity from "../../components/activity/ImportFromPDFCreateActivity";
import { checkInsertPermissionGranted } from "../../utilization/ScreenUtilization";
import { useApp } from "../../context/AppContextProvider";

const CreateActivityScreen = (props) => {
  const { mode } = useParams();
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkInsertPermissionGranted("activity_list")) {
      navigate("/permission-error");
    }
  }, []);

  const RenderMainContent = () => {
    if (mode === "import-from-pdf") {
      return <ImportFromPDFCreateActivity />;
    }
    return null;
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default CreateActivityScreen;
