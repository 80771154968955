const RenderInformationCardItem = (title, subTitle, icon, value, level) => {
  let backgroundColor;
  if (level === 1) {
    backgroundColor = "bg-catchup-blue-50";
  } else if (level === 2) {
    backgroundColor = "bg-catchup-blue-100";
  } else if (level === 3) {
    backgroundColor = "bg-catchup-blue-200";
  } else if (level === 4) {
    backgroundColor = "bg-catchup-blue-300";
  }

  const retrieveFontSizeClassName = () => {
    if (value === null || value === undefined) return "";
    if (value.toString().length < 5) {
      return "text-mega";
    } else if (value.toString().length < 10) {
      return "text-uber";
    } else if (value.toString().length < 15) {
      return "text-great";
    } else {
      return "text-3xl";
    }
  };

  const fontSizeClassName = retrieveFontSizeClassName();
  return (
    <div className="mx-4 my-2">
      <div
        className={`${backgroundColor} h-catchup-information-card rounded-catchup-medium`}
      >
        <div className="h-full">
          <div className="h-full p-5">
            <div className="h-full flex flex-col justify-between">
              <div className="flex flex-row justify-between">
                <div className="flex-1">
                  <div className="mr-2">
                    <p className="text-2xl">{title}</p>
                    <p className="text-base">{subTitle}</p>
                  </div>
                </div>
                <div>
                  <div className="p-4 bg-catchup-white rounded-full">
                    {icon}
                  </div>
                </div>
              </div>
              <div>
                <p className={`${fontSizeClassName}`}>{value}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderInformationCardItem;
