import { useEffect, useState } from "react";
import { useAuth } from "../../context/UserContextProvider";
import SingleProfileItem from "../../components/user-profiles/SingleProfileItem";
import i18n from "../../language/i18n";
import { useApp } from "../../context/AppContextProvider";
import { NotificationManager } from "react-notifications";
import {
  removeAxiosInterceptor,
  setAxiosInterceptor,
} from "../../utilization/AxiosUtilization";
import {
  patchUserOnlyRequiredAction,
  retrieveProfileTokenInformation,
} from "../../requests/ManagementRequests";
import InputGroup from "../../components/groups/InputGroup";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import {
  retrieveBrandDTOByUserProfileOptionList,
  retrieveCampusDTOByUserProfileOptionList,
  retrieveInstitutionDTOByUserProfileOptionList,
  retrieveSeasonDTOByUserProfileOptionList,
  retrieveGradeDTOByUserProfileOptionList,
  retrieveBranchDTOByUserProfileOptionList,
} from "../../utilization/ManagementUtilization";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import BaseImage from "../../components/images/BaseImage";
import FullCard from "../../components/cards/FullCard";
import RightsReserved from "../../components/landing/RightsReserved";

const ProfileSelectionScreen = () => {
  const { setBoarding, isFullScreen, setIsFullScreen } = useApp();
  const {
    handleStateLogout,
    userInformation,
    userProfileSet,
    userProfile,
    setUserProfile,
    handleStateChooseProfile,
  } = useAuth();
  const [loadingProfileId, setLoadingProfileId] = useState(null);
  const [selectedBrandId, setSelectedBrandId] = useState("DEFAULT_OPTION");
  const [selectedCampusId, setSelectedCampusId] = useState("DEFAULT_OPTION");
  const [selectedInstitutionId, setSelectedInstitutionId] =
    useState("DEFAULT_OPTION");
  const [selectedSeasonId, setSelectedSeasonId] = useState("DEFAULT_OPTION");
  const [selectedGradeId, setSelectedGradeId] = useState("DEFAULT_OPTION");
  const [selectedBranchId, setSelectedBranchId] = useState("DEFAULT_OPTION");
  const [canProceed, setCanProceed] = useState(false);

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  useEffect(() => {
    setUserProfile(null);
    resetUserContentItems();
    resetSelectionItems();
  }, []);

  useEffect(() => {
    const brandId = localStorage.getItem("brandId");
    if (brandId) {
      setSelectedBrandId(parseFloat(brandId));
    } else {
      if (
        userProfile &&
        retrieveBrandDTOByUserProfileOptionList(userProfile).length > 0
      ) {
        setSelectedBrandId(
          retrieveBrandDTOByUserProfileOptionList(userProfile)[0].value
        );
      } else {
        setSelectedBrandId("DEFAULT_OPTION");
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (!userProfile) return;
    if (selectedBrandId !== "DEFAULT_OPTION") {
      const campusId = localStorage.getItem("campusId");
      if (campusId) {
        setSelectedCampusId(parseFloat(campusId));
      } else {
        const campusDTOOptionList = retrieveCampusDTOByUserProfileOptionList(
          userProfile,
          selectedBrandId
        );
        if (campusDTOOptionList.length > 0) {
          setSelectedCampusId(campusDTOOptionList[0].value);
        } else {
          setSelectedCampusId("DEFAULT_OPTION");
        }
      }
    } else {
      setSelectedCampusId("DEFAULT_OPTION");
    }
  }, [userProfile, selectedBrandId]);

  useEffect(() => {
    if (!userProfile) return;
    if (
      selectedBrandId !== "DEFAULT_OPTION" &&
      selectedCampusId !== "DEFAULT_OPTION"
    ) {
      const institutionId = localStorage.getItem("institutionId");
      if (institutionId) {
        setSelectedInstitutionId(parseFloat(institutionId));
      } else {
        const institutionDTOOptionList =
          retrieveInstitutionDTOByUserProfileOptionList(
            userProfile,
            selectedBrandId,
            selectedCampusId
          );
        if (institutionDTOOptionList.length > 0) {
          setSelectedInstitutionId(institutionDTOOptionList[0].value);
        } else {
          setSelectedInstitutionId("DEFAULT_OPTION");
        }
      }
    } else if (selectedBrandId === "DEFAULT_OPTION") {
      setSelectedCampusId("DEFAULT_OPTION");
    }
  }, [userProfile, selectedBrandId, selectedCampusId]);

  useEffect(() => {
    if (!userProfile) return;
    if (
      selectedBrandId !== "DEFAULT_OPTION" &&
      selectedCampusId !== "DEFAULT_OPTION" &&
      selectedInstitutionId !== "DEFAULT_OPTION"
    ) {
      const seasonId = localStorage.getItem("seasonId");
      if (seasonId) {
        setSelectedSeasonId(parseFloat(seasonId));
      } else {
        const seasonDTOOptionList = retrieveSeasonDTOByUserProfileOptionList(
          userProfile,
          selectedBrandId,
          selectedInstitutionId
        );
        if (seasonDTOOptionList.length > 0) {
          setSelectedSeasonId(seasonDTOOptionList[0].value);
        } else {
          setSelectedSeasonId("DEFAULT_OPTION");
        }
      }
    } else if (selectedCampusId === "DEFAULT_OPTION") {
      setSelectedInstitutionId("DEFAULT_OPTION");
    }
  }, [userProfile, selectedBrandId, selectedCampusId, selectedInstitutionId]);

  useEffect(() => {
    if (!userProfile) return;
    if (
      selectedBrandId !== "DEFAULT_OPTION" &&
      selectedCampusId !== "DEFAULT_OPTION" &&
      selectedInstitutionId !== "DEFAULT_OPTION" &&
      selectedSeasonId !== "DEFAULT_OPTION"
    ) {
      const gradeId = localStorage.getItem("gradeId");
      if (gradeId) {
        setSelectedGradeId(parseFloat(gradeId));
      } else {
        const gradeDTOOptionList = retrieveGradeDTOByUserProfileOptionList(
          userProfile,
          selectedBrandId,
          selectedSeasonId
        );
        if (gradeDTOOptionList.length > 0) {
          setSelectedGradeId(gradeDTOOptionList[0].value);
        } else {
          setSelectedGradeId("DEFAULT_OPTION");
        }
      }
    } else if (selectedInstitutionId === "DEFAULT_OPTION") {
      setSelectedSeasonId("DEFAULT_OPTION");
    }
  }, [
    userProfile,
    selectedBrandId,
    selectedCampusId,
    selectedInstitutionId,
    selectedSeasonId,
  ]);

  useEffect(() => {
    if (!userProfile) return;
    if (
      selectedBrandId !== "DEFAULT_OPTION" &&
      selectedCampusId !== "DEFAULT_OPTION" &&
      selectedInstitutionId !== "DEFAULT_OPTION" &&
      selectedSeasonId !== "DEFAULT_OPTION" &&
      selectedGradeId !== "DEFAULT_OPTION"
    ) {
      const branchId = localStorage.getItem("branchId");
      if (branchId) {
        setSelectedBranchId(parseFloat(branchId));
      } else {
        const branchDTOOptionList = retrieveBranchDTOByUserProfileOptionList(
          userProfile,
          selectedBrandId,
          selectedGradeId
        );
        if (branchDTOOptionList.length > 0) {
          setSelectedBranchId(branchDTOOptionList[0].value);
        } else {
          setSelectedBranchId("DEFAULT_OPTION");
        }
      }
    } else if (selectedSeasonId === "DEFAULT_OPTION") {
      setSelectedGradeId("DEFAULT_OPTION");
    }
  }, [
    userProfile,
    selectedBrandId,
    selectedCampusId,
    selectedInstitutionId,
    selectedSeasonId,
    selectedGradeId,
  ]);

  useEffect(() => {
    setCanProceedOperation();
  }, [
    userProfile,
    selectedBrandId,
    selectedCampusId,
    selectedInstitutionId,
    selectedSeasonId,
    selectedGradeId,
    selectedBranchId,
  ]);

  const filterUserProfileSet = () => {
    return userProfileSet.filter(
      (userProfileDTO) =>
        userProfileDTO.role === "STAFF" ||
        userProfileDTO.role === "INDIVIDUAL" ||
        userProfileDTO.role === "CONTENT_CREATOR"
    );
  };

  const setCanProceedOperation = () => {
    if (userProfile) {
      if (userProfile.role === "LEARNER") {
        setCanProceed(false);
        return;
      }
      if (userProfile.role === "INDIVIDUAL") {
        setCanProceed(true);
        return;
      }
      if (userProfile.role === "CONTENT_CREATOR") {
        setCanProceed(true);
        return;
      }
      if (selectedBrandId === "DEFAULT_OPTION") {
        setCanProceed(false);
        return;
      }
      if (
        userProfile.campusDTOList &&
        userProfile.campusDTOList.length > 0 &&
        selectedCampusId === "DEFAULT_OPTION"
      ) {
        setCanProceed(false);
        return;
      }
      if (
        userProfile.institutionDTOList &&
        userProfile.institutionDTOList.length > 0 &&
        selectedInstitutionId === "DEFAULT_OPTION"
      ) {
        setCanProceed(false);
        return;
      }
      if (
        userProfile.seasonDTOList &&
        userProfile.seasonDTOList.length > 0 &&
        selectedSeasonId === "DEFAULT_OPTION"
      ) {
        setCanProceed(false);
        return;
      }
      if (
        userProfile.gradeDTOList &&
        userProfile.gradeDTOList.length > 0 &&
        selectedGradeId === "DEFAULT_OPTION"
      ) {
        setCanProceed(false);
        return;
      }
      if (
        userProfile.branchDTOList &&
        userProfile.branchDTOList.length > 0 &&
        selectedBranchId === "DEFAULT_OPTION"
      ) {
        setCanProceed(false);
        return;
      }
      setCanProceed(true);
      return;
    }
    setCanProceed(false);
  };

  const resetSelectionItems = () => {
    setSelectedBrandId("DEFAULT_OPTION");
    setSelectedCampusId("DEFAULT_OPTION");
    setSelectedInstitutionId("DEFAULT_OPTION");
    setSelectedSeasonId("DEFAULT_OPTION");
    setSelectedGradeId("DEFAULT_OPTION");
    setSelectedBranchId("DEFAULT_OPTION");
  };

  const resetUserContentItems = () => {
    setSelectedBrandId("DEFAULT_OPTION");
    setSelectedCampusId("DEFAULT_OPTION");
    setSelectedInstitutionId("DEFAULT_OPTION");
    setSelectedSeasonId("DEFAULT_OPTION");
    setSelectedGradeId("DEFAULT_OPTION");
    setSelectedBranchId("DEFAULT_OPTION");
  };

  const handleOnChange = (type, value) => {
    if (type === "brandId") {
      setSelectedBrandId(value);
    } else if (type === "campusId") {
      setSelectedCampusId(value);
    } else if (type === "institutionId") {
      setSelectedInstitutionId(value);
    } else if (type === "seasonId") {
      setSelectedSeasonId(value);
    } else if (type === "gradeId") {
      setSelectedGradeId(value);
    } else if (type === "branchId") {
      setSelectedBranchId(value);
    } else {
    }
  };

  const handleOnClick = async (selectedUserProfile) => {
    setLoadingProfileId(selectedUserProfile.id);
    localStorage.setItem("userProfileId", selectedUserProfile.id);
    const refreshToken = localStorage.getItem("refreshToken");
    const { data } = await retrieveProfileTokenInformation(
      selectedUserProfile.id,
      refreshToken
    );
    removeAxiosInterceptor();
    setAxiosInterceptor(data.accessToken);
    localStorage.setItem("accessToken", data.accessToken);
    setUserProfile(JSON.parse(JSON.stringify(selectedUserProfile)));
    setLoadingProfileId(null);
  };

  const handleLogoutOnClick = () => {
    handleStateLogout();
    setBoarding("LANDING");
    NotificationManager.success(i18n.t("logout_successful_text"));
  };

  const handlePatchUserRequiredActionOnClick = async () => {
    await patchUserOnlyRequiredAction({
      id: userInformation.id,
      requiredAction: null,
    });
  };

  const handleToDashboardOnClick = () => {
    let brandDTO;
    let campusDTO;
    let institutionDTO;
    let seasonDTO;
    let gradeDTO;
    let branchDTO;
    if (
      userProfile.role === "STAFF" ||
      userProfile.role === "CONTENT_CREATOR"
    ) {
      if (selectedBrandId !== "DEFAULT_OPTION") {
        brandDTO = retrieveBrandDTOByUserProfileOptionList(userProfile).find(
          (brandDTO) => brandDTO.value === parseFloat(selectedBrandId)
        )?.fullValue;
      }
      if (
        selectedCampusId !== "DEFAULT_OPTION" &&
        userProfile.campusDTOList &&
        userProfile.campusDTOList.length > 0
      ) {
        campusDTO = retrieveCampusDTOByUserProfileOptionList(
          userProfile,
          selectedBrandId
        ).find(
          (campusDTO) => campusDTO.value === parseFloat(selectedCampusId)
        )?.fullValue;
      }

      if (
        selectedInstitutionId !== "DEFAULT_OPTION" &&
        userProfile.institutionDTOList &&
        userProfile.institutionDTOList.length > 0
      ) {
        institutionDTO = retrieveInstitutionDTOByUserProfileOptionList(
          userProfile,
          selectedBrandId,
          selectedCampusId
        ).find(
          (institutionDTO) =>
            institutionDTO.value === parseFloat(selectedInstitutionId)
        )?.fullValue;
      }

      if (
        selectedSeasonId !== "DEFAULT_OPTION" &&
        userProfile.seasonDTOList &&
        userProfile.seasonDTOList.length > 0
      ) {
        seasonDTO = retrieveSeasonDTOByUserProfileOptionList(
          userProfile,
          selectedBrandId,
          selectedInstitutionId
        ).find(
          (seasonDTO) => seasonDTO.value === parseFloat(selectedSeasonId)
        )?.fullValue;
      }

      if (
        selectedGradeId !== "DEFAULT_OPTION" &&
        userProfile.gradeDTOList &&
        userProfile.gradeDTOList.length > 0
      ) {
        gradeDTO = retrieveGradeDTOByUserProfileOptionList(
          userProfile,
          selectedBrandId,
          selectedSeasonId
        ).find(
          (gradeDTO) => gradeDTO.value === parseFloat(selectedGradeId)
        )?.fullValue;
      }

      if (
        selectedBranchId !== "DEFAULT_OPTION" &&
        userProfile.branchDTOList &&
        userProfile.branchDTOList.length > 0
      ) {
        branchDTO = retrieveBranchDTOByUserProfileOptionList(
          userProfile,
          selectedBrandId,
          selectedGradeId
        ).find(
          (branchDTO) => branchDTO.value === parseFloat(selectedBranchId)
        )?.fullValue;
      }
    } else if (userProfile.role === "INDIVIDUAL") {
      brandDTO = userProfile.seasonDTO.institutionDTO.campusDTO.brandDTO;
      campusDTO = userProfile.seasonDTO.institutionDTO.campusDTO;
      institutionDTO = userProfile.seasonDTO.institutionDTO;
      seasonDTO = userProfile.seasonDTO;
    }
    handleStateChooseProfile(
      userProfile,
      brandDTO,
      campusDTO,
      institutionDTO,
      seasonDTO,
      gradeDTO,
      branchDTO
    );
    localStorage.setItem("appState", "CHANGE_PROFILE");

    if (
      userInformation.requiredAction === "PROFILE_SELECTION" ||
      userInformation.requiredAction === "LOGOUT"
    ) {
      handlePatchUserRequiredActionOnClick();
    }

    setBoarding("DASHBOARD");
  };

  const RenderMainContent = () => {
    return (
      <FullCard>
        <div className="py-5">
          <div className="flex flex-col items-center justify-center">
            <BaseImage
              size="custom"
              className="h-catchup-large-logo w-catchup-large-logo"
              src="/logos/logo-primary.png"
              alt="main-logo"
            />
          </div>
          <div className="flex flex-col items-center justify-center my-8">
            <p className="text-xl text-catchup-gray">
              {i18n.t("select_profile_text")}
            </p>
          </div>
          {filteredUserProfileSet.length > 0 ? (
            <>
              <div className="flex flex-row justify-center flex-wrap my-5">
                {filteredUserProfileSet.map((userProfileDTO) => (
                  <div
                    key={userProfileDTO.id}
                    className="w-1/5 min-w-[250px] my-2"
                  >
                    <SingleProfileItem
                      userProfile={userProfileDTO}
                      selectedUserProfileId={userProfile?.id}
                      type={i18n.t(userProfileDTO.role)}
                      onClick={() => {
                        handleOnClick(userProfileDTO);
                      }}
                      selectedProfileId={loadingProfileId}
                    />
                  </div>
                ))}
              </div>
              {userProfile &&
              (userProfile?.role === "STAFF" ||
                userProfile?.role === "CONTENT_CREATOR") ? (
                <div className="flex flex-col justify-center items-center">
                  <div className="w-full lg:w-1/2">
                    <InputGroup
                      type="select"
                      title={i18n.t("brand")}
                      placeholder={null}
                      value={selectedBrandId}
                      optionList={retrieveBrandDTOByUserProfileOptionList(
                        userProfile
                      )}
                      onChange={(event) => {
                        handleOnChange("brandId", event.target.value);
                      }}
                    />
                  </div>
                  {userProfile.campusDTOList &&
                  userProfile.campusDTOList.length > 0 ? (
                    <div className="w-full lg:w-1/2">
                      <InputGroup
                        type="select"
                        title={i18n.t("campus")}
                        placeholder={null}
                        value={selectedCampusId}
                        optionList={retrieveCampusDTOByUserProfileOptionList(
                          userProfile,
                          selectedBrandId
                        )}
                        onChange={(event) => {
                          handleOnChange("campusId", event.target.value);
                        }}
                      />
                    </div>
                  ) : null}

                  {userProfile.institutionDTOList &&
                  userProfile.institutionDTOList.length > 0 ? (
                    <div className="w-full lg:w-1/2">
                      <InputGroup
                        type="select"
                        title={i18n.t("institution")}
                        placeholder={null}
                        value={selectedInstitutionId}
                        optionList={retrieveInstitutionDTOByUserProfileOptionList(
                          userProfile,
                          selectedBrandId,
                          selectedCampusId
                        )}
                        onChange={(event) => {
                          handleOnChange("institutionId", event.target.value);
                        }}
                      />
                    </div>
                  ) : null}
                  {userProfile.seasonDTOList &&
                  userProfile.seasonDTOList.length > 0 ? (
                    <div className="w-full lg:w-1/2">
                      <InputGroup
                        type="select"
                        title={i18n.t("season")}
                        placeholder={null}
                        value={selectedSeasonId}
                        optionList={retrieveSeasonDTOByUserProfileOptionList(
                          userProfile,
                          selectedBrandId,
                          selectedInstitutionId
                        )}
                        onChange={(event) => {
                          handleOnChange("seasonId", event.target.value);
                        }}
                      />
                    </div>
                  ) : null}
                  {userProfile.gradeDTOList &&
                  userProfile.gradeDTOList.length > 0 ? (
                    <div className="w-full lg:w-1/2">
                      <InputGroup
                        type="select"
                        title={i18n.t("grade")}
                        placeholder={null}
                        value={selectedGradeId}
                        optionList={retrieveGradeDTOByUserProfileOptionList(
                          userProfile,
                          selectedBrandId,
                          selectedSeasonId
                        )}
                        onChange={(event) => {
                          handleOnChange("gradeId", event.target.value);
                        }}
                      />
                    </div>
                  ) : null}
                  {userProfile.branchDTOList &&
                  userProfile.branchDTOList.length > 0 ? (
                    <div className="w-full lg:w-1/2">
                      <InputGroup
                        type="select"
                        title={i18n.t("branch")}
                        placeholder={null}
                        value={selectedBranchId}
                        optionList={retrieveBranchDTOByUserProfileOptionList(
                          userProfile,
                          selectedBrandId,
                          selectedGradeId
                        )}
                        onChange={(event) => {
                          handleOnChange("branchId", event.target.value);
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}
              {canProceed ? (
                <div className="flex flex-col items-center justify-center">
                  <div className="mt-3">
                    <PrimaryButton
                      title={i18n.t("continue")}
                      size="small"
                      onClick={handleToDashboardOnClick}
                    />
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <div className="h-48 flex flex-col justify-center items-center m-auto border border-catchup-gray-100 rounded-catchup-xlarge">
              <p className="">
                {i18n.t("you_do_not_have_any_user_profiles_yet")}
              </p>
            </div>
          )}
          {filteredUserProfileSet.length === 0 ? (
            <div className="flex flex-col justify-center items-center">
              <div className="my-3">
                <SecondaryButton
                  title={i18n.t("logout")}
                  size="small"
                  onClick={handleLogoutOnClick}
                />
              </div>
            </div>
          ) : null}
          <div className="mt-8">
            <RightsReserved />
          </div>
        </div>
      </FullCard>
    );
  };

  const filteredUserProfileSet = filterUserProfileSet();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ProfileSelectionScreen;
