import { Text, View } from "@react-pdf/renderer";
import i18n from "../../language/i18n";

const InformationReportViewContent = (props) => {
  const { language, subType, reportDetail } = props;

  const constructSubHeaderText = () => {
    if (subType === "USER") {
      return i18n.t("user_report");
    } else if (subType === "USER_PROFILE") {
      return i18n.t("user_profile_report");
    } else if (subType === "BRANCH") {
      return i18n.t("branch_report");
    } else if (subType === "GRADE") {
      return i18n.t("grade_report");
    } else if (subType === "SEASON") {
      return i18n.t("season_report");
    } else if (subType === "INSTITUTION") {
      return i18n.t("institution_report");
    } else if (subType === "CAMPUS") {
      return i18n.t("campus_report");
    } else if (subType === "BRAND") {
      return i18n.t("brand_report");
    } else if (subType === "REGION") {
      return i18n.t("region_report");
    }
    return null;
  };

  const RenderCommonReportViewContent = () => {
    return (
      <>
        <Text style={{ marginVertical: 5 }}>
          <Text style={{ fontWeight: "bold" }}>{i18n.t("begin_at")}: </Text>
          {new Date(reportDetail.beginAt).toLocaleDateString(language)}
        </Text>
        <Text style={{ marginVertical: 5 }}>
          {i18n.t("end_at")}:{" "}
          {new Date(reportDetail.endAt).toLocaleDateString(language)}
        </Text>
        <Text style={{ marginVertical: 5 }}>
          {i18n.t("type")}: {i18n.t(reportDetail.type)}
        </Text>
      </>
    );
  };

  const RenderUserDTOContent = (userDTO) => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          paddingBottom: 5,
        }}
      >
        <View style={{ display: "flex", width: "35%" }}>
          <View style={{ marginHorizontal: 10 }}>
            <Text>{userDTO.firstName}</Text>
          </View>
        </View>
        <View style={{ display: "flex", width: "35%" }}>
          <View style={{ marginHorizontal: 10 }}>
            <Text>{userDTO.lastName}</Text>
          </View>
        </View>
        <View style={{ display: "flex", width: "30%" }}>
          <View style={{ marginHorizontal: 10 }}>
            <Text>{userDTO.username}</Text>
          </View>
        </View>
      </View>
    );
  };

  const RenderUserProfileDTOContent = (userProfileDTO) => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
        }}
      >
        <View style={{ display: "flex", width: "15%" }}>
          <View style={{ marginHorizontal: 10 }}>
            <Text>{userProfileDTO.name}</Text>
          </View>
        </View>
        <View style={{ display: "flex", width: "85%" }}>
          {RenderUserDTOContent(userProfileDTO.userDTO)}
        </View>
      </View>
    );
  };

  const RenderBranchDTOForReportContent = (branchDTOForReport) => {
    return (
      <View style={{ flex: 1 }}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            borderBottomWidth: 1,
            paddingBottom: 5,
          }}
        >
          <View style={{ display: "flex", width: "20%" }}>
            <View style={{ marginHorizontal: 10 }}>
              <Text>
                {
                  branchDTOForReport.gradeDTO.seasonDTO.institutionDTO.campusDTO
                    .brandDTO.name
                }
              </Text>
            </View>
          </View>
          <View style={{ display: "flex", width: "30%" }}>
            <View style={{ marginHorizontal: 10 }}>
              <Text>
                {branchDTOForReport.gradeDTO.seasonDTO.institutionDTO.name}
              </Text>
            </View>
          </View>
          <View style={{ display: "flex", width: "30%" }}>
            <View style={{ marginHorizontal: 10 }}>
              <Text>{branchDTOForReport.gradeDTO.seasonDTO.name}</Text>
            </View>
          </View>
          <View style={{ display: "flex", width: "12%" }}>
            <View style={{ marginHorizontal: 10 }}>
              <Text>{branchDTOForReport.gradeDTO.name}</Text>
            </View>
          </View>
          <View style={{ display: "flex", width: "8%" }}>
            <View style={{ marginHorizontal: 10 }}>
              <Text>{branchDTOForReport.name}</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            marginTop: 10,
          }}
        >
          <View style={{ marginHorizontal: 10 }}>
            {branchDTOForReport.basicUserProfileDTOList
              .filter((userProfile) => userProfile.role === "LEARNER")
              .map((basicUserProfileDTO, index) => (
                <View style={{ flex: 1, flexDirection: "row" }}>
                  <View style={{ display: "flex", width: "5%" }}>
                    <Text>{index + 1}.</Text>
                  </View>
                  <View style={{ display: "flex", width: "95%" }}>
                    {RenderUserProfileDTOContent(basicUserProfileDTO)}
                  </View>
                </View>
              ))}
          </View>
        </View>
      </View>
    );
  };

  const RenderGradeDTOForReportContent = (gradeDTOForReport) => {
    let count = 0;
    return (
      <View style={{ flex: 1 }}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            borderBottomWidth: 1,
            paddingBottom: 5,
          }}
        >
          <View style={{ display: "flex", width: "20%" }}>
            <View style={{ marginHorizontal: 10 }}>
              <Text>
                {
                  gradeDTOForReport.seasonDTO.institutionDTO.campusDTO.brandDTO
                    .name
                }
              </Text>
            </View>
          </View>
          <View style={{ display: "flex", width: "30%" }}>
            <View style={{ marginHorizontal: 10 }}>
              <Text>{gradeDTOForReport.seasonDTO.institutionDTO.name}</Text>
            </View>
          </View>
          <View style={{ display: "flex", width: "30%" }}>
            <View style={{ marginHorizontal: 10 }}>
              <Text>{gradeDTOForReport.seasonDTO.name}</Text>
            </View>
          </View>
          <View style={{ display: "flex", width: "20%" }}>
            <View style={{ marginHorizontal: 10 }}>
              <Text>{gradeDTOForReport.name}</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            marginTop: 10,
          }}
        >
          <View style={{ marginHorizontal: 10 }}>
            {gradeDTOForReport.branchDTOForReportList.map(
              (branchDTOForReport) =>
                branchDTOForReport.basicUserProfileDTOList
                  .filter((userProfile) => userProfile.role === "LEARNER")
                  .map((basicUserProfileDTO) => {
                    count++;
                    return (
                      <View style={{ flex: 1, flexDirection: "row" }}>
                        <View style={{ display: "flex", width: "5%" }}>
                          <Text>{count}.</Text>
                        </View>
                        <View style={{ display: "flex", width: "95%" }}>
                          {RenderUserProfileDTOContent(basicUserProfileDTO)}
                        </View>
                      </View>
                    );
                  })
            )}
          </View>
        </View>
      </View>
    );
  };

  const RenderSeasonDTOForReportContent = (seasonDTOForReport) => {
    let count = 0;
    return (
      <View style={{ flex: 1 }}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            borderBottomWidth: 1,
            paddingBottom: 5,
          }}
        >
          <View style={{ display: "flex", width: "30%" }}>
            <View style={{ marginHorizontal: 10 }}>
              <Text>
                {seasonDTOForReport.institutionDTO.campusDTO.brandDTO.name}
              </Text>
            </View>
          </View>
          <View style={{ display: "flex", width: "35%" }}>
            <View style={{ marginHorizontal: 10 }}>
              <Text>{seasonDTOForReport.institutionDTO.name}</Text>
            </View>
          </View>
          <View style={{ display: "flex", width: "35%" }}>
            <View style={{ marginHorizontal: 10 }}>
              <Text>{seasonDTOForReport.name}</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            marginTop: 10,
          }}
        >
          <View style={{ marginHorizontal: 10 }}>
            {seasonDTOForReport.gradeDTOForReportList.map((gradeDTOForReport) =>
              gradeDTOForReport.branchDTOForReportList.map(
                (branchDTOForReport) =>
                  branchDTOForReport.basicUserProfileDTOList
                    .filter((userProfile) => userProfile.role === "LEARNER")
                    .map((basicUserProfileDTO) => {
                      count++;
                      return (
                        <View style={{ flex: 1, flexDirection: "row" }}>
                          <View style={{ display: "flex", width: "5%" }}>
                            <Text>{count}.</Text>
                          </View>
                          <View style={{ display: "flex", width: "95%" }}>
                            {RenderUserProfileDTOContent(basicUserProfileDTO)}
                          </View>
                        </View>
                      );
                    })
              )
            )}
          </View>
        </View>
      </View>
    );
  };

  const RenderUserReportViewContent = () => {
    return (
      <>
        <View style={{ width: "100%" }}>
          <View style={{ marginVertical: 10 }}>
            {reportDetail.userDTOList.map((userDTO) => (
              <View style={{ marginBottom: 20 }}>
                {RenderUserDTOContent(userDTO)}
              </View>
            ))}
          </View>
        </View>
      </>
    );
  };

  const RenderUserProfileReportViewContent = () => {
    return (
      <>
        <View style={{ width: "100%" }}>
          <View style={{ marginVertical: 10 }}>
            {reportDetail.userProfileDTOList.map((userProfileDTO) => (
              <View style={{ marginBottom: 20 }}>
                {RenderUserProfileDTOContent(userProfileDTO)}
              </View>
            ))}
          </View>
        </View>
      </>
    );
  };

  const RenderBranchReportViewContent = () => {
    return (
      <>
        <View style={{ width: "100%" }}>
          <View style={{ marginVertical: 10 }}>
            {reportDetail.branchDTOForReportList.map((branchDTOForReport) => (
              <View style={{ marginBottom: 20 }}>
                {RenderBranchDTOForReportContent(branchDTOForReport)}
              </View>
            ))}
          </View>
        </View>
      </>
    );
  };

  const RenderGradeReportViewContent = () => {
    return (
      <>
        <View style={{ width: "100%" }}>
          <View style={{ marginVertical: 10 }}>
            {reportDetail.gradeDTOForReportList.map((gradeDTOForReport) => (
              <View style={{ marginBottom: 20 }}>
                {RenderGradeDTOForReportContent(gradeDTOForReport)}
              </View>
            ))}
          </View>
        </View>
      </>
    );
  };

  const RenderSeasonReportViewContent = () => {
    return (
      <>
        <View style={{ width: "100%" }}>
          <View style={{ marginVertical: 10 }}>
            {reportDetail.seasonDTOForReportList.map((seasonDTOForReport) => (
              <View style={{ marginBottom: 20 }}>
                {RenderSeasonDTOForReportContent(seasonDTOForReport)}
              </View>
            ))}
          </View>
        </View>
      </>
    );
  };

  return (
    <View style={{ width: "100%" }}>
      <View style={{ flexDirection: "column", alignSelf: "center" }}>
        <Text style={{ fontSize: 16, fontWeight: "bold" }}>
          {i18n.t("information_report")} - {constructSubHeaderText()}
        </Text>
      </View>
      {subType === "USER" ? (
        <>
          {RenderCommonReportViewContent()}
          {RenderUserReportViewContent()}
        </>
      ) : subType === "USER_PROFILE" ? (
        <>
          {RenderCommonReportViewContent()}
          {RenderUserProfileReportViewContent()}
        </>
      ) : subType === "BRANCH" ? (
        <>
          {RenderCommonReportViewContent()}
          {RenderBranchReportViewContent()}
        </>
      ) : subType === "GRADE" ? (
        <>
          {RenderCommonReportViewContent()}
          {RenderGradeReportViewContent()}
        </>
      ) : subType === "SEASON" ? (
        <>
          {RenderCommonReportViewContent()}
          {RenderSeasonReportViewContent()}
        </>
      ) : subType === "INSTITUTION" ? (
        <>
          {RenderCommonReportViewContent()}
          {/* {RenderInstitutionReportViewContent()} */}
        </>
      ) : subType === "CAMPUS" ? (
        <>
          {RenderCommonReportViewContent()}
          {/* {RenderCampusReportViewContent()} */}
        </>
      ) : subType === "BRAND" ? (
        <>
          {RenderCommonReportViewContent()}
          {/* {RenderBrandReportViewContent()} */}
        </>
      ) : subType === "REGION" ? (
        <>
          {RenderCommonReportViewContent()}
          {/* {RenderRegionReportViewContent()} */}
        </>
      ) : null}
    </View>
  );
};

export default InformationReportViewContent;
