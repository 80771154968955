import { useCallback, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  deleteActivity,
  queryAllActivityDTOPageByAccessibilityParams,
  queryAllActivityDTOPageByUserIdAndUserProfileId,
} from "../../requests/CatchtivityRequests";
import {
  queryBrandLabelActionByItemIdListAndItemType,
  queryLabelActionByItemIdListAndItemType,
  queryPublishingHouseLabelActionByItemIdListAndItemType,
} from "../../requests/LabelRequests";
import {
  checkDeletePermissionGranted,
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import { useApp } from "../../context/AppContextProvider";
import AdvancedTable from "../../components/tables/AdvancedTable";
import FullCard from "../../components/cards/FullCard";
import SetOrUpsertLabel from "../../components/label/SetOrUpsertLabel";
import CreateActivity from "../../components/activity/CreateActivity";
import BaseModal from "../../components/modal/BaseModal";
import BaseImage from "../../components/images/BaseImage";
import { findAISettingsFromCurrentProfile } from "../../utilization/ManagementUtilization";
import debounce from "lodash.debounce";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const ActivityListScreen = () => {
  const { state, search } = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(search);
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileGrade,
    categorySet,
  } = useAuth();
  const [activityDTOList, setActivityDTOList] = useState([]);
  const [labelActionDTOList, setLabelActionDTOList] = useState([]);
  const [brandLabelActionDTOList, setBrandLabelActionDTOList] = useState([]);
  const [
    publishingHouseLabelActionDTOList,
    setPublishingHouseLabelActionDTOList,
  ] = useState([]);
  const [pageState, setPageState] = useState(0);
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterTextContains, setFilterTextContains] = useState("");
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [filterCoterieType, setFilterCoterieType] = useState(
    userInformation.accountType === "GENIXO"
      ? "MANAGEMENT"
      : userProfile.coterieType
      ? userProfile.coterieType
      : "DEFAULT_OPTION"
  );
  const [filterLevel, setFilterLevel] = useState(
    userProfileGrade ? userProfileGrade.level : "DEFAULT_OPTION"
  );
  const [filterCategoryCode, setFilterCategoryCode] =
    useState("DEFAULT_OPTION");
  const [filterSubCategoryCode, setFilterSubCategoryCode] =
    useState("DEFAULT_OPTION");
  const [filterOutcomeCode, setFilterOutcomeCode] = useState("DEFAULT_OPTION");
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [labelLoading, setLabelLoading] = useState(false);
  const [brandLabelLoading, setBrandLabelLoading] = useState(false);
  const [publishingHouseLabelLoading, setPublishingHouseLabelLoading] =
    useState(false);
  const ITEM_TYPE = "ACTIVITY";
  const [
    showCreateActivitySelectionModal,
    setShowCreateActivitySelectionModal,
  ] = useState(false);
  const [existingActivity, setExistingActivity] = useState(null);
  const [debounceState, setDebounceState] = useState("");

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("activity_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    if (!state) return;
    const { pageState } = state;
    setPageState(pageState);
  }, [state]);

  useEffect(() => {
    if (searchParams.size > 0) return;
    retrieveAndSetActivityPageByFilter();
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    if (searchParams.size > 0) return;
    setDebounceState("BASE_FILTER");
    retrieveAndSetActivityPageByFilter();
  }, [
    isFilterApplied,
    filterBeginAt,
    filterEndAt,
    filterShowDeleted,
    filterCoterieType,
    filterLevel,
    filterCategoryCode,
    filterSubCategoryCode,
    filterOutcomeCode,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    if (filterTextContains) {
      setDebounceState("TEXT_CONTAINS");
      debouncedRetrieveAndSetActivityPageByFilterDebounce(filterTextContains);
    } else {
      retrieveAndSetActivityPageByFilter();
    }
  }, [filterTextContains]);

  useEffect(() => {
    const retrieveAndSetLabelActionList = async () => {
      setLabelLoading(true);
      const { data, err } = await queryLabelActionByItemIdListAndItemType({
        itemIdList: activityDTOList.map((activityDTO) => activityDTO.id),
        itemType: ITEM_TYPE,
        userId: userInformation.id,
        userProfileId: userProfile.id,
      });
      if (err) {
      } else {
        setLabelActionDTOList(data);
      }
      setLabelLoading(false);
    };
    const retrieveAndSetBrandLabelActionList = async () => {
      setBrandLabelLoading(true);
      const { data, err } = await queryBrandLabelActionByItemIdListAndItemType({
        itemIdList: activityDTOList.map((activityDTO) => activityDTO.id),
        itemType: ITEM_TYPE,
        brandId: userProfileBrand.id,
      });
      if (err) {
      } else {
        setBrandLabelActionDTOList(data);
      }
      setBrandLabelLoading(false);
    };
    const retrieveAndSetPublishingHouseLabelActionList = async () => {
      setPublishingHouseLabelLoading(true);
      const { data, err } =
        await queryPublishingHouseLabelActionByItemIdListAndItemType({
          itemIdList: activityDTOList.map((activityDTO) => activityDTO.id),
          itemType: ITEM_TYPE,
        });
      if (err) {
      } else {
        setPublishingHouseLabelActionDTOList(data);
      }
      setPublishingHouseLabelLoading(false);
    };
    if (activityDTOList.length > 0) {
      retrieveAndSetLabelActionList();
      if (userProfile.role === "STAFF") {
        retrieveAndSetBrandLabelActionList();
      }
      if (userInformation.accountType === "GENIXO") {
        retrieveAndSetPublishingHouseLabelActionList();
      }
    }
  }, [userInformation, activityDTOList]);

  useEffect(() => {
    if (pageState === 1) {
      document
        .getElementById("activity_main")
        ?.scrollTo(0, document.body.scrollHeight);
    }
  }, [pageState]);

  useEffect(() => {
    if (searchParams.size === 0) return;
    const searchActivityName = searchParams.get("activity_name");
    if (!searchActivityName) return;
    setDebounceState("TEXT_CONTAINS");
    setIsFilterApplied(true);
    setFilterTextContains(searchActivityName);
  }, [searchParams]);

  const retrieveAndSetActivityPageByFilter = async () => {
    setTableLoading(true);
    let data;
    let err;
    if (userProfile.role === "STAFF") {
      const { data: staffData, err: staffErr } =
        await queryAllActivityDTOPageByAccessibilityParams(pagedQueryParams);
      data = staffData;
      err = staffErr;
    } else if (
      userProfile.role === "INDIVIDUAL" ||
      userProfile.role === "CONTENT_CREATOR"
    ) {
      const { data: individualData, err: individualErr } =
        await queryAllActivityDTOPageByUserIdAndUserProfileId(pagedQueryParams);
      data = individualData;
      err = individualErr;
    }
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setActivityDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const retrieveAndSetActivityPageByFilterDebounce = async (
    filterTextContains
  ) => {
    setTableLoading(true);
    if (isFilterApplied) {
      pagedQueryParams.params.textContains = filterTextContains.trim();
    }
    let data;
    let err;
    if (userProfile.role === "STAFF") {
      const { data: staffData, err: staffErr } =
        await queryAllActivityDTOPageByAccessibilityParams(pagedQueryParams);
      data = staffData;
      err = staffErr;
    } else if (
      userProfile.role === "INDIVIDUAL" ||
      userProfile.role === "CONTENT_CREATOR"
    ) {
      const { data: individualData, err: individualErr } =
        await queryAllActivityDTOPageByUserIdAndUserProfileId(pagedQueryParams);
      data = individualData;
      err = individualErr;
    }
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setActivityDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const debouncedRetrieveAndSetActivityPageByFilterDebounce = useCallback(
    debounce(function (textContains) {
      retrieveAndSetActivityPageByFilterDebounce(textContains);
    }, 1000),
    [debounceState]
  );

  const retrieveSubCategoryCodeList = () => {
    const filteredCategoryDTOList = [];
    const filteredMainCategoryDTOList = categorySet.filter(
      (categoryDTO) => categoryDTO.versionCode === filterCategoryCode
    );
    for (const currentCategoryDTO of filteredMainCategoryDTOList) {
      const foundIndex = filteredMainCategoryDTOList.findIndex(
        (categoryDTO) => categoryDTO.parentCode === currentCategoryDTO.code
      );
      if (foundIndex !== -1) {
        filteredCategoryDTOList.push(currentCategoryDTO);
      }
    }

    return filteredCategoryDTOList
      .sort((a, b) => a.code.localeCompare(b.code))
      .map((categoryDTO) => ({
        value: categoryDTO.id,
        text: `${categoryDTO.code} - ${categoryDTO.name}`,
      }));
  };

  const retrieveOutcomeCodeList = () => {
    const foundCategory = categorySet.find(
      (category) => category.id === filterSubCategoryCode
    );
    if (!foundCategory) return [];
    const filteredCategoryList = categorySet.filter(
      (category) =>
        category.versionCode === foundCategory.versionCode &&
        category.level === foundCategory.level
    );

    const subCategoryList = categorySet.filter(
      (category) => category.parentCode === foundCategory.code
    );
    const filterOutcomeCodeList = [];
    for (const subCategory of subCategoryList) {
      const filteredSubCategoryList = filteredCategoryList.filter(
        (filteredCategory) => filteredCategory.code.startsWith(subCategory.code)
      );
      for (const filteredSubCategory of filteredSubCategoryList) {
        const foundIndex = filteredSubCategoryList.findIndex(
          (categoryDTO) => categoryDTO.parentCode === filteredSubCategory.code
        );
        if (foundIndex === -1) {
          filterOutcomeCodeList.push(filteredSubCategory);
        }
      }
    }

    return filterOutcomeCodeList
      .sort((a, b) => a.code.localeCompare(b.code))
      .map((categoryDTO) => ({
        value: categoryDTO.id,
        text: `${categoryDTO.code} - ${categoryDTO.name}`,
      }));
  };

  const retrieveSubCategoryCodeIdList = () => {
    const foundSubCategory = categorySet.find(
      (category) =>
        parseFloat(category.id) === parseFloat(filterSubCategoryCode)
    );
    if (foundSubCategory) {
      let currentCategorySet = [];
      currentCategorySet.concat(
        recursiveSubCategoryCodeIdList(
          categorySet,
          currentCategorySet,
          foundSubCategory.code
        )
      );
      return currentCategorySet.map((category) => category.id);
    }
    return [];
  };

  const recursiveSubCategoryCodeIdList = (
    categorySet,
    currentCategorySet,
    categoryCode
  ) => {
    const filterCategorySet = categorySet.filter(
      (category) => category.parentCode === categoryCode
    );
    filterCategorySet.forEach((filterCategory) => {
      const foundIndex = categorySet.findIndex(
        (categoryDTO) => categoryDTO.parentCode === filterCategory.code
      );
      if (foundIndex === -1) {
        const foundCurrentIndex = currentCategorySet.findIndex(
          (currentCategory) => currentCategory.id === filterCategory.id
        );
        if (foundCurrentIndex === -1) {
          currentCategorySet.push(filterCategory);
        }
      } else {
        recursiveSubCategoryCodeIdList(
          categorySet,
          currentCategorySet,
          filterCategory.code
        );
      }
    });
    return currentCategorySet;
  };

  const constructLabelActionList = () => {
    const actionDTOList = [];
    for (const labelAction of labelActionDTOList) {
      actionDTOList.push({
        id: labelAction.id,
        label: labelAction.labelDTO,
        labelItemList: labelAction.labelItemDTOList,
        type: "PERSONAL",
      });
    }
    for (const brandLabelAction of brandLabelActionDTOList) {
      actionDTOList.push({
        id: brandLabelAction.id,
        label: brandLabelAction.brandLabelDTO,
        labelItemList: brandLabelAction.brandLabelItemDTOList,
        type: "BRAND",
      });
    }
    for (const publishingHouseAction of publishingHouseLabelActionDTOList) {
      actionDTOList.push({
        id: publishingHouseAction.id,
        label: publishingHouseAction.publishingHouseLabelDTO,
        labelItemList: publishingHouseAction.publishingHouseLabelItemDTOList,
        type: "PUBLISHING_HOUSE",
      });
    }
    return actionDTOList;
  };

  const constructPagedQueryParams = () => {
    return {
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        beginAt:
          isFilterApplied && filterBeginAt
            ? new Date(filterBeginAt).getTime()
            : null,
        endAt:
          isFilterApplied && filterEndAt
            ? new Date(filterEndAt).getTime()
            : null,
        deleted: isFilterApplied ? (filterShowDeleted ? null : false) : false,
        userId: userInformation.id,
        userProfileId: userProfile.id,
        brandId: userProfileBrand.id,
        campusId: userProfileCampus?.id,
        institutionId: userProfileInstitution?.id,
        textContains:
          isFilterApplied && filterTextContains ? filterTextContains : null,
        coterieType: isFilterApplied
          ? filterCoterieType !== "DEFAULT_OPTION"
            ? filterCoterieType
            : null
          : userProfile.coterieType
          ? userProfile.coterieType
          : null,
        level: isFilterApplied
          ? filterLevel !== "DEFAULT_OPTION"
            ? filterLevel
            : null
          : userProfileGrade?.level,
        categoryIdList: isFilterApplied
          ? filterOutcomeCode !== "DEFAULT_OPTION"
            ? [filterOutcomeCode]
            : retrieveSubCategoryCodeIdList()
          : [],
      },
    };
  };

  const checkCanNavigate = () => {
    if (userProfile.role === "INDIVIDUAL") {
      return false;
    } else {
      return (
        checkViewPermissionGranted("activity_accessibility") ||
        checkInsertPermissionGranted("activity_list")
      );
    }
  };

  const constructNavigatableList = () => {
    let navigateList = [];
    let index = 0;
    if (checkViewPermissionGranted("activity_accessibility")) {
      navigateList.push({
        id: index,
        text: i18n.t("to_activity_accessibilities_screen"),
        icon: (
          <BaseImage
            src="/icons/navigate-gray.png"
            alt="navigate"
            size="small"
          />
        ),
        value: "/activities/{id}/accessibilities",
      });
      index++;
    }
    const { canGenerateActivity } = findAISettingsFromCurrentProfile(
      userProfileBrand,
      userProfileCampus,
      userProfileInstitution
    );
    if (checkInsertPermissionGranted("activity_list") && canGenerateActivity) {
      navigateList.push({
        id: index,
        text: i18n.t("generate_with_ai"),
        icon: (
          <BaseImage
            src="/icons/generate-with-ai-gray.png"
            alt="generate-with-ai"
            size="small"
          />
        ),
        value: "/activities/generate-with-ai/{id}/from-activity",
      });
      index++;
    }
    // const { accountType } = userInformation;
    // if (accountType === "GENIXO") {
    //   navigateList.push({
    //     id: index,
    //     text: i18n.t("try_a_prompt"),
    //     icon: (
    //       <BaseImage
    //         src="/icons/try-a-prompt-gray.png"
    //         alt="try-a-prompt"
    //         size="small"
    //       />
    //     ),
    //     value: "/activities/generate-with-ai/{id}/try-a-prompt",
    //   });
    //   index++;
    // }
    return navigateList;
  };

  const resetParams = () => {
    setExistingActivity(null);
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    } else if (type === "coterieType") {
      setFilterCoterieType(value);
      setFilterCategoryCode("DEFAULT_OPTION");
      setFilterSubCategoryCode("DEFAULT_OPTION");
      setFilterOutcomeCode("DEFAULT_OPTION");
    } else if (type === "categoryCode") {
      setFilterCategoryCode(value);
      setFilterSubCategoryCode("DEFAULT_OPTION");
      setFilterOutcomeCode("DEFAULT_OPTION");
    } else if (type === "subCategoryCode") {
      setFilterSubCategoryCode(value);
      setFilterOutcomeCode("DEFAULT_OPTION");
    } else if (type === "outcomeCode") {
      setFilterOutcomeCode(value);
    } else if (type === "textContains") {
      setFilterTextContains(value);
    } else if (type === "level") {
      setFilterLevel(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleReturnFromActivityOnClick = () => {
    resetParams();
    setPageState(0);
  };

  const handleToUpdateActivityOnClick = (activityDTO) => {
    setExistingActivity(activityDTO);
    setPageState(1);
  };

  const handleToLabelActivityOnClick = (activityDTO) => {
    setShowLabelModal(true);
    setSelectedActivity(activityDTO);
  };

  const handleToCreateActivityOnClick = () => {
    setPageState(1);
    setShowCreateActivitySelectionModal(false);
  };

  const handleRemoveActivityOnClick = async (activityId) => {
    if (checkDeletePermissionGranted("activity_list")) {
      const { data, err } = await deleteActivity(activityId);
      if (err) {
        if (err.response.data) {
          NotificationManager.error(i18n.t(err.response.data));
        }
      } else {
        const foundActivity = activityDTOList.find(
          (activityDTO) => activityDTO.id === activityId
        );
        foundActivity.deleted = true;
        setActivityDTOList(
          JSON.parse(
            JSON.stringify(activityDTOList.sort((a, b) => b.id - a.id))
          )
        );
        retrieveAndSetActivityPageByFilter();
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_delete"));
    }
  };

  const handlePreviewActivityOnClick = (activityDTO) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    window.open(
      `${connection.app.url}/preview/activity/${activityDTO.id}/${btoa(
        encodeURI(JSON.stringify({ token: accessToken }))
      )}`
    );
  };

  const handleAfterCreateActivityAction = (createdActivity) => {
    activityDTOList.unshift({
      id: createdActivity.id,
      name: createdActivity.name,
      description: createdActivity.description,
      status: createdActivity.status,
      coterieType: createdActivity.coterieType,
      type: createdActivity.type,
      deleted: createdActivity.deleted,
      level: createdActivity.level,
      activityTemplateDTOList: createdActivity.activityTemplateDTOList,
      categoryIdList: createdActivity.categoryIdList,
      createdAt: createdActivity.createdAt,
    });
    setActivityDTOList(JSON.parse(JSON.stringify(activityDTOList)));
  };

  const handleAfterUpdateActivityAction = (updatedActivity) => {
    const foundActivityIndex = activityDTOList.findIndex(
      (activityDTO) => activityDTO.id === updatedActivity.id
    );
    activityDTOList[foundActivityIndex] = {
      ...activityDTOList[foundActivityIndex],
      id: updatedActivity.id,
      name: updatedActivity.name,
      status: updatedActivity.status,
      coterieType: updatedActivity.coterieType,
      description: updatedActivity.description,
      type: updatedActivity.type,
      deleted: updatedActivity.deleted,
      level: updatedActivity.level,
      activityTemplateDTOList: updatedActivity.activityTemplateDTOList,
      categoryIdList: updatedActivity.categoryIdList,
      createdAt: updatedActivity.createdAt,
    };
    setActivityDTOList(JSON.parse(JSON.stringify(activityDTOList)));
  };

  const handleSameActivityUpdatedBySomeoneAction = () => {
    retrieveAndSetActivityPageByFilter();
    // retrieveAndSetActivityDTOList();
  };

  const RenderLabelModal = () => {
    return (
      <BaseModal
        isOpen={showLabelModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowLabelModal(false);
          setSelectedActivity(null);
        }}
      >
        <div className="flex-1 flex flex-col">
          <SetOrUpsertLabel
            item={selectedActivity}
            itemType={ITEM_TYPE}
            personalLabelLoading={labelLoading}
            personalLabelActionList={labelActionDTOList}
            setPersonalLabelActionList={setLabelActionDTOList}
            brandLabelLoading={brandLabelLoading}
            brandLabelActionList={brandLabelActionDTOList}
            setBrandLabelActionList={setBrandLabelActionDTOList}
            publishingHouseLabelLoading={publishingHouseLabelLoading}
            publishingHouseLabelActionList={publishingHouseLabelActionDTOList}
            setPublishingHouseLabelActionList={
              setPublishingHouseLabelActionDTOList
            }
          />
        </div>
      </BaseModal>
    );
  };

  const RenderCreateActivitySelectionModal = () => {
    return (
      <BaseModal
        isOpen={showCreateActivitySelectionModal}
        size="small"
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowCreateActivitySelectionModal(false);
        }}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            <div className="my-10">
              <div className="flex flex-row justify-center items-center gap-x-2">
                {userProfile.role === "STAFF" ||
                userProfile.role === "CONTENT_CREATOR" ? (
                  <div
                    className="w-[250px] flex flex-col items-center gap-y-3 cursor-pointer p-10 border border-catchup-gray-100 hover:border-catchup-blue-500 rounded-catchup-xlarge transition-all duration-300 hover:shadow-input"
                    onClick={() => {
                      navigate("/activities/create/import-from-pdf");
                    }}
                  >
                    <BaseImage src="/icons/pdf.png" alt="pdf" size="large" />
                    <p className="font-semibold text-xl text-catchup-gray-400 text-center">
                      {i18n.t("import_from_pdf")}
                    </p>
                  </div>
                ) : null}

                <div
                  className="w-[250px] flex flex-col items-center gap-y-3 cursor-pointer p-10 border border-catchup-gray-100 hover:border-catchup-blue-500 rounded-catchup-xlarge transition-all duration-300 hover:shadow-input"
                  onClick={() => {
                    handleToCreateActivityOnClick();
                  }}
                >
                  <BaseImage
                    src="/icons/special.png"
                    alt="special"
                    size="large"
                  />
                  <p className="font-semibold text-xl text-catchup-gray-400 text-center">
                    {i18n.t("custom")}
                  </p>
                </div>
              </div>
            </div>
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={i18n.t("activity_list")}
            itemName={i18n.t("activity")}
            description={i18n.t("activity_list_table_description_text")}
            headerList={[
              { name: i18n.t("name"), key: "name", type: "info" },
              {
                name: i18n.t("coterie_type"),
                key: "coterieType",
                type: "enumeration",
              },
              {
                name: i18n.t("level"),
                key: "level",
              },
              {
                name: i18n.t("created_at"),
                key: "createdAt",
                type: "datetime",
              },
            ]}
            selectable={false}
            showFilter={true}
            showItemCount={selectedShowItemCount}
            totalItemCount={totalItemCount}
            pageNumber={pageNumber}
            loading={tableLoading}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleOnFilterChange={handleOnFilterChange}
            handleOnTableParamsChange={handleOnTableParamsChange}
            filterParams={{
              isFilterApplied,
              showBeginDate: true,
              beginAt: filterBeginAt,
              showEndDate: true,
              endAt: filterEndAt,
              showTextContains: true,
              textContains: filterTextContains,
              showDeleted: filterShowDeleted,
              showCoterieType: true,
              mainCoterieType: userProfile.coterieType
                ? userProfile.coterieType
                : null,
              coterieType: filterCoterieType,
              showLevel: true,
              level: filterLevel,
              showCategoryCode: true,
              categoryCode: filterCategoryCode,
              subCategoryCodeList: retrieveSubCategoryCodeList(),
              subCategoryCode: filterSubCategoryCode,
              outcomeCodeList: retrieveOutcomeCodeList(),
              outcomeCode: filterOutcomeCode,
            }}
            itemList={activityDTOList}
            labelable={true}
            showLabelList={true}
            labelActionList={constructLabelActionList()}
            handleLabelOnClick={handleToLabelActivityOnClick}
            insertable={checkInsertPermissionGranted("activity_list")}
            handleInsertOnClick={() => {
              setShowCreateActivitySelectionModal(true);
            }}
            updatable={checkUpdatePermissionGranted("activity_list")}
            handleUpdateOnClick={handleToUpdateActivityOnClick}
            deletable={checkDeletePermissionGranted("activity_list")}
            previewable={true}
            handlePreviewOnClick={handlePreviewActivityOnClick}
            handleDeleteOnClick={handleRemoveActivityOnClick}
            navigatable={checkCanNavigate()}
            navigateOptionList={constructNavigatableList()}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <CreateActivity
            isShadowed={true}
            showHeader={true}
            existingActivity={existingActivity}
            handleGoBackOnClick={handleReturnFromActivityOnClick}
            handleAfterUpdateActivityAction={handleAfterUpdateActivityAction}
            handleAfterCreateActivityAction={handleAfterCreateActivityAction}
            handleSameActivityUpdatedBySomeoneAction={
              handleSameActivityUpdatedBySomeoneAction
            }
          />
        </div>
      </div>
    );
  };

  const pagedQueryParams = constructPagedQueryParams();

  return (
    <div className="flex-1 flex flex-col" id="activity_main">
      {RenderLabelModal()}
      {RenderCreateActivitySelectionModal()}
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ActivityListScreen;
