import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Server
 */
export const checkTokenService = async () => {
  try {
    const response = await axios
      .create()
      .get(
        `${connection.base.token.url}${endpoints.tokens.context}${endpoints.tokens.actuator.health.context}`
      );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Token Purchase
 */
export const createTokenPurchase = async (tokenPurchase) => {
  try {
    const response = await axios.post(
      `${connection.base.token.url}${endpoints.tokens.purchases.context}/`,
      tokenPurchase
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchTokenPurchase = async (patchedTokenPurchase) => {
  try {
    const response = await axios.patch(
      `${connection.base.token.url}${endpoints.tokens.purchases.context}/`,
      patchedTokenPurchase
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryTokenPurchaseDTOListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.token.url}${endpoints.tokens.purchases.context}${endpoints.tokens.purchases.query.dto["by-params"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Token Assignments
 */
export const createTokenAssignment = async (tokenAssignment) => {
  try {
    const response = await axios.post(
      `${connection.base.token.url}${endpoints.tokens.assignments.context}/`,
      tokenAssignment
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryTokenAssignmentDTOListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.token.url}${endpoints.tokens.assignments.context}${endpoints.tokens.assignments.query.dto["by-params"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Token Usages
 */
export const queryTokenUsageDTOListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.token.url}${endpoints.tokens.usages.context}${endpoints.tokens.usages.query.dto["by-params"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
