import { useEffect, useState } from "react";
import AdvancedTable from "../../components/tables/AdvancedTable";
import {
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import { useApp } from "../../context/AppContextProvider";
import { useNavigate } from "react-router-dom";
import i18n from "../../language/i18n";
import FullCard from "../../components/cards/FullCard";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import DividerLine from "../../components/divider/DividerLine";
import {
  queryCampusListByBrandId,
  queryInstitutionListByCampusId,
} from "../../requests/ManagementRequests";
import { useAuth } from "../../context/UserContextProvider";
import InputGroup from "../../components/groups/InputGroup";
import {
  createTokenPurchase,
  patchTokenPurchase,
  queryTokenPurchaseDTOListByParams,
} from "../../requests/TokenRequests";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import { NotificationManager } from "react-notifications";
import { retrieveTokenUsageSubTypeOptionList } from "../../utilization/TokenUtilization";
import CreateButton from "../../components/buttons/CreateButton";
import {
  retrieveCampusDTOOptionList,
  retrieveInstitutionDTOOptionList,
} from "../../utilization/ManagementUtilization";

const PurchaseTokenListScreen = (props) => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const { userProfileBrand, userProfileCampus, userProfileInstitution } =
    useAuth();
  const [pageState, setPageState] = useState(0);
  const [id, setId] = useState(null);
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [tokenPurchaseDTOList, setTokenPurchaseDTOList] = useState([]);
  const [selectedType, setSelectedType] = useState("AI");
  const [selectedSubType, setSelectedSubType] = useState(
    "ACTIVITY_TEMPLATE_GENERATION"
  );
  const [campusDTOList, setCampusDTOList] = useState([]);
  const [selectedCampusId, setSelectedCampusId] = useState("DEFAULT_OPTION");
  const [institutionDTOList, setInstitutionDTOList] = useState([]);
  const [selectedInstitutionId, setSelectedInstitutionId] =
    useState("DEFAULT_OPTION");
  const [beginAt, setBeginAt] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endAt, setEndAt] = useState(new Date().toISOString().split("T")[0]);
  const [amount, setAmount] = useState(1000);
  const [errorMap, setErrorMap] = useState({
    beginAt: null,
    endAt: null,
    amount: null,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("token_purchase_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    if (userProfileCampus) {
      setCampusDTOList([userProfileCampus]);
    } else {
      retrieveAndSetCampusDTOListByBrandId();
    }
  }, []);

  useEffect(() => {
    if (selectedCampusId === "DEFAULT_OPTION") {
      setSelectedInstitutionId("DEFAULT_OPTION");
    } else {
      if (userProfileInstitution) {
        setInstitutionDTOList([userProfileInstitution]);
      } else {
        retrieveAndSetInstitutionDTOListByCampusId();
      }
    }
  }, [selectedCampusId]);

  useEffect(() => {
    if (selectedSubType === "DEFAULT_OPTION") return;
    retrieveAndSetTokenPurchaseList();
  }, [selectedSubType, selectedCampusId, selectedInstitutionId]);

  const retrieveAndSetCampusDTOListByBrandId = async () => {
    const { data, err } = await queryCampusListByBrandId(userProfileBrand.id);
    if (err) {
      console.log(err);
    } else {
      setCampusDTOList(data);
    }
  };

  const retrieveAndSetInstitutionDTOListByCampusId = async () => {
    const { data, err } = await queryInstitutionListByCampusId(
      selectedCampusId
    );
    if (err) {
      console.log(err);
    } else {
      setInstitutionDTOList(data);
    }
  };

  const retrieveAndSetTokenPurchaseList = async () => {
    const queryParams = {
      brandId: userProfileBrand.id,
      type: selectedType,
      subType: selectedSubType,
    };
    if (selectedCampusId !== "DEFAULT_OPTION") {
      queryParams.campusId = selectedCampusId;
      if (selectedInstitutionId !== "DEFAULT_OPTION") {
        queryParams.institutionId = selectedInstitutionId;
      }
    }
    const { data, err } = await queryTokenPurchaseDTOListByParams(queryParams);
    if (err) {
      console.log(err);
    } else {
      setTokenPurchaseDTOList(data);
    }
  };

  const filterTokenUsageSubTypeOptionList = () => {
    const tokenSubTypeOptionList = retrieveTokenUsageSubTypeOptionList();
    return tokenSubTypeOptionList.filter(
      (item) => item.value === "ACTIVITY_TEMPLATE_GENERATION"
    );
  };

  const constructCampusName = () => {
    return campusDTOList.find((campusDTO) => campusDTO.id === selectedCampusId)
      .name;
  };

  const constructInstitutionName = () => {
    return institutionDTOList.find(
      (institutionDTO) => institutionDTO.id === selectedInstitutionId
    ).name;
  };

  const checkCommonValidation = () => {
    if (new Date(beginAt).getTime() >= new Date(endAt).getTime() || !amount) {
      if (new Date(beginAt).getTime() >= new Date(endAt).getTime()) {
        NotificationManager.error(
          i18n.t("begin_at_cannot_be_greater_than_end_at_text")
        );
      }
      const currentErrorMap = {
        amount: null,
      };
      if (!amount) {
        currentErrorMap.amount = i18n.t("amount_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const resetParams = () => {
    setId(null);
    setBeginAt(new Date().toISOString().split("T")[0]);
    setEndAt(new Date().toISOString().split("T")[0]);
    setAmount(1000);
  };

  const handleOnChange = (key, value) => {
    if (key === "campusId") {
      setSelectedCampusId(value);
    } else if (key === "institutionId") {
      setSelectedInstitutionId(value);
    } else if (key === "beginAt") {
      setBeginAt(value);
    } else if (key === "endAt") {
      setEndAt(value);
    } else if (key === "subType") {
      setSelectedSubType(value);
    } else if (key === "amount") {
      if (value < 0) {
        setAmount(0);
      } else {
        setAmount(value);
      }
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        amount: null,
      }));
    }
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleReturnFromPurchaseTokenOnClick = () => {
    resetParams();
    setPageState(0);
  };

  const handleCreateTokenPurchaseOnClick = async () => {
    if (checkInsertPermissionGranted("token_purchase_list")) {
      if (checkCommonValidation()) {
        const newTokenPurchase = {
          brandId: userProfileBrand.id,
          amount,
          beginAt: new Date(beginAt).getTime(),
          endAt: new Date(endAt).getTime(),
          type: selectedType,
          subType: selectedSubType,
        };
        if (selectedCampusId !== "DEFAULT_OPTION") {
          newTokenPurchase.campusId = selectedCampusId;
          if (selectedInstitutionId !== "DEFAULT_OPTION") {
            newTokenPurchase.institutionId = selectedInstitutionId;
          }
        }
        const { data, err } = await createTokenPurchase(newTokenPurchase);
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          retrieveAndSetTokenPurchaseList();
          handleReturnFromPurchaseTokenOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleUpdateTokenPurchaseOnClick = async () => {
    if (checkUpdatePermissionGranted("token_purchase_list")) {
      if (checkCommonValidation()) {
        const patchedTokenPurchase = {
          id,
          amount,
          beginAt: new Date(beginAt).getTime(),
          endAt: new Date(endAt).getTime(),
        };
        const { data, err } = await patchTokenPurchase(patchedTokenPurchase);
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          retrieveAndSetTokenPurchaseList();
          handleReturnFromPurchaseTokenOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleToCreateTokenPurchaseOnClick = () => {
    setPageState(1);
  };

  const handleToUpdateTokenPurchaseOnClick = (tokenPurchase) => {
    setId(tokenPurchase.id);
    setAmount(tokenPurchase.amount);
    setBeginAt(new Date(tokenPurchase.beginAt).toISOString().split("T")[0]);
    setEndAt(new Date(tokenPurchase.endAt).toISOString().split("T")[0]);
    setPageState(1);
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <FullCard>
            <div className="flex flex-row items-center flex-wrap">
              <div className="w-1/2 lg:w-1/3">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("sub_type")}
                    placeholder={i18n.t("sub_type")}
                    value={selectedSubType}
                    optionList={filterTokenUsageSubTypeOptionList()}
                    onChange={(event) => {
                      handleOnChange("subType", event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="w-1/2 lg:w-1/3">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("campus")}
                    placeholder={i18n.t("campus")}
                    value={selectedCampusId}
                    optionList={retrieveCampusDTOOptionList(campusDTOList)}
                    onChange={(event) => {
                      handleOnChange("campusId", event.target.value);
                    }}
                  />
                </div>
              </div>
              {selectedCampusId !== "DEFAULT_OPTION" ? (
                <div className="w-1/2 lg:w-1/3">
                  <div className="mx-2">
                    <InputGroup
                      type="select"
                      title={i18n.t("institution")}
                      placeholder={i18n.t("institution")}
                      value={selectedInstitutionId}
                      optionList={retrieveInstitutionDTOOptionList(
                        institutionDTOList
                      )}
                      onChange={(event) => {
                        handleOnChange("institutionId", event.target.value);
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            {selectedSubType !== "DEFAULT_OPTION" ? (
              <AdvancedTable
                title={i18n.t("token_purchase_list")}
                description={i18n.t(
                  "token_purchase_list_table_description_text"
                )}
                itemName={i18n.t("token_purchase")}
                headerList={[
                  {
                    name: i18n.t("begin_at"),
                    key: "beginAt",
                    type: "datetime",
                  },
                  { name: i18n.t("end_at"), key: "endAt", type: "datetime" },
                  { name: i18n.t("amount"), key: "amount" },
                  {
                    name: i18n.t("created_at"),
                    key: "createdAt",
                    type: "datetime",
                  },
                ]}
                selectable={false}
                showFilter={true}
                showItemCount={selectedShowItemCount}
                totalItemCount={totalItemCount}
                pageNumber={pageNumber}
                loading={tableLoading}
                sortKey={sortKey}
                sortDirection={sortDirection}
                handleOnFilterChange={handleOnFilterChange}
                handleOnTableParamsChange={handleOnTableParamsChange}
                filterParams={{
                  isFilterApplied,
                  showBeginDate: true,
                  beginAt: filterBeginAt,
                  showEndDate: true,
                  endAt: filterEndAt,
                  showDeleted: filterShowDeleted,
                }}
                itemList={tokenPurchaseDTOList}
                insertable={checkInsertPermissionGranted("token_purchase_list")}
                handleInsertOnClick={handleToCreateTokenPurchaseOnClick}
                updatable={checkUpdatePermissionGranted("token_purchase_list")}
                handleUpdateOnClick={handleToUpdateTokenPurchaseOnClick}
                deletable={false}
                navigatable={false}
              />
            ) : null}
          </FullCard>
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <FullCard isShadowed={true}>
            <div className="">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      handleReturnFromPurchaseTokenOnClick();
                    }}
                  />
                </div>
                <BaseTitle
                  title={
                    id
                      ? i18n.t("update_token_purchase")
                      : i18n.t("create_token_purchase")
                  }
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <DividerLine />
              <div className="w-full">
                <p className="font-semibold text-lg">
                  {i18n.t("token_purchase_information")}
                </p>
              </div>
              <div className="w-full my-5">
                <p>
                  {i18n.t("brand_name")}:{" "}
                  <span className="font-semibold">{userProfileBrand.name}</span>
                </p>
                {selectedCampusId !== "DEFAULT_OPTION" ? (
                  <p>
                    {i18n.t("campus_name")}:{" "}
                    <span className="font-semibold">
                      {constructCampusName()}
                    </span>
                  </p>
                ) : null}
                {selectedInstitutionId !== "DEFAULT_OPTION" ? (
                  <p>
                    {i18n.t("institution_name")}:{" "}
                    <span className="font-semibold">
                      {constructInstitutionName()}
                    </span>
                  </p>
                ) : null}
                <p>
                  {i18n.t("sub_type")}:{" "}
                  <span className="font-semibold">
                    {i18n.t(selectedSubType)}
                  </span>
                </p>
              </div>

              <div className="w-full flex flex-row flex-wrap gap-x-4">
                <div className="flex-1 flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("begin_at")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="date"
                      value={beginAt}
                      onChange={(e) => {
                        handleOnChange("beginAt", e.target.value);
                      }}
                      errorText={errorMap.beginAt}
                    />
                  </div>
                </div>
                <div className="flex-1 flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("end_at")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="date"
                      value={endAt}
                      onChange={(e) => {
                        handleOnChange("endAt", e.target.value);
                      }}
                      errorText={errorMap.endAt}
                    />
                  </div>
                </div>
                <div className="flex-1 flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("amount")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="number"
                      value={amount}
                      onChange={(e) => {
                        handleOnChange("amount", e.target.value);
                      }}
                      errorText={errorMap.amount}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 ml-auto">
                {id ? (
                  <SecondaryButton
                    title={i18n.t("update")}
                    size="medium"
                    onClick={handleUpdateTokenPurchaseOnClick}
                  />
                ) : (
                  <CreateButton
                    title={i18n.t("create")}
                    size="medium"
                    onClick={handleCreateTokenPurchaseOnClick}
                  />
                )}
              </div>
            </div>
          </FullCard>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default PurchaseTokenListScreen;
