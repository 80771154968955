const FullCard = (props) => {
  const { bgColor, opacity, isShadowed, usePadding } = props;

  return (
    <div className="flex flex-col justify-center items-center h-full">
      <div
        className={`flex-1 ${bgColor ? bgColor : ""} ${
          opacity ? opacity : "opacity-100"
        } rounded-catchup-xlarge w-full border border-catchup-gray-50 bg-catchup-white`}
        // style={{
        //   boxShadow: isShadowed
        //     ? "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"
        //     : "none",
        // }}
      >
        <div
          className={`flex flex-col mx-auto ${
            usePadding === false ? "p-0" : "p-6"
          } h-full`}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default FullCard;
