import { useCallback, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import ListActivity from "../../components/activity/ListActivity";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import FullCard from "../../components/cards/FullCard";
import InputGroup from "../../components/groups/InputGroup";
import AdvancedTable from "../../components/tables/AdvancedTable";
import { useApp } from "../../context/AppContextProvider";
import i18n from "../../language/i18n";
import {
  createActivityBundle,
  deleteActivityBundle,
  patchActivityBundle,
  queryAllActivityBundleDTOPage,
} from "../../requests/BundleRequests";
import { queryActivityDTOListByIdList } from "../../requests/CatchtivityRequests";
import {
  checkDeletePermissionGranted,
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import { useAuth } from "../../context/UserContextProvider";
import {
  filterCoterieTypeOptionList,
  retrieveGradeLevelOptionList,
} from "../../utilization/ManagementUtilization";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import debounce from "lodash.debounce";
import CreateButton from "../../components/buttons/CreateButton";
import SelectionTab from "../../components/tabs/SelectionTab";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const BundleActivityListScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileInstitution,
    userProfileGrade,
  } = useAuth();
  const [pageState, setPageState] = useState(0);
  const [id, setId] = useState();
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");
  const [selectedLevel, setSelectedLevel] = useState("DEFAULT_OPTION");
  const [innerPageState, setInnerPageState] = useState(0);
  const [activityBundleDTOList, setActivityBundleDTOList] = useState([]);
  const [selectedActivityList, setSelectedActivityList] = useState([]);
  const [selectedActivityListReady, setSelectedActivityListReady] =
    useState(false);
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterTextContains, setFilterTextContains] = useState(null);
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [debounceState, setDebounceState] = useState("");
  const [errorMap, setErrorMap] = useState({
    name: null,
    description: null,
    activityIdList: null,
    coterieType: null,
    level: null,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("bundle_activity_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetActivityListByIdList = async () => {
      setSelectedActivityListReady(false);
      const { data, err } = await queryActivityDTOListByIdList(
        selectedActivityList.map((selectedActivity) => selectedActivity.id)
      );
      if (err) {
        console.log(err);
        return;
      }
      setSelectedActivityList(data);
      setSelectedActivityListReady(true);
    };
    if (id != null && pageState === 1) {
      retrieveAndSetActivityListByIdList();
    }
  }, [id, pageState]);

  useEffect(() => {
    retrieveAndSetActivityBundlePageByFilter();
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    setDebounceState("BASE_FILTER");
    retrieveAndSetActivityBundlePageByFilter();
  }, [isFilterApplied, filterBeginAt, filterEndAt, filterShowDeleted]);

  useEffect(() => {
    if (!isFilterApplied) return;
    if (filterTextContains) {
      setDebounceState("TEXT_CONTAINS");
      debouncedRetrieveAndSetActivityBundlePageByFilterDebounce(
        filterTextContains
      );
    } else {
      retrieveAndSetActivityBundlePageByFilter();
    }
  }, [filterTextContains]);

  const retrieveAndSetActivityBundlePageByFilter = async () => {
    setTableLoading(true);
    const { data, err } = await queryAllActivityBundleDTOPage(pagedQueryParams);
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setActivityBundleDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const retrieveAndSetActivityBundlePageByFilterDebounce = async (
    filterTextContains
  ) => {
    setTableLoading(true);
    if (isFilterApplied) {
      pagedQueryParams.params.textContains = filterTextContains.trim();
    }
    const { data, err } = await queryAllActivityBundleDTOPage(pagedQueryParams);
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setActivityBundleDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const debouncedRetrieveAndSetActivityBundlePageByFilterDebounce = useCallback(
    debounce(function (textContains) {
      retrieveAndSetActivityBundlePageByFilterDebounce(textContains);
    }, 1000),
    [debounceState]
  );

  const checkCommonValidation = () => {
    if (
      !name ||
      name.trim() === "" ||
      !description ||
      description.trim() === "" ||
      selectedActivityList.length === 0
    ) {
      const currentErrorMap = {
        name: null,
        description: null,
        activityIdList: null,
      };
      if (!name || name.trim() === "") {
        currentErrorMap.name = i18n.t("name_required_field");
      }
      if (!description || description.trim() === "") {
        currentErrorMap.description = i18n.t("description_required_field");
      }
      if (selectedActivityList.length === 0) {
        currentErrorMap.activityIdList = i18n.t("activity_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const checkCanNavigate = () => {
    return false;
  };

  const constructNavigatableList = () => {
    return [];
  };

  const constructPagedQueryParams = () => {
    return {
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        beginAt: isFilterApplied ? new Date(filterBeginAt).getTime() : null,
        endAt: isFilterApplied ? new Date(filterEndAt).getTime() : null,
        textContains:
          isFilterApplied && filterTextContains ? filterTextContains : null,
        deleted: isFilterApplied ? (filterShowDeleted ? null : false) : false,
        brandId: userProfileBrand.id,
      },
    };
  };

  const resetParams = () => {
    setId(null);
    setName(null);
    setDescription(null);
    setSelectedCoterieType("DEFAULT_OPTION");
    setSelectedLevel("DEFAULT_OPTION");
    setSelectedActivityList([]);
    setSelectedActivityListReady(false);
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    } else if (type === "textContains") {
      setFilterTextContains(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleOnChange = (type, value) => {
    if (type === "name") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: i18n.t("name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: null,
        }));
      }
      setName(value);
    } else if (type === "description") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: i18n.t("description_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: null,
        }));
      }
      setDescription(value);
    } else if (type === "coterieType") {
      setSelectedCoterieType(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        coterieType: null,
      }));
    } else if (type === "level") {
      setSelectedLevel(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        level: null,
      }));
    } else {
    }
  };

  const handleReturnFromBundleActivityOnClick = () => {
    resetParams();
    setPageState(0);
  };

  const handleToCreateActivityBundleOnClick = () => {
    setId(null);
    setSelectedCoterieType(
      userProfile.coterieType ? userProfile.coterieType : "DEFAULT_OPTION"
    );
    if (userProfileGrade) {
      setSelectedLevel(userProfileGrade.level);
    }
    setPageState(1);
    setInnerPageState(0);
  };

  const handleToUpdateActivityBundleOnClick = (activityBundleDTO) => {
    setId(activityBundleDTO.id);
    setName(activityBundleDTO.name);
    setDescription(activityBundleDTO.description);
    setSelectedCoterieType(activityBundleDTO.coterieType);
    setSelectedLevel(activityBundleDTO.level);
    setSelectedActivityList(
      activityBundleDTO.activityIdList.map((activityId) => ({
        id: activityId,
      }))
    );
    setPageState(1);
  };

  const handleAddActivityToActivityBundle = (item) => {
    const foundIndex = selectedActivityList.findIndex(
      (selectedActivity) =>
        parseFloat(selectedActivity.id) === parseFloat(item.id)
    );
    if (foundIndex === -1) {
      selectedActivityList.push({
        id: item.id,
        name: item.name,
        categoryIdList: item.categoryIdList,
        // activityTemplateDTOList: item.activityTemplateDTOList,
        description: item.description,
        coterieType: item.coterieType,
        status: item.status,
        createdAt: item.createdAt,
      });
    } else {
      selectedActivityList.splice(foundIndex, 1);
    }
    setSelectedActivityList(JSON.parse(JSON.stringify(selectedActivityList)));
  };

  const handleRemoveActivityBundleOnClick = async (activityBundleId) => {
    if (checkDeletePermissionGranted("bundle_activity_list")) {
      const { data, err } = await deleteActivityBundle(activityBundleId);
      if (err) {
        if (err.response.data) {
          NotificationManager.error(i18n.t(err.response.data));
        }
      } else {
        const foundIndex = activityBundleDTOList.findIndex(
          (catchtivityDTO) => catchtivityDTO.id === activityBundleId
        );
        activityBundleDTOList.splice(foundIndex, 1);
        setActivityBundleDTOList(
          JSON.parse(JSON.stringify(activityBundleDTOList))
        );
      }
    }
  };

  const handleUpdateActivityBundleOnClick = async () => {
    if (checkUpdatePermissionGranted("bundle_activity_list")) {
      if (checkCommonValidation()) {
        const currentActivityBundle = {
          id,
          name,
          description,
          coterieType: selectedCoterieType,
          level: selectedLevel,
          activityIdList: selectedActivityList.map(
            (selectedActivity) => selectedActivity.id
          ),
        };
        const { data, err } = await patchActivityBundle(currentActivityBundle);
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          retrieveAndSetActivityBundlePageByFilter();
          handleReturnFromBundleActivityOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleCreateActivityBundleOnClick = async () => {
    if (checkInsertPermissionGranted("bundle_activity_list")) {
      if (checkCommonValidation()) {
        const newActivityBundle = {
          name,
          description,
          userId: userInformation.id,
          userProfileId: userProfile.id,
          brandId: userProfileBrand.id,
          brandName: userProfileBrand.name,
          coterieType: selectedCoterieType,
          level: selectedLevel,
          activityIdSet: selectedActivityList.map(
            (selectedActivity) => selectedActivity.id
          ),
        };
        const { data, err } = await createActivityBundle(newActivityBundle);
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          retrieveAndSetActivityBundlePageByFilter();
          handleReturnFromBundleActivityOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handlePreviewActivityOnClick = (activityDTO) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    window.open(
      `${connection.app.url}/preview/activity/${activityDTO.id}/${btoa(
        encodeURI(JSON.stringify({ token: accessToken }))
      )}`
    );
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={i18n.t("bundle_activity_list")}
            description={i18n.t("bundle_activity_list_table_description_text")}
            itemName={i18n.t("bundle_activity")}
            headerList={[
              { name: i18n.t("name"), key: "name", type: "info" },
              {
                name: i18n.t("coterie_type"),
                key: "coterieType",
                type: "enumeration",
              },
              {
                name: i18n.t("level"),
                key: "level",
              },
              {
                name: i18n.t("activity_count"),
                key: "count",
              },
              {
                name: i18n.t("created_at"),
                key: "createdAt",
                type: "datetime",
              },
            ]}
            selectable={false}
            showFilter={true}
            showItemCount={selectedShowItemCount}
            totalItemCount={totalItemCount}
            pageNumber={pageNumber}
            loading={tableLoading}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleOnFilterChange={handleOnFilterChange}
            handleOnTableParamsChange={handleOnTableParamsChange}
            filterParams={{
              isFilterApplied,
              showBeginDate: true,
              beginAt: filterBeginAt,
              showEndDate: true,
              endAt: filterEndAt,
              showTextContains: false,
              textContains: filterTextContains,
              showDeleted: filterShowDeleted,
            }}
            itemList={activityBundleDTOList}
            insertable={checkInsertPermissionGranted("bundle_activity_list")}
            handleInsertOnClick={handleToCreateActivityBundleOnClick}
            updatable={checkUpdatePermissionGranted("bundle_activity_list")}
            handleUpdateOnClick={handleToUpdateActivityBundleOnClick}
            deletable={checkDeletePermissionGranted("bundle_activity_list")}
            handleDeleteOnClick={handleRemoveActivityBundleOnClick}
            navigatable={checkCanNavigate()}
            navigateOptionList={constructNavigatableList()}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <FullCard isShadowed={true}>
            <div className="">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      handleReturnFromBundleActivityOnClick();
                    }}
                  />
                </div>
                <BaseTitle
                  title={
                    id
                      ? i18n.t("update_bundle_activity")
                      : i18n.t("create_bundle_activity")
                  }
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <DividerLine />
              <div className="w-full">
                <p className="font-semibold text-lg">
                  {i18n.t("bundle_activity_information")}
                </p>
              </div>
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={name}
                    onChange={(e) => {
                      handleOnChange("name", e.target.value);
                    }}
                    errorText={errorMap.name}
                  />
                </div>
              </div>
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("description")}</p>
                </div>
                <div className="flex-1 h-catchup-description">
                  <InputGroup
                    type="textarea"
                    value={description}
                    onChange={(e) => {
                      handleOnChange("description", e.target.value);
                    }}
                    errorText={errorMap.description}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row items-center">
                <div className="w-catchup-input-group-title py-5"></div>
                <div className="flex-[1] flex flex-row">
                  <div className="w-full">
                    <div className="mx-2">
                      <InputGroup
                        type="select"
                        title={i18n.t("coterie_type")}
                        placeholder={null}
                        value={selectedCoterieType}
                        optionList={filterCoterieTypeOptionList(
                          userInformation,
                          userProfile,
                          userProfileInstitution
                        )}
                        onChange={(event) => {
                          handleOnChange("coterieType", event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-[1] flex flex-row">
                  <div className="w-full">
                    <div className="mx-2">
                      <InputGroup
                        type="select"
                        title={i18n.t("level")}
                        placeholder={null}
                        value={selectedLevel}
                        optionList={retrieveGradeLevelOptionList()}
                        onChange={(event) => {
                          handleOnChange("level", event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {selectedCoterieType !== "DEFAULT_OPTION" &&
              selectedCoterieType !== "MANAGEMENT" &&
              selectedLevel !== "DEFAULT_OPTION" ? (
                <>
                  <DividerLine />
                  <div className="w-full my-2">
                    <SelectionTab
                      optionList={[
                        {
                          id: 0,
                          title: i18n.t("activity_list"),
                        },
                      ]}
                      selectedId={innerPageState}
                      handleSelectOnClick={(selectedId) => {
                        setInnerPageState(selectedId);
                      }}
                    />
                  </div>

                  <div className="w-full">
                    <div
                      className={`${innerPageState === 0 ? "block" : "hidden"}`}
                    >
                      <ListActivity
                        showTitle={false}
                        usePadding={false}
                        initialFilterApplied={true}
                        initialCoterieType={selectedCoterieType}
                        showCoterieType={false}
                        initialLevel={selectedLevel}
                        showLevel={false}
                        showDeleted={false}
                        labelable={true}
                        showLabelList={true}
                        selectable={true}
                        previewable={true}
                        handlePreviewOnClick={handlePreviewActivityOnClick}
                        selectedItemIdList={selectedActivityList.map(
                          (selectedActivity) => selectedActivity.id
                        )}
                        handleSelectOnClick={handleAddActivityToActivityBundle}
                      />
                    </div>
                  </div>

                  <div className="mt-4 ml-auto">
                    {id ? (
                      <SecondaryButton
                        title={i18n.t("update")}
                        size="medium"
                        onClick={handleUpdateActivityBundleOnClick}
                      />
                    ) : (
                      <CreateButton
                        title={i18n.t("create")}
                        size="medium"
                        onClick={handleCreateActivityBundleOnClick}
                      />
                    )}
                  </div>
                </>
              ) : null}
            </div>
          </FullCard>
        </div>
      </div>
    );
  };

  const pagedQueryParams = constructPagedQueryParams();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default BundleActivityListScreen;
