import { Bar, Doughnut, Radar } from "react-chartjs-2";
import i18n from "../../language/i18n";
import DividerLine from "../divider/DividerLine";
import FullCard from "../cards/FullCard";
import BaseModal from "../modal/BaseModal";
import BaseLoading from "../loading/BaseLoading";

const LearnerPerformanceGraphicalData = (props) => {
  const {
    learnerPerformance,
    selectedCoterieType,
    showLearner,
    individualModelList,
    individualModelLoading,
    showIndividualModel,
    setShowIndividualModel,
  } = props;

  if (!learnerPerformance) return;

  const filterCatchxamApplicationDTOList = () => {
    const { catchxamApplicationPerformanceDTOList } = learnerPerformance;
    const filteredCatchxamApplicationPerformanceDTOList =
      catchxamApplicationPerformanceDTOList.filter(
        (item) => item.catchxamApplicationDTO.isEvaluated
      );
    return filteredCatchxamApplicationPerformanceDTOList;
  };

  const filterCatchxamApplicationDTOListByCoterieType = () => {
    if (selectedCoterieType === "DEFAULT_OPTION") {
      return filteredCatchxamApplicationDTOList;
    } else {
      return filteredCatchxamApplicationDTOList.filter(
        (item) =>
          item.catchxamApplicationDTO.catchxamDTO.coterieType ===
          selectedCoterieType
      );
    }
  };

  const filterLearnerCatchxamApplicationPerformanceDTOListByCoterieType =
    () => {
      if (selectedCoterieType === "DEFAULT_OPTION") {
        return filteredCatchxamApplicationDTOList;
      } else {
        return filteredCatchxamApplicationDTOList.filter(
          (catchxamApplicationPerformanceDTO) =>
            catchxamApplicationPerformanceDTO.coterieType ===
            selectedCoterieType
        );
      }
    };

  const constructBarLabels = () => {
    return filteredLearnerCatchxamApplicationPerformanceDTOListByCoterieType.map(
      (catchxamApplicationPerformance) => {
        const { catchxamApplicationDTO } = catchxamApplicationPerformance;
        const { id } = catchxamApplicationDTO;
        const foundCatchxamApplication =
          filteredCatchxamApplicationDTOListByCoterieType.find(
            (item) => item.catchxamApplicationDTO.id === id
          );
        if (foundCatchxamApplication) {
          return foundCatchxamApplication.catchxamApplicationDTO.catchxamDTO
            .name;
        }
      }
    );
  };

  const constructBarData = () => {
    const barData = [];
    const learnerScoreList = [];
    const branchScoreList = [];
    const gradeScoreList = [];
    // const seasonScoreList = [];
    filteredLearnerCatchxamApplicationPerformanceDTOListByCoterieType.forEach(
      (catchxamApplicationPerformance) => {
        const {
          catchxamApplicationDTO,
          learnerScore,
          branchScore,
          gradeScore,
          // seasonScore,
        } = catchxamApplicationPerformance;
        const { id } = catchxamApplicationDTO;
        if (showLearner) {
          learnerScoreList.push({
            score: learnerScore * 100,
            stack: `${id}-learner`,
          });
        }

        branchScoreList.push({
          score: branchScore * 100,
          stack: `${id}-branch`,
        });
        gradeScoreList.push({
          score: gradeScore * 100,
          stack: `${id}-grade`,
        });
        // seasonScoreList.push({
        //   score: seasonScore * 100,
        //   stack: `${id}-season`,
        // });
      }
    );
    if (showLearner) {
      barData.push({
        label: i18n.t("learner_score"),
        data: learnerScoreList.map((learnerScore) => learnerScore.score),
        backgroundColor: "rgba(87, 194, 211, 0.6)",
        stack: `0`,
      });
    }

    barData.push({
      label: i18n.t("branch_score"),
      data: branchScoreList.map((branchScore) => branchScore.score),
      backgroundColor: "rgba(255, 99, 132, 0.6)",
      stack: `1`,
    });
    barData.push({
      label: i18n.t("grade_score"),
      data: gradeScoreList.map((gradeScore) => gradeScore.score),
      backgroundColor: "rgba(153, 102, 255, 0.6)",
      stack: `2`,
    });
    // barData.push({
    //   label: i18n.t("season_score"),
    //   data: seasonScoreList.map((seasonScore) => seasonScore.score),
    //   backgroundColor: "rgba(255, 206, 86, 0.6)",
    //   stack: `3`,
    // });
    return barData;
  };

  const constructRadarTaxonomyLabels = () => {
    return [
      i18n.t("REMEMBER"),
      i18n.t("UNDERSTAND"),
      i18n.t("APPLY"),
      i18n.t("ANALYZE"),
      i18n.t("EVALUATE"),
      i18n.t("CREATE"),
    ];
  };

  const constructRadarActivityTemplateLabels = () => {
    return [
      i18n.t("ORDERING"),
      i18n.t("DROPDOWN"),
      i18n.t("MCSA"),
      i18n.t("MCMA"),
      i18n.t("MATCHING"),
      i18n.t("GROUPING"),
      i18n.t("FILL_IN_THE_BLANKS"),
      i18n.t("OPEN_ENDED"),
      i18n.t("TRUE_FALSE"),
    ];
  };

  const constructRadarTaxonomyData = (coterieField) => {
    const individualModel = individualModelList.find(
      (item) => item.coterieField === coterieField
    );
    if (!individualModel) return;
    return {
      labels: constructRadarTaxonomyLabels(),
      datasets: [
        {
          label: i18n.t(`${coterieField.toLowerCase()}_taxonomy_tendency`),
          data: [
            individualModel.bloomBloomRemember,
            individualModel.bloomBloomUnderstand,
            individualModel.bloomBloomApply,
            individualModel.bloomBloomAnalyze,
            individualModel.bloomBloomEvaluate,
            individualModel.bloomBloomCreate,
          ],
          backgroundColor:
            coterieField === "NUMERIC"
              ? "rgba(17, 72, 89, 0.3)"
              : "rgba(87, 194, 211, 0.3)",
          borderColor:
            coterieField === "NUMERIC"
              ? "rgba(17, 72, 89, 1)"
              : "rgba(87, 194, 211, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  const constructRadarActivityTemplateData = (coterieField) => {
    const individualModel = individualModelList.find(
      (item) => item.coterieField === coterieField
    );
    if (!individualModel) return;
    return {
      labels: constructRadarActivityTemplateLabels(),
      datasets: [
        {
          label: i18n.t(
            `${coterieField.toLowerCase()}_activity_template_tendency`
          ),
          data: [
            individualModel.ordering,
            individualModel.dropdown,
            individualModel.mcsa,
            individualModel.mcma,
            individualModel.matching,
            individualModel.grouping,
            individualModel.fillInTheBlanks,
            individualModel.openEnded,
            individualModel.trueFalse,
          ],
          backgroundColor:
            coterieField === "NUMERIC"
              ? "rgba(17, 72, 89, 0.3)"
              : "rgba(87, 194, 211, 0.3)",
          borderColor:
            coterieField === "NUMERIC"
              ? "rgba(17, 72, 89, 1)"
              : "rgba(87, 194, 211, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  const constructRadarOptions = () => {
    return {
      scales: {
        r: {
          angleLines: {
            display: true,
          },
          pointLabels: {
            display: true, // Hides the labels around the radar chart
          },
          ticks: {
            display: false, // Hides the labels in the middel (numbers)
          },
          suggestedMin: 0,
          suggestedMax: 1,
        },
      },
    };
  };

  const RenderSingleDoughnotHeader = (title, background) => {
    return (
      <div className="flex flex-row items-center gap-x-2">
        <div className="w-5 h-5 rounded-full" style={{ background }} />
        <p className="text-md">{title}</p>
      </div>
    );
  };

  const constructDoughnotDataset = () => {
    const dataset = [];
    if (showLearner) {
      dataset.push({
        label: i18n.t("score"),
        data: [
          learnerPerformance.overallLearnerScore * 100,
          100 - learnerPerformance.overallLearnerScore * 100,
        ],
        backgroundColor: [
          "rgba(87, 194, 211, 0.6)",
          "rgba(255, 255, 255, 0.0)",
        ],
        borderColor: ["rgba(87, 194, 211, 1)", "rgba(255, 255, 255, 0.0)"],
        borderWidth: 1,
      });
    }

    dataset.push({
      label: i18n.t("score"),
      data: [
        learnerPerformance.overallBranchScore * 100,
        100 - learnerPerformance.overallBranchScore * 100,
      ],
      backgroundColor: ["rgba(255, 99, 132, 0.6)", "rgba(255, 255, 255, 0.0)"],
      borderColor: ["rgba(255, 99, 132, 1)", "rgba(255, 255, 255, 0.0)"],
      borderWidth: 1,
    });
    dataset.push({
      label: i18n.t("score"),
      data: [
        learnerPerformance.overallGradeScore * 100,
        100 - learnerPerformance.overallGradeScore * 100,
      ],
      backgroundColor: ["rgba(153, 102, 255, 0.6)", "rgba(255, 255, 255, 0.0)"],
      borderColor: ["rgba(75, 192, 192, 0.6)", "rgba(255, 255, 255, 0.0)"],
      borderWidth: 1,
    });
    // dataset.push({
    //   label: i18n.t("score"),
    //   data: [
    //     learnerPerformance.overallSeasonScore * 100,
    //     100 - learnerPerformance.overallSeasonScore * 100,
    //   ],
    //   backgroundColor: ["rgba(255, 206, 86, 0.6)", "rgba(255, 255, 255, 0.0)"],
    //   borderColor: ["rgba(255, 206, 86, 1)", "rgba(255, 255, 255, 0.0)"],
    //   borderWidth: 1,
    // });
    return dataset;
  };

  const RenderIndividualModelModal = () => {
    return (
      <BaseModal
        isOpen={showIndividualModel}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowIndividualModel(false);
        }}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            {individualModelLoading ? (
              <BaseLoading
                size="large"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            ) : (
              <>
                <div className="w-full flex flex-row justify-center items-center flex-wrap">
                  <div className="w-full flex flex-row justify-center items-center">
                    <div className="-rotate-90">
                      <span className="text-xl text-catchup-darker-blue">
                        {i18n.t("taxonomy")}
                      </span>
                    </div>
                    <div className="flex-1 flex">
                      <div className="w-full lg:w-1/2">
                        <Radar
                          data={constructRadarTaxonomyData("VERBAL")}
                          options={constructRadarOptions()}
                        />
                      </div>
                      <div className="w-full lg:w-1/2">
                        <Radar
                          data={constructRadarTaxonomyData("NUMERIC")}
                          options={constructRadarOptions()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <DividerLine />
                <div className="w-full flex flex-row justify-center items-center flex-wrap">
                  <div className="w-full flex flex-row justify-center items-center">
                    <div className="-rotate-90">
                      <span className="text-xl text-catchup-darker-blue">
                        {i18n.t("template")}
                      </span>
                    </div>
                    <div className="flex-1 flex">
                      <div className="w-full lg:w-1/2">
                        <Radar
                          data={constructRadarActivityTemplateData("VERBAL")}
                          options={constructRadarOptions()}
                        />
                      </div>
                      <div className="w-full lg:w-1/2">
                        <Radar
                          data={constructRadarActivityTemplateData("NUMERIC")}
                          options={constructRadarOptions()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  const filteredCatchxamApplicationDTOList = filterCatchxamApplicationDTOList();
  const filteredCatchxamApplicationDTOListByCoterieType =
    filterCatchxamApplicationDTOListByCoterieType();
  const filteredLearnerCatchxamApplicationPerformanceDTOListByCoterieType =
    filterLearnerCatchxamApplicationPerformanceDTOListByCoterieType();

  return (
    <>
      {RenderIndividualModelModal()}
      <div className="flex flex-1 flex-row item-center flex-wrap">
        <div className="w-full lg:w-1/4">
          <div className="mr-4">
            <div className="my-2">
              <p className="font-semibold text-lg">
                {i18n.t("overall_scores")}
              </p>
            </div>
            <div>
              <div className="flex flex-row items-center justify-between flex-wrap gap-x-4 gap-y-2">
                {showLearner
                  ? RenderSingleDoughnotHeader(
                      i18n.t("overall_learner_scores"),
                      "rgba(87, 194, 211, 0.6)"
                    )
                  : null}
                {RenderSingleDoughnotHeader(
                  i18n.t("overall_branch_scores"),
                  "rgba(255, 99, 132, 0.6)"
                )}
                {RenderSingleDoughnotHeader(
                  i18n.t("overall_grade_scores"),
                  "rgba(153, 102, 255, 0.6)"
                )}
                {/* {RenderSingleDoughnotHeader(
                  i18n.t("overall_season_scores"),
                  "rgba(255, 206, 86, 0.6)"
                )} */}
              </div>
            </div>
            <div className="max-w-[350px] m-auto">
              <Doughnut
                data={{
                  labels: [],
                  datasets: constructDoughnotDataset(),
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-full lg:w-3/4">
          <div className="ml-4">
            <div className="my-2">
              <p className="font-semibold text-lg">
                {i18n.t("overall_catchxam_scores")}
              </p>
            </div>
            <Bar
              options={{
                responsive: true,
                interaction: {
                  mode: "index",
                  intersect: false,
                },
                maxBarThickness: 40,
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    stacked: true,
                    min: 0,
                    max: 100,
                  },
                },
              }}
              data={{
                labels: constructBarLabels(),
                datasets: constructBarData(),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LearnerPerformanceGraphicalData;
