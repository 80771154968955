import i18n from "../language/i18n";

export const ONE_HOUR = 3600000;
export const ONE_DAY = 86400000;
export const ONE_WEEK = 604800000;
export const ONE_MONTH = 2419200000;
export const THREE_MONTHS = 7257600000;

export const retrieveMonthNameByIndex = (index) => {
  if (index === 0) {
    return i18n.t("january");
  } else if (index === 1) {
    return i18n.t("february");
  } else if (index === 2) {
    return i18n.t("march");
  } else if (index === 3) {
    return i18n.t("april");
  } else if (index === 4) {
    return i18n.t("may");
  } else if (index === 5) {
    return i18n.t("june");
  } else if (index === 6) {
    return i18n.t("july");
  } else if (index === 7) {
    return i18n.t("august");
  } else if (index === 8) {
    return i18n.t("september");
  } else if (index === 9) {
    return i18n.t("october");
  } else if (index === 10) {
    return i18n.t("november");
  } else if (index === 11) {
    return i18n.t("december");
  }
};

export const retrieveDateIntervalOptionList = () => {
  return [
    {
      value: "LAST_DAY",
      text: i18n.t("last_day"),
    },
    {
      value: "LAST_WEEK",
      text: i18n.t("last_week"),
    },
    {
      value: "LAST_MONTH",
      text: i18n.t("last_month"),
    },
    {
      value: "LAST_THREE_MONTHS",
      text: i18n.t("last_three_months"),
    },
  ];
};

export const constructWeekName = (beginDate, endDate) => {
  let currentEndDate;
  if (endDate) {
    currentEndDate = endDate;
  } else {
    currentEndDate = new Date(beginDate);
    currentEndDate.setDate(beginDate.getDate() + 4);
  }
  if (beginDate.getFullYear() !== currentEndDate.getFullYear()) {
    return `${beginDate.getDate()} ${retrieveMonthNameByIndex(
      beginDate.getMonth()
    )} ${beginDate.getFullYear()} - ${currentEndDate.getDate()} ${retrieveMonthNameByIndex(
      currentEndDate.getMonth()
    )} ${currentEndDate.getFullYear()}`;
  } else {
    if (beginDate.getMonth() !== currentEndDate.getMonth()) {
      return `${beginDate.getDate()} ${retrieveMonthNameByIndex(
        beginDate.getMonth()
      )} - ${currentEndDate.getDate()} ${retrieveMonthNameByIndex(
        currentEndDate.getMonth()
      )} ${currentEndDate.getFullYear()}`;
    } else {
      return `${beginDate.getDate()} - ${currentEndDate.getDate()} ${retrieveMonthNameByIndex(
        currentEndDate.getMonth()
      )} ${currentEndDate.getFullYear()}`;
    }
  }
};
