import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import { useEffect } from "react";
import { checkUpdatePermissionGranted } from "../../utilization/ScreenUtilization";
import ImportFromExcelCreateExternalLearner from "../../components/external-learner/ImportFromExcelCreateExternalLearner";

const CreateExternalLearnerScreen = () => {
  const { mode, externalRegistrationId } = useParams();
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkUpdatePermissionGranted("external_registration_list")) {
      navigate("/permission-error");
    }
  }, []);

  const RenderMainContent = () => {
    if (mode === "custom") {
    } else if (mode === "import-from-excel") {
      return (
        <ImportFromExcelCreateExternalLearner
          externalRegistrationId={externalRegistrationId}
        />
      );
    }
    return null;
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default CreateExternalLearnerScreen;
