import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import FullCard from "../../components/cards/FullCard";
import InputGroup from "../../components/groups/InputGroup";
import AdvancedTable from "../../components/tables/AdvancedTable";
import { useApp } from "../../context/AppContextProvider";
import i18n from "../../language/i18n";
import {
  createRegion,
  deleteRegion,
  patchRegion,
  queryAllRegionDTOPageByFilter,
} from "../../requests/ManagementRequests";
import {
  retrieveCountryCodeOptionList,
  retrieveCountryNameOptionList,
  retrieveProvinceNameOptionList,
} from "../../utilization/ManagementUtilization";
import {
  checkDeletePermissionGranted,
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import CreateButton from "../../components/buttons/CreateButton";

const RegionListScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [regionDTOList, setRegionDTOList] = useState([]);
  const [pageState, setPageState] = useState(0);
  const [id, setId] = useState(null);
  const [code, setCode] = useState(null);
  const [countryName, setCountryName] = useState("DEFAULT_OPTION");
  const [countryCode, setCountryCode] = useState("DEFAULT_OPTION");
  const [provinceName, setProvinceName] = useState("DEFAULT_OPTION");
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [errorMap, setErrorMap] = useState({
    code: null,
    countryName: null,
    countryCode: null,
    provinceName: null,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("region_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    retrieveAndSetRegionPageByFilter();
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
  ]);

  useEffect(() => {
    if (isFilterApplied) {
      retrieveAndSetRegionPageByFilter();
    }
  }, [isFilterApplied, filterBeginAt, filterEndAt, filterShowDeleted]);

  const retrieveAndSetRegionPageByFilter = async () => {
    setTableLoading(true);
    const { data, err } = await queryAllRegionDTOPageByFilter({
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        beginAt:
          isFilterApplied && filterBeginAt
            ? new Date(filterBeginAt).getTime()
            : null,
        endAt:
          isFilterApplied && filterEndAt
            ? new Date(filterEndAt).getTime()
            : null,
        deleted: isFilterApplied ? (filterShowDeleted ? null : false) : false,
      },
    });
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setRegionDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const checkCommonValidation = () => {
    if (
      !code ||
      code.trim() === "" ||
      countryCode === "DEFAULT_OPTION" ||
      countryName === "DEFAULT_OPTION" ||
      provinceName === "DEFAULT_OPTION" ||
      provinceName.trim() === ""
    ) {
      const currentErrorMap = {
        code: null,
        countryCode: null,
        countryName: null,
        provinceName: null,
      };
      if (!code || code.trim() === "") {
        currentErrorMap.code = i18n.t("region_code_required_field");
      }
      if (!countryCode) {
        currentErrorMap.countryCode = i18n.t("country_code_required_field");
      }
      if (!countryName) {
        currentErrorMap.countryName = i18n.t("country_name_required_field");
      }
      if (!provinceName || provinceName.trim() === "") {
        currentErrorMap.provinceName = i18n.t("province_name_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const filterProvinceNameOptionList = () => {
    if (countryName !== "DEFAULT_OPTION") {
      return retrieveProvinceNameOptionList().filter(
        (provinceName) => provinceName.parent === countryName
      );
    }
    return [];
  };

  const resetParams = () => {
    setId(null);
    setCode(null);
    setCountryCode(null);
    setCountryName(null);
    setProvinceName(null);
  };

  const handleOnChange = (type, value) => {
    if (type === "code") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          code: i18n.t("code_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          code: null,
        }));
      }
      setCode(value);
    } else if (type === "provinceName") {
      setProvinceName(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        provinceName: null,
      }));
    } else if (type === "countryCode") {
      setCountryCode(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        countryCode: null,
      }));
    } else if (type === "countryName") {
      setCountryName(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        countryName: null,
      }));
    } else {
    }
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleToCreateRegionOnClick = () => {
    setId(null);
    setPageState(1);
  };

  const handleToUpdateRegionOnClick = (regionDTO) => {
    setId("UNUSED_ID");
    setCode(regionDTO.code);
    setCountryCode(regionDTO.countryCode);
    setCountryName(regionDTO.countryName);
    setProvinceName(regionDTO.provinceName);
    setPageState(1);
  };

  const handleUpdateRegionOnClick = async () => {
    if (checkUpdatePermissionGranted("region_list")) {
      if (checkCommonValidation()) {
        const currentRegion = {
          code,
          countryCode,
          countryName,
          provinceName,
        };
        const { data, err } = await patchRegion(currentRegion);
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          retrieveAndSetRegionPageByFilter();
          handleReturnFromRegionOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleCreateRegionOnClick = async () => {
    if (checkInsertPermissionGranted("region_list")) {
      if (checkCommonValidation()) {
        const newRegion = {
          code,
          countryCode,
          countryName,
          provinceName,
        };
        const { data, err } = await createRegion(newRegion);
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          retrieveAndSetRegionPageByFilter();
          handleReturnFromRegionOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleRemoveRegionOnClick = async (regionCode) => {
    if (checkDeletePermissionGranted("region_list")) {
      const { err } = await deleteRegion(regionCode);
      if (err) {
      } else {
        const foundRegion = regionDTOList.find(
          (regionDTO) => regionDTO.code === regionCode
        );
        foundRegion.deleted = true;
        setRegionDTOList(
          JSON.parse(
            JSON.stringify(regionDTOList.sort((a, b) => b.code > a.code))
          )
        );
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_delete"));
    }
  };

  const handleReturnFromRegionOnClick = () => {
    resetParams();
    setPageState(0);
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={i18n.t("region_list")}
            description={i18n.t("region_list_table_description_text")}
            itemName={i18n.t("region")}
            headerList={[
              { name: i18n.t("region_code"), key: "code" },
              {
                name: i18n.t("country_code"),
                key: "countryCode",
                type: "enumeration",
              },
              {
                name: i18n.t("country_name"),
                key: "countryName",
                type: "enumeration",
              },
              { name: i18n.t("province_name"), key: "provinceName" },
              {
                name: i18n.t("created_at"),
                key: "createdAt",
                type: "datetime",
              },
            ]}
            selectable={false}
            showFilter={true}
            showItemCount={selectedShowItemCount}
            totalItemCount={totalItemCount}
            pageNumber={pageNumber}
            loading={tableLoading}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleOnFilterChange={handleOnFilterChange}
            handleOnTableParamsChange={handleOnTableParamsChange}
            filterParams={{
              isFilterApplied,
              showBeginDate: true,
              beginAt: filterBeginAt,
              showEndDate: true,
              endAt: filterEndAt,
              showDeleted: filterShowDeleted,
            }}
            itemList={regionDTOList}
            insertable={checkInsertPermissionGranted("region_list")}
            handleInsertOnClick={handleToCreateRegionOnClick}
            updatable={checkUpdatePermissionGranted("region_list")}
            handleUpdateOnClick={handleToUpdateRegionOnClick}
            deletable={false} //checkDeletePermissionGranted("region_list")
            handleDeleteOnClick={handleRemoveRegionOnClick}
            navigatable={false}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <FullCard isShadowed={true}>
            <div className="">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      handleReturnFromRegionOnClick();
                    }}
                  />
                </div>
                <BaseTitle
                  title={id ? i18n.t("update_region") : i18n.t("create_region")}
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <DividerLine />
              <div className="w-full">
                <p className="font-semibold text-lg">
                  {i18n.t("region_information")}
                </p>
              </div>
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("region_code")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={code}
                    onChange={(e) => {
                      handleOnChange("code", e.target.value);
                    }}
                    errorText={errorMap["code"]}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("country_code")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={countryCode}
                    optionList={retrieveCountryCodeOptionList()}
                    onChange={(e) => {
                      handleOnChange("countryCode", e.target.value);
                    }}
                    errorText={errorMap["countryCode"]}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("country_name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={countryName}
                    optionList={retrieveCountryNameOptionList()}
                    onChange={(e) => {
                      handleOnChange("countryName", e.target.value);
                    }}
                    errorText={errorMap["countryName"]}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("province_name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={provinceName}
                    optionList={filterProvinceNameOptionList()}
                    onChange={(e) => {
                      handleOnChange("provinceName", e.target.value);
                    }}
                    errorText={errorMap["provinceName"]}
                  />
                </div>
              </div>

              <div className="mt-4 ml-auto">
                {id ? (
                  <SecondaryButton
                    title={i18n.t("update")}
                    size="medium"
                    onClick={handleUpdateRegionOnClick}
                  />
                ) : (
                  <CreateButton
                    title={i18n.t("create")}
                    size="medium"
                    onClick={handleCreateRegionOnClick}
                  />
                )}
              </div>
            </div>
          </FullCard>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default RegionListScreen;
