import { useEffect, useState } from "react";
import FullCard from "../../components/cards/FullCard";
import InputGroup from "../../components/groups/InputGroup";
import {
  checkInsertPermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import {
  queryCampusListByBrandId,
  queryInstitutionListByCampusId,
} from "../../requests/ManagementRequests";
import i18n from "../../language/i18n";
import RenderInformationCardItem from "../../components/cards/InformationCard";
import {
  createTokenAssignment,
  queryTokenAssignmentDTOListByParams,
  queryTokenPurchaseDTOListByParams,
} from "../../requests/TokenRequests";
import AdvancedTable from "../../components/tables/AdvancedTable";
import ErrorWarning from "../../components/warnings/ErrorWarning";
import { NotificationManager } from "react-notifications";
import { retrieveTokenUsageSubTypeOptionList } from "../../utilization/TokenUtilization";
import BaseImage from "../../components/images/BaseImage";
import {
  retrieveCampusDTOOptionList,
  retrieveInstitutionDTOOptionList,
} from "../../utilization/ManagementUtilization";

const TokenAssignmentListScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    language,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
  } = useAuth();
  const [campusDTOList, setCampusDTOList] = useState([]);
  const [selectedCampusId, setSelectedCampusId] = useState("DEFAULT_OPTION");
  const [institutionDTOList, setInstitutionDTOList] = useState([]);
  const [selectedInstitutionId, setSelectedInstitutionId] =
    useState("DEFAULT_OPTION");
  const [tokenPurchaseDTOList, setTokenPurchaseDTOList] = useState([]);
  const [tokenAssignmentDTOList, setTokenAssignmentDTOList] = useState([]);
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [selectedTokenPurchaseId, setSelectedTokenPurchaseId] =
    useState("DEFAULT_OPTION");
  const [selectedType, setSelectedType] = useState("AI");
  const [selectedSubType, setSelectedSubType] = useState(
    "ACTIVITY_TEMPLATE_GENERATION"
  );
  const [amount, setAmount] = useState(100);
  const [errorMap, setErrorMap] = useState({
    amount: null,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("token_assignment_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    if (userProfileCampus) {
      setCampusDTOList([userProfileCampus]);
    } else {
      retrieveAndSetCampusDTOListByBrandId();
    }
  }, []);

  useEffect(() => {
    if (selectedCampusId === "DEFAULT_OPTION") {
      setSelectedInstitutionId("DEFAULT_OPTION");
    } else {
      if (userProfileInstitution) {
        setInstitutionDTOList([userProfileInstitution]);
      } else {
        retrieveAndSetInstitutionDTOListByCampusId();
      }
    }
  }, [selectedCampusId]);

  useEffect(() => {
    if (selectedSubType === "DEFAULT_OPTION") return;
    setSelectedTokenPurchaseId("DEFAULT_OPTION");
    retrieveAndSetTokenPurchaseList();
    retrieveAndSetTokenAssignmentList();
  }, [selectedSubType, selectedCampusId, selectedInstitutionId]);

  const retrieveAndSetCampusDTOListByBrandId = async () => {
    const { data, err } = await queryCampusListByBrandId(userProfileBrand.id);
    if (err) {
      console.log(err);
    } else {
      setCampusDTOList(data);
    }
  };

  const retrieveAndSetInstitutionDTOListByCampusId = async () => {
    const { data, err } = await queryInstitutionListByCampusId(
      selectedCampusId
    );
    if (err) {
      console.log(err);
    } else {
      setInstitutionDTOList(data);
    }
  };

  const retrieveAndSetTokenPurchaseList = async () => {
    const queryParams = {
      brandId: userProfileBrand.id,
      type: selectedType,
      subType: selectedSubType,
    };
    if (selectedCampusId !== "DEFAULT_OPTION") {
      queryParams.campusId = selectedCampusId;
      if (selectedInstitutionId !== "DEFAULT_OPTION") {
        queryParams.institutionId = selectedInstitutionId;
      }
    }
    const { data, err } = await queryTokenPurchaseDTOListByParams(queryParams);
    if (err) {
      console.log(err);
    } else {
      setTokenPurchaseDTOList(data);
    }
  };

  const retrieveAndSetTokenAssignmentList = async () => {
    const queryParams = {
      brandId: userProfileBrand.id,
      type: selectedType,
      subType: selectedSubType,
      showAll: true,
    };
    if (selectedCampusId !== "DEFAULT_OPTION") {
      queryParams.campusId = selectedCampusId;
      if (selectedInstitutionId !== "DEFAULT_OPTION") {
        queryParams.institutionId = selectedInstitutionId;
      }
    }
    const { data, err } = await queryTokenAssignmentDTOListByParams(
      queryParams
    );
    if (err) {
      console.log(err);
    } else {
      setTokenAssignmentDTOList(data);
    }
  };

  const retrieveTokenPurchaseDTOOptionList = () => {
    return tokenPurchaseDTOList.map((tokenPurchaseDTO) => ({
      value: tokenPurchaseDTO.id,
      text: `${new Date(tokenPurchaseDTO.createdAt).toLocaleDateString(
        language
      )} (${tokenPurchaseDTO.amount})`,
    }));
  };

  const filterTokenUsageSubTypeOptionList = () => {
    const tokenSubTypeOptionList = retrieveTokenUsageSubTypeOptionList();
    return tokenSubTypeOptionList.filter(
      (item) => item.value === "ACTIVITY_TEMPLATE_GENERATION"
    );
  };

  const filterTokenAssignmentDTOList = () => {
    return tokenAssignmentDTOList.filter(
      (item) => item.tokenPurchaseDTO.id === selectedTokenPurchaseId
    );
  };

  const calculateTotalAssignableTokenCount = () => {
    let assignableToken = 0;
    tokenPurchaseDTOList.forEach((tokenPurchaseDTO) => {
      const { amount } = tokenPurchaseDTO;
      assignableToken += amount;
    });
    return assignableToken;
  };

  const calculateTotalAssigedTokenCount = () => {
    let assignedToken = 0;
    tokenAssignmentDTOList.forEach((item) => {
      const { amount } = item;
      assignedToken += amount;
    });
    return assignedToken;
  };

  const calculateTotalQuotaCount = () => {
    let totalQuota = 0;
    tokenAssignmentDTOList.forEach((item) => {
      const { quota } = item;
      totalQuota += quota;
    });
    return totalQuota;
  };

  const calculateSelectedAssignableTokenCount = () => {
    const foundTokenPurchaseDTO = tokenPurchaseDTOList.find(
      (item) => item.id === selectedTokenPurchaseId
    );
    if (!foundTokenPurchaseDTO) return 0;
    const { amount } = foundTokenPurchaseDTO;
    return amount;
  };

  const calculateSelectedAssigedTokenCount = () => {
    let assignedToken = 0;
    filteredTokenAssignmentDTOList.forEach((item) => {
      const { amount } = item;
      assignedToken += amount;
    });
    return assignedToken;
  };

  const calculateSelectedQuotaCount = () => {
    let totalQuota = 0;
    filteredTokenAssignmentDTOList.forEach((item) => {
      const { quota } = item;
      totalQuota += quota;
    });
    return totalQuota;
  };

  const checkCommonValidation = () => {
    if (!amount) {
      const currentErrorMap = {
        amount: null,
      };
      if (!amount) {
        currentErrorMap.amount = i18n.t("amount_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const handleOnChange = (key, value) => {
    if (key === "campusId") {
      setSelectedCampusId(value);
    } else if (key === "institutionId") {
      setSelectedInstitutionId(value);
    } else if (key === "tokenPurchaseId") {
      setSelectedTokenPurchaseId(value);
    } else if (key === "subType") {
      setSelectedSubType(value);
    } else if (key === "amount") {
      if (value < 0) {
        setAmount(0);
      } else {
        setAmount(value);
      }
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        amount: null,
      }));
    }
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleToCreateTokenPurchaseOnClick = async () => {
    if (checkInsertPermissionGranted("token_assignment_list")) {
      if (checkCommonValidation()) {
        const newTokenAssignment = {
          brandId: userProfileBrand.id,
          amount,
          tokenPurchase: {
            id: selectedTokenPurchaseId,
          },
        };
        if (selectedCampusId !== "DEFAULT_OPTION") {
          newTokenAssignment.campusId = selectedCampusId;
          if (selectedInstitutionId !== "DEFAULT_OPTION") {
            newTokenAssignment.institutionId = selectedInstitutionId;
          }
        }
        const { data, err } = await createTokenAssignment(newTokenAssignment);
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          retrieveAndSetTokenPurchaseList();
          retrieveAndSetTokenAssignmentList();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const RenderMainContent = () => {
    return (
      <FullCard>
        <div className="flex flex-row items-center flex-wrap">
          <div className="w-1/2 lg:w-1/3">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("sub_type")}
                placeholder={i18n.t("sub_type")}
                value={selectedSubType}
                optionList={filterTokenUsageSubTypeOptionList()}
                onChange={(event) => {
                  handleOnChange("subType", event.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-1/2 lg:w-1/3">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("campus")}
                placeholder={i18n.t("campus")}
                value={selectedCampusId}
                optionList={retrieveCampusDTOOptionList(campusDTOList)}
                onChange={(event) => {
                  handleOnChange("campusId", event.target.value);
                }}
              />
            </div>
          </div>
          {selectedCampusId !== "DEFAULT_OPTION" ? (
            <div className="w-1/2 lg:w-1/3">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("institution")}
                  placeholder={i18n.t("institution")}
                  value={selectedInstitutionId}
                  optionList={retrieveInstitutionDTOOptionList(
                    institutionDTOList
                  )}
                  onChange={(event) => {
                    handleOnChange("institutionId", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
        {selectedSubType !== "DEFAULT_OPTION" ? (
          <>
            <div className="flex flex-row flex-wrap justify-start items-center">
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                {RenderInformationCardItem(
                  i18n.t("total_assignable_token"),
                  null,
                  <BaseImage src="/icons/token.png" alt="token" size="small" />,
                  totalAssignableTokenCount - totalAssignedTokenCount,
                  1
                )}
              </div>
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                {RenderInformationCardItem(
                  i18n.t("total_assigned_token"),
                  null,
                  <BaseImage src="/icons/token.png" alt="token" size="small" />,
                  totalAssignedTokenCount,
                  2
                )}
              </div>
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                {RenderInformationCardItem(
                  i18n.t("total_used_token"),
                  null,
                  <BaseImage src="/icons/token.png" alt="token" size="small" />,
                  totalAssignedTokenCount - totalQuotaCount,
                  3
                )}
              </div>
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                {RenderInformationCardItem(
                  i18n.t("total_available_token"),
                  null,
                  <BaseImage src="/icons/token.png" alt="token" size="small" />,
                  totalQuotaCount,
                  4
                )}
              </div>
            </div>

            {totalAssignableTokenCount - totalAssignedTokenCount > 0 ||
            tokenPurchaseDTOList.length > 0 ? (
              <>
                <div className="flex flex-row items-center flex-wrap">
                  <div className="w-1/2 lg:w-1/3">
                    <div className="mx-2">
                      <InputGroup
                        type="select"
                        title={i18n.t("token_purchase")}
                        placeholder={i18n.t("token_purchase")}
                        value={selectedTokenPurchaseId}
                        optionList={retrieveTokenPurchaseDTOOptionList()}
                        onChange={(event) => {
                          handleOnChange("tokenPurchaseId", event.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {selectedTokenPurchaseId !== "DEFAULT_OPTION" ? (
                    <div className="w-full">
                      <div className="flex flex-row flex-wrap justify-start items-center">
                        <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                          {RenderInformationCardItem(
                            i18n.t("selected_assignable_token"),
                            null,
                            <BaseImage
                              src="/icons/token.png"
                              alt="token"
                              size="small"
                            />,
                            selectedAssignableTokenCount -
                              selectedAssignedTokenCount,
                            1
                          )}
                        </div>
                        <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                          {RenderInformationCardItem(
                            i18n.t("selected_assigned_token"),
                            null,
                            <BaseImage
                              src="/icons/token.png"
                              alt="token"
                              size="small"
                            />,
                            selectedAssignedTokenCount,
                            2
                          )}
                        </div>
                        <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                          {RenderInformationCardItem(
                            i18n.t("selected_used_token"),
                            null,
                            <BaseImage
                              src="/icons/token.png"
                              alt="token"
                              size="small"
                            />,
                            selectedAssignedTokenCount - selectedQuotaCount,
                            3
                          )}
                        </div>
                        <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                          {RenderInformationCardItem(
                            i18n.t("selected_available_token"),
                            null,
                            <BaseImage
                              src="/icons/token.png"
                              alt="token"
                              size="small"
                            />,
                            selectedQuotaCount,
                            4
                          )}
                        </div>
                      </div>

                      {selectedAssignableTokenCount -
                        selectedAssignedTokenCount >
                      0 ? (
                        <>
                          <div className="w-1/2 lg:w-1/3">
                            <div className="mx-2">
                              <InputGroup
                                type="number"
                                title={i18n.t("amount")}
                                placeholder={i18n.t("amount")}
                                value={amount}
                                onChange={(e) => {
                                  handleOnChange("amount", e.target.value);
                                }}
                                errorText={errorMap.amount}
                              />
                            </div>
                          </div>
                          <AdvancedTable
                            title={i18n.t("token_assignment_list")}
                            description={i18n.t(
                              "token_assignment_list_table_description_text"
                            )}
                            itemName={i18n.t("token_assignment")}
                            headerList={[
                              {
                                name: i18n.t("sub_type"),
                                key: "tokenPurchaseDTO.subType",
                                type: "enumeration",
                              },
                              { name: i18n.t("amount"), key: "amount" },
                              { name: i18n.t("quota"), key: "quota" },
                              {
                                name: i18n.t("disabled"),
                                key: "tokenPurchaseDTO.disabled",
                                type: "boolean",
                              },
                              {
                                name: i18n.t("created_at"),
                                key: "createdAt",
                                type: "datetime",
                              },
                            ]}
                            selectable={false}
                            showFilter={true}
                            showItemCount={selectedShowItemCount}
                            totalItemCount={totalItemCount}
                            pageNumber={pageNumber}
                            loading={tableLoading}
                            sortKey={sortKey}
                            sortDirection={sortDirection}
                            handleOnFilterChange={handleOnFilterChange}
                            handleOnTableParamsChange={
                              handleOnTableParamsChange
                            }
                            filterParams={{
                              isFilterApplied,
                              showBeginDate: true,
                              beginAt: filterBeginAt,
                              showEndDate: true,
                              endAt: filterEndAt,
                              showDeleted: filterShowDeleted,
                            }}
                            itemList={filteredTokenAssignmentDTOList}
                            insertable={checkInsertPermissionGranted(
                              "token_assignment_list"
                            )}
                            handleInsertOnClick={
                              handleToCreateTokenPurchaseOnClick
                            }
                            updatable={false}
                            deletable={false}
                            navigatable={false}
                          />
                        </>
                      ) : (
                        <div className="mx-2">
                          <ErrorWarning
                            text={i18n.t("you_do_not_have_enough_tokens_text")}
                          />
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </>
            ) : (
              <div className="mx-2">
                <ErrorWarning
                  text={i18n.t("you_do_not_have_enough_tokens_text")}
                />
              </div>
            )}
          </>
        ) : null}
      </FullCard>
    );
  };

  const filteredTokenAssignmentDTOList = filterTokenAssignmentDTOList();
  const totalAssignableTokenCount = calculateTotalAssignableTokenCount();
  const totalAssignedTokenCount = calculateTotalAssigedTokenCount();
  const totalQuotaCount = calculateTotalQuotaCount();
  const selectedAssignableTokenCount = calculateSelectedAssignableTokenCount();
  const selectedAssignedTokenCount = calculateSelectedAssigedTokenCount();
  const selectedQuotaCount = calculateSelectedQuotaCount();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default TokenAssignmentListScreen;
