import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Server
 */
export const checkManagementService = async () => {
  try {
    const response = await axios
      .create()
      .get(
        `${connection.base.management.url}${endpoints.management.context}${endpoints.management.actuator.health.context}`
      );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Dashboard Metrics
 */
export const retrieveGenixoDashboardMetricsManagement = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.metrics.context}${endpoints.management.metrics.dashboard.genixo.context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveUserStaffDashboardMetricsManagement = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.metrics.context}${endpoints.management.metrics.dashboard.user.staff.context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * User
 */
export const retrieveTokenInformation = async (params) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.authenticate.context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveTokenInformationWithExternalResource = async (params) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.authenticate["with-external-resource"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveUserInformationFromToken = async () => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.userFromToken.context}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveAccessTokenFromRefreshToken = async (refreshToken) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.refreshToken.context}`,
      {
        refreshToken,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveAccessTokenFromRefreshTokenWithExpiration = async (
  refreshToken,
  userProfileId
) => {
  try {
    const response = await axios
      .create()
      .post(
        `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.refreshTokenFromExpiration.context}`,
        {
          refreshToken,
          userProfileId,
        }
      );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveProfileTokenInformation = async (
  profileId,
  refreshToken
) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.authenticateWithProfile.context}/${profileId}`,
      {
        refreshToken,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createBasicUser = async (user) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.basic.context}`,
      user
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createIndividualUser = async (user) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users["with-user-profile"].individual.context}`,
      user
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createUserWithUserProfile = async (newUserProfile) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users["with-user-profile"].context}`,
      newUserProfile
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createUserImportFromExcel = async (userAndUserProfileList) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users["import-from-excel"].context}`,
      userAndUserProfileList
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createUserImportFromK12 = async (userAndUserProfileList) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users["import-from-k12"].context}`,
      userAndUserProfileList
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveAllUserDTOList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.dto.context}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllUserDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchUser = async (params) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.users.context}/`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchUserOnlyRequiredAction = async (params) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users["only-requiredAction"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchUserOnlyNotification = async (params) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users["only-notification"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryUserListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.query.dto.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const searchUserListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.search.dto.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const searchUserListWithStateByParams = async (
  seasonId,
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.search.seasonId.multiple["with-states"].context}`.replace(
        "{seasonId}",
        seasonId
      ),
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const searchListWithStateChangeUsernameByParams = async (
  seasonId,
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.search.seasonId.multiple["with-states"]["change-username"].context}`.replace(
        "{seasonId}",
        seasonId
      ),
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const sendEmailVerificationEmail = async (userDTO) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.send.email["email-verification"].context}`,
      userDTO
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const sendChangePasswordEmail = async (userDTO) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.send.email["change-password"].context}`,
      userDTO
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const sendForgotPasswordEmail = async (userDTO) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.send.email["forgot-password"].context}`,
      userDTO
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * User Profile
 */
export const retrieveUserProfileList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.users.profiles.context}${endpoints.management.users.profiles.dto.context}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryUserProfileListByUserId = async (userId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.profiles.context}${endpoints.management.users.profiles.query.dto["by-userId"].context}`,
      { userId }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryUserProfileListByUserIdAndBrandId = async (
  userId,
  brandId
) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.profiles.context}${endpoints.management.users.profiles.query.dto["by-userId-brandId"].context}`,
      { userId, brandId }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryUserProfileListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.profiles.context}${endpoints.management.users.profiles.query.dto.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllUserProfileDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.profiles.context}${endpoints.management.users.profiles.paged.query.dto["by-userId-brandId"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createUserProfileByUserId = async (newUserProfile) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.profiles.context}${endpoints.management.users.profiles["by-userId"].context}`,
      newUserProfile
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createAndAssignUserProfileByUserId = async (newUserProfile) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.profiles.context}${endpoints.management.users.profiles.assign["by-userId"].context}`,
      newUserProfile
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchUserProfile = async (patchUserProfile) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.users.profiles.context}/`,
      patchUserProfile
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteUserProfileById = async (userProfileId) => {
  try {
    const response = await axios.delete(
      `${connection.base.management.url}${endpoints.management.users.profiles.context}/${userProfileId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * User Profile Authority
 */
export const retrieveUserProfileAuthorityListByUserProfileId = async (
  userProfileId
) => {
  const constructedUrl =
    `${connection.base.management.url}${endpoints.management.users.profiles.context}${endpoints.management.users.profiles.id.dto.authorities.context}`.replace(
      "{id}",
      userProfileId
    );
  try {
    const response = await axios.get(constructedUrl, { userProfileId });
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveAuthorityList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.users.authorities.context}/`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const addUserAuthorityToUserProfile = async (
  userProfileId,
  userAuthorityId
) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.profiles.context}${endpoints.management.users.profiles.add["user-authority-to-user-profile"].context}`,
      { userProfileId, userAuthorityId }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const removeUserAuthorityFromUserProfile = async (
  userProfileId,
  userAuthorityId
) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.profiles.context}${endpoints.management.users.profiles.remove["user-authority-from-user-profile"].context}`,
      { userProfileId, userAuthorityId }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const addOrRemoveUserAuthorityToUserProfile = async (
  userProfileId,
  includedIdList,
  removedIdList
) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.profiles.context}${endpoints.management.users.profiles["add-or-remove"].multiple["user-authority-to-user-profile"].context}`,
      { userProfileId, includedIdList, removedIdList }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Brand
 */
export const retrieveAllBrandDTOList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.brands.context}${endpoints.management.brands.dto.context}/`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllBrandDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.brands.context}${endpoints.management.brands.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createBrand = async (newBrand) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.brands.context}/`,
      newBrand
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchBrand = async (patchBrand) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.brands.context}/`,
      patchBrand
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteBrand = async (brandId) => {
  try {
    const response = await axios.delete(
      `${connection.base.management.url}${endpoints.management.brands.context}/${brandId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Region
 */
export const retrieveAllRegionDTOList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.regions.context}${endpoints.management.regions.dto.context}/`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllRegionDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.regions.context}${endpoints.management.regions.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createRegion = async (newRegion) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.regions.context}/`,
      newRegion
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchRegion = async (patchRegion) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.regions.context}/`,
      patchRegion
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteRegion = async (regionCode) => {
  try {
    const response = await axios.delete(
      `${connection.base.management.url}${endpoints.management.regions.context}/${regionCode}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveBrandAISettingsByBrandId = async (brandId) => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.brands.context}${endpoints.management.brands.id.settings["ai-settings"].context}`.replace(
        "{id}",
        brandId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchBrandAISettings = async (patchParams) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.brands.context}${endpoints.management.brands.settings["ai-settings"].context}`,
      patchParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Campus
 */
export const retrieveAllCampusDTOList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.campuses.context}${endpoints.management.campuses.dto.context}/`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createCampusByBrandIdAndRegionCode = async (newCampus) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.campuses.context}${endpoints.management.campuses["by-brandId-regionCode"].context}`,
      newCampus
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchCampus = async (patchCampus) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.campuses.context}/`,
      patchCampus
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteCampus = async (campusId) => {
  try {
    const response = await axios.delete(
      `${connection.base.management.url}${endpoints.management.campuses.context}/${campusId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryCampusListByUserProfileId = async (userProfileId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.campuses.context}${endpoints.management.campuses.query.dto["by-userProfileId"].context}`,
      {
        userProfileId,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryCampusListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.campuses.context}${endpoints.management.campuses.query.dto.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryCampusListByBrandId = async (brandId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.campuses.context}${endpoints.management.campuses.query.dto["by-brandId"].context}`,
      {
        brandId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllCampusDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.campuses.context}${endpoints.management.campuses.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveCampusAISettingsByCampusId = async (campusId) => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.campuses.context}${endpoints.management.campuses.id.settings["ai-settings"].context}`.replace(
        "{id}",
        campusId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchCampusAISettings = async (patchParams) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.campuses.context}${endpoints.management.campuses.settings["ai-settings"].context}`,
      patchParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Institution
 */
export const retrieveAllInstitutionDTOList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.institutions.context}${endpoints.management.institutions.dto.context}/`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveInstitutionDTOById = async (institutionId) => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.institutions.context}${endpoints.management.institutions.dto.id.context}`.replace(
        "{id}",
        institutionId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createInsitutionByCampusId = async (newInstitution) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.institutions.context}${endpoints.management.institutions["by-campusId"].context}`,
      newInstitution
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchInstitution = async (patchInstitution) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.institutions.context}/`,
      patchInstitution
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteInsitution = async (institutionId) => {
  try {
    const response = await axios.delete(
      `${connection.base.management.url}${endpoints.management.institutions.context}/${institutionId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryInstitutionListByUserProfileId = async (userProfileId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.institutions.context}${endpoints.management.institutions.query.dto["by-userProfileId"].context}`,
      {
        userProfileId,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryInstitutionListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.institutions.context}${endpoints.management.institutions.query.dto.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryInstitutionListByCampusId = async (campusId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.institutions.context}${endpoints.management.institutions.query.dto["by-campusId"].context}`,
      {
        campusId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryInstitutionListByBrandId = async (brandId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.institutions.context}${endpoints.management.institutions.query.dto["by-brandId"].context}`,
      {
        brandId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryInstitutionListByCampusIdList = async (campusIdList) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.institutions.context}${endpoints.management.institutions.query.dto["by-campusIdList"].context}`,
      {
        campusIdList,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllInstitutionDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.institutions.context}${endpoints.management.institutions.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveInstitutionGeneralSettingsByInstitutionId = async (
  institutionId
) => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.institutions.context}${endpoints.management.institutions.id.settings["general-settings"].context}`.replace(
        "{id}",
        institutionId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveInstitutionAISettingsByInstitutionId = async (
  institutionId
) => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.institutions.context}${endpoints.management.institutions.id.settings["ai-settings"].context}`.replace(
        "{id}",
        institutionId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveInstitutionIntegrationSettingsByInstitutionId = async (
  institutionId
) => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.institutions.context}${endpoints.management.institutions.id.settings["integration-settings"].context}`.replace(
        "{id}",
        institutionId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchInstitutionGeneralSettings = async (patchParams) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.institutions.context}${endpoints.management.institutions.settings["general-settings"].context}`,
      patchParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchInstitutionAISettings = async (patchParams) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.institutions.context}${endpoints.management.institutions.settings["ai-settings"].context}`,
      patchParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchInstitutionIntegrationSettings = async (patchParams) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.institutions.context}${endpoints.management.institutions.settings["integration-settings"].context}`,
      patchParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Season
 */
export const retrieveSeasonByIdInDetail = async (id) => {
  try {
    const constructedUrl =
      `${connection.base.management.url}${endpoints.management.seasons.context}${endpoints.management.seasons.dto.id.detail.context}`.replace(
        "{id}",
        id
      );
    const response = await axios.get(constructedUrl);
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveAllSeasonDTOList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.seasons.context}${endpoints.management.seasons.dto.context}/`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createSeasonByInstitutionId = async (newSeason) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.seasons.context}${endpoints.management.seasons["by-institutionId"].context}`,
      newSeason
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchSeason = async (patchSeason) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.seasons.context}/`,
      patchSeason
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteSeason = async (seasonId) => {
  try {
    const response = await axios.delete(
      `${connection.base.management.url}${endpoints.management.seasons.context}/${seasonId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const querySeasonListByInstitutionId = async (institutionId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.seasons.context}${endpoints.management.seasons.query.dto["by-institutionId"].context}`,
      {
        institutionId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActiveSeasonListByInstitutionId = async (institutionId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.seasons.context}${endpoints.management.seasons.query.dto.active["by-institutionId"].context}`,
      {
        institutionId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const querySeasonListByInstitutionIdList = async (institutionIdList) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.seasons.context}${endpoints.management.seasons.query.dto["by-institutionIdList"].context}`,
      {
        institutionIdList,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const querySeasonListByCampusId = async (campusId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.seasons.context}${endpoints.management.seasons.query.dto["by-campusId"].context}`,
      {
        campusId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActiveSeasonListByCampusId = async (campusId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.seasons.context}${endpoints.management.seasons.query.dto.active["by-campusId"].context}`,
      {
        campusId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const querySeasonListByBrandId = async (brandId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.seasons.context}${endpoints.management.seasons.query.dto["by-brandId"].context}`,
      {
        brandId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActiveSeasonListByBrandId = async (brandId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.seasons.context}${endpoints.management.seasons.query.dto.active["by-brandId"].context}`,
      {
        brandId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const querySeasonListByUserProfileId = async (userProfileId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.seasons.context}${endpoints.management.seasons.query.dto["by-userProfileId"].context}`,
      {
        userProfileId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const querySeasonListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.seasons.context}${endpoints.management.seasons.query.dto.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllSeasonDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.seasons.context}${endpoints.management.seasons.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Grade
 */
export const retrieveGradeByIdInDetail = async (id) => {
  try {
    const constructedUrl =
      `${connection.base.management.url}${endpoints.management.grades.context}${endpoints.management.grades.dto.id.detail.context}`.replace(
        "{id}",
        id
      );
    const response = await axios.get(constructedUrl);
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveAllGradeDTOList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.grades.context}${endpoints.management.grades.dto.context}/`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createGradeBySeasonId = async (newGrade) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.grades.context}${endpoints.management.grades["by-seasonId"].context}`,
      newGrade
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchGrade = async (patchGrade) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.grades.context}/`,
      patchGrade
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteGrade = async (gradeId) => {
  try {
    const response = await axios.delete(
      `${connection.base.management.url}${endpoints.management.grades.context}/${gradeId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryGradeListBySeasonId = async (seasonId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.grades.context}${endpoints.management.grades.query.dto["by-seasonId"].context}`,
      {
        seasonId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActiveGradeListBySeasonId = async (seasonId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.grades.context}${endpoints.management.grades.query.dto.active["by-seasonId"].context}`,
      {
        seasonId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryGradeListByInstitutionId = async (institutionId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.grades.context}${endpoints.management.grades.query.dto["by-institutionId"].context}`,
      {
        institutionId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActiveGradeListByInstitutionId = async (institutionId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.grades.context}${endpoints.management.grades.query.dto.active["by-institutionId"].context}`,
      {
        institutionId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryGradeListByCampusId = async (campusId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.grades.context}${endpoints.management.grades.query.dto["by-campusId"].context}`,
      {
        campusId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActiveGradeListByCampusId = async (campusId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.grades.context}${endpoints.management.grades.query.dto.active["by-campusId"].context}`,
      {
        campusId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryGradeListByBrandId = async (brandId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.grades.context}${endpoints.management.grades.query.dto["by-brandId"].context}`,
      {
        brandId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActiveGradeListByBrandId = async (brandId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.grades.context}${endpoints.management.grades.query.dto.active["by-brandId"].context}`,
      {
        brandId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryGradeListBySeasonIdList = async (seasonIdList) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.grades.context}${endpoints.management.grades.query.dto["by-seasonIdList"].context}`,
      {
        seasonIdList,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryGradeListByUserProfileId = async (userProfileId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.grades.context}${endpoints.management.grades.query.dto["by-userProfileId"].context}`,
      {
        userProfileId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryGradeListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.grades.context}${endpoints.management.grades.query.dto.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllGradeDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.grades.context}${endpoints.management.grades.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllActiveGradeDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.grades.context}${endpoints.management.grades.paged.query.dto.active["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Branch
 */
export const retrieveBranchById = async (id) => {
  try {
    const constructedUrl =
      `${connection.base.management.url}${endpoints.management.branches.context}${endpoints.management.branches.dto.id.context}`.replace(
        "{id}",
        id
      );
    const response = await axios.get(constructedUrl);
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveBranchByIdInDetail = async (id) => {
  try {
    const constructedUrl =
      `${connection.base.management.url}${endpoints.management.branches.context}${endpoints.management.branches.dto.id.detail.context}`.replace(
        "{id}",
        id
      );
    const response = await axios.get(constructedUrl);
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveAllBranchDTOList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.branches.context}${endpoints.management.branches.dto.context}/`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createBranchByGradeId = async (newBranch) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.branches.context}${endpoints.management.branches["by-gradeId"].context}`,
      newBranch
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchBranch = async (patchBranch) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.branches.context}/`,
      patchBranch
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteBranch = async (branchId) => {
  try {
    const response = await axios.delete(
      `${connection.base.management.url}${endpoints.management.branches.context}/${branchId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryBranchListBySeasonId = async (seasonId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.branches.context}${endpoints.management.branches.query.dto["by-seasonId"].context}`,
      {
        seasonId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryBranchListByGradeId = async (gradeId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.branches.context}${endpoints.management.branches.query.dto["by-gradeId"].context}`,
      {
        gradeId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryBranchListByGradeIdList = async (gradeIdList) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.branches.context}${endpoints.management.branches.query.dto["by-gradeIdList"].context}`,
      {
        gradeIdList,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryBranchListByUserProfileId = async (userProfileId) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.branches.context}${endpoints.management.branches.query.dto["by-userProfileId"].context}`,
      {
        userProfileId,
        deleted: false,
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryBranchListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.branches.context}${endpoints.management.branches.query.dto.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllBranchDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.branches.context}${endpoints.management.branches.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllActiveBranchDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.branches.context}${endpoints.management.branches.paged.query.dto.active["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Default User Profile Authorities
 */
export const retrieveDefaultUserProfileAuthorityByRole = async (userRole) => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.users["profiles-default-authorities"].context}/${userRole}`
    );
    return { data: response.data, status: response.data };
  } catch (err) {
    return { err };
  }
};

export const addOrRemoveUserAuthorityToDefaultUserRole = async (
  userRole,
  includedIdList,
  removedIdList
) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users["profiles-default-authorities"].context}${endpoints.management.users["profiles-default-authorities"]["add-or-remove"].multiple["user-authority-to-default-user-role"].context}`,
      { userRole, includedIdList, removedIdList }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * User Logins
 */
export const queryUserLoginDTOByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.logins.context}${endpoints.management.users.logins.query.dto.detail["by-params"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * User Email
 */
export const patchAndVerifyUserEmail = async (params) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.patch(
      `${connection.base.management.url}${endpoints.management.generated.code.context}${endpoints.management.generated.code["email-verification"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchAndChangeUserPassword = async (params) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.patch(
      `${connection.base.management.url}${endpoints.management.generated.code.context}${endpoints.management.generated.code["change-password"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchAndForgotUserPassword = async (params) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.patch(
      `${connection.base.management.url}${endpoints.management.generated.code.context}${endpoints.management.generated.code["forgot-password"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const randomizePassword = async (params) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users["randomize-password"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Integrations
 */
export const retrieveAllK12OrganizationList = async () => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.integrations.context}${endpoints.management.integrations.k12.organizations.context}`
    );
    return { data: response.data, status: response.data };
  } catch (err) {
    return { err };
  }
};

export const retrieveAllK12StudentListByOrganizationId = async (
  organizationId
) => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management.integrations.context}${endpoints.management.integrations.k12.organizations.id.students.context}`.replace(
        "{id}",
        organizationId
      )
    );
    return { data: response.data, status: response.data };
  } catch (err) {
    return { err };
  }
};

/**
 * External Registration
 */
export const queryAllExternalRegistrationDTOPageByFilter = async (
  pagedParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management["external-registrations"].context}${endpoints.management["external-registrations"].paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryExternalRegistrationDTOListByGradeId = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management["external-registrations"].context}${endpoints.management["external-registrations"].query.dto["by-gradeId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createExternalRegistration = async (newExternalRegistration) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management["external-registrations"].context}/`,
      newExternalRegistration
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchExternalRegistration = async (patchExternalRegistration) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management["external-registrations"].context}/`,
      patchExternalRegistration
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteExternalRegistration = async (externalRegistrationId) => {
  try {
    const response = await axios.delete(
      `${connection.base.management.url}${endpoints.management["external-registrations"].context}/${externalRegistrationId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveExternalRegistrationDTOById = async (
  externalRegistrationId
) => {
  try {
    const response = await axios.get(
      `${connection.base.management.url}${endpoints.management["external-registrations"].context}${endpoints.management["external-registrations"].dto.id.context}`.replace(
        "{externalRegistrationId}",
        externalRegistrationId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createExternalLearnerImportFromExcel = async (
  externalRegistrationId,
  createParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management["external-registrations"].context}${endpoints.management["external-registrations"].externalRegistrationId["import-from-excel"].context}`.replace(
        "{externalRegistrationId}",
        externalRegistrationId
      ),
      createParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * External Learner
 */
export const queryExternalLearnerDTOListByExternalRegistrationId = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management["external-learners"].context}${endpoints.management["external-learners"].query.dto["by-externalRegistrationId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createExternalLearner = async (newExternalLearner) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management["external-learners"].context}/`,
      newExternalLearner
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchExternalLearner = async (currentExternalLearner) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management["external-learners"].context}/`,
      currentExternalLearner
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteExternalLearner = async (externalLearnerId) => {
  try {
    const response = await axios.delete(
      `${connection.base.management.url}${endpoints.management["external-learners"].context}/${externalLearnerId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const searchExternalLearnerListWithStateByParams = async (
  externalRegistrationId,
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management["external-registrations"].context}${endpoints.management["external-registrations"].externalRegistrationId.search.multiple["with-states"].context}`.replace(
        "{externalRegistrationId}",
        externalRegistrationId
      ),
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
