import { useEffect, useState } from "react";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  queryAssignActivityBundleDTOListByBrandId,
  queryAssignActivityBundleDTOListByBrandIdAndCampusIdAndInstitutionId_Seperately,
  queryAssignActivityBundleDTOListByBrandIdAndCampusId_Seperately,
} from "../../requests/BundleRequests";
import { queryActivityDTOListByIdList } from "../../requests/CatchtivityRequests";
import FullCard from "../cards/FullCard";
import AdvancedTable from "../tables/AdvancedTable";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import { shuffleArray } from "../../utilization/AppUtilization";
import BaseImage from "../images/BaseImage";
import BaseLoading from "../loading/BaseLoading";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const ListActivityBundle = (props) => {
  const {
    usePadding,
    selectable,
    initialFilterApplied,
    initialCoterieType,
    initialLevel,
    selectedItemIdList,
    categoryOccurrenceList,
    chooseActivityParams,
    handleSelectOnClick,
    handleInitialSelectListOnClick,
    activityTemplateTypeList,
  } = props;
  const {
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    categorySet,
  } = useAuth();
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [assignedActivityBundleDTOList, setAssignedActivityBundleDTOList] =
    useState([]);
  const [selectedActivityBundleBrandId, setSelectedActivityBundleBrandId] =
    useState("DEFAULT_OPTION");
  const [selectedActivityBundleId, setSelectedActivityBundleId] =
    useState("DEFAULT_OPTION");
  const [activityDTOList, setActivityDTOList] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(
    initialFilterApplied ? initialFilterApplied : false
  );
  const [filterCategoryCode, setFilterCategoryCode] =
    useState("DEFAULT_OPTION");
  const [filterSubCategoryCode, setFilterSubCategoryCode] =
    useState("DEFAULT_OPTION");
  const [filterOutcomeCode, setFilterOutcomeCode] = useState("DEFAULT_OPTION");

  useEffect(() => {
    if (!initialFilterApplied) return;
    setIsFilterApplied(initialFilterApplied);
  }, [initialFilterApplied]);

  useEffect(() => {
    const retrieveAndSetActivityBundleDTOByBrandId = async () => {
      setLoading(true);
      const { data, err } = await queryAssignActivityBundleDTOListByBrandId(
        userProfileBrand.id
      );
      if (err) {
        console.log(err);
        return;
      }
      setAssignedActivityBundleDTOList(data);
      setLoading(false);
    };
    const retrieveAndSetActivityBundleDTOByBrandIdCampusId = async () => {
      setLoading(true);
      const { data, err } =
        await queryAssignActivityBundleDTOListByBrandIdAndCampusId_Seperately(
          userProfileBrand.id,
          userProfileCampus.id
        );
      if (err) {
        console.log(err);
        return;
      }
      setAssignedActivityBundleDTOList(data);
      setLoading(false);
    };
    const retrieveAndSetActivityBundleDTOBrandIdCampusIdInstitutionId =
      async () => {
        setLoading(true);
        const { data, err } =
          await queryAssignActivityBundleDTOListByBrandIdAndCampusIdAndInstitutionId_Seperately(
            userProfileBrand.id,
            userProfileCampus.id,
            userProfileInstitution.id
          );
        if (err) {
          console.log(err);
          return;
        }
        setAssignedActivityBundleDTOList(data);
        setLoading(false);
      };
    if (userProfileInstitution) {
      retrieveAndSetActivityBundleDTOBrandIdCampusIdInstitutionId();
    } else if (userProfileCampus) {
      retrieveAndSetActivityBundleDTOByBrandIdCampusId();
    } else if (userProfileBrand) {
      retrieveAndSetActivityBundleDTOByBrandId();
    }
  }, []);

  useEffect(() => {
    if (assignedActivityBundleDTOList.length === 0) return;
    if (assignedActivityBundleDTOList.length === 1) {
      const { activityBundleDTO } = assignedActivityBundleDTOList[0];
      setSelectedActivityBundleBrandId(activityBundleDTO.brandId);
    }
  }, [assignedActivityBundleDTOList]);

  useEffect(() => {
    const retrieveAndSetActivityDTOList = async () => {
      setTableLoading(true);
      const foundAssignedActivityBundleDTO = assignedActivityBundleDTOList.find(
        (assignedActivityBundleDTO) =>
          assignedActivityBundleDTO.activityBundleDTO.id ===
          parseFloat(selectedActivityBundleId)
      );
      const { data, err } = await queryActivityDTOListByIdList(
        foundAssignedActivityBundleDTO.activityBundleDTO.activityIdList
      );
      if (err) {
        console.log(err);
      } else {
        const foundActivityBundleOption =
          retrieveActivityBundleNameDTOOptionList().find(
            (activityBundleDTOOption) =>
              parseFloat(activityBundleDTOOption.value) ===
              parseFloat(selectedActivityBundleId)
          );
        const foundActivityBundleBrandOption =
          retrieveActivityBundleBrandDTOOptionList().find(
            (activityBundleBrandDTOOption) =>
              parseFloat(activityBundleBrandDTOOption.value) ===
              parseFloat(selectedActivityBundleBrandId)
          );
        if (activityTemplateTypeList) {
          const filteredData = [];
          for (const dataItem of data) {
            for (const activityTemplateType of activityTemplateTypeList) {
              const filteredActivityTemplateDTOList =
                dataItem.activityTemplateDTOList.filter(
                  (activityTemplateDTO) =>
                    activityTemplateDTO.type === activityTemplateType
                );
              if (filteredActivityTemplateDTOList.length > 0) {
                const foundIndex = filteredData.findIndex(
                  (filteredDataItem) => filteredDataItem.id === dataItem.id
                );
                if (foundIndex === -1) {
                  filteredData.push(dataItem);
                }
              }
            }
          }
          setActivityDTOList(
            filteredData.map((dataItem) => ({
              ...dataItem,
              brandName: foundActivityBundleBrandOption.text,
              bundleName: foundActivityBundleOption.text,
            }))
          );
        } else {
          setActivityDTOList(
            data.map((dataItem) => ({
              ...dataItem,
              brandName: foundActivityBundleBrandOption.text,
              bundleName: foundActivityBundleOption.text,
            }))
          );
        }

        setTotalItemCount(data.length);
      }
      setTableLoading(false);
    };
    if (selectedActivityBundleId !== "DEFAULT_OPTION") {
      retrieveAndSetActivityDTOList();
    }
  }, [selectedActivityBundleId]);

  useEffect(() => {
    if (!categoryOccurrenceList) return;
    if (categoryOccurrenceList.length === 0) return;
    if (!activityDTOList) return;
    if (activityDTOList.length === 0) return;
    if (selectedItemIdList.length > 0) return;
    let currentNumberOfActivity = 10;
    let currentMustCategoryRatio = 1.0;
    if (chooseActivityParams) {
      const { numberOfActivity, mustCategoryRatio } = chooseActivityParams;
      currentNumberOfActivity = numberOfActivity;
      currentMustCategoryRatio = mustCategoryRatio;
    }
    if (activityDTOList.length <= currentNumberOfActivity) {
      handleInitialSelectListOnClick(activityDTOList);
    }
    const shuffleActivityDTOList = shuffleArray(activityDTOList);

    const randomMustActivityDTOSet = new Set();
    const mustCategoryOccurrenceList = categoryOccurrenceList.filter(
      (categoryOccurrence) => categoryOccurrence.isMust
    );
    const mustData = shuffleActivityDTOList.filter((item) => {
      const { categoryIdList } = item;
      let exists = false;
      for (const categoryId of categoryIdList) {
        if (
          mustCategoryOccurrenceList.findIndex(
            (mustCategoryOccurrence) =>
              mustCategoryOccurrence.categoryId === categoryId
          ) !== -1
        ) {
          exists = true;
          break;
        }
      }
      return exists;
    });
    const copyMustData = JSON.parse(JSON.stringify(mustData));
    const currentMustActivityNumber = Math.round(
      currentNumberOfActivity * currentMustCategoryRatio
    );
    let copyMustCategoryOccurrenceList = JSON.parse(
      JSON.stringify(mustCategoryOccurrenceList)
    );
    let mustHardStop = 0;
    for (let i = 0; i < mustData.length; i++) {
      mustHardStop++;
      if (mustHardStop > 50) break;
      const randomCategoryIndex = Math.floor(
        Math.random() * copyMustCategoryOccurrenceList.length
      );
      const categoryId =
        copyMustCategoryOccurrenceList[randomCategoryIndex].categoryId;
      copyMustCategoryOccurrenceList.splice(randomCategoryIndex, 1);
      if (copyMustCategoryOccurrenceList.length === 0) {
        copyMustCategoryOccurrenceList = JSON.parse(
          JSON.stringify(mustCategoryOccurrenceList)
        );
      }
      const filteredCopyData = copyMustData.filter(
        (item) =>
          item.categoryIdList.findIndex(
            (itemCategoryId) => itemCategoryId === categoryId
          ) !== -1
      );
      if (filteredCopyData.length === 0) {
        i--;
        continue;
      }
      const randomActivityIndex = Math.floor(
        Math.random() * filteredCopyData.length
      );
      const randomActivity = filteredCopyData[randomActivityIndex];
      const removeActivityIndex = copyMustData.findIndex(
        (item) => item.id === randomActivity.id
      );
      copyMustData.splice(removeActivityIndex, 1);
      randomMustActivityDTOSet.add(randomActivity);
      if (randomMustActivityDTOSet.size === currentMustActivityNumber) {
        break;
      }
    }
    const randomActivityDTOList = [];
    randomActivityDTOList.push(...Array.from(randomMustActivityDTOSet));

    const randomOtherActivityDTOSet = new Set();
    const otherCategoryOccurrenceList = categoryOccurrenceList.filter(
      (categoryOccurrence) => !categoryOccurrence.isMust
    );
    const otherData = shuffleActivityDTOList.filter((item) => {
      const { categoryIdList } = item;
      let exists = false;
      for (const categoryId of categoryIdList) {
        if (
          otherCategoryOccurrenceList.findIndex(
            (otherCategoryOccurrence) =>
              otherCategoryOccurrence.categoryId === categoryId
          ) !== -1
        ) {
          exists = true;
          break;
        }
      }
      return exists;
    });
    const copyOtherData = JSON.parse(JSON.stringify(otherData));
    const currentOtherActivityNumber =
      currentNumberOfActivity - randomMustActivityDTOSet.size;
    let copyOtherCategoryOccurrenceList = JSON.parse(
      JSON.stringify(otherCategoryOccurrenceList)
    );

    let otherHardStop = 0;
    if (copyOtherCategoryOccurrenceList.length !== 0) {
      for (let i = 0; i < otherData.length; i++) {
        otherHardStop++;
        if (otherHardStop > 50) break;
        const randomCategoryIndex = Math.floor(
          Math.random() * copyOtherCategoryOccurrenceList.length
        );
        const categoryId =
          copyOtherCategoryOccurrenceList[randomCategoryIndex].categoryId;
        copyOtherCategoryOccurrenceList.splice(randomCategoryIndex, 1);
        if (copyOtherCategoryOccurrenceList.length === 0) {
          copyOtherCategoryOccurrenceList = JSON.parse(
            JSON.stringify(otherCategoryOccurrenceList)
          );
        }
        const filteredCopyData = copyOtherData.filter(
          (item) =>
            item.categoryIdList.findIndex(
              (itemCategoryId) => itemCategoryId === categoryId
            ) !== -1
        );
        if (filteredCopyData.length === 0) {
          i--;
          continue;
        }
        const randomActivityIndex = Math.floor(
          Math.random() * filteredCopyData.length
        );
        const randomActivity = filteredCopyData[randomActivityIndex];
        const removeActivityIndex = copyOtherData.findIndex(
          (item) => item.id === randomActivity.id
        );
        copyOtherData.splice(removeActivityIndex, 1);
        if (
          randomActivityDTOList.findIndex(
            (existingRandomActivity) =>
              existingRandomActivity.id === randomActivity.id
          ) === -1
        ) {
          randomOtherActivityDTOSet.add(randomActivity);
        } else {
          i--;
          continue;
        }
        if (randomOtherActivityDTOSet.size === currentOtherActivityNumber) {
          break;
        }
      }
    }

    randomActivityDTOList.push(...Array.from(randomOtherActivityDTOSet));
    handleInitialSelectListOnClick(randomActivityDTOList);
  }, [activityDTOList, categoryOccurrenceList]);

  const retrieveActivityBundleBrandDTOOptionList = () => {
    const activityBundleBrandDTOList = [];
    assignedActivityBundleDTOList.forEach((assignedActivityBundle) => {
      const foundIndex = activityBundleBrandDTOList.findIndex(
        (activityBundleBrand) =>
          activityBundleBrand.id ===
          assignedActivityBundle.activityBundleDTO.brandId
      );
      if (foundIndex === -1) {
        activityBundleBrandDTOList.push({
          id: assignedActivityBundle.activityBundleDTO.brandId,
          name: assignedActivityBundle.activityBundleDTO.brandName,
        });
      }
    });
    return activityBundleBrandDTOList.map((activityBundleBrandDTO) => ({
      text: activityBundleBrandDTO.name,
      value: activityBundleBrandDTO.id,
    }));
  };

  const retrieveActivityBundleNameDTOOptionList = () => {
    let filteredAssignedActivityBundleDTOList =
      assignedActivityBundleDTOList.filter(
        (assignedActivityBundleDTO) =>
          parseFloat(assignedActivityBundleDTO.activityBundleDTO.brandId) ===
          parseFloat(selectedActivityBundleBrandId)
      );

    if (initialCoterieType && initialCoterieType !== "MANAGEMENT") {
      filteredAssignedActivityBundleDTOList =
        filteredAssignedActivityBundleDTOList.filter(
          (assignedActivityBundleDTO) =>
            assignedActivityBundleDTO.activityBundleDTO.coterieType ===
            initialCoterieType
        );
    }
    if (initialLevel) {
      filteredAssignedActivityBundleDTOList =
        filteredAssignedActivityBundleDTOList.filter(
          (assignedActivityBundleDTO) =>
            assignedActivityBundleDTO.activityBundleDTO.level === initialLevel
        );
    }
    const uniqueFilteredAssignedActivityBundleDTOList = [];
    for (const filteredAssignedActivityBundleDTO of filteredAssignedActivityBundleDTOList) {
      if (
        uniqueFilteredAssignedActivityBundleDTOList.findIndex(
          (item) =>
            item.activityBundleDTO.id ===
            filteredAssignedActivityBundleDTO.activityBundleDTO.id
        ) === -1
      ) {
        uniqueFilteredAssignedActivityBundleDTOList.push(
          filteredAssignedActivityBundleDTO
        );
      }
    }
    return uniqueFilteredAssignedActivityBundleDTOList.map(
      (assignedActivityBundleDTO) => ({
        text: assignedActivityBundleDTO.activityBundleDTO.name,
        value: assignedActivityBundleDTO.activityBundleDTO.id,
      })
    );
  };

  const retrieveSubCategoryCodeList = () => {
    const filteredCategoryDTOList = [];
    const filteredMainCategoryDTOList = categorySet.filter(
      (categoryDTO) => categoryDTO.versionCode === filterCategoryCode
    );
    for (const currentCategoryDTO of filteredMainCategoryDTOList) {
      const foundIndex = filteredMainCategoryDTOList.findIndex(
        (categoryDTO) => categoryDTO.parentCode === currentCategoryDTO.code
      );
      if (foundIndex !== -1) {
        filteredCategoryDTOList.push(currentCategoryDTO);
      }
    }

    return filteredCategoryDTOList
      .sort((a, b) => a.code.localeCompare(b.code))
      .map((categoryDTO) => ({
        value: categoryDTO.id,
        text: `${categoryDTO.code} - ${categoryDTO.name}`,
      }));
  };

  const retrieveOutcomeCodeList = () => {
    const foundCategory = categorySet.find(
      (category) => category.id === filterSubCategoryCode
    );
    if (!foundCategory) return [];
    const filteredCategoryList = categorySet.filter(
      (category) =>
        category.versionCode === foundCategory.versionCode &&
        category.level === foundCategory.level
    );

    const subCategoryList = categorySet.filter(
      (category) => category.parentCode === foundCategory.code
    );
    const filterOutcomeCodeList = [];
    for (const subCategory of subCategoryList) {
      const filteredSubCategoryList = filteredCategoryList.filter(
        (filteredCategory) => filteredCategory.code.startsWith(subCategory.code)
      );
      for (const filteredSubCategory of filteredSubCategoryList) {
        const foundIndex = filteredSubCategoryList.findIndex(
          (categoryDTO) => categoryDTO.parentCode === filteredSubCategory.code
        );
        if (foundIndex === -1) {
          filterOutcomeCodeList.push(filteredSubCategory);
        }
      }
    }

    return filterOutcomeCodeList
      .sort((a, b) => a.code.localeCompare(b.code))
      .map((categoryDTO) => ({
        value: categoryDTO.id,
        text: `${categoryDTO.code} - ${categoryDTO.name}`,
      }));
  };

  const retrieveSubCategoryCodeIdList = () => {
    const foundSubCategory = categorySet.find(
      (category) =>
        parseFloat(category.id) === parseFloat(filterSubCategoryCode)
    );
    if (foundSubCategory) {
      let currentCategorySet = [];
      currentCategorySet.concat(
        recursiveSubCategoryCodeIdList(
          categorySet,
          currentCategorySet,
          foundSubCategory.code
        )
      );
      return currentCategorySet.map((category) => category.id);
    }
    return [];
  };

  const retrieveCurrentCoterieType = () => {
    const foundAssignedActivityBundleDTO = assignedActivityBundleDTOList.find(
      (item) => item.activityBundleDTO.id === selectedActivityBundleId
    );
    if (!foundAssignedActivityBundleDTO) return "DEFAULT_OPTION";
    const { activityBundleDTO } = foundAssignedActivityBundleDTO;
    const { coterieType } = activityBundleDTO;
    return coterieType;
  };

  const recursiveSubCategoryCodeIdList = (
    categorySet,
    currentCategorySet,
    categoryCode
  ) => {
    const filterCategorySet = categorySet.filter(
      (category) => category.parentCode === categoryCode
    );
    filterCategorySet.forEach((filterCategory) => {
      const foundIndex = categorySet.findIndex(
        (categoryDTO) => categoryDTO.parentCode === filterCategory.code
      );
      if (foundIndex === -1) {
        const foundCurrentIndex = currentCategorySet.findIndex(
          (currentCategory) => currentCategory.id === filterCategory.id
        );
        if (foundCurrentIndex === -1) {
          currentCategorySet.push(filterCategory);
        }
      } else {
        recursiveSubCategoryCodeIdList(
          categorySet,
          currentCategorySet,
          filterCategory.code
        );
      }
    });
    return currentCategorySet;
  };

  const filterActivityDTOList = () => {
    const startIndex = pageNumber * selectedShowItemCount;
    const endIndex = (pageNumber + 1) * selectedShowItemCount;
    if (isFilterApplied) {
      if (
        filterCategoryCode !== "DEFAULT_OPTION" &&
        filterSubCategoryCode !== "DEFAULT_OPTION"
      ) {
        const filteredActivityDTOList = [];
        if (filterOutcomeCode !== "DEFAULT_OPTION") {
          activityDTOList.forEach((activityDTO) => {
            const ifExists =
              activityDTO.categoryIdList.includes(filterOutcomeCode);
            if (ifExists) filteredActivityDTOList.push(activityDTO);
          });
        } else {
          activityDTOList.forEach((activityDTO) => {
            const ifExists =
              subCategoryCodeIdList.filter(
                (subCategoryCodeId) =>
                  activityDTO.categoryIdList.findIndex(
                    (categoryId) =>
                      parseFloat(categoryId) === parseFloat(subCategoryCodeId)
                  ) !== -1
              ).length > 0;
            if (ifExists) filteredActivityDTOList.push(activityDTO);
          });
        }

        return filteredActivityDTOList;
      }
    }
    return activityDTOList.slice(startIndex, endIndex);
  };

  const checkCanNavigate = () => {
    return false;
  };

  const constructNavigatableList = () => {
    return [];
  };

  const handleOnChange = (type, value) => {
    if (type === "activityBundleBrandId") {
      setSelectedActivityBundleId("DEFAULT_OPTION");
      setSelectedActivityBundleBrandId(value);
    } else if (type === "activityBundleId") {
      setSelectedActivityBundleId(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "categoryCode") {
      setFilterCategoryCode(value);
      setFilterSubCategoryCode("DEFAULT_OPTION");
      setFilterOutcomeCode("DEFAULT_OPTION");
    } else if (type === "subCategoryCode") {
      setFilterSubCategoryCode(value);
      setFilterOutcomeCode("DEFAULT_OPTION");
    } else if (type === "outcomeCode") {
      setFilterOutcomeCode(value);
    }
  };

  const handlePreviewActivityOnClick = (activityDTO) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    window.open(
      `${connection.app.url}/preview/activity/${activityDTO.id}/${btoa(
        encodeURI(JSON.stringify({ token: accessToken }))
      )}`
    );
  };

  const filterAssignedActivityBundleDTOList = () => {
    let uniqueAssignedActivityBundleDTOList = [];
    for (const assignedActivityBundle of assignedActivityBundleDTOList) {
      const { activityBundleDTO, institutionId } = assignedActivityBundle;
      const { id, name } = activityBundleDTO;
      const foundIndex = uniqueAssignedActivityBundleDTOList.findIndex(
        (uniqueAssignedActivityBundleDTO) =>
          uniqueAssignedActivityBundleDTO.activityBundleDTO.id === id
      );
      if (foundIndex === -1)
        uniqueAssignedActivityBundleDTOList.push(assignedActivityBundle);
    }
    let filteredAssignedActivityBundleDTOList =
      uniqueAssignedActivityBundleDTOList;

    if (initialLevel) {
      filteredAssignedActivityBundleDTOList =
        filteredAssignedActivityBundleDTOList.filter(
          (item) => item.activityBundleDTO.level === initialLevel
        );
    }
    if (initialCoterieType && initialCoterieType !== "MANAGEMENT") {
      filteredAssignedActivityBundleDTOList =
        filteredAssignedActivityBundleDTOList.filter(
          (item) => item.activityBundleDTO.coterieType === initialCoterieType
        );
    }
    return filteredAssignedActivityBundleDTOList;
  };

  const subCategoryCodeIdList = retrieveSubCategoryCodeIdList();
  const filteredActivityDTOList = filterActivityDTOList();
  // const activityBundleBrandDTOOptionList =
  //   retrieveActivityBundleBrandDTOOptionList();
  // const activityBundleNameDTOOptionList =
  //   retrieveActivityBundleNameDTOOptionList();
  const filteredAssignedActivityBundleDTOList =
    filterAssignedActivityBundleDTOList();

  return (
    <FullCard usePadding={usePadding}>
      {loading ? (
        <BaseLoading size="medium" color="#57C2D3" secondaryColor="#57C2D3" />
      ) : (
        <>
          <div className="flex flex-row justify-center items-center flex-wrap mb-3 gap-x-2 gap-y-2 p-2">
            {filteredAssignedActivityBundleDTOList.map(
              (assignedActivityBundleDTO, index) => (
                <div
                  key={index}
                  className={`${
                    assignedActivityBundleDTO.activityBundleDTO.id ===
                    selectedActivityBundleId
                      ? "border-catchup-blue-500"
                      : "border-catchup-gray-100 hover:border-catchup-blue-500"
                  } w-[200px] h-[110px] border rounded-catchup-medium px-4 py-2 cursor-pointer transition-all duration-300 hover:shadow-input`}
                  onClick={() => {
                    const { activityBundleDTO } = assignedActivityBundleDTO;
                    const { id, brandId } = activityBundleDTO;
                    setSelectedActivityBundleBrandId(brandId);
                    setSelectedActivityBundleId(id);
                  }}
                >
                  <div className="flex flex-col justify-center items-center text-center gap-y-2">
                    <BaseImage src="/icons/book.png" alt="book" size="large" />
                    <p className="">
                      {assignedActivityBundleDTO.activityBundleDTO.name}
                    </p>
                  </div>
                </div>
              )
            )}
          </div>
          {selectedActivityBundleBrandId !== "DEFAULT_OPTION" &&
          selectedActivityBundleId !== "DEFAULT_OPTION" ? (
            <AdvancedTable
              usePadding={usePadding}
              title={null}
              description={null}
              itemName={i18n.t("activity")}
              headerList={[
                {
                  name: i18n.t("brand_name"),
                  key: "brandName",
                },
                {
                  name: i18n.t("bundle_name"),
                  key: "bundleName",
                },
                { name: i18n.t("name"), key: "name", type: "info" },
                {
                  name: i18n.t("coterie_type"),
                  key: "coterieType",
                  type: "enumeration",
                },
                {
                  name: i18n.t("level"),
                  key: "level",
                },
              ]}
              selectable={selectable}
              showFilter={true}
              showItemCount={selectedShowItemCount}
              totalItemCount={totalItemCount}
              pageNumber={pageNumber}
              loading={tableLoading}
              sortKey={sortKey}
              sortDirection={sortDirection}
              handleOnFilterChange={handleOnFilterChange}
              handleOnTableParamsChange={handleOnTableParamsChange}
              filterParams={{
                isFilterApplied,
                showBeginDate: false,
                showEndDate: false,
                showCategoryCode: true,
                categoryCode: filterCategoryCode,
                coterieType: retrieveCurrentCoterieType(),
                subCategoryCodeList: retrieveSubCategoryCodeList(),
                subCategoryCode: filterSubCategoryCode,
                outcomeCodeList: retrieveOutcomeCodeList(),
                outcomeCode: filterOutcomeCode,
              }}
              itemList={filteredActivityDTOList}
              selectedItemIdList={selectedItemIdList}
              handleSelectOnClick={handleSelectOnClick}
              insertable={false}
              updatable={false}
              deletable={false}
              previewable={true}
              handlePreviewOnClick={handlePreviewActivityOnClick}
              navigatable={checkCanNavigate()}
              navigateOptionList={constructNavigatableList()}
            />
          ) : null}
        </>
      )}
    </FullCard>
  );
};

export default ListActivityBundle;
