import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import {
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import {
  createExternalLearner,
  deleteExternalLearner,
  patchExternalLearner,
  queryExternalLearnerDTOListByExternalRegistrationId,
} from "../../requests/ManagementRequests";
import AdvancedTable from "../../components/tables/AdvancedTable";
import i18n from "../../language/i18n";
import BaseModal from "../../components/modal/BaseModal";
import FullCard from "../../components/cards/FullCard";
import BaseImage from "../../components/images/BaseImage";
import InputGroup from "../../components/groups/InputGroup";
import { NotificationManager } from "react-notifications";
import BaseTitle from "../../components/titles/BaseTitle";
import DividerLine from "../../components/divider/DividerLine";
import CreateButton from "../../components/buttons/CreateButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";

const ExternalLearnerListScreen = () => {
  const { externalRegistrationId } = useParams();
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [externalLearnerDTOList, setExternalLearnerDTOList] = useState([]);
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterTextContains, setFilterTextContains] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [showCreateExternalLearnerModal, setShowCreateExternalLearnerModal] =
    useState(false);
  const [pageState, setPageState] = useState(0);
  const [id, setId] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [applicationNumber, setApplicationNumber] = useState("");
  const [identityNumber, setIdentityNumber] = useState(0);
  const [session, setSession] = useState(0);
  const [errorMap, setErrorMap] = useState({
    firstName: null,
    lastName: null,
    applicationNumber: null,
    identityNumber: null,
    session: null,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (checkViewPermissionGranted("external_registration_list")) {
      retrieveAndSetExternalLearnerDTOListByExternalRegistrationId();
    } else {
      navigate("/permission-error");
    }
  }, []);

  const retrieveAndSetExternalLearnerDTOListByExternalRegistrationId =
    async () => {
      setTableLoading(true);
      const { data, err } =
        await queryExternalLearnerDTOListByExternalRegistrationId({
          externalRegistrationId,
        });
      if (err) {
        console.log(err);
        return;
      }
      setExternalLearnerDTOList(data);
      setTableLoading(false);
    };

  const checkCommonValidation = () => {
    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !applicationNumber.trim() ||
      !identityNumber ||
      !session
    ) {
      const currentErrorMap = {
        firstName: null,
        lastName: null,
        applicationNumber: null,
        identityNumber: null,
        session: null,
      };
      if (!firstName.trim()) {
        currentErrorMap.firstName = i18n.t("first_name_required_field");
      }
      if (!lastName.trim()) {
        currentErrorMap.lastName = i18n.t("last_name_required_field");
      }
      if (!applicationNumber.trim()) {
        currentErrorMap.applicationNumber = i18n.t(
          "application_number_required_field"
        );
      }
      if (!identityNumber) {
        currentErrorMap.identityNumber = i18n.t(
          "identity_number_required_field"
        );
      }
      if (!session) {
        currentErrorMap.session = i18n.t("session_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const resetParams = () => {
    setId(null);
    setFirstName("");
    setLastName("");
    setApplicationNumber("");
    setIdentityNumber(0);
    setSession(0);
  };

  const filterExternalLearnerDTOList = () => {
    const startIndex = pageNumber * selectedShowItemCount;
    const endIndex = (pageNumber + 1) * selectedShowItemCount;
    const copyExternalLearnerDTOList = JSON.parse(
      JSON.stringify(externalLearnerDTOList)
    );
    if (sortKey === "createdAt") {
      copyExternalLearnerDTOList.sort((a, b) => a.createdAt < b.createdAt);
    } else if (sortKey === "applicationNumber") {
      copyExternalLearnerDTOList.sort(
        (a, b) => a.applicationNumber < b.applicationNumber
      );
    } else if (sortKey === "firstName") {
      copyExternalLearnerDTOList.sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
      );
    } else if (sortKey === "lastName") {
      copyExternalLearnerDTOList.sort((a, b) =>
        a.lastName.localeCompare(b.lastName)
      );
    } else if (sortKey === "identityNumber") {
      copyExternalLearnerDTOList.sort(
        (a, b) => a.identityNumber < b.identityNumber
      );
    } else if (sortKey === "session") {
      copyExternalLearnerDTOList.sort((a, b) => a.session < b.session);
    }
    if (sortDirection === "ASC") {
      copyExternalLearnerDTOList.reverse();
    }
    return copyExternalLearnerDTOList.slice(startIndex, endIndex);
  };

  const handleOnChange = (type, value) => {
    if (type === "firstName") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          firstName: i18n.t("first_name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          firstName: null,
        }));
      }
      setFirstName(value);
    } else if (type === "lastName") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          lastName: i18n.t("last_name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          lastName: null,
        }));
      }
      setLastName(value);
    } else if (type === "applicationNumber") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          applicationNumber: i18n.t("application_number_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          applicationNumber: null,
        }));
      }
      setApplicationNumber(value);
    } else if (type === "identityNumber") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          identityNumber: i18n.t("identity_number_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          identityNumber: null,
        }));
      }
      setIdentityNumber(value);
    } else if (type === "session") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          session: i18n.t("session_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          session: null,
        }));
      }
      setSession(value);
    }
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "textContains") {
      setFilterTextContains(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleCreateExternalLearnerOnClick = async () => {
    if (checkUpdatePermissionGranted("external_registration_list")) {
      if (checkCommonValidation()) {
        const newExternalLearner = {
          firstName,
          lastName,
          applicationNumber,
          identityNumber,
          session,
          externalRegistration: {
            id: externalRegistrationId,
          },
        };
        const { data, err } = await createExternalLearner(newExternalLearner);
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          retrieveAndSetExternalLearnerDTOListByExternalRegistrationId();
          handleReturnFromExternalLearnerOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleUpdateExternalLearnerOnClick = async () => {
    if (checkUpdatePermissionGranted("external_registration_list")) {
      if (checkCommonValidation()) {
        const currentExternalLearner = {
          id,
          firstName,
          lastName,
          applicationNumber,
          identityNumber,
          session,
          externalRegistration: {
            id: externalRegistrationId,
          },
        };
        const { data, err } = await patchExternalLearner(
          currentExternalLearner
        );
        if (err) {
          console.log(err);
        } else {
          retrieveAndSetExternalLearnerDTOListByExternalRegistrationId();
          handleReturnFromExternalLearnerOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleToCreateExternalLearnerOnClick = () => {
    setShowCreateExternalLearnerModal(true);
  };

  const handleToUpdateExternalLearnerOnClick = (externalLearnerDTO) => {
    setId(externalLearnerDTO.id);
    setApplicationNumber(externalLearnerDTO.applicationNumber);
    setFirstName(externalLearnerDTO.firstName);
    setLastName(externalLearnerDTO.lastName);
    setIdentityNumber(externalLearnerDTO.identityNumber);
    setSession(externalLearnerDTO.session);
    setPageState(1);
  };

  const handleRemoveExternalLearnerOnClick = async (externalLearnerId) => {
    if (checkUpdatePermissionGranted("external_registration_list")) {
      const { data, err } = await deleteExternalLearner(externalLearnerId);
      if (err) {
        console.log(err);
        if (err?.response?.data) {
          NotificationManager.error(i18n.t(err.response.data));
        }
      } else {
        const foundIndex = externalLearnerDTOList.findIndex(
          (externalLearnerDTO) => externalLearnerDTO.id === externalLearnerId
        );
        externalLearnerDTOList.splice(foundIndex, 1);
        setExternalLearnerDTOList(
          JSON.parse(JSON.stringify(externalLearnerDTOList))
        );
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_delete"));
    }
  };

  const handleReturnFromExternalLearnerOnClick = () => {
    resetParams();
    setPageState(0);
  };

  const RenderCreateExternalLearnerModal = () => {
    return (
      <BaseModal
        isOpen={showCreateExternalLearnerModal}
        size="small"
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowCreateExternalLearnerModal(false);
        }}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            <div className="my-10">
              <div className="flex flex-row justify-center items-center gap-x-2">
                <div
                  className="w-[250px] flex flex-col items-center gap-y-3 cursor-pointer p-10 border border-catchup-gray-100 hover:border-catchup-blue-500 rounded-catchup-xlarge transition-all duration-300 hover:shadow-input"
                  onClick={() => {
                    navigate(
                      `/external-registrations/${externalRegistrationId}/external-learners/create/import-from-excel`
                    );
                  }}
                >
                  <BaseImage
                    size="large"
                    src="/icons/import-from-excel.png"
                    alt="import-from-excel"
                  />
                  <p className="font-semibold text-xl text-catchup-gray-400">
                    {i18n.t("import_from_excel")}
                  </p>
                </div>

                <div
                  className="w-[250px] flex flex-col items-center gap-y-3 cursor-pointer p-10 border border-catchup-gray-100 hover:border-catchup-blue-500 rounded-catchup-xlarge transition-all duration-300 hover:shadow-input"
                  onClick={() => {
                    setPageState(1);
                    setShowCreateExternalLearnerModal(false);
                  }}
                >
                  <BaseImage
                    size="large"
                    src="/icons/user-profile-2.png"
                    alt="user-profile"
                  />
                  <p className="font-semibold text-xl text-catchup-gray-400">
                    {i18n.t("custom")}
                  </p>
                </div>
              </div>
            </div>
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={i18n.t("external_learner_list")}
            description={i18n.t("external_learner_list_table_description_text")}
            itemName={i18n.t("external_learner")}
            headerList={[
              {
                name: i18n.t("first_name"),
                key: "firstName",
              },
              {
                name: i18n.t("last_name"),
                key: "lastName",
              },
              {
                name: i18n.t("application_number"),
                key: "applicationNumber",
              },
              {
                name: i18n.t("identity_number"),
                key: "identityNumber",
              },
              {
                name: i18n.t("session"),
                key: "session",
              },
              {
                name: i18n.t("created_at"),
                key: "createdAt",
                type: "datetime",
              },
            ]}
            selectable={false}
            showFilter={true}
            showItemCount={selectedShowItemCount}
            totalItemCount={totalItemCount}
            pageNumber={pageNumber}
            loading={tableLoading}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleOnFilterChange={handleOnFilterChange}
            handleOnTableParamsChange={handleOnTableParamsChange}
            filterParams={{
              isFilterApplied,
              showBeginDate: true,
              beginAt: filterBeginAt,
              showEndDate: true,
              endAt: filterEndAt,
              showTextContains: true,
              textContains: filterTextContains,
            }}
            itemList={filteredExternalLearnerDTOList}
            insertable={checkUpdatePermissionGranted(
              "external_registration_list"
            )}
            handleInsertOnClick={handleToCreateExternalLearnerOnClick}
            updatable={checkUpdatePermissionGranted(
              "external_registration_list"
            )}
            handleUpdateOnClick={handleToUpdateExternalLearnerOnClick}
            deletable={checkUpdatePermissionGranted(
              "external_registration_list"
            )}
            handleDeleteOnClick={handleRemoveExternalLearnerOnClick}
            navigatable={false}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <FullCard isShadowed={true}>
            <div className="">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      resetParams();
                      setPageState(0);
                    }}
                  />
                </div>
                <BaseTitle title={i18n.t("create_custom_external_learner")} />
              </div>
            </div>
            <div className="">
              <div className="flex flex-col items-center justify-center text-catchup-dark-blue">
                <DividerLine />
                <div className="w-full">
                  <p className="font-semibold text-lg">
                    {i18n.t("external_learner_information")}
                  </p>
                </div>
                <div className="w-full flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("application_number")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="text"
                      value={applicationNumber}
                      onChange={(e) => {
                        handleOnChange("applicationNumber", e.target.value);
                      }}
                      errorText={errorMap["applicationNumber"]}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("first_name")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="text"
                      value={firstName}
                      onChange={(e) => {
                        handleOnChange("firstName", e.target.value);
                      }}
                      errorText={errorMap["firstName"]}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("last_name")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="text"
                      value={lastName}
                      onChange={(e) => {
                        handleOnChange("lastName", e.target.value);
                      }}
                      errorText={errorMap["lastName"]}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("identity_number")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="number"
                      value={identityNumber}
                      onChange={(e) => {
                        handleOnChange("identityNumber", e.target.value);
                      }}
                      errorText={errorMap["identityNumber"]}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("session")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="number"
                      value={session}
                      onChange={(e) => {
                        handleOnChange("session", e.target.value);
                      }}
                      errorText={errorMap["session"]}
                    />
                  </div>
                </div>

                <div className="mt-4 ml-auto">
                  {id ? (
                    <SecondaryButton
                      title={i18n.t("update")}
                      size="medium"
                      onClick={handleUpdateExternalLearnerOnClick}
                    />
                  ) : (
                    <CreateButton
                      title={i18n.t("create")}
                      size="medium"
                      onClick={handleCreateExternalLearnerOnClick}
                    />
                  )}
                </div>
              </div>
            </div>
          </FullCard>
        </div>
      </div>
    );
  };

  const filteredExternalLearnerDTOList = filterExternalLearnerDTOList();

  return (
    <div className="flex-1 flex flex-col">
      {RenderCreateExternalLearnerModal()}
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ExternalLearnerListScreen;
