import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import { useApp } from "../../context/AppContextProvider";
import { retrieveExternalExamDTOResultsById } from "../../requests/CatchtivityRequests";
import AdvancedTable from "../../components/tables/AdvancedTable";
import SelectionTab from "../../components/tabs/SelectionTab";
import i18n from "../../language/i18n";
import BaseImage from "../../components/images/BaseImage";
import { retrieveMainFileById } from "../../requests/StorageRequests";
import { queryConvertedFileList } from "../../requests/FileConverterRequests";
import { useAuth } from "../../context/UserContextProvider";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import BaseLoading from "../../components/loading/BaseLoading";

const ExternalExamResultScreen = () => {
  const navigate = useNavigate();
  const { externalExamId } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const { userProfileBrand } = useAuth();
  const [externalExamResultDTO, setExternalExamResultDTO] = useState(false);
  const [externalExamAnswerFile, setExternalExamAnswerFile] = useState(null);
  const [externalExamAnswerFileLoading, setExternalExamAnswerFileLoading] =
    useState(false);
  const [convertedFileList, setConvertedFileList] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [pageState, setPageState] = useState(0);
  const [attendedSelectedShowItemCount, setAttendedSelectedShowItemCount] =
    useState(100);
  const [attendedTotalItemCount, setAttendedTotalItemCount] = useState(0);
  const [attendedPageNumber, setAttendedPageNumber] = useState(0);
  const [attendedSortKey, setAttendedSortKey] = useState("score");
  const [attendedSortDirection, setAttendedSortDirection] = useState("DESC");
  const [unAttendedSelectedShowItemCount, setUnAttendedSelectedShowItemCount] =
    useState(100);
  const [unAttendedTotalItemCount, setUnAttendedTotalItemCount] = useState(0);
  const [unAttendedPageNumber, setUnAttendedPageNumber] = useState(0);
  const [unAttendedSortKey, setUnAttendedSortKey] = useState("score");
  const [unAttendedSortDirection, setUnAttendedSortDirection] =
    useState("DESC");

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("external_exam_list")) {
      navigate("/permission-error");
      return;
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetExternalExamResultDTO = async () => {
      setTableLoading(true);
      const { data, err } = await retrieveExternalExamDTOResultsById(
        externalExamId
      );
      if (err) {
        console.log(err);
        navigate(`/external-exams/${externalExamId}/external-exam-answers`);
        return;
      }
      setExternalExamResultDTO(data);
      setTableLoading(false);
    };
    if (!externalExamId) return;
    retrieveAndSetExternalExamResultDTO();
  }, [externalExamId]);

  useEffect(() => {
    const retrieveAndSetExternalExamAnswerFile = async () => {
      const { externalExamAnswerFileId } = externalExamResultDTO;
      setExternalExamAnswerFileLoading(true);
      const { data, err } = await retrieveMainFileById(
        externalExamAnswerFileId
      );
      if (err) {
        console.log(err);
        return;
      }
      setExternalExamAnswerFile(data);
      setExternalExamAnswerFileLoading(false);
    };
    if (!externalExamResultDTO) return;
    const { evaluationStatus } = externalExamResultDTO;
    if (evaluationStatus === "NOT_EVALUATED") {
      navigate(`/external-exams/${externalExamId}/external-exam-answers`);
    } else {
      retrieveAndSetExternalExamAnswerFile();
    }
  }, [externalExamResultDTO]);

  useEffect(() => {
    const retrieveAndSetConvertedFileList = async () => {
      const { data, err } = await queryConvertedFileList({
        fileFolder: `/${userProfileBrand.id}/pdfs/${externalExamAnswerFile.fileName}`,
      });
      if (err) {
        console.log(err);
        return;
      }
      if (data.length === 0) {
      } else {
        setConvertedFileList(data);
      }
    };
    if (!externalExamAnswerFile) return;
    retrieveAndSetConvertedFileList();
  }, [externalExamAnswerFile]);

  const retrieveExternalExamResultLearnerDTOList = () => {
    if (!externalExamResultDTO) return [];
    const { externalExamResultLearnerDTOList } = externalExamResultDTO;
    return externalExamResultLearnerDTOList;
  };

  const filterAttendedExternalExamResultLearnerDTOList = (
    externalExamResultLearnerDTOList
  ) => {
    return externalExamResultLearnerDTOList.filter(
      (item) => item.score !== null
    );
  };

  const filterUnAttendedExternalExamResultLearnerDTOList = (
    externalExamResultLearnerDTOList
  ) => {
    return externalExamResultLearnerDTOList.filter(
      (item) => item.score === null
    );
  };

  const filterPagedAttendedExternalExamResultLearnerDTOList = (
    externalExamResultLearnerDTOList
  ) => {
    const startIndex = attendedPageNumber * attendedSelectedShowItemCount;
    const endIndex = (attendedPageNumber + 1) * attendedSelectedShowItemCount;
    const copyExternalExamResultLearnerDTOList = JSON.parse(
      JSON.stringify(externalExamResultLearnerDTOList)
    );
    if (attendedSortKey === "firstName") {
      copyExternalExamResultLearnerDTOList.sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
      );
    } else if (attendedSortKey === "lastName") {
      copyExternalExamResultLearnerDTOList.sort((a, b) =>
        a.lastName.localeCompare(b.lastName)
      );
    } else if (attendedSortKey === "identityNumber") {
      copyExternalExamResultLearnerDTOList.sort((a, b) =>
        a.identityNumber.localeCompare(b.identityNumber)
      );
    } else if (attendedSortKey === "score") {
      copyExternalExamResultLearnerDTOList.sort((a, b) => b.score - a.score);
    } else if (attendedSortKey === "rank") {
      copyExternalExamResultLearnerDTOList.sort((a, b) => b.rank - a.rank);
    }
    if (attendedSortDirection === "ASC") {
      copyExternalExamResultLearnerDTOList.reverse();
    }
    return copyExternalExamResultLearnerDTOList
      .slice(startIndex, endIndex)
      .map((item) => ({ ...item, score: item.score.toFixed(2) }));
  };

  const filterPagedUnAttendedExternalExamResultLearnerDTOList = (
    externalExamResultLearnerDTOList
  ) => {
    const startIndex = unAttendedPageNumber * unAttendedSelectedShowItemCount;
    const endIndex =
      (unAttendedPageNumber + 1) * unAttendedSelectedShowItemCount;
    const copyExternalExamResultLearnerDTOList = JSON.parse(
      JSON.stringify(externalExamResultLearnerDTOList)
    );
    if (unAttendedSortKey === "firstName") {
      copyExternalExamResultLearnerDTOList.sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
      );
    } else if (unAttendedSortKey === "lastName") {
      copyExternalExamResultLearnerDTOList.sort((a, b) =>
        a.lastName.localeCompare(b.lastName)
      );
    } else if (unAttendedSortKey === "identityNumber") {
      copyExternalExamResultLearnerDTOList.sort((a, b) =>
        a.identityNumber.localeCompare(b.identityNumber)
      );
    }
    if (unAttendedSortDirection === "ASC") {
      copyExternalExamResultLearnerDTOList.reverse();
    }
    return copyExternalExamResultLearnerDTOList
      .slice(startIndex, endIndex)
      .map((item, index) => ({ ...item, id: index }));
  };

  const calculateTotalPageCount = (externalExamResultLearnerDTOList) => {
    let totalPageCount = 0;
    externalExamResultLearnerDTOList.forEach((externalExamResultLearnerDTO) => {
      const { evaluatedPageCount } = externalExamResultLearnerDTO;
      totalPageCount += evaluatedPageCount;
    });
    return totalPageCount;
  };

  const handleAttendedOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setAttendedSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setAttendedPageNumber(value);
    } else if (type === "sortKey") {
      setAttendedSortKey(value);
    } else if (type === "sortDirection") {
      setAttendedSortDirection(value);
    }
  };

  const handleUnAttendedOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setUnAttendedSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setUnAttendedPageNumber(value);
    } else if (type === "sortKey") {
      setUnAttendedSortKey(value);
    } else if (type === "sortDirection") {
      setUnAttendedSortDirection(value);
    }
  };

  const constructAttendedNavigatableList = () => {
    const navigateList = [];
    navigateList.push({
      id: 0,
      text: i18n.t("to_detail"),
      icon: (
        <BaseImage src="/icons/navigate-gray.png" alt="navigate" size="small" />
      ),
      onClick: (item) => {
        window.open(
          `/external-exams/${externalExamId}/external-exam-results/${item.id}`,
          "_blank"
        );
      },
    });
    return navigateList;
  };

  const constructUnAttendedNavigatableList = () => {
    const navigateList = [];
    return navigateList;
  };

  const RenderMainContent = () => {
    const { evaluationStatus } = externalExamResultDTO;
    return (
      <div className="w-full">
        <div className="w-full my-2">
          <div className="flex flex-row justify-between items-center flex-wrap gap-x-2">
            <SelectionTab
              optionList={[
                {
                  id: 0,
                  title: i18n.t("attended_learner_results"),
                },
                {
                  id: 1,
                  title: i18n.t("unattended_learners"),
                },
              ]}
              selectedId={pageState}
              handleSelectOnClick={(selectedId) => {
                setPageState(selectedId);
              }}
            />
            {evaluationStatus === "EVALUATING" ? (
              <div className="">
                <p className="font-semibold text-catchup-blue">
                  {i18n.t("external_exam_evaluation_has_begun")}
                </p>
              </div>
            ) : evaluationStatus === "EVALUATED" ? (
              convertedFileList.length > 0 ? (
                convertedFileList.length > totalPageCount ? (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(
                        `/external-exams/${externalExamId}/external-exam-answers`
                      );
                    }}
                  >
                    <p className="font-bold text-catchup-red">
                      {i18n.t("you_have_unevaluated_answers")}
                    </p>
                    <p className="text-catchup-red">
                      {i18n.t("please_click_here_to_evaluate")}
                    </p>
                  </div>
                ) : (
                  <div className="mx-2">
                    <PrimaryButton
                      title={i18n.t("convert_results_to_pdf")}
                      size="unlimited"
                      onClick={() => {
                        window.open(
                          `/external-exams/${externalExamId}/results/view/pdf`,
                          "_blank"
                        );
                      }}
                    />
                  </div>
                )
              ) : (
                <BaseLoading
                  size="small"
                  color="#57C2D3"
                  secondaryColor="#57C2D3"
                />
              )
            ) : null}
          </div>
        </div>
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={i18n.t("external_exam_result_list")}
            description={i18n.t(
              "external_exam_result_list_table_description_text"
            )}
            itemName={i18n.t("external_exam_result")}
            headerList={[
              { name: i18n.t("identity_number"), key: "identityNumber" },
              { name: i18n.t("first_name"), key: "firstName" },
              { name: i18n.t("last_name"), key: "lastName" },
              { name: i18n.t("rank"), key: "rank" },
              { name: i18n.t("score"), key: "score" },
            ]}
            selectable={false}
            showFilter={true}
            showItemCount={attendedSelectedShowItemCount}
            totalItemCount={attendedTotalItemCount}
            pageNumber={attendedPageNumber}
            loading={tableLoading}
            sortKey={attendedSortKey}
            sortDirection={attendedSortDirection}
            handleOnFilterChange={() => {}}
            handleOnTableParamsChange={handleAttendedOnTableParamsChange}
            filterParams={{
              isFilterApplied: false,
              showBeginDate: false,
              showEndDate: false,
              showTextContains: false,
            }}
            itemList={filteredPagedAttendedExternalExamResultLearnerDTOList}
            insertable={false}
            updatable={false}
            deletable={false}
            navigatable={true}
            navigateOptionList={constructAttendedNavigatableList()}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={i18n.t("external_exam_result_list")}
            description={i18n.t(
              "external_exam_result_list_table_description_text"
            )}
            itemName={i18n.t("external_exam_result")}
            headerList={[
              { name: i18n.t("identity_number"), key: "identityNumber" },
              { name: i18n.t("first_name"), key: "firstName" },
              { name: i18n.t("last_name"), key: "lastName" },
            ]}
            selectable={false}
            showFilter={true}
            showItemCount={unAttendedSelectedShowItemCount}
            totalItemCount={unAttendedTotalItemCount}
            pageNumber={unAttendedPageNumber}
            loading={tableLoading}
            sortKey={unAttendedSortKey}
            sortDirection={unAttendedSortDirection}
            handleOnFilterChange={() => {}}
            handleOnTableParamsChange={handleUnAttendedOnTableParamsChange}
            filterParams={{
              isFilterApplied: false,
              showBeginDate: false,
              showEndDate: false,
              showTextContains: false,
            }}
            itemList={filteredPagedUnAttendedExternalExamResultLearnerDTOList}
            insertable={false}
            updatable={false}
            deletable={false}
            navigatable={false}
            navigateOptionList={constructUnAttendedNavigatableList()}
          />
        </div>
      </div>
    );
  };

  const externalExamResultLearnerDTOList =
    retrieveExternalExamResultLearnerDTOList();

  const filteredAttendedExternalExamResultLearnerDTOList =
    filterAttendedExternalExamResultLearnerDTOList(
      externalExamResultLearnerDTOList
    );
  const totalPageCount = calculateTotalPageCount(
    filteredAttendedExternalExamResultLearnerDTOList
  );
  const filteredPagedAttendedExternalExamResultLearnerDTOList =
    filterPagedAttendedExternalExamResultLearnerDTOList(
      filteredAttendedExternalExamResultLearnerDTOList
    );

  const filteredUnAttendedExternalExamResultLearnerDTOList =
    filterUnAttendedExternalExamResultLearnerDTOList(
      externalExamResultLearnerDTOList
    );
  const filteredPagedUnAttendedExternalExamResultLearnerDTOList =
    filterPagedUnAttendedExternalExamResultLearnerDTOList(
      filteredUnAttendedExternalExamResultLearnerDTOList
    );

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ExternalExamResultScreen;
