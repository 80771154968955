import { useEffect, useState } from "react";
import FullCard from "../../components/cards/FullCard";
import DividerLine from "../../components/divider/DividerLine";
import i18n from "../../language/i18n";
import InputGroup from "../../components/groups/InputGroup";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import { filterCoterieTypeOptionList } from "../../utilization/ManagementUtilization";
import {
  filterCategoryVersionCodeOptionList,
  retrieveCategoryVersionCodeOptionList,
} from "../../utilization/CategoryUtilization";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import { queryActivityDTOListByCategoryIdList } from "../../requests/CatchtivityRequests";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import BaseImage from "../../components/images/BaseImage";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import {
  convertImageTranslateImageWithAI,
  createImageTranslateByFileName,
  queryImageTranslateListByParams,
} from "../../requests/StorageRequests";
import { NotificationManager } from "react-notifications";
import BaseLoading from "../../components/loading/BaseLoading";
import { constructActivityItemListWithAnswersForAI } from "../../utilization/CatchtivityUtilization";
import TextGroup from "../../components/groups/TextGroup";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const ActivityJSONLConverterScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    language,
    categorySet,
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileInstitution,
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");
  const [selectedCategoryCode, setSelectedCategoryCode] =
    useState("DEFAULT_OPTION");
  const [selectedSubCategoryCode, setSelectedSubCategoryCode] =
    useState("DEFAULT_OPTION");
  const [selectedOutcomeId, setSelectedOutcomeId] = useState(null);
  const [activityDTOListMap, setActivityDTOListMap] = useState({});
  const [activityDTOList, setActivityDTOList] = useState([]);
  const [systemDescriptionMap, setSystemDescriptionMap] = useState({});
  const [convertedJSONList, setConvertedJSONList] = useState([]);
  const [anotherConvertedJSONList, setAnotherConvertedJSONList] = useState([]);
  const [llamaConvertedJSONList, setLlamaConvertedJSONList] = useState([]);
  const [
    stableDiffusionConvertedJSONList,
    setStableDiffusionConvertedJSONList,
  ] = useState([]);
  const [selectedOutcomeIdList, setSelectedOutcomeIdList] = useState([]);
  const [activityImageFileNameList, setActivityImageFileNameList] = useState(
    []
  );
  const [activityImageFileNameListMap, setActivityImageFileNameListMap] =
    useState({});
  const [activityItemListListMap, setActivityItemListListMap] = useState({});
  const [imageTranslateState, setImageTranslateState] = useState("TRANSLATE");
  const [differenceImageFileNameList, setDifferenceImageFileNameList] =
    useState([]);
  const [autoMode, setAutoMode] = useState(false);
  const [settings, setSettings] = useState({
    translateImage: false,
    includeImage: false,
    onlyApprovedAnswers: true,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("activity_jsonl_converter")) {
      navigate("/permission-error");
    } else {
      setImageTranslateState("TRANSLATE");
      setDifferenceImageFileNameList([]);
      setSelectedOutcomeIdList([]);
    }
  }, []);

  useEffect(() => {
    setSelectedCategoryCode("DEFAULT_OPTION");
  }, [selectedCoterieType]);

  useEffect(() => {
    setSelectedSubCategoryCode("DEFAULT_OPTION");
  }, [selectedCategoryCode]);

  useEffect(() => {
    setSelectedOutcomeId(null);
  }, [selectedSubCategoryCode]);

  useEffect(() => {
    const retrieveAndSetActivityDTOList = async () => {
      setLoading(true);
      const { data, err } = await queryActivityDTOListByCategoryIdList({
        brandId: userProfileBrand.id,
        categoryId: selectedOutcomeId,
      });
      if (err) {
        console.log(err);
        return;
      }
      if (data.length === 0) {
        setSelectedOutcomeId(null);
        const foundIndex = selectedOutcomeIdList.findIndex(
          (outcomeId) => parseFloat(outcomeId) === parseFloat(selectedOutcomeId)
        );
        selectedOutcomeIdList.splice(foundIndex, 1);
        setSelectedOutcomeIdList(
          JSON.parse(JSON.stringify(selectedOutcomeIdList))
        );
      } else {
        setActivityDTOList(data);
      }

      setImageTranslateState("TRANSLATE");
      setLoading(false);
    };
    if (!selectedOutcomeId) return;
    if (activityDTOListMap[selectedOutcomeId]) return;
    retrieveAndSetActivityDTOList();
  }, [selectedOutcomeId]);

  useEffect(() => {
    const findAndSetImageUrlListFromActivityDTO = () => {
      const currentActivityImageFileNameSet = new Set();
      const currentActivityItemListListMap = {};
      for (const activityDTO of activityDTOList) {
        const { data, activityTemplateDTOList } = activityDTO;
        for (const foundActivityTemplate of activityTemplateDTOList) {
          const dataJSON = JSON.parse(data);
          const {
            contentMap,
            orderingBodyMap,
            dropdownBodyMap,
            MCSABodyMap,
            MCMABodyMap,
            matchingBodyMap,
            groupingBodyMap,
            fillInTheBlanksBodyMap,
            openEndedBodyMap,
            trueFalseBodyMap,
          } = dataJSON;
          const contentMapJSON = JSON.parse(contentMap);
          if (contentMapJSON.type !== "TEXT") break;
          let imageList = [];
          let itemList = [];
          if (foundActivityTemplate.type === "ORDERING") {
            const orderingBodyMapJSON = JSON.parse(orderingBodyMap);
            itemList = constructActivityItemListWithAnswersForAI(
              orderingBodyMapJSON,
              {},
              foundActivityTemplate.type
            );
            imageList = constructBodyImageListFromJSON(orderingBodyMapJSON);
          } else if (foundActivityTemplate.type === "DROPDOWN") {
            const dropdownBodyMapJSON = JSON.parse(dropdownBodyMap);
            itemList = constructActivityItemListWithAnswersForAI(
              dropdownBodyMapJSON,
              {},
              foundActivityTemplate.type
            );
            imageList = constructBodyImageListFromJSON(dropdownBodyMapJSON);
          } else if (foundActivityTemplate.type === "MCSA") {
            const MCSABodyMapJSON = JSON.parse(MCSABodyMap);
            itemList = constructActivityItemListWithAnswersForAI(
              MCSABodyMapJSON,
              {},
              foundActivityTemplate.type
            );
            imageList = constructBodyImageListFromJSON(MCSABodyMapJSON);
          } else if (foundActivityTemplate.type === "MCMA") {
            const MCMABodyMapJSON = JSON.parse(MCMABodyMap);
            itemList = constructActivityItemListWithAnswersForAI(
              MCMABodyMapJSON,
              {},
              foundActivityTemplate.type
            );
            imageList = constructBodyImageListFromJSON(MCMABodyMapJSON);
          } else if (foundActivityTemplate.type === "MATCHING") {
            const matchingBodyMapJSON = JSON.parse(matchingBodyMap);
            itemList = constructActivityItemListWithAnswersForAI(
              matchingBodyMapJSON,
              {},
              foundActivityTemplate.type
            );
            imageList = constructBodyImageListFromJSON(matchingBodyMapJSON);
          } else if (foundActivityTemplate.type === "GROUPING") {
            const groupingBodyMapJSON = JSON.parse(groupingBodyMap);
            itemList = constructActivityItemListWithAnswersForAI(
              groupingBodyMapJSON,
              {},
              foundActivityTemplate.type
            );
            imageList = constructBodyImageListFromJSON(groupingBodyMapJSON);
          } else if (foundActivityTemplate.type === "FILL_IN_THE_BLANKS") {
            const fillInTheBlanksBodyMapJSON = JSON.parse(
              fillInTheBlanksBodyMap
            );
            itemList = constructActivityItemListWithAnswersForAI(
              fillInTheBlanksBodyMapJSON,
              {},
              foundActivityTemplate.type
            );
            imageList = constructBodyImageListFromJSON(
              fillInTheBlanksBodyMapJSON
            );
          } else if (foundActivityTemplate.type === "OPEN_ENDED") {
            const openEndedBodyMapJSON = JSON.parse(openEndedBodyMap);
            itemList = constructActivityItemListWithAnswersForAI(
              openEndedBodyMapJSON,
              {},
              foundActivityTemplate.type
            );
            imageList = constructBodyImageListFromJSON(openEndedBodyMapJSON);
          } else if (foundActivityTemplate.type === "TRUE_FALSE") {
            const trueFalseBodyMapJSON = JSON.parse(trueFalseBodyMap);
            itemList = constructActivityItemListWithAnswersForAI(
              trueFalseBodyMapJSON,
              {},
              foundActivityTemplate.type
            );
            imageList = constructBodyImageListFromJSON(trueFalseBodyMapJSON);
          }
          const imageFileNameList = imageList.map((image) =>
            image.substring(image.lastIndexOf("/") + 1, image.length)
          );
          for (const imageFileName of imageFileNameList) {
            if (!currentActivityItemListListMap[imageFileName]) {
              currentActivityItemListListMap[imageFileName] = itemList;
            }
          }
          imageFileNameList.forEach((item) =>
            currentActivityImageFileNameSet.add(item)
          );
        }
      }
      setActivityImageFileNameList(Array.from(currentActivityImageFileNameSet));
      setActivityItemListListMap(currentActivityItemListListMap);
    };

    if (!selectedOutcomeId) return;
    if (activityDTOList.length === 0) return;
    if (activityDTOListMap[selectedOutcomeId]) return;
    findAndSetImageUrlListFromActivityDTO();
    activityDTOListMap[selectedOutcomeId] = JSON.parse(
      JSON.stringify(activityDTOList)
    );
    setActivityDTOListMap(JSON.parse(JSON.stringify(activityDTOListMap)));
  }, [activityDTOList]);

  useEffect(() => {
    const retrieveAndSetContentList = async () => {
      const { data, err } = await queryImageTranslateListByParams({
        fileNameList: activityImageFileNameList,
      });
      if (err) {
        console.log(err);
        return;
      }
      const currentList = [];
      for (const item of data) {
        const { content, mainFile, learnerFile, toBeLabelledFile } = item;
        let fileName;
        if (mainFile) {
          fileName = mainFile.fileName;
        } else if (learnerFile) {
          fileName = learnerFile.fileName;
        } else if (toBeLabelledFile) {
          fileName = toBeLabelledFile.fileName;
        }
        currentList.push({
          fileName,
          content,
        });
      }
      if (
        settings.translateImage &&
        currentList.length !== activityImageFileNameList.length
      ) {
        const currentDifferenceImageNameList = [];
        for (const activityImageFileName of activityImageFileNameList) {
          const foundIndex = currentList.findIndex(
            (item) => item.fileName === activityImageFileName
          );
          if (foundIndex === -1) {
            currentDifferenceImageNameList.push(activityImageFileName);
          }
        }
        setDifferenceImageFileNameList(
          JSON.parse(JSON.stringify(currentDifferenceImageNameList))
        );
        setImageTranslateState("TRANSLATE");
      } else {
        setImageTranslateState("COMPLETED");
      }
      activityImageFileNameListMap[selectedOutcomeId] = JSON.parse(
        JSON.stringify(currentList)
      );
      setActivityImageFileNameListMap(
        JSON.parse(JSON.stringify(activityImageFileNameListMap))
      );
    };
    if (!selectedOutcomeId) return;
    if (activityImageFileNameList.length === 0) {
      setImageTranslateState("COMPLETED");
    }
    if (activityImageFileNameListMap[selectedOutcomeId]) return;
    retrieveAndSetContentList();
  }, [activityImageFileNameList]);

  useEffect(() => {
    const retrieveAndSetImageTranslate = async () => {
      const copyDifferenceImageFileNameList = JSON.parse(
        JSON.stringify(differenceImageFileNameList)
      );
      let translatedImageCount = 0;
      for (const copyDifferenceImageFileName of copyDifferenceImageFileNameList) {
        const imageUrl = `${connection.base.storage.url}/storage-files/preview/file/${userProfileBrand.id}/images/${copyDifferenceImageFileName}`;
        const { data: translateOnlyImageData, err: translateOnlyImageErr } =
          await convertImageTranslateImageWithAI({
            imageUrl,
          });
        if (translateOnlyImageErr) {
          console.log(translateOnlyImageErr);
          NotificationManager.error(
            i18n.t("error_occurred_on_image_translate")
          );
        } else {
          const { content } = translateOnlyImageData;
          const { data: storageData, err: storageErr } =
            await createImageTranslateByFileName({
              mainFile: {
                fileName: copyDifferenceImageFileName,
              },
              content,
            });
          if (storageErr) {
            console.log(storageErr);
          } else {
            activityImageFileNameListMap[selectedOutcomeId].push({
              fileName: copyDifferenceImageFileName,
              content,
            });
            const foundIndex = differenceImageFileNameList.findIndex(
              (differenceImageFileName) =>
                differenceImageFileName === copyDifferenceImageFileName
            );
            if (foundIndex !== -1) {
              differenceImageFileNameList.splice(foundIndex, 1);
              setDifferenceImageFileNameList(
                JSON.parse(JSON.stringify(differenceImageFileNameList))
              );
            }
            translatedImageCount++;
          }
        }
      }
      if (translatedImageCount === copyDifferenceImageFileNameList.length) {
        setImageTranslateState("COMPLETED");
      }
      setActivityImageFileNameListMap(
        JSON.parse(JSON.stringify(activityImageFileNameListMap))
      );
    };
    if (activityDTOList.length === 0) return;
    if (differenceImageFileNameList.length === 0) return;
    if (imageTranslateState === "COMPLETED") return;

    retrieveAndSetImageTranslate();
  }, [
    activityDTOList,
    imageTranslateState,
    activityImageFileNameListMap,
    settings,
  ]);

  // useEffect(() => {
  //   if (convertedJSONList.length === 0) return;
  //   let text = "";
  //   for (let i = 0; i < convertedJSONList.length; i++) {
  //     text += JSON.stringify(convertedJSONList[i]);
  //     if (i !== convertedJSONList.length - 1) {
  //       text += "\n";
  //     }
  //   }
  //   handleDownload(
  //     `${i18n
  //       .t(selectedCoterieType)
  //       .toLocaleLowerCase(language)
  //       .replace("ö", "o")
  //       .replace("ü", "u")
  //       .replace("ğ", "g")
  //       .substring(0, 3)}-${level}-gpt4-chat.jsonl`,
  //     text
  //   );
  // }, [convertedJSONList]);

  // useEffect(() => {
  //   if (anotherConvertedJSONList.length === 0) return;
  //   let text = "";
  //   for (let i = 0; i < anotherConvertedJSONList.length; i++) {
  //     text += JSON.stringify(anotherConvertedJSONList[i]);
  //     if (i !== anotherConvertedJSONList.length - 1) {
  //       text += "\n";
  //     }
  //   }
  //   const foundCategoryDTO = filteredCategorySet.find(
  //     (categoryDTO) => categoryDTO.id === selectedSubCategoryCode
  //   );
  //   const level = foundCategoryDTO.code.split(".")[1];
  //   handleDownload(
  //     `${i18n
  //       .t(selectedCoterieType)
  //       .toLocaleLowerCase(language)
  //       .replace("ö", "o")
  //       .replace("ü", "u")
  //       .replace("ğ", "g")
  //       .substring(0, 3)}-${level}-gpt4-line.jsonl`,
  //     text
  //   );
  // }, [anotherConvertedJSONList]);

  useEffect(() => {
    if (llamaConvertedJSONList.length === 0) return;
    if (selectedCoterieType === "DEFAULT_OPTION") return;
    if (selectedSubCategoryCode === "DEFAULT_OPTION") return;
    let text = "";
    for (let i = 0; i < llamaConvertedJSONList.length; i++) {
      text += JSON.stringify(llamaConvertedJSONList[i]);
      if (i !== llamaConvertedJSONList.length - 1) {
        text += "\n";
      }
    }
    const foundCategoryDTO = filteredCategorySet.find(
      (categoryDTO) => categoryDTO.id === selectedSubCategoryCode
    );
    const level = foundCategoryDTO.code.split(".")[1];
    handleDownload(
      `${i18n
        .t(selectedCoterieType)
        .toLocaleLowerCase(language)
        .replace("ö", "o")
        .replace("ü", "u")
        .replace("ğ", "g")
        .substring(0, 3)}-${level}-llama3-line.jsonl`,
      text
    );
  }, [selectedCoterieType, llamaConvertedJSONList]);

  useEffect(() => {
    if (!settings.includeImage) return;
    if (stableDiffusionConvertedJSONList.length === 0) return;
    if (selectedCoterieType === "DEFAULT_OPTION") return;
    if (selectedSubCategoryCode === "DEFAULT_OPTION") return;
    let text = "";
    for (let i = 0; i < stableDiffusionConvertedJSONList.length; i++) {
      text += JSON.stringify(stableDiffusionConvertedJSONList[i]);
      if (i !== stableDiffusionConvertedJSONList.length - 1) {
        text += "\n";
      }
    }
    const foundCategoryDTO = filteredCategorySet.find(
      (categoryDTO) => categoryDTO.id === selectedSubCategoryCode
    );
    const level = foundCategoryDTO.code.split(".")[1];
    handleDownload(
      `${i18n
        .t(selectedCoterieType)
        .toLocaleLowerCase(language)
        .replace("ö", "o")
        .replace("ü", "u")
        .replace("ğ", "g")
        .substring(0, 3)}-${level}-stable-diffusion-line.jsonl`,
      text
    );
  }, [selectedCoterieType, stableDiffusionConvertedJSONList]);

  useEffect(() => {
    if (!autoMode) return;
    if (imageTranslateState === "COMPLETED" || !selectedOutcomeId) {
      const foundOutcomeId = selectedOutcomeIdList.find((outcomeId) => {
        return !Object.keys(activityDTOListMap)
          .map((key) => parseFloat(key))
          .includes(parseFloat(outcomeId));
      });
      if (foundOutcomeId) {
        handleSelectOutcomeOnClick(foundOutcomeId);
      } else {
        handleConvertAllToJSONLOnClick();
      }
    }
  }, [imageTranslateState, autoMode, selectedOutcomeId]);

  const filterCategorySet = () => {
    if (selectedCategoryCode === "DEFAULT_OPTION") return [];
    return categorySet.filter(
      (categoryDTO) => categoryDTO.versionCode === selectedCategoryCode
    );
  };

  const retrieveSubCategoryCodeList = (categorySet) => {
    if (selectedCategoryCode === "DEFAULT_OPTION") return [];
    const filteredCategoryDTOList = [];
    const filteredMainCategoryDTOList = categorySet.filter(
      (categoryDTO) => categoryDTO.versionCode === selectedCategoryCode
    );
    for (const currentCategoryDTO of filteredMainCategoryDTOList) {
      const foundIndex = filteredMainCategoryDTOList.findIndex(
        (categoryDTO) => categoryDTO.parentCode === currentCategoryDTO.code
      );
      if (foundIndex !== -1) {
        filteredCategoryDTOList.push(currentCategoryDTO);
      }
    }

    return filteredCategoryDTOList
      .sort((a, b) => a.code.localeCompare(b.code))
      .map((categoryDTO) => ({
        value: categoryDTO.id,
        text: `${categoryDTO.code} - ${categoryDTO.name}`,
      }));
  };

  const retrieveOutcomeDTOOptionList = (categorySet) => {
    if (selectedSubCategoryCode === "DEFAULT_OPTION") return [];
    const foundCategoryDTO = categorySet.find(
      (categoryDTO) => categoryDTO.id === selectedSubCategoryCode
    );
    if (!foundCategoryDTO) return [];
    const currentCategoryDTOList = categorySet.filter((categoryDTO) => {
      return categoryDTO.parentCode?.startsWith(foundCategoryDTO.code);
    });

    const filteredCategoryDTOList = [];
    for (const currentCategoryDTO of currentCategoryDTOList) {
      if (
        categorySet.findIndex(
          (categoryDTO) => categoryDTO.parentCode === currentCategoryDTO.code
        ) === -1
      ) {
        filteredCategoryDTOList.push(currentCategoryDTO);
      }
    }

    return filteredCategoryDTOList
      .sort((a, b) => a.code.localeCompare(b.code))
      .map((categoryDTO) => ({
        value: categoryDTO.id,
        text: `${categoryDTO.code} - ${categoryDTO.name}`,
      }));
  };

  const constructUserMessage = (
    coterieType,
    outcomeName,
    taxonomyName,
    templateType
  ) => {
    return {
      role: "user",
      content: `Zümre: ${coterieType}\nKazanım: ${outcomeName}\nTaksonomi: ${taxonomyName}\nŞablon: ${templateType}`,
    };
  };

  const constructUserMessageWithMedia = (
    bodyMapJSON,
    coterieType,
    outcomeId,
    outcomeName,
    taxonomyName,
    templateType
  ) => {
    const userMessage = constructUserMessage(
      coterieType,
      outcomeName,
      taxonomyName,
      templateType
    );
    // const imageFileNameList = activityImageFileNameListMap[outcomeId];
    // let imageIndex = 0;
    // for (const bodyMapItemKey of Object.keys(bodyMapJSON)) {
    //   const bodyItemJSON = JSON.parse(bodyMapJSON[bodyMapItemKey]);
    //   const { type, value } = bodyItemJSON;
    //   if (type === "IMAGE") {
    //     const fileName = value.substring(
    //       value.lastIndexOf("/") + 1,
    //       value.length
    //     );
    //     const foundImageFileName = imageFileNameList.find(
    //       (imageFileName) => imageFileName.fileName === fileName
    //     );
    //     userMessage.content += `\n[Görsel ${imageIndex}]: ${foundImageFileName.content}`;
    //     imageIndex++;
    //   }
    // }
    return userMessage;
  };

  const constructBodyImageListFromJSON = (bodyMapJSON) => {
    let bodyImageList = [];
    for (const bodyMapItemKey of Object.keys(bodyMapJSON)) {
      const bodyItemJSON = JSON.parse(bodyMapJSON[bodyMapItemKey]);
      const { type, value } = bodyItemJSON;
      if (type === "IMAGE") {
        bodyImageList.push(value);
      }
    }
    return bodyImageList;
  };

  const constructBodyMapFromJSON = (bodyMapJSON, imageFileNameList) => {
    let constructedString = "";
    for (const bodyMapItemKey of Object.keys(bodyMapJSON)) {
      const bodyItemJSON = JSON.parse(bodyMapJSON[bodyMapItemKey]);
      const { type, value } = bodyItemJSON;
      if (type === "TEXT") {
        constructedString += `\n${value}`;
      } else if (type === "IMAGE") {
        const fileName = value.substring(
          value.lastIndexOf("/") + 1,
          value.length
        );
        const foundImageFileName = imageFileNameList.find(
          (imageFileName) => imageFileName.fileName === fileName
        );
        constructedString += `\n<image>${foundImageFileName.content}</image>`;
      }
    }
    return constructedString;
  };

  const constructSolutionMapFromJSON = (solutionMapJSON) => {
    let constructedString = "";
    for (const solutionMapItemKey of Object.keys(solutionMapJSON)) {
      const solutionItemJSON = JSON.parse(solutionMapJSON[solutionMapItemKey]);
      const { type, value } = solutionItemJSON;
      if (type === "TEXT") {
        constructedString += `\n${value}`;
      }
    }
    return constructedString;
  };

  const handleDownload = (filename, text) => {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleOnChange = (type, value) => {
    if (type === "selectedCategoryCode") {
      setSelectedCategoryCode(value);
    } else if (type === "selectedSubCategoryCode") {
      setSelectedSubCategoryCode(value);
    } else if (type === "selectedCoterieType") {
      setSelectedCoterieType(value);
    } else if (type === "selectedOutcomeId") {
      setSelectedOutcomeId(value);
    } else if (type === "selectedOutcomeIdList") {
      handleAddOutcomeIdToList(value);
    }
  };

  const handleAddOutcomeIdToList = (outcomeId) => {
    if (outcomeId !== "DEFAULT_OPTION") {
      const foundIndex = selectedOutcomeIdList.findIndex(
        (selectedOutcomeId) => selectedOutcomeId === outcomeId
      );
      if (foundIndex === -1) {
        selectedOutcomeIdList.push(parseFloat(outcomeId));
        setSelectedOutcomeIdList(
          JSON.parse(JSON.stringify(selectedOutcomeIdList))
        );
        systemDescriptionMap[outcomeId] = `Görevin ${i18n
          .t(selectedCoterieType)
          .toLocaleLowerCase(language)} dersi için soru üretmek.`;
        setSystemDescriptionMap(
          JSON.parse(JSON.stringify(systemDescriptionMap))
        );
      }
    }
  };

  const handleConvertAllToJSONLOnClick = () => {
    const currentConvertedJSONList = [];
    const currentAnotherConvertedJSONList = [];
    const currentLlamaConvertedJSONList = [];
    const currentStableDiffusionConvertedJSONList = [];
    for (const outcomeId of selectedOutcomeIdList) {
      const foundCategoryDTO = filteredCategorySet.find(
        (categoryDTO) => categoryDTO.id === outcomeId
      );
      const convertedJSON = {
        messages: [
          {
            role: "system",
            content: systemDescriptionMap[outcomeId],
          },
        ],
      };
      if (!activityDTOListMap[outcomeId]) continue;
      const imageFileNameList = activityImageFileNameListMap[outcomeId];
      if (imageFileNameList?.length > 0) {
        for (const imageFile of imageFileNameList) {
          const { fileName, content } = imageFile;
          const foundIndex = currentStableDiffusionConvertedJSONList.findIndex(
            (item) => item.file_name === imageFile.fileName
          );
          if (foundIndex === -1) {
            currentStableDiffusionConvertedJSONList.push({
              file_url: `${connection.base.storage.url}/storage-files/preview/file/${userProfileBrand.id}/images/${fileName}`,
              file_name: fileName,
              text: content,
            });
          }
        }
      }
      let totalActivityTemplateCount = 0;
      let totalValidActivityTemplateCount = 0;
      const activityTemplateCountMap = {};
      for (const activityDTO of activityDTOListMap[outcomeId]) {
        const { name, data, activityTemplateDTOList } = activityDTO;
        const dataJSON = JSON.parse(data);
        const {
          contentMap,
          orderingBodyMap,
          orderingMaterialMap,
          orderingTaxonomyMap,
          orderingSolutionMap,
          dropdownBodyMap,
          dropdownMaterialMap,
          dropdownTaxonomyMap,
          dropdownSolutionMap,
          MCSABodyMap,
          MCSAMaterialMap,
          MCSATaxonomyMap,
          MCSASolutionMap,
          MCMABodyMap,
          MCMAMaterialMap,
          MCMATaxonomyMap,
          MCMASolutionMap,
          matchingBodyMap,
          matchingMaterialMap,
          matchingTaxonomyMap,
          matchingSolutionMap,
          groupingBodyMap,
          groupingMaterialMap,
          groupingTaxonomyMap,
          groupingSolutionMap,
          fillInTheBlanksBodyMap,
          fillInTheBlanksMaterialMap,
          fillInTheBlanksTaxonomyMap,
          fillInTheBlanksSolutionMap,
          openEndedBodyMap,
          openEndedMaterialMap,
          openEndedTaxonomyMap,
          openEndedSolutionMap,
          trueFalseBodyMap,
          trueFalseMaterialMap,
          trueFalseTaxonomyMap,
          trueFalseSolutionMap,
        } = dataJSON;
        const contentMapJSON = JSON.parse(contentMap);
        if (contentMapJSON.type !== "TEXT") continue;

        for (const foundActivityTemplate of activityTemplateDTOList) {
          totalActivityTemplateCount++;
          const anotherConvertedJSON = {
            messages: [
              {
                role: "system",
                content: systemDescriptionMap[outcomeId],
              },
            ],
          };
          const llamaConvertedJSON = {
            instruction: systemDescriptionMap[outcomeId],
          };
          if (foundActivityTemplate.type === "ORDERING") {
            const orderingBodyMapJSON = JSON.parse(orderingBodyMap);
            const orderingMaterialMapJSON = JSON.parse(orderingMaterialMap);
            const orderingTaxonomyMapJSON = JSON.parse(orderingTaxonomyMap);
            const orderingSolutionMapJSON = JSON.parse(orderingSolutionMap);
            if (!settings.includeImage && orderingBodyMap.includes("IMAGE"))
              continue;
            if (!settings.translateImage) {
              const imageList =
                constructBodyImageListFromJSON(orderingBodyMapJSON);
              const filteredImageList = imageList.filter((image) => {
                const fileName = image.substring(
                  image.lastIndexOf("/") + 1,
                  image.length
                );
                return (
                  imageFileNameList.filter(
                    (imageFileName) => imageFileName.fileName === fileName
                  ).length > 0
                );
              });
              if (filteredImageList.length !== imageList.length) continue;
            }
            if (
              settings.onlyApprovedAnswers &&
              !orderingSolutionMap.includes("[ONAYLI CEVAP]")
            ) {
              continue;
            }
            totalValidActivityTemplateCount++;
            if (!activityTemplateCountMap[foundActivityTemplate.type]) {
              activityTemplateCountMap[foundActivityTemplate.type] = 1;
            } else {
              activityTemplateCountMap[foundActivityTemplate.type]++;
            }
            convertedJSON.messages.push(
              constructUserMessageWithMedia(
                orderingBodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(orderingTaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            anotherConvertedJSON.messages.push(
              constructUserMessageWithMedia(
                orderingBodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(orderingTaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            llamaConvertedJSON.input = constructUserMessageWithMedia(
              orderingBodyMapJSON,
              i18n.t(selectedCoterieType),
              outcomeId,
              `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
              i18n.t(orderingTaxonomyMapJSON.name),
              i18n.t(foundActivityTemplate.type)
            ).content;
            let orderingActivity = constructBodyMapFromJSON(
              orderingBodyMapJSON,
              imageFileNameList
            );
            orderingActivity += "\n[GENIXO]";
            for (const key of Object.keys(orderingMaterialMapJSON)) {
              orderingActivity += `\n${orderingMaterialMapJSON[key]}`;
            }
            orderingActivity += "\n[GENIXO]";
            orderingActivity += constructSolutionMapFromJSON(
              orderingSolutionMapJSON
            );
            convertedJSON.messages.push({
              role: "assistant",
              content: orderingActivity,
            });
            anotherConvertedJSON.messages.push({
              role: "assistant",
              content: orderingActivity,
            });
            llamaConvertedJSON.output = orderingActivity;
          } else if (foundActivityTemplate.type === "DROPDOWN") {
            const dropdownBodyMapJSON = JSON.parse(dropdownBodyMap);
            const dropdownMaterialMapJSON = JSON.parse(dropdownMaterialMap);
            const dropdownTaxonomyMapJSON = JSON.parse(dropdownTaxonomyMap);
            const dropdownSolutionMapJSON = JSON.parse(dropdownSolutionMap);
            if (!settings.includeImage && dropdownBodyMap.includes("IMAGE"))
              continue;
            if (!settings.translateImage) {
              const imageList =
                constructBodyImageListFromJSON(dropdownBodyMapJSON);
              const filteredImageList = imageList.filter((image) => {
                const fileName = image.substring(
                  image.lastIndexOf("/") + 1,
                  image.length
                );
                return (
                  imageFileNameList.filter(
                    (imageFileName) => imageFileName.fileName === fileName
                  ).length > 0
                );
              });
              if (filteredImageList.length !== imageList.length) continue;
            }
            if (
              settings.onlyApprovedAnswers &&
              !dropdownSolutionMap.includes("[ONAYLI CEVAP]")
            ) {
              continue;
            }
            totalValidActivityTemplateCount++;
            if (!activityTemplateCountMap[foundActivityTemplate.type]) {
              activityTemplateCountMap[foundActivityTemplate.type] = 1;
            } else {
              activityTemplateCountMap[foundActivityTemplate.type]++;
            }
            convertedJSON.messages.push(
              constructUserMessageWithMedia(
                dropdownBodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(dropdownTaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            anotherConvertedJSON.messages.push(
              constructUserMessageWithMedia(
                dropdownBodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(dropdownTaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            llamaConvertedJSON.input = constructUserMessageWithMedia(
              dropdownBodyMapJSON,
              i18n.t(selectedCoterieType),
              outcomeId,
              `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
              i18n.t(dropdownTaxonomyMapJSON.name),
              i18n.t(foundActivityTemplate.type)
            ).content;

            let dropdownActivity = constructBodyMapFromJSON(
              dropdownBodyMapJSON,
              imageFileNameList
            );
            dropdownActivity += "\n[GENIXO]";
            for (
              let i = 0;
              i < Object.keys(dropdownMaterialMapJSON).length;
              i++
            ) {
              const currentKey = Object.keys(dropdownMaterialMapJSON)[i];
              const correctAnswer = Object.keys(
                JSON.parse(dropdownMaterialMapJSON[currentKey])
              )[0];
              for (const option of JSON.parse(
                JSON.parse(dropdownMaterialMapJSON[currentKey])[correctAnswer]
              )) {
                let prefix = `\n[${i}]`;
                if (option === correctAnswer) {
                  prefix += "[DOĞRU]";
                }
                prefix += `${option.trim()}`;
                dropdownActivity += prefix;
              }
            }
            dropdownActivity += "\n[GENIXO]";
            dropdownActivity += constructSolutionMapFromJSON(
              dropdownSolutionMapJSON
            );
            convertedJSON.messages.push({
              role: "assistant",
              content: dropdownActivity,
            });
            anotherConvertedJSON.messages.push({
              role: "assistant",
              content: dropdownActivity,
            });
            llamaConvertedJSON.output = dropdownActivity;
          } else if (foundActivityTemplate.type === "MCSA") {
            const MCSABodyMapJSON = JSON.parse(MCSABodyMap);
            const MCSAMaterialMapJSON = JSON.parse(MCSAMaterialMap);
            const MCSATaxonomyMapJSON = JSON.parse(MCSATaxonomyMap);
            const MCSASolutionMapJSON = JSON.parse(MCSASolutionMap);
            if (!settings.includeImage && MCSABodyMap.includes("IMAGE"))
              continue;
            if (!settings.translateImage) {
              const imageList = constructBodyImageListFromJSON(MCSABodyMapJSON);
              const filteredImageList = imageList.filter((image) => {
                const fileName = image.substring(
                  image.lastIndexOf("/") + 1,
                  image.length
                );
                return (
                  imageFileNameList.filter(
                    (imageFileName) => imageFileName.fileName === fileName
                  ).length > 0
                );
              });
              if (filteredImageList.length !== imageList.length) continue;
            }
            if (
              settings.onlyApprovedAnswers &&
              !MCSASolutionMap.includes("[ONAYLI CEVAP]")
            )
              continue;
            totalValidActivityTemplateCount++;
            if (!activityTemplateCountMap[foundActivityTemplate.type]) {
              activityTemplateCountMap[foundActivityTemplate.type] = 1;
            } else {
              activityTemplateCountMap[foundActivityTemplate.type]++;
            }

            convertedJSON.messages.push(
              constructUserMessageWithMedia(
                MCSABodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(MCSATaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            anotherConvertedJSON.messages.push(
              constructUserMessageWithMedia(
                MCSABodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(MCSATaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            llamaConvertedJSON.input = constructUserMessageWithMedia(
              MCSABodyMapJSON,
              i18n.t(selectedCoterieType),
              outcomeId,
              `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
              i18n.t(MCSATaxonomyMapJSON.name),
              i18n.t(foundActivityTemplate.type)
            ).content;

            let MCSAActivity = constructBodyMapFromJSON(
              MCSABodyMapJSON,
              imageFileNameList
            );
            MCSAActivity += "\n[GENIXO]";
            const correctAnswer = Object.keys(MCSAMaterialMapJSON)[0];
            const optionList = JSON.parse(MCSAMaterialMapJSON[correctAnswer]);
            for (const option of optionList) {
              let prefix = "";
              if (option === correctAnswer) {
                prefix = "[CEVAP]";
              }
              if (contentMapJSON.type === "TEXT") {
                MCSAActivity += `\n${prefix}${option}`;
              }
            }
            MCSAActivity += "\n[GENIXO]";
            MCSAActivity += constructSolutionMapFromJSON(MCSASolutionMapJSON);
            convertedJSON.messages.push({
              role: "assistant",
              content: MCSAActivity,
            });
            anotherConvertedJSON.messages.push({
              role: "assistant",
              content: MCSAActivity,
            });
            llamaConvertedJSON.output = MCSAActivity;
          } else if (foundActivityTemplate.type === "MCMA") {
            const MCMABodyMapJSON = JSON.parse(MCMABodyMap);
            const MCMAMaterialMapJSON = JSON.parse(MCMAMaterialMap);
            const MCMATaxonomyMapJSON = JSON.parse(MCMATaxonomyMap);
            const MCMASolutionMapJSON = JSON.parse(MCMASolutionMap);
            if (!settings.includeImage && MCMABodyMap.includes("IMAGE"))
              continue;
            if (!settings.translateImage) {
              const imageList = constructBodyImageListFromJSON(MCMABodyMapJSON);
              const filteredImageList = imageList.filter((image) => {
                const fileName = image.substring(
                  image.lastIndexOf("/") + 1,
                  image.length
                );
                return (
                  imageFileNameList.filter(
                    (imageFileName) => imageFileName.fileName === fileName
                  ).length > 0
                );
              });
              if (filteredImageList.length !== imageList.length) continue;
            }
            if (
              settings.onlyApprovedAnswers &&
              !MCMASolutionMap.includes("[ONAYLI CEVAP]")
            ) {
              continue;
            }
            totalValidActivityTemplateCount++;
            if (!activityTemplateCountMap[foundActivityTemplate.type]) {
              activityTemplateCountMap[foundActivityTemplate.type] = 1;
            } else {
              activityTemplateCountMap[foundActivityTemplate.type]++;
            }
            convertedJSON.messages.push(
              constructUserMessageWithMedia(
                MCMABodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(MCMATaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            anotherConvertedJSON.messages.push(
              constructUserMessageWithMedia(
                MCMABodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(MCMATaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            llamaConvertedJSON.input = constructUserMessageWithMedia(
              MCMABodyMapJSON,
              i18n.t(selectedCoterieType),
              outcomeId,
              `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
              i18n.t(MCMATaxonomyMapJSON.name),
              i18n.t(foundActivityTemplate.type)
            ).content;
            let MCMAActivity = constructBodyMapFromJSON(
              MCMABodyMapJSON,
              imageFileNameList
            );
            const correctAnswerKey = Object.keys(MCMAMaterialMapJSON)[0];
            const correctAnswerList = JSON.parse(correctAnswerKey);
            MCMAActivity += "\n[GENIXO]";
            for (const option of JSON.parse(
              MCMAMaterialMapJSON[correctAnswerKey]
            )) {
              let prefix = "";
              if (correctAnswerList.includes(option)) {
                prefix = "[CEVAP]";
              }
              if (contentMapJSON.type === "TEXT") {
                MCMAActivity += `\n${prefix} ${option}`;
              }
            }
            MCMAActivity += "\n[GENIXO]";
            MCMAActivity += constructSolutionMapFromJSON(MCMASolutionMapJSON);
            convertedJSON.messages.push({
              role: "assistant",
              content: MCMAActivity,
            });
            anotherConvertedJSON.messages.push({
              role: "assistant",
              content: MCMAActivity,
            });
            llamaConvertedJSON.output = MCMAActivity;
          } else if (foundActivityTemplate.type === "MATCHING") {
            const matchingBodyMapJSON = JSON.parse(matchingBodyMap);
            const matchingMaterialMapJSON = JSON.parse(matchingMaterialMap);
            const matchingTaxonomyMapJSON = JSON.parse(matchingTaxonomyMap);
            const matchingSolutionMapJSON = JSON.parse(matchingSolutionMap);
            if (!settings.includeImage && matchingBodyMap.includes("IMAGE"))
              continue;
            if (!settings.translateImage) {
              const imageList =
                constructBodyImageListFromJSON(matchingBodyMapJSON);
              const filteredImageList = imageList.filter((image) => {
                const fileName = image.substring(
                  image.lastIndexOf("/") + 1,
                  image.length
                );
                return (
                  imageFileNameList.filter(
                    (imageFileName) => imageFileName.fileName === fileName
                  ).length > 0
                );
              });
              if (filteredImageList.length !== imageList.length) continue;
            }
            if (
              settings.onlyApprovedAnswers &&
              !matchingSolutionMap.includes("[ONAYLI CEVAP]")
            ) {
              continue;
            }
            totalValidActivityTemplateCount++;
            if (!activityTemplateCountMap[foundActivityTemplate.type]) {
              activityTemplateCountMap[foundActivityTemplate.type] = 1;
            } else {
              activityTemplateCountMap[foundActivityTemplate.type]++;
            }
            convertedJSON.messages.push(
              constructUserMessageWithMedia(
                matchingBodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(matchingTaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            anotherConvertedJSON.messages.push(
              constructUserMessageWithMedia(
                matchingBodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(matchingTaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            llamaConvertedJSON.input = constructUserMessageWithMedia(
              matchingBodyMapJSON,
              i18n.t(selectedCoterieType),
              outcomeId,
              `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
              i18n.t(matchingTaxonomyMapJSON.name),
              i18n.t(foundActivityTemplate.type)
            ).content;
            let matchingActivity = constructBodyMapFromJSON(
              matchingBodyMapJSON,
              imageFileNameList
            );
            matchingActivity += "\n[GENIXO]";
            for (const key of Object.keys(matchingMaterialMapJSON)) {
              matchingActivity += `\n${key.trim()}: ${matchingMaterialMapJSON[
                key
              ].trim()}`;
            }
            matchingActivity += "\n[GENIXO]";
            matchingActivity += constructSolutionMapFromJSON(
              matchingSolutionMapJSON
            );
            convertedJSON.messages.push({
              role: "assistant",
              content: matchingActivity,
            });
            anotherConvertedJSON.messages.push({
              role: "assistant",
              content: matchingActivity,
            });
            llamaConvertedJSON.output = matchingActivity;
          } else if (foundActivityTemplate.type === "GROUPING") {
            const groupingBodyMapJSON = JSON.parse(groupingBodyMap);
            const groupingMaterialMapJSON = JSON.parse(groupingMaterialMap);
            const groupingTaxonomyMapJSON = JSON.parse(groupingTaxonomyMap);
            const groupingSolutionMapJSON = JSON.parse(groupingSolutionMap);
            if (!settings.includeImage && groupingBodyMap.includes("IMAGE"))
              continue;
            if (!settings.translateImage) {
              const imageList =
                constructBodyImageListFromJSON(groupingBodyMapJSON);
              const filteredImageList = imageList.filter((image) => {
                const fileName = image.substring(
                  image.lastIndexOf("/") + 1,
                  image.length
                );
                return (
                  imageFileNameList.filter(
                    (imageFileName) => imageFileName.fileName === fileName
                  ).length > 0
                );
              });
              if (filteredImageList.length !== imageList.length) continue;
            }
            if (
              settings.onlyApprovedAnswers &&
              !groupingSolutionMap.includes("[ONAYLI CEVAP]")
            ) {
              continue;
            }
            totalValidActivityTemplateCount++;
            if (!activityTemplateCountMap[foundActivityTemplate.type]) {
              activityTemplateCountMap[foundActivityTemplate.type] = 1;
            } else {
              activityTemplateCountMap[foundActivityTemplate.type]++;
            }
            convertedJSON.messages.push(
              constructUserMessageWithMedia(
                groupingBodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(groupingTaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            anotherConvertedJSON.messages.push(
              constructUserMessageWithMedia(
                groupingBodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(groupingTaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            llamaConvertedJSON.input = constructUserMessageWithMedia(
              groupingBodyMapJSON,
              i18n.t(selectedCoterieType),
              outcomeId,
              `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
              i18n.t(groupingTaxonomyMapJSON.name),
              i18n.t(foundActivityTemplate.type)
            ).content;
            let groupingActivity = constructBodyMapFromJSON(
              groupingBodyMapJSON,
              imageFileNameList
            );
            groupingActivity += "\n[GENIXO]";
            for (const key of Object.keys(groupingMaterialMapJSON)) {
              let itemString = "";
              const itemList = JSON.parse(groupingMaterialMapJSON[key]);
              for (let i = 0; i < itemList.length; i++) {
                itemString += itemList[i];
                if (i !== itemList.length - 1) {
                  itemString += "; ";
                }
              }
              groupingActivity += `\n${key.trim()}: ${itemString}`;
            }
            groupingActivity += "\n[GENIXO]";
            groupingActivity += constructSolutionMapFromJSON(
              groupingSolutionMapJSON
            );
            convertedJSON.messages.push({
              role: "assistant",
              content: groupingActivity,
            });
            anotherConvertedJSON.messages.push({
              role: "assistant",
              content: groupingActivity,
            });
            llamaConvertedJSON.output = groupingActivity;
          } else if (foundActivityTemplate.type === "FILL_IN_THE_BLANKS") {
            const fillInTheBlanksBodyMapJSON = JSON.parse(
              fillInTheBlanksBodyMap
            );
            const fillInTheBlanksMaterialMapJSON = JSON.parse(
              fillInTheBlanksMaterialMap
            );
            const fillInTheBlanksTaxonomyMapJSON = JSON.parse(
              fillInTheBlanksTaxonomyMap
            );
            const fillInTheBlanksSolutionMapJSON = JSON.parse(
              fillInTheBlanksSolutionMap
            );
            if (
              !settings.includeImage &&
              fillInTheBlanksBodyMap.includes("IMAGE")
            )
              continue;
            if (!settings.translateImage) {
              const imageList = constructBodyImageListFromJSON(
                fillInTheBlanksBodyMapJSON
              );
              const filteredImageList = imageList.filter((image) => {
                const fileName = image.substring(
                  image.lastIndexOf("/") + 1,
                  image.length
                );
                return (
                  imageFileNameList.filter(
                    (imageFileName) => imageFileName.fileName === fileName
                  ).length > 0
                );
              });
              if (filteredImageList.length !== imageList.length) continue;
            }
            if (
              settings.onlyApprovedAnswers &&
              !fillInTheBlanksSolutionMap.includes("[ONAYLI CEVAP]")
            ) {
              continue;
            }
            totalValidActivityTemplateCount++;
            if (!activityTemplateCountMap[foundActivityTemplate.type]) {
              activityTemplateCountMap[foundActivityTemplate.type] = 1;
            } else {
              activityTemplateCountMap[foundActivityTemplate.type]++;
            }
            convertedJSON.messages.push(
              constructUserMessageWithMedia(
                fillInTheBlanksBodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(fillInTheBlanksTaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            anotherConvertedJSON.messages.push(
              constructUserMessageWithMedia(
                fillInTheBlanksBodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(fillInTheBlanksTaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            llamaConvertedJSON.input = constructUserMessageWithMedia(
              fillInTheBlanksBodyMapJSON,
              i18n.t(selectedCoterieType),
              outcomeId,
              `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
              i18n.t(fillInTheBlanksTaxonomyMapJSON.name),
              i18n.t(foundActivityTemplate.type)
            ).content;
            let fillInTheBlanksActivity = constructBodyMapFromJSON(
              fillInTheBlanksBodyMapJSON,
              imageFileNameList
            );
            fillInTheBlanksActivity += "\n[GENIXO]";
            for (
              let i = 0;
              i < Object.keys(fillInTheBlanksMaterialMapJSON).length;
              i++
            ) {
              const currentKey = Object.keys(fillInTheBlanksMaterialMapJSON)[i];
              const currentAnswer = JSON.parse(
                fillInTheBlanksMaterialMapJSON[currentKey]
              )[0].trim();
              fillInTheBlanksActivity += `\n[${i}]${currentAnswer}`;
            }
            fillInTheBlanksActivity += "\n[GENIXO]";
            fillInTheBlanksActivity += constructSolutionMapFromJSON(
              fillInTheBlanksSolutionMapJSON
            );
            convertedJSON.messages.push({
              role: "assistant",
              content: fillInTheBlanksActivity,
            });
            anotherConvertedJSON.messages.push({
              role: "assistant",
              content: fillInTheBlanksActivity,
            });
            llamaConvertedJSON.output = fillInTheBlanksActivity;
          } else if (foundActivityTemplate.type === "OPEN_ENDED") {
            const openEndedBodyMapJSON = JSON.parse(openEndedBodyMap);
            const openEndedMaterialMapJSON = JSON.parse(openEndedMaterialMap);
            const openEndedTaxonomyMapJSON = JSON.parse(openEndedTaxonomyMap);
            const openEndedSolutionMapJSON = JSON.parse(openEndedSolutionMap);
            if (!settings.includeImage && openEndedBodyMap.includes("IMAGE"))
              continue;
            if (!settings.translateImage) {
              const imageList =
                constructBodyImageListFromJSON(openEndedBodyMapJSON);
              const filteredImageList = imageList.filter((image) => {
                const fileName = image.substring(
                  image.lastIndexOf("/") + 1,
                  image.length
                );
                return (
                  imageFileNameList.filter(
                    (imageFileName) => imageFileName.fileName === fileName
                  ).length > 0
                );
              });
              if (filteredImageList.length !== imageList.length) continue;
            }
            if (
              settings.onlyApprovedAnswers &&
              !openEndedSolutionMap.includes("[ONAYLI CEVAP]")
            ) {
              continue;
            }
            totalValidActivityTemplateCount++;
            if (!activityTemplateCountMap[foundActivityTemplate.type]) {
              activityTemplateCountMap[foundActivityTemplate.type] = 1;
            } else {
              activityTemplateCountMap[foundActivityTemplate.type]++;
            }
            convertedJSON.messages.push(
              constructUserMessageWithMedia(
                openEndedBodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(openEndedTaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            anotherConvertedJSON.messages.push(
              constructUserMessageWithMedia(
                openEndedBodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(openEndedTaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            llamaConvertedJSON.input = constructUserMessageWithMedia(
              openEndedBodyMapJSON,
              i18n.t(selectedCoterieType),
              outcomeId,
              `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
              i18n.t(openEndedTaxonomyMapJSON.name),
              i18n.t(foundActivityTemplate.type)
            ).content;
            let openEndedActivity = constructBodyMapFromJSON(
              openEndedBodyMapJSON,
              imageFileNameList
            );
            openEndedActivity += "\n[GENIXO]";
            openEndedActivity += constructSolutionMapFromJSON(
              openEndedSolutionMapJSON
            );
            convertedJSON.messages.push({
              role: "assistant",
              content: openEndedActivity,
            });
            anotherConvertedJSON.messages.push({
              role: "assistant",
              content: openEndedActivity,
            });
            llamaConvertedJSON.output = openEndedActivity;
          } else if (foundActivityTemplate.type === "TRUE_FALSE") {
            const trueFalseBodyMapJSON = JSON.parse(trueFalseBodyMap);
            const trueFalseMaterialMapJSON = JSON.parse(trueFalseMaterialMap);
            const trueFalseTaxonomyMapJSON = JSON.parse(trueFalseTaxonomyMap);
            const trueFalseSolutionMapJSON = JSON.parse(trueFalseSolutionMap);
            if (!settings.includeImage && trueFalseBodyMap.includes("IMAGE"))
              continue;
            if (!settings.translateImage) {
              const imageList =
                constructBodyImageListFromJSON(trueFalseBodyMapJSON);
              const filteredImageList = imageList.filter((image) => {
                const fileName = image.substring(
                  image.lastIndexOf("/") + 1,
                  image.length
                );
                return (
                  imageFileNameList.filter(
                    (imageFileName) => imageFileName.fileName === fileName
                  ).length > 0
                );
              });
              if (filteredImageList.length !== imageList.length) continue;
            }
            if (
              settings.onlyApprovedAnswers &&
              !trueFalseSolutionMap.includes("[ONAYLI CEVAP]")
            ) {
              continue;
            }
            totalValidActivityTemplateCount++;
            if (!activityTemplateCountMap[foundActivityTemplate.type]) {
              activityTemplateCountMap[foundActivityTemplate.type] = 1;
            } else {
              activityTemplateCountMap[foundActivityTemplate.type]++;
            }
            convertedJSON.messages.push(
              constructUserMessageWithMedia(
                trueFalseBodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(trueFalseTaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            anotherConvertedJSON.messages.push(
              constructUserMessageWithMedia(
                trueFalseBodyMapJSON,
                i18n.t(selectedCoterieType),
                outcomeId,
                `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
                i18n.t(trueFalseTaxonomyMapJSON.name),
                i18n.t(foundActivityTemplate.type)
              )
            );
            llamaConvertedJSON.input = constructUserMessageWithMedia(
              trueFalseBodyMapJSON,
              i18n.t(selectedCoterieType),
              outcomeId,
              `${foundCategoryDTO.code} ${foundCategoryDTO.name}`,
              i18n.t(trueFalseTaxonomyMapJSON.name),
              i18n.t(foundActivityTemplate.type)
            ).content;
            let trueFalseActivity = constructBodyMapFromJSON(
              trueFalseBodyMapJSON,
              imageFileNameList
            );
            trueFalseActivity += "\n[GENIXO]";
            const trueList = JSON.parse(trueFalseMaterialMapJSON.trueList);
            for (const option of trueList) {
              trueFalseActivity += `\n[DOĞRU]${option}`;
            }
            const falseList = JSON.parse(trueFalseMaterialMapJSON.falseList);
            for (const option of falseList) {
              trueFalseActivity += `\n[YANLIŞ]${option}`;
            }
            trueFalseActivity += "\n[GENIXO]";
            trueFalseActivity += constructSolutionMapFromJSON(
              trueFalseSolutionMapJSON
            );
            convertedJSON.messages.push({
              role: "assistant",
              content: trueFalseActivity,
            });
            anotherConvertedJSON.messages.push({
              role: "assistant",
              content: trueFalseActivity,
            });
            llamaConvertedJSON.output = trueFalseActivity;
          }
          currentAnotherConvertedJSONList.push(anotherConvertedJSON);
          currentLlamaConvertedJSONList.push(llamaConvertedJSON);
        }
      }
      console.log(
        "Total: ",
        totalActivityTemplateCount,
        "Valid: ",
        totalValidActivityTemplateCount,
        "Map: ",
        activityTemplateCountMap
      );
      currentConvertedJSONList.push(convertedJSON);
    }
    setConvertedJSONList(JSON.parse(JSON.stringify(currentConvertedJSONList)));
    setAnotherConvertedJSONList(
      JSON.parse(JSON.stringify(currentAnotherConvertedJSONList))
    );
    setLlamaConvertedJSONList(
      JSON.parse(JSON.stringify(currentLlamaConvertedJSONList))
    );
    setStableDiffusionConvertedJSONList(
      JSON.parse(JSON.stringify(currentStableDiffusionConvertedJSONList))
    );
    setAutoMode(false);
  };

  const handleSelectOutcomeOnClick = (outcomeId) => {
    if (imageTranslateState === "COMPLETED" || !selectedOutcomeId) {
      setImageTranslateState("TRANSLATE");
      setSelectedOutcomeId(outcomeId);
    }
  };

  const RenderOutcomeIdListContent = () => {
    return (
      <div className="flex flex-row flex-wrap items-center max-h-[500px] overflow-y-auto">
        {selectedOutcomeIdList.map((outcomeId, index) => {
          const foundCategoryDTO = filteredCategorySet.find(
            (categoryDTO) => categoryDTO.id === outcomeId
          );
          return (
            <div
              key={index}
              className={`flex flex-row items-center border rounded-catchup-large py-2 cursor-pointer my-2 mx-2 ${
                selectedOutcomeId === foundCategoryDTO.id
                  ? "border-catchup-blue-400 shadow-input"
                  : activityDTOListMap[outcomeId]
                  ? "border-catchup-gray-100"
                  : "border-catchup-red"
              } transition-all duration-300 px-4`}
              onClick={() => {
                handleSelectOutcomeOnClick(foundCategoryDTO.id);
              }}
            >
              <p className="">{`${foundCategoryDTO.code} ${foundCategoryDTO.name}`}</p>
              <div className="mx-2">
                <BaseImage
                  size="small"
                  src="/icons/remove-red.png"
                  alt="remove-red"
                  onClick={() => {
                    const foundIndex = selectedOutcomeIdList.findIndex(
                      (outcomeId) => outcomeId === foundCategoryDTO.id
                    );
                    selectedOutcomeIdList.splice(foundIndex, 1);
                    setSelectedOutcomeIdList(
                      JSON.parse(JSON.stringify(selectedOutcomeIdList))
                    );
                    activityDTOListMap.delete(foundCategoryDTO.id);
                    setActivityDTOListMap(
                      JSON.parse(JSON.stringify(activityDTOListMap))
                    );
                    activityImageFileNameListMap.delete(foundCategoryDTO.id);
                    setActivityImageFileNameListMap(
                      JSON.parse(JSON.stringify(activityImageFileNameListMap))
                    );
                    if (selectedOutcomeId === foundCategoryDTO.id) {
                      setSelectedOutcomeId(null);
                    }
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const RenderActivityDTOListContent = () => {
    if (!activityDTOListMap[selectedOutcomeId]) return;
    return (
      <>
        <DividerLine />
        <div className="flex flex-row items-center">
          <div className="flex-1">
            <p>
              {i18n.t("coterie_type")}:{" "}
              <span className="font-semibold">
                {i18n.t(selectedCoterieType)}
              </span>
            </p>
            <p>
              {i18n.t("category_code")}:{" "}
              <span className="font-semibold">
                {i18n.t(selectedCategoryCode)}
              </span>
            </p>
            <p>
              {i18n.t("sub_category_code")}:{" "}
              <span className="font-semibold">
                {
                  filteredCategorySet.find(
                    (categoryDTO) => categoryDTO.id === selectedSubCategoryCode
                  )?.name
                }
              </span>
            </p>
            <p>
              {i18n.t("outcome_code")}:{" "}
              <span className="font-semibold">
                {
                  filteredCategorySet.find(
                    (categoryDTO) => categoryDTO.id === selectedOutcomeId
                  )?.name
                }
              </span>
            </p>
          </div>
          <div className="flex-1 flex flex-col jutify-center items-center">
            <p className="font-semibold">{i18n.t("ready")}</p>
            <p className="italic">{i18n.t("total_activity")}</p>
            <p className="text-3xl">
              {activityDTOListMap[selectedOutcomeId].length}
            </p>
          </div>
        </div>
        <div className="w-full">
          <div className="mx-2">
            <InputGroup
              type="textarea"
              title={i18n.t("system_description")}
              value={systemDescriptionMap[selectedOutcomeId]}
              onChange={(e) => {
                systemDescriptionMap[selectedOutcomeId] = e.target.value;
                setSystemDescriptionMap(
                  JSON.parse(JSON.stringify(systemDescriptionMap))
                );
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const RenderMainContent = () => {
    return (
      <FullCard>
        <div className="">
          <p className="font-semibold text-lg">
            {i18n.t("conversion_options")}
          </p>
        </div>
        <div className="flex flex-row items-center flex-wrap">
          <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("coterie_type")}
                placeholder={i18n.t("coterie_type")}
                value={selectedCoterieType}
                optionList={filterCoterieTypeOptionList(
                  userInformation,
                  userProfile,
                  userProfileInstitution
                )}
                onChange={(event) => {
                  handleOnChange("selectedCoterieType", event.target.value);
                }}
              />
            </div>
          </div>
          {selectedCoterieType !== "DEFAULT_OPTION" ? (
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("category_code")}
                  placeholder={i18n.t("category_code")}
                  value={selectedCategoryCode}
                  optionList={filteredCategoryVersionCodeOptionList}
                  onChange={(event) => {
                    handleOnChange("selectedCategoryCode", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
          {selectedCategoryCode !== "DEFAULT_OPTION" ? (
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("sub_category_code")}
                  placeholder={i18n.t("sub_category_code")}
                  value={selectedSubCategoryCode}
                  optionList={subCategoryDTOOptionList}
                  onChange={(event) => {
                    handleOnChange(
                      "selectedSubCategoryCode",
                      event.target.value
                    );
                  }}
                />
              </div>
            </div>
          ) : null}
          {selectedSubCategoryCode !== "DEFAULT_OPTION" ? (
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="relative w-full">
                <div className="absolute right-5 top-1">
                  <TextGroup
                    title={i18n.t("choose_all")}
                    onClick={() => {
                      outcomeDTOOptionList.forEach((outcomeDTO) => {
                        handleOnChange(
                          "selectedOutcomeIdList",
                          outcomeDTO.value
                        );
                      });
                    }}
                  />
                </div>
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("outcome_code")}
                    placeholder={i18n.t("outcome_code")}
                    value={selectedOutcomeId}
                    optionList={outcomeDTOOptionList}
                    onChange={(event) => {
                      handleOnChange(
                        "selectedOutcomeIdList",
                        event.target.value
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {selectedOutcomeIdList.length > 0 ? (
          <>
            <DividerLine />
            <div className="">
              <p className="font-semibold text-lg">
                {i18n.t("conversion_settings")}
              </p>
            </div>
            <div className="flex flex-row justify-between items-center gap-x-1 my-2">
              <div className="flex flex-col justify-center gap-y-1">
                <InputGroup
                  type="checkbox"
                  title={i18n.t("only_approved_answers")}
                  value={settings.onlyApprovedAnswers}
                  onClick={() => {
                    if (autoMode) {
                      NotificationManager.info(
                        i18n.t(
                          "conversion_process_is_in_progress_stop_to_proceed_text"
                        )
                      );
                    } else {
                      setSettings((prevSettings) => ({
                        ...prevSettings,
                        onlyApprovedAnswers: !prevSettings.onlyApprovedAnswers,
                      }));
                    }
                  }}
                />

                <InputGroup
                  type="checkbox"
                  title={i18n.t("translate_image_to_text_description")}
                  value={settings.translateImage}
                  onClick={() => {
                    if (autoMode) {
                      NotificationManager.info(
                        i18n.t(
                          "conversion_process_is_in_progress_stop_to_proceed_text"
                        )
                      );
                    } else {
                      setSelectedOutcomeIdList([]);
                      setActivityDTOList([]);
                      setActivityDTOListMap({});
                      setActivityImageFileNameList([]);
                      setActivityImageFileNameListMap({});
                      setSettings((prevSettings) => ({
                        ...prevSettings,
                        translateImage: !prevSettings.translateImage,
                      }));
                    }
                  }}
                />
                <InputGroup
                  type="checkbox"
                  title={i18n.t("include_image_description")}
                  value={settings.includeImage}
                  onClick={() => {
                    if (autoMode) {
                      NotificationManager.info(
                        i18n.t(
                          "conversion_process_is_in_progress_stop_to_proceed_text"
                        )
                      );
                    } else {
                      setSettings((prevSettings) => ({
                        ...prevSettings,
                        includeImage: !prevSettings.includeImage,
                      }));
                    }
                  }}
                />
              </div>

              <div>
                <PrimaryButton
                  title={autoMode ? i18n.t("cancel") : i18n.t("auto_mode")}
                  size="unlimited"
                  onClick={() => {
                    setAutoMode(!autoMode);
                  }}
                />
              </div>

              {/* <p className="">{i18n.t("auto_mode")}</p>
              <BaseImage
                src={
                  autoMode ? "/icons/checkbox.png" : "/icons/checkbox-empty.png"
                }
                alt="checkbox"
                size="xsmall"
                onClick={() => {
                  setAutoMode(!autoMode);
                }}
              /> */}
            </div>
            <DividerLine />
            {RenderOutcomeIdListContent()}
          </>
        ) : null}

        {loading ? (
          <>
            <DividerLine />
            <BaseLoading
              size="medium"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          </>
        ) : imageTranslateState === "TRANSLATE" &&
          differenceImageFileNameList.length > 0 ? (
          <>
            <DividerLine />
            <BaseLoading
              size="medium"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
            <div className="flex flex-col jutify-center items-center my-5">
              <p className="font-semibold">{i18n.t("please_wait")}</p>
              <p className="italic">
                {i18n.t("remaining_image_translate_count")}
              </p>
              <p className="text-3xl">{differenceImageFileNameList.length}</p>
            </div>
          </>
        ) : imageTranslateState === "COMPLETED" ? (
          <>
            {RenderActivityDTOListContent()}
            {activityDTOListMap[selectedOutcomeId]?.length > 0 ? (
              <div className="mt-5 flex flex-row justify-end">
                <div className="flex flex-row items-center gap-x-2">
                  <PrimaryButton
                    title={i18n.t("convert")}
                    size="small"
                    onClick={handleConvertAllToJSONLOnClick}
                  />
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </FullCard>
    );
  };

  const categoryVersionCodeOptionList = retrieveCategoryVersionCodeOptionList();
  const filteredCategoryVersionCodeOptionList =
    filterCategoryVersionCodeOptionList(
      categoryVersionCodeOptionList,
      selectedCoterieType
    );
  const filteredCategorySet = filterCategorySet();
  const subCategoryDTOOptionList =
    retrieveSubCategoryCodeList(filteredCategorySet);
  const outcomeDTOOptionList =
    retrieveOutcomeDTOOptionList(filteredCategorySet);

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ActivityJSONLConverterScreen;
