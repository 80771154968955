import i18n from "../language/i18n";

export const retrieveBrandDTOByUserProfileOptionList = (userProfile) => {
  const brandDTOOptionList = [];
  if (userProfile) {
    for (const branchDTO of userProfile.branchDTOList) {
      const currentBrand =
        branchDTO.gradeDTO.seasonDTO.institutionDTO.campusDTO.brandDTO;
      if (
        brandDTOOptionList.findIndex(
          (brandDTOOption) => brandDTOOption.value === currentBrand.id
        ) === -1
      ) {
        brandDTOOptionList.push({
          value: currentBrand.id,
          fullValue: currentBrand,
          text: currentBrand.name,
        });
      }
    }
    for (const gradeDTO of userProfile.gradeDTOList) {
      const currentBrand = gradeDTO.seasonDTO.institutionDTO.campusDTO.brandDTO;
      if (
        brandDTOOptionList.findIndex(
          (brandDTOOption) => brandDTOOption.value === currentBrand.id
        ) === -1
      ) {
        brandDTOOptionList.push({
          value: currentBrand.id,
          fullValue: currentBrand,
          text: currentBrand.name,
        });
      }
    }
    for (const seasonDTO of userProfile.seasonDTOList) {
      const currentBrand = seasonDTO.institutionDTO.campusDTO.brandDTO;
      if (
        brandDTOOptionList.findIndex(
          (brandDTOOption) => brandDTOOption.value === currentBrand.id
        ) === -1
      ) {
        brandDTOOptionList.push({
          value: currentBrand.id,
          fullValue: currentBrand,
          text: currentBrand.name,
        });
      }
    }
    for (const institutionDTO of userProfile.institutionDTOList) {
      const currentBrand = institutionDTO.campusDTO.brandDTO;
      if (
        brandDTOOptionList.findIndex(
          (brandDTOOption) => brandDTOOption.value === currentBrand.id
        ) === -1
      ) {
        brandDTOOptionList.push({
          value: currentBrand.id,
          fullValue: currentBrand,
          text: currentBrand.name,
        });
      }
    }
    for (const campusDTO of userProfile.campusDTOList) {
      const currentBrand = campusDTO.brandDTO;
      if (
        brandDTOOptionList.findIndex(
          (brandDTOOption) => brandDTOOption.value === currentBrand.id
        ) === -1
      ) {
        brandDTOOptionList.push({
          value: currentBrand.id,
          fullValue: currentBrand,
          text: currentBrand.name,
        });
      }
    }
    for (const brandDTO of userProfile.brandDTOList) {
      const currentBrand = brandDTO;
      if (
        brandDTOOptionList.findIndex(
          (brandDTOOption) => brandDTOOption.value === currentBrand.id
        ) === -1
      ) {
        brandDTOOptionList.push({
          value: currentBrand.id,
          fullValue: currentBrand,
          text: currentBrand.name,
        });
      }
    }
  }

  return brandDTOOptionList;
};

export const retrieveBrandDTOOptionList = (brandDTOList) => {
  return brandDTOList.map((brandDTO) => ({
    value: brandDTO.id,
    text: brandDTO.name,
    fullValue: brandDTO,
  }));
};

export const retrieveCampusDTOOptionList = (campusDTOList) => {
  return campusDTOList.map((campusDTO) => ({
    value: campusDTO.id,
    text: `${campusDTO.name} (${campusDTO.brandDTO.name})`,
    fullValue: campusDTO,
  }));
};

export const retrieveInstitutionDTOOptionList = (institutionDTOList) => {
  return institutionDTOList.map((institutionDTO) => ({
    value: institutionDTO.id,
    text: `${institutionDTO.name} (${institutionDTO.campusDTO.name} - ${institutionDTO.campusDTO.brandDTO.name})`,
    fullValue: institutionDTO,
  }));
};

export const retrieveSeasonDTOOptionList = (seasonDTOList) => {
  return seasonDTOList.map((seasonDTO) => ({
    value: seasonDTO.id,
    text: `${seasonDTO.name} (${seasonDTO.institutionDTO.name})`,
    fullValue: seasonDTO,
    // text: `${seasonDTO.name} (${seasonDTO.institutionDTO.name} - ${seasonDTO.institutionDTO.campusDTO.name}/${seasonDTO.institutionDTO.campusDTO.brandDTO.name})`,
  }));
};

export const retrieveGradeDTOOptionList = (gradeDTOList) => {
  return gradeDTOList.map((gradeDTO) => ({
    value: gradeDTO.id,
    text: `${gradeDTO.name} (${gradeDTO.seasonDTO.name} - ${gradeDTO.seasonDTO.institutionDTO.name})`,
    fullValue: gradeDTO,
    // text: `${gradeDTO.name} (${gradeDTO.seasonDTO.name} ${gradeDTO.seasonDTO.institutionDTO.name} - ${gradeDTO.seasonDTO.institutionDTO.campusDTO.name}/${gradeDTO.seasonDTO.institutionDTO.campusDTO.brandDTO.name})`,
  }));
};

export const retrieveBranchDTOOptionList = (branchDTOList) => {
  return branchDTOList.map((branchDTO) => ({
    value: branchDTO.id,
    text: `${branchDTO.name} (${branchDTO.gradeDTO.name} - ${branchDTO.gradeDTO.seasonDTO.name}/${branchDTO.gradeDTO.seasonDTO.institutionDTO.name})`,
    fullValue: branchDTO,
    // text: `${branchDTO.name} (${branchDTO.gradeDTO.name} - ${branchDTO.gradeDTO.seasonDTO.name}/${branchDTO.gradeDTO.seasonDTO.institutionDTO.name}/${branchDTO.gradeDTO.seasonDTO.institutionDTO.campusDTO.name}/${branchDTO.gradeDTO.seasonDTO.institutionDTO.campusDTO.brandDTO.name})`,
  }));
};

export const retrieveCampusDTOByUserProfileOptionList = (
  userProfile,
  selectedBrandId
) => {
  const campusDTOOptionList = [];
  if (userProfile) {
    if (selectedBrandId) {
      for (const branchDTO of userProfile.branchDTOList) {
        const currentCampus =
          branchDTO.gradeDTO.seasonDTO.institutionDTO.campusDTO;
        if (
          campusDTOOptionList.findIndex(
            (campusDTOOption) => campusDTOOption.value === currentCampus.id
          ) === -1 &&
          currentCampus.brandDTO.id === parseFloat(selectedBrandId)
        ) {
          campusDTOOptionList.push({
            value: currentCampus.id,
            fullValue: currentCampus,
            text: currentCampus.name,
          });
        }
      }
      for (const gradeDTO of userProfile.gradeDTOList) {
        const currentCampus = gradeDTO.seasonDTO.institutionDTO.campusDTO;
        if (
          campusDTOOptionList.findIndex(
            (campusDTOOption) => campusDTOOption.value === currentCampus.id
          ) === -1 &&
          currentCampus.brandDTO.id === parseFloat(selectedBrandId)
        ) {
          campusDTOOptionList.push({
            value: currentCampus.id,
            fullValue: currentCampus,
            text: currentCampus.name,
          });
        }
      }
      for (const seasonDTO of userProfile.seasonDTOList) {
        const currentCampus = seasonDTO.institutionDTO.campusDTO;
        if (
          campusDTOOptionList.findIndex(
            (campusDTOOption) => campusDTOOption.value === currentCampus.id
          ) === -1 &&
          currentCampus.brandDTO.id === parseFloat(selectedBrandId)
        ) {
          campusDTOOptionList.push({
            value: currentCampus.id,
            fullValue: currentCampus,
            text: currentCampus.name,
          });
        }
      }
      for (const institutionDTO of userProfile.institutionDTOList) {
        const currentCampus = institutionDTO.campusDTO;
        if (
          campusDTOOptionList.findIndex(
            (campusDTOOption) => campusDTOOption.value === currentCampus.id
          ) === -1 &&
          currentCampus.brandDTO.id === parseFloat(selectedBrandId)
        ) {
          campusDTOOptionList.push({
            value: currentCampus.id,
            fullValue: currentCampus,
            text: currentCampus.name,
          });
        }
      }
      for (const campusDTO of userProfile.campusDTOList) {
        const currentCampus = campusDTO;
        if (
          campusDTOOptionList.findIndex(
            (campusDTOOption) => campusDTOOption.value === currentCampus.id
          ) === -1 &&
          currentCampus.brandDTO.id === parseFloat(selectedBrandId)
        ) {
          campusDTOOptionList.push({
            value: currentCampus.id,
            fullValue: currentCampus,
            text: currentCampus.name,
          });
        }
      }
      return campusDTOOptionList;
    }
  }

  return [];
};

export const retrieveInstitutionDTOByUserProfileOptionList = (
  userProfile,
  selectedBrandId,
  selectedCampusId
) => {
  const institutionDTOOptionList = [];
  if (userProfile) {
    if (selectedCampusId) {
      for (const branchDTO of userProfile.branchDTOList) {
        const currentInstitution = branchDTO.gradeDTO.seasonDTO.institutionDTO;
        if (
          institutionDTOOptionList.findIndex(
            (institutionDTOOption) =>
              institutionDTOOption.value === currentInstitution.id
          ) === -1 &&
          currentInstitution.campusDTO.brandDTO.id ===
            parseFloat(selectedBrandId) &&
          currentInstitution.campusDTO.id === parseFloat(selectedCampusId)
        ) {
          institutionDTOOptionList.push({
            value: currentInstitution.id,
            fullValue: currentInstitution,
            text: currentInstitution.name,
          });
        }
      }
      for (const gradeDTO of userProfile.gradeDTOList) {
        const currentInstitution = gradeDTO.seasonDTO.institutionDTO;
        if (
          institutionDTOOptionList.findIndex(
            (institutionDTOOption) =>
              institutionDTOOption.value === currentInstitution.id
          ) === -1 &&
          currentInstitution.campusDTO.brandDTO.id ===
            parseFloat(selectedBrandId) &&
          currentInstitution.campusDTO.id === parseFloat(selectedCampusId)
        ) {
          institutionDTOOptionList.push({
            value: currentInstitution.id,
            fullValue: currentInstitution,
            text: currentInstitution.name,
          });
        }
      }
      for (const seasonDTO of userProfile.seasonDTOList) {
        const currentInstitution = seasonDTO.institutionDTO;
        if (
          institutionDTOOptionList.findIndex(
            (institutionDTOOption) =>
              institutionDTOOption.value === currentInstitution.id
          ) === -1 &&
          currentInstitution.campusDTO.brandDTO.id ===
            parseFloat(selectedBrandId) &&
          currentInstitution.campusDTO.id === parseFloat(selectedCampusId)
        ) {
          institutionDTOOptionList.push({
            value: currentInstitution.id,
            fullValue: currentInstitution,
            text: currentInstitution.name,
          });
        }
      }
      for (const institutionDTO of userProfile.institutionDTOList) {
        const currentInstitution = institutionDTO;
        if (
          institutionDTOOptionList.findIndex(
            (institutionDTOOption) =>
              institutionDTOOption.value === currentInstitution.id
          ) === -1 &&
          currentInstitution.campusDTO.brandDTO.id ===
            parseFloat(selectedBrandId) &&
          currentInstitution.campusDTO.id === parseFloat(selectedCampusId)
        ) {
          institutionDTOOptionList.push({
            value: currentInstitution.id,
            fullValue: currentInstitution,
            text: currentInstitution.name,
          });
        }
      }
    }
  }
  return institutionDTOOptionList;
};

export const retrieveSeasonDTOByUserProfileOptionList = (
  userProfile,
  selectedBrandId,
  selectedInstitutionId
) => {
  const seasonDTOOptionList = [];
  if (userProfile) {
    if (selectedInstitutionId) {
      for (const branchDTO of userProfile.branchDTOList) {
        const currentSeason = branchDTO.gradeDTO.seasonDTO;
        if (
          seasonDTOOptionList.findIndex(
            (seasonDTOOption) => seasonDTOOption.value === currentSeason.id
          ) === -1 &&
          currentSeason.institutionDTO.campusDTO.brandDTO.id ===
            parseFloat(selectedBrandId) &&
          currentSeason.institutionDTO.id === parseFloat(selectedInstitutionId)
        ) {
          seasonDTOOptionList.push({
            value: currentSeason.id,
            fullValue: currentSeason,
            text: currentSeason.name,
          });
        }
      }
      for (const gradeDTO of userProfile.gradeDTOList) {
        const currentSeason = gradeDTO.seasonDTO;
        if (
          seasonDTOOptionList.findIndex(
            (seasonDTOOption) => seasonDTOOption.value === currentSeason.id
          ) === -1 &&
          currentSeason.institutionDTO.campusDTO.brandDTO.id ===
            parseFloat(selectedBrandId) &&
          currentSeason.institutionDTO.id === parseFloat(selectedInstitutionId)
        ) {
          seasonDTOOptionList.push({
            value: currentSeason.id,
            fullValue: currentSeason,
            text: currentSeason.name,
          });
        }
      }
      for (const seasonDTO of userProfile.seasonDTOList) {
        const currentSeason = seasonDTO;
        if (
          seasonDTOOptionList.findIndex(
            (seasonDTOOption) => seasonDTOOption.value === currentSeason.id
          ) === -1 &&
          currentSeason.institutionDTO.campusDTO.brandDTO.id ===
            parseFloat(selectedBrandId) &&
          currentSeason.institutionDTO.id === parseFloat(selectedInstitutionId)
        ) {
          seasonDTOOptionList.push({
            value: currentSeason.id,
            fullValue: currentSeason,
            text: currentSeason.name,
          });
        }
      }
    }
  }
  return seasonDTOOptionList;
};

export const retrieveGradeDTOByUserProfileOptionList = (
  userProfile,
  selectedBrandId,
  selectedSeasonId
) => {
  const gradeDTOOptionList = [];
  if (userProfile) {
    if (selectedSeasonId) {
      for (const branchDTO of userProfile.branchDTOList) {
        const currentGrade = branchDTO.gradeDTO;
        if (
          gradeDTOOptionList.findIndex(
            (gradeDTOOption) => gradeDTOOption.value === currentGrade.id
          ) === -1 &&
          currentGrade.seasonDTO.institutionDTO.campusDTO.brandDTO.id ===
            parseFloat(selectedBrandId) &&
          currentGrade.seasonDTO.id === parseFloat(selectedSeasonId)
        ) {
          gradeDTOOptionList.push({
            value: currentGrade.id,
            fullValue: currentGrade,
            text: currentGrade.name,
          });
        }
      }
      for (const gradeDTO of userProfile.gradeDTOList) {
        const currentGrade = gradeDTO;
        if (
          gradeDTOOptionList.findIndex(
            (gradeDTOOption) => gradeDTOOption.value === currentGrade.id
          ) === -1 &&
          currentGrade.seasonDTO.institutionDTO.campusDTO.brandDTO.id ===
            parseFloat(selectedBrandId) &&
          currentGrade.seasonDTO.id === parseFloat(selectedSeasonId)
        ) {
          gradeDTOOptionList.push({
            value: currentGrade.id,
            fullValue: currentGrade,
            text: currentGrade.name,
          });
        }
      }
    }
  }
  return gradeDTOOptionList;
};

export const retrieveBranchDTOByUserProfileOptionList = (
  userProfile,
  selectedBrandId,
  selectedGradeId
) => {
  const branchDTOOptionList = [];
  if (userProfile) {
    if (selectedGradeId) {
      for (const branchDTO of userProfile.branchDTOList) {
        const currentBranch = branchDTO;
        if (
          branchDTOOptionList.findIndex(
            (branchDTOOption) => branchDTOOption.value === currentBranch.id
          ) === -1 &&
          currentBranch.gradeDTO.seasonDTO.institutionDTO.campusDTO.brandDTO
            .id === parseFloat(selectedBrandId) &&
          currentBranch.gradeDTO.id === parseFloat(selectedGradeId)
        ) {
          branchDTOOptionList.push({
            value: currentBranch.id,
            fullValue: currentBranch,
            text: currentBranch.name,
          });
        }
      }
    }
  }
  return branchDTOOptionList;
};

export const retrieveExternalRegistrationDTOOptionList = (
  externalRegistrationDTOList
) => {
  return externalRegistrationDTOList.map((externalRegistrationDTO) => ({
    value: externalRegistrationDTO.id,
    text: externalRegistrationDTO.name,
    fullValue: externalRegistrationDTO,
    // text: `${seasonDTO.name} (${seasonDTO.institutionDTO.name} - ${seasonDTO.institutionDTO.campusDTO.name}/${seasonDTO.institutionDTO.campusDTO.brandDTO.name})`,
  }));
};

export const retrieveGenderOptionList = () => {
  return [
    {
      value: "MALE",
      text: i18n.t("MALE"),
    },
    {
      value: "FEMALE",
      text: i18n.t("FEMALE"),
    },
    {
      value: "NOT_GIVEN",
      text: i18n.t("NOT_GIVEN"),
    },
  ];
};

export const retrieveEnableOptionList = () => {
  return [
    {
      value: true,
      text: i18n.t("yes"),
    },
    {
      value: false,
      text: i18n.t("no"),
    },
  ];
};

export const retrieveUserRoleOptionList = () => {
  return [
    {
      value: "STAFF",
      text: i18n.t("STAFF"),
    },
    {
      value: "LEARNER",
      text: i18n.t("LEARNER"),
    },
    {
      value: "PARENT",
      text: i18n.t("PARENT"),
    },
    {
      value: "INDIVIDUAL",
      text: i18n.t("INDIVIDUAL"),
    },
    {
      value: "CONTENT_CREATOR",
      text: i18n.t("CONTENT_CREATOR"),
    },
  ];
};

export const filterUserRoleOptionList = (accountType, userProfileRole) => {
  if (accountType === "GENIXO") {
    return retrieveUserRoleOptionList().filter(
      (userRoleOption) =>
        userRoleOption.value === "STAFF" ||
        userRoleOption.value === "LEARNER" ||
        userRoleOption.value === "CONTENT_CREATOR"
    );
  } else {
    if (userProfileRole === "STAFF") {
      return retrieveUserRoleOptionList().filter(
        (userRoleOption) =>
          userRoleOption.value === "STAFF" || userRoleOption.value === "LEARNER"
      );
    } else if (userProfileRole === "INDIVIDUAL") {
      return retrieveUserRoleOptionList().filter(
        (userRoleOption) => userRoleOption.value === "LEARNER"
      );
    }
  }

  return [];
};

export const retrieveDefaultUserRoleOptionList = () => {
  return [
    {
      value: "BASIC",
      text: i18n.t("BASIC"),
    },
    {
      value: "LEARNER",
      text: i18n.t("LEARNER"),
    },
    {
      value: "STAFF_WRITER",
      text: i18n.t("STAFF_WRITER"),
    },
    {
      value: "STAFF_TEACHER",
      text: i18n.t("STAFF_TEACHER"),
    },
    {
      value: "STAFF_COTERIE_MANAGER",
      text: i18n.t("STAFF_COTERIE_MANAGER"),
    },
    {
      value: "STAFF_INSTITUTION_MANAGER",
      text: i18n.t("STAFF_INSTITUTION_MANAGER"),
    },
    {
      value: "STAFF_INSTITUTION_ASSISTANT_MANAGER",
      text: i18n.t("STAFF_INSTITUTION_ASSISTANT_MANAGER"),
    },
    {
      value: "STAFF_CAMPUS_MANAGER",
      text: i18n.t("STAFF_CAMPUS_MANAGER"),
    },
    {
      value: "STAFF_BRAND_MANAGER",
      text: i18n.t("STAFF_BRAND_MANAGER"),
    },
    {
      value: "STAFF_INFORMATION_TECHNOLOGY",
      text: i18n.t("STAFF_INFORMATION_TECHNOLOGY"),
    },
    {
      value: "INDIVIDUAL",
      text: i18n.t("INDIVIDUAL"),
    },
    {
      value: "CONTENT_CREATOR",
      text: i18n.t("CONTENT_CREATOR"),
    },
  ];
};

export const retrieveCountryCodeOptionList = () => {
  return [
    {
      value: "TR",
      text: i18n.t("TR"),
      parent: "TURKEY",
    },
    // {
    //   value: "US",
    //   text: i18n.t("US"),
    //   parent: "UNITED_STATES",
    // },
    // {
    //   value: "DE",
    //   text: i18n.t("DE"),
    //   parent: "GERMANY",
    // },
  ];
};

export const retrieveCountryNameOptionList = () => {
  return [
    {
      value: "TURKEY",
      text: i18n.t("TURKEY"),
    },
    // {
    //   value: "UNITED_STATES",
    //   text: i18n.t("UNITED_STATES"),
    // },
    // {
    //   value: "GERMANY",
    //   text: i18n.t("GERMANY"),
    // },
  ];
};

export const retrieveProvinceNameOptionList = () => {
  return [
    // { parent: "TURKEY", value: "ADANA", text: "Adana", code: "01" },
    // { parent: "TURKEY", value: "ADIYAMAN", text: "Adıyaman", code: "02" },
    // { parent: "TURKEY", value: "AFYON", text: "Afyon", code: "03" },
    // { parent: "TURKEY", value: "AGRI", text: "Ağrı", code: "04" },
    // { parent: "TURKEY", value: "AMASYA", text: "Amasya", code: "05" },
    { parent: "TURKEY", value: "ANKARA", text: "Ankara", code: "06" },
    { parent: "TURKEY", value: "ANTALYA", text: "Antalya", code: "07" },
    // { parent: "TURKEY", value: "ARTVIN", text: "Artvin", code: "08" },
    // { parent: "TURKEY", value: "AYDIN", text: "Aydın", code: "09" },
    // { parent: "TURKEY", value: "BALIKESIR", text: "Balıkesir", code: "10" },
    // { parent: "TURKEY", value: "BILECIK", text: "Bilecik", code: "11" },
    // { parent: "TURKEY", value: "BINGOL", text: "Bingöl", code: "12" },
    // { parent: "TURKEY", value: "BITLIS", text: "Bitlis", code: "13" },
    // { parent: "TURKEY", value: "BOLU", text: "Bolu", code: "14" },
    // { parent: "TURKEY", value: "BURDUR", text: "Burdur", code: "15" },
    // { parent: "TURKEY", value: "BURSA", text: "Bursa", code: "16" },
    // { parent: "TURKEY", value: "CANAKKALE", text: "Çanakkale", code: "17" },
    // { parent: "TURKEY", value: "CANKIRI", text: "Çankırı", code: "18" },
    // { parent: "TURKEY", value: "CORUM", text: "Çorum", code: "19" },
    // { parent: "TURKEY", value: "DENIZLI", text: "Denizli", code: "20" },
    // { parent: "TURKEY", value: "DIYARBAKIR", text: "Diyarbakır", code: "21" },
    // { parent: "TURKEY", value: "EDIRNE", text: "Edirne", code: "22" },
    // { parent: "TURKEY", value: "ELAZIG", text: "Elazığ", code: "23" },
    // { parent: "TURKEY", value: "ERZINCAN", text: "Erzincan", code: "24" },
    // { parent: "TURKEY", value: "ERZURUM", text: "Erzurum", code: "25" },
    { parent: "TURKEY", value: "ESKISEHIR", text: "Eskişehir", code: "26" },
    { parent: "TURKEY", value: "GAZIANTEP", text: "Gaziantep", code: "27" },
    // { parent: "TURKEY", value: "GIRESUN", text: "Giresun", code: "28" },
    // { parent: "TURKEY", value: "GUMUSHANE", text: "Gümüşhane", code: "29" },
    // { parent: "TURKEY", value: "HAKKARI", text: "Hakkari", code: "30" },
    // { parent: "TURKEY", value: "HATAY", text: "Hatay", code: "31" },
    // { parent: "TURKEY", value: "ISPARTA", text: "Isparta", code: "32" },
    { parent: "TURKEY", value: "MERSIN", text: "Mersin", code: "33" },
    { parent: "TURKEY", value: "ISTANBUL", text: "İstanbul", code: "34" },
    { parent: "TURKEY", value: "IZMIR", text: "İzmir", code: "35" },
    // { parent: "TURKEY", value: "KARS", text: "Kars", code: "36" },
    // { parent: "TURKEY", value: "KASTAMONU", text: "Kastamonu", code: "37" },
    // { parent: "TURKEY", value: "KAYSERI", text: "Kayseri", code: "38" },
    // { parent: "TURKEY", value: "KIRKLARELI", text: "Kırklareli", code: "39" },
    // { parent: "TURKEY", value: "KIRSEHIR", text: "Kırşehir", code: "40" },
    // { parent: "TURKEY", value: "KOCAELI", text: "Kocaeli", code: "41" },
    // { parent: "TURKEY", value: "KONYA", text: "Konya", code: "42" },
    // { parent: "TURKEY", value: "KUTAHYA", text: "Kütahya", code: "43" },
    // { parent: "TURKEY", value: "MALATYA", text: "Malatya", code: "44" },
    // { parent: "TURKEY", value: "MANISA", text: "Manisa", code: "45" },
    // {
    //   parent: "TURKEY",
    //   value: "KAHRAMANMARAS",
    //   text: "Kahramanmaraş",
    //   code: "46",
    // },
    // { parent: "TURKEY", value: "MARDIN", text: "Mardin", code: "47" },
    // { parent: "TURKEY", value: "MUGLA", text: "Muğla", code: "48" },
    // { parent: "TURKEY", value: "MUS", text: "Muş", code: "49" },
    // { parent: "TURKEY", value: "NEVSEHIR", text: "Nevşehir", code: "50" },
    // { parent: "TURKEY", value: "NIGDE", text: "Niğde", code: "51" },
    // { parent: "TURKEY", value: "ORDU", text: "Ordu", code: "52" },
    // { parent: "TURKEY", value: "RIZE", text: "Rize", code: "53" },
    // { parent: "TURKEY", value: "SAKARYA", text: "Sakarya", code: "54" },
    // { parent: "TURKEY", value: "SAMSUN", text: "Samsun", code: "55" },
    // { parent: "TURKEY", value: "SIIRT", text: "Siirt", code: "56" },
    // { parent: "TURKEY", value: "SINOP", text: "Sinop", code: "57" },
    // { parent: "TURKEY", value: "SIVAS", text: "Sivas", code: "58" },
    // { parent: "TURKEY", value: "TEKIRDAG", text: "Tekirdağ", code: "59" },
    // { parent: "TURKEY", value: "TOKAT", text: "Tokat", code: "60" },
    // { parent: "TURKEY", value: "TRABZON", text: "Trabzon", code: "61" },
    // { parent: "TURKEY", value: "TUNCELI", text: "Tunceli", code: "62" },
    // { parent: "TURKEY", value: "SANLIURFA", text: "Şanlıurfa", code: "63" },
    // { parent: "TURKEY", value: "USAK", text: "Uşak", code: "64" },
    // { parent: "TURKEY", value: "VAN", text: "Van", code: "65" },
    // { parent: "TURKEY", value: "YOZGAT", text: "Yozgat", code: "66" },
    // { parent: "TURKEY", value: "ZONGULDAK", text: "Zonguldak", code: "67" },
    // { parent: "TURKEY", value: "AKSARAY", text: "Aksaray", code: "68" },
    // { parent: "TURKEY", value: "BAYBURT", text: "Bayburt", code: "69" },
    // { parent: "TURKEY", value: "KARAMAN", text: "Karaman", code: "70" },
    // { parent: "TURKEY", value: "KIRIKKALE", text: "Kırıkkale", code: "71" },
    // { parent: "TURKEY", value: "BATMAN", text: "Batman", code: "72" },
    // { parent: "TURKEY", value: "SIRNAK", text: "Şırnak", code: "73" },
    // { parent: "TURKEY", value: "BARTIN", text: "Bartın", code: "74" },
    // { parent: "TURKEY", value: "ARDAHAN", text: "Ardahan", code: "75" },
    // { parent: "TURKEY", value: "IGDIR", text: "Iğdır", code: "76" },
    // { parent: "TURKEY", value: "YALOVA", text: "Yalova", code: "77" },
    // { parent: "TURKEY", value: "KARABUK", text: "Karabük", code: "78" },
    // { parent: "TURKEY", value: "KILIS", text: "Kilis", code: "79" },
    // { parent: "TURKEY", value: "OSMANIYE", text: "Osmaniye", code: "80" },
    // { parent: "TURKEY", value: "Düzce", text: "Düzce", code: "81" },
  ];
};

export const retrievePhoneNumberAreaCodeList = () => {
  return [
    {
      parent: "UNITED_STATES",
      value: "+1",
      text: "+1",
    },
    {
      parent: "GERMANY",
      value: "+49",
      text: "+49",
    },
    {
      parent: "TURKEY",
      value: "+90",
      text: "+90",
    },
  ];
};

export const retrieveInstitutionTypeOptionList = () => {
  return [
    {
      value: "WEST_PRIMARY",
      text: i18n.t("WEST_PRIMARY"),
    },
    {
      value: "EAST_PRIMARY",
      text: i18n.t("EAST_PRIMARY"),
    },
    {
      value: "EAST_SECONDARY",
      text: i18n.t("EAST_SECONDARY"),
    },
    {
      value: "HIGH_SCHOOL",
      text: i18n.t("HIGH_SCHOOL"),
    },
    {
      value: "COLLEGE",
      text: i18n.t("COLLEGE"),
    },
    {
      value: "PRIVATE_TRAINING",
      text: i18n.t("PRIVATE_TRAINING"),
    },
    {
      value: "PRIVATE_LESSON",
      text: i18n.t("PRIVATE_LESSON"),
    },
    {
      value: "COURSE",
      text: i18n.t("COURSE"),
    },
    {
      value: "COMPANY",
      text: i18n.t("COMPANY"),
    },
    {
      value: "ORGANIZATION",
      text: i18n.t("ORGANIZATION"),
    },
  ];
};

export const retrieveGradeLevelOptionList = () => {
  return [
    {
      value: 0,
      text: i18n.t("other"),
    },
    {
      value: 1,
      text: i18n.t("1"),
    },
    {
      value: 2,
      text: i18n.t("2"),
    },
    {
      value: 3,
      text: i18n.t("3"),
    },
    {
      value: 4,
      text: i18n.t("4"),
    },
    {
      value: 5,
      text: i18n.t("5"),
    },
    {
      value: 6,
      text: i18n.t("6"),
    },
    {
      value: 7,
      text: i18n.t("7"),
    },
    {
      value: 8,
      text: i18n.t("8"),
    },
    {
      value: 9,
      text: i18n.t("9"),
    },
    {
      value: 10,
      text: i18n.t("10"),
    },
    {
      value: 11,
      text: i18n.t("11"),
    },
    {
      value: 12,
      text: i18n.t("12"),
    },
  ];
};

export const constructUserProfileQueryParams = (
  userProfile,
  userProfileBrand
) => {
  let queryParams;
  if (userProfile.branchDTOList.length > 0) {
    queryParams = {
      ...queryParams,
      branchIdList: userProfile.branchDTOList.map((branchDTO) => branchDTO.id),
    };
  } else if (userProfile.gradeDTOList.length > 0) {
    queryParams = {
      ...queryParams,
      gradeIdList: userProfile.gradeDTOList.map((gradeDTO) => gradeDTO.id),
    };
  } else if (userProfile.seasonDTOList.length > 0) {
    queryParams = {
      ...queryParams,
      seasonIdList: userProfile.seasonDTOList.map((seasonDTO) => seasonDTO.id),
    };
  } else if (userProfile.institutionDTOList.length > 0) {
    queryParams = {
      ...queryParams,
      institutionIdList: userProfile.institutionDTOList.map(
        (institutionDTO) => institutionDTO.id
      ),
    };
  } else if (userProfile.campusDTOList.length > 0) {
    queryParams = {
      ...queryParams,
      campusIdList: userProfile.campusDTOList.map((campusDTO) => campusDTO.id),
    };
  } else if (userProfile.brandDTOList.length > 0) {
    queryParams = {
      ...queryParams,
      brandIdList: [userProfileBrand.id],
    };
  }
  queryParams.deleted = false;
  return queryParams;
};

export const retrieveCoterieTypeOptionList = () => {
  return [
    {
      text: i18n.t("MANAGEMENT"),
      value: "MANAGEMENT",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "HIGH_SCHOOL",
        "COURSE",
      ],
    },
    {
      text: i18n.t("TURKISH"),
      value: "TURKISH",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "HIGH_SCHOOL",
        "COURSE",
      ],
    },
    {
      text: i18n.t("HISTORY"),
      value: "HISTORY",
      includes: ["COLLEGE", "HIGH_SCHOOL", "COURSE"],
    },
    {
      text: i18n.t("MATHEMATICS"),
      value: "MATHEMATICS",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "HIGH_SCHOOL",
        "COURSE",
      ],
    },
    {
      text: i18n.t("BIOLOGY"),
      value: "BIOLOGY",
      includes: ["COLLEGE", "HIGH_SCHOOL", "COURSE"],
    },
    {
      text: i18n.t("LITERATURE"),
      value: "LITERATURE",
      includes: ["COLLEGE", "HIGH_SCHOOL", "COURSE"],
    },
    {
      text: i18n.t("GEOGRAPHY"),
      value: "GEOGRAPHY",
      includes: ["COLLEGE", "HIGH_SCHOOL", "COURSE"],
    },
    {
      text: i18n.t("ENGLISH"),
      value: "ENGLISH",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "HIGH_SCHOOL",
        "COURSE",
      ],
    },
    {
      text: i18n.t("PHYSICS"),
      value: "PHYSICS",
      includes: ["COLLEGE", "HIGH_SCHOOL", "COURSE"],
    },
    {
      text: i18n.t("CHEMISTRY"),
      value: "CHEMISTRY",
      includes: ["COLLEGE", "HIGH_SCHOOL", "COURSE"],
    },
    {
      text: i18n.t("PHILOSOPHY"),
      value: "PHILOSOPHY",
      includes: ["COLLEGE", "HIGH_SCHOOL", "COURSE"],
    },
    {
      text: i18n.t("PHYSICAL_EDUCATION"),
      value: "PHYSICAL_EDUCATION",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "HIGH_SCHOOL",
        "COURSE",
      ],
    },
    {
      text: i18n.t("SOCIAL_STUDIES"),
      value: "SOCIAL_STUDIES",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "COURSE",
      ],
    },
    {
      text: i18n.t("SCIENCE"),
      value: "SCIENCE",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "COURSE",
      ],
    },
    {
      text: i18n.t("LIFE_STUDIES"),
      value: "LIFE_STUDIES",
      includes: ["WEST_PRIMARY", "EAST_PRIMARY", "COLLEGE", "COURSE"],
    },
    {
      text: i18n.t("CULTURE_AND_RELIGION_KNOWLEDGE"),
      value: "CULTURE_AND_RELIGION_KNOWLEDGE",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "HIGH_SCHOOL",
        "COURSE",
      ],
    },
    {
      text: i18n.t("TRAFFIC_SAFETY"),
      value: "TRAFFIC_SAFETY",
      includes: ["WEST_PRIMARY", "EAST_PRIMARY", "COLLEGE", "COURSE"],
    },
    {
      text: i18n.t("GENERAL_CULTURE"),
      value: "GENERAL_CULTURE",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "HIGH_SCHOOL",
        "COURSE",
      ],
    },
  ];
};

export const retrieveUserAuthorityGeneralOptionList = () => {
  return [
    { text: i18n.t("all"), value: "" },
    { text: i18n.t("user"), value: "USER" },
    { text: i18n.t("activity"), value: "ACTIVITY" },
    { text: i18n.t("catchtivity"), value: "CATCHTIVITY" },
    { text: i18n.t("catchxam"), value: "CATCHXAM" },
    { text: i18n.t("label"), value: "LABEL" },
    { text: i18n.t("announcement"), value: "NOTIFICATION" },
    { text: i18n.t("report"), value: "REPORT" },
    { text: i18n.t("category"), value: "CATEGORY" },
    { text: i18n.t("library"), value: "STORAGE_FILE" },
    { text: i18n.t("brand"), value: "BRAND" },
    { text: i18n.t("region"), value: "REGION" },
    { text: i18n.t("campus"), value: "CAMPUS" },
    { text: i18n.t("institution"), value: "INSTITUTION" },
    { text: i18n.t("season"), value: "SEASON" },
    { text: i18n.t("grade"), value: "GRADE" },
    { text: i18n.t("branch"), value: "BRANCH" },
    { text: i18n.t("plan"), value: "PLAN" },
    { text: i18n.t("standard_exam"), value: "STANDARD_EXAM" },
    { text: i18n.t("etude"), value: "ETUDE" },
  ];
};

export const filterGradeLevelOptionList = (institutionDTO, gradeDTO) => {
  if (gradeDTO) {
    return retrieveGradeLevelOptionList().filter(
      (gradeLevel) => parseFloat(gradeLevel.value) === gradeDTO.level
    );
  }
  if (institutionDTO) {
    const { type } = institutionDTO;
    if (type === "WEST_PRIMARY") {
      return retrieveGradeLevelOptionList().filter(
        (gradeLevel) => parseFloat(gradeLevel.value) <= 8
      );
    } else if (type === "EAST_PRIMARY") {
      return retrieveGradeLevelOptionList().filter(
        (gradeLevel) => parseFloat(gradeLevel.value) <= 4
      );
    } else if (type === "EAST_SECONDARY") {
      return retrieveGradeLevelOptionList().filter(
        (gradeLevel) =>
          parseFloat(gradeLevel.value) > 4 && parseFloat(gradeLevel.value) <= 8
      );
    } else if (type === "HIGH_SCHOOL") {
      return retrieveGradeLevelOptionList().filter(
        (gradeLevel) => parseFloat(gradeLevel.value) > 8
      );
    } else if (
      type === "COLLEGE" ||
      type === "PRIVATE_TRAINING" ||
      type === "PRIVATE_LESSON" ||
      type === "COURSE"
    ) {
      return retrieveGradeLevelOptionList();
    } else {
      return [];
    }
  }
  return retrieveGradeLevelOptionList();
};

export const filterCoterieTypeOptionList = (
  userInformation,
  userProfile,
  userProfileInstitution
) => {
  if (userInformation.accountType === "GENIXO") {
    return retrieveCoterieTypeOptionList().sort((a, b) =>
      a.text.localeCompare(b.text)
    );
  }
  if (userProfile.role === "STAFF" || userProfile.role === "CONTENT_CREATOR") {
    if (userProfile.coterieType === "MANAGEMENT") {
      if (userProfileInstitution) {
        return retrieveCoterieTypeOptionList()
          .filter((coterieTypeOption) =>
            coterieTypeOption.includes.includes(userProfileInstitution.type)
          )
          .sort((a, b) => a.text.localeCompare(b.text));
      } else {
        return retrieveCoterieTypeOptionList().sort((a, b) =>
          a.text.localeCompare(b.text)
        );
      }
    } else {
      return retrieveCoterieTypeOptionList()
        .filter(
          (coterieTypeOption) =>
            coterieTypeOption.value === userProfile.coterieType
        )
        .sort((a, b) => a.text.localeCompare(b.text));
    }
  } else if (userProfile.role === "INDIVIDUAL") {
    const individualCoterieTypeOptionList = [];
    const coterieTypeOptionList = retrieveCoterieTypeOptionList();
    userProfile.coterieTypeList.forEach((coterieType) => {
      const foundCoterieTypeOption = coterieTypeOptionList.find(
        (coterieTypeOption) => coterieTypeOption.value === coterieType
      );
      if (foundCoterieTypeOption) {
        individualCoterieTypeOptionList.push(foundCoterieTypeOption);
      }
    });
    return individualCoterieTypeOptionList;
  }
  return [];
};

export const findAISettingsFromCurrentProfile = (
  userProfileBrand,
  userProfileCampus,
  userProfileInstitution
) => {
  if (userProfileInstitution) {
    const { institutionSettingsDTO } = userProfileInstitution;
    const { institutionAISettingsDTO } = institutionSettingsDTO;
    return institutionAISettingsDTO;
    // const { canConvertActivityTemplate } = institutionAISettingsDTO;
    // if (!canConvertActivityTemplate) return false;
  } else {
    if (userProfileCampus) {
      const { campusSettingsDTO } = userProfileCampus;
      const { campusAISettingsDTO } = campusSettingsDTO;
      return campusAISettingsDTO;
    } else {
      if (userProfileBrand) {
        const { brandSettingsDTO } = userProfileBrand;
        const { brandAISettingsDTO } = brandSettingsDTO;
        return brandAISettingsDTO;
      }
    }
  }
};
