import { useNavigate } from "react-router-dom";
import i18n from "../../language/i18n";
import ApproveButton from "../buttons/ApproveButton";
import SecondaryButton from "../buttons/SecondaryButton";
import BaseImage from "../images/BaseImage";
import { Tooltip } from "react-tooltip";
import DividerLine from "../divider/DividerLine";
import GradeLabel from "../label/GradeLabel";
import { retrieveMonthNameByIndex } from "../../utilization/DateUtilization";

const CatchtivityApplicationCard = (props) => {
  const navigate = useNavigate();
  const {
    catchtivityApplicationDTO,
    language,
    handleToUpdateOnClick,
    handleEvaluateOnClick,
  } = props;
  const ONE_DAY = 86400000;

  let borderColor;
  let deadlineAtColor;
  let showToUpdateButton;
  let showToDetailButton;
  let showEvaluateButton;

  if (!catchtivityApplicationDTO) return;
  const { id, beginAt, endAt, isEvaluated, catchtivityDTO, completionStatus } =
    catchtivityApplicationDTO;
  const { name, description, coterieType, level, activityDTOList } =
    catchtivityDTO;

  const currentTime = new Date();
  const currentTimestamp = currentTime.getTime();
  const beginTime = new Date(beginAt);
  const endTime = new Date(endAt);

  const calculateRemainingDays = (providedTime) => {
    const remainingTime = providedTime - currentTimestamp;
    return Math.ceil(remainingTime / 1000 / 60 / 60 / 24);
  };

  if (beginAt > currentTimestamp) {
    if (beginAt - currentTimestamp > ONE_DAY) {
      borderColor = "border-catchup-gray-50";
      deadlineAtColor = "text-catchup-gray-400";
      showToUpdateButton = true;
      showToDetailButton = false;
      showEvaluateButton = false;
    } else {
      borderColor = "border-catchup-gray-50";
      deadlineAtColor = "text-catchup-gray-400";
      showToUpdateButton = true;
      showToDetailButton = false;
      showEvaluateButton = false;
    }
  } else if (beginAt < currentTimestamp && endAt > currentTimestamp) {
    if (endAt - currentTimestamp > ONE_DAY) {
      borderColor = "border-catchup-blue-400";
      deadlineAtColor = "text-catchup-gray-400";
      showToUpdateButton = true;
      showToDetailButton = false;
      showEvaluateButton = false;
    } else {
      borderColor = "border-catchup-blue-400";
      deadlineAtColor = "text-catchup-red";
      showToUpdateButton = true;
      showToDetailButton = false;
      showEvaluateButton = false;
    }
  } else {
    if (isEvaluated) {
      borderColor = "border-catchup-green";
      deadlineAtColor = "text-catchup-gray-400";
      showToUpdateButton = false;
      showToDetailButton = false;
      showEvaluateButton = true;
    } else {
      borderColor = "border-catchup-green";
      deadlineAtColor = "text-catchup-gray-400";
      showToUpdateButton = false;
      showToDetailButton = false;
      showEvaluateButton = true;
    }
  }

  return (
    <div
      className={`${borderColor} bg-catchup-white border-2 rounded-catchup-xlarge p-4 mx-2 my-4 w-[400px]`}
    >
      <div className="flex flex-row gap-x-2">
        <BaseImage
          size="large"
          src="/icons/catchtivity.png"
          alt="catchtivity"
        />
        <div className="flex-1 flex flex-col">
          <p className="font-semibold text-catchup-blue-500 text-lg">
            {i18n.t("catchtivity")}
          </p>
          <p>
            {beginTime.getUTCDate()}
            {"-"}
            {endTime.getUTCDate()}{" "}
            {retrieveMonthNameByIndex(endTime.getMonth())}{" "}
            {endTime.getFullYear()}
          </p>
        </div>
        <div>
          <GradeLabel title={`${i18n.t("grade")}: ${level}`} />
        </div>
      </div>
      <div className="my-2">
        <DividerLine />
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <p className="font-semibold">{name}</p>
          <div className="flex flex-row items-center gap-x-2 text-catchup-gray-300">
            <div>
              <p>{i18n.t(coterieType)}</p>
            </div>
            <img
              className="w-1 h-1"
              src="/icons/small-dot-gray.png"
              alt="small-dot-gray"
            />
            <div>
              <p>
                {activityDTOList.length} {i18n.t("activity")}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-2 mt-2">
            <p className={`${deadlineAtColor}`}>{`${i18n.t(
              "deadline_date"
            )}: ${endTime.toLocaleString(language)}`}</p>
          </div>
        </div>
        <div>
          <Tooltip id="info-description" />
          <div className="mr-2">
            <BaseImage
              size="small"
              src="/icons/info.png"
              alt="info"
              dataToolTipId="info-description"
              dataToolTipContent={description}
              dataToolTipPlace="top"
              dataToolTipVariant="info"
            />
          </div>
        </div>
      </div>

      {showEvaluateButton ? (
        isEvaluated ? (
          <div className="flex-1 flex flex-col mt-5">
            <SecondaryButton
              title={i18n.t("to_detail")}
              size="unlimited"
              onClick={() => {
                navigate(`/catchtivities/applications/${id}/detail`);
              }}
            />
          </div>
        ) : (
          <div className="flex-1 flex flex-col mt-5">
            <ApproveButton
              title={i18n.t("evaluate")}
              size="unlimited"
              onClick={async () => {
                await handleEvaluateOnClick(id);
              }}
            />
          </div>
        )
      ) : showToDetailButton ? (
        <div className="flex-1 flex flex-col mt-5">
          <SecondaryButton
            title={i18n.t("to_detail")}
            size="unlimited"
            onClick={() => {
              navigate(`/catchtivities/applications/${id}/detail`);
            }}
          />
        </div>
      ) : showToUpdateButton ? (
        <div className="flex-1 flex flex-col mt-5">
          <SecondaryButton
            title={i18n.t("edit")}
            size="unlimited"
            onClick={() => {
              handleToUpdateOnClick(catchtivityApplicationDTO);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CatchtivityApplicationCard;
