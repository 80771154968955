import { useNavigate, useParams } from "react-router-dom";
import FullCard from "../../components/cards/FullCard";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../context/UserContextProvider";
import {
  createParsedActivity,
  deleteParsedActivityById,
  patchParsedActivity,
  processActivityParserFromPath,
  queryParsedActivity,
} from "../../requests/FileActivityParserRequests";
import { checkInsertPermissionGranted } from "../../utilization/ScreenUtilization";
import { NotificationManager } from "react-notifications";
import i18n from "../../language/i18n";
import { showDocumentByPathAndFileName } from "../../utilization/StorageFileUtilization";
import useScreenSize from "../../hooks/useScreenSize";
import CreateActivity from "../../components/activity/CreateActivity";
import { retrieveActivityWithDataById } from "../../requests/CatchtivityRequests";
import { queryConvertedFileList } from "../../requests/FileConverterRequests";
import {
  filterCoterieTypeOptionList,
  filterGradeLevelOptionList,
} from "../../utilization/ManagementUtilization";
import InputGroup from "../../components/groups/InputGroup";
import {
  filterCategoryVersionCodeOptionList,
  retrieveCategoryVersionCodeOptionList,
} from "../../utilization/CategoryUtilization";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import {
  deleteStorageFileByFileName,
  moveFileToToBeLabelledPath,
  retrieveMainFileByFileName,
} from "../../requests/StorageRequests";
import BaseModal from "../../components/modal/BaseModal";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import { addPublishingHouseLabelItemToPublishingHouseLabelActionFromPublishingHouseLabelId } from "../../requests/LabelRequests";
import { useApp } from "../../context/AppContextProvider";
import DividerLine from "../../components/divider/DividerLine";
import PageTravelGroup from "../../components/groups/PageTravelGroup";
import BaseLoading from "../../components/loading/BaseLoading";

const CreateActivityFromPDFScreen = () => {
  const navigate = useNavigate();
  const { mode, fileName } = useParams();
  const screenSize = useScreenSize();
  const { isFullScreen, setIsFullScreen, mainLeftNavigationState } = useApp();
  const {
    language,
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileInstitution,
    userProfileGrade,
    userPublishingHouseLabelSet,
  } = useAuth();
  const [fileId, setFileId] = useState(null);
  const [fileOriginalName, setFileOriginalName] = useState(null);
  const [filePageOffset, setFilePageOffset] = useState(0);
  const [pureFileName, setPureFileName] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [initialTotalPageNumber, setInitialTotalPageNumber] = useState(0);
  const [convertedFileList, setConvertedFileList] = useState([]);
  const [parsedActivityList, setParsedActivityList] = useState([]);
  const [imageReady, setImageReady] = useState(false);
  const [isImageProcessing, setIsImageProcessing] = useState(false);
  const [selectedParsedActivity, setSelectedParsedActivity] = useState(null);
  const [selectedCroppedActivity, setSelectedCroppedActivity] = useState(null);
  const [existingActivity, setExistingActivity] = useState(null);
  const [fileParseStatus, setFileParseStatus] = useState(null);
  const [pageState, setPageState] = useState(0);
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");
  const [selectedLevel, setSelectedLevel] = useState("DEFAULT_OPTION");
  const [selectedVersionCode, setSelectedVersionCode] =
    useState("DEFAULT_OPTION");
  const [selectedParsedActivityParams, setSelectedParsedActivityParams] =
    useState({
      canvasId: null,
    });
  const [isCropModeActive, setIsCropModeActive] = useState(false);
  const [canProcessImage, setCanProcessImage] = useState(false);
  const imageContainerRef = useRef(null);
  const continueButtonRef = useRef(null);
  const [crop, setCrop] = useState(null);
  const [uploadedCroppedFileName, setUploadedCroppedFileName] = useState(null);
  const [
    includedPublishingHouseLabelIdList,
    setIncludedPublishingHouseLabelIdList,
  ] = useState([]);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkInsertPermissionGranted("activity_list")) {
      navigate("/permission-error");
    } else {
      retrieveAndSetConvertedFileList();
    }
  }, []);

  useEffect(() => {
    if (!userProfile) return;
    const { coterieType } = userProfile;
    if (coterieType !== "MANAGEMENT") {
      setSelectedCoterieType(coterieType);
    }
  }, []);

  useEffect(() => {
    const splittedFileName = fileName.split(".");
    if (splittedFileName.length === 1 || splittedFileName.length === 2) {
      setPureFileName(splittedFileName[0]);
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const createActivityFromPDFParamListString = localStorage.getItem(
      "createActivityFromPDFParamList"
    );
    if (createActivityFromPDFParamListString) {
      const createActivityFromPDFParamList = JSON.parse(
        createActivityFromPDFParamListString
      );
      const foundCreateActivityFromPDFParam =
        createActivityFromPDFParamList.find(
          (createActivityFromPDFParam) =>
            createActivityFromPDFParam.fileName === fileName
        );
      if (!foundCreateActivityFromPDFParam) return;
      const {
        pageNumber: foundPageNumber,
        coterieType: foundPageCoterieType,
        level: foundPageLevel,
        versionCode: foundPageVersionCode,
      } = foundCreateActivityFromPDFParam;
      if (foundPageNumber) {
        setPageNumber(foundPageNumber);
      }
      if (foundPageCoterieType) {
        setSelectedCoterieType(foundPageCoterieType);
      }
      if (foundPageLevel) {
        setSelectedLevel(foundPageLevel);
      }
      if (foundPageVersionCode) {
        setSelectedVersionCode(foundPageVersionCode);
      }
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetMainFileByFileName = async () => {
      const { data, err } = await retrieveMainFileByFileName(fileName);
      if (err) {
        console.log(err);
        return;
      }
      const { id, fileOriginalName, pageOffset } = data;
      setFileId(id);
      setFileOriginalName(fileOriginalName);
      setFilePageOffset(pageOffset);
    };
    if (!fileName) return;
    retrieveAndSetMainFileByFileName();
  }, [fileName]);

  useEffect(() => {
    if (mode !== "import-from-pdf") {
      navigate("/");
    }
  }, [mode]);

  useEffect(() => {
    if (pageState === 0) {
      setExistingActivity(null);
    }
  }, [pageState]);

  useEffect(() => {
    if (pureFileName) {
      setParsedActivityList([]);
      setCrop(null);
      setIsCropModeActive(false);
      retrieveAndSetFileActivityParser();
    }
  }, [pureFileName, pageNumber]);

  useEffect(() => {
    if (!imageReady) return;
    if (pageState === 0) {
      drawParsedActivityCanvasList();
    }
  }, [
    parsedActivityList,
    imageReady,
    pageState,
    isCropModeActive,
    screenSize,
    mainLeftNavigationState,
  ]);

  useEffect(() => {
    if (fileParseStatus === "PARSED") {
      setTimeout(() => {
        retrieveAndSetConvertedFileList();
        setFileParseStatus(null);
      }, 3000);
    }
  }, [fileParseStatus]);

  useEffect(() => {
    const constructCreateActivityParams = async (params) => {
      const parsedActivityId = parseInt(
        params.canvasId.replace("parsed_activity_canvas_", "")
      );
      const foundParsedActivity = parsedActivityList.find(
        (parsedActivity) => parsedActivity.id === parsedActivityId
      );
      if (selectedCoterieType === "DEFAULT_OPTION") {
        NotificationManager.error(i18n.t("coterie_type_must_be_selected"));
        return;
      }
      if (selectedLevel === "DEFAULT_OPTION") {
        NotificationManager.error(i18n.t("level_must_be_selected"));
        return;
      }
      if (selectedVersionCode === "DEFAULT_OPTION") {
        NotificationManager.error(i18n.t("version_code_must_be_selected"));
        return;
      }
      if (
        userInformation.accountType === "GENIXO" &&
        includedPublishingHouseLabelIdList.length === 0
      ) {
        NotificationManager.error(
          i18n.t("publishing_house_label_items_must_be_selected")
        );
        return;
      }

      const canvas = document.getElementById(params.canvasId);
      const canvasLeft = parseInt(canvas.style.left.replace("px", ""));
      const canvasTop = parseInt(canvas.style.top.replace("px", ""));
      const canvasWidth = parseInt(canvas.style.width.replace("px", ""));
      const canvasHeight = parseInt(canvas.style.height.replace("px", ""));
      const image = Array.from(imageContainerRef.current.children).filter(
        (child) => child.nodeName === "IMG"
      )[0];

      handleOnParsedActivityOnClick(
        foundParsedActivity,
        image,
        canvasLeft,
        canvasTop,
        canvasWidth,
        canvasHeight
      );
    };

    if (selectedParsedActivityParams.canvasId) {
      constructCreateActivityParams(selectedParsedActivityParams);
    }
    setSelectedParsedActivityParams({
      canvasId: null,
    });
  }, [selectedParsedActivityParams.canvasId]);

  useEffect(() => {
    const createActivityFromPDFParamListString = localStorage.getItem(
      "createActivityFromPDFParamList"
    );
    if (createActivityFromPDFParamListString) {
      const createActivityFromPDFParamList = JSON.parse(
        createActivityFromPDFParamListString
      );
      const foundCreateActivityFromPDFParam =
        createActivityFromPDFParamList.find(
          (createActivityFromPDFParam) =>
            createActivityFromPDFParam.fileName === fileName
        );
      if (foundCreateActivityFromPDFParam) {
        foundCreateActivityFromPDFParam.pageNumber = pageNumber;
        foundCreateActivityFromPDFParam.coterieType = selectedCoterieType;
        foundCreateActivityFromPDFParam.level = selectedLevel;
        foundCreateActivityFromPDFParam.versionCode = selectedVersionCode;
      } else {
        createActivityFromPDFParamList.push({
          fileName,
          pageNumber,
          coterieType: selectedCoterieType,
          level: selectedLevel,
          versionCode: selectedVersionCode,
        });
      }
      localStorage.setItem(
        "createActivityFromPDFParamList",
        JSON.stringify(createActivityFromPDFParamList)
      );
    } else {
      localStorage.setItem(
        "createActivityFromPDFParamList",
        JSON.stringify([
          {
            fileName,
            pageNumber,
            coterieType: selectedCoterieType,
            level: selectedLevel,
            versionCode: selectedVersionCode,
          },
        ])
      );
    }
  }, [pageNumber, selectedCoterieType, selectedLevel, selectedVersionCode]);

  const retrieveAndSetFileActivityParser = async () => {
    let fileFolder = "";
    if (process.env.REACT_APP_NODE_ENV === "development") {
      fileFolder += ".";
    }
    fileFolder += `./catchup-storage/brand-files/${userProfileBrand.id}/pdfs/${pureFileName}`;
    const { data, err } = await queryParsedActivity({
      fileFolder,
      pageNumber,
    });
    if (err) {
      return;
    }
    if (data.length === 0) {
      setCanProcessImage(true);
      return;
    }
    setCanProcessImage(false);
    setParsedActivityList(data);
  };

  const retrieveAndProcessActivityParserFromPath = async () => {
    setIsImageProcessing(true);
    let fileFolder = "";
    if (process.env.REACT_APP_NODE_ENV === "development") {
      fileFolder += ".";
    }
    fileFolder += `./catchup-storage/brand-files/${userProfileBrand.id}/pdfs/${pureFileName}`;
    const { data, err } = await processActivityParserFromPath({
      fileId,
      fileName,
      fileFolder,
      pageNumber,
    });
    setIsImageProcessing(false);
    if (err) {
      NotificationManager.error(i18n.t("error_occurred_on_page_processing"));
      return;
    }
    setParsedActivityList(data);
    NotificationManager.success(i18n.t("page_processing_completed"));
  };

  const retrieveAndSetConvertedFileList = async () => {
    const { data, err } = await queryConvertedFileList({
      fileFolder: `/${userProfileBrand.id}/pdfs/${fileName}`,
    });
    if (err) {
      console.log(err);
      return;
    }
    if (data.length === 0) {
      // handleRemoveUploadedPDFOnClick();
    } else {
      setConvertedFileList(data);
      setInitialTotalPageNumber(data.length);
    }
  };

  const retrieveFileNameFromPageNumber = () => {
    const foundConvertedFile = convertedFileList.find(
      (convertedFile) => convertedFile.pageNumber === pageNumber
    );
    if (foundConvertedFile) {
      return foundConvertedFile.fileName;
    }
    return "NOT_FOUND";
  };

  const filterPublishingHouseLabelOptionList = () => {
    return userPublishingHouseLabelSet.map((userPublishingHouseLabel) => ({
      text: userPublishingHouseLabel.name,
      value: userPublishingHouseLabel.id,
    }));
  };

  const handleRemoveUploadedPDFOnClick = async () => {
    const { err } = await deleteStorageFileByFileName(fileName);
    if (err) {
      console.log(err);
      return;
    }
    navigate("/activities/create/import-from-pdf");
  };

  const drawParsedActivityCanvasList = () => {
    if (imageContainerRef.current) {
      const { clientWidth } = imageContainerRef.current;
      parsedActivityList.map((parsedActivity) => {
        const {
          id,
          detectionBoxList,
          inputWidth,
          activityId,
          classification,
          confidence,
        } = parsedActivity;

        if (detectionBoxList.length === 0) return;

        const ratio = clientWidth / inputWidth;
        // const heightRatio = clientHeight / inputHeight;
        const x0 = parseInt(
          detectionBoxList.find((detectionBox) => detectionBox.key === "x0")
            .pixel
        );
        const y0 = parseInt(
          detectionBoxList.find((detectionBox) => detectionBox.key === "y0")
            .pixel
        );
        const x1 = parseInt(
          detectionBoxList.find((detectionBox) => detectionBox.key === "x1")
            .pixel
        );
        const y1 = parseInt(
          detectionBoxList.find((detectionBox) => detectionBox.key === "y1")
            .pixel
        );
        const convertedLeft = parseInt(x0 * ratio);
        const convertedTop = parseInt(y0 * ratio);
        const convertedWidth = parseInt(x1 * ratio);
        const convertedHeight = parseInt(y1 * ratio);
        const canvasWidth = convertedWidth - convertedLeft;
        const canvasHeight = convertedHeight - convertedTop;
        const canvasId = `parsed_activity_canvas_${id}`;
        const pId = `parsed_activity_canvas_text_${id}`;
        const crossId = `parsed_activity_canvas_cross_${id}`;

        let canvas;
        const foundCanvas = document.getElementById(canvasId);
        if (isCropModeActive) {
          if (foundCanvas) {
            foundCanvas.remove();
          }
        } else {
          if (foundCanvas) {
            canvas = foundCanvas;
          } else {
            canvas = document.createElement("canvas");
            canvas.id = canvasId;
            canvas.addEventListener(
              "click",
              (event) => {
                handleOnSetParsedActivityOnClick(event);
              },
              true
            );
            imageContainerRef.current.append(canvas);
          }
          canvas.className = `absolute hover:opacity-50 ${
            activityId
              ? "hover:bg-catchup-green"
              : classification === "TEXT"
              ? "hover:bg-catchup-blue"
              : classification === "IMAGE"
              ? "hover:bg-catchup-orange"
              : ""
          } hover:cursor-pointer transition-all duration-500 border rounded ${
            activityId
              ? "border-catchup-green"
              : classification === "TEXT"
              ? "border-catchup-blue"
              : classification === "IMAGE"
              ? "border-catchup-orange"
              : ""
          } ${activityId ? "bg-catchup-green opacity-25" : "opacity-100"}`;
          canvas.style.left = `${convertedLeft}px`;
          canvas.style.top = `${convertedTop}px`;
          canvas.style.width = `${canvasWidth}px`;
          canvas.style.height = `${canvasHeight}px`;
        }

        let p;
        const foundP = document.getElementById(pId);
        if (isCropModeActive) {
          if (foundP) {
            foundP.remove();
          }
        } else {
          if (foundP) {
            p = foundP;
          } else {
            p = document.createElement("p");
            p.id = pId;

            imageContainerRef.current.append(p);
          }

          p.className = `absolute 
          ${
            activityId
              ? "text-catchup-green"
              : classification === "TEXT"
              ? "text-catchup-blue"
              : classification === "IMAGE"
              ? "text-catchup-orange"
              : ""
          } text-xl
            ${
              activityId
                ? "hover:bg-catchup-green hover:text-catchup-white"
                : classification === "TEXT"
                ? "hover:bg-catchup-blue hover:text-catchup-white"
                : classification === "IMAGE"
                ? "hover:bg-catchup-orange hover:text-catchup-white "
                : ""
            } p-1 rounded transition-all duration-500 cursor-pointer`;

          p.innerText = `${i18n.t(classification)} (${
            confidence ? confidence.toFixed(2) : 1.0
          }) ${activityId ? " ✔" : ""}`;
          p.style.top = `${convertedTop - 34}px`;
          p.style.left = `${activityId ? convertedLeft : convertedLeft}px`;
        }

        let cross;
        const foundCross = document.getElementById(crossId);
        if (isCropModeActive) {
          if (foundCross) {
            foundCross.remove();
          }
        } else {
          if (foundCross) {
            cross = foundCross;
            if (activityId) {
              document.getElementById(crossId)?.remove();
            }
          } else {
            cross = document.createElement("p");
            cross.id = crossId;
            cross.addEventListener("click", (event) => {
              handleDeleteParsedActivityOnClick(event);
            });
            if (!activityId) {
              imageContainerRef.current.append(cross);
            }
          }
          cross.className = `absolute 
          ${
            activityId
              ? "text-catchup-green"
              : classification === "TEXT"
              ? "text-catchup-blue"
              : classification === "IMAGE"
              ? "text-catchup-orange"
              : ""
          } text-xl
            ${
              activityId
                ? "hover:bg-catchup-green hover:text-catchup-white"
                : classification === "TEXT"
                ? "hover:bg-catchup-blue hover:text-catchup-white"
                : classification === "IMAGE"
                ? "hover:bg-catchup-orange hover:text-catchup-white "
                : ""
            } p-1 rounded transition-all duration-500 cursor-pointer font-semibold`;

          cross.innerText = `X`;
          cross.style.top = `${convertedTop - 34}px`;
          cross.style.left = `${convertedLeft + canvasWidth - 20}px`;
        }
      });
    }
  };

  const handleOnChange = (type, value) => {
    if (type === "coterieType") {
      setSelectedCoterieType(value);
      setSelectedVersionCode("DEFAULT_OPTION");
    } else if (type === "level") {
      setSelectedLevel(value);
    } else if (type === "versionCode") {
      setSelectedVersionCode(value);
    }
  };

  const handleOnImageLoad = () => {
    setImageReady(true);
  };

  const handleOnSetParsedActivityOnClick = async (event) => {
    setSelectedParsedActivityParams({ canvasId: event.target.id });
  };

  const handleOnParsedActivityOnClick = async (
    foundParsedActivity,
    image,
    canvasLeft,
    canvasTop,
    canvasWidth,
    canvasHeight
  ) => {
    const ratio = image.naturalWidth / image.clientWidth;

    const newCanvas = document.createElement("canvas");
    const context = newCanvas.getContext("2d");
    newCanvas.width = canvasWidth * ratio;
    newCanvas.height = canvasHeight * ratio;

    context.drawImage(
      image,
      canvasLeft * ratio,
      canvasTop * ratio,
      canvasWidth * ratio,
      canvasHeight * ratio,
      0,
      0,
      canvasWidth * ratio,
      canvasHeight * ratio
    );
    const imageData = newCanvas.toDataURL("image/jpeg");

    setSelectedParsedActivity({
      ...foundParsedActivity,
      imageData,
      name: `${fileOriginalName} [${i18n.t("page")} ${
        pageNumber + filePageOffset + 1
      }] - ${new Date().toLocaleString(language)}`,
      description: `${fileOriginalName} [${i18n.t("page")} ${
        pageNumber + filePageOffset + 1
      }] - ${new Date().toLocaleString(language)}`,
      selectedCoterieType,
      selectedLevel,
      selectedVersionCode,
    });
    if (foundParsedActivity.activityId) {
      NotificationManager.info(i18n.t("please_wait_loading_text"));
      const { data, err } = await retrieveActivityWithDataById(
        foundParsedActivity.activityId
      );
      if (err) {
        NotificationManager.error(i18n.t("error_occurred_on_loading_activity"));
        return;
      }
      setExistingActivity(data);
      NotificationManager.success(i18n.t("loading_completed"));
    }
    setPageState(1);
  };

  const handleToCreateFromPDFOnClick = () => {
    setPageState(0);
  };

  const handleAfterUpdateActivityAction = async () => {
    setCrop(null);
    return;
  };

  const handleAfterCreateActivityAction = async (createdActivity) => {
    if (isCropModeActive) {
      const { data, err } = await createParsedActivity({
        ...selectedCroppedActivity,
        fileId,
        fileName,
        activityId: createdActivity.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      parsedActivityList.push(data);
      setParsedActivityList([...parsedActivityList]);
      setIsCropModeActive(false);

      if (uploadedCroppedFileName) {
        const { data: moveData, err: moveErr } =
          await moveFileToToBeLabelledPath({
            tempPath: `/${userProfileBrand.id}/images`,
            fileName: uploadedCroppedFileName,
            level: selectedLevel,
            versionCode: selectedVersionCode,
          });
        if (moveErr) {
          NotificationManager.error(i18n.t("moving_cropped_image_has_failed"));
          return;
        }
        NotificationManager.success(
          i18n.t("moving_cropped_image_has_successfully_completed")
        );
      }
    } else {
      const { data, err } = await patchParsedActivity({
        id: selectedParsedActivity.id,
        activityId: createdActivity.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      const foundIndex = parsedActivityList.findIndex(
        (parsedActivity) => parsedActivity.id === data.id
      );
      if (foundIndex !== -1) {
        parsedActivityList[foundIndex].activityId = createdActivity.id;
        setParsedActivityList([...parsedActivityList]);
      }
    }
    if (includedPublishingHouseLabelIdList.length > 0) {
      for (const includedPublishingHouseLabelId of includedPublishingHouseLabelIdList) {
        const { err } =
          await addPublishingHouseLabelItemToPublishingHouseLabelActionFromPublishingHouseLabelId(
            {
              userId: userInformation.id,
              userProfileId: userProfile.id,
              publishingHouseLabelId: includedPublishingHouseLabelId,
              itemId: createdActivity.id,
              itemType: "ACTIVITY",
            }
          );
      }
    }
    setCrop(null);
  };

  const handleSameActivityUpdatedBySomeoneAction = () => {
    return;
  };

  const handleReturnFromActivityOnClick = () => {
    navigate(-1);
  };

  const handleCropModeSelectedOnClick = () => {
    if (selectedCoterieType === "DEFAULT_OPTION") {
      NotificationManager.error(i18n.t("coterie_type_must_be_selected"));
      return;
    }
    if (selectedLevel === "DEFAULT_OPTION") {
      NotificationManager.error(i18n.t("level_must_be_selected"));
      return;
    }
    if (selectedVersionCode === "DEFAULT_OPTION") {
      NotificationManager.error(i18n.t("version_code_must_be_selected"));
      return;
    }
    if (
      userInformation.accountType === "GENIXO" &&
      includedPublishingHouseLabelIdList.length === 0
    ) {
      NotificationManager.error(
        i18n.t("publishing_house_label_items_must_be_selected")
      );
      return;
    }

    const canvasLeft = parseInt(crop.x);
    const canvasTop = parseInt(crop.y);
    const canvasWidth = parseInt(crop.width);
    const canvasHeight = parseInt(crop.height);
    const image = Array.from(imageContainerRef.current.children).filter(
      (child) => child.nodeName === "IMG"
    )[0];

    const detectionBoxList = [];
    const ratio = image.naturalWidth / image.clientWidth;
    detectionBoxList.push({
      key: "x0",
      pixel: parseInt(crop.x * ratio),
    });
    detectionBoxList.push({
      key: "y0",
      pixel: parseInt(crop.y * ratio),
    });
    detectionBoxList.push({
      key: "x1",
      pixel: parseInt((crop.width + crop.x) * ratio),
    });
    detectionBoxList.push({
      key: "y1",
      pixel: parseInt((crop.height + crop.y) * ratio),
    });

    let fileFolder = "";
    if (process.env.REACT_APP_NODE_ENV === "development") {
      fileFolder += ".";
    }
    fileFolder += `./catchup-storage/brand-files/${userProfileBrand.id}/pdfs/${pureFileName}`;

    setSelectedCroppedActivity({
      detectionBoxList,
      fileFolder,
      pageNumber: pageNumber,
      inputWidth: image.naturalWidth,
      inputHeight: image.naturalHeight,
    });

    handleOnParsedActivityOnClick(
      {},
      image,
      canvasLeft,
      canvasTop,
      canvasWidth,
      canvasHeight
    );
  };

  const handleDeleteParsedActivityOnClick = async (event) => {
    const parsedActivityId = parseInt(
      event.target.id.replace("parsed_activity_canvas_cross_", "")
    );
    const { data, err } = await deleteParsedActivityById(parsedActivityId);
    if (err) {
      console.log(err);
      return;
    }
    const foundIndex = parsedActivityList.findIndex(
      (parsedActivity) => parsedActivity.id === parsedActivityId
    );
    if (foundIndex !== -1) {
      const canvasId = `parsed_activity_canvas_${parsedActivityId}`;
      document.getElementById(canvasId)?.remove();
      const pId = `parsed_activity_canvas_text_${parsedActivityId}`;
      document.getElementById(pId)?.remove();
      const crossId = `parsed_activity_canvas_cross_${parsedActivityId}`;
      document.getElementById(crossId)?.remove();

      parsedActivityList.splice(foundIndex, 1);
      if (parsedActivityList.length === 0) {
        setCanProcessImage(true);
      }
      setParsedActivityList([...parsedActivityList]);
    }
  };

  const handleManualProcessImageOnClick = async () => {
    if (selectedCoterieType === "DEFAULT_OPTION") {
      NotificationManager.error(i18n.t("coterie_type_must_be_selected"));
      return;
    }
    if (selectedLevel === "DEFAULT_OPTION") {
      NotificationManager.error(i18n.t("level_must_be_selected"));
      return;
    }
    if (selectedVersionCode === "DEFAULT_OPTION") {
      NotificationManager.error(i18n.t("version_code_must_be_selected"));
      return;
    }
    NotificationManager.info(i18n.t("page_is_being_processed_please_wait"));
    await retrieveAndProcessActivityParserFromPath();
    setCanProcessImage(false);
  };

  const handleAddPublishingHouseLabelOnClick = (publishingHouseLabelId) => {
    const foundUserPublishingHouseLabel = userPublishingHouseLabelSet.find(
      (userPublishingHouseLabel) =>
        userPublishingHouseLabel.id === publishingHouseLabelId
    );
    if (foundUserPublishingHouseLabel) {
      const foundIndex = includedPublishingHouseLabelIdList.findIndex(
        (includedPublishingHouseLabelId) =>
          includedPublishingHouseLabelId === publishingHouseLabelId
      );
      if (foundIndex === -1) {
        includedPublishingHouseLabelIdList.push(publishingHouseLabelId);
        setIncludedPublishingHouseLabelIdList(
          JSON.parse(JSON.stringify(includedPublishingHouseLabelIdList))
        );
      }
    }
  };

  const handleRemovePublishingHouseLabelOnClick = (publishingHouseLabelId) => {
    const foundIndex = includedPublishingHouseLabelIdList.findIndex(
      (includedPublishingHouseLabelId) =>
        includedPublishingHouseLabelId === publishingHouseLabelId
    );
    if (foundIndex !== -1) {
      includedPublishingHouseLabelIdList.splice(foundIndex, 1);
      setIncludedPublishingHouseLabelIdList(
        JSON.parse(JSON.stringify(includedPublishingHouseLabelIdList))
      );
    }
  };

  const RenderShowUploadStatusModal = () => {
    if (fileParseStatus === "PARSING") {
      return (
        <BaseModal
          isOpen={true}
          onAfterOpen={() => {}}
          onRequestClose={() => {}}
        >
          <div className="flex-1 flex flex-col">
            <FullCard>
              <p className="text-xl  text-center">
                {i18n.t("upload_status_file_parsing")}
              </p>
            </FullCard>
          </div>
        </BaseModal>
      );
    } else if (fileParseStatus === "PARSED") {
      return (
        <BaseModal
          isOpen={true}
          onAfterOpen={() => {}}
          onRequestClose={() => {}}
        >
          <div className="flex-1 flex flex-col">
            <FullCard>
              <p className="text-xl  text-center">
                {i18n.t("upload_status_file_parsed")}
              </p>
            </FullCard>
          </div>
        </BaseModal>
      );
    }
  };

  const RenderPublishingHouseLabelListContent = () => {
    return (
      <div className="flex flex-row flex-wrap items-center gap-x-2">
        {includedPublishingHouseLabelIdList.map(
          (includedPublishingHouseLabelId, index) => {
            const foundLabel = userPublishingHouseLabelSet.find(
              (userPublishingHouseLabel) =>
                userPublishingHouseLabel.id === includedPublishingHouseLabelId
            );
            if (!foundLabel) return;
            return (
              <div
                key={index}
                className={`px-3 py-1 gap-x-3 border border-catchup-red rounded-md my-2 cursor-pointer`}
                onClick={() =>
                  handleRemovePublishingHouseLabelOnClick(foundLabel.id)
                }
              >
                <p className="text-sm">{foundLabel.name}</p>
              </div>
            );
          }
        )}
      </div>
    );
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        {pageState === 0 ? (
          <FullCard>
            <div className="flex-1 flex flex-row flex-wrap justify-between items-center">
              <div className="w-full lg:flex-1">
                <div className="flex flex-row items-center gap-x-2">
                  <div className="cursor-pointer">
                    <BaseImage
                      size="medium"
                      src="/icons/long-arrow-left.png"
                      alt="long-arrow-left"
                      onClick={() => {
                        handleReturnFromActivityOnClick();
                      }}
                    />
                  </div>
                  <BaseTitle title={i18n.t("create_activity_from_pdf")} />
                </div>
              </div>
              <PageTravelGroup
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                imageReady={imageReady}
                setImageReady={setImageReady}
                isImageProcessing={isImageProcessing}
                initialTotalPageNumber={initialTotalPageNumber}
              />
              <div className="flex-1 flex justify-end">
                {isCropModeActive ? (
                  <PrimaryButton
                    title={i18n.t("auto_mode")}
                    size="unlimited"
                    onClick={() => {
                      if (isImageProcessing) {
                        NotificationManager.info(
                          i18n.t("page_is_being_processed_please_wait")
                        );
                      } else {
                        setIsCropModeActive(false);
                        setCrop(null);
                      }
                    }}
                  />
                ) : (
                  <PrimaryButton
                    title={i18n.t("crop_mode")}
                    size="unlimited"
                    onClick={() => {
                      if (isImageProcessing) {
                        NotificationManager.info(
                          i18n.t("page_is_being_processed_please_wait")
                        );
                      } else {
                        setIsCropModeActive(true);
                      }
                    }}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <div className="w-1/2 lg:flex-1">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("coterie_type")}
                    placeholder={null}
                    value={selectedCoterieType}
                    optionList={filterCoterieTypeOptionList(
                      userInformation,
                      userProfile,
                      userProfileInstitution
                    )}
                    onChange={(event) => {
                      handleOnChange("coterieType", event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="w-1/2 lg:flex-1">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("level")}
                    placeholder={null}
                    value={selectedLevel}
                    optionList={filterGradeLevelOptionList(
                      userProfileInstitution,
                      userProfileGrade
                    )}
                    onChange={(event) => {
                      handleOnChange("level", event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="w-1/2 lg:flex-1">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("category_code")}
                    placeholder={null}
                    value={selectedVersionCode}
                    optionList={filteredCategoryVersionCodeOptionList}
                    onChange={(event) => {
                      handleOnChange("versionCode", event.target.value);
                    }}
                  />
                </div>
              </div>

              {!isCropModeActive && canProcessImage ? (
                <div
                  className="relative mt-auto py-2 ml-2"
                  ref={continueButtonRef}
                >
                  <SecondaryButton
                    title={i18n.t("process")}
                    size="small"
                    onClick={handleManualProcessImageOnClick}
                  />
                </div>
              ) : null}
              {crop ? (
                <div
                  className="relative mt-auto py-2 ml-2"
                  ref={continueButtonRef}
                >
                  <SecondaryButton
                    title={i18n.t("continue")}
                    size="small"
                    onClick={handleCropModeSelectedOnClick}
                  />
                </div>
              ) : null}
            </div>

            {userInformation.accountType === "GENIXO" ? (
              <>
                <DividerLine />
                <div className="flex flex-row flex-wrap items-center">
                  <div className="w-1/2 lg:w-1/4">
                    <div className="mx-2">
                      <InputGroup
                        type="select"
                        title={i18n.t("label")}
                        placeholder={null}
                        value={null}
                        optionList={filterPublishingHouseLabelOptionList()}
                        onChange={(event) => {
                          handleAddPublishingHouseLabelOnClick(
                            parseFloat(event.target.value)
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex-1">
                    {RenderPublishingHouseLabelListContent()}
                  </div>
                </div>
              </>
            ) : null}

            {fileOriginalName &&
            pureFileName &&
            convertedFileList.length > 0 ? (
              <div className="my-4">
                <div
                  className={`w-full ${
                    isCropModeActive
                      ? "border rounded-catchup-xlarge border-catchup-red cursor-crosshair"
                      : ""
                  }`}
                >
                  {isCropModeActive ? (
                    <>
                      <ReactCrop
                        crop={crop}
                        onChange={(c) => {
                          setCrop(c);
                        }}
                      >
                        <div
                          ref={imageContainerRef}
                          className={`w-full relative`}
                          key={pageNumber}
                        >
                          {showDocumentByPathAndFileName(
                            "FULL_WIDTH_IMAGE",
                            `/${userProfileBrand.id}/pdfs/${pureFileName}/${pageNumber}`,
                            retrieveFileNameFromPageNumber(),
                            handleOnImageLoad
                          )}
                        </div>
                      </ReactCrop>
                    </>
                  ) : (
                    <div
                      ref={imageContainerRef}
                      className={`w-full relative`}
                      key={pageNumber}
                    >
                      {showDocumentByPathAndFileName(
                        "FULL_WIDTH_IMAGE",
                        `/${userProfileBrand.id}/pdfs/${pureFileName}/${pageNumber}`,
                        retrieveFileNameFromPageNumber(),
                        handleOnImageLoad
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <BaseLoading
                size="large"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            )}
          </FullCard>
        ) : (
          <div className={`${pageState === 1 ? "block" : "hidden"}`}>
            <CreateActivity
              isShadowed={true}
              showHeader={true}
              lockedCoterieType={selectedCoterieType}
              lockedLevel={selectedLevel}
              lockedVersionCode={selectedVersionCode}
              existingActivity={existingActivity}
              parsedActivity={selectedParsedActivity}
              setUploadedFileName={setUploadedCroppedFileName}
              handleGoBackOnClick={handleToCreateFromPDFOnClick}
              handleAfterUpdateActivityAction={handleAfterUpdateActivityAction}
              handleAfterCreateActivityAction={handleAfterCreateActivityAction}
              handleSameActivityUpdatedBySomeoneAction={
                handleSameActivityUpdatedBySomeoneAction
              }
            />
          </div>
        )}
      </div>
    );
  };

  const categoryVersionCodeOptionList = retrieveCategoryVersionCodeOptionList();
  const filteredCategoryVersionCodeOptionList =
    filterCategoryVersionCodeOptionList(
      categoryVersionCodeOptionList,
      selectedCoterieType,
      selectedLevel
    );

  return (
    <div className="flex-1 flex flex-col">
      {RenderShowUploadStatusModal()}
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default CreateActivityFromPDFScreen;
