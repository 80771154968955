import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FullCard from "../cards/FullCard";
import BaseImage from "../images/BaseImage";
import i18n from "../../language/i18n";
import SecondaryButton from "../buttons/SecondaryButton";
import SingleInformationIconTextItem from "../infos/SingleInformationIconTextItem";
import DividerLine from "../divider/DividerLine";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import AdvancedTable from "../tables/AdvancedTable";
import BaseTitle from "../titles/BaseTitle";
import InputGroup from "../groups/InputGroup";
import {
  deleteExternalLearner,
  createExternalLearnerImportFromExcel,
  retrieveExternalRegistrationDTOById,
  searchExternalLearnerListWithStateByParams,
} from "../../requests/ManagementRequests";
import BaseLoading from "../loading/BaseLoading";
import CreateButton from "../buttons/CreateButton";

const ImportFromExcelCreateExternalLearner = (props) => {
  const navigate = useNavigate();
  const { externalRegistrationId } = props;
  const [loading, setLoading] = useState(false);
  const [externalRegistrationDTO, setExternalRegistrationDTO] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [excelStatus, setExcelStatus] = useState({ data: null, state: null });
  const [parameterStatus, setParameterStatus] = useState({
    data: null,
    state: null,
  });
  const [nullCheckSelectedItemCount, setNullCheckSelectedItemCount] =
    useState(25);
  const [nullCheckPageNumber, setNullCheckPageNumber] = useState(0);
  const [nullCheckSortKey, setNullCheckSortKey] = useState("row");
  const [nullCheckSortDirection, setNullCheckSortDirection] = useState("ASC");
  const [invalidCheckSelectedItemCount, setInvalidCheckSelectedItemCount] =
    useState(25);
  const [invalidCheckPageNumber, setInvalidCheckPageNumber] = useState(0);
  const [invalidCheckSortKey, setInvalidCheckSortKey] = useState("row");
  const [invalidCheckSortDirection, setInvalidCheckSortDirection] =
    useState("ASC");
  const [inExcelCheckSelectedItemCount, setInExcelCheckSelectedItemCount] =
    useState(25);
  const [inExcelCheckPageNumber, setInExcelCheckPageNumber] = useState(0);
  const [inExcelCheckSortKey, setInExcelCheckSortKey] = useState("row");
  const [inExcelCheckSortDirection, setInExcelCheckSortDirection] =
    useState("ASC");
  const [
    notAcceptingCheckSelectedItemCount,
    setNotAcceptingCheckSelectedItemCount,
  ] = useState(25);
  const [notAcceptingCheckPageNumber, setNotAcceptingCheckPageNumber] =
    useState(0);
  const [notAcceptingCheckSortKey, setNotAcceptingCheckSortKey] =
    useState("row");
  const [notAcceptingCheckSortDirection, setNotAcceptingCheckSortDirection] =
    useState("ASC");
  const [deletingCheckSelectedItemCount, setDeletingCheckSelectedItemCount] =
    useState(25);
  const [deletingCheckPageNumber, setDeletingCheckPageNumber] = useState(0);
  const [deletingCheckSortKey, setDeletingCheckSortKey] = useState("row");
  const [deletingCheckSortDirection, setDeletingCheckSortDirection] =
    useState("ASC");
  const [acceptingCheckSelectedItemCount, setAcceptingCheckSelectedItemCount] =
    useState(25);
  const [acceptingCheckPageNumber, setAcceptingCheckPageNumber] = useState(0);
  const [acceptingCheckSortKey, setAcceptingCheckSortKey] = useState("row");
  const [acceptingCheckSortDirection, setAcceptingCheckSortDirection] =
    useState("ASC");

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  useEffect(() => {
    const retrieveAndSetExternalRegistrationDTO = async () => {
      setLoading(true);
      const { data, err } = await retrieveExternalRegistrationDTOById(
        externalRegistrationId
      );
      if (err) {
        console.log(err);
        return;
      }
      setExternalRegistrationDTO(data);
      setLoading(false);
    };
    if (!externalRegistrationId) return;
    retrieveAndSetExternalRegistrationDTO();
  }, [externalRegistrationId]);

  useEffect(() => {
    const handleImportFromCSVOnChange = async () => {
      setExcelStatus({
        data: null,
        state: "WAITING",
      });
      setParameterStatus({
        data: null,
        state: null,
      });
      const data = await uploadFile.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets["data"];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
      });
      setExcelStatus({
        data: jsonData,
        state: "READY",
      });
    };
    if (!uploadFile) return;
    handleImportFromCSVOnChange();
  }, [uploadFile]);

  useEffect(() => {
    const retrieveAndCheckWithState = async (queryParams) => {
      const { data, err } = await searchExternalLearnerListWithStateByParams(
        externalRegistrationId,
        queryParams
      );
      if (err) {
        console.log(err);
      } else {
        setParameterStatus({
          state: "READY",
          data,
        });
      }
    };
    if (excelStatus.state === "READY") {
      const header = excelStatus.data[0];
      const dataArray = JSON.parse(JSON.stringify(excelStatus.data)).splice(1);
      if (
        dataArray.length > 0 &&
        convertDataArrayToInvalidDataArray(header, dataArray).length +
          convertDataArrayToNullDataArray(header, dataArray).length +
          convertDataArrayToInExcelCheckArray(header, dataArray).length ===
          0
      ) {
        setParameterStatus({ state: "WAITING", data: null });
        const checkParams = [];
        for (const data of dataArray) {
          const applicationNumberIndex = header.findIndex((h) =>
            h.startsWith(i18n.t("application_number"))
          );
          const firstNameIndex = header.findIndex((h) =>
            h.startsWith(i18n.t("first_name"))
          );
          const lastNameIndex = header.findIndex((h) =>
            h.startsWith(i18n.t("last_name"))
          );

          const identityNumberIndex = header.findIndex((h) =>
            h.startsWith(i18n.t("identity_number"))
          );
          const sessionIndex = header.findIndex((h) =>
            h.startsWith(i18n.t("session_number"))
          );
          checkParams.push({
            identityNumber: data[identityNumberIndex],
          });
        }
        retrieveAndCheckWithState(checkParams);
      }
    }
  }, [excelStatus]);

  const retrieveAcceptedFormats = () => {
    return ".xlsx,.numbers";
  };

  const retrieveCurrentNullCheckItemList = (nullDataArray) => {
    const operationDataArray = JSON.parse(JSON.stringify(nullDataArray));
    operationDataArray.sort(
      (a, b) => a[nullCheckSortKey] - b[nullCheckSortKey]
    );
    if (nullCheckSortDirection === "DESC") {
      operationDataArray.reverse();
    }
    return operationDataArray.splice(
      nullCheckPageNumber * nullCheckSelectedItemCount,
      (nullCheckPageNumber + 1) * nullCheckSelectedItemCount
    );
  };

  const retrieveCurrentInvalidCheckItemList = (invalidDataArray) => {
    const operationDataArray = JSON.parse(JSON.stringify(invalidDataArray));
    operationDataArray.sort(
      (a, b) => a[invalidCheckSortKey] - b[invalidCheckSortKey]
    );
    if (invalidCheckSortDirection === "DESC") {
      operationDataArray.reverse();
    }
    return operationDataArray.splice(
      invalidCheckPageNumber * invalidCheckSelectedItemCount,
      (invalidCheckPageNumber + 1) * invalidCheckSelectedItemCount
    );
  };

  const retrieveCurrentInExcelCheckItemList = (inExcelDataArray) => {
    const operationDataArray = JSON.parse(JSON.stringify(inExcelDataArray));
    operationDataArray.sort(
      (a, b) => a[inExcelCheckSortKey] - b[inExcelCheckSortKey]
    );
    if (inExcelCheckSortDirection === "DESC") {
      operationDataArray.reverse();
    }
    return operationDataArray.splice(
      inExcelCheckPageNumber * inExcelCheckSelectedItemCount,
      (inExcelCheckPageNumber + 1) * inExcelCheckSelectedItemCount
    );
  };

  const retrieveCurrentNotAcceptingCheckItemList = (notAcceptingCheckArray) => {
    const operationDataArray = JSON.parse(
      JSON.stringify(notAcceptingCheckArray)
    );
    operationDataArray.sort(
      (a, b) => a[notAcceptingCheckSortKey] - b[notAcceptingCheckSortKey]
    );
    if (notAcceptingCheckSortDirection === "DESC") {
      operationDataArray.reverse();
    }
    return operationDataArray.splice(
      notAcceptingCheckPageNumber * notAcceptingCheckSelectedItemCount,
      (notAcceptingCheckPageNumber + 1) * notAcceptingCheckSelectedItemCount
    );
  };

  const retrieveCurrentAcceptingCheckItemList = (acceptingCheckArray) => {
    const operationDataArray = JSON.parse(JSON.stringify(acceptingCheckArray));
    operationDataArray.sort(
      (a, b) => a[acceptingCheckSortKey] - b[acceptingCheckSortKey]
    );
    if (acceptingCheckSortDirection === "DESC") {
      operationDataArray.reverse();
    }
    return operationDataArray.splice(
      acceptingCheckPageNumber * acceptingCheckSelectedItemCount,
      (acceptingCheckPageNumber + 1) * acceptingCheckSelectedItemCount
    );
  };

  const convertDataArrayToNullDataArray = (header, dataArray) => {
    const nullDataMap = {};
    for (let i = 0; i < dataArray.length; i++) {
      const rowLine = dataArray[i];
      for (let j = 0; j < rowLine.length; j++) {
        if (j === 3 && rowLine[3]) continue;
        const colElement = rowLine[j];
        if (colElement === "") {
          let errorText;
          errorText = `${header[j].split(" - ")[0]} ${i18n.t(
            "cannot_be_null_text"
          )}`;

          if (nullDataMap[i]) {
            const error = nullDataMap[i].error;
            nullDataMap[i] = {
              ...nullDataMap[i],
              error: `${error} ${errorText}`,
            };
          } else {
            nullDataMap[i] = {
              id: i,
              [header[0].split(" - ")[0]]: rowLine[0],
              [header[1].split(" - ")[0]]: rowLine[1],
              [header[2].split(" - ")[0]]: rowLine[2],
              [header[3].split(" - ")[0]]: rowLine[3],
              [header[4].split(" - ")[0]]: rowLine[4],
              row: i + 2,
              error: errorText,
            };
          }
        }
      }
    }
    const nullDataArray = [];
    Object.keys(nullDataMap).forEach((key) => {
      nullDataArray.push(nullDataMap[key]);
    });
    return nullDataArray;
  };

  const convertDataArrayToInvalidDataArray = (header, dataArray) => {
    const invalidDataMap = {};
    for (let i = 0; i < dataArray.length; i++) {
      const rowLine = dataArray[i];
      for (let j = 0; j < rowLine.length; j++) {
        const colElement = rowLine[j];
        if (colElement !== "") {
          let error = null;
          if (header[j].startsWith(i18n.t("application_number"))) {
          } else if (header[j].startsWith(i18n.t("first_name"))) {
          } else if (header[j].startsWith(i18n.t("last_name"))) {
          } else if (header[j].startsWith(i18n.t("identity_number"))) {
            if ((colElement + "").length !== 11) {
              error = `${i18n.t("identity_number")} ${i18n.t("must_be_valid")}`;
            }
          } else if (header[j].startsWith(i18n.t("session"))) {
          }
          if (error) {
            if (invalidDataMap[i]) {
              const prevError = invalidDataMap[i].error;
              invalidDataMap[i] = {
                ...invalidDataMap[i],
                error: `${prevError} ${error}`,
              };
            } else {
              invalidDataMap[i] = {
                id: i,
                [header[0].split(" - ")[0]]: rowLine[0],
                [header[1].split(" - ")[0]]: rowLine[1],
                [header[2].split(" - ")[0]]: rowLine[2],
                [header[3].split(" - ")[0]]: rowLine[3],
                [header[4].split(" - ")[0]]: rowLine[4],
                row: i + 2,
                error,
              };
            }
          }
        }
      }
    }
    const invalidDataArray = [];
    Object.keys(invalidDataMap).forEach((key) => {
      invalidDataArray.push(invalidDataMap[key]);
    });
    return invalidDataArray;
  };

  const convertDataArrayToInExcelCheckArray = (header, dataArray) => {
    const inExcelDataMap = {};
    for (let i = 0; i < dataArray.length; i++) {
      const rowLine = dataArray[i];
      for (let j = 0; j < rowLine.length; j++) {
        const colElement = rowLine[j];
        if (colElement !== "") {
          let error = null;
          if (header[j].startsWith(i18n.t("application_number"))) {
            const filteredDataArray = dataArray.filter(
              (data) => data[j] === colElement
            );
            if (filteredDataArray.length > 1) {
              error = `${i18n.t("application_number")} ${i18n.t(
                "must_be_unique"
              )}`;
            }
          } else if (header[j].startsWith(i18n.t("first_name"))) {
          } else if (header[j].startsWith(i18n.t("last_name"))) {
          } else if (header[j].startsWith(i18n.t("identity_number"))) {
            const filteredDataArray = dataArray.filter(
              (data) => data[j] === colElement
            );
            if (filteredDataArray.length > 1) {
              error = `${i18n.t("identity_number")} ${i18n.t(
                "must_be_unique"
              )}`;
            }
          } else if (header[j].startsWith(i18n.t("session"))) {
          }
          if (error) {
            if (inExcelDataMap[i]) {
              const prevError = inExcelDataMap[i].error;
              inExcelDataMap[i] = {
                ...inExcelDataMap[i],
                error: `${prevError} ${error}`,
              };
            } else {
              inExcelDataMap[i] = {
                id: i,
                [header[0].split(" - ")[0]]: rowLine[0],
                [header[1].split(" - ")[0]]: rowLine[1],
                [header[2].split(" - ")[0]]: rowLine[2],
                [header[3].split(" - ")[0]]: rowLine[3],
                [header[4].split(" - ")[0]]: rowLine[4],
                row: i + 2,
                error,
              };
            }
          }
        }
      }
    }
    const inExcelDataArray = [];
    Object.keys(inExcelDataMap).forEach((key) => {
      inExcelDataArray.push(inExcelDataMap[key]);
    });
    return inExcelDataArray;
  };

  const convertDataArrayToNotAcceptingCheckArray = (
    header,
    dataArray,
    filteredDataArray
  ) => {
    const notAcceptingCheckMap = {};
    let count = 0;
    for (let i = 0; i < dataArray.length; i++) {
      const rowLine = dataArray[i];
      if (rowLine) {
        const usernameIndex = header.findIndex((h) =>
          h.startsWith(i18n.t("username"))
        );
        const foundFilteredData = filteredDataArray.find(
          (filteredData) =>
            filteredData.providedUsername === rowLine[usernameIndex]
        );
        if (foundFilteredData) {
          let error;
          if (
            foundFilteredData.searchExternalLearnerState === "ALREADY_EXISTS"
          ) {
            error = i18n.t("ALREADY_EXISTS");
          }
          notAcceptingCheckMap[count] = {
            id: count,
            [header[0].split(" - ")[0]]: rowLine[0],
            [header[1].split(" - ")[0]]: rowLine[1],
            [header[2].split(" - ")[0]]: rowLine[2],
            [header[3].split(" - ")[0]]: rowLine[3],
            [header[4].split(" - ")[0]]: rowLine[4],
            row: i + 2,
            error,
          };
          count++;
        }
      }
    }
    const notAcceptingCheckArray = [];
    Object.keys(notAcceptingCheckMap).forEach((key) => {
      notAcceptingCheckArray.push(notAcceptingCheckMap[key]);
    });
    return notAcceptingCheckArray;
  };

  const convertDataArrayToAcceptingCheckArray = (
    header,
    dataArray,
    filteredDataArray
  ) => {
    const acceptingCheckMap = {};
    let count = 0;
    for (let i = 0; i < dataArray.length; i++) {
      const rowLine = dataArray[i];
      if (rowLine) {
        const identityNumberIndex = header.findIndex((h) =>
          h.startsWith(i18n.t("identity_number"))
        );
        const foundFilteredData = filteredDataArray.find(
          (filteredData) =>
            filteredData.identityNumber == rowLine[identityNumberIndex]
        );
        if (foundFilteredData) {
          let status;
          if (
            foundFilteredData.searchExternalLearnerState ===
            "NEW_EXTERNAL_LEARNER"
          ) {
            status = i18n.t("NEW_EXTERNAL_LEARNER");
          }
          acceptingCheckMap[count] = {
            id: count,
            [header[0].split(" - ")[0]]: rowLine[0],
            [header[1].split(" - ")[0]]: rowLine[1],
            [header[2].split(" - ")[0]]: rowLine[2],
            [header[3].split(" - ")[0]]: rowLine[3],
            [header[4].split(" - ")[0]]: rowLine[4],
            row: i + 2,
            status,
          };
          count++;
        }
      }
    }
    const acceptingCheckArray = [];
    Object.keys(acceptingCheckMap).forEach((key) => {
      acceptingCheckArray.push(acceptingCheckMap[key]);
    });
    return acceptingCheckArray;
  };

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleOnChange = (type, value) => {
    if (type === "uploadFile") {
      setUploadFile(value[0]);
    }
  };

  const handleCreateExcelFormOnClick = () => {
    exportToCSV(
      [
        {
          [i18n.t("application_number")]: null,
          [i18n.t("first_name")]: null,
          [i18n.t("last_name")]: null,
          [i18n.t("identity_number")]: null,
          [i18n.t("session")]: null,
        },
      ],
      i18n.t("import_external_learners")
    );
  };

  const handleOnNullCheckTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setNullCheckSelectedItemCount(value);
    } else if (type === "pageNumber") {
      setNullCheckPageNumber(value);
    } else if (type === "sortKey") {
      setNullCheckSortKey(value);
    } else if (type === "sortDirection") {
      setNullCheckSortDirection(value);
    }
  };

  const handleOnInvalidCheckTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setInvalidCheckSelectedItemCount(value);
    } else if (type === "pageNumber") {
      setInvalidCheckPageNumber(value);
    } else if (type === "sortKey") {
      setInvalidCheckSortKey(value);
    } else if (type === "sortDirection") {
      setInvalidCheckSortDirection(value);
    }
  };

  const handleOnInExcelCheckTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setInExcelCheckSelectedItemCount(value);
    } else if (type === "pageNumber") {
      setInExcelCheckPageNumber(value);
    } else if (type === "sortKey") {
      setInExcelCheckSortKey(value);
    } else if (type === "sortDirection") {
      setInExcelCheckSortDirection(value);
    }
  };

  const handleOnNotAcceptingCheckTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setNotAcceptingCheckSelectedItemCount(value);
    } else if (type === "pageNumber") {
      setNotAcceptingCheckPageNumber(value);
    } else if (type === "sortKey") {
      setNotAcceptingCheckSortKey(value);
    } else if (type === "sortDirection") {
      setNotAcceptingCheckSortDirection(value);
    }
  };

  const handleOnDeletingCheckTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setDeletingCheckSelectedItemCount(value);
    } else if (type === "pageNumber") {
      setDeletingCheckPageNumber(value);
    } else if (type === "sortKey") {
      setDeletingCheckSortKey(value);
    } else if (type === "sortDirection") {
      setDeletingCheckSortDirection(value);
    }
  };

  const handleOnAcceptingCheckTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setAcceptingCheckSelectedItemCount(value);
    } else if (type === "pageNumber") {
      setAcceptingCheckPageNumber(value);
    } else if (type === "sortKey") {
      setAcceptingCheckSortKey(value);
    } else if (type === "sortDirection") {
      setAcceptingCheckSortDirection(value);
    }
  };

  const handleCreateImportFromExcelOnClick = async () => {
    const { externalLearnerDTOList } = externalRegistrationDTO;
    const filteredExternalLearnerDTOList = externalLearnerDTOList.filter(
      (externalLearnerDTO) =>
        parameterStatus.data.findIndex(
          (item) => item.identityNumber == externalLearnerDTO.identityNumber
        ) === -1
    );
    for (const externalLearnerDTO of filteredExternalLearnerDTOList) {
      const { data, err } = await deleteExternalLearner(externalLearnerDTO.id);
      if (err) {
        console.log(err);
        return;
      }
    }
    const header = excelStatus.data[0];
    const dataArray = JSON.parse(JSON.stringify(excelStatus.data)).splice(1);
    const filteredDataArray = parameterStatus.data.filter(
      (data) => data.searchExternalLearnerState === "NEW_EXTERNAL_LEARNER"
    );
    const applicationNumberIndex = header.findIndex((h) =>
      h.startsWith(i18n.t("application_number"))
    );
    const firstNameIndex = header.findIndex((h) =>
      h.startsWith(i18n.t("first_name"))
    );
    const lastNameIndex = header.findIndex((h) =>
      h.startsWith(i18n.t("last_name"))
    );
    const identityNumberIndex = header.findIndex((h) =>
      h.startsWith(i18n.t("identity_number"))
    );
    const sessionIndex = header.findIndex((h) =>
      h.startsWith(i18n.t("session"))
    );

    const constructedData = [];
    for (const data of dataArray) {
      const foundFilteredData = filteredDataArray.find(
        (filteredData) =>
          filteredData.identityNumber == data[identityNumberIndex]
      );
      if (!foundFilteredData) continue;

      constructedData.push({
        applicationNumber: data[applicationNumberIndex],
        firstName: data[firstNameIndex],
        lastName: data[lastNameIndex],
        identityNumber: data[identityNumberIndex],
        session: data[sessionIndex],
        searchExternalLearnerState:
          foundFilteredData.searchExternalLearnerState,
      });
    }
    const { data, err } = await createExternalLearnerImportFromExcel(
      externalRegistrationId,
      constructedData
    );
    if (err) {
      console.log(err);
      return;
    }
    navigate(-1);
  };

  const RenderNullRows = (header, dataArray) => {
    const nullDataArray = convertDataArrayToNullDataArray(header, dataArray);
    if (nullDataArray.length === 0) {
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/checkbox.png"
                alt="checkbox"
              />
            </div>
            <p className="text-xl">
              {i18n.t("import_from_excel_items_not_null_text")}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/exclamation-red.png"
                alt="exclamation-red"
              />
            </div>
            <p className="text-xl font-semibold">
              {i18n.t("import_from_excel_items_cannot_be_null_or_empty")}
            </p>
          </div>
          <AdvancedTable
            title={null}
            description={null}
            itemName={i18n.t("learner")}
            headerList={[
              { name: i18n.t("error"), key: "error" },
              { name: i18n.t("row"), key: "row" },
              {
                name: i18n.t("application_number"),
                key: header[0].split(" - ")[0],
              },
              { name: i18n.t("first_name"), key: header[1].split(" - ")[0] },
              { name: i18n.t("last_name"), key: header[2].split(" - ")[0] },

              {
                name: i18n.t("identity_number"),
                key: header[3].split(" - ")[0],
              },
              { name: i18n.t("session"), key: header[4].split(" - ")[0] },
            ]}
            selectable={false}
            showFilter={false}
            showItemCount={nullCheckSelectedItemCount}
            totalItemCount={nullDataArray.length}
            pageNumber={nullCheckPageNumber}
            loading={false}
            sortKey={nullCheckSortKey}
            sortDirection={nullCheckSortDirection}
            handleOnTableParamsChange={handleOnNullCheckTableParamsChange}
            itemList={retrieveCurrentNullCheckItemList(nullDataArray)}
            insertable={false}
            updatable={false}
            deletable={false}
            navigatable={false}
          />
        </div>
      );
    }
  };

  const RenderInvalidRows = (header, dataArray) => {
    const invalidDataArray = convertDataArrayToInvalidDataArray(
      header,
      dataArray
    );
    if (invalidDataArray.length === 0) {
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/checkbox.png"
                alt="checkbox"
              />
            </div>
            <p className="text-xl">
              {i18n.t("import_from_excel_items_valid_text")}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/exclamation-red.png"
                alt="exclamation-red"
              />
            </div>
            <p className="text-xl font-semibold">
              {i18n.t("import_from_excel_items_validation_exception_text")}
            </p>
          </div>
          <AdvancedTable
            title={null}
            description={null}
            itemName={i18n.t("learner")}
            headerList={[
              { name: i18n.t("error"), key: "error" },
              { name: i18n.t("row"), key: "row" },
              {
                name: i18n.t("application_number"),
                key: header[0].split(" - ")[0],
              },
              { name: i18n.t("first_name"), key: header[1].split(" - ")[0] },
              { name: i18n.t("last_name"), key: header[2].split(" - ")[0] },

              {
                name: i18n.t("identity_number"),
                key: header[3].split(" - ")[0],
              },
              { name: i18n.t("session"), key: header[4].split(" - ")[0] },
            ]}
            selectable={false}
            showFilter={false}
            showItemCount={invalidCheckSelectedItemCount}
            totalItemCount={invalidDataArray.length}
            pageNumber={invalidCheckPageNumber}
            loading={false}
            sortKey={invalidCheckSortKey}
            sortDirection={invalidCheckSortDirection}
            handleOnTableParamsChange={handleOnInvalidCheckTableParamsChange}
            itemList={retrieveCurrentInvalidCheckItemList(invalidDataArray)}
            insertable={false}
            updatable={false}
            deletable={false}
            navigatable={false}
          />
        </div>
      );
    }
  };

  const RenderInExcelRows = (header, dataArray) => {
    const inExcelDataArray = convertDataArrayToInExcelCheckArray(
      header,
      dataArray
    );
    if (inExcelDataArray.length === 0) {
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/checkbox.png"
                alt="checkbox"
              />
            </div>
            <p className="text-xl">
              {i18n.t("import_from_excel_items_in_excel_valid_text")}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/exclamation-red.png"
                alt="exclamation-red"
              />
            </div>
            <p className="text-xl font-semibold">
              {i18n.t(
                "import_from_excel_items_in_excel_validation_exception_text"
              )}
            </p>
          </div>
          <AdvancedTable
            title={null}
            description={null}
            itemName={i18n.t("learner")}
            headerList={[
              { name: i18n.t("error"), key: "error" },
              { name: i18n.t("row"), key: "row" },
              {
                name: i18n.t("application_number"),
                key: header[0].split(" - ")[0],
              },
              { name: i18n.t("first_name"), key: header[1].split(" - ")[0] },
              { name: i18n.t("last_name"), key: header[2].split(" - ")[0] },

              {
                name: i18n.t("identity_number"),
                key: header[3].split(" - ")[0],
              },
              { name: i18n.t("session"), key: header[4].split(" - ")[0] },
            ]}
            selectable={false}
            showFilter={false}
            showItemCount={inExcelCheckSelectedItemCount}
            totalItemCount={inExcelDataArray.length}
            pageNumber={inExcelCheckPageNumber}
            loading={false}
            sortKey={inExcelCheckSortKey}
            sortDirection={inExcelCheckSortDirection}
            handleOnTableParamsChange={handleOnInExcelCheckTableParamsChange}
            itemList={retrieveCurrentInExcelCheckItemList(inExcelDataArray)}
            insertable={false}
            updatable={false}
            deletable={false}
            navigatable={false}
          />
        </div>
      );
    }
  };

  const RenderNotAcceptingRows = (header, dataArray) => {
    if (parameterStatus.state === "READY") {
      const filteredDataArray = parameterStatus.data.filter(
        (data) => data.searchExternalLearnerState === "ALREADY_EXISTS"
      );

      if (filteredDataArray.length === 0) {
        return (
          <div className="flex flex-col mt-5 pl-7">
            <div className="flex flex-row items-center">
              <div className="mr-3">
                <BaseImage
                  size="medium"
                  src="/icons/checkbox.png"
                  alt="checkbox"
                />
              </div>
              <p className="text-xl">
                {i18n.t("import_from_excel_items_not_accepted_valid_text")}
              </p>
            </div>
          </div>
        );
      } else {
        const notAcceptingCheckArray = convertDataArrayToNotAcceptingCheckArray(
          header,
          dataArray,
          filteredDataArray
        );
        return (
          <div className="flex flex-col mt-5 pl-7">
            <div className="flex flex-row items-center">
              <div className="mr-3">
                <BaseImage
                  size="medium"
                  src="/icons/exclamation-red.png"
                  alt="exclamation-red"
                />
              </div>
              <p className="text-xl font-semibold">
                {i18n.t("import_from_excel_items_not_accepted_exception_text")}
              </p>
            </div>
            <AdvancedTable
              title={null}
              description={null}
              itemName={i18n.t("learner")}
              headerList={[
                { name: i18n.t("error"), key: "error" },
                { name: i18n.t("row"), key: "row" },
                {
                  name: i18n.t("application_number"),
                  key: header[0].split(" - ")[0],
                },
                { name: i18n.t("first_name"), key: header[1].split(" - ")[0] },
                { name: i18n.t("last_name"), key: header[2].split(" - ")[0] },

                {
                  name: i18n.t("identity_number"),
                  key: header[3].split(" - ")[0],
                },
                { name: i18n.t("session"), key: header[4].split(" - ")[0] },
              ]}
              selectable={false}
              showFilter={false}
              showItemCount={notAcceptingCheckSelectedItemCount}
              totalItemCount={notAcceptingCheckArray.length}
              pageNumber={notAcceptingCheckPageNumber}
              loading={false}
              sortKey={notAcceptingCheckSortKey}
              sortDirection={notAcceptingCheckSortDirection}
              handleOnTableParamsChange={
                handleOnNotAcceptingCheckTableParamsChange
              }
              itemList={retrieveCurrentNotAcceptingCheckItemList(
                notAcceptingCheckArray
              )}
              insertable={false}
              updatable={false}
              deletable={false}
              navigatable={false}
            />
          </div>
        );
      }
    }
    return null;
  };

  const RenderDeletingRows = () => {
    if (parameterStatus.state === "READY") {
      const { externalLearnerDTOList } = externalRegistrationDTO;
      const filteredExternalLearnerDTOList = externalLearnerDTOList.filter(
        (externalLearnerDTO) =>
          parameterStatus.data.findIndex(
            (item) => item.identityNumber == externalLearnerDTO.identityNumber
          ) === -1
      );
      if (filteredExternalLearnerDTOList.length === 0) {
        return (
          <div className="flex flex-col mt-5 pl-7">
            <div className="flex flex-row items-center">
              <div className="mr-3">
                <BaseImage
                  size="medium"
                  src="/icons/checkbox.png"
                  alt="checkbox"
                />
              </div>
              <p className="text-xl font-semibold">
                {i18n.t("import_from_excel_items_deleting_exception_text")}
              </p>
            </div>
          </div>
        );
      } else {
        return (
          <div className="flex flex-col mt-5 pl-7">
            <div className="flex flex-row items-center">
              <div className="mr-3">
                <BaseImage
                  size="medium"
                  src="/icons/exclamation-red.png"
                  alt="exclamation-red"
                />
              </div>
              <p className="text-xl">
                {i18n.t("import_from_excel_items_deleting_valid_text")}
              </p>
            </div>
            <AdvancedTable
              title={null}
              description={null}
              itemName={i18n.t("learner")}
              headerList={[
                {
                  name: i18n.t("application_number"),
                  key: "applicationNumber",
                },
                { name: i18n.t("first_name"), key: "firstName" },
                { name: i18n.t("last_name"), key: "lastName" },

                {
                  name: i18n.t("identity_number"),
                  key: "identityNumber",
                },
                { name: i18n.t("session"), key: "session" },
              ]}
              selectable={false}
              showFilter={false}
              showItemCount={deletingCheckSelectedItemCount}
              totalItemCount={filteredExternalLearnerDTOList.length}
              pageNumber={deletingCheckPageNumber}
              loading={false}
              sortKey={deletingCheckSortKey}
              sortDirection={deletingCheckSortDirection}
              handleOnTableParamsChange={handleOnDeletingCheckTableParamsChange}
              itemList={retrieveCurrentAcceptingCheckItemList(
                filteredExternalLearnerDTOList
              )}
              insertable={false}
              updatable={false}
              deletable={false}
              navigatable={false}
            />
          </div>
        );
      }
    }
  };

  const RenderAcceptingRows = (header, dataArray) => {
    if (parameterStatus.state === "READY") {
      const filteredDataArray = parameterStatus.data.filter(
        (data) => data.searchExternalLearnerState === "NEW_EXTERNAL_LEARNER"
      );

      if (filteredDataArray.length === 0) {
        return (
          <div className="flex flex-col mt-5 pl-7">
            <div className="flex flex-row items-center">
              <div className="mr-3">
                <BaseImage
                  size="medium"
                  src="/icons/exclamation-red.png"
                  alt="exclamation-red"
                />
              </div>
              <p className="text-xl font-semibold">
                {i18n.t("import_from_excel_items_accepted_exception_text")}
              </p>
            </div>
          </div>
        );
      } else {
        const acceptingCheckArray = convertDataArrayToAcceptingCheckArray(
          header,
          dataArray,
          filteredDataArray
        );
        return (
          <div className="flex flex-col mt-5 pl-7">
            <div className="flex flex-row items-center">
              <div className="mr-3">
                <BaseImage
                  size="medium"
                  src="/icons/checkbox.png"
                  alt="checkbox"
                />
              </div>
              <p className="text-xl">
                {i18n.t("import_from_excel_items_accepted_valid_text")}
              </p>
            </div>
            <AdvancedTable
              title={null}
              description={null}
              itemName={i18n.t("learner")}
              headerList={[
                { name: i18n.t("status"), key: "status" },
                { name: i18n.t("row"), key: "row" },
                {
                  name: i18n.t("application_number"),
                  key: header[0].split(" - ")[0],
                },
                { name: i18n.t("first_name"), key: header[1].split(" - ")[0] },
                { name: i18n.t("last_name"), key: header[2].split(" - ")[0] },

                {
                  name: i18n.t("identity_number"),
                  key: header[3].split(" - ")[0],
                },
                { name: i18n.t("session"), key: header[4].split(" - ")[0] },
              ]}
              selectable={false}
              showFilter={false}
              showItemCount={acceptingCheckSelectedItemCount}
              totalItemCount={acceptingCheckArray.length}
              pageNumber={acceptingCheckPageNumber}
              loading={false}
              sortKey={acceptingCheckSortKey}
              sortDirection={acceptingCheckSortDirection}
              handleOnTableParamsChange={
                handleOnAcceptingCheckTableParamsChange
              }
              itemList={retrieveCurrentAcceptingCheckItemList(
                acceptingCheckArray
              )}
              insertable={false}
              updatable={false}
              deletable={false}
              navigatable={false}
            />
            <div className="mt-4 ml-auto">
              <CreateButton
                title={i18n.t("create")}
                size="medium"
                onClick={handleCreateImportFromExcelOnClick}
              />
            </div>
          </div>
        );
      }
    }
    return null;
  };

  const RenderExcelData = () => {
    if (excelStatus.state) {
      if (excelStatus.state === "READY") {
        const header = excelStatus.data[0];
        const dataArray = JSON.parse(JSON.stringify(excelStatus.data)).splice(
          1
        );
        return (
          <>
            <DividerLine />
            <div>
              <p>
                {i18n.t("found_entry_count")}: {dataArray.length}
              </p>
            </div>
            {dataArray.length === 0 ? (
              <div className="my-2 p-3 border rounded-catchup-xlarge bg-catchup-red text-catchup-white">
                <p>
                  {i18n.t(
                    "import_excel_data_array_empty_length_check_sheet_name_text"
                  )}
                </p>
              </div>
            ) : (
              <>
                {RenderNullRows(header, dataArray)}
                {RenderInvalidRows(header, dataArray)}
                {RenderInExcelRows(header, dataArray)}
                {parameterStatus.state === "WAITING" ? (
                  <BaseLoading
                    size="large"
                    color="#57C2D3"
                    secondaryColor="#57C2D3"
                  />
                ) : parameterStatus.state === "READY" ? (
                  <>
                    <DividerLine />
                    {RenderNotAcceptingRows(header, dataArray)}
                    {RenderDeletingRows()}
                    {RenderAcceptingRows(header, dataArray)}
                  </>
                ) : null}
              </>
            )}
          </>
        );
      } else {
        return (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        );
      }
    } else {
      return null;
    }
  };

  return (
    <FullCard isShadowed={true}>
      <div className="">
        <div className="flex flex-row items-center gap-x-2">
          <div className="cursor-pointer">
            <BaseImage
              size="medium"
              src="/icons/long-arrow-left.png"
              alt="long-arrow-left"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <BaseTitle title={i18n.t("create_external_learner_from_excel")} />
        </div>
      </div>
      <DividerLine />
      {loading ? (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      ) : (
        <div className="flex flex-col">
          <div className="ml-auto">
            <SecondaryButton
              title={i18n.t("download_excel")}
              size="unlimited"
              onClick={handleCreateExcelFormOnClick}
            />
          </div>
          <div className="my-2 p-3">
            {SingleInformationIconTextItem(
              i18n.t("import_excel_data_general_information_text_2")
            )}
            {SingleInformationIconTextItem(
              i18n.t("import_excel_data_general_information_text_3")
            )}
          </div>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("file_upload")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="file"
                multiple={false}
                accept={retrieveAcceptedFormats()}
                value={null}
                onChange={(event) => {
                  handleOnChange("uploadFile", {
                    ...event.target.files,
                  });
                }}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </div>
          </div>
          {RenderExcelData()}
        </div>
      )}
    </FullCard>
  );
};

export default ImportFromExcelCreateExternalLearner;
