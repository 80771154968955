import i18n from "../language/i18n";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import BaseImage from "../components/images/BaseImage";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

export const retrieveDocumentTypeOptionList = (optionFilter) => {
  if (optionFilter === "PDF_ONLY") {
    return [
      {
        text: i18n.t("PDF"),
        value: "PDF",
      },
    ];
  } else if (optionFilter === "ACTIVITY") {
    return [
      {
        text: i18n.t("VIDEO"),
        value: "VIDEO",
      },
      {
        text: i18n.t("AUDIO"),
        value: "AUDIO",
      },
      {
        text: i18n.t("IMAGE"),
        value: "IMAGE",
      },
    ];
  }
  return [
    {
      text: i18n.t("VIDEO"),
      value: "VIDEO",
    },
    {
      text: i18n.t("AUDIO"),
      value: "AUDIO",
    },
    {
      text: i18n.t("IMAGE"),
      value: "IMAGE",
    },
    {
      text: i18n.t("PDF"),
      value: "PDF",
    },
  ];
};

export const retrieveAcceptedFormats = (documentType) => {
  if (documentType === "IMAGE") {
    return "image/jpeg, image/png, image/jpg";
  } else if (documentType === "VIDEO") {
    return "video/mp4";
  } else if (documentType === "AUDIO") {
    return "audio/mp3";
  } else if (documentType === "PDF") {
    return "application/pdf";
  }
};

export const showDocumentByPathAndFileName = (
  documentType,
  path,
  fileName,
  onLoad
) => {
  if (documentType === "IMAGE") {
    return (
      <img
        className="w-catchup-table-header-item rounded-catchup-xlarge"
        src={`${connection.base.storage.url}/storage-files/preview/file${path}/${fileName}`}
        alt="document-file"
        onLoad={onLoad}
      />
    );
  } else if (documentType === "FULL_WIDTH_IMAGE") {
    return (
      <img
        className="w-full"
        src={`${connection.base.storage.url}/storage-files/preview/file${path}/${fileName}`}
        alt="document-file"
        onLoad={onLoad}
        crossOrigin="anonymous"
      />
    );
  } else if (documentType === "VIDEO") {
    return (
      <video
        className="w-catchup-table-header-item rounded-catchup-xlarge"
        src={`${connection.base.storage.url}/storage-files/preview/file${path}/${fileName}`}
        controls
        onLoad={onLoad}
      />
    );
  } else if (documentType === "AUDIO") {
    return (
      <audio
        className="w-catchup-table-header-item rounded-catchup-xlarge"
        src={`${connection.base.storage.url}/storage-files/preview/file${path}/${fileName}`}
        controls
        onLoad={onLoad}
      />
    );
  } else if (documentType === "PDF") {
    return (
      <img
        className="w-catchup-table-header-item rounded-catchup-xlarge"
        onLoad={onLoad}
        src="/images/pdf-image.png"
        alt="document-file"
        crossOrigin="anonymous"
      />
    );
  }
};

export const showDocumentByUrl = (documentType, url) => {
  if (documentType === "IMAGE") {
    return (
      <BaseImage
        size="custom"
        className="w-catchup-table-header-item rounded-catchup-xlarge"
        src={url}
        alt="document-file"
      />
    );
  } else if (documentType === "VIDEO") {
    return (
      <video
        className="w-catchup-table-header-item rounded-catchup-xlarge"
        src={url}
        controls
      />
    );
  } else if (documentType === "AUDIO") {
    return (
      <audio
        className="w-catchup-table-header-item rounded-catchup-xlarge"
        src={url}
        controls
      />
    );
  }
};

export const convertDataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const retrieveDocumentTypeFromAcceptedFormat = (format) => {
  if (format === "application/pdf") {
    return "PDF";
  } else if (
    format === "image/jpeg" ||
    format === "image/png" ||
    format === "image/jpg"
  ) {
    return "IMAGE";
  } else if (format === "audio/mp3") {
    return "AUDIO";
  }
};

export const retrieveDocumentTypeFromExtension = (format) => {
  if (format === "pdf") {
    return "PDF";
  } else if (format === "jpeg" || format === "png" || format === "jpg") {
    return "IMAGE";
  } else if (format === "mp3") {
    return "AUDIO";
  }
};
