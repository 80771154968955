import { useCallback, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useLocation, useNavigate } from "react-router-dom";
import InputGroup from "../../components/groups/InputGroup";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  createStandardExamApplicationByStandardExamId,
  deleteStandardExamApplication,
  patchStandardExamApplication,
  queryAllStandardExamApplicationDTOPageByFilter,
  queryStandardExamFromStandardExamAccessibilityByParams,
  queryAllStandardExamApplicationDTOListByFilterActive,
  evaluateStandardExamApplicationById,
  queryStandardExamDTOListByUserIdUserProfileId,
} from "../../requests/CatchtivityRequests";
import {
  queryGradeListBySeasonIdList,
  queryBranchListByGradeIdList,
  queryBranchListByParams,
  queryCampusListByBrandId,
  queryInstitutionListByCampusIdList,
  queryInstitutionListByCampusId,
  queryActiveSeasonListByInstitutionId,
  queryActiveSeasonListByCampusId,
  queryActiveSeasonListByBrandId,
} from "../../requests/ManagementRequests";
import {
  retrieveDurationInMinutesOptionList,
  retrieveDurationTypeOptionList,
  retrieveStandardExamTypeOptionList,
} from "../../utilization/CatchtivityUtilization";
import {
  checkDeletePermissionGranted,
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import AdvancedTable from "../../components/tables/AdvancedTable";
import FullCard from "../../components/cards/FullCard";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import { Tooltip } from "react-tooltip";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import debounce from "lodash.debounce";
import CreateButton from "../../components/buttons/CreateButton";
import SelectionTab from "../../components/tabs/SelectionTab";
import StandardExamApplicationCard from "../../components/cards/StandardExamApplicationCard";
import {
  retrieveBranchDTOOptionList,
  retrieveGradeDTOOptionList,
  retrieveInstitutionDTOOptionList,
  retrieveSeasonDTOOptionList,
} from "../../utilization/ManagementUtilization";
import BaseLoading from "../../components/loading/BaseLoading";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const StandardExamApplicationListScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    language,
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
  } = useAuth();
  const [pageState, setPageState] = useState(0);
  const [standardExamApplicationDTOList, setStandardExamApplicationDTOList] =
    useState([]);
  const [standardExamDTOList, setStandardExamDTOList] = useState([]);
  const [id, setId] = useState(null);
  const [beginAt, setBeginAt] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .split(":")[0] +
      ":" +
      new Date().toISOString().split(":")[1]
  );
  const [endAt, setEndAt] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .split(":")[0] +
      ":" +
      new Date().toISOString().split(":")[1]
  );
  const [selectedDurationType, setSelectedDurationType] = useState("NONE");
  const [selectedDurationInMinutes, setSelectedDurationInMinutes] =
    useState("DEFAULT_OPTION");
  const [seasonDTOList, setSeasonDTOList] = useState([]);
  const [selectedSeasonId, setSelectedSeasonId] = useState("DEFAULT_OPTION");
  const [seasonIdList, setSeasonIdList] = useState([]);
  const [gradeDTOList, setGradeDTOList] = useState([]);
  const [selectedGradeId, setSelectedGradeId] = useState("DEFAULT_OPTION");
  const [gradeIdList, setGradeIdList] = useState([]);
  const [branchDTOList, setBranchDTOList] = useState([]);
  const [selectedBranchId, setSelectedBranchId] = useState("DEFAULT_OPTION");
  const [branchIdList, setBranchIdList] = useState([]);
  const [selectedStandardExamId, setSelectedStandardExamId] =
    useState("DEFAULT_OPTION");
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterTextContains, setFilterTextContains] = useState(null);
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [debounceState, setDebounceState] = useState("");
  const [errorMap, setErrorMap] = useState({
    beginAt: null,
    endAt: null,
    selectedStandardExamId: null,
    durationType: null,
    durationInMinutes: null,
    seasonIdList: null,
    gradeIdList: null,
    branchIdList: null,
  });
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [
    activeStandardExamApplicationDTOList,
    setActiveStandardExamApplicationDTOList,
  ] = useState([]);
  const [filterStandardExamType, setFilterStandardExamType] =
    useState("DEFAULT_OPTION");
  const [filterInstitutionId, setFilterInstitutionId] = useState(
    userProfileInstitution ? userProfileInstitution.id : "DEFAULT_OPTION"
  );
  const [institutionDTOList, setInstitutionDTOList] = useState([]);
  const [activeLoading, setActiveLoading] = useState(false);
  // const [activityListLoading, setActivityListLoading] = useState(false);
  const [standardExamListUpdated, setStandardExamListUpdated] = useState(false);
  const [isEvaluated, setIsEvaluated] = useState(false);
  const ONE_DAY = 86400000;

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("standard_exam_application_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    if (standardExamDTOList.length > 0) {
      setStandardExamListUpdated(true);
    }
  }, [selectedStandardExamId]);

  useEffect(() => {
    const retrieveAndSetStandardExamList = async () => {
      let data = [];
      let err;
      if (userProfile.role === "STAFF") {
        const { data: staffData, err: staffErr } =
          await queryStandardExamFromStandardExamAccessibilityByParams({
            userId: userInformation.id,
            userProfileId: userProfile.id,
            brandId: userProfileBrand.id,
            campusId: userProfileCampus?.id,
            institutionId: userProfileInstitution?.id,
            deleted: false,
          });
        data = staffData;
        err = staffErr;
      } else if (userProfile.role === "INDIVIDUAL") {
        const { data: individualData, err: individualErr } =
          await queryStandardExamDTOListByUserIdUserProfileId({
            userId: userInformation.id,
            userProfileId: userProfile.id,
          });
        data = individualData;
        err = individualErr;
      }
      if (err) {
        console.log(err);
      } else {
        setStandardExamDTOList(data.sort((a, b) => b.id - a.id));
        if (data.length > 0) {
          setStandardExamListUpdated(true);
        }
      }
    };
    const retrieveAndSetSeasonList = async () => {
      if (userProfileInstitution) {
        if (userProfileSeason) {
          setSeasonDTOList([userProfileSeason]);
          if (userProfile.role === "INDIVIDUAL") {
            setSeasonIdList([userProfileSeason.id]);
          }
        } else {
          const { data, err } = await queryActiveSeasonListByInstitutionId(
            userProfileInstitution.id
          );
          if (err) {
            console.log(err);
          } else {
            setSeasonDTOList(data);
          }
        }
      } else {
        if (userProfileCampus) {
          const { data, err } = await queryActiveSeasonListByCampusId(
            userProfileCampus.id
          );
          setSeasonDTOList(data);
        } else {
          const { data, err } = await queryActiveSeasonListByBrandId(
            userProfileBrand.id
          );
          setSeasonDTOList(data);
        }
      }
    };
    if (pageState === 1) {
      retrieveAndSetStandardExamList();
      retrieveAndSetSeasonList();
    }
  }, [pageState]);

  useEffect(() => {
    const retrieveAndSetGradeDTOList = async () => {
      const { data, err } = await queryGradeListBySeasonIdList(seasonIdList);
      if (err) {
        console.log(err);
      } else {
        setGradeDTOList(data);
      }
    };
    if (pageState === 0) return;
    if (seasonIdList.length > 0) {
      if (userProfileGrade) {
        setGradeDTOList([userProfileGrade]);
      } else {
        retrieveAndSetGradeDTOList();
      }
    }
  }, [pageState, seasonIdList]);

  useEffect(() => {
    if (gradeDTOList.length > 0) {
      if (seasonIdList.length === 0) {
        setGradeDTOList([]);
        setGradeIdList([]);
        setBranchDTOList([]);
        setBranchIdList([]);
      } else {
        const filteredGradeIdList = gradeIdList.filter((gradeId) => {
          const foundGradeDTO = gradeDTOList.find(
            (gradeDTO) => parseFloat(gradeDTO.id) === parseFloat(gradeId)
          );
          if (
            seasonIdList.find(
              (seasonId) =>
                parseFloat(seasonId) === parseFloat(foundGradeDTO.seasonDTO.id)
            )
          ) {
            return true;
          }
          return false;
        });
        setGradeIdList(
          JSON.parse(JSON.stringify(filteredGradeIdList.sort((a, b) => b - a)))
        );
      }
    }
  }, [seasonIdList]);

  useEffect(() => {
    const retrieveAndSetBranchDTOList = async () => {
      const { data, err } = await queryBranchListByGradeIdList(gradeIdList);
      if (err) {
        console.log(err);
      } else {
        setBranchDTOList(data);
      }
    };
    if (pageState === 0) return;
    if (gradeIdList.length > 0) {
      if (userProfileBranch) {
        setBranchDTOList([userProfileBranch]);
      } else {
        retrieveAndSetBranchDTOList();
      }
    }
  }, [pageState, gradeIdList]);

  useEffect(() => {
    if (branchDTOList.length > 0) {
      if (gradeIdList.length === 0) {
        setBranchDTOList([]);
        setBranchIdList([]);
      } else {
        const filteredBranchIdList = branchIdList.filter((branchId) => {
          const foundBranchDTO = branchDTOList.find(
            (branchDTO) => parseFloat(branchDTO.id) === parseFloat(branchId)
          );
          if (
            gradeIdList.find(
              (gradeId) =>
                parseFloat(gradeId) === parseFloat(foundBranchDTO.gradeDTO.id)
            )
          ) {
            return true;
          }
          return false;
        });
        setBranchIdList(
          JSON.parse(JSON.stringify(filteredBranchIdList.sort((a, b) => b - a)))
        );
      }
    }
  }, [gradeIdList]);

  useEffect(() => {
    const queryAndSetInstitutionListByBrandId = async () => {
      const { data: campusData, err: campusErr } =
        await queryCampusListByBrandId(userProfileBrand.id);
      if (campusErr) {
        console.log(campusErr);
      } else {
        const { data: institutionData, err: institutionErr } =
          await queryInstitutionListByCampusIdList(
            campusData.map((campus) => campus.id)
          );
        if (institutionErr) {
          console.log(institutionErr);
        } else {
          setInstitutionDTOList(institutionData);
        }
      }
    };
    const queryAndSetInstitutionListByCampusId = async () => {
      const { data: institutionData, err: institutionErr } =
        await queryInstitutionListByCampusId(userProfileCampus.id);
      if (institutionErr) {
        console.log(institutionErr);
      } else {
        setInstitutionDTOList(institutionData);
      }
    };
    if (!userProfileInstitution) {
      if (userProfileCampus) {
        queryAndSetInstitutionListByCampusId();
      } else {
        queryAndSetInstitutionListByBrandId();
      }
    } else {
      setInstitutionDTOList([userProfileInstitution]);
    }
  }, [userProfileInstitution]);

  useEffect(() => {
    if (!userProfileSeason) return;
    setSeasonIdList([userProfileSeason.id]);
  }, [userProfileSeason]);

  useEffect(() => {
    if (!userProfileGrade) return;
    setGradeIdList([userProfileGrade.id]);
  }, [userProfileGrade]);

  useEffect(() => {
    if (!userProfileBranch) return;
    setBranchIdList([userProfileBranch.id]);
  }, [userProfileBranch]);

  useEffect(() => {
    if (selectedTabIndex === 1) {
      retrieveAndSetStandardExamApplicationPageByFilter();
    }
  }, [
    selectedTabIndex,
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    setDebounceState("BASE_FILTER");
    retrieveAndSetStandardExamApplicationPageByFilter();
  }, [isFilterApplied, filterBeginAt, filterEndAt, filterShowDeleted]);

  useEffect(() => {
    if (!isFilterApplied) return;
    if (filterTextContains) {
      setDebounceState("TEXT_CONTAINS");
      debouncedRetrieveAndSetStandardExamApplicationPageByFilterDebounce(
        filterTextContains
      );
    } else {
      retrieveAndSetStandardExamApplicationPageByFilter();
    }
  }, [filterTextContains]);

  useEffect(() => {
    if (selectedTabIndex === 0 && filterInstitutionId !== "DEFAULT_OPTION") {
      retrieveAndSetActiveStandardExamApplicationListByFilter();
    }
  }, [selectedTabIndex, filterInstitutionId]);

  useEffect(() => {
    if (filterInstitutionId === "DEFAULT_OPTION") {
      setActiveStandardExamApplicationDTOList([]);
    }
  }, [filterInstitutionId]);

  useEffect(() => {
    if (!state) return;
    const { pageState, tabIndex, standardExamId } = state;
    if (tabIndex) {
      setSelectedTabIndex(tabIndex);
    }
    if (pageState) {
      setPageState(pageState);
    }
    if (standardExamId) {
      setSelectedStandardExamId(standardExamId);
    }
  }, [state]);

  const retrieveAndSetActiveStandardExamApplicationListByFilter = async () => {
    setActiveLoading(true);
    let queryBranchParams;
    queryBranchParams = {
      institutionIdList: [filterInstitutionId],
      deleted: false,
    };
    const { data, err } = await queryBranchListByParams(queryBranchParams);
    if (err) {
      console.log(err);
    } else {
      const seasonIdList = [];
      const gradeIdList = [];
      const branchIdList = [];
      for (const foundBranch of data) {
        if (
          branchIdList.findIndex((branchId) => branchId === foundBranch.id) ===
          -1
        ) {
          branchIdList.push(foundBranch.id);
        }
        if (
          gradeIdList.findIndex(
            (gradeId) => gradeId === foundBranch.gradeDTO.id
          ) === -1
        ) {
          gradeIdList.push(foundBranch.gradeDTO.id);
        }
        if (
          seasonIdList.findIndex(
            (seasonId) => seasonId === foundBranch.gradeDTO.seasonDTO.id
          ) === -1
        ) {
          seasonIdList.push(foundBranch.gradeDTO.seasonDTO.id);
        }
      }
      const { data: activeData, err: activeErr } =
        await queryAllStandardExamApplicationDTOListByFilterActive({
          seasonIdList,
          gradeIdList,
          branchIdList,
        });
      if (activeErr) {
        console.log(activeErr);
      } else {
        setActiveStandardExamApplicationDTOList(
          activeData.sort((a, b) => a.endAt - b.endAt)
        );
      }
    }
    setActiveLoading(false);
  };

  const retrieveAndSetStandardExamApplicationPageByFilter = async () => {
    setTableLoading(true);
    let queryBranchParams;
    if (userProfileBranch) {
      queryBranchParams = { branchIdList: [userProfileBranch.id] };
    } else if (userProfileGrade) {
      queryBranchParams = { gradeIdList: [userProfileGrade.id] };
    } else if (userProfileSeason) {
      queryBranchParams = { seasonIdList: [userProfileSeason.id] };
    } else if (userProfileInstitution) {
      queryBranchParams = { institutionIdList: [userProfileInstitution.id] };
    } else if (userProfileCampus) {
      queryBranchParams = { campusIdList: [userProfileCampus.id] };
    } else if (userProfileBrand) {
      queryBranchParams = { brandIdList: [userProfileBrand.id] };
    }
    queryBranchParams.deleted = false;
    const { data, err } = await queryBranchListByParams(queryBranchParams);
    if (err) {
      console.log(err);
    } else {
      const seasonIdList = [];
      const gradeIdList = [];
      const branchIdList = [];
      for (const foundBranch of data) {
        if (
          branchIdList.findIndex((branchId) => branchId === foundBranch.id) ===
          -1
        ) {
          branchIdList.push(foundBranch.id);
        }
        if (
          gradeIdList.findIndex(
            (gradeId) => gradeId === foundBranch.gradeDTO.id
          ) === -1
        ) {
          gradeIdList.push(foundBranch.gradeDTO.id);
        }
        if (
          seasonIdList.findIndex(
            (seasonId) => seasonId === foundBranch.gradeDTO.seasonDTO.id
          ) === -1
        ) {
          seasonIdList.push(foundBranch.gradeDTO.seasonDTO.id);
        }
      }
      const { data: requestData, err: requestErr } =
        await queryAllStandardExamApplicationDTOPageByFilter({
          pageNumber,
          itemCount: selectedShowItemCount,
          sortKey,
          sortDirection,
          params: {
            beginAt:
              isFilterApplied && filterBeginAt
                ? new Date(filterBeginAt).getTime()
                : null,
            endAt:
              isFilterApplied && filterEndAt
                ? new Date(filterEndAt).getTime()
                : null,
            deleted: isFilterApplied
              ? filterShowDeleted
                ? null
                : false
              : false,
            textContains:
              isFilterApplied && filterTextContains
                ? filterTextContains.trim()
                : null,
            seasonIdList,
            gradeIdList,
            branchIdList,
          },
        });
      if (requestErr) {
        console.log(err);
      } else {
        setTotalItemCount(requestData.totalItemCount);
        setStandardExamApplicationDTOList(requestData.itemList);
      }
    }
    setTableLoading(false);
  };

  const retrieveAndSetStandardExamApplicationPageByFilterDebounce = async (
    filterTextContains
  ) => {
    setTableLoading(true);
    let queryBranchParams;
    if (userProfileInstitution) {
      queryBranchParams = { institutionIdList: [userProfileInstitution.id] };
    } else if (userProfileCampus) {
      queryBranchParams = { campusIdList: [userProfileCampus.id] };
    } else if (userProfileBrand) {
      queryBranchParams = { brandIdList: [userProfileBrand.id] };
    }
    queryBranchParams.deleted = false;
    const { data, err } = await queryBranchListByParams(queryBranchParams);
    if (err) {
      console.log(err);
    } else {
      const seasonIdList = [];
      const gradeIdList = [];
      const branchIdList = [];
      for (const foundBranch of data) {
        if (
          branchIdList.findIndex((branchId) => branchId === foundBranch.id) ===
          -1
        ) {
          branchIdList.push(foundBranch.id);
        }
        if (
          gradeIdList.findIndex(
            (gradeId) => gradeId === foundBranch.gradeDTO.id
          ) === -1
        ) {
          gradeIdList.push(foundBranch.gradeDTO.id);
        }
        if (
          seasonIdList.findIndex(
            (seasonId) => seasonId === foundBranch.gradeDTO.seasonDTO.id
          ) === -1
        ) {
          seasonIdList.push(foundBranch.gradeDTO.seasonDTO.id);
        }
      }
      const { data: requestData, err: requestErr } =
        await queryAllStandardExamApplicationDTOPageByFilter({
          pageNumber,
          itemCount: selectedShowItemCount,
          sortKey,
          sortDirection,
          params: {
            beginAt:
              isFilterApplied && filterBeginAt
                ? new Date(filterBeginAt).getTime()
                : null,
            endAt:
              isFilterApplied && filterEndAt
                ? new Date(filterEndAt).getTime()
                : null,
            deleted: isFilterApplied
              ? filterShowDeleted
                ? null
                : false
              : false,
            textContains:
              isFilterApplied && filterTextContains
                ? filterTextContains.trim()
                : null,
            seasonIdList,
            gradeIdList,
            branchIdList,
          },
        });
      if (requestErr) {
        console.log(err);
      } else {
        setTotalItemCount(requestData.totalItemCount);
        setStandardExamApplicationDTOList(requestData.itemList);
      }
    }

    setTableLoading(false);
  };

  const debouncedRetrieveAndSetStandardExamApplicationPageByFilterDebounce =
    useCallback(
      debounce(function (textContains) {
        retrieveAndSetStandardExamApplicationPageByFilterDebounce(textContains);
      }, 1000),
      [debounceState]
    );

  const retrieveStandardExamDTOOptionList = () => {
    return standardExamDTOList.map((standardExamDTO) => ({
      text: standardExamDTO.name,
      value: standardExamDTO.id,
    }));
  };

  const checkCommonValidation = () => {
    if (
      selectedStandardExamId === "DEFAULT_OPTION" ||
      (userProfileSeason && seasonIdList.length === 0) ||
      (userProfile.role === "INDIVIDUAL" &&
        (gradeIdList.length === 0 || seasonIdList.length === 0)) ||
      selectedDurationType === "DEFAULT_OPTION" ||
      (selectedDurationType === "NONE" &&
        selectedDurationInMinutes !== "DEFAULT_OPTION") ||
      new Date(beginAt).getTime() >= new Date(endAt).getTime()
    ) {
      const currentErrorMap = {
        selectedStandardExamId: null,
        seasonIdList: null,
        gradeIdList: null,
        branchIdList: null,
      };
      if (selectedStandardExamId === "DEFAULT_OPTION") {
        currentErrorMap.selectedStandardExamId = i18n.t(
          "standard_exam_required_field"
        );
      }
      if (userProfileSeason && seasonIdList.length === 0) {
        currentErrorMap.seasonIdList = i18n.t("season_required_field");
      }
      if (selectedDurationType === "DEFAULT_OPTION") {
        currentErrorMap.durationType = i18n.t("duration_type_required");
      }
      if (
        selectedDurationType === "NONE" &&
        selectedDurationInMinutes !== "DEFAULT_OPTION"
      ) {
        currentErrorMap.durationInMinutes = i18n.t(
          "duration_in_minutes_required"
        );
      }
      if (userProfile.role === "INDIVIDUAL") {
        if (gradeIdList.length === 0) {
          currentErrorMap.gradeIdList = i18n.t("grade_name_required_field");
        }
        if (branchIdList.length === 0) {
          currentErrorMap.branchIdList = i18n.t("branch_name_required_field");
        }
      }
      // if (userProfileGrade && gradeIdList.length === 0) {
      //   currentErrorMap.gradeIdList = i18n.t("grade_required_field");
      // }
      // if (userProfileBranch && branchIdList.length === 0) {
      //   currentErrorMap.branchIdList = i18n.t("branch_required_field");
      // }
      if (new Date(beginAt).getTime() >= new Date(endAt).getTime()) {
        NotificationManager.error(
          i18n.t("begin_at_cannot_be_greater_than_end_at_text")
        );
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    // const foundStandardExamDTO = standardExamDTOList.find(
    //   (standardExamDTO) =>
    //     parseFloat(standardExamDTO.id) === parseFloat(selectedStandardExamId)
    // );
    // if (selectedDurationType !== "NONE" && foundStandardExamDTO.activityDTOList.filter((activityDTO) => activityDTO.))
    return true;
  };

  const calculateRemainingDays = (providedTime) => {
    const remainingTime = providedTime - new Date().getTime();
    return Math.ceil(remainingTime / 1000 / 60 / 60 / 24);
  };

  const checkCanNavigate = () => {
    let canNavigate = true;
    if (!checkViewPermissionGranted("standard_exam_application_list")) {
      canNavigate = false;
    }
    return canNavigate;
  };

  const constructNavigatableList = () => {
    let navigateList = [];
    if (checkViewPermissionGranted("standard_exam_application_list")) {
      navigateList.push({
        id: 0,
        text: i18n.t("to_detail"),
        icon: (
          <BaseImage
            src="/icons/navigate-gray.png"
            alt="navigate"
            size="small"
          />
        ),
        value: `/standard-exams/applications/{id}/detail`,
      });
    }
    return navigateList;
  };

  const resetParams = () => {
    setId(null);
    setSelectedStandardExamId("DEFAULT_OPTION");
    setBeginAt(
      new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .split(":")[0] +
        ":" +
        new Date().toISOString().split(":")[1]
    );
    setEndAt(
      new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .split(":")[0] +
        ":" +
        new Date().toISOString().split(":")[1]
    );
    setSelectedDurationType("NONE");
    setSelectedDurationInMinutes("DEFAULT_OPTION");
    // setSeasonIdList([]);
    setSeasonDTOList([]);
    // setGradeIdList([]);
    setGradeDTOList([]);
    // setBranchIdList([]);
    setBranchDTOList([]);
    setIsEvaluated(false);
  };

  const handleOnChange = (type, value) => {
    if (type === "beginAt") {
      setBeginAt(value);
    } else if (type === "endAt") {
      setEndAt(value);
    } else if (type === "durationType") {
      setSelectedDurationType(value);
      setSelectedDurationInMinutes("DEFAULT_OPTION");
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        durationType: null,
      }));
    } else if (type === "durationInMinutes") {
      setSelectedDurationInMinutes(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        durationInMinutes: null,
      }));
    } else if (type === "standardExamId") {
      setSelectedStandardExamId(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        selectedStandardExamId: null,
      }));
    } else if (type === "seasonId") {
      setSelectedSeasonId(value);
    } else if (type === "seasonIdAdd") {
      handleAddSeasonToCathctivityApplication(value);
    } else if (type === "gradeId") {
      setSelectedGradeId(value);
    } else if (type === "gradeIdAdd") {
      handleAddGradeToCathctivityApplication(value);
    } else if (type === "branchId") {
      setSelectedBranchId(value);
    } else if (type === "branchIdAdd") {
      handleAddBranchToCathctivityApplication(value);
    } else if (type === "filterStandardExamType") {
      setFilterStandardExamType(value);
    } else if (type === "filterInstitutionId") {
      setFilterInstitutionId(value);
    }
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    } else if (type === "textContains") {
      setFilterTextContains(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleAddSeasonToCathctivityApplication = (selectedSeasonId) => {
    if (selectedSeasonId !== "DEFAULT_OPTION") {
      const foundIndex = seasonIdList.findIndex(
        (seasonId) => parseFloat(seasonId) === parseFloat(selectedSeasonId)
      );
      if (foundIndex === -1) {
        seasonIdList.push(parseFloat(selectedSeasonId));
        setSeasonIdList(
          JSON.parse(JSON.stringify(seasonIdList.sort((a, b) => b - a)))
        );
      }
      setSelectedSeasonId("DEFAULT_OPTION");
    }
  };

  const handleAddGradeToCathctivityApplication = (selectedGradeId) => {
    if (selectedGradeId !== "DEFAULT_OPTION") {
      const foundIndex = gradeIdList.findIndex(
        (gradeId) => parseFloat(gradeId) === parseFloat(selectedGradeId)
      );
      if (foundIndex === -1) {
        gradeIdList.push(parseFloat(selectedGradeId));
        setGradeIdList(
          JSON.parse(JSON.stringify(gradeIdList.sort((a, b) => b - a)))
        );
      }
      setSelectedGradeId("DEFAULT_OPTION");
    }
  };

  const handleAddBranchToCathctivityApplication = (selectedBranchId) => {
    if (selectedBranchId !== "DEFAULT_OPTION") {
      const foundIndex = branchIdList.findIndex(
        (branchId) => parseFloat(branchId) === parseFloat(selectedBranchId)
      );
      if (foundIndex === -1) {
        branchIdList.push(parseFloat(selectedBranchId));
        setBranchIdList(
          JSON.parse(JSON.stringify(branchIdList.sort((a, b) => b - a)))
        );
      }
      setSelectedBranchId("DEFAULT_OPTION");
    }
  };

  const handleRemoveSeasonFromStandardExamApplication = (seasonId) => {
    const foundIndex = seasonIdList.findIndex(
      (foundSeasonId) => parseFloat(foundSeasonId) === parseFloat(seasonId)
    );
    if (foundIndex > -1) {
      seasonIdList.splice(foundIndex, 1);
      setSeasonIdList(
        JSON.parse(JSON.stringify(seasonIdList.sort((a, b) => b - a)))
      );
    }
  };

  const handleRemoveGradeFromStandardExamApplication = (gradeId) => {
    const foundIndex = gradeIdList.findIndex(
      (foundGradeId) => parseFloat(foundGradeId) === parseFloat(gradeId)
    );
    if (foundIndex > -1) {
      gradeIdList.splice(foundIndex, 1);
      setGradeIdList(
        JSON.parse(JSON.stringify(gradeIdList.sort((a, b) => b - a)))
      );
    }
  };

  const handleRemoveBranchFromStandardExamApplication = (branchId) => {
    const foundIndex = branchIdList.findIndex(
      (foundBranchId) => parseFloat(foundBranchId) === parseFloat(branchId)
    );
    if (foundIndex > -1) {
      branchIdList.splice(foundIndex, 1);
      setBranchIdList(
        JSON.parse(JSON.stringify(branchIdList.sort((a, b) => b - a)))
      );
    }
  };

  const handleReturnFromStandardExamApplicationOnClick = () => {
    resetParams();
    setPageState(0);
  };

  const handleToCreateStandardExamApplicationOnClick = () => {
    setId(null);
    setPageState(1);
  };

  const handleToUpdateStandardExamApplicationOnClick = async (
    standardExamApplicationDTO
  ) => {
    setId(standardExamApplicationDTO.id);
    setBeginAt(
      new Date(
        standardExamApplicationDTO.beginAt -
          new Date().getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .split(":")[0] +
        ":" +
        new Date(
          standardExamApplicationDTO.beginAt -
            new Date().getTimezoneOffset() * 60 * 1000
        )
          .toISOString()
          .split(":")[1]
    );
    setEndAt(
      new Date(
        standardExamApplicationDTO.endAt -
          new Date().getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .split(":")[0] +
        ":" +
        new Date(
          standardExamApplicationDTO.endAt -
            new Date().getTimezoneOffset() * 60 * 1000
        )
          .toISOString()
          .split(":")[1]
    );
    setSelectedDurationType(standardExamApplicationDTO.durationType);
    if (standardExamApplicationDTO.durationInMinutes) {
      setSelectedDurationInMinutes(
        standardExamApplicationDTO.durationInMinutes
      );
    } else {
      setSelectedDurationInMinutes("DEFAULT_OPTION");
    }
    setSelectedStandardExamId(standardExamApplicationDTO.standardExamDTO.id);
    setSeasonIdList(standardExamApplicationDTO.seasonIdSet);
    setGradeIdList(standardExamApplicationDTO.gradeIdSet);
    setBranchIdList(standardExamApplicationDTO.branchIdSet);
    setIsEvaluated(standardExamApplicationDTO.isEvaluated);
    setPageState(1);
  };

  const handleToUpdateActiveStandardExamApplicationOnClick = (
    standardExamApplicationDTO
  ) => {
    setFilterTextContains(standardExamApplicationDTO.standardExamDTO.name);
    setDebounceState("TEXT_CONTAINS");
    setFilterBeginAt("");
    setFilterEndAt("");
    setFilterShowDeleted(false);
    setIsFilterApplied(true);
    setSelectedTabIndex(1);
  };

  const handleCreateStandardExamApplicationOnClick = async () => {
    if (checkInsertPermissionGranted("standard_exam_application_list")) {
      if (checkCommonValidation()) {
        const newStandardExamApplication = {
          userId: userInformation.id,
          userProfileId: userProfile.id,
          beginAt: new Date(beginAt).getTime(),
          endAt: new Date(endAt).getTime(),
          durationType:
            selectedDurationType === "DEFAULT_OPTION"
              ? null
              : selectedDurationType,
          durationInMinutes:
            selectedDurationInMinutes === "DEFAULT_OPTION"
              ? null
              : selectedDurationInMinutes,
          standardExam: {
            id: selectedStandardExamId,
          },
          seasonIdSet: seasonIdList,
          gradeIdSet: gradeIdList,
          branchIdSet: branchIdList,
        };
        const { data, err } =
          await createStandardExamApplicationByStandardExamId(
            newStandardExamApplication
          );
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          if (selectedTabIndex === 0) {
            retrieveAndSetActiveStandardExamApplicationListByFilter();
          } else {
            retrieveAndSetStandardExamApplicationPageByFilter();
          }

          handleReturnFromStandardExamApplicationOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleUpdateStandardExamApplicationOnClick = async () => {
    if (checkUpdatePermissionGranted("standard_exam_application_list")) {
      if (checkCommonValidation()) {
        const currentStandardExamApplication = {
          id,
          userId: userInformation.id,
          userProfileId: userProfile.id,
          beginAt: new Date(beginAt).getTime(),
          endAt: new Date(endAt).getTime(),
          durationType:
            selectedDurationType === "DEFAULT_OPTION"
              ? null
              : selectedDurationType,
          durationInMinutes:
            selectedDurationInMinutes === "DEFAULT_OPTION"
              ? null
              : selectedDurationInMinutes,
          standardExamId: selectedStandardExamId,
          seasonIdList,
          gradeIdList,
          branchIdList,
        };
        const { data, err } = await patchStandardExamApplication(
          currentStandardExamApplication
        );
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          retrieveAndSetStandardExamApplicationPageByFilter();
          handleReturnFromStandardExamApplicationOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleRemoveStandardExamApplicationOnClick = async (
    standardExamApplicationId
  ) => {
    if (checkDeletePermissionGranted("standard_exam_application_list")) {
      const { err } = await deleteStandardExamApplication(
        standardExamApplicationId
      );
      if (err) {
      } else {
        const foundStandardExamApplication =
          standardExamApplicationDTOList.find(
            (standardExamApplicationDTO) =>
              standardExamApplicationDTO.id === standardExamApplicationId
          );
        foundStandardExamApplication.deleted = true;
        setStandardExamApplicationDTOList(
          JSON.parse(
            JSON.stringify(
              standardExamApplicationDTOList.sort((a, b) => b.id - a.id)
            )
          )
        );
        retrieveAndSetStandardExamApplicationPageByFilter();
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_delete"));
    }
  };

  const handleEvaluateStandardExamApplicationOnClick = async (id) => {
    const { data, err } = await evaluateStandardExamApplicationById(id);
    if (err) {
      console.log(err);
    } else {
      if (data === "EVALUATED") {
        const foundActiveStandardExamApplicationIndex =
          activeStandardExamApplicationDTOList.findIndex(
            (activeStandardExamApplicationDTO) =>
              activeStandardExamApplicationDTO.id === id
          );
        activeStandardExamApplicationDTOList[
          foundActiveStandardExamApplicationIndex
        ].isEvaluated = true;
        setActiveStandardExamApplicationDTOList(
          JSON.parse(JSON.stringify(activeStandardExamApplicationDTOList))
        );
      }
    }
  };

  const handlePreviewStandardExamOnClick = (standardExamDTO) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    window.open(
      `${connection.app.url}/preview/standard-exam/${standardExamDTO.id}/${btoa(
        encodeURI(JSON.stringify({ token: accessToken }))
      )}`
    );
  };

  const RenderSingleSeasonItem = (seasonId) => {
    let foundSeasonDTO = seasonDTOList.find(
      (seasonDTO) => seasonDTO.id === parseFloat(seasonId)
    );
    if (!foundSeasonDTO) {
      foundSeasonDTO = {
        id: seasonId,
        name: i18n.t("unknown"),
        description: i18n.t("unknown"),
      };
    }
    return (
      <div className="w-full">
        <div className="flex flex-row justify-between items-center my-2 w-full">
          <div className="w-1/6">
            <div className="flex flex-row">
              <Tooltip id="item-descriptions-in-standard-exam-application" />
              <div className="mr-2">
                <BaseImage
                  size="small"
                  src="/icons/info.png"
                  alt="info"
                  dataToolTipId="item-descriptions-in-standard-exam-application"
                  dataToolTipContent={foundSeasonDTO.description}
                  dataToolTipPlace="top"
                  dataToolTipVariant="info"
                />
              </div>
              <p className="mx-1">{foundSeasonDTO.name}</p>
            </div>
          </div>
          <div className="w-1/12">
            <p className="mx-1">{foundSeasonDTO.institutionDTO?.name}</p>
          </div>

          <div className="w-1/12">
            <p className="mx-1">
              {new Date(foundSeasonDTO.beginAt).toLocaleDateString(language)}
            </p>
          </div>
          <div className="w-1/12">
            <p className="mx-1">
              {new Date(foundSeasonDTO.endAt).toLocaleDateString(language)}
            </p>
          </div>
          <div className="w-1/12">
            <div className="mx-2">
              <BaseImage
                src="/icons/remove-red.png"
                alt="remove"
                size="small"
                onClick={() => {
                  handleRemoveSeasonFromStandardExamApplication(
                    foundSeasonDTO.id
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderSingleGradeItem = (gradeId) => {
    let foundGradeDTO = gradeDTOList.find(
      (gradeDTO) => gradeDTO.id === parseFloat(gradeId)
    );
    if (!foundGradeDTO) {
      foundGradeDTO = {
        id: gradeId,
        name: i18n.t("unknown"),
        description: i18n.t("unknown"),
      };
    }
    return (
      <div className="w-full">
        <div className="flex flex-row justify-between items-center my-2 w-full">
          <div className="w-1/6">
            <div className="flex flex-row">
              <Tooltip id="item-descriptions-in-standard-exam-application" />
              <div className="mr-2">
                <BaseImage
                  size="small"
                  src="/icons/info.png"
                  alt="info"
                  dataToolTipId="item-descriptions-in-standard-exam-application"
                  dataToolTipContent={foundGradeDTO.description}
                  dataToolTipPlace="top"
                  dataToolTipVariant="info"
                />
              </div>
              <p className="mx-1">{foundGradeDTO.name}</p>
            </div>
          </div>
          <div className="w-1/12">
            <p className="mx-1">{foundGradeDTO.seasonDTO?.name}</p>
          </div>
          <div className="w-1/12">
            <p className="mx-1">
              {foundGradeDTO.seasonDTO?.institutionDTO.name}
            </p>
          </div>
          <div className="w-1/12">
            <p className="mx-1">{foundGradeDTO.level}</p>
          </div>

          <div className="w-1/12">
            <div className="mx-2">
              <BaseImage
                src="/icons/remove-red.png"
                alt="remove"
                size="small"
                onClick={() => {
                  handleRemoveGradeFromStandardExamApplication(
                    foundGradeDTO.id
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderSingleBranchItem = (branchId) => {
    let foundBranchDTO = branchDTOList.find(
      (branchDTO) => branchDTO.id === parseFloat(branchId)
    );
    if (!foundBranchDTO) {
      foundBranchDTO = {
        id: branchId,
        name: i18n.t("unknown"),
        description: i18n.t("unknown"),
      };
    }
    return (
      <div className="w-full">
        <div className="flex flex-row justify-between items-center my-2 w-full">
          <div className="w-1/6">
            <div className="flex flex-row">
              <Tooltip id="item-descriptions-in-standard-exam-application" />
              <div className="mr-2">
                <BaseImage
                  size="small"
                  src="/icons/info.png"
                  alt="info"
                  dataToolTipId="item-descriptions-in-standard-exam-application"
                  dataToolTipContent={foundBranchDTO.description}
                  dataToolTipPlace="top"
                  dataToolTipVariant="info"
                />
              </div>
              <p className="mx-1">{foundBranchDTO.name}</p>
            </div>
          </div>
          <div className="w-1/12">
            <p className="mx-1">{foundBranchDTO.gradeDTO?.name}</p>
          </div>
          <div className="w-1/12">
            <p className="mx-1">{foundBranchDTO.gradeDTO?.seasonDTO.name}</p>
          </div>
          <div className="w-1/12">
            <p className="mx-1">
              {foundBranchDTO.gradeDTO?.seasonDTO.institutionDTO.name}
            </p>
          </div>
          <div className="w-1/12">
            <div className="mx-2">
              <BaseImage
                src="/icons/remove-red.png"
                alt="remove"
                size="small"
                onClick={() => {
                  handleRemoveBranchFromStandardExamApplication(
                    foundBranchDTO.id
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const retrieveStandardExamApplicationDTOListByStandardExamType = () => {
    if (filterStandardExamType === "DEFAULT_OPTION") {
      return activeStandardExamApplicationDTOList;
    } else {
      return activeStandardExamApplicationDTOList.filter(
        (item) =>
          item.standardExamDTO.standardExamType === filterStandardExamType
      );
    }
  };

  const RenderStandardExamApplicationContent = () => {
    if (selectedTabIndex === 0) {
      return (
        <FullCard>
          <div className="p-1">
            <div className="flex flex-row items-center justify-between">
              <div></div>
              <CreateButton
                title={i18n.t("create")}
                size="medium"
                onClick={() => {
                  setPageState(1);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row items-center">
            <div className="w-1/2 lg:w-1/3">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("standard_exam_type")}
                  placeholder={i18n.t("standard_exam_type")}
                  value={filterStandardExamType}
                  optionList={retrieveStandardExamTypeOptionList()}
                  onChange={(event) => {
                    handleOnChange(
                      "filterStandardExamType",
                      event.target.value
                    );
                  }}
                />
              </div>
            </div>
            {userProfileInstitution ? null : (
              <div className="w-1/2 lg:w-1/3">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("institution_name")}
                    placeholder={i18n.t("institution_name")}
                    value={filterInstitutionId}
                    optionList={retrieveInstitutionDTOOptionList(
                      institutionDTOList
                    )}
                    onChange={(event) => {
                      handleOnChange("filterInstitutionId", event.target.value);
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          {activeLoading ? (
            <BaseLoading
              size="large"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          ) : (
            <div className="flex flex-row items-center flex-wrap">
              {activeStandardExamApplicationDTOList.map(
                (standardExamApplicationDTO, index) => (
                  <div key={index}>
                    <StandardExamApplicationCard
                      standardExamApplicationDTO={standardExamApplicationDTO}
                      language={language}
                      handleToUpdateOnClick={
                        handleToUpdateActiveStandardExamApplicationOnClick
                      }
                      handleEvaluateOnClick={
                        handleEvaluateStandardExamApplicationOnClick
                      }
                    />
                  </div>
                )
              )}
            </div>
          )}
        </FullCard>
      );
    } else if (selectedTabIndex === 1) {
      return (
        <AdvancedTable
          title={i18n.t("standard_exam_application_list")}
          description={i18n.t(
            "standard_exam_application_list_table_description_text"
          )}
          itemName={i18n.t("standard_exam_application")}
          headerList={[
            { name: i18n.t("begin_at"), key: "beginAt", type: "datetime" },
            { name: i18n.t("end_at"), key: "endAt", type: "datetime" },
            {
              name: i18n.t("name"),
              key: "standardExamDTO.name",
              type: "info",
              infoKey: "standardExamDTO.description",
            },
            {
              name: i18n.t("type"),
              key: "standardExamDTO.standardExamType",
              type: "enumeration",
            },
            {
              name: i18n.t("is_evaluated"),
              key: "isEvaluated",
              type: "boolean",
            },
            {
              name: i18n.t("duration_type"),
              key: "durationType",
              type: "enumeration",
            },
            {
              name: i18n.t("duration_in_minutes"),
              key: "durationInMinutes",
            },
            { name: i18n.t("created_at"), key: "createdAt", type: "datetime" },
          ]}
          selectable={false}
          showFilter={true}
          showItemCount={selectedShowItemCount}
          totalItemCount={totalItemCount}
          pageNumber={pageNumber}
          loading={tableLoading}
          sortKey={sortKey}
          sortDirection={sortDirection}
          handleOnFilterChange={handleOnFilterChange}
          handleOnTableParamsChange={handleOnTableParamsChange}
          filterParams={{
            isFilterApplied,
            showBeginDate: true,
            beginAt: filterBeginAt,
            showEndDate: true,
            endAt: filterEndAt,
            showTextContains: true,
            textContains: filterTextContains,
            showDeleted: filterShowDeleted,
          }}
          itemList={standardExamApplicationDTOList}
          insertable={checkInsertPermissionGranted(
            "standard_exam_application_list"
          )}
          handleInsertOnClick={handleToCreateStandardExamApplicationOnClick}
          updatable={checkUpdatePermissionGranted(
            "standard_exam_application_list"
          )}
          handleUpdateOnClick={handleToUpdateStandardExamApplicationOnClick}
          deletable={checkDeletePermissionGranted(
            "standard_exam_application_list"
          )}
          handleDeleteOnClick={handleRemoveStandardExamApplicationOnClick}
          navigatable={checkCanNavigate()}
          navigateOptionList={constructNavigatableList()}
        />
      );
    }
  };

  const RenderMainContent = () => {
    return (
      <>
        {pageState === 0 ? (
          <SelectionTab
            optionList={[
              {
                id: 0,
                title: i18n.t("active_standard_exam_applications"),
              },
              {
                id: 1,
                title: i18n.t("all_standard_exam_applications"),
              },
            ]}
            selectedId={selectedTabIndex}
            handleSelectOnClick={(selectedId) => {
              setSelectedTabIndex(selectedId);
            }}
          />
        ) : null}

        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          {RenderStandardExamApplicationContent()}
        </div>

        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <FullCard>
            <div className="">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      handleReturnFromStandardExamApplicationOnClick();
                    }}
                  />
                </div>
                <BaseTitle
                  title={
                    id
                      ? i18n.t("update_standard_exam_application")
                      : i18n.t("create_standard_exam_application")
                  }
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center text-catchup-dark-blue">
              <DividerLine />
              <div className="w-full">
                <p className="font-semibold text-lg">
                  {i18n.t("standard_exam_application_information")}
                </p>
              </div>

              <div className="w-full flex flex-row flex-wrap gap-x-4">
                <div className="flex-1 flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("begin_at")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="datetime-local"
                      value={beginAt}
                      onChange={(e) => {
                        handleOnChange("beginAt", e.target.value);
                      }}
                      errorText={errorMap.beginAt}
                    />
                  </div>
                </div>

                <div className="flex-1 flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("end_at")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="datetime-local"
                      value={endAt}
                      onChange={(e) => {
                        handleOnChange("endAt", e.target.value);
                      }}
                      errorText={errorMap.endAt}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-row gap-x-4">
                <div className="flex-1 flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("duration_type")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="select"
                      value={selectedDurationType}
                      optionList={retrieveDurationTypeOptionList()}
                      onChange={(event) => {
                        handleOnChange("durationType", event.target.value);
                      }}
                      errorText={errorMap.durationType}
                    />
                  </div>
                </div>
                {selectedDurationType !== "DEFAULT_OPTION" &&
                selectedDurationType !== "NONE" ? (
                  <div className="flex-1 flex flex-row">
                    <div className="w-catchup-input-group-title py-5">
                      <p>{i18n.t("duration_in_minutes")}</p>
                    </div>
                    <div className="flex-1">
                      <InputGroup
                        type="select"
                        value={selectedDurationInMinutes}
                        optionList={retrieveDurationInMinutesOptionList(
                          selectedDurationType
                        )}
                        onChange={(event) => {
                          handleOnChange(
                            "durationInMinutes",
                            event.target.value
                          );
                        }}
                        errorText={errorMap.durationInMinutes}
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="w-full flex flex-row items-center">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("standard_exam_name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={selectedStandardExamId}
                    optionList={retrieveStandardExamDTOOptionList()}
                    onChange={(event) => {
                      handleOnChange("standardExamId", event.target.value);
                    }}
                    errorText={errorMap.selectedStandardExamId}
                  />
                </div>
                {selectedStandardExamId !== "DEFAULT_OPTION" ? (
                  <div className="mx-2">
                    <BaseImage
                      src="/icons/preview.png"
                      alt="preview"
                      size="small"
                      onClick={(e) => {
                        handlePreviewStandardExamOnClick({
                          id: selectedStandardExamId,
                        });
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <DividerLine />
              {userProfile.role === "STAFF" ? (
                <>
                  <div className="w-full flex flex-row items-center">
                    <div className="w-catchup-input-group-title py-5">
                      <p>{i18n.t("season_name")}</p>
                    </div>
                    <div className="flex-1">
                      <InputGroup
                        type="select"
                        value={selectedSeasonId}
                        optionList={retrieveSeasonDTOOptionList(seasonDTOList)}
                        onChange={(event) => {
                          handleOnChange("seasonIdAdd", event.target.value);
                        }}
                      />
                    </div>
                  </div>

                  {seasonIdList.map((seasonId) => (
                    <div className="ml-auto w-5/6" key={seasonId}>
                      {RenderSingleSeasonItem(seasonId)}
                    </div>
                  ))}
                </>
              ) : null}

              {retrieveGradeDTOOptionList(gradeDTOList).length === 0 ? (
                <div className="w-full flex flex-row items-center gap-x-2 my-2">
                  <BaseImage src="/icons/info.png" alt="info" size="small" />
                  <div className="flex-1">
                    <p className="">
                      {i18n.t("grade_not_found_error_text")}{" "}
                      <span
                        className="cursor-pointer underline italic"
                        onClick={() => {
                          navigate("/grades");
                        }}
                      >
                        {i18n.t("you_can_create_grade_from_here_text")}
                      </span>
                    </p>
                  </div>
                </div>
              ) : null}

              <div className="w-full flex flex-row items-center">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("grade_name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={selectedGradeId}
                    optionList={retrieveGradeDTOOptionList(gradeDTOList)}
                    onChange={(event) => {
                      handleOnChange("gradeIdAdd", event.target.value);
                    }}
                    errorText={errorMap.gradeIdList}
                  />
                </div>
              </div>

              {gradeIdList.map((gradeId) => (
                <div className="ml-auto w-5/6" key={gradeId}>
                  {RenderSingleGradeItem(gradeId)}
                </div>
              ))}

              {retrieveBranchDTOOptionList(branchDTOList).length === 0 ? (
                <div className="w-full flex flex-row items-center gap-x-2 my-2">
                  <BaseImage src="/icons/info.png" alt="info" size="small" />
                  <div className="flex-1">
                    <p className="">
                      {i18n.t("branch_not_found_error_text")}{" "}
                      <span
                        className="cursor-pointer underline italic"
                        onClick={() => {
                          navigate("/branches");
                        }}
                      >
                        {i18n.t("you_can_create_branch_from_here_text")}
                      </span>
                    </p>
                  </div>
                </div>
              ) : null}

              <div className="w-full flex flex-row items-center">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("branch_name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={selectedBranchId}
                    optionList={retrieveBranchDTOOptionList(branchDTOList)}
                    onChange={(event) => {
                      handleOnChange("branchIdAdd", event.target.value);
                    }}
                    errorText={errorMap.branchIdList}
                  />
                </div>
              </div>

              {branchIdList.map((branchId) => (
                <div className="ml-auto w-5/6" key={branchId}>
                  {RenderSingleBranchItem(branchId)}
                </div>
              ))}

              {isEvaluated || branchIdList.length === 0 ? null : (
                <div className="mt-4 ml-auto">
                  {id ? (
                    <SecondaryButton
                      title={i18n.t("update")}
                      size="medium"
                      onClick={handleUpdateStandardExamApplicationOnClick}
                    />
                  ) : (
                    <CreateButton
                      title={i18n.t("create")}
                      size="medium"
                      onClick={handleCreateStandardExamApplicationOnClick}
                    />
                  )}
                </div>
              )}
            </div>
          </FullCard>
        </div>
      </>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default StandardExamApplicationListScreen;
