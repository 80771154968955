import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomCreateUser from "../../components/user/CustomCreateUser";
import ImportFromExcelCreateUser from "../../components/user/ImportFromExcelCreateUser";
import { checkInsertPermissionGranted } from "../../utilization/ScreenUtilization";
import { useApp } from "../../context/AppContextProvider";

const CreateUserScreen = () => {
  const { mode } = useParams();
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkInsertPermissionGranted("user_list")) {
      navigate("/permission-error");
    }
  }, []);

  const RenderMainContent = () => {
    if (mode === "custom") {
      return <CustomCreateUser />;
    } else if (mode === "import-from-excel") {
      return <ImportFromExcelCreateUser />;
    }
    return null;
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default CreateUserScreen;
