import i18n from "../language/i18n";

export const retrieveTokenUsageTypeOptionList = () => {
  return [
    {
      text: i18n.t("AI"),
      value: "AI",
    },
    {
      text: i18n.t("SYSTEM"),
      value: "SYSTEM",
    },
  ];
};

export const retrieveTokenUsageSubTypeOptionList = () => {
  return [
    {
      text: i18n.t("ACTIVITY_EVALUATION"),
      value: "ACTIVITY_EVALUATION",
    },
    {
      text: i18n.t("ACTIVITY_TEMPLATE_GENERATION"),
      value: "ACTIVITY_TEMPLATE_GENERATION",
    },
    {
      text: i18n.t("IMAGE_PROCESSING"),
      value: "IMAGE_PROCESSING",
    },
    {
      text: i18n.t("ACTIVITY_SOLUTION"),
      value: "ACTIVITY_SOLUTION",
    },
    {
      text: i18n.t("ACTIVITY_HINT"),
      value: "ACTIVITY_HINT",
    },
  ];
};
