import { useEffect, useState } from "react";
import { useAuth } from "../../context/UserContextProvider";
import { useApp } from "../../context/AppContextProvider";
import i18n from "../../language/i18n";
import { NotificationManager } from "react-notifications";
import ClickableHeaderItem from "./ClickableHeaderItem";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import FullCard from "../cards/FullCard";
import DividerLine from "../divider/DividerLine";
import BaseImage from "../images/BaseImage";
import BaseTitle from "../titles/BaseTitle";
import { retrieveMainLeftNavigationOptionList } from "../../utilization/HeaderUtilization";
import { calculateLevenshteinDistance } from "../../utilization/FunctionUtilization";

const DashboardHeader = () => {
  const navigate = useNavigate();
  const { theme, setTheme, setBoarding } = useApp();
  const {
    language,
    handleStateLogout,
    numberOfUnreadAnnouncements,
    setLanguage,
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
  } = useAuth();
  const [searchText, setSearchText] = useState(null);
  const [showFAQModal, setShowFAQModal] = useState(false);
  const [selectedFAQParentIndex, setSelectedFAQParentIndex] = useState(0);
  const [selectedFAQChildIndex, setSelectedFAQChildIndex] = useState(0);
  const [selectedFAQChildImageIndex, setSelectedFAQChildImageIndex] =
    useState(0);
  const [FAQImageLoaded, setFAQImageLoaded] = useState(false);
  const [parentOption, setParentOption] = useState(null);
  const [subOption, setSubOption] = useState(null);
  const [subSubOption, setSubSubOption] = useState(null);
  const [subSubSubOption, setSubSubSubOption] = useState(null);

  useEffect(() => {
    const pathName = window.location.pathname;
    const foundParentNavigationOption = mainFilterNavigationOptionList.find(
      (navigationOption) => navigationOption.navigate === pathName
    );
    if (foundParentNavigationOption) {
      if (foundParentNavigationOption.navigate === "/") {
        setParentOption(null);
        setSubOption(null);
        setSubSubOption(null);
        setSubSubSubOption(null);
      } else {
        setParentOption(foundParentNavigationOption);
        setSubOption(null);
        setSubSubOption(null);
        setSubSubSubOption(null);
      }
    } else {
      const foundFilteredSubNavigationOptionList =
        mainFilterNavigationOptionList.filter(
          (navigationOption) => navigationOption.subOptionList !== null
        );
      const foundNavigationOption = foundFilteredSubNavigationOptionList.find(
        (navigationOption) =>
          navigationOption.subOptionList.find(
            (subNavigationOption) => subNavigationOption.navigate === pathName
          )
      );
      if (foundNavigationOption) {
        setParentOption(foundNavigationOption);
        const subOptionList = foundNavigationOption.subOptionList;
        if (!subOptionList) return;
        const foundSubOption = subOptionList.find(
          (subNavigationOption) => subNavigationOption.navigate === pathName
        );
        setSubOption(foundSubOption);
        setSubSubOption(null);
        setSubSubSubOption(null);
      } else {
        const splittedPathName = pathName.split("/");
        const foundNavigationOption = foundFilteredSubNavigationOptionList.find(
          (navigationOption) =>
            navigationOption.subOptionList.find((subNavigationOption) =>
              pathName.startsWith(subNavigationOption.navigate)
            )
        );
        if (!foundNavigationOption) return;
        setParentOption(foundNavigationOption);
        const subOptionList = foundNavigationOption.subOptionList;
        if (!subOptionList) return;
        let foundSubNavigationOption;
        let distance = Number.MAX_SAFE_INTEGER;
        for (const subOption of subOptionList) {
          const calculatedDistance = calculateLevenshteinDistance(
            subOption.navigate,
            pathName
          );
          if (
            pathName.startsWith(subOption.navigate) &&
            calculatedDistance < distance
          ) {
            distance = calculatedDistance;
            foundSubNavigationOption = subOption;
          }
        }
        if (foundSubNavigationOption) {
          setSubOption(foundSubNavigationOption);
          const subSubOptionList = foundSubNavigationOption.subOptionList;
          if (!subSubOptionList) return;
          let closestSubSubNavigationOption;
          let foundSubSubNavigationOption;
          for (const subSubOption of subSubOptionList) {
            const splittedSubSubNavigationOptionList =
              subSubOption.navigate.split("/");
            let exists = true;
            let count = 0;
            for (const splittedPart of splittedSubSubNavigationOptionList) {
              if (splittedPart === "{id}") {
                count++;
              } else {
                if (!pathName.includes(splittedPart)) {
                  exists = false;
                  break;
                } else {
                  count++;
                }
              }
            }
            if (exists) {
              closestSubSubNavigationOption = subSubOption;
              if (count === splittedPathName.length) {
                foundSubSubNavigationOption = subSubOption;
              }
            }
          }
          if (foundSubSubNavigationOption) {
            setSubSubOption(foundSubSubNavigationOption);
            setSubSubSubOption(null);
          } else {
            setSubSubOption(closestSubSubNavigationOption);
            const subSubSubOptionList =
              closestSubSubNavigationOption.subOptionList;
            if (!subSubSubOptionList) return;
            // let closestSubSubSubNavigationOption;
            let foundSubSubSubNavigationOption;
            for (const subSubSubOption of subSubSubOptionList) {
              const splittedSubSubNavigationOptionList =
                subSubSubOption.navigate.split("/");
              let exists = true;
              let count = 0;
              for (const splittedPart of splittedSubSubNavigationOptionList) {
                if (splittedPart === "{id}") {
                  count++;
                } else {
                  if (!pathName.includes(splittedPart)) {
                    exists = false;
                    break;
                  } else {
                    count++;
                  }
                }
              }
              if (exists) {
                // closestSubSubSubNavigationOption = subSubSubOption;
                if (count === splittedPathName.length) {
                  foundSubSubSubNavigationOption = subSubSubOption;
                }
              }
            }
            if (foundSubSubSubNavigationOption) {
              setSubSubSubOption(foundSubSubSubNavigationOption);
            }
          }
        }
      }
    }
  }, [window.location.pathname]);

  const retrieveFAQParentOptionList = () => {
    return [{ value: 0, text: i18n.t("activity_management") }];
  };

  const retrieveFAQChildOptionList = () => {
    return [
      {
        value: 0,
        parent: 0,
        text: i18n.t("how_to_create_an_activity"),
        imageList: [
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_an_activity/${language.toLowerCase()}/0.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_an_activity/${language.toLowerCase()}/1.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_an_activity/${language.toLowerCase()}/2.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_an_activity/${language.toLowerCase()}/3.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_an_activity/${language.toLowerCase()}/4.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_an_activity/${language.toLowerCase()}/5.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_an_activity/${language.toLowerCase()}/6.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_an_activity/${language.toLowerCase()}/7.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_an_activity/${language.toLowerCase()}/8.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_an_activity/${language.toLowerCase()}/9.png`,
          },
        ],
      },
      {
        value: 1,
        parent: 0,
        text: i18n.t("how_to_create_a_catchtivity"),
        imageList: [
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_a_catchtivity/${language.toLowerCase()}/0.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_a_catchtivity/${language.toLowerCase()}/1.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_a_catchtivity/${language.toLowerCase()}/2.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_a_catchtivity/${language.toLowerCase()}/3.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_a_catchtivity/${language.toLowerCase()}/4.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_a_catchtivity/${language.toLowerCase()}/5.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_a_catchtivity/${language.toLowerCase()}/6.png`,
          },
        ],
      },
      {
        value: 2,
        parent: 0,
        text: i18n.t("how_to_create_a_catchxam"),
        imageList: [
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_a_catchxam/${language.toLowerCase()}/0.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_a_catchxam/${language.toLowerCase()}/1.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_a_catchxam/${language.toLowerCase()}/2.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_a_catchxam/${language.toLowerCase()}/3.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_a_catchxam/${language.toLowerCase()}/4.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_a_catchxam/${language.toLowerCase()}/5.png`,
          },
          {
            value: `https://catchupper.s3.eu-west-2.amazonaws.com/faq/how_to_create_a_catchxam/${language.toLowerCase()}/6.png`,
          },
        ],
      },
    ];
  };

  const retrieveLanguageOptionList = () => {
    return [
      { value: "TR", text: i18n.t("turkish") },
      { value: "EN", text: i18n.t("english") },
    ];
  };

  const retrieveUserProfileType = () => {
    if (userProfile && userProfile.role) {
      return i18n.t(userProfile.role);
    }
    return null;
  };

  const retrieveFlagIcon = (type) => {
    if (type === "TR") {
      return "/icons/flags/turkish.png";
    } else if (type === "EN") {
      return "/icons/flags/english.png";
    }
  };

  const calculateTotalNumberOfActions = () => {
    let totalActionCount = 0;
    totalActionCount += numberOfUnreadAnnouncements;
    if (userInformation && !userInformation.emailVerified) {
      totalActionCount += 1;
    }
    return totalActionCount;
  };

  const constructInformationOptionList = () => {
    const optionList = [];
    if (!userProfile) return optionList;
    if (userProfile.role === "STAFF") {
      if (userProfileCampus) {
        optionList.push({
          id: "campus",
          text: userProfileCampus.name,
          icon: <BaseImage src="/icons/campus.png" alt="campus" size="small" />,
        });
      }
      if (userProfileInstitution) {
        optionList.push({
          id: "institution",
          text: userProfileInstitution.name,
          icon: (
            <BaseImage
              src="/icons/institution.png"
              alt="institution"
              size="small"
            />
          ),
        });
      }
      if (userProfileSeason) {
        optionList.push({
          id: "season",
          text: userProfileSeason.name,
          icon: <BaseImage src="/icons/season.png" alt="season" size="small" />,
        });
      }
      if (userProfileGrade) {
        optionList.push({
          id: "grade",
          text: userProfileGrade.name,
          icon: <BaseImage src="/icons/grade.png" alt="grade" size="small" />,
        });
      }
      if (userProfileBranch) {
        optionList.push({
          id: "branch",
          text: userProfileBranch.name,
          icon: <BaseImage src="/icons/branch.png" alt="branch" size="small" />,
        });
      }
    } else if (userProfile.role === "INDIVIDUAL") {
    }

    return optionList;
  };

  const handleModeSwitchChange = () => {
    if (theme === "LIGHT") {
      setTheme("DARK");
    } else {
      setTheme("LIGHT");
    }
    window.location.reload();
  };

  const handleLanguageOnChange = (value) => {
    setLanguage(value);
    window.location.reload();
  };

  const handleLogoutOnClick = () => {
    navigate("/");
    handleStateLogout();
    setBoarding("LANDING");
    NotificationManager.success(i18n.t("logout_successful_text"));
  };

  const handleOnChange = (type, value) => {
    if (type === "searchText") {
      setSearchText(value);
    }
  };

  const RenderFAQModal = () => {
    return (
      <Modal
        isOpen={showFAQModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowFAQModal(false);
          setSelectedFAQParentIndex(0);
          setSelectedFAQChildIndex(0);
          setSelectedFAQChildImageIndex(0);
        }}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: 0,
            borderRadius: 20,
            background: "",
            border: "",
            width: "95%",
            overflow: "auto",
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.6)",
          },
        }}
        contentLabel=""
      >
        <div className="flex-1 flex flex-col z-10">
          <div className="ml-auto px-5 py-3">
            <BaseImage
              src="/icons/cross-red.png"
              alt="cross-red"
              size="medium"
              onClick={() => {
                setShowFAQModal(false);
                setSelectedFAQParentIndex(0);
                setSelectedFAQChildIndex(0);
                setSelectedFAQChildImageIndex(0);
              }}
            />
          </div>
          <div className="">
            <FullCard>
              <div className="flex flex-col">
                <BaseTitle
                  title={
                    retrieveFAQParentOptionList().find(
                      (parentOption) =>
                        parseFloat(parentOption.value) ===
                        parseFloat(selectedFAQParentIndex)
                    ).text
                  }
                />
              </div>
              <DividerLine />
              <div className="flex flex-row gap-x-2">
                <div className="w-1/3">
                  {retrieveFAQChildOptionList()
                    .filter(
                      (childOption) =>
                        parseFloat(childOption.parent) ===
                        parseFloat(selectedFAQParentIndex)
                    )
                    .map((childOption) => (
                      <div
                        className={`${
                          parseFloat(selectedFAQChildIndex) ===
                          parseFloat(childOption.value)
                            ? "text-catchup-blue border-catchup-blue"
                            : ""
                        } border-b p-2 m-2 cursor-pointer transition-all duration-300`}
                        onClick={() => {
                          setSelectedFAQChildIndex(childOption.value);
                          setSelectedFAQChildImageIndex(0);
                        }}
                      >
                        <p
                          className={`${
                            parseFloat(selectedFAQChildIndex) ===
                            parseFloat(childOption.value)
                              ? ""
                              : ""
                          } `}
                        >
                          {childOption.text}
                        </p>
                      </div>
                    ))}
                </div>
                <div className="w-2/3">
                  <div className="flex-1">
                    <img
                      src={
                        retrieveFAQChildOptionList().find(
                          (childOption) =>
                            parseFloat(childOption.value) ===
                            parseFloat(selectedFAQChildIndex)
                        ).imageList[selectedFAQChildImageIndex].value
                      }
                      className={`w-full rounded-catchup-xlarge ${
                        FAQImageLoaded ? "visible" : "invisible"
                      }`}
                      onLoad={() => {
                        setFAQImageLoaded(true);
                      }}
                    />
                    <div className="flex flex-col justify-center items-center mt-2">
                      <div className="flex flex-row items-center gap-x-4">
                        <div className="h-8 w-8">
                          {selectedFAQChildImageIndex !== 0 ? (
                            <img
                              className="w-full h-full cursor-pointer"
                              src="/icons/arrow-left.png"
                              onClick={() => {
                                setSelectedFAQChildImageIndex(
                                  selectedFAQChildImageIndex - 1
                                );
                                setFAQImageLoaded(false);
                              }}
                            />
                          ) : null}
                        </div>
                        {retrieveFAQChildOptionList()
                          .find(
                            (childOption) =>
                              parseFloat(childOption.value) ===
                              parseFloat(selectedFAQChildIndex)
                          )
                          .imageList.map((image, imageIndex) => (
                            <div
                              className="cursor-pointer text-catchup-dark-blue"
                              onClick={() => {
                                setSelectedFAQChildImageIndex(imageIndex);
                                setFAQImageLoaded(false);
                              }}
                            >
                              <p
                                className={`${
                                  parseFloat(imageIndex) ===
                                  parseFloat(selectedFAQChildImageIndex)
                                    ? "text-2xl"
                                    : "text-md"
                                } `}
                              >
                                {imageIndex + 1}
                              </p>
                            </div>
                          ))}
                        <div className="h-8 w-8 cursor-pointer">
                          {selectedFAQChildImageIndex <
                          retrieveFAQChildOptionList().find(
                            (childOption) =>
                              parseFloat(childOption.value) ===
                              parseFloat(selectedFAQChildIndex)
                          ).imageList.length -
                            1 ? (
                            <img
                              className="w-full h-full"
                              src="/icons/arrow-right.png"
                              onClick={() => {
                                setSelectedFAQChildImageIndex(
                                  selectedFAQChildImageIndex + 1
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FullCard>
          </div>
        </div>
      </Modal>
    );
  };

  const mainFilterNavigationOptionList = retrieveMainLeftNavigationOptionList();

  return (
    <div className="pt-12 min-h-catchup-header">
      {RenderFAQModal()}
      <div className="h-full flex flex-col justify-center">
        <div className="flex flex-row items-center justify-between">
          <nav className="flex flex-row justify-between items-center w-full h-full mx-4">
            <ul>
              {parentOption ? (
                <div className="flex flex-row flex-wrap items-center">
                  <p
                    className={`${
                      subOption ? "opacity-50" : "opacity-100"
                    } text-2xl`}
                  >
                    {parentOption.name}
                  </p>
                  {subOption ? (
                    <>
                      <BaseImage
                        src="/icons/header-arrow-right.png"
                        alt="arrow-right"
                        size="small"
                      />
                      <p
                        className={`${
                          subSubOption ? "opacity-50" : "opacity-100"
                        } text-2xl`}
                      >
                        {subOption.name}
                      </p>
                    </>
                  ) : null}
                  {subSubOption ? (
                    <>
                      <BaseImage
                        src="/icons/header-arrow-right.png"
                        alt="arrow-right"
                        size="small"
                      />
                      <p
                        className={`${
                          subSubSubOption ? "opacity-50" : "opacity-100"
                        } text-2xl`}
                      >
                        {subSubOption.name}
                      </p>
                    </>
                  ) : null}
                  {subSubSubOption ? (
                    <>
                      <BaseImage
                        src="/icons/header-arrow-right.png"
                        alt="arrow-right"
                        size="small"
                      />
                      <p className="text-2xl">{subSubSubOption.name}</p>
                    </>
                  ) : null}
                </div>
              ) : null}
            </ul>
            <ul className="flex flex-row items-center gap-x-4 mr-5 lg:mr-0 ml-5 lg:ml-0 ">
              <ClickableHeaderItem
                id="faq"
                name={
                  <div className="mr-2">
                    <BaseImage
                      size="medium"
                      src="/icons/question-mark.png"
                      alt="question-mark"
                    />
                  </div>
                }
                type={"IMAGE"}
                onClick={() => {
                  setShowFAQModal(true);
                }}
              />
              <ClickableHeaderItem
                id="language"
                name={
                  <div className="mr-2">
                    <BaseImage
                      size="medium"
                      src="/icons/language.png"
                      alt="language"
                    />
                  </div>
                }
                type={"IMAGE_DROPDOWN"}
                optionList={retrieveLanguageOptionList().map((option) => ({
                  ...option,
                  id: option.value,
                  icon: (
                    <BaseImage
                      size="custom"
                      className="h-5 w-8"
                      src={retrieveFlagIcon(option.value)}
                      alt="flag"
                    />
                  ),
                  onClick: () => {
                    handleLanguageOnChange(option.value);
                  },
                }))}
                showText={true}
              />
              <ClickableHeaderItem
                id="faq"
                name={
                  <div className="mr-2 relative">
                    {numberOfUnreadAnnouncements > 0 ? (
                      <div className="absolute top-0 right-0 w-2 h-2 bg-catchup-red rounded-full" />
                    ) : null}

                    <BaseImage
                      size="medium"
                      src="/icons/notification.png"
                      alt=""
                    />
                  </div>
                }
                type={"IMAGE"}
                onClick={() => {
                  navigate("/my-announcements");
                }}
              />
              <ClickableHeaderItem
                id="information"
                name={`${userProfileBrand?.name}`}
                faviconUrl={userProfileBrand?.faviconUrl}
                type={"SUBTEXT_DROPDOWN"}
                optionList={constructInformationOptionList()}
              />
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
