import { GiWorld } from "react-icons/gi";
import { useAuth } from "../../context/UserContextProvider";
import Switch from "react-switch";
import { useApp } from "../../context/AppContextProvider";
import i18n from "../../language/i18n";
import ClickableHeaderItem from "./ClickableHeaderItem";

const LandingHeader = () => {
  const { theme, setTheme } = useApp();
  const { language, setLanguage } = useAuth();

  const retrieveIconByName = (name) => {
    if (name === "language") {
      return (
        <div className="flex flex-row">
          <GiWorld size={24} />
          <p className="px-2">{language}</p>
        </div>
      );
    }
  };

  const retrieveImageByName = (name) => {
    if (name === "catchup_logo") {
      return <img className="h-10" src="logo.png" alt="main-logo" />;
    }
  };

  const retrieveLanguageOptionList = () => {
    return [
      { value: "TR", text: i18n.t("turkish") },
      { value: "EN", text: i18n.t("english") },
    ];
  };

  const handleModeSwitchChange = () => {
    if (theme === "LIGHT") {
      setTheme("DARK");
    } else {
      setTheme("LIGHT");
    }
  };

  const handleLanguageOnChange = (e) => {
    setLanguage(e.target.value);
  };

  return (
    <div className="flex items-center justify-between h-18 border-b border-slate-700 dark:border-slate-200">
      <nav>
        <ul>
          <ClickableHeaderItem
            name={retrieveImageByName("catchup_logo")}
            link={"/"}
            type={"IMAGE"}
          />
        </ul>
      </nav>
      <nav>
        <ul className="flex flex-row">
          <li className="flex flex-row items-center">
            <p className="px-2">{i18n.t("light")}</p>
            <Switch
              onChange={handleModeSwitchChange}
              checkedIcon={false}
              onColor={"#e2e8f0"}
              offColor={"#475569"}
              onHandleColor={"#475569"}
              offHandleColor={"#e2e8f0"}
              uncheckedIcon={false}
              checked={theme === "DARK"}
            />
            <p className="px-2">{i18n.t("dark")}</p>
          </li>
          <ClickableHeaderItem
            optionList={retrieveLanguageOptionList()}
            onChange={handleLanguageOnChange}
            value={language}
            type={"SELECT"}
          />
          <ClickableHeaderItem
            name={i18n.t("login")}
            link={"/login"}
            type={"TEXT"}
          />
          <ClickableHeaderItem
            name={i18n.t("register")}
            link={"/register"}
            type={"CTA"}
          />
        </ul>
      </nav>
    </div>
  );
};

export default LandingHeader;
