const TextGroup = (props) => {
  const { title, onClick } = props;

  return (
    <div
      className="flex flex-row items-center gap-x-2 cursor-pointer text-md transition-all duration-300 text-catchup-gray-300"
      onClick={onClick}
    >
      <div className="flex flex-row items-center gap-x-1">
        <p className="font-bold">{title}</p>
      </div>
    </div>
  );
};

export default TextGroup;
