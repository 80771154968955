import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Server
 */
export const checkReportService = async () => {
  try {
    const response = await axios
      .create()
      .get(
        `${connection.base.report.url}${endpoints.reports.context}${endpoints.reports.actuator.health.context}`
      );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Base Participation Report
 */
export const generateParticipationBranchReport = async (branchReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.participations.context}${endpoints.reports.participations.generate.branch.context}`,
      branchReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateParticipationGradeReport = async (gradeReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.participations.context}${endpoints.reports.participations.generate.grade.context}`,
      gradeReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateParticipationSeasonReport = async (seasonReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.participations.context}${endpoints.reports.participations.generate.season.context}`,
      seasonReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateParticipationUserProfileReport = async (
  userProfileReport
) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.participations.context}${endpoints.reports.participations.generate["user-profile"].context}`,
      userProfileReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Base Performance Report
 */

export const generatePerformanceUserProfileReport = async (
  userProfileReport
) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.performances.context}${endpoints.reports.performances.generate["user-profile"].context}`,
      userProfileReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generatePerformanceCoterieReport = async (coterieReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.performances.context}${endpoints.reports.performances.generate.coterie.context}`,
      coterieReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Base Learning Outcome Report
 */
export const generateLearningOutcomeBranchReport = async (branchReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports["learning-outcomes"].context}${endpoints.reports["learning-outcomes"].generate.branch.context}`,
      branchReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateLearningOutcomeGradeReport = async (gradeReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports["learning-outcomes"].context}${endpoints.reports["learning-outcomes"].generate.grade.context}`,
      gradeReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateLearningOutcomeSeasonReport = async (seasonReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports["learning-outcomes"].context}${endpoints.reports["learning-outcomes"].generate.season.context}`,
      seasonReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateLearningOutcomeUserProfileReport = async (
  userProfileReport
) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports["learning-outcomes"].context}${endpoints.reports["learning-outcomes"].generate["user-profile"].context}`,
      userProfileReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Base Catchtivity Report
 */
export const generateCatchtivityBranchReport = async (branchReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.catchtivities.context}${endpoints.reports.catchtivities.generate.branch.context}`,
      branchReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateCatchtivityGradeReport = async (gradeReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.catchtivities.context}${endpoints.reports.catchtivities.generate.grade.context}`,
      gradeReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateCatchtivitySeasonReport = async (seasonReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.catchtivities.context}${endpoints.reports.catchtivities.generate.season.context}`,
      seasonReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Base Catchxam Report
 */
export const generateCatchxamBranchReport = async (branchReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.catchxams.context}${endpoints.reports.catchxams.generate.branch.context}`,
      branchReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateCatchxamGradeReport = async (gradeReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.catchxams.context}${endpoints.reports.catchxams.generate.grade.context}`,
      gradeReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateCatchxamSeasonReport = async (seasonReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.catchxams.context}${endpoints.reports.catchxams.generate.season.context}`,
      seasonReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveLearnerCatchxamReportDetailsByParams = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.catchxams.context}${endpoints.reports.catchxams.learner.dto["by-catchxamApplicationId-userId-userProfileId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveLearnerStandardExamReportDetailsByParams = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports["standard-exams"].context}${endpoints.reports["standard-exams"].learner.dto["by-standardExamApplicationId-userId-userProfileId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Base Standard Exam Report
 */
export const generateStandardExamBranchReport = async (branchReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports["standard-exams"].context}${endpoints.reports["standard-exams"].generate.branch.context}`,
      branchReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateStandardExamGradeReport = async (gradeReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports["standard-exams"].context}${endpoints.reports["standard-exams"].generate.grade.context}`,
      gradeReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateStandardExamSeasonReport = async (seasonReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports["standard-exams"].context}${endpoints.reports["standard-exams"].generate.season.context}`,
      seasonReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Base Report
 */

export const generateInformationBranchReport = async (branchReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.generate.branch.context}`,
      branchReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateInformationGradeReport = async (gradeReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.generate.grade.context}`,
      gradeReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateInformationSeasonReport = async (seasonReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.generate.season.context}`,
      seasonReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateInformationInstitutionReport = async (
  institutionReport
) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.generate.institution.context}`,
      institutionReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateInformationCampusReport = async (campusReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.generate.campus.context}`,
      campusReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateInformationRegionReport = async (regionReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.generate.region.context}`,
      regionReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const generateInformationBrandReport = async (brandReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.generate.brand.context}`,
      brandReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryReportListByUserIdAndUserProfileId = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.query.dto["by-userId-userProfileId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllReportDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createUserReport = async (newReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.users.context}`,
      newReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchUserReport = async (patchReport) => {
  try {
    const response = await axios.patch(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.users.context}`,
      patchReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveUserReportDetail = async (reportId) => {
  try {
    const response = await axios.get(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.users.context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    console.log(err);
  }
};

export const deleteUserReport = async (reportId) => {
  try {
    const response = await axios.delete(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.users.context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createUserProfileReport = async (newReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information["user-profiles"].context}`,
      newReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchUserProfileReport = async (patchReport) => {
  try {
    const response = await axios.patch(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information["user-profiles"].context}`,
      patchReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveUserProfileReportDetail = async (reportId) => {
  try {
    const response = await axios.get(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information["user-profiles"].context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    console.log(err);
  }
};

export const deleteUserProfileReport = async (reportId) => {
  try {
    const response = await axios.delete(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information["user-profiles"].context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createBranchReport = async (newReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.branches.context}`,
      newReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchBranchReport = async (patchReport) => {
  try {
    const response = await axios.patch(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.branches.context}`,
      patchReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveBranchReportDetail = async (reportId) => {
  try {
    const response = await axios.get(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.branches.context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    console.log(err);
  }
};

export const deleteBranchReport = async (reportId) => {
  try {
    const response = await axios.delete(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.branches.context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createGradeReport = async (newReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.grades.context}`,
      newReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchGradeReport = async (patchReport) => {
  try {
    const response = await axios.patch(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.grades.context}`,
      patchReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveGradeReportDetail = async (reportId) => {
  try {
    const response = await axios.get(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.grades.context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    console.log(err);
  }
};

export const deleteGradeReport = async (reportId) => {
  try {
    const response = await axios.delete(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.grades.context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createSeasonReport = async (newReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.seasons.context}`,
      newReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchSeasonReport = async (patchReport) => {
  try {
    const response = await axios.patch(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.seasons.context}`,
      patchReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveSeasonReportDetail = async (reportId) => {
  try {
    const response = await axios.get(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.seasons.context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    console.log(err);
  }
};

export const deleteSeasonReport = async (reportId) => {
  try {
    const response = await axios.delete(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.seasons.context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createInstitutionReport = async (newReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.institutions.context}`,
      newReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchInstitutionReport = async (patchReport) => {
  try {
    const response = await axios.patch(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.institutions.context}`,
      patchReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveInstitutionReportDetail = async (reportId) => {
  try {
    const response = await axios.get(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.institutions.context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    console.log(err);
  }
};

export const deleteInstitutionReport = async (reportId) => {
  try {
    const response = await axios.delete(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.institutions.context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createCampusReport = async (newReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.campuses.context}`,
      newReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchCampusReport = async (patchReport) => {
  try {
    const response = await axios.patch(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.campuses.context}`,
      patchReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveCampusReportDetail = async (reportId) => {
  try {
    const response = await axios.get(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.campuses.context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    console.log(err);
  }
};

export const deleteCampusReport = async (reportId) => {
  try {
    const response = await axios.delete(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.campuses.context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createBrandReport = async (newReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.brands.context}`,
      newReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchBrandReport = async (patchReport) => {
  try {
    const response = await axios.patch(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.brands.context}`,
      patchReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveBrandReportDetail = async (reportId) => {
  try {
    const response = await axios.get(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.brands.context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    console.log(err);
  }
};

export const deleteBrandReport = async (reportId) => {
  try {
    const response = await axios.delete(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.brands.context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createRegionReport = async (newReport) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.regions.context}`,
      newReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchRegionReport = async (patchReport) => {
  try {
    const response = await axios.patch(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.regions.context}`,
      patchReport
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveRegionReportDetail = async (reportId) => {
  try {
    const response = await axios.get(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.regions.context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    console.log(err);
  }
};

export const deleteRegionReport = async (reportId) => {
  try {
    const response = await axios.delete(
      `${connection.base.report.url}${endpoints.reports.information.context}${endpoints.reports.information.regions.context}/${reportId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
