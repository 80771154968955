import { useState } from "react";
import { Link } from "react-router-dom";
import InputGroup from "../groups/InputGroup";
import HoverViewButton from "../buttons/HoverViewButton";

const ClickableHeaderItem = (props) => {
  const {
    id,
    name,
    fullName,
    email,
    faviconUrl,
    subText,
    link,
    type,
    optionList,
    onClick,
    onChange,
    value,
    mainClassNames,
    showText,
  } = props;

  if (type === "CTA") {
    return (
      <li key={id} className="h-14 m-auto text-blue-600">
        <Link to={link}>{name}</Link>
      </li>
    );
  } else if (type === "ICON") {
    return (
      <li key={id} className="h-14">
        <Link to={link}>{name}</Link>
      </li>
    );
  } else if (type === "SELECT") {
    return (
      <li key={id} className="h-14">
        <InputGroup
          type="select"
          optionList={optionList}
          onChange={onChange}
          value={value}
        />
      </li>
    );
  } else if (type === "IMAGE") {
    return (
      <li key={id} className="h-14">
        <button className="h-full" onClick={onClick}>
          {name}
        </button>
      </li>
    );
  } else if (type === "IMAGE_DROPDOWN") {
    return (
      <li className={`h-14 flex flex-col items-center justify-center`}>
        <HoverViewButton
          title={name}
          src={faviconUrl}
          alt="favicon"
          size="large"
          optionList={optionList}
          optionSize="small"
        />
      </li>
    );
  } else if (type === "TEXT") {
    return (
      <li key={id} className="py-2 px-4 m-auto">
        <Link to={link}>{name}</Link>
      </li>
    );
  } else if (type === "SUBTEXT") {
    return (
      <li
        key={id}
        className={`py-2 px-4 m-auto cursor-pointer ${mainClassNames}`}
        onClick={onClick}
      >
        <div className="flex flex-row items-center gap-x-2">
          {faviconUrl ? (
            <div className="w-10">
              <img src={faviconUrl} className="w-full" />
            </div>
          ) : null}

          <div className="flex-1">
            <p className="">{name}</p>
            <p className="text-base">{subText}</p>
          </div>
        </div>
      </li>
    );
  } else if (type === "SUBTEXT_DROPDOWN") {
    return (
      <li className={`h-14 flex flex-col items-center justify-center`}>
        <HoverViewButton
          title={name}
          src={faviconUrl}
          alt="favicon"
          size="large"
          optionList={optionList}
          optionSize="medium"
        />
      </li>
    );
  } else if (type === "BUTTON") {
    return (
      <li key={id} className="h-14" onClick={onClick}>
        <Link to={link}>{name}</Link>
      </li>
    );
  }
};

export default ClickableHeaderItem;
