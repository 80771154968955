import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import { useApp } from "../../context/AppContextProvider";
import ListAndUpsertStorageFile from "../../components/storage-file/ListAndUpsertStorageFile";
import i18n from "../../language/i18n";

const AnnouncementDocumentListScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("storage_file_list")) {
      navigate("/permission-error");
    }
  }, []);

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">
        <ListAndUpsertStorageFile
          title={i18n.t("announcement_documents")}
          description={i18n.t(
            "announcement_document_list_table_description_text"
          )}
          type={"personal"}
          showLabelList={true}
          labelable={true}
          initialFilterApplied={false}
          ownershipType={"NOTIFICATION_DOCUMENT"}
          optionFilter={"ACTIVITY"}
        />
      </div>
    </div>
  );
};

export default AnnouncementDocumentListScreen;
