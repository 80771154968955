import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FullCard from "../../components/cards/FullCard";
import RenderInformationCardItem from "../../components/cards/InformationCard";
import { useApp } from "../../context/AppContextProvider";
import i18n from "../../language/i18n";
import {
  retrieveCatchxamApplicationInDetail,
  evaluateCatchxamApplicationById,
  retrieveActivityWithDataById,
  checkUnevaluatedExists,
} from "../../requests/CatchtivityRequests";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import { useAuth } from "../../context/UserContextProvider";
import InputGroup from "../../components/groups/InputGroup";
import { Tooltip } from "react-tooltip";
import { retrieveLearnerCatchxamReportDetailsByParams } from "../../requests/ReportRequests";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import { retrieveColorByScore } from "../../utilization/CatchtivityUtilization";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { querySeasonListByInstitutionId } from "../../requests/ManagementRequests";
import SelectionTab from "../../components/tabs/SelectionTab";
import ActivityTemplateLabel from "../../components/label/ActivityTemplateLabel";
import BaseLoading from "../../components/loading/BaseLoading";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const CatchxamApplicationDetailScreen = () => {
  const { catchxamApplicationId } = useParams();
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userProfileInstitution,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
  } = useAuth();
  const [catchxamApplicationDetail, setCatchxamApplicationDetail] =
    useState(null);
  const [selectedInstitutionId, setSelectedInstitutionId] =
    useState("DEFAULT_OPTION");
  const [selectedSeasonId, setSelectedSeasonId] = useState("DEFAULT_OPTION");
  const [selectedGradeId, setSelectedGradeId] = useState("DEFAULT_OPTION");
  const [selectedBranchId, setSelectedBranchId] = useState("DEFAULT_OPTION");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserProfileId, setSelectedUserProfileId] = useState(null);
  const [learnerCatchxamReportLoading, setLearnerCatchxamReportLoading] =
    useState(false);
  const [learnerCatchxamReport, setLearnerCatchxamReport] = useState(null);
  const [unevaluatedExistsLoading, setUnevaluatedExistsLoading] =
    useState(false);
  const [unevaluatedExists, setUnevaluatedExists] = useState(null);
  const [selectedInformationState, setSelectedInformationState] =
    useState("LEARNER");

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (catchxamApplicationId) {
      retrieveAndSetCatchxamApplicationInDetail();
    }
  }, [catchxamApplicationId]);

  useEffect(() => {
    if (userProfileBranch) {
      setSelectedInstitutionId(
        userProfileBranch.gradeDTO.seasonDTO.institutionDTO.id
      );
      setSelectedSeasonId(userProfileBranch.gradeDTO.seasonDTO.id);
      setSelectedGradeId(userProfileBranch.gradeDTO.id);
      setSelectedBranchId(userProfileBranch.id);
    } else if (userProfileGrade) {
      setSelectedInstitutionId(userProfileGrade.seasonDTO.institutionDTO.id);
      setSelectedSeasonId(userProfileGrade.seasonDTO.id);
      setSelectedGradeId(userProfileGrade.id);
    } else if (userProfileSeason) {
      setSelectedInstitutionId(userProfileSeason.institutionDTO.id);
      setSelectedSeasonId(userProfileSeason.id);
    } else if (userProfileInstitution) {
      setSelectedInstitutionId(userProfileInstitution.id);
    } else {
      const filteredApplicationSet =
        retrieveInstitutionFilteredApplicationSet();
      if (filteredApplicationSet.length === 1) {
        setSelectedInstitutionId(filteredApplicationSet[0].institutionId);
      }
    }
  }, [catchxamApplicationDetail]);

  useEffect(() => {
    if (selectedInstitutionId !== "DEFAULT_OPTION") {
      if (retrieveSeasonDTOOptionList().length > 0) {
        setSelectedSeasonId(retrieveSeasonDTOOptionList()[0].value);
      }
    }
  }, [catchxamApplicationDetail, selectedInstitutionId]);

  useEffect(() => {
    if (selectedSeasonId !== "DEFAULT_OPTION") {
      if (retrieveGradeDTOOptionList().length > 0) {
        setSelectedGradeId(retrieveGradeDTOOptionList()[0].value);
      }
    }
  }, [catchxamApplicationDetail, selectedSeasonId]);

  useEffect(() => {
    if (selectedGradeId !== "DEFAULT_OPTION") {
      if (retrieveBranchDTOOptionList().length > 0) {
        setSelectedBranchId(retrieveBranchDTOOptionList()[0].value);
      }
    }
  }, [catchxamApplicationDetail, selectedGradeId]);

  useEffect(() => {
    const retrieveAndSetCatchxamReportDTO = async () => {
      setLearnerCatchxamReportLoading(true);
      const { data, err } = await retrieveLearnerCatchxamReportDetailsByParams({
        userId: selectedUserId,
        userProfileId: selectedUserProfileId,
        catchxamApplicationId,
      });
      if (err) {
        console.log(err);
      } else {
        setLearnerCatchxamReport(data);
      }
      setLearnerCatchxamReportLoading(false);
    };
    if (selectedUserId && selectedUserProfileId) {
      retrieveAndSetCatchxamReportDTO();
    }
  }, [selectedUserId, selectedUserProfileId]);

  useEffect(() => {
    const constructUnevaluatedExistsDTOMap = async () => {
      let currentUserProfileSeason = userProfileSeason;
      if (!userProfileBranch && !userProfileGrade && !userProfileSeason) {
        const { data, err } = await querySeasonListByInstitutionId(
          selectedInstitutionId
        );
        if (err) {
          console.log(err);
          return;
        }
        if (data.length === 0) return;
        const filteredSeasonDTOList = data.filter(
          (seasonDTO) =>
            seasonDTO.beginAt < new Date().getTime() &&
            seasonDTO.endAt > new Date().getTime()
        );
        if (filteredSeasonDTOList.length === 0) {
          const sortedSeasonDTOList = data.sort((a, b) => a.endAt > b.endAt);
          currentUserProfileSeason = sortedSeasonDTOList[0];
        } else {
          const foundSeasonDTO = filteredSeasonDTOList[0];
          currentUserProfileSeason = foundSeasonDTO;
        }
      }
      const { catchxamApplicationDTO } = catchxamApplicationDetail;
      const { endAt, catchxamDTO, id } = catchxamApplicationDTO;
      const { coterieType, level } = catchxamDTO;
      const { data, err } = await checkUnevaluatedExists({
        activityTemplateId: 295,
        endAt,
        seasonId: currentUserProfileSeason.id,
        gradeId: userProfileGrade?.id,
        branchId: userProfileBranch?.id,
        coterieType,
        level,
      });
      if (err) {
        console.log(err);
        return;
      }
      setUnevaluatedExists(data);
      setUnevaluatedExistsLoading(false);
    };
    if (selectedInstitutionId === "DEFAULT_OPTION") return;
    constructUnevaluatedExistsDTOMap();
  }, [selectedInstitutionId]);

  const retrieveAndSetCatchxamApplicationInDetail = async () => {
    const { data, err } = await retrieveCatchxamApplicationInDetail(
      catchxamApplicationId
    );
    if (err) {
      console.log(err);
      return;
    }
    setCatchxamApplicationDetail(data);
  };

  const filterAvailableApplicationUserDetailList = () => {
    let filteredApplicationList = [];
    if (catchxamApplicationDetail) {
      if (userProfileBranch) {
        filteredApplicationList =
          catchxamApplicationDetail.catchxamUserDTOList.filter(
            (catchxamUserDTO) =>
              parseFloat(catchxamUserDTO.branchId) ===
              parseFloat(userProfileBranch.id)
          );
      } else if (userProfileGrade) {
        filteredApplicationList =
          catchxamApplicationDetail.catchxamUserDTOList.filter(
            (catchxamUserDTO) =>
              parseFloat(catchxamUserDTO.gradeId) ===
              parseFloat(userProfileGrade.id)
          );
      } else if (userProfileSeason) {
        filteredApplicationList =
          catchxamApplicationDetail.catchxamUserDTOList.filter(
            (catchxamUserDTO) =>
              parseFloat(catchxamUserDTO.seasonId) ===
              parseFloat(userProfileSeason.id)
          );
      } else if (userProfileInstitution) {
        filteredApplicationList =
          catchxamApplicationDetail.catchxamUserDTOList.filter(
            (catchxamUserDTO) =>
              parseFloat(catchxamUserDTO.institutionId) ===
              parseFloat(userProfileInstitution.id)
          );
      } else {
        return catchxamApplicationDetail.catchxamUserDTOList;
      }
    }
    return filteredApplicationList;
  };

  const filterSelectedApplicationUserDetailList = () => {
    if (catchxamApplicationDetail) {
      return catchxamApplicationDetail.catchxamUserDTOList.filter(
        (catchxamUserDTO) =>
          parseFloat(catchxamUserDTO.branchId) ===
            parseFloat(selectedBranchId) &&
          parseFloat(catchxamUserDTO.gradeId) === parseFloat(selectedGradeId) &&
          parseFloat(catchxamUserDTO.seasonId) ===
            parseFloat(selectedSeasonId) &&
          parseFloat(catchxamUserDTO.institutionId) ===
            parseFloat(selectedInstitutionId)
      );
    }
    return [];
  };

  const retrieveInstitutionFilteredApplicationSet = () => {
    const filteredApplicationSet = [];
    filterAvailableApplicationUserDetailList().forEach((filterApplication) => {
      if (
        filteredApplicationSet.findIndex(
          (newFilterApplication) =>
            parseFloat(newFilterApplication.institutionId) ===
            parseFloat(filterApplication.institutionId)
        ) === -1
      ) {
        filteredApplicationSet.push(filterApplication);
      }
    });
    return filteredApplicationSet;
  };

  const retrieveInstitutionDTOOptionList = () => {
    return retrieveInstitutionFilteredApplicationSet().map(
      (catchxamUserDTO) => ({
        value: catchxamUserDTO.institutionId,
        text: catchxamUserDTO.institutionName,
      })
    );
  };

  const retrieveSeasonDTOOptionList = () => {
    const filteredApplicationSet = [];
    filterAvailableApplicationUserDetailList()
      .filter(
        (catchxamUserDTO) =>
          parseFloat(catchxamUserDTO.institutionId) ===
          parseFloat(selectedInstitutionId)
      )
      .forEach((filterApplication) => {
        if (
          filteredApplicationSet.findIndex(
            (newFilterApplication) =>
              parseFloat(newFilterApplication.seasonId) ===
              parseFloat(filterApplication.seasonId)
          ) === -1
        ) {
          filteredApplicationSet.push(filterApplication);
        }
      });

    return filteredApplicationSet.map((catchxamUserDTO) => ({
      value: catchxamUserDTO.seasonId,
      text: catchxamUserDTO.seasonName,
    }));
  };

  const retrieveGradeDTOOptionList = () => {
    const filteredApplicationSet = [];
    filterAvailableApplicationUserDetailList()
      .filter(
        (catchxamUserDTO) =>
          parseFloat(catchxamUserDTO.seasonId) === parseFloat(selectedSeasonId)
      )
      .forEach((filterApplication) => {
        if (
          filteredApplicationSet.findIndex(
            (newFilterApplication) =>
              parseFloat(newFilterApplication.gradeId) ===
              parseFloat(filterApplication.gradeId)
          ) === -1
        ) {
          filteredApplicationSet.push(filterApplication);
        }
      });

    return filteredApplicationSet.map((catchxamUserDTO) => ({
      value: catchxamUserDTO.gradeId,
      text: catchxamUserDTO.gradeName,
    }));
  };

  const retrieveBranchDTOOptionList = () => {
    const filteredApplicationSet = [];
    filterAvailableApplicationUserDetailList()
      .filter(
        (catchxamUserDTO) =>
          parseFloat(catchxamUserDTO.gradeId) === parseFloat(selectedGradeId)
      )
      .forEach((filterApplication) => {
        if (
          filteredApplicationSet.findIndex(
            (newFilterApplication) =>
              parseFloat(newFilterApplication.branchId) ===
              parseFloat(filterApplication.branchId)
          ) === -1
        ) {
          filteredApplicationSet.push(filterApplication);
        }
      });

    return filteredApplicationSet.map((catchxamUserDTO) => ({
      value: catchxamUserDTO.branchId,
      text: catchxamUserDTO.branchName,
    }));
  };

  const findStarStudent = () => {
    const sortedCatchxamUserDTOList = JSON.parse(
      JSON.stringify(applicationUserDetailList)
    ).sort((a, b) => b.score - a.score);
    if (sortedCatchxamUserDTOList.length > 0) {
      const highestScore = sortedCatchxamUserDTOList[0].score;
      if (highestScore === 0) {
        return "-";
      } else {
        const filteredCatchxamUserDTOList = sortedCatchxamUserDTOList.filter(
          (catchxamUserDTO) => catchxamUserDTO.score === highestScore
        );
        filteredCatchxamUserDTOList.sort((a, b) => a.timeSpent - b.timeSpent);
        return `${filteredCatchxamUserDTOList[0].firstName} ${filteredCatchxamUserDTOList[0].lastName}`;
      }
    }
    return "-";
  };

  const calculateAverageTimeSpent = () => {
    let currentTimeSpent = 0;
    let currentCount = 0;
    if (catchxamApplicationDetail) {
      applicationUserDetailList
        .filter(
          (catchxamUserDTO) =>
            catchxamUserDTO.completionStatus !== "NOT_STARTED"
        )
        .forEach((catchxamUserDTO) => {
          currentTimeSpent += catchxamUserDTO.timeSpent;
          currentCount++;
        });
    }
    return currentCount === 0
      ? 0
      : `${(currentTimeSpent / currentCount / 1000 / 60).toFixed(2)}`;
  };

  const calculateCompletedLearnerCount = () => {
    let notStartedCount = 0;
    if (catchxamApplicationDetail) {
      notStartedCount = applicationUserDetailList.filter(
        (catchxamUserDTO) => catchxamUserDTO.completionStatus === "COMPLETED"
      ).length;
    }
    return notStartedCount;
  };

  const calculateNotStartedLearnerCount = () => {
    let notStartedCount = 0;
    if (catchxamApplicationDetail) {
      notStartedCount = applicationUserDetailList.filter(
        (catchxamUserDTO) => catchxamUserDTO.completionStatus === "NOT_STARTED"
      ).length;
    }
    return notStartedCount;
  };

  const calculateActivityCount = () => {
    let activityCount = 0;
    if (catchxamApplicationDetail) {
      activityCount =
        catchxamApplicationDetail.catchxamApplicationDTO.catchxamDTO
          .activityDTOList.length;
    }
    return activityCount;
  };

  const calculateActivityTemplateCount = () => {
    let activityTemplateCount = 0;
    if (catchxamApplicationDetail) {
      catchxamApplicationDetail.catchxamApplicationDTO.catchxamDTO.activityDTOList.forEach(
        (activityDTO) => {
          activityTemplateCount += activityDTO.activityTemplateDTOList.length;
        }
      );
    }
    return activityTemplateCount;
  };

  const calculateScoreMoreThan = () => {
    return applicationUserDetailList.filter(
      (catchxamUserDTO) => calculateUserPerformance(catchxamUserDTO) >= 80
    ).length;
  };

  const calculateUserPerformance = (catchxamUserDTO) => {
    const totalLength =
      catchxamApplicationDetail.catchxamApplicationDTO.catchxamDTO
        .activityDTOList.length;
    if (totalLength === 0) return 0;
    return (catchxamUserDTO.score / totalLength) * 100;
  };

  const calculateAverageScore = () => {
    const filteredCatchxamUserDTOList = applicationUserDetailList.filter(
      (catchxamUserDTO) => catchxamUserDTO.completionStatus !== "NOT_STARTED"
    );
    let totalScore = 0;
    filteredCatchxamUserDTOList.forEach((catchxamUserDTO) => {
      totalScore += calculateUserPerformance(catchxamUserDTO);
    });
    if (filteredCatchxamUserDTOList.length === 0) {
      return 0;
    } else {
      return totalScore / filteredCatchxamUserDTOList.length;
    }
  };

  const constructActivityActivityTemplateStaticticsList = () => {
    const activityStatistics = [];
    for (const catchxamUserDTO of applicationUserDetailList) {
      const { firstName, lastName, activityTemplateScoreDTOList } =
        catchxamUserDTO;
      for (const activityTemplateScoreDTO of activityTemplateScoreDTOList) {
        const foundActivityActivityTemplateStatictics = activityStatistics.find(
          (activityActivityTemplateStatictics) =>
            activityActivityTemplateStatictics.id ===
            activityTemplateScoreDTO.activityId
        );
        if (foundActivityActivityTemplateStatictics) {
          const { templateList } = foundActivityActivityTemplateStatictics;
          const foundTemplate = templateList.find(
            (template) =>
              template.id === activityTemplateScoreDTO.activityTemplateId
          );
          if (foundTemplate) {
            foundTemplate.score += activityTemplateScoreDTO.score;
            foundTemplate.count += 1;
            foundTemplate.userList.push({
              firstName,
              lastName,
              score: activityTemplateScoreDTO.score,
            });
          } else {
            templateList.push({
              id: activityTemplateScoreDTO.activityTemplateId,
              name: activityTemplateScoreDTO.activityTemplateName,
              score: activityTemplateScoreDTO.score,
              isEmpty: activityTemplateScoreDTO.isEmpty,
              userList: [
                {
                  firstName,
                  lastName,
                  score: activityTemplateScoreDTO.score,
                },
              ],
              count: 1,
            });
          }
          foundActivityActivityTemplateStatictics.score +=
            activityTemplateScoreDTO.score;
          foundActivityActivityTemplateStatictics.count += 1;
        } else {
          activityStatistics.push({
            id: activityTemplateScoreDTO.activityId,
            name: activityTemplateScoreDTO.activityName,
            templateList: [
              {
                id: activityTemplateScoreDTO.activityTemplateId,
                name: activityTemplateScoreDTO.activityTemplateName,
                score: activityTemplateScoreDTO.score,
                isEmpty: activityTemplateScoreDTO.isEmpty,
                userList: [
                  {
                    firstName,
                    lastName,
                    score: activityTemplateScoreDTO.score,
                  },
                ],
                count: 1,
              },
            ],
            score: activityTemplateScoreDTO.score,
            count: 1,
          });
        }
      }
    }
    for (const activityStaticticsItem of activityStatistics) {
      const { templateList } = activityStaticticsItem;
      activityStaticticsItem.score /= activityStaticticsItem.count;
      for (const template of templateList) {
        template.score /= template.count;
        template.userList.sort((a, b) => a.score - b.score);
      }
      activityStaticticsItem.templateList =
        activityStaticticsItem.templateList.sort((a, b) => a.score - b.score);
    }

    return activityStatistics.sort((a, b) => a.score - b.score);
  };

  const handleOnChange = (type, value) => {
    if (type === "institutionId") {
      setSelectedInstitutionId(value);
      setSelectedSeasonId("DEFAULT_OPTION");
      setSelectedGradeId("DEFAULT_OPTION");
      setSelectedBranchId("DEFAULT_OPTION");
    } else if (type === "seasonId") {
      setSelectedSeasonId(value);
      setSelectedGradeId("DEFAULT_OPTION");
      setSelectedBranchId("DEFAULT_OPTION");
    } else if (type === "gradeId") {
      setSelectedGradeId(value);
      setSelectedBranchId("DEFAULT_OPTION");
    } else if (type === "branchId") {
      setSelectedBranchId(value);
    }
  };

  const handleEvaluateCatchxamApplicationOnClick = async (id) => {
    const { data, err } = await evaluateCatchxamApplicationById(id);
    if (err) {
      console.log(err);
    } else {
      if (data === "EVALUATED") {
        retrieveAndSetCatchxamApplicationInDetail();
      }
    }
  };

  const handlePreviewActivityOnClick = (activityDTO) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    window.open(
      `${connection.app.url}/preview/activity/${activityDTO.id}/${btoa(
        encodeURI(JSON.stringify({ token: accessToken }))
      )}`
    );
  };

  const RenderRecursivelyCategoryList = (
    categoryActivityAnswerDTO,
    recursiveCount
  ) => {
    let score = 0;
    for (const activityScoreDTO of categoryActivityAnswerDTO.activityScoreDTOSet) {
      score += activityScoreDTO.score;
    }
    return (
      <div className="flex flex-col">
        <div
          className="flex-1 flex flex-row bg-catchup-dark-blue text-catchup-white border-b border-b-catchup-white"
          style={{
            paddingLeft: recursiveCount * 20,
            opacity: 1 - 0.05 * recursiveCount,
          }}
        >
          <div className="flex-1">
            <p className="p-2">{categoryActivityAnswerDTO.categoryDTO.name}</p>
          </div>
          <div
            className="flex justify-center items-center w-[75px] bg-catchup-white text-catchup-dark-blue border-r border-b border-catchup-dark-blue"
            style={{
              fontWeight: "bold",
            }}
          >
            <p className="p-2">
              {categoryActivityAnswerDTO.activityScoreDTOSet.length}
            </p>
          </div>
          <div
            className="flex justify-center items-center w-[75px] bg-catchup-white text-catchup-dark-blue border-r border-b border-catchup-dark-blue text-center"
            style={{
              fontWeight: "bold",
            }}
          >
            <p className="p-2">{score}</p>
          </div>
          <div
            className="flex justify-center items-center w-[100px] bg-catchup-white text-catchup-dark-blue border-r border-b border-catchup-dark-blue text-center"
            style={{
              fontWeight: "bold",
            }}
          >
            <p className="p-2">
              {(
                (score / categoryActivityAnswerDTO.activityScoreDTOSet.length) *
                100
              ).toFixed(2)}
              %
            </p>
          </div>
        </div>
        {categoryActivityAnswerDTO.subCategoryActivityAnswerDTOList &&
        categoryActivityAnswerDTO.subCategoryActivityAnswerDTOList.length > 0
          ? categoryActivityAnswerDTO.subCategoryActivityAnswerDTOList.map(
              (subCategoryActivityAnswerDTO) =>
                RenderRecursivelyCategoryList(
                  subCategoryActivityAnswerDTO,
                  recursiveCount + 1
                )
            )
          : null}
      </div>
    );
  };

  const RenderLearnerCatchxamReport = () => {
    if (learnerCatchxamReportLoading) {
      return (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      );
    } else {
      if (learnerCatchxamReport) {
        return (
          <>
            <DividerLine />
            <div className="w-full flex flex-col my-5 text-sm">
              {learnerCatchxamReport.categoryActivityAnswerDTOList.map(
                (categoryActivityAnswerDTO) => (
                  <>
                    <div className="flex flex-col">
                      <div className="flex-1 flex flex-row bg-catchup-white font-semibold">
                        <div className="flex-1"></div>
                        <div className="w-[75px] bg-catchup-dark-blue text-catchup-white border-r border-r-catchup-white text-center">
                          <p className="p-2">#</p>
                        </div>
                        <div className="w-[75px] bg-catchup-dark-blue text-catchup-white border-r border-r-catchup-white text-center">
                          <p className="p-2">✓</p>
                        </div>
                        <div className="w-[100px] bg-catchup-dark-blue text-catchup-white border-r border-r-catchup-dark-blue text-center">
                          <p className="p-2">%</p>
                        </div>
                      </div>
                    </div>
                    {RenderRecursivelyCategoryList(
                      categoryActivityAnswerDTO,
                      0
                    )}
                  </>
                )
              )}
            </div>
          </>
        );
      }
    }

    return null;
  };

  const RenderDetailedUserUserProfile = () => {
    const foundUserUserProfile = applicationUserDetailList.find(
      (catchxamUserDTO) =>
        parseFloat(catchxamUserDTO.userId) === selectedUserId &&
        parseFloat(catchxamUserDTO.userProfileId) === selectedUserProfileId
    );
    if (!foundUserUserProfile) return;
    const {
      firstName,
      lastName,
      branchName,
      gradeName,
      seasonName,
      institutionName,
      answeredActivityCount,
      correctActivityCount,
      timeSpent,
      activityTemplateScoreDTOList,
    } = foundUserUserProfile;
    const { catchxamApplicationDTO } = catchxamApplicationDetail;
    const { catchxamDTO, isEvaluated } = catchxamApplicationDTO;
    const { activityDTOList } = catchxamDTO;
    const activityTemplateScoreIdList = activityTemplateScoreDTOList.map(
      (activityTemplateScore) => activityTemplateScore.activityId
    );
    const notAnsweredActivityList = activityDTOList.filter(
      (activity) => !activityTemplateScoreIdList.includes(activity.id)
    );

    return (
      <div className="border border-catchup-blue-500 rounded-catchup-xlarge p-5">
        <div className="flex flex-row justify-between flex-wrap my-2 text-lg px-4">
          <div className="flex-1 flex flex-col">
            <div>
              <p className="font-semibold text-2xl">
                {firstName} {lastName}
              </p>
            </div>
            <div className="my-1 text-catchup-gray-400">
              <div className="flex flex-row flex-wrap gap-x-2 items-center">
                <p className="">{branchName}</p>
                <img
                  className="w-1 h-1"
                  src="/icons/small-dot-gray.png"
                  alt="small-dot-gray"
                />
                <p className="">{gradeName}</p>
                <img
                  className="w-1 h-1"
                  src="/icons/small-dot-gray.png"
                  alt="small-dot-gray"
                />
                <p className="">{seasonName}</p>
                <img
                  className="w-1 h-1"
                  src="/icons/small-dot-gray.png"
                  alt="small-dot-gray"
                />
                <p className="">{institutionName}</p>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-row flex-wrap justify-end items-center gap-x-5">
            {isEvaluated ? (
              <>
                <div>
                  <p>
                    {i18n.t("answered_activity_count")}:{" "}
                    <span className="font-bold">{answeredActivityCount}</span>
                  </p>
                </div>
                <div>
                  <p>
                    {i18n.t("correct_activity_count")}:{" "}
                    <span className="font-bold">{correctActivityCount}</span>
                  </p>
                </div>
                <div>
                  <p>
                    {i18n.t("time_spent")}:{" "}
                    <span className="font-bold">{`${(
                      timeSpent /
                      1000 /
                      60
                    ).toFixed(2)} ${i18n.t("minute_abbrevation")}`}</span>
                  </p>
                </div>
              </>
            ) : null}
          </div>
        </div>
        {isEvaluated ? <DividerLine /> : null}
        {isEvaluated ? (
          <div className="flex-1 flex flex-col my-3">
            {activityTemplateScoreDTOList.length > 0 ? (
              <>
                <div className="flex flex-row flex-wrap">
                  {activityTemplateScoreDTOList.map(
                    (activityActivityTemplateScoreDTO, index) => {
                      const foundAnswer = JSON.parse(
                        activityActivityTemplateScoreDTO.data
                      ).find((answerData) => answerData.type === "MCSA");
                      let correctAnswer;
                      if (foundAnswer) {
                        correctAnswer = Object.keys(foundAnswer.answerMap)[0];
                      }
                      return (
                        <div
                          className={`flex flex-col my-1 ${
                            activityActivityTemplateScoreDTO.isEmpty
                              ? "border border-dashed rounded-catchup-xlarge border-catchup-orange py-1"
                              : ""
                          } px-2 mx-2`}
                          key={index}
                        >
                          <div className="flex flex-col justify-center items-center">
                            <div className="my-1">
                              <p>{`${index + 1}`}</p>
                            </div>
                            {activityActivityTemplateScoreDTO.score === 1 ? (
                              <div className="my-1">
                                <BaseImage
                                  size="small"
                                  src="/icons/checkbox.png"
                                  alt="checkbox"
                                />
                              </div>
                            ) : activityActivityTemplateScoreDTO.isEmpty ? (
                              <div className="my-1">
                                <BaseImage
                                  size="small"
                                  src="/icons/checkbox-empty.png"
                                  alt="checkbox"
                                />
                              </div>
                            ) : (
                              <div className="my-1">
                                {/* <Tooltip id="correct-incorrect-answer" /> */}
                                <div className="">
                                  <BaseImage
                                    size="small"
                                    src="/icons/cross-red.png"
                                    alt="info"
                                  />
                                </div>
                              </div>
                            )}
                            <div className="my-1">
                              <BaseImage
                                src="/icons/preview.png"
                                alt="preview"
                                size="small"
                                onClick={async () => {
                                  const { data, err } =
                                    await retrieveActivityWithDataById(
                                      activityActivityTemplateScoreDTO.activityId
                                    );
                                  if (err) {
                                  } else {
                                    const dataWithAnswer = {
                                      ...JSON.parse(data.data),
                                      answerMap: JSON.parse(
                                        activityActivityTemplateScoreDTO.data
                                      ),
                                    };
                                    window.open(
                                      `${
                                        connection.app.url
                                      }/preview/activity/by-learner-data/${btoa(
                                        encodeURI(
                                          JSON.stringify(dataWithAnswer)
                                        )
                                      ).replaceAll("/", "CATCHUPPER_SLASH")}`
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </>
            ) : null}
            {notAnsweredActivityList.length > 0 ? (
              <>
                <div className="my-2">
                  <p className="font-semibold text-lg">
                    {i18n.t("not_answered_learner_answers")}
                  </p>
                </div>
                <div className="flex flex-row flex-wrap">
                  {notAnsweredActivityList.map((activity, index) => (
                    <div
                      className={`flex flex-col my-2 px-2 opacity-50`}
                      key={index}
                    >
                      <div className="flex flex-col justify-center items-center">
                        <div className="my-1">
                          <p>-</p>
                        </div>
                        <div className="my-1">
                          <BaseImage
                            src="/icons/exclamation-red.png"
                            alt="exclamation"
                            size="small"
                          />
                        </div>
                        <div className="my-1">
                          <BaseImage
                            src="/icons/preview.png"
                            alt="preview"
                            size="small"
                            onClick={(e) => {
                              handlePreviewActivityOnClick(activity);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
            {RenderLearnerCatchxamReport()}
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <p className="font-semibold text-catchup-red">
              {i18n.t("not_evaluated_yet")}
            </p>
            <p className="text-catchup-red italic text-md">
              {i18n.t("not_evaluated_yet_description")}
            </p>
          </div>
        )}
      </div>
    );
  };

  const RenderMainContent = () => {
    if (catchxamApplicationDetail) {
      const { catchxamApplicationDTO } = catchxamApplicationDetail;
      const { id, catchxamDTO, endAt, isEvaluated } = catchxamApplicationDTO;

      return (
        <FullCard isShadowed={true}>
          <div className="">
            <div className="flex flex-row justify-between items-center gap-x-4">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      navigate("/catchxams/applications");
                    }}
                  />
                </div>
                <BaseTitle title={catchxamDTO.name} />
              </div>
              {endAt > new Date().getTime() ? (
                <div className="flex flex-col items-end">
                  <p className="font-semibold text-catchup-red">
                    {i18n.t("not_evaluated_yet")}
                  </p>
                  <p className="text-catchup-red italic text-md">
                    {i18n.t("not_evaluated_yet_description")}
                  </p>
                </div>
              ) : isEvaluated ? null : unevaluatedExistsLoading ? (
                <BaseLoading
                  size="small"
                  color="#57C2D3"
                  secondaryColor="#57C2D3"
                />
              ) : unevaluatedExists && unevaluatedExists.length > 0 ? (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(
                      `/catchtivities/applications/${unevaluatedExists[0].catchtivityApplicationId}/detail`
                    );
                  }}
                >
                  <div className="italic">
                    <p>
                      {i18n.t("requirements_to_evaluate_catchxam_text")}{" "}
                      <span className="underline text-catchup-red">
                        {i18n.t(
                          "number_of_unevaluated_open_ended_activity_count"
                        )}
                        : {unevaluatedExists.length}
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <PrimaryButton
                    title={i18n.t("evaluate")}
                    size="small"
                    onClick={async () => {
                      await handleEvaluateCatchxamApplicationOnClick(id);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <DividerLine />
          <div className="">
            <p className="font-semibold text-lg">
              {i18n.t("branch_selection")}
            </p>
          </div>

          <div className="flex flex-row flex-wrap items-center mb-3">
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("institution_name")}
                  placeholder={i18n.t("institution_name")}
                  value={selectedInstitutionId}
                  optionList={retrieveInstitutionDTOOptionList()}
                  onChange={(event) => {
                    handleOnChange("institutionId", event.target.value);
                  }}
                />
              </div>
            </div>
            {selectedInstitutionId !== "DEFAULT_OPTION" ? (
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("season_name")}
                    placeholder={i18n.t("season_name")}
                    value={selectedSeasonId}
                    optionList={retrieveSeasonDTOOptionList()}
                    onChange={(event) => {
                      handleOnChange("seasonId", event.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}

            {selectedSeasonId !== "DEFAULT_OPTION" ? (
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("grade_name")}
                    placeholder={i18n.t("grade_name")}
                    value={selectedGradeId}
                    optionList={retrieveGradeDTOOptionList()}
                    onChange={(event) => {
                      handleOnChange("gradeId", event.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}

            {selectedGradeId !== "DEFAULT_OPTION" ? (
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("branch_name")}
                    placeholder={i18n.t("branch_name")}
                    value={selectedBranchId}
                    optionList={retrieveBranchDTOOptionList()}
                    onChange={(event) => {
                      handleOnChange("branchId", event.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="">
            <p className="font-semibold text-lg">
              {i18n.t("general_overview")}
            </p>
          </div>
          <div className="flex flex-row items-center flex-wrap">
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderInformationCardItem(
                i18n.t("activity_count"),
                null,
                <BaseImage
                  src="/icons/activity.png"
                  alt="activity"
                  size="small"
                />,
                calculateActivityCount(),
                1
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderInformationCardItem(
                i18n.t("activity_template_count"),
                null,
                <BaseImage
                  src="/icons/activity-template.png"
                  alt="activity-template"
                  size="small"
                />,
                calculateActivityTemplateCount(),
                2
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderInformationCardItem(
                i18n.t("completed_learner_count"),
                null,
                <BaseImage
                  src="/icons/user-profile-check.png"
                  alt="user-profile-check"
                  size="small"
                />,
                calculateCompletedLearnerCount(),
                3
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderInformationCardItem(
                i18n.t("not_started_learner_count"),
                null,
                <BaseImage
                  src="/icons/user-profile-exclamation.png"
                  alt="user-profile-exclamation"
                  size="small"
                />,
                calculateNotStartedLearnerCount(),
                4
              )}
            </div>
            {isEvaluated ? (
              <>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("star_learner"),
                    null,
                    <BaseImage src="/icons/star.png" alt="star" size="small" />,
                    findStarStudent(),
                    1
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("high_performance_learner_count"),
                    null,
                    <BaseImage
                      src="/icons/learner.png"
                      alt="learner"
                      size="small"
                    />,
                    calculateScoreMoreThan(),
                    2
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("average_time_spent"),
                    null,
                    <BaseImage
                      src="/icons/average-time-spent.png"
                      alt="average-time-spent"
                      size="small"
                    />,
                    `${calculateAverageTimeSpent()} ${i18n.t(
                      "minute_abbrevation"
                    )}`,
                    3
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("average_performance"),
                    null,
                    <BaseImage
                      src="/icons/average-performance.png"
                      alt="average-performance"
                      size="small"
                    />,
                    `${calculateAverageScore().toFixed(2)}%`,
                    4
                  )}
                </div>
              </>
            ) : null}
          </div>
          {isEvaluated ? (
            <div className="w-full mt-5">
              <SelectionTab
                optionList={[
                  {
                    id: "LEARNER",
                    title: i18n.t("learners"),
                  },
                  {
                    id: "ACTIVITY_STATISTICS",
                    title: i18n.t("activity_statistics"),
                  },
                ]}
                selectedId={selectedInformationState}
                handleSelectOnClick={(selectedId) => {
                  setSelectedInformationState(selectedId);
                }}
              />
            </div>
          ) : null}
          {selectedInformationState === "LEARNER" ? (
            <>
              <div className="flex flex-row items-start flex-wrap gap-x-3 mt-5">
                {applicationUserDetailList.map((catchxamUserDTO, index) => (
                  <div
                    key={index}
                    className={`${
                      selectedUserId === catchxamUserDTO.userId
                        ? "border-catchup-blue-800"
                        : "border-catchup-gray-50"
                    } relative w-[150px] lg:w-[200px] min-h-[220px] flex flex-col items-center justify-start my-3 p-3 cursor-pointer border rounded-catchup-xlarge`}
                    onClick={() => {
                      setSelectedUserId(catchxamUserDTO.userId);
                      setSelectedUserProfileId(catchxamUserDTO.userProfileId);
                    }}
                  >
                    <div
                      className={`absolute ${
                        catchxamUserDTO.completionStatus === "TIME_UP"
                          ? "bg-catchup-light-red border-catchup-red text-catchup-red"
                          : catchxamUserDTO.completionStatus === "NOT_STARTED"
                          ? "bg-catchup-light-red border-catchup-red text-catchup-red"
                          : catchxamUserDTO.completionStatus === "ON_GOING"
                          ? "bg-catchup-blue-50 border-catchup-blue-400 text-catchup-blue-400"
                          : catchxamUserDTO.completionStatus === "ON_GOING"
                          ? ""
                          : "bg-catchup-light-green border-catchup-green text-catchup-green"
                      } -top-4 bg-catchup-white border rounded-catchup-3xlarge px-4`}
                    >
                      <p className="text-center mt-1">
                        {i18n.t(catchxamUserDTO.completionStatus)}
                      </p>
                    </div>
                    <div className="mt-4 mb-2">
                      <BaseImage
                        size="xlarge"
                        src={
                          catchxamUserDTO.completionStatus === "TIME_UP"
                            ? "/icons/user-profile-time-up.png"
                            : catchxamUserDTO.completionStatus === "NOT_STARTED"
                            ? "/icons/user-profile-not-started.png"
                            : catchxamUserDTO.completionStatus === "ON_GOING"
                            ? "/icons/user-profile-started.png"
                            : catchxamUserDTO.completionStatus === "COMPLETED"
                            ? "/icons/user-profile-completed.png"
                            : null
                        }
                        alt="user-profile-completion-status"
                      />
                    </div>

                    <div className="my-2">
                      <p className="text-center font-semibold">
                        {catchxamUserDTO.firstName} {catchxamUserDTO.lastName}
                      </p>
                      <p className="text-center text-base text-catchup-gray-400">
                        {catchxamUserDTO.profileName}
                      </p>

                      {isEvaluated ? (
                        <>
                          <div className="flex flex-col justify-center items-center">
                            <div className="flex flex-row items-center gap-x-1">
                              <BaseImage
                                src="/icons/timer-gray.png"
                                alt="timer"
                                size="xsmall"
                              />
                              <div className="flex-1">
                                <p className="text-center">
                                  {`${(
                                    catchxamUserDTO.timeSpent /
                                    1000 /
                                    60
                                  ).toFixed(2)} ${i18n.t(
                                    "minute_abbrevation"
                                  )}`}
                                </p>
                              </div>
                            </div>
                            <DividerLine />
                            <div>
                              <p
                                className="text-center text-2xl"
                                style={{
                                  color: retrieveColorByScore(
                                    calculateUserPerformance(catchxamUserDTO)
                                  ),
                                }}
                              >
                                {catchxamUserDTO.score > 0
                                  ? `${calculateUserPerformance(
                                      catchxamUserDTO
                                    ).toFixed(2)}%`
                                  : "0.00%"}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
              {RenderDetailedUserUserProfile()}
            </>
          ) : selectedInformationState === "ACTIVITY_STATISTICS" ? (
            <div className="p-5">
              <div className="flex flex-row flex-wrap">
                {activityStatistics.map((activityStatisticsItem, index) => {
                  const { id, name, score, templateList } =
                    activityStatisticsItem;
                  return (
                    <div key={index} className="w-full xl:w-1/2">
                      <div className="mx-2">
                        <div className="flex flex-col px-4 py-2 my-2 text-lg">
                          <div className="flex flex-row justify-between">
                            <div className="flex flex-row items-center justify-start gap-x-2">
                              <div>
                                <div>
                                  <p className="">{name}</p>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-row items-center justify-center gap-x-1">
                              <div>
                                <p
                                  className=""
                                  style={{
                                    color: retrieveColorByScore(score * 100),
                                  }}
                                >
                                  {(score * 100).toFixed(2)}%
                                </p>
                              </div>
                              <div>
                                <BaseImage
                                  src="/icons/preview.png"
                                  alt="preview"
                                  size="small"
                                  onClick={() => {
                                    const accessToken =
                                      localStorage.getItem("accessToken");
                                    if (!accessToken) return;
                                    window.open(
                                      `${
                                        connection.app.url
                                      }/preview/activity/${id}/${btoa(
                                        encodeURI(
                                          JSON.stringify({ token: accessToken })
                                        )
                                      )}`
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-col mx-5 my-2 gap-y-1">
                            {templateList.map((template) => {
                              const { name, count, score, userList } = template;
                              let nameString = "";
                              for (const user of userList) {
                                const { firstName, lastName, score } = user;
                                nameString += `${firstName} ${lastName} (${(
                                  score * 100
                                ).toFixed(2)}%)<br />`;
                              }
                              return (
                                <div className="flex flex-row justify-between">
                                  <ActivityTemplateLabel
                                    title={`${i18n.t(name)} (${count})`}
                                  />
                                  <div className="flex flex-row items-center justify-center gap-x-1">
                                    <div>
                                      <p
                                        className="font-medium"
                                        style={{
                                          color: retrieveColorByScore(
                                            score * 100
                                          ),
                                        }}
                                      >
                                        {(score * 100).toFixed(2)}%
                                      </p>
                                    </div>
                                    <Tooltip id="activity_activity_template_statistics" />
                                    <div className="">
                                      <BaseImage
                                        size="small"
                                        src="/icons/info.png"
                                        alt="info"
                                        dataToolTipId="activity_activity_template_statistics"
                                        dataToolTipHTML={nameString}
                                        dataToolTipPlace="top"
                                        dataToolTipVariant="info"
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {(activityStatistics.length % 2 === 0 &&
                          index > activityStatistics.length - 3) ||
                        (activityStatistics.length % 2 === 1 &&
                          index > activityStatistics.length - 2) ? null : (
                          <DividerLine />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </FullCard>
      );
    } else {
      return (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      );
    }
  };

  const applicationUserDetailList = filterSelectedApplicationUserDetailList();
  const activityStatistics = constructActivityActivityTemplateStaticticsList();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default CatchxamApplicationDetailScreen;
