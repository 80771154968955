import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Server
 */
export const checkStorageService = async () => {
  try {
    const response = await axios
      .create()
      .get(
        `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.actuator.health.context}`
      );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Storage files
 */
export const retrieveMainFileById = async (id) => {
  try {
    const response = await axios.get(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.main.id.context}`.replace(
        "{id}",
        id
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveMainFileByFileName = async (fileName) => {
  try {
    const response = await axios.get(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.main["by-fileName"].context}`.replace(
        "{fileName}",
        fileName
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const uploadFileToPath = async (formData) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.upload.file["to-path"].context}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const moveFileToBrandPath = async (moveFile) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.move.file["to-brand-path"].context}`,
      moveFile
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const moveFileToToBeLabelledPath = async (moveFile) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.move.file["to-to-be-labelled-path"].context}`,
      moveFile
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryMainFileListByUserIdUserProfileId = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.query["by-userId-userProfileId"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryMainFileListByBrandId = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.query["by-brandId"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryMainFileListByBrandIdDocumentType = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.query["by-brandId-documentType"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryMainFileByIdList = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.query["by-idList"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryMainFileByPlanWeekItemList = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.query["by-planWeekItemList"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteStorageFile = async (id) => {
  try {
    const response = await axios.delete(
      `${connection.base.storage.url}${endpoints.storage.context}/${id}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteStorageFileByFileName = async (fileName) => {
  try {
    const response = await axios.delete(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage["by-fileName"].context}/${fileName}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryAllMainFileDTOPageByFilter = async (pagedParams) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.paged.query.dto["by-filter"].context}`,
      pagedParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const convertImageTranslateImageWithAI = async (
  convertImageTranslate
) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.ai["image-translate"]["image"]["from-gpt4"].context}`,
      convertImageTranslate
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const convertImageTranslateTextInImageWithAI = async (
  convertImageTranslate
) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.ai["image-translate"]["text-in-image"]["from-gpt4"].context}`,
      convertImageTranslate
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const convertImageTranslateImageInContextWithAI = async (
  convertImageTranslate
) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.ai["image-translate"]["image-in-context"]["from-gpt4"].context}`,
      convertImageTranslate
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createImageTranslateByFileName = async (imageTranslate) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage["image-translates"]["by-fileName"].context}`,
      imageTranslate
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchImageTranslate = async (imageTranslate) => {
  try {
    const response = await axios.patch(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage["image-translates"].context}/`,
      imageTranslate
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryImageTranslateListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage["image-translates"].query["by-fileNameList"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchMainFile = async (patchParams) => {
  try {
    const response = await axios.patch(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage["main"].context}`,
      patchParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
