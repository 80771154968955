import { Component, createContext, useContext } from "react";

export const AppContext = createContext();

class AppContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: "LIGHT",
      boarding: "INITIALIZED",
      isFullScreen: false,
      mainLeftNavigationState: "FULL",
      showSearchBar: true,
      managementStompClient: null,
      catchtivityStompClient: null,
      planStompClient: null,
    };
    this.setTheme = this.setTheme.bind(this);
    this.setBoarding = this.setBoarding.bind(this);
    this.setIsFullScreen = this.setIsFullScreen.bind(this);
    this.setMainLeftNavigationState =
      this.setMainLeftNavigationState.bind(this);
    this.setShowSearchBar = this.setShowSearchBar.bind(this);
    this.setManagementStompClient = this.setManagementStompClient.bind(this);
    this.setCatchtivityStompClient = this.setCatchtivityStompClient.bind(this);
    this.setPlanStompClient = this.setPlanStompClient.bind(this);
  }

  setTheme(theme) {
    this.setState({ theme });
  }

  setBoarding(boarding) {
    this.setState({ boarding });
  }

  setMainLeftNavigationState(mainLeftNavigationState) {
    this.setState({ mainLeftNavigationState });
  }

  setShowSearchBar(showSearchBar) {
    this.setState({ showSearchBar });
  }

  setIsFullScreen(isFullScreen) {
    this.setState({ isFullScreen });
  }

  setManagementStompClient(managementStompClient) {
    this.setState({ managementStompClient });
  }

  setCatchtivityStompClient(catchtivityStompClient) {
    this.setState({ catchtivityStompClient });
  }

  setPlanStompClient(planStompClient) {
    this.setState({ planStompClient });
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          setTheme: this.setTheme,
          setBoarding: this.setBoarding,
          setIsFullScreen: this.setIsFullScreen,
          setMainLeftNavigationState: this.setMainLeftNavigationState,
          setShowSearchBar: this.setShowSearchBar,
          setManagementStompClient: this.setManagementStompClient,
          setCatchtivityStompClient: this.setCatchtivityStompClient,
          setPlanStompClient: this.setPlanStompClient,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) throw Error("Context must be not null.");
  return context;
};

export default AppContextProvider;
