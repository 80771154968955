import { useCallback, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import FullCard from "../../components/cards/FullCard";
import InputGroup from "../../components/groups/InputGroup";
import AdvancedTable from "../../components/tables/AdvancedTable";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  queryBranchListByParams,
  queryCampusListByParams,
  queryGradeListByParams,
  queryInstitutionListByParams,
  querySeasonListByParams,
  queryUserListByParams,
  queryUserProfileListByParams,
} from "../../requests/ManagementRequests";
import {
  createAnnouncement,
  deleteAnnouncement,
  patchAnnouncement,
  queryAllAnnouncementDTOPageByFilter,
} from "../../requests/NotificationRequests";
import {
  constructUserProfileQueryParams,
  retrieveBrandDTOOptionList,
  retrieveCoterieTypeOptionList,
  retrieveEnableOptionList,
} from "../../utilization/ManagementUtilization";
import {
  retrieveAnnouncementAudienceOptionList,
  retrieveAnnouncementTypeOptionList,
} from "../../utilization/NotificationUtilization";
import {
  checkDeletePermissionGranted,
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import {
  retrieveDocumentTypeOptionList,
  showDocumentByUrl,
} from "../../utilization/StorageFileUtilization";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import ListAndUpsertStorageFile from "../../components/storage-file/ListAndUpsertStorageFile";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import DividerLine from "../../components/divider/DividerLine";
import BaseModal from "../../components/modal/BaseModal";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import debounce from "lodash.debounce";
import CreateButton from "../../components/buttons/CreateButton";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const AnnouncementListScreen = (props) => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
  } = useAuth();
  const [announcementDTOList, setAnnouncementDTOList] = useState([]);
  const [pageState, setPageState] = useState(0);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [documentExists, setDocumentExists] = useState(false);
  const [documentType, setDocumentType] = useState("IMAGE");
  const [documentUrl, setDocumentUrl] = useState(null);
  const [selectedType, setSelectedType] = useState("DEFAULT_OPTION");
  const [selectedAudience, setSelectedAudience] = useState("DEFAULT_OPTION");
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");
  const [selectedToUserId, setSelectedToUserId] = useState("DEFAULT_OPTION");
  const [selectedToUserProfileId, setSelectedToUserProfileId] =
    useState("DEFAULT_OPTION");
  const [selectedToBranchId, setSelectedToBranchId] =
    useState("DEFAULT_OPTION");
  const [selectedToGradeId, setSelectedToGradeId] = useState("DEFAULT_OPTION");
  const [selectedToSeasonId, setSelectedToSeasonId] =
    useState("DEFAULT_OPTION");
  const [selectedToInstitutionId, setSelectedToInstitutionId] =
    useState("DEFAULT_OPTION");
  const [selectedToCampusId, setSelectedToCampusId] =
    useState("DEFAULT_OPTION");
  const [selectedToBrandId, setSelectedToBrandId] = useState("DEFAULT_OPTION");
  const [userDTOList, setUserDTOList] = useState([]);
  const [userProfileDTOList, setUserProfileDTOList] = useState([]);
  const [branchDTOList, setBranchDTOList] = useState([]);
  const [gradeDTOList, setGradeDTOList] = useState([]);
  const [seasonDTOList, setSeasonDTOList] = useState([]);
  const [institutionDTOList, setInstitutionDTOList] = useState([]);
  const [campusDTOList, setCampusDTOList] = useState([]);
  const [brandDTOList, setBrandDTOList] = useState([userProfileBrand]);
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterTextContains, setFilterTextContains] = useState("");
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [debounceState, setDebounceState] = useState("");
  const [errorMap, setErrorMap] = useState({
    name: null,
    description: null,
    type: null,
  });
  const [showDocumentModal, setShowDocumentModal] = useState(false);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("announcement_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    const queryAndSetUserList = async () => {
      setUserDTOList([]);
      const { data, err } = await queryUserListByParams(
        constructUserProfileQueryParams(userProfile, userProfileBrand)
      );
      if (err) {
        console.log(err);
      } else {
        setUserDTOList(data);
      }
    };
    const queryAndSetBranchList = async () => {
      setBranchDTOList([]);
      let data = [];
      let err;
      if (userProfile.role === "STAFF") {
        const { data: staffData, err: staffErr } =
          await queryBranchListByParams(
            constructUserProfileQueryParams(userProfile, userProfileBrand)
          );
        data = staffData;
        err = staffErr;
      } else if (userProfile.role === "INDIVIDUAL") {
        const { data: individualData, err: individualErr } =
          await queryBranchListByParams({
            seasonIdList: [userProfileSeason.id],
            deleted: false,
          });
        data = individualData;
        err = individualErr;
      }

      if (err) {
        console.log(err);
      } else {
        setBranchDTOList(data);
      }
    };
    const queryAndSetGradeList = async () => {
      setGradeDTOList([]);
      let data = [];
      let err;
      if (userProfile.role === "STAFF") {
        const { data: staffData, err: staffErr } = await queryGradeListByParams(
          constructUserProfileQueryParams(userProfile, userProfileBrand)
        );
        data = staffData;
        err = staffErr;
      } else if (userProfile.role === "INDIVIDUAL") {
        const { data: individualData, err: individualErr } =
          await queryGradeListByParams({
            seasonIdList: [userProfileSeason.id],
            deleted: false,
          });
        data = individualData;
        err = individualErr;
      }

      if (err) {
        console.log(err);
      } else {
        setGradeDTOList(data);
      }
    };
    const queryAndSetSeasonList = async () => {
      setSeasonDTOList([]);
      const { data, err } = await querySeasonListByParams(
        constructUserProfileQueryParams(userProfile, userProfileBrand)
      );
      if (err) {
        console.log(err);
      } else {
        setSeasonDTOList(data);
      }
    };
    const queryAndSetInstitutionList = async () => {
      setInstitutionDTOList([]);
      const { data, err } = await queryInstitutionListByParams(
        constructUserProfileQueryParams(userProfile, userProfileBrand)
      );
      if (err) {
        console.log(err);
      } else {
        setInstitutionDTOList(data);
      }
    };
    const queryAndSetCampusList = async () => {
      setCampusDTOList([]);
      const { data, err } = await queryCampusListByParams(
        constructUserProfileQueryParams(userProfile, userProfileBrand)
      );
      if (err) {
        console.log(err);
      } else {
        setCampusDTOList(data);
      }
    };

    if (selectedType === "USER" || selectedType === "USER_PROFILE") {
      queryAndSetUserList();
    } else if (selectedType === "BRANCH") {
      if (userProfileBranch) {
        setBranchDTOList([userProfileBranch]);
      } else {
        queryAndSetBranchList();
      }
    } else if (selectedType === "GRADE") {
      if (userProfileGrade) {
        setGradeDTOList([userProfileGrade]);
      } else {
        queryAndSetGradeList();
      }
    } else if (selectedType === "SEASON") {
      if (userProfileSeason) {
        setSeasonDTOList([userProfileSeason]);
      } else {
        queryAndSetSeasonList();
      }
    } else if (selectedType === "INSTITUTION") {
      if (userProfileInstitution) {
        setInstitutionDTOList([userProfileInstitution]);
      } else {
        queryAndSetInstitutionList();
      }
    } else if (selectedType === "CAMPUS") {
      if (userProfileCampus) {
        setCampusDTOList([userProfileCampus]);
      } else {
        queryAndSetCampusList();
      }
    }
  }, [selectedType]);

  useEffect(() => {
    const queryAndSetUserProfileInformation = async () => {
      setUserProfileDTOList([]);
      const { data, err } = await queryUserProfileListByParams({
        ...constructUserProfileQueryParams(userProfile, userProfileBrand),
        userId: selectedToUserId,
      });
      if (err) {
        console.log(err);
      } else {
        setUserProfileDTOList(data);
      }
    };

    if (
      selectedToUserId !== "DEFAULT_OPTION" &&
      selectedType === "USER_PROFILE"
    ) {
      queryAndSetUserProfileInformation();
    }
  }, [selectedToUserId, selectedType]);

  useEffect(() => {
    retrieveAndSetAnnouncementPageByFilter();
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    setDebounceState("BASE_FILTER");
    retrieveAndSetAnnouncementPageByFilter();
  }, [isFilterApplied, filterBeginAt, filterEndAt, filterShowDeleted]);

  useEffect(() => {
    if (!isFilterApplied) return;
    if (filterTextContains) {
      setDebounceState("TEXT_CONTAINS");
      debouncedRetrieveAndSetAnnouncementPageByFilterDebounce(
        filterTextContains
      );
    } else {
      retrieveAndSetAnnouncementPageByFilter();
    }
  }, [filterTextContains]);

  const retrieveAndSetAnnouncementPageByFilter = async () => {
    setTableLoading(true);
    const { data, err } = await queryAllAnnouncementDTOPageByFilter(
      pagedQueryParams
    );
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setAnnouncementDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const retrieveAndSetAnnouncementPageByFilterDebounce = async (
    filterTextContains
  ) => {
    setTableLoading(true);
    if (isFilterApplied) {
      pagedQueryParams.params.textContains = filterTextContains.trim();
    }
    const { data, err } = await queryAllAnnouncementDTOPageByFilter(
      pagedQueryParams
    );
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setAnnouncementDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const debouncedRetrieveAndSetAnnouncementPageByFilterDebounce = useCallback(
    debounce(function (textContains) {
      retrieveAndSetAnnouncementPageByFilterDebounce(textContains);
    }, 1000),
    [debounceState]
  );

  const retrieveUserOptionList = () => {
    return userDTOList.map((userDTO) => ({
      text: `${userDTO.firstName} ${userDTO.lastName}`,
      value: userDTO.id,
    }));
  };

  const retrieveUserProfileOptionList = () => {
    return userProfileDTOList.map((userProfileDTO) => ({
      text: `(${i18n.t(userProfileDTO.role)}) ${userProfileDTO.name}`,
      value: userProfileDTO.id,
    }));
  };

  const retrieveBranchOptionList = () => {
    return branchDTOList.map((branchDTO) => ({
      text: `${branchDTO.name} - ${branchDTO.gradeDTO.seasonDTO.institutionDTO.name}`,
      value: branchDTO.id,
    }));
  };

  const retrieveGradeOptionList = () => {
    return gradeDTOList.map((gradeDTO) => ({
      text: `${gradeDTO.name} - ${gradeDTO.seasonDTO.institutionDTO.name}`,
      value: gradeDTO.id,
    }));
  };

  const retrieveSeasonOptionList = () => {
    return seasonDTOList.map((seasonDTO) => ({
      text: `${seasonDTO.name} - ${seasonDTO.institutionDTO.name}`,
      value: seasonDTO.id,
    }));
  };

  const retrieveInstitutionOptionList = () => {
    return institutionDTOList.map((institutionDTO) => ({
      text: `${institutionDTO.name} - ${institutionDTO.campusDTO.name}`,
      value: institutionDTO.id,
    }));
  };

  const retrieveCampusOptionList = () => {
    return campusDTOList.map((campusDTO) => ({
      text: `${campusDTO.name} - ${campusDTO.brandDTO.name}`,
      value: campusDTO.id,
    }));
  };

  const filterAnnouncementTypeOptionList = () => {
    if (userProfile.role === "STAFF") {
      if (userProfileBranch) {
        if (userInformation.accountType === "GENIXO") {
          return retrieveAnnouncementTypeOptionList().filter(
            (announcementType) =>
              announcementType.value === "USER" ||
              announcementType.value === "USER_PROFILE" ||
              announcementType.value === "BRANCH"
          );
        } else {
          return retrieveAnnouncementTypeOptionList().filter(
            (announcementType) => announcementType.value === "BRANCH"
          );
        }
      }
      if (userProfileGrade) {
        if (userInformation.accountType === "GENIXO") {
          return retrieveAnnouncementTypeOptionList().filter(
            (announcementType) =>
              announcementType.value === "USER" ||
              announcementType.value === "USER_PROFILE" ||
              announcementType.value === "BRANCH" ||
              announcementType.value === "GRADE"
          );
        } else {
          return retrieveAnnouncementTypeOptionList().filter(
            (announcementType) =>
              announcementType.value === "BRANCH" ||
              announcementType.value === "GRADE"
          );
        }
      }
      if (userProfileSeason) {
        if (userInformation.accountType === "GENIXO") {
          return retrieveAnnouncementTypeOptionList().filter(
            (announcementType) =>
              announcementType.value === "USER" ||
              announcementType.value === "USER_PROFILE" ||
              announcementType.value === "BRANCH" ||
              announcementType.value === "GRADE" ||
              announcementType.value === "SEASON"
          );
        } else {
          return retrieveAnnouncementTypeOptionList().filter(
            (announcementType) =>
              announcementType.value === "BRANCH" ||
              announcementType.value === "GRADE" ||
              announcementType.value === "SEASON"
          );
        }
      }
      if (userProfileInstitution) {
        if (userInformation.accountType === "GENIXO") {
          return retrieveAnnouncementTypeOptionList().filter(
            (announcementType) =>
              announcementType.value === "USER" ||
              announcementType.value === "USER_PROFILE" ||
              announcementType.value === "BRANCH" ||
              announcementType.value === "GRADE" ||
              announcementType.value === "SEASON" ||
              announcementType.value === "INSTITUTION"
          );
        } else {
          return retrieveAnnouncementTypeOptionList().filter(
            (announcementType) =>
              announcementType.value === "BRANCH" ||
              announcementType.value === "GRADE" ||
              announcementType.value === "SEASON" ||
              announcementType.value === "INSTITUTION"
          );
        }
      }
      if (userProfileCampus) {
        if (userInformation.accountType === "GENIXO") {
          return retrieveAnnouncementTypeOptionList().filter(
            (announcementType) =>
              announcementType.value === "USER" ||
              announcementType.value === "USER_PROFILE" ||
              announcementType.value === "BRANCH" ||
              announcementType.value === "GRADE" ||
              announcementType.value === "SEASON" ||
              announcementType.value === "INSTITUTION" ||
              announcementType.value === "CAMPUS"
          );
        } else {
          return retrieveAnnouncementTypeOptionList().filter(
            (announcementType) =>
              announcementType.value === "BRANCH" ||
              announcementType.value === "GRADE" ||
              announcementType.value === "SEASON" ||
              announcementType.value === "INSTITUTION" ||
              announcementType.value === "CAMPUS"
          );
        }
      }
      if (userProfileBrand) {
        if (userInformation.accountType === "GENIXO") {
          return retrieveAnnouncementTypeOptionList().filter(
            (announcementType) =>
              announcementType.value === "USER" ||
              announcementType.value === "USER_PROFILE" ||
              announcementType.value === "BRANCH" ||
              announcementType.value === "GRADE" ||
              announcementType.value === "SEASON" ||
              announcementType.value === "INSTITUTION" ||
              announcementType.value === "CAMPUS" ||
              announcementType.value === "BRAND"
          );
        } else {
          return retrieveAnnouncementTypeOptionList().filter(
            (announcementType) =>
              announcementType.value === "BRANCH" ||
              announcementType.value === "GRADE" ||
              announcementType.value === "SEASON" ||
              announcementType.value === "INSTITUTION" ||
              announcementType.value === "CAMPUS" ||
              announcementType.value === "BRAND"
          );
        }
      }
    } else if (userProfile.role === "INDIVIDUAL") {
      return retrieveAnnouncementTypeOptionList().filter(
        (announcementType) =>
          announcementType.value === "BRANCH" ||
          announcementType.value === "GRADE"
      );
    }
    return [];
  };

  const filterAnnouncementAudienceOptionList = () => {
    if (userProfile.role === "STAFF") {
      return retrieveAnnouncementAudienceOptionList();
    } else if (userProfile.role === "INDIVIDUAL") {
      return retrieveAnnouncementAudienceOptionList().filter(
        (announcementAudienceOption) =>
          announcementAudienceOption.value === "LEARNER"
      );
    }
  };

  const checkCommonValidation = () => {
    if (
      !name ||
      name.trim() === "" ||
      !description ||
      description.trim() === "" ||
      selectedType === "DEFAULT_OPTION"
    ) {
      const currentErrorMap = {
        name: null,
        description: null,
        type: null,
      };
      if (!name || name.trim() === "") {
        currentErrorMap.name = i18n.t("name_required_field");
      }
      if (!description || description.trim() === "") {
        currentErrorMap.description = i18n.t("description_required_field");
      }
      if (selectedType === "DEFAULT_OPTION") {
        currentErrorMap.type = i18n.t("type_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    if (selectedType === "USER") {
      if (selectedToUserId === "DEFAULT_OPTION") return false;
    } else if (selectedType === "USER_PROFILE") {
      if (selectedToUserId === "DEFAULT_OPTION") return false;
      if (selectedToUserProfileId === "DEFAULT_OPTION") return false;
    } else if (selectedType === "BRANCH") {
      if (selectedToBranchId === "DEFAULT_OPTION") return false;
    } else if (selectedType === "GRADE") {
      if (selectedToGradeId === "DEFAULT_OPTION") return false;
    } else if (selectedType === "SEASON") {
      if (selectedToSeasonId === "DEFAULT_OPTION") return false;
    } else if (selectedType === "INSTITUTION") {
      if (selectedToInstitutionId === "DEFAULT_OPTION") return false;
    } else if (selectedType === "CAMPUS") {
      if (selectedToCampusId === "DEFAULT_OPTION") return false;
    } else if (selectedType === "BRAND") {
      if (selectedToBrandId === "DEFAULT_OPTION") return false;
    }
    return true;
  };

  const resetParams = () => {
    setId(null);
    setName(null);
    setDescription(null);
    setSelectedToBranchId("DEFAULT_OPTION");
    setSelectedToGradeId("DEFAULT_OPTION");
    setSelectedToSeasonId("DEFAULT_OPTION");
    setSelectedToInstitutionId("DEFAULT_OPTION");
    setSelectedToCampusId("DEFAULT_OPTION");
    setSelectedToBrandId("DEFAULT_OPTION");
    setSelectedToUserId("DEFAULT_OPTION");
    setSelectedToUserProfileId("DEFAULT_OPTION");
    setSelectedType("DEFAULT_OPTION");
  };

  const handleOnChange = (type, value) => {
    if (type === "name") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: i18n.t("name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: null,
        }));
      }
      setName(value);
    } else if (type === "description") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: i18n.t("description_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: null,
        }));
      }
      setDescription(value);
    } else if (type === "documentExists") {
      setDocumentExists(value);
    } else if (type === "documentType") {
      setDocumentType(value);
      setDocumentUrl(null);
    } else if (type === "documentUrl") {
      setDocumentUrl(value);
    } else if (type === "type") {
      setSelectedType(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        type: null,
      }));
    } else if (type === "toUserId") {
      setSelectedToUserId(value);
    } else if (type === "toUserProfileId") {
      setSelectedToUserProfileId(value);
    } else if (type === "toBranchId") {
      setSelectedToBranchId(value);
    } else if (type === "toGradeId") {
      setSelectedToGradeId(value);
    } else if (type === "toSeasonId") {
      setSelectedToSeasonId(value);
    } else if (type === "toInstitutionId") {
      setSelectedToInstitutionId(value);
    } else if (type === "toCampusId") {
      setSelectedToCampusId(value);
    } else if (type === "toBrandId") {
      setSelectedToBrandId(value);
    } else if (type === "audience") {
      setSelectedAudience(value);
      setSelectedCoterieType("DEFAULT_OPTION");
    } else if (type === "coterieType") {
      setSelectedCoterieType(value);
    } else {
    }
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    } else if (type === "textContains") {
      setFilterTextContains(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleReturnFromAnnouncementOnClick = () => {
    resetParams();
    setPageState(0);
  };
  const handleToCreateAnnouncementOnClick = () => {
    setId(null);
    setPageState(1);
  };

  const handleToUpdateAnnouncementOnClick = (announcementDTO) => {
    setId(announcementDTO.id);
    setName(announcementDTO.name);
    setDescription(announcementDTO.description);
    setSelectedAudience(announcementDTO.audience);
    setSelectedCoterieType(announcementDTO.coterieType);
    setSelectedType(announcementDTO.type);
    setDocumentType(
      announcementDTO.documentType
        ? announcementDTO.documentType
        : "DEFAULT_OPTION"
    );
    setDocumentUrl(announcementDTO.documentUrl);
    if (announcementDTO.type === "USER") {
      setSelectedToUserId(announcementDTO.toUserId);
    } else if (announcementDTO.type === "USER_PROFILE") {
      setSelectedToUserId(announcementDTO.toUserId);
      setSelectedToUserProfileId(announcementDTO.toUserProfileId);
    } else if (announcementDTO.type === "BRANCH") {
      setSelectedToBranchId(announcementDTO.toBranchId);
    } else if (announcementDTO.type === "GRADE") {
      setSelectedToGradeId(announcementDTO.toGradeId);
    } else if (announcementDTO.type === "SEASON") {
      setSelectedToSeasonId(announcementDTO.toSeasonId);
    } else if (announcementDTO.type === "INSTITUTION") {
      setSelectedToInstitutionId(announcementDTO.toInstitutionId);
    } else if (announcementDTO.type === "CAMPUS") {
      setSelectedToCampusId(announcementDTO.toCampusId);
    } else if (announcementDTO.type === "BRAND") {
      setSelectedToBrandId(announcementDTO.toBrandId);
    }
    setPageState(1);
  };

  const handleUpdateAnnouncementOnClick = async () => {
    if (checkUpdatePermissionGranted("announcement_list")) {
      if (checkCommonValidation()) {
        let currentAnnouncement = {
          id,
          userId: userInformation.id,
          userProfileId: userProfile.id,
          name,
          description,
          type: selectedType,
          documentType: documentExists === "true" ? documentType : null,
          documentUrl: documentExists === "true" ? documentUrl : null,
        };
        if (selectedType === "USER") {
          currentAnnouncement = {
            ...currentAnnouncement,
            toUserId: selectedToUserId,
          };
        } else if (selectedType === "USER_PROFILE") {
          currentAnnouncement = {
            ...currentAnnouncement,
            toUserId: selectedToUserId,
            toUserProfileId: selectedToUserProfileId,
          };
        } else if (selectedType === "BRANCH") {
          currentAnnouncement = {
            ...currentAnnouncement,
            toBranchId: selectedToBranchId,
            audience:
              selectedAudience === "DEFAULT_OPTION" ? null : selectedAudience,
            coterieType:
              selectedAudience === "COTERIE" ? selectedCoterieType : null,
          };
        } else if (selectedType === "GRADE") {
          currentAnnouncement = {
            ...currentAnnouncement,
            toGradeId: selectedToGradeId,
            audience:
              selectedAudience === "DEFAULT_OPTION" ? null : selectedAudience,
            coterieType:
              selectedAudience === "COTERIE" ? selectedCoterieType : null,
          };
        } else if (selectedType === "SEASON") {
          currentAnnouncement = {
            ...currentAnnouncement,
            toSeasonId: selectedToSeasonId,
            audience:
              selectedAudience === "DEFAULT_OPTION" ? null : selectedAudience,
            coterieType:
              selectedAudience === "COTERIE" ? selectedCoterieType : null,
          };
        } else if (selectedType === "INSTITUTION") {
          currentAnnouncement = {
            ...currentAnnouncement,
            toInstitutionId: selectedToInstitutionId,
            audience:
              selectedAudience === "DEFAULT_OPTION" ? null : selectedAudience,
            coterieType:
              selectedAudience === "COTERIE" ? selectedCoterieType : null,
          };
        } else if (selectedType === "CAMPUS") {
          currentAnnouncement = {
            ...currentAnnouncement,
            toCampusId: selectedToCampusId,
          };
        } else if (selectedType === "BRAND") {
          currentAnnouncement = {
            ...currentAnnouncement,
            toBrandId: selectedToBrandId,
            audience:
              selectedAudience === "DEFAULT_OPTION" ? null : selectedAudience,
            coterieType:
              selectedAudience === "COTERIE" ? selectedCoterieType : null,
          };
        }
        const { data, err } = await patchAnnouncement(currentAnnouncement);
        if (err) {
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          const foundAnnouncementIndex = announcementDTOList.findIndex(
            (announcementDTO) => announcementDTO.id === currentAnnouncement.id
          );
          announcementDTOList[foundAnnouncementIndex] = {
            ...announcementDTOList[foundAnnouncementIndex],
            ...data,
          };
          setAnnouncementDTOList(
            JSON.parse(
              JSON.stringify(announcementDTOList.sort((a, b) => b.id - a.id))
            )
          );
          handleReturnFromAnnouncementOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleCreateAnnouncementOnClick = async () => {
    if (checkInsertPermissionGranted("announcement_list")) {
      if (checkCommonValidation()) {
        let newAnnouncement = {
          userId: userInformation.id,
          userProfileId: userProfile.id,
          name,
          description,
          type: selectedType,
          documentType,
          documentUrl,
        };
        if (selectedType === "USER") {
          newAnnouncement = {
            ...newAnnouncement,
            toUserId: selectedToUserId,
          };
        } else if (selectedType === "USER_PROFILE") {
          newAnnouncement = {
            ...newAnnouncement,
            toUserId: selectedToUserId,
            toUserProfileId: selectedToUserProfileId,
          };
        } else if (selectedType === "BRANCH") {
          newAnnouncement = {
            ...newAnnouncement,
            toBranchId: selectedToBranchId,
            toGradeId: selectedToGradeId,
            toSeasonId: selectedToSeasonId,
            toInstitutionId: selectedToInstitutionId,
            toCampusId: selectedToCampusId,
            toBrandId: selectedToBrandId,
            audience:
              selectedAudience === "DEFAULT_OPTION" ? null : selectedAudience,
            coterieType:
              selectedAudience === "COTERIE" ? selectedCoterieType : null,
          };
        } else if (selectedType === "GRADE") {
          newAnnouncement = {
            ...newAnnouncement,
            toGradeId: selectedToGradeId,
            toSeasonId: selectedToSeasonId,
            toInstitutionId: selectedToInstitutionId,
            toCampusId: selectedToCampusId,
            toBrandId: selectedToBrandId,
            audience:
              selectedAudience === "DEFAULT_OPTION" ? null : selectedAudience,
            coterieType:
              selectedAudience === "COTERIE" ? selectedCoterieType : null,
          };
        } else if (selectedType === "SEASON") {
          newAnnouncement = {
            ...newAnnouncement,
            toSeasonId: selectedToSeasonId,
            toInstitutionId: selectedToInstitutionId,
            toCampusId: selectedToCampusId,
            toBrandId: selectedToBrandId,
            audience:
              selectedAudience === "DEFAULT_OPTION" ? null : selectedAudience,
            coterieType:
              selectedAudience === "COTERIE" ? selectedCoterieType : null,
          };
        } else if (selectedType === "INSTITUTION") {
          newAnnouncement = {
            ...newAnnouncement,
            toInstitutionId: selectedToInstitutionId,
            toCampusId: selectedToCampusId,
            toBrandId: selectedToBrandId,
            audience:
              selectedAudience === "DEFAULT_OPTION" ? null : selectedAudience,
            coterieType:
              selectedAudience === "COTERIE" ? selectedCoterieType : null,
          };
        } else if (selectedType === "CAMPUS") {
          newAnnouncement = {
            ...newAnnouncement,
            toCampusId: selectedToCampusId,
            toBrandId: selectedToBrandId,
            audience:
              selectedAudience === "DEFAULT_OPTION" ? null : selectedAudience,
            coterieType:
              selectedAudience === "COTERIE" ? selectedCoterieType : null,
          };
        } else if (selectedType === "BRAND") {
          newAnnouncement = {
            ...newAnnouncement,
            toBrandId: selectedToBrandId,
            audience:
              selectedAudience === "DEFAULT_OPTION" ? null : selectedAudience,
            coterieType:
              selectedAudience === "COTERIE" ? selectedCoterieType : null,
          };
        }
        const { data, err } = await createAnnouncement(newAnnouncement);
        if (err) {
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          announcementDTOList.push(data);
          setAnnouncementDTOList(
            JSON.parse(
              JSON.stringify(announcementDTOList.sort((a, b) => b.id - a.id))
            )
          );
          handleReturnFromAnnouncementOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleRemoveAnnouncementOnClick = async (announcementId) => {
    if (checkDeletePermissionGranted("announcement_list")) {
      const { err } = await deleteAnnouncement(announcementId);
      if (err) {
        console.log(err);
      } else {
        const foundAnnouncementIndex = announcementDTOList.findIndex(
          (announcementDTO) => announcementDTO.id === announcementId
        );
        announcementDTOList.splice(foundAnnouncementIndex, 1);
        setAnnouncementDTOList(
          JSON.parse(
            JSON.stringify(announcementDTOList.sort((a, b) => b.id - a.id))
          )
        );
      }
    }
  };

  const handleChooseDocumentOnClick = (item) => {
    const constructedUrl = `${connection.base.storage.url}/storage-files/preview/file${item.path}/${item.fileName}`;
    setDocumentUrl(constructedUrl);
    setShowDocumentModal(false);
  };

  const constructPagedQueryParams = () => {
    return {
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        userId: userInformation.id,
        userProfileId: userProfile.id,
        beginAt:
          isFilterApplied && filterBeginAt
            ? new Date(filterBeginAt).getTime()
            : null,
        endAt:
          isFilterApplied && filterEndAt
            ? new Date(filterEndAt).getTime()
            : null,
        textContains:
          isFilterApplied && filterTextContains ? filterTextContains : null,
        deleted: isFilterApplied ? (filterShowDeleted ? null : false) : false,
      },
    };
  };

  const RenderDetailedAnnouncementItem = () => {
    if (selectedType === "USER") {
      return (
        <>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("user")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedToUserId}
                optionList={retrieveUserOptionList()}
                onChange={(event) => {
                  handleOnChange("toUserId", event.target.value);
                }}
              />
            </div>
          </div>
        </>
      );
    } else if (selectedType === "USER_PROFILE") {
      return (
        <>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("user")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedToUserId}
                optionList={retrieveUserOptionList()}
                onChange={(event) => {
                  handleOnChange("toUserId", event.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("user_profile")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedToUserProfileId}
                optionList={retrieveUserProfileOptionList()}
                onChange={(event) => {
                  handleOnChange("toUserProfileId", event.target.value);
                }}
              />
            </div>
          </div>
        </>
      );
    } else if (selectedType === "BRANCH") {
      return (
        <>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("branch_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedToBranchId}
                optionList={retrieveBranchOptionList()}
                onChange={(event) => {
                  handleOnChange("toBranchId", event.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("audience")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedAudience}
                optionList={filterAnnouncementAudienceOptionList()}
                onChange={(event) => {
                  handleOnChange("audience", event.target.value);
                }}
              />
            </div>
          </div>
          {selectedAudience === "COTERIE" ? (
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("coterie_type")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedCoterieType}
                  optionList={retrieveCoterieTypeOptionList()}
                  onChange={(event) => {
                    handleOnChange("coterieType", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
        </>
      );
    } else if (selectedType === "GRADE") {
      return (
        <>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("grade_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedToGradeId}
                optionList={retrieveGradeOptionList()}
                onChange={(event) => {
                  handleOnChange("toGradeId", event.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("audience")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedAudience}
                optionList={filterAnnouncementAudienceOptionList()}
                onChange={(event) => {
                  handleOnChange("audience", event.target.value);
                }}
              />
            </div>
          </div>
          {selectedAudience === "COTERIE" ? (
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("coterie_type")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedCoterieType}
                  optionList={retrieveCoterieTypeOptionList()}
                  onChange={(event) => {
                    handleOnChange("coterieType", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
        </>
      );
    } else if (selectedType === "SEASON") {
      return (
        <>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("season_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedToSeasonId}
                optionList={retrieveSeasonOptionList()}
                onChange={(event) => {
                  handleOnChange("toSeasonId", event.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("audience")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedAudience}
                optionList={retrieveAnnouncementAudienceOptionList()}
                onChange={(event) => {
                  handleOnChange("audience", event.target.value);
                }}
              />
            </div>
          </div>
          {selectedAudience === "COTERIE" ? (
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("coterie_type")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedCoterieType}
                  optionList={retrieveCoterieTypeOptionList()}
                  onChange={(event) => {
                    handleOnChange("coterieType", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
        </>
      );
    } else if (selectedType === "INSTITUTION") {
      return (
        <>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("institution_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedToInstitutionId}
                optionList={retrieveInstitutionOptionList()}
                onChange={(event) => {
                  handleOnChange("toInstitutionId", event.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("audience")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedAudience}
                optionList={retrieveAnnouncementAudienceOptionList()}
                onChange={(event) => {
                  handleOnChange("audience", event.target.value);
                }}
              />
            </div>
          </div>
          {selectedAudience === "COTERIE" ? (
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("coterie_type")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedCoterieType}
                  optionList={retrieveCoterieTypeOptionList()}
                  onChange={(event) => {
                    handleOnChange("coterieType", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
        </>
      );
    } else if (selectedType === "CAMPUS") {
      return (
        <>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("campus_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedToCampusId}
                optionList={retrieveCampusOptionList()}
                onChange={(event) => {
                  handleOnChange("toCampusId", event.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("audience")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedAudience}
                optionList={retrieveAnnouncementAudienceOptionList()}
                onChange={(event) => {
                  handleOnChange("audience", event.target.value);
                }}
              />
            </div>
          </div>
          {selectedAudience === "COTERIE" ? (
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("coterie_type")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedCoterieType}
                  optionList={retrieveCoterieTypeOptionList()}
                  onChange={(event) => {
                    handleOnChange("coterieType", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
        </>
      );
    } else if (selectedType === "BRAND") {
      return (
        <>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("brand_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedToBrandId}
                optionList={retrieveBrandDTOOptionList(brandDTOList)}
                onChange={(event) => {
                  handleOnChange("toBrandId", event.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("audience")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedAudience}
                optionList={retrieveAnnouncementAudienceOptionList()}
                onChange={(event) => {
                  handleOnChange("audience", event.target.value);
                }}
              />
            </div>
          </div>
          {selectedAudience === "COTERIE" ? (
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("coterie_type")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedCoterieType}
                  optionList={retrieveCoterieTypeOptionList()}
                  onChange={(event) => {
                    handleOnChange("coterieType", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
        </>
      );
    }
  };

  const RenderLibrarySelectionModal = () => {
    return (
      <BaseModal
        isOpen={showDocumentModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowDocumentModal(false);
        }}
      >
        <div className="flex-1 flex flex-col">
          <ListAndUpsertStorageFile
            title={i18n.t("announcement_documents")}
            description={i18n.t(
              "announcement_document_list_table_description_text"
            )}
            type={"brand"}
            selectable={false}
            labelable={false}
            showLabelList={true}
            isForAnnouncement={true}
            initialFilterApplied={true}
            initialDocumentType={documentType}
            initialCoterieType={"MANAGEMENT"}
            handleSelectOnClick={handleChooseDocumentOnClick}
            ownershipType={"NOTIFICATION_DOCUMENT"}
            optionFilter={"ACTIVITY"}
          />
        </div>
      </BaseModal>
    );
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={i18n.t("announcement_list")}
            description={i18n.t("announcement_list_table_description_text")}
            itemName={i18n.t("announcement")}
            headerList={[
              { name: i18n.t("name"), key: "name", type: "info" },
              { name: i18n.t("type"), key: "type", type: "enumeration" },
              {
                name: i18n.t("audience"),
                key: "audience",
                type: "enumeration",
              },
              {
                name: i18n.t("document_preview"),
                func: showDocumentByUrl,
                funcParams: ["documentType", "documentUrl"],
                type: "document",
              },
              {
                name: i18n.t("created_at"),
                key: "createdAt",
                type: "datetime",
              },
            ]}
            selectable={false}
            showFilter={true}
            showItemCount={selectedShowItemCount}
            totalItemCount={totalItemCount}
            pageNumber={pageNumber}
            loading={tableLoading}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleOnFilterChange={handleOnFilterChange}
            handleOnTableParamsChange={handleOnTableParamsChange}
            filterParams={{
              isFilterApplied,
              showBeginDate: true,
              beginAt: filterBeginAt,
              showEndDate: true,
              endAt: filterEndAt,
              showTextContains: false,
              textContains: filterTextContains,
              showDeleted: filterShowDeleted,
            }}
            itemList={announcementDTOList}
            insertable={checkInsertPermissionGranted("announcement_list")}
            handleInsertOnClick={handleToCreateAnnouncementOnClick}
            updatable={checkUpdatePermissionGranted("announcement_list")}
            handleUpdateOnClick={handleToUpdateAnnouncementOnClick}
            deletable={checkDeletePermissionGranted("announcement_list")}
            handleDeleteOnClick={handleRemoveAnnouncementOnClick}
            navigatable={false}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <FullCard isShadowed={true}>
            <div className="">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      handleReturnFromAnnouncementOnClick();
                    }}
                  />
                </div>
                <BaseTitle
                  title={
                    id
                      ? i18n.t("update_announcement")
                      : i18n.t("create_announcement")
                  }
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center ">
              <DividerLine />

              <div className="w-full">
                <p className="font-semibold text-lg">
                  {i18n.t("announcement_information")}
                </p>
              </div>
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={name}
                    onChange={(e) => {
                      handleOnChange("name", e.target.value);
                    }}
                    errorText={errorMap.name}
                  />
                </div>
              </div>
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("description")}</p>
                </div>
                <div className="flex-1 h-catchup-description">
                  <InputGroup
                    type="textarea"
                    value={description}
                    onChange={(e) => {
                      handleOnChange("description", e.target.value);
                    }}
                    errorText={errorMap.description}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("document")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={documentExists}
                    optionList={retrieveEnableOptionList()}
                    onChange={(event) => {
                      handleOnChange("documentExists", event.target.value);
                    }}
                  />
                </div>
              </div>

              {documentExists === true ? (
                <>
                  <div className="w-full flex flex-row">
                    <div className="w-catchup-input-group-title py-5">
                      <p>{i18n.t("document_type")}</p>
                    </div>
                    <div className="flex-1">
                      <InputGroup
                        type="select"
                        value={documentType}
                        optionList={retrieveDocumentTypeOptionList("ACTIVITY")}
                        onChange={(event) => {
                          handleOnChange("documentType", event.target.value);
                        }}
                      />
                    </div>
                  </div>

                  {documentType !== "DEFAULT_OPTION" ? (
                    <div className="w-full flex flex-row items-center mb-3">
                      <div className="w-catchup-input-group-title py-5"></div>
                      {documentUrl ? (
                        showDocumentByUrl(documentType, documentUrl)
                      ) : (
                        <div className="">
                          <PrimaryButton
                            title={i18n.t("add")}
                            size="small"
                            onClick={() => {
                              setShowDocumentModal(true);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ) : null}
                </>
              ) : null}

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("type")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={selectedType}
                    optionList={filterAnnouncementTypeOptionList()}
                    onChange={(event) => {
                      handleOnChange("type", event.target.value);
                    }}
                    errorText={errorMap.type}
                  />
                </div>
              </div>

              {RenderDetailedAnnouncementItem()}
              <div className="mt-4 ml-auto">
                {id ? (
                  <SecondaryButton
                    title={i18n.t("update")}
                    size="medium"
                    onClick={handleUpdateAnnouncementOnClick}
                  />
                ) : (
                  <CreateButton
                    title={i18n.t("create")}
                    size="medium"
                    onClick={handleCreateAnnouncementOnClick}
                  />
                )}
              </div>
            </div>
          </FullCard>
        </div>
      </div>
    );
  };

  const pagedQueryParams = constructPagedQueryParams();

  return (
    <div className="flex-1 flex flex-col">
      {RenderLibrarySelectionModal()}
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default AnnouncementListScreen;
