import { useParams } from "react-router-dom";
import FullCard from "../../components/cards/FullCard";
import DividerLine from "../../components/divider/DividerLine";
import i18n from "../../language/i18n";
import { useEffect, useState } from "react";
import {
  convertActivityTemplateWithAI,
  retrieveActivityWithDataById,
} from "../../requests/CatchtivityRequests";
import { useAuth } from "../../context/UserContextProvider";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import { NotificationManager } from "react-notifications";
import InputGroup from "../../components/groups/InputGroup";
import {
  retrieveActivityTemplateDTOOptionList,
  retrieveTaxonomyGroupName,
  retrieveTaxonomyType,
} from "../../utilization/CatchtivityUtilization";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import BaseLoading from "../../components/loading/BaseLoading";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const constructBasicTaxonomyMap = () => {
  return {
    type: retrieveTaxonomyType()[0].value,
    groupName: retrieveTaxonomyGroupName()[0].value,
    name: null,
    stage: null,
  };
};

const GenerateWithAITryAPromptScreen = (props) => {
  const { activityId } = useParams();
  const {
    language,
    categorySet,
    activityTemplateSet,
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
  } = useAuth();
  const [canGenerate, setCanGenerate] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [activityLoading, setActivityLoading] = useState(true);
  const [activityWithDataDTO, setActivityWithDataDTO] = useState(null);
  const [selectedMaterialContentType, setSelectedMaterialContentType] =
    useState(null);
  const [MCSAMaterialMap, setMCSAMaterialMap] = useState({ ANSWER_KEY: [] });
  const [MCSABodyMap, setMCSABodyMap] = useState({});
  const [selectedLevel, setSelectedLevel] = useState("DEFAULT_OPTION");
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");
  const [selectedVersionCode, setSelectedVersionCode] =
    useState("DEFAULT_OPTION");
  const [selectedActivityCategoryList, setSelectedActivityCategoryList] =
    useState([]);
  const [dropdownMaterialMap, setDropdownMaterialMap] = useState({});
  const [dropdownBodyMap, setDropdownBodyMap] = useState({});
  const [dropdownTaxonomyMap, setDropdownTaxonomyMap] = useState(
    constructBasicTaxonomyMap()
  );
  const [MCMAMaterialMap, setMCMAMaterialMap] = useState({ ANSWER_KEY: [] });
  const [MCMABodyMap, setMCMABodyMap] = useState({});
  const [MCMATaxonomyMap, setMCMATaxonomyMap] = useState(
    constructBasicTaxonomyMap()
  );
  const [matchingMaterialMap, setMatchingMaterialMap] = useState({});
  const [matchingBodyMap, setMatchingBodyMap] = useState({});
  const [matchingTaxonomyMap, setMatchingTaxonomyMap] = useState(
    constructBasicTaxonomyMap()
  );
  const [groupingMaterialMap, setGroupingMaterialMap] = useState({});
  const [groupingBodyMap, setGroupingBodyMap] = useState({});
  const [groupingTaxonomyMap, setGroupingTaxonomyMap] = useState(
    constructBasicTaxonomyMap()
  );
  const [fillInTheBlanksMaterialMap, setFillInTheBlanksMaterialMap] = useState(
    {}
  );
  const [fillInTheBlanksBodyMap, setFillInTheBlanksBodyMap] = useState({});
  const [fillInTheBlanksTaxonomyMap, setFillInTheBlanksTaxonomyMap] = useState(
    constructBasicTaxonomyMap()
  );
  const [openEndedMaterialMap, setOpenEndedMaterialMap] = useState({});
  const [openEndedBodyMap, setOpenEndedBodyMap] = useState({});
  const [openEndedTaxonomyMap, setOpenEndedTaxonomyMap] = useState(
    constructBasicTaxonomyMap()
  );
  const [systemMessageText, setSystemMessageText] = useState("");
  const [selectedActivityTemplateId, setSelectedActivityTemplateId] =
    useState(null);
  const [content, setContent] = useState(null);
  const [selectedTemperature, setSelectedTemperature] = useState(0.3);

  useEffect(() => {
    const retrieveAndSetActivityDTOById = async () => {
      setActivityLoading(true);
      const { data, err } = await retrieveActivityWithDataById(activityId);
      if (err) {
        console.log(err);
        return;
      }
      setActivityWithDataDTO(data);
      setActivityLoading(false);
    };
    if (!activityId) return;
    retrieveAndSetActivityDTOById();
  }, [activityId]);

  useEffect(() => {
    const convertToBodyStringMap = (bodyMapString) => {
      const convertedBodyMap = {};
      Object.keys(bodyMapString).forEach((bodyMapStringKey) => {
        convertedBodyMap[bodyMapStringKey] = JSON.parse(
          bodyMapString[bodyMapStringKey]
        );
      });
      return convertedBodyMap;
    };
    if (!activityWithDataDTO) return;
    const { data, activityTemplateDTOList } = activityWithDataDTO;
    setCanGenerate(
      activityTemplateDTOList.findIndex(
        (activityTemplate) => activityTemplate.type === "MCSA"
      ) !== -1
    );
    const constructedMap = JSON.parse(data);
    const currentContentMap = JSON.parse(constructedMap["contentMap"]);
    setSelectedMaterialContentType(currentContentMap.type);
    const currentMCSABodyMap = JSON.parse(constructedMap["MCSABodyMap"]);
    setMCSABodyMap(convertToBodyStringMap(currentMCSABodyMap));
    const currentMCSAMaterialMap = JSON.parse(
      constructedMap["MCSAMaterialMap"]
    );
    const answerKey = Object.keys(currentMCSAMaterialMap)[0];
    setMCSAMaterialMap({
      [answerKey]: JSON.parse(currentMCSAMaterialMap[answerKey]),
    });
    // const currentMCSATaxonomyMap = JSON.parse(
    //   constructedMap["MCSATaxonomyMap"]
    // );
    // setMCSATaxonomyMap(currentMCSATaxonomyMap);
  }, [activityWithDataDTO]);

  useEffect(() => {
    if (!canGenerate) return;
    const { coterieType, level, categoryIdList } = activityWithDataDTO;
    setSelectedCoterieType(coterieType);
    setSelectedLevel(level);
    const currentActivityCategoryList = [];
    for (const categoryId of categoryIdList) {
      const foundCategory = categorySet.find(
        (category) => category.id === categoryId
      );
      if (foundCategory) {
        currentActivityCategoryList.push(foundCategory);
        const { versionCode } = foundCategory;
        setSelectedVersionCode(versionCode);
      }
    }
    setSelectedActivityCategoryList(currentActivityCategoryList);
  }, [canGenerate, categorySet]);

  const retrieveTemperatureOptionList = () => {
    return [
      0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 1.1, 1.2, 1.3, 1.4,
      1.5, 1.6, 1.7, 1.8, 1.9, 2,
    ].map((item) => ({
      text: item,
      value: item,
    }));
  };

  const constructPreviewActivityUrl = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    return `${connection.app.url}/preview/activity/${
      activityWithDataDTO.id
    }/${btoa(encodeURI(JSON.stringify({ token: accessToken })))}`;
  };

  const constructParsedActivity = () => {
    return {
      name: `${i18n.t(
        selectedCoterieType
      )}-${selectedLevel} (${new Date().toLocaleString(language)})`,
      description: `${i18n.t(selectedCoterieType)}-${selectedLevel}-${i18n.t(
        selectedVersionCode
      )}`,
      selectedCoterieType,
      selectedLevel,
      selectedVersionCode,
      selectedActivityCategoryList,
      dropdownBodyMap,
      dropdownMaterialMap,
      dropdownTaxonomyMap,
      mcmaBodyMap: MCMABodyMap,
      mcmaMaterialMap: MCMAMaterialMap,
      mcmaTaxonomyMap: MCMATaxonomyMap,
      matchingBodyMap,
      matchingMaterialMap,
      matchingTaxonomyMap,
      groupingBodyMap,
      groupingMaterialMap,
      groupingTaxonomyMap,
      fillInTheBlanksBodyMap,
      fillInTheBlanksMaterialMap,
      fillInTheBlanksTaxonomyMap,
      openEndedBodyMap,
      openEndedMaterialMap,
      openEndedTaxonomyMap,
    };
  };

  const handleOnChange = (key, value) => {
    if (key === "systemMessageText") {
      setSystemMessageText(value);
    } else if (key === "selectedTemperature") {
      setSelectedTemperature(value);
    }
  };

  const handleGenerateWithAIOnClick = async () => {
    setContent(null);
    NotificationManager.info(i18n.t("please_wait_generating_text"));
    const foundActivityTemplate = activityTemplateSet.find(
      (activityTemplate) =>
        activityTemplate.id === parseFloat(selectedActivityTemplateId)
    );
    const itemList = [];
    Object.keys(MCSABodyMap).forEach((key) => {
      const bodyItem = MCSABodyMap[key];
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
    Object.keys(MCSAMaterialMap).forEach((key) => {
      const materialItemList = MCSAMaterialMap[key];
      for (const materialItem of materialItemList) {
        if (selectedMaterialContentType === "IMAGE") {
          itemList.push({
            type: "image_url",
            imageUrl: materialItem,
          });
        } else {
          itemList.push({
            type: "text",
            text: materialItem,
          });
        }
      }
    });
    const generateWithAI = {
      itemList,
      activityTemplateType: foundActivityTemplate.type,
      systemMessageText,
      temperature: selectedTemperature,
      userId: userInformation.id,
      userProfileId: userProfile.id,
      brandId: userProfileBrand.id,
      campusId: userProfileCampus?.id,
      institutionId: userProfileInstitution?.id,
      outcome: "",
    };
    const { data, err } = await convertActivityTemplateWithAI(generateWithAI);
    if (err) {
      NotificationManager.error(
        i18n.t("error_occurred_on_activity_template_generation")
      );
      return;
    }
    NotificationManager.success(
      i18n.t("activity_template_successfully_generated")
    );
    const {
      dropdownBodyMap,
      dropdownMaterialMap,
      mcmaBodyMap,
      mcmaMaterialMap,
      matchingBodyMap,
      matchingMaterialMap,
      groupingBodyMap,
      groupingMaterialMap,
      openEndedBodyMap,
      fillInTheBlanksBodyMap,
      fillInTheBlanksMaterialMap,
      taxonomy,
      content,
    } = data;
    setContent(content);
    const foundImageKeyList = Object.keys(MCSABodyMap).filter(
      (key) => MCSABodyMap[key].type === "IMAGE"
    );
    const currentBodyMap = {};
    if (foundImageKeyList.length > 0) {
      for (let i = 0; i < foundImageKeyList.length; i++) {
        currentBodyMap[i] = MCSABodyMap[foundImageKeyList[i]];
      }
    }
    if (foundActivityTemplate.type === "DROPDOWN") {
      for (let i = 0; i < Object.keys(dropdownBodyMap).length; i++) {
        currentBodyMap[i + foundImageKeyList.length] = dropdownBodyMap[i];
      }
      setDropdownBodyMap(currentBodyMap);
      setDropdownMaterialMap(dropdownMaterialMap);
      setDropdownTaxonomyMap(taxonomy);
    } else if (foundActivityTemplate.type === "MCMA") {
      for (let i = 0; i < Object.keys(mcmaBodyMap).length; i++) {
        currentBodyMap[i + foundImageKeyList.length] = mcmaBodyMap[i];
      }
      setMCMABodyMap(currentBodyMap);
      setMCMAMaterialMap(mcmaMaterialMap);
      setMCMATaxonomyMap(taxonomy);
    } else if (foundActivityTemplate.type === "MATCHING") {
      for (let i = 0; i < Object.keys(matchingBodyMap).length; i++) {
        currentBodyMap[i + foundImageKeyList.length] = matchingBodyMap[i];
      }
      setMatchingBodyMap(currentBodyMap);
      setMatchingMaterialMap(matchingMaterialMap);
      setMatchingTaxonomyMap(taxonomy);
    } else if (foundActivityTemplate.type === "GROUPING") {
      for (let i = 0; i < Object.keys(groupingBodyMap).length; i++) {
        currentBodyMap[i + foundImageKeyList.length] = groupingBodyMap[i];
      }
      setGroupingBodyMap(currentBodyMap);
      setGroupingMaterialMap(groupingMaterialMap);
      setGroupingTaxonomyMap(taxonomy);
    } else if (foundActivityTemplate.type === "OPEN_ENDED") {
      for (let i = 0; i < Object.keys(openEndedBodyMap).length; i++) {
        currentBodyMap[i + foundImageKeyList.length] = openEndedBodyMap[i];
      }
      setOpenEndedBodyMap(currentBodyMap);
      setOpenEndedTaxonomyMap(taxonomy);
    } else if (foundActivityTemplate.type === "FILL_IN_THE_BLANKS") {
      for (let i = 0; i < Object.keys(fillInTheBlanksBodyMap).length; i++) {
        currentBodyMap[i + foundImageKeyList.length] =
          fillInTheBlanksBodyMap[i];
      }
      setFillInTheBlanksBodyMap(currentBodyMap);
      setFillInTheBlanksMaterialMap(fillInTheBlanksMaterialMap);
      setFillInTheBlanksTaxonomyMap(taxonomy);
    }
    setIsGenerated(true);
  };

  const RenderCurrentSystemMessageText = () => {
    return (
      <div>
        {selectedActivityTemplateId === 96 ? (
          <p>
            Sen MATHEMATICS öğrenen öğrenciler için çalışma sayfaları üreten bir
            soru yazarısın. Aşağıdaki adımları uygula. Adım 1: Verilen sorunun
            ne öğretmek istediği anla. Adım 2: Boşluk doldurma soruları üret.
            Adım 3: Her bir boşluğa bir doğru iki yanlış cevap üret. Cümlelerini
            üretirken aşağıdaki zorunlulukların hepsini sağladığından emin ol.
            Zorunlu: Üretilen boşluk sayısı ile doğru cevap sayısı "kesinlikle"
            eşit olacak. Zorunlu: Üretilen sorunun taksonomi grubu BLOOM ve
            taksonomisi REMEMBER olacak. Zorunlu: Boşluk için seçtiğin kelimeler
            sorunun konusu ile ilgili önemli kavramlardan seçilecek. Zorunlu:
            Ürettiğin soru verilen sorunun zorluk seviyesine yakın olacak.
            Zorunlu: Ürettiğin her bir cümlede en az bir boşluk olacak. Zorunlu:
            Üretilen cevaplar ilgili boşlukların doğru cevapları olacak.
            Zorunlu: Sorunun gövdesi ve cevaplar dışında başka hiç bir şey
            yazmayacak. Numaralandırma yapılmayacak. Cümlelerini ürettikten
            sonra aşağıdaki dönüşümleri sırayla gerçekleştir. Dönüşüm 1: Her bir
            boşluğu @@ işareti ile değiştir. Dönüşüm 2: Her bir cevabın önüne
            boşluk indeksini [] içinde ekle. Dönüşüm 3: Her bir doğru cevabın
            önüne [DOĞRU][GENIXO] ekle. Dönüşüm 4: Her bir yanlış cevabın önüne
            [GENIXO] ekle. Dönüşüm 5: Matematiksel ifadelerin başlangıç ve
            bitişlerini ` ile değiştir.
          </p>
        ) : selectedActivityTemplateId === 195 ? (
          <p>
            Sen MATHEMATICS öğrenen öğrenciler için soru dönüştüren bir
            yazarsın. Aşağıdaki adımları uygula. Adım 1: Verilen sorunun ne
            öğretmek istediği anla. Adım 2: Birden fazla cevabın doğru olduğu
            yeni bir soruya dönüştür. Soruyu dönüştürürken aşağıdaki
            zorunlulukların hepsini sağladığından emin ol. Zorunlu: Dönüştürülen
            sorunun taksonomi grubu BLOOM ve taksonomisi REMEMBER olacak.
            Zorunlu: Dönüştürdüğün soru verilen sorunun zorluk seviyesine yakın
            olacak. Zorunlu: Sadece soru gövdesi ve cevapları yazacak.
            Numaralandırma yapılmayacak. Detay verilmeyecek. Zorunlu: Soruda en
            az iki doğru cevap olduğundan emin ol. Soruyu dönüştürdükten sonra
            aşağıdaki değişimleri gerçekleştir. Değişim 1: Yanlış cevapların
            önüne [GENIXO] ekle. Değişim 2: Doğru cevapların önüne
            [DOĞRU][GENIXO] ekle. Dönüşüm 3: Matematiksel ifadelerin başlangıç
            ve bitişlerini ` ile değiştir.
          </p>
        ) : selectedActivityTemplateId === 295 ? (
          <p>
            Sen MATHEMATICS öğrenen öğrenciler için soru dönüştüren bir
            yazarsın. Görevin, verilen sorunun ne öğretmek istediğini anlayıp
            soruyu ve cevaplarını harmanlayarak açık uçlu bir soruya
            dönüştürmek. Soruyu dönüştürürken aşağıdaki zorunlulukların hepsini
            sağladığından emin ol. Zorunlu: Üretilen sorunun taksonomi grubu
            BLOOM ve taksonomisi REMEMBER olacak. Zorunlu: Verilen soru öğrenci
            tarafından görülmeyecek. Bu nedenle dönüştürdüğün soruyu tamamen
            yaz. Zorunlu: Dönüştürdüğün soru verilen sorunun zorluk seviyesine
            yakın olacak. Zorunlu: Soru dışında başka hiç bir şey yazmayacak.
            Numaralandırma yapılmayacak. Detay verilmeyecek. Soruyu
            dönüştürdükten sonra aşağıdaki dönüşümleri gerçekleştir. Dönüşüm 1:
            Matematiksel ifadelerin başlangıç ve bitişlerini ` ile değiştir.
          </p>
        ) : selectedActivityTemplateId === 339 ? (
          <p>
            Sen MATHEMATICS öğrenen öğrenciler için çalışma sayfaları üreten bir
            soru yazarısın. Aşağıdaki adımları uygula. Adım 1: Verilen sorunun
            ne öğretmek istediği anla. Adım 2: Başka boşluk doldurma soruları
            üret. Cümlelerini üretirken aşağıdaki zorunlulukların hepsini
            sağladığından emin ol. Zorunlu: Üretilen boşluk sayısı ile doğru
            cevap sayısı "kesinlikle" eşit olacak. Zorunlu: Üretilen sorunun
            taksonomi grubu BLOOM ve taksonomisi REMEMBER olacak. Zorunlu:
            Boşluk için seçtiğin kelimeler sorunun konusu ile ilgili önemli
            kavramlardan seçilecek. Zorunlu: Ürettiğin soru verilen sorunun
            zorluk seviyesine yakın olacak. Zorunlu: Ürettiğin her bir cümlede
            en az bir boşluk olacak. Zorunlu: Üretilen cevaplar ilgili
            boşlukların doğru cevapları olacak. Zorunlu: Sorunun gövdesi ve
            cevaplar dışında başka hiç bir şey yazmayacak. Numaralandırma
            yapılmayacak. Cümlelerini ürettikten sonra aşağıdaki dönüşümleri
            gerçekleştir. Dönüşüm 1: Her bir boşluğu @@ işareti ile değiştir.
            Dönüşüm 2: Her bir cevabın önüne [GENIXO] ekle. Dönüşüm 3: Cevapları
            sırasıyla en sona yaz. Dönüşüm 4: Matematiksel ifadelerin başlangıç
            ve bitişlerini ` ile değiştir.
          </p>
        ) : selectedActivityTemplateId === 50 ? (
          <p>
            Sen MATHEMATICS öğrenen öğrenciler için çalışma sayfaları üreten bir
            soru yazarısın. Aşağıdaki adımları uygula. Adım 1: Verilen sorunun
            ne öğretmek istediği anla. Adım 2: Eşleştirme soruları üret.
            Cümlelerini üretirken aşağıdaki zorunlulukların hepsini
            sağladığından emin ol. Zorunlu: Bütün seçenekler birbirinden farklı
            olacak. Zorunlu: Üretilen sorunun taksonomi grubu BLOOM ve
            taksonomisi REMEMBER olacak. Zorunlu: Eşleştirme için seçtiğin
            kelimeler sorunun konusu ile ilgili önemli kavramlardan seçilecek.
            Zorunlu: Ürettiğin soru verilen sorunun zorluk seviyesine yakın
            olacak. Zorunlu: Soru gövdesi ve cevaplar dışında başka hiç bir şey
            yazmayacak. Numaralandırma ve harflendirme yapma. Cümlelerini
            ürettikten sonra aşağıdaki dönüşümleri sırayla gerçekleştir. Dönüşüm
            1: Soru gövdesi ile cevapların arasına [GENIXO] ekle. Dönüşüm 2:
            Seçenekleri {`<eşleşen> : <eşleştirilen>`} şeklinde ver. Dönüşüm 3:
            Matematiksel ifadelerin başlangıç ve bitişlerini ` ile değiştir.
          </p>
        ) : selectedActivityTemplateId === 247 ? (
          <p>
            Sen MATHEMATICS öğrenen öğrenciler için çalışma sayfaları üreten bir
            soru yazarısın. Aşağıdaki adımları uygula. Adım 1: Verilen sorunun
            ne öğretmek istediği anla. Adım 2: Gruplama soruları üret.
            Cümlelerini üretirken aşağıdaki zorunlulukların hepsini
            sağladığından emin ol. Zorunlu: En az iki farklı grup oluştur.
            Zorunlu: Üretilen sorunun taksonomi grubu BLOOM ve taksonomisi
            REMEMBER olacak. Zorunlu: Eşleştirme için seçtiğin kelimeler sorunun
            konusu ile ilgili önemli kavramlardan seçilecek. Zorunlu: Ürettiğin
            soru verilen sorunun zorluk seviyesine yakın olacak. Zorunlu: Soru
            gövdesi ve cevaplar dışında başka hiç bir şey yazmayacak.
            Numaralandırma ve harflendirme yapma. Cümlelerini ürettikten sonra
            aşağıdaki dönüşümleri sırayla gerçekleştir. Dönüşüm 1: Soru gövdesi
            ile cevapların arasına [GENIXO] ekle. Dönüşüm 2: Grup isimlerini
            grup açıklamalarıyla değiştir. Dönüşüm 3: Seçenekleri{" "}
            {`<grup açıklaması> : <elemanlar>`} şeklinde ver. Dönüşüm 4:
            Elemanları ; ile ayır. Dönüşüm 5: Matematiksel ifadelerin başlangıç
            ve bitişlerini ` ile değiştir.
          </p>
        ) : selectedActivityTemplateId === 387 ? (
          <p>
            Sen MATHEMATICS öğrenen öğrenciler için soru dönüştüren bir
            yazarsın. Aşağıdaki adımları uygula. Adım 1: Verilen sorunun ne
            öğretmek istediği anla. Adım 2: Doğru yanlış sorusuna dönüştür.
            Soruyu dönüştürürken aşağıdaki zorunlulukların hepsini sağladığından
            emin ol. Zorunlu: Dönüştürülen sorunun taksonomi grubu BLOOM ve
            taksonomisi REMEMBER olacak. Zorunlu: Dönüştürdüğün soru verilen
            sorunun zorluk seviyesine yakın olacak. Zorunlu: Sadece soru gövdesi
            ve cevapları yazacak. Numaralandırma yapılmayacak. Detay
            verilmeyecek. Soruyu dönüştürdükten sonra aşağıdaki dönüşümleri
            gerçekleştir. Dönüşüm 1: Yanlış cevapların önüne [YANLIŞ][GENIXO]
            ekle. Dönüşüm 2: Doğru cevapların önüne [DOĞRU][GENIXO] ekle.
            Dönüşüm 3: Matematiksel ifadelerin başlangıç ve bitişlerini ` ile
            değiştir.
          </p>
        ) : null}
      </div>
    );
  };

  const RenderGenerateWithAIContentContent = () => {
    if (!content) return;
    const splittedContent = content.split("\n");
    return splittedContent.map((item) => (
      <div className="min-h-[25px]">
        <p>{item}</p>
      </div>
    ));
  };

  const RenderMainContent = () => {
    if (activityLoading) {
      return (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      );
    } else {
      return (
        <FullCard isShadowed={true}>
          {canGenerate ? (
            <>
              <div className="h-[850px] w-full rounded-catchup-xlarge">
                <iframe
                  width="100%"
                  height="100%"
                  className="rounded-catchup-xlarge"
                  src={constructPreviewActivityUrl()}
                />
              </div>
              <DividerLine />
              <div className="w-full">
                <p className="font-semibold text-lg">
                  {i18n.t("activity_template")}
                </p>
                <div className="flex flex-row flex-wrap items-center gap-3 my-4">
                  {retrieveActivityTemplateDTOOptionList(activityTemplateSet)
                    .filter(
                      (option) =>
                        option.value === 339 ||
                        option.value === 295 ||
                        option.value === 195 ||
                        option.value === 96 ||
                        option.value === 50 ||
                        option.value === 247 ||
                        option.value === 1 ||
                        option.value === 387
                    )
                    .map((option, optionIndex) => (
                      <div className="relative" key={optionIndex}>
                        <div
                          className={`flex flex-col items-center justify-center ${
                            parseFloat(selectedActivityTemplateId) ===
                            parseFloat(option.value)
                              ? "bg-catchup-blue"
                              : "bg-catchup-light-gray"
                          }  text-catchup-white rounded-catchup-xlarge w-[150px] h-[80px] text-center cursor-pointer transition-all duration-300`}
                          onClick={() => {
                            setSelectedActivityTemplateId(option.value);
                            setSystemMessageText("");
                          }}
                        >
                          <p className="p-5">{i18n.t(option.text)}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              {selectedActivityTemplateId !== null ? (
                <>
                  <DividerLine />
                  <div className="flex flex-row items-center justify-start flex-wrap gap-x-2">
                    <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                      <div className="mx-2">
                        <InputGroup
                          type="select"
                          title={i18n.t("temperature")}
                          placeholder={i18n.t("temperature")}
                          value={selectedTemperature}
                          optionList={retrieveTemperatureOptionList()}
                          onChange={(event) => {
                            handleOnChange(
                              "selectedTemperature",
                              parseFloat(event.target.value)
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <DividerLine />
                  {RenderCurrentSystemMessageText()}
                  <div className="w-full flex flex-row">
                    <div className="w-catchup-input-group-title py-5">
                      <p>{i18n.t("system_message_text")}</p>
                    </div>
                    <div className="flex-1">
                      <InputGroup
                        type="textarea"
                        value={systemMessageText}
                        onChange={(e) => {
                          handleOnChange("systemMessageText", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-end items-center mt-5 gap-x-2">
                    <SecondaryButton
                      title={i18n.t("generate")}
                      size="small"
                      onClick={handleGenerateWithAIOnClick}
                    />
                  </div>
                </>
              ) : null}

              {isGenerated ? (
                <>
                  <DividerLine />
                  <div>
                    <p className="font-semibold text-lg">{i18n.t("content")}</p>
                    <div className="my-4">
                      {RenderGenerateWithAIContentContent()}
                    </div>
                  </div>
                </>
              ) : null}
            </>
          ) : (
            <div className="my-2 p-3 border rounded-catchup-xlarge bg-catchup-red text-catchup-white">
              <p>{i18n.t("generate_activity_with_ai_mcsa_required")}</p>
            </div>
          )}
        </FullCard>
      );
    }
  };
  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default GenerateWithAITryAPromptScreen;
