import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import FullCard from "../../components/cards/FullCard";
import i18n from "../../language/i18n";
import DividerLine from "../../components/divider/DividerLine";
import { useAuth } from "../../context/UserContextProvider";
import {
  queryBranchListByGradeId,
  queryCampusListByBrandId,
  queryGradeListBySeasonId,
  queryInstitutionListByCampusId,
  querySeasonListByInstitutionId,
  retrieveBranchByIdInDetail,
} from "../../requests/ManagementRequests";
import {
  filterCoterieTypeOptionList,
  retrieveBranchDTOOptionList,
  retrieveCampusDTOOptionList,
  retrieveGradeDTOOptionList,
  retrieveInstitutionDTOOptionList,
  retrieveSeasonDTOOptionList,
} from "../../utilization/ManagementUtilization";
import InputGroup from "../../components/groups/InputGroup";
import {
  retrieveAllEtudeCategoryScoreDTOListByEtudeId,
  queryAllEtudeDTOListByUserIdAndUserProfileId,
  retrieveActivityWithDataById,
  patchActivityEvaluation,
} from "../../requests/CatchtivityRequests";
import { NotificationManager } from "react-notifications";
import { retrieveColorByScore } from "../../utilization/CatchtivityUtilization";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import BaseImage from "../../components/images/BaseImage";
import { useApp } from "../../context/AppContextProvider";
import ActivityTemplateLabel from "../../components/label/ActivityTemplateLabel";
import CancelButton from "../../components/buttons/CancelButton";
import BaseLoading from "../../components/loading/BaseLoading";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const CheckEtudeScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userProfile,
    userInformation,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
  } = useAuth();
  const [brandDTOList, setBrandDTOList] = useState([userProfileBrand]);
  const [campusDTOList, setCampusDTOList] = useState([]);
  const [institutionDTOList, setInstitutionDTOList] = useState([]);
  const [seasonDTOList, setSeasonDTOList] = useState([]);
  const [gradeDTOList, setGradeDTOList] = useState([]);
  const [branchDTOList, setBranchDTOList] = useState([]);
  const [userProfileDTOList, setUserProfileDTOList] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState(userProfileBrand.id);
  const [selectedCampusId, setSelectedCampusId] = useState("DEFAULT_OPTION");
  const [selectedInstitutionId, setSelectedInstitutionId] =
    useState("DEFAULT_OPTION");
  const [selectedSeasonId, setSelectedSeasonId] = useState("DEFAULT_OPTION");
  const [selectedGradeId, setSelectedGradeId] = useState("DEFAULT_OPTION");
  const [selectedBranchId, setSelectedBranchId] = useState("DEFAULT_OPTION");
  const [selectedUserId, setSelectedUserId] = useState("DEFAULT_OPTION");
  const [selectedUserProfileId, setSelectedUserProfileId] =
    useState("DEFAULT_OPTION");
  const [selectedCoterieType, setSelectedCoterieType] = useState(
    userInformation.accountType === "GENIXO"
      ? "MANAGEMENT"
      : userProfile.coterieType
      ? userProfile.coterieType
      : "DEFAULT_OPTION"
  );
  const [etudeList, setEtudeList] = useState([]);
  const [selectedEtudeId, setSelectedEtudeId] = useState("DEFAULT_OPTION");
  const [etudeActivityScoreList, setEtudeActivityScoreList] = useState([]);
  const [
    selectedActivityTemplateScoreDTO,
    setSelectedActivityTemplateScoreDTO,
  ] = useState(null);
  const [contructedUrl, setContructedUrl] = useState(null);
  const [evaluationWithAIContent, setEvaluationWithAIContent] = useState(null);
  const [learnerState, setLearnerState] = useState(0);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("check_etude")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    if (selectedBrandId === "DEFAULT_OPTION") {
      setSelectedCampusId("DEFAULT_OPTION");
      setCampusDTOList([]);
    } else {
      if (userProfileCampus) {
        setCampusDTOList([userProfileCampus]);
        setSelectedCampusId(userProfileCampus.id);
      } else {
        retrieveAndSetCampusDTOListByBrandId();
      }
    }
  }, [selectedBrandId]);

  useEffect(() => {
    if (selectedCampusId === "DEFAULT_OPTION") {
      setSelectedInstitutionId("DEFAULT_OPTION");
      setInstitutionDTOList([]);
    } else {
      if (userProfileInstitution) {
        setInstitutionDTOList([userProfileInstitution]);
        setSelectedInstitutionId(userProfileInstitution.id);
      } else {
        retrieveAndSetInstitutionDTOListByCampusId();
      }
    }
  }, [selectedCampusId]);

  useEffect(() => {
    if (selectedInstitutionId === "DEFAULT_OPTION") {
      setSelectedSeasonId("DEFAULT_OPTION");
      setSeasonDTOList([]);
    } else {
      if (userProfileSeason) {
        setSeasonDTOList([userProfileSeason]);
        setSelectedSeasonId(userProfileSeason.id);
      } else {
        retrieveAndSetSeasonDTOListByInstitutionId();
      }
    }
  }, [selectedInstitutionId]);

  useEffect(() => {
    if (selectedSeasonId === "DEFAULT_OPTION") {
      setSelectedGradeId("DEFAULT_OPTION");
      setGradeDTOList([]);
    } else {
      if (userProfileGrade) {
        setGradeDTOList([userProfileGrade]);
        setSelectedGradeId(userProfileGrade.id);
      } else {
        retrieveAndSetGradeDTOListBySeasonId();
      }
    }
  }, [selectedSeasonId]);

  useEffect(() => {
    if (selectedGradeId === "DEFAULT_OPTION") {
      setBranchDTOList([]);
    } else {
      if (userProfileBranch) {
        setBranchDTOList([userProfileBranch]);
        setSelectedBranchId(userProfileBranch.id);
      } else {
        retrieveAndSetBranchDTOListByGradeId();
      }
    }
  }, [selectedGradeId]);

  useEffect(() => {
    if (selectedBranchId === "DEFAULT_OPTION") {
      setUserProfileDTOList([]);
    } else {
      retrieveAndSetBranchInDetail();
    }
  }, [selectedBranchId]);

  useEffect(() => {
    if (selectedUserProfileId === "DEFAULT_OPTION") return;
    retrieveAndSetEtudeList();
  }, [selectedUserProfileId]);

  useEffect(() => {
    if (selectedEtudeId === "DEFAULT_OPTION") return;
    retrieveAndSetEtudeActivityScoreDTOListByEtudeId();
  }, [selectedEtudeId]);

  useEffect(() => {
    const constructAndSetPreviewLearnerActivityUrl = async () => {
      const url = await constructLearnerActivityUrl(
        selectedActivityTemplateScoreDTO
      );
      setContructedUrl(url);
    };
    if (selectedActivityTemplateScoreDTO) {
      constructAndSetPreviewLearnerActivityUrl();
    } else {
      setContructedUrl(null);
    }
  }, [selectedActivityTemplateScoreDTO]);

  const retrieveAndSetCampusDTOListByBrandId = async () => {
    const { data, err } = await queryCampusListByBrandId(selectedBrandId);
    if (err) {
      console.log(err);
    } else {
      setCampusDTOList(data);
    }
  };

  const retrieveAndSetInstitutionDTOListByCampusId = async () => {
    const { data, err } = await queryInstitutionListByCampusId(
      selectedCampusId
    );
    if (err) {
      console.log(err);
    } else {
      setInstitutionDTOList(data);
    }
  };

  const retrieveAndSetSeasonDTOListByInstitutionId = async () => {
    const { data, err } = await querySeasonListByInstitutionId(
      selectedInstitutionId
    );
    if (err) {
      console.log(err);
    } else {
      setSeasonDTOList(data);
    }
  };

  const retrieveAndSetGradeDTOListBySeasonId = async () => {
    const { data, err } = await queryGradeListBySeasonId(selectedSeasonId);
    if (err) {
      console.log(err);
    } else {
      setGradeDTOList(data);
    }
  };

  const retrieveAndSetBranchDTOListByGradeId = async () => {
    const { data, err } = await queryBranchListByGradeId(selectedGradeId);
    if (err) {
      console.log(err);
    } else {
      setBranchDTOList(data);
    }
  };

  const retrieveAndSetBranchInDetail = async () => {
    const { data, err } = await retrieveBranchByIdInDetail(selectedBranchId);
    if (err) {
      console.log(err);
    } else {
      const { learnerUserProfileDTOList } = data;
      setUserProfileDTOList(learnerUserProfileDTOList);
    }
  };

  const retrieveAndSetEtudeList = async () => {
    const foundUserProfile = userProfileDTOList.find(
      (userProfile) => userProfile.id === parseFloat(selectedUserProfileId)
    );
    const { data, err } = await queryAllEtudeDTOListByUserIdAndUserProfileId({
      userId: foundUserProfile.userDTO.id,
      userProfileId: foundUserProfile.id,
    });
    if (err) {
      NotificationManager.error(i18n.t("error_on_etude_query"));
      return;
    }
    setEtudeList(data);
  };

  const retrieveAndSetEtudeActivityScoreDTOListByEtudeId = async () => {
    const { data, err } = await retrieveAllEtudeCategoryScoreDTOListByEtudeId(
      selectedEtudeId
    );
    if (err) {
      console.log(err);
      return;
    }
    setEtudeActivityScoreList(data);
  };

  const retrieveUserProfileDTOOptionList = () => {
    return userProfileDTOList.map((userProfileDTO) => ({
      value: userProfileDTO.id,
      text: `${userProfileDTO.userDTO.firstName} ${userProfileDTO.userDTO.lastName} (${userProfileDTO.name})`,
    }));
  };

  const retrieveEtudeDTOOptionList = () => {
    return etudeList.map((etude) => ({
      value: etude.id,
      text: etude.catchxamApplicationDTO.catchxamDTO.name,
    }));
  };

  const retrieveActivityTemplateAnswerType = (activityTemplateScoreDTO) => {
    const answer = JSON.parse(activityTemplateScoreDTO.data)[0].answerMap[
      "ANSWER"
    ];
    if (answer.startsWith("http://") || answer.startsWith("https://")) {
      if (
        answer.toLowerCase().endsWith("jpeg") ||
        answer.toLowerCase().endsWith("png") ||
        answer.toLowerCase().endsWith("svg") ||
        answer.toLowerCase().endsWith("webp")
      ) {
        return "IMAGE";
      } else if (
        answer.toLowerCase().endsWith("mp4") ||
        answer.toLowerCase().endsWith("mov") ||
        answer.toLowerCase().endsWith("wmv") ||
        answer.toLowerCase().endsWith("avi") ||
        answer.toLowerCase().endsWith("flv") ||
        answer.toLowerCase().endsWith("mkv")
      ) {
        return "VIDEO";
      } else if (
        answer.toLowerCase().endsWith("mp3") ||
        answer.toLowerCase().endsWith("wav") ||
        answer.toLowerCase().endsWith("m4a") ||
        answer.toLowerCase().endsWith("flac") ||
        answer.toLowerCase().endsWith("wma") ||
        answer.toLowerCase().endsWith("aac")
      ) {
        return "AUDIO";
      }
    }
    return "TEXT";
  };

  const constructLearnerActivityUrl = async (
    activityActivityTemplateScoreDTO
  ) => {
    const { activityId, activityTemplateName, data } =
      activityActivityTemplateScoreDTO;
    const { data: resData, err } = await retrieveActivityWithDataById(
      activityId
    );
    if (err) {
      console.log(err);
      return;
    }
    const filteredData = JSON.parse(resData.data);
    let reConstructedData = {
      contentMap: filteredData.contentMap,
    };
    if (activityTemplateName === "ORDERING") {
      reConstructedData = {
        ...reConstructedData,
        orderingBodyMap: filteredData["orderingBodyMap"],
        orderingMaterialMap: filteredData["orderingMaterialMap"],
        orderingTaxonomyMap: filteredData["orderingTaxonomyMap"],
      };
    } else if (activityTemplateName === "DROPDOWN") {
      reConstructedData = {
        ...reConstructedData,
        dropdownBodyMap: filteredData["dropdownBodyMap"],
        dropdownMaterialMap: filteredData["dropdownMaterialMap"],
        dropdownTaxonomyMap: filteredData["dropdownTaxonomyMap"],
      };
    } else if (activityTemplateName === "MCSA") {
      reConstructedData = {
        ...reConstructedData,
        MCSABodyMap: filteredData["MCSABodyMap"],
        MCSAMaterialMap: filteredData["MCSAMaterialMap"],
        MCSATaxonomyMap: filteredData["MCSATaxonomyMap"],
      };
    } else if (activityTemplateName === "MCMA") {
      reConstructedData = {
        ...reConstructedData,
        MCMABodyMap: filteredData["MCMABodyMap"],
        MCMAMaterialMap: filteredData["MCMAMaterialMap"],
        MCMATaxonomyMap: filteredData["MCMATaxonomyMap"],
      };
    } else if (activityTemplateName === "MATCHING") {
      reConstructedData = {
        ...reConstructedData,
        matchingBodyMap: filteredData["matchingBodyMap"],
        matchingMaterialMap: filteredData["matchingMaterialMap"],
        matchingTaxonomyMap: filteredData["matchingTaxonomyMap"],
      };
    } else if (activityTemplateName === "GROUPING") {
      reConstructedData = {
        ...reConstructedData,
        groupingBodyMap: filteredData["groupingBodyMap"],
        groupingMaterialMap: filteredData["groupingMaterialMap"],
        groupingTaxonomyMap: filteredData["groupingTaxonomyMap"],
      };
    } else if (activityTemplateName === "FILL_IN_THE_BLANKS") {
      reConstructedData = {
        ...reConstructedData,
        fillInTheBlanksBodyMap: filteredData["fillInTheBlanksBodyMap"],
        fillInTheBlanksMaterialMap: filteredData["fillInTheBlanksMaterialMap"],
        fillInTheBlanksTaxonomyMap: filteredData["fillInTheBlanksTaxonomyMap"],
      };
    } else if (activityTemplateName === "OPEN_ENDED") {
      reConstructedData = {
        ...reConstructedData,
        openEndedBodyMap: filteredData["openEndedBodyMap"],
        openEndedMaterialMap: filteredData["openEndedMaterialMap"],
        openEndedTaxonomyMap: filteredData["openEndedTaxonomyMap"],
      };
    } else if (activityTemplateName === "TRUE_FALSE") {
      reConstructedData = {
        ...reConstructedData,
        trueFalseBodyMap: filteredData["trueFalseBodyMap"],
        trueFalseMaterialMap: filteredData["trueFalseMaterialMap"],
        trueFalseTaxonomyMap: filteredData["trueFalseTaxonomyMap"],
      };
    }
    const dataWithAnswer = {
      ...reConstructedData,
      answerMap: JSON.parse(data),
    };
    return `${connection.app.url}/preview/activity/by-learner-data/${btoa(
      encodeURI(JSON.stringify(dataWithAnswer))
    ).replaceAll("/", "CATCHUPPER_SLASH")}`;
  };

  const checkCanHaveOpenEndedAction = (activityTemplateScoreDTO) => {
    const activityTemplateScoreDTOData = JSON.parse(
      activityTemplateScoreDTO.data
    );
    if (activityTemplateScoreDTOData) {
      const foundData = activityTemplateScoreDTOData.find(
        (data) => data.type === "OPEN_ENDED"
      );
      if (
        foundData &&
        foundData.answerMap["ANSWER"] !== "" &&
        activityTemplateScoreDTO.score === 0
      ) {
        return true;
      }
    }
    return false;
  };

  const handleOnChange = (type, value) => {
    if (type === "selectedBrandId") {
      setSelectedBrandId(value);
    } else if (type === "selectedCampusId") {
      setSelectedCampusId(value);
    } else if (type === "selectedInstitutionId") {
      setSelectedInstitutionId(value);
    } else if (type === "selectedSeasonId") {
      setSelectedSeasonId(value);
    } else if (type === "selectedGradeId") {
      setSelectedGradeId(value);
    } else if (type === "selectedBranchId") {
      setSelectedBranchId(value);
    } else if (type === "selectedUserProfileId") {
      setSelectedUserProfileId(value);
    } else if (type === "selectedCoterieType") {
      setSelectedCoterieType(value);
    } else if (type === "selectedEtudeId") {
      setSelectedEtudeId(value);
    }
  };

  const handlePreviewActivityOnClick = (activityDTO) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    window.open(
      `${connection.app.url}/preview/activity/${activityDTO.id}/${btoa(
        encodeURI(JSON.stringify({ token: accessToken }))
      )}`
    );
  };

  const handlePreviewLearnerActivityOnClick = async (
    activityActivityTemplateScoreDTO
  ) => {
    const constructedUrl = await constructLearnerActivityUrl(
      activityActivityTemplateScoreDTO
    );
    window.open(constructedUrl);
  };

  const handleEvaluateOpenEndedActivity = async (baseScore) => {
    const { data, err } = await patchActivityEvaluation({
      id: selectedActivityTemplateScoreDTO.id,
      score: baseScore / 100,
    });
    if (err) {
      console.log(err);
      if (err.response.data) {
        NotificationManager.error(i18n.t(err.response.data));
      }
    } else {
      retrieveAndSetEtudeActivityScoreDTOListByEtudeId();
      setLearnerState(0);
    }
  };

  const RenderSingleUserProfileActivityItem = (item) => {
    const {
      isEmpty,
      activityName,
      activityTemplateName,
      score,
      aiFeedbackText,
      userId,
      userProfileId,
    } = item;
    return (
      <div
        className={`w-full ${
          isEmpty
            ? "border-dashed rounded-catchup-xlarge border-catchup-orange"
            : checkCanHaveOpenEndedAction(item)
            ? "rounded-catchup-xlarge border-catchup-red"
            : "border-catchup-white"
        } border px-2 py-2 text-lg`}
      >
        <div className="flex flex-row items-center">
          <div className="flex-1 flex flex-col">
            <div className="mr-6">
              <p>{activityName}</p>
            </div>
            <div className="flex flex-row items-center gap-x-1 my-2">
              <div>
                <ActivityTemplateLabel title={i18n.t(activityTemplateName)} />
              </div>
            </div>
          </div>
          <div className="w-[75px] flex flex-col items-center">
            <p className="">
              <span style={{ color: retrieveColorByScore(score * 100) }}>
                {(score * 100).toFixed(2)}%
              </span>
            </p>
          </div>
          <div className="flex flex-row items-center gap-x-1">
            <div className="h-6 w-6">
              {activityTemplateName === "OPEN_ENDED" ? (
                <BaseImage
                  src="/icons/edit.png"
                  alt="edit"
                  size="small"
                  onClick={() => {
                    setSelectedUserId(userId);
                    setSelectedUserProfileId(userProfileId);
                    setSelectedActivityTemplateScoreDTO(item);
                    setEvaluationWithAIContent(aiFeedbackText);
                    setLearnerState(1);
                  }}
                />
              ) : (
                <BaseImage src="/icons/empty.png" alt="empty" size="small" />
              )}
            </div>
            <BaseImage
              src="/icons/preview.png"
              alt="preview"
              size="small"
              onClick={() => {
                handlePreviewLearnerActivityOnClick(item);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const RenderOpenEndedLearnerAnswerContent = () => {
    if (contructedUrl) {
      return (
        <div className="h-[850px] w-full rounded-catchup-xlarge">
          <iframe
            width="100%"
            height="100%"
            className="rounded-catchup-xlarge"
            src={contructedUrl}
          />
        </div>
      );
    } else {
      return (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      );
    }
  };

  const RenderSingleEvaluateOpenEndedItem = () => {
    return (
      <>
        {RenderOpenEndedLearnerAnswerContent()}
        <div className="flex flex-row justify-between items-center my-5">
          <CancelButton
            title={i18n.t("cancel")}
            size="small"
            onClick={() => {
              setLearnerState(0);
              setSelectedActivityTemplateScoreDTO(null);
            }}
          />
        </div>
        <div className="flex flex-row items-center justify-center gap-x-4 my-4">
          {[0.001, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(
            (baseScore, index) => (
              <div
                key={index}
                className={`flex flex-col items-center justify-center cursor-pointer border border-catchup-blue rounded-full h-12 w-12 ${
                  selectedActivityTemplateScoreDTO.score * 100 === baseScore
                    ? "bg-catchup-dark-blue border-catchup-dark-blue"
                    : ""
                }`}
                onClick={async () => {
                  handleEvaluateOpenEndedActivity(baseScore);
                }}
              >
                <p
                  className={`${
                    selectedActivityTemplateScoreDTO.score * 100 === baseScore
                      ? "text-catchup-white"
                      : ""
                  } text-2xl`}
                  style={{ color: retrieveColorByScore(baseScore) }}
                >
                  {baseScore.toFixed(0)}
                </p>
              </div>
            )
          )}
        </div>
      </>
    );
  };

  const RenderEtudeActivityScoreListContent = () => {
    const foundUserProfile = userProfileDTOList.find(
      (userProfile) => userProfile.id === parseFloat(selectedUserProfileId)
    );
    const foundEtude = etudeList.find((etude) => etude.id === selectedEtudeId);
    if (!foundEtude) return;
    const notAnsweredActivityList = foundEtude.activityDTOList.filter(
      (etudeActivityDTO) =>
        etudeActivityScoreList.findIndex(
          (etudeActivityScore) =>
            etudeActivityScore.activityId === etudeActivityDTO.id
        ) === -1
    );
    return learnerState === 0 ? (
      <>
        <DividerLine />
        <div className="flex-1 flex flex-col my-3">
          {etudeActivityScoreList.length > 0 ? (
            <>
              <div className="my-2 mx-4">
                <p className="font-semibold text-lg text-catchup-blue-500">
                  {i18n.t("learner_answers")}
                </p>
              </div>
              <div className="flex flex-row flex-wrap gap-y-2">
                {etudeActivityScoreList.map((etudeActivityScore, index) => (
                  <div key={index} className="w-full xl:w-1/2">
                    <div className="mx-2">
                      {RenderSingleUserProfileActivityItem({
                        ...etudeActivityScore,
                        userId: foundUserProfile.userDTO.id,
                        userProfileId: selectedUserProfileId,
                      })}
                      {(etudeActivityScoreList.length % 2 === 0 &&
                        index > etudeActivityScoreList.length - 3) ||
                      (etudeActivityScoreList.length % 2 === 1 &&
                        index > etudeActivityScoreList.length - 2) ? null : (
                        <DividerLine />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : null}

          {notAnsweredActivityList.length > 0 ? (
            <>
              <div className="my-2">
                <p className="font-semibold text-lg text-catchup-blue-500">
                  {i18n.t("not_answered_learner_answers")}
                </p>
              </div>
              {notAnsweredActivityList.map((activity, index) => (
                <div
                  key={index}
                  className={`flex flex-row my-2 px-2 text-lg opacity-50`}
                >
                  <div className="flex-1 flex flex-row flex-wrap">
                    <p>{activity.name}</p>
                  </div>
                  <div className="mx-2">
                    <BaseImage
                      src="/icons/preview.png"
                      alt="preview"
                      onClick={(e) => {
                        handlePreviewActivityOnClick(activity);
                      }}
                      size="small"
                    />
                  </div>
                </div>
              ))}
            </>
          ) : null}
        </div>
      </>
    ) : (
      RenderSingleEvaluateOpenEndedItem()
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">
        <FullCard isShadowed={true}>
          <div className="">
            <p className="font-semibold text-lg">{i18n.t("user_selection")}</p>
          </div>

          <div className="flex flex-row items-center flex-wrap">
            {selectedBrandId === "DEFAULT_OPTION" ? null : (
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("campus")}
                    placeholder={i18n.t("campus")}
                    value={selectedCampusId}
                    optionList={retrieveCampusDTOOptionList(campusDTOList)}
                    onChange={(event) => {
                      handleOnChange("selectedCampusId", event.target.value);
                    }}
                  />
                </div>
              </div>
            )}

            {selectedCampusId !== "DEFAULT_OPTION" ? (
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("institution")}
                    placeholder={i18n.t("institution")}
                    value={selectedInstitutionId}
                    optionList={retrieveInstitutionDTOOptionList(
                      institutionDTOList
                    )}
                    onChange={(event) => {
                      handleOnChange(
                        "selectedInstitutionId",
                        event.target.value
                      );
                    }}
                  />
                </div>
              </div>
            ) : null}

            {selectedInstitutionId !== "DEFAULT_OPTION" ? (
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("season")}
                    placeholder={i18n.t("season")}
                    value={selectedSeasonId}
                    optionList={retrieveSeasonDTOOptionList(seasonDTOList)}
                    onChange={(event) => {
                      handleOnChange("selectedSeasonId", event.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}

            {selectedSeasonId !== "DEFAULT_OPTION" ? (
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("grade")}
                    placeholder={i18n.t("grade")}
                    value={selectedGradeId}
                    optionList={retrieveGradeDTOOptionList(gradeDTOList)}
                    onChange={(event) => {
                      handleOnChange("selectedGradeId", event.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}

            {selectedGradeId !== "DEFAULT_OPTION" ? (
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("branch")}
                    placeholder={i18n.t("branch")}
                    value={selectedBranchId}
                    optionList={retrieveBranchDTOOptionList(branchDTOList)}
                    onChange={(event) => {
                      handleOnChange("selectedBranchId", event.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}

            {selectedBranchId !== "DEFAULT_OPTION" ? (
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("user_profile")}
                    placeholder={i18n.t("user_profile")}
                    value={selectedUserProfileId}
                    optionList={retrieveUserProfileDTOOptionList()}
                    onChange={(event) => {
                      handleOnChange(
                        "selectedUserProfileId",
                        event.target.value
                      );
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>

          {selectedUserProfileId !== "DEFAULT_OPTION" ? (
            <>
              <DividerLine />
              <div className="">
                <p className="font-semibold text-lg">
                  {i18n.t("etude_selection")}
                </p>
              </div>

              <div className="flex flex-row items-center flex-wrap">
                {userProfile.coterieType === "MANAGEMENT" ? (
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    <div className="mx-2">
                      <InputGroup
                        type="select"
                        title={i18n.t("coterie_type")}
                        placeholder={i18n.t("coterie_type")}
                        value={selectedCoterieType}
                        optionList={filterCoterieTypeOptionList(
                          userInformation,
                          userProfile,
                          userProfileInstitution
                        )}
                        onChange={(event) => {
                          handleOnChange(
                            "selectedCoterieType",
                            event.target.value
                          );
                        }}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  <div className="mx-2">
                    <InputGroup
                      type="select"
                      title={i18n.t("etude")}
                      placeholder={i18n.t("etude")}
                      value={selectedEtudeId}
                      optionList={retrieveEtudeDTOOptionList()}
                      onChange={(event) => {
                        handleOnChange("selectedEtudeId", event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <div>{RenderEtudeActivityScoreListContent()}</div>
        </FullCard>
      </div>
    </div>
  );
};

export default CheckEtudeScreen;
