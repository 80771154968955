import { useEffect, useState } from "react";
import i18n from "../../language/i18n";
import InputGroup from "../groups/InputGroup";
import { Bar, Line } from "react-chartjs-2";
import { getColorByIndex } from "../../utilization/AppUtilization";
import DividerLine from "../divider/DividerLine";

const OutcomeGraph = (props) => {
  const { catchxamReportList, language } = props;
  const [selectedParentCategoryCode, setSelectedParentCategoryCode] =
    useState("DEFAULT_OPTION");
  const [selectedSubCategoryCode1, setSelectedSubCategoryCode1] =
    useState("DEFAULT_OPTION");
  const [selectedSubCategoryCode2, setSelectedSubCategoryCode2] =
    useState("DEFAULT_OPTION");
  const [selectedSubCategoryCode3, setSelectedSubCategoryCode3] =
    useState("DEFAULT_OPTION");
  const [selectedSubCategoryCode4, setSelectedSubCategoryCode4] =
    useState("DEFAULT_OPTION");

  useEffect(() => {
    if (parentCategoryOptionList.length > 0) {
      setSelectedParentCategoryCode(parentCategoryOptionList[0].value);
    }
  }, []);

  useEffect(() => {
    if (selectedParentCategoryCode === "DEFAULT_OPTION") {
      setSelectedSubCategoryCode1("DEFAULT_OPTION");
    }
  }, [selectedParentCategoryCode]);

  useEffect(() => {
    if (selectedSubCategoryCode1 === "DEFAULT_OPTION") {
      setSelectedSubCategoryCode2("DEFAULT_OPTION");
    }
  }, [selectedSubCategoryCode1]);

  useEffect(() => {
    if (selectedSubCategoryCode2 === "DEFAULT_OPTION") {
      setSelectedSubCategoryCode3("DEFAULT_OPTION");
    }
  }, [selectedSubCategoryCode2]);

  useEffect(() => {
    if (selectedSubCategoryCode3 === "DEFAULT_OPTION") {
      setSelectedSubCategoryCode4("DEFAULT_OPTION");
    }
  }, [selectedSubCategoryCode3]);

  const handleOnChange = (key, value) => {
    if (key === "selectedParentCategoryCode") {
      setSelectedParentCategoryCode(value);
    } else if (key === "selectedSubCategoryCode1") {
      setSelectedSubCategoryCode1(value);
    } else if (key === "selectedSubCategoryCode2") {
      setSelectedSubCategoryCode2(value);
    } else if (key === "selectedSubCategoryCode3") {
      setSelectedSubCategoryCode3(value);
    } else if (key === "selectedSubCategoryCode4") {
      setSelectedSubCategoryCode4(value);
    }
  };

  const constructParentCategoryOptionList = () => {
    const optionList = [];
    catchxamReportList.forEach((catchxamReport) => {
      const { categoryDTO } = catchxamReport;
      const { id, code } = categoryDTO;
      if (optionList.findIndex((option) => option.value === id) === -1) {
        optionList.push({
          value: id,
          text: code,
        });
      }
    });
    return optionList.sort((a, b) => a.text.localeCompare(b.text));
  };

  const findCategoryData = (baseData, selectedCategoryCode) => {
    return baseData.find(
      (catchxamReport) => catchxamReport.categoryDTO.id === selectedCategoryCode
    );
  };

  const findSubCategoryData = (parentCategoryData, selectedCategoryCode) => {
    if (!parentCategoryData) return;
    return parentCategoryData.subCategoryActivityAnswerDTOList.find(
      (subCategoryActivityAnswerDTO) =>
        subCategoryActivityAnswerDTO.categoryDTO.id === selectedCategoryCode
    );
  };

  // const findParentCategoryData = () => {
  //   return catchxamReportList.find(
  //     (catchxamReport) =>
  //       catchxamReport.categoryDTO.id === selectedParentCategoryCode
  //   );
  // };

  const constructSubCategory1OptionList = () => {
    const optionList = [];
    if (selectedParentCategoryCode === "DEFAULT_OPTION") return optionList;
    if (!parentCategoryData) return optionList;
    const { subCategoryActivityAnswerDTOList } = parentCategoryData;
    subCategoryActivityAnswerDTOList.forEach((subCategoryActivityAnswerDTO) => {
      const { categoryDTO } = subCategoryActivityAnswerDTO;
      const { id, code, name } = categoryDTO;
      if (optionList.findIndex((option) => option.value === id) === -1) {
        optionList.push({
          value: id,
          text: `${code} - ${name}`,
        });
      }
    });
    return optionList.sort((a, b) => a.text.localeCompare(b.text));
  };

  // const findSubCategory1Data = () => {
  //   if (!parentCategoryData) return;
  //   return parentCategoryData.subCategoryActivityAnswerDTOList.find(
  //     (subCategoryActivityAnswerDTO) =>
  //       subCategoryActivityAnswerDTO.categoryDTO.id === selectedSubCategoryCode1
  //   );
  // };

  const constructSubCategory2OptionList = () => {
    const optionList = [];
    if (selectedSubCategoryCode1 === "DEFAULT_OPTION") return optionList;
    if (!subCategory1Data) return optionList;
    const { subCategoryActivityAnswerDTOList } = subCategory1Data;
    subCategoryActivityAnswerDTOList.forEach((subCategoryActivityAnswerDTO) => {
      const { categoryDTO } = subCategoryActivityAnswerDTO;
      const { id, code, name } = categoryDTO;
      if (optionList.findIndex((option) => option.value === id) === -1) {
        optionList.push({
          value: id,
          text: `${code} - ${name}`,
        });
      }
    });

    return optionList.sort((a, b) => a.text.localeCompare(b.text));
  };

  // const findSubCategory2Data = () => {
  //   if (!subCategory1Data) return;
  //   return subCategory1Data.subCategoryActivityAnswerDTOList.find(
  //     (subCategoryActivityAnswerDTO) =>
  //       subCategoryActivityAnswerDTO.categoryDTO.id === selectedSubCategoryCode2
  //   );
  // };

  const constructSubCategory3OptionList = () => {
    const optionList = [];
    if (selectedSubCategoryCode2 === "DEFAULT_OPTION") return optionList;
    if (!subCategory2Data) return optionList;
    const { subCategoryActivityAnswerDTOList } = subCategory2Data;
    subCategoryActivityAnswerDTOList.forEach((subCategoryActivityAnswerDTO) => {
      const { categoryDTO } = subCategoryActivityAnswerDTO;
      const { id, code, name } = categoryDTO;
      if (optionList.findIndex((option) => option.value === id) === -1) {
        optionList.push({
          value: id,
          text: `${code} - ${name}`,
        });
      }
    });
    return optionList.sort((a, b) => a.text.localeCompare(b.text));
  };

  // const findSubCategory3Data = () => {
  //   if (!subCategory2Data) return;
  //   return subCategory2Data.subCategoryActivityAnswerDTOList.find(
  //     (subCategoryActivityAnswerDTO) =>
  //       subCategoryActivityAnswerDTO.categoryDTO.id === selectedSubCategoryCode3
  //   );
  // };

  const constructSubCategory4OptionList = () => {
    const optionList = [];
    if (selectedSubCategoryCode3 === "DEFAULT_OPTION") return optionList;
    if (!subCategory3Data) return optionList;
    const { subCategoryActivityAnswerDTOList } = subCategory3Data;
    subCategoryActivityAnswerDTOList.forEach((subCategoryActivityAnswerDTO) => {
      const { categoryDTO } = subCategoryActivityAnswerDTO;
      const { id, code, name } = categoryDTO;
      if (optionList.findIndex((option) => option.value === id) === -1) {
        optionList.push({
          value: id,
          text: `${code} - ${name}`,
        });
      }
    });
    return optionList.sort((a, b) => a.text.localeCompare(b.text));
  };

  // const findSubCategory4Data = () => {
  //   if (!subCategory3Data) return;
  //   return subCategory3Data.subCategoryActivityAnswerDTOList.find(
  //     (subCategoryActivityAnswerDTO) =>
  //       subCategoryActivityAnswerDTO.categoryDTO.id === selectedSubCategoryCode4
  //   );
  // };

  const constructBaseData = () => {
    let categoryActivityAnswerDTOList = [];
    if (parentCategoryData) {
      if (subCategory1Data) {
        if (subCategory2Data) {
          if (subCategory3Data) {
            if (subCategory4Data) {
              const {
                subCategoryActivityAnswerDTOList,
                activityScoreDTOSet,
                categoryDTO,
              } = subCategory4Data;
              if (subCategoryActivityAnswerDTOList.length > 0) {
                categoryActivityAnswerDTOList =
                  subCategoryActivityAnswerDTOList;
              } else {
                categoryActivityAnswerDTOList = [
                  { activityScoreDTOSet, categoryDTO },
                ];
              }
            } else {
              const {
                subCategoryActivityAnswerDTOList,
                activityScoreDTOSet,
                categoryDTO,
              } = subCategory3Data;
              if (subCategoryActivityAnswerDTOList.length > 0) {
                categoryActivityAnswerDTOList =
                  subCategoryActivityAnswerDTOList;
              } else {
                categoryActivityAnswerDTOList = [
                  { activityScoreDTOSet, categoryDTO },
                ];
              }
            }
          } else {
            const {
              subCategoryActivityAnswerDTOList,
              activityScoreDTOSet,
              categoryDTO,
            } = subCategory2Data;
            if (subCategoryActivityAnswerDTOList.length > 0) {
              categoryActivityAnswerDTOList = subCategoryActivityAnswerDTOList;
            } else {
              categoryActivityAnswerDTOList = [
                { activityScoreDTOSet, categoryDTO },
              ];
            }
          }
        } else {
          const {
            subCategoryActivityAnswerDTOList,
            activityScoreDTOSet,
            categoryDTO,
          } = subCategory1Data;
          if (subCategoryActivityAnswerDTOList.length > 0) {
            categoryActivityAnswerDTOList = subCategoryActivityAnswerDTOList;
          } else {
            categoryActivityAnswerDTOList = [
              { activityScoreDTOSet, categoryDTO },
            ];
          }
        }
      } else {
        const {
          subCategoryActivityAnswerDTOList,
          activityScoreDTOSet,
          categoryDTO,
        } = parentCategoryData;
        if (subCategoryActivityAnswerDTOList.length > 0) {
          categoryActivityAnswerDTOList = subCategoryActivityAnswerDTOList;
        } else {
          categoryActivityAnswerDTOList = [
            { activityScoreDTOSet, categoryDTO },
          ];
        }
      }
    }
    return categoryActivityAnswerDTOList;
  };

  const constructBarLabels = () => {
    const labels = [];
    categoryActivityAnswerDTOList
      .sort((a, b) => a.categoryDTO.code.localeCompare(b.categoryDTO.code))
      .forEach((categoryActivityAnswerDTO) => {
        const { categoryDTO } = categoryActivityAnswerDTO;
        const { name, code } = categoryDTO;
        let currentName = name;
        if (name.length > 30) {
          currentName = currentName.substring(0, 27) + "...";
        }
        labels.push(`${code} - ${currentName}`);
      });

    return labels;
  };

  const constructBarData = () => {
    const barData = [];
    barData.push({
      label: i18n.t("learner_score"),
      data: [],
      backgroundColor: "rgba(17, 72, 89, 0.8)",
    });
    categoryActivityAnswerDTOList
      .sort((a, b) => a.categoryDTO.code.localeCompare(b.categoryDTO.code))
      .forEach((categoryActivityAnswerDTO) => {
        let score = 0;
        const { activityScoreDTOSet } = categoryActivityAnswerDTO;
        for (const activityScoreDTO of activityScoreDTOSet) {
          score += activityScoreDTO.score;
        }
        barData[0].data.push([(score / activityScoreDTOSet.length) * 100]);
      });

    return barData;
  };

  const constructLineData = () => {
    const labelList = [];
    const itemList = [];
    const names = [];
    for (const categoryActivityAnswerDTO of categoryActivityAnswerDTOList) {
      const { categoryDTO, activityScoreDTOSet } = categoryActivityAnswerDTO;
      const { id, name, code } = categoryDTO;
      for (const activityScoreDTO of activityScoreDTOSet) {
        const { beginAt, score } = activityScoreDTO;
        const beginDate = new Date(beginAt).toLocaleString(language);
        if (
          labelList.findIndex((label) => label.timestamp === beginAt) === -1
        ) {
          labelList.push({
            timestamp: beginAt,
            text: beginDate,
          });
        }
        let currentName = name;
        if (name.length > 30) {
          currentName = currentName.substring(0, 27) + "...";
        }
        currentName = `${code} - ${currentName}`;
        if (names.findIndex((n) => n === currentName) === -1) {
          names.push(currentName);
        }
        const foundData = itemList.find(
          (item) => item.timestamp === beginAt && item.id === id
        );
        if (foundData) {
          foundData.items.push(score);
        } else {
          itemList.push({
            timestamp: beginAt,
            id,
            items: [score],
          });
        }
      }
    }
    const labels = [];
    labelList.sort((a, b) => a.timestamp - b.timestamp);
    for (const label of labelList) {
      labels.push(label.text);
    }

    const data = [];
    itemList.sort((a, b) => a.timestamp - b.timestamp);
    for (const item of itemList) {
      const { id, items } = item;
      const foundData = data.find((datum) => datum.id === id);
      let sum = 0;
      if (items.length > 0) {
        sum = items.reduce((partialSum, a) => partialSum + a, 0);
        sum /= items.length;
        sum *= 100;
      }
      if (foundData) {
        foundData.items.push(sum);
      } else {
        data.push({
          id,
          items: [sum],
        });
      }
    }
    return { labels, data, names };
  };

  const retrieveOutcomeByTimeData = () => {
    const { labels, data, names } = constructLineData();
    return {
      labels,
      datasets: data.map((datum, index) => ({
        label: names[index],
        data: datum.items,
        borderColor: getColorByIndex(index),
        backgroundColor: getColorByIndex(index),
      })),
    };
  };

  const retrieveOutcomeByTimeOptions = () => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: false,
          text: i18n.t("line_graphic_outcome_by_time"),
        },
      },
      scales: {
        y: {
          min: 0,
          max: 100,
        },
      },
    };
  };

  const parentCategoryOptionList = constructParentCategoryOptionList();
  // const parentCategoryData = findParentCategoryData();
  const parentCategoryData = findCategoryData(
    catchxamReportList,
    selectedParentCategoryCode
  );
  const subCategory1OptionList = constructSubCategory1OptionList();
  // const subCategory1Data = findSubCategory1Data();
  const subCategory1Data = findSubCategoryData(
    parentCategoryData,
    selectedSubCategoryCode1
  );
  const subCategory2OptionList = constructSubCategory2OptionList();
  // const subCategory2Data = findSubCategory2Data();
  const subCategory2Data = findSubCategoryData(
    subCategory1Data,
    selectedSubCategoryCode2
  );
  const subCategory3OptionList = constructSubCategory3OptionList();
  // const subCategory3Data = findSubCategory3Data();
  const subCategory3Data = findSubCategoryData(
    subCategory2Data,
    selectedSubCategoryCode3
  );
  const subCategory4OptionList = constructSubCategory4OptionList();
  // const subCategory4Data = findSubCategory4Data();
  const subCategory4Data = findSubCategoryData(
    subCategory3Data,
    selectedSubCategoryCode4
  );
  const categoryActivityAnswerDTOList = constructBaseData();

  return (
    <>
      <div className="flex flex-row items-center justify-start flex-wrap">
        {parentCategoryOptionList.length > 0 ? (
          <div className="w-1/3 lg:w-1/4 xl:w-1/5">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("category_code")}
                placeholder={i18n.t("category_code")}
                value={selectedParentCategoryCode}
                optionList={parentCategoryOptionList}
                onChange={(event) => {
                  handleOnChange(
                    "selectedParentCategoryCode",
                    event.target.value
                  );
                }}
              />
            </div>
          </div>
        ) : null}

        {selectedParentCategoryCode !== "DEFAULT_OPTION" &&
        subCategory1OptionList.length > 0 ? (
          <div className="w-1/3 lg:w-1/4 xl:w-1/5">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("sub_category_code")}
                placeholder={i18n.t("sub_category_code")}
                value={selectedSubCategoryCode1}
                optionList={subCategory1OptionList}
                onChange={(event) => {
                  handleOnChange(
                    "selectedSubCategoryCode1",
                    event.target.value
                  );
                }}
              />
            </div>
          </div>
        ) : null}
        {selectedSubCategoryCode1 !== "DEFAULT_OPTION" &&
        subCategory2OptionList.length > 0 ? (
          <div className="w-1/3 lg:w-1/4 xl:w-1/5">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("sub_category_code")}
                placeholder={i18n.t("sub_category_code")}
                value={selectedSubCategoryCode2}
                optionList={subCategory2OptionList}
                onChange={(event) => {
                  handleOnChange(
                    "selectedSubCategoryCode2",
                    event.target.value
                  );
                }}
              />
            </div>
          </div>
        ) : null}
        {selectedSubCategoryCode2 !== "DEFAULT_OPTION" &&
        subCategory3OptionList.length > 0 ? (
          <div className="w-1/3 lg:w-1/4 xl:w-1/5">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("sub_category_code")}
                placeholder={i18n.t("sub_category_code")}
                value={selectedSubCategoryCode3}
                optionList={subCategory3OptionList}
                onChange={(event) => {
                  handleOnChange(
                    "selectedSubCategoryCode3",
                    event.target.value
                  );
                }}
              />
            </div>
          </div>
        ) : null}
        {selectedSubCategoryCode3 !== "DEFAULT_OPTION" &&
        subCategory4OptionList.length > 0 ? (
          <div className="w-1/3 lg:w-1/4 xl:w-1/5">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("sub_category_code")}
                placeholder={i18n.t("sub_category_code")}
                value={selectedSubCategoryCode4}
                optionList={subCategory4OptionList}
                onChange={(event) => {
                  handleOnChange(
                    "selectedSubCategoryCode4",
                    event.target.value
                  );
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="w-3/4 m-auto">
        {/* <div className="ml-4"> */}
        <div className="my-2">
          <p className="font-semibold text-lg">
            {i18n.t("overall_outcome_scores")}
          </p>
        </div>
        <Bar
          options={{
            responsive: true,
            interaction: {
              mode: "index",
              intersect: false,
            },
            maxBarThickness: 80,
            scales: {
              y: {
                min: 0,
                max: 100,
              },
            },
          }}
          data={{
            labels: constructBarLabels(),
            datasets: constructBarData(),
          }}
        />
        {/* <DividerLine />
        <Line
          options={retrieveOutcomeByTimeOptions()}
          data={retrieveOutcomeByTimeData()}
        /> */}
      </div>
    </>
  );
};

export default OutcomeGraph;
