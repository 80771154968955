import i18n from "../language/i18n";

export const retrieveAnnouncementTypeOptionList = () => {
  return [
    {
      value: "USER",
      text: i18n.t("USER"),
    },
    {
      value: "USER_PROFILE",
      text: i18n.t("USER_PROFILE"),
    },
    {
      value: "BRAND",
      text: i18n.t("BRAND"),
    },
    {
      value: "CAMPUS",
      text: i18n.t("CAMPUS"),
    },
    {
      value: "INSTITUTION",
      text: i18n.t("INSTITUTION"),
    },
    {
      value: "SEASON",
      text: i18n.t("SEASON"),
    },
    {
      value: "GRADE",
      text: i18n.t("GRADE"),
    },
    {
      value: "BRANCH",
      text: i18n.t("BRANCH"),
    },
  ];
};

export const retrieveAnnouncementAudienceOptionList = () => {
  return [
    {
      value: "EVERYONE",
      text: i18n.t("EVERYONE"),
    },
    {
      value: "STAFF",
      text: i18n.t("STAFF"),
    },
    {
      value: "COTERIE",
      text: i18n.t("COTERIE"),
    },
    {
      value: "LEARNER",
      text: i18n.t("LEARNER"),
    },
  ];
};
