import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/UserContextProvider";
import FullCard from "../../components/cards/FullCard";
import BaseTitle from "../../components/titles/BaseTitle";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import InputGroup from "../../components/groups/InputGroup";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import i18n from "../../language/i18n";
import {
  patchInstitutionIntegrationSettings,
  retrieveAllK12OrganizationList,
  retrieveInstitutionDTOById,
  retrieveInstitutionIntegrationSettingsByInstitutionId,
} from "../../requests/ManagementRequests";
import { useApp } from "../../context/AppContextProvider";
import BaseLoading from "../../components/loading/BaseLoading";

const InstitutionIntegrationSettingsScreen = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const { userInformation } = useAuth();
  const [institutionDTO, setInstitutionDTO] = useState(null);
  const [k12OrganzationList, setK12OrganizationList] = useState([]);
  const [institutionIntegrationSettings, setInstitutionIntegrationSettings] =
    useState(null);
  const [loadingMap, setLoadingMap] = useState({
    institution: false,
    k12OrganizationList: false,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const { accountType } = userInformation;
    if (accountType !== "GENIXO") navigate("/");
  }, [userInformation]);

  useEffect(() => {
    if (!id) return;
    retrieveAndSetInstitutionById();
    retrieveAndSetAllK12OrganizationList();
    retrieveAndSetInstitutionIntegrationSettings();
  }, [id]);

  const retrieveAndSetAllK12OrganizationList = async () => {
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      k12OrganizationList: true,
    }));
    const { data, err } = await retrieveAllK12OrganizationList();
    if (err) {
      console.log(err);
      return;
    }
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      k12OrganizationList: false,
    }));
    setK12OrganizationList(data);
  };

  const retrieveAndSetInstitutionIntegrationSettings = async () => {
    const { data, err } =
      await retrieveInstitutionIntegrationSettingsByInstitutionId(id);
    if (err) {
      console.log(err);
      return;
    }
    setInstitutionIntegrationSettings(data);
  };

  const retrieveAndSetInstitutionById = async () => {
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      institution: true,
    }));
    const { data, err } = await retrieveInstitutionDTOById(id);
    if (err) {
      console.log(err);
      return;
    }
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      institution: false,
    }));
    setInstitutionDTO(data);
  };

  const retrieveK12OrganizationOptionList = () => {
    return k12OrganzationList.map((item) => ({
      value: item.id,
      text: item.name,
    }));
  };

  const handleOnChange = (type, value) => {
    if (type === "k12Id") {
      setInstitutionIntegrationSettings(
        (prevInstitutionIntegrationSettings) => ({
          ...prevInstitutionIntegrationSettings,
          k12Id: value,
        })
      );
    }
  };

  const handleUpdateInstitutionIntegrationSettingsOnClick = async () => {
    const { data, err } = await patchInstitutionIntegrationSettings(
      institutionIntegrationSettings
    );
    if (err) {
      console.log(err);
      return;
    }
    navigate(-1);
  };

  const RenderMainContent = () => {
    if (!institutionIntegrationSettings) return;
    const { k12Id } = institutionIntegrationSettings;
    return (
      <FullCard isShadowed={true}>
        <div className="">
          <div className="flex flex-row items-center gap-x-2">
            <div className="cursor-pointer">
              <BaseImage
                size="medium"
                src="/icons/long-arrow-left.png"
                alt="long-arrow-left"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <BaseTitle title={i18n.t("institution_integration_settings")} />
          </div>
        </div>
        <DividerLine />
        {loadingMap.institution ? (
          <BaseLoading size="medium" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <>
            <div className="">
              <p className="">
                {i18n.t("brand_name")}:{" "}
                <span className="font-semibold">
                  {institutionDTO.campusDTO.brandDTO.name}
                </span>
              </p>
              <p className="">
                {i18n.t("campus_name")}:{" "}
                <span className="font-semibold">
                  {institutionDTO.campusDTO.name}
                </span>
              </p>
              <p className="">
                {i18n.t("branch_name")}:{" "}
                <span className="font-semibold">{institutionDTO.name}</span>
              </p>
            </div>
            {loadingMap.k12OrganizationList ? (
              <BaseLoading
                size="medium"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            ) : (
              <>
                <DividerLine />
                <div className="flex flex-row items-center flex-wrap">
                  <div className="w-full lg:w-1/2">
                    <div className="mx-2">
                      <InputGroup
                        type="select"
                        optionList={retrieveK12OrganizationOptionList()}
                        title={i18n.t("k12_organization_id")}
                        placeholder={null}
                        value={k12Id}
                        onChange={(event) => {
                          handleOnChange("k12Id", event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {!loadingMap.institution && !loadingMap.k12OrganizationList ? (
          <div className="mt-4 ml-auto">
            <SecondaryButton
              title={i18n.t("update")}
              size="medium"
              onClick={handleUpdateInstitutionIntegrationSettingsOnClick}
            />
          </div>
        ) : null}
      </FullCard>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default InstitutionIntegrationSettingsScreen;
