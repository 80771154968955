import { useEffect, useState } from "react";
import FullCard from "../../components/cards/FullCard";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import DividerLine from "../../components/divider/DividerLine";
import InputGroup from "../../components/groups/InputGroup";
import { useApp } from "../../context/AppContextProvider";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import { useNavigate } from "react-router-dom";
import { queryPublishingHouseLabelActionByPublishingHouseLabelIdInDetail } from "../../requests/LabelRequests";
import {
  ONE_DAY,
  ONE_HOUR,
  ONE_MONTH,
  ONE_WEEK,
  THREE_MONTHS,
  retrieveDateIntervalOptionList,
} from "../../utilization/DateUtilization";
import { Line } from "react-chartjs-2";
import RenderInformationCardItem from "../../components/cards/InformationCard";
import BaseImage from "../../components/images/BaseImage";
import BaseLoading from "../../components/loading/BaseLoading";

const PublishingHouseLabelVisualizerScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const { language, userPublishingHouseLabelSet } = useAuth();
  const [selectedLabelId, setSelectedLabelId] = useState("DEFAULT_OPTION");
  const [selectedDateInterval, setSelectedDateInterval] = useState("LAST_DAY");
  const [publishingHouseLabelActionDTO, setPublishingHouseLabelActionDTO] =
    useState(null);
  const [selectedUserProfileId, setSelectedUserProfileId] = useState(-1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("publishing_house_label_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    if (selectedLabelId === "DEFAULT_OPTION") {
      setPublishingHouseLabelActionDTO(null);
    } else {
      retrieveAndSetPublishingHouseLabelActionDTO();
    }
  }, [selectedLabelId]);

  const retrieveAndSetPublishingHouseLabelActionDTO = async () => {
    setLoading(true);
    const { data, err } =
      await queryPublishingHouseLabelActionByPublishingHouseLabelIdInDetail({
        publishingHouseLabelId: selectedLabelId,
      });
    if (err) {
      console.log(err);
      return;
    }
    setPublishingHouseLabelActionDTO(data);
    setLoading(false);
  };

  const retrievePublishingHouseLabelOptionList = () => {
    return userPublishingHouseLabelSet.map((item) => ({
      text: item.name,
      value: item.id,
    }));
  };

  const retrieveDayIntervalArray = () => {
    const intervalArray = [];
    if (selectedDateInterval === "LAST_DAY") {
      for (let i = 22; i >= -1; i--) {
        const iHourAgo = new Date().getTime() - i * ONE_HOUR;
        const currentTimestamp = new Date(
          new Date(iHourAgo).getFullYear(),
          new Date(iHourAgo).getUTCMonth(),
          new Date(iHourAgo).getDate(),
          new Date(iHourAgo).getHours(),
          0,
          0
        ).getTime();
        intervalArray.push({
          timestamp: currentTimestamp,
        });
      }
    } else if (selectedDateInterval === "LAST_WEEK") {
      for (let i = 166; i >= -1; i--) {
        const iHourAgo = new Date().getTime() - i * ONE_HOUR;
        const currentTimestamp = new Date(
          new Date(iHourAgo).getFullYear(),
          new Date(iHourAgo).getUTCMonth(),
          new Date(iHourAgo).getDate(),
          new Date(iHourAgo).getHours(),
          0,
          0
        ).getTime();
        intervalArray.push({
          timestamp: currentTimestamp,
        });
      }
    } else if (selectedDateInterval === "LAST_MONTH") {
      for (let i = 28; i >= -1; i--) {
        const iDayAgo = new Date().getTime() - i * ONE_DAY;
        const currentTimestamp = new Date(
          new Date(iDayAgo).getFullYear(),
          new Date(iDayAgo).getUTCMonth(),
          new Date(iDayAgo).getDate(),
          0,
          0,
          0
        ).getTime();
        intervalArray.push({
          timestamp: currentTimestamp,
        });
      }
    } else if (selectedDateInterval === "LAST_THREE_MONTHS") {
      for (let i = 88; i >= -1; i--) {
        const iDayAgo = new Date().getTime() - i * ONE_DAY;
        const currentTimestamp = new Date(
          new Date(iDayAgo).getFullYear(),
          new Date(iDayAgo).getUTCMonth(),
          new Date(iDayAgo).getDate(),
          0,
          0,
          0
        ).getTime();
        intervalArray.push({
          timestamp: currentTimestamp,
        });
      }
    }
    return intervalArray;
  };

  const retrieveLabelUsedLineData = () => {
    const labels = dayIntervalArray.map((dayInterval) =>
      new Date(dayInterval.timestamp).toLocaleString(language)
    );
    return {
      labels,
      datasets: [
        {
          label: i18n.t("amount"),
          data: labelData,
          borderColor: "rgb(17, 72, 89)",
          backgroundColor: "rgba(17, 72, 89, 0.5)",
        },
      ],
    };
  };

  const retrieveLabelUsedLineOptions = () => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: i18n.t("line_graphic_label_used"),
        },
      },
    };
  };

  const filterByTimeInterval = () => {
    if (publishingHouseLabelActionDTO) {
      const { publishingHouseLabelItemDTOList } = publishingHouseLabelActionDTO;
      if (selectedDateInterval === "LAST_DAY") {
        return publishingHouseLabelItemDTOList.filter(
          (publishingHouseLabelItemDTO) =>
            publishingHouseLabelItemDTO.createdAt >
            new Date().getTime() - ONE_DAY
        );
      } else if (selectedDateInterval === "LAST_WEEK") {
        return publishingHouseLabelItemDTOList.filter(
          (publishingHouseLabelItemDTO) =>
            publishingHouseLabelItemDTO.createdAt >
            new Date().getTime() - ONE_WEEK
        );
      } else if (selectedDateInterval === "LAST_MONTH") {
        return publishingHouseLabelItemDTOList.filter(
          (publishingHouseLabelItemDTO) =>
            publishingHouseLabelItemDTO.createdAt >
            new Date().getTime() - ONE_MONTH
        );
      } else if (selectedDateInterval === "LAST_THREE_MONTHS") {
        return publishingHouseLabelItemDTOList.filter(
          (publishingHouseLabelItemDTO) =>
            publishingHouseLabelItemDTO.createdAt >
            new Date().getTime() - THREE_MONTHS
        );
      }
    }

    return [];
  };

  const filterUserOptionList = () => {
    const userOptionList = [
      {
        value: -1,
        text: i18n.t("all"),
      },
    ];
    if (publishingHouseLabelActionDTO) {
      const { publishingHouseLabelItemDTOList } = publishingHouseLabelActionDTO;
      publishingHouseLabelItemDTOList.forEach((publishingHouseLabelItemDTO) => {
        const { profileName, firstName, lastName, userProfileId } =
          publishingHouseLabelItemDTO;
        const foundIndex = userOptionList.findIndex(
          (userOption) => userOption.value === userProfileId
        );
        if (foundIndex === -1) {
          userOptionList.push({
            value: userProfileId,
            text: `${firstName} ${lastName} (${profileName})`,
          });
        }
      });
    }
    return userOptionList;
  };

  const constructLabelLineData = () => {
    const labelData = [];
    for (let i = 0; i < dayIntervalArray.length - 1; i++) {
      let filteredPublishingHouseLabelDTOItemList =
        filteredByTimeInterval.filter(
          (filteredPublishingHouseLabelDTOItem) =>
            filteredPublishingHouseLabelDTOItem.createdAt >
              dayIntervalArray[i].timestamp &&
            filteredPublishingHouseLabelDTOItem.createdAt <
              dayIntervalArray[i + 1].timestamp
        );
      if (selectedUserProfileId !== -1) {
        filteredPublishingHouseLabelDTOItemList =
          filteredPublishingHouseLabelDTOItemList.filter(
            (filteredPublishingHouseLabelDTOItem) =>
              filteredPublishingHouseLabelDTOItem.userProfileId ===
              selectedUserProfileId
          );
      }
      const distinctUserList = [];
      let labelUsed = 0;
      filteredPublishingHouseLabelDTOItemList.forEach((tokenUsage) => {
        const { userProfileId } = tokenUsage;
        labelUsed += 1;
        const foundDistinctUser = distinctUserList.find(
          (distinctUser) => distinctUser.userProfileId === userProfileId
        );
        if (!foundDistinctUser) {
          distinctUserList.push({
            userProfileId: userProfileId,
          });
        }
      });

      if (distinctUserList.length === 0) {
        labelData.push(0);
      } else {
        labelData.push(labelUsed);
      }
    }

    return { labelData };
  };

  const calculateTotalLabelUsed = () => {
    let totalLabelUsed = 0;
    labelData.forEach((item) => {
      totalLabelUsed += item;
    });
    return totalLabelUsed;
  };

  const handleOnChange = (type, value) => {
    if (type === "labelId") {
      setSelectedLabelId(value);
    } else if (type === "userProfileId") {
      setSelectedUserProfileId(value);
    } else if (type === "date_interval") {
      setSelectedDateInterval(value);
    }
  };

  const RenderMainContent = () => {
    return (
      <FullCard isShadowed={true}>
        <div className="flex flex-row flex-wrap items-center mb-3">
          <div className="w-1/2 lg:w-1/3">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("label")}
                placeholder={i18n.t("label")}
                value={selectedLabelId}
                optionList={retrievePublishingHouseLabelOptionList()}
                onChange={(event) => {
                  handleOnChange("labelId", event.target.value);
                }}
              />
            </div>
          </div>
          {selectedLabelId !== "DEFAULT_OPTION" ? (
            <>
              <div className="w-1/2 lg:w-1/3">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("user_profile")}
                    placeholder={i18n.t("user_profile")}
                    value={selectedUserProfileId}
                    optionList={filterUserOptionList()}
                    onChange={(event) => {
                      handleOnChange("userProfileId", event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="w-1/2 lg:w-1/3">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("date_interval")}
                    placeholder={i18n.t("date_interval")}
                    value={selectedDateInterval}
                    optionList={retrieveDateIntervalOptionList()}
                    onChange={(event) => {
                      handleOnChange("date_interval", event.target.value);
                    }}
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
        {selectedLabelId !== "DEFAULT_OPTION" ? (
          <>
            <DividerLine />
            {loading ? (
              <BaseLoading
                size="large"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            ) : (
              <>
                <div className="flex flex-row flex-wrap">
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("total_label_used"),
                      null,
                      <BaseImage
                        src="/icons/label.png"
                        alt="label"
                        size="small"
                      />,
                      totalTokenSpent,
                      1
                    )}
                  </div>
                </div>
                <DividerLine />
                <Line
                  options={retrieveLabelUsedLineOptions()}
                  data={retrieveLabelUsedLineData()}
                />
              </>
            )}
          </>
        ) : null}
      </FullCard>
    );
  };

  const dayIntervalArray = retrieveDayIntervalArray();
  const filteredByTimeInterval = filterByTimeInterval();
  const { labelData } = constructLabelLineData();
  const totalTokenSpent = calculateTotalLabelUsed();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default PublishingHouseLabelVisualizerScreen;
