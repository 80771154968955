import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import FullCard from "../../components/cards/FullCard";
import InputGroup from "../../components/groups/InputGroup";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  createStandardExamAccessibilityByStandardExamId,
  patchStandardExamAccessibility,
  retrieveStandardExamAccessibilityByStandardExamId,
} from "../../requests/CatchtivityRequests";
import {
  queryCampusListByBrandId,
  queryInstitutionListByCampusIdList,
} from "../../requests/ManagementRequests";
import {
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import BaseImage from "../../components/images/BaseImage";
import CreateButton from "../../components/buttons/CreateButton";
import {
  retrieveBrandDTOOptionList,
  retrieveCampusDTOOptionList,
} from "../../utilization/ManagementUtilization";

const StandardExamAccessibilityScreen = () => {
  const { standardExamId } = useParams();
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
  } = useAuth();
  const [standardExamAccessibility, setStandardExamAccessibility] = useState({
    id: null,
  });
  const [selectedBrandId, setSelectedBrandId] = useState(userProfileBrand.id);
  const [brandDTOList, setBrandDTOList] = useState([userProfileBrand]);
  const [campusDTOList, setCampusDTOList] = useState([]);
  const [institutionDTOList, setInstitutionDTOList] = useState([]);
  const [selectedCampusId, setSelectedCampusId] = useState("DEFAULT_OPTION");
  const [campusIdList, setCampusIdList] = useState([]);
  const [selectedInstitutionId, setSelectedInstitutionId] =
    useState("DEFAULT_OPTION");
  const [institutionIdList, setInstitutionIdList] = useState([]);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetStandardExamAccessibility = async () => {
      const { data, err } =
        await retrieveStandardExamAccessibilityByStandardExamId(standardExamId);
      if (err) {
        console.log(err);
      } else {
        setStandardExamAccessibility(data);
      }
    };
    if (checkViewPermissionGranted("standard_exam_accessibility")) {
      if (standardExamId) {
        retrieveAndSetStandardExamAccessibility();
      }
    } else {
      navigate("/permission-error");
    }
  }, [standardExamId]);

  useEffect(() => {
    if (standardExamAccessibility.id) {
      if (standardExamAccessibility.brandIdSet.length > 0) {
        setSelectedBrandId(standardExamAccessibility.brandIdSet[0]);
      } else {
        setSelectedBrandId("DEFAULT_OPTION");
      }
      setCampusIdList(standardExamAccessibility.campusIdSet);
      setInstitutionIdList(standardExamAccessibility.institutionIdSet);
    }
  }, [standardExamAccessibility]);

  useEffect(() => {
    const queryAndSetCampusIdList = async () => {
      const { data, err } = await queryCampusListByBrandId(selectedBrandId);
      if (err) {
        console.log(err);
      } else {
        setCampusDTOList(data);
      }
    };
    if (selectedBrandId !== "DEFAULT_OPTION") {
      if (userProfileCampus) {
        setCampusDTOList([userProfileCampus]);
      } else {
        queryAndSetCampusIdList();
      }
    } else {
      // setCampusDTOList([]);
      // setCampusIdList([]);
    }
  }, [selectedBrandId]);

  useEffect(() => {
    const queryAndSetInstitutionList = async () => {
      const { data, err } = await queryInstitutionListByCampusIdList(
        campusIdList
      );
      if (err) {
        console.log(err);
      } else {
        setInstitutionDTOList(data);
      }
    };
    if (campusIdList.length > 0) {
      if (userProfileInstitution) {
        setInstitutionDTOList([userProfileInstitution]);
      } else {
        queryAndSetInstitutionList();
      }
    } else {
    }
  }, [campusIdList]);

  const retrieveInstitutionDTOOptionList = (campusId) => {
    return institutionDTOList
      .filter(
        (institutionDTO) =>
          parseFloat(institutionDTO.campusDTO.id) === parseFloat(campusId)
      )
      .map((institutionDTO) => ({
        value: institutionDTO.id,
        text: `${institutionDTO.name} (${institutionDTO.campusDTO.name} - ${institutionDTO.campusDTO.brandDTO.name})`,
        fullValue: institutionDTO,
      }));
  };

  const retrieveMultipleCampusDTOOptionList = () => {
    const multipleCampusDTOOptionList = [];
    multipleCampusDTOOptionList.push(
      ...retrieveCampusDTOOptionList(campusDTOList)
    );
    return multipleCampusDTOOptionList;
  };

  const retrieveMultipleInstitutionDTOOptionList = () => {
    const multipleInstitutionDTOOptionList = [];
    campusIdList.forEach((campusId) => {
      multipleInstitutionDTOOptionList.push(
        ...retrieveInstitutionDTOOptionList(campusId)
      );
    });
    return multipleInstitutionDTOOptionList;
  };

  const checkCommonValidation = () => {
    if (selectedBrandId === "DEFAULT_OPTION") {
      return false;
    }
    return true;
  };

  const handleOnChange = (type, value) => {
    if (type === "brandId") {
      setSelectedCampusId("DEFAULT_OPTION");
      setSelectedInstitutionId("DEFAULT_OPTION");
      setSelectedBrandId(value);
    } else if (type === "campusId") {
      setSelectedInstitutionId("DEFAULT_OPTION");
      setSelectedCampusId(value);
    } else if (type === "campusIdAdd") {
      handleAddCampusToStandardExamAccessibilityOnClick(value);
    } else if (type === "institutionId") {
      setSelectedInstitutionId(value);
    } else if (type === "institutionIdAdd") {
      handleAddInstitutionToStandardExamAccessibilityOnClick(value);
    } else {
    }
  };

  const handleAddCampusToStandardExamAccessibilityOnClick = (
    selectedCampusId
  ) => {
    if (selectedCampusId !== "DEFAULT_OPTION") {
      const foundIndex = campusIdList.findIndex(
        (campusId) => parseFloat(campusId) === parseFloat(selectedCampusId)
      );
      if (foundIndex === -1) {
        campusIdList.push(selectedCampusId);
        setCampusIdList(JSON.parse(JSON.stringify(campusIdList)));
        setSelectedCampusId("DEFAULT_OPTION");
      }
    }
  };

  const handleAddInstitutionToStandardExamAccessibilityOnClick = (
    selectedInstitutionId
  ) => {
    if (selectedInstitutionId !== "DEFAULT_OPTION") {
      const foundIndex = institutionIdList.findIndex(
        (institutionId) =>
          parseFloat(institutionId) === parseFloat(selectedInstitutionId)
      );
      if (foundIndex === -1) {
        institutionIdList.push(selectedInstitutionId);
        setInstitutionIdList(JSON.parse(JSON.stringify(institutionIdList)));
        setSelectedInstitutionId("DEFAULT_OPTION");
      }
    }
  };

  const handleRemoveCampusFromStandardExamAccessibilityOnClick = (campusId) => {
    const foundIndex = campusIdList.findIndex(
      (currentCampusId) => parseFloat(currentCampusId) === parseFloat(campusId)
    );
    if (foundIndex > -1) {
      campusIdList.splice(foundIndex, 1);
      setCampusIdList(JSON.parse(JSON.stringify(campusIdList)));
      setInstitutionIdList([]);
    }
  };

  const handleRemoveInstitutionFromStandardExamAccessibilityOnClick = (
    institutionId
  ) => {
    const foundIndex = institutionIdList.findIndex(
      (currentInstitutionId) =>
        parseFloat(currentInstitutionId) === parseFloat(institutionId)
    );
    if (foundIndex > -1) {
      institutionIdList.splice(foundIndex, 1);
      setInstitutionIdList(JSON.parse(JSON.stringify(institutionIdList)));
    }
  };

  const handleUpdateStandardExamAccessibilityOnClick = async () => {
    if (checkUpdatePermissionGranted("standard_exam_accessibility")) {
      if (checkCommonValidation()) {
        const currentStandardExamAccessibility = {
          id: standardExamAccessibility.id,
          userId: userInformation.id,
          userProfileId: userProfile.id,
          brandIdList: [selectedBrandId],
          campusIdList,
          institutionIdList,
          standardExamId,
        };
        const { data, err } = await patchStandardExamAccessibility(
          currentStandardExamAccessibility
        );
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          navigate("/standard-exams");
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleCreateStandardExamAccessibilityOnClick = async () => {
    if (checkInsertPermissionGranted("standard_exam_accessibility")) {
      if (checkCommonValidation()) {
        const newStandardExamAccessibility = {
          userId: userInformation.id,
          userProfileId: userProfile.id,
          brandIdSet: [selectedBrandId],
          campusIdSet: campusIdList,
          institutionIdSet: institutionIdList,
          standardExam: { id: standardExamId },
        };
        const { data, err } =
          await createStandardExamAccessibilityByStandardExamId(
            newStandardExamAccessibility
          );
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          navigate("/standard-exams");
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const RenderSingleCampusBrandItem = (brandId, campusId) => {
    let foundCampusDTO = retrieveCampusDTOOptionList(campusDTOList).find(
      (campusOption) => parseFloat(campusOption.value) === parseFloat(campusId)
    );
    if (foundCampusDTO) {
      foundCampusDTO = foundCampusDTO.fullValue;
      if (!foundCampusDTO) {
        foundCampusDTO = {
          id: campusId,
          name: i18n.t("unknown"),
          description: i18n.t("unknown"),
        };
      }
      return (
        <div className="w-full" key={`${brandId}${campusId}`}>
          <div className="flex flex-row justify-between items-center my-2 w-full">
            <div className="w-1/6">
              <div className="flex flex-row">
                <Tooltip id="item-descriptions-in-standardExam-accessibility" />
                <div className="mr-2">
                  <BaseImage
                    size="small"
                    src="/icons/info.png"
                    alt="info"
                    dataToolTipId="item-descriptions-in-standardExam-accessibility"
                    dataToolTipContent={foundCampusDTO.description}
                    dataToolTipPlace="top"
                    dataToolTipVariant="info"
                  />
                </div>
                <p className="mx-1">{foundCampusDTO.name}</p>
              </div>
            </div>
            <div className="w-1/12">
              <p className="mx-1">{foundCampusDTO.brandDTO.name}</p>
            </div>
            <div className="w-1/12"></div>
            <div className="mx-2">
              <BaseImage
                src="/icons/remove-red.png"
                alt="remove-red"
                size="small"
                onClick={() => {
                  handleRemoveCampusFromStandardExamAccessibilityOnClick(
                    foundCampusDTO.id
                  );
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const RenderSingleCampusItem = (campusId) => {
    return RenderSingleCampusBrandItem(selectedBrandId, campusId);
  };

  const RenderSingleInstitutionCampusBrandItem = (
    brandId,
    campusId,
    institutionId
  ) => {
    let foundInstitutionDTO = retrieveInstitutionDTOOptionList(campusId).find(
      (institutionOption) =>
        parseFloat(institutionOption.value) === parseFloat(institutionId)
    );
    if (foundInstitutionDTO) {
      foundInstitutionDTO = foundInstitutionDTO.fullValue;
      if (!foundInstitutionDTO) {
        foundInstitutionDTO = {
          id: institutionId,
          name: i18n.t("unknown"),
          description: i18n.t("unknown"),
        };
      }
      return (
        <div className="w-full" key={`${brandId}${campusId}${institutionId}`}>
          <div className="flex flex-row justify-between items-center my-2 w-full">
            <div className="w-1/6">
              <div className="flex flex-row">
                <Tooltip id="item-descriptions-in-standardExam-accessibility" />
                <div className="mr-2">
                  <BaseImage
                    size="small"
                    src="/icons/info.png"
                    alt="info"
                    dataToolTipId="item-descriptions-in-standardExam-accessibility"
                    dataToolTipContent={foundInstitutionDTO.description}
                    dataToolTipPlace="top"
                    dataToolTipVariant="info"
                  />
                </div>
                <p className="mx-1">{foundInstitutionDTO.name}</p>
              </div>
            </div>
            <div className="w-1/12">
              <p className="mx-1">{foundInstitutionDTO.campusDTO.name}</p>
            </div>
            <div className="w-1/12">
              <p className="mx-1">
                {foundInstitutionDTO.campusDTO.brandDTO.name}
              </p>
            </div>

            <div className="mx-2">
              <BaseImage
                src="/icons/remove-red.png"
                alt="remove-red"
                size="small"
                onClick={() => {
                  handleRemoveInstitutionFromStandardExamAccessibilityOnClick(
                    foundInstitutionDTO.id
                  );
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const RenderSingleInstitutionItem = (institutionId) => {
    return campusIdList.map((campusId) => {
      const foundCampus = retrieveCampusDTOOptionList(campusDTOList).find(
        (campusDTOOption) => campusDTOOption.value === parseFloat(campusId)
      );
      return RenderSingleInstitutionCampusBrandItem(
        selectedBrandId,
        foundCampus.value,
        institutionId
      );
    });
  };

  const RenderMainContent = () => {
    return (
      <FullCard isShadowed={true}>
        <div className="flex flex-col items-center justify-center">
          <div className="w-full">
            <p className="font-semibold text-lg">
              {i18n.t("standard_exam_accessibility_information")}
            </p>
          </div>
          <div className="w-full flex flex-row items-center">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("brand_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedBrandId}
                optionList={retrieveBrandDTOOptionList(brandDTOList)}
                onChange={(event) => {
                  handleOnChange("brandId", event.target.value);
                }}
              />
            </div>
          </div>

          <div className="w-full flex flex-row items-center">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("campus_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedCampusId}
                optionList={retrieveMultipleCampusDTOOptionList()}
                onChange={(event) => {
                  handleOnChange("campusIdAdd", event.target.value);
                }}
              />
            </div>
          </div>
          {campusIdList.map((campusId) => (
            <div className="ml-auto w-5/6" key={campusId}>
              {RenderSingleCampusItem(campusId)}
            </div>
          ))}

          <div className="w-full flex flex-row items-center">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("institution_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedInstitutionId}
                optionList={retrieveMultipleInstitutionDTOOptionList()}
                onChange={(event) => {
                  handleOnChange("institutionIdAdd", event.target.value);
                }}
              />
            </div>
          </div>

          {institutionIdList.map((institutionId) => (
            <div className="ml-auto w-5/6" key={institutionId}>
              {RenderSingleInstitutionItem(institutionId)}
            </div>
          ))}

          <div className="mt-4 ml-auto">
            {standardExamAccessibility.id ? (
              <SecondaryButton
                title={i18n.t("update")}
                size="medium"
                onClick={handleUpdateStandardExamAccessibilityOnClick}
              />
            ) : (
              <CreateButton
                title={i18n.t("create")}
                size="medium"
                onClick={handleCreateStandardExamAccessibilityOnClick}
              />
            )}
          </div>
        </div>
      </FullCard>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default StandardExamAccessibilityScreen;
