import { useNavigate } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import { useEffect, useState } from "react";
import FullCard from "../../components/cards/FullCard";
import i18n from "../../language/i18n";
import DividerLine from "../../components/divider/DividerLine";
import { useAuth } from "../../context/UserContextProvider";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import InputGroup from "../../components/groups/InputGroup";
import { retrieveGenderOptionList } from "../../utilization/ManagementUtilization";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import {
  patchAndChangeUserPassword,
  patchUser,
  patchUserOnlyNotification,
  patchUserProfile,
  sendChangePasswordEmail,
  sendEmailVerificationEmail,
} from "../../requests/ManagementRequests";
import { retrieveCoterieTypeOptionList } from "../../utilization/ManagementUtilization";
import Switch from "react-switch";
import { NotificationManager } from "react-notifications";
import BaseModal from "../../components/modal/BaseModal";
import BaseImage from "../../components/images/BaseImage";
import CancelButton from "../../components/buttons/CancelButton";
import SingleInformationIconTextItem from "../../components/infos/SingleInformationIconTextItem";

const UserSettingsScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen, setBoarding } = useApp();
  const {
    language,
    handleStateLogout,
    userInformation,
    setUserInformation,
    userProfile,
    setUserProfile,
  } = useAuth();
  const [firstName, setFirstName] = useState(userInformation.firstName);
  const [lastName, setLastName] = useState(userInformation.lastName);
  const [email, setEmail] = useState(userInformation.email);
  const [phoneNumber, setPhoneNumber] = useState(userInformation.phoneNumber);
  const [dateOfBirth, setDateOfBirth] = useState(
    new Date(userInformation.dateOfBirth).toISOString().split("T")[0]
  );
  const [selectedGender, setSelectedGender] = useState(userInformation.gender);
  const [showUpdateUserInformationModal, setShowUpdateUserInformationModal] =
    useState(false);

  const [name, setName] = useState(userProfile.name);
  const [description, setDescription] = useState(userProfile.description);
  const [coterieTypeList, setCoterieTypeList] = useState(
    userProfile.coterieTypeList
  );
  const [showUpdateUserProfileModal, setShowUpdateUserProfileModal] =
    useState(false);
  const [showUpdateUserPasswordModal, setShowUpdateUserPasswordModal] =
    useState(false);
  const [emailPromotionOn, setEmailPromotionOn] = useState(
    userInformation.emailPromotionOn
  );
  const [smsPromotionOn, setSmsPromotionOn] = useState(
    userInformation.smsPromotionOn
  );
  const [isEmailVerificationSend, setIsEmailVerificationSend] = useState(
    userInformation.emailVerified
  );
  const [changePasswordState, setChangePasswordState] = useState(0);
  const [code, setCode] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [errorMap, setErrorMap] = useState({
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    dateOfBirth: null,
    gender: null,
    name: null,
    description: null,
    code: null,
    oldPassword: null,
    newPassword: null,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  const constructCoterieTypeText = () => {
    let text = "";
    if (userProfile.role === "STAFF") {
      text = i18n.t(userProfile.coterieType);
    } else if (userProfile.role === "INDIVIDUAL") {
      userProfile.coterieTypeList.forEach((coterieType) => {
        text += i18n.t(coterieType) + ", ";
      });
      text = text.substring(0, text.length - 2);
    }
    return text;
  };

  const filterCoterieTypeOptionList = () => {
    return retrieveCoterieTypeOptionList().filter(
      (coterieTypeOption) => coterieTypeOption.value !== "MANAGEMENT"
    );
  };

  const checkCommonUserValidation = () => {
    if (
      !firstName ||
      firstName.trim() === "" ||
      !lastName ||
      lastName.trim() === "" ||
      !email ||
      email.trim() === "" ||
      !phoneNumber ||
      phoneNumber.trim() === "" ||
      !dateOfBirth ||
      dateOfBirth.trim() === "" ||
      selectedGender === "DEFAULT_OPTION"
    ) {
      const currentErrorMap = {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        gender: null,
        dateOfBirth: null,
      };
      if (!firstName || firstName.trim() === "") {
        currentErrorMap.firstName = i18n.t("first_name_required_field");
      }
      if (!lastName || lastName.trim() === "") {
        currentErrorMap.lastName = i18n.t("last_name_required_field");
      }
      if (!email || email.trim() === "") {
        currentErrorMap.email = i18n.t("email_required_field");
      }
      if (!phoneNumber || phoneNumber.trim() === "") {
        currentErrorMap.phoneNumber = i18n.t("phone_number_required_field");
      }
      if (!dateOfBirth || dateOfBirth.trim() === "") {
        currentErrorMap.dateOfBirth = i18n.t("date_of_birth_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const checkCommonUserProfileValidation = () => {
    if (
      !name ||
      name.trim() === "" ||
      !description ||
      description.trim() === ""
    ) {
      const currentErrorMap = {
        name: null,
        description: null,
      };
      if (!name || name.trim() === "") {
        currentErrorMap.name = i18n.t("name_required_field");
      }
      if (!description || description.trim() === "") {
        currentErrorMap.description = i18n.t("description_required_field");
      }

      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const checkCommonUserPasswordValidation = () => {
    if (
      !code ||
      code.trim() === "" ||
      !oldPassword ||
      oldPassword.trim() === "" ||
      !newPassword ||
      newPassword.trim() === ""
    ) {
      const currentErrorMap = {
        code: null,
        oldPassword: null,
        newPassword: null,
      };
      if (!code || code.trim() === "") {
        currentErrorMap.code = i18n.t("code_required_field");
      }
      if (!oldPassword || oldPassword.trim() === "") {
        currentErrorMap.oldPassword = i18n.t("old_password_required_field");
      }
      if (!newPassword || newPassword.trim() === "") {
        currentErrorMap.newPassword = i18n.t("new_password_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const handleOnChange = (type, value) => {
    if (type === "firstName") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          firstName: i18n.t("first_name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          firstName: null,
        }));
      }
      setFirstName(value);
    } else if (type === "lastName") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          lastName: i18n.t("last_name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          lastName: null,
        }));
      }
      setLastName(value);
    } else if (type === "email") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          email: i18n.t("email_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          email: null,
        }));
      }
      setEmail(value);
    } else if (type === "phoneNumber") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          phoneNumber: i18n.t("phone_number_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          phoneNumber: null,
        }));
      }
      setPhoneNumber(value);
    } else if (type === "dateOfBirth") {
      setDateOfBirth(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        dateOfBirth: null,
      }));
    } else if (type === "gender") {
      setSelectedGender(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        gender: null,
      }));
    } else if (type === "name") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: i18n.t("name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: null,
        }));
      }
      setName(value);
    } else if (type === "description") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: i18n.t("description_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: null,
        }));
      }
      setDescription(value);
    } else if (type === "code") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          code: i18n.t("code_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          code: null,
        }));
      }
      setCode(value);
    } else if (type === "oldPassword") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          oldPassword: i18n.t("old_password_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          oldPassword: null,
        }));
      }
      setOldPassword(value);
    } else if (type === "newPassword") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          newPassword: i18n.t("new_password_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          newPassword: null,
        }));
      }
      setNewPassword(value);
    }
  };

  const handleAddCoterieTypeOnClick = (newCoterieType) => {
    if (
      coterieTypeList.findIndex(
        (coterieType) => coterieType === newCoterieType
      ) === -1
    ) {
      coterieTypeList.push(newCoterieType);
      setCoterieTypeList(JSON.parse(JSON.stringify(coterieTypeList)));
    }
  };

  const handleRemoveCoterieTypeOnClick = (currentCoterieType) => {
    const foundIndex = coterieTypeList.findIndex(
      (coterieType) => coterieType === currentCoterieType
    );
    if (foundIndex !== -1) {
      coterieTypeList.splice(foundIndex, 1);
      setCoterieTypeList(JSON.parse(JSON.stringify(coterieTypeList)));
    }
  };

  const handleUpdateUserOnClick = async () => {
    if (checkCommonUserValidation()) {
      const currentUser = {
        id: userInformation.id,
        firstName,
        lastName,
        email,
        phoneNumber,
        dateOfBirth: new Date(dateOfBirth).getTime(),
        gender: selectedGender,
        enabled: userInformation.enabled,
      };
      const { data, err } = await patchUser(currentUser);
      if (err) {
        console.log(err);
      } else {
        setUserInformation(data);
        setShowUpdateUserInformationModal(false);
      }
    }
  };

  const handleUpdateUserProfileOnClick = async () => {
    if (checkCommonUserProfileValidation()) {
      const currentUserProfile = {
        id: userProfile.id,
        role: userProfile.role,
        name,
        description,
        coterieType: userProfile.coterieType,
        seasonId: userProfile.seasonDTO.id,
        brandIdList: userProfile.brandDTOList.map((brandDTO) => brandDTO.id),
        campusIdList: userProfile.campusDTOList.map(
          (campusDTO) => campusDTO.id
        ),
        institutionIdList: userProfile.institutionDTOList.map(
          (institutionDTO) => institutionDTO.id
        ),
        seasonIdList: userProfile.seasonDTOList.map(
          (seasonDTO) => seasonDTO.id
        ),
        gradeIdList: userProfile.gradeDTOList.map((gradeDTO) => gradeDTO.id),
        branchIdList: userProfile.branchDTOList.map(
          (branchDTO) => branchDTO.id
        ),
        coterieTypeList,
      };
      const { data, err } = await patchUserProfile(currentUserProfile);
      if (err) {
        console.log(err);
      } else {
        if (userProfile.role === "STAFF") {
          setUserProfile({ ...userProfile, name, description });
        } else {
          setUserProfile({
            ...userProfile,
            name,
            description,
            coterieTypeList,
          });
        }

        setShowUpdateUserProfileModal(false);
      }
    }
  };

  const handleChangeNotificationOnClick = async (type, val) => {
    let currentEmailPromotionOn = emailPromotionOn;
    let currentSmsPromotionOn = smsPromotionOn;
    if (type === "EMAIL") {
      currentEmailPromotionOn = val;
      setEmailPromotionOn(val);
    } else if (type === "SMS") {
      currentSmsPromotionOn = val;
      setSmsPromotionOn(val);
    }
    const { data, err } = await patchUserOnlyNotification({
      id: userInformation.id,
      emailPromotionOn: currentEmailPromotionOn,
      smsPromotionOn: currentSmsPromotionOn,
    });
    if (err) {
      console.log(err);
    } else {
      setEmailPromotionOn(data.emailPromotionOn);
      setSmsPromotionOn(data.smsPromotionOn);
    }
  };

  const handleLogoutOnClick = () => {
    navigate("/");
    setBoarding("LANDING");
    handleStateLogout();
    NotificationManager.success(i18n.t("logout_successful_text"));
  };

  const handleChangePasswordSendCodeOnClick = async () => {
    const { data, err } = await sendChangePasswordEmail({
      id: userInformation.id,
      firstName: userInformation.firstName,
      email: userInformation.email,
    });
    if (err) {
      console.log(err);
    } else {
      setChangePasswordState(1);
    }
  };

  const handleChangePasswordWithCodeOnClick = async () => {
    if (checkCommonUserPasswordValidation()) {
      const { data, err } = await patchAndChangeUserPassword({
        userId: userInformation.id,
        type: "CHANGE_PASSWORD",
        code,
        oldPassword,
        newPassword,
      });
      if (err) {
        if (err.response.status === 400) {
          NotificationManager.error(i18n.t(err.response.data));
        }
      } else {
        handleLogoutOnClick();
      }
    }
  };

  const RenderCoterieTypeList = () => {
    return (
      <div className="w-full flex flex-row items-center">
        <div className="w-catchup-input-group-title">
          <p>{i18n.t("coteries")}</p>
        </div>
        <div className="flex-1 flex flex-row flex-wrap gap-x-4 gap-y-2 my-5">
          {filterCoterieTypeOptionList().map((coterieTypeOption) => {
            if (
              coterieTypeList.findIndex(
                (coterieType) => coterieType === coterieTypeOption.value
              ) > -1
            ) {
              return (
                <div
                  className="flex flex-row items-center gap-x-2 cursor-pointer"
                  onClick={() => {
                    handleRemoveCoterieTypeOnClick(coterieTypeOption.value);
                  }}
                >
                  <BaseImage
                    size="medium"
                    src="/icons/checkbox.png"
                    alt="checkbox"
                  />
                  <div className="">
                    <p className="">{coterieTypeOption.text}</p>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  className="flex flex-row items-center gap-x-2 cursor-pointer"
                  onClick={() => {
                    handleAddCoterieTypeOnClick(coterieTypeOption.value);
                  }}
                >
                  <BaseImage
                    size="small"
                    src="/icons/checkbox-empty.png"
                    alt="checkbox"
                  />
                  <div className="text-md">
                    <p>{coterieTypeOption.text}</p>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  };

  const RenderSingleKeyValueChangeableItem = (
    type,
    key,
    valueText,
    value,
    optionList
  ) => {
    return (
      <div className="flex flex-row items-center gap-x-4 my-1">
        <div className="w-catchup-input-group-title">
          <p className="">{key}:</p>
        </div>
        <div className="flex-1">
          <InputGroup
            type={type}
            value={value}
            optionList={optionList}
            onChange={(e) => {
              handleOnChange(valueText, e.target.value);
            }}
            errorText={errorMap[valueText]}
          />
        </div>
      </div>
    );
  };

  const RenderSingleKeyValueItem = (key, value, error, icon) => {
    return (
      <div className={`${error ? "" : ""} flex flex-row gap-x-4 my-1`}>
        <div>
          <p className={`${error ? "text-catchup-red" : ""} font-semibold`}>
            {key}:
          </p>
        </div>
        <div className="flex-1 flex flex-row items-center gap-x-2">
          <p className={`${error ? "text-catchup-red font-semibold" : ""} `}>
            {value}
          </p>
          {icon}
        </div>
      </div>
    );
  };

  const RenderUpdateUserInformationModal = () => {
    return (
      <BaseModal
        isOpen={showUpdateUserInformationModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowUpdateUserInformationModal(false);
        }}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            <div className="flex flex-row items-center">
              <p className="font-semibold text-lg">
                {i18n.t("update_user_information")}
              </p>
            </div>
            <DividerLine />
            <div className="flex flex-row flex-wrap my-4">
              <div className="w-full">
                {RenderSingleKeyValueChangeableItem(
                  "text",
                  i18n.t("first_name"),
                  "firstName",
                  firstName
                )}
              </div>
              <div className="w-full">
                {RenderSingleKeyValueChangeableItem(
                  "text",
                  i18n.t("last_name"),
                  "lastName",
                  lastName
                )}
              </div>
              <div className="w-full">
                {RenderSingleKeyValueChangeableItem(
                  "text",
                  i18n.t("email"),
                  "email",
                  email
                )}
              </div>
              <div className="w-full">
                {RenderSingleKeyValueChangeableItem(
                  "select",
                  i18n.t("gender"),
                  "gender",
                  selectedGender,
                  retrieveGenderOptionList()
                )}
              </div>
              <div className="w-full">
                {RenderSingleKeyValueChangeableItem(
                  "text",
                  i18n.t("phone_number"),
                  "phoneNumber",
                  phoneNumber
                )}
              </div>
              <div className="w-full">
                {RenderSingleKeyValueChangeableItem(
                  "date",
                  i18n.t("date_of_birth"),
                  "dateOfBirth",
                  dateOfBirth
                )}
              </div>
              <div className="ml-auto mt-5">
                <SecondaryButton
                  title={i18n.t("update")}
                  size="medium"
                  onClick={handleUpdateUserOnClick}
                />
              </div>
            </div>
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  const RenderUpdateUserProfileModal = () => {
    return (
      <BaseModal
        isOpen={showUpdateUserProfileModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowUpdateUserProfileModal(false);
        }}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            <div className="flex flex-row items-center">
              <p className="font-semibold text-lg">
                {i18n.t("update_user_profile")}
              </p>
            </div>
            <DividerLine />
            <div className="w-full">
              {RenderSingleKeyValueChangeableItem(
                "text",
                i18n.t("name"),
                "name",
                name
              )}
            </div>

            <div className="w-full">
              {RenderSingleKeyValueChangeableItem(
                "textarea",
                i18n.t("description"),
                "description",
                description
              )}
            </div>

            {userProfile.role === "INDIVIDUAL" ? RenderCoterieTypeList() : null}

            <div className="ml-auto mt-5">
              <SecondaryButton
                title={i18n.t("update")}
                size="medium"
                onClick={handleUpdateUserProfileOnClick}
              />
            </div>
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  const RenderUpdateUserPasswordModal = () => {
    return (
      <BaseModal
        isOpen={showUpdateUserPasswordModal}
        size="small"
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowUpdateUserPasswordModal(false);
          setChangePasswordState(0);
          setCode(null);
          setOldPassword(null);
          setNewPassword(null);
        }}
      >
        <div className="flex-1 flex flex-col">
          <div className="">
            <FullCard>
              <div className="w-full">
                <div className="flex flex-col items-center justify-center gap-y-4">
                  <BaseImage
                    src="/icons/password.png"
                    alt="password"
                    size="large"
                  />
                  <div className="">
                    <p className="text-xl">
                      {i18n.t("steps_follow_to_change_password_text")}
                    </p>
                  </div>
                  <div className="my-2 text-catchup-gray-400">
                    {SingleInformationIconTextItem(
                      i18n.t("steps_follow_to_change_password_step_1_text")
                    )}
                    {SingleInformationIconTextItem(
                      i18n.t("steps_follow_to_change_password_step_2_text")
                    )}
                    {SingleInformationIconTextItem(
                      i18n.t("steps_follow_to_change_password_step_3_text")
                    )}
                  </div>
                </div>

                {changePasswordState === 0 ? (
                  <div className="flex-1 flex flex-row items-center justify-center gap-x-4 mt-5">
                    <CancelButton
                      title={i18n.t("cancel")}
                      size="small"
                      onClick={() => {
                        setShowUpdateUserPasswordModal(false);
                        setChangePasswordState(0);
                        setCode(null);
                        setOldPassword(null);
                        setNewPassword(null);
                      }}
                    />
                    <PrimaryButton
                      title={i18n.t("send_code")}
                      size="unlimited"
                      onClick={handleChangePasswordSendCodeOnClick}
                    />
                  </div>
                ) : (
                  <div className="flex flex-row flex-wrap my-4">
                    <div className="w-full">
                      {RenderSingleKeyValueChangeableItem(
                        "text",
                        i18n.t("code"),
                        "code",
                        code
                      )}
                    </div>
                    <div className="w-full">
                      {RenderSingleKeyValueChangeableItem(
                        "password",
                        i18n.t("old_password"),
                        "oldPassword",
                        oldPassword
                      )}
                    </div>
                    <div className="w-full">
                      {RenderSingleKeyValueChangeableItem(
                        "password",
                        i18n.t("new_password"),
                        "newPassword",
                        newPassword
                      )}
                    </div>
                    <div className="flex-1 flex">
                      <div className="mt-5 mx-auto">
                        <PrimaryButton
                          title={i18n.t("change_password")}
                          size="unlimited"
                          onClick={handleChangePasswordWithCodeOnClick}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </FullCard>
          </div>
        </div>
      </BaseModal>
    );
  };

  const RenderMainContent = () => {
    return (
      <FullCard isShadowed={true}>
        <div className="flex flex-col justify-center">
          <div className="flex flex-row justify-between">
            <div className="">
              <p className="font-semibold text-lg">
                {i18n.t("user_information")}
              </p>
            </div>
            <div className="mr-5">
              <BaseImage
                src="/icons/edit.png"
                alt="edit"
                size="small"
                onClick={() => {
                  setShowUpdateUserInformationModal(true);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap my-4">
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderSingleKeyValueItem(
                i18n.t("account_type"),
                i18n.t(userInformation.accountType)
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderSingleKeyValueItem(
                i18n.t("username"),
                userInformation.username
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderSingleKeyValueItem(i18n.t("first_name"), firstName)}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderSingleKeyValueItem(i18n.t("last_name"), lastName)}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderSingleKeyValueItem(
                i18n.t("email"),
                email,
                !userInformation.emailVerified
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderSingleKeyValueItem(
                i18n.t("email_verified"),
                i18n.t(userInformation.emailVerified),
                !userInformation.emailVerified,
                userInformation.emailVerified ? (
                  <BaseImage
                    size="small"
                    src="/icons/checkbox.png"
                    alt="checkbox"
                  />
                ) : (
                  <BaseImage
                    size="small"
                    src="/icons/cross-red.png"
                    alt="cross-red"
                  />
                )
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderSingleKeyValueItem(i18n.t("phone_number"), phoneNumber)}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderSingleKeyValueItem(
                i18n.t("gender"),
                i18n.t(selectedGender)
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderSingleKeyValueItem(
                i18n.t("date_of_birth"),
                new Date(dateOfBirth).toLocaleDateString(language)
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderSingleKeyValueItem(
                i18n.t("user_profile_count"),
                userInformation.userProfileSet.length
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderSingleKeyValueItem(
                i18n.t("created_at"),
                new Date(userInformation.createdAt).toLocaleDateString(language)
              )}
            </div>
          </div>
          {isEmailVerificationSend ? null : (
            <div className="flex flex-row my-2">
              <div
                className="cursor-pointer"
                onClick={async () => {
                  const { data, err } = await sendEmailVerificationEmail({
                    id: userInformation.id,
                    firstName: userInformation.firstName,
                    email: userInformation.email,
                  });
                  if (err) {
                    console.log(err);
                  } else {
                    setIsEmailVerificationSend(true);
                    NotificationManager.success(
                      i18n.t("email_verification_send_successfully_text")
                    );
                  }
                }}
              >
                <p className="italic underline">
                  {i18n.t("resend_email_verification_email_text")}
                </p>
              </div>
            </div>
          )}

          <div className="flex flex-row my-2">
            <div
              className="cursor-pointer"
              onClick={() => {
                if (userInformation.emailVerified) {
                  setShowUpdateUserPasswordModal(true);
                } else {
                  NotificationManager.error(
                    i18n.t("your_email_has_to_be_verified_to_proceed_text")
                  );
                }
              }}
            >
              <p className="italic underline">
                {i18n.t("i_want_to_change_my_password_text")}
              </p>
            </div>
          </div>
        </div>
        <DividerLine />
        <div className="flex flex-col justify-center">
          <div className="flex flex-row justify-between">
            <div className="">
              <p className="font-semibold text-lg">
                {i18n.t("user_profile_information")}
              </p>
            </div>
            <div className="mr-5">
              <BaseImage
                src="/icons/edit.png"
                alt="edit"
                size="small"
                onClick={() => {
                  setShowUpdateUserProfileModal(true);
                }}
              />
            </div>
          </div>

          <div className="flex flex-row flex-wrap my-4">
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderSingleKeyValueItem(i18n.t("profile_name"), name)}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderSingleKeyValueItem(
                i18n.t("type"),
                i18n.t(userProfile.role)
              )}
            </div>
            <div className="w-1/2">
              {RenderSingleKeyValueItem(
                i18n.t("coterie_type"),
                constructCoterieTypeText()
              )}
            </div>

            <div className="w-full">
              {RenderSingleKeyValueItem(
                i18n.t("profile_description"),
                i18n.t(userProfile.description)
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderSingleKeyValueItem(
                i18n.t("created_at"),
                new Date(userProfile.createdAt).toLocaleDateString(language)
              )}
            </div>
          </div>
        </div>
        <DividerLine />
        <div className="flex flex-col justify-center">
          <div className="flex flex-row justify-between">
            <div className="">
              <p className="font-semibold text-lg">
                {i18n.t("notification_information")}
              </p>
            </div>
          </div>
          <div className="w-1/2 my-4">
            <div className="flex-1 flex flex-row justify-between gap-x-4 my-1">
              <div>
                <p className="font-semibold">{i18n.t("email_notification")}</p>
              </div>
              <div className="flex flex-row items-center">
                <Switch
                  onChange={() => {
                    handleChangeNotificationOnClick("EMAIL", !emailPromotionOn);
                  }}
                  checkedIcon={false}
                  offColor={"#e2e8f0"}
                  onColor={"#57C2D3"}
                  offHandleColor={"#57C2D3"}
                  onHandleColor={"#e2e8f0"}
                  uncheckedIcon={false}
                  checked={emailPromotionOn}
                />
              </div>
            </div>
            <div className="flex-1 flex flex-row justify-between gap-x-4 my-1">
              <div>
                <p className="font-semibold">{i18n.t("sms_notification")}</p>
              </div>
              <div className="flex flex-row items-center">
                <Switch
                  onChange={() => {
                    handleChangeNotificationOnClick("SMS", !smsPromotionOn);
                  }}
                  checkedIcon={false}
                  offColor={"#e2e8f0"}
                  onColor={"#57C2D3"}
                  offHandleColor={"#57C2D3"}
                  onHandleColor={"#e2e8f0"}
                  uncheckedIcon={false}
                  checked={smsPromotionOn}
                />
              </div>
            </div>
          </div>
        </div>
      </FullCard>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      {RenderUpdateUserPasswordModal()}
      {RenderUpdateUserInformationModal()}
      {RenderUpdateUserProfileModal()}
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default UserSettingsScreen;
