import { useEffect, useState } from "react";
import BaseCarousel from "../../components/carousel/BaseCarousel";
import { useApp } from "../../context/AppContextProvider";
import i18n from "../../language/i18n";
import FullCard from "../../components/cards/FullCard";
import { useAuth } from "../../context/UserContextProvider";
import RenderInformationCardItem from "../../components/cards/InformationCard";
import ListPlan from "../../components/plans/ListPlan";
import moment from "moment";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import BaseLoading from "../../components/loading/BaseLoading";

const DashboardScreen = () => {
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    language,
    userInformation,
    userProfile,
    userProfileSeason,
    managementData,
    catchtivityData,
    dashboardLoading,
  } = useAuth();
  const [brandContentDTOList, setBrandContentDTOList] = useState([]);
  const [systemContentDTOList, setSystemContentDTOList] = useState([]);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    setBrandContentDTOList([
      {
        id: 0,
        banner: `/banners/banner-brand-${language.toLowerCase()}.png`,
      },
    ]);
    setSystemContentDTOList([
      {
        id: 0,
        banner: `/banners/banner-system-${language.toLowerCase()}.png`,
      },
    ]);
  }, []);

  const RenderBrandContent = () => {
    return brandContentDTOList.map((announcementDTO) => (
      <div key={announcementDTO.id}>
        <BaseImage
          size="custom"
          className="w-full"
          src={announcementDTO.banner}
          alt="announcement"
        />
      </div>
    ));
  };

  const RenderSystemContent = () => {
    return systemContentDTOList.map((newsDTO) => (
      <div className="w-full" key={newsDTO.id}>
        <img src={newsDTO.banner} className="w-full" alt="news" />
      </div>
    ));
  };

  const RenderGenixoUserContent = () => {
    return (
      <FullCard isShadowed={true}>
        <div className="my-4">
          {dashboardLoading ? (
            <BaseLoading
              size="large"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          ) : (
            <>
              <div className="">
                <BaseTitle title={i18n.t("management_statistics")} />
              </div>
              <DividerLine />
              <div className="flex flex-row flex-wrap">
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("active_season_count"),
                    null,
                    <BaseImage
                      src="/icons/season.png"
                      alt="season"
                      size="small"
                    />,
                    managementData.activeSeasonCount,
                    1
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("user_count"),
                    null,
                    <BaseImage
                      src="/icons/users.png"
                      alt="users"
                      size="small"
                    />,
                    managementData.userCount,
                    2
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("staff_user_profile"),
                    null,
                    <BaseImage
                      src="/icons/staff.png"
                      alt="staff"
                      size="small"
                    />,
                    managementData.staffUserProfileCount,
                    3
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("learner_user_profile"),
                    null,
                    <BaseImage
                      src="/icons/learner.png"
                      alt="learner"
                      size="small"
                    />,
                    managementData.learnerUserProfileCount,
                    4
                  )}
                </div>
              </div>
              <div className="mt-4">
                <BaseTitle title={i18n.t("catchtivity_statistics")} />
              </div>
              <DividerLine />
              <div className="flex flex-row flex-wrap">
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("total_activity"),
                    null,
                    <BaseImage
                      src="/icons/activity.png"
                      alt="activity"
                      size="small"
                    />,
                    catchtivityData.activityCount,
                    1
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("total_catchtivity"),
                    null,
                    <BaseImage
                      src="/icons/catchtivity.png"
                      alt="catchtivity"
                      size="small"
                    />,
                    catchtivityData.catchtivityCount,
                    2
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("total_catchxam"),
                    null,
                    <BaseImage
                      src="/icons/catchxam.png"
                      alt="catchxam"
                      size="small"
                    />,
                    catchtivityData.catchxamCount,
                    3
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </FullCard>
    );
  };

  const RenderStaffUserContent = () => {
    return (
      <FullCard isShadowed={true}>
        <div className="my-4">
          {dashboardLoading ? (
            <BaseLoading
              size="large"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          ) : (
            <>
              <div className="">
                <BaseTitle title={i18n.t("management_statistics")} />
              </div>
              <DividerLine />
              <div className="flex flex-row flex-wrap">
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("active_season_count"),
                    null,
                    <BaseImage
                      src="/icons/season.png"
                      alt="season"
                      size="small"
                    />,
                    managementData.activeSeasonCount,
                    1
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("user_count"),
                    null,
                    <BaseImage
                      src="/icons/users.png"
                      alt="users"
                      size="small"
                    />,
                    managementData.userCount,
                    2
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("staff_user_profile"),
                    null,
                    <BaseImage
                      src="/icons/staff.png"
                      alt="staff"
                      size="small"
                    />,
                    managementData.staffUserProfileCount,
                    3
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("learner_user_profile"),
                    null,
                    <BaseImage
                      src="/icons/learner.png"
                      alt="learner"
                      size="small"
                    />,
                    managementData.learnerUserProfileCount,
                    4
                  )}
                </div>
              </div>
              <div className="mt-4">
                <BaseTitle title={i18n.t("catchtivity_statistics")} />
              </div>
              <DividerLine />
              {userProfileSeason ? (
                <div className="flex flex-row flex-wrap">
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("total"),
                      i18n.t("activity_catchtivity"),
                      <BaseImage
                        src="/icons/activity.png"
                        alt="activity"
                        size="small"
                      />,
                      `${catchtivityData.catchtivityActivityCount} / ${catchtivityData.catchtivityCount}`,
                      1
                    )}
                  </div>
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("expired"),
                      i18n.t("catchtivity_application"),
                      <BaseImage
                        src="/icons/catchtivity.png"
                        alt="catchtivity"
                        size="small"
                      />,
                      catchtivityData.expiredCatchtivityApplicationCount,
                      2
                    )}
                  </div>
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("current"),
                      i18n.t("catchtivity_application"),
                      <BaseImage
                        src="/icons/catchtivity.png"
                        alt="catchtivity"
                        size="small"
                      />,
                      catchtivityData.currentCatchtivityApplicationCount,
                      3
                    )}
                  </div>
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("incoming"),
                      i18n.t("catchtivity_application"),
                      <BaseImage
                        src="/icons/catchtivity.png"
                        alt="catchtivity"
                        size="small"
                      />,
                      catchtivityData.incomingCatchtivityApplicationCount,
                      4
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex flex-row flex-wrap">
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("total"),
                      i18n.t("activity_count"),
                      <BaseImage
                        src="/icons/activity.png"
                        alt="activity"
                        size="small"
                      />,
                      catchtivityData.catchtivityActivityCount,
                      1
                    )}
                  </div>
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("total"),
                      i18n.t("catchtivity_count"),
                      <BaseImage
                        src="/icons/catchtivity.png"
                        alt="catchtivity"
                        size="small"
                      />,
                      catchtivityData.catchtivityCount,
                      2
                    )}
                  </div>
                </div>
              )}

              <div className="mt-4">
                <BaseTitle title={i18n.t("catchxam_statistics")} />
              </div>
              <DividerLine />
              {userProfileSeason ? (
                <div className="flex flex-row flex-wrap">
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("total"),
                      i18n.t("activity_catchxam"),
                      <BaseImage
                        src="/icons/activity.png"
                        alt="activity"
                        size="small"
                      />,
                      `${catchtivityData.catchxamActivityCount} / ${catchtivityData.catchxamCount}`,
                      1
                    )}
                  </div>
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("expired"),
                      i18n.t("catchxam_application"),
                      <BaseImage
                        src="/icons/catchxam.png"
                        alt="catchxam"
                        size="small"
                      />,
                      catchtivityData.expiredCatchxamApplicationCount,
                      2
                    )}
                  </div>
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("current"),
                      i18n.t("catchxam_application"),
                      <BaseImage
                        src="/icons/catchxam.png"
                        alt="catchxam"
                        size="small"
                      />,
                      catchtivityData.currentCatchxamApplicationCount,
                      3
                    )}
                  </div>
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("incoming"),
                      i18n.t("catchxam_application"),
                      <BaseImage
                        src="/icons/catchxam.png"
                        alt="catchxam"
                        size="small"
                      />,
                      catchtivityData.incomingCatchxamApplicationCount,
                      4
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex flex-row flex-wrap">
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("total"),
                      i18n.t("activity_count"),
                      <BaseImage
                        src="/icons/activity.png"
                        alt="activity"
                        size="small"
                      />,
                      catchtivityData.catchxamActivityCount,
                      1
                    )}
                  </div>
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                    {RenderInformationCardItem(
                      i18n.t("total"),
                      i18n.t("catchxam_count"),
                      <BaseImage
                        src="/icons/activity.png"
                        alt="activity"
                        size="small"
                      />,
                      catchtivityData.catchxamCount,
                      2
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </FullCard>
    );
  };

  const RenderIndividualUserContent = () => {
    return (
      <FullCard isShadowed={true}>
        <div className="my-4">
          {dashboardLoading ? (
            <BaseLoading
              size="large"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          ) : (
            <>
              <div className="">
                <BaseTitle title={i18n.t("management_statistics")} />
              </div>
              <DividerLine />
              <div className="flex flex-row flex-wrap">
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("active_season_count"),
                    null,
                    <BaseImage
                      src="/icons/season.png"
                      alt="season"
                      size="small"
                    />,
                    managementData.activeSeasonCount,
                    1
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("user_count"),
                    null,
                    <BaseImage
                      src="/icons/users.png"
                      alt="users"
                      size="small"
                    />,
                    managementData.userCount,
                    2
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("staff_user_profile"),
                    null,
                    <BaseImage
                      src="/icons/staff.png"
                      alt="staff"
                      size="small"
                    />,
                    managementData.staffUserProfileCount,
                    3
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("learner_user_profile"),
                    null,
                    <BaseImage
                      src="/icons/learner.png"
                      alt="learner"
                      size="small"
                    />,
                    managementData.learnerUserProfileCount,
                    4
                  )}
                </div>
              </div>
              <div className="mt-4">
                <BaseTitle title={i18n.t("catchtivity_statistics")} />
              </div>
              <DividerLine />
              <div className="flex flex-row flex-wrap">
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("total"),
                    i18n.t("activity_catchtivity"),
                    <BaseImage
                      src="/icons/activity.png"
                      alt="activity"
                      size="small"
                    />,
                    `${catchtivityData.catchtivityActivityCount} / ${catchtivityData.catchtivityCount}`,
                    1
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("expired"),
                    i18n.t("catchtivity_application"),
                    <BaseImage
                      src="/icons/catchtivity.png"
                      alt="catchtivity"
                      size="small"
                    />,
                    catchtivityData.expiredCatchtivityApplicationCount,
                    2
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("current"),
                    i18n.t("catchtivity_application"),
                    <BaseImage
                      src="/icons/catchtivity.png"
                      alt="catchtivity"
                      size="small"
                    />,
                    catchtivityData.currentCatchtivityApplicationCount,
                    3
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("incoming"),
                    i18n.t("catchtivity_application"),
                    <BaseImage
                      src="/icons/catchtivity.png"
                      alt="catchtivity"
                      size="small"
                    />,
                    catchtivityData.incomingCatchtivityApplicationCount,
                    4
                  )}
                </div>
              </div>
              <div className="mt-4">
                <BaseTitle title={i18n.t("catchxam_statistics")} />
              </div>
              <DividerLine />
              <div className="flex flex-row flex-wrap">
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("total"),
                    i18n.t("activity_catchxam"),
                    <BaseImage
                      src="/icons/activity.png"
                      alt="activity"
                      size="small"
                    />,
                    `${catchtivityData.catchxamActivityCount} / ${catchtivityData.catchxamCount}`,
                    1
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("expired"),
                    i18n.t("catchxam_application"),
                    <BaseImage
                      src="/icons/catchxam.png"
                      alt="catchxam"
                      size="small"
                    />,
                    catchtivityData.expiredCatchxamApplicationCount,
                    2
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("current"),
                    i18n.t("catchxam_application"),
                    <BaseImage
                      src="/icons/catchxam.png"
                      alt="catchxam"
                      size="small"
                    />,
                    catchtivityData.currentCatchxamApplicationCount,
                    3
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("incoming"),
                    i18n.t("catchxam_application"),
                    <BaseImage
                      src="/icons/catchxam.png"
                      alt="catchxam"
                      size="small"
                    />,
                    catchtivityData.incomingCatchxamApplicationCount,
                    4
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </FullCard>
    );
  };

  const RenderContentCreatorUserContent = () => {
    return (
      <FullCard isShadowed={true}>
        <div className="my-4">
          {dashboardLoading ? (
            <BaseLoading
              size="large"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          ) : (
            <>
              <div className="mt-4">
                <BaseTitle title={i18n.t("catchtivity_statistics")} />
              </div>
              <DividerLine />
              <div className="flex flex-row flex-wrap">
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("total"),
                    i18n.t("activity"),
                    <BaseImage
                      src="/icons/activity.png"
                      alt="activity"
                      size="small"
                    />,
                    catchtivityData.activityCount,
                    1
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </FullCard>
    );
  };

  const RenderMainContent = () => {
    if (userInformation.accountType === "GENIXO") {
      return RenderGenixoUserContent();
    } else {
      if (userProfile.role === "STAFF") {
        return RenderStaffUserContent();
      } else if (userProfile.role === "INDIVIDUAL") {
        return RenderIndividualUserContent();
      } else if (userProfile.role === "CONTENT_CREATOR") {
        return RenderContentCreatorUserContent();
      }
    }
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex flex-row items-center mt-4">
        <div className="w-1/2">
          <div className="mx-4 my-2">
            <BaseCarousel
              showBoxShadow={false}
              componentList={RenderBrandContent()}
              showArrows={false}
            />
          </div>
        </div>

        <div className="w-1/2">
          <div className="mx-4 my-2">
            <BaseCarousel
              showBoxShadow={false}
              componentList={RenderSystemContent()}
              showArrows={false}
            />
          </div>
        </div>
      </div>

      <div className="m-4">
        {checkViewPermissionGranted("plan_list") ? (
          <div className="mb-4">
            <ListPlan
              isPlanWeek={true}
              planWeekBeginAt={moment(new Date().getTime())
                .startOf("isoWeek")
                .toDate()
                .getTime()}
              planWeekEndAt={moment(new Date().getTime())
                .endOf("isoWeek")
                .toDate()
                .getTime()}
            />
          </div>
        ) : null}

        {RenderMainContent()}
      </div>
    </div>
  );
};

export default DashboardScreen;
