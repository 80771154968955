import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LandingHeader from "../../components/headers/LandingHeader";
import i18n from "../../language/i18n";
import BaseTitle from "../../components/titles/BaseTitle";

const LandingScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  }, []);

  return (
    <div className="text-slate-800 bg-slate-300 dark:bg-slate-800 dark:text-slate-300">
      <div className="container mx-auto min-h-screen text-slate-700 bg-slate-200 dark:bg-slate-700 dark:text-slate-200">
        <LandingHeader />
        <div className="py-5">
          <BaseTitle title={i18n.t("landing_screen_header_text")} />
        </div>
      </div>
    </div>
  );
};

export default LandingScreen;
