import { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import BaseCard from "../../components/cards/BaseCard";
import Error from "../../components/errors/Error";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import BaseImage from "../../components/images/BaseImage";
import RightsReserved from "../../components/landing/RightsReserved";

const AccountActivationErrorScreen = () => {
  const { setBoarding, isFullScreen, setIsFullScreen } = useApp();
  const { handleStateLogout } = useAuth();

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  const handleClickToNavigate = () => {
    handleStateLogout();
    setBoarding("LANDING");
    NotificationManager.success(i18n.t("logout_successful_text"));
  };

  return (
    <BaseCard>
      <BaseImage
        src="/logos/logo-primary.png"
        alt="logo"
        size="custom"
        className="h-20 w-72 mx-auto"
      />
      <div className="flex justify-center my-5">
        <Error
          statusCode={"400"}
          statusText={i18n.t("account_activation_status_text")}
        />
      </div>
      <div className="m-auto">
        <PrimaryButton
          title={i18n.t("login")}
          size="small"
          onClick={handleClickToNavigate}
        />
      </div>
      <div className="mt-auto">
        <RightsReserved />
      </div>
    </BaseCard>
  );
};

export default AccountActivationErrorScreen;
