import i18n from "../language/i18n";
import { filterGradeLevelOptionList } from "./ManagementUtilization";

export const retrieveCategoryVersionCodeOptionList = () => {
  return [
    {
      value: "MEB-IO-MAT-2024",
      text: i18n.t("MEB-IO-MAT-2024"),
      type: "MATHEMATICS",
      availableLevelList: [5],
    },
    {
      value: "MEB-IO-MAT-2018",
      text: i18n.t("MEB-IO-MAT-2018"),
      type: "MATHEMATICS",
      availableLevelList: [4, 6, 7, 8],
    },
    {
      value: "MEB-IO-TUR-2024",
      text: i18n.t("MEB-IO-TUR-2024"),
      type: "TURKISH",
      availableLevelList: [5],
    },
    {
      value: "MEB-IO-TUR-2019",
      text: i18n.t("MEB-IO-TUR-2019"),
      type: "TURKISH",
      availableLevelList: [4, 6, 7, 8],
    },
    {
      value: "MEB-IO-HAY-2018",
      text: i18n.t("MEB-IO-HAY-2018"),
      type: "LIFE_STUDIES",
      availableLevelList: [3],
    },
    {
      value: "MEB-IO-TRAF-2018",
      text: i18n.t("MEB-IO-TRAF-2018"),
      type: "TRAFFIC_SAFETY",
      availableLevelList: [4],
    },
    {
      value: "MEB-IO-FEN-2024",
      text: i18n.t("MEB-IO-FEN-2024"),
      type: "SCIENCE",
      availableLevelList: [5],
    },
    {
      value: "MEB-IO-SCI-2018",
      text: i18n.t("MEB-IO-SCI-2018"),
      type: "SCIENCE",
      availableLevelList: [4, 6, 7, 8],
    },
    {
      value: "MEB-IO-SOS-2024",
      text: i18n.t("MEB-IO-SOS-2024"),
      type: "SOCIAL_STUDIES",
      availableLevelList: [5],
    },
    {
      value: "MEB-IO-SOS-2018",
      text: i18n.t("MEB-IO-SOS-2018"),
      type: "SOCIAL_STUDIES",
      availableLevelList: [4, 6, 7],
    },
    {
      value: "MEB-IO-ITA-2018",
      text: i18n.t("MEB-IO-ITA-2018"),
      type: "SOCIAL_STUDIES",
      availableLevelList: [8],
    },
    {
      value: "MEB-IO-ENG-2018",
      text: i18n.t("MEB-IO-ENG-2018"),
      type: "ENGLISH",
      availableLevelList: [4, 5, 6, 7, 8],
    },
    {
      value: "MEB-AL-MAT-2024",
      text: i18n.t("MEB-AL-MAT-2024"),
      type: "MATHEMATICS",
      availableLevelList: [9],
    },
    {
      value: "MEB-AL-MAT-2018",
      text: i18n.t("MEB-AL-MAT-2018"),
      type: "MATHEMATICS",
      availableLevelList: [10, 11, 12],
    },
    {
      value: "MEB-AL-FİZ-2024",
      text: i18n.t("MEB-AL-FİZ-2024"),
      type: "PHYSICS",
      availableLevelList: [9],
    },
    {
      value: "MEB-AL-FIZ-2018",
      text: i18n.t("MEB-AL-FIZ-2018"),
      type: "PHYSICS",
      availableLevelList: [10, 11, 12],
    },
    {
      value: "MEB-AL-BİY-2024",
      text: i18n.t("MEB-AL-BİY-2024"),
      type: "BIOLOGY",
      availableLevelList: [9],
    },
    {
      value: "MEB-AL-BIO-2018",
      text: i18n.t("MEB-AL-BIO-2018"),
      type: "BIOLOGY",
      availableLevelList: [10, 11, 12],
    },
    {
      value: "MEB-AL-KİM-2024",
      text: i18n.t("MEB-AL-KİM-2024"),
      type: "CHEMISTRY",
      availableLevelList: [9],
    },
    {
      value: "MEB-AL-KIM-2018",
      text: i18n.t("MEB-AL-KIM-2018"),
      type: "CHEMISTRY",
      availableLevelList: [10, 11, 12],
    },
    {
      value: "MEB-AL-TAR-2024",
      text: i18n.t("MEB-AL-TAR-2024"),
      type: "HISTORY",
      availableLevelList: [9],
    },
    {
      value: "MEB-AL-TAR-2018",
      text: i18n.t("MEB-AL-TAR-2018"),
      type: "HISTORY",
      availableLevelList: [10, 11],
    },
    {
      value: "MEB-AL-ITA-2018",
      text: i18n.t("MEB-AL-ITA-2018"),
      type: "HISTORY",
      availableLevelList: [12],
    },
    {
      value: "MEB-AL-COĞ-2024",
      text: i18n.t("MEB-AL-COĞ-2024"),
      type: "GEOGRAPHY",
      availableLevelList: [9],
    },
    {
      value: "MEB-AL-COĞ-2018",
      text: i18n.t("MEB-AL-COĞ-2018"),
      type: "GEOGRAPHY",
      availableLevelList: [10, 11, 12],
    },
    {
      value: "MEB-IO-DKAB-2018",
      text: i18n.t("MEB-IO-DKAB-2018"),
      type: "CULTURE_AND_RELIGION_KNOWLEDGE",
      availableLevelList: [4, 5, 6, 7, 8],
    },
    {
      value: "MEB-AL-DKAB-2018",
      text: i18n.t("MEB-AL-DKAB-2018"),
      type: "CULTURE_AND_RELIGION_KNOWLEDGE",
      availableLevelList: [9, 10, 11, 12],
    },
    {
      value: "MEB-AL-FEL-2018",
      text: i18n.t("MEB-AL-FEL-2018"),
      type: "PHILOSOPHY",
      availableLevelList: [10, 11],
    },
    {
      value: "MEB-AL-TDE-2024",
      text: i18n.t("MEB-AL-TDE-2024"),
      type: "LITERATURE",
      availableLevelList: [9],
    },
    {
      value: "MEB-AL-LIT-2018",
      text: i18n.t("MEB-AL-LIT-2018"),
      type: "LITERATURE",
      availableLevelList: [10, 11, 12],
    },
    // {
    //   value: "CEFR-STANDARDS-A1",
    //   text: i18n.t("CEFR-STANDARDS-A1"),
    //   type: "ENGLISH",
    //   availableLevelList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    // },
    // {
    //   value: "CEFR-STANDARDS-A2",
    //   text: i18n.t("CEFR-STANDARDS-A2"),
    //   type: "ENGLISH",
    //   availableLevelList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    // },
    // {
    //   value: "CEFR-STANDARDS-B1",
    //   text: i18n.t("CEFR-STANDARDS-B1"),
    //   type: "ENGLISH",
    //   availableLevelList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    // },
    // {
    //   value: "CEFR-STANDARDS-B2",
    //   text: i18n.t("CEFR-STANDARDS-B2"),
    //   type: "ENGLISH",
    //   availableLevelList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    // },
    {
      value: "GENERAL-CULTURE",
      text: i18n.t("GENERAL-CULTURE"),
      type: "GENERAL_CULTURE",
      availableLevelList: [8, 12],
    },
    {
      value: "SPE-TYT-TUR-2024",
      text: i18n.t("SPE-TYT-TUR-2024"),
      type: "TURKISH",
      availableLevelList: [12],
    },
    {
      value: "SPE-LGS-TUR-2024",
      text: i18n.t("SPE-LGS-TUR-2024"),
      type: "TURKISH",
      availableLevelList: [8],
    },
  ];
};

export const filterCategoryVersionCodeOptionList = (
  categoryVersionCodeOptionList,
  coterieType,
  level
) => {
  if (coterieType && coterieType === "DEFAULT_OPTION") return [];
  if (level && level === "DEFAULT_OPTION") return [];
  let currentCategoryVersionCodeOptionList = categoryVersionCodeOptionList;
  if (coterieType !== "MANAGEMENT") {
    currentCategoryVersionCodeOptionList =
      currentCategoryVersionCodeOptionList.filter(
        (categoryVersionCode) => categoryVersionCode.type === coterieType
      );
  }
  if (level) {
    currentCategoryVersionCodeOptionList =
      currentCategoryVersionCodeOptionList.filter((categoryVersionCode) =>
        categoryVersionCode.availableLevelList.includes(level)
      );
  }

  return currentCategoryVersionCodeOptionList;
};

export const filterCategoryVersionCodeOptionListByGradeDTO = (
  categoryVersionCodeOptionList,
  coterieType,
  gradeDTO
) => {
  if (coterieType && coterieType === "DEFAULT_OPTION") return [];

  let currentCategoryVersionCodeOptionList = categoryVersionCodeOptionList;

  if (coterieType !== "MANAGEMENT") {
    currentCategoryVersionCodeOptionList =
      currentCategoryVersionCodeOptionList.filter(
        (categoryVersionCode) => categoryVersionCode.type === coterieType
      );
  }
  if (gradeDTO) {
    currentCategoryVersionCodeOptionList =
      currentCategoryVersionCodeOptionList.filter((categoryVersionCodeOption) =>
        categoryVersionCodeOption.availableLevelList.includes(gradeDTO.level)
      );
  }

  return currentCategoryVersionCodeOptionList;
};

export const filterCategoryVersionCodeOptionListByInstitutionDTO = (
  categoryVersionCodeOptionList,
  coterieType,
  institutionDTO
) => {
  if (coterieType && coterieType === "DEFAULT_OPTION") return [];

  let currentCategoryVersionCodeOptionList = categoryVersionCodeOptionList;

  if (coterieType !== "MANAGEMENT") {
    currentCategoryVersionCodeOptionList =
      currentCategoryVersionCodeOptionList.filter(
        (categoryVersionCodeOption) =>
          categoryVersionCodeOption.type === coterieType
      );
  }
  if (institutionDTO) {
    const gradeLevelList = filterGradeLevelOptionList(institutionDTO).map(
      (option) => option.value
    );
    currentCategoryVersionCodeOptionList =
      currentCategoryVersionCodeOptionList.filter(
        (categoryVersionCodeOption) => {
          return (
            gradeLevelList.find((gradeLevel) =>
              categoryVersionCodeOption.availableLevelList.includes(gradeLevel)
            ) !== undefined
          );
        }
      );
  }

  return currentCategoryVersionCodeOptionList;
};
