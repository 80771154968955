import { InlineMath } from "react-katex";
import { constructInputWithSpecialExpressionList } from "../../utilization/CatchtivityUtilization";

const InputWithSpecialExpression = ({ value }) => {
  return constructInputWithSpecialExpressionList(value).length > 1 ? (
    <div className="m-2">
      <span className="whitespace-pre-wrap">
        {constructInputWithSpecialExpressionList(value).map(
          (inputPart, index) => (
            <span
              className={`${inputPart.isBold ? "font-semibold" : ""} ${
                inputPart.isUnderline ? "underline" : ""
              }`}
            >
              {inputPart.isEquation ? (
                <span className="text-lg">
                  <InlineMath key={index} math={inputPart.value} />
                </span>
              ) : (
                inputPart.value
              )}
            </span>
          )
        )}
      </span>
    </div>
  ) : null;
};

export default InputWithSpecialExpression;
