import BaseImage from "../images/BaseImage";

const RedAlert = (props) => {
  const { text, children } = props;
  return (
    <div className="flex flex-row items-center bg-catchup-light-red border border-catchup-red rounded-catchup-3xlarge px-4 py-1 gap-x-2">
      <BaseImage
        src="/icons/exclamation-red.png"
        alt="exclamation-red"
        size="small"
      />
      <div className="flex-1">
        <p className="text-catchup-red">
          <span className="">{text}: </span>
          {children}
        </p>
      </div>
    </div>
  );
};

export default RedAlert;
