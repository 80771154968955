const ProgressBar = (props) => {
  const {
    title,
    showRemainingTime,
    totalTimeInSeconds,
    remainingTimeInSeconds,
    color,
    height,
  } = props;

  const retrieveMinute = () => {
    return parseInt(remainingTimeInSeconds / 60);
  };

  const retrieveSeconds = () => {
    const minuteInSeconds = retrieveMinute() * 60;
    return parseInt(remainingTimeInSeconds - minuteInSeconds);
  };

  const calculatePercentage = () => {
    if (remainingTimeInSeconds < 0) {
      return (totalTimeInSeconds / totalTimeInSeconds) * 100;
    } else {
      return (
        ((totalTimeInSeconds - remainingTimeInSeconds) / totalTimeInSeconds) *
        100
      );
    }
  };

  return (
    <div className="relative w-full">
      {title ? (
        <div className="absolute top-2 left-4">
          <span className="text-2xl text-catchup-white">{title}</span>
        </div>
      ) : null}

      {showRemainingTime ? (
        <div className="absolute top-2 right-4">
          <span className="flex flex-row text-2xl text-catchup-dark-blue">
            <p className="mx-1">
              {retrieveMinute() < 10
                ? `0${retrieveMinute()}`
                : retrieveMinute()}{" "}
            </p>
            :
            <p className="mx-1">
              {retrieveSeconds() < 10
                ? `0${retrieveSeconds()}`
                : retrieveSeconds()}{" "}
            </p>
          </span>
        </div>
      ) : null}

      <div
        className={`w-full bg-catchup-light-gray rounded-full ${
          height ? height : "h-catchup-quicktivity"
        }`}
      >
        <div
          className={`rounded-full ${color ? color : "bg-catchup-red"} ${
            height ? height : "h-catchup-quicktivity"
          }`}
          style={{ width: `${calculatePercentage()}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
