import { useEffect, useState } from "react";
import { useAuth } from "../../context/UserContextProvider";
import { useNavigate, useParams } from "react-router-dom";
import {
  patchCampusAISettings,
  retrieveCampusAISettingsByCampusId,
} from "../../requests/ManagementRequests";
import FullCard from "../../components/cards/FullCard";
import i18n from "../../language/i18n";
import Switch from "react-switch";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import DividerLine from "../../components/divider/DividerLine";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import { useApp } from "../../context/AppContextProvider";

const CampusAISettingsScreen = () => {
  const { id } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const { userInformation } = useAuth();
  const navigate = useNavigate();
  const [campusAISettings, setCampusAISettings] = useState(null);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const { accountType } = userInformation;
    if (accountType !== "GENIXO") {
      navigate("/");
    }
  }, [userInformation]);

  useEffect(() => {
    if (!id) return;
    retrieveAndSetCampusAISettings();
  }, [id]);

  const retrieveAndSetCampusAISettings = async () => {
    const { data, err } = await retrieveCampusAISettingsByCampusId(id);
    if (err) {
      console.log(err);
      return;
    }
    setCampusAISettings(data);
  };

  const handleUpdateCampusAISettingsOnClick = async () => {
    const { data, err } = await patchCampusAISettings(campusAISettings);
    if (err) {
      console.log(err);
      return err;
    }
    navigate(-1);
  };

  const RenderMainContent = () => {
    if (campusAISettings) {
      const {
        canConvertActivityTemplate,
        canGenerateActivity,
        canEvaluateOpenEnded,
        canSolveActivityTemplate,
      } = campusAISettings;
      return (
        <FullCard isShadowed={true}>
          <div className="">
            <div className="flex flex-row items-center gap-x-2">
              <div className="cursor-pointer">
                <BaseImage
                  size="medium"
                  src="/icons/long-arrow-left.png"
                  alt="long-arrow-left"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              <BaseTitle title={i18n.t("campus_ai_settings")} />
            </div>
          </div>
          <DividerLine />
          <div className="flex flex-row items-center flex-wrap">
            <div className="w-full lg:w-1/2">
              <div className="flex flex-row items-center justify-between gap-x-2">
                <div className="flex-1">
                  <p>{i18n.t("can_convert_activity_template")}</p>
                </div>
                <div className="mx-2">
                  <Switch
                    onChange={() => {
                      if (canConvertActivityTemplate) {
                        setCampusAISettings((prevCampusAISettings) => ({
                          ...prevCampusAISettings,
                          canConvertActivityTemplate: false,
                        }));
                      } else {
                        setCampusAISettings((prevCampusAISettings) => ({
                          ...prevCampusAISettings,
                          canConvertActivityTemplate: true,
                        }));
                      }
                    }}
                    checkedIcon={false}
                    offColor={"#e2e8f0"}
                    onColor={"#57C2D3"}
                    offHandleColor={"#57C2D3"}
                    onHandleColor={"#e2e8f0"}
                    uncheckedIcon={false}
                    checked={canConvertActivityTemplate}
                  />
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <div className="flex flex-row items-center justify-between gap-x-2">
                <div className="flex-1">
                  <p>{i18n.t("can_generate_activity")}</p>
                </div>
                <div className="mx-2">
                  <Switch
                    onChange={() => {
                      if (canGenerateActivity) {
                        setCampusAISettings((prevCampusAISettings) => ({
                          ...prevCampusAISettings,
                          canGenerateActivity: false,
                        }));
                      } else {
                        setCampusAISettings((prevCampusAISettings) => ({
                          ...prevCampusAISettings,
                          canGenerateActivity: true,
                        }));
                      }
                    }}
                    checkedIcon={false}
                    offColor={"#e2e8f0"}
                    onColor={"#57C2D3"}
                    offHandleColor={"#57C2D3"}
                    onHandleColor={"#e2e8f0"}
                    uncheckedIcon={false}
                    checked={canGenerateActivity}
                  />
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <div className="flex flex-row items-center justify-between gap-x-2">
                <div className="flex-1">
                  <p>{i18n.t("can_evaluate_open_ended")}</p>
                </div>
                <div className="mx-2">
                  <Switch
                    onChange={() => {
                      if (canEvaluateOpenEnded) {
                        setCampusAISettings((prevCampusAISettings) => ({
                          ...prevCampusAISettings,
                          canEvaluateOpenEnded: false,
                        }));
                      } else {
                        setCampusAISettings((prevCampusAISettings) => ({
                          ...prevCampusAISettings,
                          canEvaluateOpenEnded: true,
                        }));
                      }
                    }}
                    checkedIcon={false}
                    offColor={"#e2e8f0"}
                    onColor={"#57C2D3"}
                    offHandleColor={"#57C2D3"}
                    onHandleColor={"#e2e8f0"}
                    uncheckedIcon={false}
                    checked={canEvaluateOpenEnded}
                  />
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <div className="flex flex-row items-center justify-between gap-x-2">
                <div className="flex-1">
                  <p>{i18n.t("can_solve_activity_template")}</p>
                </div>
                <div className="mx-2">
                  <Switch
                    onChange={() => {
                      if (canSolveActivityTemplate) {
                        setCampusAISettings((prevCampusAISettings) => ({
                          ...prevCampusAISettings,
                          canSolveActivityTemplate: false,
                        }));
                      } else {
                        setCampusAISettings((prevCampusAISettings) => ({
                          ...prevCampusAISettings,
                          canSolveActivityTemplate: true,
                        }));
                      }
                    }}
                    checkedIcon={false}
                    offColor={"#e2e8f0"}
                    onColor={"#57C2D3"}
                    offHandleColor={"#57C2D3"}
                    onHandleColor={"#e2e8f0"}
                    uncheckedIcon={false}
                    checked={canSolveActivityTemplate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 ml-auto">
            <SecondaryButton
              title={i18n.t("update")}
              size="medium"
              onClick={handleUpdateCampusAISettingsOnClick}
            />
          </div>
        </FullCard>
      );
    } else {
    }
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default CampusAISettingsScreen;
