import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import InputGroup from "../../components/groups/InputGroup";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import {
  generateCatchtivityBranchReport,
  generateCatchtivityGradeReport,
  generateCatchtivitySeasonReport,
  generateCatchxamBranchReport,
  generateCatchxamGradeReport,
  generateCatchxamSeasonReport,
  generateInformationBranchReport,
  generateInformationBrandReport,
  generateInformationCampusReport,
  generateInformationGradeReport,
  generateInformationInstitutionReport,
  generateInformationRegionReport,
  generateInformationSeasonReport,
  generateLearningOutcomeBranchReport,
  generateLearningOutcomeGradeReport,
  generateLearningOutcomeSeasonReport,
  generateLearningOutcomeUserProfileReport,
  generateParticipationBranchReport,
  generateParticipationGradeReport,
  generateParticipationSeasonReport,
  generateParticipationUserProfileReport,
  generatePerformanceCoterieReport,
  generatePerformanceUserProfileReport,
  generateStandardExamBranchReport,
  generateStandardExamGradeReport,
  generateStandardExamSeasonReport,
} from "../../requests/ReportRequests";
import {
  queryBranchListByGradeId,
  queryBranchListByGradeIdList,
  queryCampusListByBrandId,
  queryGradeListBySeasonId,
  queryGradeListBySeasonIdList,
  queryInstitutionListByCampusId,
  queryInstitutionListByCampusIdList,
  querySeasonListByInstitutionId,
  querySeasonListByInstitutionIdList,
  retrieveAllRegionDTOList,
  retrieveBranchByIdInDetail,
  retrieveSeasonByIdInDetail,
} from "../../requests/ManagementRequests";
import { useApp } from "../../context/AppContextProvider";
import FullCard from "../../components/cards/FullCard";
import { Tooltip } from "react-tooltip";
import GeneralInformationReportDetail from "../../components/reports/GeneralInformationReportDetail";
import {
  queryCatchtivityDTOListByUserIdUserProfileId,
  queryCatchtivityFromCatchtivityAccessibilityByParams,
  queryCatchxamDTOListByUserIdUserProfileId,
  queryCatchxamFromCatchxamAccessibilityByParams,
  queryStandardExamDTOListByUserIdUserProfileId,
  queryStandardExamFromStandardExamAccessibilityByParams,
} from "../../requests/CatchtivityRequests";
import GeneralCatchxamReportDetail from "../../components/reports/GeneralCatchxamReportDetail";
import GeneralCatchtivityReportDetail from "../../components/reports/GeneralCatchtivityReportDetail";
import { retrieveCategoryVersionCodeOptionList } from "../../utilization/CategoryUtilization";
import GeneralLearningOutcomeReportDetail from "../../components/reports/GeneralLearningOutcomeReportDetail";
import GeneralParticipationReportDetail from "../../components/reports/GeneralParticipationReportDetail";
import BaseImage from "../../components/images/BaseImage";
import GeneralPerformanceReportDetail from "../../components/reports/GeneralPerformanceReportDetail";
import GeneralStandardExamReportDetail from "../../components/reports/GeneralStandardExamReportDetail";
import { queryIndividualModalByParams } from "../../requests/AIModelConstructorRequests";
import {
  constructBaseNumericIndividualModel,
  constructBaseVerbalIndvidualModel,
} from "../../utilization/IndividualModelUtilization";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import {
  retrieveBranchDTOOptionList,
  retrieveBrandDTOOptionList,
  retrieveCampusDTOOptionList,
  retrieveGradeDTOOptionList,
  retrieveInstitutionDTOOptionList,
  retrieveSeasonDTOOptionList,
} from "../../utilization/ManagementUtilization";

const ReportListScreen = (props) => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    language,
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
    categorySet,
  } = useAuth();
  const [pageState, setPageState] = useState(0);
  const [beginAt, setBeginAt] = useState("");
  const [endAt, setEndAt] = useState("");
  const [selectedBranchId, setSelectedBranchId] = useState("DEFAULT_OPTION");
  const [selectedBrandId, setSelectedBrandId] = useState(userProfileBrand.id);
  const [selectedRegionCode, setSelectedRegionCode] =
    useState("DEFAULT_OPTION");
  const [selectedCampusId, setSelectedCampusId] = useState("DEFAULT_OPTION");
  const [selectedInstitutionId, setSelectedInstitutionId] =
    useState("DEFAULT_OPTION");
  const [selectedSeasonId, setSelectedSeasonId] = useState("DEFAULT_OPTION");
  const [selectedGradeId, setSelectedGradeId] = useState("DEFAULT_OPTION");
  const [campusIdList, setCampusIdList] = useState([]);
  const [institutionIdList, setInstitutionIdList] = useState([]);
  const [seasonIdList, setSeasonIdList] = useState([]);
  const [gradeIdList, setGradeIdList] = useState([]);
  const [branchIdList, setBranchIdList] = useState([]);
  const [staffUserProfileIdList, setStaffUserProfileIdList] = useState([]);
  const [regionCodeList, setRegionCodeList] = useState([]);
  const [regionDTOList, setRegionDTOList] = useState([]);
  const [brandDTOList, setBrandDTOList] = useState([userProfileBrand]);
  const [campusDTOList, setCampusDTOList] = useState([]);
  const [institutionDTOList, setInstitutionDTOList] = useState([]);
  const [gradeDTOList, setGradeDTOList] = useState([]);
  const [seasonDTOList, setSeasonDTOList] = useState([]);
  const [branchDTOList, setBranchDTOList] = useState([]);
  const [selectedBaseReportType, setSelectedBaseReportType] = useState(null);
  const [selectedSubReportType, setSelectedSubReportType] = useState(null);
  const [reportDetail, setReportDetail] = useState(null);
  const [reportDetailList, setReportDetailList] = useState([]);
  const [reportDetailLoading, setReportDetailLoading] = useState(false);
  const [selectedCatchxamId, setSelectedCatchxamId] =
    useState("DEFAULT_OPTION");
  const [catchxamDTOList, setCatchxamDTOList] = useState([]);
  const [selectedStandardExamId, setSelectedStandardExamId] =
    useState("DEFAULT_OPTION");
  const [standardExamDTOList, setStandardExamDTOList] = useState([]);
  const [selectedCatchtivityId, setSelectedCatchtivityId] =
    useState("DEFAULT_OPTION");
  const [catchtivityDTOList, setCatchtivityDTOList] = useState([]);
  const [selectedCategoryCode, setSelectedCategoryCode] =
    useState("DEFAULT_OPTION");
  const [selectedSubCategoryCode, setSelectedSubCategoryCode] =
    useState("DEFAULT_OPTION");
  const [learnerUserProfileDTOList, setLearnerUserProfileDTOList] = useState(
    []
  );
  const [staffUserProfileDTOList, setStaffUserProfileDTOList] = useState([]);
  const [selectedUserProfileId, setSelectedUserProfileId] =
    useState("DEFAULT_OPTION");
  const [errorMap, setErrorMap] = useState({
    brandId: null,
    beginAt: null,
    endAt: null,
    selectedCatchtivityId: null,
    selectedCatchxamId: null,
    selectedStandardExamId: null,
    selectedCategoryCode: null,
    selectedSubCategoryCode: null,
  });
  const [showIndividualModel, setShowIndividualModel] = useState(false);
  const [individualModelLoading, setIndividualModelLoading] = useState(false);
  const [individualModelList, setIndividualModelList] = useState([]);
  const [base64DataEncoded, setBase64DataEncoded] = useState(null);
  const [canAutoSelectCampus, setCanAutoSelectCampus] = useState(false);
  const [canAutoSelectInstitution, setCanAutoSelectInstitution] =
    useState(false);
  const [canAutoSelectSeason, setCanAutoSelectSeason] = useState(false);
  const [canAutoSelectGrade, setCanAutoSelectGrade] = useState(false);
  const [canAutoSelectBranch, setCanAutoSelectBranch] = useState(false);
  const NUMBER_OF_ACTIVITY_TEMPLATE = 9;
  const NUMBER_OF_TAXONOMY = 6;
  const INITIAL_TAXONOMY_VALUE = 1 / NUMBER_OF_TAXONOMY;
  const INITIAL_TEMPLATE_VALUE = 1 / NUMBER_OF_ACTIVITY_TEMPLATE;

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (checkViewPermissionGranted("report_list")) {
    } else {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    setCanAutoSelectCampus(true);
    resetParams();
  }, [selectedBaseReportType, selectedSubReportType]);

  useEffect(() => {
    const retrieveAndSetAllRegionList = async () => {
      const { data, err } = await retrieveAllRegionDTOList();
      if (err) {
        console.log(err);
      } else {
        setRegionDTOList(data);
      }
    };
    const retrieveAndSetCatchxamList = async () => {
      let data = [];
      let err;
      if (userProfile.role === "STAFF") {
        const { data: staffData, err: staffErr } =
          await queryCatchxamFromCatchxamAccessibilityByParams({
            userId: userInformation.id,
            userProfileId: userProfile.id,
            brandId: userProfileBrand.id,
            campusId: userProfileCampus?.id,
            institutionId: userProfileInstitution?.id,
            coterieType: userProfile.coterieType
              ? userProfile.coterieType
              : null,
            level: userProfileGrade?.level,
            deleted: false,
          });
        data = staffData;
        err = staffErr;
      } else if (userProfile.role === "INDIVIDUAL") {
        const { data: individualData, err: individualErr } =
          await queryCatchxamDTOListByUserIdUserProfileId({
            userId: userInformation.id,
            userProfileId: userProfile.id,
          });
        data = individualData;
        err = individualErr;
      }
      if (err) {
        console.log(err);
      } else {
        setCatchxamDTOList(data.sort((a, b) => b.id - a.id));
      }
    };
    const retrieveAndSetCatchtivityList = async () => {
      let data = [];
      let err;
      if (userProfile.role === "STAFF") {
        const { data: staffData, err: staffErr } =
          await queryCatchtivityFromCatchtivityAccessibilityByParams({
            userId: userInformation.id,
            userProfileId: userProfile.id,
            brandId: userProfileBrand.id,
            campusId: userProfileCampus?.id,
            institutionId: userProfileInstitution?.id,
            coterieType: userProfile.coterieType
              ? userProfile.coterieType
              : null,
            level: userProfileGrade?.level,
            deleted: false,
          });
        data = staffData;
        err = staffErr;
      } else if (userProfile.role === "INDIVIDUAL") {
        const { data: individualData, err: individualErr } =
          await queryCatchtivityDTOListByUserIdUserProfileId({
            userId: userInformation.id,
            userProfileId: userProfile.id,
          });
        data = individualData;
        err = individualErr;
      }
      if (err) {
        console.log(err);
      } else {
        setCatchtivityDTOList(data.sort((a, b) => b.id - a.id));
      }
    };
    const retrieveAndSetStandardExamList = async () => {
      let data = [];
      let err;
      if (userProfile.role === "STAFF") {
        const { data: staffData, err: staffErr } =
          await queryStandardExamFromStandardExamAccessibilityByParams({
            userId: userInformation.id,
            userProfileId: userProfile.id,
            brandId: userProfileBrand.id,
            campusId: userProfileCampus?.id,
            institutionId: userProfileInstitution?.id,
            deleted: false,
          });
        data = staffData;
        err = staffErr;
      } else if (userProfile.role === "INDIVIDUAL") {
        const { data: individualData, err: individualErr } =
          await queryStandardExamDTOListByUserIdUserProfileId({
            userId: userInformation.id,
            userProfileId: userProfile.id,
          });
        data = individualData;
        err = individualErr;
      }
      if (err) {
        console.log(err);
      } else {
        setStandardExamDTOList(data.sort((a, b) => b.id - a.id));
      }
    };
    if (
      selectedBaseReportType === "INFORMATION" &&
      selectedSubReportType === "REGION"
    ) {
      retrieveAndSetAllRegionList();
    }
    if (selectedBaseReportType === "CATCHTIVITY") {
      retrieveAndSetCatchtivityList();
    }
    if (selectedBaseReportType === "CATCHXAM") {
      retrieveAndSetCatchxamList();
    }
    if (selectedBaseReportType === "STANDARD_EXAM") {
      retrieveAndSetStandardExamList();
    }
  }, [selectedBaseReportType, selectedSubReportType]);

  useEffect(() => {
    const retrieveAndSetCampusIdList = async () => {
      const { data, err } = await queryCampusListByBrandId(selectedBrandId);
      if (err) {
        console.log(err);
      } else {
        setCampusDTOList(data);
      }
    };
    if (selectedBrandId !== "DEFAULT_OPTION") {
      if (userProfileCampus) {
        setCampusDTOList([userProfileCampus]);
      } else {
        retrieveAndSetCampusIdList();
      }
    } else {
      setCampusDTOList([]);
      setSelectedCampusId("DEFAULT_OPTION");
      setCampusIdList([]);
    }
  }, [selectedBrandId]);

  useEffect(() => {
    if (!canAutoSelectCampus) return;
    if (userProfileCampus) {
      if (checkIfManagementIsList()) {
        setCampusIdList([userProfileCampus.id]);
      } else {
        setSelectedCampusId(userProfileCampus.id);
      }
      setCanAutoSelectInstitution(true);
    }
    setCanAutoSelectCampus(false);
  }, [campusDTOList, canAutoSelectCampus]);

  useEffect(() => {
    const retrieveAndSetInstitutionList = async () => {
      const { data, err } = await queryInstitutionListByCampusId(
        selectedCampusId
      );
      if (err) {
        console.log(err);
      } else {
        setInstitutionDTOList(data);
      }
    };
    // if (
    //   (selectedBaseReportType === "LEARNING_OUTCOME" ||
    //     selectedBaseReportType === "PARTICIPATION" ||
    //     selectedBaseReportType === "PERFORMANCE") &&
    //   (selectedSubReportType === "USER_PROFILE" ||
    //     selectedSubReportType === "COTERIE_TYPE")
    // ) {

    // }
    if (selectedCampusId !== "DEFAULT_OPTION") {
      if (userProfileInstitution) {
        setInstitutionDTOList([userProfileInstitution]);
      } else {
        retrieveAndSetInstitutionList();
      }
    } else {
      setInstitutionDTOList([]);
      setSelectedInstitutionId("DEFAULT_OPTION");
      setInstitutionIdList([]);
    }
  }, [selectedCampusId]);

  useEffect(() => {
    if (!canAutoSelectInstitution) return;
    if (userProfileInstitution) {
      if (checkIfManagementIsList()) {
        setInstitutionIdList([userProfileInstitution.id]);
      } else {
        setSelectedInstitutionId(userProfileInstitution.id);
      }
      setCanAutoSelectSeason(true);
    }
    setCanAutoSelectInstitution(false);
  }, [institutionDTOList, canAutoSelectInstitution]);

  useEffect(() => {
    const retrieveAndSetSeasonList = async () => {
      const { data, err } = await querySeasonListByInstitutionId(
        selectedInstitutionId
      );
      if (err) {
        console.log(err);
      } else {
        setSeasonDTOList(data);
      }
    };
    if (userProfile.role === "STAFF") {
      // if (
      //   (selectedBaseReportType === "LEARNING_OUTCOME" ||
      //     selectedBaseReportType === "PARTICIPATION" ||
      //     selectedBaseReportType === "PERFORMANCE") &&
      //   (selectedSubReportType === "USER_PROFILE" ||
      //     selectedSubReportType === "COTERIE_TYPE")
      // ) {

      // }
      if (selectedInstitutionId !== "DEFAULT_OPTION") {
        if (userProfileSeason) {
          setSeasonDTOList([userProfileSeason]);
        } else {
          retrieveAndSetSeasonList();
        }
      } else {
        setSeasonDTOList([]);
        setSelectedSeasonId("DEFAULT_OPTION");
        setSeasonIdList([]);
      }
    } else {
      setSelectedSeasonId(userProfileSeason.id);
    }
  }, [selectedInstitutionId]);

  useEffect(() => {
    if (!canAutoSelectSeason) return;
    if (userProfileSeason) {
      if (checkIfManagementIsList()) {
        setSeasonIdList([userProfileSeason.id]);
      } else {
        setSelectedSeasonId(userProfileSeason.id);
      }
      setCanAutoSelectGrade(true);
    }
    setCanAutoSelectSeason(false);
  }, [seasonDTOList, canAutoSelectSeason]);

  useEffect(() => {
    const retrieveAndSetGradeList = async () => {
      const { data, err } = await queryGradeListBySeasonId(selectedSeasonId);
      if (err) {
        console.log(err);
      } else {
        setGradeDTOList(data);
      }
    };
    // if (
    //   (selectedBaseReportType === "LEARNING_OUTCOME" ||
    //     selectedBaseReportType === "PARTICIPATION" ||
    //     selectedBaseReportType === "PERFORMANCE") &&
    //   (selectedSubReportType === "USER_PROFILE" ||
    //     selectedSubReportType === "COTERIE_TYPE")
    // ) {

    // }
    if (selectedSeasonId !== "DEFAULT_OPTION") {
      if (userProfileGrade) {
        setGradeDTOList([userProfileGrade]);
      } else {
        retrieveAndSetGradeList();
      }
    } else {
      setGradeDTOList([]);
      setSelectedGradeId("DEFAULT_OPTION");
      setGradeIdList([]);
    }
  }, [selectedSeasonId]);

  useEffect(() => {
    if (!canAutoSelectGrade) return;
    if (userProfileGrade) {
      if (checkIfManagementIsList()) {
        setGradeIdList([userProfileGrade.id]);
      } else {
        setSelectedGradeId(userProfileGrade.id);
      }
      setCanAutoSelectBranch(true);
    }
    setCanAutoSelectGrade(false);
  }, [gradeDTOList, canAutoSelectGrade]);

  useEffect(() => {
    const retrieveAndSetBranchList = async () => {
      const { data, err } = await queryBranchListByGradeId(selectedGradeId);
      if (err) {
        console.log(err);
      } else {
        setBranchDTOList(data);
      }
    };
    // if (
    //   (selectedBaseReportType === "LEARNING_OUTCOME" ||
    //     selectedBaseReportType === "PARTICIPATION" ||
    //     selectedBaseReportType === "PERFORMANCE") &&
    //   (selectedSubReportType === "USER_PROFILE" ||
    //     selectedSubReportType === "COTERIE_TYPE")
    // ) {

    // }
    if (selectedGradeId !== "DEFAULT_OPTION") {
      if (userProfileBranch) {
        setBranchDTOList([userProfileBranch]);
      } else {
        retrieveAndSetBranchList();
      }
    } else {
      setBranchDTOList([]);
      setSelectedBranchId("DEFAULT_OPTION");
      setBranchIdList([]);
    }
  }, [selectedGradeId]);

  useEffect(() => {
    if (!canAutoSelectBranch) return;
    if (userProfileBranch) {
      if (checkIfManagementIsList()) {
        setBranchIdList([userProfileBranch.id]);
      } else {
        setSelectedBranchId(userProfileBranch.id);
      }
    }
    setCanAutoSelectBranch(false);
  }, [branchDTOList, canAutoSelectBranch]);

  useEffect(() => {
    const retrieveAndSetBranchInDetail = async () => {
      const { data, err } = await retrieveBranchByIdInDetail(selectedBranchId);
      if (err) {
        console.log(err);
      } else {
        const { learnerUserProfileDTOList, staffUserProfileDTOList } = data;
        setLearnerUserProfileDTOList(learnerUserProfileDTOList);
        setStaffUserProfileDTOList(staffUserProfileDTOList);
      }
    };
    setLearnerUserProfileDTOList([]);
    setSelectedUserProfileId("DEFAULT_OPTION");
    if (
      (selectedBaseReportType === "LEARNING_OUTCOME" ||
        selectedBaseReportType === "PARTICIPATION" ||
        selectedBaseReportType === "PERFORMANCE") &&
      selectedSubReportType === "USER_PROFILE"
    ) {
      if (selectedBranchId !== "DEFAULT_OPTION") {
        retrieveAndSetBranchInDetail();
      }
    }
  }, [selectedBranchId]);

  useEffect(() => {
    if (
      selectedBaseReportType === "PERFORMANCE" &&
      selectedSubReportType === "USER_PROFILE"
    ) {
      setReportDetailList([]);
    }
  }, [selectedUserProfileId]);

  useEffect(() => {
    setReportDetailList([]);
  }, [staffUserProfileIdList]);

  useEffect(() => {
    const retrieveAndSetInstitutionList = async () => {
      const { data, err } = await queryInstitutionListByCampusIdList(
        campusIdList
      );
      if (err) {
        console.log(err);
      } else {
        setInstitutionDTOList(data);
      }
    };
    if (campusIdList.length > 0) {
      if (userProfileInstitution) {
        setInstitutionDTOList([userProfileInstitution]);
      } else {
        retrieveAndSetInstitutionList();
      }
    } else {
      setInstitutionIdList([]);
      setInstitutionDTOList([]);
    }
  }, [campusIdList]);

  useEffect(() => {
    const retrieveAndSetSeasonList = async () => {
      const { data, err } = await querySeasonListByInstitutionIdList(
        institutionIdList
      );
      if (err) {
        console.log(err);
      } else {
        setSeasonDTOList(data);
      }
    };
    if (userProfile.role === "STAFF") {
      if (institutionIdList.length > 0) {
        if (userProfileSeason) {
          setSeasonDTOList([userProfileSeason]);
        } else {
          retrieveAndSetSeasonList();
        }
      } else {
        setSeasonIdList([]);
        setSeasonDTOList([]);
      }
    } else {
      setSeasonIdList([userProfileSeason.id]);
    }
  }, [institutionIdList]);

  useEffect(() => {
    const retrieveAndSetGradeList = async () => {
      const { data, err } = await queryGradeListBySeasonIdList(seasonIdList);
      if (err) {
        console.log(err);
      } else {
        setGradeDTOList(data);
      }
    };
    if (seasonIdList.length > 0) {
      if (userProfileGrade) {
        setGradeDTOList([userProfileGrade]);
      } else {
        retrieveAndSetGradeList();
      }
    } else {
      setGradeDTOList([]);
      setGradeIdList([]);
    }
  }, [seasonIdList]);

  useEffect(() => {
    const retrieveAndSetBranchList = async () => {
      const { data, err } = await queryBranchListByGradeIdList(gradeIdList);
      if (err) {
        console.log(err);
      } else {
        setBranchDTOList(data);
      }
    };
    if (gradeIdList.length > 0) {
      if (userProfileBranch) {
        setBranchDTOList([userProfileBranch]);
      } else {
        retrieveAndSetBranchList();
      }
    } else {
      setBranchDTOList([]);
      setBranchIdList([]);
    }
  }, [gradeIdList]);

  useEffect(() => {
    const retrieveAndSetSeasonDetail = async () => {
      const { data, err } = await retrieveSeasonByIdInDetail(selectedSeasonId);
      if (err) {
        console.log(err);
        return;
      }
      const { staffUserProfileDTOList } = data;
      setStaffUserProfileDTOList(staffUserProfileDTOList);
    };
    if (
      selectedBaseReportType === "PERFORMANCE" &&
      selectedSubReportType === "COTERIE_TYPE"
    ) {
      if (selectedSeasonId !== "DEFAULT_OPTION") {
        retrieveAndSetSeasonDetail();
      }
    }
  }, [selectedSeasonId]);

  useEffect(() => {
    const retrieveAndSetIndividualModelList = async () => {
      const { data, err } = await queryIndividualModalByParams({
        userId: findUserIdFromSelectedUserProfileId(),
      });
      if (err) {
        console.log(err);
        return;
      }
      if (data.length === 0) {
        setIndividualModelList([
          constructBaseVerbalIndvidualModel(),
          constructBaseNumericIndividualModel(),
        ]);
      } else {
        const currentData = [];
        const foundVerbal = data.find((item) => item.coterieField === "VERBAL");
        if (foundVerbal) {
          currentData.push(foundVerbal);
        } else {
          currentData.push(constructBaseVerbalIndvidualModel());
        }
        const foundNumeric = data.find(
          (item) => item.coterieField === "NUMERIC"
        );
        if (foundNumeric) {
          currentData.push(foundNumeric);
        } else {
          currentData.push(constructBaseNumericIndividualModel());
        }
        setIndividualModelList(currentData);
      }
      setIndividualModelLoading(false);
    };
    if (showIndividualModel) {
      retrieveAndSetIndividualModelList();
    } else {
      setIndividualModelLoading(true);
      setIndividualModelList([]);
    }
  }, [showIndividualModel]);

  const retrieveRegionDTOOptionList = () => {
    return regionDTOList.map((regionDTO) => ({
      value: regionDTO.code,
      text: regionDTO.code,
    }));
  };

  const retrieveLearnerUserProfileDTOOptionList = () => {
    return learnerUserProfileDTOList.map((userProfileDTO) => ({
      value: userProfileDTO.id,
      text: `${userProfileDTO.userDTO.firstName} ${userProfileDTO.userDTO.lastName} (${userProfileDTO.name})`,
    }));
  };

  const retrieveStaffUserProfileDTOOptionList = () => {
    return staffUserProfileDTOList.map((userProfileDTO) => {
      let name = "";
      const { seasonId, seasonName, gradeId, gradeName, branchId, branchName } =
        userProfileDTO;
      if (branchId) {
        name = branchName;
      } else if (gradeId) {
        name = gradeName;
      } else if (seasonId) {
        name = seasonName;
      }
      return {
        value: userProfileDTO.id,
        text: `${i18n.t(userProfileDTO.coterieType)} - ${name} | ${
          userProfileDTO.userDTO.firstName
        } ${userProfileDTO.userDTO.lastName} (${userProfileDTO.name})`,
      };
    });
  };

  const retrieveBaseReportTypeOptionList = () => {
    return [
      {
        value: "INFORMATION",
        text: i18n.t("information_report"),
      },
      {
        value: "CATCHTIVITY",
        text: i18n.t("catchtivity_report"),
      },
      {
        value: "CATCHXAM",
        text: i18n.t("catchxam_report"),
      },
      {
        value: "STANDARD_EXAM",
        text: i18n.t("standard_exam_report"),
      },
      {
        value: "LEARNING_OUTCOME",
        text: i18n.t("learning_outcome_report"),
      },
      { value: "PARTICIPATION", text: i18n.t("participation_report") },
      { value: "PERFORMANCE", text: i18n.t("performance_report") },
    ];
  };

  const retrieveSubReportTypeOptionList = () => {
    const subReportTypeOptionList = [];
    subReportTypeOptionList.push(
      {
        value: "BRANCH",
        parent: "INFORMATION",
        text: i18n.t("branch_report"),
      },
      { value: "BRANCH", parent: "CATCHTIVITY", text: i18n.t("branch_report") },
      { value: "BRANCH", parent: "CATCHXAM", text: i18n.t("branch_report") },
      {
        value: "BRANCH",
        parent: "STANDARD_EXAM",
        text: i18n.t("branch_report"),
      },
      {
        value: "BRANCH",
        parent: "LEARNING_OUTCOME",
        text: i18n.t("branch_report"),
      },
      {
        value: "USER_PROFILE",
        parent: "LEARNING_OUTCOME",
        text: i18n.t("user_report"),
      },
      {
        value: "BRANCH",
        parent: "PARTICIPATION",
        text: i18n.t("branch_report"),
      },
      {
        value: "USER_PROFILE",
        parent: "PARTICIPATION",
        text: i18n.t("user_report"),
      },
      {
        value: "USER_PROFILE",
        parent: "PERFORMANCE",
        text: i18n.t("user_report"),
      }
    );
    if (userProfileGrade) {
      subReportTypeOptionList.push(
        {
          value: "GRADE",
          parent: "INFORMATION",
          text: i18n.t("grade_report"),
        },
        { value: "GRADE", parent: "CATCHTIVITY", text: i18n.t("grade_report") },
        { value: "GRADE", parent: "CATCHXAM", text: i18n.t("grade_report") },
        {
          value: "GRADE",
          parent: "STANDARD_EXAM",
          text: i18n.t("grade_report"),
        },
        {
          value: "GRADE",
          parent: "LEARNING_OUTCOME",
          text: i18n.t("grade_report"),
        },
        {
          value: "GRADE",
          parent: "PARTICIPATION",
          text: i18n.t("grade_report"),
        }
      );
    } else {
      subReportTypeOptionList.push(
        {
          value: "GRADE",
          parent: "INFORMATION",
          text: i18n.t("grade_report"),
        },
        { value: "GRADE", parent: "CATCHTIVITY", text: i18n.t("grade_report") },
        { value: "GRADE", parent: "CATCHXAM", text: i18n.t("grade_report") },
        {
          value: "GRADE",
          parent: "STANDARD_EXAM",
          text: i18n.t("grade_report"),
        },
        {
          value: "GRADE",
          parent: "LEARNING_OUTCOME",
          text: i18n.t("grade_report"),
        },
        {
          value: "GRADE",
          parent: "PARTICIPATION",
          text: i18n.t("grade_report"),
        }
      );
      if (userProfileSeason) {
        if (userProfile.role === "STAFF") {
          subReportTypeOptionList.push(
            {
              value: "SEASON",
              parent: "INFORMATION",
              text: i18n.t("season_report"),
            },
            {
              value: "SEASON",
              parent: "CATCHTIVITY",
              text: i18n.t("season_report"),
            },
            {
              value: "SEASON",
              parent: "CATCHXAM",
              text: i18n.t("season_report"),
            },
            {
              value: "SEASON",
              parent: "STANDARD_EXAM",
              text: i18n.t("season_report"),
            },
            {
              value: "SEASON",
              parent: "LEARNING_OUTCOME",
              text: i18n.t("season_report"),
            },
            {
              value: "SEASON",
              parent: "PARTICIPATION",
              text: i18n.t("season_report"),
            }
          );
        }
      } else {
        subReportTypeOptionList.push(
          {
            value: "SEASON",
            parent: "INFORMATION",
            text: i18n.t("season_report"),
          },
          {
            value: "SEASON",
            parent: "CATCHTIVITY",
            text: i18n.t("season_report"),
          },
          {
            value: "SEASON",
            parent: "CATCHXAM",
            text: i18n.t("season_report"),
          },
          {
            value: "SEASON",
            parent: "STANDARD_EXAM",
            text: i18n.t("season_report"),
          },
          {
            value: "SEASON",
            parent: "LEARNING_OUTCOME",
            text: i18n.t("season_report"),
          },
          {
            value: "SEASON",
            parent: "PARTICIPATION",
            text: i18n.t("season_report"),
          }
        );
        if (userProfileInstitution) {
          // subReportTypeOptionList.push({
          //   value: "INSTITUTION",
          //   parent: "INFORMATION",
          //   text: i18n.t("institution_report"),
          // });
          subReportTypeOptionList.push({
            value: "COTERIE_TYPE",
            parent: "PERFORMANCE",
            text: i18n.t("coterie_report"),
          });
        } else {
          // subReportTypeOptionList.push({
          //   value: "INSTITUTION",
          //   parent: "INFORMATION",
          //   text: i18n.t("institution_report"),
          // });
          subReportTypeOptionList.push({
            value: "COTERIE_TYPE",
            parent: "PERFORMANCE",
            text: i18n.t("coterie_report"),
          });
          if (userProfileCampus) {
            // subReportTypeOptionList.push({
            //   value: "CAMPUS",
            //   parent: "INFORMATION",
            //   text: i18n.t("campus_report"),
            // });
          } else {
            // subReportTypeOptionList.push({
            //   value: "CAMPUS",
            //   parent: "INFORMATION",
            //   text: i18n.t("campus_report"),
            // });
            if (userProfileBrand) {
              // subReportTypeOptionList.push({
              //   value: "REGION",
              //   parent: "INFORMATION",
              //   text: i18n.t("region_report"),
              // });
              // subReportTypeOptionList.push({
              //   value: "BRAND",
              //   parent: "INFORMATION",
              //   text: i18n.t("brand_report"),
              // });
            }
          }
        }
      }
    }
    return subReportTypeOptionList;
  };

  const retrieveCatchtivityDTOOptionList = () => {
    return catchtivityDTOList.map((catchtivityDTO) => ({
      text: `${catchtivityDTO.name} (${i18n.t(
        catchtivityDTO.coterieType
      )} ${i18n.t(catchtivityDTO.level)})`,
      value: catchtivityDTO.id,
    }));
  };

  const retrieveCatchxamDTOOptionList = () => {
    return catchxamDTOList.map((catchxamDTO) => ({
      text: `${catchxamDTO.name} (${i18n.t(catchxamDTO.coterieType)} ${i18n.t(
        catchxamDTO.level
      )})`,
      value: catchxamDTO.id,
    }));
  };

  const retrieveStandardExamDTOOptionList = () => {
    return standardExamDTOList.map((standardExamDTO) => ({
      text: standardExamDTO.name,
      value: standardExamDTO.id,
    }));
  };

  const filterCategoryVersionCodeOptionList = () => {
    return retrieveCategoryVersionCodeOptionList();
  };

  const retrieveSubCategoryCodeList = () => {
    const filteredCategoryDTOList = [];
    const filteredMainCategoryDTOList = categorySet.filter(
      (categoryDTO) => categoryDTO.versionCode === selectedCategoryCode
    );
    for (const currentCategoryDTO of filteredMainCategoryDTOList) {
      const foundIndex = filteredMainCategoryDTOList.findIndex(
        (categoryDTO) => categoryDTO.parentCode === currentCategoryDTO.code
      );
      if (foundIndex !== -1) {
        filteredCategoryDTOList.push(currentCategoryDTO);
      }
    }

    return filteredCategoryDTOList
      .sort((a, b) => a.code.localeCompare(b.code))
      .map((categoryDTO) => ({
        value: categoryDTO.id,
        text: `${categoryDTO.code} - ${categoryDTO.name}`,
      }));
  };

  const retrieveSubCategoryCodeIdList = () => {
    const foundSubCategory = categorySet.find(
      (category) =>
        parseFloat(category.id) === parseFloat(selectedSubCategoryCode)
    );
    if (foundSubCategory) {
      let currentCategorySet = [];
      currentCategorySet.concat(
        recursiveSubCategoryCodeIdList(
          categorySet,
          currentCategorySet,
          foundSubCategory.code
        )
      );
      return currentCategorySet.map((category) => category.id);
    }
    return [];
  };

  const retrieveSubReportTypeIcon = (subReportType) => {
    if (subReportType === "BRAND") {
      return "/icons/brand-report.png";
    } else if (subReportType === "REGION") {
      return "/icons/region-report.png";
    } else if (subReportType === "CAMPUS") {
      return "/icons/campus-report.png";
    } else if (subReportType === "INSTITUTION") {
      return "/icons/institution-report.png";
    } else if (subReportType === "SEASON") {
      return "/icons/season-report.png";
    } else if (subReportType === "GRADE") {
      return "/icons/grade-report.png";
    } else if (subReportType === "BRANCH") {
      return "/icons/branch-report.png";
    } else if (subReportType === "USER_PROFILE") {
      return "/icons/user-profile-report.png";
    } else if (subReportType === "COTERIE_TYPE") {
      return "/icons/coterie-report.png";
    }
    return null;
  };

  const retrieveBaseReportTypeIcon = (baseReportType) => {
    if (baseReportType === "INFORMATION") {
      return "/icons/info.png";
    } else if (baseReportType === "CATCHTIVITY") {
      return "/icons/catchtivity.png";
    } else if (baseReportType === "CATCHXAM") {
      return "/icons/catchxam.png";
    } else if (baseReportType === "STANDARD_EXAM") {
      return "/icons/standard-exam.png";
    } else if (baseReportType === "LEARNING_OUTCOME") {
      return "/icons/outcome.png";
    } else if (baseReportType === "PARTICIPATION") {
      return "/icons/participation.png";
    } else if (baseReportType === "PERFORMANCE") {
      return "/icons/performance.png";
    }
  };

  const checkIfManagementIsList = () => {
    if (
      selectedSubReportType === "USER_PROFILE" ||
      selectedSubReportType === "COTERIE_TYPE"
    ) {
      return false;
    }
    return true;
  };

  const recursiveSubCategoryCodeIdList = (
    categorySet,
    currentCategorySet,
    categoryCode
  ) => {
    const filterCategorySet = categorySet.filter(
      (category) => category.parentCode === categoryCode
    );
    filterCategorySet.forEach((filterCategory) => {
      const foundIndex = categorySet.findIndex(
        (categoryDTO) => categoryDTO.parentCode === filterCategory.code
      );
      if (foundIndex === -1) {
        const foundCurrentIndex = currentCategorySet.findIndex(
          (currentCategory) => currentCategory.id === filterCategory.id
        );
        if (foundCurrentIndex === -1) {
          currentCategorySet.push(filterCategory);
        }
      } else {
        recursiveSubCategoryCodeIdList(
          categorySet,
          currentCategorySet,
          filterCategory.code
        );
      }
    });
    return currentCategorySet;
  };

  const resetParams = () => {
    setSelectedRegionCode("DEFAULT_OPTION");
    setRegionCodeList([]);
    setSelectedCampusId("DEFAULT_OPTION");
    setCampusIdList([]);
    setSelectedInstitutionId("DEFAULT_OPTION");
    setInstitutionIdList([]);
    setSelectedSeasonId("DEFAULT_OPTION");
    setSeasonIdList([]);
    setSelectedGradeId("DEFAULT_OPTION");
    setGradeIdList([]);
    setSelectedBranchId("DEFAULT_OPTION");
    setBranchIdList([]);
    setBeginAt("");
    setEndAt("");
    setCatchxamDTOList([]);
    setSelectedCatchxamId("DEFAULT_OPTION");
    setStandardExamDTOList([]);
    setSelectedStandardExamId("DEFAULT_OPTION");
    setCatchtivityDTOList([]);
    setSelectedCatchtivityId("DEFAULT_OPTION");
    setReportDetail(null);
    setReportDetailList([]);
    setReportDetailLoading(false);
    setLearnerUserProfileDTOList([]);
    setStaffUserProfileDTOList([]);
    setStaffUserProfileIdList([]);
    setSelectedUserProfileId("DEFAULT_OPTION");
    setErrorMap({
      brandId: null,
      beginAt: null,
      endAt: null,
      selectedCatchtivityId: null,
      selectedCatchxamId: null,
      selectedStandardExamId: null,
      selectedCategoryCode: null,
      selectedSubCategoryCode: null,
    });
  };

  const canShowGenerateReportButton = () => {
    if (userProfile.role === "STAFF") {
      if (
        selectedSubReportType === "BRAND" &&
        selectedBrandId !== "DEFAULT_OPTION"
      ) {
        return true;
      }
      if (selectedSubReportType === "REGION" && regionCodeList.length > 0) {
        return true;
      }
      if (
        selectedSubReportType === "CAMPUS" &&
        selectedBrandId !== "DEFAULT_OPTION" &&
        campusIdList.length > 0
      ) {
        return true;
      }
      if (
        selectedSubReportType === "INSTITUTION" &&
        selectedBrandId !== "DEFAULT_OPTION" &&
        campusIdList.length > 0 &&
        institutionIdList.length > 0
      ) {
        return true;
      }
      if (
        selectedSubReportType === "SEASON" &&
        selectedBrandId !== "DEFAULT_OPTION" &&
        campusIdList.length > 0 &&
        institutionIdList.length > 0 &&
        seasonIdList.length > 0
      ) {
        return true;
      }
      if (
        selectedSubReportType === "GRADE" &&
        selectedBrandId !== "DEFAULT_OPTION" &&
        campusIdList.length > 0 &&
        institutionIdList.length > 0 &&
        seasonIdList.length > 0 &&
        gradeIdList.length > 0
      ) {
        return true;
      }
      if (
        selectedSubReportType === "BRANCH" &&
        selectedBrandId !== "DEFAULT_OPTION" &&
        campusIdList.length > 0 &&
        institutionIdList.length > 0 &&
        seasonIdList.length > 0 &&
        gradeIdList.length > 0 &&
        branchIdList.length > 0
      ) {
        return true;
      }
      if (
        (selectedBaseReportType === "LEARNING_OUTCOME" ||
          selectedBaseReportType === "PARTICIPATION" ||
          selectedBaseReportType === "PERFORMANCE") &&
        (selectedSubReportType === "USER_PROFILE" ||
          selectedSubReportType === "COTERIE_TYPE") &&
        selectedBrandId !== "DEFAULT_OPTION" &&
        selectedCampusId !== "DEFAULT_OPTION" &&
        selectedInstitutionId !== "DEFAULT_OPTION" &&
        selectedSeasonId !== "DEFAULT_OPTION" &&
        selectedGradeId !== "DEFAULT_OPTION" &&
        selectedBranchId !== "DEFAULT_OPTION" &&
        selectedUserProfileId !== "DEFAULT_OPTION"
      ) {
        return true;
      }
      if (
        selectedBaseReportType === "PERFORMANCE" &&
        selectedSubReportType === "COTERIE_TYPE" &&
        selectedBrandId !== "DEFAULT_OPTION" &&
        selectedCampusId !== "DEFAULT_OPTION" &&
        selectedInstitutionId !== "DEFAULT_OPTION" &&
        selectedSeasonId !== "DEFAULT_OPTION" &&
        staffUserProfileIdList.length > 0
      ) {
        return true;
      }
    } else {
      if (
        selectedSubReportType === "GRADE" &&
        selectedBrandId !== "DEFAULT_OPTION" &&
        seasonIdList.length > 0 &&
        gradeIdList.length > 0
      ) {
        return true;
      }
      if (
        selectedSubReportType === "BRANCH" &&
        selectedBrandId !== "DEFAULT_OPTION" &&
        seasonIdList.length > 0 &&
        gradeIdList.length > 0 &&
        branchIdList.length > 0
      ) {
        return true;
      }
      if (
        (selectedBaseReportType === "LEARNING_OUTCOME" ||
          selectedBaseReportType === "PARTICIPATION") &&
        selectedSubReportType === "USER_PROFILE" &&
        selectedGradeId !== "DEFAULT_OPTION" &&
        selectedBranchId !== "DEFAULT_OPTION" &&
        selectedUserProfileId !== "DEFAULT_OPTION"
      ) {
        return true;
      }
    }
  };

  const checkCommonValidation = () => {
    if (selectedBaseReportType === "INFORMATION") {
      if (
        beginAt === "" ||
        endAt === "" ||
        new Date(beginAt).getTime() >= new Date(endAt).getTime()
      ) {
        const currentErrorMap = {
          beginAt: null,
          endAt: null,
        };
        if (beginAt === "") {
          currentErrorMap.beginAt = i18n.t("begin_at_required_field");
        }
        if (endAt === "") {
          currentErrorMap.endAt = i18n.t("end_at_required_field");
        }
        if (new Date(beginAt).getTime() >= new Date(endAt).getTime()) {
          NotificationManager.error(
            i18n.t("begin_at_cannot_be_greater_than_end_at_text")
          );
        }
        setErrorMap(currentErrorMap);
        return false;
      }
    } else if (selectedBaseReportType === "CATCHTIVITY") {
      if (selectedCatchtivityId === "DEFAULT_OPTION") {
        const currentErrorMap = {
          selectedCatchtivityId: null,
        };
        if (selectedCampusId === "DEFAULT_OPTION") {
          currentErrorMap.selectedCatchtivityId = i18n.t(
            "catchtivity_required_field"
          );
        }
        setErrorMap(currentErrorMap);
        return false;
      }
    } else if (selectedBaseReportType === "CATCHXAM") {
      if (selectedCatchxamId === "DEFAULT_OPTION") {
        const currentErrorMap = {
          selectedCatchxamId: null,
        };
        if (selectedCampusId === "DEFAULT_OPTION") {
          currentErrorMap.selectedCatchxamId = i18n.t(
            "catchxam_required_field"
          );
        }
        setErrorMap(currentErrorMap);
        return false;
      }
    } else if (selectedBaseReportType === "STANDARD_EXAM") {
      if (selectedStandardExamId === "DEFAULT_OPTION") {
        const currentErrorMap = {
          selectedStandardExamId: null,
        };
        if (selectedCampusId === "DEFAULT_OPTION") {
          currentErrorMap.selectedStandardExamId = i18n.t(
            "standard_exam_required_field"
          );
        }
        setErrorMap(currentErrorMap);
        return false;
      }
    } else if (selectedBaseReportType === "LEARNING_OUTCOME") {
      if (
        selectedCategoryCode === "DEFAULT_OPTION" ||
        selectedSubCategoryCode === "DEFAULT_OPTION"
      ) {
        const currentErrorMap = {
          selectedCategoryCode: null,
          selectedSubCategoryCode: null,
        };
        if (selectedCategoryCode === "DEFAULT_OPTION") {
          currentErrorMap.selectedCategoryCode = i18n.t(
            "category_code_required_field"
          );
        }
        if (selectedSubCategoryCode === "DEFAULT_OPTION") {
          currentErrorMap.selectedSubCategoryCode = i18n.t(
            "sub_category_code_required_field"
          );
        }
        setErrorMap(currentErrorMap);
        return false;
      }
    } else if (selectedBaseReportType === "PERFORMANCE") {
      // if (selectedCoterieType === "DEFAULT_OPTION") {
      //   const currentErrorMap = {
      //     selectedCoterieType: null,
      //   };
      //   if (selectedCoterieType === "DEFAULT_OPTION") {
      //     currentErrorMap.selectedCoterieType = i18n.t(
      //       "coterie_type_required_field"
      //     );
      //   }
      //   setErrorMap(currentErrorMap);
      //   return false;
      // }
    }
    return true;
  };

  const constructSeasonGradeBranchMap = () => {
    const foundBranch = branchDTOList.find(
      (branchDTO) => parseFloat(branchDTO.id) === parseFloat(selectedBranchId)
    );
    return {
      branchId: foundBranch.id,
      branchName: foundBranch.name,
      gradeId: foundBranch.gradeDTO.id,
      gradeName: foundBranch.gradeDTO.name,
      seasonId: foundBranch.gradeDTO.seasonDTO.id,
      seasonName: foundBranch.gradeDTO.seasonDTO.name,
    };
  };

  const constructSeasonGradeBranchMapList = () => {
    const seasonGradeBranchMapList = [];
    for (const branchId of branchIdList) {
      const foundBranch = branchDTOList.find(
        (branchDTO) => parseFloat(branchDTO.id) === parseFloat(branchId)
      );
      if (foundBranch) {
        seasonGradeBranchMapList.push({
          branchId: foundBranch.id,
          branchName: foundBranch.name,
          gradeId: foundBranch.gradeDTO.id,
          gradeName: foundBranch.gradeDTO.name,
          seasonId: foundBranch.gradeDTO.seasonDTO.id,
          seasonName: foundBranch.gradeDTO.seasonDTO.name,
        });
      }
    }

    return seasonGradeBranchMapList;
  };

  const constructSeasonGradeMap = () => {
    const seasonGradeMapList = [];
    for (const gradeId of gradeIdList) {
      const foundGrade = gradeDTOList.find(
        (gradeDTO) => parseFloat(gradeDTO.id) === parseFloat(gradeId)
      );
      seasonGradeMapList.push({
        gradeId: foundGrade.id,
        gradeName: foundGrade.name,
        seasonId: foundGrade.seasonDTO.id,
        seasonName: foundGrade.seasonDTO.name,
      });
    }
    return seasonGradeMapList;
  };

  const constructSeasonMap = () => {
    const seasonMapList = [];
    for (const seasonId of seasonIdList) {
      const foundSeason = seasonDTOList.find(
        (seasonDTO) => parseFloat(seasonDTO.id) === parseFloat(seasonId)
      );
      seasonMapList.push({
        seasonId: foundSeason.id,
        seasonName: foundSeason.name,
      });
    }
    return seasonMapList;
  };

  const constructLearnerUserProfileMap = () => {
    const foundUserProfileDTO = learnerUserProfileDTOList.find(
      (userProfileDTO) =>
        parseFloat(userProfileDTO.id) === parseFloat(selectedUserProfileId)
    );
    return {
      userId: foundUserProfileDTO.userDTO.id,
      firstName: foundUserProfileDTO.userDTO.firstName,
      lastName: foundUserProfileDTO.userDTO.lastName,
      userProfileId: foundUserProfileDTO.id,
      userProfileName: foundUserProfileDTO.name,
    };
  };

  const constructStaffUserProfileMapListFromIdList = () => {
    return staffUserProfileIdList.map((staffUserProfileId) => {
      const foundUserProfileDTO = staffUserProfileDTOList.find(
        (userProfileDTO) =>
          parseFloat(userProfileDTO.id) === parseFloat(staffUserProfileId)
      );
      return foundUserProfileDTO;
    });
  };

  const findUserIdFromSelectedUserProfileId = () => {
    const foundLearnerUserProfile = learnerUserProfileDTOList.find(
      (learnerUserProfile) => learnerUserProfile.id === selectedUserProfileId
    );
    if (foundLearnerUserProfile) {
      return foundLearnerUserProfile.userDTO.id;
    }
    return -1;
  };

  const handleOnChange = (type, value) => {
    if (type === "beginAt") {
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        beginAt: null,
      }));
      setBeginAt(value);
    } else if (type === "endAt") {
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        endAt: null,
      }));
      setEndAt(value);
    } else if (type === "regionCode") {
      setSelectedRegionCode(value);
    } else if (type === "regionCodeAdd") {
      handleAddRegionToUserProfileOnClick(value);
    } else if (type === "brandId") {
      setSelectedBrandId(value);
    } else if (type === "campusId") {
      setSelectedCampusId(value);
    } else if (type === "campusIdAdd") {
      handleAddCampusToCampusIdListOnClick(value);
    } else if (type === "institutionId") {
      setSelectedInstitutionId(value);
    } else if (type === "institutionIdAdd") {
      handleAddInstitutionToInstitutionIdListOnClick(value);
    } else if (type === "seasonId") {
      setSelectedSeasonId(value);
    } else if (type === "seasonIdAdd") {
      handleAddSeasonToSeasonIdListOnClick(value);
    } else if (type === "gradeId") {
      setSelectedGradeId(value);
    } else if (type === "gradeIdAdd") {
      handleAddGradeToGradeIdListOnClick(value);
    } else if (type === "branchId") {
      setSelectedBranchId(value);
    } else if (type === "branchIdAdd") {
      handleAddBranchToBranchIdListOnClick(value);
    } else if (type === "catchtivityId") {
      setSelectedCatchtivityId(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        selectedCatchtivityId: null,
      }));
    } else if (type === "catchxamId") {
      setSelectedCatchxamId(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        selectedCatchxamId: null,
      }));
    } else if (type === "standardExamId") {
      setSelectedStandardExamId(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        selectedStandardExamId: null,
      }));
    } else if (type === "categoryCode") {
      setSelectedCategoryCode(value);
      setSelectedSubCategoryCode("DEFAULT_OPTION");
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        selectedCategoryCode: null,
      }));
    } else if (type === "subCategoryCode") {
      setSelectedSubCategoryCode(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        selectedSubCategoryCode: null,
      }));
    } else if (type === "userProfileId") {
      setSelectedUserProfileId(value);
    } else if (type === "staffUserProfileIdAdd") {
      handleAddStaffUserProfileToUserProfileOnClick(value);
    }
  };

  const handleAddRegionToUserProfileOnClick = (selectedRegionCode) => {
    if (selectedRegionCode !== "DEFAULT_OPTION") {
      const foundIndex = regionCodeList.findIndex(
        (regionCode) => regionCode === selectedRegionCode
      );
      if (foundIndex === -1) {
        regionCodeList.push(selectedRegionCode);
        setRegionCodeList(JSON.parse(JSON.stringify(regionCodeList)));
      }
    }
  };

  const handleAddCampusToCampusIdListOnClick = (selectedCampusId) => {
    if (selectedCampusId !== "DEFAULT_OPTION") {
      const foundIndex = campusIdList.findIndex(
        (campusId) => parseFloat(campusId) === parseFloat(selectedCampusId)
      );
      if (foundIndex === -1) {
        campusIdList.push(selectedCampusId);
        setCampusIdList(JSON.parse(JSON.stringify(campusIdList)));
      }
    }
  };

  const handleAddInstitutionToInstitutionIdListOnClick = (
    selectedInstitutionId
  ) => {
    if (selectedInstitutionId !== "DEFAULT_OPTION") {
      const foundIndex = institutionIdList.findIndex(
        (institutionId) =>
          parseFloat(institutionId) === parseFloat(selectedInstitutionId)
      );
      if (foundIndex === -1) {
        institutionIdList.push(selectedInstitutionId);
        setInstitutionIdList(JSON.parse(JSON.stringify(institutionIdList)));
      }
    }
  };

  const handleAddSeasonToSeasonIdListOnClick = (selectedSeasonId) => {
    if (selectedSeasonId !== "DEFAULT_OPTION") {
      const foundIndex = seasonIdList.findIndex(
        (seasonId) => parseFloat(seasonId) === parseFloat(selectedSeasonId)
      );
      if (foundIndex === -1) {
        seasonIdList.push(selectedSeasonId);
        setSeasonIdList(JSON.parse(JSON.stringify(seasonIdList)));
      }
    }
  };

  const handleAddGradeToGradeIdListOnClick = (selectedGradeId) => {
    if (selectedGradeId !== "DEFAULT_OPTION") {
      const foundIndex = gradeIdList.findIndex(
        (gradeId) => parseFloat(gradeId) === parseFloat(selectedGradeId)
      );
      if (foundIndex === -1) {
        gradeIdList.push(selectedGradeId);
        setGradeIdList(JSON.parse(JSON.stringify(gradeIdList)));
      }
    }
  };

  const handleAddBranchToBranchIdListOnClick = (selectedBranchId) => {
    if (selectedBranchId !== "DEFAULT_OPTION") {
      const foundIndex = branchIdList.findIndex(
        (branchId) => parseFloat(branchId) === parseFloat(selectedBranchId)
      );
      if (foundIndex === -1) {
        branchIdList.push(selectedBranchId);
        setBranchIdList(JSON.parse(JSON.stringify(branchIdList)));
      }
    }
  };

  const handleAddStaffUserProfileToUserProfileOnClick = (
    selectedUserProfileId
  ) => {
    if (selectedUserProfileId !== "DEFAULT_OPTION") {
      const foundIndex = staffUserProfileIdList.findIndex(
        (staffUserProfileId) =>
          parseFloat(staffUserProfileId) === parseFloat(selectedUserProfileId)
      );
      if (foundIndex === -1) {
        staffUserProfileIdList.push(selectedUserProfileId);
        setStaffUserProfileIdList(
          JSON.parse(JSON.stringify(staffUserProfileIdList))
        );
      }
    }
  };

  const handleRemoveRegionFromUserProfileOnClick = (regionCode) => {
    const foundIndex = regionCodeList.findIndex(
      (foundRegionCode) => foundRegionCode === regionCode
    );
    if (foundIndex > -1) {
      regionCodeList.splice(foundIndex, 1);
      setRegionCodeList(JSON.parse(JSON.stringify(regionCodeList)));
    }
  };

  const handleRemoveCampusFromUserProfileOnClick = (campusId) => {
    const foundIndex = campusIdList.findIndex(
      (foundCampusId) => parseFloat(foundCampusId) === parseFloat(campusId)
    );
    if (foundIndex > -1) {
      campusIdList.splice(foundIndex, 1);
      setCampusIdList(
        JSON.parse(JSON.stringify(campusIdList.sort((a, b) => b.id - a.id)))
      );
    }
  };

  const handleRemoveInstitutionFromUserProfileOnClick = (institutionId) => {
    const foundIndex = institutionIdList.findIndex(
      (foundInstitutionId) =>
        parseFloat(foundInstitutionId) === parseFloat(institutionId)
    );
    if (foundIndex > -1) {
      institutionIdList.splice(foundIndex, 1);
      setInstitutionIdList(
        JSON.parse(
          JSON.stringify(institutionIdList.sort((a, b) => b.id - a.id))
        )
      );
    }
  };

  const handleRemoveSeasonFromUserProfileOnClick = (seasonId) => {
    const foundIndex = seasonIdList.findIndex(
      (foundSeasonId) => parseFloat(foundSeasonId) === parseFloat(seasonId)
    );
    if (foundIndex > -1) {
      seasonIdList.splice(foundIndex, 1);
      setSeasonIdList(
        JSON.parse(JSON.stringify(seasonIdList.sort((a, b) => b.id - a.id)))
      );
    }
  };

  const handleRemoveGradeFromUserProfileOnClick = (gradeId) => {
    const foundIndex = gradeIdList.findIndex(
      (foundGradeId) => parseFloat(foundGradeId) === parseFloat(gradeId)
    );
    if (foundIndex > -1) {
      gradeIdList.splice(foundIndex, 1);
      setGradeIdList(
        JSON.parse(JSON.stringify(gradeIdList.sort((a, b) => b.id - a.id)))
      );
    }
  };

  const handleRemoveBranchFromUserProfileOnClick = (branchId) => {
    const foundIndex = branchIdList.findIndex(
      (foundBranchId) => parseFloat(foundBranchId) === parseFloat(branchId)
    );
    if (foundIndex > -1) {
      branchIdList.splice(foundIndex, 1);
      setBranchIdList(
        JSON.parse(JSON.stringify(branchIdList.sort((a, b) => b.id - a.id)))
      );
    }
  };

  const handleRemoveStaffUserProfileFromUserProfileOnClick = (
    staffUserProfileId
  ) => {
    const foundIndex = staffUserProfileIdList.findIndex(
      (foundStaffUserProfileId) =>
        parseFloat(foundStaffUserProfileId) === parseFloat(staffUserProfileId)
    );
    if (foundIndex > -1) {
      staffUserProfileIdList.splice(foundIndex, 1);
      setStaffUserProfileIdList(
        JSON.parse(
          JSON.stringify(staffUserProfileIdList.sort((a, b) => b.id - a.id))
        )
      );
    }
  };

  const handleGenerateReportOnClick = async () => {
    setReportDetailLoading(true);
    if (checkCommonValidation()) {
      if (selectedBaseReportType === "INFORMATION") {
        const newReport = {
          type: selectedSubReportType,
          name: "",
          description: "",
          userId: userInformation.id,
          userProfileId: userProfile.id,
          beginAt: new Date(beginAt).getTime(),
          endAt: new Date(endAt).getTime(),
          brandIdSet: userProfile.role === "STAFF" ? [selectedBrandId] : [],
          campusIdSet: campusIdList,
          institutionIdSet: institutionIdList,
          seasonIdSet: seasonIdList,
          gradeIdSet: gradeIdList,
          branchIdSet: branchIdList,
          regionCodeSet: regionCodeList,
          deleted: false,
        };
        setBase64DataEncoded(
          btoa(
            encodeURI(
              JSON.stringify({
                ...newReport,
                baseReportType: selectedBaseReportType,
                subReportType: selectedSubReportType,
              })
            )
          )
        );
        let data = null;
        let err = null;
        if (selectedSubReportType === "BRANCH") {
          const { data: branchData, err: branchErr } =
            await generateInformationBranchReport(newReport);
          data = branchData;
          err = branchErr;
        } else if (selectedSubReportType === "GRADE") {
          const { data: gradeData, err: gradeErr } =
            await generateInformationGradeReport(newReport);
          data = gradeData;
          err = gradeErr;
        } else if (selectedSubReportType === "SEASON") {
          const { data: seasonData, err: seasonErr } =
            await generateInformationSeasonReport(newReport);
          data = seasonData;
          err = seasonErr;
        } else if (selectedSubReportType === "INSTITUTION") {
          const { data: institutionData, err: institutionErr } =
            await generateInformationInstitutionReport(newReport);
          data = institutionData;
          err = institutionErr;
        } else if (selectedSubReportType === "CAMPUS") {
          const { data: campusData, err: campusErr } =
            await generateInformationCampusReport(newReport);
          data = campusData;
          err = campusErr;
        } else if (selectedSubReportType === "REGION") {
          const { data: regionData, err: regionErr } =
            await generateInformationRegionReport(newReport);
          data = regionData;
          err = regionErr;
        } else if (selectedSubReportType === "BRAND") {
          const { data: brandData, err: brandErr } =
            await generateInformationBrandReport(newReport);
          data = brandData;
          err = brandErr;
        }
        if (err) {
          console.log(err);
          NotificationManager.error(i18n.t(err.response.data));
        } else {
          setReportDetail(data);
        }
      } else if (selectedBaseReportType === "CATCHTIVITY") {
        let data = null;
        let err = null;
        if (selectedSubReportType === "BRANCH") {
          const newReport = {
            catchtivityId: selectedCatchtivityId,
            seasonGradeBranchMapList: constructSeasonGradeBranchMapList(),
            deleted: false,
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                  subReportType: selectedSubReportType,
                })
              )
            )
          );
          const { data: branchData, err: branchErr } =
            await generateCatchtivityBranchReport(newReport);
          data = branchData;
          err = branchErr;
        } else if (selectedSubReportType === "GRADE") {
          const newReport = {
            catchtivityId: selectedCatchtivityId,
            seasonGradeMapList: constructSeasonGradeMap(),
            deleted: false,
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                  subReportType: selectedSubReportType,
                })
              )
            )
          );
          const { data: gradeData, err: gradeErr } =
            await generateCatchtivityGradeReport(newReport);
          data = gradeData;
          err = gradeErr;
        } else if (selectedSubReportType === "SEASON") {
          const newReport = {
            catchtivityId: selectedCatchtivityId,
            seasonMapList: constructSeasonMap(),
            deleted: false,
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                  subReportType: selectedSubReportType,
                })
              )
            )
          );
          const { data: seasonData, err: seasonErr } =
            await generateCatchtivitySeasonReport(newReport);
          data = seasonData;
          err = seasonErr;
        }
        if (err) {
          console.log(err);
        } else {
          setReportDetailList(data);
        }
      } else if (selectedBaseReportType === "CATCHXAM") {
        let data = null;
        let err = null;
        if (selectedSubReportType === "BRANCH") {
          const newReport = {
            catchxamId: selectedCatchxamId,
            seasonGradeBranchMapList: constructSeasonGradeBranchMapList(),
            deleted: false,
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                  subReportType: selectedSubReportType,
                })
              )
            )
          );
          const { data: branchData, err: branchErr } =
            await generateCatchxamBranchReport(newReport);
          data = branchData;
          err = branchErr;
        } else if (selectedSubReportType === "GRADE") {
          const newReport = {
            catchxamId: selectedCatchxamId,
            seasonGradeMapList: constructSeasonGradeMap(),
            deleted: false,
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                  subReportType: selectedSubReportType,
                })
              )
            )
          );
          const { data: gradeData, err: gradeErr } =
            await generateCatchxamGradeReport(newReport);
          data = gradeData;
          err = gradeErr;
        } else if (selectedSubReportType === "SEASON") {
          const newReport = {
            catchxamId: selectedCatchxamId,
            seasonMapList: constructSeasonMap(),
            deleted: false,
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                  subReportType: selectedSubReportType,
                })
              )
            )
          );
          const { data: seasonData, err: seasonErr } =
            await generateCatchxamSeasonReport(newReport);
          data = seasonData;
          err = seasonErr;
        }

        if (err) {
          console.log(err);
        } else {
          setReportDetailList(data);
        }
      } else if (selectedBaseReportType === "STANDARD_EXAM") {
        let data = null;
        let err = null;
        if (selectedSubReportType === "BRANCH") {
          const newReport = {
            standardExamId: selectedStandardExamId,
            seasonGradeBranchMapList: constructSeasonGradeBranchMapList(),
            deleted: false,
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                })
              )
            )
          );
          const { data: branchData, err: branchErr } =
            await generateStandardExamBranchReport(newReport);
          data = branchData;
          err = branchErr;
        } else if (selectedSubReportType === "GRADE") {
          const newReport = {
            standardExamId: selectedStandardExamId,
            seasonGradeMapList: constructSeasonGradeMap(),
            deleted: false,
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                })
              )
            )
          );
          const { data: gradeData, err: gradeErr } =
            await generateStandardExamGradeReport(newReport);
          data = gradeData;
          err = gradeErr;
        } else if (selectedSubReportType === "SEASON") {
          const newReport = {
            standardExamId: selectedStandardExamId,
            seasonMapList: constructSeasonMap(),
            deleted: false,
          };
          setBase64DataEncoded(
            btoa(
              JSON.stringify({
                ...newReport,
                baseReportType: selectedBaseReportType,
              })
            )
          );
          const { data: seasonData, err: seasonErr } =
            await generateStandardExamSeasonReport(newReport);
          data = seasonData;
          err = seasonErr;
        }

        if (err) {
          console.log(err);
        } else {
          setReportDetailList(data);
        }
      } else if (selectedBaseReportType === "LEARNING_OUTCOME") {
        let data = null;
        let err = null;
        const categoryIdList = retrieveSubCategoryCodeIdList();
        if (selectedSubReportType === "BRANCH") {
          const newReport = {
            categoryIdList,
            seasonGradeBranchMapList: constructSeasonGradeBranchMapList(),
            versionCode: selectedCategoryCode,
            baseCategoryId: selectedSubCategoryCode,
            deleted: false,
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                  subReportType: selectedSubReportType,
                })
              )
            )
          );
          const { data: branchData, err: branchErr } =
            await generateLearningOutcomeBranchReport(newReport);
          data = branchData;
          err = branchErr;
        } else if (selectedSubReportType === "GRADE") {
          const newReport = {
            categoryIdList,
            seasonGradeMapList: constructSeasonGradeMap(),
            versionCode: selectedCategoryCode,
            baseCategoryId: selectedSubCategoryCode,
            deleted: false,
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                  subReportType: selectedSubReportType,
                })
              )
            )
          );
          const { data: gradeData, err: gradeErr } =
            await generateLearningOutcomeGradeReport(newReport);
          data = gradeData;
          err = gradeErr;
        } else if (selectedSubReportType === "SEASON") {
          const newReport = {
            categoryIdList,
            seasonMapList: constructSeasonMap(),
            versionCode: selectedCategoryCode,
            baseCategoryId: selectedSubCategoryCode,
            deleted: false,
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                  subReportType: selectedSubReportType,
                })
              )
            )
          );
          const { data: seasonData, err: seasonErr } =
            await generateLearningOutcomeSeasonReport(newReport);
          data = seasonData;
          err = seasonErr;
        } else if (selectedSubReportType === "USER_PROFILE") {
          const newReport = {
            categoryIdList,
            versionCode: selectedCategoryCode,
            baseCategoryId: selectedSubCategoryCode,
            userProfileMap: constructLearnerUserProfileMap(),
            seasonGradeBranchMap: constructSeasonGradeBranchMap(),
            deleted: false,
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                  subReportType: selectedSubReportType,
                })
              )
            )
          );
          const { data: userProfileData, err: userProfileErr } =
            await generateLearningOutcomeUserProfileReport(newReport);
          data = userProfileData;
          err = userProfileErr;
        }
        if (err) {
          console.log(err);
        } else {
          setReportDetailList(data);
        }
      } else if (selectedBaseReportType === "PARTICIPATION") {
        let data = null;
        let err = null;
        if (selectedSubReportType === "BRANCH") {
          const newReport = {
            seasonGradeBranchMapList: constructSeasonGradeBranchMapList(),
            deleted: false,
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                  subReportType: selectedSubReportType,
                })
              )
            )
          );
          const { data: branchData, err: branchErr } =
            await generateParticipationBranchReport(newReport);
          data = branchData;
          err = branchErr;
        } else if (selectedSubReportType === "GRADE") {
          const newReport = {
            seasonGradeMapList: constructSeasonGradeMap(),
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                  subReportType: selectedSubReportType,
                })
              )
            )
          );
          const { data: gradeData, err: gradeErr } =
            await generateParticipationGradeReport(newReport);
          data = gradeData;
          err = gradeErr;
        } else if (selectedSubReportType === "SEASON") {
          const newReport = {
            seasonMapList: constructSeasonMap(),
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                  subReportType: selectedSubReportType,
                })
              )
            )
          );
          const { data: seasonData, err: seasonErr } =
            await generateParticipationSeasonReport(newReport);
          data = seasonData;
          err = seasonErr;
        } else if (selectedSubReportType === "USER_PROFILE") {
          const newReport = {
            userProfileMap: constructLearnerUserProfileMap(),
            seasonGradeBranchMap: constructSeasonGradeBranchMap(),
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                  subReportType: selectedSubReportType,
                })
              )
            )
          );
          const { data: userProfileData, err: userProfileErr } =
            await generateParticipationUserProfileReport(newReport);
          data = userProfileData;
          err = userProfileErr;
        }
        if (err) {
          console.log(err);
        } else {
          setReportDetailList(data);
        }
      } else if (selectedBaseReportType === "PERFORMANCE") {
        let data = null;
        let err = null;
        if (selectedSubReportType === "USER_PROFILE") {
          const newReport = {
            userProfileMap: constructLearnerUserProfileMap(),
            seasonGradeBranchMap: constructSeasonGradeBranchMap(),
          };
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  ...newReport,
                  baseReportType: selectedBaseReportType,
                  subReportType: selectedSubReportType,
                })
              )
            )
          );
          const { data: userProfileData, err: userProfileErr } =
            await generatePerformanceUserProfileReport(newReport);
          data = userProfileData;
          err = userProfileErr;
        } else if (selectedSubReportType === "COTERIE_TYPE") {
          const newReportList =
            constructStaffUserProfileMapListFromIdList().map((item) => ({
              basicUserProfileDTO: item,
            }));
          setBase64DataEncoded(
            btoa(
              encodeURI(
                JSON.stringify({
                  newReportList,
                  baseReportType: selectedBaseReportType,
                  subReportType: selectedSubReportType,
                })
              )
            )
          );
          const { data: coterieData, err: coterieErr } =
            await generatePerformanceCoterieReport(newReportList);
          data = coterieData;
          err = coterieErr;
        }
        if (err) {
          console.log(err);
          return;
        }
        setReportDetailList(data);
      }
    }
    setReportDetailLoading(false);
  };

  const handleGeneratePDF = () => {
    window.open(`/view/pdf/${base64DataEncoded}`, "_blank");
  };

  const RenderUserReportContent = () => {
    return (
      <>
        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("brand")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedBrandId}
              optionList={retrieveBrandDTOOptionList(brandDTOList)}
              onChange={(event) => {
                handleOnChange("brandId", event.target.value);
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-row items-center">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("campus")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedCampusId}
              optionList={retrieveCampusDTOOptionList(campusDTOList)}
              onChange={(event) => {
                handleOnChange("campusId", event.target.value);
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-row items-center">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("institution")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedInstitutionId}
              optionList={retrieveInstitutionDTOOptionList(institutionDTOList)}
              onChange={(event) => {
                handleOnChange("institutionId", event.target.value);
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-row items-center">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("season")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedSeasonId}
              optionList={retrieveSeasonDTOOptionList(seasonDTOList)}
              onChange={(event) => {
                handleOnChange("seasonId", event.target.value);
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-row items-center">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("grade")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedGradeId}
              optionList={retrieveGradeDTOOptionList(gradeDTOList)}
              onChange={(event) => {
                handleOnChange("gradeId", event.target.value);
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-row items-center">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("branch")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedBranchId}
              optionList={retrieveBranchDTOOptionList(branchDTOList)}
              onChange={(event) => {
                handleOnChange("branchId", event.target.value);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const RenderUserProfileReportContent = () => {
    return (
      <>
        {userProfile.role === "STAFF" ? (
          <>
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("brand")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedBrandId}
                  optionList={retrieveBrandDTOOptionList(brandDTOList)}
                  onChange={(event) => {
                    handleOnChange("brandId", event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="w-full flex flex-row items-center">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("campus")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedCampusId}
                  optionList={retrieveCampusDTOOptionList(campusDTOList)}
                  onChange={(event) => {
                    handleOnChange("campusId", event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="w-full flex flex-row items-center">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("institution")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedInstitutionId}
                  optionList={retrieveInstitutionDTOOptionList(
                    institutionDTOList
                  )}
                  onChange={(event) => {
                    handleOnChange("institutionId", event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="w-full flex flex-row items-center">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("season")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedSeasonId}
                  optionList={retrieveSeasonDTOOptionList(seasonDTOList)}
                  onChange={(event) => {
                    handleOnChange("seasonId", event.target.value);
                  }}
                />
              </div>
            </div>
          </>
        ) : null}
        <div className="w-full flex flex-row items-center">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("grade")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedGradeId}
              optionList={retrieveGradeDTOOptionList(gradeDTOList)}
              onChange={(event) => {
                handleOnChange("gradeId", event.target.value);
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-row items-center">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("branch")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedBranchId}
              optionList={retrieveBranchDTOOptionList(branchDTOList)}
              onChange={(event) => {
                handleOnChange("branchId", event.target.value);
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-row items-center">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("user_profile")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedUserProfileId}
              optionList={retrieveLearnerUserProfileDTOOptionList()}
              onChange={(event) => {
                handleOnChange("userProfileId", event.target.value);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const RenderCoterieReportContent = () => {
    return (
      <>
        {userProfile.role === "STAFF" ? (
          <>
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("brand")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedBrandId}
                  optionList={retrieveBrandDTOOptionList(brandDTOList)}
                  onChange={(event) => {
                    handleOnChange("brandId", event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="w-full flex flex-row items-center">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("campus")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedCampusId}
                  optionList={retrieveCampusDTOOptionList(campusDTOList)}
                  onChange={(event) => {
                    handleOnChange("campusId", event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="w-full flex flex-row items-center">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("institution")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedInstitutionId}
                  optionList={retrieveInstitutionDTOOptionList(
                    institutionDTOList
                  )}
                  onChange={(event) => {
                    handleOnChange("institutionId", event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="w-full flex flex-row items-center">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("season")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedSeasonId}
                  optionList={retrieveSeasonDTOOptionList(seasonDTOList)}
                  onChange={(event) => {
                    handleOnChange("seasonId", event.target.value);
                  }}
                />
              </div>
            </div>
          </>
        ) : null}
        <div className="w-full flex flex-row items-center">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("staff_user_profile")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedUserProfileId}
              optionList={retrieveStaffUserProfileDTOOptionList()}
              onChange={(event) => {
                handleOnChange("staffUserProfileIdAdd", event.target.value);
              }}
            />
          </div>
        </div>

        {staffUserProfileIdList.map((staffUserProfileId) => (
          <div className="ml-auto w-5/6" key={staffUserProfileId}>
            {RenderSingleStaffUserProfileItem(staffUserProfileId)}
          </div>
        ))}
      </>
    );
  };

  const RenderBranchReportContent = () => {
    return (
      <>
        {RenderGradeReportContent()}
        <div className="w-full flex flex-row items-center">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("branch")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedBranchId}
              optionList={retrieveBranchDTOOptionList(branchDTOList)}
              onChange={(event) => {
                handleOnChange("branchIdAdd", event.target.value);
              }}
            />
          </div>
        </div>

        {branchIdList.map((branchId) => (
          <div className="ml-auto w-5/6" key={branchId}>
            {RenderSingleBranchItem(branchId)}
          </div>
        ))}
      </>
    );
  };

  const RenderGradeReportContent = () => {
    return (
      <>
        {userProfile.role === "STAFF" ? RenderSeasonReportContent() : null}
        <div className="w-full flex flex-row items-center">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("grade")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedGradeId}
              optionList={retrieveGradeDTOOptionList(gradeDTOList)}
              onChange={(event) => {
                handleOnChange("gradeIdAdd", event.target.value);
              }}
            />
          </div>
        </div>

        {gradeIdList.map((gradeId) => (
          <div className="ml-auto w-5/6" key={gradeId}>
            {RenderSingleGradeItem(gradeId)}
          </div>
        ))}
      </>
    );
  };

  const RenderSeasonReportContent = () => {
    return (
      <>
        {RenderInstitutionReportContent()}
        <div className="w-full flex flex-row items-center">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("season")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedSeasonId}
              optionList={retrieveSeasonDTOOptionList(seasonDTOList)}
              onChange={(event) => {
                handleOnChange("seasonIdAdd", event.target.value);
              }}
            />
          </div>
        </div>

        {seasonIdList.map((seasonId) => (
          <div className="ml-auto w-5/6" key={seasonId}>
            {RenderSingleSeasonItem(seasonId)}
          </div>
        ))}
      </>
    );
  };

  const RenderInstitutionReportContent = () => {
    return (
      <>
        {RenderCampusReportContent()}
        <div className="w-full flex flex-row items-center">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("institution")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedInstitutionId}
              optionList={retrieveInstitutionDTOOptionList(institutionDTOList)}
              onChange={(event) => {
                handleOnChange("institutionIdAdd", event.target.value);
              }}
            />
          </div>
        </div>

        {institutionIdList.map((institutionId) => (
          <div className="ml-auto w-5/6" key={institutionId}>
            {RenderSingleInstitutionItem(institutionId)}
          </div>
        ))}
      </>
    );
  };

  const RenderCampusReportContent = () => {
    return (
      <>
        {RenderBrandReportContent()}
        <div className="w-full flex flex-row items-center">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("campus")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedCampusId}
              optionList={retrieveCampusDTOOptionList(campusDTOList)}
              onChange={(event) => {
                handleOnChange("campusIdAdd", event.target.value);
              }}
            />
          </div>
        </div>

        {campusIdList.map((campusId) => (
          <div className="ml-auto w-5/6" key={campusId}>
            {RenderSingleCampusItem(campusId)}
          </div>
        ))}
      </>
    );
  };

  const RenderBrandReportContent = () => {
    return (
      <>
        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("brand")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedBrandId}
              optionList={retrieveBrandDTOOptionList(brandDTOList)}
              onChange={(event) => {
                handleOnChange("brandId", event.target.value);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const RenderRegionReportContent = () => {
    return (
      <>
        <div className="w-full flex flex-row items-center">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("region")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedRegionCode}
              optionList={retrieveRegionDTOOptionList()}
              onChange={(event) => {
                handleOnChange("regionCodeAdd", event.target.value);
              }}
            />
          </div>
        </div>
        {regionCodeList.map((regionCode) => (
          <div className="ml-auto w-5/6" key={regionCode}>
            {RenderSingleRegionItem(regionCode)}
          </div>
        ))}
      </>
    );
  };

  const RenderSingleRegionItem = (regionCode) => {
    let foundRegionDTO = regionDTOList.find(
      (regionDTO) => regionDTO.code === regionCode
    );
    if (!foundRegionDTO) {
      foundRegionDTO = {
        code: regionCode,
        name: i18n.t("unknown"),
        description: i18n.t("unknown"),
      };
    }
    return (
      <div className="flex flex-row justify-between items-center my-2 w-full flex-wrap">
        <div className="w-full lg:w-1/2 xl:w-1/6">
          <p className="mx-1">{foundRegionDTO.code}</p>
        </div>
        <div className="w-full lg:w-1/2 xl:w-1/6">
          <p className="mx-1">{foundRegionDTO.countryCode}</p>
        </div>
        <div className="w-full lg:w-1/2 xl:w-1/6">
          <p className="mx-1">{i18n.t(foundRegionDTO.countryName)}</p>
        </div>

        <div className="mx-2">
          <BaseImage
            src="/icons/remove-red.png"
            alt="remove-red"
            size="small"
            onClick={() => {
              handleRemoveRegionFromUserProfileOnClick(foundRegionDTO.code);
            }}
          />
        </div>
      </div>
    );
  };

  const RenderSingleCampusItem = (campusId) => {
    let foundCampusDTO = campusDTOList.find(
      (campusDTO) => parseFloat(campusDTO.id) === parseFloat(campusId)
    );
    if (!foundCampusDTO) {
      foundCampusDTO = {
        id: campusId,
        name: i18n.t("unknown"),
        description: i18n.t("unknown"),
      };
    }
    return (
      <div className="flex flex-row justify-between items-center my-2 w-full flex-wrap">
        <div className="w-full lg:w-1/2 xl:w-1/6">
          <div className="flex flex-row">
            <Tooltip id="item-descriptions-in-report" />
            <div className="mr-2">
              <BaseImage
                size="small"
                src="/icons/info.png"
                alt="info"
                dataToolTipId="item-descriptions-in-report"
                dataToolTipContent={foundCampusDTO.description}
                dataToolTipPlace="top"
                dataToolTipVariant="info"
              />
            </div>
            <p className="mx-1">{foundCampusDTO.name}</p>
          </div>
        </div>
        <div className="w-full lg:w-1/4 xl:w-1/12">
          <p className="mx-1">{foundCampusDTO.brandDTO?.name}</p>
        </div>
        <div className="w-full lg:w-1/4 xl:w-1/12"></div>
        <div className="w-full lg:w-1/4 xl:w-1/12"></div>
        <div className="mx-2">
          <BaseImage
            src="/icons/remove-red.png"
            alt="remove-red"
            size="small"
            onClick={() => {
              handleRemoveCampusFromUserProfileOnClick(foundCampusDTO.id);
            }}
          />
        </div>
      </div>
    );
  };

  const RenderSingleInstitutionItem = (institutionId) => {
    let foundInstitutionDTO = institutionDTOList.find(
      (institutionDTO) => institutionDTO.id === parseFloat(institutionId)
    );
    if (!foundInstitutionDTO) {
      foundInstitutionDTO = {
        id: institutionId,
        name: i18n.t("unknown"),
        description: i18n.t("unknown"),
      };
    }
    return (
      <div className="flex flex-row justify-between items-center my-2 w-full flex-wrap">
        <div className="w-full lg:w-1/2 xl:w-1/6">
          <div className="flex flex-row">
            <Tooltip id="item-descriptions-in-report" />
            <div className="mr-2">
              <BaseImage
                size="small"
                src="/icons/info.png"
                alt="info"
                dataToolTipId="item-descriptions-in-report"
                dataToolTipContent={foundInstitutionDTO.description}
                dataToolTipPlace="top"
                dataToolTipVariant="info"
              />
            </div>
            <p className="mx-1">{foundInstitutionDTO.name}</p>
          </div>
        </div>
        <div className="w-full lg:w-1/4 xl:w-1/12">
          <p className="mx-1">{foundInstitutionDTO.campusDTO?.name}</p>
        </div>
        <div className="w-full lg:w-1/4 xl:w-1/12">
          <p className="mx-1">{foundInstitutionDTO.campusDTO?.brandDTO.name}</p>
        </div>
        <div className="w-full lg:w-1/4 xl:w-1/12"></div>
        <div className="mx-2">
          <BaseImage
            src="/icons/remove-red.png"
            alt="remove-red"
            size="small"
            onClick={() => {
              handleRemoveInstitutionFromUserProfileOnClick(
                foundInstitutionDTO.id
              );
            }}
          />
        </div>
      </div>
    );
  };

  const RenderSingleSeasonItem = (seasonId) => {
    let foundSeasonDTO = seasonDTOList.find(
      (seasonDTO) => seasonDTO.id === parseFloat(seasonId)
    );
    if (!foundSeasonDTO) {
      foundSeasonDTO = {
        id: seasonId,
        name: i18n.t("unknown"),
        description: i18n.t("unknown"),
      };
    }
    return (
      <div className="w-full">
        <div className="flex flex-row justify-between items-center my-2 w-full flex-wrap">
          <div className="w-full lg:w-1/2 xl:w-1/6">
            <div className="flex flex-row">
              <Tooltip id="item-descriptions-in-report" />
              <div className="mr-2">
                <BaseImage
                  size="small"
                  src="/icons/info.png"
                  alt="info"
                  dataToolTipId="item-descriptions-in-report"
                  dataToolTipContent={foundSeasonDTO.description}
                  dataToolTipPlace="top"
                  dataToolTipVariant="info"
                />
              </div>
              <p className="mx-1">{foundSeasonDTO.name}</p>
            </div>
          </div>
          <div className="w-full lg:w-1/4 xl:w-1/12">
            <p className="mx-1">{foundSeasonDTO.institutionDTO?.name}</p>
          </div>

          <div className="w-full lg:w-1/4 xl:w-1/12">
            <p className="mx-1">
              {new Date(foundSeasonDTO.beginAt).toLocaleDateString(language)}
            </p>
          </div>
          <div className="w-full lg:w-1/4 xl:w-1/12">
            <p className="mx-1">
              {new Date(foundSeasonDTO.endAt).toLocaleDateString(language)}
            </p>
          </div>
          <div className="mx-2">
            <BaseImage
              src="/icons/remove-red.png"
              alt="remove-red"
              size="small"
              onClick={() => {
                handleRemoveSeasonFromUserProfileOnClick(foundSeasonDTO.id);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const RenderSingleGradeItem = (gradeId) => {
    let foundGradeDTO = gradeDTOList.find(
      (gradeDTO) => gradeDTO.id === parseFloat(gradeId)
    );
    if (!foundGradeDTO) {
      foundGradeDTO = {
        id: gradeId,
        name: i18n.t("unknown"),
        description: i18n.t("unknown"),
      };
    }
    return (
      <div className="w-full">
        <div className="flex flex-row justify-between items-center my-2 w-full flex-wrap">
          <div className="w-full lg:w-1/2 xl:w-1/6">
            <div className="flex flex-row">
              <Tooltip id="item-descriptions-in-report" effect="solid" />
              <div className="mr-2">
                <BaseImage
                  size="small"
                  src="/icons/info.png"
                  alt="info"
                  dataToolTipId="item-descriptions-in-report"
                  dataToolTipContent={foundGradeDTO.description}
                  dataToolTipPlace="top"
                  dataToolTipVariant="info"
                />
              </div>
              <p className="mx-1">{foundGradeDTO.name}</p>
            </div>
          </div>
          <div className="w-full lg:w-1/4 xl:w-1/12">
            <p className="mx-1">{foundGradeDTO.seasonDTO?.name}</p>
          </div>
          <div className="w-full lg:w-1/4 xl:w-1/12">
            <p className="mx-1">
              {foundGradeDTO.seasonDTO?.institutionDTO.name}
            </p>
          </div>
          <div className="w-full lg:w-1/4 xl:w-1/12">
            <p className="mx-1">{foundGradeDTO.level}</p>
          </div>

          <div className="mx-2">
            <BaseImage
              src="/icons/remove-red.png"
              alt="remove-red"
              size="small"
              onClick={() => {
                handleRemoveGradeFromUserProfileOnClick(foundGradeDTO.id);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const RenderSingleBranchItem = (branchId) => {
    let foundBranchDTO = branchDTOList.find(
      (branchDTO) => branchDTO.id === parseFloat(branchId)
    );
    if (!foundBranchDTO) {
      foundBranchDTO = {
        id: branchId,
        name: i18n.t("unknown"),
        description: i18n.t("unknown"),
      };
    }
    return (
      <div className="w-full">
        <div className="flex flex-row justify-between items-center my-2 w-full flex-wrap">
          <div className="w-full lg:w-1/2 xl:w-1/6">
            <div className="flex flex-row">
              <Tooltip id="item-descriptions-in-report" />
              <div className="mr-2">
                <BaseImage
                  size="small"
                  src="/icons/info.png"
                  alt="info"
                  dataToolTipId="item-descriptions-in-report"
                  dataToolTipContent={foundBranchDTO.description}
                  dataToolTipPlace="top"
                  dataToolTipVariant="info"
                />
              </div>
              <p className="mx-1">{foundBranchDTO.name}</p>
            </div>
          </div>
          <div className="w-full lg:w-1/4 xl:w-1/12">
            <p className="mx-1">{foundBranchDTO.gradeDTO?.name}</p>
          </div>
          <div className="w-full lg:w-1/4 xl:w-1/12">
            <p className="mx-1">{foundBranchDTO.gradeDTO?.seasonDTO.name}</p>
          </div>
          <div className="w-full lg:w-1/4 xl:w-1/12">
            <p className="mx-1">
              {foundBranchDTO.gradeDTO?.seasonDTO.institutionDTO.name}
            </p>
          </div>
          <div className="mx-2">
            <BaseImage
              src="/icons/remove-red.png"
              alt="remove-red"
              size="small"
              onClick={() => {
                handleRemoveBranchFromUserProfileOnClick(foundBranchDTO.id);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const RenderSingleStaffUserProfileItem = (staffUserProfileId) => {
    let foundStaffUserProfileDTO = staffUserProfileDTOList.find(
      (staffUserProfileDTO) =>
        staffUserProfileDTO.id === parseFloat(staffUserProfileId)
    );
    let name = "";
    const { seasonId, seasonName, gradeId, gradeName, branchId, branchName } =
      foundStaffUserProfileDTO;
    if (branchId) {
      name = branchName;
    } else if (gradeId) {
      name = gradeName;
    } else if (seasonId) {
      name = seasonName;
    }

    if (!foundStaffUserProfileDTO) {
      foundStaffUserProfileDTO = {
        id: staffUserProfileId,
        name: i18n.t("unknown"),
        description: i18n.t("unknown"),
        userDTO: {
          firstName: i18n.t("unknown"),
          lastName: i18n.t("unknown"),
        },
      };
    }
    return (
      <div className="w-full">
        <div className="flex flex-row justify-between items-center my-2 w-full flex-wrap">
          <div className="w-full lg:w-1/2 xl:w-1/6">
            <div className="flex flex-row">
              <Tooltip id="item-descriptions-in-report" />
              <div className="mr-2">
                <BaseImage
                  size="small"
                  src="/icons/info.png"
                  alt="info"
                  dataToolTipId="item-descriptions-in-report"
                  dataToolTipContent={foundStaffUserProfileDTO.description}
                  dataToolTipPlace="top"
                  dataToolTipVariant="info"
                />
              </div>
              <p className="mx-1">{foundStaffUserProfileDTO.name}</p>
            </div>
          </div>
          <div className="w-full lg:w-1/2 xl:w-1/6">
            <p className="mx-1">{`${foundStaffUserProfileDTO.userDTO.firstName} ${foundStaffUserProfileDTO.userDTO.lastName}`}</p>
          </div>
          <div className="w-full lg:w-1/4 xl:w-1/12">
            <p className="mx-1">
              {i18n.t(foundStaffUserProfileDTO.coterieType)}
            </p>
          </div>
          <div className="w-full lg:w-1/4 xl:w-1/12">
            <p className="mx-1">{name}</p>
          </div>
          <div className="mx-2">
            <BaseImage
              src="/icons/remove-red.png"
              alt="remove-red"
              size="small"
              onClick={() => {
                handleRemoveStaffUserProfileFromUserProfileOnClick(
                  foundStaffUserProfileDTO.id
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const RenderInformationReportCommonContent = () => {
    return (
      <>
        <div className="w-full flex flex-row flex-wrap gap-x-4">
          <div className="flex-1 flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("begin_at")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="date"
                placeholder={i18n.t("begin_at")}
                value={beginAt}
                onChange={(e) => {
                  handleOnChange("beginAt", e.target.value);
                }}
                errorText={errorMap.beginAt}
              />
            </div>
          </div>
          <div className="flex-1 flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("end_at")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="date"
                value={endAt}
                onChange={(e) => {
                  handleOnChange("endAt", e.target.value);
                }}
                errorText={errorMap.endAt}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const RenderInformationReportDetailContent = () => {
    if (selectedSubReportType === "USER") {
      return RenderUserReportContent();
    } else if (selectedSubReportType === "USER_PROFILE") {
      return RenderUserProfileReportContent();
    } else if (selectedSubReportType === "BRANCH") {
      return RenderBranchReportContent();
    } else if (selectedSubReportType === "GRADE") {
      return RenderGradeReportContent();
    } else if (selectedSubReportType === "SEASON") {
      return RenderSeasonReportContent();
    } else if (selectedSubReportType === "INSTITUTION") {
      return RenderInstitutionReportContent();
    } else if (selectedSubReportType === "CAMPUS") {
      return RenderCampusReportContent();
    } else if (selectedSubReportType === "BRAND") {
      return RenderBrandReportContent();
    } else if (selectedSubReportType === "REGION") {
      return RenderRegionReportContent();
    }
    return null;
  };

  const RenderCatchtivityReportCommonContent = () => {
    return (
      <div className="w-full flex flex-row">
        <div className="w-catchup-input-group-title py-5">
          <p>{i18n.t("catchtivity_name")}</p>
        </div>
        <div className="flex-1">
          <InputGroup
            type="select"
            value={selectedCatchtivityId}
            optionList={retrieveCatchtivityDTOOptionList()}
            onChange={(event) => {
              handleOnChange("catchtivityId", event.target.value);
            }}
            errorText={errorMap.selectedCatchtivityId}
          />
        </div>
      </div>
    );
  };

  const RenderCatchtivityReportDetailContent = () => {
    if (selectedSubReportType === "BRANCH") {
      return RenderBranchReportContent();
    } else if (selectedSubReportType === "GRADE") {
      return RenderGradeReportContent();
    } else if (selectedSubReportType === "SEASON") {
      return RenderSeasonReportContent();
    }
  };

  const RenderCatchxamReportCommonContent = () => {
    return (
      <div className="w-full flex flex-row">
        <div className="w-catchup-input-group-title py-5 text-">
          <p>{i18n.t("catchxam_name")}</p>
        </div>
        <div className="flex-1">
          <InputGroup
            type="select"
            value={selectedCatchxamId}
            optionList={retrieveCatchxamDTOOptionList()}
            onChange={(event) => {
              handleOnChange("catchxamId", event.target.value);
            }}
            errorText={errorMap.selectedCatchxamId}
          />
        </div>
      </div>
    );
  };

  const RenderCatchxamReportDetailContent = () => {
    if (selectedSubReportType === "BRANCH") {
      return RenderBranchReportContent();
    } else if (selectedSubReportType === "GRADE") {
      return RenderGradeReportContent();
    } else if (selectedSubReportType === "SEASON") {
      return RenderSeasonReportContent();
    }
  };

  const RenderStandardExamReportCommonContent = () => {
    return (
      <div className="w-full flex flex-row">
        <div className="w-catchup-input-group-title py-5 text-">
          <p>{i18n.t("standard_exam_name")}</p>
        </div>
        <div className="flex-1">
          <InputGroup
            type="select"
            value={selectedStandardExamId}
            optionList={retrieveStandardExamDTOOptionList()}
            onChange={(event) => {
              handleOnChange("standardExamId", event.target.value);
            }}
            errorText={errorMap.selectedStandardExamId}
          />
        </div>
      </div>
    );
  };

  const RenderStandardExamReportDetailContent = () => {
    if (selectedSubReportType === "BRANCH") {
      return RenderBranchReportContent();
    } else if (selectedSubReportType === "GRADE") {
      return RenderGradeReportContent();
    } else if (selectedSubReportType === "SEASON") {
      return RenderSeasonReportContent();
    }
  };

  const RenderLearningOutcomeReportCommonContent = () => {
    return (
      <>
        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("category_name")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              title={null}
              placeholder={null}
              value={selectedCategoryCode}
              optionList={filterCategoryVersionCodeOptionList()}
              onChange={(event) => {
                handleOnChange("categoryCode", event.target.value);
              }}
              errorText={errorMap.selectedCategoryCode}
            />
          </div>
        </div>
        {selectedCategoryCode !== "DEFAULT_OPTION" ? (
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("sub_category_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                title={null}
                placeholder={null}
                value={selectedSubCategoryCode}
                optionList={retrieveSubCategoryCodeList()}
                onChange={(event) => {
                  handleOnChange("subCategoryCode", event.target.value);
                }}
                errorText={errorMap.selectedSubCategoryCode}
              />
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const RenderLearningOutcomeReportDetailContent = () => {
    if (selectedSubReportType === "BRANCH") {
      return RenderBranchReportContent();
    } else if (selectedSubReportType === "GRADE") {
      return RenderGradeReportContent();
    } else if (selectedSubReportType === "SEASON") {
      return RenderSeasonReportContent();
    } else if (selectedSubReportType === "USER_PROFILE") {
      return RenderUserProfileReportContent();
    }
  };

  const RenderParticipationReportCommonContent = () => {
    return (
      <>
        {selectedSubReportType === "CATCHTIVITY" ? (
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("catchtivity_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedCatchtivityId}
                optionList={retrieveCatchtivityDTOOptionList()}
                onChange={(event) => {
                  handleOnChange("catchtivityId", event.target.value);
                }}
                errorText={errorMap.selectedCatchtivityId}
              />
            </div>
          </div>
        ) : null}
        {selectedSubReportType === "CATCHXAM" ? (
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5 text-">
              <p>{i18n.t("catchxam_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedCatchxamId}
                optionList={retrieveCatchxamDTOOptionList()}
                onChange={(event) => {
                  handleOnChange("catchxamId", event.target.value);
                }}
                errorText={errorMap.selectedCatchxamId}
              />
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const RenderParticipationReportDetailContent = () => {
    if (selectedSubReportType === "BRANCH") {
      return RenderBranchReportContent();
    } else if (selectedSubReportType === "GRADE") {
      return RenderGradeReportContent();
    } else if (selectedSubReportType === "SEASON") {
      return RenderSeasonReportContent();
    } else if (selectedSubReportType === "USER_PROFILE") {
      return RenderUserProfileReportContent();
    }
  };

  const RenderPerformanceReportCommonContent = () => {
    return <></>;
  };

  const RenderPerformanceReportDetailContent = () => {
    if (selectedSubReportType === "USER_PROFILE") {
      return RenderUserProfileReportContent();
    } else if (selectedSubReportType === "COTERIE_TYPE") {
      return RenderCoterieReportContent();
    }
  };

  const RenderReportContent = () => {
    if (selectedBaseReportType === "INFORMATION") {
      return (
        <>
          {RenderInformationReportCommonContent()}
          {RenderInformationReportDetailContent()}
          {canShowGenerateReportButton() ? (
            <div className="mt-4 ml-auto">
              <PrimaryButton
                title={i18n.t("generate")}
                size="medium"
                onClick={handleGenerateReportOnClick}
              />
            </div>
          ) : null}
          <GeneralInformationReportDetail
            showTitle={true}
            usePadding={false}
            isShadowed={false}
            reportDetail={reportDetail}
            reportDetailLoading={reportDetailLoading}
            subType={selectedSubReportType}
            handleGeneratePDF={handleGeneratePDF}
          />
        </>
      );
    } else if (selectedBaseReportType === "CATCHTIVITY") {
      return (
        <>
          {RenderCatchtivityReportCommonContent()}
          {RenderCatchtivityReportDetailContent()}
          {canShowGenerateReportButton() ? (
            <div className="mt-4 ml-auto">
              <PrimaryButton
                title={i18n.t("generate")}
                size="medium"
                onClick={handleGenerateReportOnClick}
              />
            </div>
          ) : null}
          <GeneralCatchtivityReportDetail
            showTitle={true}
            usePadding={false}
            isShadowed={false}
            reportDetailList={reportDetailList}
            reportDetailLoading={reportDetailLoading}
            subType={selectedSubReportType}
            handleGeneratePDF={handleGeneratePDF}
          />
        </>
      );
    } else if (selectedBaseReportType === "CATCHXAM") {
      return (
        <>
          {RenderCatchxamReportCommonContent()}
          {RenderCatchxamReportDetailContent()}
          {canShowGenerateReportButton() ? (
            <div className="mt-4 ml-auto">
              <PrimaryButton
                title={i18n.t("generate")}
                size="medium"
                onClick={handleGenerateReportOnClick}
              />
            </div>
          ) : null}
          <GeneralCatchxamReportDetail
            showTitle={true}
            usePadding={false}
            isShadowed={false}
            reportDetailList={reportDetailList}
            reportDetailLoading={reportDetailLoading}
            subType={selectedSubReportType}
            handleGeneratePDF={handleGeneratePDF}
          />
        </>
      );
    } else if (selectedBaseReportType === "STANDARD_EXAM") {
      return (
        <>
          {RenderStandardExamReportCommonContent()}
          {RenderStandardExamReportDetailContent()}
          {canShowGenerateReportButton() ? (
            <div className="mt-4 ml-auto">
              <PrimaryButton
                title={i18n.t("generate")}
                size="medium"
                onClick={handleGenerateReportOnClick}
              />
            </div>
          ) : null}
          <GeneralStandardExamReportDetail
            showTitle={true}
            usePadding={false}
            isShadowed={false}
            reportDetailList={reportDetailList}
            reportDetailLoading={reportDetailLoading}
            subType={selectedSubReportType}
            handleGeneratePDF={handleGeneratePDF}
          />
        </>
      );
    } else if (selectedBaseReportType === "LEARNING_OUTCOME") {
      return (
        <>
          {RenderLearningOutcomeReportCommonContent()}
          {RenderLearningOutcomeReportDetailContent()}
          {canShowGenerateReportButton() ? (
            <div className="mt-4 ml-auto">
              <PrimaryButton
                title={i18n.t("generate")}
                size="medium"
                onClick={handleGenerateReportOnClick}
              />
            </div>
          ) : null}
          <GeneralLearningOutcomeReportDetail
            showTitle={true}
            usePadding={false}
            isShadowed={false}
            reportDetailList={reportDetailList}
            reportDetailLoading={reportDetailLoading}
            subType={selectedSubReportType}
            handleGeneratePDF={handleGeneratePDF}
          />
        </>
      );
    } else if (selectedBaseReportType === "PARTICIPATION") {
      return (
        <>
          {RenderParticipationReportCommonContent()}
          {RenderParticipationReportDetailContent()}
          {canShowGenerateReportButton() ? (
            <div className="mt-4 ml-auto">
              <PrimaryButton
                title={i18n.t("generate")}
                size="medium"
                onClick={handleGenerateReportOnClick}
              />
            </div>
          ) : null}
          <GeneralParticipationReportDetail
            language={language}
            showTitle={true}
            usePadding={false}
            isShadowed={false}
            reportDetailList={reportDetailList}
            reportDetailLoading={reportDetailLoading}
            subType={selectedSubReportType}
            handleGeneratePDF={handleGeneratePDF}
          />
        </>
      );
    } else if (selectedBaseReportType === "PERFORMANCE") {
      return (
        <>
          {RenderPerformanceReportCommonContent()}
          {RenderPerformanceReportDetailContent()}
          {canShowGenerateReportButton() ? (
            <div className="mt-4 ml-auto">
              <PrimaryButton
                title={i18n.t("generate")}
                size="medium"
                onClick={handleGenerateReportOnClick}
              />
            </div>
          ) : null}
          <GeneralPerformanceReportDetail
            language={language}
            userProfile={userProfile}
            showTitle={true}
            usePadding={false}
            isShadowed={false}
            reportDetailList={reportDetailList}
            reportDetailLoading={reportDetailLoading}
            defaultCoterieType={
              userInformation.accountType === "GENIXO"
                ? "MANAGEMENT"
                : userProfile.coterieType
                ? userProfile.coterieType
                : "DEFAULT_OPTION"
            }
            subType={selectedSubReportType}
            individualModelLoading={individualModelLoading}
            individualModelList={individualModelList}
            showIndividualModel={showIndividualModel}
            setShowIndividualModel={setShowIndividualModel}
            handleGeneratePDF={handleGeneratePDF}
          />
        </>
      );
    }
    return null;
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <FullCard isShadowed={true}>
            {selectedBaseReportType === null ? (
              <div className="flex flex-row flex-wrap">
                {retrieveBaseReportTypeOptionList().map(
                  (baseReportType, baseReportTypeIndex) => (
                    <div
                      key={baseReportTypeIndex}
                      className="w-[200px] h-[200px] m-4"
                    >
                      <div
                        className="flex-1 flex flex-col h-full justify-center items-center  rounded-catchup-xlarge p-6 cursor-pointer"
                        onClick={() => {
                          setSelectedBaseReportType(baseReportType.value);
                        }}
                        style={{
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                      >
                        <div className="flex-1 flex flex-col justify-center items-center gap-y-2">
                          <BaseImage
                            size="large"
                            src={retrieveBaseReportTypeIcon(
                              baseReportType.value
                            )}
                            alt="base-report"
                          />
                          <p className="text-lg text-center">
                            {baseReportType.text}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : null}
            {selectedBaseReportType && selectedSubReportType === null ? (
              <>
                <div className="flex flex-row items-center gap-x-2">
                  <p
                    className="text-lg font-semibold cursor-pointer"
                    onClick={() => {
                      setSelectedBaseReportType(null);
                      setSelectedSubReportType(null);
                      resetParams();
                    }}
                  >
                    {i18n.t("report_selection")}
                  </p>
                  <p>/</p>
                  <p className="text-lg font-semibold cursor-pointer">
                    {
                      retrieveBaseReportTypeOptionList().find(
                        (baseReportType) =>
                          baseReportType.value === selectedBaseReportType
                      ).text
                    }
                  </p>
                </div>
                <div className="flex flex-row flex-wrap">
                  {retrieveSubReportTypeOptionList()
                    .filter(
                      (subReportType) =>
                        subReportType.parent === selectedBaseReportType
                    )
                    .map((subReportType, subReportTypeIndex) => (
                      <div
                        key={subReportTypeIndex}
                        className="w-[200px] h-[200px] m-4"
                      >
                        <div
                          className="flex-1 flex flex-col h-full justify-center items-center rounded-catchup-xlarge p-6 cursor-pointer"
                          onClick={() => {
                            setSelectedSubReportType(subReportType.value);
                          }}
                          style={{
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                        >
                          <div className="flex-1 flex flex-col justify-center items-center gap-y-2">
                            <BaseImage
                              size="large"
                              src={retrieveSubReportTypeIcon(
                                subReportType.value
                              )}
                              alt="sub-report"
                            />
                            <p className="text-lg text-center">
                              {subReportType.text}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            ) : null}
            {selectedBaseReportType && selectedSubReportType ? (
              <>
                <div className="flex flex-row items-center gap-x-2">
                  <p
                    className="text-lg font-semibold cursor-pointer"
                    onClick={() => {
                      setSelectedBaseReportType(null);
                      setSelectedSubReportType(null);
                      resetParams();
                    }}
                  >
                    {i18n.t("report_selection")}
                  </p>
                  <p>/</p>
                  <p
                    className="text-lg font-semibold cursor-pointer"
                    onClick={() => {
                      setSelectedSubReportType(null);
                      resetParams();
                    }}
                  >
                    {
                      retrieveBaseReportTypeOptionList().find(
                        (baseReportType) =>
                          baseReportType.value === selectedBaseReportType
                      ).text
                    }
                  </p>
                  <p>/</p>
                  <p className="text-lg font-semibold">
                    {
                      retrieveSubReportTypeOptionList().find(
                        (subReportType) =>
                          subReportType.parent === selectedBaseReportType &&
                          subReportType.value === selectedSubReportType
                      ).text
                    }
                  </p>
                </div>
                {RenderReportContent()}
              </>
            ) : null}
          </FullCard>
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}></div>
      </div>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ReportListScreen;
