import { useNavigate } from "react-router-dom";
import i18n from "../../language/i18n";
import SecondaryButton from "../buttons/SecondaryButton";
import FullCard from "../cards/FullCard";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import InputGroup from "../groups/InputGroup";
import { useEffect, useState } from "react";
import AdvancedTable from "../tables/AdvancedTable";
import {
  createUserImportFromExcel,
  queryBranchListBySeasonId,
  querySeasonListByBrandId,
  searchUserListWithStateByParams,
} from "../../requests/ManagementRequests";
import { useAuth } from "../../context/UserContextProvider";
import DividerLine from "../divider/DividerLine";
import BaseImage from "../images/BaseImage";
import BaseTitle from "../titles/BaseTitle";
import SingleInformationIconTextItem from "../infos/SingleInformationIconTextItem";
import CreateButton from "../buttons/CreateButton";
import { retrieveSeasonDTOOptionList } from "../../utilization/ManagementUtilization";
import BaseLoading from "../loading/BaseLoading";

const ImportFromExcelCreateUser = () => {
  const navigate = useNavigate();
  const { userProfileBrand } = useAuth();
  const [uploadFile, setUploadFile] = useState(null);
  const [excelStatus, setExcelStatus] = useState({ data: null, state: null });
  const [parameterStatus, setParameterStatus] = useState({
    data: null,
    state: null,
  });
  const [seasonDTOList, setSeasonDTOList] = useState([]);
  const [selectedSeasonId, setSelectedSeasonId] = useState("DEFAULT_OPTION");
  const [branchDTOList, setBranchDTOList] = useState([]);
  const [nullCheckSelectedItemCount, setNullCheckSelectedItemCount] =
    useState(25);
  const [nullCheckPageNumber, setNullCheckPageNumber] = useState(0);
  const [nullCheckSortKey, setNullCheckSortKey] = useState("row");
  const [nullCheckSortDirection, setNullCheckSortDirection] = useState("ASC");
  const [invalidCheckSelectedItemCount, setInvalidCheckSelectedItemCount] =
    useState(25);
  const [invalidCheckPageNumber, setInvalidCheckPageNumber] = useState(0);
  const [invalidCheckSortKey, setInvalidCheckSortKey] = useState("row");
  const [invalidCheckSortDirection, setInvalidCheckSortDirection] =
    useState("ASC");
  const [inExcelCheckSelectedItemCount, setInExcelCheckSelectedItemCount] =
    useState(25);
  const [inExcelCheckPageNumber, setInExcelCheckPageNumber] = useState(0);
  const [inExcelCheckSortKey, setInExcelCheckSortKey] = useState("row");
  const [inExcelCheckSortDirection, setInExcelCheckSortDirection] =
    useState("ASC");
  const [
    notAcceptingCheckSelectedItemCount,
    setNotAcceptingCheckSelectedItemCount,
  ] = useState(25);
  const [notAcceptingCheckPageNumber, setNotAcceptingCheckPageNumber] =
    useState(0);
  const [notAcceptingCheckSortKey, setNotAcceptingCheckSortKey] =
    useState("row");
  const [notAcceptingCheckSortDirection, setNotAcceptingCheckSortDirection] =
    useState("ASC");
  const [acceptingCheckSelectedItemCount, setAcceptingCheckSelectedItemCount] =
    useState(25);
  const [acceptingCheckPageNumber, setAcceptingCheckPageNumber] = useState(0);
  const [acceptingCheckSortKey, setAcceptingCheckSortKey] = useState("row");
  const [acceptingCheckSortDirection, setAcceptingCheckSortDirection] =
    useState("ASC");

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  useEffect(() => {
    const retrieveAndSetSeasonDTOList = async () => {
      const { data, err } = await querySeasonListByBrandId(userProfileBrand.id);
      if (err) {
        console.log(err);
      } else {
        setSeasonDTOList(data);
      }
    };
    retrieveAndSetSeasonDTOList();
  }, []);

  useEffect(() => {
    const retrieveAndSetBranchDTOList = async () => {
      const { data, err } = await queryBranchListBySeasonId(selectedSeasonId);
      if (err) {
        console.log(err);
      } else {
        setBranchDTOList(data);
      }
    };
    if (selectedSeasonId !== "DEFAULT_OPTION") {
      retrieveAndSetBranchDTOList();
    }
  }, [selectedSeasonId]);

  useEffect(() => {
    const handleImportFromCSVOnChange = async () => {
      setExcelStatus({
        data: null,
        state: "WAITING",
      });
      setParameterStatus({
        data: null,
        state: null,
      });
      const data = await uploadFile.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets["data"];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
      });
      setExcelStatus({
        data: jsonData,
        state: "READY",
      });
    };
    if (!uploadFile) return;
    handleImportFromCSVOnChange();
  }, [uploadFile]);

  useEffect(() => {
    const retrieveAndCheckWithState = async (queryParams) => {
      const { data, err } = await searchUserListWithStateByParams(
        selectedSeasonId,
        queryParams
      );
      if (err) {
        console.log(err);
      } else {
        setParameterStatus({
          state: "READY",
          data,
        });
      }
    };
    if (excelStatus.state === "READY") {
      const header = excelStatus.data[0];
      const dataArray = JSON.parse(JSON.stringify(excelStatus.data)).splice(1);
      if (
        dataArray.length > 0 &&
        convertDataArrayToInvalidDataArray(header, dataArray).length +
          convertDataArrayToNullDataArray(header, dataArray).length +
          convertDataArrayToInExcelCheckArray(header, dataArray).length ===
          0
      ) {
        setParameterStatus({ state: "WAITING", data: null });
        const checkParams = [];
        for (const data of dataArray) {
          const usernameIndex = header.findIndex((h) =>
            h.startsWith(i18n.t("username"))
          );
          const firstNameIndex = header.findIndex((h) =>
            h.startsWith(i18n.t("first_name"))
          );
          const lastNameIndex = header.findIndex((h) =>
            h.startsWith(i18n.t("last_name"))
          );
          const dateOfBirthIndex = header.findIndex((h) =>
            h.startsWith(i18n.t("date_of_birth"))
          );
          const phoneNumberIndex = header.findIndex((h) =>
            h.startsWith(i18n.t("phone_number"))
          );
          const identityNumberIndex = header.findIndex((h) =>
            h.startsWith(i18n.t("identity_number"))
          );
          checkParams.push({
            username: data[usernameIndex],
            firstName: data[firstNameIndex],
            lastName: data[lastNameIndex],
            dateOfBirth:
              new Date(data[dateOfBirthIndex]).getTime() -
              60000 * new Date(data[dateOfBirthIndex]).getTimezoneOffset(),
            phoneNumber: data[phoneNumberIndex] ? data[phoneNumberIndex] : null,
            identityNumber: data[identityNumberIndex],
          });
        }
        retrieveAndCheckWithState(checkParams);
      }
    }
  }, [excelStatus]);

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const retrieveAcceptedFormats = () => {
    return ".xlsx,.numbers";
  };

  const retrieveCurrentNullCheckItemList = (nullDataArray) => {
    const operationDataArray = JSON.parse(JSON.stringify(nullDataArray));
    operationDataArray.sort(
      (a, b) => a[nullCheckSortKey] - b[nullCheckSortKey]
    );
    if (nullCheckSortDirection === "DESC") {
      operationDataArray.reverse();
    }
    return operationDataArray.splice(
      nullCheckPageNumber * nullCheckSelectedItemCount,
      (nullCheckPageNumber + 1) * nullCheckSelectedItemCount
    );
  };

  const retrieveCurrentInvalidCheckItemList = (invalidDataArray) => {
    const operationDataArray = JSON.parse(JSON.stringify(invalidDataArray));
    operationDataArray.sort(
      (a, b) => a[invalidCheckSortKey] - b[invalidCheckSortKey]
    );
    if (invalidCheckSortDirection === "DESC") {
      operationDataArray.reverse();
    }
    return operationDataArray.splice(
      invalidCheckPageNumber * invalidCheckSelectedItemCount,
      (invalidCheckPageNumber + 1) * invalidCheckSelectedItemCount
    );
  };

  const retrieveCurrentInExcelCheckItemList = (inExcelDataArray) => {
    const operationDataArray = JSON.parse(JSON.stringify(inExcelDataArray));
    operationDataArray.sort(
      (a, b) => a[inExcelCheckSortKey] - b[inExcelCheckSortKey]
    );
    if (inExcelCheckSortDirection === "DESC") {
      operationDataArray.reverse();
    }
    return operationDataArray.splice(
      inExcelCheckPageNumber * inExcelCheckSelectedItemCount,
      (inExcelCheckPageNumber + 1) * inExcelCheckSelectedItemCount
    );
  };

  const retrieveCurrentNotAcceptingCheckItemList = (notAcceptingCheckArray) => {
    const operationDataArray = JSON.parse(
      JSON.stringify(notAcceptingCheckArray)
    );
    operationDataArray.sort(
      (a, b) => a[notAcceptingCheckSortKey] - b[notAcceptingCheckSortKey]
    );
    if (notAcceptingCheckSortDirection === "DESC") {
      operationDataArray.reverse();
    }
    return operationDataArray.splice(
      notAcceptingCheckPageNumber * notAcceptingCheckSelectedItemCount,
      (notAcceptingCheckPageNumber + 1) * notAcceptingCheckSelectedItemCount
    );
  };

  const retrieveCurrentAcceptingCheckItemList = (acceptingCheckArray) => {
    const operationDataArray = JSON.parse(JSON.stringify(acceptingCheckArray));
    operationDataArray.sort(
      (a, b) => a[acceptingCheckSortKey] - b[acceptingCheckSortKey]
    );
    if (acceptingCheckSortDirection === "DESC") {
      operationDataArray.reverse();
    }
    return operationDataArray.splice(
      acceptingCheckPageNumber * acceptingCheckSelectedItemCount,
      (acceptingCheckPageNumber + 1) * acceptingCheckSelectedItemCount
    );
  };

  const convertDataArrayToNullDataArray = (header, dataArray) => {
    const nullDataMap = {};
    for (let i = 0; i < dataArray.length; i++) {
      const rowLine = dataArray[i];
      let correspondingColumnError = false;
      for (let j = 0; j < rowLine.length; j++) {
        if (
          (j === 6 || j === 7 || j === 8) &&
          (rowLine[6] || rowLine[7] || rowLine[8])
        )
          continue;
        if (j === 4) continue;

        const colElement = rowLine[j];
        if (colElement === "") {
          let errorText;
          if (j === 6 || j === 7 || j === 8) {
            errorText = i18n.t(
              "date_of_birth_phone_number_identity_number_cannot_be_null"
            );
          } else {
            errorText = `${header[j].split(" - ")[0]} ${i18n.t(
              "cannot_be_null_text"
            )}`;
          }
          if (correspondingColumnError) continue;
          if (j === 6 || j === 7 || j === 8) {
            correspondingColumnError = true;
          }

          if (nullDataMap[i]) {
            const error = nullDataMap[i].error;
            nullDataMap[i] = {
              ...nullDataMap[i],
              error: `${error} ${errorText}`,
            };
          } else {
            nullDataMap[i] = {
              id: i,
              [header[0].split(" - ")[0]]: rowLine[0],
              [header[1].split(" - ")[0]]: rowLine[1],
              [header[2].split(" - ")[0]]: rowLine[2],
              [header[3].split(" - ")[0]]: rowLine[3],
              [header[4].split(" - ")[0]]: rowLine[4],
              [header[5].split(" - ")[0]]: rowLine[5],
              [header[6].split(" - ")[0]]: rowLine[6],
              [header[7].split(" - ")[0]]: rowLine[7],
              [header[8].split(" - ")[0]]: rowLine[8],
              [header[9].split(" - ")[0]]: rowLine[9],
              row: i + 2,
              error: errorText,
            };
          }
        }
      }
    }
    const nullDataArray = [];
    Object.keys(nullDataMap).forEach((key) => {
      nullDataArray.push(nullDataMap[key]);
    });
    return nullDataArray;
  };

  const convertDataArrayToInvalidDataArray = (header, dataArray) => {
    const invalidDataMap = {};
    for (let i = 0; i < dataArray.length; i++) {
      const rowLine = dataArray[i];
      for (let j = 0; j < rowLine.length; j++) {
        const colElement = rowLine[j];
        if (colElement !== "") {
          let error = null;
          if (header[j].startsWith(i18n.t("username"))) {
            if (colElement.length <= 6) {
              error = `${i18n.t("username")} ${i18n.t(
                "size_must_be_greater_than_6"
              )}`;
            }
          } else if (header[j].startsWith(i18n.t("password"))) {
          } else if (header[j].startsWith(i18n.t("first_name"))) {
          } else if (header[j].startsWith(i18n.t("last_name"))) {
          } else if (header[j].startsWith(i18n.t("email"))) {
            if (
              !String(colElement)
                .toLowerCase()
                .match(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
            ) {
              error = `${i18n.t("email")} ${i18n.t("must_be_valid")}`;
            }
          } else if (header[j].startsWith(i18n.t("gender"))) {
            if (
              !colElement === i18n.t("MALE") ||
              colElement === i18n.t("FEMALE")
            ) {
              error = `${i18n.t("gender")} ${i18n.t("must_be_valid")}`;
            }
          } else if (header[j].startsWith(i18n.t("identity_number"))) {
            if ((colElement + "").length !== 11) {
              error = `${i18n.t("identity_number")} ${i18n.t("must_be_valid")}`;
            }
          } else if (header[j].startsWith(i18n.t("phone_number"))) {
          } else if (header[j].startsWith(i18n.t("date_of_birth"))) {
            if (new Date(colElement) == "Invalid Date") {
              error = `${i18n.t("date_of_birth")} ${i18n.t("must_be_valid")}`;
            }
          } else if (header[j].startsWith(i18n.t("branch_name"))) {
            if (
              branchDTOList.findIndex(
                (branchDTO) => branchDTO.name === colElement
              ) === -1
            ) {
              error = `${i18n.t("branch_name")} ${i18n.t("must_be_valid")}`;
            }
          }
          if (error) {
            if (invalidDataMap[i]) {
              const prevError = invalidDataMap[i].error;
              invalidDataMap[i] = {
                ...invalidDataMap[i],
                error: `${prevError} ${error}`,
              };
            } else {
              invalidDataMap[i] = {
                id: i,
                [header[0].split(" - ")[0]]: rowLine[0],
                [header[1].split(" - ")[0]]: rowLine[1],
                [header[2].split(" - ")[0]]: rowLine[2],
                [header[3].split(" - ")[0]]: rowLine[3],
                [header[4].split(" - ")[0]]: rowLine[4],
                [header[5].split(" - ")[0]]: rowLine[5],
                [header[6].split(" - ")[0]]: rowLine[6],
                [header[7].split(" - ")[0]]: rowLine[7],
                [header[8].split(" - ")[0]]: rowLine[8],
                [header[9].split(" - ")[0]]: rowLine[9],
                row: i + 2,
                error,
              };
            }
          }
        }
      }
    }
    const invalidDataArray = [];
    Object.keys(invalidDataMap).forEach((key) => {
      invalidDataArray.push(invalidDataMap[key]);
    });
    return invalidDataArray;
  };

  const convertDataArrayToInExcelCheckArray = (header, dataArray) => {
    const inExcelDataMap = {};
    for (let i = 0; i < dataArray.length; i++) {
      const rowLine = dataArray[i];
      for (let j = 0; j < rowLine.length; j++) {
        const colElement = rowLine[j];
        if (colElement !== "") {
          let error = null;
          if (header[j].startsWith(i18n.t("username"))) {
            const filteredDataArray = dataArray.filter(
              (data) => data[j] === colElement
            );
            if (filteredDataArray.length > 1) {
              error = `${i18n.t("username")} ${i18n.t("must_be_unique")}`;
            }
          } else if (header[j].startsWith(i18n.t("password"))) {
          } else if (header[j].startsWith(i18n.t("first_name"))) {
          } else if (header[j].startsWith(i18n.t("last_name"))) {
          } else if (header[j].startsWith(i18n.t("email"))) {
          } else if (header[j].startsWith(i18n.t("gender"))) {
            if (colElement !== "MALE" && colElement !== "FEMALE") {
              error = `${i18n.t("gender")} ${i18n.t("must_be_male_or_female")}`;
            }
          } else if (header[j].startsWith(i18n.t("identity_number"))) {
          } else if (header[j].startsWith(i18n.t("phone_number"))) {
          } else if (header[j].startsWith(i18n.t("date_of_birth"))) {
          } else if (header[j].startsWith(i18n.t("branch_name"))) {
          }
          if (error) {
            if (inExcelDataMap[i]) {
              const prevError = inExcelDataMap[i].error;
              inExcelDataMap[i] = {
                ...inExcelDataMap[i],
                error: `${prevError} ${error}`,
              };
            } else {
              inExcelDataMap[i] = {
                id: i,
                [header[0].split(" - ")[0]]: rowLine[0],
                [header[1].split(" - ")[0]]: rowLine[1],
                [header[2].split(" - ")[0]]: rowLine[2],
                [header[3].split(" - ")[0]]: rowLine[3],
                [header[4].split(" - ")[0]]: rowLine[4],
                [header[5].split(" - ")[0]]: rowLine[5],
                [header[6].split(" - ")[0]]: rowLine[6],
                [header[7].split(" - ")[0]]: rowLine[7],
                [header[8].split(" - ")[0]]: rowLine[8],
                [header[9].split(" - ")[0]]: rowLine[9],
                row: i + 2,
                error,
              };
            }
          }
        }
      }
    }
    const inExcelDataArray = [];
    Object.keys(inExcelDataMap).forEach((key) => {
      inExcelDataArray.push(inExcelDataMap[key]);
    });
    return inExcelDataArray;
  };

  const convertDataArrayToNotAcceptingCheckArray = (
    header,
    dataArray,
    filteredDataArray
  ) => {
    const notAcceptingCheckMap = {};
    let count = 0;
    for (let i = 0; i < dataArray.length; i++) {
      const rowLine = dataArray[i];
      if (rowLine) {
        const usernameIndex = header.findIndex((h) =>
          h.startsWith(i18n.t("username"))
        );
        const foundFilteredData = filteredDataArray.find(
          (filteredData) =>
            filteredData.providedUsername === rowLine[usernameIndex]
        );
        if (foundFilteredData) {
          let error;
          if (foundFilteredData.searchUserState === "FOUND_MULTIPLE_USERS") {
            error = i18n.t("FOUND_MULTIPLE_USERS");
          } else if (
            foundFilteredData.searchUserState === "USERNAME_NOT_UNIQUE"
          ) {
            error = i18n.t("USERNAME_NOT_UNIQUE");
          } else if (
            foundFilteredData.searchUserState === "ALREADY_EXISTS_IN_SEASON"
          ) {
            error = i18n.t("ALREADY_EXISTS_IN_SEASON");
          }
          notAcceptingCheckMap[count] = {
            id: count,
            [header[0].split(" - ")[0]]: rowLine[0],
            [header[1].split(" - ")[0]]: rowLine[1],
            [header[2].split(" - ")[0]]: rowLine[2],
            [header[3].split(" - ")[0]]: rowLine[3],
            [header[4].split(" - ")[0]]: rowLine[4],
            [header[5].split(" - ")[0]]: rowLine[5],
            [header[6].split(" - ")[0]]: rowLine[6],
            [header[7].split(" - ")[0]]: rowLine[7],
            [header[8].split(" - ")[0]]: rowLine[8],
            [header[9].split(" - ")[0]]: rowLine[9],
            row: i + 2,
            error,
          };
          count++;
        }
      }
    }
    const notAcceptingCheckArray = [];
    Object.keys(notAcceptingCheckMap).forEach((key) => {
      notAcceptingCheckArray.push(notAcceptingCheckMap[key]);
    });
    return notAcceptingCheckArray;
  };

  const convertDataArrayToAcceptingCheckArray = (
    header,
    dataArray,
    filteredDataArray
  ) => {
    const acceptingCheckMap = {};
    let count = 0;
    for (let i = 0; i < dataArray.length; i++) {
      const rowLine = dataArray[i];
      if (rowLine) {
        const usernameIndex = header.findIndex((h) =>
          h.startsWith(i18n.t("username"))
        );
        const foundFilteredData = filteredDataArray.find(
          (filteredData) =>
            filteredData.providedUsername === rowLine[usernameIndex]
        );
        if (foundFilteredData) {
          let status;
          if (
            foundFilteredData.searchUserState === "NEW_USER_AND_USER_PROFILE"
          ) {
            status = i18n.t("NEW_USER_AND_USER_PROFILE");
          } else if (foundFilteredData.searchUserState === "NEW_USER_PROFILE") {
            status = i18n.t("NEW_USER_PROFILE");
          }
          acceptingCheckMap[count] = {
            id: count,
            [header[0].split(" - ")[0]]: rowLine[0],
            [header[1].split(" - ")[0]]: rowLine[1],
            [header[2].split(" - ")[0]]: rowLine[2],
            [header[3].split(" - ")[0]]: rowLine[3],
            [header[4].split(" - ")[0]]: rowLine[4],
            [header[5].split(" - ")[0]]: rowLine[5],
            [header[6].split(" - ")[0]]: rowLine[6],
            [header[7].split(" - ")[0]]: rowLine[7],
            [header[8].split(" - ")[0]]: rowLine[8],
            [header[9].split(" - ")[0]]: rowLine[9],
            row: i + 2,
            status,
          };
          count++;
        }
      }
    }
    const acceptingCheckArray = [];
    Object.keys(acceptingCheckMap).forEach((key) => {
      acceptingCheckArray.push(acceptingCheckMap[key]);
    });
    return acceptingCheckArray;
  };

  const filterSeasonList = () => {
    return seasonDTOList.filter(
      (seasonDTO) => seasonDTO.endAt > new Date().getTime()
    );
  };

  const handleOnChange = (type, value) => {
    if (type === "uploadFile") {
      setUploadFile(value[0]);
    } else if (type === "seasonId") {
      setSelectedSeasonId(value);
    }
  };

  const handleCreateExcelFormOnClick = () => {
    exportToCSV(
      [
        {
          [`${i18n.t("username")} - ${i18n.t("username_requirement_text")}`]:
            null,
          [`${i18n.t("password")} - ${i18n.t("will_be_used_for_first_login")}`]:
            null,
          [i18n.t("first_name")]: null,
          [i18n.t("last_name")]: null,
          [i18n.t("email")]: null,
          [`${i18n.t("gender")} - ${i18n.t("male_or_female")}`]: null,
          [i18n.t("identity_number")]: null,
          [`${i18n.t("phone_number")} - ${i18n.t("without_area_code")}`]: null,
          [`${i18n.t("date_of_birth")} - (yyyy-MM-dd)`]: null,
          [`${i18n.t("branch_name")} - ${i18n.t("must_be_exact_match")}`]: null,
        },
      ],
      i18n.t("import_users")
    );
  };

  const handleOnNullCheckTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setNullCheckSelectedItemCount(value);
    } else if (type === "pageNumber") {
      setNullCheckPageNumber(value);
    } else if (type === "sortKey") {
      setNullCheckSortKey(value);
    } else if (type === "sortDirection") {
      setNullCheckSortDirection(value);
    }
  };

  const handleOnInvalidCheckTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setInvalidCheckSelectedItemCount(value);
    } else if (type === "pageNumber") {
      setInvalidCheckPageNumber(value);
    } else if (type === "sortKey") {
      setInvalidCheckSortKey(value);
    } else if (type === "sortDirection") {
      setInvalidCheckSortDirection(value);
    }
  };

  const handleOnInExcelCheckTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setInExcelCheckSelectedItemCount(value);
    } else if (type === "pageNumber") {
      setInExcelCheckPageNumber(value);
    } else if (type === "sortKey") {
      setInExcelCheckSortKey(value);
    } else if (type === "sortDirection") {
      setInExcelCheckSortDirection(value);
    }
  };

  const handleOnNotAcceptingCheckTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setNotAcceptingCheckSelectedItemCount(value);
    } else if (type === "pageNumber") {
      setNotAcceptingCheckPageNumber(value);
    } else if (type === "sortKey") {
      setNotAcceptingCheckSortKey(value);
    } else if (type === "sortDirection") {
      setNotAcceptingCheckSortDirection(value);
    }
  };

  const handleOnAcceptingCheckTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setAcceptingCheckSelectedItemCount(value);
    } else if (type === "pageNumber") {
      setAcceptingCheckPageNumber(value);
    } else if (type === "sortKey") {
      setAcceptingCheckSortKey(value);
    } else if (type === "sortDirection") {
      setAcceptingCheckSortDirection(value);
    }
  };

  const handleCreateImportFromExcelOnClick = async () => {
    const header = excelStatus.data[0];
    const dataArray = JSON.parse(JSON.stringify(excelStatus.data)).splice(1);
    const filteredDataArray = parameterStatus.data.filter(
      (data) =>
        data.searchUserState == "NEW_USER_AND_USER_PROFILE" ||
        data.searchUserState == "NEW_USER_PROFILE"
    );

    const usernameIndex = header.findIndex((h) =>
      h.startsWith(i18n.t("username"))
    );
    const passwordIndex = header.findIndex((h) =>
      h.startsWith(i18n.t("password"))
    );
    const firstNameIndex = header.findIndex((h) =>
      h.startsWith(i18n.t("first_name"))
    );
    const lastNameIndex = header.findIndex((h) =>
      h.startsWith(i18n.t("last_name"))
    );
    const emailIndex = header.findIndex((h) => h.startsWith(i18n.t("email")));
    const genderIndex = header.findIndex((h) => h.startsWith(i18n.t("gender")));
    const identityNumberIndex = header.findIndex((h) =>
      h.startsWith(i18n.t("identity_number"))
    );
    const phoneNumberIndex = header.findIndex((h) =>
      h.startsWith(i18n.t("phone_number"))
    );
    const dateOfBirthIndex = header.findIndex((h) =>
      h.startsWith(i18n.t("date_of_birth"))
    );
    const branchNameIndex = header.findIndex((h) =>
      h.startsWith(i18n.t("branch_name"))
    );
    const constructedData = [];
    for (const data of dataArray) {
      const foundFilteredData = filteredDataArray.find(
        (filteredData) => filteredData.foundUsername === data[usernameIndex]
      );
      const foundBranch = branchDTOList.find(
        (branchDTO) => branchDTO.name === data[branchNameIndex]
      );
      const { gradeDTO } = foundBranch;
      const { level } = gradeDTO;
      if (!foundFilteredData) continue;
      constructedData.push({
        user: {
          username: data[usernameIndex],
          password: data[passwordIndex],
          firstName: data[firstNameIndex],
          lastName: data[lastNameIndex],
          email: data[emailIndex],
          gender: data[genderIndex],
          identityNumber: data[identityNumberIndex]
            ? data[identityNumberIndex]
            : "11111111111",
          phoneNumber: data[phoneNumberIndex]
            ? data[phoneNumberIndex]
            : "5555555555",
          dateOfBirth: data[dateOfBirthIndex]
            ? new Date(data[dateOfBirthIndex]).getTime() -
              60000 * new Date(data[dateOfBirthIndex]).getTimezoneOffset()
            : new Date(
                `${new Date().getFullYear() - level - 7}-01-02T00:00:00`
              ).getTime(),
          accountType: "USER",
        },
        role: "LEARNER",
        seasonId: parseFloat(selectedSeasonId),
        branchIdList: [foundBranch.id],
        name: `${foundBranch.name} ${i18n.t("LEARNER")}`,
        description: i18n.t("automatically_generated_by_admin"),
        searchUserState: foundFilteredData.searchUserState,
      });
    }
    const { data, err } = await createUserImportFromExcel(constructedData);
    if (err) {
      console.log(err);
    } else {
      navigate(`/learner-users`);
    }
  };

  const RenderNullRows = (header, dataArray) => {
    const nullDataArray = convertDataArrayToNullDataArray(header, dataArray);
    if (nullDataArray.length === 0) {
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/checkbox.png"
                alt="checkbox"
              />
            </div>
            <p className="text-xl">
              {i18n.t("import_from_excel_items_not_null_text")}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/exclamation-red.png"
                alt="exclamation-red"
              />
            </div>
            <p className="text-xl font-semibold">
              {i18n.t("import_from_excel_items_cannot_be_null_or_empty")}
            </p>
          </div>
          <AdvancedTable
            title={null}
            description={null}
            itemName={i18n.t("learner")}
            headerList={[
              { name: i18n.t("error"), key: "error" },
              { name: i18n.t("row"), key: "row" },
              { name: i18n.t("username"), key: header[0].split(" - ")[0] },
              { name: i18n.t("password"), key: header[1].split(" - ")[0] },
              { name: i18n.t("first_name"), key: header[2].split(" - ")[0] },
              { name: i18n.t("last_name"), key: header[3].split(" - ")[0] },
              { name: i18n.t("email"), key: header[4].split(" - ")[0] },
              {
                name: i18n.t("gender"),
                key: header[5].split(" - ")[0],
                type: "enumeration",
              },
              {
                name: i18n.t("identity_number"),
                key: header[6].split(" - ")[0],
              },
              { name: i18n.t("phone_number"), key: header[7].split(" - ")[0] },
              {
                name: i18n.t("date_of_birth"),
                key: header[8].split(" - ")[0],
                type: "date",
              },
              {
                name: i18n.t("branch_name"),
                key: header[9].split(" - ")[0],
              },
            ]}
            selectable={false}
            showFilter={false}
            showItemCount={nullCheckSelectedItemCount}
            totalItemCount={nullDataArray.length}
            pageNumber={nullCheckPageNumber}
            loading={false}
            sortKey={nullCheckSortKey}
            sortDirection={nullCheckSortDirection}
            handleOnTableParamsChange={handleOnNullCheckTableParamsChange}
            itemList={retrieveCurrentNullCheckItemList(nullDataArray)}
            insertable={false}
            updatable={false}
            deletable={false}
            navigatable={false}
          />
        </div>
      );
    }
  };

  const RenderInvalidRows = (header, dataArray) => {
    const invalidDataArray = convertDataArrayToInvalidDataArray(
      header,
      dataArray
    );
    if (invalidDataArray.length === 0) {
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/checkbox.png"
                alt="checkbox"
              />
            </div>
            <p className="text-xl">
              {i18n.t("import_from_excel_items_valid_text")}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/exclamation-red.png"
                alt="exclamation-red"
              />
            </div>
            <p className="text-xl font-semibold">
              {i18n.t("import_from_excel_items_validation_exception_text")}
            </p>
          </div>
          <AdvancedTable
            title={null}
            description={null}
            itemName={i18n.t("learner")}
            headerList={[
              { name: i18n.t("error"), key: "error" },
              { name: i18n.t("row"), key: "row" },
              { name: i18n.t("username"), key: header[0].split(" - ")[0] },
              { name: i18n.t("password"), key: header[1].split(" - ")[0] },
              { name: i18n.t("first_name"), key: header[2].split(" - ")[0] },
              { name: i18n.t("last_name"), key: header[3].split(" - ")[0] },
              { name: i18n.t("email"), key: header[4].split(" - ")[0] },
              {
                name: i18n.t("gender"),
                key: header[5].split(" - ")[0],
                type: "enumeration",
              },
              {
                name: i18n.t("identity_number"),
                key: header[6].split(" - ")[0],
              },
              { name: i18n.t("phone_number"), key: header[7].split(" - ")[0] },
              {
                name: i18n.t("date_of_birth"),
                key: header[8].split(" - ")[0],
                type: "date",
              },
              {
                name: i18n.t("branch_name"),
                key: header[9].split(" - ")[0],
              },
            ]}
            selectable={false}
            showFilter={false}
            showItemCount={invalidCheckSelectedItemCount}
            totalItemCount={invalidDataArray.length}
            pageNumber={invalidCheckPageNumber}
            loading={false}
            sortKey={invalidCheckSortKey}
            sortDirection={invalidCheckSortDirection}
            handleOnTableParamsChange={handleOnInvalidCheckTableParamsChange}
            itemList={retrieveCurrentInvalidCheckItemList(invalidDataArray)}
            insertable={false}
            updatable={false}
            deletable={false}
            navigatable={false}
          />
        </div>
      );
    }
  };

  const RenderInExcelRows = (header, dataArray) => {
    const inExcelDataArray = convertDataArrayToInExcelCheckArray(
      header,
      dataArray
    );
    if (inExcelDataArray.length === 0) {
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/checkbox.png"
                alt="checkbox"
              />
            </div>
            <p className="text-xl">
              {i18n.t("import_from_excel_items_in_excel_valid_text")}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col mt-5 pl-7">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <BaseImage
                size="medium"
                src="/icons/exclamation-red.png"
                alt="exclamation-red"
              />
            </div>
            <p className="text-xl font-semibold">
              {i18n.t(
                "import_from_excel_items_in_excel_validation_exception_text"
              )}
            </p>
          </div>
          <AdvancedTable
            title={null}
            description={null}
            itemName={i18n.t("learner")}
            headerList={[
              { name: i18n.t("error"), key: "error" },
              { name: i18n.t("row"), key: "row" },
              { name: i18n.t("username"), key: header[0].split(" - ")[0] },
              { name: i18n.t("password"), key: header[1].split(" - ")[0] },
              { name: i18n.t("first_name"), key: header[2].split(" - ")[0] },
              { name: i18n.t("last_name"), key: header[3].split(" - ")[0] },
              { name: i18n.t("email"), key: header[4].split(" - ")[0] },
              {
                name: i18n.t("gender"),
                key: header[5].split(" - ")[0],
                type: "enumeration",
              },
              {
                name: i18n.t("identity_number"),
                key: header[6].split(" - ")[0],
              },
              { name: i18n.t("phone_number"), key: header[7].split(" - ")[0] },
              {
                name: i18n.t("date_of_birth"),
                key: header[8].split(" - ")[0],
                type: "date",
              },
              {
                name: i18n.t("branch_name"),
                key: header[9].split(" - ")[0],
              },
            ]}
            selectable={false}
            showFilter={false}
            showItemCount={inExcelCheckSelectedItemCount}
            totalItemCount={inExcelDataArray.length}
            pageNumber={inExcelCheckPageNumber}
            loading={false}
            sortKey={inExcelCheckSortKey}
            sortDirection={inExcelCheckSortDirection}
            handleOnTableParamsChange={handleOnInExcelCheckTableParamsChange}
            itemList={retrieveCurrentInExcelCheckItemList(inExcelDataArray)}
            insertable={false}
            updatable={false}
            deletable={false}
            navigatable={false}
          />
        </div>
      );
    }
  };

  const RenderNotAcceptingRows = (header, dataArray) => {
    if (parameterStatus.state === "READY") {
      const filteredDataArray = parameterStatus.data.filter(
        (data) =>
          data.searchUserState === "FOUND_MULTIPLE_USERS" ||
          data.searchUserState === "USERNAME_NOT_UNIQUE" ||
          data.searchUserState === "ALREADY_EXISTS_IN_SEASON"
      );

      if (filteredDataArray.length === 0) {
        return (
          <div className="flex flex-col mt-5 pl-7">
            <div className="flex flex-row items-center">
              <div className="mr-3">
                <BaseImage
                  size="medium"
                  src="/icons/checkbox.png"
                  alt="checkbox"
                />
              </div>
              <p className="text-xl">
                {i18n.t("import_from_excel_items_not_accepted_valid_text")}
              </p>
            </div>
          </div>
        );
      } else {
        const notAcceptingCheckArray = convertDataArrayToNotAcceptingCheckArray(
          header,
          dataArray,
          filteredDataArray
        );
        return (
          <div className="flex flex-col mt-5 pl-7">
            <div className="flex flex-row items-center">
              <div className="mr-3">
                <BaseImage
                  size="medium"
                  src="/icons/exclamation-red.png"
                  alt="exclamation-red"
                />
              </div>
              <p className="text-xl font-semibold">
                {i18n.t("import_from_excel_items_not_accepted_exception_text")}
              </p>
            </div>
            <AdvancedTable
              title={null}
              description={null}
              itemName={i18n.t("learner")}
              headerList={[
                { name: i18n.t("error"), key: "error" },
                { name: i18n.t("row"), key: "row" },
                { name: i18n.t("username"), key: header[0].split(" - ")[0] },
                { name: i18n.t("password"), key: header[1].split(" - ")[0] },
                { name: i18n.t("first_name"), key: header[2].split(" - ")[0] },
                { name: i18n.t("last_name"), key: header[3].split(" - ")[0] },
                { name: i18n.t("email"), key: header[4].split(" - ")[0] },
                {
                  name: i18n.t("gender"),
                  key: header[5].split(" - ")[0],
                  type: "enumeration",
                },
                {
                  name: i18n.t("identity_number"),
                  key: header[6].split(" - ")[0],
                },
                {
                  name: i18n.t("phone_number"),
                  key: header[7].split(" - ")[0],
                },
                {
                  name: i18n.t("date_of_birth"),
                  key: header[8].split(" - ")[0],
                  type: "date",
                },
                {
                  name: i18n.t("branch_name"),
                  key: header[9].split(" - ")[0],
                },
              ]}
              selectable={false}
              showFilter={false}
              showItemCount={notAcceptingCheckSelectedItemCount}
              totalItemCount={notAcceptingCheckArray.length}
              pageNumber={notAcceptingCheckPageNumber}
              loading={false}
              sortKey={notAcceptingCheckSortKey}
              sortDirection={notAcceptingCheckSortDirection}
              handleOnTableParamsChange={
                handleOnNotAcceptingCheckTableParamsChange
              }
              itemList={retrieveCurrentNotAcceptingCheckItemList(
                notAcceptingCheckArray
              )}
              insertable={false}
              updatable={false}
              deletable={false}
              navigatable={false}
            />
          </div>
        );
      }
    }
    return null;
  };

  const RenderAcceptingRows = (header, dataArray) => {
    if (parameterStatus.state === "READY") {
      const filteredDataArray = parameterStatus.data.filter(
        (data) =>
          data.searchUserState === "NEW_USER_AND_USER_PROFILE" ||
          data.searchUserState === "NEW_USER_PROFILE"
      );

      if (filteredDataArray.length === 0) {
        return (
          <div className="flex flex-col mt-5 pl-7">
            <div className="flex flex-row items-center">
              <div className="mr-3">
                <BaseImage
                  size="medium"
                  src="/icons/exclamation-red.png"
                  alt="exclamation-red"
                />
              </div>
              <p className="text-xl font-semibold">
                {i18n.t("import_from_excel_items_accepted_exception_text")}
              </p>
            </div>
          </div>
        );
      } else {
        const acceptingCheckArray = convertDataArrayToAcceptingCheckArray(
          header,
          dataArray,
          filteredDataArray
        );
        return (
          <div className="flex flex-col mt-5 pl-7">
            <div className="flex flex-row items-center">
              <div className="mr-3">
                <BaseImage
                  size="medium"
                  src="/icons/checkbox.png"
                  alt="checkbox"
                />
              </div>
              <p className="text-xl">
                {i18n.t("import_from_excel_items_accepted_valid_text")}
              </p>
            </div>
            <AdvancedTable
              title={null}
              description={null}
              itemName={i18n.t("learner")}
              headerList={[
                { name: i18n.t("status"), key: "status" },
                { name: i18n.t("row"), key: "row" },
                { name: i18n.t("username"), key: header[0].split(" - ")[0] },
                { name: i18n.t("password"), key: header[1].split(" - ")[0] },
                { name: i18n.t("first_name"), key: header[2].split(" - ")[0] },
                { name: i18n.t("last_name"), key: header[3].split(" - ")[0] },
                { name: i18n.t("email"), key: header[4].split(" - ")[0] },
                {
                  name: i18n.t("gender"),
                  key: header[5].split(" - ")[0],
                  type: "enumeration",
                },
                {
                  name: i18n.t("identity_number"),
                  key: header[6].split(" - ")[0],
                },
                {
                  name: i18n.t("phone_number"),
                  key: header[7].split(" - ")[0],
                },
                {
                  name: i18n.t("date_of_birth"),
                  key: header[8].split(" - ")[0],
                  type: "date",
                },
                {
                  name: i18n.t("branch_name"),
                  key: header[9].split(" - ")[0],
                },
              ]}
              selectable={false}
              showFilter={false}
              showItemCount={acceptingCheckSelectedItemCount}
              totalItemCount={acceptingCheckArray.length}
              pageNumber={acceptingCheckPageNumber}
              loading={false}
              sortKey={acceptingCheckSortKey}
              sortDirection={acceptingCheckSortDirection}
              handleOnTableParamsChange={
                handleOnAcceptingCheckTableParamsChange
              }
              itemList={retrieveCurrentAcceptingCheckItemList(
                acceptingCheckArray
              )}
              insertable={false}
              updatable={false}
              deletable={false}
              navigatable={false}
            />
            <div className="mt-4 ml-auto">
              <CreateButton
                title={i18n.t("create")}
                size="medium"
                onClick={handleCreateImportFromExcelOnClick}
              />
            </div>
          </div>
        );
      }
    }
    return null;
  };

  const RenderExcelData = () => {
    if (excelStatus.state) {
      if (excelStatus.state === "READY") {
        const header = excelStatus.data[0];
        const dataArray = JSON.parse(JSON.stringify(excelStatus.data)).splice(
          1
        );
        return (
          <>
            <DividerLine />
            <div>
              <p>
                {i18n.t("found_entry_count")}: {dataArray.length}
              </p>
            </div>
            {dataArray.length === 0 ? (
              <div className="my-2 p-3 border rounded-catchup-xlarge bg-catchup-red text-catchup-white">
                <p>
                  {i18n.t(
                    "import_excel_data_array_empty_length_check_sheet_name_text"
                  )}
                </p>
              </div>
            ) : (
              <>
                {RenderNullRows(header, dataArray)}
                {RenderInvalidRows(header, dataArray)}
                {RenderInExcelRows(header, dataArray)}
                {parameterStatus.state === "WAITING" ? (
                  <BaseLoading
                    size="large"
                    color="#57C2D3"
                    secondaryColor="#57C2D3"
                  />
                ) : parameterStatus.state === "READY" ? (
                  <>
                    <DividerLine />
                    {RenderNotAcceptingRows(header, dataArray)}
                    {RenderAcceptingRows(header, dataArray)}
                  </>
                ) : null}
              </>
            )}
          </>
        );
      } else {
        return (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        );
      }
    } else {
      return null;
    }
  };

  const filteredSeasonList = filterSeasonList();

  return (
    <FullCard isShadowed={true}>
      <div className="">
        <div className="flex flex-row items-center gap-x-2">
          <div className="cursor-pointer">
            <BaseImage
              size="medium"
              src="/icons/long-arrow-left.png"
              alt="long-arrow-left"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <BaseTitle title={i18n.t("create_user_from_excel")} />
        </div>
      </div>
      <DividerLine />
      <div className="flex flex-col">
        <div className="ml-auto">
          <SecondaryButton
            title={i18n.t("download_excel")}
            size="unlimited"
            onClick={handleCreateExcelFormOnClick}
          />
        </div>

        {/* <div className="my-2 p-3 border rounded-catchup-xlarge bg-catchup-dark-blue text-catchup-white"> */}
        <div className="my-2 p-3">
          {SingleInformationIconTextItem(
            i18n.t("import_excel_data_general_information_text_1")
          )}
          {SingleInformationIconTextItem(
            i18n.t("import_excel_data_general_information_text_2")
          )}
          {SingleInformationIconTextItem(
            i18n.t("import_excel_data_general_information_text_3")
          )}
        </div>

        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("season_name")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedSeasonId}
              optionList={retrieveSeasonDTOOptionList(filteredSeasonList)}
              onChange={(event) => {
                handleOnChange("seasonId", event.target.value);
              }}
            />
          </div>
        </div>

        {selectedSeasonId !== "DEFAULT_OPTION" ? (
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("file_upload")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="file"
                multiple={false}
                accept={retrieveAcceptedFormats()}
                value={null}
                onChange={(event) => {
                  handleOnChange("uploadFile", {
                    ...event.target.files,
                  });
                }}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </div>
          </div>
        ) : null}

        {RenderExcelData()}
      </div>
    </FullCard>
  );
};

export default ImportFromExcelCreateUser;
