import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import { retrieveMainLeftNavigationOptionList } from "../../utilization/HeaderUtilization";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import BaseImage from "../images/BaseImage";
import i18n from "../../language/i18n";
import { NotificationManager } from "react-notifications";
import DividerLine from "../divider/DividerLine";
import useScreenSize from "../../hooks/useScreenSize";

const MainLeftNavigation = () => {
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const { setBoarding, mainLeftNavigationState, setMainLeftNavigationState } =
    useApp();
  const {
    userInformation,
    userProfile,
    handleStateChangeProfile,
    handleStateLogout,
  } = useAuth();
  const [modeIconHeight, setModeIconHeight] = useState(0);
  const [selectedParentOptionId, setSelectedParentOptionId] = useState(null);
  const [selectedSubOptionId, setSelectedSubOptionId] = useState(null);

  useEffect(() => {
    const pathName = window.location.pathname;
    const foundParentNavigationOption =
      visibleMainLeftNavigationOptionList.find(
        (navigationOption) => navigationOption.navigate === pathName
      );

    if (foundParentNavigationOption) {
      setSelectedParentOptionId(foundParentNavigationOption.id);
      setSelectedSubOptionId(null);
    } else {
      const foundSubNavigationOption = visibleMainLeftNavigationOptionList
        .filter((navigationOption) => navigationOption.subOptionList !== null)
        .find((navigationOption) =>
          navigationOption.subOptionList.find(
            (subNavigationOption) => subNavigationOption.navigate === pathName
          )
        );
      if (foundSubNavigationOption) {
        setSelectedParentOptionId(foundSubNavigationOption.id);
        const subOptionList = foundSubNavigationOption.subOptionList;
        const foundSubOption = subOptionList.find(
          (subNavigationOption) => subNavigationOption.navigate === pathName
        );
        setSelectedSubOptionId(foundSubOption.id);
      }
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const { height } = screenSize;
    setModeIconHeight(height / 2);
  }, [screenSize]);

  const handleChangeMainLeftNavigationState = () => {
    if (mainLeftNavigationState === "FULL") {
      setMainLeftNavigationState("ICON_ONLY");
    } else if (mainLeftNavigationState === "ICON_ONLY") {
      setMainLeftNavigationState("FULL");
    }
  };

  const filterVisibleMainLeftNavigationOptionList = () => {
    return mainLeftNavigationOptionList.filter((option) => option.visible);
  };

  const filterMainLeftNavigationOptionList = () => {
    if (userProfile && userProfile.userDTO) {
      if (userProfile.userDTO.accountType === "GENIXO") {
        return visibleMainLeftNavigationOptionList;
      } else {
        const filteredMainLeftNavigationOptionList = [];
        visibleMainLeftNavigationOptionList.forEach((navigationOption) => {
          if (navigationOption.subOptionList) {
            if (
              navigationOption.roleList.findIndex(
                (role) => role === userProfile.role
              ) > -1
            ) {
              const filteredSubOptionList = [];
              navigationOption.subOptionList.forEach((subNavigationOption) => {
                if (
                  subNavigationOption.roleList.findIndex(
                    (role) => role === userProfile.role
                  ) > -1
                ) {
                  if (
                    checkViewPermissionGranted(
                      subNavigationOption.authorityScreenName
                    )
                  ) {
                    filteredSubOptionList.push(subNavigationOption);
                  }
                }
              });
              if (filteredSubOptionList.length > 0) {
                navigationOption.subOptionList = filteredSubOptionList;
                filteredMainLeftNavigationOptionList.push(navigationOption);
              }
            }
          } else {
            if (
              navigationOption.roleList.findIndex(
                (role) => role === userProfile.role
              ) > -1
            ) {
              if (navigationOption.authorityScreenName === "dashboard") {
                filteredMainLeftNavigationOptionList.push(navigationOption);
              } else {
                if (
                  checkViewPermissionGranted(
                    navigationOption.authorityScreenName
                  )
                ) {
                  filteredMainLeftNavigationOptionList.push(navigationOption);
                }
              }
            }
          }
        });
        return filteredMainLeftNavigationOptionList;
      }
    }
    return [];
  };

  const mainLeftNavigationOptionList = retrieveMainLeftNavigationOptionList();
  const visibleMainLeftNavigationOptionList =
    filterVisibleMainLeftNavigationOptionList();
  const filteredMainLeftNavigationOptionList =
    filterMainLeftNavigationOptionList();

  return (
    <div className="h-full">
      <div
        className={`relative ${
          mainLeftNavigationState === "FULL"
            ? "w-catchup-main-left-navigation"
            : mainLeftNavigationState === "ICON_ONLY"
            ? "w-catchup-main-left-navigation-icon-only"
            : ""
        } h-full pr-10 bg-catchup-background text-lg text-catchup-gray-400 transition-all duration-500 z-0`}
      >
        <div
          className="absolute z-10"
          style={{ top: `${modeIconHeight - 24}px`, right: 20 }}
          onClick={handleChangeMainLeftNavigationState}
        >
          <div className="bg-catchup-white rounded-full p-2">
            <BaseImage
              src={`${
                mainLeftNavigationState === "FULL"
                  ? "/icons/navigation-panel-arrow-left.png"
                  : mainLeftNavigationState === "ICON_ONLY"
                  ? "/icons/navigation-panel-arrow-right.png"
                  : ""
              }`}
              alt="navigation-panel-arrow-left"
              size="medium"
            />
          </div>
        </div>
        <div className="h-full flex flex-col py-10 px-5 border-r-2 border-r-catchup-white rounded-r-catchup-clock bg-catchup-white shadow-left-navigation">
          <div
            className={`${
              mainLeftNavigationState === "FULL" ? "mb-10" : "mb-10"
            } px-2 mx-auto`}
          >
            {mainLeftNavigationState === "FULL" ? (
              <BaseImage
                src="/logos/logo-primary.png"
                alt="main-logo"
                className="w-catchup-medium-logo"
                onClick={() => {
                  navigate("/");
                }}
              />
            ) : mainLeftNavigationState === "ICON_ONLY" ? (
              <BaseImage
                src="/logos/logo-primary-icon.png"
                alt="main-logo-icon"
                size="custom"
                className="w-8"
                onClick={() => {
                  navigate("/");
                }}
              />
            ) : null}
          </div>
          <div className="h-full no-scrollbar overflow-y-auto">
            <div className="pb-[100px] flex flex-col gap-y-1">
              {filteredMainLeftNavigationOptionList.map((navigationOption) => (
                <div key={navigationOption.id} className="flex flex-col z-20">
                  <div
                    className={`flex flex-row ${
                      mainLeftNavigationState === "FULL"
                        ? "justify-between"
                        : "justify-center"
                    } items-center cursor-pointer ${
                      selectedParentOptionId === navigationOption.id
                        ? "bg-catchup-blue-700 "
                        : "hover:bg-catchup-gray-50"
                    } transition-all duration-300 py-1 rounded-catchup-header px-1`}
                    onClick={() => {
                      if (mainLeftNavigationState === "FULL") {
                        setSelectedParentOptionId(navigationOption.id);
                        if (navigationOption.navigate) {
                          setSelectedSubOptionId(null);
                          navigate(navigationOption.navigate);
                        }
                      } else {
                        handleChangeMainLeftNavigationState();
                      }
                    }}
                  >
                    <div className="flex flex-row items-center gap-x-2">
                      <div className="">
                        <BaseImage
                          src={
                            selectedParentOptionId === navigationOption.id
                              ? navigationOption.selectedIcon
                              : navigationOption.icon
                          }
                          alt="navigation-icon"
                          size="small"
                        />
                      </div>
                      {mainLeftNavigationState === "FULL" ? (
                        <div className="flex-1">
                          <span
                            className={`${
                              selectedParentOptionId === navigationOption.id
                                ? "text-catchup-white"
                                : null
                            }`}
                          >
                            {navigationOption.name}
                          </span>
                        </div>
                      ) : null}
                    </div>
                    {mainLeftNavigationState === "FULL" &&
                    navigationOption.subOptionList ? (
                      <div className="">
                        <BaseImage
                          src={
                            selectedParentOptionId === navigationOption.id
                              ? "/icons/header-arrow-up-selected.png"
                              : "/icons/header-arrow-down.png"
                          }
                          alt="arrow-up"
                          size="custom"
                          className="h-5 w-5"
                        />
                      </div>
                    ) : null}
                  </div>
                  {mainLeftNavigationState === "FULL" &&
                  navigationOption.subOptionList &&
                  selectedParentOptionId === navigationOption.id ? (
                    <div className="mx-4">
                      {navigationOption.subOptionList.map(
                        (subNavigationOption) => (
                          <div
                            key={subNavigationOption.id}
                            className="flex flex-row items-center justify-center px-2 cursor-pointer transition-all duration-300 my-1"
                            onClick={() => {
                              setSelectedSubOptionId(subNavigationOption.id);
                              navigate(subNavigationOption.navigate);
                            }}
                          >
                            <p className={`flex-1 text-base py-1`}>
                              <span
                                className={`${
                                  selectedSubOptionId === subNavigationOption.id
                                    ? "border-b-catchup-blue"
                                    : "border-b-catchup-white"
                                } border-b-3`}
                              >
                                {subNavigationOption.name}
                              </span>
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
          <div className="mt-auto relative">
            <div className="absolute bg-catchup-left-navigation-gradient -top-[100px] w-full h-[100px] z-30" />
            {userInformation ? (
              <>
                <div
                  className={`flex-1 flex flex-row items-center cursor-pointer transition-all duration-300 py-1`}
                  onClick={() => {
                    if (mainLeftNavigationState === "FULL") {
                      navigate("/");
                      setBoarding("PROFILE_SELECTION_REQUIRED");
                      handleStateChangeProfile();
                      localStorage.setItem("appState", "CHANGE_PROFILE");
                    } else {
                      handleChangeMainLeftNavigationState();
                    }
                  }}
                >
                  <div
                    className={`flex-1 flex flex-row gap-x-1 ${
                      mainLeftNavigationState === "FULL" ? "" : "justify-center"
                    }`}
                  >
                    <BaseImage alt="bag" src="/icons/bag.png" size="small" />
                    {mainLeftNavigationState === "FULL" ? (
                      <div className="flex-1 flex-row items-center">
                        <p className={`font-semibold`}>
                          {userInformation.firstName} {userInformation.lastName}
                        </p>
                        <p className={`text-base opacity-50`}>
                          {userInformation.email.length > 25
                            ? `${userInformation.email.substr(0, 22)}...`
                            : userInformation.email}
                        </p>
                      </div>
                    ) : null}
                    {mainLeftNavigationState === "FULL" ? (
                      <BaseImage
                        src="/icons/change-profile.png"
                        alt="change-profile"
                        size="small"
                      />
                    ) : null}
                  </div>
                </div>
                <DividerLine />
                <div
                  className={`flex-1 flex flex-row justify-between items-center cursor-pointer transition-all duration-300 py-1`}
                  onClick={() => {
                    if (mainLeftNavigationState === "FULL") {
                      navigate("/settings");
                    } else {
                      handleChangeMainLeftNavigationState();
                    }
                  }}
                >
                  <div className="flex-1 flex flex-row justify-center gap-x-1">
                    <BaseImage
                      src="/icons/settings.png"
                      alt="settings"
                      size="small"
                    />
                    {mainLeftNavigationState === "FULL" ? (
                      <div className="flex-1">
                        <p>{i18n.t("settings")}</p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}

            <div
              className={`flex-1 flex flex-row justify-between items-center cursor-pointer transition-all duration-300 py-1`}
              onClick={() => {
                if (mainLeftNavigationState === "FULL") {
                  navigate("/");
                  handleStateLogout();
                  setBoarding("LANDING");
                  NotificationManager.success(i18n.t("logout_successful_text"));
                } else {
                  handleChangeMainLeftNavigationState();
                }
              }}
            >
              <div className="flex-1 flex flex-row justify-center gap-x-1">
                <BaseImage src="/icons/logout.png" alt="logout" size="small" />
                {mainLeftNavigationState === "FULL" ? (
                  <div className="flex-1">
                    <p>{i18n.t("logout")}</p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLeftNavigation;
