import {
  Document,
  Font,
  Image,
  Text,
  Page,
  PDFViewer,
  View,
} from "@react-pdf/renderer";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import { useEffect, useState } from "react";
import { retrieveExternalExamDTOResultsById } from "../../requests/CatchtivityRequests";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import BaseLoading from "../../components/loading/BaseLoading";
import i18n from "../../language/i18n";
import { useAuth } from "../../context/UserContextProvider";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
      fontWeight: "normal",
      textDecoration: "none",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
      fontWeight: "bold",
      textDecoration: "none",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
      fontWeight: "normal",
      textDecoration: "underline",
    },
  ],
});

const ExternalExamResultsPDFViewerScreen = () => {
  const navigate = useNavigate();
  const { externalExamId } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const { userProfileBrand } = useAuth();
  const [externalExamResultDTO, setExternalExamResultDTO] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("external_exam_list")) {
      navigate("/permission-error");
      return;
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetExternalExamResultDTO = async () => {
      setLoading(true);
      const { data, err } = await retrieveExternalExamDTOResultsById(
        externalExamId
      );
      if (err) {
        console.log(err);
        return;
      }
      setExternalExamResultDTO(data);
      setLoading(false);
    };
    if (!externalExamId) return;
    retrieveAndSetExternalExamResultDTO();
  }, [externalExamId]);

  const filterAttendedExternalExamResultLearnerDTOList = () => {
    if (!externalExamResultDTO) return [];
    const { externalExamResultLearnerDTOList } = externalExamResultDTO;
    return externalExamResultLearnerDTOList
      .filter((item) => item.score !== null)
      .sort((a, b) => b.score - a.score);
  };

  const RenderViewContent = () => {
    if (!externalExamResultDTO) return;
    const { name } = externalExamResultDTO;
    return (
      <View>
        <View
          style={{
            flex: 1,
            borderBottom: 1,
            marginBottom: 5,
            paddingBottom: 5,
          }}
        >
          <Text>{name}</Text>
        </View>
        <View style={{ flex: 1, flexDirection: "row", marginTop: 5 }}>
          <Text style={{ width: "5%", fontWeight: "bold" }}></Text>
          <Text style={{ width: "15%", fontWeight: "bold" }}>
            {i18n.t("identity_number")}
          </Text>
          <Text style={{ width: "25%", fontWeight: "bold" }}>
            {i18n.t("first_name")}
          </Text>
          <Text style={{ width: "25%", fontWeight: "bold" }}>
            {i18n.t("last_name")}
          </Text>

          <Text style={{ width: "15%", fontWeight: "bold" }}>
            {i18n.t("score")}
          </Text>
          <Text style={{ width: "10%", fontWeight: "bold" }}>
            {i18n.t("rank")}
          </Text>
        </View>
        {filteredAttendedExternalExamResultLearnerDTOList.map(
          (externalExamResultLearnerDTO, index) => (
            <View style={{ flex: 1, flexDirection: "row", marginTop: 5 }}>
              <Text style={{ width: "5%" }}>{index + 1}</Text>
              <Text style={{ width: "15%" }}>
                {externalExamResultLearnerDTO.identityNumber}
              </Text>
              <Text style={{ width: "25%" }}>
                {externalExamResultLearnerDTO.firstName}
              </Text>
              <Text style={{ width: "25%" }}>
                {externalExamResultLearnerDTO.lastName}
              </Text>

              <Text style={{ width: "15%" }}>
                {externalExamResultLearnerDTO.score.toFixed(2)}
              </Text>
              <Text style={{ width: "10%" }}>
                {externalExamResultLearnerDTO.rank}
              </Text>
            </View>
          )
        )}
      </View>
    );
  };

  const filteredAttendedExternalExamResultLearnerDTOList =
    filterAttendedExternalExamResultLearnerDTOList();

  return (
    <div className="flex-1 flex flex-col h-full">
      <div className="m-4 h-full">
        {loading ? (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <PDFViewer className="h-full w-full">
            <Document>
              <Page
                size="A4"
                style={{
                  backgroundColor: "#ffffff",
                  fontFamily: "Roboto",
                  paddingVertical: 30,
                  paddingHorizontal: 15,
                  fontSize: 10,
                }}
              >
                {userProfileBrand.logoUrl ? (
                  <View
                    style={{
                      height: "10%",
                      margin: "auto",
                    }}
                  >
                    <Image
                      src={{
                        uri: userProfileBrand.logoUrl,
                      }}
                      style={{ height: "100%", marginVertical: 10 }}
                    />
                  </View>
                ) : null}
                <View style={{ flex: 1 }}>{RenderViewContent()}</View>
              </Page>
            </Document>
          </PDFViewer>
        )}
      </div>
    </div>
  );
};

export default ExternalExamResultsPDFViewerScreen;
