import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import {
  evaluateExternalExam,
  evaluateSingleExternalExam,
  patchExternalExam,
  queryExternalActivityEvaluationDTOListByExternalExamId,
  retrieveExternalExamDTOById,
} from "../../requests/CatchtivityRequests";
import { checkUpdatePermissionGranted } from "../../utilization/ScreenUtilization";
import FullCard from "../../components/cards/FullCard";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import i18n from "../../language/i18n";
import BaseLoading from "../../components/loading/BaseLoading";
import DividerLine from "../../components/divider/DividerLine";
import InputGroup from "../../components/groups/InputGroup";
import { useAuth } from "../../context/UserContextProvider";
import { NotificationManager } from "react-notifications";
import {
  deleteStorageFile,
  moveFileToBrandPath,
  retrieveMainFileById,
  uploadFileToPath,
} from "../../requests/StorageRequests";
import { Dna } from "react-loader-spinner";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import BaseModal from "../../components/modal/BaseModal";
import SingleInformationIconTextItem from "../../components/infos/SingleInformationIconTextItem";
import {
  convertFileAndUpload,
  queryConvertedFileList,
} from "../../requests/FileConverterRequests";
import PageTravelGroup from "../../components/groups/PageTravelGroup";
import { showDocumentByPathAndFileName } from "../../utilization/StorageFileUtilization";
import CreateButton from "../../components/buttons/CreateButton";

const ExternalExamAnswerListScreen = () => {
  const navigate = useNavigate();
  const { externalExamId } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const { userInformation, userProfile, userProfileBrand } = useAuth();
  const [externalExamDTO, setExternalExamDTO] = useState(null);
  const [externalExamAnswerFileId, setExternalExamAnswerFileId] =
    useState(null);
  const [externalExamAnswerFile, setExternalExamAnswerFile] = useState(null);
  const [externalExamAnswerFileLoading, setExternalExamAnswerFileLoading] =
    useState(false);
  const [
    externalActivityEvaluationDTOList,
    setExternalActivityEvaluationDTOList,
  ] = useState([]);
  const [
    externalActivityEvaluationDTOListLoading,
    setExternalActivityEvaluationDTOListLoading,
  ] = useState(false);
  const [externalExamLoading, setExternalExamLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [fileUploadStatus, setFileUploadStatus] = useState(null);
  const [fileMoveStatus, setFileMoveStatus] = useState(null);
  const [fileParseStatus, setFileParseStatus] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [imageReady, setImageReady] = useState(false);
  const [convertedFileList, setConvertedFileList] = useState([]);
  const [initialTotalPageNumber, setInitialTotalPageNumber] = useState(0);

  const ownershipType = "EXTERNAL_EXAM_DOCUMENT";

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkUpdatePermissionGranted("external_exam_list")) {
      navigate("/permission-error");
      return;
    }
  }, []);

  useEffect(() => {
    if (!externalExamId) return;
    retrieveAndSetExternalExamPartDTOList();
  }, [externalExamId]);

  useEffect(() => {
    if (!externalExamDTO) return;
    const { evaluationStatus } = externalExamDTO;
    if (evaluationStatus !== "EVALUATED") return;
    retrieveAndSetExternalActivityEvaluationList();
  }, [externalExamDTO]);

  useEffect(() => {
    const retrieveAndSetExternalExamAnswerFile = async () => {
      setExternalExamAnswerFileLoading(true);
      const { data, err } = await retrieveMainFileById(
        externalExamAnswerFileId
      );
      if (err) {
        console.log(err);
        return;
      }
      setExternalExamAnswerFile(data);
      setExternalExamAnswerFileLoading(false);
    };
    if (!externalExamAnswerFileId) return;
    retrieveAndSetExternalExamAnswerFile();
  }, [externalExamAnswerFileId]);

  useEffect(() => {
    if (uploadFile) {
      handleSendSingleFileUpload(uploadFile);
    }
  }, [uploadFile]);

  useEffect(() => {
    const handleConvertFileAndUpload = async () => {
      setFileParseStatus("PARSING");
      let inputPath = "";
      if (process.env.REACT_APP_NODE_ENV === "development") {
        inputPath += ".";
      }
      inputPath += `./catchup-storage/brand-files/${userProfileBrand.id}/pdfs/${uploadedFile.fileName}`;
      const { data, err } = await convertFileAndUpload({
        inputPath,
        inputUploadPath: `/${userProfileBrand.id}/pdfs/${uploadedFile.fileName}`,
        inputType: "PDF",
        outputType: "JPEG",
        outputEncoding: "file",
        ownershipType,
        coterieType: "MANAGEMENT",
      });
      if (err) {
        console.log(err);
        NotificationManager.error(i18n.t("error_occurred_on_parse_file"));
        setFileParseStatus("ERROR");
        return;
      }
      setFileParseStatus("PARSED");
    };
    if (fileMoveStatus === "MOVED") {
      handleConvertFileAndUpload();
      setFileMoveStatus(null);
    }
  }, [fileMoveStatus]);

  useEffect(() => {
    const patchAndSetExternalExam = async () => {
      const { data: patchExternalExamData, err: patchExternalExamErr } =
        await patchExternalExam({
          id: externalExamDTO.id,
          externalExamAnswerFileId,
        });
      if (patchExternalExamErr) {
        console.log(patchExternalExamErr);
        return;
      }
      setFileParseStatus(null);
      retrieveAndSetConvertedFileList();
    };
    if (fileParseStatus === "PARSED") {
      patchAndSetExternalExam();
    } else if (fileParseStatus === "ERROR") {
      handleRemoveUploadedPDFOnClick(externalExamAnswerFileId);
    }
  }, [fileParseStatus]);

  useEffect(() => {
    if (!externalExamAnswerFile) return;
    retrieveAndSetConvertedFileList();
  }, [externalExamAnswerFile]);

  const retrieveAndSetExternalExamPartDTOList = async () => {
    setExternalExamLoading(true);
    const { data, err } = await retrieveExternalExamDTOById(externalExamId);
    if (err) {
      console.log(err);
      return;
    }
    setExternalExamDTO(data);
    setExternalExamAnswerFileId(data.externalExamAnswerFileId);
    setExternalExamLoading(false);
  };

  const retrieveAcceptedFormats = () => {
    return ".pdf";
  };

  const retrieveFileNameFromPageNumber = () => {
    const foundConvertedFile = convertedFileList.find(
      (convertedFile) => convertedFile.pageNumber === pageNumber
    );
    if (foundConvertedFile) {
      return foundConvertedFile.fileName;
    }
    return "NOT_FOUND";
  };

  const retrieveAndSetConvertedFileList = async () => {
    const { data, err } = await queryConvertedFileList({
      fileFolder: `/${userProfileBrand.id}/pdfs/${externalExamAnswerFile.fileName}`,
    });
    if (err) {
      console.log(err);
      return;
    }
    if (data.length === 0) {
    } else {
      setConvertedFileList(data);
      setInitialTotalPageNumber(data.length);
    }
  };

  const retrieveAndSetExternalActivityEvaluationList = async () => {
    setExternalActivityEvaluationDTOListLoading(true);
    const { data, err } =
      await queryExternalActivityEvaluationDTOListByExternalExamId({
        externalExamId,
      });
    if (err) {
      console.log(err);
      return;
    }
    setExternalActivityEvaluationDTOList(data);
    setExternalActivityEvaluationDTOListLoading(false);
  };

  const filterNotEvalutedPageNumberList = () => {
    if (!externalExamDTO) return [];
    const { evaluationStatus } = externalExamDTO;
    if (evaluationStatus !== "EVALUATED") return [];
    if (convertedFileList.length === 0) return [];
    if (externalActivityEvaluationDTOListLoading) return [];
    const pageNumberList = [];
    for (const convertedFile of convertedFileList) {
      const foundExternalActivityEvaluationDTO =
        externalActivityEvaluationDTOList.find(
          (externalActivityEvaluationDTO) =>
            externalActivityEvaluationDTO.externalExamAnswerFileName ===
            convertedFile.fileName
        );
      if (!foundExternalActivityEvaluationDTO) {
        pageNumberList.push(convertedFile.pageNumber);
      }
    }
    return pageNumberList;
  };

  const handleOnChange = (type, value) => {
    if (type === "uploadFile") {
      setUploadFile(value[0]);
    }
  };

  const handleSendSingleFileUpload = async (file) => {
    setFileUploadStatus("UPLOADING");
    const formData = new FormData();
    formData.append("brandId", userProfileBrand.id);
    formData.append("userId", userInformation.id);
    formData.append("userProfileId", userProfile.id);
    formData.append("coterieType", "MANAGEMENT");
    formData.append("documentType", "PDF");
    formData.append("path", `/${userProfileBrand.id}/pdfs`);
    formData.append("file", file);
    formData.append("changeFileName", true);
    const { data, err } = await uploadFileToPath(formData);
    if (err) {
      setFileUploadStatus("ERROR");
      NotificationManager.error(i18n.t("error_occurred_on_upload_file"));
      return;
    }
    setUploadedFile({
      id: data.id,
      fileName: data.fileName,
      tempPath: data.path,
      ownershipType,
    });
    setFileUploadStatus("UPLOADED");
  };

  const handleMoveSingleFileUpload = async (uploadedFile) => {
    setFileMoveStatus("MOVING");
    const { id, ...rest } = uploadedFile;
    const { data: moveFileData, err: moveFileErr } = await moveFileToBrandPath(
      rest
    );
    if (moveFileErr) {
      console.log(moveFileErr);
      setFileMoveStatus("FAILED");
      NotificationManager.error(i18n.t("error_occurred_on_upload_file"));
      return;
    }
    setExternalExamAnswerFileId(moveFileData.id);
    setFileMoveStatus("MOVED");
    NotificationManager.success(i18n.t("file_successfully_uploaded"));
  };

  const handleRemoveUploadedPDFOnClick = async (storageFileId) => {
    const { err } = await deleteStorageFile(storageFileId);
    if (err) {
      console.log(err);
      return;
    }
    setFileUploadStatus("UPLOADED");
  };

  const handleOnImageLoad = () => {
    setImageReady(true);
  };

  const handleEvaluateExternalExamOnClick = async () => {
    setExternalExamLoading(true);
    const { data, err } = await evaluateExternalExam({
      id: externalExamDTO.id,
    });
    if (err) {
      console.log(err);
      return;
    }
    externalExamDTO.evaluationStatus = "EVALUATING";
    setExternalExamDTO(JSON.parse(JSON.stringify(externalExamDTO)));
    setExternalExamLoading(false);
  };

  const handleEvaluateSingleExternalExamOnClick = async () => {
    const foundConvertedFile = convertedFileList.find(
      (convertedFile) => convertedFile.pageNumber === pageNumber
    );
    if (!foundConvertedFile) return;
    const { data, err } = await evaluateSingleExternalExam({
      id: externalExamDTO.id,
      fileFolder: foundConvertedFile.fileFolder,
      pageNumber: foundConvertedFile.pageNumber,
      fileName: foundConvertedFile.fileName,
    });
    if (err) {
      console.log(err);
      return;
    }
    retrieveAndSetExternalActivityEvaluationList();
  };

  const RenderBeforeParseContent = () => {
    return externalExamLoading ? (
      <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
    ) : externalExamAnswerFileId ? (
      externalExamAnswerFileLoading ? (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      ) : null
    ) : (
      <div className="flex flex-col">
        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("file_upload")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="file"
              multiple={false}
              accept={retrieveAcceptedFormats()}
              value={null}
              onChange={(event) => {
                handleOnChange("uploadFile", {
                  ...event.target.files,
                });
              }}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
          </div>
        </div>
        {uploadFile ? (
          <div className="w-5/6 ml-auto">
            <div className="flex flex-row justify-between items-center">
              <div>
                <p>{uploadFile.name}</p>
              </div>
              <div>
                <div>
                  {fileUploadStatus === "UPLOADING" ? (
                    <>
                      <div className="mx-2">
                        <Dna
                          visible={true}
                          height="32"
                          width="32"
                          ariaLabel="dna-loading"
                          wrapperStyle={{}}
                          wrapperClass="dna-wrapper"
                        />
                      </div>
                    </>
                  ) : fileUploadStatus === "UPLOADED" ? (
                    <>
                      <div className="mx-2">
                        <BaseImage
                          src="/icons/checkbox.png"
                          alt="checkbox"
                          size="small"
                        />
                      </div>
                    </>
                  ) : fileUploadStatus === "ERROR" ? (
                    <>
                      <div className="mx-2">
                        <BaseImage
                          src="/icons/remove-red.png"
                          alt="remove"
                          onClick={() => {
                            setUploadFile(null);
                            setFileUploadStatus(null);
                          }}
                          size="small"
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {fileUploadStatus === "UPLOADED" ? (
          <div className="ml-auto mt-2">
            <PrimaryButton
              title={i18n.t("continue")}
              size="small"
              onClick={async () => {
                await handleMoveSingleFileUpload(uploadedFile);
                setUploadFile(null);
                setFileUploadStatus(null);
              }}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const RenderAfterParseContent = () => {
    if (!externalExamAnswerFile) return;
    const { evaluationStatus } = externalExamDTO;
    const { fileOriginalName, fileName } = externalExamAnswerFile;
    const currentFileName = retrieveFileNameFromPageNumber();
    const evaluationExists =
      externalActivityEvaluationDTOList.find(
        (externalActivityEvaluationDTO) =>
          externalActivityEvaluationDTO.externalExamAnswerFileName ===
          currentFileName
      ) !== undefined;
    const splittedFileName = fileName.split(".");
    const pureFileName = splittedFileName[0];
    return (
      <div className="w-full">
        <div className="flex-1 flex flex-row flex-wrap justify-between items-center">
          <div className="w-full lg:flex-1">
            {filteredNotEvalutedPageNumberList.length > 0 ? (
              <div className="flex flex-col">
                <div>
                  <p>{i18n.t("not_evaluated_page_numbers")}</p>
                </div>
                <div className="flex flex-row flex-wrap gap-x-1">
                  {filteredNotEvalutedPageNumberList.map(
                    (notEvaluatedPageNumber, index) => (
                      <div key={index}>
                        <p className="text-catchup-red">
                          {notEvaluatedPageNumber + 1}
                          {index !==
                          filteredNotEvalutedPageNumberList.length - 1
                            ? ", "
                            : ""}
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : null}
          </div>
          <PageTravelGroup
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            imageReady={imageReady}
            setImageReady={setImageReady}
            isImageProcessing={false}
            initialTotalPageNumber={initialTotalPageNumber}
          />
          {evaluationStatus === "NOT_EVALUATED" ? (
            <div className="flex-1 flex justify-end">
              <PrimaryButton
                title={i18n.t("evaluate")}
                size="unlimited"
                onClick={handleEvaluateExternalExamOnClick}
              />
            </div>
          ) : evaluationStatus === "EVALUATING" ? (
            <div className="flex-1 flex justify-end">
              <p className="font-semibold text-catchup-blue">
                {i18n.t("external_exam_evaluation_has_begun")}
              </p>
            </div>
          ) : evaluationStatus === "EVALUATED" ? (
            <div className="flex-1 flex justify-end">
              <p className="font-semibold text-catchup-dark-blue">
                {i18n.t("external_exam_has_evaluated")}
              </p>
            </div>
          ) : (
            <div className="flex-1 flex justify-end"></div>
          )}
        </div>

        {fileOriginalName && pureFileName && convertedFileList.length > 0 ? (
          <div className="my-4">
            <div className="w-full relative" key={pageNumber}>
              {externalActivityEvaluationDTOListLoading ? (
                <div className="absolute left-1/2 -translate-x-1/2 top-20 z-10">
                  <BaseLoading
                    size="small"
                    color="#57C2D3"
                    secondaryColor="#57C2D3"
                  />
                </div>
              ) : evaluationStatus === "EVALUATED" ? (
                evaluationExists ? (
                  <div className="absolute left-1/2 -translate-x-1/2 top-20 z-10">
                    <p className="text-xl font-bold text-catchup-dark-blue">
                      {i18n.t("evaluated")}
                    </p>
                  </div>
                ) : (
                  <div className="absolute left-1/2 -translate-x-1/2 top-20 z-10">
                    <div className="mx-2">
                      <CreateButton
                        title={i18n.t("reevaluate")}
                        size="unlimited"
                        onClick={handleEvaluateSingleExternalExamOnClick}
                      />
                    </div>
                  </div>
                )
              ) : null}
              <div
                className={`${
                  evaluationStatus === "EVALUATED"
                    ? "opacity-10"
                    : "opacity-100"
                } w-full`}
              >
                {showDocumentByPathAndFileName(
                  "FULL_WIDTH_IMAGE",
                  `/${userProfileBrand.id}/pdfs/${pureFileName}/${pageNumber}`,
                  currentFileName,
                  handleOnImageLoad
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="my-4">
            <BaseLoading
              size="large"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          </div>
        )}
      </div>
    );
  };

  const RenderMainContent = () => {
    return (
      <FullCard isShadowed={true}>
        <div className="">
          <div className="flex flex-row items-center gap-x-2">
            <div className="cursor-pointer">
              <BaseImage
                size="medium"
                src="/icons/long-arrow-left.png"
                alt="long-arrow-left"
                onClick={() => {
                  navigate("/external-exams");
                }}
              />
            </div>
            <BaseTitle
              title={i18n.t("create_external_exam_answers_from_pdf")}
            />
          </div>
          <DividerLine />
          {externalExamAnswerFile
            ? RenderAfterParseContent()
            : RenderBeforeParseContent()}
        </div>
      </FullCard>
    );
  };

  const RenderShowUploadStatusModal = () => {
    if (fileMoveStatus === "MOVING") {
      return (
        <BaseModal
          isOpen={true}
          size="small"
          onAfterOpen={() => {}}
          onRequestClose={() => {}}
        >
          <div className="flex-1 flex flex-col">
            <FullCard>
              <p className="text-xl text-center">
                {i18n.t("upload_status_file_moving")}
              </p>
            </FullCard>
          </div>
        </BaseModal>
      );
    } else if (fileMoveStatus === "MOVED") {
      return (
        <BaseModal
          isOpen={true}
          size="small"
          onAfterOpen={() => {}}
          onRequestClose={() => {}}
        >
          <div className="flex-1 flex flex-col">
            <FullCard>
              <p className="text-xl text-center">
                {i18n.t("upload_status_file_moved")}
              </p>
            </FullCard>
          </div>
        </BaseModal>
      );
    } else if (fileParseStatus === "PARSING") {
      return (
        <BaseModal
          isOpen={true}
          size="small"
          onAfterOpen={() => {}}
          onRequestClose={() => {}}
        >
          <div className="flex-1 flex flex-col">
            <FullCard>
              <div className="flex flex-col items-center justify-center gap-y-4">
                <div className="flex flex-row items-center justify-center">
                  <div className="mx-2">
                    <Dna
                      visible={true}
                      height="32"
                      width="32"
                      ariaLabel="dna-loading"
                      wrapperStyle={{}}
                      wrapperClass="dna-wrapper"
                    />
                  </div>
                  <div>
                    <p className="text-xl  text-center">
                      {i18n.t("upload_status_file_parsing")}
                    </p>
                  </div>
                  <div className="mx-2">
                    <Dna
                      visible={true}
                      height="32"
                      width="32"
                      ariaLabel="dna-loading"
                      wrapperStyle={{}}
                      wrapperClass="dna-wrapper"
                    />
                  </div>
                </div>
                <div className="my-2 text-catchup-gray-400">
                  {SingleInformationIconTextItem(
                    i18n.t("upload_status_file_parsing_description_text_1")
                  )}
                  {SingleInformationIconTextItem(
                    i18n.t("upload_status_file_parsing_description_text_2")
                  )}
                  {SingleInformationIconTextItem(
                    i18n.t("upload_status_file_parsing_description_text_3")
                  )}
                </div>
              </div>
            </FullCard>
          </div>
        </BaseModal>
      );
    } else if (fileParseStatus === "PARSED") {
      return (
        <BaseModal
          isOpen={true}
          size="small"
          onAfterOpen={() => {}}
          onRequestClose={() => {}}
        >
          <div className="flex-1 flex flex-col">
            <FullCard>
              <p className="text-xl  text-center">
                {i18n.t("upload_status_file_parsed")}
              </p>
            </FullCard>
          </div>
        </BaseModal>
      );
    }
  };

  const filteredNotEvalutedPageNumberList = filterNotEvalutedPageNumberList();

  return (
    <div className="flex-1 flex flex-col">
      {RenderShowUploadStatusModal()}
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ExternalExamAnswerListScreen;
