import FullCard from "../cards/FullCard";
import i18n from "../../language/i18n";
import PrimaryButton from "../buttons/PrimaryButton";
import DividerLine from "../divider/DividerLine";
import BaseTitle from "../titles/BaseTitle";
import BaseLoading from "../loading/BaseLoading";

const GeneralCatchtivityReportDetail = (props) => {
  const {
    isShadowed,
    usePadding,
    showTitle,
    reportDetailList,
    reportDetailLoading,
    subType,
    handleGeneratePDF,
  } = props;

  const RenderRecursivelyCategoryList = (
    categoryActivityAnswerDTO,
    recursiveCount
  ) => {
    let score = 0;
    for (const activityScoreDTO of categoryActivityAnswerDTO.activityScoreDTOSet) {
      score += activityScoreDTO.score;
    }
    return (
      <div className="flex flex-col">
        <div
          className="flex-1 flex flex-row bg-catchup-dark-blue text-catchup-white border-b border-b-catchup-white"
          style={{
            paddingLeft: recursiveCount * 20,
            opacity: 1 - 0.05 * recursiveCount,
          }}
        >
          <div className="flex-1">
            <p className="p-2">{categoryActivityAnswerDTO.categoryDTO.name}</p>
          </div>
          <div
            className="flex justify-center items-center w-[75px] bg-catchup-white text-catchup-dark-blue border-r border-b border-catchup-dark-blue"
            style={{
              fontWeight: "bold",
            }}
          >
            <p className="p-2">
              {categoryActivityAnswerDTO.activityScoreDTOSet.length}
            </p>
          </div>
          <div
            className="flex justify-center items-center w-[75px] bg-catchup-white text-catchup-dark-blue border-r border-b border-catchup-dark-blue text-center"
            style={{
              fontWeight: "bold",
            }}
          >
            <p className="p-2">{score.toFixed(2)}</p>
          </div>
          <div
            className="flex justify-center items-center w-[100px] bg-catchup-white text-catchup-dark-blue border-r border-b border-catchup-dark-blue text-center"
            style={{
              fontWeight: "bold",
            }}
          >
            <p className="p-2">
              {(
                (score / categoryActivityAnswerDTO.activityScoreDTOSet.length) *
                100
              ).toFixed(2)}
              %
            </p>
          </div>
        </div>
        {categoryActivityAnswerDTO.subCategoryActivityAnswerDTOList &&
        categoryActivityAnswerDTO.subCategoryActivityAnswerDTOList.length > 0
          ? categoryActivityAnswerDTO.subCategoryActivityAnswerDTOList.map(
              (subCategoryActivityAnswerDTO) =>
                RenderRecursivelyCategoryList(
                  subCategoryActivityAnswerDTO,
                  recursiveCount + 1
                )
            )
          : null}
      </div>
    );
  };

  const RenderCommonCatchtivityReport = (reportDetail) => {
    return (
      <>
        <DividerLine />
        <div className="w-full flex flex-col my-5 text-md">
          {reportDetail.categoryActivityAnswerDTOList.map(
            (categoryActivityAnswerDTO) => (
              <>
                <div className="flex flex-col">
                  <div className="flex-1 flex flex-row bg-catchup-white font-semibold">
                    <div className="flex-1"></div>
                    <div className="w-[75px] bg-catchup-dark-blue text-catchup-white border-r border-r-catchup-white text-center">
                      <p className="p-2">#</p>
                    </div>
                    <div className="w-[75px] bg-catchup-dark-blue text-catchup-white border-r border-r-catchup-white text-center">
                      <p className="p-2">✓</p>
                    </div>
                    <div className="w-[100px] bg-catchup-dark-blue text-catchup-white border-r border-r-catchup-dark-blue text-center">
                      <p className="p-2">%</p>
                    </div>
                  </div>
                </div>
                {RenderRecursivelyCategoryList(categoryActivityAnswerDTO, 0)}
              </>
            )
          )}
        </div>
      </>
    );
  };

  const RenderBranchCatchtivityReport = (reportDetail) => {
    return <>{RenderCommonCatchtivityReport(reportDetail)}</>;
  };

  const RenderGradeCatchtivityReport = (reportDetail) => {
    return <>{RenderCommonCatchtivityReport(reportDetail)}</>;
  };

  const RenderSeasonCatchtivityReport = (reportDetail) => {
    return <>{RenderCommonCatchtivityReport(reportDetail)}</>;
  };

  const RenderReportContent = (reportDetail) => {
    if (subType === "BRANCH") {
      return RenderBranchCatchtivityReport(reportDetail);
    } else if (subType === "GRADE") {
      return RenderGradeCatchtivityReport(reportDetail);
    } else if (subType === "SEASON") {
      return RenderSeasonCatchtivityReport(reportDetail);
    }
  };

  const RenderMainContent = () => {
    if (reportDetailLoading) {
      return (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      );
    } else {
      if (reportDetailList.length > 0) {
        return (
          <>
            {showTitle ? (
              <>
                <div className="">
                  <BaseTitle title={i18n.t("report_details")} />
                </div>
                <DividerLine />
              </>
            ) : null}
            {reportDetailList.length > 0 ? (
              <div className="my-2 ml-auto">
                <PrimaryButton
                  title={i18n.t("generate_pdf")}
                  size="unlimited"
                  onClick={handleGeneratePDF}
                />
              </div>
            ) : null}

            {reportDetailList.map((reportDetail, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center"
              >
                <div className="w-full flex flex-row flex-wrap items-center">
                  <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                    <div className="w-catchup-input-group-title py-2">
                      <p className="font-semibold">{i18n.t("type")}</p>
                    </div>
                    <div className="flex-1">
                      <p>{i18n.t(subType)}</p>
                    </div>
                  </div>
                  {reportDetail.seasonGradeBranchMap ? (
                    <>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("season_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonGradeBranchMap.seasonName}</p>
                        </div>
                      </div>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("grade_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonGradeBranchMap.gradeName}</p>
                        </div>
                      </div>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("branch_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonGradeBranchMap.branchName}</p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {reportDetail.seasonGradeMap ? (
                    <>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("season_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonGradeMap.seasonName}</p>
                        </div>
                      </div>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("grade_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonGradeMap.gradeName}</p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {reportDetail.seasonMap ? (
                    <>
                      <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row items-center">
                        <div className="w-catchup-input-group-title py-2">
                          <p className="font-semibold">
                            {i18n.t("season_name")}
                          </p>
                        </div>
                        <div className="flex-1">
                          <p>{reportDetail.seasonMap.seasonName}</p>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="w-full">
                  {RenderReportContent(reportDetail)}
                </div>
              </div>
            ))}
          </>
        );
      }
    }
  };

  return <div className="flex-1 flex flex-col">{RenderMainContent()}</div>;
};

export default GeneralCatchtivityReportDetail;
