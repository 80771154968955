import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import i18n from "../../language/i18n";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import { Dna } from "react-loader-spinner";
import { checkManagementService } from "../../requests/ManagementRequests";
import { checkCatchtivityService } from "../../requests/CatchtivityRequests";
import { checkNotificationService } from "../../requests/NotificationRequests";
import { checkReportService } from "../../requests/ReportRequests";
import { checkCategoryService } from "../../requests/CategoryRequests";
import { checkCrontaskService } from "../../requests/CrontaskRequests";
import { checkEmailService } from "../../requests/EmailRequests";
import { checkLabelService } from "../../requests/LabelRequests";
import { checkStorageService } from "../../requests/StorageRequests";
import { checkFileConverterService } from "../../requests/FileConverterRequests";
import { checkFileActivityParserService } from "../../requests/FileActivityParserRequests";
import { checkPlanService } from "../../requests/PlanRequests";
import { useApp } from "../../context/AppContextProvider";
import FullCard from "../../components/cards/FullCard";
import { checkBundleService } from "../../requests/BundleRequests";
import { checkMediaCompressionService } from "../../requests/MediaCompressionRequests";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import { checkTokenService } from "../../requests/TokenRequests";

const ServerDiagnosticsScreen = (props) => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [checkMap, setCheckMap] = useState({
    management: null,
    catchtivity: null,
    report: null,
    notification: null,
    category: null,
    crontask: null,
    email: null,
    label: null,
    storage: null,
    bundle: null,
    fileConverter: null,
    fileActivityParser: null,
    plan: null,
    mediaCompression: null,
    token: null,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("server_diagnostics")) {
      navigate("/permission-error");
    }
  }, []);

  const checkManagement = async () => {
    setCheckMap((prevErrorMap) => ({
      ...prevErrorMap,
      management: "WAITING",
    }));
    const { status } = await checkManagementService();
    if (status === 200) {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        management: "UP",
      }));
    } else {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        management: "DOWN",
      }));
    }
  };

  const checkCatchtivity = async () => {
    setCheckMap((prevErrorMap) => ({
      ...prevErrorMap,
      catchtivity: "WAITING",
    }));
    const { status } = await checkCatchtivityService();
    if (status === 200) {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        catchtivity: "UP",
      }));
    } else {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        catchtivity: "DOWN",
      }));
    }
  };

  const checkReport = async () => {
    setCheckMap((prevErrorMap) => ({
      ...prevErrorMap,
      report: "WAITING",
    }));
    const { status } = await checkReportService();
    if (status === 200) {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        report: "UP",
      }));
    } else {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        report: "DOWN",
      }));
    }
  };

  const checkNotification = async () => {
    setCheckMap((prevErrorMap) => ({
      ...prevErrorMap,
      notification: "WAITING",
    }));
    const { status } = await checkNotificationService();
    if (status === 200) {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        notification: "UP",
      }));
    } else {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        notification: "DOWN",
      }));
    }
  };

  const checkCategory = async () => {
    setCheckMap((prevErrorMap) => ({
      ...prevErrorMap,
      category: "WAITING",
    }));
    const { status } = await checkCategoryService();
    if (status === 200) {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        category: "UP",
      }));
    } else {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        category: "DOWN",
      }));
    }
  };

  const checkCrontask = async () => {
    setCheckMap((prevErrorMap) => ({
      ...prevErrorMap,
      crontask: "WAITING",
    }));
    const { status } = await checkCrontaskService();
    if (status === 200) {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        crontask: "UP",
      }));
    } else {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        crontask: "DOWN",
      }));
    }
  };

  const checkEmail = async () => {
    setCheckMap((prevErrorMap) => ({
      ...prevErrorMap,
      email: "WAITING",
    }));
    const { status } = await checkEmailService();
    if (status === 200) {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        email: "UP",
      }));
    } else {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        email: "DOWN",
      }));
    }
  };

  const checkLabel = async () => {
    setCheckMap((prevErrorMap) => ({
      ...prevErrorMap,
      label: "WAITING",
    }));
    const { status } = await checkLabelService();
    if (status === 200) {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        label: "UP",
      }));
    } else {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        label: "DOWN",
      }));
    }
  };

  const checkStorage = async () => {
    setCheckMap((prevErrorMap) => ({
      ...prevErrorMap,
      storage: "WAITING",
    }));
    const { status } = await checkStorageService();
    if (status === 200) {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        storage: "UP",
      }));
    } else {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        storage: "DOWN",
      }));
    }
  };

  const checkBundle = async () => {
    setCheckMap((prevErrorMap) => ({
      ...prevErrorMap,
      bundle: "WAITING",
    }));
    const { status } = await checkBundleService();
    if (status === 200) {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        bundle: "UP",
      }));
    } else {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        bundle: "DOWN",
      }));
    }
  };

  const checkFileConverter = async () => {
    setCheckMap((prevErrorMap) => ({
      ...prevErrorMap,
      fileConverter: "WAITING",
    }));
    const { status } = await checkFileConverterService();
    if (status === 200) {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        fileConverter: "UP",
      }));
    } else {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        fileConverter: "DOWN",
      }));
    }
  };

  const checkFileActivityParser = async () => {
    setCheckMap((prevErrorMap) => ({
      ...prevErrorMap,
      fileActivityParser: "WAITING",
    }));
    const { status } = await checkFileActivityParserService();
    if (status === 200) {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        fileActivityParser: "UP",
      }));
    } else {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        fileActivityParser: "DOWN",
      }));
    }
  };

  const checkPlan = async () => {
    setCheckMap((prevErrorMap) => ({
      ...prevErrorMap,
      plan: "WAITING",
    }));
    const { status } = await checkPlanService();
    if (status === 200) {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        plan: "UP",
      }));
    } else {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        plan: "DOWN",
      }));
    }
  };

  const checkMediaCompression = async () => {
    setCheckMap((prevErrorMap) => ({
      ...prevErrorMap,
      mediaCompression: "WAITING",
    }));
    const { status } = await checkMediaCompressionService();
    if (status === 200) {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        mediaCompression: "UP",
      }));
    } else {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        mediaCompression: "DOWN",
      }));
    }
  };

  const checkToken = async () => {
    setCheckMap((prevErrorMap) => ({
      ...prevErrorMap,
      token: "WAITING",
    }));
    const { status } = await checkTokenService();
    if (status === 200) {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        token: "UP",
      }));
    } else {
      setCheckMap((prevErrorMap) => ({
        ...prevErrorMap,
        token: "DOWN",
      }));
    }
  };

  const handleCheckOnClick = () => {
    checkManagement();
    checkCatchtivity();
    checkReport();
    checkNotification();
    checkCategory();
    checkCrontask();
    checkEmail();
    checkLabel();
    checkStorage();
    checkBundle();
    checkFileConverter();
    checkFileActivityParser();
    checkPlan();
    checkMediaCompression();
    checkToken();
  };

  const RenderStatusIcon = (status) => {
    if (status === "UP") {
      return (
        <BaseImage size="small" src="/icons/checkbox.png" alt="checkbox" />
      );
    } else if (status === "DOWN") {
      return (
        <BaseImage size="small" src="/icons/cross-red.png" alt="cross-red" />
      );
    } else if (status === "WAITING") {
      return (
        <Dna
          visible={true}
          height="24"
          width="24"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      );
    } else {
      return null;
    }
  };

  const RenderSingleService = (name, status) => {
    return (
      <>
        <div className="flex flex-row justify-between my-2">
          <div className="">
            <p>{name}</p>
          </div>
          <div className="">{RenderStatusIcon(status)}</div>
        </div>
        <DividerLine />
      </>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">
        <FullCard isShadowed={true}>
          <div className="my-4">
            {RenderSingleService(
              i18n.t("management_service"),
              checkMap.management
            )}
            {RenderSingleService(
              i18n.t("catchtivity_service"),
              checkMap.catchtivity
            )}
            {RenderSingleService(i18n.t("report_service"), checkMap.report)}
            {RenderSingleService(
              i18n.t("notification_service"),
              checkMap.notification
            )}
            {RenderSingleService(i18n.t("category_service"), checkMap.category)}
            {RenderSingleService(i18n.t("crontask_service"), checkMap.crontask)}
            {RenderSingleService(i18n.t("email_service"), checkMap.email)}
            {RenderSingleService(i18n.t("label_service"), checkMap.label)}
            {RenderSingleService(i18n.t("storage_service"), checkMap.storage)}
            {RenderSingleService(i18n.t("bundle_service"), checkMap.bundle)}
            {RenderSingleService(
              i18n.t("file_converter_service"),
              checkMap.fileConverter
            )}
            {RenderSingleService(
              i18n.t("file_activity_parser_service"),
              checkMap.fileActivityParser
            )}
            {RenderSingleService(i18n.t("plan_service"), checkMap.plan)}
            {RenderSingleService(
              i18n.t("media_compression_service"),
              checkMap.mediaCompression
            )}
            {RenderSingleService(i18n.t("token_service"), checkMap.token)}
          </div>
          <div className="mt-4 ml-auto">
            <PrimaryButton
              title={i18n.t("check")}
              size="small"
              onClick={handleCheckOnClick}
            />
          </div>
        </FullCard>
      </div>
    </div>
  );
};

export default ServerDiagnosticsScreen;
