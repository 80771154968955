import { useEffect, useState } from "react";
import FullCard from "../../components/cards/FullCard";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  createAnnouncementActionByAnnouncementId,
  queryStaffAnnouncementDTOList,
} from "../../requests/NotificationRequests";
import DividerLine from "../../components/divider/DividerLine";
import BaseImage from "../../components/images/BaseImage";
import { useApp } from "../../context/AppContextProvider";

const MyAnnouncementListScreen = () => {
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    language,
    userInformation,
    userProfile,
    numberOfUnreadAnnouncements,
    setNumberOfUnreadAnnouncements,
  } = useAuth();
  const [announcementDTOList, setAnnouncementDTOList] = useState([]);

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetAnnouncementDTOList = async () => {
      const brandId = localStorage.getItem("brandId");
      const campusId = localStorage.getItem("campusId");
      const institutionId = localStorage.getItem("institutionId");
      const seasonId = localStorage.getItem("seasonId");
      const gradeId = localStorage.getItem("gradeId");
      const branchId = localStorage.getItem("branchId");
      const { data, err } = await queryStaffAnnouncementDTOList({
        userId: userInformation.id,
        userProfileId: userProfile.id,
        coterieType: userProfile.coterieType,
        brandId,
        campusId,
        institutionId,
        seasonId,
        gradeId,
        branchId,
      });
      if (err) {
      } else {
        setAnnouncementDTOList(
          JSON.parse(
            JSON.stringify(data.sort((a, b) => b.createdAt - a.createdAt))
          )
        );
      }
    };
    retrieveAndSetAnnouncementDTOList();
  }, []);

  const retrieveCreatedForText = (type) => {
    if (type === "USER") {
      return i18n.t("announcement_created_for_user");
    } else if (type === "USER_PROFILE") {
      return i18n.t("announcement_created_for_user_profile");
    } else if (type === "BRAND") {
      return i18n.t("announcement_created_for_brand");
    } else if (type === "CAMPUS") {
      return i18n.t("announcement_created_for_campus");
    } else if (type === "INSTITUTION") {
      return i18n.t("announcement_created_for_institution");
    } else if (type === "SEASON") {
      return i18n.t("announcement_created_for_season");
    } else if (type === "GRADE") {
      return i18n.t("announcement_created_for_grade");
    } else if (type === "BRANCH") {
      return i18n.t("announcement_created_for_branch");
    }
  };

  const handleMarkAsReadOnClick = async (announcement) => {
    const { data, err } = await createAnnouncementActionByAnnouncementId({
      userId: userInformation.id,
      userProfileId: userProfile.id,
      announcement: {
        id: announcement.id,
        type: announcement.type,
      },
      type: "READ",
    });
    if (err) {
      console.log(err);
    } else {
      const foundIndex = announcementDTOList.findIndex(
        (announcementDTO) =>
          parseFloat(announcementDTO.id) === parseFloat(announcement.id)
      );
      announcementDTOList[foundIndex].actionType = "READ";
      setAnnouncementDTOList(JSON.parse(JSON.stringify(announcementDTOList)));
      setNumberOfUnreadAnnouncements(numberOfUnreadAnnouncements - 1);
    }
  };

  const RenderSingleAnnouncementItem = (announcement) => {
    return (
      <div className={`p-4`}>
        <div className={`flex flex-row justify-between`}>
          <div className="m-2">
            <BaseImage
              src="/icons/announcement.png"
              alt="announcement"
              size="medium"
            />
          </div>
          <div className="flex-1 flex flex-col">
            <div className="flex-1 flex flex-col mr-5">
              <div className="my-2 flex flex-row items-end">
                <p
                  className={`${
                    announcement.actionType === "READ"
                      ? "font-normal"
                      : "font-semibold"
                  } text-xl`}
                >
                  {i18n.t(announcement.name)}
                </p>
                <p
                  className={`${
                    announcement.actionType === "READ"
                      ? "font-normal"
                      : "font-semibold"
                  } ml-2 text-sm italic`}
                >
                  ({retrieveCreatedForText(announcement.type)})
                </p>
              </div>
              <div
                className={`${
                  announcement.actionType === "READ"
                    ? "font-normal"
                    : "font-semibold"
                } my-2`}
              >
                <p className="">{i18n.t(announcement.description)}</p>
              </div>
              <div className="mt-auto flex flex-col justify-between">
                <p
                  className={`${
                    announcement.actionType === "READ"
                      ? "font-normal"
                      : "font-semibold"
                  } text-sm text-catchup-gray`}
                >
                  {new Date(announcement.createdAt).toLocaleString(language)}
                </p>
                {announcement.actionType !== "READ" ? (
                  <p
                    className="text-sm underline text-catchup-orange cursor-pointer"
                    onClick={() => {
                      handleMarkAsReadOnClick(announcement);
                    }}
                  >
                    {i18n.t("mark_as_read")}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          {announcement.documentType === "IMAGE" ||
          announcement.documentType === "VIDEO" ||
          announcement.documentType === "AUDIO" ? (
            <div className="w-[300px]">
              {announcement.documentType === "IMAGE" ? (
                <BaseImage
                  size="custom"
                  className={"w-full h-auto rounded-catchup-xlarge"}
                  src={announcement.documentUrl}
                  alt="announcement-document"
                />
              ) : announcement.documentType === "VIDEO" ? (
                <video
                  src={announcement.documentUrl}
                  className={"w-full h-auto rounded-catchup-xlarge"}
                  controls
                />
              ) : announcement.documentType === "AUDIO" ? (
                <audio
                  src={announcement.documentUrl}
                  className={"w-full h-auto rounded-catchup-xlarge"}
                  controls
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const RenderMainContent = () => {
    return (
      <FullCard isShadowed={true}>
        <div className="mb-5">
          <div className="my-5">
            {announcementDTOList.map((announcement) => (
              <div key={announcement.id}>
                {RenderSingleAnnouncementItem(announcement)}
                <DividerLine />
              </div>
            ))}
          </div>
        </div>
      </FullCard>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default MyAnnouncementListScreen;
