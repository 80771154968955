import i18n from "../language/i18n";

export const retrieveColorByScore = (score) => {
  if (score <= 25) {
    return "#F96666";
  } else if (score > 25 && score <= 50) {
    return "#f98d66";
  } else if (score > 50 && score <= 75) {
    return "#cbd357";
  } else {
    return "#ABD357";
  }
};

export const retrieveContentTypeOptionList = (textOnly) => {
  if (textOnly) {
    return [
      {
        id: "TEXT",
        value: "TEXT",
        text: i18n.t("TEXT"),
        icon: "/icons/text-gray.png",
      },
    ];
  } else {
    return [
      {
        id: "TEXT",
        value: "TEXT",
        text: i18n.t("TEXT"),
        icon: "/icons/text-gray.png",
      },
      {
        id: "IMAGE",
        value: "IMAGE",
        text: i18n.t("IMAGE"),
        icon: "/icons/image-gray.png",
      },
      {
        id: "VIDEO",
        value: "VIDEO",
        text: i18n.t("VIDEO"),
        icon: "/icons/video-gray.png",
      },
      {
        id: "AUDIO",
        value: "AUDIO",
        text: i18n.t("AUDIO"),
        icon: "/icons/audio-gray.png",
      },
    ];
  }
};

export const retrieveStatusOptionList = () => {
  return [
    {
      value: "ACTIVE",
      text: i18n.t("ACTIVE"),
    },
    {
      value: "PASSIVE",
      text: i18n.t("PASSIVE"),
    },
  ];
};

export const retrieveDurationTypeOptionList = () => {
  return [
    { value: "NONE", text: i18n.t("NONE") },
    { value: "ALL", text: i18n.t("ALL") },
    { value: "EACH", text: i18n.t("EACH") },
  ];
};

export const retrieveDurationInMinutesOptionList = (durationType) => {
  if (durationType === "ALL") {
    return [
      { value: 15, text: 15 },
      { value: 30, text: 30 },
      { value: 45, text: 45 },
      { value: 60, text: 60 },
      { value: 75, text: 75 },
      { value: 90, text: 90 },
      { value: 105, text: 105 },
      { value: 120, text: 120 },
    ];
  } else if (durationType === "EACH") {
    return [
      { value: 1, text: 1 },
      { value: 2, text: 2 },
      { value: 3, text: 3 },
      { value: 5, text: 5 },
      { value: 10, text: 10 },
    ];
  }
  return [];
};

export const retrieveDurationInSecondsOptionList = () => {
  return [
    { value: 15, text: `15 ${i18n.t("seconds")}` },
    { value: 20, text: `20 ${i18n.t("seconds")}` },
    { value: 25, text: `25 ${i18n.t("seconds")}` },
    { value: 30, text: `30 ${i18n.t("seconds")}` },
    { value: 35, text: `35 ${i18n.t("seconds")}` },
    { value: 40, text: `40 ${i18n.t("seconds")}` },
    { value: 45, text: `45 ${i18n.t("seconds")}` },
  ];
};

export const retrieveTaxonomyType = () => {
  return [
    {
      value: "BLOOM",
      text: i18n.t("BLOOM"),
    },
  ];
};

export const retrieveTaxonomyGroupName = () => {
  return [
    {
      type: "BLOOM",
      value: "BLOOM",
      text: i18n.t("BLOOM"),
    },
  ];
};

export const retrieveTaxonomyName = () => {
  return [
    {
      stage: 1,
      groupName: "BLOOM",
      value: "REMEMBER",
      text: i18n.t("REMEMBER"),
    },
    {
      stage: 2,
      groupName: "BLOOM",
      value: "UNDERSTAND",
      text: i18n.t("UNDERSTAND"),
    },
    {
      stage: 3,
      groupName: "BLOOM",
      value: "APPLY",
      text: i18n.t("APPLY"),
    },
    {
      stage: 4,
      groupName: "BLOOM",
      value: "ANALYZE",
      text: i18n.t("ANALYZE"),
    },
    {
      stage: 5,
      groupName: "BLOOM",
      value: "EVALUATE",
      text: i18n.t("EVALUATE"),
    },
    {
      stage: 6,
      groupName: "BLOOM",
      value: "CREATE",
      text: i18n.t("CREATE"),
    },
  ];
};

export const constructInputWithSpecialExpressionList = (inputText) => {
  const inputPartList = [];
  if (!inputText) return [];
  const splittedBold = inputText.split("**");
  let isBold = false;
  for (let i = 0; i < splittedBold.length; i++) {
    let isUnderline = false;
    const splittedUnderline = splittedBold[i].split("__");
    for (let j = 0; j < splittedUnderline.length; j++) {
      let isEquation = false;
      const splittedEquation = splittedUnderline[j].split("`");
      for (let k = 0; k < splittedEquation.length; k++) {
        inputPartList.push({
          value: splittedEquation[k],
          isEquation,
          isUnderline,
          isBold,
        });
        isEquation = !isEquation;
      }
      isUnderline = !isUnderline;
    }
    isBold = !isBold;
  }
  return inputPartList;
};

export const retrieveStandardExamTypeOptionList = () => {
  return [
    { value: "AYT", text: "AYT" },
    { value: "TYT", text: "TYT" },
    { value: "LGS", text: "LGS" },
  ];
};

export const retrieveStandardExamTypeIcon = (baseReportType) => {
  if (baseReportType === "TYT") {
    return "/icons/tyt.png";
  } else if (baseReportType === "AYT") {
    return "/icons/ayt.png";
  } else if (baseReportType === "LGS") {
    return "/icons/lgs.png";
  }
};

export const retrieveCoterieTypeFromStandardExamCoterieType = (
  standardExamType,
  standardExamCoterieType
) => {
  if (standardExamCoterieType === "TURKISH") {
    return "TURKISH";
  } else if (standardExamCoterieType === "SCIENCE") {
    return "SCIENCE";
  } else if (
    (standardExamType === "TYT" || standardExamType === "AYT") &&
    standardExamCoterieType === "PHYSICS"
  ) {
    return "PHYSICS";
  } else if (
    standardExamType === "LGS" &&
    standardExamCoterieType === "PHYSICS"
  ) {
    return "SOCIAL_STUDIES";
  } else if (
    (standardExamType === "TYT" || standardExamType === "AYT") &&
    standardExamCoterieType === "CHEMISTRY"
  ) {
    return "CHEMISTRY";
  } else if (
    standardExamType === "LGS" &&
    standardExamCoterieType === "CHEMISTRY"
  ) {
    return "SOCIAL_STUDIES";
  } else if (
    (standardExamType === "TYT" || standardExamType === "AYT") &&
    standardExamCoterieType === "BIOLOGY"
  ) {
    return "BIOLOGY";
  } else if (
    standardExamType === "LGS" &&
    standardExamCoterieType === "BIOLOGY"
  ) {
    return "SOCIAL_STUDIES";
  } else if (
    (standardExamType === "TYT" || standardExamType === "AYT") &&
    standardExamCoterieType === "HISTORY"
  ) {
    return "HISTORY";
  } else if (
    (standardExamType === "TYT" || standardExamType === "AYT") &&
    standardExamCoterieType === "GEOGRAPHY"
  ) {
    return "GEOGRAPHY";
  } else if (
    (standardExamType === "LGS" && standardExamCoterieType === "HISTORY") ||
    (standardExamType === "LGS" && standardExamCoterieType === "GEOGRAPHY") ||
    standardExamCoterieType === "REVOLUTION_HISTORY"
  ) {
    return "SOCIAL_STUDIES";
  } else if (
    standardExamCoterieType === "HISTORY_I" ||
    standardExamCoterieType === "HISTORY_II"
  ) {
    return "HISTORY";
  } else if (
    standardExamCoterieType === "GEOGRAPHY_I" ||
    standardExamCoterieType === "GEOGRAPHY_II"
  ) {
    return "GEOGRAPHY";
  } else if (
    standardExamCoterieType === "MATHEMATICS" ||
    standardExamCoterieType === "GEOMETRY"
  ) {
    return "MATHEMATICS";
  } else if (
    standardExamCoterieType === "PHILOSOPHY" ||
    standardExamCoterieType === "PHILOSOPHY_OR_CULTURE_AND_RELIGION_KNOWLEDGE"
  ) {
    return "PHILOSOPHY";
  } else if (
    (standardExamType === "TYT" || standardExamType === "AYT") &&
    standardExamCoterieType === "CULTURE_AND_RELIGION_KNOWLEDGE"
  ) {
    return "CULTURE_AND_RELIGION_KNOWLEDGE";
  } else if (
    standardExamType === "LGS" &&
    standardExamCoterieType === "CULTURE_AND_RELIGION_KNOWLEDGE"
  ) {
    return "CULTURE_AND_RELIGION_KNOWLEDGE";
  } else if (standardExamCoterieType === "ENGLISH") {
    return "ENGLISH";
  } else if (standardExamCoterieType === "LITERATURE") {
    return "LITERATURE";
  }
};

export const retrieveStandardExamCoterieTypeOptionListByStandardExamType = (
  standardExamType
) => {
  if (standardExamType === "TYT") {
    return retrieveStandardExamCoterieTypeOptionList().filter(
      (item) =>
        item.value === "TURKISH" ||
        item.value === "HISTORY" ||
        item.value === "GEOGRAPHY" ||
        item.value === "PHILOSOPHY" ||
        item.value === "CULTURE_AND_RELIGION_KNOWLEDGE" ||
        item.value === "MATHEMATICS" ||
        item.value === "GEOMETRY" ||
        item.value === "PHYSICS" ||
        item.value === "CHEMISTRY" ||
        item.value === "BIOLOGY"
    );
  } else if (standardExamType === "AYT") {
    return retrieveStandardExamCoterieTypeOptionList().filter(
      (item) =>
        item.value === "LITERATURE" ||
        item.value === "HISTORY_I" ||
        item.value === "HISTORY_II" ||
        item.value === "GEOGRAPHY_I" ||
        item.value === "GEOGRAPHY_II" ||
        item.value === "PHILOSOPHY" ||
        item.value === "PHILOSOPHY_OR_CULTURE_AND_RELIGION_KNOWLEDGE" ||
        item.value === "MATHEMATICS" ||
        item.value === "GEOMETRY" ||
        item.value === "PHYSICS" ||
        item.value === "CHEMISTRY" ||
        item.value === "BIOLOGY" ||
        item.value === "ENGLISH"
    );
  } else if (standardExamType === "LGS") {
    return retrieveStandardExamCoterieTypeOptionList().filter(
      (item) =>
        item.value === "TURKISH" ||
        item.value === "REVOLUTION_HISTORY" ||
        item.value === "CULTURE_AND_RELIGION_KNOWLEDGE" ||
        item.value === "MATHEMATICS" ||
        item.value === "SCIENCE" ||
        item.value === "ENGLISH"
    );
  } else return [];
};

const retrieveStandardExamCoterieTypeOptionList = () => {
  return [
    { value: "TURKISH", text: i18n.t("TURKISH") },
    { value: "SCIENCE", text: i18n.t("SCIENCE") },
    { value: "HISTORY", text: i18n.t("HISTORY") },
    { value: "GEOGRAPHY", text: i18n.t("GEOGRAPHY") },
    { value: "PHILOSOPHY", text: i18n.t("PHILOSOPHY") },
    {
      value: "CULTURE_AND_RELIGION_KNOWLEDGE",
      text: i18n.t("CULTURE_AND_RELIGION_KNOWLEDGE"),
    },
    { value: "MATHEMATICS", text: i18n.t("MATHEMATICS") },
    { value: "GEOMETRY", text: i18n.t("GEOMETRY") },
    { value: "PHYSICS", text: i18n.t("PHYSICS") },
    { value: "CHEMISTRY", text: i18n.t("CHEMISTRY") },
    { value: "BIOLOGY", text: i18n.t("BIOLOGY") },
    { value: "LITERATURE", text: i18n.t("LITERATURE") },
    { value: "HISTORY_I", text: i18n.t("HISTORY_I") },
    { value: "HISTORY_II", text: i18n.t("HISTORY_II") },
    { value: "GEOGRAPHY_I", text: i18n.t("GEOGRAPHY_I") },
    { value: "GEOGRAPHY_II", text: i18n.t("GEOGRAPHY_II") },
    {
      value: "PHILOSOPHY_OR_CULTURE_AND_RELIGION_KNOWLEDGE",
      text: i18n.t("PHILOSOPHY_OR_CULTURE_AND_RELIGION_KNOWLEDGE"),
    },
    { value: "ENGLISH", text: i18n.t("ENGLISH") },
    { value: "REVOLUTION_HISTORY", text: i18n.t("REVOLUTION_HISTORY") },
  ];
};

const retrieveValidationRequirementList_AYT = () => {
  return [
    {
      value: "LITERATURE",
      count: 24,
    },
    {
      value: "HISTORY_I",
      count: 10,
    },
    {
      value: "HISTORY_II",
      count: 11,
    },
    {
      value: "GEOGRAPHY_I",
      count: 6,
    },
    {
      value: "GEOGRAPHY_II",
      count: 11,
    },
    {
      value: "PHILOSOPHY",
      count: 12,
    },
    {
      value: "PHILOSOPHY_OR_CULTURE_AND_RELIGION_KNOWLEDGE",
      count: 6,
    },
    {
      value: "MATHEMATICS",
      count: 30,
    },
    {
      value: "GEOMETRY",
      count: 10,
    },
    {
      value: "PHYSICS",
      count: 14,
    },
    {
      value: "CHEMISTRY",
      count: 13,
    },
    {
      value: "BIOLOGY",
      count: 13,
    },
    {
      value: "ENGLISH",
      count: 80,
    },
  ];
};

const retrieveValidationRequirementList_TYT = () => {
  return [
    {
      value: "TURKISH",
      count: 40,
    },
    {
      value: "HISTORY",
      count: 5,
    },
    {
      value: "GEOGRAPHY",
      count: 5,
    },
    {
      value: "PHILOSOPHY",
      count: 5,
    },
    {
      value: "CULTURE_AND_RELIGION_KNOWLEDGE",
      count: 5,
    },
    {
      value: "MATHEMATICS",
      count: 30,
    },
    {
      value: "GEOMETRY",
      count: 10,
    },
    {
      value: "PHYSICS",
      count: 7,
    },
    {
      value: "CHEMISTRY",
      count: 7,
    },
    {
      value: "BIOLOGY",
      count: 6,
    },
  ];
};

const retrieveValidationRequirementList_LGS = () => {
  return [
    {
      value: "TURKISH",
      count: 20,
    },
    {
      value: "REVOLUTION_HISTORY",
      count: 10,
    },
    {
      value: "CULTURE_AND_RELIGION_KNOWLEDGE",
      count: 10,
    },
    {
      value: "MATHEMATICS",
      count: 20,
    },
    {
      value: "SCIENCE",
      count: 20,
    },
    {
      value: "ENGLISH",
      count: 10,
    },
  ];
};

export const retrieveValidationRequirementList = (selectedStandardExamType) => {
  if (selectedStandardExamType === "TYT") {
    return retrieveValidationRequirementList_TYT();
  } else if (selectedStandardExamType === "AYT") {
    return retrieveValidationRequirementList_AYT();
  } else if (selectedStandardExamType === "LGS") {
    return retrieveValidationRequirementList_LGS();
  }
  return [];
};

export const constructActivityItemListWithSolutionForAI = (
  bodyMap,
  materialMap,
  type,
  imageContentList
) => {
  const itemList = [];
  Object.keys(bodyMap).forEach((key) => {
    const bodyItem = bodyMap[key];
    if (bodyItem.type === "IMAGE") {
      if (imageContentList && imageContentList.length > 0) {
        const foundImageContent = imageContentList.find(
          (imageContent) => imageContent.url === bodyItem.value
        );
        itemList.push({
          type: "text",
          text: foundImageContent.content,
        });
      } else {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      }
    } else {
      itemList.push({
        type: "text",
        text: bodyItem.value.replaceAll("@@", "___________"),
      });
    }
  });
  if (type === "ORDERING") {
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_order")}: `,
      });
      Object.keys(materialMap).forEach((key) => {
        itemList.push({
          type: "text",
          text: materialMap[key],
        });
      });
    }
  } else if (type === "DROPDOWN") {
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_blanks")}: `,
      });
      Object.keys(materialMap).forEach((key, index) => {
        itemList.push({
          type: "text",
          text: `${i18n.t("blank")} ${index + 1}: ${
            Object.keys(materialMap[key])[0]
          }`,
        });
      });
    }
  } else if (type === "MCSA") {
    if (Object.keys(materialMap).length > 0) {
      const answer = Object.keys(materialMap)[0];
      materialMap[answer].forEach((option) => {
        itemList.push({
          type: "text",
          text: option,
        });
      });
      materialMap[answer].forEach((option) => {
        if (option === answer) {
          itemList.push({
            type: "text",
            text: `[${i18n.t("answer").toUpperCase()}]${option}`,
          });
        }
      });
    }
  } else if (type === "MCMA") {
    if (Object.keys(materialMap).length > 0) {
      const answer = Object.keys(materialMap)[0];
      materialMap[answer].forEach((option) => {
        itemList.push({
          type: "text",
          text: option,
        });
      });
      const splittedTextList = answer.split("§");
      materialMap[answer].forEach((option) => {
        if (splittedTextList.includes(option)) {
          itemList.push({
            type: "text",
            text: `[${i18n.t("answer").toUpperCase()}]${option}`,
          });
        }
      });
    }
  } else if (type === "MATCHING") {
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_matching")}: `,
      });
      Object.keys(materialMap).forEach((key, index) => {
        const matchingKey = Object.keys(materialMap[key])[0];
        const matchingValue = materialMap[key][matchingKey];
        itemList.push({
          type: "text",
          text: `${matchingKey}: ${matchingValue}`,
        });
      });
    }
  } else if (type === "GROUPING") {
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_grouping")}: `,
      });
      Object.keys(materialMap).forEach((key, index) => {
        let text = `${i18n.t("group")} ${index + 1}: `;
        itemList.push({
          type: "text",
          text: text,
        });

        const groupName = Object.keys(materialMap[key])[0];
        let currentText = "";
        materialMap[key][groupName].forEach((item) => {
          currentText += `${item}, `;
        });
        currentText = currentText.substring(0, currentText.length - 2);
        itemList.push({
          type: "text",
          text: `${groupName}: ${currentText}`,
        });
      });
    }
  } else if (type === "FILL_IN_THE_BLANKS") {
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_blanks")}: `,
      });
      Object.keys(materialMap).forEach((key, index) => {
        materialMap[key].forEach((item) => {
          itemList.push({
            type: "text",
            text: `${i18n.t("blank")} ${index + 1}: ${item}`,
          });
        });
      });
    }
  } else if (type === "OPEN_ENDED") {
  } else if (type === "TRUE_FALSE") {
    if (materialMap.trueList && Object.keys(materialMap.trueList).length > 0) {
      materialMap.trueList.forEach((item) => {
        itemList.push({
          type: "text",
          text: `[${i18n.t("correct").toUpperCase()}]${item}`,
        });
      });
    }

    if (
      materialMap.falseList &&
      Object.keys(materialMap.falseList).length > 0
    ) {
      materialMap.falseList.forEach((item) => {
        itemList.push({
          type: "text",
          text: `[${i18n.t("incorrect").toUpperCase()}]${item}`,
        });
      });
    }
  }

  return itemList;
};

export const constructActivityItemListWithAnswersForAI = (
  bodyMap,
  materialMap,
  type
) => {
  const itemList = [];
  if (type === "ORDERING") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_order")}: `,
      });
      Object.keys(materialMap).forEach((key) => {
        itemList.push({
          type: "text",
          text: materialMap[key],
        });
      });
    }
  } else if (type === "DROPDOWN") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value.replaceAll("@@", "___________"),
        });
      }
    });
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_blanks")}: `,
      });
      Object.keys(materialMap).forEach((key, index) => {
        itemList.push({
          type: "text",
          text: `${index + 1}. ${Object.keys(materialMap[key])[0]}`,
        });
      });
    }
  } else if (type === "MCSA") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_answer")}: `,
      });
      Object.keys(materialMap).forEach((key) => {
        itemList.push({
          type: "text",
          text: key,
        });
      });
    }
  } else if (type === "MCMA") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_answers")}: `,
      });
      const splittedTextList = Object.keys(materialMap)[0].split("§");
      splittedTextList.forEach((item) => {
        const parsedItemList = JSON.parse(item);
        for (const parsedItem of parsedItemList) {
          itemList.push({
            type: "text",
            text: parsedItem,
          });
        }
      });
    }
  } else if (type === "MATCHING") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_answers")}: `,
      });
      Object.keys(materialMap).forEach((key, index) => {
        itemList.push({
          type: "text",
          text: `${i18n.t("match")} ${index + 1}:`,
        });
        itemList.push({
          type: "text",
          text: `${key}: ${materialMap[key]}`,
        });
      });
    }
  } else if (type === "GROUPING") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_answers")}: `,
      });
      Object.keys(materialMap).forEach((key, index) => {
        let text = `${i18n.t("group")} ${index + 1}: `;
        itemList.push({
          type: "text",
          text: text,
        });

        const groupName = key;
        let currentText = "";
        materialMap[groupName].forEach((item) => {
          currentText += `${item}, `;
        });
        currentText = currentText.substring(0, currentText.length - 2);
        itemList.push({
          type: "text",
          text: `${groupName}: [${currentText}]`,
        });
      });
    }
  } else if (type === "FILL_IN_THE_BLANKS") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value.replaceAll("@@", "___________"),
        });
      }
    });
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_blanks")}: `,
      });
      Object.keys(materialMap).forEach((key, index) => {
        JSON.parse(materialMap[key]).forEach((item) => {
          itemList.push({
            type: "text",
            text: `${index + 1}. ${item}`,
          });
        });
      });
    }
  } else if (type === "OPEN_ENDED") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
  } else if (type === "TRUE_FALSE") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
    if (materialMap.trueList && Object.keys(materialMap.trueList).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_answers")}: `,
      });
      materialMap.trueList.forEach((item) => {
        itemList.push({
          type: "text",
          text: item,
        });
      });
    }
    if (
      materialMap.falseList &&
      Object.keys(materialMap.falseList).length > 0
    ) {
      itemList.push({
        type: "text",
        text: `${i18n.t("incorrect_answers")}: `,
      });
      materialMap.falseList.forEach((item) => {
        itemList.push({
          type: "text",
          text: item,
        });
      });
    }
  }

  return itemList;
};

export const retrieveActivityTemplateDTOOptionList = (activityTemplateSet) => {
  return activityTemplateSet.map((activityTemplateDTO) => ({
    id: activityTemplateDTO.id,
    value: activityTemplateDTO.id,
    text: i18n.t(activityTemplateDTO.type),
  }));
};
