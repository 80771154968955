import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InputGroup from "../../components/groups/InputGroup";
import i18n from "../../language/i18n";
import {
  patchUser,
  patchUserOnlyRequiredAction,
  queryAllUserDTOPageByFilter,
  randomizePassword,
} from "../../requests/ManagementRequests";
import {
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import {
  retrieveEnableOptionList,
  retrieveGenderOptionList,
} from "../../utilization/ManagementUtilization";
import { NotificationManager } from "react-notifications";
import { useApp } from "../../context/AppContextProvider";
import AdvancedTable from "../../components/tables/AdvancedTable";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import FullCard from "../../components/cards/FullCard";
import { useAuth } from "../../context/UserContextProvider";
import BaseModal from "../../components/modal/BaseModal";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import debounce from "lodash.debounce";
import CreateButton from "../../components/buttons/CreateButton";

const UserListScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userInformation,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfile,
  } = useAuth();
  const [pageState, setPageState] = useState(0);
  const [userDTOList, setUserDTOList] = useState([]);
  const [id, setId] = useState(null);
  const [username, setUsername] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [identityNumber, setIdentityNumber] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [selectedGender, setSelectedGender] = useState("DEFAULT_OPTION");
  const [selectedEnabled, setSelectedEnabled] = useState("DEFAULT_OPTION");
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterTextContains, setFilterTextContains] = useState(null);
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [userProfileRole, setUserProfileRole] = useState(null);
  const [showSearchAndAddUserModal, setShowSearchAndAddUserModal] =
    useState(false);
  const [debounceState, setDebounceState] = useState("");
  const [errorMap, setErrorMap] = useState({
    firstName: null,
    lastName: null,
    phoneNumber: null,
    dateOfBirth: null,
    gender: null,
    enabled: null,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("user_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    if (state) {
      const foundState = state.find((s) => s.screenName === "user_list");
      if (!foundState) return;
      const { pageNumber, itemCount } = foundState;
      setPageNumber(pageNumber);
      setSelectedShowItemCount(itemCount);
    } else {
      setPageNumber(0);
      setSelectedShowItemCount(25);
    }
  }, [state]);

  useEffect(() => {
    const pathRole = window.location.pathname.split("/")[1];
    if (pathRole === "learner-users") {
      setUserProfileRole("LEARNER");
    } else if (pathRole === "staff-users") {
      setUserProfileRole("STAFF");
    } else if (pathRole === "individual-users") {
      setUserProfileRole("INDIVIDUAL");
    } else if (pathRole === "content-creator-users") {
      setUserProfileRole("CONTENT_CREATOR");
    } else {
      setUserProfileRole("ADMIN");
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (userProfileRole) {
      retrieveAndSetUserPageByFilter();
    }
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
    userProfileRole,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    setDebounceState("BASE_FILTER");
    retrieveAndSetUserPageByFilter();
  }, [isFilterApplied, filterBeginAt, filterEndAt, filterShowDeleted]);

  useEffect(() => {
    if (!isFilterApplied) return;
    if (filterTextContains) {
      setDebounceState("TEXT_CONTAINS");
      debouncedRetrieveAndSetUserPageByFilterDebounce(filterTextContains);
    } else {
      retrieveAndSetUserPageByFilter();
    }
  }, [filterTextContains]);

  const retrieveAndSetUserPageByFilter = async () => {
    setTableLoading(true);
    const { data, err } = await queryAllUserDTOPageByFilter(pagedQueryParams);
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setUserDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const retrieveAndSetUserPageByFilterDebounce = async (filterTextContains) => {
    setTableLoading(true);
    if (isFilterApplied) {
      pagedQueryParams.params.textContains = filterTextContains.trim();
    }
    const { data, err } = await queryAllUserDTOPageByFilter(pagedQueryParams);
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setUserDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const debouncedRetrieveAndSetUserPageByFilterDebounce = useCallback(
    debounce(function (textContains) {
      retrieveAndSetUserPageByFilterDebounce(textContains);
    }, 1000),
    [debounceState]
  );

  const retrieveTitle = () => {
    if (userProfileRole === "LEARNER") {
      return i18n.t("learner_user_list");
    } else if (userProfileRole === "STAFF") {
      return i18n.t("staff_user_list");
    } else if (userProfileRole === "INDIVIDUAL") {
      return i18n.t("individual_user_list");
    } else if (userProfileRole === "CONTENT_CREATOR") {
      return i18n.t("content_creator_user_list");
    } else if (userProfileRole === "ADMIN") {
      return i18n.t("user_list");
    }
    return null;
  };

  const retrieveItemName = () => {
    if (userProfileRole === "LEARNER") {
      return i18n.t("learner");
    } else if (userProfileRole === "STAFF") {
      return i18n.t("staff");
    } else if (userProfileRole === "INDIVIDUAL") {
      return i18n.t("individual");
    } else if (userProfileRole === "CONTENT_CREATOR") {
      return i18n.t("content_creator");
    } else if (userProfileRole === "ADMIN") {
      return i18n.t("user");
    }
    return null;
  };

  const retrieveDescription = () => {
    if (userProfileRole === "LEARNER") {
      return i18n.t("learner_user_list_table_description_text");
    } else if (userProfileRole === "STAFF") {
      return i18n.t("staff_user_list_table_description_text");
    } else if (userProfileRole === "INDIVIDUAL") {
      return i18n.t("individual_user_list_table_description_text");
    } else if (userProfileRole === "CONTENT_CREATOR") {
      return i18n.t("content_creator_user_list_table_description_text");
    } else if (userProfileRole === "ADMIN") {
      return i18n.t("user_list_table_description_text");
    }
    return null;
  };

  const checkCommonValidation = () => {
    if (
      !firstName ||
      firstName.trim() === "" ||
      !lastName ||
      lastName.trim() === "" ||
      !identityNumber ||
      identityNumber.trim() === "" ||
      !phoneNumber ||
      phoneNumber.trim() === "" ||
      !dateOfBirth ||
      dateOfBirth.trim() === "" ||
      selectedGender === "DEFAULT_OPTION" ||
      selectedEnabled === "DEFAULT_OPTION"
    ) {
      const currentErrorMap = {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        gender: null,
        dateOfBirth: null,
        enabled: null,
      };
      if (!firstName || firstName.trim() === "") {
        currentErrorMap.firstName = i18n.t("first_name_required_field");
      }
      if (!lastName || lastName.trim() === "") {
        currentErrorMap.lastName = i18n.t("last_name_required_field");
      }
      if (!identityNumber || identityNumber.trim() === "") {
        currentErrorMap.identityNumber = i18n.t(
          "identity_number_required_field"
        );
      }
      if (!phoneNumber || phoneNumber.trim() === "") {
        currentErrorMap.phoneNumber = i18n.t("phone_number_required_field");
      }
      if (!dateOfBirth || dateOfBirth.trim() === "") {
        currentErrorMap.dateOfBirth = i18n.t("date_of_birth_required_field");
      }
      if (selectedGender === "DEFAULT_OPTION") {
        currentErrorMap.gender = i18n.t("gender_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const checkCanNavigate = () => {
    let canNavigate = true;
    if (!checkViewPermissionGranted("user_profile_list")) {
      canNavigate = false;
    }
    return canNavigate;
  };

  const constructNavigatableList = () => {
    let navigateList = [];
    if (checkViewPermissionGranted("user_profile_list")) {
      navigateList.push({
        id: 0,
        text: i18n.t("to_profile_set_screen"),
        icon: (
          <BaseImage
            src="/icons/navigate-gray.png"
            alt="navigate"
            size="small"
          />
        ),
        value: `/${window.location.pathname.split("/")[1]}/{id}/profiles`,
        params: [
          {
            screenName: "user_list",
            pageNumber,
            itemCount: selectedShowItemCount,
          },
        ],
      });
    }
    if (userInformation.accountType === "GENIXO") {
      if (checkUpdatePermissionGranted("user_list")) {
        navigateList.push({
          id: 1,
          text: i18n.t("change_state_to_profile_selection"),
          icon: (
            <BaseImage
              src="/icons/user-profile-2-gray.png"
              alt="user-profile"
              size="small"
            />
          ),
          onClick: (item) =>
            handlePatchUserRequiredActionOnClick(item.id, "PROFILE_SELECTION"),
        });
        navigateList.push({
          id: 2,
          text: i18n.t("randomize_password"),
          icon: (
            <BaseImage
              src="/icons/password-gray.png"
              alt="password-gray"
              size="small"
            />
          ),
          onClick: (item) => handleRandomizePasswordOnClick(item.id),
        });
        navigateList.push({
          id: 3,
          text: i18n.t("change_state_to_logout"),
          icon: <BaseImage src="/icons/logout-gray.png" size="small" />,
          onClick: (item) =>
            handlePatchUserRequiredActionOnClick(item.id, "LOGOUT"),
        });
      }
    }
    return navigateList;
  };

  const constructHeaderList = () => {
    const headerList = [
      { name: i18n.t("username"), key: "username" },
      { name: i18n.t("first_name"), key: "firstName" },
      { name: i18n.t("last_name"), key: "lastName" },
      { name: i18n.t("gender"), key: "gender", type: "enumeration" },
      {
        name: i18n.t("account_type"),
        key: "accountType",
        type: "enumeration",
      },
      { name: i18n.t("enabled"), key: "enabled", type: "boolean" },
      { name: i18n.t("email"), key: "email" },
      { name: i18n.t("created_at"), key: "createdAt", type: "datetime" },
    ];
    if (userInformation.accountType === "GENIXO") {
      headerList.push({
        name: i18n.t("required_action"),
        key: "requiredAction",
        type: "enumeration",
      });
    }
    return headerList;
  };

  const constructPagedQueryParams = () => {
    return {
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        beginAt:
          isFilterApplied && filterBeginAt
            ? new Date(filterBeginAt).getTime()
            : null,
        endAt:
          isFilterApplied && filterEndAt
            ? new Date(filterEndAt).getTime()
            : null,
        textContains:
          isFilterApplied && filterTextContains ? filterTextContains : null,
        deleted: isFilterApplied ? (filterShowDeleted ? null : false) : false,
        userProfileRole: userProfileRole === "ADMIN" ? null : userProfileRole,
        brandId: userProfileBrand.id,
        campusId: userProfileCampus?.id,
        institutionId: userProfileInstitution?.id,
      },
    };
  };

  const resetParams = () => {
    setId(null);
    setUsername(null);
    setFirstName(null);
    setLastName(null);
    setEmail(null);
    setIdentityNumber(null);
    setDateOfBirth(new Date().toISOString().split("T")[0]);
    setPhoneNumber(null);
    setSelectedGender("DEFAULT_OPTION");
    setSelectedEnabled("DEFAULT_OPTION");
  };

  const handleOnChange = (type, value) => {
    if (type === "firstName") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          firstName: i18n.t("first_name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          firstName: null,
        }));
      }
      setFirstName(value);
    } else if (type === "lastName") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          lastName: i18n.t("last_name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          lastName: null,
        }));
      }
      setLastName(value);
    } else if (type === "email") {
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        email: null,
      }));
      setEmail(value);
    } else if (type === "identityNumber") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          identityNumber: i18n.t("identity_number_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          identityNumber: null,
        }));
      }
      setIdentityNumber(value);
    } else if (type === "phoneNumber") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          phoneNumber: i18n.t("phone_number_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          phoneNumber: null,
        }));
      }
      setPhoneNumber(value);
    } else if (type === "dateOfBirth") {
      setDateOfBirth(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        dateOfBirth: null,
      }));
    } else if (type === "gender") {
      setSelectedGender(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        gender: null,
      }));
    } else if (type === "enabled") {
      setSelectedEnabled(value);
    }
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    } else if (type === "textContains") {
      setFilterTextContains(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleReturnFromUserOnClick = () => {
    resetParams();
    setPageState(0);
  };

  const handleToUpdateUserInformation = (userDTO) => {
    setId(userDTO.id);
    setUsername(userDTO.username);
    setFirstName(userDTO.firstName);
    setLastName(userDTO.lastName);
    setEmail(userDTO.email);
    setDateOfBirth(new Date(userDTO.dateOfBirth).toISOString().split("T")[0]);
    setIdentityNumber(userDTO.identityNumber);
    setPhoneNumber(userDTO.phoneNumber);
    setSelectedGender(userDTO.gender);
    setSelectedEnabled(userDTO.enabled);
    setPageState(1);
  };

  const handleUpdateUserOnClick = async () => {
    if (checkUpdatePermissionGranted("user_list")) {
      if (checkCommonValidation()) {
        const currentUser = {
          id,
          firstName,
          lastName,
          email,
          identityNumber,
          phoneNumber,
          dateOfBirth: new Date(dateOfBirth).getTime(),
          gender: selectedGender,
          enabled: selectedEnabled,
        };
        const { data, err } = await patchUser(currentUser);
        if (err) {
          console.log(err);
        } else {
          retrieveAndSetUserPageByFilter();
          handleReturnFromUserOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handlePatchUserRequiredActionOnClick = async (id, requiredAction) => {
    const { data, err } = await patchUserOnlyRequiredAction({
      id,
      requiredAction,
    });
    if (err) {
      console.log(err);
      return;
    }
    await retrieveAndSetUserPageByFilter();
  };

  const handleRandomizePasswordOnClick = async (id) => {
    const { data, err } = await randomizePassword({
      id,
    });
    if (err) {
      console.log(err);
      return;
    }
    await retrieveAndSetUserPageByFilter();
  };

  const RenderSearchAndAddUserModal = () => {
    const pathRole = window.location.pathname.split("/")[1];
    return (
      <BaseModal
        isOpen={showSearchAndAddUserModal}
        size="small"
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowSearchAndAddUserModal(false);
        }}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            <div className="my-10">
              <div className="flex flex-row justify-center items-center gap-x-2">
                {pathRole === "learner-users" &&
                userProfile.role === "STAFF" ? (
                  <div
                    className="w-[250px] flex flex-col items-center gap-y-3 cursor-pointer p-10 border border-catchup-gray-100 hover:border-catchup-blue-500 rounded-catchup-xlarge transition-all duration-300 hover:shadow-input"
                    onClick={() => {
                      navigate("/users/create/import-from-excel");
                    }}
                  >
                    <BaseImage
                      size="large"
                      src="/icons/import-from-excel.png"
                      alt="import-from-excel"
                    />
                    <p className="font-semibold text-xl text-catchup-gray-400">
                      {i18n.t("import_from_excel")}
                    </p>
                  </div>
                ) : null}

                <div
                  className="w-[250px] flex flex-col items-center gap-y-3 cursor-pointer p-10 border border-catchup-gray-100 hover:border-catchup-blue-500 rounded-catchup-xlarge transition-all duration-300 hover:shadow-input"
                  onClick={() => {
                    navigate("/users/create/custom");
                  }}
                >
                  <BaseImage
                    size="large"
                    src="/icons/user-profile-2.png"
                    alt="user-profile"
                  />
                  <p className="font-semibold text-xl text-catchup-gray-400">
                    {i18n.t("custom")}
                  </p>
                </div>
              </div>
            </div>
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={retrieveTitle()}
            description={retrieveDescription()}
            itemName={retrieveItemName()}
            headerList={constructHeaderList()}
            selectable={false}
            showFilter={true}
            showItemCount={selectedShowItemCount}
            totalItemCount={totalItemCount}
            pageNumber={pageNumber}
            loading={tableLoading}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleOnFilterChange={handleOnFilterChange}
            handleOnTableParamsChange={handleOnTableParamsChange}
            filterParams={{
              isFilterApplied,
              showBeginDate: true,
              beginAt: filterBeginAt,
              showEndDate: true,
              showTextContains: true,
              textContains: filterTextContains,
              endAt: filterEndAt,
              showDeleted: filterShowDeleted,
            }}
            itemList={userDTOList}
            insertable={checkInsertPermissionGranted("user_list")}
            handleInsertOnClick={() => {
              setShowSearchAndAddUserModal(true);
            }}
            updatable={checkUpdatePermissionGranted("user_list")}
            handleUpdateOnClick={handleToUpdateUserInformation}
            navigatable={checkCanNavigate()}
            navigateOptionList={constructNavigatableList()}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <FullCard isShadowed={true}>
            <div className="mb-5">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      handleReturnFromUserOnClick();
                    }}
                  />
                </div>
                <BaseTitle
                  title={id ? i18n.t("update_user") : i18n.t("create_user")}
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center text-catchup-dark-blue">
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("username")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={username}
                    disabled={true}
                    onChange={(e) => {
                      handleOnChange("username", e.target.value);
                    }}
                    errorText={errorMap.username}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("first_name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={firstName}
                    onChange={(e) => {
                      handleOnChange("firstName", e.target.value);
                    }}
                    errorText={errorMap.firstName}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("last_name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={lastName}
                    onChange={(e) => {
                      handleOnChange("lastName", e.target.value);
                    }}
                    errorText={errorMap.lastName}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("email")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={email}
                    onChange={(e) => {
                      handleOnChange("email", e.target.value);
                    }}
                    errorText={errorMap.email}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("phone_number")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => {
                      handleOnChange("phoneNumber", e.target.value);
                    }}
                    errorText={errorMap.phoneNumber}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("identity_number")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="number"
                    value={identityNumber}
                    onChange={(e) => {
                      handleOnChange("identityNumber", e.target.value);
                    }}
                    errorText={errorMap.identityNumber}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("date_of_birth")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="date"
                    value={dateOfBirth}
                    onChange={(e) => {
                      handleOnChange("dateOfBirth", e.target.value);
                    }}
                    errorText={errorMap.dateOfBirth}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("gender")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={selectedGender}
                    optionList={retrieveGenderOptionList()}
                    onChange={(e) => {
                      handleOnChange("gender", e.target.value);
                    }}
                    errorText={errorMap.gender}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("enabled")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={selectedEnabled}
                    optionList={retrieveEnableOptionList()}
                    onChange={(e) => {
                      // changeUserEnableStatus(userDTO.id, e.target.value);
                      handleOnChange("enabled", e.target.value);
                    }}
                    errorText={errorMap.enabled}
                  />
                </div>
              </div>

              <div className="mt-4 ml-auto">
                {id ? (
                  <SecondaryButton
                    title={i18n.t("update")}
                    size="medium"
                    onClick={handleUpdateUserOnClick}
                  />
                ) : (
                  <CreateButton
                    title={i18n.t("create")}
                    size="medium"
                    onClick={() => {}}
                  />
                )}
              </div>
            </div>
          </FullCard>
        </div>
      </div>
    );
  };

  const pagedQueryParams = constructPagedQueryParams();

  return (
    <div className="flex-1 flex flex-col">
      {RenderSearchAndAddUserModal()}
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default UserListScreen;
