import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Server
 */
export const checkCategoryService = async () => {
  try {
    const response = await axios
      .create()
      .get(
        `${connection.base.category.url}${endpoints.categories.context}${endpoints.categories.actuator.health.context}`
      );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Category
 */
export const queryCategoryListByVersionCode = async (queryCategoryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.category.url}${endpoints.categories.context}${endpoints.categories.query["by-versionCode"].context}`,
      queryCategoryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryCategoryListByVersionCodeList = async (
  queryCategoryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.category.url}${endpoints.categories.context}${endpoints.categories.query["by-versionCodeList"].context}`,
      queryCategoryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryCategoryDTOByVersionCode = async (queryCategoryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.category.url}${endpoints.categories.context}${endpoints.categories.query.dto["by-versionCode"].context}`,
      queryCategoryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryCategoryListByIdList = async (queryCategoryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.category.url}${endpoints.categories.context}${endpoints.categories.query["by-idList"].context}`,
      queryCategoryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createCategory = async (category) => {
  try {
    const response = await axios.post(
      `${connection.base.category.url}${endpoints.categories.context}/`,
      category
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchCategory = async (patchCategory) => {
  try {
    const response = await axios.patch(
      `${connection.base.category.url}${endpoints.categories.context}/`,
      patchCategory
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
