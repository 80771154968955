import { useNavigate } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import {
  checkDeletePermissionGranted,
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import { useCallback, useEffect, useState } from "react";
import AdvancedTable from "../../components/tables/AdvancedTable";
import i18n from "../../language/i18n";
import { NotificationManager } from "react-notifications";
import {
  createExternalRegistration,
  deleteExternalRegistration,
  patchExternalRegistration,
  queryActiveGradeListByBrandId,
  queryActiveGradeListByCampusId,
  queryActiveGradeListByInstitutionId,
  queryActiveGradeListBySeasonId,
  queryAllExternalRegistrationDTOPageByFilter,
} from "../../requests/ManagementRequests";
import FullCard from "../../components/cards/FullCard";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import DividerLine from "../../components/divider/DividerLine";
import InputGroup from "../../components/groups/InputGroup";
import { retrieveGradeDTOOptionList } from "../../utilization/ManagementUtilization";
import { useAuth } from "../../context/UserContextProvider";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import CreateButton from "../../components/buttons/CreateButton";
import debounce from "lodash.debounce";

const ExternalRegistrationListScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileSeason,
    userProfileGrade,
  } = useAuth();
  const [externalRegistrationDTOList, setExternalRegistrationDTOList] =
    useState([]);
  const [gradeDTOList, setGradeDTOList] = useState([]);
  const [pageState, setPageState] = useState(0);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedGradeId, setSelectedGradeId] = useState("DEFAULT_OPTION");
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterTextContains, setFilterTextContains] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [debounceState, setDebounceState] = useState("");
  const [errorMap, setErrorMap] = useState({
    name: null,
    description: null,
    gradeId: null,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetGradeDTOListBySeasonId = async () => {
      const { data, err } = await queryActiveGradeListBySeasonId(
        userProfileSeason.id
      );
      if (err) {
        console.log(err);
      } else {
        setGradeDTOList(data);
      }
    };
    const retrieveAndSetGradeDTOListByInstitutionId = async () => {
      const { data, err } = await queryActiveGradeListByInstitutionId(
        userProfileInstitution.id
      );
      if (err) {
        console.log(err);
      } else {
        setGradeDTOList(data);
      }
    };
    const retrieveAndSetGradeDTOListByCampusId = async () => {
      const { data, err } = await queryActiveGradeListByCampusId(
        userProfileCampus.id
      );
      if (err) {
        console.log(err);
      } else {
        setGradeDTOList(data);
      }
    };
    const retrieveAndSetGradeDTOListByBrandId = async () => {
      const { data, err } = await queryActiveGradeListByBrandId(
        userProfileBrand.id
      );
      if (err) {
        console.log(err);
      } else {
        setGradeDTOList(data);
      }
    };
    if (!checkViewPermissionGranted("external_registration_list")) {
      navigate("/permission-error");
      return;
    }
    if (userProfileGrade) {
      setGradeDTOList([userProfileGrade]);
    } else if (userProfileSeason) {
      retrieveAndSetGradeDTOListBySeasonId();
    } else if (userProfileInstitution) {
      retrieveAndSetGradeDTOListByInstitutionId();
    } else if (userProfileCampus) {
      retrieveAndSetGradeDTOListByCampusId();
    } else if (userProfileBrand) {
      retrieveAndSetGradeDTOListByBrandId();
    }
  }, []);

  useEffect(() => {
    retrieveAndSetExternalRegistrationPageByFilter();
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    setDebounceState("BASE_FILTER");
    retrieveAndSetExternalRegistrationPageByFilter();
  }, [isFilterApplied, filterBeginAt, filterEndAt]);

  useEffect(() => {
    if (!isFilterApplied) return;
    if (filterTextContains) {
      setDebounceState("TEXT_CONTAINS");
      debouncedRetrieveAndSetExternalRegistrationPageByFilterDebounce(
        filterTextContains
      );
    } else {
      retrieveAndSetExternalRegistrationPageByFilter();
    }
  }, [filterTextContains]);

  const retrieveAndSetExternalRegistrationPageByFilter = async () => {
    setTableLoading(true);
    const { data, err } = await queryAllExternalRegistrationDTOPageByFilter(
      pagedQueryParams
    );
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setExternalRegistrationDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const retrieveAndSetExternalRegistrationPageByFilterDebounce = async (
    filterTextContains
  ) => {
    setTableLoading(true);
    if (isFilterApplied) {
      pagedQueryParams.params.textContains = filterTextContains.trim();
    }
    const { data, err } = await queryAllExternalRegistrationDTOPageByFilter(
      pagedQueryParams
    );
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setExternalRegistrationDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const debouncedRetrieveAndSetExternalRegistrationPageByFilterDebounce =
    useCallback(
      debounce(function (textContains) {
        retrieveAndSetExternalRegistrationPageByFilterDebounce(textContains);
      }, 1000),
      [debounceState]
    );

  const checkCanNavigate = () => {
    let canNavigate = false;
    if (checkUpdatePermissionGranted("external_registration_list")) {
      canNavigate = true;
    }
    return canNavigate;
  };

  const constructNavigatableList = () => {
    const navigateList = [];
    navigateList.push({
      id: 0,
      text: i18n.t("learner_list"),
      icon: (
        <BaseImage src="/icons/navigate-gray.png" alt="navigate" size="small" />
      ),
      value: "/external-registrations/{id}/external-learners",
    });
    return navigateList;
  };

  const resetParams = () => {
    setId(null);
    setName(null);
    setDescription(null);
    setSelectedGradeId("DEFAULT_OPTION");
  };

  const checkCommonValidation = () => {
    if (
      !name ||
      name.trim() === "" ||
      !description ||
      description.trim() === "" ||
      selectedGradeId === "DEFAULT_OPTION"
    ) {
      const currentErrorMap = {
        name: null,
        description: null,
        gradeId: null,
      };
      if (!name || name.trim() === "") {
        currentErrorMap.name = i18n.t("name_required_field");
      }
      if (!description || description.trim() === "") {
        currentErrorMap.description = i18n.t("description_required_field");
      }
      if (selectedGradeId === "DEFAULT_OPTION") {
        currentErrorMap.gradeId = i18n.t("grade_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const constructPagedQueryParams = () => {
    return {
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        beginAt:
          isFilterApplied && filterBeginAt
            ? new Date(filterBeginAt).getTime()
            : null,
        endAt:
          isFilterApplied && filterEndAt
            ? new Date(filterEndAt).getTime()
            : null,
        textContains:
          isFilterApplied && filterTextContains ? filterTextContains : null,
        brandId: userProfileBrand.id,
        campusId: userProfileCampus?.id,
        institutionId: userProfileInstitution?.id,
        seasonId: userProfileSeason?.id,
        gradeId: userProfileGrade?.id,
      },
    };
  };

  const handleOnChange = (type, value) => {
    if (type === "name") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: i18n.t("name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: null,
        }));
      }
      setName(value);
    } else if (type === "description") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: i18n.t("description_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: null,
        }));
      }
      setDescription(value);
    } else if (type === "gradeId") {
      setSelectedGradeId(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        gradeId: null,
      }));
    }
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "textContains") {
      setFilterTextContains(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleToCreateExternalRegistrationOnClick = () => {
    setId(null);
    setPageState(1);
  };

  const handleToUpdateExternalRegistrationOnClick = (
    externalRegistrationDTO
  ) => {
    setId(externalRegistrationDTO.id);
    setName(externalRegistrationDTO.name);
    setDescription(externalRegistrationDTO.description);
    setSelectedGradeId(externalRegistrationDTO.gradeDTO?.id);
    setPageState(1);
  };

  const handleCreateExternalRegistrationOnClick = async () => {
    if (checkInsertPermissionGranted("external_registration_list")) {
      if (checkCommonValidation()) {
        const newExternalRegistration = {
          name,
          description,
          grade: {
            id: selectedGradeId,
          },
        };
        const { data, err } = await createExternalRegistration(
          newExternalRegistration
        );
        if (err) {
          console.log(err);
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          retrieveAndSetExternalRegistrationPageByFilter();
          handleReturnFromExternalRegistrationOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleUpdateExternalRegistrationOnClick = async () => {
    if (checkUpdatePermissionGranted("external_registration_list")) {
      if (checkCommonValidation()) {
        const currentExternalRegistration = {
          id,
          name,
          description,
        };
        const { data, err } = await patchExternalRegistration(
          currentExternalRegistration
        );
        if (err) {
          console.log(err);
        } else {
          retrieveAndSetExternalRegistrationPageByFilter();
          handleReturnFromExternalRegistrationOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleRemoveExternalRegistrationOnClick = async (
    externalRegistrationId
  ) => {
    if (checkDeletePermissionGranted("external_registration_list")) {
      const { err } = await deleteExternalRegistration(externalRegistrationId);
      if (err) {
        console.log(err);
        if (err?.response?.data) {
          NotificationManager.error(i18n.t(err.response.data));
        }
      } else {
        const foundExternalRegistrationIndex =
          externalRegistrationDTOList.findIndex(
            (externalRegistrationDTO) =>
              externalRegistrationDTO.id === externalRegistrationId
          );
        externalRegistrationDTOList.splice(foundExternalRegistrationIndex, 1);
        setExternalRegistrationDTOList(
          JSON.parse(JSON.stringify(externalRegistrationDTOList))
        );
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_delete"));
    }
  };

  const handleReturnFromExternalRegistrationOnClick = () => {
    resetParams();
    setPageState(0);
  };

  const pagedQueryParams = constructPagedQueryParams();

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={i18n.t("external_registration_list")}
            description={i18n.t(
              "external_registration_list_table_description_text"
            )}
            itemName={i18n.t("external_registration")}
            headerList={[
              { name: i18n.t("name"), key: "name", type: "info" },
              {
                name: i18n.t("institution_name"),
                key: "gradeDTO.seasonDTO.institutionDTO.name",
              },
              {
                name: i18n.t("season_name"),
                key: "gradeDTO.seasonDTO.name",
              },
              {
                name: i18n.t("grade_name"),
                key: "gradeDTO.name",
              },
              {
                name: i18n.t("created_at"),
                key: "createdAt",
                type: "datetime",
              },
            ]}
            selectable={false}
            showFilter={true}
            showItemCount={selectedShowItemCount}
            totalItemCount={totalItemCount}
            pageNumber={pageNumber}
            loading={tableLoading}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleOnFilterChange={handleOnFilterChange}
            handleOnTableParamsChange={handleOnTableParamsChange}
            filterParams={{
              isFilterApplied,
              showBeginDate: true,
              beginAt: filterBeginAt,
              showEndDate: true,
              endAt: filterEndAt,
              showTextContains: true,
              textContains: filterTextContains,
            }}
            itemList={externalRegistrationDTOList}
            insertable={checkInsertPermissionGranted(
              "external_registration_list"
            )}
            handleInsertOnClick={handleToCreateExternalRegistrationOnClick}
            updatable={checkUpdatePermissionGranted(
              "external_registration_list"
            )}
            handleUpdateOnClick={handleToUpdateExternalRegistrationOnClick}
            deletable={checkDeletePermissionGranted(
              "external_registration_list"
            )}
            handleDeleteOnClick={handleRemoveExternalRegistrationOnClick}
            navigatable={checkCanNavigate()}
            navigateOptionList={constructNavigatableList()}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <FullCard isShadowed={true}>
            <div className="">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      handleReturnFromExternalRegistrationOnClick();
                    }}
                  />
                </div>
                <BaseTitle
                  title={
                    id
                      ? i18n.t("update_external_registration")
                      : i18n.t("create_external_registration")
                  }
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <DividerLine />
              <div className="w-full">
                <p className="font-semibold text-lg">
                  {i18n.t("external_registration_information")}
                </p>
              </div>
              {/* <div className="w-full flex flex-row items-center gap-x-2 my-2">
                <BaseImage src="/icons/info.png" alt="info" size="small" />
                <div className="flex-1">
                  <p className="">
                    {i18n.t("external_exam_name_detailed_explaination_text")}
                  </p>
                </div>
              </div> */}
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="text"
                    value={name}
                    onChange={(e) => {
                      handleOnChange("name", e.target.value);
                    }}
                    errorText={errorMap.name}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("description")}</p>
                </div>
                <div className="flex-1 h-catchup-description">
                  <InputGroup
                    type="textarea"
                    value={description}
                    onChange={(e) => {
                      handleOnChange("description", e.target.value);
                    }}
                    errorText={errorMap.description}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("grade_name")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={selectedGradeId}
                    optionList={retrieveGradeDTOOptionList(gradeDTOList)}
                    onChange={(event) => {
                      handleOnChange("gradeId", event.target.value);
                    }}
                    errorText={errorMap.gradeId}
                  />
                </div>
              </div>

              <div className="mt-4 ml-auto">
                {id ? (
                  <SecondaryButton
                    title={i18n.t("update")}
                    size="medium"
                    onClick={handleUpdateExternalRegistrationOnClick}
                  />
                ) : (
                  <CreateButton
                    title={i18n.t("create")}
                    size="medium"
                    onClick={handleCreateExternalRegistrationOnClick}
                  />
                )}
              </div>
            </div>
          </FullCard>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ExternalRegistrationListScreen;
