import { Tooltip } from "react-tooltip";
import BaseImage from "../images/BaseImage";
import i18n from "../../language/i18n";
import ActivityTemplateLabel from "../label/ActivityTemplateLabel";

const SingleActivityItem = (props) => {
  const {
    number,
    language,
    activityDTO,
    showActivityCount,
    showActivityTemplate,
    activityCountDTOList,
    categorySet,
    handlePreviewActivityOnClick,
    handleRemoveActivityOnClick,
  } = props;
  const { id, description, name, categoryIdList, activityTemplateDTOList } =
    activityDTO;
  const foundActivityCountDTO = activityCountDTOList.find(
    (activityCountDTO) => activityCountDTO.activityId === id
  );
  return (
    <div className="p-4">
      <div className="flex flex-row gap-x-4">
        {number ? (
          <div className="h-6 w-6 flex flex-col justify-center items-center">
            <p className="font-semibold">{number}</p>
          </div>
        ) : null}

        <div className="flex-1 flex flex-col">
          <div className="flex flex-row justify-between items-center">
            <div className="flex-1 flex flex-row items-center w-full">
              <div className="flex-1">
                <div className="flex flex-row items-center">
                  <Tooltip id="activity-description" />
                  <div className="mr-2">
                    <BaseImage
                      size="small"
                      src="/icons/info.png"
                      alt="info"
                      dataToolTipId="activity-description"
                      dataToolTipContent={description}
                      dataToolTipPlace="top"
                      dataToolTipVariant="info"
                    />
                  </div>
                  <p className="mx-1 font-semibold">{name}</p>
                </div>
              </div>

              {showActivityCount ? (
                <div className="w-1/4">
                  {foundActivityCountDTO ? (
                    <p className="mx-1">
                      {" "}
                      <span className="font-semibold">
                        {foundActivityCountDTO.count}
                      </span>{" "}
                      {i18n.t("times")}
                      {", "}
                      <span className="italic">
                        {new Date(
                          foundActivityCountDTO.lastUsedAt
                        ).toLocaleDateString(language)}
                      </span>
                    </p>
                  ) : (
                    <p className="italic opacity-40">
                      {i18n.t("activity_not_used_yet")}
                    </p>
                  )}
                </div>
              ) : null}
            </div>

            <div className="flex flex-row">
              <div className="mx-2">
                <BaseImage
                  src="/icons/preview.png"
                  alt="preview"
                  size="small"
                  onClick={() => {
                    handlePreviewActivityOnClick(activityDTO);
                  }}
                />
              </div>
              {handleRemoveActivityOnClick ? (
                <div className="mx-2">
                  <BaseImage
                    src="/icons/remove-red.png"
                    alt="remove-red"
                    onClick={() => {
                      handleRemoveActivityOnClick(id);
                    }}
                    size="small"
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex-1 flex flex-col gap-y-1 pl-9">
              {categoryIdList.map((categoryId) => {
                const foundCategory = categorySet.find(
                  (category) => category.id === categoryId
                );
                if (!foundCategory) return;
                return (
                  <div>
                    <p className="text-catchup-gray-400">
                      {foundCategory.code} {foundCategory.name}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-row">
              <div className="mx-2">
                <div className="w-6 h-6"></div>
              </div>
              {handleRemoveActivityOnClick ? (
                <div className="mx-2">
                  <div className="w-6 h-6"></div>
                </div>
              ) : null}
            </div>
          </div>
          {showActivityTemplate ? (
            <div className="flex flex-row items-center flex-wrap pl-8 my-2 gap-x-1">
              {activityTemplateDTOList.map((activityTemplateDTO, index) => (
                <div key={index} className="flex flex-row">
                  <ActivityTemplateLabel
                    title={i18n.t(activityTemplateDTO.type)}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SingleActivityItem;
