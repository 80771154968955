import { useEffect, useState, useCallback } from "react";
import InputGroup from "../groups/InputGroup";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  checkDeletePermissionGranted,
  checkInsertPermissionGranted,
} from "../../utilization/ScreenUtilization";
import {
  retrieveAcceptedFormats,
  retrieveDocumentTypeOptionList,
  showDocumentByPathAndFileName,
} from "../../utilization/StorageFileUtilization";
import {
  deleteStorageFile,
  moveFileToBrandPath,
  queryAllMainFileDTOPageByFilter,
  uploadFileToPath,
} from "../../requests/StorageRequests";
import { Dna } from "react-loader-spinner";
import AdvancedTable from "../tables/AdvancedTable";
import FullCard from "../cards/FullCard";
import { filterCoterieTypeOptionList } from "../../utilization/ManagementUtilization";
import {
  queryBrandLabelActionByItemIdListAndItemType,
  queryLabelActionByItemIdListAndItemType,
} from "../../requests/LabelRequests";
import SetOrUpsertLabel from "../label/SetOrUpsertLabel";
import DividerLine from "../divider/DividerLine";
import BaseModal from "../modal/BaseModal";
import BaseImage from "../images/BaseImage";
import BaseTitle from "../titles/BaseTitle";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import debounce from "lodash.debounce";
import ErrorWarning from "../warnings/ErrorWarning";
import CreateButton from "../buttons/CreateButton";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const ListAndUpsertStorageFile = (props) => {
  const {
    title,
    description,
    type,
    labelable,
    showLabelList,
    ownershipType,
    optionFilter,
    initialFilterApplied,
    initialCoterieType,
    initialDocumentType,
    handleSelectOnClick,
  } = props;
  const {
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileInstitution,
  } = useAuth();
  const [pageState, setPageState] = useState(0);
  const [storageFileDTOList, setStorageFileDTOList] = useState([]);
  const [id, setId] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(
    initialDocumentType ? initialDocumentType : "DEFAULT_OPTION"
  );
  const [selectedCoterieType, setSelectedCoterieType] = useState(
    initialCoterieType
      ? initialCoterieType
      : userProfile?.coterieType
      ? userProfile?.coterieType
      : userProfile?.coterieTypeList && userProfile?.coterieTypeList.length > 0
      ? userProfile?.coterieTypeList[0]
      : "DEFAULT_OPTION"
  );
  const [uploadFiles, setUploadFiles] = useState([]);
  const [fileUploadStatus, setFileUploadStatus] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [fileMoveStatus, setFileMoveStatus] = useState({});
  const [updated, setUpdated] = useState(false);
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(initialFilterApplied);
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterTextContains, setFilterTextContains] = useState(null);
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [filterCoterieType, setFilterCoterieType] = useState(
    initialCoterieType ? initialCoterieType : "DEFAULT_OPTION"
  );
  const [filterDocumentType, setFilterDocumentType] = useState(
    initialDocumentType ? initialDocumentType : "DEFAULT_OPTION"
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [selectedStorageFile, setSelectedStorageFile] = useState(null);
  const [labelLoading, setLabelLoading] = useState(false);
  const [brandLabelLoading, setBrandLabelLoading] = useState(false);
  const [labelActionDTOList, setLabelActionDTOList] = useState([]);
  const [brandLabelActionDTOList, setBrandLabelActionDTOList] = useState([]);
  const [debounceState, setDebounceState] = useState("");
  const [errorMap, setErrorMap] = useState({
    documentType: null,
    coterieType: null,
  });
  const ITEM_TYPE = "STORAGE_FILE";

  useEffect(() => {
    if (updated) {
      setUpdated(false);
    }
  }, [updated]);

  useEffect(() => {
    const retrieveAndSetLabelActionList = async () => {
      setLabelLoading(true);
      const { data, err } = await queryLabelActionByItemIdListAndItemType({
        itemIdList: storageFileDTOList.map((storageFile) => storageFile.id),
        itemType: ITEM_TYPE,
        userId: userInformation.id,
        userProfileId: userProfile.id,
      });
      if (err) {
      } else {
        setLabelActionDTOList(data);
      }
      setLabelLoading(false);
    };
    const retrieveAndSetBrandLabelActionList = async () => {
      setBrandLabelLoading(true);
      const { data, err } = await queryBrandLabelActionByItemIdListAndItemType({
        itemIdList: storageFileDTOList.map((storageFile) => storageFile.id),
        itemType: ITEM_TYPE,
        brandId: userProfileBrand.id,
      });
      if (err) {
      } else {
        setBrandLabelActionDTOList(data);
      }
      setBrandLabelLoading(false);
    };
    if (storageFileDTOList.length === 0) {
      setLabelActionDTOList([]);
      setBrandLabelActionDTOList([]);
    } else {
      retrieveAndSetLabelActionList();
      retrieveAndSetBrandLabelActionList();
    }
  }, [storageFileDTOList]);

  useEffect(() => {
    if (Object.keys(uploadFiles).length > 0) {
      Object.keys(uploadFiles).forEach((key) =>
        handleSendSingleFileUpload(key, uploadFiles[key])
      );
    } else {
      setFileUploadStatus({});
      setUploadedFiles({});
    }
  }, [uploadFiles]);

  useEffect(() => {
    if (Object.keys(fileMoveStatus).length > 0) {
      if (
        Object.keys(fileMoveStatus).filter(
          (key) => fileMoveStatus[key] === "MOVED"
        ).length === Object.keys(fileMoveStatus).length
      ) {
        handleReturnFromStorageFileOnClick();
        retrieveAndSetStorageFilePageByFilter();
      }
    }
  }, [fileMoveStatus]);

  useEffect(() => {
    retrieveAndSetStorageFilePageByFilter();
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    setDebounceState("BASE_FILTER");
    retrieveAndSetStorageFilePageByFilter();
  }, [
    isFilterApplied,
    filterBeginAt,
    filterEndAt,
    filterShowDeleted,
    filterCoterieType,
    filterDocumentType,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    if (filterTextContains) {
      setDebounceState("TEXT_CONTAINS");
      debouncedRetrieveAndSetStorageFilePageByFilterDebounce(
        filterTextContains
      );
    } else {
      retrieveAndSetStorageFilePageByFilter();
    }
  }, [filterTextContains]);

  const retrieveAndSetStorageFilePageByFilter = async () => {
    setTableLoading(true);
    const { data, err } = await queryAllMainFileDTOPageByFilter(
      pagedQueryParams
    );
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setStorageFileDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const retrieveAndSetStorageFilePageByFilterDebounce = async (
    filterTextContains
  ) => {
    setTableLoading(true);
    if (isFilterApplied) {
      pagedQueryParams.params.textContains = filterTextContains.trim();
    }
    const { data, err } = await queryAllMainFileDTOPageByFilter(
      pagedQueryParams
    );
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setStorageFileDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const debouncedRetrieveAndSetStorageFilePageByFilterDebounce = useCallback(
    debounce(function (textContains) {
      retrieveAndSetStorageFilePageByFilterDebounce(textContains);
    }, 1000),
    [debounceState]
  );

  const checkCommonValidation = () => {
    if (
      selectedDocumentType === "DEFAULT_OPTION" ||
      selectedCoterieType === "DEFAULT_OPTION"
    ) {
      const currentErrorMap = {
        coterieType: null,
        documentType: null,
      };
      if (selectedDocumentType === "DEFAULT_OPTION") {
        currentErrorMap.documentType = i18n.t("document_type_required");
      }
      if (selectedCoterieType === "DEFAULT_OPTION") {
        currentErrorMap.coterieType = i18n.t("coterie_type_required");
      }
      setErrorMap(currentErrorMap);
      return false;
    }

    return true;
  };

  const constructLabelActionList = () => {
    const actionDTOList = [];
    for (const labelAction of labelActionDTOList) {
      actionDTOList.push({
        id: labelAction.id,
        label: labelAction.labelDTO,
        labelItemList: labelAction.labelItemDTOList,
        type: "PERSONAL",
      });
    }
    for (const brandLabelAction of brandLabelActionDTOList) {
      actionDTOList.push({
        id: brandLabelAction.id,
        label: brandLabelAction.brandLabelDTO,
        labelItemList: brandLabelAction.brandLabelItemDTOList,
        type: "BRAND",
      });
    }
    return actionDTOList;
  };

  const constructPagedQueryParams = () => {
    return {
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        userId: type === "personal" ? userInformation.id : null,
        userProfileId: type === "personal" ? userProfile.id : null,
        brandId: type === "brand" ? userProfileBrand.id : null,
        ownershipType,
        beginAt:
          isFilterApplied && filterBeginAt
            ? new Date(filterBeginAt).getTime()
            : null,
        endAt:
          isFilterApplied && filterEndAt
            ? new Date(filterEndAt).getTime()
            : null,
        textContains:
          isFilterApplied && filterTextContains ? filterTextContains : null,
        coterieType:
          isFilterApplied && filterCoterieType !== "DEFAULT_OPTION"
            ? filterCoterieType
            : null,
        deleted: isFilterApplied ? (filterShowDeleted ? null : false) : false,
        documentType: initialDocumentType
          ? initialDocumentType
          : isFilterApplied && filterDocumentType !== "DEFAULT_OPTION"
          ? filterDocumentType
          : null,
      },
    };
  };

  const resetParams = () => {
    setUploadFiles({});
    setFileUploadStatus({});
    setUploadedFiles({});
    setFileMoveStatus({});
    setSelectedDocumentType("DEFAULT_OPTION");
    setSelectedCoterieType("DEFAULT_OPTION");
  };

  const handleOnChange = (type, value) => {
    if (type === "documentType") {
      setSelectedDocumentType(value);
      setUploadFiles({});
      setFileUploadStatus({});
      setUploadedFiles({});
      setFileMoveStatus({});
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        documentType: null,
      }));
    } else if (type === "uploadFiles") {
      setUploadFiles(value);
    } else if (type === "coterieType") {
      setSelectedCoterieType(value);
      setUploadFiles({});
      setFileUploadStatus({});
      setUploadedFiles({});
      setFileMoveStatus({});
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        coterieType: null,
      }));
    }
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    } else if (type === "coterieType") {
      setFilterCoterieType(value);
    } else if (type === "documentType") {
      setFilterDocumentType(value);
    } else if (type === "textContains") {
      setFilterTextContains(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleReturnFromStorageFileOnClick = () => {
    resetParams();
    setPageState(0);
  };

  const handleSendSingleFileUpload = async (key, file) => {
    setFileUploadStatus((prevFileUploadStatus) => ({
      ...prevFileUploadStatus,
      [key]: "UPLOADING",
    }));
    const formData = new FormData();
    formData.append("brandId", userProfileBrand.id);
    formData.append("userId", userInformation.id);
    formData.append("userProfileId", userProfile.id);
    formData.append("coterieType", selectedCoterieType);
    formData.append("documentType", selectedDocumentType);
    formData.append(
      "path",
      `/${userProfileBrand.id}/${selectedDocumentType.toLowerCase()}s`
    );
    formData.append("file", file);
    formData.append("changeFileName", true);
    setTimeout(() => {}, Math.random() * 100);
    const { data, err } = await uploadFileToPath(formData);
    if (err) {
      setFileUploadStatus((prevFileUploadStatus) => ({
        ...prevFileUploadStatus,
        [key]: "FAILED",
      }));
    } else {
      setFileUploadStatus((prevFileUploadStatus) => ({
        ...prevFileUploadStatus,
        [key]: "UPLOADED",
      }));
      setUploadedFiles((prevUpladedFiles) => ({
        ...prevUpladedFiles,
        [key]: { fileName: data.fileName, tempPath: data.path, ownershipType },
      }));
    }
  };

  const handleMoveSingleFileUpload = async (key, uploadedFile) => {
    setFileMoveStatus((prevFileUploadStatus) => ({
      ...prevFileUploadStatus,
      [key]: "MOVING",
    }));
    const { data, err } = await moveFileToBrandPath(uploadedFile);
    if (err) {
      console.log(err);
      setFileMoveStatus((prevFileUploadStatus) => ({
        ...prevFileUploadStatus,
        [key]: "FAILED",
      }));
    } else {
      setFileMoveStatus((prevFileUploadStatus) => ({
        ...prevFileUploadStatus,
        [key]: "MOVED",
      }));
    }
  };

  const handleToCreateStorageFileOnClick = () => {
    setId(null);
    setPageState(1);
  };

  const handleCreateStorageFileOnClick = async () => {
    if (checkInsertPermissionGranted("storage_file_list")) {
      if (checkCommonValidation()) {
        Object.keys(uploadedFiles).forEach((key) =>
          handleMoveSingleFileUpload(key, uploadedFiles[key])
        );
      }
    }
  };

  const handleRemoveStorageFileOnClick = async (storageFileId) => {
    const { err } = await deleteStorageFile(storageFileId);
    if (err) {
      console.log(err);
    } else {
      const foundStorageFileIndex = storageFileDTOList.findIndex(
        (storageFileDTO) => storageFileDTO.id === parseFloat(storageFileId)
      );
      storageFileDTOList.splice(foundStorageFileIndex, 1);
      setStorageFileDTOList(
        JSON.parse(
          JSON.stringify(storageFileDTOList.sort((a, b) => b.id - a.id))
        )
      );
    }
  };

  const handleToLabelStorageFileOnClick = (storageFile) => {
    setShowLabelModal(true);
    setSelectedStorageFile(storageFile);
  };

  const handlePreviewOnClick = (file) => {
    const { path, fileName } = file;
    window.open(
      `${connection.base.storage.url}/storage-files/preview/file${path}/${fileName}`
    );
  };

  const RenderLabelModal = () => {
    return (
      <BaseModal
        isOpen={showLabelModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowLabelModal(false);
          setSelectedStorageFile(null);
        }}
      >
        <div className="flex-1 flex flex-col">
          <SetOrUpsertLabel
            item={selectedStorageFile}
            itemType={ITEM_TYPE}
            personalLabelLoading={labelLoading}
            personalLabelActionList={labelActionDTOList}
            setPersonalLabelActionList={setLabelActionDTOList}
            brandLabelLoading={brandLabelLoading}
            brandLabelActionList={brandLabelActionDTOList}
            setBrandLabelActionList={setBrandLabelActionDTOList}
          />
        </div>
      </BaseModal>
    );
  };

  const RenderMainContent = () => {
    return (
      <div className="w-full">
        <div className={`${pageState === 0 ? "block" : "hidden"}`}>
          <AdvancedTable
            title={title}
            description={description}
            itemName={i18n.t("file")}
            headerList={[
              {
                name: i18n.t("document_preview"),
                func: showDocumentByPathAndFileName,
                funcParams: ["documentType", "path", "fileName"],
                type: "document",
              },
              { name: i18n.t("name"), key: "fileOriginalName" },
              {
                name: i18n.t("coterie_type"),
                key: "coterieType",
                type: "enumeration",
              },
              {
                name: i18n.t("document_type"),
                key: "documentType",
                type: "enumeration",
              },
              {
                name: i18n.t("created_at"),
                key: "createdAt",
                type: "datetime",
              },
            ]}
            selectable={false}
            showFilter={true}
            showItemCount={selectedShowItemCount}
            totalItemCount={totalItemCount}
            pageNumber={pageNumber}
            loading={tableLoading}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleOnFilterChange={handleOnFilterChange}
            handleOnTableParamsChange={handleOnTableParamsChange}
            filterParams={{
              isFilterApplied,
              showBeginDate: true,
              beginAt: filterBeginAt,
              showEndDate: true,
              endAt: filterEndAt,
              showTextContains: true,
              textContains: filterTextContains,
              showDeleted: filterShowDeleted,
              showCoterieType: initialDocumentType ? false : true,
              mainCoterieType: userProfile.coterieType
                ? userProfile.coterieType
                : null,
              coterieType: filterCoterieType,
              showDocumentType: initialDocumentType ? false : true,
              documentType: filterDocumentType,
              documentOptionFilter: optionFilter,
            }}
            itemList={storageFileDTOList}
            handleSelectOnClick={handleSelectOnClick}
            labelable={labelable}
            showLabelList={showLabelList}
            labelActionList={constructLabelActionList()}
            handleLabelOnClick={handleToLabelStorageFileOnClick}
            insertable={checkInsertPermissionGranted("storage_file_list")}
            handleInsertOnClick={handleToCreateStorageFileOnClick}
            updatable={false}
            deletable={checkDeletePermissionGranted("storage_file_list")}
            handleDeleteOnClick={handleRemoveStorageFileOnClick}
            navigatable={false}
            previewable={true}
            handlePreviewOnClick={handlePreviewOnClick}
          />
        </div>
        <div className={`${pageState === 1 ? "block" : "hidden"}`}>
          <FullCard isShadowed={true}>
            <div className="">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      handleReturnFromStorageFileOnClick();
                    }}
                  />
                </div>
                <BaseTitle
                  title={id ? i18n.t("update_file") : i18n.t("create_file")}
                />
              </div>
            </div>

            <div className="flex flex-col items-center justify-center">
              <DividerLine />

              <div className="w-full">
                <p className="font-semibold text-lg">
                  {i18n.t("storage_file_information")}
                </p>
              </div>
              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("coterie_type")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={selectedCoterieType}
                    optionList={filterCoterieTypeOptionList(
                      userInformation,
                      userProfile,
                      userProfileInstitution
                    )}
                    onChange={(event) => {
                      handleOnChange("coterieType", event.target.value);
                    }}
                    errorText={errorMap.coterieType}
                  />
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-catchup-input-group-title py-5">
                  <p>{i18n.t("document_type")}</p>
                </div>
                <div className="flex-1">
                  <InputGroup
                    type="select"
                    value={selectedDocumentType}
                    optionList={retrieveDocumentTypeOptionList(optionFilter)}
                    onChange={(event) => {
                      handleOnChange("documentType", event.target.value);
                    }}
                    errorText={errorMap.documentType}
                  />
                </div>
              </div>

              {selectedDocumentType !== "DEFAULT_OPTION" &&
              selectedCoterieType !== "DEFAULT_OPTION" ? (
                selectedCoterieType === "MANAGEMENT" ? (
                  <div className="w-full">
                    <ErrorWarning
                      text={i18n.t(
                        "please_select_a_different_coterie_type_to_upload_a_document"
                      )}
                    />
                  </div>
                ) : (
                  <>
                    <div className="w-full flex flex-row">
                      <div className="w-catchup-input-group-title py-5">
                        <p>{i18n.t("file_upload")}</p>
                      </div>
                      <div className="flex-1">
                        <InputGroup
                          type="file"
                          multiple={true}
                          accept={retrieveAcceptedFormats(selectedDocumentType)}
                          value={null}
                          onChange={(event) => {
                            setUploadFiles({});
                            setFileUploadStatus({});
                            setUploadedFiles({});
                            handleOnChange("uploadFiles", {
                              ...event.target.files,
                            });
                          }}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                        />
                      </div>
                    </div>
                    {Object.keys(uploadFiles).map((key) => {
                      return (
                        <div className="w-5/6 ml-auto" key={key}>
                          <div className="flex flex-row justify-between items-center gap-x-1">
                            {fileUploadStatus[key] === "FAILED" ? (
                              <BaseImage
                                src="/icons/exclamation-red.png"
                                alt="exclamation-red"
                                size="small"
                              />
                            ) : null}
                            <div className="flex-1">
                              <p>{uploadFiles[key].name}</p>
                            </div>
                            <div>
                              {fileUploadStatus[key] === "UPLOADING" ? (
                                <Dna
                                  visible={true}
                                  height="24"
                                  width="24"
                                  ariaLabel="dna-loading"
                                  wrapperStyle={{}}
                                  wrapperClass="dna-wrapper"
                                />
                              ) : fileUploadStatus[key] === "UPLOADED" ? (
                                <BaseImage
                                  src="/icons/checkbox.png"
                                  alt="checkbox"
                                  size="small"
                                />
                              ) : fileUploadStatus[key] === "FAILED" ? (
                                <BaseImage
                                  src="/icons/remove-red.png"
                                  alt="remove-red"
                                  onClick={() => {
                                    delete uploadFiles[key];
                                    delete fileUploadStatus[key];
                                    let currentIndex = 0;
                                    const newUploadFiles = {};
                                    const newFileUploadStatus = {};
                                    Object.keys(uploadFiles).forEach((key) => {
                                      newUploadFiles[currentIndex] =
                                        uploadFiles[key];
                                      newFileUploadStatus[currentIndex] =
                                        fileUploadStatus[key];
                                      currentIndex++;
                                    });
                                    setUploadFiles(newUploadFiles);
                                    setFileUploadStatus(newFileUploadStatus);
                                  }}
                                  size="small"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {Object.keys(fileMoveStatus).filter(
                      (key) => fileMoveStatus[key] === "FAILED"
                    ).length > 0 ? (
                      <>
                        <div>
                          <p>{i18n.t("please_retry_uploads")}</p>
                        </div>
                        {Object.keys(fileMoveStatus)
                          .filter((key) => fileMoveStatus[key] === "FAILED")
                          .map((key) => (
                            <div className="flex flex-row justify-between items-center">
                              <div>
                                <p>{uploadFiles[key].name}</p>
                              </div>
                              <div>
                                <BaseImage
                                  src="/icons/edit.png"
                                  alt="edit"
                                  onClick={() => {
                                    handleMoveSingleFileUpload(
                                      key,
                                      uploadedFiles[key]
                                    );
                                  }}
                                  size="small"
                                />
                              </div>
                            </div>
                          ))}
                      </>
                    ) : null}
                    <div className="mt-4 ml-auto">
                      <CreateButton
                        title={i18n.t("create")}
                        size="medium"
                        onClick={handleCreateStorageFileOnClick}
                      />
                    </div>
                  </>
                )
              ) : null}
            </div>
          </FullCard>
        </div>
      </div>
    );
  };

  const pagedQueryParams = constructPagedQueryParams();

  return (
    <>
      {RenderLabelModal()}
      {RenderMainContent()}
    </>
  );
};

export default ListAndUpsertStorageFile;
