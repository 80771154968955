import { useEffect, useRef, useState } from "react";
import FullCard from "../cards/FullCard";
import DividerLine from "../divider/DividerLine";
import {
  constructActivityItemListWithSolutionForAI,
  retrieveActivityTemplateDTOOptionList,
  retrieveContentTypeOptionList,
  retrieveDurationInSecondsOptionList,
  retrieveStatusOptionList,
  retrieveTaxonomyGroupName,
  retrieveTaxonomyName,
  retrieveTaxonomyType,
} from "../../utilization/CatchtivityUtilization";
import {
  filterCoterieTypeOptionList,
  filterGradeLevelOptionList,
  findAISettingsFromCurrentProfile,
} from "../../utilization/ManagementUtilization";
import { useAuth } from "../../context/UserContextProvider";
import {
  filterCategoryVersionCodeOptionList,
  retrieveCategoryVersionCodeOptionList,
} from "../../utilization/CategoryUtilization";
import i18n from "../../language/i18n";
import Switch from "react-switch";
import InputGroup from "../groups/InputGroup";
import ListAndUpsertStorageFile from "../storage-file/ListAndUpsertStorageFile";
import SecondaryButton from "../buttons/SecondaryButton";
import { NotificationManager } from "react-notifications";
import {
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
} from "../../utilization/ScreenUtilization";
import { useApp } from "../../context/AppContextProvider";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import {
  createActivityAccessibilityByActivityId,
  createActivityWithData,
  convertActivityTemplateWithAI,
  generateActivityTemplateWithAI,
  patchActivityWithData,
  processImageToMCSAWithAI,
  retrieveActivityWithDataById,
  solveActivityWithAIFromO1,
} from "../../requests/CatchtivityRequests";
import { convertDataURLtoFile } from "../../utilization/StorageFileUtilization";
import {
  convertImageTranslateImageWithAI,
  convertImageTranslateTextInImageWithAI,
  moveFileToBrandPath,
  patchImageTranslate,
  queryImageTranslateListByParams,
  uploadFileToPath,
} from "../../requests/StorageRequests";
import ReactCrop from "react-image-crop";
import BaseModal from "../modal/BaseModal";
import BaseImage from "../images/BaseImage";
import BaseTitle from "../titles/BaseTitle";
import SingleInformationIconTextItem from "../infos/SingleInformationIconTextItem";
import { calculateLevenshteinDistance } from "../../utilization/FunctionUtilization";
import { queryTokenAssignmentDTOListByParams } from "../../requests/TokenRequests";
import InputWithSpecialExpression from "../texts/InputWithSpecialExpression";
import CreateButton from "../buttons/CreateButton";
import PrimaryButton from "../buttons/PrimaryButton";
import CleanFilterGroup from "../groups/CleanFilterGroup";
import SelectionBox from "../boxes/SelectionBox";
import SelectionCheckbox from "../boxes/SelectionCheckbox";
import SelectionTab from "../tabs/SelectionTab";
import TextGroup from "../groups/TextGroup";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const constructBasicTaxonomyMap = () => {
  return {
    type: retrieveTaxonomyType()[0].value,
    groupName: retrieveTaxonomyGroupName()[0].value,
    name: null,
    stage: null,
  };
};

const CreateActivity = (props) => {
  const { catchtivityStompClient } = useApp();
  const {
    isShadowed,
    showHeader,
    lockedCoterieType,
    lockedLevel,
    lockedVersionCode,
    existingActivity,
    parsedActivity,
    setUploadedFileName,
    handleGoBackOnClick,
    handleAfterUpdateActivityAction,
    handleAfterCreateActivityAction,
    handleSameActivityUpdatedBySomeoneAction,
  } = props;
  const {
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileGrade,
    categorySet,
    activityTemplateSet,
  } = useAuth();
  const [subscribedId, setSubscribedId] = useState(null);
  const [id, setId] = useState(null);
  const [someoneInformation, setSomeoneInformation] = useState(null);
  const [showSomeoneUpdatedModal, setShowSomeoneUpdatedModal] = useState(false);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("ACTIVE");
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");
  const [selectedLevel, setSelectedLevel] = useState("DEFAULT_OPTION");
  const [selectedVersionCode, setSelectedVersionCode] =
    useState("DEFAULT_OPTION");
  const [subCategoryFilter, setSubCategoryFilter] = useState(true);
  const [selectedCategoryId, setSelectedCategoryId] =
    useState("DEFAULT_OPTION");
  const [activityCategoryList, setActivityCategoryList] = useState([]);
  const [updateCategoryList, setUpdateCategoryList] = useState(false);
  const [selectedDurationType, setSelectedDurationType] = useState("NONE");
  const [selectedDurationInSeconds, setSelectedDurationInSeconds] =
    useState("DEFAULT_OPTION");
  const [activityTemplateIdList, setActivityTemplateIdList] = useState([]);
  const [selectedActivityTemplateId, setSelectedActivityTemplateId] =
    useState(null);
  const [selectedMaterialContentType, setSelectedMaterialContentType] =
    useState(null);
  const [orderingMaterialMap, setOrderingMaterialMap] = useState({});
  const [orderingBodyMap, setOrderingBodyMap] = useState({});
  const [orderingTaxonomyMap, setOrderingTaxonomyMap] = useState(
    constructBasicTaxonomyMap()
  );
  const [orderingSolutionMap, setOrderingSolutionMap] = useState({});
  const [dropdownMaterialMap, setDropdownMaterialMap] = useState({});
  const [dropdownBodyMap, setDropdownBodyMap] = useState({});
  const [dropdownTaxonomyMap, setDropdownTaxonomyMap] = useState(
    constructBasicTaxonomyMap()
  );
  const [dropdownSolutionMap, setDropdownSolutionMap] = useState({});
  const [MCSAMaterialMap, setMCSAMaterialMap] = useState({ ANSWER_KEY: [] });
  const [MCSABodyMap, setMCSABodyMap] = useState({});
  const [MCSATaxonomyMap, setMCSATaxonomyMap] = useState(
    constructBasicTaxonomyMap()
  );
  const [MCSASolutionMap, setMCSASolutionMap] = useState({});
  const [MCMAMaterialMap, setMCMAMaterialMap] = useState({ ANSWER_KEY: [] });
  const [MCMABodyMap, setMCMABodyMap] = useState({});
  const [MCMATaxonomyMap, setMCMATaxonomyMap] = useState(
    constructBasicTaxonomyMap()
  );
  const [MCMASolutionMap, setMCMASolutionMap] = useState({});
  const [matchingMaterialMap, setMatchingMaterialMap] = useState({});
  const [matchingBodyMap, setMatchingBodyMap] = useState({});
  const [matchingTaxonomyMap, setMatchingTaxonomyMap] = useState(
    constructBasicTaxonomyMap()
  );
  const [matchingSolutionMap, setMatchingSolutionMap] = useState({});
  const [groupingMaterialMap, setGroupingMaterialMap] = useState({});
  const [groupingBodyMap, setGroupingBodyMap] = useState({});
  const [groupingTaxonomyMap, setGroupingTaxonomyMap] = useState(
    constructBasicTaxonomyMap()
  );
  const [groupingSolutionMap, setGroupingSolutionMap] = useState({});
  const [fillInTheBlanksMaterialMap, setFillInTheBlanksMaterialMap] = useState(
    {}
  );
  const [fillInTheBlanksIncorrectList, setFillInTheBlanksIncorrectList] =
    useState([]);
  const [fillInTheBlanksBodyMap, setFillInTheBlanksBodyMap] = useState({});
  const [fillInTheBlanksTaxonomyMap, setFillInTheBlanksTaxonomyMap] = useState(
    constructBasicTaxonomyMap()
  );
  const [fillInTheBlanksSolutionMap, setFillInTheBlanksSolutionMap] = useState(
    {}
  );
  const [openEndedMaterialMap, setOpenEndedMaterialMap] = useState({});
  const [openEndedBodyMap, setOpenEndedBodyMap] = useState({});
  const [openEndedTaxonomyMap, setOpenEndedTaxonomyMap] = useState(
    constructBasicTaxonomyMap()
  );
  const [openEndedSolutionMap, setOpenEndedSolutionMap] = useState({});
  const [openEndedEvaluationRubricMap, setOpenEndedEvaluationRubricMap] =
    useState({});
  const [trueFalseMaterialMap, setTrueFalseMaterialMap] = useState({
    trueList: [],
    falseList: [],
  });
  const [trueFalseBodyMap, setTrueFalseBodyMap] = useState({});
  const [trueFalseTaxonomyMap, setTrueFalseTaxonomyMap] = useState(
    constructBasicTaxonomyMap()
  );
  const [trueFalseSolutionMap, setTrueFalseSolutionMap] = useState({});
  const [materialLibraryModal, setMaterialLibraryModal] = useState({
    show: false,
  });
  const [bodyLibraryModal, setBodyLibraryModal] = useState({ show: false });
  const [solutionLibraryModal, setSolutionLibraryModal] = useState({
    show: false,
  });
  const [isCropModeActive, setIsCropModeActive] = useState(false);
  const [canGenerateActivityTemplate, setCanGenerateActivityTemplate] =
    useState(false);
  const [canSolveActivity, setCanSolveActivity] = useState(false);
  const [crop, setCrop] = useState(null);
  const cropImageRef = useRef(null);
  const [errorMap, setErrorMap] = useState({
    name: null,
    description: null,
    status: null,
    coterieType: null,
    level: null,
    durationType: null,
    durationInSeconds: null,
  });
  const [loadingCompleted, setLoadingCompleted] = useState(false);
  const [activityTemplateTypeImageMap, setActivityTemplateTypeImageMap] =
    useState({
      ORDERING: [],
      DROPDOWN: [],
      MCSA: [],
      MCMA: [],
      MATCHING: [],
      GROUPING: [],
      FILL_IN_THE_BLANKS: [],
      OPEN_ENDED: [],
      TRUE_FALSE: [],
    });
  const [imageList, setImageList] = useState([]);
  const [imageContentList, setImageContentList] = useState([]);
  const [showRefreshButton, setShowRefreshButton] = useState(true);
  const [imageTextUpdateId, setImageTextUpdateId] = useState(null);
  const [imageText, setImageText] = useState(null);
  const [innerActivityState, setInnerActivityState] =
    useState("ACTIVITY_SOLUTION");
  const [translateTextLoading, setTranslateTextLoading] = useState(false);

  useEffect(() => {
    if (!userProfile) return;
    const { role, coterieType } = userProfile;
    if (lockedCoterieType) {
      setSelectedCoterieType(coterieType);
    } else {
      if (role === "STAFF") {
        setSelectedCoterieType(coterieType);
      }
    }
  }, [lockedCoterieType]);

  useEffect(() => {
    if (!userProfileGrade) return;
    const { role } = userProfile;
    if (lockedLevel) {
      setSelectedLevel(lockedLevel);
    } else {
      if (role === "STAFF") {
        setSelectedLevel(userProfileGrade.level);
      }
    }
  }, [lockedLevel]);

  useEffect(() => {
    if (lockedCoterieType) {
      setSelectedVersionCode(lockedVersionCode);
    } else {
      if (selectedCoterieType === "DEFAULT_OPTION") return;
      if (filteredCategoryVersionCodeOptionList.length === 0) return;
      setSelectedVersionCode(filteredCategoryVersionCodeOptionList[0].value);
    }
  }, [lockedCoterieType, lockedVersionCode, selectedCoterieType]);

  useEffect(() => {
    if (existingActivity) {
      setId(existingActivity.id);
      setName(existingActivity.name);
      setDescription(existingActivity.description);
      setSelectedStatus(existingActivity.status);
      setSelectedCoterieType(existingActivity.coterieType);
      setSelectedLevel(existingActivity.level);
    }
  }, [existingActivity]);

  useEffect(() => {
    const retrieveAndSetActivityCategoryList = async () => {
      const currentActivityCategoryList = [];
      activityCategoryList.forEach((activityCategory) => {
        const foundCategory = categorySet.find(
          (category) => category.id === activityCategory.id
        );
        if (foundCategory) {
          currentActivityCategoryList.push(foundCategory);
        }
      });
      setActivityCategoryList(currentActivityCategoryList);
    };
    if (updateCategoryList) {
      retrieveAndSetActivityCategoryList();
      setUpdateCategoryList(false);
    }
  }, [updateCategoryList]);

  useEffect(() => {
    const retrieveAndSetSpecificActivity = async () => {
      const { data, err } = await retrieveActivityWithDataById(id);
      if (err) {
        console.log(err);
        return;
      }
      setLoadingCompleted(false);
      setActivityTemplateIdList(
        data.activityTemplateDTOList.map(
          (activityTemplateDTO) => activityTemplateDTO.id
        )
      );
      setActivityCategoryList(
        data.categoryIdList.map((categoryId) => ({
          id: categoryId,
          code: i18n.t("unknown"),
          name: i18n.t("unknown"),
          description: i18n.t("unknown"),
        }))
      );
      setUpdateCategoryList(true);
      const constructedMap = JSON.parse(data.data);
      const currentContentMap = JSON.parse(constructedMap["contentMap"]);
      setSelectedMaterialContentType(currentContentMap.type);
      if (constructedMap["durationMap"]) {
        const currentDurationMap = JSON.parse(constructedMap["durationMap"]);
        if (currentDurationMap.durationType) {
          setSelectedDurationType(currentDurationMap.durationType);
          setSelectedDurationInSeconds(currentDurationMap.duration);
        } else {
          setSelectedDurationType("NONE");
          setSelectedDurationInSeconds("DEFAULT_OPTION");
        }
      } else {
        setSelectedDurationType("NONE");
        setSelectedDurationInSeconds("DEFAULT_OPTION");
      }
      if (
        data.activityTemplateDTOList.find(
          (activityTemplateDTO) => activityTemplateDTO.type === "ORDERING"
        )
      ) {
        const currentOrderingBodyMap = JSON.parse(
          constructedMap["orderingBodyMap"]
        );
        setOrderingBodyMap(convertToBodyStringMap(currentOrderingBodyMap));
        if (constructedMap["orderingSolutionMap"]) {
          const currentOrderingSolutionMap = JSON.parse(
            constructedMap["orderingSolutionMap"]
          );
          setOrderingSolutionMap(
            convertToSolutionStringMap(currentOrderingSolutionMap)
          );
        }
        const currentOrderingMaterialMap = JSON.parse(
          constructedMap["orderingMaterialMap"]
        );
        setOrderingMaterialMap(currentOrderingMaterialMap);
        const currentOrderingTaxonomyMap = JSON.parse(
          constructedMap["orderingTaxonomyMap"]
        );
        setOrderingTaxonomyMap(currentOrderingTaxonomyMap);
      }
      if (
        data.activityTemplateDTOList.find(
          (activityTemplateDTO) => activityTemplateDTO.type === "DROPDOWN"
        )
      ) {
        const currentDropdownBodyMap = JSON.parse(
          constructedMap["dropdownBodyMap"]
        );
        setDropdownBodyMap(convertToBodyStringMap(currentDropdownBodyMap));
        if (constructedMap["dropdownSolutionMap"]) {
          const currentDropdownSolutionMap = JSON.parse(
            constructedMap["dropdownSolutionMap"]
          );
          setDropdownSolutionMap(
            convertToSolutionStringMap(currentDropdownSolutionMap)
          );
        }
        const currentDropdownMaterialMap = JSON.parse(
          constructedMap["dropdownMaterialMap"]
        );
        Object.keys(currentDropdownMaterialMap).forEach((key) => {
          currentDropdownMaterialMap[key] = JSON.parse(
            currentDropdownMaterialMap[key]
          );
          const answerKey = Object.keys(currentDropdownMaterialMap[key])[0];
          currentDropdownMaterialMap[key] = {
            [answerKey]: JSON.parse(currentDropdownMaterialMap[key][answerKey]),
          };
        });
        setDropdownMaterialMap(currentDropdownMaterialMap);
        const currentDropdownTaxonomyMap = JSON.parse(
          constructedMap["dropdownTaxonomyMap"]
        );
        setDropdownTaxonomyMap(currentDropdownTaxonomyMap);
      }
      if (
        data.activityTemplateDTOList.find(
          (activityTemplateDTO) => activityTemplateDTO.type === "MCSA"
        )
      ) {
        const currentMCSABodyMap = JSON.parse(constructedMap["MCSABodyMap"]);
        setMCSABodyMap(convertToBodyStringMap(currentMCSABodyMap));
        if (constructedMap["MCSASolutionMap"]) {
          const currentMCSASolutionMap = JSON.parse(
            constructedMap["MCSASolutionMap"]
          );
          setMCSASolutionMap(
            convertToSolutionStringMap(currentMCSASolutionMap)
          );
        }
        const currentMCSAMaterialMap = JSON.parse(
          constructedMap["MCSAMaterialMap"]
        );
        const answerKey = Object.keys(currentMCSAMaterialMap)[0];
        setMCSAMaterialMap({
          [answerKey]: JSON.parse(currentMCSAMaterialMap[answerKey]),
        });
        const currentMCSATaxonomyMap = JSON.parse(
          constructedMap["MCSATaxonomyMap"]
        );
        setMCSATaxonomyMap(currentMCSATaxonomyMap);
      }
      if (
        data.activityTemplateDTOList.find(
          (activityTemplateDTO) => activityTemplateDTO.type === "MCMA"
        )
      ) {
        const currentMCMABodyMap = JSON.parse(constructedMap["MCMABodyMap"]);
        setMCMABodyMap(convertToBodyStringMap(currentMCMABodyMap));
        if (constructedMap["MCMASolutionMap"]) {
          const currentMCMASolutionMap = JSON.parse(
            constructedMap["MCMASolutionMap"]
          );
          setMCMASolutionMap(
            convertToSolutionStringMap(currentMCMASolutionMap)
          );
        }
        const currentMCMAMaterialMap = JSON.parse(
          constructedMap["MCMAMaterialMap"]
        );
        const answerKey = Object.keys(currentMCMAMaterialMap)[0];
        const convertedAnswerKey = JSON.parse(answerKey).join("§"); // .join
        setMCMAMaterialMap({
          [convertedAnswerKey]: JSON.parse(currentMCMAMaterialMap[answerKey]),
        });
        const currentMCMATaxonomyMap = JSON.parse(
          constructedMap["MCMATaxonomyMap"]
        );
        setMCMATaxonomyMap(currentMCMATaxonomyMap);
      }
      if (
        data.activityTemplateDTOList.find(
          (activityTemplateDTO) => activityTemplateDTO.type === "MATCHING"
        )
      ) {
        const currentMatchingBodyMap = JSON.parse(
          constructedMap["matchingBodyMap"]
        );
        setMatchingBodyMap(convertToBodyStringMap(currentMatchingBodyMap));
        if (constructedMap["matchingSolutionMap"]) {
          const currentMatchingSolutionMap = JSON.parse(
            constructedMap["matchingSolutionMap"]
          );
          setMatchingSolutionMap(
            convertToSolutionStringMap(currentMatchingSolutionMap)
          );
        }
        let currentIndex = 0;
        const currentMatchingMaterialMap = JSON.parse(
          constructedMap["matchingMaterialMap"]
        );
        const newCurrentMatchingMaterialMap = {};
        Object.keys(currentMatchingMaterialMap).forEach((key) => {
          newCurrentMatchingMaterialMap[currentIndex] = {
            [key]: currentMatchingMaterialMap[key],
          };
          currentIndex++;
        });
        setMatchingMaterialMap(newCurrentMatchingMaterialMap);
        const currentMatchingTaxonomyMap = JSON.parse(
          constructedMap["matchingTaxonomyMap"]
        );
        setMatchingTaxonomyMap(currentMatchingTaxonomyMap);
      }
      if (
        data.activityTemplateDTOList.find(
          (activityTemplateDTO) => activityTemplateDTO.type === "GROUPING"
        )
      ) {
        const currentGroupingBodyMap = JSON.parse(
          constructedMap["groupingBodyMap"]
        );
        setGroupingBodyMap(convertToBodyStringMap(currentGroupingBodyMap));
        if (constructedMap["groupingSolutionMap"]) {
          const currentGroupingSolutionMap = JSON.parse(
            constructedMap["groupingSolutionMap"]
          );
          setGroupingSolutionMap(
            convertToSolutionStringMap(currentGroupingSolutionMap)
          );
        }
        let currentIndex = 0;
        const currentGroupingMaterialMap = JSON.parse(
          constructedMap["groupingMaterialMap"]
        );
        const newCurrentGroupingMaterialMap = {};
        Object.keys(currentGroupingMaterialMap).forEach((key) => {
          newCurrentGroupingMaterialMap[currentIndex] = {
            [key]: JSON.parse(currentGroupingMaterialMap[key]),
          };
          currentIndex++;
        });
        setGroupingMaterialMap(newCurrentGroupingMaterialMap);
        const currentGroupingTaxonomyMap = JSON.parse(
          constructedMap["groupingTaxonomyMap"]
        );
        setGroupingTaxonomyMap(currentGroupingTaxonomyMap);
      }
      if (
        data.activityTemplateDTOList.find(
          (activityTemplateDTO) =>
            activityTemplateDTO.type === "FILL_IN_THE_BLANKS"
        )
      ) {
        const currentFillInTheBlanksBodyMap = JSON.parse(
          constructedMap["fillInTheBlanksBodyMap"]
        );
        setFillInTheBlanksBodyMap(
          convertToBodyStringMap(currentFillInTheBlanksBodyMap)
        );
        if (constructedMap["fillInTheBlanksSolutionMap"]) {
          const currentFillInTheBlanksSolutionMap = JSON.parse(
            constructedMap["fillInTheBlanksSolutionMap"]
          );
          setFillInTheBlanksSolutionMap(
            convertToSolutionStringMap(currentFillInTheBlanksSolutionMap)
          );
        }
        if (constructedMap["fillInTheBlanksIncorrectList"]) {
          setFillInTheBlanksIncorrectList(
            JSON.parse(constructedMap["fillInTheBlanksIncorrectList"])
          );
        }
        const currentFillInTheBlanksMaterialMap = JSON.parse(
          constructedMap["fillInTheBlanksMaterialMap"]
        );
        Object.keys(currentFillInTheBlanksMaterialMap).forEach((key) => {
          currentFillInTheBlanksMaterialMap[key] = JSON.parse(
            currentFillInTheBlanksMaterialMap[key]
          );
        });
        setFillInTheBlanksMaterialMap(currentFillInTheBlanksMaterialMap);
        const currentFillInTheBlanksTaxonomyMap = JSON.parse(
          constructedMap["fillInTheBlanksTaxonomyMap"]
        );
        setFillInTheBlanksTaxonomyMap(currentFillInTheBlanksTaxonomyMap);
      }
      if (
        data.activityTemplateDTOList.find(
          (activityTemplateDTO) => activityTemplateDTO.type === "OPEN_ENDED"
        )
      ) {
        const currentOpenEndedBodyMap = JSON.parse(
          constructedMap["openEndedBodyMap"]
        );
        setOpenEndedBodyMap(convertToBodyStringMap(currentOpenEndedBodyMap));
        if (constructedMap["openEndedSolutionMap"]) {
          const currentOpenEndedSolutionMap = JSON.parse(
            constructedMap["openEndedSolutionMap"]
          );
          setOpenEndedSolutionMap(
            convertToSolutionStringMap(currentOpenEndedSolutionMap)
          );
        }
        if (constructedMap["openEndedEvaluationRubricMap"]) {
          const currentOpenEndedEvaluationRubricMap = JSON.parse(
            constructedMap["openEndedEvaluationRubricMap"]
          );
          setOpenEndedEvaluationRubricMap(
            convertToEvaluationRubricStringMap(
              currentOpenEndedEvaluationRubricMap
            )
          );
        }
        const currentOpenEndedMaterialMap = JSON.parse(
          constructedMap["openEndedMaterialMap"]
        );
        setOpenEndedMaterialMap(currentOpenEndedMaterialMap);
        const currentOpenEndedTaxonomyMap = JSON.parse(
          constructedMap["openEndedTaxonomyMap"]
        );
        setOpenEndedTaxonomyMap(currentOpenEndedTaxonomyMap);
      }
      if (
        data.activityTemplateDTOList.find(
          (activityTemplateDTO) => activityTemplateDTO.type === "TRUE_FALSE"
        )
      ) {
        const currentTrueFalseBodyMap = JSON.parse(
          constructedMap["trueFalseBodyMap"]
        );
        setTrueFalseBodyMap(convertToBodyStringMap(currentTrueFalseBodyMap));
        if (constructedMap["trueFalseSolutionMap"]) {
          const currentTrueFalseSolutionMap = JSON.parse(
            constructedMap["trueFalseSolutionMap"]
          );
          setTrueFalseSolutionMap(
            convertToSolutionStringMap(currentTrueFalseSolutionMap)
          );
        }
        const currentConstructedMap = JSON.parse(
          constructedMap["trueFalseMaterialMap"]
        );
        currentConstructedMap.trueList = JSON.parse(
          currentConstructedMap.trueList
        );
        currentConstructedMap.falseList = JSON.parse(
          currentConstructedMap.falseList
        );
        setTrueFalseMaterialMap(currentConstructedMap);
        const currentTrueFalseTaxonomyMap = JSON.parse(
          constructedMap["trueFalseTaxonomyMap"]
        );
        setTrueFalseTaxonomyMap(currentTrueFalseTaxonomyMap);
      }
      setLoadingCompleted(true);
    };
    if (!id) return;
    retrieveAndSetSpecificActivity();
  }, [id]);

  useEffect(() => {
    if (id && catchtivityStompClient) {
      const subscribed = catchtivityStompClient.subscribe(
        "/topic/activity",
        (message) => {
          let { body } = message;
          body = JSON.parse(body);
          if (body.state === "UPDATED" && body.activityId === id) {
            // retrieveAndSetActivityPageByFilter();
            // retrieveAndSetActivityDTOList();
            if (body.username !== userInformation.username) {
              catchtivityStompClient.unsubscribe(subscribedId);
              setSubscribedId(null);
              handleSameActivityUpdatedBySomeoneAction();
              setSomeoneInformation({
                username: body.username,
                firstName: body.firstName,
                lastName: body.lastName,
              });
              setShowSomeoneUpdatedModal(true);
            }
          }
        }
      );
      setSubscribedId(subscribed.id);
    }
  }, [id]);

  useEffect(() => {
    const handleUploadParsedActivityImageData = async (
      coterieType,
      documentType,
      imageData
    ) => {
      const formData = new FormData();
      formData.append("brandId", userProfileBrand.id);
      formData.append("userId", userInformation.id);
      formData.append("userProfileId", userProfile.id);
      formData.append("coterieType", coterieType);
      formData.append("documentType", documentType);
      formData.append(
        "path",
        `/${userProfileBrand.id}/${documentType.toLowerCase()}s`
      );
      formData.append(
        "file",
        convertDataURLtoFile(imageData, `${new Date().getTime()}.jpeg`)
      );
      formData.append("changeFileName", true);
      const { data, err } = await uploadFileToPath(formData);
      if (err) {
        NotificationManager.error(i18n.t("uploading_cropped_image_has_failed"));
        return;
      }
      setUploadedFileName(data.fileName);
      NotificationManager.success(
        i18n.t("uploading_cropped_image_has_successfully_completed")
      );
      return data;
    };

    const handleProcessImageToTextWithAI = async (documentType, imageData) => {
      let imageUrl = "";
      if (process.env.REACT_APP_NODE_ENV === "development") {
        imageUrl += ".";
      }
      imageUrl += `./catchup-storage/temp/${
        userProfileBrand.id
      }/${documentType.toLowerCase()}s`;
      const { data, err } = await processImageToMCSAWithAI({
        imageData,
        temperature: 0,
        userId: userInformation.id,
        userProfileId: userProfile.id,
        brandId: userProfileBrand.id,
        campusId: userProfileCampus?.id,
        institutionId: userProfileInstitution?.id,
      });
      if (err) {
        NotificationManager.error(
          i18n.t("uploaded_cropped_image_process_image_to_text_has_failed")
        );
        return;
      }
      NotificationManager.success(
        i18n.t(
          "uploaded_cropped_image_process_image_to_text_has_successfully_completed"
        )
      );
      const { mcsaBodyMap, mcsaMaterialMap, mcsaTaxonomy } = data;
      setMCSABodyMap(mcsaBodyMap);
      setMCSAMaterialMap(mcsaMaterialMap);
      if (mcsaTaxonomy) {
        const { name } = mcsaTaxonomy;
        let currentName = "";
        let currentStage = Number.MAX_SAFE_INTEGER;
        let currentDistance = Number.MAX_SAFE_INTEGER;
        retrieveFilteredTaxonomyName(MCSATaxonomyMap.groupName).forEach(
          (taxonomy) => {
            const calculatedDistance = calculateLevenshteinDistance(
              name.toUpperCase().trim(),
              taxonomy.value.toUpperCase()
            );
            if (calculatedDistance < currentDistance) {
              currentDistance = calculatedDistance;
              currentName = taxonomy.value;
              currentStage = taxonomy.stage;
            }
          }
        );
        setMCSATaxonomyMap({
          type: MCSATaxonomyMap.type,
          groupName: MCSATaxonomyMap.groupName,
          name: currentName,
          stage: currentStage,
        });
      }
    };
    const handleUploadParsedActivityImageAndProcessToText = async (
      coterieType,
      imageData
    ) => {
      const data = await handleUploadParsedActivityImageData(
        coterieType,
        "IMAGE",
        imageData
      );
      if (data) {
        // await handleProcessImageToText("IMAGE", data.fileName);
        await handleProcessImageToTextWithAI("IMAGE", imageData);
      }
    };
    if (parsedActivity) {
      const {
        imageData,
        classification,
        name,
        description,
        activityId,
        selectedCoterieType,
        selectedLevel,
        selectedVersionCode,
        selectedActivityCategoryList,
        dropdownBodyMap,
        dropdownMaterialMap,
        dropdownTaxonomyMap,
        mcsaMaterialMap,
        mcsaBodyMap,
        mcsaTaxonomyMap,
        mcmaBodyMap,
        mcmaMaterialMap,
        mcmaTaxonomyMap,
        matchingBodyMap,
        matchingMaterialMap,
        matchingTaxonomyMap,
        groupingBodyMap,
        groupingMaterialMap,
        groupingTaxonomyMap,
        fillInTheBlanksBodyMap,
        fillInTheBlanksMaterialMap,
        fillInTheBlanksIncorrectList,
        fillInTheBlanksTaxonomyMap,
        openEndedBodyMap,
        openEndedMaterialMap,
        openEndedTaxonomyMap,
        trueFalseBodyMap,
        trueFalseMaterialMap,
        trueFalseTaxonomyMap,
      } = parsedActivity;
      if (!activityId) {
        setName(name);
        setDescription(description);
        setSelectedCoterieType(selectedCoterieType);
        setSelectedLevel(selectedLevel);
        setSelectedVersionCode(selectedVersionCode);
        if (selectedActivityCategoryList) {
          setActivityCategoryList(selectedActivityCategoryList);
        }
        if (dropdownBodyMap) {
          setDropdownBodyMap(dropdownBodyMap);
        }
        if (dropdownMaterialMap) {
          setDropdownMaterialMap(dropdownMaterialMap);
        }
        if (dropdownTaxonomyMap) {
          setDropdownTaxonomyMap(dropdownTaxonomyMap);
        }
        if (mcsaBodyMap) {
          setMCSABodyMap(mcsaBodyMap);
        }
        if (mcsaMaterialMap) {
          setMCSAMaterialMap(mcsaMaterialMap);
        }
        if (mcsaTaxonomyMap) {
          setMCSATaxonomyMap(mcsaTaxonomyMap);
        }
        if (mcmaMaterialMap) {
          setMCMAMaterialMap(mcmaMaterialMap);
        }
        if (mcmaBodyMap) {
          setMCMABodyMap(mcmaBodyMap);
        }
        if (mcmaTaxonomyMap) {
          setMCMATaxonomyMap(mcmaTaxonomyMap);
        }
        if (matchingBodyMap) {
          setMatchingBodyMap(matchingBodyMap);
        }
        if (matchingMaterialMap) {
          setMatchingMaterialMap(matchingMaterialMap);
        }
        if (matchingTaxonomyMap) {
          setMatchingTaxonomyMap(matchingTaxonomyMap);
        }
        if (groupingBodyMap) {
          setGroupingBodyMap(groupingBodyMap);
        }
        if (groupingMaterialMap) {
          setGroupingMaterialMap(groupingMaterialMap);
        }
        if (groupingTaxonomyMap) {
          setGroupingTaxonomyMap(groupingTaxonomyMap);
        }
        if (fillInTheBlanksBodyMap) {
          setFillInTheBlanksBodyMap(fillInTheBlanksBodyMap);
        }
        if (fillInTheBlanksMaterialMap) {
          setFillInTheBlanksMaterialMap(fillInTheBlanksMaterialMap);
        }
        if (fillInTheBlanksIncorrectList) {
          setFillInTheBlanksIncorrectList(fillInTheBlanksIncorrectList);
        }
        if (fillInTheBlanksTaxonomyMap) {
          setFillInTheBlanksTaxonomyMap(fillInTheBlanksTaxonomyMap);
        }
        if (openEndedBodyMap) {
          setOpenEndedBodyMap(openEndedBodyMap);
        }
        if (openEndedMaterialMap) {
          setOpenEndedMaterialMap(openEndedMaterialMap);
        }
        if (openEndedTaxonomyMap) {
          setOpenEndedTaxonomyMap(openEndedTaxonomyMap);
        }
        if (trueFalseBodyMap) {
          setTrueFalseBodyMap(trueFalseBodyMap);
        }
        if (trueFalseMaterialMap) {
          setTrueFalseMaterialMap(trueFalseMaterialMap);
        }
        if (trueFalseTaxonomyMap) {
          setTrueFalseTaxonomyMap(trueFalseTaxonomyMap);
        }

        setSelectedMaterialContentType("TEXT");
        const foundActivityTemplate = activityTemplateSet.find(
          (activityTemplate) => activityTemplate.type === "MCSA"
        );
        if (foundActivityTemplate) {
          setActivityTemplateIdList([foundActivityTemplate.id]);
          setSelectedActivityTemplateId(foundActivityTemplate.id);

          if (imageData) {
            handleUploadParsedActivityImageAndProcessToText(
              selectedCoterieType,
              imageData
            );
          }
        }
      }
    }
  }, [parsedActivity]);

  useEffect(() => {
    retrieveAndSetTokenAssignmentList();
    setCanSolveActivity(true);
  }, []);

  useEffect(() => {
    if (!loadingCompleted) return;
    for (const key of Object.keys(orderingBodyMap)) {
      const { type, value } = orderingBodyMap[key];
      if (type === "IMAGE") {
        if (!activityTemplateTypeImageMap["ORDERING"].includes(value)) {
          activityTemplateTypeImageMap["ORDERING"].push(value);
        }
      }
    }
    for (const key of Object.keys(dropdownBodyMap)) {
      const { type, value } = dropdownBodyMap[key];
      if (type === "IMAGE") {
        if (!activityTemplateTypeImageMap["DROPDOWN"].includes(value)) {
          activityTemplateTypeImageMap["DROPDOWN"].push(value);
        }
      }
    }
    for (const key of Object.keys(MCSABodyMap)) {
      const { type, value } = MCSABodyMap[key];
      if (type === "IMAGE") {
        if (!activityTemplateTypeImageMap["MCSA"].includes(value)) {
          activityTemplateTypeImageMap["MCSA"].push(value);
        }
      }
    }
    for (const key of Object.keys(MCMABodyMap)) {
      const { type, value } = MCMABodyMap[key];
      if (type === "IMAGE") {
        if (!activityTemplateTypeImageMap["MCMA"].includes(value)) {
          activityTemplateTypeImageMap["MCMA"].push(value);
        }
      }
    }
    for (const key of Object.keys(matchingBodyMap)) {
      const { type, value } = matchingBodyMap[key];
      if (type === "IMAGE") {
        if (!activityTemplateTypeImageMap["MATCHING"].includes(value)) {
          activityTemplateTypeImageMap["MATCHING"].push(value);
        }
      }
    }
    for (const key of Object.keys(groupingBodyMap)) {
      const { type, value } = groupingBodyMap[key];
      if (type === "IMAGE") {
        if (!activityTemplateTypeImageMap["GROUPING"].includes(value)) {
          activityTemplateTypeImageMap["GROUPING"].push(value);
        }
      }
    }
    for (const key of Object.keys(fillInTheBlanksBodyMap)) {
      const { type, value } = fillInTheBlanksBodyMap[key];
      if (type === "IMAGE") {
        if (
          !activityTemplateTypeImageMap["FILL_IN_THE_BLANKS"].includes(value)
        ) {
          activityTemplateTypeImageMap["FILL_IN_THE_BLANKS"].push(value);
        }
      }
    }
    for (const key of Object.keys(openEndedBodyMap)) {
      const { type, value } = openEndedBodyMap[key];
      if (type === "IMAGE") {
        if (!activityTemplateTypeImageMap["OPEN_ENDED"].includes(value)) {
          activityTemplateTypeImageMap["OPEN_ENDED"].push(value);
        }
      }
    }
    for (const key of Object.keys(trueFalseBodyMap)) {
      const { type, value } = trueFalseBodyMap[key];
      if (type === "IMAGE") {
        if (!activityTemplateTypeImageMap["TRUE_FALSE"].includes(value)) {
          activityTemplateTypeImageMap["TRUE_FALSE"].push(value);
        }
      }
    }
    setActivityTemplateTypeImageMap(
      JSON.parse(JSON.stringify(activityTemplateTypeImageMap))
    );
  }, [
    loadingCompleted,
    orderingBodyMap,
    dropdownBodyMap,
    MCSABodyMap,
    MCMABodyMap,
    matchingBodyMap,
    groupingBodyMap,
    fillInTheBlanksBodyMap,
    openEndedBodyMap,
    trueFalseBodyMap,
  ]);

  useEffect(() => {
    const activityTemplateTypeImageMapKeys = Object.keys(
      activityTemplateTypeImageMap
    );
    if (activityTemplateTypeImageMapKeys.length === 0) return;
    const currentImageSet = new Set();
    for (const activityTemplateType of activityTemplateTypeImageMapKeys) {
      activityTemplateTypeImageMap[activityTemplateType].forEach((value) =>
        currentImageSet.add(value)
      );
    }
    const currentImageList = Array.from(currentImageSet);
    setImageList(currentImageList);
  }, [activityTemplateTypeImageMap]);

  useEffect(() => {
    const retrieveAndSetImageContentList = async () => {
      const fileNameList = imageList.map((image) =>
        image.substring(image.lastIndexOf("/") + 1, image.length)
      );
      const { data, err } = await queryImageTranslateListByParams({
        fileNameList,
      });
      if (err) {
        console.log(err);
        return;
      }
      const currentList = [];
      for (const item of data) {
        const { id, content, mainFile, learnerFile, toBeLabelledFile } = item;
        let fileName;
        if (mainFile) {
          fileName = mainFile.fileName;
        } else if (learnerFile) {
          fileName = learnerFile.fileName;
        } else if (toBeLabelledFile) {
          fileName = toBeLabelledFile.fileName;
        }
        currentList.push({
          id,
          fileName,
          url: `${connection.base.storage.url}/storage-files/preview/file/${userProfileBrand.id}/images/${fileName}`,
          content,
        });
        setImageContentList(currentList);
      }
    };
    if (imageList.length === 0) return;
    retrieveAndSetImageContentList();
  }, [imageList]);

  const retrieveAndSetTokenAssignmentList = async () => {
    setCanGenerateActivityTemplate(false);
    const { data, err } = await queryTokenAssignmentDTOListByParams({
      brandId: userProfileBrand.id,
      campusId: userProfileCampus?.id,
      institutionId: userProfileInstitution?.id,
      type: "AI",
      subType: "ACTIVITY_TEMPLATE_GENERATION",
    });
    if (err) {
      console.log(err);
    } else {
      if (data.length > 0) setCanGenerateActivityTemplate(true);
    }
  };

  const retrieveCategoryDTOOptionList = () => {
    const filteredCategoryDTOList = [];
    let filteredMainCategoryDTOList = [];
    if (subCategoryFilter) {
      filteredMainCategoryDTOList = categorySet.filter(
        (categoryDTO) =>
          parseFloat(categoryDTO.level) === parseFloat(selectedLevel) &&
          categoryDTO.versionCode === selectedVersionCode
      );
    } else {
      filteredMainCategoryDTOList = categorySet.filter(
        (categoryDTO) => categoryDTO.versionCode === selectedVersionCode
      );
    }

    for (const currentCategoryDTO of filteredMainCategoryDTOList) {
      const foundIndex = filteredMainCategoryDTOList.findIndex(
        (categoryDTO) => categoryDTO.parentCode === currentCategoryDTO.code
      );
      if (foundIndex === -1) {
        filteredCategoryDTOList.push(currentCategoryDTO);
      }
    }

    return filteredCategoryDTOList
      .sort((a, b) => a.code.localeCompare(b.code))
      .map((categoryDTO) => ({
        value: categoryDTO.id,
        text: `${categoryDTO.code} - ${categoryDTO.name}`,
      }));
  };

  const retrieveFilteredTaxonomyGroupName = (type) => {
    return retrieveTaxonomyGroupName().filter(
      (taxonomyGroupName) => taxonomyGroupName.type === type
    );
  };

  const retrieveFilteredTaxonomyName = (groupName) => {
    return retrieveTaxonomyName().filter(
      (taxonomyName) => taxonomyName.groupName === groupName
    );
  };

  const retrieveMediaSizeOptionList = () => {
    return [
      {
        text: "%33",
        value: "1/3",
      },
      {
        text: "%50",
        value: "1/2",
      },
      {
        text: "%100",
        value: "1",
      },
    ];
  };

  const retrieveCurrentSolutionMap = () => {
    const foundActivityTemplate = activityTemplateSet.find(
      (activityTemplateDTO) =>
        activityTemplateDTO.id === parseFloat(selectedActivityTemplateId)
    );
    if (!foundActivityTemplate) return {};
    if (foundActivityTemplate.type === "ORDERING") {
      return orderingSolutionMap;
    } else if (foundActivityTemplate.type === "DROPDOWN") {
      return dropdownSolutionMap;
    } else if (foundActivityTemplate.type === "MCSA") {
      return MCSASolutionMap;
    } else if (foundActivityTemplate.type === "MCMA") {
      return MCMASolutionMap;
    } else if (foundActivityTemplate.type === "MATCHING") {
      return matchingSolutionMap;
    } else if (foundActivityTemplate.type === "GROUPING") {
      return groupingBodyMap;
    } else if (foundActivityTemplate.type === "FILL_IN_THE_BLANKS") {
      return fillInTheBlanksSolutionMap;
    } else if (foundActivityTemplate.type === "OPEN_ENDED") {
      return openEndedSolutionMap;
    } else if (foundActivityTemplate.type === "TRUE_FALSE") {
      return trueFalseSolutionMap;
    } else {
      return {};
    }
  };

  const retrieveInnerActivityStateOptionList = () => {
    return [
      {
        id: "ACTIVITY_SOLUTION",
        title: i18n.t("activity_solution"),
      },
      {
        id: "ACTIVITY_EVALUATION_RUBRIC",
        title: i18n.t("activity_evaluation_rubric"),
      },
    ];
  };

  const findActivityTemplate = (type) => {
    return activityTemplateSet.find(
      (activityTemplateTO) => activityTemplateTO.type === type
    );
  };

  const convertToBodyMapString = (type) => {
    const convertedBodyMap = {};
    if (type === "ORDERING") {
      Object.keys(orderingBodyMap).forEach(
        (orderingBodyMapKey) =>
          (convertedBodyMap[orderingBodyMapKey] = JSON.stringify(
            orderingBodyMap[orderingBodyMapKey]
          ))
      );
    } else if (type === "DROPDOWN") {
      Object.keys(dropdownBodyMap).forEach(
        (dropdownBodyMapKey) =>
          (convertedBodyMap[dropdownBodyMapKey] = JSON.stringify(
            dropdownBodyMap[dropdownBodyMapKey]
          ))
      );
    } else if (type === "MCSA") {
      Object.keys(MCSABodyMap).forEach(
        (MCSABodyMapKey) =>
          (convertedBodyMap[MCSABodyMapKey] = JSON.stringify(
            MCSABodyMap[MCSABodyMapKey]
          ))
      );
    } else if (type === "MCMA") {
      Object.keys(MCMABodyMap).forEach(
        (MCMABodyMapKey) =>
          (convertedBodyMap[MCMABodyMapKey] = JSON.stringify(
            MCMABodyMap[MCMABodyMapKey]
          ))
      );
    } else if (type === "MATCHING") {
      Object.keys(matchingBodyMap).forEach(
        (matchingBodyMapKey) =>
          (convertedBodyMap[matchingBodyMapKey] = JSON.stringify(
            matchingBodyMap[matchingBodyMapKey]
          ))
      );
    } else if (type === "GROUPING") {
      Object.keys(groupingBodyMap).forEach(
        (groupingBodyMapKey) =>
          (convertedBodyMap[groupingBodyMapKey] = JSON.stringify(
            groupingBodyMap[groupingBodyMapKey]
          ))
      );
    } else if (type === "FILL_IN_THE_BLANKS") {
      Object.keys(fillInTheBlanksBodyMap).forEach(
        (fillInTheBlanksBodyMapKey) =>
          (convertedBodyMap[fillInTheBlanksBodyMapKey] = JSON.stringify(
            fillInTheBlanksBodyMap[fillInTheBlanksBodyMapKey]
          ))
      );
    } else if (type === "OPEN_ENDED") {
      Object.keys(openEndedBodyMap).forEach(
        (openEndedBodyMapKey) =>
          (convertedBodyMap[openEndedBodyMapKey] = JSON.stringify(
            openEndedBodyMap[openEndedBodyMapKey]
          ))
      );
    } else if (type === "TRUE_FALSE") {
      Object.keys(trueFalseBodyMap).forEach(
        (trueFalseBodyMapKey) =>
          (convertedBodyMap[trueFalseBodyMapKey] = JSON.stringify(
            trueFalseBodyMap[trueFalseBodyMapKey]
          ))
      );
    }
    return convertedBodyMap;
  };

  const convertToSolutionMapString = (type) => {
    const convertedSolutionMap = {};
    if (type === "ORDERING") {
      Object.keys(orderingSolutionMap).forEach(
        (orderingSolutionMapKey) =>
          (convertedSolutionMap[orderingSolutionMapKey] = JSON.stringify(
            orderingSolutionMap[orderingSolutionMapKey]
          ))
      );
    } else if (type === "DROPDOWN") {
      Object.keys(dropdownSolutionMap).forEach(
        (dropdownSolutionMapKey) =>
          (convertedSolutionMap[dropdownSolutionMapKey] = JSON.stringify(
            dropdownSolutionMap[dropdownSolutionMapKey]
          ))
      );
    } else if (type === "MCSA") {
      Object.keys(MCSASolutionMap).forEach(
        (MCSASolutionMapKey) =>
          (convertedSolutionMap[MCSASolutionMapKey] = JSON.stringify(
            MCSASolutionMap[MCSASolutionMapKey]
          ))
      );
    } else if (type === "MCMA") {
      Object.keys(MCMASolutionMap).forEach(
        (MCMASolutionMapKey) =>
          (convertedSolutionMap[MCMASolutionMapKey] = JSON.stringify(
            MCMASolutionMap[MCMASolutionMapKey]
          ))
      );
    } else if (type === "MATCHING") {
      Object.keys(matchingSolutionMap).forEach(
        (matchingSolutionMapKey) =>
          (convertedSolutionMap[matchingSolutionMapKey] = JSON.stringify(
            matchingSolutionMap[matchingSolutionMapKey]
          ))
      );
    } else if (type === "GROUPING") {
      Object.keys(groupingSolutionMap).forEach(
        (groupingSolutionMapKey) =>
          (convertedSolutionMap[groupingSolutionMapKey] = JSON.stringify(
            groupingSolutionMap[groupingSolutionMapKey]
          ))
      );
    } else if (type === "FILL_IN_THE_BLANKS") {
      Object.keys(fillInTheBlanksSolutionMap).forEach(
        (fillInTheBlanksSolutionMapKey) =>
          (convertedSolutionMap[fillInTheBlanksSolutionMapKey] = JSON.stringify(
            fillInTheBlanksSolutionMap[fillInTheBlanksSolutionMapKey]
          ))
      );
    } else if (type === "OPEN_ENDED") {
      Object.keys(openEndedSolutionMap).forEach(
        (openEndedSolutionMapKey) =>
          (convertedSolutionMap[openEndedSolutionMapKey] = JSON.stringify(
            openEndedSolutionMap[openEndedSolutionMapKey]
          ))
      );
    } else if (type === "TRUE_FALSE") {
      Object.keys(trueFalseSolutionMap).forEach(
        (trueFalseSolutionMapKey) =>
          (convertedSolutionMap[trueFalseSolutionMapKey] = JSON.stringify(
            trueFalseSolutionMap[trueFalseSolutionMapKey]
          ))
      );
    }
    return convertedSolutionMap;
  };

  const convertToEvaluationRubricMapString = (type) => {
    const convertedEvaluationRubricMap = {};
    if (type === "ORDERING") {
    } else if (type === "DROPDOWN") {
    } else if (type === "MCSA") {
    } else if (type === "MCMA") {
    } else if (type === "MATCHING") {
    } else if (type === "GROUPING") {
    } else if (type === "FILL_IN_THE_BLANKS") {
    } else if (type === "OPEN_ENDED") {
      Object.keys(openEndedEvaluationRubricMap).forEach(
        (openEndedEvaluationRubricMapKey) =>
          (convertedEvaluationRubricMap[openEndedEvaluationRubricMapKey] =
            JSON.stringify(
              openEndedEvaluationRubricMap[openEndedEvaluationRubricMapKey]
            ))
      );
    } else if (type === "TRUE_FALSE") {
    }
    return convertedEvaluationRubricMap;
  };

  const convertToBodyStringMap = (bodyMapString) => {
    const convertedBodyMap = {};
    Object.keys(bodyMapString).forEach((bodyMapStringKey) => {
      convertedBodyMap[bodyMapStringKey] = JSON.parse(
        bodyMapString[bodyMapStringKey]
      );
    });
    return convertedBodyMap;
  };

  const convertToSolutionStringMap = (solutionMapString) => {
    const convertedBodyMap = {};
    Object.keys(solutionMapString).forEach((solutionMapStringKey) => {
      convertedBodyMap[solutionMapStringKey] = JSON.parse(
        solutionMapString[solutionMapStringKey]
      );
    });
    return convertedBodyMap;
  };

  const convertToEvaluationRubricStringMap = (evaluationRubricMapString) => {
    const convertedBodyMap = {};
    Object.keys(evaluationRubricMapString).forEach((evaluationRubricMapKey) => {
      convertedBodyMap[evaluationRubricMapKey] = JSON.parse(
        evaluationRubricMapString[evaluationRubricMapKey]
      );
    });
    return convertedBodyMap;
  };

  const constructActivityData = (activityTemplateType, constructedData) => {
    if (activityTemplateType === "ORDERING") {
      constructedData["orderingBodyMap"] = JSON.stringify(
        convertToBodyMapString("ORDERING")
      );
      constructedData["orderingSolutionMap"] = JSON.stringify(
        convertToSolutionMapString("ORDERING")
      );
      constructedData["orderingMaterialMap"] =
        JSON.stringify(orderingMaterialMap);
      constructedData["orderingTaxonomyMap"] =
        JSON.stringify(orderingTaxonomyMap);
    } else if (activityTemplateType === "DROPDOWN") {
      constructedData["dropdownBodyMap"] = JSON.stringify(
        convertToBodyMapString("DROPDOWN")
      );
      constructedData["dropdownSolutionMap"] = JSON.stringify(
        convertToSolutionMapString("DROPDOWN")
      );
      const convertedDropdownMaterialMap = {};
      Object.keys(dropdownMaterialMap).forEach((key) => {
        const answerKey = Object.keys(dropdownMaterialMap[key])[0];
        const valueList = dropdownMaterialMap[key][answerKey];
        convertedDropdownMaterialMap[key] = JSON.stringify({
          [answerKey]: JSON.stringify(valueList),
        });
      });
      constructedData["dropdownMaterialMap"] = JSON.stringify(
        convertedDropdownMaterialMap
      );
      constructedData["dropdownTaxonomyMap"] =
        JSON.stringify(dropdownTaxonomyMap);
    } else if (activityTemplateType === "MCSA") {
      constructedData["MCSABodyMap"] = JSON.stringify(
        convertToBodyMapString("MCSA")
      );
      constructedData["MCSASolutionMap"] = JSON.stringify(
        convertToSolutionMapString("MCSA")
      );
      const answerKey = Object.keys(MCSAMaterialMap)[0];
      const convertedMCSAMaterialMap = {
        [answerKey]: JSON.stringify(MCSAMaterialMap[answerKey]),
      };
      constructedData["MCSAMaterialMap"] = JSON.stringify(
        convertedMCSAMaterialMap
      );
      constructedData["MCSATaxonomyMap"] = JSON.stringify(MCSATaxonomyMap);
    } else if (activityTemplateType === "MCMA") {
      constructedData["MCMABodyMap"] = JSON.stringify(
        convertToBodyMapString("MCMA")
      );
      constructedData["MCMASolutionMap"] = JSON.stringify(
        convertToSolutionMapString("MCMA")
      );
      const answerKeys = Object.keys(MCMAMaterialMap)[0];
      const splittedAnswerKeyList = answerKeys.split("§");
      const convertedMCMAMaterialMap = {
        [JSON.stringify(splittedAnswerKeyList)]: JSON.stringify(
          MCMAMaterialMap[answerKeys]
        ),
      };
      constructedData["MCMAMaterialMap"] = JSON.stringify(
        convertedMCMAMaterialMap
      );
      constructedData["MCMATaxonomyMap"] = JSON.stringify(MCMATaxonomyMap);
    } else if (activityTemplateType === "MATCHING") {
      constructedData["matchingBodyMap"] = JSON.stringify(
        convertToBodyMapString("MATCHING")
      );
      constructedData["matchingSolutionMap"] = JSON.stringify(
        convertToSolutionMapString("MATCHING")
      );
      const convertedMatchingMaterialMap = {};
      Object.keys(matchingMaterialMap).forEach((key) => {
        const subKey = Object.keys(matchingMaterialMap[key])[0];
        const value = matchingMaterialMap[key][subKey];
        convertedMatchingMaterialMap[subKey] = value;
      });
      constructedData["matchingMaterialMap"] = JSON.stringify(
        convertedMatchingMaterialMap
      );
      constructedData["matchingTaxonomyMap"] =
        JSON.stringify(matchingTaxonomyMap);
    } else if (activityTemplateType === "GROUPING") {
      constructedData["groupingBodyMap"] = JSON.stringify(
        convertToBodyMapString("GROUPING")
      );
      constructedData["groupingSolutionMap"] = JSON.stringify(
        convertToSolutionMapString("GROUPING")
      );
      const convertedGroupingMaterialMap = {};
      Object.keys(groupingMaterialMap).forEach((key) => {
        const subKey = Object.keys(groupingMaterialMap[key])[0];
        const valueList = groupingMaterialMap[key][subKey];
        convertedGroupingMaterialMap[subKey] = JSON.stringify(valueList);
      });
      constructedData["groupingMaterialMap"] = JSON.stringify(
        convertedGroupingMaterialMap
      );
      constructedData["groupingTaxonomyMap"] =
        JSON.stringify(groupingTaxonomyMap);
    } else if (activityTemplateType === "FILL_IN_THE_BLANKS") {
      constructedData["fillInTheBlanksBodyMap"] = JSON.stringify(
        convertToBodyMapString("FILL_IN_THE_BLANKS")
      );
      constructedData["fillInTheBlanksSolutionMap"] = JSON.stringify(
        convertToSolutionMapString("FILL_IN_THE_BLANKS")
      );
      constructedData["fillInTheBlanksIncorrectList"] = JSON.stringify(
        fillInTheBlanksIncorrectList
      );
      const convertedFillInTheBlanksMaterialMap = {};
      Object.keys(fillInTheBlanksMaterialMap).forEach((key) => {
        convertedFillInTheBlanksMaterialMap[key] = JSON.stringify(
          fillInTheBlanksMaterialMap[key]
        );
      });
      constructedData["fillInTheBlanksMaterialMap"] = JSON.stringify(
        convertedFillInTheBlanksMaterialMap
      );
      constructedData["fillInTheBlanksTaxonomyMap"] = JSON.stringify(
        fillInTheBlanksTaxonomyMap
      );
    } else if (activityTemplateType === "OPEN_ENDED") {
      constructedData["openEndedBodyMap"] = JSON.stringify(
        convertToBodyMapString("OPEN_ENDED")
      );
      constructedData["openEndedSolutionMap"] = JSON.stringify(
        convertToSolutionMapString("OPEN_ENDED")
      );
      constructedData["openEndedEvaluationRubricMap"] = JSON.stringify(
        convertToEvaluationRubricMapString("OPEN_ENDED")
      );
      constructedData["openEndedMaterialMap"] =
        JSON.stringify(openEndedMaterialMap);

      constructedData["openEndedTaxonomyMap"] =
        JSON.stringify(openEndedTaxonomyMap);
    } else if (activityTemplateType === "TRUE_FALSE") {
      constructedData["trueFalseBodyMap"] = JSON.stringify(
        convertToBodyMapString("TRUE_FALSE")
      );
      constructedData["trueFalseSolutionMap"] = JSON.stringify(
        convertToSolutionMapString("TRUE_FALSE")
      );
      const convertTrueFalseMaterialMap = {
        trueList: JSON.stringify(trueFalseMaterialMap.trueList),
        falseList: JSON.stringify(trueFalseMaterialMap.falseList),
      };
      constructedData["trueFalseMaterialMap"] = JSON.stringify(
        convertTrueFalseMaterialMap
      );
      constructedData["trueFalseTaxonomyMap"] =
        JSON.stringify(trueFalseTaxonomyMap);
    }
  };

  const checkActivityTemplateExists = (type) => {
    const foundTemplate = findActivityTemplate(type);
    if (foundTemplate) {
      const foundActivityTemplateId = activityTemplateIdList.find(
        (activityTemplateId) =>
          parseFloat(activityTemplateId) === parseFloat(foundTemplate.id)
      );
      if (foundActivityTemplateId) {
        return true;
      }
      return false;
    }
    return false;
  };

  const checkCommonValidation = () => {
    if (
      !name ||
      name.trim() === "" ||
      !description ||
      description.trim() === "" ||
      selectedStatus === "DEFAULT_OPTION" ||
      selectedCoterieType === "DEFAULT_OPTION" ||
      selectedLevel === "DEFAULT_OPTION" ||
      selectedDurationType === "DEFAULT_OPTION" ||
      (selectedDurationType === "EACH" &&
        selectedDurationInSeconds === "DEFAULT_OPTION")
    ) {
      const currentErrorMap = {
        name: null,
        description: null,
        status: null,
        coterieType: null,
        level: null,
        durationType: null,
        durationInSeconds: null,
      };
      if (!name || name.trim() === "") {
        currentErrorMap.name = i18n.t("name_required_field");
      }
      if (!description || description.trim() === "") {
        currentErrorMap.description = i18n.t("description_required_field");
      }
      if (selectedStatus === "DEFAULT_OPTION") {
        currentErrorMap.status = i18n.t("status_required_field");
      }
      if (selectedCoterieType === "DEFAULT_OPTION") {
        currentErrorMap.coterieType = i18n.t("coterie_type_required_field");
      }
      if (selectedLevel === "DEFAULT_OPTION") {
        currentErrorMap.level = i18n.t("level_required_field");
      }
      if (selectedDurationType === "DEFAULT_OPTION") {
        currentErrorMap.durationType = i18n.t("duration_type_required_field");
      }
      if (
        selectedDurationType === "EACH" &&
        selectedDurationInSeconds === "DEFAULT_OPTION"
      ) {
        currentErrorMap.durationInSeconds = i18n.t(
          "duration_in_seconds_required_field"
        );
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    if (activityCategoryList.length === 0) {
      NotificationManager.error(
        i18n.t("activity_category_list_cannot_be_empty")
      );
      return false;
    }
    for (const activityTemplateId of activityTemplateIdList) {
      const foundActivityTemplate = activityTemplateSet.find(
        (activityTemplateDTO) =>
          activityTemplateDTO.id === parseFloat(activityTemplateId)
      );
      let currentTaxonomyMap;
      if (foundActivityTemplate.type === "ORDERING") {
        currentTaxonomyMap = orderingTaxonomyMap;
      } else if (foundActivityTemplate.type === "DROPDOWN") {
        currentTaxonomyMap = dropdownTaxonomyMap;
      } else if (foundActivityTemplate.type === "MCSA") {
        currentTaxonomyMap = MCSATaxonomyMap;
      } else if (foundActivityTemplate.type === "MCMA") {
        currentTaxonomyMap = MCMATaxonomyMap;
      } else if (foundActivityTemplate.type === "MATCHING") {
        currentTaxonomyMap = matchingTaxonomyMap;
      } else if (foundActivityTemplate.type === "GROUPING") {
        currentTaxonomyMap = groupingTaxonomyMap;
      } else if (foundActivityTemplate.type === "FILL_IN_THE_BLANKS") {
        currentTaxonomyMap = fillInTheBlanksTaxonomyMap;
      } else if (foundActivityTemplate.type === "OPEN_ENDED") {
        currentTaxonomyMap = openEndedTaxonomyMap;
      } else if (foundActivityTemplate.type === "TRUE_FALSE") {
        currentTaxonomyMap = trueFalseTaxonomyMap;
      }
      if (
        Object.keys(currentTaxonomyMap).length === 0 ||
        currentTaxonomyMap.type === null ||
        currentTaxonomyMap.type === "DEFAULT_OPTION" ||
        currentTaxonomyMap.groupName === null ||
        currentTaxonomyMap.groupName === "DEFAULT_OPTION" ||
        currentTaxonomyMap.name === null ||
        currentTaxonomyMap.name === "DEFAULT_OPTION"
      ) {
        NotificationManager.error(
          i18n.t("activity_template_taxonomy_cannot_be_empty")
        );
        return false;
      }
    }
    for (const activityTemplateId of activityTemplateIdList) {
      const foundActivityTemplate = activityTemplateSet.find(
        (activityTemplateDTO) =>
          activityTemplateDTO.id === parseFloat(activityTemplateId)
      );
      if (
        foundActivityTemplate.type === "DROPDOWN" ||
        foundActivityTemplate.type === "FILL_IN_THE_BLANKS"
      ) {
        const { bodyPartCount, materialPartCount } =
          calculateActivityMaterialErrorCount(foundActivityTemplate.type);
        if (bodyPartCount !== materialPartCount) {
          NotificationManager.error(
            i18n.t("activity_body_and_material_does_not_match_error_text")
          );
          return false;
        }
      }
    }
    return true;
  };

  const checkCanShowInitialWithAI = () => {
    const { canConvertActivityTemplate } = findAISettingsFromCurrentProfile(
      userProfileBrand,
      userProfileCampus,
      userProfileInstitution
    );
    return canConvertActivityTemplate;
  };

  const checkCanShowCommonWithAI = () => {
    if (selectedMaterialContentType !== "TEXT") return false;
    const foundActivityTemplate = activityTemplateSet.find(
      (activityTemplate) => activityTemplate.id === selectedActivityTemplateId
    );
    if (!foundActivityTemplate) return false;
    if (
      foundActivityTemplate.type !== "ORDERING" &&
      foundActivityTemplate.type !== "DROPDOWN" &&
      foundActivityTemplate.type !== "MCSA" &&
      foundActivityTemplate.type !== "MCMA" &&
      foundActivityTemplate.type !== "MATCHING" &&
      foundActivityTemplate.type !== "GROUPING" &&
      foundActivityTemplate.type !== "OPEN_ENDED" &&
      foundActivityTemplate.type !== "FILL_IN_THE_BLANKS" &&
      foundActivityTemplate.type !== "TRUE_FALSE"
    )
      return false;

    if (foundActivityTemplate.type === "ORDERING") {
      if (Object.keys(orderingBodyMap).length > 0) return false;
      if (Object.keys(orderingMaterialMap).length > 0) return false;
    } else if (foundActivityTemplate.type === "DROPDOWN") {
      if (Object.keys(dropdownBodyMap).length > 0) return false;
      if (Object.keys(dropdownMaterialMap).length > 0) return false;
    } else if (foundActivityTemplate.type === "MCSA") {
      if (Object.keys(MCSABodyMap).length > 0) return false;
      if (MCSAMaterialMap[Object.keys(MCSAMaterialMap)[0]].length > 0)
        return false;
    } else if (foundActivityTemplate.type === "MCMA") {
      if (Object.keys(MCMABodyMap).length > 0) return false;
      if (MCMAMaterialMap[Object.keys(MCMAMaterialMap)[0]].length > 0)
        return false;
    } else if (foundActivityTemplate.type === "MATCHING") {
      if (Object.keys(matchingBodyMap).length > 0) return false;
      if (Object.keys(matchingMaterialMap).length > 0) return false;
    } else if (foundActivityTemplate.type === "GROUPING") {
      if (Object.keys(groupingBodyMap).length > 0) return false;
      if (Object.keys(groupingMaterialMap).length > 0) return false;
    } else if (foundActivityTemplate.type === "OPEN_ENDED") {
      if (Object.keys(openEndedBodyMap).length > 0) return false;
      if (Object.keys(openEndedMaterialMap).length > 0) return false;
    } else if (foundActivityTemplate.type === "FILL_IN_THE_BLANKS") {
      if (Object.keys(fillInTheBlanksBodyMap).length > 0) return false;
      if (Object.keys(fillInTheBlanksMaterialMap).length > 0) return false;
    } else if (foundActivityTemplate.type === "TRUE_FALSE") {
      if (Object.keys(trueFalseBodyMap).length > 0) return false;
      if (Object.keys(trueFalseMaterialMap.trueList).length > 0) return false;
      if (Object.keys(trueFalseMaterialMap.falseList).length > 0) return false;
    }
    return true;
  };

  const checkCanShowConvertWithAI = () => {
    const canShowCommonWithAI = checkCanShowCommonWithAI();
    if (!canShowCommonWithAI) return false;
    const foundMCSAActivityTemplate = activityTemplateSet.find(
      (activityTemplate) => activityTemplate.type === "MCSA"
    );
    const foundActivityTemplateId = activityTemplateIdList.find(
      (activityTemplateId) =>
        parseFloat(activityTemplateId) === foundMCSAActivityTemplate.id
    );
    if (!foundActivityTemplateId) return false;
    if (Object.keys(MCSABodyMap).length === 0) return false;
    if (
      Object.keys(MCSABodyMap).filter(
        (key) =>
          MCSABodyMap[key].type === "VIDEO" || MCSABodyMap[key].type === "AUDIO"
      ).length > 0
    )
      return false;
    if (Object.keys(MCSAMaterialMap).length === 0) return false;
    return true;
  };

  const checkCanShowGenerateWithAI = () => {
    return checkCanShowCommonWithAI();
  };

  const checkCanShowSolveWithAI = () => {
    const { canSolveActivityTemplate } = findAISettingsFromCurrentProfile(
      userProfileBrand,
      userProfileCampus,
      userProfileInstitution
    );
    if (!canSolveActivityTemplate) return false;
    if (userInformation.id !== 49) return false;
    const foundActivityTemplate = activityTemplateSet.find(
      (activityTemplate) => activityTemplate.id === selectedActivityTemplateId
    );
    if (!foundActivityTemplate) return false;
    if (selectedMaterialContentType !== "TEXT") return false;
    if (foundActivityTemplate.type === "ORDERING") {
      if (Object.keys(orderingMaterialMap).length === 0) return false;
      if (Object.keys(orderingSolutionMap).length > 0) return false;
    } else if (foundActivityTemplate.type === "DROPDOWN") {
      if (Object.keys(dropdownMaterialMap).length === 0) return false;
      if (Object.keys(dropdownSolutionMap).length > 0) return false;
    } else if (foundActivityTemplate.type === "MCSA") {
      if (Object.keys(MCSABodyMap).length === 0) return false;
      if (Object.keys(MCSAMaterialMap)[0] === "ANSWER_KEY") return false;
      if (Object.keys(MCSASolutionMap).length > 0) return false;
    } else if (foundActivityTemplate.type === "MCMA") {
      if (Object.keys(MCMABodyMap).length === 0) return false;
      if (Object.keys(MCMAMaterialMap)[0] === "ANSWER_KEY") return false;
      if (Object.keys(MCMASolutionMap).length > 0) return false;
    } else if (foundActivityTemplate.type === "MATCHING") {
      if (Object.keys(matchingMaterialMap).length === 0) return false;
      if (Object.keys(matchingSolutionMap).length > 0) return false;
    } else if (foundActivityTemplate.type === "GROUPING") {
      if (Object.keys(groupingMaterialMap).length === 0) return false;
      if (Object.keys(groupingSolutionMap).length > 0) return false;
    } else if (foundActivityTemplate.type === "FILL_IN_THE_BLANKS") {
      if (Object.keys(fillInTheBlanksMaterialMap).length === 0) return false;
      if (Object.keys(fillInTheBlanksSolutionMap).length > 0) return false;
    } else if (foundActivityTemplate.type === "OPEN_ENDED") {
      if (Object.keys(openEndedSolutionMap).length > 0) return false;
    } else if (foundActivityTemplate.type === "TRUE_FALSE") {
      if (
        trueFalseMaterialMap.trueList.length === 0 &&
        trueFalseMaterialMap.falseList.length === 0
      )
        return false;
      if (Object.keys(trueFalseSolutionMap).length > 0) return false;
    } else {
      return false;
    }
    return true;
  };

  const findCanCreateActivityError = () => {
    let error = null;
    if (!selectedMaterialContentType) {
      return i18n.t("activity_create_activity_format_should_be_selected");
    }

    for (const activityTemplateId of activityTemplateIdList) {
      const foundActivityTemplate = activityTemplateSet.find(
        (activityTemplate) => activityTemplate.id === activityTemplateId
      );
      const { type } = foundActivityTemplate;
      if (type === "ORDERING") {
        let emptyValueCount = 0;
        Object.keys(orderingMaterialMap).forEach((key) => {
          if (!orderingMaterialMap[key]) {
            emptyValueCount++;
          }
        });
        if (emptyValueCount) {
          error = i18n.t("activity_create_ordering_values_empty_value_text");
        }
        if (Object.keys(orderingMaterialMap).length <= 2) {
          error = i18n.t(
            "activity_create_ordering_material_map_more_than_two_text"
          );
        }
      } else if (type === "DROPDOWN") {
        let emptyValueCount = 0;
        Object.keys(dropdownMaterialMap).forEach((key) => {
          Object.keys(dropdownMaterialMap[key]).forEach((subKey) => {
            dropdownMaterialMap[key][subKey].forEach((value) => {
              if (!value) {
                emptyValueCount++;
              }
            });
          });
        });
        if (emptyValueCount) {
          error = i18n.t("activity_create_dropdown_values_empty_value_text");
        }
        if (Object.keys(dropdownMaterialMap).length <= 2) {
          error = i18n.t(
            "activity_create_dropdown_material_map_more_than_two_text"
          );
        } else {
          for (let i = 0; i < Object.keys(dropdownMaterialMap).length; i++) {
            const answerKey = Object.keys(dropdownMaterialMap[i])[0];
            if (answerKey === "ANSWER_KEY") {
              error = i18n.t(
                "activity_create_dropdown_material_select_answer_key_text"
              );
              break;
            }
            const optionList = dropdownMaterialMap[i][answerKey];
            if (optionList.length < 3) {
              error = i18n.t(
                "activity_create_dropdown_material_option_list_more_than_two_text"
              );
              break;
            }
          }
        }
      } else if (type === "MCSA") {
        let emptyValueCount = 0;
        Object.keys(MCSAMaterialMap).forEach((key) => {
          MCSAMaterialMap[key].forEach((value) => {
            if (!value) {
              emptyValueCount++;
            }
          });
        });
        if (emptyValueCount) {
          error = i18n.t("activity_create_mcsa_values_empty_value_text");
        }
        const answerKey = Object.keys(MCSAMaterialMap)[0];
        if (MCSAMaterialMap[answerKey].length <= 3) {
          error = i18n.t(
            "activity_create_mcsa_material_map_more_than_three_text"
          );
        } else if (answerKey === "ANSWER_KEY") {
          error = i18n.t(
            "activity_create_mcsa_material_select_answer_key_text"
          );
        }
      } else if (type === "MCMA") {
        let emptyValueCount = 0;
        Object.keys(MCMAMaterialMap).forEach((key) => {
          MCMAMaterialMap[key].forEach((value) => {
            if (!value) {
              emptyValueCount++;
            }
          });
        });
        if (emptyValueCount) {
          error = i18n.t("activity_create_mcma_values_empty_value_text");
        }
        const answerKey = Object.keys(MCMAMaterialMap)[0];
        if (MCMAMaterialMap[answerKey].length <= 3) {
          error = i18n.t(
            "activity_create_mcma_material_map_more_than_three_text"
          );
        } else {
          if (answerKey.split("§").length <= 1) {
            error = i18n.t(
              "activity_create_mcma_material_select_answer_key_more_than_two_text"
            );
          }
        }
      } else if (type === "MATCHING") {
        let emptyValueCount = 0;
        Object.keys(matchingMaterialMap).forEach((key) => {
          Object.keys(matchingMaterialMap[key]).forEach((subKey) => {
            if (!subKey) {
              emptyValueCount++;
            }
            if (!matchingMaterialMap[key][subKey]) {
              emptyValueCount++;
            }
          });
        });
        if (emptyValueCount) {
          error = i18n.t("activity_create_matching_values_empty_value_text");
        }
        if (Object.keys(matchingMaterialMap).length <= 2) {
          error = i18n.t(
            "activity_create_matching_material_map_more_than_three_text"
          );
        }
        const keySet = new Set();
        const valueSet = new Set();
        for (const matchingMaterialKey of Object.keys(matchingMaterialMap)) {
          const key = Object.keys(matchingMaterialMap[matchingMaterialKey])[0];
          const value = matchingMaterialMap[matchingMaterialKey][key];
          valueSet.add(value);
          keySet.add(key);
        }
        if (keySet.size !== Object.keys(matchingMaterialMap).length) {
          error = i18n.t("activity_create_matching_material_map_key_different");
        }
        if (valueSet.size !== Object.keys(matchingMaterialMap).length) {
          error = i18n.t(
            "activity_create_matching_material_map_value_different"
          );
        }
      } else if (type === "GROUPING") {
        let emptyValueCount = 0;
        Object.keys(groupingMaterialMap).forEach((key) => {
          Object.keys(groupingMaterialMap[key]).forEach((subKey) => {
            if (!subKey) {
              emptyValueCount++;
            }
            groupingMaterialMap[key][subKey].forEach((value) => {
              if (!value) {
                emptyValueCount++;
              }
            });
          });
        });
        if (emptyValueCount) {
          error = i18n.t("activity_create_grouping_values_empty_value_text");
        }
        if (Object.keys(groupingMaterialMap).length <= 1) {
          error = i18n.t(
            "activity_create_grouping_material_map_more_than_two_text"
          );
        }
      } else if (type === "FILL_IN_THE_BLANKS") {
        const valueSet = new Set();
        let valueCount = 0;
        let emptyValueCount = 0;
        Object.keys(fillInTheBlanksMaterialMap).forEach((key) => {
          const valueArray = fillInTheBlanksMaterialMap[key];
          valueArray.forEach((value) => {
            if (!value) {
              emptyValueCount++;
            }
            valueSet.add(value);
            valueCount++;
          });
        });
        fillInTheBlanksIncorrectList.forEach((value) => {
          if (!value) {
            emptyValueCount++;
          }
          valueSet.add(value);
          valueCount++;
        });
        if (valueCount !== valueSet.size) {
          error = i18n.t(
            "activity_create_fill_in_the_blanks_values_must_be_unique_text"
          );
        }
        if (emptyValueCount) {
          error = i18n.t(
            "activity_create_fill_in_the_blanks_values_empty_value_text"
          );
        }

        if (Object.keys(fillInTheBlanksMaterialMap).length <= 2) {
          error = i18n.t(
            "activity_create_fill_in_the_blanks_material_map_more_than_three_text"
          );
        }
      } else if (type === "OPEN_ENDED") {
        if (Object.keys(openEndedBodyMap).length === 0) {
          error = i18n.t(
            "activity_create_open_ended_body_map_more_than_two_text"
          );
        }
      } else if (type === "TRUE_FALSE") {
        let emptyValueCount = 0;
        trueFalseMaterialMap.trueList.forEach((value) => {
          if (!value) {
            emptyValueCount++;
          }
        });
        trueFalseMaterialMap.falseList.forEach((value) => {
          if (!value) {
            emptyValueCount++;
          }
        });
        if (emptyValueCount) {
          error = i18n.t("activity_create_true_false_values_empty_value_text");
        }
        if (
          trueFalseMaterialMap.falseList.length === 0 &&
          trueFalseMaterialMap.trueList.length === 0
        ) {
          error = i18n.t(
            "activity_create_true_false_material_map_cannot_be_empty_text"
          );
        }
      }
    }
    return error;
  };

  const resetParams = () => {
    setId(null);
    setName(null);
    setDescription(null);
    setSelectedStatus("ACTIVE");
    if (userProfile.coterieType === "MANAGEMENT") {
      setSelectedCoterieType("DEFAULT_OPTION");
      setSelectedVersionCode("DEFAULT_OPTION");
    }
    if (!userProfileGrade?.level) {
      setSelectedLevel("DEFAULT_OPTION");
    }
    setSubCategoryFilter(true);
    setSelectedCategoryId("DEFAULT_OPTION");
    setActivityCategoryList([]);
    setSelectedDurationType("NONE");
    setSelectedDurationInSeconds("DEFAULT_OPTION");
    setActivityTemplateIdList([]);
    setSelectedActivityTemplateId(null);
    setSelectedMaterialContentType(null);
    setErrorMap({
      name: null,
      description: null,
      status: null,
      coterieType: null,
      level: null,
      durationType: null,
      durationInSeconds: null,
    });
    setActivityTemplateTypeImageMap({
      ORDERING: [],
      DROPDOWN: [],
      MCSA: [],
      MCMA: [],
      MATCHING: [],
      GROUPING: [],
      FILL_IN_THE_BLANKS: [],
      OPEN_ENDED: [],
      TRUE_FALSE: [],
    });
    setImageList([]);
    setImageContentList([]);
    setShowRefreshButton(true);
    setImageTextUpdateId(null);
    setImageText(null);
    resetMapValues();
  };

  const resetMapValues = () => {
    setOrderingMaterialMap({});
    setOrderingTaxonomyMap(constructBasicTaxonomyMap());
    setOrderingSolutionMap({});
    setDropdownMaterialMap({});
    setDropdownTaxonomyMap(constructBasicTaxonomyMap());
    setDropdownSolutionMap({});
    setMCSAMaterialMap({ ANSWER_KEY: [] });
    setMCSATaxonomyMap(constructBasicTaxonomyMap());
    setMCSASolutionMap({});
    setMCMAMaterialMap({ ANSWER_KEY: [] });
    setMCMATaxonomyMap(constructBasicTaxonomyMap());
    setMCMASolutionMap({});
    setMatchingMaterialMap({});
    setMatchingTaxonomyMap(constructBasicTaxonomyMap());
    setMatchingSolutionMap({});
    setGroupingMaterialMap({});
    setGroupingTaxonomyMap(constructBasicTaxonomyMap());
    setGroupingSolutionMap({});
    setFillInTheBlanksMaterialMap({});
    setFillInTheBlanksIncorrectList([]);
    setFillInTheBlanksTaxonomyMap(constructBasicTaxonomyMap());
    setFillInTheBlanksSolutionMap({});
    setOpenEndedMaterialMap({});
    setOpenEndedTaxonomyMap(constructBasicTaxonomyMap());
    setOpenEndedSolutionMap({});
    setOpenEndedEvaluationRubricMap({});
    setTrueFalseMaterialMap({ trueList: [], falseList: [] });
    setTrueFalseTaxonomyMap(constructBasicTaxonomyMap());
    setTrueFalseSolutionMap({});
  };

  const calculateActivityMaterialErrorCount = (type) => {
    if (type === "DROPDOWN") {
      let bodyPartCount = 0;
      Object.keys(dropdownBodyMap).forEach((dropdownBodyKey) => {
        if (dropdownBodyMap[dropdownBodyKey].value.includes("@@")) {
          const array = dropdownBodyMap[dropdownBodyKey].value.split("@@");
          bodyPartCount += array.length - 1;
        }
      });
      let materialPartCount;
      if (dropdownMaterialMap) {
        materialPartCount = Object.keys(dropdownMaterialMap).length;
      }

      return { bodyPartCount, materialPartCount };
    } else if (type === "FILL_IN_THE_BLANKS") {
      let bodyPartCount = 0;
      Object.keys(fillInTheBlanksBodyMap).forEach((fillInTheBlanksBodyKey) => {
        if (
          fillInTheBlanksBodyMap[fillInTheBlanksBodyKey].value.includes("@@")
        ) {
          const array =
            fillInTheBlanksBodyMap[fillInTheBlanksBodyKey].value.split("@@");
          bodyPartCount += array.length - 1;
        }
      });
      let materialPartCount = Object.keys(fillInTheBlanksMaterialMap).length;

      return { bodyPartCount, materialPartCount };
    }
    return null;
  };

  const canShowGenerateButton = () => {
    return (
      userProfileBrand?.id === 1 ||
      (userProfileBrand?.id === 2103 &&
        (userInformation.id === 49 || userInformation.id === 3052)) ||
      (userProfileBrand?.id === 4865 && userInformation.id === 49)
    );
  };

  const handleOnChange = (type, value) => {
    if (type === "name") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: i18n.t("name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: null,
        }));
      }
      setName(value);
    } else if (type === "description") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: i18n.t("description_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: null,
        }));
      }
      setDescription(value);
    } else if (type === "activityTemplateId") {
      setSelectedActivityTemplateId(value);
    } else if (type === "materialContentType") {
      setSelectedMaterialContentType(value);
    } else if (type === "coterieType") {
      if (parsedActivity) {
        NotificationManager.error(
          i18n.t("import_from_pdf_you_cannot_change_coterie_type")
        );
        return;
      }
      setSelectedCoterieType(value);
      setSelectedVersionCode("DEFAULT_OPTION");
      setActivityCategoryList([]);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        coterieType: null,
      }));
    } else if (type === "level") {
      if (parsedActivity) {
        NotificationManager.error(
          i18n.t("import_from_pdf_you_cannot_change_level")
        );
        return;
      }
      setSelectedLevel(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        level: null,
      }));
    } else if (type === "status") {
      setSelectedStatus(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        status: null,
      }));
    } else if (type === "versionCode") {
      if (parsedActivity) {
        NotificationManager.error(
          i18n.t("import_from_pdf_you_cannot_change_version_code")
        );
        return;
      }
      setSelectedVersionCode(value);
    } else if (type === "categoryId") {
      setSelectedCategoryId(value);
    } else if (type === "durationType") {
      setSelectedDurationType(value);
      setSelectedDurationInSeconds("DEFAULT_OPTION");
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        durationType: null,
      }));
    } else if (type === "durationInSeconds") {
      setSelectedDurationInSeconds(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        durationInSeconds: null,
      }));
    } else if (type === "imageText") {
      setImageText(value);
    }
  };

  const handleAddCategoryToActivityCategoryListOnClick = (categoryId) => {
    if (categoryId !== "DEFAULT_OPTION") {
      const currentIndex = activityCategoryList.findIndex(
        (activityCategory) =>
          parseFloat(activityCategory.id) === parseFloat(categoryId)
      );
      if (currentIndex === -1) {
        const foundCategoryDTO = categorySet.find(
          (categoryDTO) => categoryDTO.id === parseFloat(categoryId)
        );
        activityCategoryList.push(foundCategoryDTO);
        setActivityCategoryList(
          JSON.parse(JSON.stringify(activityCategoryList))
        );
        setSelectedCategoryId("DEFAULT_OPTION");
      }
    }
  };

  const handleAddActivityTemplateToActivityOnClick = (
    selectedActivityTemplateId
  ) => {
    const foundIndex = activityTemplateIdList.findIndex(
      (activityTemplateId) =>
        parseFloat(activityTemplateId) ===
        parseFloat(selectedActivityTemplateId)
    );
    if (foundIndex === -1) {
      activityTemplateIdList.push(selectedActivityTemplateId);
      setActivityTemplateIdList(
        JSON.parse(JSON.stringify(activityTemplateIdList.sort((a, b) => b - a)))
      );
    }
  };

  const handleAddBodyContentToOrderingBodyMap = (bodyType, value, size) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(orderingBodyMap).length;
      orderingBodyMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setOrderingBodyMap(JSON.parse(JSON.stringify(orderingBodyMap)));
    }
  };

  const handleAddBodyContentToDropdownBodyMap = (bodyType, value, size) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(dropdownBodyMap).length;
      dropdownBodyMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setDropdownBodyMap(JSON.parse(JSON.stringify(dropdownBodyMap)));
    }
  };

  const handleAddBodyContentToMCSABodyMap = (bodyType, value, size) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(MCSABodyMap).length;
      MCSABodyMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setMCSABodyMap(JSON.parse(JSON.stringify(MCSABodyMap)));
    }
  };

  const handleAddBodyContentToMCMABodyMap = (bodyType, value, size) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(MCMABodyMap).length;
      MCMABodyMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setMCMABodyMap(JSON.parse(JSON.stringify(MCMABodyMap)));
    }
  };

  const handleAddBodyContentToMatchingBodyMap = (bodyType, value, size) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(matchingBodyMap).length;
      matchingBodyMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setMatchingBodyMap(JSON.parse(JSON.stringify(matchingBodyMap)));
    }
  };

  const handleAddBodyContentToGroupingBodyMap = (bodyType, value, size) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(groupingBodyMap).length;
      groupingBodyMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setGroupingBodyMap(JSON.parse(JSON.stringify(groupingBodyMap)));
    }
  };

  const handleAddBodyContentToFillInTheBlanksBodyMap = (
    bodyType,
    value,
    size
  ) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(fillInTheBlanksBodyMap).length;
      fillInTheBlanksBodyMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setFillInTheBlanksBodyMap(
        JSON.parse(JSON.stringify(fillInTheBlanksBodyMap))
      );
    }
  };

  const handleAddBodyContentToOpenEndedBodyMap = (bodyType, value, size) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(openEndedBodyMap).length;
      openEndedBodyMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setOpenEndedBodyMap(JSON.parse(JSON.stringify(openEndedBodyMap)));
    }
  };

  const handleAddBodyContentToTrueFalseBodyMap = (bodyType, value, size) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(trueFalseBodyMap).length;
      trueFalseBodyMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setTrueFalseBodyMap(JSON.parse(JSON.stringify(trueFalseBodyMap)));
    }
  };

  const handleAddBodyContentToBodyMap = (
    contentType,
    bodyType,
    value,
    size
  ) => {
    if (contentType === "ORDERING") {
      handleAddBodyContentToOrderingBodyMap(bodyType, value, size);
    } else if (contentType === "DROPDOWN") {
      handleAddBodyContentToDropdownBodyMap(bodyType, value, size);
    } else if (contentType === "MCSA") {
      handleAddBodyContentToMCSABodyMap(bodyType, value, size);
    } else if (contentType === "MCMA") {
      handleAddBodyContentToMCMABodyMap(bodyType, value, size);
    } else if (contentType === "MATCHING") {
      handleAddBodyContentToMatchingBodyMap(bodyType, value, size);
    } else if (contentType === "GROUPING") {
      handleAddBodyContentToGroupingBodyMap(bodyType, value, size);
    } else if (contentType === "FILL_IN_THE_BLANKS") {
      handleAddBodyContentToFillInTheBlanksBodyMap(bodyType, value, size);
    } else if (contentType === "OPEN_ENDED") {
      handleAddBodyContentToOpenEndedBodyMap(bodyType, value, size);
    } else if (contentType === "TRUE_FALSE") {
      handleAddBodyContentToTrueFalseBodyMap(bodyType, value, size);
    }
  };

  const handleAddMaterialContentToMaterialMap = (
    templateType,
    value,
    key,
    option
  ) => {
    if (templateType === "ORDERING") {
      const currentIndex = Object.keys(orderingMaterialMap).length;
      orderingMaterialMap[currentIndex] = value;
      setOrderingMaterialMap(JSON.parse(JSON.stringify(orderingMaterialMap)));
    } else if (templateType === "DROPDOWN") {
      const currentIndex = Object.keys(dropdownMaterialMap).length;
      dropdownMaterialMap[currentIndex] = { ANSWER_KEY: [value] };
      setDropdownMaterialMap(JSON.parse(JSON.stringify(dropdownMaterialMap)));
    } else if (templateType === "MCSA") {
      const answerKey = Object.keys(MCSAMaterialMap)[0];
      MCSAMaterialMap[answerKey].push(value);
      setMCSAMaterialMap(JSON.parse(JSON.stringify(MCSAMaterialMap)));
    } else if (templateType === "MCMA") {
      const answerKey = Object.keys(MCMAMaterialMap)[0];
      MCMAMaterialMap[answerKey].push(value);
      setMCMAMaterialMap(JSON.parse(JSON.stringify(MCMAMaterialMap)));
    } else if (templateType === "MATCHING") {
      const newKey = `${i18n.t("insert_a_valid_key")}`;
      matchingMaterialMap[new Date().getTime()] = { [newKey]: value };
      setMatchingMaterialMap(JSON.parse(JSON.stringify(matchingMaterialMap)));
    } else if (templateType === "GROUPING") {
      const newKey = `${i18n.t("insert_a_valid_key")}`;
      groupingMaterialMap[new Date().getTime()] = {
        [newKey]: [value],
      };
      setGroupingMaterialMap(JSON.parse(JSON.stringify(groupingMaterialMap)));
    } else if (templateType === "FILL_IN_THE_BLANKS") {
      if (option === "CORRECT") {
        const currentIndex = Object.keys(fillInTheBlanksMaterialMap).length;
        fillInTheBlanksMaterialMap[currentIndex] = [value];
        setFillInTheBlanksMaterialMap(
          JSON.parse(JSON.stringify(fillInTheBlanksMaterialMap))
        );
      } else if (option === "INCORRECT") {
        fillInTheBlanksIncorrectList.push(value);
        setFillInTheBlanksIncorrectList(
          JSON.parse(JSON.stringify(fillInTheBlanksIncorrectList))
        );
      }
    } else if (templateType === "OPEN_ENDED") {
      const currentIndex = Object.keys(openEndedMaterialMap).length;
      openEndedMaterialMap[currentIndex] = value;
      setOpenEndedMaterialMap(JSON.parse(JSON.stringify(openEndedMaterialMap)));
    } else if (templateType === "TRUE_FALSE") {
      trueFalseMaterialMap[key].push(value);
      setTrueFalseMaterialMap(JSON.parse(JSON.stringify(trueFalseMaterialMap)));
    }
  };

  const handleRemoveCategoryFromActivityCategoryListOnClick = (categoryId) => {
    const currentIndex = activityCategoryList.findIndex(
      (activityCategory) =>
        parseFloat(activityCategory.id) === parseFloat(categoryId)
    );
    if (currentIndex !== -1) {
      activityCategoryList.splice(currentIndex, 1);
      setActivityCategoryList(JSON.parse(JSON.stringify(activityCategoryList)));
    }
  };

  const handleRemoveActivityTemplateFromActivityOnClick = (
    activityTemplateId
  ) => {
    const foundIndex = activityTemplateIdList.findIndex(
      (foundActivityTemplateId) =>
        parseFloat(foundActivityTemplateId) === parseFloat(activityTemplateId)
    );
    if (foundIndex > -1) {
      activityTemplateIdList.splice(foundIndex, 1);
      setActivityTemplateIdList(
        JSON.parse(JSON.stringify(activityTemplateIdList.sort((a, b) => b - a)))
      );
    }
    setSelectedActivityTemplateId(null);
  };

  const handleRemoveBodyContentFromBodyMap = (type, key) => {
    if (type === "ORDERING") {
      delete orderingBodyMap[key];
      let currentIndex = 0;
      const newOrderingBodyMap = {};
      Object.keys(orderingBodyMap).forEach((key) => {
        newOrderingBodyMap[currentIndex] = orderingBodyMap[key];
        currentIndex++;
      });
      setOrderingBodyMap(newOrderingBodyMap);
    } else if (type === "DROPDOWN") {
      delete dropdownBodyMap[key];
      let currentIndex = 0;
      const newDropdownBodyMap = {};
      Object.keys(dropdownBodyMap).forEach((key) => {
        newDropdownBodyMap[currentIndex] = dropdownBodyMap[key];
        currentIndex++;
      });
      setDropdownBodyMap(newDropdownBodyMap);
    } else if (type === "MCSA") {
      delete MCSABodyMap[key];
      let currentIndex = 0;
      const newMCSABodyMap = {};
      Object.keys(MCSABodyMap).forEach((key) => {
        newMCSABodyMap[currentIndex] = MCSABodyMap[key];
        currentIndex++;
      });
      setMCSABodyMap(newMCSABodyMap);
    } else if (type === "MCMA") {
      delete MCMABodyMap[key];
      let currentIndex = 0;
      const newMCMABodyMap = {};
      Object.keys(MCMABodyMap).forEach((key) => {
        newMCMABodyMap[currentIndex] = MCMABodyMap[key];
        currentIndex++;
      });
      setMCMABodyMap(newMCMABodyMap);
    } else if (type === "MATCHING") {
      delete matchingBodyMap[key];
      let currentIndex = 0;
      const newMatchingBodyMap = {};
      Object.keys(matchingBodyMap).forEach((key) => {
        newMatchingBodyMap[currentIndex] = matchingBodyMap[key];
        currentIndex++;
      });
      setMatchingBodyMap(newMatchingBodyMap);
    } else if (type === "GROUPING") {
      delete groupingBodyMap[key];
      let currentIndex = 0;
      const newGroupingBodyMap = {};
      Object.keys(groupingBodyMap).forEach((key) => {
        newGroupingBodyMap[currentIndex] = groupingBodyMap[key];
        currentIndex++;
      });
      setGroupingBodyMap(newGroupingBodyMap);
    } else if (type === "FILL_IN_THE_BLANKS") {
      delete fillInTheBlanksBodyMap[key];
      let currentIndex = 0;
      const newFillInTheBlanksBodyMap = {};
      Object.keys(fillInTheBlanksBodyMap).forEach((key) => {
        newFillInTheBlanksBodyMap[currentIndex] = fillInTheBlanksBodyMap[key];
        currentIndex++;
      });
      setFillInTheBlanksBodyMap(newFillInTheBlanksBodyMap);
    } else if (type === "OPEN_ENDED") {
      delete openEndedBodyMap[key];
      let currentIndex = 0;
      const newOpenEndedBodyMap = {};
      Object.keys(openEndedBodyMap).forEach((key) => {
        newOpenEndedBodyMap[currentIndex] = openEndedBodyMap[key];
        currentIndex++;
      });
      setOpenEndedBodyMap(newOpenEndedBodyMap);
    } else if (type === "TRUE_FALSE") {
      delete trueFalseBodyMap[key];
      let currentIndex = 0;
      const newTrueFalseBodyMap = {};
      Object.keys(trueFalseBodyMap).forEach((key) => {
        newTrueFalseBodyMap[currentIndex] = trueFalseBodyMap[key];
        currentIndex++;
      });
      setTrueFalseBodyMap(newTrueFalseBodyMap);
    }
  };

  const handleRemoveMaterialContentFromOrderingMaterialMap = (key) => {
    delete orderingMaterialMap[key];
    let currentIndex = 0;
    const newOrderingMaterialMap = {};
    Object.keys(orderingMaterialMap).forEach((key) => {
      newOrderingMaterialMap[currentIndex] = orderingMaterialMap[key];
      currentIndex++;
    });
    setOrderingMaterialMap(newOrderingMaterialMap);
  };

  const handleRemoveMaterialContentFromDropdownMaterialMap = (
    key,
    subKey,
    index
  ) => {
    if (dropdownMaterialMap[key][subKey].length === 1) {
      delete dropdownMaterialMap[key];
      let currentIndex = 0;
      const newDropdownMaterialMap = {};
      Object.keys(dropdownMaterialMap).forEach((key) => {
        newDropdownMaterialMap[currentIndex] = dropdownMaterialMap[key];
        currentIndex++;
      });
      setDropdownMaterialMap(
        JSON.parse(JSON.stringify(newDropdownMaterialMap))
      );
    } else {
      dropdownMaterialMap[key][subKey].splice(index, 1);
      setDropdownMaterialMap(JSON.parse(JSON.stringify(dropdownMaterialMap)));
    }
  };

  const handleRemoveMaterialContentFromMCSAMaterialMap = (key) => {
    const answerKey = Object.keys(MCSAMaterialMap)[0];
    delete MCSAMaterialMap[answerKey][key];
    const newMCSAMaterialMapValueList = [];
    MCSAMaterialMap[answerKey].forEach((value) => {
      newMCSAMaterialMapValueList.push(value);
    });
    MCSAMaterialMap[answerKey] = newMCSAMaterialMapValueList;
    setMCSAMaterialMap(JSON.parse(JSON.stringify(MCSAMaterialMap)));
  };

  const handleRemoveMaterialContentFromMCMAMaterialMap = (key) => {
    const answerKeys = Object.keys(MCMAMaterialMap)[0];
    // const splittedAnswerKeyList = answerKeys.split(",");
    const splittedAnswerKeyList = answerKeys.split("§");
    const deletedValue = MCMAMaterialMap[answerKeys][key];
    const foundIndex = splittedAnswerKeyList.findIndex(
      (splittedKey) => splittedKey === deletedValue
    );
    if (foundIndex !== -1) {
      splittedAnswerKeyList.splice(foundIndex, 1);
    }
    const newKeys = splittedAnswerKeyList
      .filter((splittedKey) => splittedKey !== "")
      .join("§"); // join()

    delete MCMAMaterialMap[answerKeys][key];
    const newMCMAMaterialMapValueList = [];
    MCMAMaterialMap[answerKeys].forEach((value) => {
      newMCMAMaterialMapValueList.push(value);
    });
    setMCMAMaterialMap({ [newKeys]: newMCMAMaterialMapValueList });
  };

  const handleRemoveMaterialContentFromMatchingMaterialMap = (key) => {
    delete matchingMaterialMap[key];
    const newMatchingMaterialMap = {};
    Object.keys(matchingMaterialMap).forEach((key) => {
      newMatchingMaterialMap[key] = matchingMaterialMap[key];
    });
    setMatchingMaterialMap(newMatchingMaterialMap);
  };

  const handleRemoveMaterialContentFromGroupingMaterialMap = (
    key,
    subKey,
    index
  ) => {
    if (groupingMaterialMap[key][subKey].length === 1) {
      delete groupingMaterialMap[key];
      let currentIndex = 0;
      const newGroupingMaterialMap = {};
      Object.keys(groupingMaterialMap).forEach((key) => {
        newGroupingMaterialMap[currentIndex] = groupingMaterialMap[key];
        currentIndex++;
      });
      setGroupingMaterialMap(JSON.parse(JSON.stringify(groupingMaterialMap)));
    } else {
      groupingMaterialMap[key][subKey].splice(index, 1);
      setGroupingMaterialMap(JSON.parse(JSON.stringify(groupingMaterialMap)));
    }
  };

  const handleRemoveMaterialContentFromFillInTheBlanksMaterialMap = (
    key,
    index,
    option
  ) => {
    if (option === "CORRECT") {
      if (fillInTheBlanksMaterialMap[key].length === 1) {
        delete fillInTheBlanksMaterialMap[key];
        let currentIndex = 0;
        const newFillInTheBlanksMaterialMap = {};
        Object.keys(fillInTheBlanksMaterialMap).forEach((key) => {
          newFillInTheBlanksMaterialMap[currentIndex] =
            fillInTheBlanksMaterialMap[key];
          currentIndex++;
        });
        setFillInTheBlanksMaterialMap(newFillInTheBlanksMaterialMap);
      } else {
        fillInTheBlanksMaterialMap[key].splice(index, 1);
        setFillInTheBlanksMaterialMap(
          JSON.parse(JSON.stringify(fillInTheBlanksMaterialMap))
        );
      }
    } else if (option === "INCORRECT") {
      fillInTheBlanksIncorrectList.splice(index, 1);
      setFillInTheBlanksIncorrectList(
        JSON.parse(JSON.stringify(fillInTheBlanksIncorrectList))
      );
    }
  };

  const handleRemoveMaterialContentFromTrueFalseMaterialMap = (
    baseKey,
    subKey
  ) => {
    trueFalseMaterialMap[baseKey].splice(subKey, 1);
    setTrueFalseMaterialMap(JSON.parse(JSON.stringify(trueFalseMaterialMap)));
  };

  const handleAddSolutionContentToOrderingSolutionMap = (
    bodyType,
    value,
    size
  ) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(orderingSolutionMap).length;
      orderingSolutionMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setOrderingSolutionMap(JSON.parse(JSON.stringify(orderingSolutionMap)));
    }
  };

  const handleAddSolutionContentToDropdownSolutionMap = (
    bodyType,
    value,
    size
  ) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(dropdownSolutionMap).length;
      dropdownSolutionMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setDropdownSolutionMap(JSON.parse(JSON.stringify(dropdownSolutionMap)));
    }
  };

  const handleAddSolutionContentToMCSASolutionMap = (bodyType, value, size) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(MCSASolutionMap).length;
      MCSASolutionMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setMCSASolutionMap(JSON.parse(JSON.stringify(MCSASolutionMap)));
    }
  };

  const handleAddSolutionContentToMCMASolutionMap = (bodyType, value, size) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(MCMASolutionMap).length;
      MCMASolutionMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setMCMASolutionMap(JSON.parse(JSON.stringify(MCMASolutionMap)));
    }
  };

  const handleAddSolutionContentToMatchingSolutionMap = (
    bodyType,
    value,
    size
  ) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(matchingSolutionMap).length;
      matchingSolutionMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setMatchingSolutionMap(JSON.parse(JSON.stringify(matchingSolutionMap)));
    }
  };

  const handleAddSolutionContentToGroupingSolutionMap = (
    bodyType,
    value,
    size
  ) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(groupingSolutionMap).length;
      groupingSolutionMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setGroupingSolutionMap(JSON.parse(JSON.stringify(groupingSolutionMap)));
    }
  };

  const handleAddSolutionContentToFillInTheBlanksSolutionMap = (
    bodyType,
    value,
    size
  ) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(fillInTheBlanksSolutionMap).length;
      fillInTheBlanksSolutionMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setFillInTheBlanksSolutionMap(
        JSON.parse(JSON.stringify(fillInTheBlanksSolutionMap))
      );
    }
  };

  const handleAddSolutionContentToOpenEndedSolutionMap = (
    bodyType,
    value,
    size
  ) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(openEndedSolutionMap).length;
      openEndedSolutionMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setOpenEndedSolutionMap(JSON.parse(JSON.stringify(openEndedSolutionMap)));
    }
  };

  const handleAddEvaluationRubricContentToOpenEndedEvaluationRubricMap = (
    bodyType,
    value
  ) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(openEndedEvaluationRubricMap).length;
      openEndedEvaluationRubricMap[currentIndex] = {
        type: bodyType,
        value,
      };
      setOpenEndedEvaluationRubricMap(
        JSON.parse(JSON.stringify(openEndedEvaluationRubricMap))
      );
    }
  };

  const handleAddSolutionContentToTrueFalseSolutionMap = (
    bodyType,
    value,
    size
  ) => {
    if (bodyType !== null) {
      const currentIndex = Object.keys(trueFalseSolutionMap).length;
      trueFalseSolutionMap[currentIndex] = {
        type: bodyType,
        value,
        size: size ? size : null,
      };
      setTrueFalseSolutionMap(JSON.parse(JSON.stringify(trueFalseSolutionMap)));
    }
  };

  const handleAddSolutionContentToSolutionMap = (
    contentType,
    bodyType,
    value,
    size
  ) => {
    if (contentType === "ORDERING") {
      handleAddSolutionContentToOrderingSolutionMap(bodyType, value, size);
    } else if (contentType === "DROPDOWN") {
      handleAddSolutionContentToDropdownSolutionMap(bodyType, value, size);
    } else if (contentType === "MCSA") {
      handleAddSolutionContentToMCSASolutionMap(bodyType, value, size);
    } else if (contentType === "MCMA") {
      handleAddSolutionContentToMCMASolutionMap(bodyType, value, size);
    } else if (contentType === "MATCHING") {
      handleAddSolutionContentToMatchingSolutionMap(bodyType, value, size);
    } else if (contentType === "GROUPING") {
      handleAddSolutionContentToGroupingSolutionMap(bodyType, value, size);
    } else if (contentType === "FILL_IN_THE_BLANKS") {
      handleAddSolutionContentToFillInTheBlanksSolutionMap(
        bodyType,
        value,
        size
      );
    } else if (contentType === "OPEN_ENDED") {
      handleAddSolutionContentToOpenEndedSolutionMap(bodyType, value, size);
    } else if (contentType === "TRUE_FALSE") {
      handleAddSolutionContentToTrueFalseSolutionMap(bodyType, value, size);
    }
  };

  const handleAddEvaluationRubricContentToEvaluationRubricMap = (
    contentType,
    bodyType,
    value
  ) => {
    if (contentType === "ORDERING") {
    } else if (contentType === "DROPDOWN") {
    } else if (contentType === "MCSA") {
    } else if (contentType === "MCMA") {
    } else if (contentType === "MATCHING") {
    } else if (contentType === "GROUPING") {
    } else if (contentType === "FILL_IN_THE_BLANKS") {
    } else if (contentType === "OPEN_ENDED") {
      handleAddEvaluationRubricContentToOpenEndedEvaluationRubricMap(
        bodyType,
        value
      );
    } else if (contentType === "TRUE_FALSE") {
    }
  };

  const handleRemoveSolutionContentFromSolutionMap = (type, key) => {
    if (type === "ORDERING") {
      delete orderingSolutionMap[key];
      let currentIndex = 0;
      const newOrderingSolutionMap = {};
      Object.keys(orderingSolutionMap).forEach((key) => {
        newOrderingSolutionMap[currentIndex] = orderingSolutionMap[key];
        currentIndex++;
      });
      setOrderingSolutionMap(newOrderingSolutionMap);
    } else if (type === "DROPDOWN") {
      delete dropdownSolutionMap[key];
      let currentIndex = 0;
      const newDropdownSolutionMap = {};
      Object.keys(dropdownSolutionMap).forEach((key) => {
        newDropdownSolutionMap[currentIndex] = dropdownSolutionMap[key];
        currentIndex++;
      });
      setDropdownSolutionMap(newDropdownSolutionMap);
    } else if (type === "MCSA") {
      delete MCSASolutionMap[key];
      let currentIndex = 0;
      const newMCSASolutionMap = {};
      Object.keys(MCSASolutionMap).forEach((key) => {
        newMCSASolutionMap[currentIndex] = MCSASolutionMap[key];
        currentIndex++;
      });
      setMCSASolutionMap(newMCSASolutionMap);
    } else if (type === "MCMA") {
      delete MCMASolutionMap[key];
      let currentIndex = 0;
      const newMCMASolutionMap = {};
      Object.keys(MCMASolutionMap).forEach((key) => {
        newMCMASolutionMap[currentIndex] = MCMASolutionMap[key];
        currentIndex++;
      });
      setMCMASolutionMap(newMCMASolutionMap);
    } else if (type === "MATCHING") {
      delete matchingSolutionMap[key];
      let currentIndex = 0;
      const newMatchingSolutionMap = {};
      Object.keys(matchingSolutionMap).forEach((key) => {
        newMatchingSolutionMap[currentIndex] = matchingSolutionMap[key];
        currentIndex++;
      });
      setMatchingSolutionMap(newMatchingSolutionMap);
    } else if (type === "GROUPING") {
      delete groupingSolutionMap[key];
      let currentIndex = 0;
      const newGroupingSolutionMap = {};
      Object.keys(groupingSolutionMap).forEach((key) => {
        newGroupingSolutionMap[currentIndex] = groupingSolutionMap[key];
        currentIndex++;
      });
      setGroupingSolutionMap(newGroupingSolutionMap);
    } else if (type === "FILL_IN_THE_BLANKS") {
      delete fillInTheBlanksSolutionMap[key];
      let currentIndex = 0;
      const newFillInTheBlanksSolutionMap = {};
      Object.keys(fillInTheBlanksSolutionMap).forEach((key) => {
        newFillInTheBlanksSolutionMap[currentIndex] =
          fillInTheBlanksSolutionMap[key];
        currentIndex++;
      });
      setFillInTheBlanksSolutionMap(newFillInTheBlanksSolutionMap);
    } else if (type === "OPEN_ENDED") {
      delete openEndedSolutionMap[key];
      let currentIndex = 0;
      const newOpenEndedSolutionMap = {};
      Object.keys(openEndedSolutionMap).forEach((key) => {
        newOpenEndedSolutionMap[currentIndex] = openEndedSolutionMap[key];
        currentIndex++;
      });
      setOpenEndedSolutionMap(newOpenEndedSolutionMap);
    } else if (type === "TRUE_FALSE") {
      delete trueFalseSolutionMap[key];
      let currentIndex = 0;
      const newTrueFalseSolutionMap = {};
      Object.keys(trueFalseSolutionMap).forEach((key) => {
        newTrueFalseSolutionMap[currentIndex] = trueFalseSolutionMap[key];
        currentIndex++;
      });
      setTrueFalseSolutionMap(newTrueFalseSolutionMap);
    }
  };

  const handleRemoveEvaluationRubricContentFromEvaluationRubricMap = (
    type,
    key
  ) => {
    if (type === "ORDERING") {
    } else if (type === "DROPDOWN") {
    } else if (type === "MCSA") {
    } else if (type === "MCMA") {
    } else if (type === "MATCHING") {
    } else if (type === "GROUPING") {
    } else if (type === "FILL_IN_THE_BLANKS") {
    } else if (type === "OPEN_ENDED") {
      delete openEndedEvaluationRubricMap[key];
      let currentIndex = 0;
      const newOpenEndedEvaluationRubricMap = {};
      Object.keys(openEndedEvaluationRubricMap).forEach((key) => {
        newOpenEndedEvaluationRubricMap[currentIndex] =
          openEndedEvaluationRubricMap[key];
        currentIndex++;
      });
      setOpenEndedEvaluationRubricMap(newOpenEndedEvaluationRubricMap);
    } else if (type === "TRUE_FALSE") {
    }
  };

  const handleChooseBodyStorageFileItemOnClick = (item) => {
    const foundActivityTemplate = activityTemplateSet.find(
      (activityTemplateDTO) =>
        parseFloat(activityTemplateDTO.id) === selectedActivityTemplateId
    );
    const constructedUrl = `${connection.base.storage.url}/storage-files/preview/file${item.path}/${item.fileName}`;
    handleAddBodyContentToBodyMap(
      foundActivityTemplate.type,
      bodyLibraryModal.type,
      constructedUrl,
      "1/2"
    );
    setBodyLibraryModal({ show: false });
  };

  const handleChooseMaterialStorageFileItemOnClick = (item) => {
    const foundActivityTemplate = activityTemplateSet.find(
      (activityTemplateDTO) =>
        parseFloat(activityTemplateDTO.id) === selectedActivityTemplateId
    );
    const constructedUrl = `${connection.base.storage.url}/storage-files/preview/file${item.path}/${item.fileName}`;
    if (materialLibraryModal.sub) {
      if (foundActivityTemplate.type === "DROPDOWN") {
        dropdownMaterialMap[materialLibraryModal.index][
          Object.keys(dropdownMaterialMap[materialLibraryModal.index])[0]
        ].push(constructedUrl);
        setDropdownMaterialMap(JSON.parse(JSON.stringify(dropdownMaterialMap)));
      } else if (foundActivityTemplate.type === "GROUPING") {
        const subGroupingMaterialMap =
          groupingMaterialMap[
            Object.keys(groupingMaterialMap)[materialLibraryModal.index]
          ];
        subGroupingMaterialMap[Object.keys(subGroupingMaterialMap)[0]].push(
          constructedUrl
        );
        setGroupingMaterialMap(JSON.parse(JSON.stringify(groupingMaterialMap)));
      } else if (foundActivityTemplate.type === "FILL_IN_THE_BLANKS") {
        if (materialLibraryModal.option === "CORRECT") {
          fillInTheBlanksMaterialMap[materialLibraryModal.index].push(
            constructedUrl
          );
          setFillInTheBlanksMaterialMap(
            JSON.parse(JSON.stringify(fillInTheBlanksMaterialMap))
          );
        } else if (materialLibraryModal.option === "INCORRECT") {
          fillInTheBlanksIncorrectList.push(constructedUrl);
          setFillInTheBlanksIncorrectList(
            JSON.parse(JSON.stringify(fillInTheBlanksIncorrectList))
          );
        }
      }
    } else {
      handleAddMaterialContentToMaterialMap(
        foundActivityTemplate.type,
        constructedUrl,
        materialLibraryModal.key,
        materialLibraryModal.option
      );
    }

    setMaterialLibraryModal({
      show: false,
    });
  };

  const handleChooseSolutionStorageFileItemOnClick = (item) => {
    const foundActivityTemplate = activityTemplateSet.find(
      (activityTemplateDTO) =>
        parseFloat(activityTemplateDTO.id) === selectedActivityTemplateId
    );
    const constructedUrl = `${connection.base.storage.url}/storage-files/preview/file${item.path}/${item.fileName}`;
    handleAddSolutionContentToSolutionMap(
      foundActivityTemplate.type,
      solutionLibraryModal.type,
      constructedUrl,
      "1"
    );
    setSolutionLibraryModal({ show: false });
  };

  const handleReturnFromActivityOnClick = () => {
    if (subscribedId) {
      catchtivityStompClient.unsubscribe(subscribedId);
      setSubscribedId(null);
    }
    resetParams();
    handleGoBackOnClick();
  };

  const handleUpdateActivityOnClick = async () => {
    if (checkUpdatePermissionGranted("activity_list")) {
      if (checkCommonValidation()) {
        const constructedData = {};
        const currentActivityTemplateSet = [];
        if (selectedDurationType === "EACH") {
          constructedData["durationMap"] = JSON.stringify({
            durationType: selectedDurationType,
            duration: selectedDurationInSeconds,
          });
        }
        constructedData["contentMap"] = JSON.stringify({
          type: selectedMaterialContentType,
        });
        activityTemplateIdList.forEach((activityTemplateId) => {
          const foundActivityTemplate = activityTemplateSet.find(
            (activityTemplateDTO) =>
              activityTemplateDTO.id === parseFloat(activityTemplateId)
          );
          constructActivityData(foundActivityTemplate.type, constructedData);
          currentActivityTemplateSet.push({
            id: activityTemplateId,
            type: foundActivityTemplate.type,
          });
        });
        const currentActivity = {
          id,
          name,
          description,
          userId: userInformation.id,
          userProfileId: userProfile.id,
          status: selectedStatus,
          coterieType: selectedCoterieType,
          level: selectedLevel,
          data: JSON.stringify(constructedData),
          activityTemplateIdList,
          categoryIdList: activityCategoryList.map(
            (activityCategory) => activityCategory.id
          ),
        };
        const { data, err } = await patchActivityWithData(currentActivity);
        if (err) {
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          handleAfterUpdateActivityAction(data);
          if (catchtivityStompClient) {
            catchtivityStompClient.send(
              "/app/activity/updates",
              null,
              JSON.stringify({
                activityId: id,
                username: userInformation.username,
                firstName: userInformation.firstName,
                lastName: userInformation.lastName,
                state: "UPDATED",
              })
            );
          }
          handleReturnFromActivityOnClick();
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleCreateActivityAccessibilityOnClick = async (activityId) => {
    let newActivityAccessibility = {
      userId: userInformation.id,
      userProfileId: userProfile.id,
      activity: { id: activityId },
    };
    if (userProfileInstitution) {
      newActivityAccessibility = {
        ...newActivityAccessibility,
        brandIdSet: [userProfileBrand.id],
        campusIdSet: [userProfileCampus.id],
        institutionIdSet: [userProfileInstitution.id],
      };
    } else if (userProfileCampus) {
      newActivityAccessibility = {
        ...newActivityAccessibility,
        brandIdSet: [userProfileBrand.id],
        campusIdSet: [userProfileCampus.id],
        institutionIdSet: [],
      };
    } else if (userProfileBrand) {
      newActivityAccessibility = {
        ...newActivityAccessibility,
        brandIdSet: [userProfileBrand.id],
        campusIdSet: [],
        institutionIdSet: [],
      };
    }
    await createActivityAccessibilityByActivityId(newActivityAccessibility);
  };

  const handleCreateActivityOnClick = async () => {
    if (checkInsertPermissionGranted("activity_list")) {
      if (checkCommonValidation()) {
        const constructedData = {};
        const currentActivityTemplateSet = [];
        if (selectedDurationType === "EACH") {
          constructedData["durationMap"] = JSON.stringify({
            durationType: selectedDurationType,
            duration: selectedDurationInSeconds,
          });
        }
        constructedData["contentMap"] = JSON.stringify({
          type: selectedMaterialContentType,
        });
        activityTemplateIdList.forEach((activityTemplateId) => {
          const foundActivityTemplate = activityTemplateSet.find(
            (activityTemplateDTO) =>
              activityTemplateDTO.id === parseFloat(activityTemplateId)
          );
          constructActivityData(foundActivityTemplate.type, constructedData);
          currentActivityTemplateSet.push({
            id: activityTemplateId,
            type: foundActivityTemplate.type,
          });
        });
        const newActivity = {
          name,
          description,
          userId: userInformation.id,
          userProfileId: userProfile.id,
          status: selectedStatus,
          coterieType: selectedCoterieType,
          level: selectedLevel,
          data: JSON.stringify(constructedData),
          activityTemplateSet: currentActivityTemplateSet,
          categoryIdSet: activityCategoryList.map(
            (activityCategory) => activityCategory.id
          ),
        };
        const { data, err } = await createActivityWithData(newActivity);
        if (err) {
          if (err.response.data) {
            NotificationManager.error(i18n.t(err.response.data));
            return;
          }
        }
        handleAfterCreateActivityAction(data);
        handleCreateActivityAccessibilityOnClick(data.id);
        handleReturnFromActivityOnClick();
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleUploadCroppedImageData = async () => {
    const canvasLeft = parseInt(crop.x);
    const canvasTop = parseInt(crop.y);
    const canvasWidth = parseInt(crop.width);
    const canvasHeight = parseInt(crop.height);
    const image = cropImageRef.current;

    const ratio = image.naturalWidth / image.clientWidth;

    const newCanvas = document.createElement("canvas");
    const context = newCanvas.getContext("2d");
    newCanvas.width = canvasWidth * ratio;
    newCanvas.height = canvasHeight * ratio;

    context.drawImage(
      image,
      canvasLeft * ratio,
      canvasTop * ratio,
      canvasWidth * ratio,
      canvasHeight * ratio,
      0,
      0,
      canvasWidth * ratio,
      canvasHeight * ratio
    );
    const imageData = newCanvas.toDataURL("image/jpeg");

    const formData = new FormData();
    formData.append("brandId", userProfileBrand.id);
    formData.append("userId", userInformation.id);
    formData.append("userProfileId", userProfile.id);
    formData.append("coterieType", selectedCoterieType);
    formData.append("documentType", "IMAGE");
    formData.append("path", `/${userProfileBrand.id}/images`);
    formData.append(
      "file",
      convertDataURLtoFile(imageData, `${new Date().getTime()}.jpeg`)
    );
    formData.append("changeFileName", true);
    const { data: uploadData, err: uploadErr } = await uploadFileToPath(
      formData
    );
    if (uploadErr) {
      NotificationManager.error(i18n.t("uploading_cropped_image_has_failed"));
      return;
    }
    const { data: moveData, err: moveErr } = await moveFileToBrandPath({
      fileName: uploadData.fileName,
      tempPath: uploadData.path,
      ownershipType: "ACTIVITY_DOCUMENT",
    });
    if (moveErr) {
      NotificationManager.error(i18n.t("moving_cropped_image_has_failed"));
      return;
    }
    return uploadData;
  };

  const handleAddCroppedImageToActivityBody = async () => {
    if (activityCategoryList.length === 0) {
      NotificationManager.error(
        i18n.t("activity_category_list_cannot_be_empty")
      );
      return;
    }

    const foundActivityTemplate = activityTemplateSet.find(
      (activityTemplate) =>
        parseFloat(activityTemplate.id) ===
        parseFloat(selectedActivityTemplateId)
    );
    if (!foundActivityTemplate) {
      NotificationManager.error(i18n.t("activity_template_not_found"));
      return;
    }
    const data = await handleUploadCroppedImageData();
    if (data) {
      const constructedUrl = `${connection.base.storage.url}/storage-files/preview/file${data.path}/${data.fileName}`;
      handleAddBodyContentToBodyMap(
        foundActivityTemplate.type,
        "IMAGE",
        constructedUrl,
        "1/2"
      );
      NotificationManager.success(
        i18n.t("uploading_cropped_image_has_successfully_completed")
      );
      setCrop(null);
      setIsCropModeActive(false);
    }
  };

  const handleAddCroppedImageToActivityMaterial = async () => {
    if (activityCategoryList.length === 0) {
      NotificationManager.error(
        i18n.t("activity_category_list_cannot_be_empty")
      );
      return;
    }
    const foundActivityTemplate = activityTemplateSet.find(
      (activityTemplate) =>
        parseFloat(activityTemplate.id) ===
        parseFloat(selectedActivityTemplateId)
    );
    if (!foundActivityTemplate) {
      NotificationManager.error(i18n.t("activity_template_not_found"));
      return;
    }
    const data = await handleUploadCroppedImageData();
    if (data) {
      const constructedUrl = `${connection.base.storage.url}/storage-files/preview/file${data.path}/${data.fileName}`;
      handleAddMaterialContentToMaterialMap(
        foundActivityTemplate.type,
        constructedUrl
      );
      NotificationManager.success(
        i18n.t("uploading_cropped_image_has_successfully_completed")
      );
      setCrop(null);
      setIsCropModeActive(false);
    }
  };

  const handleAddCroppedImageToLibrary = async () => {
    if (activityCategoryList.length === 0) {
      NotificationManager.error(
        i18n.t("activity_category_list_cannot_be_empty")
      );
      return;
    }
    const foundActivityTemplate = activityTemplateSet.find(
      (activityTemplate) =>
        parseFloat(activityTemplate.id) ===
        parseFloat(selectedActivityTemplateId)
    );
    if (!foundActivityTemplate) {
      NotificationManager.error(i18n.t("activity_template_not_found"));
      return;
    }
    const data = await handleUploadCroppedImageData();
    if (data) {
      NotificationManager.success(
        i18n.t("uploading_cropped_image_has_successfully_completed")
      );
      setCrop(null);
      setIsCropModeActive(false);
    }
  };

  const constructGenerateWithAIRequest = (activityTemplateType) => {
    let taxonomyMap = null;
    if (activityCategoryList.length === 0) {
      NotificationManager.error(
        i18n.t("activity_activity_category_cannot_be_empty")
      );
      return;
    }
    if (activityTemplateType === "ORDERING") {
      if (!orderingTaxonomyMap.name) {
        NotificationManager.error(
          i18n.t("activity_template_taxonomy_cannot_be_empty")
        );
        return;
      }
      taxonomyMap = orderingTaxonomyMap;
    } else if (activityTemplateType === "DROPDOWN") {
      if (!dropdownTaxonomyMap.name) {
        NotificationManager.error(
          i18n.t("activity_template_taxonomy_cannot_be_empty")
        );
        return;
      }
      taxonomyMap = dropdownTaxonomyMap;
    } else if (activityTemplateType === "MCSA") {
      if (!MCSATaxonomyMap.name) {
        NotificationManager.error(
          i18n.t("activity_template_taxonomy_cannot_be_empty")
        );
        return;
      }
      taxonomyMap = MCSATaxonomyMap;
    } else if (activityTemplateType === "MCMA") {
      if (!MCMATaxonomyMap.name) {
        NotificationManager.error(
          i18n.t("activity_template_taxonomy_cannot_be_empty")
        );
        return;
      }
      taxonomyMap = MCMATaxonomyMap;
    } else if (activityTemplateType === "MATCHING") {
      if (!matchingTaxonomyMap.name) {
        NotificationManager.error(
          i18n.t("activity_template_taxonomy_cannot_be_empty")
        );
        return;
      }
      taxonomyMap = matchingTaxonomyMap;
    } else if (activityTemplateType === "GROUPING") {
      if (!groupingTaxonomyMap.name) {
        NotificationManager.error(
          i18n.t("activity_template_taxonomy_cannot_be_empty")
        );
        return;
      }
      taxonomyMap = groupingTaxonomyMap;
    } else if (activityTemplateType === "OPEN_ENDED") {
      if (!openEndedTaxonomyMap.name) {
        NotificationManager.error(
          i18n.t("activity_template_taxonomy_cannot_be_empty")
        );
        return;
      }
      taxonomyMap = openEndedTaxonomyMap;
    } else if (activityTemplateType === "FILL_IN_THE_BLANKS") {
      if (!fillInTheBlanksTaxonomyMap.name) {
        NotificationManager.error(
          i18n.t("activity_template_taxonomy_cannot_be_empty")
        );
        return;
      }
      taxonomyMap = fillInTheBlanksTaxonomyMap;
    } else if (activityTemplateType === "TRUE_FALSE") {
      if (!trueFalseTaxonomyMap.name) {
        NotificationManager.error(
          i18n.t("activity_template_taxonomy_cannot_be_empty")
        );
        return;
      }
      taxonomyMap = trueFalseTaxonomyMap;
    }

    NotificationManager.info(i18n.t("please_wait_generating_text"));
    const itemList = [];
    Object.keys(MCSABodyMap).forEach((key) => {
      const bodyItem = MCSABodyMap[key];
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
    Object.keys(MCSAMaterialMap).forEach((key) => {
      const materialItemList = MCSAMaterialMap[key];
      for (const materialItem of materialItemList) {
        if (selectedMaterialContentType === "IMAGE") {
          itemList.push({
            type: "image_url",
            imageUrl: materialItem,
          });
        } else {
          itemList.push({
            type: "text",
            text: materialItem,
          });
        }
      }
    });
    const selectedCategory = activityCategoryList[0];
    const {
      code: categoryCode,
      name: categoryName,
      parentCode: categoryParentCode,
    } = selectedCategory;

    const foundCategory = categorySet.find(
      (category) => category.code === categoryParentCode
    );
    const { name: parentCategoryName } = foundCategory;

    return {
      itemList,
      taxonomy: {
        type: taxonomyMap.type,
        groupName: taxonomyMap.groupName,
        name: taxonomyMap.name,
      },
      outcomeCode: categoryCode,
      outcome: categoryName,
      parentOutcome: parentCategoryName,
      level: selectedLevel,
      coterieType: selectedCoterieType,
      activityTemplateType,
      temperature: 0.4,
      userId: userInformation.id,
      userProfileId: userProfile.id,
      brandId: userProfileBrand.id,
      campusId: userProfileCampus?.id,
      institutionId: userProfileInstitution?.id,
    };
  };

  const constructGenerateWithAIResponse = (
    activityTemplateType,
    data,
    includeImage
  ) => {
    const {
      orderingBodyMap,
      orderingMaterialMap,
      orderingSolutionMap,
      dropdownBodyMap,
      dropdownMaterialMap,
      dropdownSolutionMap,
      mcsaBodyMap,
      mcsaMaterialMap,
      mcsaSolutionMap,
      mcmaBodyMap,
      mcmaMaterialMap,
      mcmaSolutionMap,
      matchingBodyMap,
      matchingMaterialMap,
      matchingSolutionMap,
      groupingBodyMap,
      groupingMaterialMap,
      groupingSolutionMap,
      openEndedBodyMap,
      openEndedSolutionMap,
      fillInTheBlanksBodyMap,
      fillInTheBlanksMaterialMap,
      fillInTheBlanksSolutionMap,
      trueFalseBodyMap,
      trueFalseMaterialMap,
      trueFalseSolutionMap,
      taxonomy,
    } = data;
    const currentBodyMap = {};
    let foundImageKeyList = [];
    if (includeImage) {
      foundImageKeyList = Object.keys(MCSABodyMap).filter(
        (key) => MCSABodyMap[key].type === "IMAGE"
      );
      if (foundImageKeyList.length > 0) {
        for (let i = 0; i < foundImageKeyList.length; i++) {
          currentBodyMap[i] = MCSABodyMap[foundImageKeyList[i]];
        }
      }
    }

    if (activityTemplateType === "ORDERING") {
      for (let i = 0; i < Object.keys(orderingBodyMap).length; i++) {
        currentBodyMap[i + foundImageKeyList.length] = orderingBodyMap[i];
      }
      setOrderingBodyMap(currentBodyMap);
      setOrderingMaterialMap(orderingMaterialMap);
      setOrderingTaxonomyMap(taxonomy);
      setOrderingSolutionMap(orderingSolutionMap);
    } else if (activityTemplateType === "DROPDOWN") {
      for (let i = 0; i < Object.keys(dropdownBodyMap).length; i++) {
        currentBodyMap[i + foundImageKeyList.length] = dropdownBodyMap[i];
      }
      setDropdownBodyMap(currentBodyMap);
      setDropdownMaterialMap(dropdownMaterialMap);
      setDropdownTaxonomyMap(taxonomy);
      setDropdownSolutionMap(dropdownSolutionMap);
    } else if (activityTemplateType === "MCSA") {
      for (let i = 0; i < Object.keys(mcsaBodyMap).length; i++) {
        currentBodyMap[i + foundImageKeyList.length] = mcsaBodyMap[i];
      }
      setMCSABodyMap(currentBodyMap);
      setMCSAMaterialMap(mcsaMaterialMap);
      setMCSATaxonomyMap(taxonomy);
      setMCSASolutionMap(mcsaSolutionMap);
    } else if (activityTemplateType === "MCMA") {
      for (let i = 0; i < Object.keys(mcmaBodyMap).length; i++) {
        currentBodyMap[i + foundImageKeyList.length] = mcmaBodyMap[i];
      }
      setMCMABodyMap(currentBodyMap);
      setMCMAMaterialMap(mcmaMaterialMap);
      setMCMATaxonomyMap(taxonomy);
      setMCMASolutionMap(mcmaSolutionMap);
    } else if (activityTemplateType === "MATCHING") {
      for (let i = 0; i < Object.keys(matchingBodyMap).length; i++) {
        currentBodyMap[i + foundImageKeyList.length] = matchingBodyMap[i];
      }
      setMatchingBodyMap(currentBodyMap);
      setMatchingMaterialMap(matchingMaterialMap);
      setMatchingTaxonomyMap(taxonomy);
      setMatchingSolutionMap(matchingSolutionMap);
    } else if (activityTemplateType === "GROUPING") {
      for (let i = 0; i < Object.keys(groupingBodyMap).length; i++) {
        currentBodyMap[i + foundImageKeyList.length] = groupingBodyMap[i];
      }
      setGroupingBodyMap(currentBodyMap);
      setGroupingMaterialMap(groupingMaterialMap);
      setGroupingTaxonomyMap(taxonomy);
      setGroupingSolutionMap(groupingSolutionMap);
    } else if (activityTemplateType === "OPEN_ENDED") {
      for (let i = 0; i < Object.keys(openEndedBodyMap).length; i++) {
        currentBodyMap[i + foundImageKeyList.length] = openEndedBodyMap[i];
      }
      setOpenEndedBodyMap(currentBodyMap);
      setOpenEndedTaxonomyMap(taxonomy);
      setOpenEndedSolutionMap(openEndedSolutionMap);
    } else if (activityTemplateType === "FILL_IN_THE_BLANKS") {
      for (let i = 0; i < Object.keys(fillInTheBlanksBodyMap).length; i++) {
        currentBodyMap[i + foundImageKeyList.length] =
          fillInTheBlanksBodyMap[i];
      }
      setFillInTheBlanksBodyMap(currentBodyMap);
      setFillInTheBlanksMaterialMap(fillInTheBlanksMaterialMap);
      setFillInTheBlanksTaxonomyMap(taxonomy);
      setFillInTheBlanksSolutionMap(fillInTheBlanksSolutionMap);
    } else if (activityTemplateType === "TRUE_FALSE") {
      for (let i = 0; i < Object.keys(trueFalseBodyMap).length; i++) {
        currentBodyMap[i + foundImageKeyList.length] = trueFalseBodyMap[i];
      }
      setTrueFalseBodyMap(currentBodyMap);
      setTrueFalseMaterialMap(trueFalseMaterialMap);
      setTrueFalseTaxonomyMap(taxonomy);
      setTrueFalseSolutionMap(trueFalseSolutionMap);
    }
  };

  const handleConvertWithAIOnClick = async () => {
    const foundActivityTemplate = activityTemplateSet.find(
      (activityTemplate) =>
        activityTemplate.id === parseFloat(selectedActivityTemplateId)
    );
    if (!foundActivityTemplate) return;
    const generateWithAI = constructGenerateWithAIRequest(
      foundActivityTemplate.type
    );
    if (!generateWithAI) return;
    const { data, err } = await convertActivityTemplateWithAI(generateWithAI);
    if (err) {
      NotificationManager.error(
        i18n.t("error_occurred_on_activity_template_generation")
      );
      return;
    }
    NotificationManager.success(
      i18n.t("activity_template_successfully_generated")
    );
    retrieveAndSetTokenAssignmentList();
    constructGenerateWithAIResponse(foundActivityTemplate.type, data, true);
  };

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      // Fallback method
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
    }
  };

  const handleGenerateWithAIOnClick = async () => {
    const foundActivityTemplate = activityTemplateSet.find(
      (activityTemplate) =>
        activityTemplate.id === parseFloat(selectedActivityTemplateId)
    );
    if (!foundActivityTemplate) return;
    const generateWithAI = constructGenerateWithAIRequest(
      foundActivityTemplate.type
    );
    if (!generateWithAI) return;
    const { data, err } = await generateActivityTemplateWithAI(generateWithAI);
    if (err) {
      NotificationManager.error(
        i18n.t("error_occurred_on_activity_template_generation")
      );
      return;
    }
    NotificationManager.success(
      i18n.t("activity_template_successfully_generated")
    );
    // retrieveAndSetTokenAssignmentList();
    constructGenerateWithAIResponse(foundActivityTemplate.type, data, false);
  };

  const handleSolveActivityWithAIOnClick = async () => {
    if (selectedMaterialContentType !== "TEXT") return;
    const foundActivityTemplate = activityTemplateSet.find(
      (activityTemplate) =>
        activityTemplate.id === parseFloat(selectedActivityTemplateId)
    );
    NotificationManager.info(i18n.t("please_wait_solving_text"));
    let itemList = [];
    if (foundActivityTemplate.type === "ORDERING") {
      itemList = constructActivityItemListWithSolutionForAI(
        orderingBodyMap,
        orderingMaterialMap,
        foundActivityTemplate.type,
        imageContentList
      );
    } else if (foundActivityTemplate.type === "DROPDOWN") {
      itemList = constructActivityItemListWithSolutionForAI(
        dropdownBodyMap,
        dropdownMaterialMap,
        foundActivityTemplate.type,
        imageContentList
      );
    } else if (foundActivityTemplate.type === "MCSA") {
      itemList = constructActivityItemListWithSolutionForAI(
        MCSABodyMap,
        MCSAMaterialMap,
        foundActivityTemplate.type,
        imageContentList
      );
    } else if (foundActivityTemplate.type === "MCMA") {
      itemList = constructActivityItemListWithSolutionForAI(
        MCMABodyMap,
        MCMAMaterialMap,
        foundActivityTemplate.type,
        imageContentList
      );
    } else if (foundActivityTemplate.type === "MATCHING") {
      itemList = constructActivityItemListWithSolutionForAI(
        matchingBodyMap,
        matchingMaterialMap,
        foundActivityTemplate.type,
        imageContentList
      );
    } else if (foundActivityTemplate.type === "GROUPING") {
      itemList = constructActivityItemListWithSolutionForAI(
        groupingBodyMap,
        groupingMaterialMap,
        foundActivityTemplate.type,
        imageContentList
      );
    } else if (foundActivityTemplate.type === "FILL_IN_THE_BLANKS") {
      itemList = constructActivityItemListWithSolutionForAI(
        fillInTheBlanksBodyMap,
        fillInTheBlanksMaterialMap,
        foundActivityTemplate.type,
        imageContentList
      );
    } else if (foundActivityTemplate.type === "OPEN_ENDED") {
      itemList = constructActivityItemListWithSolutionForAI(
        openEndedBodyMap,
        openEndedMaterialMap,
        foundActivityTemplate.type,
        imageContentList
      );
    } else if (foundActivityTemplate.type === "TRUE_FALSE") {
      itemList = constructActivityItemListWithSolutionForAI(
        trueFalseBodyMap,
        trueFalseMaterialMap,
        foundActivityTemplate.type,
        imageContentList
      );
    }

    const solveWithAI = {
      itemList,
      temperature: 0,
      level: selectedLevel,
      coterieType: selectedCoterieType,
      activityTemplateType: foundActivityTemplate.type,
      userId: userInformation.id,
      userProfileId: userProfile.id,
      brandId: userProfileBrand.id,
      campusId: userProfileCampus?.id,
      institutionId: userProfileInstitution?.id,
    };
    const { data, err } = await solveActivityWithAIFromO1(solveWithAI);
    if (err) {
      NotificationManager.error(i18n.t("error_occurred_on_solving_activity"));
      return;
    }
    NotificationManager.success(
      i18n.t("activity_solution_successfully_generated")
    );
    const { solutionMap } = data;
    if (foundActivityTemplate.type === "ORDERING") {
      setOrderingSolutionMap(solutionMap);
    } else if (foundActivityTemplate.type === "DROPDOWN") {
      setDropdownSolutionMap(solutionMap);
    } else if (foundActivityTemplate.type === "MCSA") {
      setMCSASolutionMap(solutionMap);
    } else if (foundActivityTemplate.type === "MCMA") {
      setMCMASolutionMap(solutionMap);
    } else if (foundActivityTemplate.type === "MATCHING") {
      setMatchingSolutionMap(solutionMap);
    } else if (foundActivityTemplate.type === "GROUPING") {
      setGroupingSolutionMap(solutionMap);
    } else if (foundActivityTemplate.type === "FILL_IN_THE_BLANKS") {
      setFillInTheBlanksSolutionMap(solutionMap);
    } else if (foundActivityTemplate.type === "OPEN_ENDED") {
      setOpenEndedSolutionMap(solutionMap);
    } else if (foundActivityTemplate.type === "TRUE_FALSE") {
      setTrueFalseSolutionMap(solutionMap);
    }
  };

  const handleRefreshImageTextOnClick = async (value) => {
    NotificationManager.info(i18n.t("please_wait_refreshing_image_text"));
    const foundImageContent = imageContentList.find(
      (imageContent) => imageContent.url === value
    );
    if (!foundImageContent) return;
    setShowRefreshButton(false);
    const { data: translateOnlyImageData, err: translateOnlyImageErr } =
      await convertImageTranslateImageWithAI({
        imageUrl: foundImageContent.url,
      });
    if (translateOnlyImageErr) {
      console.log(translateOnlyImageErr);
      setShowRefreshButton(true);
      NotificationManager.error(i18n.t("refreshing_image_text_has_failed"));
      return;
    }

    const { data: imageTranslateData, err: imageTranslateErr } =
      await patchImageTranslate({
        id: foundImageContent.id,
        content: translateOnlyImageData.content,
      });
    if (imageTranslateErr) {
      console.log(imageTranslateErr);
      setShowRefreshButton(true);
      NotificationManager.error(i18n.t("refreshing_image_text_has_failed"));
      return;
    }
    foundImageContent.content = imageTranslateData.content;
    setImageContentList(JSON.parse(JSON.stringify(imageContentList)));
    NotificationManager.success(
      i18n.t("refreshing_image_text_has_successfully_completed")
    );
    setShowRefreshButton(true);
  };

  const handleCancelImageTextOnClick = (value) => {
    const foundImageContent = imageContentList.find(
      (imageContent) => imageContent.url === value
    );
    if (!foundImageContent) return;
    setImageTextUpdateId(null);
    setShowRefreshButton(true);
  };

  const handleUpdateImageTextOnClick = async (value) => {
    const foundImageContent = imageContentList.find(
      (imageContent) => imageContent.url === value
    );
    if (!foundImageContent) return;
    if (imageTextUpdateId) {
      const { data, err } = await patchImageTranslate({
        id: foundImageContent.id,
        content: imageText,
      });
      if (err) {
        console.log(err);
        NotificationManager.error(i18n.t("updating_image_text_has_failed"));
        setShowRefreshButton(true);
        return;
      }
      foundImageContent.content = imageText;
      NotificationManager.success(
        i18n.t("refreshing_image_text_has_successfully_completed")
      );
      setImageContentList(JSON.parse(JSON.stringify(imageContentList)));
      setImageTextUpdateId(null);
      setShowRefreshButton(true);
    } else {
      setShowRefreshButton(false);
      setImageTextUpdateId(foundImageContent.id);
      setImageText(foundImageContent.content);
    }
  };

  const handleConvertImageTranslateTextInImageOnClick = async (imageUrl) => {
    setTranslateTextLoading(true);
    NotificationManager.info(
      i18n.t("translating_text_inside_image_please_wait_text")
    );
    const { data, err } = await convertImageTranslateTextInImageWithAI({
      imageUrl,
    });
    if (err) {
      console.log(err);
      return;
    }
    const { content } = data;
    await handleCopy(content);
    NotificationManager.success(i18n.t("you_can_paste_the_translated_text"));
    setTranslateTextLoading(false);
  };

  const RenderSingleActivityCategoryItem = (activityCategory) => {
    return (
      <div className="flex flex-row justify-between items-center my-2 border rounded-catchup-xlarge p-2 border-catchup-gray-100">
        <div className="flex-1 flex flex-row items-center">
          <div className="w-[100px] lg:w-[150px]">
            <p className="mx-1">{activityCategory.code}</p>
          </div>
          <div className="flex-1">
            <p className="mx-1">{activityCategory.name}</p>
          </div>
          <div className="w-1/2">
            <p className="mx-1">{activityCategory.description}</p>
          </div>
        </div>

        <div className="mx-2">
          <BaseImage
            src="/icons/remove-red.png"
            alt="remove-red"
            size="small"
            onClick={() => {
              handleRemoveCategoryFromActivityCategoryListOnClick(
                activityCategory.id
              );
            }}
          />
        </div>
      </div>
    );
  };

  const RenderTaxonomyOptionList = (taxonomyMap, setTaxonomyMap) => {
    return (
      <div>
        <div className="">
          <p className="font-semibold text-lg">{i18n.t("taxonomy_choise")}</p>
        </div>
        <div className="flex flex-row items-center flex-wrap">
          <div className="w-1/2 lg:w-1/3">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("taxonomy_type")}
                placeholder={i18n.t("taxonomy_type")}
                value={taxonomyMap.type}
                optionList={retrieveTaxonomyType()}
                onChange={(event) => {
                  setTaxonomyMap({
                    type: event.target.value,
                    groupName: "DEFAULT_OPTION",
                    name: "DEFAULT_OPTION",
                    stage: "DEFAULT_OPTION",
                  });
                }}
              />
            </div>
          </div>
          {taxonomyMap.type && taxonomyMap.type !== "DEFAULT_OPTION" ? (
            <div className="w-1/2 lg:w-1/3">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("taxonomy_group_name")}
                  placeholder={i18n.t("taxonomy_group_name")}
                  value={taxonomyMap.groupName}
                  optionList={retrieveFilteredTaxonomyGroupName(
                    taxonomyMap.type
                  )}
                  onChange={(event) => {
                    setTaxonomyMap({
                      type: taxonomyMap.type,
                      groupName: event.target.value,
                      name: "DEFAULT_OPTION",
                      stage: "DEFAULT_OPTION",
                    });
                  }}
                />
              </div>
            </div>
          ) : null}

          {taxonomyMap.groupName &&
          taxonomyMap.groupName !== "DEFAULT_OPTION" ? (
            <div className="w-1/2 lg:w-1/3">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("taxonomy_name")}
                  placeholder={i18n.t("taxonomy_name")}
                  value={taxonomyMap.name ? taxonomyMap.name : "DEFAULT_OPTION"}
                  optionList={retrieveFilteredTaxonomyName(
                    taxonomyMap.groupName
                  )}
                  onChange={(event) => {
                    const currentTaxonomyName = event.target.value;
                    const foundTaxonomyName = retrieveFilteredTaxonomyName(
                      taxonomyMap.groupName
                    ).find(
                      (taxonomyName) =>
                        taxonomyName.value === currentTaxonomyName
                    );
                    setTaxonomyMap({
                      type: taxonomyMap.type,
                      groupName: taxonomyMap.groupName,
                      name: foundTaxonomyName.value,
                      stage: foundTaxonomyName.stage,
                    });
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
        <DividerLine />
      </div>
    );
  };

  const RenderParsedActivityImageData = () => {
    if (parsedActivity?.imageData) {
      return (
        <>
          <div className="flex flex-row justify-center border rounded-catchup-xlarge border-catchup-gray-100 p-2">
            <div className={`flex-1 flex justify-center`}>
              {isCropModeActive ? (
                <ReactCrop
                  crop={crop}
                  onChange={(c) => {
                    setCrop(c);
                  }}
                >
                  <img ref={cropImageRef} src={parsedActivity.imageData} />
                </ReactCrop>
              ) : (
                <img src={parsedActivity.imageData} />
              )}
            </div>
            <div className="flex flex-col gap-y-2">
              <SecondaryButton
                title={i18n.t("crop_mode")}
                size="unlimited"
                onClick={() => {
                  setIsCropModeActive(true);
                }}
                disabled={isCropModeActive}
              />
              <SecondaryButton
                title={i18n.t("image_mode")}
                size="unlimited"
                onClick={() => {
                  setIsCropModeActive(false);
                  setCrop(null);
                }}
                disabled={!isCropModeActive}
              />

              <div className="flex flex-col mt-6 gap-y-2">
                <SecondaryButton
                  title={i18n.t("add_to_activity_body")}
                  size="unlimited"
                  onClick={handleAddCroppedImageToActivityBody}
                  disabled={!isCropModeActive || !crop}
                />
                {selectedMaterialContentType === "IMAGE" ? (
                  <SecondaryButton
                    title={i18n.t("add_to_activity_material")}
                    size="unlimited"
                    onClick={handleAddCroppedImageToActivityMaterial}
                    disabled={!isCropModeActive || !crop}
                  />
                ) : null}

                <SecondaryButton
                  title={i18n.t("add_only_to_library")}
                  size="unlimited"
                  onClick={handleAddCroppedImageToLibrary}
                  disabled={!isCropModeActive || !crop}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  const RenderSingleDocumentByContentType = (type, src) => {
    return type === "IMAGE" ? (
      <div className="flex flex-row flex-wrap items-center my-2">
        <div className="flex flex-col justify-center items-center ">
          <BaseImage
            src={src}
            alt="document-content-type"
            size="custom"
            className="w-[200px] rounded-catchup-xlarge"
          />
        </div>
      </div>
    ) : type === "VIDEO" ? (
      <div className="flex flex-row flex-wrap items-center my-2">
        <div className="flex flex-col justify-center items-center">
          <div className="w-[200px] rounded-catchup-xlarge">
            <video className="w-full" src={src} controls />
          </div>
        </div>
      </div>
    ) : type === "AUDIO" ? (
      <div className="flex flex-row flex-wrap items-center my-2">
        <div className="flex flex-col justify-center items-center">
          <div className="w-[300px] rounded-catchup-xlarge">
            <audio className="w-full" src={src} controls />
          </div>
        </div>
      </div>
    ) : null;
  };

  const RenderActivityMaterialErrorCheck = (type) => {
    if (type === "DROPDOWN" || type === "FILL_IN_THE_BLANKS") {
      const { bodyPartCount, materialPartCount } =
        calculateActivityMaterialErrorCount(type);
      if (bodyPartCount !== materialPartCount) {
        return (
          <div className="my-2 p-3 border rounded-catchup-xlarge bg-catchup-red text-catchup-white">
            <p>
              {i18n.t("activity_body_and_material_does_not_match_error_text")}
            </p>
            <p className="ml-3">
              - {i18n.t("activity_body_@@_count")}: {bodyPartCount}
            </p>
            <p className="ml-3">
              - {i18n.t("activity_material_@@_count")}: {materialPartCount}
            </p>
          </div>
        );
      }
    }
    return null;
  };

  const RenderActivityTemplateBodyMap = (contentType, bodyMap, setBodyMap) => {
    const currentSolutionMap = retrieveCurrentSolutionMap();
    let isApprovedSolution = false;
    for (const key of Object.keys(currentSolutionMap)) {
      const { type, value } = currentSolutionMap[key];
      if (type === "TEXT" && value.includes("[ONAYLI CEVAP]")) {
        isApprovedSolution = true;
      }
    }
    return (
      <div className="my-5">
        <div className="">
          <p className="font-semibold text-lg">{i18n.t("activity_body")}</p>
        </div>

        <div className="mt-3">
          {Object.keys(bodyMap).map((key, index) => {
            let foundImageContent;
            if (bodyMap[key].type === "IMAGE") {
              foundImageContent = imageContentList.find(
                (image) => image.url === bodyMap[key].value
              );
            }
            return (
              <div key={index}>
                <div className="flex flex-row justify-between items-center">
                  <div className="relative mx-2 w-4 flex flex-col items-center">
                    {Object.keys(bodyMap).length === 1 ||
                    (index === 0 && Object.keys(bodyMap).length > 1) ? null : (
                      <div className="absolute -top-3">
                        <BaseImage
                          size="xsmall"
                          src="/icons/arrow-up.png"
                          alt="arrow-up"
                          onClick={() => {
                            const currentValue = bodyMap[key];
                            const nextKey = Object.keys(bodyMap)[index - 1];
                            const nextValue = bodyMap[nextKey];
                            bodyMap[key] = nextValue;
                            bodyMap[nextKey] = currentValue;
                            setBodyMap(JSON.parse(JSON.stringify(bodyMap)));
                          }}
                        />
                      </div>
                    )}
                    <p>{parseFloat(key) + 1}.</p>
                    {Object.keys(bodyMap).length === 1 ||
                    (index === Object.keys(bodyMap).length - 1 &&
                      Object.keys(bodyMap).length > 1) ? null : (
                      <div className="absolute -bottom-3">
                        <BaseImage
                          size="xsmall"
                          src="/icons/arrow-down.png"
                          alt="arrow-down"
                          onClick={() => {
                            const currentValue = bodyMap[key];
                            const nextKey = Object.keys(bodyMap)[index + 1];
                            const nextValue = bodyMap[nextKey];
                            bodyMap[key] = nextValue;
                            bodyMap[nextKey] = currentValue;
                            setBodyMap(JSON.parse(JSON.stringify(bodyMap)));
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {bodyMap[key].type === "TEXT" ? (
                    <div className="flex-1">
                      <InputGroup
                        type="textarea"
                        title={null}
                        placeholder={null}
                        value={bodyMap[key].value}
                        onChange={(event) => {
                          bodyMap[key].value = event.target.value;
                          setBodyMap(JSON.parse(JSON.stringify(bodyMap)));
                        }}
                      />
                    </div>
                  ) : (
                    <div className="flex-1">
                      {RenderSingleDocumentByContentType(
                        bodyMap[key].type,
                        bodyMap[key].value
                      )}
                    </div>
                  )}
                  {bodyMap[key].type === "TEXT" ? null : (
                    <div className="flex flex-row items-center gap-x-2">
                      <div className="">
                        <BaseImage
                          src="/icons/translate-with-ai.png"
                          size="small"
                          onClick={() => {
                            if (translateTextLoading) return;
                            handleConvertImageTranslateTextInImageOnClick(
                              bodyMap[key].value
                            );
                          }}
                        />
                      </div>
                      <div className="">
                        <InputGroup
                          type="select"
                          title={null}
                          placeholder={null}
                          value={bodyMap[key].size}
                          optionList={retrieveMediaSizeOptionList()}
                          onChange={(event) => {
                            bodyMap[key].size = event.target.value;
                            setBodyMap(JSON.parse(JSON.stringify(bodyMap)));
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="mx-2">
                    <BaseImage
                      src="/icons/remove-red.png"
                      alt="remove-red"
                      size="small"
                      onClick={() => {
                        handleRemoveBodyContentFromBodyMap(contentType, key);
                      }}
                    />
                  </div>
                </div>
                {bodyMap[key].type === "TEXT" ? (
                  <InputWithSpecialExpression value={bodyMap[key].value} />
                ) : foundImageContent ? (
                  <div className="flex-1 relative">
                    {userInformation.id === 49 && !isApprovedSolution ? (
                      imageTextUpdateId ? (
                        imageTextUpdateId === foundImageContent.id ? (
                          <>
                            {showRefreshButton ? (
                              <div className="absolute left-5 -top-2 z-10 bg-catchup-white border border-catchup-gray-100 rounded-md px-2">
                                <TextGroup
                                  title={i18n.t("refresh")}
                                  onClick={() => {
                                    handleRefreshImageTextOnClick(
                                      bodyMap[key].value
                                    );
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="absolute right-24 -top-2 z-10 bg-catchup-white border border-catchup-gray-100 rounded-md px-2">
                                <TextGroup
                                  title={i18n.t("cancel")}
                                  onClick={() => {
                                    handleCancelImageTextOnClick(
                                      bodyMap[key].value
                                    );
                                  }}
                                />
                              </div>
                            )}
                            <div className="absolute right-5 -top-2 z-10 bg-catchup-white border border-catchup-gray-100 rounded-md px-2">
                              <TextGroup
                                title={i18n.t("edit")}
                                onClick={() => {
                                  handleUpdateImageTextOnClick(
                                    bodyMap[key].value
                                  );
                                }}
                              />
                            </div>
                          </>
                        ) : null
                      ) : (
                        <>
                          {showRefreshButton ? (
                            <div className="absolute left-5 -top-2 z-10 bg-catchup-white border border-catchup-gray-100 rounded-md px-2">
                              <TextGroup
                                title={i18n.t("refresh")}
                                onClick={() => {
                                  handleRefreshImageTextOnClick(
                                    bodyMap[key].value
                                  );
                                }}
                              />
                            </div>
                          ) : (
                            <div className="absolute right-24 -top-2 z-10 bg-catchup-white border border-catchup-gray-100 rounded-md px-2">
                              <TextGroup
                                title={i18n.t("cancel")}
                                onClick={() => {
                                  handleCancelImageTextOnClick(
                                    bodyMap[key].value
                                  );
                                }}
                              />
                            </div>
                          )}
                          <div className="absolute right-5 -top-2 z-10 bg-catchup-white border border-catchup-gray-100 rounded-md px-2">
                            <TextGroup
                              title={i18n.t("edit")}
                              onClick={() => {
                                handleUpdateImageTextOnClick(
                                  bodyMap[key].value
                                );
                              }}
                            />
                          </div>
                        </>
                      )
                    ) : null}

                    {imageTextUpdateId &&
                    imageTextUpdateId === foundImageContent.id ? (
                      <div className="mx-2">
                        <InputGroup
                          type="textarea"
                          title={null}
                          placeholder={null}
                          disabled={false}
                          onChange={(e) => {
                            handleOnChange("imageText", e.target.value);
                          }}
                          value={imageText}
                        />
                      </div>
                    ) : (
                      <div className="mx-2">
                        <InputGroup
                          type="textarea"
                          title={null}
                          placeholder={null}
                          disabled={true}
                          value={
                            imageContentList.find(
                              (image) => image.url === bodyMap[key].value
                            ).content
                          }
                        />
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>

        <div className="">
          <div className="flex flex-row justify-center items-center">
            <div className="flex flex-row items-center gap-3 my-5">
              {retrieveContentTypeOptionList().map((option, optionIndex) => (
                <div key={optionIndex} className="mx-2">
                  <BaseImage
                    src={
                      option.value === "TEXT"
                        ? "/icons/activity-template-material-text-plus.png"
                        : option.value === "IMAGE"
                        ? "/icons/activity-template-material-image-plus.png"
                        : option.value === "VIDEO"
                        ? "/icons/activity-template-material-video-plus.png"
                        : option.value === "AUDIO"
                        ? "/icons/activity-template-material-audio-plus.png"
                        : null
                    }
                    alt="activity-template-material"
                    size="large"
                    onClick={() => {
                      if (option.value === "TEXT") {
                        handleAddBodyContentToBodyMap(
                          contentType,
                          option.value,
                          ""
                        );
                      } else {
                        setBodyLibraryModal({ show: true, type: option.value });
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderActivityTemplateSolutionMap = (
    contentType,
    solutionMap,
    setSolutionMap
  ) => {
    return (
      <div className="my-5">
        <div className="">
          <p className="font-semibold text-lg">{i18n.t("activity_solution")}</p>
        </div>

        <div className="flex flex-row items-center justify-between gap-x-2">
          <div></div>
          {checkCanShowSolveWithAI() ? (
            canSolveActivity ? (
              <div className="">
                <SecondaryButton
                  title={i18n.t("solve_with_ai")}
                  size="unlimited"
                  onClick={handleSolveActivityWithAIOnClick}
                />
              </div>
            ) : (
              RenderInsufficientTokensSolveActivityContent()
            )
          ) : null}
        </div>

        <div className="mt-3">
          {Object.keys(solutionMap).map((key, index) => (
            <div key={index}>
              <div className="flex flex-row justify-between items-center">
                <div className="relative mx-2 w-4 flex flex-col items-center">
                  {Object.keys(solutionMap).length === 1 ||
                  (index === 0 &&
                    Object.keys(solutionMap).length > 1) ? null : (
                    <div className="absolute -top-3">
                      <BaseImage
                        size="xsmall"
                        src="/icons/arrow-up.png"
                        alt="arrow-up"
                        onClick={() => {
                          const currentValue = solutionMap[key];
                          const nextKey = Object.keys(solutionMap)[index - 1];
                          const nextValue = solutionMap[nextKey];
                          solutionMap[key] = nextValue;
                          solutionMap[nextKey] = currentValue;
                          setSolutionMap(
                            JSON.parse(JSON.stringify(solutionMap))
                          );
                        }}
                      />
                    </div>
                  )}
                  <p>{parseFloat(key) + 1}.</p>
                  {Object.keys(solutionMap).length === 1 ||
                  (index === Object.keys(solutionMap).length - 1 &&
                    Object.keys(solutionMap).length > 1) ? null : (
                    <div className="absolute -bottom-3">
                      <BaseImage
                        size="xsmall"
                        src="/icons/arrow-down.png"
                        alt="arrow-down"
                        onClick={() => {
                          const currentValue = solutionMap[key];
                          const nextKey = Object.keys(solutionMap)[index + 1];
                          const nextValue = solutionMap[nextKey];
                          solutionMap[key] = nextValue;
                          solutionMap[nextKey] = currentValue;
                          setSolutionMap(
                            JSON.parse(JSON.stringify(solutionMap))
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
                {solutionMap[key].type === "TEXT" ? (
                  <div className="flex-1">
                    <InputGroup
                      type="textarea"
                      title={null}
                      placeholder={null}
                      value={solutionMap[key].value}
                      onChange={(event) => {
                        solutionMap[key].value = event.target.value;
                        setSolutionMap(JSON.parse(JSON.stringify(solutionMap)));
                      }}
                    />
                  </div>
                ) : (
                  <div className="flex-1">
                    {RenderSingleDocumentByContentType(
                      solutionMap[key].type,
                      solutionMap[key].value
                    )}
                  </div>
                )}
                {solutionMap[key].type === "TEXT" ? null : (
                  <div className="mx-2">
                    <InputGroup
                      type="select"
                      title={null}
                      placeholder={null}
                      value={solutionMap[key].size}
                      optionList={retrieveMediaSizeOptionList()}
                      onChange={(event) => {
                        solutionMap[key].size = event.target.value;
                        setSolutionMap(JSON.parse(JSON.stringify(solutionMap)));
                      }}
                    />
                  </div>
                )}

                <div className="mx-2">
                  <BaseImage
                    src="/icons/remove-red.png"
                    alt="remove-red"
                    size="small"
                    onClick={() => {
                      handleRemoveSolutionContentFromSolutionMap(
                        contentType,
                        key
                      );
                    }}
                  />
                </div>
              </div>
              <InputWithSpecialExpression value={solutionMap[key].value} />
            </div>
          ))}
        </div>

        <div className="">
          <div className="flex flex-row justify-center items-center">
            <div className="flex flex-row items-center gap-3 my-5">
              {retrieveContentTypeOptionList().map((option, optionIndex) => (
                <div key={optionIndex} className="mx-2">
                  <BaseImage
                    src={
                      option.value === "TEXT"
                        ? "/icons/activity-template-material-text-plus.png"
                        : option.value === "IMAGE"
                        ? "/icons/activity-template-material-image-plus.png"
                        : option.value === "VIDEO"
                        ? "/icons/activity-template-material-video-plus.png"
                        : option.value === "AUDIO"
                        ? "/icons/activity-template-material-audio-plus.png"
                        : null
                    }
                    alt="activity-template-material"
                    size="large"
                    onClick={() => {
                      if (option.value === "TEXT") {
                        handleAddSolutionContentToSolutionMap(
                          contentType,
                          option.value,
                          ""
                        );
                      } else {
                        setSolutionLibraryModal({
                          show: true,
                          type: option.value,
                        });
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderActivityTemplateEvaluationRubricMap = (
    contentType,
    evaluationRubricMap,
    setEvaluationRubricMap
  ) => {
    return (
      <div className="my-5">
        <div className="">
          <p className="font-semibold text-lg">
            {i18n.t("activity_evaluation_rubric")}
          </p>
        </div>
        <div className="mt-3">
          {Object.keys(evaluationRubricMap).map((key, index) => (
            <div key={index}>
              <div className="flex flex-row justify-between items-center">
                <div className="relative mx-2 w-4 flex flex-col items-center">
                  {Object.keys(evaluationRubricMap).length === 1 ||
                  (index === 0 &&
                    Object.keys(evaluationRubricMap).length > 1) ? null : (
                    <div className="absolute -top-3">
                      <BaseImage
                        size="xsmall"
                        src="/icons/arrow-up.png"
                        alt="arrow-up"
                        onClick={() => {
                          const currentValue = evaluationRubricMap[key];
                          const nextKey =
                            Object.keys(evaluationRubricMap)[index - 1];
                          const nextValue = evaluationRubricMap[nextKey];
                          evaluationRubricMap[key] = nextValue;
                          evaluationRubricMap[nextKey] = currentValue;
                          setEvaluationRubricMap(
                            JSON.parse(JSON.stringify(evaluationRubricMap))
                          );
                        }}
                      />
                    </div>
                  )}
                  <p>{parseFloat(key) + 1}.</p>
                  {Object.keys(evaluationRubricMap).length === 1 ||
                  (index === Object.keys(evaluationRubricMap).length - 1 &&
                    Object.keys(evaluationRubricMap).length > 1) ? null : (
                    <div className="absolute -bottom-3">
                      <BaseImage
                        size="xsmall"
                        src="/icons/arrow-down.png"
                        alt="arrow-down"
                        onClick={() => {
                          const currentValue = evaluationRubricMap[key];
                          const nextKey =
                            Object.keys(evaluationRubricMap)[index + 1];
                          const nextValue = evaluationRubricMap[nextKey];
                          evaluationRubricMap[key] = nextValue;
                          evaluationRubricMap[nextKey] = currentValue;
                          setEvaluationRubricMap(
                            JSON.parse(JSON.stringify(evaluationRubricMap))
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
                {evaluationRubricMap[key].type === "TEXT" ? (
                  <div className="flex-1">
                    <InputGroup
                      type="textarea"
                      title={null}
                      placeholder={null}
                      value={evaluationRubricMap[key].value}
                      onChange={(event) => {
                        evaluationRubricMap[key].value = event.target.value;
                        setEvaluationRubricMap(
                          JSON.parse(JSON.stringify(evaluationRubricMap))
                        );
                      }}
                    />
                  </div>
                ) : (
                  <div className="flex-1">
                    {RenderSingleDocumentByContentType(
                      evaluationRubricMap[key].type,
                      evaluationRubricMap[key].value
                    )}
                  </div>
                )}
                {evaluationRubricMap[key].type === "TEXT" ? null : (
                  <div className="mx-2">
                    <InputGroup
                      type="select"
                      title={null}
                      placeholder={null}
                      value={evaluationRubricMap[key].size}
                      optionList={retrieveMediaSizeOptionList()}
                      onChange={(event) => {
                        evaluationRubricMap[key].size = event.target.value;
                        setEvaluationRubricMap(
                          JSON.parse(JSON.stringify(evaluationRubricMap))
                        );
                      }}
                    />
                  </div>
                )}

                <div className="mx-2">
                  <BaseImage
                    src="/icons/remove-red.png"
                    alt="remove-red"
                    size="small"
                    onClick={() => {
                      handleRemoveEvaluationRubricContentFromEvaluationRubricMap(
                        contentType,
                        key
                      );
                    }}
                  />
                </div>
              </div>
              <InputWithSpecialExpression
                value={evaluationRubricMap[key].value}
              />
            </div>
          ))}
        </div>

        <div className="">
          <div className="flex flex-row justify-center items-center">
            <div className="flex flex-row items-center gap-3 my-5">
              {retrieveContentTypeOptionList(true).map(
                (option, optionIndex) => (
                  <div key={optionIndex} className="mx-2">
                    <BaseImage
                      src={
                        option.value === "TEXT"
                          ? "/icons/activity-template-material-text-plus.png"
                          : option.value === "IMAGE"
                          ? "/icons/activity-template-material-image-plus.png"
                          : option.value === "VIDEO"
                          ? "/icons/activity-template-material-video-plus.png"
                          : option.value === "AUDIO"
                          ? "/icons/activity-template-material-audio-plus.png"
                          : null
                      }
                      alt="activity-template-material"
                      size="large"
                      onClick={() => {
                        if (option.value === "TEXT") {
                          handleAddEvaluationRubricContentToEvaluationRubricMap(
                            contentType,
                            option.value,
                            ""
                          );
                        } else {
                        }
                      }}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderActivityTemplateInformationContent = (activityTemplateType) => {
    if (activityTemplateType === "ORDERING") {
      return (
        <>
          {SingleInformationIconTextItem(
            i18n.t("create_ordering_activity_information_text_1")
          )}
          {SingleInformationIconTextItem(
            i18n.t("create_ordering_activity_information_text_2")
          )}
        </>
      );
    } else if (activityTemplateType === "DROPDOWN") {
      return (
        <>
          {SingleInformationIconTextItem(
            i18n.t("create_dropdown_activity_information_text_1")
          )}
          {SingleInformationIconTextItem(
            i18n.t("create_dropdown_activity_information_text_2")
          )}
          {SingleInformationIconTextItem(
            i18n.t("create_dropdown_activity_information_text_3")
          )}
        </>
      );
    } else if (activityTemplateType === "MCSA") {
      return (
        <>
          {SingleInformationIconTextItem(
            i18n.t("create_mcsa_activity_information_text_1")
          )}
          {SingleInformationIconTextItem(
            i18n.t("create_mcsa_activity_information_text_2")
          )}
        </>
      );
    } else if (activityTemplateType === "MCMA") {
      return (
        <>
          {SingleInformationIconTextItem(
            i18n.t("create_mcma_activity_information_text_1")
          )}
          {SingleInformationIconTextItem(
            i18n.t("create_mcma_activity_information_text_2")
          )}
        </>
      );
    } else if (activityTemplateType === "MATCHING") {
      return (
        <>
          {SingleInformationIconTextItem(
            i18n.t("create_matching_activity_information_text_1")
          )}
          {SingleInformationIconTextItem(
            i18n.t("create_matching_activity_information_text_2")
          )}
          {SingleInformationIconTextItem(
            i18n.t("create_matching_activity_information_text_3")
          )}
        </>
      );
    } else if (activityTemplateType === "GROUPING") {
      return (
        <>
          {SingleInformationIconTextItem(
            i18n.t("create_grouping_activity_information_text_1")
          )}
          {SingleInformationIconTextItem(
            i18n.t("create_grouping_activity_information_text_2")
          )}
          {SingleInformationIconTextItem(
            i18n.t("create_grouping_activity_information_text_3")
          )}
          {SingleInformationIconTextItem(
            i18n.t("create_grouping_activity_information_text_4")
          )}
        </>
      );
    } else if (activityTemplateType === "FILL_IN_THE_BLANKS") {
      return (
        <>
          {/* {SingleInformationIconTextItem(
            i18n.t("create_fill_in_the_blanks_activity_information_text_1")
          )} */}
          {SingleInformationIconTextItem(
            i18n.t("create_fill_in_the_blanks_activity_information_text_2")
          )}
          {/* {SingleInformationIconTextItem(
            i18n.t("create_fill_in_the_blanks_activity_information_text_3")
          )} */}
          {SingleInformationIconTextItem(
            i18n.t("create_fill_in_the_blanks_activity_information_text_4")
          )}
        </>
      );
    } else if (activityTemplateType === "TRUE_FALSE") {
      return (
        <>
          {SingleInformationIconTextItem(
            i18n.t("create_true_false_activity_information_text_1")
          )}
          {SingleInformationIconTextItem(
            i18n.t("create_true_false_activity_information_text_2")
          )}
        </>
      );
    }
  };

  const RenderOrderingActivityTemplate = () => {
    return (
      <div className="my-1 py-1">
        <div className="flex flex-row items-center justify-between gap-x-2">
          <div className="flex-1">
            {SingleInformationIconTextItem(
              i18n.t("create_your_ordering_activity_text")
            )}
          </div>
          {checkCanShowInitialWithAI()
            ? canGenerateActivityTemplate
              ? RenderGenerateOrConvertWithAIContent(showGenerateButton, false)
              : RenderInsufficientTokensGenerateActivityTemplateContent()
            : null}
        </div>
        <div className="my-5">
          {RenderTaxonomyOptionList(
            orderingTaxonomyMap,
            setOrderingTaxonomyMap
          )}
          {RenderParsedActivityImageData()}
          {RenderActivityTemplateBodyMap(
            "ORDERING",
            orderingBodyMap,
            setOrderingBodyMap
          )}
          <DividerLine />

          <div className="">
            <div className="flex flex-row items-center">
              <p className="font-semibold text-lg">
                {i18n.t("activity_material")}
              </p>
            </div>

            {RenderActivityTemplateInformationContent("ORDERING")}

            <div className="mt-3">
              {Object.keys(orderingMaterialMap).map((key, index) => (
                <div
                  key={index}
                  className="flex flex-row justify-between items-center"
                >
                  <div className="relative mx-2 w-4 flex flex-col items-center">
                    {Object.keys(orderingMaterialMap).length === 1 ||
                    (index === 0 &&
                      Object.keys(orderingMaterialMap).length > 1) ? null : (
                      <div className="absolute -top-3">
                        <BaseImage
                          size="xsmall"
                          src="/icons/arrow-up.png"
                          alt="arrow-up"
                          onClick={() => {
                            const currentValue = orderingMaterialMap[key];
                            const nextKey =
                              Object.keys(orderingMaterialMap)[index - 1];
                            const nextValue = orderingMaterialMap[nextKey];
                            orderingMaterialMap[key] = nextValue;
                            orderingMaterialMap[nextKey] = currentValue;
                            setOrderingMaterialMap(
                              JSON.parse(JSON.stringify(orderingMaterialMap))
                            );
                          }}
                        />
                      </div>
                    )}
                    <p>{parseFloat(key) + 1}.</p>
                    {Object.keys(orderingMaterialMap).length === 1 ||
                    (index === Object.keys(orderingMaterialMap).length - 1 &&
                      Object.keys(orderingMaterialMap).length > 1) ? null : (
                      <div className="absolute -bottom-3">
                        <BaseImage
                          size="xsmall"
                          src="/icons/arrow-down.png"
                          alt="arrow-down"
                          onClick={() => {
                            const currentValue = orderingMaterialMap[key];
                            const nextKey =
                              Object.keys(orderingMaterialMap)[index + 1];
                            const nextValue = orderingMaterialMap[nextKey];
                            orderingMaterialMap[key] = nextValue;
                            orderingMaterialMap[nextKey] = currentValue;
                            setOrderingMaterialMap(
                              JSON.parse(JSON.stringify(orderingMaterialMap))
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="flex-1">
                    {selectedMaterialContentType === "TEXT" ? (
                      <>
                        <InputGroup
                          type="textarea"
                          title={null}
                          placeholder={null}
                          value={orderingMaterialMap[key]}
                          onChange={(event) => {
                            orderingMaterialMap[key] = event.target.value;
                            setOrderingMaterialMap(
                              JSON.parse(JSON.stringify(orderingMaterialMap))
                            );
                          }}
                        />
                        <InputWithSpecialExpression
                          value={orderingMaterialMap[key]}
                        />
                      </>
                    ) : (
                      RenderSingleDocumentByContentType(
                        selectedMaterialContentType,
                        orderingMaterialMap[key]
                      )
                    )}
                  </div>
                  <div className="mx-2">
                    <BaseImage
                      src="/icons/remove-red.png"
                      alt="remove-red"
                      size="small"
                      onClick={() => {
                        handleRemoveMaterialContentFromOrderingMaterialMap(key);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            {selectedMaterialContentType !== null ? (
              <>
                <div className="flex flex-col items-center">
                  <div className="mx-2">
                    <BaseImage
                      src="/icons/plus.png"
                      alt="plus"
                      size="large"
                      onClick={() => {
                        if (selectedMaterialContentType === "TEXT") {
                          handleAddMaterialContentToMaterialMap("ORDERING", "");
                        } else {
                          setMaterialLibraryModal({
                            show: true,
                            type: selectedMaterialContentType,
                            sub: false,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              SingleInformationIconTextItem(
                i18n.t("please_select_activity_format_to_proceed_text")
              )
            )}
          </div>

          <DividerLine />
          <SelectionTab
            optionList={innerActivityStateOptionList}
            selectedId={innerActivityState}
            handleSelectOnClick={(itemId) => {
              setInnerActivityState(itemId);
            }}
          />
          {innerActivityState === "ACTIVITY_SOLUTION"
            ? RenderActivityTemplateSolutionMap(
                "ORDERING",
                orderingSolutionMap,
                setOrderingSolutionMap
              )
            : innerActivityState === "ACTIVITY_EVALUATION_RUBRIC"
            ? null
            : null}
        </div>
      </div>
    );
  };

  const RenderDropdownActivityTemplate = () => {
    return (
      <div className="my-1 py-1">
        <div className="flex flex-row items-center justify-between gap-x-2">
          <div className="flex-1">
            {SingleInformationIconTextItem(
              i18n.t("create_your_dropdown_activity_text")
            )}
          </div>
          {checkCanShowInitialWithAI()
            ? canGenerateActivityTemplate
              ? RenderGenerateOrConvertWithAIContent(showGenerateButton, true)
              : RenderInsufficientTokensGenerateActivityTemplateContent()
            : null}
        </div>

        <div className="my-5">
          {RenderTaxonomyOptionList(
            dropdownTaxonomyMap,
            setDropdownTaxonomyMap
          )}
          {RenderParsedActivityImageData()}
          {RenderActivityTemplateBodyMap(
            "DROPDOWN",
            dropdownBodyMap,
            setDropdownBodyMap
          )}
          <DividerLine />
          {RenderActivityMaterialErrorCheck("DROPDOWN")}

          <div className="">
            <div className="flex flex-row items-center">
              <p className="font-semibold text-lg">
                {i18n.t("activity_material")}
              </p>
            </div>
            {RenderActivityTemplateInformationContent("DROPDOWN")}

            <div className="mt-3">
              {Object.keys(dropdownMaterialMap).map((key, index) => (
                <div key={index}>
                  <div className="flex flex-row items-center">
                    {dropdownMaterialMap[key][
                      Object.keys(dropdownMaterialMap[key])[0]
                    ].map((material, materialIndex) => (
                      <div
                        key={materialIndex}
                        className="flex-1 flex flex-row items-center"
                      >
                        <div className="mx-2">
                          <BaseImage
                            src={
                              Object.keys(dropdownMaterialMap[key])[0] ===
                              material
                                ? "/icons/checkbox.png"
                                : "/icons/checkbox-empty.png"
                            }
                            alt="checkbox"
                            size="small"
                            onClick={() => {
                              if (material !== "") {
                                const valueList =
                                  dropdownMaterialMap[key][
                                    Object.keys(dropdownMaterialMap[key])[0]
                                  ];
                                if (
                                  Object.keys(dropdownMaterialMap[key])[0] ===
                                  material
                                ) {
                                  dropdownMaterialMap[key] = {
                                    ["ANSWER_KEY"]: valueList,
                                  };
                                } else {
                                  dropdownMaterialMap[key] = {
                                    [material]: valueList,
                                  };
                                }

                                setDropdownMaterialMap(
                                  JSON.parse(
                                    JSON.stringify(dropdownMaterialMap)
                                  )
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="flex-1">
                          {selectedMaterialContentType === "TEXT" ? (
                            <>
                              <InputGroup
                                type="textarea"
                                title={null}
                                placeholder={null}
                                disabled={
                                  material !== "" &&
                                  Object.keys(dropdownMaterialMap[key])[0] ===
                                    material
                                }
                                value={material}
                                onChange={(event) => {
                                  const answerKey = Object.keys(
                                    dropdownMaterialMap[key]
                                  )[0];
                                  dropdownMaterialMap[key][answerKey][
                                    materialIndex
                                  ] = event.target.value;
                                  setDropdownMaterialMap(
                                    JSON.parse(
                                      JSON.stringify(dropdownMaterialMap)
                                    )
                                  );
                                }}
                              />
                              <InputWithSpecialExpression value={material} />
                            </>
                          ) : (
                            RenderSingleDocumentByContentType(
                              selectedMaterialContentType,
                              material
                            )
                          )}
                        </div>
                        <div className="mx-2">
                          <BaseImage
                            src="/icons/remove-red.png"
                            alt="remove-red"
                            size="small"
                            onClick={() => {
                              const answerKey = Object.keys(
                                dropdownMaterialMap[key]
                              )[0];
                              handleRemoveMaterialContentFromDropdownMaterialMap(
                                key,
                                answerKey,
                                materialIndex
                              );
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    <div className="mx-2">
                      <BaseImage
                        src="/icons/plus.png"
                        alt="plus"
                        size="small"
                        onClick={() => {
                          if (selectedMaterialContentType === "TEXT") {
                            const answerKey = Object.keys(
                              dropdownMaterialMap[key]
                            )[0];
                            dropdownMaterialMap[key][answerKey].push("");
                            setDropdownMaterialMap(
                              JSON.parse(JSON.stringify(dropdownMaterialMap))
                            );
                          } else {
                            setMaterialLibraryModal({
                              show: true,
                              type: selectedMaterialContentType,
                              sub: true,
                              index,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {selectedMaterialContentType !== null ? (
              <div className="flex flex-col items-center">
                <div className="mx-2">
                  <BaseImage
                    src="/icons/plus.png"
                    alt="plus"
                    size="large"
                    onClick={() => {
                      if (selectedMaterialContentType === "TEXT") {
                        handleAddMaterialContentToMaterialMap("DROPDOWN", "");
                      } else {
                        setMaterialLibraryModal({
                          show: true,
                          type: selectedMaterialContentType,
                          sub: false,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            ) : (
              SingleInformationIconTextItem(
                i18n.t("please_select_activity_format_to_proceed_text")
              )
            )}
          </div>

          <DividerLine />
          <SelectionTab
            optionList={innerActivityStateOptionList}
            selectedId={innerActivityState}
            handleSelectOnClick={(itemId) => {
              setInnerActivityState(itemId);
            }}
          />
          {innerActivityState === "ACTIVITY_SOLUTION"
            ? RenderActivityTemplateSolutionMap(
                "DROPDOWN",
                dropdownSolutionMap,
                setDropdownSolutionMap
              )
            : innerActivityState === "ACTIVITY_EVALUATION_RUBRIC"
            ? null
            : null}
        </div>
      </div>
    );
  };

  const RenderMCSAActivityTemplate = () => {
    return (
      <div className="my-1 py-1">
        <div className="flex flex-row items-center justify-between gap-x-2">
          <div className="flex-1">
            {SingleInformationIconTextItem(
              i18n.t("create_your_mcsa_activity_text")
            )}
          </div>
          {checkCanShowInitialWithAI()
            ? canGenerateActivityTemplate
              ? RenderGenerateOrConvertWithAIContent(showGenerateButton, true)
              : RenderInsufficientTokensGenerateActivityTemplateContent()
            : null}
        </div>
        <div className="my-5">
          {RenderTaxonomyOptionList(MCSATaxonomyMap, setMCSATaxonomyMap)}
          {RenderParsedActivityImageData()}
          {RenderActivityTemplateBodyMap("MCSA", MCSABodyMap, setMCSABodyMap)}
          <DividerLine />

          <div className="">
            <div className="flex flex-row items-center">
              <p className="font-semibold text-lg">
                {i18n.t("activity_material")}
              </p>
            </div>

            {RenderActivityTemplateInformationContent("MCSA")}

            <div className="mt-3">
              {MCSAMaterialMap[Object.keys(MCSAMaterialMap)[0]].map(
                (value, index) => (
                  <div key={index} className="">
                    <div className="flex flex-row justify-between items-center">
                      <div className="mx-2">
                        <BaseImage
                          src={
                            Object.keys(MCSAMaterialMap)[0] === value
                              ? "/icons/checkbox.png"
                              : "/icons/checkbox-empty.png"
                          }
                          alt="checkbox"
                          size="small"
                          onClick={() => {
                            if (value !== "") {
                              const answerKey = Object.keys(MCSAMaterialMap)[0];
                              const valueList = MCSAMaterialMap[answerKey];
                              if (value === answerKey) {
                                setMCSAMaterialMap({ ANSWER_KEY: valueList });
                              } else {
                                setMCSAMaterialMap({ [value]: valueList });
                              }
                            }
                          }}
                        />
                      </div>
                      <div className="flex-1">
                        {selectedMaterialContentType === "TEXT" ? (
                          <>
                            <InputGroup
                              type="textarea"
                              title={null}
                              placeholder={null}
                              value={value}
                              disabled={
                                value !== "" &&
                                Object.keys(MCSAMaterialMap)[0] === value
                              }
                              onChange={(event) => {
                                const answerKey =
                                  Object.keys(MCSAMaterialMap)[0];
                                MCSAMaterialMap[answerKey][index] =
                                  event.target.value;
                                setMCSAMaterialMap(
                                  JSON.parse(JSON.stringify(MCSAMaterialMap))
                                );
                              }}
                            />
                            <InputWithSpecialExpression value={value} />
                          </>
                        ) : (
                          RenderSingleDocumentByContentType(
                            selectedMaterialContentType,
                            value
                          )
                        )}
                      </div>
                      <div className="mx-2">
                        <BaseImage
                          src="/icons/remove-red.png"
                          alt="remove-red"
                          size="small"
                          onClick={() => {
                            handleRemoveMaterialContentFromMCSAMaterialMap(
                              index
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            {selectedMaterialContentType !== null ? (
              <div className="flex flex-col items-center">
                <div className="mx-2">
                  <BaseImage
                    src="/icons/plus.png"
                    alt="plus"
                    size="large"
                    onClick={() => {
                      if (selectedMaterialContentType === "TEXT") {
                        handleAddMaterialContentToMaterialMap("MCSA", "");
                      } else {
                        setMaterialLibraryModal({
                          show: true,
                          type: selectedMaterialContentType,
                          sub: false,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            ) : (
              SingleInformationIconTextItem(
                i18n.t("please_select_activity_format_to_proceed_text")
              )
            )}
          </div>

          <DividerLine />
          <SelectionTab
            optionList={innerActivityStateOptionList}
            selectedId={innerActivityState}
            handleSelectOnClick={(itemId) => {
              setInnerActivityState(itemId);
            }}
          />
          {innerActivityState === "ACTIVITY_SOLUTION"
            ? RenderActivityTemplateSolutionMap(
                "MCSA",
                MCSASolutionMap,
                setMCSASolutionMap
              )
            : innerActivityState === "ACTIVITY_EVALUATION_RUBRIC"
            ? null
            : null}
        </div>
      </div>
    );
  };

  const RenderMCMAActivityTemplate = () => {
    return (
      <div className="my-1 py-1">
        <div className="flex flex-row items-center justify-between gap-x-2">
          <div className="flex-1">
            {SingleInformationIconTextItem(
              i18n.t("create_your_mcma_activity_text")
            )}
          </div>
          {checkCanShowInitialWithAI()
            ? canGenerateActivityTemplate
              ? RenderGenerateOrConvertWithAIContent(showGenerateButton, true)
              : RenderInsufficientTokensGenerateActivityTemplateContent()
            : null}
        </div>
        <div className="my-5">
          {RenderTaxonomyOptionList(MCMATaxonomyMap, setMCMATaxonomyMap)}
          {RenderParsedActivityImageData()}
          {RenderActivityTemplateBodyMap("MCMA", MCMABodyMap, setMCMABodyMap)}
          <DividerLine />

          <div className="">
            <div className="flex flex-row items-center">
              <p className="font-semibold text-lg">
                {i18n.t("activity_material")}
              </p>
            </div>

            {RenderActivityTemplateInformationContent("MCMA")}

            <div className="mt-3">
              {MCMAMaterialMap[Object.keys(MCMAMaterialMap)[0]].map(
                (value, index) => (
                  <div key={index} className="">
                    <div className="flex flex-row justify-between items-center">
                      <div className="mx-2">
                        <BaseImage
                          src={
                            Object.keys(MCMAMaterialMap)[0]
                              .split("§") // .split(",")
                              .includes(value)
                              ? "/icons/checkbox.png"
                              : "/icons/checkbox-empty.png"
                          }
                          alt="checkbox"
                          size="small"
                          onClick={() => {
                            if (value !== "") {
                              const answerKeys =
                                Object.keys(MCMAMaterialMap)[0];
                              const valueList = MCMAMaterialMap[answerKeys];
                              const splittedKeyList = answerKeys
                                .replace("ANSWER_KEY", "")
                                .split("§");
                              const foundIndex = splittedKeyList.findIndex(
                                (splittedKey) => splittedKey === value
                              );
                              if (foundIndex === -1) {
                                splittedKeyList.push(value);
                              } else {
                                splittedKeyList.splice(foundIndex, 1);
                              }
                              const newKeys = splittedKeyList
                                .filter((splittedKey) => splittedKey !== "")
                                .join("§"); // join()
                              setMCMAMaterialMap({
                                [newKeys]: valueList,
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="flex-1">
                        {selectedMaterialContentType === "TEXT" ? (
                          <>
                            <InputGroup
                              type="textarea"
                              title={null}
                              placeholder={null}
                              value={value}
                              disabled={
                                value !== "" &&
                                Object.keys(MCMAMaterialMap)[0]
                                  .replace("ANSWER_KEY", "")
                                  .split("§")
                                  .findIndex(
                                    (splittedKey) => splittedKey === value
                                  ) !== -1
                              }
                              onChange={(event) => {
                                const answerKey =
                                  Object.keys(MCMAMaterialMap)[0];
                                MCMAMaterialMap[answerKey][index] =
                                  event.target.value.replace("§", "");
                                setMCMAMaterialMap(
                                  JSON.parse(JSON.stringify(MCMAMaterialMap))
                                );
                              }}
                            />
                            <InputWithSpecialExpression value={value} />
                          </>
                        ) : (
                          RenderSingleDocumentByContentType(
                            selectedMaterialContentType,
                            value
                          )
                        )}
                      </div>
                      <div className="mx-2">
                        <BaseImage
                          src="/icons/remove-red.png"
                          alt="remove-red"
                          size="small"
                          onClick={() => {
                            handleRemoveMaterialContentFromMCMAMaterialMap(
                              index
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            {selectedMaterialContentType !== null ? (
              <div className="flex flex-col items-center">
                <div className="mx-2">
                  <BaseImage
                    src="/icons/plus.png"
                    alt="plus"
                    size="large"
                    onClick={() => {
                      if (selectedMaterialContentType === "TEXT") {
                        handleAddMaterialContentToMaterialMap("MCMA", "");
                      } else {
                        setMaterialLibraryModal({
                          show: true,
                          type: selectedMaterialContentType,
                          sub: false,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            ) : (
              SingleInformationIconTextItem(
                i18n.t("please_select_activity_format_to_proceed_text")
              )
            )}
          </div>

          <DividerLine />
          <SelectionTab
            optionList={innerActivityStateOptionList}
            selectedId={innerActivityState}
            handleSelectOnClick={(itemId) => {
              setInnerActivityState(itemId);
            }}
          />
          {innerActivityState === "ACTIVITY_SOLUTION"
            ? RenderActivityTemplateSolutionMap(
                "MCMA",
                MCMASolutionMap,
                setMCMASolutionMap
              )
            : innerActivityState === "ACTIVITY_EVALUATION_RUBRIC"
            ? null
            : null}
        </div>
      </div>
    );
  };

  const RenderMatchingActivityTemplate = () => {
    return (
      <div className="my-1 py-1">
        <div className="flex flex-row items-center justify-between gap-x-2">
          <div className="flex-1">
            {SingleInformationIconTextItem(
              i18n.t("create_your_matching_activity_text")
            )}
          </div>
          {checkCanShowInitialWithAI()
            ? canGenerateActivityTemplate
              ? RenderGenerateOrConvertWithAIContent(showGenerateButton, true)
              : RenderInsufficientTokensGenerateActivityTemplateContent()
            : null}
        </div>

        <div className="my-5">
          {RenderTaxonomyOptionList(
            matchingTaxonomyMap,
            setMatchingTaxonomyMap
          )}
          {RenderParsedActivityImageData()}
          {RenderActivityTemplateBodyMap(
            "MATCHING",
            matchingBodyMap,
            setMatchingBodyMap
          )}
          <DividerLine />

          <div className="">
            <div className="flex flex-row items-center">
              <p className="font-semibold text-lg">
                {i18n.t("activity_material")}
              </p>
            </div>

            {RenderActivityTemplateInformationContent("MATCHING")}

            <div className="mt-3">
              {Object.keys(matchingMaterialMap).map((key, index) => (
                <div key={index} className="">
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex-1">
                      <div className="mx-2">
                        <InputGroup
                          type="textarea"
                          title={null}
                          placeholder={null}
                          value={Object.keys(matchingMaterialMap[key])[0]}
                          onFocus={() => {
                            if (
                              Object.keys(
                                matchingMaterialMap[key]
                              )[0].startsWith(i18n.t("insert_a_valid_key"))
                            ) {
                              const subKey = Object.keys(
                                matchingMaterialMap[key]
                              )[0];
                              const currentValue =
                                matchingMaterialMap[key][subKey];
                              matchingMaterialMap[key][""] = currentValue;
                              delete matchingMaterialMap[key][subKey];
                              setMatchingMaterialMap(
                                JSON.parse(JSON.stringify(matchingMaterialMap))
                              );
                            }
                          }}
                          onChange={(event) => {
                            const newSubKey = event.target.value;
                            const subKey = Object.keys(
                              matchingMaterialMap[key]
                            )[0];
                            const currentValue =
                              matchingMaterialMap[key][subKey];
                            matchingMaterialMap[key][newSubKey] = currentValue;
                            delete matchingMaterialMap[key][subKey];
                            setMatchingMaterialMap(
                              JSON.parse(JSON.stringify(matchingMaterialMap))
                            );
                          }}
                        />
                        <InputWithSpecialExpression
                          value={Object.keys(matchingMaterialMap[key])[0]}
                        />
                      </div>
                    </div>
                    <div className="flex-1">
                      {selectedMaterialContentType === "TEXT" ? (
                        <>
                          <InputGroup
                            type="textarea"
                            title={null}
                            placeholder={null}
                            value={
                              matchingMaterialMap[key][
                                Object.keys(matchingMaterialMap[key])[0]
                              ]
                            }
                            onFocus={() => {
                              if (
                                matchingMaterialMap[key][
                                  Object.keys(matchingMaterialMap[key])[0]
                                ].startsWith(i18n.t("insert_a_valid_value"))
                              ) {
                                const answerKey = [
                                  Object.keys(matchingMaterialMap[key])[0],
                                ];
                                matchingMaterialMap[key][answerKey] = "";
                                setMatchingMaterialMap(
                                  JSON.parse(
                                    JSON.stringify(matchingMaterialMap)
                                  )
                                );
                              }
                            }}
                            onChange={(event) => {
                              const answerKey = [
                                Object.keys(matchingMaterialMap[key])[0],
                              ];
                              matchingMaterialMap[key][answerKey] =
                                event.target.value;
                              setMatchingMaterialMap(
                                JSON.parse(JSON.stringify(matchingMaterialMap))
                              );
                            }}
                          />
                          <InputWithSpecialExpression
                            value={
                              matchingMaterialMap[key][
                                Object.keys(matchingMaterialMap[key])[0]
                              ]
                            }
                          />
                        </>
                      ) : (
                        RenderSingleDocumentByContentType(
                          selectedMaterialContentType,
                          matchingMaterialMap[key][
                            Object.keys(matchingMaterialMap[key])[0]
                          ]
                        )
                      )}
                    </div>
                    <div className="mx-2">
                      <BaseImage
                        src="/icons/remove-red.png"
                        alt="remove-red"
                        size="small"
                        onClick={() => {
                          handleRemoveMaterialContentFromMatchingMaterialMap(
                            key
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {selectedMaterialContentType !== null ? (
              <div className="flex flex-col items-center my-5">
                <div className="mx-2">
                  <BaseImage
                    src="/icons/plus.png"
                    alt="plus"
                    size="large"
                    onClick={() => {
                      if (selectedMaterialContentType === "TEXT") {
                        handleAddMaterialContentToMaterialMap(
                          "MATCHING",
                          `${i18n.t("insert_a_valid_value")}`
                        );
                      } else {
                        setMaterialLibraryModal({
                          show: true,
                          type: selectedMaterialContentType,
                          sub: false,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            ) : (
              SingleInformationIconTextItem(
                i18n.t("please_select_activity_format_to_proceed_text")
              )
            )}
          </div>

          <DividerLine />
          <SelectionTab
            optionList={innerActivityStateOptionList}
            selectedId={innerActivityState}
            handleSelectOnClick={(itemId) => {
              setInnerActivityState(itemId);
            }}
          />
          {innerActivityState === "ACTIVITY_SOLUTION"
            ? RenderActivityTemplateSolutionMap(
                "MATCHING",
                matchingSolutionMap,
                setMatchingSolutionMap
              )
            : innerActivityState === "ACTIVITY_EVALUATION_RUBRIC"
            ? null
            : null}
        </div>
      </div>
    );
  };

  const RenderGroupingActivityTemplate = () => {
    return (
      <div className="my-1 py-1">
        <div className="flex flex-row items-center justify-between gap-x-2">
          <div className="flex-1">
            {SingleInformationIconTextItem(
              i18n.t("create_your_grouping_activity_text")
            )}
          </div>
          {checkCanShowInitialWithAI()
            ? canGenerateActivityTemplate
              ? RenderGenerateOrConvertWithAIContent(showGenerateButton, true)
              : RenderInsufficientTokensGenerateActivityTemplateContent()
            : null}
        </div>
        <div className="my-5">
          {RenderTaxonomyOptionList(
            groupingTaxonomyMap,
            setGroupingTaxonomyMap
          )}
          {RenderParsedActivityImageData()}
          {RenderActivityTemplateBodyMap(
            "GROUPING",
            groupingBodyMap,
            setGroupingBodyMap
          )}
          <DividerLine />

          <div className="px-3">
            <div className="flex flex-row items-center">
              <p className="font-semibold text-lg">
                {i18n.t("activity_material")}
              </p>
            </div>

            {RenderActivityTemplateInformationContent("GROUPING")}

            <div className="mt-3">
              {Object.keys(groupingMaterialMap).map((key, index) => (
                <div key={index} className="">
                  <div className="flex flex-row justify-between mb-3">
                    <div className="flex-1">
                      <div className="mx-2">
                        <InputGroup
                          type="textarea"
                          title={null}
                          placeholder={null}
                          value={Object.keys(groupingMaterialMap[key])[0]}
                          onFocus={() => {
                            if (
                              Object.keys(groupingMaterialMap[key])[0] ===
                              i18n.t("insert_a_valid_key")
                            ) {
                              const subKey = Object.keys(
                                groupingMaterialMap[key]
                              )[0];
                              const currentValue =
                                groupingMaterialMap[key][subKey];
                              groupingMaterialMap[key][""] = currentValue;
                              delete groupingMaterialMap[key][subKey];
                              setGroupingMaterialMap(
                                JSON.parse(JSON.stringify(groupingMaterialMap))
                              );
                            }
                          }}
                          onChange={(event) => {
                            const newSubKey = event.target.value;
                            const subKey = Object.keys(
                              groupingMaterialMap[key]
                            )[0];
                            const currentValue =
                              groupingMaterialMap[key][subKey];
                            groupingMaterialMap[key][newSubKey] = currentValue;
                            delete groupingMaterialMap[key][subKey];
                            setGroupingMaterialMap(
                              JSON.parse(JSON.stringify(groupingMaterialMap))
                            );
                          }}
                        />
                        <InputWithSpecialExpression
                          value={Object.keys(groupingMaterialMap[key])[0]}
                        />
                      </div>
                    </div>
                    <div className="flex-1">
                      {groupingMaterialMap[key][
                        Object.keys(groupingMaterialMap[key])[0]
                      ].map((material, materialIndex) => (
                        <div
                          key={materialIndex}
                          className="flex flex-row items-center"
                        >
                          <div className="flex-1">
                            {selectedMaterialContentType === "TEXT" ? (
                              <>
                                <InputGroup
                                  type="textarea"
                                  title={null}
                                  placeholder={null}
                                  value={material}
                                  onFocus={() => {
                                    if (
                                      groupingMaterialMap[key][
                                        Object.keys(groupingMaterialMap[key])[0]
                                      ][materialIndex].startsWith(
                                        i18n.t("insert_a_valid_value")
                                      )
                                    ) {
                                      const subKey = Object.keys(
                                        groupingMaterialMap[key]
                                      )[0];
                                      groupingMaterialMap[key][subKey][
                                        materialIndex
                                      ] = "";
                                      setGroupingMaterialMap(
                                        JSON.parse(
                                          JSON.stringify(groupingMaterialMap)
                                        )
                                      );
                                    }
                                  }}
                                  onChange={(event) => {
                                    const subKey = Object.keys(
                                      groupingMaterialMap[key]
                                    )[0];
                                    groupingMaterialMap[key][subKey][
                                      materialIndex
                                    ] = event.target.value;
                                    setGroupingMaterialMap(
                                      JSON.parse(
                                        JSON.stringify(groupingMaterialMap)
                                      )
                                    );
                                  }}
                                />
                                <InputWithSpecialExpression value={material} />
                              </>
                            ) : (
                              RenderSingleDocumentByContentType(
                                selectedMaterialContentType,
                                material
                              )
                            )}
                          </div>
                          <div className="mx-2">
                            <BaseImage
                              src="/icons/remove-red.png"
                              alt="remove-red"
                              size="small"
                              onClick={() => {
                                const subKey = Object.keys(
                                  groupingMaterialMap[key]
                                )[0];
                                handleRemoveMaterialContentFromGroupingMaterialMap(
                                  key,
                                  subKey,
                                  materialIndex
                                );
                              }}
                            />
                          </div>
                          {materialIndex ===
                          groupingMaterialMap[key][
                            Object.keys(groupingMaterialMap[key])[0]
                          ].length -
                            1 ? (
                            <div className="mx-2">
                              <BaseImage
                                src="/icons/plus.png"
                                alt="plus"
                                size="small"
                                onClick={() => {
                                  if (selectedMaterialContentType === "TEXT") {
                                    const answerKey = Object.keys(
                                      groupingMaterialMap[key]
                                    )[0];
                                    const currentLength =
                                      groupingMaterialMap[key][answerKey]
                                        .length;
                                    groupingMaterialMap[key][answerKey].push(
                                      `${i18n.t(
                                        "insert_a_valid_value"
                                      )} (${currentLength})`
                                    );
                                    setGroupingMaterialMap(
                                      JSON.parse(
                                        JSON.stringify(groupingMaterialMap)
                                      )
                                    );
                                  } else {
                                    setMaterialLibraryModal({
                                      show: true,
                                      type: selectedMaterialContentType,
                                      sub: true,
                                      index,
                                    });
                                  }
                                }}
                              />
                            </div>
                          ) : (
                            <div className="mx-2">
                              <div className="h-5 w-5"></div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {selectedMaterialContentType !== null ? (
              <div className="flex flex-col items-center">
                <div className="mx-2">
                  <BaseImage
                    src="/icons/plus.png"
                    alt="plus"
                    size="large"
                    onClick={() => {
                      if (selectedMaterialContentType === "TEXT") {
                        handleAddMaterialContentToMaterialMap(
                          "GROUPING",
                          `${i18n.t("insert_a_valid_value")}`
                        );
                      } else {
                        setMaterialLibraryModal({
                          show: true,
                          type: selectedMaterialContentType,
                          sub: false,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            ) : (
              SingleInformationIconTextItem(
                i18n.t("please_select_activity_format_to_proceed_text")
              )
            )}
          </div>

          <DividerLine />
          <SelectionTab
            optionList={innerActivityStateOptionList}
            selectedId={innerActivityState}
            handleSelectOnClick={(itemId) => {
              setInnerActivityState(itemId);
            }}
          />
          {innerActivityState === "ACTIVITY_SOLUTION"
            ? RenderActivityTemplateSolutionMap(
                "GROUPING",
                groupingSolutionMap,
                setGroupingSolutionMap
              )
            : innerActivityState === "ACTIVITY_EVALUATION_RUBRIC"
            ? null
            : null}
        </div>
      </div>
    );
  };

  const RenderFillInTheBlanksActivityTemplate = () => {
    return (
      <div className="my-1 py-1">
        <div className="flex flex-row items-center justify-between gap-x-2">
          <div className="flex-1">
            {SingleInformationIconTextItem(
              i18n.t("create_your_fill_in_the_blanks_activity_text")
            )}
          </div>
          {checkCanShowInitialWithAI()
            ? canGenerateActivityTemplate
              ? RenderGenerateOrConvertWithAIContent(showGenerateButton, true)
              : RenderInsufficientTokensGenerateActivityTemplateContent()
            : null}
        </div>
        <div className="my-5">
          {RenderTaxonomyOptionList(
            fillInTheBlanksTaxonomyMap,
            setFillInTheBlanksTaxonomyMap
          )}
          {RenderParsedActivityImageData()}
          {RenderActivityTemplateBodyMap(
            "FILL_IN_THE_BLANKS",
            fillInTheBlanksBodyMap,
            setFillInTheBlanksBodyMap
          )}
          <DividerLine />

          {RenderActivityMaterialErrorCheck("FILL_IN_THE_BLANKS")}

          <div className="">
            <div className="flex flex-row items-center">
              <p className="font-semibold text-lg">
                {i18n.t("activity_material")}
              </p>
            </div>

            <div className="px-4 mt-3">
              <div className="flex flex-row items-center">
                <p className="font-semibold text-lg">
                  {i18n.t("activity_correct_answers")}
                </p>
              </div>
              {RenderActivityTemplateInformationContent("FILL_IN_THE_BLANKS")}

              <div className="mt-3">
                {Object.keys(fillInTheBlanksMaterialMap).map((key, index) => (
                  <div key={index}>
                    <div className="flex flex-row justify-between items-center">
                      <div className="relative mx-2 w-4 flex flex-col items-center">
                        {Object.keys(fillInTheBlanksMaterialMap).length === 1 ||
                        (index === 0 &&
                          Object.keys(fillInTheBlanksMaterialMap).length >
                            1) ? null : (
                          <div className="absolute -top-3">
                            <BaseImage
                              size="xsmall"
                              src="/icons/arrow-up.png"
                              alt="arrow-up"
                              onClick={() => {
                                const currentValue =
                                  fillInTheBlanksMaterialMap[key];
                                const nextKey = Object.keys(
                                  fillInTheBlanksMaterialMap
                                )[index - 1];
                                const nextValue =
                                  fillInTheBlanksMaterialMap[nextKey];
                                fillInTheBlanksMaterialMap[key] = nextValue;
                                fillInTheBlanksMaterialMap[nextKey] =
                                  currentValue;
                                setFillInTheBlanksMaterialMap(
                                  JSON.parse(
                                    JSON.stringify(fillInTheBlanksMaterialMap)
                                  )
                                );
                              }}
                            />
                          </div>
                        )}
                        <p>{parseFloat(key) + 1}.</p>
                        {Object.keys(fillInTheBlanksMaterialMap).length === 1 ||
                        (index ===
                          Object.keys(fillInTheBlanksMaterialMap).length - 1 &&
                          Object.keys(fillInTheBlanksMaterialMap).length >
                            1) ? null : (
                          <div className="absolute -bottom-3">
                            <BaseImage
                              size="xsmall"
                              src="/icons/arrow-down.png"
                              alt="arrow-down"
                              onClick={() => {
                                const currentValue =
                                  fillInTheBlanksMaterialMap[key];
                                const nextKey = Object.keys(
                                  fillInTheBlanksMaterialMap
                                )[index + 1];
                                const nextValue =
                                  fillInTheBlanksMaterialMap[nextKey];
                                fillInTheBlanksMaterialMap[key] = nextValue;
                                fillInTheBlanksMaterialMap[nextKey] =
                                  currentValue;
                                setFillInTheBlanksMaterialMap(
                                  JSON.parse(
                                    JSON.stringify(fillInTheBlanksMaterialMap)
                                  )
                                );
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex-1 flex flex-row items-center ">
                        {fillInTheBlanksMaterialMap[key].map(
                          (material, materialIndex) => (
                            <div
                              key={materialIndex}
                              className="flex-1 flex flex-row items-center"
                            >
                              <div className="flex-1">
                                {selectedMaterialContentType === "TEXT" ? (
                                  <>
                                    <InputGroup
                                      type="textarea"
                                      title={null}
                                      placeholder={null}
                                      value={material}
                                      onChange={(event) => {
                                        fillInTheBlanksMaterialMap[key][
                                          materialIndex
                                        ] = event.target.value;
                                        setFillInTheBlanksMaterialMap(
                                          JSON.parse(
                                            JSON.stringify(
                                              fillInTheBlanksMaterialMap
                                            )
                                          )
                                        );
                                      }}
                                    />
                                    <InputWithSpecialExpression
                                      value={material}
                                    />
                                  </>
                                ) : (
                                  RenderSingleDocumentByContentType(
                                    selectedMaterialContentType,
                                    material
                                  )
                                )}
                              </div>
                              <div className="mx-2">
                                <BaseImage
                                  src="/icons/remove-red.png"
                                  alt="remove-red"
                                  size="small"
                                  onClick={() => {
                                    handleRemoveMaterialContentFromFillInTheBlanksMaterialMap(
                                      key,
                                      materialIndex,
                                      "CORRECT"
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {selectedMaterialContentType !== null ? (
                <div className="flex flex-col items-center">
                  <div className="mx-2">
                    <BaseImage
                      src="/icons/plus.png"
                      alt="plus"
                      size="large"
                      onClick={() => {
                        if (selectedMaterialContentType === "TEXT") {
                          handleAddMaterialContentToMaterialMap(
                            "FILL_IN_THE_BLANKS",
                            "",
                            null,
                            "CORRECT"
                          );
                        } else {
                          setMaterialLibraryModal({
                            show: true,
                            type: selectedMaterialContentType,
                            sub: false,
                            option: "CORRECT",
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              ) : (
                SingleInformationIconTextItem(
                  i18n.t("please_select_activity_format_to_proceed_text")
                )
              )}

              <div className="mt-3">
                <div className="flex flex-row items-center">
                  <p className="font-semibold text-lg">
                    {i18n.t("activity_incorrect_answers")}
                  </p>
                </div>
              </div>
              {SingleInformationIconTextItem(
                i18n.t("create_fill_in_the_blanks_activity_information_text_5")
              )}

              <div className="mt-3">
                {fillInTheBlanksIncorrectList.map((value, index) => (
                  <div key={index} className="flex flex-row items-center">
                    <div className="flex-1">
                      {selectedMaterialContentType === "TEXT" ? (
                        <div className="">
                          <InputGroup
                            type="textarea"
                            title={null}
                            placeholder={null}
                            value={value}
                            onChange={(event) => {
                              fillInTheBlanksIncorrectList[index] =
                                event.target.value;
                              setFillInTheBlanksIncorrectList(
                                JSON.parse(
                                  JSON.stringify(fillInTheBlanksIncorrectList)
                                )
                              );
                            }}
                          />
                          <InputWithSpecialExpression value={value} />
                        </div>
                      ) : (
                        RenderSingleDocumentByContentType(
                          selectedMaterialContentType,
                          value
                        )
                      )}
                    </div>
                    <div className="mx-2">
                      <BaseImage
                        src="/icons/remove-red.png"
                        alt="remove-red"
                        size="small"
                        onClick={() => {
                          handleRemoveMaterialContentFromFillInTheBlanksMaterialMap(
                            null,
                            index,
                            "INCORRECT"
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>

              {selectedMaterialContentType !== null ? (
                <div className="flex flex-col items-center">
                  <div className="mx-2">
                    <BaseImage
                      src="/icons/plus.png"
                      alt="plus"
                      size="large"
                      onClick={() => {
                        if (selectedMaterialContentType === "TEXT") {
                          handleAddMaterialContentToMaterialMap(
                            "FILL_IN_THE_BLANKS",
                            "",
                            null,
                            "INCORRECT"
                          );
                        } else {
                          setMaterialLibraryModal({
                            show: true,
                            type: selectedMaterialContentType,
                            sub: false,
                            option: "INCORRECT",
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              ) : (
                SingleInformationIconTextItem(
                  i18n.t("please_select_activity_format_to_proceed_text")
                )
              )}
            </div>
          </div>

          <DividerLine />
          <SelectionTab
            optionList={innerActivityStateOptionList}
            selectedId={innerActivityState}
            handleSelectOnClick={(itemId) => {
              setInnerActivityState(itemId);
            }}
          />
          {innerActivityState === "ACTIVITY_SOLUTION"
            ? RenderActivityTemplateSolutionMap(
                "FILL_IN_THE_BLANKS",
                fillInTheBlanksSolutionMap,
                setFillInTheBlanksSolutionMap
              )
            : innerActivityState === "ACTIVITY_EVALUATION_RUBRIC"
            ? null
            : null}
        </div>
      </div>
    );
  };

  const RenderOpenEndedActivityTemplate = () => {
    return (
      <div className="my-1 py-1">
        <div className="flex flex-row items-center justify-between gap-x-2">
          <div className="flex-1">
            {SingleInformationIconTextItem(
              i18n.t("create_your_open_ended_activity_text")
            )}
          </div>
          {checkCanShowInitialWithAI()
            ? canGenerateActivityTemplate
              ? RenderGenerateOrConvertWithAIContent(showGenerateButton, true)
              : RenderInsufficientTokensGenerateActivityTemplateContent()
            : null}
        </div>
        <div className="my-5">
          {RenderTaxonomyOptionList(
            openEndedTaxonomyMap,
            setOpenEndedTaxonomyMap
          )}
          {RenderParsedActivityImageData()}
          {RenderActivityTemplateBodyMap(
            "OPEN_ENDED",
            openEndedBodyMap,
            setOpenEndedBodyMap
          )}
          <DividerLine />

          <div className="">
            {selectedMaterialContentType !== null
              ? SingleInformationIconTextItem(
                  i18n.t(
                    "open_ended_activities_does_not_require_activity_answer_body_text"
                  )
                )
              : SingleInformationIconTextItem(
                  i18n.t("please_select_activity_format_to_proceed_text")
                )}
          </div>
          <DividerLine />
          <SelectionTab
            optionList={innerActivityStateOptionList}
            selectedId={innerActivityState}
            handleSelectOnClick={(itemId) => {
              setInnerActivityState(itemId);
            }}
          />
          {innerActivityState === "ACTIVITY_SOLUTION"
            ? RenderActivityTemplateSolutionMap(
                "OPEN_ENDED",
                openEndedSolutionMap,
                setOpenEndedSolutionMap
              )
            : innerActivityState === "ACTIVITY_EVALUATION_RUBRIC"
            ? RenderActivityTemplateEvaluationRubricMap(
                "OPEN_ENDED",
                openEndedEvaluationRubricMap,
                setOpenEndedEvaluationRubricMap
              )
            : null}
        </div>
      </div>
    );
  };

  const RenderTrueFalseActivityTemplate = () => {
    return (
      <div className="my-1 py-1">
        <div className="flex flex-row items-center justify-between gap-x-2">
          <div className="flex-1">
            {SingleInformationIconTextItem(
              i18n.t("create_your_true_false_activity_text")
            )}
          </div>
          {checkCanShowInitialWithAI()
            ? canGenerateActivityTemplate
              ? RenderGenerateOrConvertWithAIContent(showGenerateButton, true)
              : RenderInsufficientTokensGenerateActivityTemplateContent()
            : null}
        </div>

        <div className="my-5">
          {RenderTaxonomyOptionList(
            trueFalseTaxonomyMap,
            setTrueFalseTaxonomyMap
          )}
          {RenderParsedActivityImageData()}
          {RenderActivityTemplateBodyMap(
            "TRUE_FALSE",
            trueFalseBodyMap,
            setTrueFalseBodyMap
          )}
          <DividerLine />

          <div className="">
            <div className="flex flex-row items-center">
              <p className="font-semibold text-lg">
                {i18n.t("activity_material")}
              </p>
            </div>
            {RenderActivityTemplateInformationContent("TRUE_FALSE")}

            <div className="mt-3">
              <div className="flex flex-row items-center">
                <p className="italic underline">{i18n.t("true_section")}</p>
              </div>

              {trueFalseMaterialMap.trueList.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-row justify-between items-center"
                >
                  <div className="flex-1">
                    {selectedMaterialContentType === "TEXT" ? (
                      <>
                        <InputGroup
                          type="textarea"
                          title={null}
                          placeholder={null}
                          value={item}
                          onChange={(event) => {
                            trueFalseMaterialMap.trueList[index] =
                              event.target.value;
                            setTrueFalseMaterialMap(
                              JSON.parse(JSON.stringify(trueFalseMaterialMap))
                            );
                          }}
                        />
                        <InputWithSpecialExpression value={item} />
                      </>
                    ) : (
                      RenderSingleDocumentByContentType(
                        selectedMaterialContentType,
                        item
                      )
                    )}
                  </div>
                  <div className="mx-2">
                    <BaseImage
                      src="/icons/remove-red.png"
                      alt="remove-red"
                      size="small"
                      onClick={() => {
                        handleRemoveMaterialContentFromTrueFalseMaterialMap(
                          "trueList",
                          index
                        );
                      }}
                    />
                  </div>
                </div>
              ))}

              {selectedMaterialContentType !== null ? (
                <>
                  <div className="flex flex-col items-center">
                    <div className="mx-2">
                      <BaseImage
                        src="/icons/plus.png"
                        alt="plus"
                        size="large"
                        onClick={() => {
                          if (selectedMaterialContentType === "TEXT") {
                            handleAddMaterialContentToMaterialMap(
                              "TRUE_FALSE",
                              "",
                              "trueList"
                            );
                          } else {
                            setMaterialLibraryModal({
                              show: true,
                              type: selectedMaterialContentType,
                              sub: false,
                              key: "trueList",
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                SingleInformationIconTextItem(
                  i18n.t("please_select_activity_format_to_proceed_text")
                )
              )}

              <div className="flex flex-row items-center">
                <p className="italic underline">{i18n.t("false_section")}</p>
              </div>

              {trueFalseMaterialMap.falseList.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-row justify-between items-center"
                >
                  <div className="flex-1">
                    {selectedMaterialContentType === "TEXT" ? (
                      <>
                        <InputGroup
                          type="textarea"
                          title={null}
                          placeholder={null}
                          value={item}
                          onChange={(event) => {
                            trueFalseMaterialMap.falseList[index] =
                              event.target.value;
                            setTrueFalseMaterialMap(
                              JSON.parse(JSON.stringify(trueFalseMaterialMap))
                            );
                          }}
                        />
                        <InputWithSpecialExpression value={item} />
                      </>
                    ) : (
                      RenderSingleDocumentByContentType(
                        selectedMaterialContentType,
                        item
                      )
                    )}
                  </div>
                  <div className="mx-2">
                    <BaseImage
                      src="/icons/remove-red.png"
                      alt="remove-red"
                      size="small"
                      onClick={() => {
                        handleRemoveMaterialContentFromTrueFalseMaterialMap(
                          "falseList",
                          index
                        );
                      }}
                    />
                  </div>
                </div>
              ))}

              {selectedMaterialContentType !== null ? (
                <>
                  <div className="flex flex-col items-center">
                    <div className="mx-2">
                      <BaseImage
                        src="/icons/plus.png"
                        alt="plus"
                        size="large"
                        onClick={() => {
                          if (selectedMaterialContentType === "TEXT") {
                            handleAddMaterialContentToMaterialMap(
                              "TRUE_FALSE",
                              "",
                              "falseList"
                            );
                          } else {
                            setMaterialLibraryModal({
                              show: true,
                              type: selectedMaterialContentType,
                              sub: false,
                              key: "falseList",
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                SingleInformationIconTextItem(
                  i18n.t("please_select_activity_format_to_proceed_text")
                )
              )}
            </div>
          </div>

          <DividerLine />
          <SelectionTab
            optionList={innerActivityStateOptionList}
            selectedId={innerActivityState}
            handleSelectOnClick={(itemId) => {
              setInnerActivityState(itemId);
            }}
          />
          {innerActivityState === "ACTIVITY_SOLUTION"
            ? RenderActivityTemplateSolutionMap(
                "TRUE_FALSE",
                trueFalseSolutionMap,
                setTrueFalseSolutionMap
              )
            : innerActivityState === "ACTIVITY_EVALUATION_RUBRIC"
            ? null
            : null}
        </div>
      </div>
    );
  };

  const RenderActivityTemplate = (type) => {
    if (type === "ORDERING") {
      return RenderOrderingActivityTemplate();
    } else if (type === "DROPDOWN") {
      return RenderDropdownActivityTemplate();
    } else if (type === "MCSA") {
      return RenderMCSAActivityTemplate();
    } else if (type === "MCMA") {
      return RenderMCMAActivityTemplate();
    } else if (type === "MATCHING") {
      return RenderMatchingActivityTemplate();
    } else if (type === "GROUPING") {
      return RenderGroupingActivityTemplate();
    } else if (type === "FILL_IN_THE_BLANKS") {
      return RenderFillInTheBlanksActivityTemplate();
    } else if (type === "OPEN_ENDED") {
      return RenderOpenEndedActivityTemplate();
    } else if (type === "TRUE_FALSE") {
      return RenderTrueFalseActivityTemplate();
    }
    return null;
  };

  const RenderSingleActivityTemplateItem = (activityTemplateId) => {
    let foundActivityTemplateDTO = activityTemplateSet.find(
      (activityTemplateDTO) =>
        activityTemplateDTO.id === parseFloat(activityTemplateId)
    );
    if (!foundActivityTemplateDTO) {
      foundActivityTemplateDTO = {
        id: activityTemplateId,
        type: null,
        name: i18n.t("unknown"),
        description: i18n.t("unknown"),
      };
    }
    return (
      <div className="w-full">
        {checkActivityTemplateExists(foundActivityTemplateDTO.type)
          ? RenderActivityTemplate(foundActivityTemplateDTO.type)
          : null}
      </div>
    );
  };

  const RenderInsufficientTokensGenerateActivityTemplateContent = () => {
    return (
      <p className="text-catchup-red">
        {i18n.t("insufficient_tokens_to_generate_activity_template")}
      </p>
    );
  };

  const RenderGenerateOrConvertWithAIContent = (showGenerate, showConvert) => {
    return (
      <div className="flex flex-row items-center gap-x-4">
        {checkCanShowGenerateWithAI() && showGenerate ? (
          <div className="relative">
            <div className="absolute -top-5 right-2">
              <p className="text-catchup-red font-semibold">{i18n.t("new")}</p>
            </div>
            <SecondaryButton
              title={i18n.t("generate_with_ai")}
              size="unlimited"
              onClick={handleGenerateWithAIOnClick}
            />
          </div>
        ) : null}
        {checkCanShowConvertWithAI() && showConvert ? (
          <div className="">
            <PrimaryButton
              title={i18n.t("convert_with_ai")}
              size="unlimited"
              onClick={handleConvertWithAIOnClick}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const RenderInsufficientTokensSolveActivityContent = () => {
    return (
      <p className="text-catchup-red">
        {i18n.t("insufficient_tokens_to_solve_activity")}
      </p>
    );
  };

  const RenderLibrarySelectionModal = () => {
    return (
      <BaseModal
        isOpen={
          bodyLibraryModal.show ||
          materialLibraryModal.show ||
          solutionLibraryModal.show
        }
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setBodyLibraryModal({ show: false });
          setMaterialLibraryModal({
            show: false,
          });
          setSolutionLibraryModal({ show: false });
        }}
      >
        <div className="flex-1 flex flex-col">
          {bodyLibraryModal.show ? (
            <ListAndUpsertStorageFile
              title={i18n.t("activity_documents")}
              description={i18n.t(
                "activity_document_list_table_description_text"
              )}
              type={userProfile.role === "STAFF" ? "brand" : "personal"}
              selectable={false}
              labelable={true}
              showLabelList={true}
              initialFilterApplied={true}
              initialCoterieType={selectedCoterieType}
              initialDocumentType={bodyLibraryModal.type}
              handleSelectOnClick={handleChooseBodyStorageFileItemOnClick}
              ownershipType={"ACTIVITY_DOCUMENT"}
              optionFilter={"ACTIVITY"}
            />
          ) : null}
          {materialLibraryModal.show ? (
            <ListAndUpsertStorageFile
              title={i18n.t("activity_documents")}
              description={i18n.t(
                "activity_document_list_table_description_text"
              )}
              type={userProfile.role === "STAFF" ? "brand" : "personal"}
              selectable={false}
              labelable={true}
              showLabelList={true}
              initialFilterApplied={true}
              initialCoterieType={selectedCoterieType}
              initialDocumentType={materialLibraryModal.type}
              handleSelectOnClick={handleChooseMaterialStorageFileItemOnClick}
              ownershipType={"ACTIVITY_DOCUMENT"}
              optionFilter={"ACTIVITY"}
            />
          ) : null}
          {solutionLibraryModal.show ? (
            <ListAndUpsertStorageFile
              title={i18n.t("activity_documents")}
              description={i18n.t(
                "activity_document_list_table_description_text"
              )}
              type={userProfile.role === "STAFF" ? "brand" : "personal"}
              selectable={false}
              labelable={true}
              showLabelList={true}
              initialFilterApplied={true}
              initialCoterieType={selectedCoterieType}
              initialDocumentType={solutionLibraryModal.type}
              handleSelectOnClick={handleChooseSolutionStorageFileItemOnClick}
              ownershipType={"ACTIVITY_DOCUMENT"}
              optionFilter={"ACTIVITY"}
            />
          ) : null}
        </div>
      </BaseModal>
    );
  };

  const RenderShowSomeoneUpdatedModal = () => {
    return (
      <BaseModal
        isOpen={showSomeoneUpdatedModal}
        size="small"
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowSomeoneUpdatedModal(false);
          setId(null);
          handleReturnFromActivityOnClick();
        }}
      >
        <div className="flex-1 flex flex-col">
          <FullCard>
            <div className="flex flex-col items-center justify-center gap-y-4">
              <BaseImage
                size="large"
                src="/icons/already-updated.png"
                alt="already-updated"
              />
              <div>
                <p className="w-[300px] text-xl text-center">
                  {i18n.t("someone_updated_the_activity_text")}
                </p>
              </div>
            </div>
            {someoneInformation ? (
              <div className="flex flex-col justify-center items-center">
                <p className="">
                  {someoneInformation.firstName} {someoneInformation.lastName}
                </p>
              </div>
            ) : null}
          </FullCard>
        </div>
      </BaseModal>
    );
  };

  const error = findCanCreateActivityError();
  const showGenerateButton = canShowGenerateButton();
  const innerActivityStateOptionList = retrieveInnerActivityStateOptionList();
  const categoryVersionCodeOptionList = retrieveCategoryVersionCodeOptionList();
  const filteredCategoryVersionCodeOptionList =
    filterCategoryVersionCodeOptionList(
      categoryVersionCodeOptionList,
      selectedCoterieType,
      selectedLevel
    );

  return (
    <>
      {RenderLibrarySelectionModal()}
      {RenderShowSomeoneUpdatedModal()}
      <FullCard isShadowed={isShadowed}>
        {showHeader ? (
          <div className="">
            <div className="flex flex-row items-center gap-x-2">
              <BaseImage
                size="medium"
                src="/icons/long-arrow-left.png"
                alt="long-arrow-left"
                onClick={() => {
                  handleReturnFromActivityOnClick();
                }}
              />
              <BaseTitle
                title={
                  id ? i18n.t("update_activity") : i18n.t("create_activity")
                }
              />
            </div>
          </div>
        ) : null}

        <div className="flex flex-col items-center justify-center">
          <DividerLine />
          <div className="w-full">
            <p className="font-semibold text-lg">
              {i18n.t("activity_information")}
            </p>
          </div>
          {SingleInformationIconTextItem(
            i18n.t("follow_a_format_to_provide_an_activity_name")
          )}
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="text"
                value={name}
                onChange={(e) => {
                  handleOnChange("name", e.target.value);
                }}
                errorText={errorMap.name}
              />
            </div>
          </div>
          {SingleInformationIconTextItem(
            i18n.t("follow_a_format_to_provide_an_activity_description")
          )}
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("description")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="textarea"
                value={description}
                onChange={(e) => {
                  handleOnChange("description", e.target.value);
                }}
                errorText={errorMap.description}
              />
            </div>
          </div>

          <div className="w-full flex flex-row items-center flex-wrap">
            <div className="hidden lg:block w-catchup-input-group-title py-5"></div>
            <div className="flex-1 flex flex-row items-center flex-wrap">
              <div className="w-1/2 lg:w-1/3 flex flex-row">
                <div className="w-full">
                  <div className="mr-2">
                    <InputGroup
                      type="select"
                      title={i18n.t("status")}
                      value={selectedStatus}
                      optionList={retrieveStatusOptionList()}
                      onChange={(event) => {
                        handleOnChange("status", event.target.value);
                      }}
                      errorText={errorMap.status}
                    />
                  </div>
                </div>
              </div>
              <div className="w-1/2 lg:w-1/3 flex flex-row">
                <div className="w-full">
                  <div className="mr-2 lg:mx-2">
                    <InputGroup
                      type="select"
                      title={i18n.t("coterie_type")}
                      placeholder={null}
                      value={selectedCoterieType}
                      optionList={filterCoterieTypeOptionList(
                        userInformation,
                        userProfile,
                        userProfileInstitution
                      )}
                      onChange={(event) => {
                        handleOnChange("coterieType", event.target.value);
                      }}
                      errorText={errorMap.coterieType}
                    />
                  </div>
                </div>
              </div>
              <div className="w-1/2 lg:w-1/3 flex flex-row">
                <div className="w-full">
                  <div className="mr-2 lg:mr-0 lg:ml-2">
                    <InputGroup
                      type="select"
                      title={i18n.t("level")}
                      placeholder={null}
                      value={selectedLevel}
                      optionList={filterGradeLevelOptionList(
                        userProfileInstitution,
                        userProfileGrade
                      )}
                      onChange={(event) => {
                        handleOnChange("level", event.target.value);
                      }}
                      errorText={errorMap.level}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {selectedCoterieType !== "DEFAULT_OPTION" &&
          selectedLevel !== "DEFAULT_OPTION" ? (
            <div className="w-full flex flex-row items-center flex-wrap">
              <div className="hidden lg:block w-catchup-input-group-title py-5"></div>
              <div className="flex-1 flex flex-row items-center flex-wrap">
                <div className="w-1/2 xl:w-1/3 flex flex-row">
                  <div className="w-full">
                    <div className="mr-2">
                      <InputGroup
                        type="select"
                        title={i18n.t("category_code")}
                        placeholder={null}
                        value={selectedVersionCode}
                        optionList={filteredCategoryVersionCodeOptionList}
                        onChange={(event) => {
                          handleOnChange("versionCode", event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-1/2 xl:w-1/3 flex flex-row">
                  <div className="relative w-full">
                    <div className="absolute right-5 top-[12px]">
                      <CleanFilterGroup
                        subCategoryFilter={subCategoryFilter}
                        setSubCategoryFilter={setSubCategoryFilter}
                      />
                    </div>
                    <div className="mr-2 lg:mx-2">
                      <InputGroup
                        type="select"
                        title={i18n.t("sub_category_code")}
                        placeholder={null}
                        value={selectedCategoryId}
                        optionList={retrieveCategoryDTOOptionList()}
                        onChange={(event) => {
                          // handleOnChange("categoryId", event.target.value);
                          handleAddCategoryToActivityCategoryListOnClick(
                            event.target.value
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex flex-row">
                  <div className="flex-1 flex flex-row mx-2">
                    <div
                      className="flex flex-row items-center"
                      style={{
                        marginTop:
                          selectedDurationType !== "DEFAULT_OPTION" &&
                          selectedDurationType !== "NONE"
                            ? 32
                            : 32,
                      }}
                    >
                      <Switch
                        onChange={() => {
                          if (selectedDurationType === "NONE") {
                            handleOnChange("durationType", "EACH");
                          } else {
                            handleOnChange("durationType", "NONE");
                          }
                        }}
                        checkedIcon={false}
                        offColor={"#e2e8f0"}
                        onColor={"#57C2D3"}
                        offHandleColor={"#57C2D3"}
                        onHandleColor={"#e2e8f0"}
                        uncheckedIcon={false}
                        checked={selectedDurationType === "EACH"}
                      />
                      <p
                        className={`${
                          selectedDurationType === "EACH"
                            ? "text-catchup-blue"
                            : "text-catchup-gray text-sm"
                        } px-2 transition-all duration-300`}
                      >
                        {i18n.t("quicktivity")}
                      </p>
                    </div>
                    {selectedDurationType !== "DEFAULT_OPTION" &&
                    selectedDurationType !== "NONE" ? (
                      <div className="flex-1 flex flex-row">
                        <div className="flex-1">
                          <InputGroup
                            type="select"
                            title={i18n.t("duration")}
                            value={selectedDurationInSeconds}
                            optionList={retrieveDurationInSecondsOptionList(
                              selectedDurationType
                            )}
                            onChange={(event) => {
                              handleOnChange(
                                "durationInSeconds",
                                event.target.value
                              );
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <DividerLine />

          {activityCategoryList.length > 0 ? (
            <div className="w-full">
              <p className="font-semibold text-lg">
                {i18n.t("selected_sub_categories")}
              </p>
              {activityCategoryList.map((activityCategory) => (
                <div key={activityCategory.id} className="w-full">
                  {RenderSingleActivityCategoryItem(activityCategory)}
                </div>
              ))}
            </div>
          ) : null}

          {activityCategoryList.length > 0 ? <DividerLine /> : null}

          <div className="w-full">
            <div className="my-2">
              <p className="font-semibold text-lg">
                {i18n.t("activity_format")}
              </p>
            </div>
            <SelectionBox
              optionList={retrieveContentTypeOptionList().map(
                (contentTypeOption) => ({
                  ...contentTypeOption,
                  icon: (
                    <BaseImage
                      src={contentTypeOption.icon}
                      alt="content-type-image"
                      size="small"
                    />
                  ),
                })
              )}
              selectedId={selectedMaterialContentType}
              handleSelectOnClick={(itemId) => {
                setSelectedMaterialContentType(itemId);
              }}
            />
          </div>

          <DividerLine />

          <div className="w-full">
            <div className="my-2">
              <p className="font-semibold text-lg">
                {i18n.t("activity_template")}
              </p>
            </div>
            <SelectionCheckbox
              optionList={retrieveActivityTemplateDTOOptionList(
                activityTemplateSet
              )}
              selectedIdList={activityTemplateIdList}
              handleSelectOnClick={(itemId) => {
                handleAddActivityTemplateToActivityOnClick(itemId);
                setSelectedActivityTemplateId(itemId);
              }}
              handleRemoveOnClick={(itemId) => {
                handleRemoveActivityTemplateFromActivityOnClick(
                  parseFloat(itemId)
                );
              }}
            />
          </div>

          {activityTemplateIdList.length > 0 ? (
            <>
              <div className="w-full">
                <div className="mt-3">
                  <SelectionTab
                    optionList={activityTemplateIdList.map(
                      (activityTemplateId) => {
                        const foundActivityTemplateDTO =
                          activityTemplateSet.find(
                            (activityTemplateDTO) =>
                              activityTemplateDTO.id === activityTemplateId
                          );
                        return {
                          id: foundActivityTemplateDTO.id,
                          value: foundActivityTemplateDTO.type,
                          title: i18n.t(foundActivityTemplateDTO.type),
                        };
                      }
                    )}
                    selectedId={selectedActivityTemplateId}
                    handleSelectOnClick={(itemId) => {
                      setSelectedActivityTemplateId(itemId);
                    }}
                  />
                </div>
              </div>
            </>
          ) : null}

          {selectedActivityTemplateId ? (
            <div className="w-full" key={selectedActivityTemplateId}>
              {RenderSingleActivityTemplateItem(selectedActivityTemplateId)}
            </div>
          ) : null}

          <div className="mt-4 ml-auto">
            {error ? (
              <div>
                <p className="text-catchup-red">{error}</p>
              </div>
            ) : (
              <div className="flex flex-row items-center gap-x-3">
                {selectedMaterialContentType && selectedActivityTemplateId ? (
                  <SecondaryButton
                    title={i18n.t("preview")}
                    size="medium"
                    onClick={() => {
                      for (const activityTemplateId of activityTemplateIdList) {
                        const foundActivityTemplate = activityTemplateSet.find(
                          (activityTemplateDTO) =>
                            activityTemplateDTO.id ===
                            parseFloat(activityTemplateId)
                        );
                        if (
                          foundActivityTemplate.type === "DROPDOWN" ||
                          foundActivityTemplate.type === "FILL_IN_THE_BLANKS"
                        ) {
                          const { bodyPartCount, materialPartCount } =
                            calculateActivityMaterialErrorCount(
                              foundActivityTemplate.type
                            );
                          if (bodyPartCount !== materialPartCount) {
                            NotificationManager.error(
                              i18n.t(
                                "activity_body_and_material_does_not_match_error_text"
                              )
                            );
                            return;
                          }
                        }
                      }

                      const constructedData = {};
                      if (selectedDurationType === "EACH") {
                        constructedData["durationMap"] = JSON.stringify({
                          durationType: selectedDurationType,
                          duration: selectedDurationInSeconds,
                        });
                      }
                      constructedData["contentMap"] = JSON.stringify({
                        type: selectedMaterialContentType,
                      });
                      const foundActivityTemplate = activityTemplateSet.find(
                        (activityTemplateDTO) =>
                          activityTemplateDTO.id === selectedActivityTemplateId
                      );
                      if (foundActivityTemplate) {
                        constructActivityData(
                          foundActivityTemplate.type,
                          constructedData
                        );
                      }
                      constructedData.orderingSolutionMap = "{}";
                      constructedData.dropdownSolutionMap = "{}";
                      constructedData.MCSASolutionMap = "{}";
                      constructedData.MCMASolutionMap = "{}";
                      constructedData.matchingSolutionMap = "{}";
                      constructedData.groupingSolutionMap = "{}";
                      constructedData.fillInTheBlanksSolutionMap = "{}";
                      constructedData.openEndedSolutionMap = "{}";
                      constructedData.openEndedEvaluationRubricMap = "{}";
                      constructedData.trueFalseSolutionMap = "{}";
                      window.open(
                        `${connection.app.url}/preview/activity/by-data/${btoa(
                          encodeURI(JSON.stringify(constructedData))
                        ).replaceAll("/", "CATCHUPPER_SLASH")}`
                      );
                    }}
                  />
                ) : null}

                {id ? (
                  <SecondaryButton
                    title={i18n.t("update")}
                    size="medium"
                    onClick={handleUpdateActivityOnClick}
                  />
                ) : (
                  <CreateButton
                    title={i18n.t("create")}
                    size="medium"
                    onClick={handleCreateActivityOnClick}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </FullCard>
    </>
  );
};

export default CreateActivity;
