import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  queryActivityListByAccessibilityParams,
  queryAllActivityDTOPageByAccessibilityParams,
} from "../../requests/CatchtivityRequests";
import AdvancedTable from "../tables/AdvancedTable";
import SetOrUpsertLabel from "../../components/label/SetOrUpsertLabel";
import {
  queryBrandLabelActionByItemIdListAndItemType,
  queryLabelActionByItemIdListAndItemType,
} from "../../requests/LabelRequests";
import {
  checkInsertPermissionGranted,
  checkViewPermissionGranted,
} from "../../utilization/ScreenUtilization";
import BaseModal from "../modal/BaseModal";
import debounce from "lodash.debounce";
import BaseImage from "../images/BaseImage";
import { shuffleArray } from "../../utilization/AppUtilization";

const ListActivity = (props) => {
  const {
    showTitle,
    usePadding,
    activityTemplateTypeList,
    labelable,
    showLabelList,
    insertable,
    updatable,
    deletable,
    previewable,
    handlePreviewOnClick,
    selectedItemIdList,
    handleSelectOnClick,
    handleInitialSelectListOnClick,
    initialFilterApplied,
    showCoterieType,
    initialLevel,
    showLevel,
    initialCoterieType,
    initialCategoryCode,
    initialSubCategoryCode,
    initialOutcomeCode,
    categoryOccurrenceList,
    chooseActivityParams,
    navigatable,
  } = props;
  const {
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    categorySet,
  } = useAuth();
  const [initialState, setInitialState] = useState("WAIT");
  const [activityDTOList, setActivityDTOList] = useState([]);
  const [selectedShowItemCount, setSelectedShowItemCount] = useState(25);
  const [isFilterApplied, setIsFilterApplied] = useState(
    initialFilterApplied ? initialFilterApplied : false
  );
  const [filterBeginAt, setFilterBeginAt] = useState("");
  const [filterEndAt, setFilterEndAt] = useState("");
  const [filterTextContains, setFilterTextContains] = useState("");
  const [filterShowDeleted, setFilterShowDeleted] = useState(false);
  const [filterCoterieType, setFilterCoterieType] = useState(
    initialCoterieType
      ? initialCoterieType
      : userInformation.accountType === "GENIXO"
      ? "MANAGEMENT"
      : userProfile.coterieType
      ? userProfile.coterieType
      : "DEFAULT_OPTION"
  );
  const [filterLevel, setFilterLevel] = useState("DEFAULT_OPTION");
  const [filterCategoryCode, setFilterCategoryCode] =
    useState("DEFAULT_OPTION");
  const [filterSubCategoryCode, setFilterSubCategoryCode] =
    useState("DEFAULT_OPTION");
  const [filterOutcomeCode, setFilterOutcomeCode] = useState("DEFAULT_OPTION");
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [labelActionDTOList, setLabelActionDTOList] = useState([]);
  const [brandLabelActionDTOList, setBrandLabelActionDTOList] = useState([]);
  const [labelLoading, setLabelLoading] = useState(false);
  const [brandLabelLoading, setBrandLabelLoading] = useState(false);
  const [debounceState, setDebounceState] = useState("");
  const ITEM_TYPE = "ACTIVITY";

  useEffect(() => {
    setInitialState("WAIT");
    if (initialFilterApplied) {
      setIsFilterApplied(initialFilterApplied);
    }
    if (initialLevel) {
      setFilterLevel(initialLevel);
    }
    if (initialCoterieType) {
      setFilterCoterieType(initialCoterieType);
    }
    if (initialCategoryCode) {
      setFilterCategoryCode(initialCategoryCode);
    }
    if (initialSubCategoryCode) {
      setFilterSubCategoryCode(initialSubCategoryCode);
    }
    if (initialOutcomeCode) {
      setFilterOutcomeCode(initialOutcomeCode);
    }
    setInitialState("OK");
  }, [
    initialFilterApplied,
    initialLevel,
    initialCoterieType,
    initialCategoryCode,
    initialSubCategoryCode,
    initialOutcomeCode,
  ]);

  useEffect(() => {
    if (initialState !== "OK") return;
    retrieveAndSetActivityPageByFilter();
  }, [
    pageNumber,
    selectedShowItemCount,
    sortKey,
    sortDirection,
    isFilterApplied,
    initialState,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    setDebounceState("BASE_FILTER");
    retrieveAndSetActivityPageByFilter();
  }, [
    isFilterApplied,
    filterBeginAt,
    filterEndAt,
    filterLevel,
    filterShowDeleted,
    filterCoterieType,
    filterCategoryCode,
    filterSubCategoryCode,
    filterOutcomeCode,
  ]);

  useEffect(() => {
    if (!isFilterApplied) return;
    if (filterTextContains) {
      setDebounceState("TEXT_CONTAINS");
      debouncedRetrieveAndSetActivityPageByFilterDebounce(filterTextContains);
    } else {
      retrieveAndSetActivityPageByFilter();
    }
  }, [filterTextContains]);

  useEffect(() => {
    const retrieveAndSetLabelActionList = async () => {
      setLabelLoading(true);
      const { data, err } = await queryLabelActionByItemIdListAndItemType({
        itemIdList: activityDTOList.map((activityDTO) => activityDTO.id),
        itemType: ITEM_TYPE,
        userId: userInformation.id,
        userProfileId: userProfile.id,
      });
      if (err) {
      } else {
        setLabelActionDTOList(data);
      }
      setLabelLoading(false);
    };
    const retrieveAndSetBrandLabelActionList = async () => {
      setBrandLabelLoading(true);
      const { data, err } = await queryBrandLabelActionByItemIdListAndItemType({
        itemIdList: activityDTOList.map((activityDTO) => activityDTO.id),
        itemType: ITEM_TYPE,
        brandId: userProfileBrand.id,
      });
      if (err) {
      } else {
        setBrandLabelActionDTOList(data);
      }
      setBrandLabelLoading(false);
    };
    if (activityDTOList.length > 0) {
      retrieveAndSetLabelActionList();
      retrieveAndSetBrandLabelActionList();
    }
  }, [activityDTOList]);

  useEffect(() => {
    const retrieveAndSetActivityListByAccessibilityParams = async () => {
      const { data, err } = await queryActivityListByAccessibilityParams({
        brandId: userProfileBrand.id,
        campusId: userProfileCampus?.id,
        institutionId: userProfileInstitution?.id,
        categoryIdList: categoryOccurrenceList.map(
          (categoryOccurrence) => categoryOccurrence.categoryId
        ),
        coterieType: initialCoterieType,
        level: initialLevel,
      });
      if (err) {
        console.log(err);
      } else {
        let currentNumberOfActivity = 10;
        let currentMustCategoryRatio = 1.0;
        if (chooseActivityParams) {
          const { numberOfActivity, mustCategoryRatio } = chooseActivityParams;
          currentNumberOfActivity = numberOfActivity;
          currentMustCategoryRatio = mustCategoryRatio;
        }
        if (data.length <= currentNumberOfActivity) {
          handleInitialSelectListOnClick(data);
        }
        const shuffleData = shuffleArray(data);

        const randomMustActivityDTOSet = new Set();
        const mustCategoryOccurrenceList = categoryOccurrenceList.filter(
          (categoryOccurrence) => categoryOccurrence.isMust
        );
        const mustData = shuffleData.filter((item) => {
          const { categoryIdList } = item;
          let exists = false;
          for (const categoryId of categoryIdList) {
            if (
              mustCategoryOccurrenceList.findIndex(
                (mustCategoryOccurrence) =>
                  mustCategoryOccurrence.categoryId === categoryId
              ) !== -1
            ) {
              exists = true;
              break;
            }
          }
          return exists;
        });
        const copyMustData = JSON.parse(JSON.stringify(mustData));
        const currentMustActivityNumber = Math.round(
          currentNumberOfActivity * currentMustCategoryRatio
        );

        let copyMustCategoryOccurrenceList = JSON.parse(
          JSON.stringify(mustCategoryOccurrenceList)
        );
        let mustHardStop = 0;
        for (let i = 0; i < mustData.length; i++) {
          mustHardStop++;
          if (mustHardStop > 50) break;
          const randomCategoryIndex = Math.floor(
            Math.random() * copyMustCategoryOccurrenceList.length
          );
          const categoryId =
            copyMustCategoryOccurrenceList[randomCategoryIndex].categoryId;
          copyMustCategoryOccurrenceList.splice(randomCategoryIndex, 1);
          if (copyMustCategoryOccurrenceList.length === 0) {
            copyMustCategoryOccurrenceList = JSON.parse(
              JSON.stringify(mustCategoryOccurrenceList)
            );
          }
          const filteredCopyData = copyMustData.filter(
            (item) =>
              item.categoryIdList.findIndex(
                (itemCategoryId) => itemCategoryId === categoryId
              ) !== -1
          );
          if (filteredCopyData.length === 0) {
            i--;
            continue;
          }
          const randomActivityIndex = Math.floor(
            Math.random() * filteredCopyData.length
          );
          const randomActivity = filteredCopyData[randomActivityIndex];
          const removeActivityIndex = copyMustData.findIndex(
            (item) => item.id === randomActivity.id
          );
          copyMustData.splice(removeActivityIndex, 1);
          randomMustActivityDTOSet.add(randomActivity);
          if (randomMustActivityDTOSet.size === currentMustActivityNumber) {
            break;
          }
        }
        const randomActivityDTOList = [];
        randomActivityDTOList.push(...Array.from(randomMustActivityDTOSet));

        const randomOtherActivityDTOSet = new Set();
        const otherCategoryOccurrenceList = categoryOccurrenceList.filter(
          (categoryOccurrence) => !categoryOccurrence.isMust
        );
        const otherData = shuffleData.filter((item) => {
          const { categoryIdList } = item;
          let exists = false;
          for (const categoryId of categoryIdList) {
            if (
              otherCategoryOccurrenceList.findIndex(
                (otherCategoryOccurrence) =>
                  otherCategoryOccurrence.categoryId === categoryId
              ) !== -1
            ) {
              exists = true;
              break;
            }
          }
          return exists;
        });
        const copyOtherData = JSON.parse(JSON.stringify(otherData));
        const currentOtherActivityNumber =
          currentNumberOfActivity - randomMustActivityDTOSet.size;
        let copyOtherCategoryOccurrenceList = JSON.parse(
          JSON.stringify(otherCategoryOccurrenceList)
        );
        let otherHardStop = 0;
        if (copyOtherCategoryOccurrenceList.length !== 0) {
          for (let i = 0; i < otherData.length; i++) {
            otherHardStop++;
            if (otherHardStop > 50) break;
            const randomCategoryIndex = Math.floor(
              Math.random() * copyOtherCategoryOccurrenceList.length
            );
            const categoryId =
              copyOtherCategoryOccurrenceList[randomCategoryIndex].categoryId;
            copyOtherCategoryOccurrenceList.splice(randomCategoryIndex, 1);
            if (copyOtherCategoryOccurrenceList.length === 0) {
              copyOtherCategoryOccurrenceList = JSON.parse(
                JSON.stringify(otherCategoryOccurrenceList)
              );
            }
            const filteredCopyData = copyOtherData.filter(
              (item) =>
                item.categoryIdList.findIndex(
                  (itemCategoryId) => itemCategoryId === categoryId
                ) !== -1
            );
            if (filteredCopyData.length === 0) {
              i--;
              continue;
            }
            const randomActivityIndex = Math.floor(
              Math.random() * filteredCopyData.length
            );
            const randomActivity = filteredCopyData[randomActivityIndex];
            const removeActivityIndex = copyOtherData.findIndex(
              (item) => item.id === randomActivity.id
            );
            copyOtherData.splice(removeActivityIndex, 1);
            if (
              randomActivityDTOList.findIndex(
                (existingRandomActivity) =>
                  existingRandomActivity.id === randomActivity.id
              ) === -1
            ) {
              randomOtherActivityDTOSet.add(randomActivity);
            } else {
              i--;
              continue;
            }
            if (randomOtherActivityDTOSet.size === currentOtherActivityNumber) {
              break;
            }
          }
        }

        randomActivityDTOList.push(...Array.from(randomOtherActivityDTOSet));
        handleInitialSelectListOnClick(randomActivityDTOList);
      }
    };
    if (!categoryOccurrenceList) return;
    if (categoryOccurrenceList.length === 0) return;
    if (initialLevel === "DEFAULT_OPTION") return;
    if (initialCoterieType === "DEFAULT_OPTION") return;
    if (selectedItemIdList.length > 0) return;
    retrieveAndSetActivityListByAccessibilityParams();
  }, [categoryOccurrenceList]);

  const retrieveAndSetActivityPageByFilter = async () => {
    if (initialState !== "OK") return;
    setTableLoading(true);
    const { data, err } = await queryAllActivityDTOPageByAccessibilityParams(
      pagedQueryParams
    );
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setActivityDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const retrieveAndSetActivityPageByFilterDebounce = async (
    filterTextContains
  ) => {
    if (initialState !== "OK") return;
    setTableLoading(true);
    if (isFilterApplied) {
      pagedQueryParams.params.textContains = filterTextContains.trim();
    }
    const { data, err } = await queryAllActivityDTOPageByAccessibilityParams(
      pagedQueryParams
    );
    if (err) {
      console.log(err);
    } else {
      setTotalItemCount(data.totalItemCount);
      setActivityDTOList(data.itemList);
    }
    setTableLoading(false);
  };

  const debouncedRetrieveAndSetActivityPageByFilterDebounce = useCallback(
    debounce(function (textContains) {
      retrieveAndSetActivityPageByFilterDebounce(textContains);
    }, 1000),
    [debounceState]
  );

  const retrieveSubCategoryCodeList = () => {
    const filteredCategoryDTOList = [];
    const filteredMainCategoryDTOList = categorySet.filter(
      (categoryDTO) => categoryDTO.versionCode === filterCategoryCode
    );
    for (const currentCategoryDTO of filteredMainCategoryDTOList) {
      const foundIndex = filteredMainCategoryDTOList.findIndex(
        (categoryDTO) => categoryDTO.parentCode === currentCategoryDTO.code
      );
      if (foundIndex !== -1) {
        filteredCategoryDTOList.push(currentCategoryDTO);
      }
    }

    return filteredCategoryDTOList
      .sort((a, b) => a.code.localeCompare(b.code))
      .map((categoryDTO) => ({
        value: categoryDTO.id,
        text: `${categoryDTO.code} - ${categoryDTO.name}`,
      }));
  };

  const retrieveOutcomeCodeList = () => {
    const foundCategory = categorySet.find(
      (category) => category.id === filterSubCategoryCode
    );
    if (!foundCategory) return [];
    const filteredCategoryList = categorySet.filter(
      (category) =>
        category.versionCode === foundCategory.versionCode &&
        category.level === foundCategory.level
    );

    const subCategoryList = categorySet.filter(
      (category) => category.parentCode === foundCategory.code
    );
    const filterOutcomeCodeList = [];
    for (const subCategory of subCategoryList) {
      const filteredSubCategoryList = filteredCategoryList.filter(
        (filteredCategory) => filteredCategory.code.startsWith(subCategory.code)
      );
      for (const filteredSubCategory of filteredSubCategoryList) {
        const foundIndex = filteredSubCategoryList.findIndex(
          (categoryDTO) => categoryDTO.parentCode === filteredSubCategory.code
        );
        if (foundIndex === -1) {
          filterOutcomeCodeList.push(filteredSubCategory);
        }
      }
    }

    return filterOutcomeCodeList
      .sort((a, b) => a.code.localeCompare(b.code))
      .map((categoryDTO) => ({
        value: categoryDTO.id,
        text: `${categoryDTO.code} - ${categoryDTO.name}`,
      }));
  };

  const retrieveSubCategoryCodeIdList = () => {
    const foundSubCategory = categorySet.find(
      (category) =>
        parseFloat(category.id) === parseFloat(filterSubCategoryCode)
    );
    if (foundSubCategory) {
      let currentCategorySet = [];
      currentCategorySet.concat(
        recursiveSubCategoryCodeIdList(
          categorySet,
          currentCategorySet,
          foundSubCategory.code
        )
      );
      return currentCategorySet.map((category) => category.id);
    }
    return [];
  };

  const recursiveSubCategoryCodeIdList = (
    categorySet,
    currentCategorySet,
    categoryCode
  ) => {
    const filterCategorySet = categorySet.filter(
      (category) => category.parentCode === categoryCode
    );
    filterCategorySet.forEach((filterCategory) => {
      const foundIndex = categorySet.findIndex(
        (categoryDTO) => categoryDTO.parentCode === filterCategory.code
      );
      if (foundIndex === -1) {
        const foundCurrentIndex = currentCategorySet.findIndex(
          (currentCategory) => currentCategory.id === filterCategory.id
        );
        if (foundCurrentIndex === -1) {
          currentCategorySet.push(filterCategory);
        }
      } else {
        recursiveSubCategoryCodeIdList(
          categorySet,
          currentCategorySet,
          filterCategory.code
        );
      }
    });
    return currentCategorySet;
  };

  const constructLabelActionList = () => {
    const actionDTOList = [];
    for (const labelAction of labelActionDTOList) {
      actionDTOList.push({
        id: labelAction.id,
        label: labelAction.labelDTO,
        labelItemList: labelAction.labelItemDTOList,
        type: "PERSONAL",
      });
    }
    for (const brandLabelAction of brandLabelActionDTOList) {
      actionDTOList.push({
        id: brandLabelAction.id,
        label: brandLabelAction.brandLabelDTO,
        labelItemList: brandLabelAction.brandLabelItemDTOList,
        type: "BRAND",
      });
    }
    return actionDTOList;
  };

  const constructNavigatableList = () => {
    const navigateList = [];
    let index = 0;
    if (checkViewPermissionGranted("activity_accessibility")) {
      navigateList.push({
        id: index,
        text: i18n.t("to_activity_accessibilities_screen"),
        icon: (
          <BaseImage
            src="/icons/navigate-gray.png"
            alt="navigate"
            size="small"
          />
        ),
        value: "/activities/{id}/accessibilities",
      });
      index++;
    }
    if (checkInsertPermissionGranted("activity_list")) {
      navigateList.push({
        id: index,
        text: i18n.t("generate_with_ai"),
        icon: (
          <BaseImage
            src="/icons/navigate-gray.png"
            alt="navigate"
            size="small"
          />
        ),
        value: "/activities/generate-with-ai/{id}/from-activity",
      });
      index++;
    }
    return navigateList;
  };

  const constructPagedQueryParams = () => {
    return {
      pageNumber,
      itemCount: selectedShowItemCount,
      sortKey,
      sortDirection,
      params: {
        beginAt:
          isFilterApplied && filterBeginAt
            ? new Date(filterBeginAt).getTime()
            : null,
        endAt:
          isFilterApplied && filterEndAt
            ? new Date(filterEndAt).getTime()
            : null,
        deleted: isFilterApplied ? (filterShowDeleted ? null : false) : false,
        userId: userInformation.id,
        userProfileId: userProfile.id,
        brandId: userProfileBrand.id,
        campusId: userProfileCampus?.id,
        institutionId: userProfileInstitution?.id,
        textContains:
          isFilterApplied && filterTextContains ? filterTextContains : null,
        coterieType:
          isFilterApplied && filterCoterieType !== "DEFAULT_OPTION"
            ? filterCoterieType
            : null,
        level:
          isFilterApplied && filterLevel !== "DEFAULT_OPTION"
            ? filterLevel
            : null,
        categoryIdList: isFilterApplied
          ? filterOutcomeCode !== "DEFAULT_OPTION"
            ? [filterOutcomeCode]
            : retrieveSubCategoryCodeIdList()
          : [],
        activityTemplateTypeList,
        deleted: filterShowDeleted === null ? null : filterShowDeleted,
      },
    };
  };

  const handleOnFilterChange = (type, value) => {
    if (type === "isFilterApplied") {
      setIsFilterApplied(value);
    } else if (type === "beginAt") {
      setFilterBeginAt(value);
    } else if (type === "endAt") {
      setFilterEndAt(value);
    } else if (type === "showDeleted") {
      setFilterShowDeleted(value);
    } else if (type === "coterieType") {
      setFilterCoterieType(value);
      setFilterCategoryCode("DEFAULT_OPTION");
      setFilterSubCategoryCode("DEFAULT_OPTION");
      setFilterOutcomeCode("DEFAULT_OPTION");
    } else if (type === "categoryCode") {
      setFilterCategoryCode(value);
      setFilterSubCategoryCode("DEFAULT_OPTION");
      setFilterOutcomeCode("DEFAULT_OPTION");
    } else if (type === "subCategoryCode") {
      setFilterSubCategoryCode(value);
      setFilterOutcomeCode("DEFAULT_OPTION");
    } else if (type === "outcomeCode") {
      setFilterOutcomeCode("outcomeCode");
    } else if (type === "textContains") {
      setFilterTextContains(value);
    } else if (type === "level") {
      setFilterLevel(value);
    }
  };

  const handleOnTableParamsChange = (type, value) => {
    if (type === "showItemCount") {
      setSelectedShowItemCount(value);
    } else if (type === "pageNumber") {
      setPageNumber(value);
    } else if (type === "sortKey") {
      setSortKey(value);
    } else if (type === "sortDirection") {
      setSortDirection(value);
    }
  };

  const handleToLabelActivityOnClick = (activityDTO) => {
    setShowLabelModal(true);
    setSelectedActivity(activityDTO);
  };

  const RenderLabelModal = () => {
    return (
      <BaseModal
        isOpen={showLabelModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowLabelModal(false);
          setSelectedActivity(null);
        }}
      >
        <div className="flex-1 flex flex-col">
          <SetOrUpsertLabel
            item={selectedActivity}
            itemType={ITEM_TYPE}
            personalLabelLoading={labelLoading}
            personalLabelActionList={labelActionDTOList}
            setPersonalLabelActionList={setLabelActionDTOList}
            brandLabelLoading={brandLabelLoading}
            brandLabelActionList={brandLabelActionDTOList}
            setBrandLabelActionList={setBrandLabelActionDTOList}
          />
        </div>
      </BaseModal>
    );
  };

  const pagedQueryParams = constructPagedQueryParams();

  return (
    <>
      {RenderLabelModal()}
      <AdvancedTable
        title={showTitle ? i18n.t("activity_list") : null}
        description={
          showTitle ? i18n.t("activity_list_table_description_text") : null
        }
        itemName={i18n.t("activity")}
        usePadding={usePadding}
        headerList={[
          { name: i18n.t("name"), key: "name", type: "info" },
          {
            name: i18n.t("coterie_type"),
            key: "coterieType",
            type: "enumeration",
          },
          {
            name: i18n.t("level"),
            key: "level",
          },
          { name: i18n.t("created_at"), key: "createdAt", type: "datetime" },
        ]}
        selectable={true}
        showFilter={true}
        showItemCount={selectedShowItemCount}
        totalItemCount={totalItemCount}
        pageNumber={pageNumber}
        loading={tableLoading}
        sortKey={sortKey}
        sortDirection={sortDirection}
        handleOnFilterChange={handleOnFilterChange}
        handleOnTableParamsChange={handleOnTableParamsChange}
        filterParams={{
          isFilterApplied,
          showBeginDate: true,
          beginAt: filterBeginAt,
          showEndDate: true,
          endAt: filterEndAt,
          showTextContains: true,
          textContains: filterTextContains,
          showDeleted: filterShowDeleted,
          showCoterieType,
          mainCoterieType:
            userInformation.accountType === "GENIXO"
              ? "MANAGEMENT"
              : userProfile.coterieType,
          coterieType: filterCoterieType,
          showLevel,
          level: filterLevel,
          showCategoryCode: true,
          categoryCode: filterCategoryCode,
          subCategoryCodeList: retrieveSubCategoryCodeList(),
          subCategoryCode: filterSubCategoryCode,
          outcomeCodeList: retrieveOutcomeCodeList(),
          outcomeCode: filterOutcomeCode,
        }}
        itemList={activityDTOList}
        labelable={labelable}
        showLabelList={showLabelList}
        labelActionList={constructLabelActionList()}
        handleLabelOnClick={handleToLabelActivityOnClick}
        selectedItemIdList={selectedItemIdList}
        handleSelectOnClick={handleSelectOnClick}
        insertable={insertable}
        updatable={updatable}
        deletable={deletable}
        previewable={previewable}
        handlePreviewOnClick={handlePreviewOnClick}
        navigatable={navigatable}
        navigateOptionList={constructNavigatableList()}
      />
    </>
  );
};

export default ListActivity;
