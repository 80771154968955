import Select from "react-select";
import i18n from "../../language/i18n";
import { useEffect, useRef } from "react";
import BaseImage from "../images/BaseImage";

const InputGroup = (props) => {
  const {
    type,
    title,
    defaultValue,
    placeholder,
    value,
    onFocus,
    onChange,
    onClick,
    onKeyDown,
    optionList,
    errorText,
    multiple,
    accept,
    theme,
    disabled,
  } = props;

  const textAreaRef = useRef();

  useEffect(() => {
    if (!textAreaRef) return;
    if (!textAreaRef.current) return;
    if (value) {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    } else {
      textAreaRef.current.style.height = `44px`;
    }
  }, [textAreaRef, value]);

  const retrieveNullableOptionList = () => {
    if (!optionList) return [];
    const currentOptionList = {
      text: i18n.t("option_please_select"),
      value: "DEFAULT_OPTION",
    };
    return [currentOptionList, ...optionList];
  };

  const convertOptionListToSelectComponent = (optionList) => {
    return optionList.map((option) => ({
      value: option.value,
      label: option.text,
    }));
  };

  const handleTextAreaOnChange = (e) => {
    textAreaRef.current.style.height = `30px`;
    textAreaRef.current.style.height = `${e.target.scrollHeight + 6}px`;
    onChange(e);
  };

  const CheckboxInputGroup = () => {
    return (
      <div
        className="flex flex-row items-center gap-x-1 cursor-pointer"
        onClick={onClick}
      >
        <BaseImage
          src={value ? "/icons/checkbox.png" : "/icons/checkbox-empty.png"}
          alt="checkbox"
          size="xsmall"
          onClick={() => {}}
        />
        <p className="">{title}</p>
      </div>
    );
  };

  const FileInputGroup = () => {
    return (
      <div className="my-1">
        {title ? (
          <p className="text-md font-semibold pl-2 py-1 text-catchup-gray-400">
            {title}
          </p>
        ) : null}
        <input
          className="w-full py-2 px-4 border border-catchup-gray-100 placeholder-catchup-gray-200 rounded-catchup-large text-black focus:outline-none focus:border-catchup-blue-400 focus:shadow-input"
          type={type}
          defaultValue={defaultValue}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onClick={onClick}
          multiple={multiple}
          accept={accept}
        />
      </div>
    );
  };

  const DateInputGroup = () => {
    return (
      <div className="my-1">
        {title ? (
          <p className="text-md font-semibold pl-2 py-1 text-catchup-gray-400">
            {title}
          </p>
        ) : null}
        <input
          className={`w-full py-2 px-4 border ${
            errorText
              ? "border-catchup-red shadow-error"
              : theme === "red"
              ? "border-catchup-red bg-catchup-red text-catchup-white focus:border-catchup-red"
              : "border-catchup-gray-100 placeholder-catchup-gray-200 focus:border-catchup-blue-400"
          } rounded-catchup-large text-black focus:outline-none focus:shadow-input`}
          type={type}
          defaultValue={defaultValue}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    );
  };

  const SearchableSelectInputGroup = () => {
    return (
      <div className="my-1">
        {title ? (
          <p className="text-md font-semibold pl-2 py-1 text-catchup-gray-400 ">
            {title}
          </p>
        ) : null}
        <Select
          options={convertOptionListToSelectComponent(
            retrieveNullableOptionList()
          )}
          className={`w-full`}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderWidth: 1,
              borderStyle: "solid",
              borderRadius: 16,
              borderColor: errorText
                ? "#ff6b6b"
                : theme === "red"
                ? "#ff6b6b"
                : state.isFocused
                ? "#73d7e6"
                : state.isActive
                ? "#d2dde1"
                : "#d2dde1",
              backgroundColor: theme !== "red" ? "#ffffff" : "#ff6b6b",
              paddingRight: 6,
              paddingLeft: 6,
              paddingTop: 2,
              paddingBottom: 2,
              outline: "none",
              margin: 0,
              text: theme === "red" ? "#ffffff" : "#55777f",
              boxShadow: errorText
                ? "0px 0px 7px 0px rgba(255, 107, 107, 1)"
                : state.isFocused
                ? "0px 0px 7px 0px rgba(115, 215, 230, 100)"
                : "none",
              "&:hover": {
                outline: "none",
              },
            }),
            menu: (baseStyles, state) => ({
              ...baseStyles,
              borderRadius: 16,
              paddingTop: 10,
              paddingBottom: 10,
              boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
            }),
            option: (baseStyles, state) => ({
              ...baseStyles,
              color: state.isSelected ? "#2b3a41" : "#2b3a41",
              backgroundColor: state.isSelected ? "#eaecf1" : "",
              "&:hover": {
                backgroundColor: "#eaecf1",
              },
              paddingLeft: 20,
              paddingRight: 20,
            }),
            dropdownIndicator: (baseStyles, state) => ({
              ...baseStyles,
              color: state.isFocused ? "#57c2d3" : "#55777f",
              "&:hover": {
                color: state.isFocused ? "#57c2d3" : "#55777f",
              },
            }),
          }}
          components={{
            IndicatorSeparator: () => null,
          }}
          value={convertOptionListToSelectComponent(
            retrieveNullableOptionList()
          ).find((option) => option.value === value)}
          onChange={(target) => {
            const event = { target };
            onChange(event);
          }}
        />
      </div>
    );
  };

  const TextAreaInputGroup = () => {
    return (
      <div className="my-1 flex-1 flex flex-col relative">
        {title ? (
          <p className="text-md pl-2 py-2 text-catchup-gray-400">{title}</p>
        ) : null}
        <textarea
          ref={textAreaRef}
          disabled={disabled}
          className={`w-full h-[44px] resize-none overflow-hidden py-2 px-4 border ${
            errorText
              ? "border-catchup-red shadow-error placeholder:text-catchup-red placeholder:opacity-80"
              : "border-catchup-gray-100"
          } placeholder-catchup-gray-200 rounded-catchup-medium focus:outline-none focus:border-catchup-blue-400 ${
            disabled ? "bg-catchup-lighter-gray" : null
          } focus:shadow-input`}
          type={type}
          defaultValue={defaultValue}
          placeholder={errorText ? errorText : placeholder}
          value={value}
          onLoad={(e) => {
            console.log(e);
          }}
          onChange={handleTextAreaOnChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
        />
        {/* <div
          className={`${
            title ? "absolute top-0 right-0" : "absolute top-3 left-5"
          }`}
        >
          <p className="italic text-catchup-red opacity-70">{errorText}</p>
        </div> */}
      </div>
    );
  };

  const TextInputGroup = () => {
    return (
      <div className="my-1 relative">
        {title ? (
          <p className="text-md font-semibold pl-2 py-1 text-catchup-gray-400">
            {title}
          </p>
        ) : null}
        <input
          disabled={disabled}
          className={`w-full py-2 px-4 border ${
            errorText
              ? "border-catchup-red shadow-error placeholder:text-catchup-red placeholder:opacity-80"
              : "border-catchup-gray-100"
          } rounded-catchup-large focus:outline-none placeholder-catchup-gray-200 focus:border-catchup-blue-400 ${
            disabled ? "bg-catchup-lighter-gray" : null
          } focus:shadow-input`}
          type={type}
          defaultValue={defaultValue}
          placeholder={errorText ? errorText : placeholder}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
        />

        {/* <div
          className={`${
            title ? "absolute top-0 right-0" : "absolute top-3 left-5"
          }`}
        >
          <p className="italic text-catchup-red opacity-70">{errorText}</p>
        </div> */}
      </div>
    );
  };

  const RenderMainContent = () => {
    if (type === "text" || type === "number") {
      return TextInputGroup();
    } else if (type === "textarea") {
      return TextAreaInputGroup();
    } else if (type === "password") {
      return TextInputGroup();
    } else if (type === "select") {
      return SearchableSelectInputGroup();
    } else if (type === "date" || type === "datetime-local") {
      return DateInputGroup();
    } else if (type === "file") {
      return FileInputGroup();
    } else if (type === "checkbox") {
      return CheckboxInputGroup();
    }
  };

  return RenderMainContent();
};

export default InputGroup;
