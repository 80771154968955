import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import FullCard from "../../components/cards/FullCard";
import RenderInformationCardItem from "../../components/cards/InformationCard";
import InputGroup from "../../components/groups/InputGroup";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  evaluateActivityOpenEndedWithAI,
  evaluateCatchtivityApplicationById,
  patchActivityEvaluation,
  retrieveActivityWithDataById,
  retrieveCatchtivityApplicationInDetail,
} from "../../requests/CatchtivityRequests";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import {
  constructInputWithSpecialExpressionList,
  retrieveColorByScore,
} from "../../utilization/CatchtivityUtilization";
import DividerLine from "../../components/divider/DividerLine";
import { NotificationManager } from "react-notifications";
import BaseImage from "../../components/images/BaseImage";
import BaseTitle from "../../components/titles/BaseTitle";
import { Tooltip } from "react-tooltip";
import { findAISettingsFromCurrentProfile } from "../../utilization/ManagementUtilization";
import { InlineMath } from "react-katex";
import SelectionTab from "../../components/tabs/SelectionTab";
import CancelButton from "../../components/buttons/CancelButton";
import ActivityTemplateLabel from "../../components/label/ActivityTemplateLabel";
import BaseLoading from "../../components/loading/BaseLoading";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const CatchtivityApplicationDetailScreen = () => {
  const { catchtivityApplicationId } = useParams();
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
  } = useAuth();
  const [catchtivityApplicationDetail, setCatchtivityApplicationDetail] =
    useState(null);
  const [selectedInstitutionId, setSelectedInstitutionId] =
    useState("DEFAULT_OPTION");
  const [selectedSeasonId, setSelectedSeasonId] = useState("DEFAULT_OPTION");
  const [selectedGradeId, setSelectedGradeId] = useState("DEFAULT_OPTION");
  const [selectedBranchId, setSelectedBranchId] = useState("DEFAULT_OPTION");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserProfileId, setSelectedUserProfileId] = useState(null);
  const [
    selectedActivityTemplateScoreDTO,
    setSelectedActivityTemplateScoreDTO,
  ] = useState(null);
  const [evaluationWithAIContent, setEvaluationWithAIContent] = useState(null);
  const [contructedUrl, setContructedUrl] = useState(null);
  const [selectedInformationState, setSelectedInformationState] =
    useState("LEARNER");
  const [allOpenEndedActivityState, setAllOpenEndedActivityState] = useState(0);
  const [learnerState, setLearnerState] = useState(0);
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("catchtivity_application_list")) {
      navigate("/permission-error");
    }
  }, []);

  useEffect(() => {
    if (catchtivityApplicationId) {
      retrieveAndSetCatchtivityApplicationInDetail();
    }
  }, [catchtivityApplicationId]);

  useEffect(() => {
    if (userProfileBranch) {
      setSelectedInstitutionId(
        userProfileBranch.gradeDTO.seasonDTO.institutionDTO.id
      );
      setSelectedSeasonId(userProfileBranch.gradeDTO.seasonDTO.id);
      setSelectedGradeId(userProfileBranch.gradeDTO.id);
      setSelectedBranchId(userProfileBranch.id);
    } else if (userProfileGrade) {
      setSelectedInstitutionId(userProfileGrade.seasonDTO.institutionDTO.id);
      setSelectedSeasonId(userProfileGrade.seasonDTO.id);
      setSelectedGradeId(userProfileGrade.id);
    } else if (userProfileSeason) {
      setSelectedInstitutionId(userProfileSeason.institutionDTO.id);
      setSelectedSeasonId(userProfileSeason.id);
    } else if (userProfileInstitution) {
      setSelectedInstitutionId(userProfileInstitution.id);
    } else {
      const filteredApplicationSet =
        retrieveInstitutionFilteredApplicationSet();
      if (filteredApplicationSet.length === 1) {
        setSelectedInstitutionId(filteredApplicationSet[0].institutionId);
      }
    }
  }, [catchtivityApplicationDetail]);

  useEffect(() => {
    if (selectedInstitutionId !== "DEFAULT_OPTION") {
      if (retrieveSeasonDTOOptionList().length > 0) {
        setSelectedSeasonId(retrieveSeasonDTOOptionList()[0].value);
      }
    }
  }, [catchtivityApplicationDetail, selectedInstitutionId]);

  useEffect(() => {
    if (selectedSeasonId !== "DEFAULT_OPTION") {
      if (retrieveGradeDTOOptionList().length > 0) {
        setSelectedGradeId(retrieveGradeDTOOptionList()[0].value);
      }
    }
  }, [catchtivityApplicationDetail, selectedSeasonId]);

  useEffect(() => {
    if (selectedGradeId !== "DEFAULT_OPTION") {
      if (retrieveBranchDTOOptionList().length > 0) {
        setSelectedBranchId(retrieveBranchDTOOptionList()[0].value);
      }
    }
  }, [catchtivityApplicationDetail, selectedGradeId]);

  useEffect(() => {
    const constructAndSetPreviewLearnerActivityUrl = async () => {
      const url = await constructLearnerActivityUrl(
        selectedActivityTemplateScoreDTO
      );
      setContructedUrl(url);
    };
    if (selectedActivityTemplateScoreDTO) {
      constructAndSetPreviewLearnerActivityUrl();
    } else {
      setContructedUrl(null);
    }
  }, [selectedActivityTemplateScoreDTO]);

  useEffect(() => {
    const patchActivityEvaluationAiFeedbackText = async () => {
      const { data, err } = await patchActivityEvaluation({
        id: selectedActivityTemplateScoreDTO.id,
        aiFeedbackText: evaluationWithAIContent,
      });
      if (err) {
        console.log(err);
        return;
      }
      selectedActivityTemplateScoreDTO.aiFeedbackText = evaluationWithAIContent;
      setSelectedActivityTemplateScoreDTO(
        JSON.parse(JSON.stringify(selectedActivityTemplateScoreDTO))
      );
      const foundCatchtivityUserDTO =
        catchtivityApplicationDetail.catchtivityUserDTOList.find(
          (catchtivityUserDTO) =>
            catchtivityUserDTO.userId === selectedUserId &&
            catchtivityUserDTO.userProfileId === selectedUserProfileId
        );
      const foundActivityTemplateScoreDTO =
        foundCatchtivityUserDTO.activityTemplateScoreDTOList.find(
          (activityTemplateScoreDTO) =>
            activityTemplateScoreDTO.id === selectedActivityTemplateScoreDTO.id
        );
      foundActivityTemplateScoreDTO.aiFeedbackText = evaluationWithAIContent;
    };
    if (!evaluationWithAIContent) return;
    patchActivityEvaluationAiFeedbackText();
  }, [evaluationWithAIContent]);

  const retrieveAndSetCatchtivityApplicationInDetail = async () => {
    const { data, err } = await retrieveCatchtivityApplicationInDetail(
      catchtivityApplicationId
    );
    if (err) {
      console.log(err);
      return;
    }
    setCatchtivityApplicationDetail(data);
  };

  const filterAvailableApplicationUserDetailList = () => {
    let filteredApplicationList = [];

    if (catchtivityApplicationDetail) {
      if (userProfileBranch) {
        filteredApplicationList =
          catchtivityApplicationDetail.catchtivityUserDTOList.filter(
            (catchtivityUserDTO) =>
              parseFloat(catchtivityUserDTO.branchId) ===
              parseFloat(userProfileBranch.id)
          );
      } else if (userProfileGrade) {
        filteredApplicationList =
          catchtivityApplicationDetail.catchtivityUserDTOList.filter(
            (catchtivityUserDTO) =>
              parseFloat(catchtivityUserDTO.gradeId) ===
              parseFloat(userProfileGrade.id)
          );
      } else if (userProfileSeason) {
        filteredApplicationList =
          catchtivityApplicationDetail.catchtivityUserDTOList.filter(
            (catchtivityUserDTO) =>
              parseFloat(catchtivityUserDTO.seasonId) ===
              parseFloat(userProfileSeason.id)
          );
      } else if (userProfileInstitution) {
        filteredApplicationList =
          catchtivityApplicationDetail.catchtivityUserDTOList.filter(
            (catchtivityUserDTO) =>
              parseFloat(catchtivityUserDTO.institutionId) ===
              parseFloat(userProfileInstitution.id)
          );
      } else {
        return catchtivityApplicationDetail.catchtivityUserDTOList;
      }
    }
    return filteredApplicationList;
  };

  const filterSelectedApplicationUserDetailList = () => {
    if (!catchtivityApplicationDetail) return [];
    return catchtivityApplicationDetail.catchtivityUserDTOList.filter(
      (catchtivityUserDTO) =>
        parseFloat(catchtivityUserDTO.branchId) ===
          parseFloat(selectedBranchId) &&
        parseFloat(catchtivityUserDTO.gradeId) ===
          parseFloat(selectedGradeId) &&
        parseFloat(catchtivityUserDTO.seasonId) ===
          parseFloat(selectedSeasonId) &&
        parseFloat(catchtivityUserDTO.institutionId) ===
          parseFloat(selectedInstitutionId)
    );
  };

  const retrieveInstitutionFilteredApplicationSet = () => {
    const filteredApplicationSet = [];
    filterAvailableApplicationUserDetailList().forEach((filterApplication) => {
      if (
        filteredApplicationSet.findIndex(
          (newFilterApplication) =>
            parseFloat(newFilterApplication.institutionId) ===
            parseFloat(filterApplication.institutionId)
        ) === -1
      ) {
        filteredApplicationSet.push(filterApplication);
      }
    });
    return filteredApplicationSet;
  };

  const retrieveInstitutionDTOOptionList = () => {
    return retrieveInstitutionFilteredApplicationSet().map(
      (catchtivityUserDTO) => ({
        value: catchtivityUserDTO.institutionId,
        text: catchtivityUserDTO.institutionName,
      })
    );
  };

  const retrieveSeasonDTOOptionList = () => {
    const filteredApplicationSet = [];
    filterAvailableApplicationUserDetailList()
      .filter(
        (catchtivityUserDTO) =>
          parseFloat(catchtivityUserDTO.institutionId) ===
          parseFloat(selectedInstitutionId)
      )
      .forEach((filterApplication) => {
        if (
          filteredApplicationSet.findIndex(
            (newFilterApplication) =>
              parseFloat(newFilterApplication.seasonId) ===
              parseFloat(filterApplication.seasonId)
          ) === -1
        ) {
          filteredApplicationSet.push(filterApplication);
        }
      });

    return filteredApplicationSet.map((catchtivityUserDTO) => ({
      value: catchtivityUserDTO.seasonId,
      text: catchtivityUserDTO.seasonName,
    }));
  };

  const retrieveGradeDTOOptionList = () => {
    const filteredApplicationSet = [];
    filterAvailableApplicationUserDetailList()
      .filter(
        (catchtivityUserDTO) =>
          parseFloat(catchtivityUserDTO.seasonId) ===
          parseFloat(selectedSeasonId)
      )
      .forEach((filterApplication) => {
        if (
          filteredApplicationSet.findIndex(
            (newFilterApplication) =>
              parseFloat(newFilterApplication.gradeId) ===
              parseFloat(filterApplication.gradeId)
          ) === -1
        ) {
          filteredApplicationSet.push(filterApplication);
        }
      });

    return filteredApplicationSet.map((catchtivityUserDTO) => ({
      value: catchtivityUserDTO.gradeId,
      text: catchtivityUserDTO.gradeName,
    }));
  };

  const retrieveBranchDTOOptionList = () => {
    const filteredApplicationSet = [];
    filterAvailableApplicationUserDetailList()
      .filter(
        (catchtivityUserDTO) =>
          parseFloat(catchtivityUserDTO.gradeId) === parseFloat(selectedGradeId)
      )
      .forEach((filterApplication) => {
        if (
          filteredApplicationSet.findIndex(
            (newFilterApplication) =>
              parseFloat(newFilterApplication.branchId) ===
              parseFloat(filterApplication.branchId)
          ) === -1
        ) {
          filteredApplicationSet.push(filterApplication);
        }
      });

    return filteredApplicationSet.map((catchtivityUserDTO) => ({
      value: catchtivityUserDTO.branchId,
      text: catchtivityUserDTO.branchName,
    }));
  };

  const findStarStudent = () => {
    const sortedCatchtivityUserDTOList = JSON.parse(
      JSON.stringify(applicationUserDetailList)
    ).sort((a, b) => b.score - a.score);
    if (sortedCatchtivityUserDTOList.length > 0) {
      const highestScore = sortedCatchtivityUserDTOList[0].score;
      if (highestScore === 0) {
        return "-";
      } else {
        const filteredCatchtivityUserDTOList =
          sortedCatchtivityUserDTOList.filter(
            (catchtivityUserDTO) => catchtivityUserDTO.score === highestScore
          );
        filteredCatchtivityUserDTOList.sort(
          (a, b) => a.timeSpent - b.timeSpent
        );
        return `${filteredCatchtivityUserDTOList[0].firstName} ${filteredCatchtivityUserDTOList[0].lastName}`;
      }
    }
    return "-";
  };

  const calculateAverageTimeSpent = () => {
    let currentTimeSpent = 0;
    let currentCount = 0;
    if (catchtivityApplicationDetail) {
      applicationUserDetailList
        .filter(
          (catchtivityUserDTO) =>
            catchtivityUserDTO.completionStatus !== "NOT_STARTED"
        )
        .forEach((catchtivityUserDTO) => {
          currentTimeSpent += catchtivityUserDTO.timeSpent;
          currentCount++;
        });
    }
    return currentCount === 0
      ? 0
      : `${(currentTimeSpent / currentCount / 1000 / 60).toFixed(2)}`;
  };

  const calculateCompletedLearner = () => {
    let notStartedCount = 0;
    if (catchtivityApplicationDetail) {
      notStartedCount = applicationUserDetailList.filter(
        (catchtivityUserDTO) =>
          catchtivityUserDTO.completionStatus === "COMPLETED"
      ).length;
    }
    return notStartedCount;
  };

  const calculateNotStartedLearner = () => {
    let notStartedCount = 0;
    if (catchtivityApplicationDetail) {
      notStartedCount = applicationUserDetailList.filter(
        (catchtivityUserDTO) =>
          catchtivityUserDTO.completionStatus === "NOT_STARTED"
      ).length;
    }
    return notStartedCount;
  };

  const calculateActivityCount = () => {
    let activityCount = 0;
    if (catchtivityApplicationDetail) {
      activityCount =
        catchtivityApplicationDetail.catchtivityApplicationDTO.catchtivityDTO
          .activityDTOList.length;
    }
    return activityCount;
  };

  const calculateActivityTemplateCount = () => {
    let activityTemplateCount = 0;
    if (catchtivityApplicationDetail) {
      catchtivityApplicationDetail.catchtivityApplicationDTO.catchtivityDTO.activityDTOList.forEach(
        (activityDTO) => {
          activityTemplateCount += activityDTO.activityTemplateDTOList.length;
        }
      );
    }
    return activityTemplateCount;
  };

  const calculateScoreMoreThan = () => {
    return applicationUserDetailList.filter(
      (catchtivityUserDTO) => calculateUserPerformance(catchtivityUserDTO) >= 80
    ).length;
  };

  const calculateUserPerformance = (catchtivityUserDTO) => {
    const totalLength =
      catchtivityApplicationDetail.catchtivityApplicationDTO.catchtivityDTO
        .activityDTOList.length;
    if (totalLength === 0) return 0;
    return (catchtivityUserDTO.score / totalLength) * 100;
  };

  const calculateAverageScore = () => {
    const filteredCatchtivityUserDTOList = applicationUserDetailList.filter(
      (catchtivityUserDTO) =>
        catchtivityUserDTO.completionStatus !== "NOT_STARTED"
    );
    if (filteredCatchtivityUserDTOList.length === 0) return 0;
    let totalScore = 0;
    filteredCatchtivityUserDTOList.forEach((catchtivityUserDTO) => {
      totalScore += calculateUserPerformance(catchtivityUserDTO);
    });
    return totalScore / filteredCatchtivityUserDTOList.length;
  };

  const checkCanHaveOpenEndedAction = (data, score) => {
    const activityTemplateScoreDTOData = JSON.parse(data);
    if (activityTemplateScoreDTOData) {
      const foundData = activityTemplateScoreDTOData.find(
        (data) => data.type === "OPEN_ENDED"
      );
      if (foundData && foundData.answerMap["ANSWER"] !== "" && score === 0) {
        return true;
      }
    }
    return false;
  };

  const calculateUserActionCount = (catchtivityUserDTO) => {
    let actionCount = 0;
    catchtivityUserDTO.activityTemplateScoreDTOList
      .filter(
        (activityTemplateScoreDTO) =>
          activityTemplateScoreDTO.activityTemplateName === "OPEN_ENDED"
      )
      .forEach((activityTemplateScoreDTO) => {
        const { data, score } = activityTemplateScoreDTO;
        if (checkCanHaveOpenEndedAction(data, score)) {
          actionCount++;
        }
      });
    return actionCount;
  };

  const constructActivityActivityTemplateStaticticsList = () => {
    const activityStatistics = [];
    for (const catchtivityUserDTO of applicationUserDetailList) {
      const { firstName, lastName, activityTemplateScoreDTOList } =
        catchtivityUserDTO;
      for (const activityTemplateScoreDTO of activityTemplateScoreDTOList) {
        const foundActivityActivityTemplateStatictics = activityStatistics.find(
          (activityActivityTemplateStatictics) =>
            activityActivityTemplateStatictics.id ===
            activityTemplateScoreDTO.activityId
        );
        if (foundActivityActivityTemplateStatictics) {
          const { templateList } = foundActivityActivityTemplateStatictics;
          const foundTemplate = templateList.find(
            (template) =>
              template.id === activityTemplateScoreDTO.activityTemplateId
          );
          if (foundTemplate) {
            foundTemplate.score += activityTemplateScoreDTO.score;
            foundTemplate.count += 1;
            foundTemplate.userList.push({
              firstName,
              lastName,
              score: activityTemplateScoreDTO.score,
            });
          } else {
            templateList.push({
              id: activityTemplateScoreDTO.activityTemplateId,
              name: activityTemplateScoreDTO.activityTemplateName,
              score: activityTemplateScoreDTO.score,
              isEmpty: activityTemplateScoreDTO.isEmpty,
              userList: [
                {
                  firstName,
                  lastName,
                  score: activityTemplateScoreDTO.score,
                },
              ],
              count: 1,
            });
          }
          foundActivityActivityTemplateStatictics.score +=
            activityTemplateScoreDTO.score;
          foundActivityActivityTemplateStatictics.count += 1;
        } else {
          activityStatistics.push({
            id: activityTemplateScoreDTO.activityId,
            name: activityTemplateScoreDTO.activityName,
            templateList: [
              {
                id: activityTemplateScoreDTO.activityTemplateId,
                name: activityTemplateScoreDTO.activityTemplateName,
                score: activityTemplateScoreDTO.score,
                isEmpty: activityTemplateScoreDTO.isEmpty,
                userList: [
                  {
                    firstName,
                    lastName,
                    score: activityTemplateScoreDTO.score,
                  },
                ],
                count: 1,
              },
            ],
            score: activityTemplateScoreDTO.score,
            count: 1,
          });
        }
      }
    }
    for (const activityStaticticsItem of activityStatistics) {
      const { templateList } = activityStaticticsItem;
      activityStaticticsItem.score /= activityStaticticsItem.count;
      for (const template of templateList) {
        template.score /= template.count;
        template.userList.sort((a, b) => a.score - b.score);
      }
      activityStaticticsItem.templateList =
        activityStaticticsItem.templateList.sort((a, b) => a.score - b.score);
    }

    return activityStatistics.sort((a, b) => a.score - b.score);
  };

  const constructActivityOpenEndedMap = () => {
    const activityOpenEndedMap = {};
    for (const applicationUserDetail of applicationUserDetailList) {
      const { userId, userProfileId, activityTemplateScoreDTOList } =
        applicationUserDetail;
      for (const activityTemplateScoreDTO of activityTemplateScoreDTOList) {
        const { activityId, activityTemplateName } = activityTemplateScoreDTO;
        if (activityTemplateName === "OPEN_ENDED") {
          if (
            activityOpenEndedMap[activityId] &&
            activityOpenEndedMap[activityId].length > 0
          ) {
            activityOpenEndedMap[activityId].push({
              ...activityTemplateScoreDTO,
              userId,
              userProfileId,
            });
          } else {
            activityOpenEndedMap[activityId] = [
              { ...activityTemplateScoreDTO, userId, userProfileId },
            ];
          }
        }
      }
    }
    return activityOpenEndedMap;
  };

  const checkCanEvaluateWithAI = () => {
    const { canEvaluateOpenEnded } = findAISettingsFromCurrentProfile(
      userProfileBrand,
      userProfileCampus,
      userProfileInstitution
    );
    return canEvaluateOpenEnded;
  };

  const handleEvaluateCatchtivityApplicationOnClick = async (id) => {
    const { data, err } = await evaluateCatchtivityApplicationById(id);
    if (err) {
      console.log(err);
    } else {
      if (data === "EVALUATED") {
        retrieveAndSetCatchtivityApplicationInDetail();
      }
    }
  };

  const handleOnChange = (type, value) => {
    if (type === "institutionId") {
      setSelectedInstitutionId(value);
      setSelectedSeasonId("DEFAULT_OPTION");
      setSelectedGradeId("DEFAULT_OPTION");
      setSelectedBranchId("DEFAULT_OPTION");
    } else if (type === "seasonId") {
      setSelectedSeasonId(value);
      setSelectedGradeId("DEFAULT_OPTION");
      setSelectedBranchId("DEFAULT_OPTION");
    } else if (type === "gradeId") {
      setSelectedGradeId(value);
      setSelectedBranchId("DEFAULT_OPTION");
    } else if (type === "branchId") {
      setSelectedBranchId(value);
    }
  };

  const handlePreviewActivityOnClick = (activityDTO) => {
    window.open(
      `${connection.app.url}/preview/activity/${activityDTO.id}/${btoa(
        encodeURI(JSON.stringify({ token: accessToken }))
      )}`
    );
  };

  const constructLearnerActivityUrl = async (
    activityActivityTemplateScoreDTO
  ) => {
    const { activityId, activityTemplateName, data } =
      activityActivityTemplateScoreDTO;
    const { data: resData, err } = await retrieveActivityWithDataById(
      activityId
    );
    if (err) {
      console.log(err);
      return;
    }
    const filteredData = JSON.parse(resData.data);
    let reConstructedData = {
      contentMap: filteredData.contentMap,
    };
    if (activityTemplateName === "ORDERING") {
      reConstructedData = {
        ...reConstructedData,
        orderingBodyMap: filteredData["orderingBodyMap"],
        orderingMaterialMap: filteredData["orderingMaterialMap"],
        orderingTaxonomyMap: filteredData["orderingTaxonomyMap"],
      };
    } else if (activityTemplateName === "DROPDOWN") {
      reConstructedData = {
        ...reConstructedData,
        dropdownBodyMap: filteredData["dropdownBodyMap"],
        dropdownMaterialMap: filteredData["dropdownMaterialMap"],
        dropdownTaxonomyMap: filteredData["dropdownTaxonomyMap"],
      };
    } else if (activityTemplateName === "MCSA") {
      reConstructedData = {
        ...reConstructedData,
        MCSABodyMap: filteredData["MCSABodyMap"],
        MCSAMaterialMap: filteredData["MCSAMaterialMap"],
        MCSATaxonomyMap: filteredData["MCSATaxonomyMap"],
      };
    } else if (activityTemplateName === "MCMA") {
      reConstructedData = {
        ...reConstructedData,
        MCMABodyMap: filteredData["MCMABodyMap"],
        MCMAMaterialMap: filteredData["MCMAMaterialMap"],
        MCMATaxonomyMap: filteredData["MCMATaxonomyMap"],
      };
    } else if (activityTemplateName === "MATCHING") {
      reConstructedData = {
        ...reConstructedData,
        matchingBodyMap: filteredData["matchingBodyMap"],
        matchingMaterialMap: filteredData["matchingMaterialMap"],
        matchingTaxonomyMap: filteredData["matchingTaxonomyMap"],
      };
    } else if (activityTemplateName === "GROUPING") {
      reConstructedData = {
        ...reConstructedData,
        groupingBodyMap: filteredData["groupingBodyMap"],
        groupingMaterialMap: filteredData["groupingMaterialMap"],
        groupingTaxonomyMap: filteredData["groupingTaxonomyMap"],
      };
    } else if (activityTemplateName === "FILL_IN_THE_BLANKS") {
      reConstructedData = {
        ...reConstructedData,
        fillInTheBlanksBodyMap: filteredData["fillInTheBlanksBodyMap"],
        fillInTheBlanksMaterialMap: filteredData["fillInTheBlanksMaterialMap"],
        fillInTheBlanksTaxonomyMap: filteredData["fillInTheBlanksTaxonomyMap"],
      };
    } else if (activityTemplateName === "OPEN_ENDED") {
      reConstructedData = {
        ...reConstructedData,
        openEndedBodyMap: filteredData["openEndedBodyMap"],
        openEndedMaterialMap: filteredData["openEndedMaterialMap"],
        openEndedTaxonomyMap: filteredData["openEndedTaxonomyMap"],
      };
    } else if (activityTemplateName === "TRUE_FALSE") {
      reConstructedData = {
        ...reConstructedData,
        trueFalseBodyMap: filteredData["trueFalseBodyMap"],
        trueFalseMaterialMap: filteredData["trueFalseMaterialMap"],
        trueFalseTaxonomyMap: filteredData["trueFalseTaxonomyMap"],
      };
    }
    const dataWithAnswer = {
      ...reConstructedData,
      answerMap: JSON.parse(data),
    };
    return `${connection.app.url}/preview/activity/by-learner-data/${btoa(
      encodeURI(JSON.stringify(dataWithAnswer))
    ).replaceAll("/", "CATCHUPPER_SLASH")}`;
  };

  const handlePreviewLearnerActivityOnClick = async (
    activityActivityTemplateScoreDTO
  ) => {
    const constructedUrl = await constructLearnerActivityUrl(
      activityActivityTemplateScoreDTO
    );
    window.open(constructedUrl);
  };

  const handleEvaluateOpenEndedActivity = async (baseScore) => {
    const { data, err } = await patchActivityEvaluation({
      id: selectedActivityTemplateScoreDTO.id,
      score: baseScore / 100,
    });
    if (err) {
      console.log(err);
      if (err.response.data) {
        NotificationManager.error(i18n.t(err.response.data));
      }
    } else {
      selectedActivityTemplateScoreDTO.score = baseScore / 100;
      setSelectedActivityTemplateScoreDTO(
        JSON.parse(JSON.stringify(selectedActivityTemplateScoreDTO))
      );
      const foundCatchtivityUserDTO =
        catchtivityApplicationDetail.catchtivityUserDTOList.find(
          (catchtivityUserDTO) =>
            catchtivityUserDTO.userId === selectedUserId &&
            catchtivityUserDTO.userProfileId === selectedUserProfileId
        );
      const foundActivityTemplateScoreDTO =
        foundCatchtivityUserDTO.activityTemplateScoreDTOList.find(
          (activityTemplateScoreDTO) =>
            activityTemplateScoreDTO.id === selectedActivityTemplateScoreDTO.id
        );
      foundActivityTemplateScoreDTO.score =
        selectedActivityTemplateScoreDTO.score;
      let currentScore = 0;
      foundCatchtivityUserDTO.activityTemplateScoreDTOList.forEach(
        (activityTemplateScoreDTO) =>
          (currentScore += activityTemplateScoreDTO.score)
      );
      foundCatchtivityUserDTO.score = currentScore;
      if (selectedInformationState === "LEARNER") {
        setLearnerState(0);
      } else if (selectedInformationState === "ALL_OPEN_ENDED_ACTIVITY") {
        setAllOpenEndedActivityState(0);
      }
    }
  };

  const handleEvaluateOpenEndedWithAIOnClick = async (
    forUserId,
    forUserProfileId
  ) => {
    const { activityId, data } = selectedActivityTemplateScoreDTO;
    const evaluateWithAI = {
      content: JSON.parse(data)[0].answerMap["ANSWER"],
      catchtivityApplicationId,
      activityId,
      temperature: 0.3,
      forUserId,
      forUserProfileId,
      userId: userInformation.id,
      userProfileId: userProfile.id,
      brandId: userProfileBrand.id,
      campusId: userProfileCampus?.id,
      institutionId: userProfileInstitution?.id,
    };
    const { data: resData, err } = await evaluateActivityOpenEndedWithAI(
      evaluateWithAI
    );
    if (err) {
      console.log(err);
      NotificationManager.error(i18n.t("error_on_evaluate_open_ended"));
      return;
    }
    const { content } = resData;
    setEvaluationWithAIContent(content);
  };

  const RenderEvaluationWithAIResultContent = () => {
    if (!evaluationWithAIContent) return;
    const itemList = evaluationWithAIContent.split("\n");
    return itemList.map((item) => (
      <div className="flex-1 flex flex-row flex-wrap items-center">
        <span className="text-lg whitespace-pre-wrap">
          {constructInputWithSpecialExpressionList(item).map(
            (inputPart, index) => (
              <span
                className={`${inputPart.isBold ? "font-semibold" : ""} ${
                  inputPart.isUnderline ? "underline" : ""
                }`}
              >
                {inputPart.isEquation ? (
                  <span className="text-2xl">
                    <InlineMath key={index} math={inputPart.value} />
                  </span>
                ) : (
                  inputPart.value
                )}
              </span>
            )
          )}
        </span>
      </div>
    ));
  };

  const RenderOpenEndedLearnerAnswerContent = () => {
    if (contructedUrl) {
      return (
        <div className="h-[850px] w-full rounded-catchup-xlarge">
          <iframe
            width="100%"
            height="100%"
            className="rounded-catchup-xlarge"
            src={contructedUrl}
          />
        </div>
      );
    } else {
      return (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      );
    }
  };

  const RenderSingleUserProfileActivityItem = (item) => {
    const {
      isEmpty,
      activityName,
      activityTemplateName,
      data,
      score,
      aiFeedbackText,
      userId,
      userProfileId,
    } = item;
    return (
      <div
        className={`w-full ${
          isEmpty
            ? "border-dashed rounded-catchup-xlarge border-catchup-orange"
            : checkCanHaveOpenEndedAction(data, score)
            ? "rounded-catchup-xlarge border-catchup-red"
            : "border-catchup-white"
        } border px-2 py-2 text-lg`}
      >
        <div className="flex flex-row items-center">
          <div className="flex-1 flex flex-col">
            <div className="mr-6">
              <p>{activityName}</p>
            </div>
            <div className="flex flex-row items-center gap-x-1 my-2">
              <div>
                <ActivityTemplateLabel title={i18n.t(activityTemplateName)} />
              </div>
            </div>
          </div>
          <div className="w-[75px] flex flex-col items-center">
            <p className="">
              <span style={{ color: retrieveColorByScore(score * 100) }}>
                {(score * 100).toFixed(2)}%
              </span>
            </p>
          </div>
          <div className="flex flex-row items-center gap-x-1">
            <div className="h-6 w-6">
              {activityTemplateName === "OPEN_ENDED" ? (
                <BaseImage
                  src="/icons/edit.png"
                  alt="edit"
                  size="small"
                  onClick={() => {
                    setSelectedUserId(userId);
                    setSelectedUserProfileId(userProfileId);
                    setSelectedActivityTemplateScoreDTO(item);
                    setEvaluationWithAIContent(aiFeedbackText);
                    if (selectedInformationState === "LEARNER") {
                      setLearnerState(1);
                    } else if (
                      selectedInformationState === "ALL_OPEN_ENDED_ACTIVITY"
                    ) {
                      setAllOpenEndedActivityState(1);
                    }
                  }}
                />
              ) : (
                <BaseImage src="/icons/empty.png" alt="empty" size="small" />
              )}
            </div>
            <BaseImage
              src="/icons/preview.png"
              alt="preview"
              size="small"
              onClick={() => {
                handlePreviewLearnerActivityOnClick(item);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const RenderSingleEvaluateOpenEndedItem = () => {
    const { userId, userProfileId } = selectedActivityTemplateScoreDTO;
    return (
      <>
        {RenderOpenEndedLearnerAnswerContent()}
        {checkCanEvaluateWithAI() && evaluationWithAIContent ? (
          <div className="my-5">{RenderEvaluationWithAIResultContent()}</div>
        ) : null}
        <div className="flex flex-row justify-between items-center my-5">
          <CancelButton
            title={i18n.t("cancel")}
            size="small"
            onClick={() => {
              if (selectedInformationState === "LEARNER") {
                setLearnerState(0);
              } else if (
                selectedInformationState === "ALL_OPEN_ENDED_ACTIVITY"
              ) {
                setAllOpenEndedActivityState(0);
              }
              setSelectedActivityTemplateScoreDTO(null);
            }}
          />
          {checkCanEvaluateWithAI() && !evaluationWithAIContent ? (
            <PrimaryButton
              title={i18n.t("evaluate_with_ai")}
              size="unlimited"
              onClick={async () => {
                await handleEvaluateOpenEndedWithAIOnClick(
                  userId,
                  userProfileId
                );
              }}
            />
          ) : null}
        </div>
        <div className="flex flex-row items-center justify-center gap-x-4 my-4">
          {[0.001, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(
            (baseScore, index) => (
              <div
                key={index}
                className={`flex flex-col items-center justify-center cursor-pointer border border-catchup-blue rounded-full h-12 w-12 ${
                  selectedActivityTemplateScoreDTO.score * 100 === baseScore
                    ? "bg-catchup-dark-blue border-catchup-dark-blue"
                    : ""
                }`}
                onClick={async () => {
                  handleEvaluateOpenEndedActivity(baseScore);
                }}
              >
                <p
                  className={`${
                    selectedActivityTemplateScoreDTO.score * 100 === baseScore
                      ? "text-catchup-white"
                      : ""
                  } text-2xl`}
                  style={{ color: retrieveColorByScore(baseScore) }}
                >
                  {baseScore.toFixed(0)}
                </p>
              </div>
            )
          )}
        </div>
      </>
    );
  };

  const RenderDetailedUserUserProfile = () => {
    const foundUserUserProfile = applicationUserDetailList.find(
      (catchtivityUserDTO) =>
        parseFloat(catchtivityUserDTO.userId) === selectedUserId &&
        parseFloat(catchtivityUserDTO.userProfileId) === selectedUserProfileId
    );
    if (!foundUserUserProfile) return;
    const {
      firstName,
      lastName,
      branchName,
      gradeName,
      seasonName,
      institutionName,
      answeredActivityCount,
      correctActivityCount,
      timeSpent,
      activityTemplateScoreDTOList,
      userId,
      userProfileId,
    } = foundUserUserProfile;
    const { catchtivityApplicationDTO } = catchtivityApplicationDetail;
    const { catchtivityDTO, isEvaluated } = catchtivityApplicationDTO;
    const { activityDTOList } = catchtivityDTO;
    const activityTemplateScoreIdList = activityTemplateScoreDTOList.map(
      (activityTemplateScore) => activityTemplateScore.activityId
    );
    const notAnsweredActivityList = activityDTOList.filter(
      (activity) => !activityTemplateScoreIdList.includes(activity.id)
    );
    return learnerState === 0 ? (
      <div className="border border-catchup-blue-500 rounded-catchup-xlarge p-5">
        <div className="flex flex-row justify-between flex-wrap my-2 text-lg px-4">
          <div className="flex-1 flex flex-col">
            <div>
              <p className="font-semibold text-2xl">
                {firstName} {lastName}
              </p>
            </div>
            <div className="my-1 text-catchup-gray-400">
              <div className="flex flex-row flex-wrap gap-x-2 items-center">
                <p className="">{branchName}</p>
                <img
                  className="w-1 h-1"
                  src="/icons/small-dot-gray.png"
                  alt="small-dot-gray"
                />
                <p className="">{gradeName}</p>
                <img
                  className="w-1 h-1"
                  src="/icons/small-dot-gray.png"
                  alt="small-dot-gray"
                />
                <p className="">{seasonName}</p>
                <img
                  className="w-1 h-1"
                  src="/icons/small-dot-gray.png"
                  alt="small-dot-gray"
                />
                <p className="">{institutionName}</p>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-row flex-wrap justify-end items-center gap-x-5">
            {isEvaluated ? (
              <>
                <div>
                  <p>
                    {i18n.t("answered_activity_count")}:{" "}
                    <span className="font-bold">{answeredActivityCount}</span>
                  </p>
                </div>
                <div>
                  <p>
                    {i18n.t("correct_activity_count")}:{" "}
                    <span className="font-bold">{correctActivityCount}</span>
                  </p>
                </div>
                <div>
                  <p>
                    {i18n.t("time_spent")}:{" "}
                    <span className="font-bold">{`${(
                      timeSpent /
                      1000 /
                      60
                    ).toFixed(2)} ${i18n.t("minute_abbrevation")}`}</span>
                  </p>
                </div>
              </>
            ) : null}
          </div>
        </div>
        {isEvaluated ? <DividerLine /> : null}
        {isEvaluated ? (
          <div className="flex-1 flex flex-col my-3">
            {activityTemplateScoreDTOList.length > 0 ? (
              <>
                <div className="my-2 mx-4">
                  <p className="font-semibold text-lg text-catchup-blue-500">
                    {i18n.t("learner_answers")}
                  </p>
                </div>
                <div className="flex flex-row flex-wrap gap-y-2">
                  {activityTemplateScoreDTOList.map(
                    (activityActivityTemplateScoreDTO, index) => (
                      <div key={index} className="w-full xl:w-1/2">
                        <div className="mx-2">
                          {RenderSingleUserProfileActivityItem({
                            ...activityActivityTemplateScoreDTO,
                            userId,
                            userProfileId,
                          })}
                          {(activityTemplateScoreDTOList.length % 2 === 0 &&
                            index > activityTemplateScoreDTOList.length - 3) ||
                          (activityTemplateScoreDTOList.length % 2 === 1 &&
                            index >
                              activityTemplateScoreDTOList.length -
                                2) ? null : (
                            <DividerLine />
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </>
            ) : null}

            {notAnsweredActivityList.length > 0 ? (
              <>
                <div className="my-2">
                  <p className="font-semibold text-lg text-catchup-blue-500">
                    {i18n.t("not_answered_learner_answers")}
                  </p>
                </div>
                {notAnsweredActivityList.map((activity, index) => (
                  <div
                    key={index}
                    className={`flex flex-row my-2 px-2 text-lg opacity-50`}
                  >
                    <div className="flex-1 flex flex-row flex-wrap">
                      <p>{activity.name}</p>
                    </div>
                    <div className="mx-2">
                      <BaseImage
                        src="/icons/preview.png"
                        alt="preview"
                        onClick={(e) => {
                          handlePreviewActivityOnClick(activity);
                        }}
                        size="small"
                      />
                    </div>
                  </div>
                ))}
              </>
            ) : null}
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <p className="font-semibold text-catchup-red">
              {i18n.t("not_evaluated_yet")}
            </p>
            <p className="text-catchup-red italic text-md">
              {i18n.t("not_evaluated_yet_description")}
            </p>
          </div>
        )}
      </div>
    ) : (
      RenderSingleEvaluateOpenEndedItem()
    );
  };

  const RenderMainContent = () => {
    if (catchtivityApplicationDetail) {
      const { catchtivityApplicationDTO } = catchtivityApplicationDetail;
      const { id, isEvaluated, catchtivityDTO, endAt } =
        catchtivityApplicationDTO;
      return (
        <FullCard isShadowed={true}>
          <div className="">
            <div className="flex flex-row justify-between items-center gap-x-4">
              <div className="flex flex-row items-center gap-x-2">
                <div className="cursor-pointer">
                  <BaseImage
                    size="medium"
                    src="/icons/long-arrow-left.png"
                    alt="long-arrow-left"
                    onClick={() => {
                      navigate("/catchtivities/applications");
                    }}
                  />
                </div>
                <BaseTitle title={catchtivityDTO.name} />
              </div>
              {endAt > new Date().getTime() ? (
                <div className="flex flex-col items-end">
                  <p className="font-semibold text-catchup-red">
                    {i18n.t("not_evaluated_yet")}
                  </p>
                  <p className="text-catchup-red italic text-md">
                    {i18n.t("not_evaluated_yet_description")}
                  </p>
                </div>
              ) : isEvaluated ? null : (
                <div>
                  <PrimaryButton
                    title={i18n.t("evaluate")}
                    size="unlimited"
                    onClick={async () => {
                      await handleEvaluateCatchtivityApplicationOnClick(id);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <DividerLine />
          <div className="flex flex-row flex-wrap items-center mb-3">
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("institution_name")}
                  placeholder={i18n.t("institution_name")}
                  value={selectedInstitutionId}
                  optionList={retrieveInstitutionDTOOptionList()}
                  onChange={(event) => {
                    handleOnChange("institutionId", event.target.value);
                  }}
                />
              </div>
            </div>
            {selectedInstitutionId !== "DEFAULT_OPTION" ? (
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("season_name")}
                    placeholder={i18n.t("season_name")}
                    value={selectedSeasonId}
                    optionList={retrieveSeasonDTOOptionList()}
                    onChange={(event) => {
                      handleOnChange("seasonId", event.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}

            {selectedSeasonId !== "DEFAULT_OPTION" ? (
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("grade_name")}
                    placeholder={i18n.t("grade_name")}
                    value={selectedGradeId}
                    optionList={retrieveGradeDTOOptionList()}
                    onChange={(event) => {
                      handleOnChange("gradeId", event.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}

            {selectedGradeId !== "DEFAULT_OPTION" ? (
              <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("branch_name")}
                    placeholder={i18n.t("branch_name")}
                    value={selectedBranchId}
                    optionList={retrieveBranchDTOOptionList()}
                    onChange={(event) => {
                      handleOnChange("branchId", event.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="flex flex-row items-center flex-wrap">
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderInformationCardItem(
                i18n.t("activity_count"),
                null,
                <BaseImage
                  src="/icons/activity.png"
                  alt="activity"
                  size="small"
                />,
                calculateActivityCount(),
                1
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderInformationCardItem(
                i18n.t("activity_template_count"),
                null,
                <BaseImage
                  src="/icons/activity-template.png"
                  alt="activity-template"
                  size="small"
                />,
                calculateActivityTemplateCount(),
                2
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderInformationCardItem(
                i18n.t("completed_learner_count"),
                null,
                <BaseImage
                  src="/icons/user-profile-check.png"
                  alt="user-profile-check"
                  size="small"
                />,
                calculateCompletedLearner(),
                3
              )}
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              {RenderInformationCardItem(
                i18n.t("not_started_learner_count"),
                null,
                <BaseImage
                  src="/icons/user-profile-exclamation.png"
                  alt="user-profile-exclamation"
                  size="small"
                />,
                calculateNotStartedLearner(),
                4
              )}
            </div>
            {isEvaluated ? (
              <>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("star_learner"),
                    null,
                    <BaseImage src="/icons/star.png" alt="star" size="small" />,
                    findStarStudent(),
                    1
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("high_performance_learner_count"),
                    null,
                    <BaseImage
                      src="/icons/learner.png"
                      alt="learner"
                      size="small"
                    />,
                    calculateScoreMoreThan(),
                    2
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("average_time_spent"),
                    null,
                    <BaseImage
                      src="/icons/average-time-spent.png"
                      alt="average-time-spent"
                      size="small"
                    />,
                    `${calculateAverageTimeSpent()} ${i18n.t(
                      "minute_abbrevation"
                    )}`,
                    3
                  )}
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4">
                  {RenderInformationCardItem(
                    i18n.t("average_performance"),
                    null,
                    <BaseImage
                      src="/icons/average-performance.png"
                      alt="average-performance"
                      size="small"
                    />,
                    `${calculateAverageScore().toFixed(2)}%`,
                    4
                  )}
                </div>
              </>
            ) : null}
          </div>
          {isEvaluated ? (
            <div className="w-full mt-5">
              <SelectionTab
                optionList={[
                  {
                    id: "LEARNER",
                    title: i18n.t("learners"),
                  },
                  {
                    id: "ALL_OPEN_ENDED_ACTIVITY",
                    title: i18n.t("all_open_ended_activities"),
                  },
                  {
                    id: "ACTIVITY_STATISTICS",
                    title: i18n.t("activity_statistics"),
                  },
                ]}
                selectedId={selectedInformationState}
                handleSelectOnClick={(selectedId) => {
                  setSelectedInformationState(selectedId);
                  setAllOpenEndedActivityState(0);
                  setLearnerState(0);
                }}
              />
            </div>
          ) : null}
          {selectedInformationState === "LEARNER" ? (
            <>
              <div className="flex flex-row items-start flex-wrap gap-x-3 mt-5">
                {applicationUserDetailList.map((catchtivityUserDTO, index) => (
                  <div
                    key={index}
                    className={`${
                      selectedUserId === catchtivityUserDTO.userId
                        ? "border-catchup-blue-800"
                        : "border-catchup-gray-50"
                    } relative w-[150px] lg:w-[200px] min-h-[220px] flex flex-col items-center justify-start my-3 p-3 cursor-pointer border rounded-catchup-xlarge`}
                    onClick={() => {
                      setSelectedUserId(catchtivityUserDTO.userId);
                      setSelectedUserProfileId(
                        catchtivityUserDTO.userProfileId
                      );
                      setLearnerState(0);
                    }}
                  >
                    <div
                      className={`absolute ${
                        catchtivityUserDTO.completionStatus === "TIME_UP"
                          ? "bg-catchup-light-red border-catchup-red text-catchup-red"
                          : catchtivityUserDTO.completionStatus ===
                            "NOT_STARTED"
                          ? "bg-catchup-light-red border-catchup-red text-catchup-red"
                          : catchtivityUserDTO.completionStatus === "ON_GOING"
                          ? "bg-catchup-blue-50 border-catchup-blue-400 text-catchup-blue-400"
                          : catchtivityUserDTO.completionStatus === "ON_GOING"
                          ? ""
                          : "bg-catchup-light-green border-catchup-green text-catchup-green"
                      } -top-4 bg-catchup-white border rounded-catchup-3xlarge px-4`}
                    >
                      <p className="text-center mt-1">
                        {i18n.t(catchtivityUserDTO.completionStatus)}
                      </p>
                    </div>
                    <div className="mt-4 mb-2 relative">
                      {calculateUserActionCount(catchtivityUserDTO) > 0 ? (
                        <div className="absolute flex flex-col items-center justify-center -top-1 -right-1 h-4 w-4 border border-catchup-red bg-catchup-red rounded-full">
                          <p className="text-md text-catchup-white">
                            {calculateUserActionCount(catchtivityUserDTO)}
                          </p>
                        </div>
                      ) : null}
                      <BaseImage
                        size="xlarge"
                        src={
                          catchtivityUserDTO.completionStatus === "TIME_UP"
                            ? "/icons/user-profile-time-up.png"
                            : catchtivityUserDTO.completionStatus ===
                              "NOT_STARTED"
                            ? "/icons/user-profile-not-started.png"
                            : catchtivityUserDTO.completionStatus === "ON_GOING"
                            ? "/icons/user-profile-started.png"
                            : catchtivityUserDTO.completionStatus ===
                              "COMPLETED"
                            ? "/icons/user-profile-completed.png"
                            : null
                        }
                        alt="user-profile-completion-status"
                      />
                    </div>

                    <div className="my-2">
                      <p className="text-center font-semibold">
                        {catchtivityUserDTO.firstName}{" "}
                        {catchtivityUserDTO.lastName}
                      </p>
                      <p className="text-center text-base text-catchup-gray-400">
                        {catchtivityUserDTO.profileName}
                      </p>

                      {isEvaluated ? (
                        <>
                          <div className="flex flex-col justify-center items-center">
                            <div className="flex flex-row items-center gap-x-1">
                              <BaseImage
                                src="/icons/timer-gray.png"
                                alt="timer"
                                size="xsmall"
                              />
                              <div className="flex-1">
                                <p className="text-center">
                                  {`${(
                                    catchtivityUserDTO.timeSpent /
                                    1000 /
                                    60
                                  ).toFixed(2)} ${i18n.t(
                                    "minute_abbrevation"
                                  )}`}
                                </p>
                              </div>
                            </div>
                            <DividerLine />
                            <div>
                              <p
                                className="text-center text-2xl"
                                style={{
                                  color: retrieveColorByScore(
                                    calculateUserPerformance(catchtivityUserDTO)
                                  ),
                                }}
                              >
                                {catchtivityUserDTO.score > 0
                                  ? `${calculateUserPerformance(
                                      catchtivityUserDTO
                                    ).toFixed(2)}%`
                                  : "0.00%"}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
              {RenderDetailedUserUserProfile()}
            </>
          ) : selectedInformationState === "ALL_OPEN_ENDED_ACTIVITY" ? (
            <>
              {allOpenEndedActivityState === 0 ? (
                <div className=" p-5">
                  {Object.keys(activityOpenEndedMap).map(
                    (activityId, index) => {
                      const activityOpenEndedItemList =
                        activityOpenEndedMap[activityId];
                      return (
                        <div className=" text-lg mb-3 ">
                          <div className="mx-4">
                            <p className="font-semibold my-1">
                              {i18n.t("open_ended_group")} ({index + 1})
                            </p>
                          </div>
                          <div className="flex flex-row flex-wrap gap-y-2">
                            {activityOpenEndedItemList.map(
                              (activityOpenEndedItem, index) => (
                                <div key={index} className="w-full xl:w-1/2">
                                  <div className="mx-2">
                                    {RenderSingleUserProfileActivityItem(
                                      activityOpenEndedItem
                                    )}
                                    {(activityOpenEndedItemList.length % 2 ===
                                      0 &&
                                      index >
                                        activityOpenEndedItemList.length - 3) ||
                                    (activityOpenEndedItemList.length % 2 ===
                                      1 &&
                                      index >
                                        activityOpenEndedItemList.length -
                                          2) ? null : (
                                      <DividerLine />
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              ) : (
                RenderSingleEvaluateOpenEndedItem()
              )}
            </>
          ) : selectedInformationState === "ACTIVITY_STATISTICS" ? (
            <div className="p-5">
              <div className="flex flex-row flex-wrap">
                {activityStatistics.map((activityStatisticsItem, index) => {
                  const { id, name, score, templateList } =
                    activityStatisticsItem;
                  return (
                    <div key={index} className="w-full xl:w-1/2">
                      <div className="mx-2">
                        <div className="flex flex-col px-4 py-2 my-2 text-lg">
                          <div className="flex flex-row justify-between">
                            <div className="flex flex-row items-center justify-start gap-x-2">
                              <div>
                                <div>
                                  <p className="">{name}</p>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-row items-center justify-center gap-x-1">
                              <div>
                                <p
                                  className=""
                                  style={{
                                    color: retrieveColorByScore(score * 100),
                                  }}
                                >
                                  {(score * 100).toFixed(2)}%
                                </p>
                              </div>
                              <div>
                                <BaseImage
                                  src="/icons/preview.png"
                                  alt="preview"
                                  size="small"
                                  onClick={() => {
                                    const accessToken =
                                      localStorage.getItem("accessToken");
                                    if (!accessToken) return;
                                    window.open(
                                      `${
                                        connection.app.url
                                      }/preview/activity/${id}/${btoa(
                                        encodeURI(
                                          JSON.stringify({ token: accessToken })
                                        )
                                      )}`
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-col mx-5 my-2 gap-y-1">
                            {templateList.map((template) => {
                              const { name, count, score, userList } = template;
                              let nameString = "";
                              for (const user of userList) {
                                const { firstName, lastName, score } = user;
                                nameString += `${firstName} ${lastName} (${(
                                  score * 100
                                ).toFixed(2)}%)<br />`;
                              }
                              return (
                                <div className="flex flex-row justify-between">
                                  <ActivityTemplateLabel
                                    title={`${i18n.t(name)} (${count})`}
                                  />
                                  <div className="flex flex-row items-center justify-center gap-x-1">
                                    <div>
                                      <p
                                        className="font-medium"
                                        style={{
                                          color: retrieveColorByScore(
                                            score * 100
                                          ),
                                        }}
                                      >
                                        {(score * 100).toFixed(2)}%
                                      </p>
                                    </div>
                                    <Tooltip id="activity_activity_template_statistics" />
                                    <div className="">
                                      <BaseImage
                                        size="small"
                                        src="/icons/info.png"
                                        alt="info"
                                        dataToolTipId="activity_activity_template_statistics"
                                        dataToolTipHTML={nameString}
                                        dataToolTipPlace="top"
                                        dataToolTipVariant="info"
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {(activityStatistics.length % 2 === 0 &&
                          index > activityStatistics.length - 3) ||
                        (activityStatistics.length % 2 === 1 &&
                          index > activityStatistics.length - 2) ? null : (
                          <DividerLine />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </FullCard>
      );
    } else {
      return (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      );
    }
  };

  const applicationUserDetailList = filterSelectedApplicationUserDetailList();
  const activityStatistics = constructActivityActivityTemplateStaticticsList();
  const activityOpenEndedMap = constructActivityOpenEndedMap();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default CatchtivityApplicationDetailScreen;
