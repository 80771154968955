import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  patchMainFile,
  retrieveMainFileByFileName,
} from "../../requests/StorageRequests";
import { useAuth } from "../../context/UserContextProvider";
import FullCard from "../cards/FullCard";
import BaseImage from "../images/BaseImage";
import BaseTitle from "../titles/BaseTitle";
import i18n from "../../language/i18n";
import SecondaryButton from "../buttons/SecondaryButton";
import DividerLine from "../divider/DividerLine";
import InputGroup from "../groups/InputGroup";
import { filterCoterieTypeOptionList } from "../../utilization/ManagementUtilization";

const ActivityDocumentSettingsScreen = () => {
  const navigate = useNavigate();
  const { fileName } = useParams();
  const { userInformation, userProfile, userProfileInstitution } = useAuth();
  const [mainFile, setMainFile] = useState(null);
  const [pageOffset, setPageOffset] = useState(null);
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");
  const [errorMap, setErrorMap] = useState({
    pageOffset: null,
  });

  useEffect(() => {
    const { accountType } = userInformation;
    if (accountType !== "GENIXO") {
      navigate("/");
    }
  }, [userInformation]);

  useEffect(() => {
    const retrieveAndSetMainFileByFileName = async () => {
      const { data, err } = await retrieveMainFileByFileName(fileName);
      if (err) {
        console.log(err);
        return;
      }
      setMainFile(data);
      const { pageOffset, coterieType } = data;
      setPageOffset(pageOffset);
      setSelectedCoterieType(coterieType);
    };
    if (!fileName) return;
    retrieveAndSetMainFileByFileName();
  }, [fileName]);

  const checkCommonValidation = () => {
    if (!pageOffset) {
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        pageOffset: i18n.t("page_offset_required_field"),
      }));
      return false;
    }
    return true;
  };

  const handleOnChange = (type, value) => {
    if (type === "pageOffset") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          pageOffset: i18n.t("page_offset_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          pageOffset: null,
        }));
      }
      setPageOffset(value);
    } else if (type === "coterieType") {
      setSelectedCoterieType(value);
    }
  };

  const handlePatchMainFile = async () => {
    if (checkCommonValidation()) {
      const { data, err } = await patchMainFile({
        id: mainFile.id,
        coterieType: selectedCoterieType,
        pageOffset,
      });
      if (err) {
        console.log(err);
        return;
      }
      navigate(-1);
    }
  };

  const RenderMainContent = () => {
    if (!mainFile) return;
    return (
      <FullCard isShadowed={true}>
        <div className="">
          <div className="flex flex-row items-center gap-x-2">
            <div className="cursor-pointer">
              <BaseImage
                size="medium"
                src="/icons/long-arrow-left.png"
                alt="long-arrow-left"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <BaseTitle title={i18n.t("activity_document_settings")} />
          </div>
        </div>
        <DividerLine />
        <div className="w-full flex flex-row flex-wrap gap-x-4">
          <div className="flex-1 flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("coterie_type")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedCoterieType}
                optionList={filterCoterieTypeOptionList(
                  userInformation,
                  userProfile,
                  userProfileInstitution
                )}
                onChange={(event) => {
                  handleOnChange("coterieType", event.target.value);
                }}
                errorText={errorMap.coterieType}
              />
            </div>
          </div>
          <div className="flex-1 flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("page_offset")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="number"
                value={pageOffset}
                onChange={(e) => {
                  handleOnChange("pageOffset", e.target.value);
                }}
                errorText={errorMap.pageOffset}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 ml-auto">
          <SecondaryButton
            title={i18n.t("update")}
            size="medium"
            onClick={handlePatchMainFile}
          />
        </div>
      </FullCard>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ActivityDocumentSettingsScreen;
