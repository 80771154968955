import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Server
 */
export const checkFileActivityParserService = async () => {
  try {
    const response = await axios
      .create()
      .get(
        `${connection.base["file-activity-parser"].url}${endpoints["file-activity-parser"].context}${endpoints["file-activity-parser"].actuator.health.context}`
      );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * File Activity Parser
 */
export const queryParsedActivity = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base["file-activity-parser"].url}${endpoints["file-activity-parser"].context}${endpoints["file-activity-parser"].query.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const processActivityParserFromPath = async (processParams) => {
  try {
    const response = await axios.post(
      `${connection.base["file-activity-parser"].url}${endpoints["file-activity-parser"].context}${endpoints["file-activity-parser"]["process-from-path"].context}`,
      processParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const processImageToText = async (processParams) => {
  try {
    const response = await axios.post(
      `${connection.base["file-activity-parser"].url}${endpoints["file-activity-parser"].context}${endpoints["file-activity-parser"]["process-image-to-text"].context}`,
      processParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createParsedActivity = async (createParams) => {
  try {
    const response = await axios.post(
      `${connection.base["file-activity-parser"].url}${endpoints["file-activity-parser"].context}${endpoints["file-activity-parser"]["parsed-activity"].context}`,
      createParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchParsedActivity = async (patchParams) => {
  try {
    const response = await axios.patch(
      `${connection.base["file-activity-parser"].url}${endpoints["file-activity-parser"].context}${endpoints["file-activity-parser"]["parsed-activity"].context}`,
      patchParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deleteParsedActivityById = async (id) => {
  try {
    const response = await axios.delete(
      `${connection.base["file-activity-parser"].url}${endpoints["file-activity-parser"].context}${endpoints["file-activity-parser"]["parsed-activity"].id.context}`.replace(
        "{id}",
        id
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
