import { useNavigate, useParams } from "react-router-dom";
import FullCard from "../../components/cards/FullCard";
import DividerLine from "../../components/divider/DividerLine";
import i18n from "../../language/i18n";
import { useEffect, useState } from "react";
import {
  convertActivityTemplateWithAI,
  retrieveActivityWithDataById,
} from "../../requests/CatchtivityRequests";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import { useAuth } from "../../context/UserContextProvider";
import CreateActivity from "../../components/activity/CreateActivity";
import {
  retrieveTaxonomyGroupName,
  retrieveTaxonomyName,
  retrieveTaxonomyType,
} from "../../utilization/CatchtivityUtilization";
import InputGroup from "../../components/groups/InputGroup";
import { NotificationManager } from "react-notifications";
import { findAISettingsFromCurrentProfile } from "../../utilization/ManagementUtilization";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import BaseLoading from "../../components/loading/BaseLoading";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const constructBasicTaxonomyMap = () => {
  return {
    type: retrieveTaxonomyType()[0].value,
    groupName: retrieveTaxonomyGroupName()[0].value,
    name: null,
    stage: null,
  };
};

const GenerateWithAIFromActivityScreen = () => {
  const navigate = useNavigate();
  const { activityId } = useParams();
  const {
    language,
    userInformation,
    userProfile,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    categorySet,
  } = useAuth();
  const [activityLoading, setActivityLoading] = useState(true);
  const [activityWithDataDTO, setActivityWithDataDTO] = useState(null);
  const [canGenerate, setCanGenerate] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState("DEFAULT_OPTION");
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");
  const [selectedVersionCode, setSelectedVersionCode] =
    useState("DEFAULT_OPTION");
  const [selectedActivityCategoryList, setSelectedActivityCategoryList] =
    useState([]);
  const [taxonomyMap, setTaxonomyMap] = useState(constructBasicTaxonomyMap());
  const [MCSAMaterialMap, setMCSAMaterialMap] = useState({ ANSWER_KEY: [] });
  const [MCSABodyMap, setMCSABodyMap] = useState({});
  const [generatedMCSAMaterialMap, setGeneratedMCSAMaterialMap] = useState({
    ANSWER_KEY: [],
  });
  const [generatedMCSABodyMap, setGeneratedMCSABodyMap] = useState({});
  const [selectedMaterialContentType, setSelectedMaterialContentType] =
    useState(null);

  useEffect(() => {
    const { canGenerateActivity } = findAISettingsFromCurrentProfile(
      userProfileBrand,
      userProfileCampus,
      userProfileInstitution
    );
    if (!canGenerateActivity) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetActivityDTOById = async () => {
      setActivityLoading(true);
      const { data, err } = await retrieveActivityWithDataById(activityId);
      if (err) {
        console.log(err);
        return;
      }
      setActivityWithDataDTO(data);
      setActivityLoading(false);
    };
    if (!activityId) return;
    retrieveAndSetActivityDTOById();
  }, [activityId]);

  useEffect(() => {
    const convertToBodyStringMap = (bodyMapString) => {
      const convertedBodyMap = {};
      Object.keys(bodyMapString).forEach((bodyMapStringKey) => {
        convertedBodyMap[bodyMapStringKey] = JSON.parse(
          bodyMapString[bodyMapStringKey]
        );
      });
      return convertedBodyMap;
    };
    if (!activityWithDataDTO) return;
    const { data, activityTemplateDTOList } = activityWithDataDTO;
    setCanGenerate(
      activityTemplateDTOList.findIndex(
        (activityTemplate) => activityTemplate.type === "MCSA"
      ) !== -1
    );
    const constructedMap = JSON.parse(data);
    const currentContentMap = JSON.parse(constructedMap["contentMap"]);
    setSelectedMaterialContentType(currentContentMap.type);
    const currentMCSABodyMap = JSON.parse(constructedMap["MCSABodyMap"]);
    setMCSABodyMap(convertToBodyStringMap(currentMCSABodyMap));
    const currentMCSAMaterialMap = JSON.parse(
      constructedMap["MCSAMaterialMap"]
    );
    const answerKey = Object.keys(currentMCSAMaterialMap)[0];
    setMCSAMaterialMap({
      [answerKey]: JSON.parse(currentMCSAMaterialMap[answerKey]),
    });
    // const currentMCSATaxonomyMap = JSON.parse(
    //   constructedMap["MCSATaxonomyMap"]
    // );
    // setMCSATaxonomyMap(currentMCSATaxonomyMap);
  }, [activityWithDataDTO]);

  useEffect(() => {
    if (!canGenerate) return;
    const { coterieType, level, categoryIdList } = activityWithDataDTO;
    setSelectedCoterieType(coterieType);
    setSelectedLevel(level);
    const currentActivityCategoryList = [];
    for (const categoryId of categoryIdList) {
      const foundCategory = categorySet.find(
        (category) => category.id === categoryId
      );
      if (foundCategory) {
        currentActivityCategoryList.push(foundCategory);
        const { versionCode } = foundCategory;
        setSelectedVersionCode(versionCode);
      }
    }
    setSelectedActivityCategoryList(currentActivityCategoryList);
  }, [canGenerate, categorySet]);

  useEffect(() => {
    if (!isGenerated) return;
  }, [isGenerated]);

  const retrieveFilteredTaxonomyGroupName = (type) => {
    return retrieveTaxonomyGroupName().filter(
      (taxonomyGroupName) => taxonomyGroupName.type === type
    );
  };

  const retrieveFilteredTaxonomyName = (groupName) => {
    return retrieveTaxonomyName().filter(
      (taxonomyName) => taxonomyName.groupName === groupName
    );
  };

  const constructPreviewActivityUrl = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    return `${connection.app.url}/preview/activity/${
      activityWithDataDTO.id
    }/${btoa(encodeURI(JSON.stringify({ token: accessToken })))}`;
  };

  const constructParsedActivity = () => {
    return {
      name: `${i18n.t(
        selectedCoterieType
      )}-${selectedLevel} (${new Date().toLocaleString(language)})`,
      description: `${i18n.t(selectedCoterieType)}-${selectedLevel}-${i18n.t(
        selectedVersionCode
      )}`,
      selectedCoterieType,
      selectedLevel,
      selectedVersionCode,
      selectedActivityCategoryList,
      mcsaMaterialMap: generatedMCSAMaterialMap,
      mcsaBodyMap: generatedMCSABodyMap,
      mcsaTaxonomyMap: taxonomyMap,
    };
  };

  const handleGenerateWithAIOnClick = async () => {
    NotificationManager.info(i18n.t("please_wait_generating_text"));
    const itemList = [];
    Object.keys(MCSABodyMap).forEach((key) => {
      const bodyItem = MCSABodyMap[key];
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
    Object.keys(MCSAMaterialMap).forEach((key) => {
      const materialItemList = MCSAMaterialMap[key];
      for (const materialItem of materialItemList) {
        if (selectedMaterialContentType === "IMAGE") {
          itemList.push({
            type: "image_url",
            imageUrl: materialItem,
          });
        } else {
          itemList.push({
            type: "text",
            text: materialItem,
          });
        }
      }
    });
    const generateWithAI = {
      itemList,
      taxonomy: {
        type: taxonomyMap.type,
        groupName: taxonomyMap.groupName,
        name: taxonomyMap.name,
      },
      level: selectedLevel,
      coterieType: selectedCoterieType,
      activityTemplateType: "MCSA",
      userId: userInformation.id,
      userProfileId: userProfile.id,
      brandId: userProfileBrand.id,
      campusId: userProfileCampus?.id,
      institutionId: userProfileInstitution?.id,
    };
    const { data, err } = await convertActivityTemplateWithAI(generateWithAI);
    if (err) {
      NotificationManager.error(
        i18n.t("error_occurred_on_activity_template_generation")
      );
      return;
    }
    NotificationManager.success(
      i18n.t("activity_template_successfully_generated")
    );
    const { mcsaBodyMap, mcsaMaterialMap, taxonomy } = data;
    const foundImageKeyList = Object.keys(MCSABodyMap).filter(
      (key) => MCSABodyMap[key].type === "IMAGE"
    );
    const currentBodyMap = {};
    if (foundImageKeyList.length > 0) {
      for (let i = 0; i < foundImageKeyList.length; i++) {
        currentBodyMap[i] = MCSABodyMap[foundImageKeyList[i]];
      }
    }
    for (let i = 0; i < Object.keys(mcsaBodyMap).length; i++) {
      currentBodyMap[i + foundImageKeyList.length] = mcsaBodyMap[i];
    }
    setGeneratedMCSABodyMap(currentBodyMap);
    setGeneratedMCSAMaterialMap(mcsaMaterialMap);
    setIsGenerated(true);
  };

  const RenderTaxonomyOptionList = (taxonomyMap, setTaxonomyMap) => {
    return (
      <div>
        <div className="">
          <p className="font-semibold text-lg">{i18n.t("taxonomy_choise")}</p>
        </div>
        <div className="flex flex-row items-center justify-start flex-wrap">
          <div className="w-1/2 lg:w-1/3 xl:w-1/4">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("taxonomy_type")}
                placeholder={i18n.t("taxonomy_type")}
                value={taxonomyMap.type}
                optionList={retrieveTaxonomyType()}
                onChange={(event) => {
                  setTaxonomyMap({
                    type: event.target.value,
                    groupName: "DEFAULT_OPTION",
                    name: "DEFAULT_OPTION",
                    stage: "DEFAULT_OPTION",
                  });
                }}
              />
            </div>
          </div>
          {taxonomyMap.type && taxonomyMap.type !== "DEFAULT_OPTION" ? (
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("taxonomy_group_name")}
                  placeholder={i18n.t("taxonomy_group_name")}
                  value={taxonomyMap.groupName}
                  optionList={retrieveFilteredTaxonomyGroupName(
                    taxonomyMap.type
                  )}
                  onChange={(event) => {
                    setTaxonomyMap({
                      type: taxonomyMap.type,
                      groupName: event.target.value,
                      name: "DEFAULT_OPTION",
                      stage: "DEFAULT_OPTION",
                    });
                  }}
                />
              </div>
            </div>
          ) : null}

          {taxonomyMap.groupName &&
          taxonomyMap.groupName !== "DEFAULT_OPTION" ? (
            <div className="w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("taxonomy_name")}
                  placeholder={i18n.t("taxonomy_name")}
                  value={taxonomyMap.name ? taxonomyMap.name : "DEFAULT_OPTION"}
                  optionList={retrieveFilteredTaxonomyName(
                    taxonomyMap.groupName
                  )}
                  onChange={(event) => {
                    const currentTaxonomyName = event.target.value;
                    const foundTaxonomyName = retrieveFilteredTaxonomyName(
                      taxonomyMap.groupName
                    ).find(
                      (taxonomyName) =>
                        taxonomyName.value === currentTaxonomyName
                    );
                    setTaxonomyMap({
                      type: taxonomyMap.type,
                      groupName: taxonomyMap.groupName,
                      name: foundTaxonomyName.value,
                      stage: foundTaxonomyName.stage,
                    });
                  }}
                />
              </div>
            </div>
          ) : null}

          {taxonomyMap.name ? (
            <div className="w-auto">
              <div className="mt-6">
                <PrimaryButton
                  title={i18n.t("generate")}
                  size="medium"
                  onClick={handleGenerateWithAIOnClick}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const RenderMainContent = () => {
    if (activityLoading) {
      return (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      );
    } else {
      return (
        <FullCard isShadowed={true}>
          {canGenerate ? (
            <>
              {isGenerated ? null : (
                <>
                  <div className="">
                    {RenderTaxonomyOptionList(taxonomyMap, setTaxonomyMap)}
                  </div>
                  <DividerLine />
                </>
              )}
              <div className="h-[850px] w-full rounded-catchup-xlarge">
                <iframe
                  width="100%"
                  height="100%"
                  className="rounded-catchup-xlarge"
                  src={constructPreviewActivityUrl()}
                />
              </div>

              {isGenerated ? (
                <CreateActivity
                  isShadowed={false}
                  showHeader={false}
                  lockedCoterieType={selectedCoterieType}
                  lockedLevel={selectedLevel}
                  lockedVersionCode={selectedVersionCode}
                  parsedActivity={constructParsedActivity()}
                  handleGoBackOnClick={() => {}}
                  handleAfterCreateActivityAction={() => {
                    navigate("/activities");
                  }}
                  handleSameActivityUpdatedBySomeoneAction={() => {}}
                />
              ) : null}
            </>
          ) : (
            <div className="my-2 p-3 border rounded-catchup-xlarge bg-catchup-red text-catchup-white">
              <p>{i18n.t("generate_activity_with_ai_mcsa_required")}</p>
            </div>
          )}
        </FullCard>
      );
    }
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default GenerateWithAIFromActivityScreen;
