import { useEffect } from "react";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import { useNavigate } from "react-router-dom";
import ListPlan from "../../components/plans/ListPlan";
import { useApp } from "../../context/AppContextProvider";

const PlanScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!checkViewPermissionGranted("plan_list")) {
      navigate("/permission-error");
    }
  }, []);

  const RenderMainContent = () => {
    return <ListPlan isPlanWeek={false} />;
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default PlanScreen;
