import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Server
 */
export const checkPlanService = async () => {
  try {
    const response = await axios
      .create()
      .get(
        `${connection.base.plan.url}${endpoints.plans.context}${endpoints.plans.actuator.health.context}`
      );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Plan
 */
export const queryPlanListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.plan.url}${endpoints.plans.context}${endpoints.plans.query.dto.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createPlan = async (plan) => {
  try {
    const response = await axios.post(
      `${connection.base.plan.url}${endpoints.plans.context}/`,
      plan
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const deletePlan = async (id) => {
  try {
    const response = await axios.delete(
      `${connection.base.plan.url}${endpoints.plans.context}/${id}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Plan Week
 */
export const queryPlanWeekListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.plan.url}${endpoints.plans.week.context}${endpoints.plans.week.query.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchPlanWeek = async (patchPlanWeek) => {
  try {
    const response = await axios.patch(
      `${connection.base.plan.url}${endpoints.plans.week.context}/`,
      patchPlanWeek
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const addOrRemoveMultiplePlanWeekCategoryToPlanWeek = async (
  planWeekOperations
) => {
  try {
    const response = await axios.post(
      `${connection.base.plan.url}${endpoints.plans.week.context}${endpoints.plans.week["add-or-remove"].multiple["plan-week-category-to-plan-week"].context}`,
      planWeekOperations
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const addOrRemoveMultiplePlanWeekDocumentToPlanWeek = async (
  planWeekOperations
) => {
  try {
    const response = await axios.post(
      `${connection.base.plan.url}${endpoints.plans.week.context}${endpoints.plans.week["add-or-remove"].multiple["plan-week-document-to-plan-week"].context}`,
      planWeekOperations
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Plan Transfer
 */
export const transferPlanFromGradeIdToGradeId = async (transferPlan) => {
  try {
    const response = await axios.post(
      `${connection.base.plan.url}${endpoints.plans.transfer.context}/`,
      transferPlan
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
