import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Tooltip } from "react-tooltip";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  createAndAssignUserProfileByUserId,
  createUserWithUserProfile,
  patchUserProfile,
  queryBranchListByGradeIdList,
  queryBranchListBySeasonId,
  queryCampusListByBrandId,
  queryGradeListBySeasonId,
  queryGradeListBySeasonIdList,
  queryInstitutionListByCampusIdList,
  querySeasonListByBrandId,
  querySeasonListByInstitutionIdList,
} from "../../requests/ManagementRequests";
import {
  retrieveCoterieTypeOptionList,
  retrieveDefaultUserRoleOptionList,
  filterCoterieTypeOptionList,
  filterUserRoleOptionList,
  retrieveBrandDTOOptionList,
  retrieveCampusDTOOptionList,
  retrieveGradeDTOOptionList,
} from "../../utilization/ManagementUtilization";
import {
  checkInsertPermissionGranted,
  checkUpdatePermissionGranted,
} from "../../utilization/ScreenUtilization";
import SecondaryButton from "../buttons/SecondaryButton";
import FullCard from "../cards/FullCard";
import InputGroup from "../groups/InputGroup";
import { filterGradeLevelOptionList } from "../../utilization/ManagementUtilization";
import BaseImage from "../images/BaseImage";
import CreateButton from "../buttons/CreateButton";

const CreateUserProfile = (props) => {
  const {
    showTitle,
    useCardView,
    handleAfterUpdateOnClick,
    handleAfterCreateOnClick,
    handleReturnOnClick,
    user,
    selectedUserProfile,
  } = props;
  const {
    language,
    userProfile,
    userInformation,
    userProfileBrand,
    userProfileCampus,
    userProfileInstitution,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
  } = useAuth();
  const [selectedRole, setSelectedRole] = useState("DEFAULT_OPTION");
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");
  const [selectedAssignMode, setSelectedAssignMode] = useState(null);
  const [selectedBrandId, setSelectedBrandId] = useState(userProfileBrand.id);
  const [selectedCampusId, setSelectedCampusId] = useState("DEFAULT_OPTION");
  const [selectedInstitutionId, setSelectedInstitutionId] =
    useState("DEFAULT_OPTION");
  const [selectedDefaultUserRole, setSelectedDefaultUserRole] =
    useState("DEFAULT_OPTION");
  const [selectedSeasonId, setSelectedSeasonId] = useState("DEFAULT_OPTION");
  const [selectedGradeId, setSelectedGradeId] = useState("DEFAULT_OPTION");
  const [selectedBranchId, setSelectedBranchId] = useState("DEFAULT_OPTION");
  const [campusIdList, setCampusIdList] = useState([]);
  const [institutionIdList, setInstitutionIdList] = useState([]);
  const [seasonIdList, setSeasonIdList] = useState([]);
  const [gradeIdList, setGradeIdList] = useState([]);
  const [branchIdList, setBranchIdList] = useState([]);
  const [brandDTOList, setBrandDTOList] = useState([userProfileBrand]);
  const [campusDTOList, setCampusDTOList] = useState([]);
  const [institutionDTOList, setInstitutionDTOList] = useState([]);
  const [gradeDTOList, setGradeDTOList] = useState([]);
  const [seasonDTOList, setSeasonDTOList] = useState([]);
  const [branchDTOList, setBranchDTOList] = useState([]);
  const [coterieTypeList, setCoterieTypeList] = useState([]);
  const [branchName, setBranchName] = useState(null);
  const [gradeName, setGradeName] = useState(null);
  const [gradeLevel, setGradeLevel] = useState("DEFAULT_OPTION");
  const [errorMap, setErrorMap] = useState({
    name: null,
    description: null,
    role: null,
    coterieType: null,
    defaultUserRole: null,
    seasonId: null,
    branchName: null,
    gradeId: null,
    gradeName: null,
    gradeLevel: null,
  });

  useEffect(() => {
    if (selectedUserProfile) {
      setId(selectedUserProfile.id);
      setName(selectedUserProfile.name);
      setSelectedRole(selectedUserProfile.role);
      setSelectedCoterieType(
        selectedUserProfile.coterieType
          ? selectedUserProfile.coterieType
          : "DEFAULT_OPTION"
      );
      setDescription(selectedUserProfile.description);
      setSelectedSeasonId(
        selectedUserProfile.seasonDTO
          ? selectedUserProfile.seasonDTO.id
          : "DEFAULT_OPTION"
      );
      if (selectedUserProfile.role === "LEARNER") {
        setBranchIdList(
          selectedUserProfile.branchDTOList.map((branchDTO) => branchDTO.id)
        );
        setSelectedSeasonId(
          selectedUserProfile.seasonDTO
            ? selectedUserProfile.seasonDTO.id
            : "DEFAULT_OPTION"
        );
      } else if (selectedUserProfile.role === "STAFF") {
        const currentBranchIdList = selectedUserProfile.branchDTOList.map(
          (branchDTO) => branchDTO.id
        );
        const currentGradeIdList = selectedUserProfile.gradeDTOList.map(
          (gradeDTO) => gradeDTO.id
        );
        const currentSeasonIdList = selectedUserProfile.seasonDTOList.map(
          (seasonDTO) => seasonDTO.id
        );
        const currentInstitutionIdList =
          selectedUserProfile.institutionDTOList.map(
            (institutionDTO) => institutionDTO.id
          );
        const currentCampusIdList = selectedUserProfile.campusDTOList.map(
          (campusDTO) => campusDTO.id
        );

        setBranchIdList(currentBranchIdList);
        setGradeIdList(currentGradeIdList);
        setSeasonIdList(currentSeasonIdList);
        setInstitutionIdList(currentInstitutionIdList);
        setCampusIdList(currentCampusIdList);
        setSelectedBrandId(userProfileBrand.id);

        if (currentBranchIdList.length > 0) {
          setSelectedAssignMode("BRANCH");
        } else if (currentGradeIdList.length > 0) {
          setSelectedAssignMode("GRADE");
        } else if (currentSeasonIdList.length > 0) {
          setSelectedAssignMode("SEASON");
        } else if (currentInstitutionIdList.length > 0) {
          setSelectedAssignMode("INSTITUTION");
        } else if (currentCampusIdList.length > 0) {
          setSelectedAssignMode("CAMPUS");
        } else {
          setSelectedAssignMode("BRAND");
        }
      } else if (selectedUserProfile.role === "INDIVIDUAL") {
        setCoterieTypeList(selectedUserProfile.coterieTypeList);
      } else if (selectedUserProfile.role === "CONTENT_CREATOR") {
        const currentInstitutionIdList =
          selectedUserProfile.institutionDTOList.map(
            (institutionDTO) => institutionDTO.id
          );
        const currentCampusIdList = selectedUserProfile.campusDTOList.map(
          (campusDTO) => campusDTO.id
        );

        setInstitutionIdList(currentInstitutionIdList);
        setCampusIdList(currentCampusIdList);
        setSelectedBrandId(userProfileBrand.id);

        if (currentInstitutionIdList.length > 0) {
          setSelectedAssignMode("INSTITUTION");
        } else if (currentCampusIdList.length > 0) {
          setSelectedAssignMode("CAMPUS");
        } else {
          setSelectedAssignMode("BRAND");
        }
      }
    }
  }, [selectedUserProfile]);

  useEffect(() => {
    if (
      userProfileSeason &&
      userProfile.role === "INDIVIDUAL" &&
      selectedRole === "LEARNER"
    ) {
      setSelectedSeasonId(
        userProfileSeason ? userProfileSeason.id : "DEFAULT_OPTION"
      );
    }
  }, [selectedRole]);

  useEffect(() => {
    const retrieveAndSetSeasonDTOList = async () => {
      const { data, err } = await querySeasonListByBrandId(userProfileBrand.id);
      if (err) {
        console.log(err);
      } else {
        setSeasonDTOList(data);
      }
    };
    if (selectedRole === "LEARNER") {
      retrieveAndSetSeasonDTOList();
    }
  }, [selectedRole]);

  useEffect(() => {
    const queryAndSetCampusIdList = async () => {
      const { data, err } = await queryCampusListByBrandId(selectedBrandId);
      if (err) {
        console.log(err);
      } else {
        setCampusDTOList(data);
      }
    };
    if (selectedBrandId !== "DEFAULT_OPTION") {
      if (userProfileCampus) {
        setCampusDTOList([userProfileCampus]);
      } else {
        queryAndSetCampusIdList();
      }
    } else {
      // setCampusDTOList([]);
      // setCampusIdList([]);
    }
  }, [selectedBrandId]);

  useEffect(() => {
    const queryAndSetInstitutionList = async () => {
      const { data, err } = await queryInstitutionListByCampusIdList(
        campusIdList
      );
      if (err) {
        console.log(err);
      } else {
        setInstitutionDTOList(data);
      }
    };
    if (campusIdList.length > 0) {
      if (userProfileInstitution) {
        setInstitutionDTOList([userProfileInstitution]);
      } else {
        queryAndSetInstitutionList();
      }
    } else {
    }
  }, [campusIdList]);

  useEffect(() => {
    const queryAndSetSeasonList = async () => {
      const { data, err } = await querySeasonListByInstitutionIdList(
        institutionIdList
      );
      if (err) {
        console.log(err);
      } else {
        setSeasonDTOList(data);
      }
    };
    if (institutionIdList.length > 0) {
      if (userProfileSeason) {
        setSeasonDTOList([userProfileSeason]);
      } else {
        queryAndSetSeasonList();
      }
    } else {
    }
  }, [institutionIdList]);

  useEffect(() => {
    const queryAndSetGradeList = async () => {
      const { data, err } = await queryGradeListBySeasonIdList(seasonIdList);
      if (err) {
        console.log(err);
      } else {
        setGradeDTOList(data);
      }
    };
    if (seasonIdList.length > 0) {
      if (userProfileGrade) {
        setGradeDTOList([userProfileGrade]);
      } else {
        queryAndSetGradeList();
      }
    } else {
    }
  }, [seasonIdList]);

  useEffect(() => {
    const queryAndSetBranchList = async () => {
      const { data, err } = await queryBranchListByGradeIdList(gradeIdList);
      if (err) {
        console.log(err);
      } else {
        setBranchDTOList(data);
      }
    };
    if (gradeIdList.length > 0) {
      if (userProfileBranch) {
        setBranchDTOList([userProfileBranch]);
      } else {
        queryAndSetBranchList();
      }
    } else {
    }
  }, [gradeIdList]);

  useEffect(() => {
    const retrieveAndSetBranchDTOList = async (seasonId) => {
      const { data, err } = await queryBranchListBySeasonId(seasonId);
      if (err) {
        console.log(err);
      } else {
        setBranchDTOList(data);
      }
    };
    const retrieveAndSetGradeDTOList = async (seasonId) => {
      const { data, err } = await queryGradeListBySeasonId(seasonId);
      if (err) {
        console.log(err);
      } else {
        setGradeDTOList(data);
      }
    };
    if (selectedSeasonId !== "DEFAULT_OPTION" && selectedRole === "LEARNER") {
      if (userProfile.role === "INDIVIDUAL") {
        retrieveAndSetGradeDTOList(selectedSeasonId);
        setSelectedGradeId("DEFAULT_OPTION");
      }
      retrieveAndSetBranchDTOList(selectedSeasonId);
      setSelectedBranchId("DEFAULT_OPTION");
    } else {
      if (userProfile.role === "INDIVIDUAL") {
        setGradeDTOList([]);
        setSelectedGradeId("DEFAULT_OPTION");
      }
      setSelectedBranchId("DEFAULT_OPTION");
      setBranchDTOList([]);
    }
  }, [selectedSeasonId]);

  useEffect(() => {
    if (selectedAssignMode === "BRAND") {
      setCampusIdList([]);
      setSelectedCampusId("DEFAULT_OPTION");
      setInstitutionIdList([]);
      setSelectedInstitutionId("DEFAULT_OPTION");
      setSeasonIdList([]);
      setSelectedSeasonId("DEFAULT_OPTION");
      setGradeIdList([]);
      setSelectedGradeId("DEFAULT_OPTION");
      setBranchIdList([]);
      setSelectedBranchId("DEFAULT_OPTION");
    } else if (selectedAssignMode === "CAMPUS") {
      setInstitutionIdList([]);
      setSelectedInstitutionId("DEFAULT_OPTION");
      setSeasonIdList([]);
      setSelectedSeasonId("DEFAULT_OPTION");
      setGradeIdList([]);
      setSelectedGradeId("DEFAULT_OPTION");
      setBranchIdList([]);
      setSelectedBranchId("DEFAULT_OPTION");
    } else if (selectedAssignMode === "INSTITUTION") {
      setSeasonIdList([]);
      setSelectedSeasonId("DEFAULT_OPTION");
      setGradeIdList([]);
      setSelectedGradeId("DEFAULT_OPTION");
      setBranchIdList([]);
      setSelectedBranchId("DEFAULT_OPTION");
    } else if (selectedAssignMode === "SEASON") {
      setGradeIdList([]);
      setSelectedGradeId("DEFAULT_OPTION");
      setBranchIdList([]);
      setSelectedBranchId("DEFAULT_OPTION");
    } else if (selectedAssignMode === "GRADE") {
      setBranchIdList([]);
      setSelectedBranchId("DEFAULT_OPTION");
    }
  }, [selectedAssignMode]);

  const retrieveSelectedAssignModeOptionList = () => {
    if (selectedRole === "STAFF") {
      return [
        {
          value: "BRAND",
          icon: "/icons/brand-plus.png",
        },
        {
          value: "CAMPUS",
          icon: "/icons/campus-plus.png",
        },
        {
          value: "INSTITUTION",
          icon: "/icons/institution-plus.png",
        },
        {
          value: "SEASON",
          icon: "/icons/season-plus.png",
        },
        {
          value: "GRADE",
          icon: "/icons/grade-plus.png",
        },
        {
          value: "BRANCH",
          icon: "/icons/branch-plus.png",
        },
      ];
    } else if (selectedRole === "CONTENT_CREATOR") {
      return [
        {
          value: "BRAND",
          icon: "/icons/brand-plus.png",
        },
        {
          value: "CAMPUS",
          icon: "/icons/campus-plus.png",
        },
        {
          value: "INSTITUTION",
          icon: "/icons/institution-plus.png",
        },
      ];
    }
  };

  const filterDefaultUserRoleOptionList = () => {
    return retrieveDefaultUserRoleOptionList().filter((defaultUserRole) =>
      defaultUserRole.value.startsWith("STAFF")
    );
  };

  const retrieveInstitutionDTOOptionList = () => {
    return institutionDTOList.map((institutionDTO) => ({
      value: institutionDTO.id,
      text: `${institutionDTO.name} (${institutionDTO.campusDTO.name} - ${institutionDTO.campusDTO.brandDTO.name})`,
    }));
  };

  const retrieveSeasonDTOOptionList = () => {
    if (userProfile.role === "INDIVIDUAL") {
      return [
        {
          value: userProfileSeason.id,
          text: `${userProfileSeason.name} (${userProfileSeason.institutionDTO.name} - ${userProfileSeason.institutionDTO.campusDTO.name}/${userProfileSeason.institutionDTO.campusDTO.brandDTO.name})`,
        },
      ];
    } else {
      return seasonDTOList.map((seasonDTO) => ({
        value: seasonDTO.id,
        text: `${seasonDTO.name} (${seasonDTO.institutionDTO.name} - ${seasonDTO.institutionDTO.campusDTO.name}/${seasonDTO.institutionDTO.campusDTO.brandDTO.name})`,
      }));
    }
  };

  const retrieveBranchDTOOptionList = () => {
    return branchDTOList.map((branchDTO) => ({
      text: `${branchDTO.name} (${branchDTO.gradeDTO.name} - ${branchDTO.gradeDTO.seasonDTO.name}/${branchDTO.gradeDTO.seasonDTO.institutionDTO.name}/${branchDTO.gradeDTO.seasonDTO.institutionDTO.campusDTO.name}/${branchDTO.gradeDTO.seasonDTO.institutionDTO.campusDTO.brandDTO.name})`,
      value: branchDTO.id,
    }));
  };

  const filterGradeDTOOptionList = () => {
    if (userProfile.role === "STAFF") {
      return retrieveGradeDTOOptionList(gradeDTOList);
    } else if (userProfile.role === "INDIVIDUAL") {
      return [
        {
          value: "CREATE_NEW_GRADE",
          text: i18n.t("create_new_grade"),
        },
        ...retrieveGradeDTOOptionList(gradeDTOList),
      ];
    }
    return [];
  };

  const filterBranchDTOOptionList = () => {
    if (userProfile.role === "STAFF") {
      return retrieveBranchDTOOptionList();
    } else if (userProfile.role === "INDIVIDUAL") {
      return [
        {
          value: "CREATE_NEW_BRANCH",
          text: i18n.t("create_new_branch"),
        },
        ...retrieveBranchDTOOptionList(),
      ];
    }
    return [];
  };

  const filterGradeLevelOptionListBySeasonDTO = () => {
    const foundSeasonDTO = seasonDTOList.find(
      (season) => parseFloat(season.id) === parseFloat(selectedSeasonId)
    );
    return filterGradeLevelOptionList(foundSeasonDTO.institutionDTO, null);
  };

  const checkCanCreateOrUpdateProfile = () => {
    if (userProfile.role === "STAFF") {
      if (selectedRole === "STAFF") {
        if (!selectedAssignMode) return false;
        if (userProfileCampus && campusIdList.length === 0) {
          return false;
        }
        if (userProfileInstitution && institutionIdList.length === 0) {
          return false;
        }
        if (userProfileSeason && seasonIdList.length === 0) {
          return false;
        }
        if (userProfileGrade && gradeIdList.length === 0) {
          return false;
        }
        if (userProfileBranch && branchIdList.length === 0) {
          return false;
        }
      } else if (selectedRole === "INDIVIDUAL") {
        if (coterieTypeList.length === 0) {
          return false;
        }
      } else if (selectedRole === "CONTENT_CREATOR") {
        if (!selectedAssignMode) return false;
        if (userProfileCampus && campusIdList.length === 0) {
          return false;
        }
        if (userProfileInstitution && institutionIdList.length === 0) {
          return false;
        }
      }
    } else if (userProfile.role === "INDIVIDUAL") {
      if (
        (selectedBranchId === "CREATE_NEW_BRANCH" &&
          selectedGradeId === "DEFAULT_OPTION") ||
        (selectedBranchId === "DEFAULT_OPTION" && branchIdList.length === 0)
      ) {
        return false;
      }
    } else if (userProfile.role === "CONTENT_CREATOR") {
      return false;
    }
    return true;
  };

  const checkCommonValidation = () => {
    if (
      !name ||
      name.trim() === "" ||
      !description ||
      description.trim() === "" ||
      selectedRole === "DEFAULT_OPTION" ||
      (selectedRole === "STAFF" && selectedCoterieType === "DEFAULT_OPTION") ||
      (id === null &&
        selectedRole === "STAFF" &&
        selectedDefaultUserRole === "DEFAULT_OPTION") ||
      (user &&
        user.id === null &&
        (user.gender === "DEFAULT_OPTION" ||
          !user.username ||
          user.username.trim() === "" ||
          !user.password ||
          user.password.trim() === "" ||
          !user.email ||
          user.email.trim() === "")) ||
      (selectedRole === "LEARNER" && selectedSeasonId === "DEFAULT_OPTION") ||
      (selectedRole === "INDIVIDUAL" && coterieTypeList.length === 0)
    ) {
      const currentErrorMap = {
        name: null,
        description: null,
        seasonId: null,
        role: null,
        coterieType: null,
        defaultUserRole: null,
      };
      if (!name || name.trim() === "") {
        currentErrorMap.name = i18n.t("name_required_field");
      }
      if (!description || description.trim() === "") {
        currentErrorMap.description = i18n.t("description_required_field");
      }
      if (selectedRole === "DEFAULT_OPTION") {
        currentErrorMap.role = i18n.t("role_required_field");
      }
      if (
        selectedRole === "STAFF" &&
        selectedCoterieType === "DEFAULT_OPTION"
      ) {
        currentErrorMap.coterieType = i18n.t("coterie_type_required_field");
      }
      if (
        id === null &&
        selectedRole === "STAFF" &&
        selectedDefaultUserRole === "DEFAULT_OPTION"
      ) {
        currentErrorMap.defaultUserRole = i18n.t(
          "default_user_role_required_field"
        );
      }
      if (user && user.id === null) {
        if (!user.username || user.username.trim() === "") {
          NotificationManager.error(i18n.t("username_required_field"));
        } else if (!user.password || user.password.trim() === "") {
          NotificationManager.error(i18n.t("password_required_field"));
        } else if (!user.email || user.email.trim() === "") {
          NotificationManager.error(i18n.t("email_required_field"));
        } else if (user.gender === "DEFAULT_OPTION") {
          NotificationManager.error(i18n.t("gender_required_field"));
        }
      }

      if (selectedRole === "LEARNER") {
        if (selectedSeasonId === "DEFAULT_OPTION") {
          currentErrorMap.seasonId = i18n.t("season_required_field");
        }
      }
      if (selectedRole === "INDIVIDUAL" && coterieTypeList.length === 0) {
        NotificationManager.error(i18n.t("coterie_type_list_cannot_be_empty"));
      }
      setErrorMap(currentErrorMap);
      return false;
    }

    if (userProfile.role === "INDIVIDUAL") {
      if (
        (selectedBranchId === "CREATE_NEW_BRANCH" &&
          (!branchName || branchName.trim() === "")) ||
        (selectedGradeId === "CREATE_NEW_GRADE" &&
          (!gradeName ||
            gradeName.trim() === "" ||
            gradeLevel === "DEFAULT_OPTION")) ||
        (selectedBranchId === "CREATE_NEW_BRANCH" &&
          selectedGradeId === "DEFAULT_OPTION")
      ) {
        const currentErrorMap = {
          gradeId: null,
          branchName: null,
          gradeName: null,
          gradeLevel: null,
        };
        if (selectedBranchId === "CREATE_NEW_BRANCH") {
          if (!branchName || branchName.trim() === "") {
            currentErrorMap.branchName = i18n.t("branch_name_required_field");
          }
        }
        if (selectedGradeId === "CREATE_NEW_GRADE") {
          if (!gradeName || gradeName.trim() === "") {
            currentErrorMap.gradeName = i18n.t("grade_name_required_field");
          }
          if (gradeLevel === "DEFAULT_OPTION") {
            currentErrorMap.gradeLevel = i18n.t("grade_level_required_field");
          }
        } else if (
          selectedBranchId === "CREATE_NEW_BRANCH" &&
          selectedGradeId === "DEFAULT_OPTION"
        ) {
          currentErrorMap.gradeId = i18n.t("grade_required_field");
        }
        setErrorMap(currentErrorMap);
        return false;
      }
    }

    if (selectedRole === "STAFF") {
      if (selectedAssignMode === "BRAND") {
        if (
          campusIdList.length > 0 ||
          institutionIdList.length > 0 ||
          seasonIdList.length > 0 ||
          gradeIdList.length > 0 ||
          branchIdList.length > 0
        ) {
          return false;
        }
        if (selectedBrandId === "DEFAULT_OPTION") {
          return false;
        }
      } else if (selectedAssignMode === "CAMPUS") {
        if (
          institutionIdList.length > 0 ||
          seasonIdList.length > 0 ||
          gradeIdList.length > 0 ||
          branchIdList.length > 0
        ) {
          return false;
        }
        if (selectedBrandId === "DEFAULT_OPTION" || campusIdList.length === 0) {
          return false;
        }
      } else if (selectedAssignMode === "INSTITUTION") {
        if (
          seasonIdList.length > 0 ||
          gradeIdList.length > 0 ||
          branchIdList.length > 0
        ) {
          return false;
        }
        if (
          selectedBrandId === "DEFAULT_OPTION" ||
          campusIdList.length === 0 ||
          institutionIdList.length === 0
        ) {
          return false;
        }
      } else if (selectedAssignMode === "SEASON") {
        if (gradeIdList.length > 0 || branchIdList.length > 0) {
          return false;
        }
        if (
          selectedBrandId === "DEFAULT_OPTION" ||
          campusIdList.length === 0 ||
          institutionIdList.length === 0 ||
          seasonIdList.length === 0
        ) {
          return false;
        }
      } else if (selectedAssignMode === "GRADE") {
        if (branchIdList.length > 0) {
          return false;
        }
        if (
          selectedBrandId === "DEFAULT_OPTION" ||
          campusIdList.length === 0 ||
          institutionIdList.length === 0 ||
          seasonIdList.length === 0 ||
          gradeIdList.length === 0
        ) {
          return false;
        }
      }
    } else if (selectedRole === "LEARNER") {
      if (
        selectedSeasonId === "DEFAULT_OPTION" ||
        (selectedBranchId !== "CREATE_NEW_BRANCH" && branchIdList.length === 0)
      ) {
        return false;
      }
    }

    return true;
  };

  const resetParams = () => {
    setSelectedAssignMode(null);
    setSelectedSeasonId("DEFAULT_OPTION");
    setSelectedBrandId(userProfileBrand.id);
    setSelectedCampusId("DEFAULT_OPTION");
    setSelectedInstitutionId("DEFAULT_OPTION");
    setSelectedSeasonId("DEFAULT_OPTION");
    setSelectedGradeId("DEFAULT_OPTION");
    setSelectedBranchId("DEFAULT_OPTION");
    setCampusIdList([]);
    setInstitutionIdList([]);
    setSeasonIdList([]);
    setGradeIdList([]);
    setBranchIdList([]);
    setBranchName(null);
    setGradeName(null);
    setGradeLevel("DEFAULT_OPTION");
  };

  const handleAddCampusToUserProfileOnClick = (selectedCampusId) => {
    if (selectedCampusId !== "DEFAULT_OPTION") {
      const foundIndex = campusIdList.findIndex(
        (campusId) => parseFloat(campusId) === parseFloat(selectedCampusId)
      );
      if (foundIndex === -1) {
        campusIdList.push(selectedCampusId);
        setCampusIdList(JSON.parse(JSON.stringify(campusIdList)));
      }
    }
  };

  const handleAddInstitutionToUserProfileOnClick = (selectedInstitutionId) => {
    if (selectedInstitutionId !== "DEFAULT_OPTION") {
      const foundIndex = institutionIdList.findIndex(
        (institutionId) =>
          parseFloat(institutionId) === parseFloat(selectedInstitutionId)
      );
      if (foundIndex === -1) {
        institutionIdList.push(selectedInstitutionId);
        setInstitutionIdList(JSON.parse(JSON.stringify(institutionIdList)));
      }
    }
  };

  const handleAddSeasonToUserProfileOnClick = (selectedSeasonId) => {
    if (selectedSeasonId !== "DEFAULT_OPTION") {
      const foundIndex = seasonIdList.findIndex(
        (seasonId) => parseFloat(seasonId) === parseFloat(selectedSeasonId)
      );
      if (foundIndex === -1) {
        seasonIdList.push(selectedSeasonId);
        setSeasonIdList(JSON.parse(JSON.stringify(seasonIdList)));
      }
    }
  };

  const handleAddGradeToUserProfileOnClick = (selectedGradeId) => {
    if (selectedGradeId !== "DEFAULT_OPTION") {
      const foundIndex = gradeIdList.findIndex(
        (gradeId) => parseFloat(gradeId) === parseFloat(selectedGradeId)
      );
      if (foundIndex === -1) {
        gradeIdList.push(selectedGradeId);
        setGradeIdList(JSON.parse(JSON.stringify(gradeIdList)));
      }
    }
  };

  const handleAddBranchToUserProfileOnClick = (selectedBranchId) => {
    if (selectedBranchId !== "DEFAULT_OPTION") {
      const foundIndex = branchIdList.findIndex(
        (branchId) => parseFloat(branchId) === parseFloat(selectedBranchId)
      );
      if (foundIndex === -1) {
        if (userProfile.role === "INDIVIDUAL") {
          setBranchIdList(JSON.parse(JSON.stringify([selectedBranchId])));
        } else {
          branchIdList.push(selectedBranchId);
          setBranchIdList(JSON.parse(JSON.stringify(branchIdList)));
        }
      }
    }
  };

  const handleRemoveCampusFromUserProfileOnClick = (campusId) => {
    const foundIndex = campusIdList.findIndex(
      (foundCampusId) => parseFloat(foundCampusId) === parseFloat(campusId)
    );
    if (foundIndex > -1) {
      campusIdList.splice(foundIndex, 1);
      setCampusIdList(JSON.parse(JSON.stringify(campusIdList)));
    }
  };

  const handleRemoveInstitutionFromUserProfileOnClick = (institutionId) => {
    const foundIndex = institutionIdList.findIndex(
      (foundInstitutionId) =>
        parseFloat(foundInstitutionId) === parseFloat(institutionId)
    );
    if (foundIndex > -1) {
      institutionIdList.splice(foundIndex, 1);
      setInstitutionIdList(JSON.parse(JSON.stringify(institutionIdList)));
    }
  };

  const handleRemoveSeasonFromUserProfileOnClick = (seasonId) => {
    const foundIndex = seasonIdList.findIndex(
      (foundSeasonId) => parseFloat(foundSeasonId) === parseFloat(seasonId)
    );
    if (foundIndex > -1) {
      seasonIdList.splice(foundIndex, 1);
      setSeasonIdList(JSON.parse(JSON.stringify(seasonIdList)));
    }
  };

  const handleRemoveGradeFromUserProfileOnClick = (gradeId) => {
    const foundIndex = gradeIdList.findIndex(
      (foundGradeId) => parseFloat(foundGradeId) === parseFloat(gradeId)
    );
    if (foundIndex > -1) {
      gradeIdList.splice(foundIndex, 1);
      setGradeIdList(JSON.parse(JSON.stringify(gradeIdList)));
    }
  };

  const handleRemoveBranchFromUserProfileOnClick = (branchId) => {
    const foundIndex = branchIdList.findIndex(
      (foundBranchId) => parseFloat(foundBranchId) === parseFloat(branchId)
    );
    if (foundIndex > -1) {
      branchIdList.splice(foundIndex, 1);
      setBranchIdList(JSON.parse(JSON.stringify(branchIdList)));
    }
  };

  const handleOnChange = (type, value) => {
    if (type === "name") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: i18n.t("name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          name: null,
        }));
      }
      setName(value);
    } else if (type === "description") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: i18n.t("description_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          description: null,
        }));
      }
      setDescription(value);
    } else if (type === "selectedRole") {
      resetParams();
      setSelectedRole(value);
    } else if (type === "coterieType") {
      setSelectedCoterieType(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        coterieType: null,
      }));
    } else if (type === "coterieTypeAdd") {
      handleAddCoterieTypeOnClick(value);
    } else if (type === "brandId") {
      setSelectedBrandId(value);
    } else if (type === "campusId") {
      setSelectedCampusId(value);
    } else if (type === "campusIdAdd") {
      handleAddCampusToUserProfileOnClick(value);
    } else if (type === "institutionId") {
      setSelectedInstitutionId(value);
    } else if (type === "institutionIdAdd") {
      handleAddInstitutionToUserProfileOnClick(value);
    } else if (type === "seasonId") {
      setSelectedSeasonId(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        seasonId: null,
      }));
    } else if (type === "seasonIdAdd") {
      handleAddSeasonToUserProfileOnClick(value);
    } else if (type === "gradeId") {
      setSelectedGradeId(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        gradeId: null,
      }));
    } else if (type === "gradeIdAdd") {
      handleAddGradeToUserProfileOnClick(value);
    } else if (type === "branchId") {
      setSelectedBranchId(value);
    } else if (type === "branchIdAdd") {
      handleAddBranchToUserProfileOnClick(value);
    } else if (type === "selectedDefaultUserRole") {
      setSelectedDefaultUserRole(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        defaultUserRole: null,
      }));
    } else if (type === "branchName") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          branchName: i18n.t("branch_name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          branchName: null,
        }));
      }
      setBranchName(value);
    } else if (type === "gradeName") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          gradeName: i18n.t("grade_name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          gradeName: null,
        }));
      }
      setGradeName(value);
    } else if (type === "gradeLevel") {
      setGradeLevel(value);
      setErrorMap((prevErrorMap) => ({
        ...prevErrorMap,
        gradeLevel: null,
      }));
    }
  };

  const handleUpdateUserProfileOnClick = async () => {
    if (checkUpdatePermissionGranted("user_profile_list")) {
      if (checkCommonValidation()) {
        const currentUserProfile = {
          id,
          role: selectedRole,
          name,
          description,
          coterieType:
            selectedCoterieType === "DEFAULT_OPTION"
              ? null
              : selectedCoterieType,
          seasonId:
            selectedSeasonId === "DEFAULT_OPTION" ? null : selectedSeasonId,
          brandIdList: [selectedBrandId],
          campusIdList,
          institutionIdList,
          seasonIdList,
          gradeIdList,
          branchIdList,
          coterieTypeList,
        };
        const { data, err } = await patchUserProfile(currentUserProfile);
        if (err) {
          console.log(err);
        } else {
          handleAfterUpdateOnClick(currentUserProfile);
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_update"));
    }
  };

  const handleCreateUserProfileOnClick = async () => {
    if (checkInsertPermissionGranted("user_profile_list")) {
      if (checkCommonValidation()) {
        const newUserProfile = {
          user,
          role: selectedRole,
          name,
          description,
          coterieType:
            selectedCoterieType === "DEFAULT_OPTION"
              ? null
              : selectedCoterieType,
          defaultUserRole:
            selectedDefaultUserRole === "DEFAULT_OPTION"
              ? null
              : selectedDefaultUserRole,
          seasonId:
            selectedSeasonId === "DEFAULT_OPTION" ? null : selectedSeasonId,
          brandIdList: [selectedBrandId],
          campusIdList,
          institutionIdList,
          seasonIdList,
          gradeIdList,
          branchIdList,
          gradeId:
            selectedGradeId === "CREATE_NEW_GRADE" ||
            selectedGradeId === "DEFAULT_OPTION"
              ? null
              : selectedGradeId,
          newBranchName:
            selectedBranchId === "CREATE_NEW_BRANCH" ? branchName : null,
          newGradeName:
            selectedGradeId === "CREATE_NEW_GRADE" ? gradeName : null,
          newGradeLevel:
            selectedGradeId === "CREATE_NEW_GRADE" ? gradeLevel : null,
        };
        let data;
        let err;
        if (newUserProfile.user.id) {
          const { data: byUserIdData, err: byUserIdErr } =
            await createAndAssignUserProfileByUserId(newUserProfile);
          data = byUserIdData;
          err = byUserIdErr;
        } else {
          const { data: withUserData, err: withUserErr } =
            await createUserWithUserProfile(newUserProfile);
          data = withUserData;
          err = withUserErr;
        }

        if (err) {
          console.log(err);
          if (err.response.status === 400) {
            NotificationManager.error(i18n.t(err.response.data));
          }
        } else {
          handleAfterCreateOnClick(newUserProfile);
        }
      }
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const handleAddCoterieTypeOnClick = (newCoterieType) => {
    const foundIndex = coterieTypeList.findIndex(
      (coterieType) => coterieType === newCoterieType
    );
    if (foundIndex === -1) {
      coterieTypeList.push(newCoterieType);
      setCoterieTypeList(JSON.parse(JSON.stringify(coterieTypeList)));
    }
  };

  const handleRemoveCoterieTypeOnClick = (currentCoterieType) => {
    const foundIndex = coterieTypeList.findIndex(
      (coterieType) => coterieType === currentCoterieType
    );
    if (foundIndex !== -1) {
      coterieTypeList.splice(foundIndex, 1);
      setCoterieTypeList(JSON.parse(JSON.stringify(coterieTypeList)));
    }
  };

  const RenderSingleCampusItem = (campusId) => {
    let foundCampusDTO = campusDTOList.find(
      (campusDTO) => parseFloat(campusDTO.id) === parseFloat(campusId)
    );
    if (!foundCampusDTO) {
      foundCampusDTO = {
        id: campusId,
        name: i18n.t("unknown"),
        description: i18n.t("unknown"),
      };
    }
    return (
      <div className="flex flex-row justify-between items-center my-2 w-full">
        <div className="w-1/6">
          <div className="flex flex-row">
            <Tooltip id="item-descriptions-in-user-profile" />
            <div className="mr-2">
              <BaseImage
                size="small"
                src="/icons/info.png"
                alt="info"
                dataToolTipId="item-descriptions-in-user-profile"
                dataToolTipContent={foundCampusDTO.description}
                dataToolTipPlace="top"
                dataToolTipVariant="info"
              />
            </div>
            <p className="mx-1">{foundCampusDTO.name}</p>
          </div>
        </div>
        <div className="w-1/12">
          <p className="mx-1">{foundCampusDTO.brandDTO?.name}</p>
        </div>
        <div className="w-1/12"></div>
        <div className="w-1/12"></div>
        <div className="mx-2">
          <BaseImage
            src="/icons/remove-red.png"
            alt="remove-red"
            onClick={() => {
              handleRemoveCampusFromUserProfileOnClick(foundCampusDTO.id);
            }}
            size="small"
          />
        </div>
      </div>
    );
  };

  const RenderSingleInstitutionItem = (institutionId) => {
    let foundInstitutionDTO = institutionDTOList.find(
      (institutionDTO) => institutionDTO.id === parseFloat(institutionId)
    );
    if (!foundInstitutionDTO) {
      foundInstitutionDTO = {
        id: institutionId,
        name: i18n.t("unknown"),
        description: i18n.t("unknown"),
      };
    }
    return (
      <div className="flex flex-row justify-between items-center my-2 w-full">
        <div className="w-1/6">
          <div className="flex flex-row">
            <Tooltip id="item-descriptions-in-user-profile" />
            <div className="mr-2">
              <BaseImage
                size="small"
                src="/icons/info.png"
                alt="info"
                dataToolTipId="item-descriptions-in-user-profile"
                dataToolTipContent={foundInstitutionDTO.description}
                dataToolTipPlace="top"
                dataToolTipVariant="info"
              />
            </div>
            <p className="mx-1">{foundInstitutionDTO.name}</p>
          </div>
        </div>
        <div className="w-1/12">
          <p className="mx-1">{foundInstitutionDTO.campusDTO?.name}</p>
        </div>
        <div className="w-1/12">
          <p className="mx-1">{foundInstitutionDTO.campusDTO?.brandDTO.name}</p>
        </div>
        <div className="w-1/12"></div>
        <div className="mx-2">
          <BaseImage
            src="/icons/remove-red.png"
            alt="remove-red"
            onClick={() => {
              handleRemoveInstitutionFromUserProfileOnClick(
                foundInstitutionDTO.id
              );
            }}
            size="small"
          />
        </div>
      </div>
    );
  };

  const RenderSingleSeasonItem = (seasonId) => {
    let foundSeasonDTO = seasonDTOList.find(
      (seasonDTO) => seasonDTO.id === parseFloat(seasonId)
    );
    if (!foundSeasonDTO) {
      foundSeasonDTO = {
        id: seasonId,
        name: i18n.t("unknown"),
        description: i18n.t("unknown"),
      };
    }
    return (
      <div className="w-full">
        <div className="flex flex-row justify-between items-center my-2 w-full">
          <div className="w-1/6">
            <div className="flex flex-row">
              <Tooltip id="item-descriptions-in-user-profile" />
              <div className="mr-2">
                <BaseImage
                  size="small"
                  src="/icons/info.png"
                  alt="info"
                  dataToolTipId="item-descriptions-in-user-profile"
                  dataToolTipContent={foundSeasonDTO.description}
                  dataToolTipPlace="top"
                  dataToolTipVariant="info"
                />
              </div>
              <p className="mx-1">{foundSeasonDTO.name}</p>
            </div>
          </div>
          <div className="w-1/12">
            <p className="mx-1">{foundSeasonDTO.institutionDTO?.name}</p>
          </div>

          <div className="w-1/12">
            <p className="mx-1">
              {new Date(foundSeasonDTO.beginAt).toLocaleDateString(language)}
            </p>
          </div>
          <div className="w-1/12">
            <p className="mx-1">
              {new Date(foundSeasonDTO.endAt).toLocaleDateString(language)}
            </p>
          </div>
          <div className="mx-2">
            <BaseImage
              src="/icons/remove-red.png"
              alt="remove-red"
              onClick={() => {
                handleRemoveSeasonFromUserProfileOnClick(foundSeasonDTO.id);
              }}
              size="small"
            />
          </div>
        </div>
      </div>
    );
  };

  const RenderSingleGradeItem = (gradeId) => {
    let foundGradeDTO = gradeDTOList.find(
      (gradeDTO) => gradeDTO.id === parseFloat(gradeId)
    );
    if (!foundGradeDTO) {
      foundGradeDTO = {
        id: gradeId,
        name: i18n.t("unknown"),
        description: i18n.t("unknown"),
      };
    }
    return (
      <div className="w-full">
        <div className="flex flex-row justify-between items-center my-2 w-full">
          <div className="w-1/6">
            <div className="flex flex-row">
              <Tooltip id="item-descriptions-in-user-profile" />
              <div className="mr-2">
                <BaseImage
                  size="small"
                  src="/icons/info.png"
                  alt="info"
                  dataToolTipId="item-descriptions-in-user-profile"
                  dataToolTipContent={foundGradeDTO.description}
                  dataToolTipPlace="top"
                  dataToolTipVariant="info"
                />
              </div>
              <p className="mx-1">{foundGradeDTO.name}</p>
            </div>
          </div>
          <div className="w-1/12">
            <p className="mx-1">{foundGradeDTO.seasonDTO?.name}</p>
          </div>
          <div className="w-1/12">
            <p className="mx-1">
              {foundGradeDTO.seasonDTO?.institutionDTO.name}
            </p>
          </div>
          <div className="w-1/12">
            <p className="mx-1">{foundGradeDTO.level}</p>
          </div>

          <div className="mx-2">
            <BaseImage
              src="/icons/remove-red.png"
              alt="remove-red"
              onClick={() => {
                handleRemoveGradeFromUserProfileOnClick(foundGradeDTO.id);
              }}
              size="small"
            />
          </div>
        </div>
      </div>
    );
  };

  const RenderSingleBranchItem = (branchId) => {
    let foundBranchDTO = branchDTOList.find(
      (branchDTO) => branchDTO.id === parseFloat(branchId)
    );
    if (!foundBranchDTO) {
      foundBranchDTO = {
        id: branchId,
        name: i18n.t("unknown"),
        description: i18n.t("unknown"),
      };
    }
    return (
      <div className="w-full">
        <div className="flex flex-row justify-between items-center my-2 w-full">
          <div className="w-1/6">
            <div className="flex flex-row">
              <Tooltip id="item-descriptions-in-user-profile" />
              <div className="mr-2">
                <BaseImage
                  size="small"
                  src="/icons/info.png"
                  alt="info"
                  dataToolTipId="item-descriptions-in-user-profile"
                  dataToolTipContent={foundBranchDTO.description}
                  dataToolTipPlace="top"
                  dataToolTipVariant="info"
                />
              </div>
              <p className="mx-1">{foundBranchDTO.name}</p>
            </div>
          </div>
          <div className="w-1/12">
            <p className="mx-1">{foundBranchDTO.gradeDTO?.name}</p>
          </div>
          <div className="w-1/12">
            <p className="mx-1">{foundBranchDTO.gradeDTO?.seasonDTO.name}</p>
          </div>
          <div className="w-1/12">
            <p className="mx-1">
              {foundBranchDTO.gradeDTO?.seasonDTO.institutionDTO.name}
            </p>
          </div>
          <div className="mx-2">
            <BaseImage
              src="/icons/remove-red.png"
              alt="remove-red"
              onClick={() => {
                handleRemoveBranchFromUserProfileOnClick(foundBranchDTO.id);
              }}
              size="small"
            />
          </div>
        </div>
      </div>
    );
  };

  const RenderSingleCoterieType = (coterieType) => {
    return (
      <div className="flex flex-row items-center gap-x-2">
        <div>
          <p>{i18n.t(coterieType)}</p>
        </div>
        <div className="w-6 h-6">
          <BaseImage
            src="/icons/remove-red.png"
            alt="remove-red"
            showLoading={true}
            onClick={() => {
              handleRemoveCoterieTypeOnClick(coterieType);
            }}
            size="small"
          />
        </div>
      </div>
    );
  };

  const RenderLearnerProfileContent = () => {
    return (
      <div className="flex flex-col items-center justify-center text-catchup-dark-blue">
        <div className="w-full flex flex-row items-center gap-x-2 my-2">
          <BaseImage src="/icons/info.png" alt="info" size="small" />
          <div className="flex-1">
            <p className="">{i18n.t("profile_name_description_text")}</p>
          </div>
        </div>
        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("name")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="text"
              value={name}
              onChange={(e) => {
                handleOnChange("name", e.target.value);
              }}
              errorText={errorMap.name}
            />
          </div>
        </div>

        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("description")}</p>
          </div>
          <div className="flex-1 h-catchup-description">
            <InputGroup
              type="textarea"
              value={description}
              onChange={(e) => {
                handleOnChange("description", e.target.value);
              }}
              errorText={errorMap.description}
            />
          </div>
        </div>

        {userProfile.role === "INDIVIDUAL" ? null : (
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("season_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedSeasonId}
                optionList={retrieveSeasonDTOOptionList()}
                onChange={(event) => {
                  handleOnChange("seasonId", event.target.value);
                }}
                errorText={errorMap.seasonId}
              />
            </div>
          </div>
        )}

        {selectedSeasonId !== "DEFAULT_OPTION" ? (
          <div className="w-full flex flex-row items-center">
            <div className="w-catchup-input-group-title py-5">
              {selectedBranchId === "CREATE_NEW_BRANCH" ? null : (
                <p>{i18n.t("branch_name")}</p>
              )}
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedBranchId}
                optionList={filterBranchDTOOptionList()}
                onChange={(event) => {
                  if (event.target.value === "CREATE_NEW_BRANCH") {
                    handleOnChange("branchId", event.target.value);
                    setBranchIdList([]);
                  } else {
                    handleOnChange("branchIdAdd", event.target.value);
                    handleOnChange("branchId", "DEFAULT_OPTION");
                  }
                }}
              />
            </div>
          </div>
        ) : null}

        {branchIdList.map((branchId) => (
          <div className="ml-auto w-5/6" key={branchId}>
            {RenderSingleBranchItem(branchId)}
          </div>
        ))}

        {selectedBranchId === "CREATE_NEW_BRANCH" ? (
          <>
            <div className="w-full flex flex-row items-center gap-x-2 my-2">
              <BaseImage src="/icons/info.png" alt="info" size="small" />
              <div className="flex-1">
                <p className="">
                  {i18n.t("branch_name_detailed_explaination_text")}
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("branch_name")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="text"
                  value={branchName}
                  onChange={(e) => {
                    handleOnChange("branchName", e.target.value);
                  }}
                  errorText={errorMap.branchName}
                />
              </div>
            </div>

            <div className="w-full flex flex-row items-center">
              <div className="w-catchup-input-group-title py-5">
                {selectedGradeId === "CREATE_NEW_GRADE" ? null : (
                  <p>{i18n.t("grade_name")}</p>
                )}
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedGradeId}
                  optionList={filterGradeDTOOptionList()}
                  onChange={(event) => {
                    handleOnChange("gradeId", event.target.value);
                  }}
                  errorText={errorMap.gradeId}
                />
              </div>
            </div>
            {selectedGradeId === "CREATE_NEW_GRADE" ? (
              <>
                <div className="w-full flex flex-row items-center gap-x-2 my-2">
                  <BaseImage src="/icons/info.png" alt="info" size="small" />
                  <div className="flex-1">
                    <p className="">
                      {i18n.t("grade_name_detailed_explaination_text")}
                    </p>
                  </div>
                </div>
                <div className="w-full flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("grade_name")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="text"
                      value={gradeName}
                      onChange={(e) => {
                        handleOnChange("gradeName", e.target.value);
                      }}
                      errorText={errorMap.gradeName}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-row">
                  <div className="w-catchup-input-group-title py-5">
                    <p>{i18n.t("grade_level")}</p>
                  </div>
                  <div className="flex-1">
                    <InputGroup
                      type="select"
                      value={gradeLevel}
                      optionList={filterGradeLevelOptionListBySeasonDTO()}
                      onChange={(event) => {
                        handleOnChange("gradeLevel", event.target.value);
                      }}
                      errorText={errorMap.gradeLevel}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </>
        ) : null}

        {checkCanCreateOrUpdateProfile() ? (
          <div className="mt-4 ml-auto">
            {id ? (
              <SecondaryButton
                title={i18n.t("update")}
                size="medium"
                onClick={handleUpdateUserProfileOnClick}
              />
            ) : (
              <CreateButton
                title={i18n.t("create")}
                size="medium"
                onClick={handleCreateUserProfileOnClick}
              />
            )}
          </div>
        ) : null}
      </div>
    );
  };

  const RenderStaffProfileContent = () => {
    return (
      <div className="flex flex-col items-center justify-center">
        {id === null ? (
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("user_sub_role")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedDefaultUserRole}
                optionList={filterDefaultUserRoleOptionList()}
                onChange={(event) => {
                  handleOnChange("selectedDefaultUserRole", event.target.value);
                }}
                errorText={errorMap.defaultUserRole}
              />
            </div>
          </div>
        ) : null}

        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("name")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="text"
              value={name}
              onChange={(e) => {
                handleOnChange("name", e.target.value);
              }}
              errorText={errorMap.name}
            />
          </div>
        </div>

        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("description")}</p>
          </div>
          <div className="flex-1 h-catchup-description">
            <InputGroup
              type="textarea"
              value={description}
              onChange={(e) => {
                handleOnChange("description", e.target.value);
              }}
              errorText={errorMap.description}
            />
          </div>
        </div>

        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("coterie_type")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedCoterieType}
              optionList={retrieveCoterieTypeOptionList()}
              onChange={(event) => {
                handleOnChange("coterieType", event.target.value);
              }}
              errorText={errorMap.coterieType}
            />
          </div>
        </div>

        <div className="flex flex-row items-center gap-x-4 my-5">
          {retrieveSelectedAssignModeOptionList().map((option) => (
            <div
              className={`${
                selectedAssignMode === option.value
                  ? "border rounded-full p-2"
                  : "border-none m-2"
              } transition-all duration-300`}
              onClick={() => {
                setSelectedAssignMode(option.value);
              }}
            >
              <div className="custom-pointer">
                <BaseImage
                  size="large"
                  src={option.icon}
                  alt="assign-mode-option"
                />
              </div>
            </div>
          ))}
        </div>

        {selectedAssignMode === "BRAND" ||
        selectedAssignMode === "CAMPUS" ||
        selectedAssignMode === "INSTITUTION" ||
        selectedAssignMode === "SEASON" ||
        selectedAssignMode === "GRADE" ||
        selectedAssignMode === "BRANCH" ? (
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("brand_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedBrandId}
                optionList={retrieveBrandDTOOptionList(brandDTOList)}
                onChange={(event) => {
                  handleOnChange("brandId", event.target.value);
                }}
              />
            </div>
          </div>
        ) : null}

        {selectedAssignMode === "CAMPUS" ||
        selectedAssignMode === "INSTITUTION" ||
        selectedAssignMode === "SEASON" ||
        selectedAssignMode === "GRADE" ||
        selectedAssignMode === "BRANCH" ? (
          <>
            <div className="w-full flex flex-row items-center">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("campus_name")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedCampusId}
                  optionList={retrieveCampusDTOOptionList(campusDTOList)}
                  onChange={(event) => {
                    handleOnChange("campusIdAdd", event.target.value);
                  }}
                />
              </div>
            </div>
            {campusIdList.map((campusId) => (
              <div className="ml-auto w-5/6" key={campusId}>
                {RenderSingleCampusItem(campusId)}
              </div>
            ))}
          </>
        ) : null}

        {selectedAssignMode === "INSTITUTION" ||
        selectedAssignMode === "SEASON" ||
        selectedAssignMode === "GRADE" ||
        selectedAssignMode === "BRANCH" ? (
          <>
            <div className="w-full flex flex-row items-center">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("institution_name")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedInstitutionId}
                  optionList={retrieveInstitutionDTOOptionList()}
                  onChange={(event) => {
                    handleOnChange("institutionIdAdd", event.target.value);
                  }}
                />
              </div>
            </div>
            {institutionIdList.map((institutionId) => (
              <div className="ml-auto w-5/6" key={institutionId}>
                {RenderSingleInstitutionItem(institutionId)}
              </div>
            ))}
          </>
        ) : null}

        {selectedAssignMode === "SEASON" ||
        selectedAssignMode === "GRADE" ||
        selectedAssignMode === "BRANCH" ? (
          <>
            <div className="w-full flex flex-row items-center">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("season_name")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedSeasonId}
                  optionList={retrieveSeasonDTOOptionList()}
                  onChange={(event) => {
                    handleOnChange("seasonIdAdd", event.target.value);
                  }}
                />
              </div>
            </div>
            {seasonIdList.map((seasonId) => (
              <div className="ml-auto w-5/6" key={seasonId}>
                {RenderSingleSeasonItem(seasonId)}
              </div>
            ))}
          </>
        ) : null}

        {selectedAssignMode === "GRADE" || selectedAssignMode === "BRANCH" ? (
          <>
            <div className="w-full flex flex-row items-center">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("grade_name")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedGradeId}
                  optionList={retrieveGradeDTOOptionList(gradeDTOList)}
                  onChange={(event) => {
                    handleOnChange("gradeIdAdd", event.target.value);
                  }}
                />
              </div>
            </div>
            {gradeIdList.map((gradeId) => (
              <div className="ml-auto w-5/6" key={gradeId}>
                {RenderSingleGradeItem(gradeId)}
              </div>
            ))}
          </>
        ) : null}

        {selectedAssignMode === "BRANCH" ? (
          <>
            <div className="w-full flex flex-row items-center">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("branch_name")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedBranchId}
                  optionList={retrieveBranchDTOOptionList()}
                  onChange={(event) => {
                    handleOnChange("branchIdAdd", event.target.value);
                  }}
                />
              </div>
            </div>
            {branchIdList.map((branchId) => (
              <div className="ml-auto w-5/6" key={branchId}>
                {RenderSingleBranchItem(branchId)}
              </div>
            ))}
          </>
        ) : null}

        {checkCanCreateOrUpdateProfile() ? (
          id ? (
            <div className="mt-4 ml-auto">
              <SecondaryButton
                title={i18n.t("update")}
                size="medium"
                onClick={handleUpdateUserProfileOnClick}
              />
            </div>
          ) : (
            <div className="mt-4 ml-auto">
              <CreateButton
                title={i18n.t("create")}
                size="medium"
                onClick={handleCreateUserProfileOnClick}
              />
            </div>
          )
        ) : null}
      </div>
    );
  };

  const RenderIndividualProfileContent = () => {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("name")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="text"
              value={name}
              onChange={(e) => {
                handleOnChange("name", e.target.value);
              }}
              errorText={errorMap.name}
            />
          </div>
        </div>

        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("description")}</p>
          </div>
          <div className="flex-1 h-catchup-description">
            <InputGroup
              type="textarea"
              value={description}
              onChange={(e) => {
                handleOnChange("description", e.target.value);
              }}
              errorText={errorMap.description}
            />
          </div>
        </div>

        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("coterie_type")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedCoterieType}
              optionList={filterCoterieTypeOptionList(
                userInformation,
                selectedUserProfile,
                userProfileInstitution
              )}
              onChange={(event) => {
                handleOnChange("coterieTypeAdd", event.target.value);
              }}
            />
          </div>
        </div>

        <div className="w-full ml-auto flex flex-row flex-wrap gap-x-4 gap-y-2">
          <div className="w-catchup-input-group-title py-5"></div>
          {coterieTypeList.map((coterieType, coterieTypeIndex) => (
            <div className="" key={coterieTypeIndex}>
              {RenderSingleCoterieType(coterieType)}
            </div>
          ))}
        </div>

        {checkCanCreateOrUpdateProfile() ? (
          id ? (
            <div className="mt-4 ml-auto">
              <SecondaryButton
                title={i18n.t("update")}
                size="medium"
                onClick={handleUpdateUserProfileOnClick}
              />
            </div>
          ) : null
        ) : null}
      </div>
    );
  };

  const RenderContentCreatorProfileContent = () => {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("name")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="text"
              value={name}
              onChange={(e) => {
                handleOnChange("name", e.target.value);
              }}
              errorText={errorMap.name}
            />
          </div>
        </div>

        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("description")}</p>
          </div>
          <div className="flex-1 h-catchup-description">
            <InputGroup
              type="textarea"
              value={description}
              onChange={(e) => {
                handleOnChange("description", e.target.value);
              }}
              errorText={errorMap.description}
            />
          </div>
        </div>

        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("coterie_type")}</p>
          </div>
          <div className="flex-1">
            <InputGroup
              type="select"
              value={selectedCoterieType}
              optionList={retrieveCoterieTypeOptionList()}
              onChange={(event) => {
                handleOnChange("coterieType", event.target.value);
              }}
              errorText={errorMap.coterieType}
            />
          </div>
        </div>

        <div className="flex flex-row items-center gap-x-4 my-5">
          {retrieveSelectedAssignModeOptionList().map((option) => (
            <div
              className={`${
                selectedAssignMode === option.value
                  ? "border rounded-full p-2"
                  : "border-none m-2"
              } transition-all duration-300`}
              onClick={() => {
                setSelectedAssignMode(option.value);
              }}
            >
              <div className="custom-pointer">
                <BaseImage
                  size="large"
                  src={option.icon}
                  alt="assign-mode-option"
                />
              </div>
            </div>
          ))}
        </div>

        {selectedAssignMode === "BRAND" ||
        selectedAssignMode === "CAMPUS" ||
        selectedAssignMode === "INSTITUTION" ? (
          <div className="w-full flex flex-row">
            <div className="w-catchup-input-group-title py-5">
              <p>{i18n.t("brand_name")}</p>
            </div>
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedBrandId}
                optionList={retrieveBrandDTOOptionList(brandDTOList)}
                onChange={(event) => {
                  handleOnChange("brandId", event.target.value);
                }}
              />
            </div>
          </div>
        ) : null}

        {selectedAssignMode === "CAMPUS" ||
        selectedAssignMode === "INSTITUTION" ? (
          <>
            <div className="w-full flex flex-row items-center">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("campus_name")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedCampusId}
                  optionList={retrieveCampusDTOOptionList(campusDTOList)}
                  onChange={(event) => {
                    handleOnChange("campusIdAdd", event.target.value);
                  }}
                />
              </div>
            </div>
            {campusIdList.map((campusId) => (
              <div className="ml-auto w-5/6" key={campusId}>
                {RenderSingleCampusItem(campusId)}
              </div>
            ))}
          </>
        ) : null}

        {selectedAssignMode === "INSTITUTION" ? (
          <>
            <div className="w-full flex flex-row items-center">
              <div className="w-catchup-input-group-title py-5">
                <p>{i18n.t("institution_name")}</p>
              </div>
              <div className="flex-1">
                <InputGroup
                  type="select"
                  value={selectedInstitutionId}
                  optionList={retrieveInstitutionDTOOptionList()}
                  onChange={(event) => {
                    handleOnChange("institutionIdAdd", event.target.value);
                  }}
                />
              </div>
            </div>
            {institutionIdList.map((institutionId) => (
              <div className="ml-auto w-5/6" key={institutionId}>
                {RenderSingleInstitutionItem(institutionId)}
              </div>
            ))}
          </>
        ) : null}

        {checkCanCreateOrUpdateProfile() ? (
          <div className="mt-4 ml-auto">
            {id ? (
              <SecondaryButton
                title={i18n.t("update")}
                size="medium"
                onClick={handleUpdateUserProfileOnClick}
              />
            ) : (
              <CreateButton
                title={i18n.t("create")}
                size="medium"
                onClick={handleCreateUserProfileOnClick}
              />
            )}
          </div>
        ) : null}
      </div>
    );
  };

  const RenderMainContent = () => {
    return (
      <>
        <div className="w-full">
          <p className="font-semibold text-lg">
            {i18n.t("user_profile_information")}
          </p>
        </div>
        <div className="w-full flex flex-row">
          <div className="w-catchup-input-group-title py-5">
            <p>{i18n.t("role")}</p>
          </div>
          {id === null ? (
            <div className="flex-1">
              <InputGroup
                type="select"
                value={selectedRole}
                optionList={filterUserRoleOptionList(
                  userInformation.accountType,
                  userProfile.role
                )}
                onChange={(event) => {
                  handleOnChange("selectedRole", event.target.value);
                }}
                errorText={errorMap.role}
              />
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <p>{i18n.t(selectedRole)}</p>
            </div>
          )}
        </div>
        {selectedRole === "LEARNER"
          ? RenderLearnerProfileContent()
          : selectedRole === "STAFF"
          ? RenderStaffProfileContent()
          : selectedRole === "INDIVIDUAL"
          ? RenderIndividualProfileContent()
          : selectedRole === "CONTENT_CREATOR"
          ? RenderContentCreatorProfileContent()
          : null}
      </>
    );
  };

  return useCardView ? (
    <FullCard isShadowed={true}>{RenderMainContent()}</FullCard>
  ) : (
    <div className="w-full">{RenderMainContent()}</div>
  );
};

export default CreateUserProfile;
